import React, { useState } from "react"

import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import PrintIcon from "@material-ui/icons/Print"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import UsedDialog from "Components/UI/Organisms/Plant/Used/Dialog"
import SetState from "Components/Hooks/SetState"
import EditContextProvider from "Components/Hooks/ContextEdit"
import { interfaces } from "service/interfaces"
import { formatter, printPDF } from "Helpers"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Loader from "Components/UI/Atoms/Loader"

import { columnTheme } from "./styles"

const UsedTable = (props) => {
  const { render, setRender, setCanExport } = props

  const windowSize = useMediaQuery("(min-width:1000px)")

  const createDismissableSnackbar = useCustomSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const { state, setClose, setOpen } = SetState()
  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantUsed.list(options)
      setInfo({
        filters: result.data.filters,
        rows: result.data.list,
        header: result.header.header.count,
      })
      setCanExport(!result.data.canExport)
      callback()
    } catch (error) {}
  }

  async function openDialog(id) {
    await interfaces.plantUsed.usedInfo(id).then((res) => {
      setOpen({ dataObject: res.data })
    })
  }

  async function printId(e, id) {
    e.stopPropagation()
    setIsLoading(true)
    await interfaces.plantUsed
      .stockSticker(id)
      .then((res) => {
        printPDF(res).print()
        createDismissableSnackbar("Sticker impreso con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  const actions = (file) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        <Tooltip title="Imprimir">
          <IconButton
            onClick={(e) => printId(e, file.id)}
            style={{ color: "black" }}
            size={windowSize ? "medium" : "normal"}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const RowsUsed = () =>
    info.rows.map((file) => [
      "",
      file.codigo,
      file.producto,
      file.vehiculo,
      file.motor,
      file.calidad,
      file.estado,
      file.almacen,
      file.ubicacion,
      formatter.format(file.precio),
      actions(file),
      "",
    ])

  const columns = columnsForTable(
    [
      {
        name: "cod",
        label: "Código",
        filterLabel: "Cod",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
        marginLeft: windowSize ? "10px" : "",
      },
      {
        name: "producto",
        label: "Producto",
        display: true,
        filter: false,
        sort: false,
        filterName: "producto",
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "nivel",
        label: "Vehículo",
        display: windowSize ? true : false,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "motor",
        label: "Motor",
        display: windowSize ? true : false,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "codigo",
        customBodyRender: true,
        options: info.filters.motores,
      },
      {
        name: "calidad",
        label: "Calidad",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        fullWidth: true,
        options: info.filters.calidades,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: false,
        sort: false,
      },
      {
        label: "Almacén",
        name: "almacen",
        display: true,
        filter: false,
        sort: false,
      },
      {
        label: "Ubicación",
        name: "ubicacion",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        fullWidth: true,
        options: info.filters.ubicaciones,
      },
      {
        label: "Precio",
        name: "precio",
        display: true,
        filter: false,
        sort: false,
        filterName: "nombre",
      },
      {
        label: "",
        display: true,
      },
      {
        label: "Marca",
        name: "marca",
        display: false,
        filter: true,
        sort: false,
        filterName: "marca",
        fullWidth: true,
        options: info.filters.brands,
      },
      {
        label: "Modelo",
        name: "modelo",
        display: false,
        filter: true,
        sort: false,
        filterName: "modelo",
        fullWidth: true,
        options: info.filters.models,
      },
    ],
    info.filters
  )

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <UsedDialog
            open={state.open}
            handleclose={setClose}
            data={state.dataObject}
            setRender={setRender}
            render={render}
          />
        </EditContextProvider>
      )}

      {isLoading && <Loader />}

      <MuiDatables
        columns={columns}
        data={RowsUsed()}
        rows={25}
        title={`Cantidad de productos: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar por vehículo o código…"
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        padding={windowSize ? "none" : ""}
        setRowProps={(row) => {
          if (row[11]) {
            return {
              style: { background: "#c3c3c3" },
            }
          }
        }}
        onRowClick={(_, index) => openDialog(info.rows[index.dataIndex].id)}
      />
    </>
  )
}
export default UsedTable
