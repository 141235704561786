import React, { useEffect } from "react"

import TemplateTransitVehicle from "Components/UI/Templates/Vehicles/TransitVehicle"

const TransitVehicles = () => {
  useEffect(() => {
    document.title = "Tránsito de vehículos"
  }, [])

  return <TemplateTransitVehicle />
}

export default TransitVehicles
