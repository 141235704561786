import React, { useEffect, useState } from "react"

import CustomButton from "Components/UI/Atoms/CustomButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import ListItemNotification from "Components/UI/Molecules/ListItemNotification"

import { interfaces } from "service/interfaces"

const NotiHistory = () => {
  const createDismissableSnackbar = useCustomSnackbar()

  const [data, setData] = useState([])
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    interfaces.dashboardInterface
      .listNotification(0, offset)
      .then((res) => setData((oldArray) => [...oldArray, ...res.data]))
  }, [offset])

  const deleteNotification = (id) => {
    const newArray = [...data].filter((e) => e.id !== id)
    setData(newArray)
    interfaces.dashboardInterface.deleteNotification(id).then((res) => {
      createDismissableSnackbar(res.data, {
        variant: "success",
      })
    })
  }

  return (
    <>
      <ListItemNotification
        data={data}
        deleteNotification={deleteNotification}
      />
      <div
        style={{
          bottom: "0px",
          width: "100%",
        }}
      >
        <CustomButton
          fullWidth
          text="Ver más"
          disabled={data.length % 25 !== 0 || data.length === 0 ? true : false}
          onClick={() => setOffset(offset + 1)}
        />
      </div>
    </>
  )
}

export default NotiHistory
