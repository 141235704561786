import React, { useRef, useState } from "react"

import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { newUser } from "Components/Validation/Settings/Users"
import { interfaces } from "service/interfaces"

const NewUser = (props) => {
  const { open, handleclose, render, setRender, data } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [loading, setLoading] = useState(false)

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    setLoading(true)
    await interfaces.configUsersInterface
      .newUser(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleclose()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const NEW_USER = (setFieldValue, values) => {
    return [
      {
        class: "textfield",
        label: "Nombre",
        name: "nombre",
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        label: "Apellido",
        name: "apellido",
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        label: "Nombre de usuario",
        name: "username",
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        type: "number",
        label: "Legajo",
        name: "legajo",
        sm: 12,
        xs: 12,
      },
      {
        class: "autocomplete",
        label: "Ubicación",
        name: "ubicacion_id",
        options: data.ubicaciones,
        optionLabel: `options["nombre"]`,
        optionValue: "id",
        sm: 12,
        xs: 12,
      },
      {
        class: "autocomplete",
        label: "Área",
        name: "area",
        options: data.areas,
        optionLabel: `options["label"]`,
        optionValue: "value",
        sm: 12,
        xs: 12,
      },
      {
        class: "autocomplete",
        label: "Rol",
        name: "puesto",
        options: data.roles,
        optionLabel: `options["label"]`,
        optionValue: "value",
        onInputChange: (_) => {
          setFieldValue(values.puesto)
        },
        freeSolo: "true",
        sm: 12,
        xs: 12,
      },
    ]
  }

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          nombre: "",
          apellido: "",
          username: "",
          legajo: "",
          ubicacion_id: null,
          area: "",
          puesto: "",
        }}
        validationSchema={newUser}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={NEW_USER(setFieldValue, values)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      handleclosedisabled={loading}
      children={children()}
      title={"Nuevo usuario"}
      disableBackdropClick
      disableEscapeKeyDown
      maxHeight
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "2px",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleclose}
            onClickSubmit={handleSubmit}
            submitText={"Guardar"}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default NewUser
