import React, { useState, useContext } from "react"

import { Grid, Typography, Card, ButtonBase } from "@material-ui/core"

import { useStyles } from "./styles"
import { interfaces } from "service/interfaces"
import PictureCard from "Components/UI/Atoms/PictureCard"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import Loader from "Components/UI/Atoms/Loader"
import DialogUpload from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/Files/DialogUpload"
import { shortenUrlFiles } from "Helpers"
import DeleteFile from "Components/UI/Molecules/DeleteFile"

import { EditContext } from "Components/Hooks/ContextEdit"

export default function ViewFiles(props) {
  const { file, id, data } = props
  const [edit] = useContext(EditContext)

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [changeUpload, setChangeUpload] = useState([])
  const createDismissableSnackbar = useCustomSnackbar()

  const [startDelete, setStartDelete] = useState({
    open: false,
    id: null,
    index: null,
  })
  const [startEdit, setStartEdit] = useState({
    open: false,
    id: null,
  })

  function handleChangeFile(e) {
    e.preventDefault()
    let img = e.target.files[0]
    setChangeUpload(img)
  }
  function handleOpenDelete(index) {
    setStartDelete({
      open: true,
      index: index,
      data: data.data.id,
      id: data.data.id,
    })
  }
  function handleOpenUpload() {
    setStartEdit({
      open: true,
      id: id,
    })
  }
  function handleCloseDelete() {
    setStartDelete({
      open: false,
      index: null,
    })
  }
  function handleCloseUpload() {
    setChangeUpload([])
    setStartEdit({
      open: false,
    })
  }
  function handleAdd(arr) {
    let lastElement = arr[arr.length - 1]
    file.push(lastElement)
  }
  function handleDeleteContext() {
    file.splice(startDelete.index, 1)
  }

  async function handleSubmitDelete(_, index) {
    setIsLoading(true)
    let position = startDelete.index + 1
    await interfaces.plantUsed
      .deleteFile(data.data.id, position)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleDeleteContext()
        handleCloseDelete()
        setIsLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setIsLoading(false)
      })
  }

  async function handleUploadFile() {
    setIsLoading(true)
    await interfaces.plantUsed
      .uploadFiles(data.data.id, changeUpload)
      .then((res) => {
        createDismissableSnackbar(`${res.data.msg}`, {
          variant: "success",
        })
        handleAdd(res.data.files)
        handleCloseUpload()
        setIsLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setIsLoading(false)
      })
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {file.map((item, index) => (
          <Grid item xs={12} xl={4} sm={6} md={4} className={classes.root2}>
            <PictureCard
              img="https://is2-ssl.mzstatic.com/image/thumb/Purple125/v4/73/bf/7f/73bf7f96-57f5-86ec-f1bc-e092493ab189/AppIcon-0-1x_U007emarketing-0-7-0-85-220.png/1200x630wa.png"
              href={item}
              edit={edit}
              txt={shortenUrlFiles(file[index])}
              index={file.index}
              handleDelete={() => handleOpenDelete(index)}
            />
          </Grid>
        ))}
        {edit ? (
          <Grid item xs={12} xl={4} sm={6} md={4} style={{ marginTop: "10px" }}>
            <ButtonBase component="a" onClick={() => handleOpenUpload()}>
              <Card className={classes.cardSelectImg}>
                <Typography>Seleccione un archivo..</Typography>
              </Card>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>

      <DeleteFile
        onSubmit={handleSubmitDelete}
        handleClose={handleCloseDelete}
        open={startDelete.open}
      />

      {startEdit.open && (
        <DialogUpload
          handleChange={handleChangeFile}
          change={changeUpload}
          onSubmit={() => handleUploadFile()}
          handleClose={handleCloseUpload}
          open={startEdit.open}
          loading={isLoading}
        />
      )}
      {isLoading && <Loader />}
    </>
  )
}
