import React, { useContext, useEffect, useState } from "react"

import { styles } from "./styles"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"
import BasicTable from "Components/UI/Atoms/BasicTable"
import { logo } from "Helpers"
import CustomButton from "Components/UI/Atoms/CustomButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { EditContext } from "Components/Hooks/ContextEdit"

const Stock = () => {
  const classes = styles()
  const createDismissableSnackbar = useCustomSnackbar()
  const [edit] = useContext(EditContext)
  const [isLoading, setIsLoading] = useState(false)
  const { formEdit, setFormEdit } = useContext(FormContext)

  useEffect(() => {
    async function apiCall() {
      await interfaces.vehicleInterface
        .getVehiclesStock(formEdit.vehicle.id)
        .then((res) => {
          setFormEdit({ ...formEdit, stock: res.data.data, stockApi: true })
        })
    }
    !formEdit.stockApi && apiCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async () => {
    setIsLoading(true)
    await interfaces.vehicleInterface
      .getTransferedStock(formEdit.vehicle.id)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar(
          "Listado de piezas transeferidas descargada!",
          {
            variant: "success",
          }
        )
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <BasicTable
          col={[
            { title: "", style: { width: "20px" } },
            { title: "Código", style: { width: "55px" } },
            { title: "Pieza", style: { width: "300px" } },
            { title: "Estado", style: { width: "100px" } },
            { title: "Ubicación", style: { width: "100px" } },
            { title: "Almacén", style: { width: "100px" } },
          ]}
          size={"small"}
          rows={formEdit.stock?.map((row) => {
            return {
              codigo: logo(row.codigo[0]),
              code: row.codigo,
              producto: row.producto,
              estado: row.estado,
              ubicacion: row.ubicacion,
              almacen: row.almacen,
            }
          })}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 20px 10px 10px",
        }}
      >
        <CustomButton
          text="PIEZAS TRANSFERIDAS"
          onClick={() => onClick()}
          isLoading={isLoading}
          disabled={isLoading || !edit || !Boolean(formEdit.stock?.length)}
        />
      </div>
    </div>
  )
}

export default Stock
