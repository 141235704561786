import React, { useEffect } from "react"

import PriceTemplate from "Components/UI/Templates/Sales/Prices"

const Prices = () => {
  useEffect(() => {
    document.title = "Precios"
  }, [])

  return <PriceTemplate />
}

export default Prices
