import React, { useState } from "react"

import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import TemplateGlobal from "Components/UI/Templates/Common"
import NavbarGlobal from "Components/UI/Molecules/NavBar"

import ContentEcosoft from "Components/UI/Organisms/Ecosoft/Body"

const EcosoftTemplate = () => {
  const BUTTONS = [
    {
      menuIcon: <ShoppingCartIcon fontSize="small" />,
      menuTitle: "Pedidos",
      buttonContent: "Pedidos",
      buttonStartIcon: <ShoppingCartIcon />,
      disabled: true,
    },
  ]

  const [count, setCount] = useState(0)

  return (
    <TemplateGlobal
      navbar={
        <NavbarGlobal
          title="ECOSOFT "
          subTitle={`Vehículos: ${count}`}
          ecoSoft={true}
          buttons={BUTTONS}
        />
      }
      table={<ContentEcosoft count={count} setCount={setCount} />}
    />
  )
}

export default EcosoftTemplate
