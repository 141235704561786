import React, { useState, useEffect } from "react"

import InputSearch from "Components/UI/Atoms/InputSearch"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import Edit from "Components/UI/Organisms/Settings/Various/Operators/Content/Edit"
import New from "Components/UI/Organisms/Settings/Various/Operators/Content/New"

import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const ContentOperators = () => {
  const createDismissableSnackbar = useCustomSnackbar()
  const [data, setData] = useState([])
  const [render, setRender] = useState(true)
  const { state: edit, setOpen: openEdit, setClose: closeEdit } = SetState()
  const {
    state: newOperator,
    setOpen: openNew,
    setClose: closeNew,
  } = SetState()
  const [id, setId] = useState()
  const [valueSearch, setValueSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const onChangeNew = (e) => {
    setValueSearch(e.target.value)
  }

  const handleListItemClick = (id) => {
    setId(id.id)
  }

  const changeRender = () => {
    setRender(!render)
  }

  useEffect(() => {
    interfaces.operatorsInterface.list().then((res) => setData(res.data))
  }, [render])

  async function handleExportOperators() {
    setLoading(true)
    await interfaces.operatorsInterface
      .exportOperator(id)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Operario exportado con éxito.", {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueSearch) {
          return e.nombre.includes(valueSearch.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  function editInfo() {
    return data.filter((e) => {
      return e.id === id
    })[0]
  }
  return (
    <>
      <InputSearch
        onClick={() => openNew()}
        onChange={onChangeNew}
        value={valueSearch}
      />
      <PaperList
        children={
          <ListWithIcon
            data={filtered(data)}
            nameIncludes="nombre"
            selectedItem={id}
            onClickSelect={handleListItemClick}
            onClickEdit={openEdit}
            onClickExport={handleExportOperators}
            loading={loading}
          />
        }
        height="500px"
        styles={{ marginBottom: "15px" }}
      />
      {edit.suspense && (
        <Edit
          open={edit.open}
          handleclose={closeEdit}
          id={id}
          editInfo={editInfo}
          changeRender={changeRender}
        />
      )}
      {newOperator.suspense && (
        <New
          open={newOperator.open}
          handleclose={closeNew}
          changeRender={changeRender}
        />
      )}
    </>
  )
}

export default ContentOperators
