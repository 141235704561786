import React, { useState } from "react"
import BarcodeReader from "react-barcode-reader"

import HomeIcon from "@material-ui/icons/Home"
import { Typography, IconButton, Grid } from "@material-ui/core"
import { Hidden } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import SimpleTable from "Components/UI/Atoms/SimpleTable"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import { columnTheme } from "Components/UI/Organisms/Plant/Stock/TableIngress/StylesColumn"
import SecondNav from "Components/UI/Molecules/SecondNav"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import useStyles from "./styles"
import { interfaces } from "service/interfaces"
import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { getUbicacion } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"

const TableMovement = () => {
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()

  const windowSize = useMediaQuery("(min-width:1000px)")

  const location = getUbicacion().includes("ECO") ? 1 : 2

  const [enterprise, setEnterprise] = useState(location)
  const [enterpriseCode, setEnterpriseCode] = useState()
  const [scanField, setScanField] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingCode, setLoadingCode] = useState(false)
  const {
    state: dialog,
    setOpen: setOpenDialog,
    setClose: setCloseDialog
  } = SetState()

  const [dataScanField, setDataScanField] = useState({
    rows: [],
    header: [],
  })

  async function apiCall(callback) {
    const result = await interfaces.plantInterfaceStock.ingressList()
    setDataScanField({
      rows: result.data,
      header: result.header.header,
    })
    callback()
  }

  async function onSubmitField(values) {
    setLoadingCode(true)
    let body = values
    if (typeof values === "object") {
      body = Object.values(values)
    }
    await interfaces.plantInterfaceStock
      .ingressAdd({
        codigo: body,
        deposito: enterprise,
        empresa: enterpriseCode,
      })
      .then(() => {
        createDismissableSnackbar("Item agregado con éxito! Actualizando...", {
          variant: "success",
        })
        setScanField([])
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoadingCode(false)
  }

  function scanCode(data) {
    onSubmitField(data)
  }

  async function onSubmitProcess() {
    setLoading(true)
    await interfaces.plantInterfaceStock
      .ingressProcess()
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setScanField([])
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  async function deleteOrClear(id) {
    await interfaces.plantInterfaceStock
      .ingressDelete(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setScanField([])
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const actions = (id) => {
    return (
      <IconButton onClick={() => deleteOrClear(id)}>
        <CloseIcon />
      </IconButton>
    )
  }

  const Rows = () =>
    dataScanField.rows.map((file, index) => [
      "",
      file.codigo,
      file.producto,
      <>
        {file.vehiculo} {file.nrodev ? ` - V${file.nrodev}` : ""}
      </>,
      file.calidad,
      file.estado,
      file.ubicacion,
      file.fecha,
      actions(file.id),
    ])

  const columns = columnsForTable([
    {
      name: "Codigo",
      label: "Código",
      display: true,
      filter: false,
      sort: false,
      customBodyRender: true,
      marginLeft: windowSize ? "10px" : "",
    },
    {
      name: "producto",
      label: "Producto",
      display: true,
      filter: false,
      sort: false,
    },
    {
      name: "vehiculo",
      label: "Vehículo",
      display: true,
      filter: false,
      sort: false,
    },
    {
      name: "calidad",
      label: "Calidad",
      display: true,
      filter: false,
      sort: false,
    },
    {
      name: "estado",
      label: "Estado",
      display: true,
      filter: false,
      sort: false,
    },
    {
      label: <HomeIcon />,
      display: true,
      customBodyRender: true,
      marginLeft: windowSize ? "5px" : "",
    },
    {
      name: "fecha",
      label: "Fecha de carga",
      display: true,
      filter: false,
      sort: false,
    },
    {
      label: "",
      display: true,
    },
  ])

  const entepriseOptions = [
    { id: 1, label: "ECO" },
    { id: 2, label: "OEM" },
  ]

  const title = () => {
    return (
      <div className={classes.divFlex}>
        <Hidden xsDown>
          <CustomAutocomplete
            options={entepriseOptions}
            getOptionLabel={(option) => option.label}
            defaultValue={entepriseOptions.find((e) => e.id === location)}
            value={entepriseOptions.find((e) => e.id === enterprise)}
            onChange={(_, value) => {
              setEnterprise(value.id)
            }}
            label="Depósito"
            size="small"
            style={{ width: "100px" }}
          />
          <CustomAutocomplete
            options={entepriseOptions}
            getOptionLabel={(option) => option.label}
            value={entepriseOptions.find((e) => e.id === enterpriseCode)}
            onChange={(_, value) => {
              setEnterpriseCode(value.id)
            }}
            label="Empresa pieza"
            size="small"
            style={{ marginLeft: "5px", width: "150px" }}
          />
          <SecondNav
            open={dialog.open}
            openDialog={setOpenDialog}
            closeDialog={setCloseDialog}
            scanField={scanField}
            dataScanField={dataScanField}
            onSubmitField={onSubmitField}
            onSubmitProcess={onSubmitProcess}
            onSubmitCancel={deleteOrClear}
            loading={loading}
            hiddenColector
            colorlessBtn
            loadingCode={loadingCode}
          />
        </Hidden>
        <Typography variant="h6" style={{ color: "black" }}>
          &nbsp;Cantidad de piezas:&nbsp;
          {dataScanField.rows.filter((e) => e.vehiculo).length}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <BarcodeReader onScan={scanCode} />
      <Hidden smUp>
        <Grid container spacing={2} style={{ marginBottom: "5px" }}>
          <Grid item xs={6}>
            <CustomAutocomplete
              options={entepriseOptions}
              getOptionLabel={(option) => option.label}
              value={entepriseOptions.find((e) => e.id === enterprise)}
              onChange={(_, value) => {
                setEnterprise(value.id)
              }}
              label="Depósito"
              size="small"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              options={entepriseOptions}
              getOptionLabel={(option) => option.label}
              value={entepriseOptions.find((e) => e.id === enterpriseCode)}
              onChange={(_, value) => {
                setEnterpriseCode(value.id)
              }}
              label="Empresa pieza"
              size="small"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>

        <SecondNav
          open={dialog.open}
          openDialog={setOpenDialog}
          closeDialog={setCloseDialog}
          scanField={scanField}
          dataScanField={dataScanField}
          onSubmitField={onSubmitField}
          onSubmitProcess={onSubmitProcess}
          onSubmitCancel={deleteOrClear}
          loading={loading}
          hiddenColector
          colorlessBtn
          loadingCode={loadingCode}
        />
      </Hidden>
      <SimpleTable
        count={2}
        data={Rows()}
        title={title()}
        columns={columns}
        apiCall={apiCall}
        render={scanField}
        columnTheme={columnTheme()}
        padding={windowSize ? "none" : ""}
      />
    </>
  )
}
export default TableMovement
