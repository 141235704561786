import React, { useContext, useState } from "react"
import CustomButton from "Components/UI/Atoms/CustomButton"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { getUserId } from "service/helpers/localstorage"
import { EditContext } from "Components/Hooks/ContextEdit"

const LowChange = ({
  vehicle_id,
  tipo_baja_id,
  afterSubmitDNRPA,
  estado_id,
}) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const { state, setOpen, setClose } = SetState()
  const [edit] = useContext(EditContext)
  const [lowChange, setLowChange] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = () => {
    apiCall(lowChange)
  }

  const apiCall = async (tipo) => {
    setIsLoading(true)
    await interfaces.vehicleInterface[tipo](vehicle_id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
    afterSubmitDNRPA()
    setClose()
  }

  const options = [
    { label: "04-BD", value: "convert04BD" },
    { label: "04-D NO APTO", value: "convert04Not" },
  ]

  const optionsDisabled = (options) => {
    if (options.value === "convert04BD" && !([1, 3].indexOf(tipo_baja_id) > -1))
      return true
    if (options.value === "convert04Not" && !(tipo_baja_id === 1)) return true
    return false
  }

  return (
    <div>
      <CustomButton
        text="CAMBIO DE BAJA"
        style={{
          margin: "5px",
        }}
        onClick={setOpen}
        disabled={
          edit &&
          (tipo_baja_id === 1 || [1, 3].indexOf(tipo_baja_id) > -1) &&
          [1, 2].indexOf(parseInt(getUserId())) > -1 &&
          estado_id !== 12
            ? false
            : true
        }
      />
      {state.suspense && (
        <DialogComponent
          tipo="center"
          open={state.open}
          handleclose={setClose}
          title="Cambio de baja"
          subtitle="Este cambio es irreversible!"
          children={
            <CustomAutocomplete
              name="tipo_baja"
              label="Tipo de baja"
              options={options}
              getOptionLabel={(options) => options.label}
              value={options.value}
              onChange={(_, value) => {
                setLowChange(value?.value)
              }}
              getOptionDisabled={optionsDisabled}
            />
          }
          actions={
            <CancelConfirmButtons
              onClickSubmit={handleSubmit}
              isLoading={isLoading}
              disabledSubmit={isLoading}
              disabledCancel={isLoading}
              onClickCancel={setClose}
            />
          }
        />
      )}
    </div>
  )
}

export default LowChange
