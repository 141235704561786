const LIST = "/plant/checklist"
const MORE_INFO = "/plant/checklist/"
const NEW_CHECKLIST = "/plant/checklist/new"
const UPDATE_CHECKLIST = "/plant/checklist"
const SEARCH_CHECKLIST = "/plant/checklist/infovehicle"
const PRINT = "/plant/checklist/print/"
export const plantEndpointsChecklist = {
  LIST,
  MORE_INFO,
  NEW_CHECKLIST,
  UPDATE_CHECKLIST,
  SEARCH_CHECKLIST,
  PRINT,
}
