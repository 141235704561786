import React from "react";

import NavbarGlobal from "Components/UI/Molecules/NavBar";
import Template from "Components/UI/Templates/Common";
import DashboardStock from "Components/UI/Organisms/Plant/Stock";

const StockMovementTemplate = () => {
  const MOVEMENT_TYPE = [
    {
      id: 1,
      label: "INGRESO",
    },
    {
      id: 2,
      label: "DEVOLUCIÓN",
    },
  ];

  return (
    <Template
      navbar={
        <NavbarGlobal
          title="Planta"
          subTitle="Movimiento de stock"
          label={MOVEMENT_TYPE.label}
        />
      }
      table={<DashboardStock />}
    />
  );
};
export default StockMovementTemplate;
