import React from "react"

import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import Children from "Components/UI/Organisms/Settings/Various/LotsRow/Modal/index"
import { getNavbar } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"

const LotsRow = () => {
  const { state, setOpen, setClose } = SetState()

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Lotes y filas"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/lotsRow.png"}
        disabled={JSON.parse(getNavbar()).products ? false : true}
      />
      <DialogComponent
        open={state.open}
        title="Lotes y filas"
        widthLarge="75vw"
        handleclose={setClose}
        disableBackdropClick
        disableEscapeKeyDown
        maxHeight
        children={<Children />}
      />
    </>
  )
}

export default LotsRow
