import { endpointsEcosoft } from "service/sources"
import { axiosService } from "service"

const list = (options) => {
  return axiosService.get(endpointsEcosoft.LIST + options)
}

const stock_list = (nro) => {
  return axiosService.get(endpointsEcosoft.STOCK_LIST + nro)
}

const filters = () => {
  return axiosService.get(endpointsEcosoft.FILTERS)
}

export const ecoSoft = {
  list,
  stock_list,
  filters,
}
