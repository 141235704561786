import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "#C4C4C475",
    borderRadius: "15px",
    padding: "10px",
    margin: "3px",
  },
}))

export default useStyles
