import React, { useState } from "react"

import StockTable from "Components/UI/Organisms/Sales/Stock/Table"
import setState from "Components/Hooks/SetState"
import DialogStock from "Components/UI/Organisms/Sales/Stock/DialogStock"

const Stock = (props) => {
  const { setCanExport } = props

  const { state, setClose, setObject } = setState()

  const [render, setRender] = useState(false)
  return (
    <>
      {state.suspense && (
        <DialogStock
          open={state.open}
          handleclose={setClose}
          data={state.dataObject}
          setRender={setRender}
          render={render}
        />
      )}
      <StockTable
        render={render}
        setRender={setRender}
        setCanExport={setCanExport}
        setObject={setObject}
      />
    </>
  )
}

export default Stock
