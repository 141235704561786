const USER_LIST = "/settings/users/userlist"
const NEW_USER = "/settings/users/create"
const BEFORE_CREATE = "/settings/users/beforecreate"
const USER_INFO = "/settings/users"
const UPDATE_USER = "/settings/users/edit"
const PROFILE_USER = "/settings/users/photo"
const RESET_PASSWORD = "/settings/users/reset"
const DELETE_USER = "/settings/users/delete"
const DEACTIVATE_USER = "/settings/users/deactivate"
const EXPORT_USER_DATA = "/settings/users/export"
const PERMISSIONS_LIST = "/settings/users/permissions/list"
const NEW_PERMISSIONS = "/settings/users/permission"
const NEW_NAVBAR = "/settings/users/navbar"
const TEMPLATE = "/settings/users/permissions/template/"
const UPDATE_TEMPLATE = "/settings/users/permissions/templates"

export const configUsersEndpoints = {
  USER_LIST,
  NEW_USER,
  BEFORE_CREATE,
  USER_INFO,
  UPDATE_USER,
  PROFILE_USER,
  RESET_PASSWORD,
  DELETE_USER,
  DEACTIVATE_USER,
  EXPORT_USER_DATA,
  PERMISSIONS_LIST,
  NEW_PERMISSIONS,
  NEW_NAVBAR,
  TEMPLATE,
  UPDATE_TEMPLATE,
}
