import React, { useEffect } from "react"

import EcosoftTemplate from "Components/UI/Templates/Ecosoft"

const Ecosoft = () => {
  useEffect(() => {
    document.title = "ECOSOFT"
  }, [])

  return <EcosoftTemplate />
}

export default Ecosoft
