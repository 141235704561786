import { UBICACIONES_MANUAL, PROVINCIAS } from "Constants"
export function home(
  DATA,
  RESPONSABLE,
  CUIT,
  handleChange,
  handleChangeAutoComplete
) {
  return [
    {
      class: "textfield",
      name: "empresa",
      label: "Empresa",
      value: DATA.empresa,
      onChange: handleChange,
      disabled: true,
      sm: 3,
      xs: 6,
    },
    {
      class: "textfield",
      name: "tipo_remito",
      label: "Tipo remito",
      value: DATA.tipo,
      disabled: true,
      sm: 3,
      xs: 6,
    },
    {
      class: "autocomplete",
      name: "origen_id",
      label: "Origen",
      value: DATA.origen_id,
      disabled: DATA.tipo === "Remito Retiro" ? true : false,
      options: UBICACIONES_MANUAL,
      getOptionDisabled: (options) => options.id === 7,
      disabledOptions: [7],
      optionValue: "id",
      optionLabel: `options["nombre"]`,
      onChange: (e) =>
        handleChangeAutoComplete({
          origen_id: e.id,
        }),
      sm: 3,
      xs: 6,
    },
    {
      class: "autocomplete",
      name: "destino_id",
      label: "Destino",
      disabled: DATA.tipo === "Remito R" ? true : false,
      value: DATA.destino_id,
      options: UBICACIONES_MANUAL,
      onChange: (e) =>
        handleChangeAutoComplete({
          destino_id: e.id,
        }),
      getOptionDisabled: (options) => options.id === 7,
      disabledOptions: [7],
      optionValue: "id",
      optionLabel: `options["nombre"]`,
      sm: 3,
      xs: 6,
    },
    {
      class: "textfield",
      name: "cliente_id",
      label: "Nº cliente",
      value: DATA.cliente_id,
      onChange: handleChange,
      disabled: true,
      sm: 4,
      xs: 4,
    },
    {
      class: "textfield",
      name: "cliente_nombre",
      label: "Nombre o razón social",
      value: DATA.cliente_nombre,
      onChange: handleChange,
      disabled: DATA.tipo === "Remito R" ? false : true,
      sm: 8,
      xs: 8,
    },
    {
      class: "autocomplete",
      name: "cliente_responsable",
      label: "Condición",
      disabled: DATA.tipo === "Remito R" ? false : true,
      value: DATA.cliente_responsable,
      options: RESPONSABLE,
      onChange: (e) =>
        handleChangeAutoComplete({
          cliente_responsable: e.value,
        }),
      optionValue: "value",
      optionLabel: `options["name"]`,
      sm: 4,
      xs: 4,
    },
    {
      class: "textfield",
      name: "cliente_cuit",
      label: "CUIT / CUIL / DNI",
      value: DATA.cliente_cuit,
      onChange: handleChange,
      disabled: DATA.tipo === "Remito R" ? false : true,
      sm: 4,
      xs: 4,
    },
    {
      class: "autocomplete",
      name: "cliente_tipocuit",
      label: "Tipo responsable",
      disabled: DATA.tipo === "Remito R" ? false : true,
      value: DATA.cliente_tipocuit,
      options: CUIT,
      onChange: (e) =>
        handleChangeAutoComplete({
          cliente_tipocuit: e.value,
        }),
      optionValue: "value",
      optionLabel: `options["name"]`,
      sm: 4,
      xs: 4,
    },

    {
      class: "textfield",
      name: "cliente_domicilio",
      label: "Domicilio",
      value: DATA.cliente_domicilio,
      onChange: handleChange,
      disabled: DATA.tipo === "Remito R" ? false : true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "cliente_cp",
      label: "CP",
      value: DATA.cliente_cp,
      onChange: handleChange,
      disabled: DATA.tipo === "Remito R" ? false : true,
      sm: 4,
      xs: 4,
    },
    {
      class: "textfield",
      name: "cliente_localidad",
      label: "Localidad",
      value: DATA.cliente_localidad,
      onChange: handleChange,
      disabled: DATA.tipo === "Remito R" ? false : true,
      sm: 4,
      xs: 4,
    },
    {
      class: "autocomplete",
      name: "cliente_provincia",
      label: "Provincia",
      options: PROVINCIAS,
      optionValue: "provincia",
      optionLabel: `options["provincia"]`,
      disabled: DATA.tipo === "Remito R" ? false : true,
      value: DATA.cliente_provincia,
      onChange: (e) =>
        handleChangeAutoComplete({
          cliente_provincia: e.provincia,
        }),
      sm: 4,
      xs: 4,
    },
    {
      class: "textfield",
      name: "observaciones",
      label: "Observaciones",
      value: DATA.observaciones,
      onChange: handleChange,
      disabled: false,
      sm: 12,
      xs: 12,
    },
  ]
}

export function transport(DATA, TRANSPORTS, handleChangeAutoComplete) {
  let index = TRANSPORTS.find((value) => value.nombre === DATA.transporte)
  return [
    {
      class: "autocomplete",
      name: "transporte",
      freeSolo: true,
      label: "Transporte",
      options: TRANSPORTS,
      optionValue: "nombre",
      optionLabel: `options["nombre"]`,
      defaultValue: { nombre: DATA.transporte },
      onChange: (e) =>
        handleChangeAutoComplete({
          transporte: e.nombre,
        }),
      onInputChange: (e) => handleChangeAutoComplete({ transporte: e }),
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "chofer",
      freeSolo: true,
      label: "Choferes",
      defaultValue: { descripcion: DATA.chofer },
      options: !index ? [] : index.choferes,
      onChange: (e) =>
        handleChangeAutoComplete({
          chofer: e.descripcion,
        }),
      onInputChange: (e) => handleChangeAutoComplete({ chofer: e }),
      optionValue: "descripcion",
      optionLabel: `options["descripcion"]`,
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "camion",
      freeSolo: true,
      label: "Camiones",
      options: !index ? [] : index.camiones,
      onChange: (e) =>
        handleChangeAutoComplete({
          camion: e.descripcion,
        }),
      onInputChange: (e) => handleChangeAutoComplete({ camion: e }),
      defaultValue: { descripcion: DATA.camion },
      optionValue: "descripcion",
      optionLabel: `options["descripcion"]`,
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "batea",
      freeSolo: true,
      label: "Bateas",
      defaultValue: { descripcion: DATA.batea },
      options: !index ? [] : index.bateas,
      onChange: (e) =>
        handleChangeAutoComplete({
          batea: e.descripcion,
        }),
      onInputChange: (e) => handleChangeAutoComplete({ batea: e }),
      value: DATA.batea,
      optionValue: "descripcion",
      optionLabel: `options["descripcion"]`,
      sm: 12,
      xs: 12,
    },
  ]
}
