import React, { useContext, useState } from "react"
import CustomButton from "Components/UI/Atoms/CustomButton"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { EditContext } from "Components/Hooks/ContextEdit"

const ReturnVehicles = ({ vehicle_id, afterSubmitDNRPA, estado_id }) => {
  const { state, setOpen, setClose } = SetState()
  const [edit] = useContext(EditContext)
  const [isLoading, setIsLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()

  const handleSubmit = async () => {
    setIsLoading(true)
    await interfaces.vehicleInterface
      .returnVehicles(vehicle_id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
    afterSubmitDNRPA()
    setClose()
  }

  return (
    <div>
      <CustomButton
        text="DEVOLVER"
        style={{
          margin: "5px",
        }}
        onClick={setOpen}
        disabled={edit && estado_id !== 12 ? false : true}
      />
      {state.suspense && (
        <DialogComponent
          tipo="center"
          open={state.open}
          handleclose={setClose}
          title="¿Desea marcar este vehículo como devuelto?"
          subtitle="Este cambio es irreversible!"
          actions={
            <CancelConfirmButtons
              onClickSubmit={handleSubmit}
              isLoading={isLoading}
              disabledSubmit={isLoading}
              disabledCancel={isLoading}
              onClickCancel={setClose}
            />
          }
        />
      )}
    </div>
  )
}

export default ReturnVehicles
