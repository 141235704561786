import React, { useState } from "react"

import { Typography } from "@material-ui/core"

import Login from "./Components/Login"
import PasswordReseted from "./Components/PasswordReseted"
import ForgetPassword from "./Components/ForgetPassword"
import useStyles from "./styles"

const FormLogin = () => {
  const [mostrar, setMostrar] = useState(1)
  const classes = useStyles()

  const form = <Login onClick={() => setMostrar(2)} />
  const removePass = (
    <ForgetPassword
      onClick={() => setMostrar(3)}
      onClickVolver={() => setMostrar(1)}
      onSubmit={() => {
        setMostrar(3)
      }}
    />
  )
  const formBack = <PasswordReseted onClick={() => setMostrar(1)} />

  return (
    <div>
      <div className={classes.paper}>
        <img
          src={process.env.PUBLIC_URL + "./Images/logoLogin.webp"}
          alt="#"
          className={classes.logo}
        />
        {mostrar === 1 && form}
        {mostrar === 2 && removePass}
        {mostrar === 3 && formBack}
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ margin: 30 }}
        >
          {"ECO Autoparts SA © "}
          {new Date().getFullYear()}
        </Typography>
      </div>
    </div>
  )
}
export default FormLogin
