export const CITIES = [
  {
    id: 1,
    localidad: "ZURITA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 2,
    localidad: "ZAVALIA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 3,
    localidad: "ZARATE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 4,
    localidad: "ZAPALLAR",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 5,
    localidad: "ZAPALLAR",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 6,
    localidad: "ZANJON MASCIO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 7,
    localidad: "YUNOPONGO SUD",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 8,
    localidad: "YUNCA SUMA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 9,
    localidad: "YUMILLURA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 10,
    localidad: "YUCUMANITA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 11,
    localidad: "YUCHACO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 12,
    localidad: "YONOPONGO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 13,
    localidad: "YMPAS",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 14,
    localidad: "YERBA HUASI",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 15,
    localidad: "YERBA BUENA",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 16,
    localidad: "YERBA BUENA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 17,
    localidad: "YATAPAYANA",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 18,
    localidad: "YARAMI",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 19,
    localidad: "YAQUILO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 20,
    localidad: "YAPACHIN",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 21,
    localidad: "YANIMAS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 22,
    localidad: "YANGALLO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 23,
    localidad: "YAMINAS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 24,
    localidad: "YALAPA",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 25,
    localidad: "YACUCHIRI",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 26,
    localidad: "YACUCHINA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 27,
    localidad: "YACO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 28,
    localidad: "VIZCACHERA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 29,
    localidad: "VIPOS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 30,
    localidad: "VILTRAN",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 31,
    localidad: "VILLA VIEJA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 32,
    localidad: "VILLA TERCERA",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 33,
    localidad: "VILLA SAN JAVIER",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 34,
    localidad: "VILLA ROSA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 35,
    localidad: "VILLA RITA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 36,
    localidad: "VILLA QUINTEROS",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 37,
    localidad: "VILLA PADRE MONTI",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 38,
    localidad: "VILLA NUEVA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 39,
    localidad: "VILLA NOUGUES",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 40,
    localidad: "VILLA MITRE",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 41,
    localidad: "VILLA MARCOS PAZ",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 42,
    localidad: "VILLA LEALES",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 43,
    localidad: "VILLA LA TRINIDAD",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 44,
    localidad: "VILLA LA COLMENA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 45,
    localidad: "VILLA GLORIA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 46,
    localidad: "VILLA FIAD",
    cp: 4118,
    provincia_id: 24,
  },
  {
    id: 47,
    localidad: "VILLA DEVOTO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 48,
    localidad: "VILLA CAROLINA",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 49,
    localidad: "VILLA CARMELA",
    cp: 4005,
    provincia_id: 24,
  },
  {
    id: 50,
    localidad: "VILLA CARMELA",
    cp: 4010,
    provincia_id: 24,
  },
  {
    id: 51,
    localidad: "VILLA BURRUYACU",
    cp: 4121,
    provincia_id: 24,
  },
  {
    id: 52,
    localidad: "VILLA BRAVA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 53,
    localidad: "VILLA BELGRANO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 54,
    localidad: "VILLA ANGELINA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 55,
    localidad: "VILLA ALVEAR",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 56,
    localidad: "VILLA ALBERDI",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 57,
    localidad: "VILCA POZO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 58,
    localidad: "VIELOS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 59,
    localidad: "VICLOS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 60,
    localidad: "VIADUCTOS DEL TORO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 61,
    localidad: "VESUBIO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 62,
    localidad: "VALENZUELA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 63,
    localidad: "VACAHUASI",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 64,
    localidad: "VA RECASTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 65,
    localidad: "V ZENON SANTILLAN",
    cp: 4000,
    provincia_id: 24,
  },
  {
    id: 66,
    localidad: "V VIEJA SANTA ANA",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 67,
    localidad: "V NUEVA AGUILARES",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 68,
    localidad: "V DE LOS BRITOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 69,
    localidad: "V CLODOMIRO H",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 70,
    localidad: "V BENJAMIN ARAOZ",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 71,
    localidad: "V ALBERDI ESTACION",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 72,
    localidad: "UTURUNGU",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 73,
    localidad: "UCUCHACRA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 74,
    localidad: "TUSQUITAS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 75,
    localidad: "TUSCAL",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 76,
    localidad: "TUSCA POZO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 77,
    localidad: "TUSCA PAMPA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 78,
    localidad: "TUNALITO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 79,
    localidad: "TUNA SOLA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 80,
    localidad: "TRINIDAD",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 81,
    localidad: "TRES SARGENTOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 82,
    localidad: "TRES POZOS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 83,
    localidad: "TRES ALMACENES",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 84,
    localidad: "TRANQUITAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 85,
    localidad: "TRANCAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 86,
    localidad: "TOTORAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 87,
    localidad: "TOSTADO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 88,
    localidad: "TORO YACO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 89,
    localidad: "TORO MUERTO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 90,
    localidad: "TORO LOCO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 91,
    localidad: "TOQUELLO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 92,
    localidad: "TOCO LLANA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 93,
    localidad: "TIPAS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 94,
    localidad: "TIPA MAYO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 95,
    localidad: "TIO PUNCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 96,
    localidad: "TIMBO VIEJO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 97,
    localidad: "TIMBO NUEVO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 98,
    localidad: "TIERRAS BLANCAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 99,
    localidad: "TICUCHO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 100,
    localidad: "TENIENTE BERDINA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 101,
    localidad: "TECOTEX",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 102,
    localidad: "TATA YACU",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 103,
    localidad: "TARUCA PAMPA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 104,
    localidad: "TAQUELLO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 105,
    localidad: "TAPIA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 106,
    localidad: "TAMBOR DE TACUARI",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 107,
    localidad: "TALLERES NACES",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 108,
    localidad: "TALITAS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 109,
    localidad: "TALITA POZO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 110,
    localidad: "TALILAR",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 111,
    localidad: "TALAMUYO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 112,
    localidad: "TALA YACO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 113,
    localidad: "TALA POZO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 114,
    localidad: "TALA PAMPA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 115,
    localidad: "TAFICILLO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 116,
    localidad: "TAFI VIEJO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 117,
    localidad: "TAFI DEL VALLE",
    cp: 4127,
    provincia_id: 24,
  },
  {
    id: 118,
    localidad: "TAFI DEL VALLE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 119,
    localidad: "TAFI DEL VALLE",
    cp: 4140,
    provincia_id: 24,
  },
  {
    id: 120,
    localidad: "TACO YANA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 121,
    localidad: "TACO YACO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 122,
    localidad: "TACO RODEO",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 123,
    localidad: "TACO RALO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 124,
    localidad: "TACO PUNCO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 125,
    localidad: "TACO PALTA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 126,
    localidad: "TACO LLANO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 127,
    localidad: "TACO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 128,
    localidad: "TACANAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 129,
    localidad: "TACANAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 130,
    localidad: "SURIYACO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 131,
    localidad: "SUNCHO PUNTA",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 132,
    localidad: "SUNCHAL",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 133,
    localidad: "SUELDOS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 134,
    localidad: "SUELDO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 135,
    localidad: "SUD DE TREJOS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 136,
    localidad: "SUD DE SANDOVALES",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 137,
    localidad: "SUD DE LAZARTE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 138,
    localidad: "SORAIRE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 139,
    localidad: "SOLEDAD",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 140,
    localidad: "SOLDADO MALDONADO",
    cp: 4156,
    provincia_id: 24,
  },
  {
    id: 141,
    localidad: "SINQUIAL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 142,
    localidad: "SINQUEAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 143,
    localidad: "SIMOCA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 144,
    localidad: "SIMBOLAR",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 145,
    localidad: "SIMBOL",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 146,
    localidad: "SESTEADERO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 147,
    localidad: "SEPULTURA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 148,
    localidad: "SAUZAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 149,
    localidad: "SAUCE YACU",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 150,
    localidad: "SAUCE YACU",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 151,
    localidad: "SAUCE YACO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 152,
    localidad: "SAUCE SECO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 153,
    localidad: "SAUCE PARTIDO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 154,
    localidad: "SAUCE HUACHO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 155,
    localidad: "SAUCE GAUCHO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 156,
    localidad: "SARGENTO MOYA",
    cp: 4165,
    provincia_id: 24,
  },
  {
    id: 157,
    localidad: "SANTOS LUGARES",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 158,
    localidad: "SANTO DOMINGO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 159,
    localidad: "SANTA VICTORIA",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 160,
    localidad: "SANTA TERESA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 161,
    localidad: "SANTA ROSA DE LEALES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 162,
    localidad: "SANTA ROSA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 163,
    localidad: "SANTA ROSA",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 164,
    localidad: "SANTA ROSA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 165,
    localidad: "SANTA ROSA",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 166,
    localidad: "SANTA RITA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 167,
    localidad: "SANTA RITA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 168,
    localidad: "SANTA MONICA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 169,
    localidad: "SANTA LUCIA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 170,
    localidad: "SANTA LUCIA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 171,
    localidad: "SANTA ISABEL",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 172,
    localidad: "SANTA FELISA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 173,
    localidad: "SANTA ELENA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 174,
    localidad: "SANTA CRUZ",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 175,
    localidad: "SANTA CATALINA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 176,
    localidad: "SANTA BARBARA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 177,
    localidad: "SANTA BARBARA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 178,
    localidad: "SANTA ANA",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 179,
    localidad: "SANDOVALES",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 180,
    localidad: "SANDIS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 181,
    localidad: "SAN VICENTE",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 182,
    localidad: "SAN VICENTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 183,
    localidad: "SAN RAMON",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 184,
    localidad: "SAN RAMON",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 185,
    localidad: "SAN RAFAEL",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 186,
    localidad: "SAN PEREYRA",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 187,
    localidad: "SAN PEDRO MARTIR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 188,
    localidad: "SAN PEDRO DE COLALAO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 189,
    localidad: "SAN PEDRO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 190,
    localidad: "SAN PATRICIO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 191,
    localidad: "SAN PABLO",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 192,
    localidad: "SAN NICOLAS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 193,
    localidad: "SAN MIGUELITO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 194,
    localidad: "SAN MIGUEL DE TUCUMAN",
    cp: 4000,
    provincia_id: 24,
  },
  {
    id: 195,
    localidad: "SAN MIGUEL DE TUCUMAN",
    cp: 4001,
    provincia_id: 24,
  },
  {
    id: 196,
    localidad: "SAN MIGUEL DE TUCUMAN",
    cp: 4002,
    provincia_id: 24,
  },
  {
    id: 197,
    localidad: "SAN MIGUEL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 198,
    localidad: "SAN MIGUEL",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 199,
    localidad: "SAN MIGUEL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 200,
    localidad: "SAN MIGUEL",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 201,
    localidad: "SAN MIGUEL",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 202,
    localidad: "SAN MIGUEL",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 203,
    localidad: "SAN LUIS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 204,
    localidad: "SAN LORENZO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 205,
    localidad: "SAN JULIAN YACO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 206,
    localidad: "SAN JUANCITO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 207,
    localidad: "SAN JOSE DE MACOMITA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 208,
    localidad: "SAN JOSE DE LEALES",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 209,
    localidad: "SAN JOSE DE FLORES",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 210,
    localidad: "SAN JOSE",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 211,
    localidad: "SAN JOSE",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 212,
    localidad: "SAN JOSE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 213,
    localidad: "SAN JENARO",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 214,
    localidad: "SAN JAVIER",
    cp: 4108,
    provincia_id: 24,
  },
  {
    id: 215,
    localidad: "SAN JAVIER",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 216,
    localidad: "SAN ISIDRO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 217,
    localidad: "SAN IGNACIO",
    cp: 4171,
    provincia_id: 24,
  },
  {
    id: 218,
    localidad: "SAN GERONIMO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 219,
    localidad: "SAN GERMAN",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 220,
    localidad: "SAN GABRIEL",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 221,
    localidad: "SAN FRANCISCO",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 222,
    localidad: "SAN FRANCISCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 223,
    localidad: "SAN FERNANDO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 224,
    localidad: "SAN FELIPE",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 225,
    localidad: "SAN FELIPE",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 226,
    localidad: "SAN EUSEBIO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 227,
    localidad: "SAN CARLOS",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 228,
    localidad: "SAN CARLOS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 229,
    localidad: "SAN CARLOS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 230,
    localidad: "SAN CARLITOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 231,
    localidad: "SAN ANTONIO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 232,
    localidad: "SAN ANTONIO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 233,
    localidad: "SAN ANDRES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 234,
    localidad: "SAN ALBERTO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 235,
    localidad: "SALINAS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 236,
    localidad: "SALAZAR",
    cp: 4126,
    provincia_id: 24,
  },
  {
    id: 237,
    localidad: "SALAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 238,
    localidad: "SALAMANCA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 239,
    localidad: "SALADILLO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 240,
    localidad: "SALA VIEJA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 241,
    localidad: "SACRIFICIO",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 242,
    localidad: "S ROSA DE LEALES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 243,
    localidad: "S R CHICLIGASTA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 244,
    localidad: "S PEDRO DE COLALAO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 245,
    localidad: "S LEDESMA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 246,
    localidad: "S JOSE DE S MARTIN",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 247,
    localidad: "S JOSE DE MACOMITA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 248,
    localidad: "S JOSE DE LA COCHA",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 249,
    localidad: "S J DE CHASQUIVIL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 250,
    localidad: "S J DE BUENA VISTA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 251,
    localidad: "S G DEL MONTE",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 252,
    localidad: "S ANTONIO DE PADUA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 253,
    localidad: "RUTA NACIONAL38",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 254,
    localidad: "RUTA NACIONAL 9",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 255,
    localidad: "RUTA NACIONAL 9",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 256,
    localidad: "RUTA NACIONAL 64",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 257,
    localidad: "RUTA NACIONAL 38",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 258,
    localidad: "RUTA NACIONAL 38",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 259,
    localidad: "RUTA NACIONAL 38",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 260,
    localidad: "RUTA NACIONAL 38",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 261,
    localidad: "RUTA NACIONAL 157",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 262,
    localidad: "RUTA NACIONAL 157",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 263,
    localidad: "RUTA NACIONAL 157",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 264,
    localidad: "RUMI YURA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 265,
    localidad: "RUMI PUNCO",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 266,
    localidad: "ROSARIO OESTE",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 267,
    localidad: "ROSARIO OESTE",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 268,
    localidad: "ROMERELLO",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 269,
    localidad: "ROMERA POZO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 270,
    localidad: "ROMA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 271,
    localidad: "RODEO TORO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 272,
    localidad: "RODEO GRANDE",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 273,
    localidad: "RODEO GRANDE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 274,
    localidad: "RODEO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 275,
    localidad: "RIO VIPOS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 276,
    localidad: "RIO SECO KM 1207",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 277,
    localidad: "RIO SECO",
    cp: 4145,
    provincia_id: 24,
  },
  {
    id: 278,
    localidad: "RIO LULES",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 279,
    localidad: "RIO LORO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 280,
    localidad: "RIO DEL NIO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 281,
    localidad: "RIO COLORADO",
    cp: 4173,
    provincia_id: 24,
  },
  {
    id: 282,
    localidad: "RIO CHICO",
    cp: 4153,
    provincia_id: 24,
  },
  {
    id: 283,
    localidad: "RIO BLANCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 284,
    localidad: "RINCON HUASA",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 285,
    localidad: "RINCON GRANDE",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 286,
    localidad: "RINCON",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 287,
    localidad: "RIEGASTA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 288,
    localidad: "RETIRO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 289,
    localidad: "RETIRO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 290,
    localidad: "RESCATE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 291,
    localidad: "REQUELME",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 292,
    localidad: "REARTE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 293,
    localidad: "REARTE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 294,
    localidad: "RANCHO DE CASCADA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 295,
    localidad: "RANCHILLOS VIEJOS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 296,
    localidad: "RANCHILLOS",
    cp: 4179,
    provincia_id: 24,
  },
  {
    id: 297,
    localidad: "RAMOS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 298,
    localidad: "RAMADITAS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 299,
    localidad: "RAFAELA POZO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 300,
    localidad: "RACO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 301,
    localidad: "R. DE LAS TACANAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 302,
    localidad: "R. DE BALDERRAMA",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 303,
    localidad: "R provincia_idL 380",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 304,
    localidad: "R provincia_idL 380",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 305,
    localidad: "R provincia_idL 375",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 306,
    localidad: "R provincia_idL 374",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 307,
    localidad: "R provincia_idL 366",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 308,
    localidad: "R provincia_idL 338",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 309,
    localidad: "R provincia_idL 338",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 310,
    localidad: "R provincia_idL 335",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 311,
    localidad: "R provincia_idL 334",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 312,
    localidad: "R provincia_idL 334",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 313,
    localidad: "R provincia_idL 323",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 314,
    localidad: "R provincia_idL 322",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 315,
    localidad: "R provincia_idL 320",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 316,
    localidad: "R provincia_idL 319",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 317,
    localidad: "R provincia_idL 308",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 318,
    localidad: "R provincia_idL 306",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 319,
    localidad: "R provincia_idL 306",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 320,
    localidad: "R provincia_idL 304",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 321,
    localidad: "R provincia_idL 303",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 322,
    localidad: "R provincia_idL 302",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 323,
    localidad: "R provincia_idL 302",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 324,
    localidad: "R provincia_idL 301",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 325,
    localidad: "R DEL ALGARROBO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 326,
    localidad: "QUINTEROS 2",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 327,
    localidad: "QUINTEROS 1",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 328,
    localidad: "QUILMES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 329,
    localidad: "QUEBRADA DE LULES",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 330,
    localidad: "QUEBRACHITO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 331,
    localidad: "PUNTA RIELES",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 332,
    localidad: "PUNTA DEL MONTE",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 333,
    localidad: "PUNTA DEL AGUA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 334,
    localidad: "PUNTA DE RIELES",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 335,
    localidad: "PUMA POZO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 336,
    localidad: "PUESTO VILLAGRA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 337,
    localidad: "PUESTO VIEJO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 338,
    localidad: "PUESTO VARELA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 339,
    localidad: "PUESTO NUEVO",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 340,
    localidad: "PUESTO LOS ROBLES",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 341,
    localidad: "PUESTO LOS ROBLES",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 342,
    localidad: "PUESTO LOS PEREZ",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 343,
    localidad: "PUESTO LOS AVILAS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 344,
    localidad: "PUESTO LA RAMADITA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 345,
    localidad: "PUESTO GRANDE",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 346,
    localidad: "PUESTO DE ZARZO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 347,
    localidad: "PUESTO DE UNCOS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 348,
    localidad: "PUESTO DE AVILA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 349,
    localidad: "PUESTO DE ALUMBRE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 350,
    localidad: "PUESTO COCHUCHO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 351,
    localidad: "PUESTO CHICO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 352,
    localidad: "PUESTO 9 DE JULIO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 353,
    localidad: "PUESTITO DE ARRIBA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 354,
    localidad: "PUERTAS GRANDES",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 355,
    localidad: "PUERTAS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 356,
    localidad: "PUERTA VIEJA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 357,
    localidad: "PUERTA SAN JAVIER",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 358,
    localidad: "PUERTA QUEMADA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 359,
    localidad: "PUERTA GRANDE",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 360,
    localidad: "PUENTE RIO SALI",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 361,
    localidad: "PUEBLO VIEJO",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 362,
    localidad: "PUEBLO OBRERO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 363,
    localidad: "PUEBLO NUEVO",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 364,
    localidad: "PRADERA ALEGRE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 365,
    localidad: "POZO SUNCHO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 366,
    localidad: "POZO HONDO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 367,
    localidad: "POZO HONDO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 368,
    localidad: "POZO DEL ALTO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 369,
    localidad: "POZO DEL ALGARROBO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 370,
    localidad: "POZO CAVADO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 371,
    localidad: "POZO ALTO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 372,
    localidad: "POTRO YACO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 373,
    localidad: "POTRERO GRANDE",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 374,
    localidad: "POTRERO DE LAS TABLAS",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 375,
    localidad: "POTRERO",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 376,
    localidad: "POTRERILLOS",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 377,
    localidad: "POTRERILLO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 378,
    localidad: "POTRERILLO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 379,
    localidad: "POSTA VIEJA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 380,
    localidad: "POSTA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 381,
    localidad: "POSSE DESV P FCGM",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 382,
    localidad: "PORVENIR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 383,
    localidad: "PORTEZUELO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 384,
    localidad: "PORTEZUELO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 385,
    localidad: "PORT DE TOMAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 386,
    localidad: "PORT DE LAS ANIMAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 387,
    localidad: "PONZACON",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 388,
    localidad: "POLITO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 389,
    localidad: "POLIAR",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 390,
    localidad: "PLAYA LARGA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 391,
    localidad: "PLANTA COMP YPF",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 392,
    localidad: "PIRHUAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 393,
    localidad: "PINGOLLAR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 394,
    localidad: "PILCO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 395,
    localidad: "PIEDRAS COLORADAS",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 396,
    localidad: "PIEDRAS BLANCAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 397,
    localidad: "PIEDRA TENDIDA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 398,
    localidad: "PIEDRA GRANDE",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 399,
    localidad: "PIEDRA BLANCA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 400,
    localidad: "PIE DEL ACONQUIJA",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 401,
    localidad: "PIE DE LA CUESTA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 402,
    localidad: "PERUCHO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 403,
    localidad: "PEREYRA SUR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 404,
    localidad: "PEREYRA NORTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 405,
    localidad: "PASTEUR LUIS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 406,
    localidad: "PASO DE LAS LANZAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 407,
    localidad: "PARAISO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 408,
    localidad: "PARADA DE OHUANTA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 409,
    localidad: "PANTANILLO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 410,
    localidad: "PAMPA POZO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 411,
    localidad: "PAMPA MAYO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 412,
    localidad: "PAMPA LARGA",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 413,
    localidad: "PALOMITAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 414,
    localidad: "PALOMINOS BANDA DE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 415,
    localidad: "PALOMAS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 416,
    localidad: "PALO GACHO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 417,
    localidad: "PALO BLANCO",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 418,
    localidad: "PALMITAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 419,
    localidad: "PALMAS REDONDAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 420,
    localidad: "PALA PALA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 421,
    localidad: "PAJA BLANCA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 422,
    localidad: "PAEZ",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 423,
    localidad: "PADILLA",
    cp: 4133,
    provincia_id: 24,
  },
  {
    id: 424,
    localidad: "PACARA PINTADO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 425,
    localidad: "PACARA PINTADO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 426,
    localidad: "PACARA MARCADO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 427,
    localidad: "PACARA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 428,
    localidad: "PACARA",
    cp: 4003,
    provincia_id: 24,
  },
  {
    id: 429,
    localidad: "P MAYO NOROESTE",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 430,
    localidad: "P G MENDEZ",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 431,
    localidad: "P EL MANANTIAL",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 432,
    localidad: "P DE PALAVECINO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 433,
    localidad: "P DE LOS VALDES",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 434,
    localidad: "P DE LOS ALAMOS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 435,
    localidad: "P DE LAS TABLAS",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 436,
    localidad: "P DE LAS CABRAS",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 437,
    localidad: "P DE ENCALILLO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 438,
    localidad: "P DE BOMBEO DE YPF",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 439,
    localidad: "P CIENAGA AMARILLA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 440,
    localidad: "P CEVIL CON AGUA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 441,
    localidad: "OVERO POZO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 442,
    localidad: "OVEJERO",
    cp: 4126,
    provincia_id: 24,
  },
  {
    id: 443,
    localidad: "OVEJERIA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 444,
    localidad: "ORAN",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 445,
    localidad: "ORAN",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 446,
    localidad: "OJO DE AGUA",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 447,
    localidad: "OJO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 448,
    localidad: "OBRAJE",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 449,
    localidad: "ÑORCO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 450,
    localidad: "NUEVOS MATADEROS",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 451,
    localidad: "NUEVO P LA FLORIDA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 452,
    localidad: "NUEVA TRINIDAD",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 453,
    localidad: "NUEVA ROSA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 454,
    localidad: "NUEVA ESQUINA",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 455,
    localidad: "NUEVA ESPERANZA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 456,
    localidad: "NUEVA BAVIERA",
    cp: 4136,
    provincia_id: 24,
  },
  {
    id: 457,
    localidad: "NOGALITO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 458,
    localidad: "NOGALITA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 459,
    localidad: "NOARIO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 460,
    localidad: "NIOGASTA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 461,
    localidad: "NIO V PADRE MONTI",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 462,
    localidad: "NIO EL PUESTITO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 463,
    localidad: "NEGRO POTRERO",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 464,
    localidad: "NASCHE",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 465,
    localidad: "NARANJO ESQUINA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 466,
    localidad: "NARANJITO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 467,
    localidad: "MUYO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 468,
    localidad: "MUNDO NUEVO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 469,
    localidad: "MULTIFLORES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 470,
    localidad: "MUJER MUERTA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 471,
    localidad: "MTIAL DE OVANTA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 472,
    localidad: "MOYAR",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 473,
    localidad: "MOYA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 474,
    localidad: "MOTHE",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 475,
    localidad: "MORON",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 476,
    localidad: "MORAS MINUCAS",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 477,
    localidad: "MONTEROS VIEJO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 478,
    localidad: "MONTEROS",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 479,
    localidad: "MONTEAGUDO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 480,
    localidad: "MONTE RICO",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 481,
    localidad: "MONTE REDONDO",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 482,
    localidad: "MONTE REDONDO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 483,
    localidad: "MONTE REDOMON",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 484,
    localidad: "MONTE LARGO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 485,
    localidad: "MONTE GRANDE",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 486,
    localidad: "MONTE GRANDE",
    cp: 4133,
    provincia_id: 24,
  },
  {
    id: 487,
    localidad: "MONTE BELLO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 488,
    localidad: "MONTE BELLO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 489,
    localidad: "MONTE BELLO",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 490,
    localidad: "MONASTERIO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 491,
    localidad: "MOLLE YACO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 492,
    localidad: "MOLLE YACO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 493,
    localidad: "MOLLE POZO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 494,
    localidad: "MOLLE DE ABAJO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 495,
    localidad: "MOLLE CHATO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 496,
    localidad: "MOLINOS",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 497,
    localidad: "MOJON",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 498,
    localidad: "MIXTA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 499,
    localidad: "MISTOL",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 500,
    localidad: "MISKY",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 501,
    localidad: "MIRANDA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 502,
    localidad: "MIMILLO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 503,
    localidad: "MIGUEL LILLO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 504,
    localidad: "MESADA DE ENCIMA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 505,
    localidad: "MERCEDES",
    cp: 4130,
    provincia_id: 24,
  },
  {
    id: 506,
    localidad: "MERCEDES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 507,
    localidad: "MEMBRILLO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 508,
    localidad: "MEDINA",
    cp: 4167,
    provincia_id: 24,
  },
  {
    id: 509,
    localidad: "MEDINA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 510,
    localidad: "MAYO",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 511,
    localidad: "MATUL",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 512,
    localidad: "MATO YACO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 513,
    localidad: "MASCIO PILCO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 514,
    localidad: "MARTA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 515,
    localidad: "MARIÑO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 516,
    localidad: "MARIA LUISA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 517,
    localidad: "MARIA ELENA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 518,
    localidad: "MARIA BLANCA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 519,
    localidad: "MARAPA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 520,
    localidad: "MANUELA PEDRAZA",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 521,
    localidad: "MANUEL GARCIA",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 522,
    localidad: "MANCOPA CHICO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 523,
    localidad: "MANCOPA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 524,
    localidad: "MANCHALA",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 525,
    localidad: "MANANTIALES",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 526,
    localidad: "MANANTIAL DE OVANTA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 527,
    localidad: "MANANTIAL",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 528,
    localidad: "MALVINAS",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 529,
    localidad: "MAL PASO",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 530,
    localidad: "MACOMITA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 531,
    localidad: "MACIO SUR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 532,
    localidad: "MACIO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 533,
    localidad: "M GARCIA FERNANDEZ",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 534,
    localidad: "LUZ Y FUERZA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 535,
    localidad: "LUNAREJOS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 536,
    localidad: "LULES",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 537,
    localidad: "LUJAN",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 538,
    localidad: "LUISIANA E FCGM",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 539,
    localidad: "LOVAR",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 540,
    localidad: "LOS ZELAYAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 541,
    localidad: "LOS ZAZOS",
    cp: 4125,
    provincia_id: 24,
  },
  {
    id: 542,
    localidad: "LOS ZARAGOZA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 543,
    localidad: "LOS VILLEGAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 544,
    localidad: "LOS VILLAGRA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 545,
    localidad: "LOS VILLAGRA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 546,
    localidad: "LOS VEGA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 547,
    localidad: "LOS VAZQUEZ",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 548,
    localidad: "LOS VALLISTOS",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 549,
    localidad: "LOS VALDES",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 550,
    localidad: "LOS TRES BAJOS",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 551,
    localidad: "LOS TREJOS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 552,
    localidad: "LOS TIMBRES",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 553,
    localidad: "LOS TIMBOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 554,
    localidad: "LOS SUELDOS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 555,
    localidad: "LOS SOSA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 556,
    localidad: "LOS SIFONES",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 557,
    localidad: "LOS SARMIENTOS",
    cp: 4065,
    provincia_id: 24,
  },
  {
    id: 558,
    localidad: "LOS ROMANOS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 559,
    localidad: "LOS ROJOS",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 560,
    localidad: "LOS RODRIGUEZ",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 561,
    localidad: "LOS ROBLES",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 562,
    localidad: "LOS RIZOS",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 563,
    localidad: "LOS RIOS",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 564,
    localidad: "LOS REYES",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 565,
    localidad: "LOS RALOS",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 566,
    localidad: "LOS QUEMADOS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 567,
    localidad: "LOS PUESTOS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 568,
    localidad: "LOS PUESTOS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 569,
    localidad: "LOS PORCELES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 570,
    localidad: "LOS POCITOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 571,
    localidad: "LOS POCITOS",
    cp: 4102,
    provincia_id: 24,
  },
  {
    id: 572,
    localidad: "LOS POCITOS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 573,
    localidad: "LOS POCITOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 574,
    localidad: "LOS POCITOS",
    cp: 4104,
    provincia_id: 24,
  },
  {
    id: 575,
    localidad: "LOS PLANCHONES",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 576,
    localidad: "LOS PIZARRO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 577,
    localidad: "LOS PEREZ",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 578,
    localidad: "LOS PEREZ",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 579,
    localidad: "LOS PEREYRA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 580,
    localidad: "LOS PEDRAZA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 581,
    localidad: "LOS OCHO CUARTOS",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 582,
    localidad: "LOS NOGALES",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 583,
    localidad: "LOS MOYES",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 584,
    localidad: "LOS MOLLES",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 585,
    localidad: "LOS MOGOTES",
    cp: 4126,
    provincia_id: 24,
  },
  {
    id: 586,
    localidad: "LOS MISTOLES",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 587,
    localidad: "LOS MENDOZAS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 588,
    localidad: "LOS LUNAS",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 589,
    localidad: "LOS LESCANOS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 590,
    localidad: "LOS JUAREZ",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 591,
    localidad: "LOS HILOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 592,
    localidad: "LOS HERRERAS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 593,
    localidad: "LOS GUCHEA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 594,
    localidad: "LOS GUAYACANES",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 595,
    localidad: "LOS GRAMAJOS",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 596,
    localidad: "LOS GRAMAJO",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 597,
    localidad: "LOS GONZALEZ",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 598,
    localidad: "LOS GONZALES",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 599,
    localidad: "LOS GOMEZ",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 600,
    localidad: "LOS GODOS",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 601,
    localidad: "LOS GALPONES",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 602,
    localidad: "LOS EUCALIPTOS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 603,
    localidad: "LOS ESTANQUES",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 604,
    localidad: "LOS DIAZ",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 605,
    localidad: "LOS DECIMA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 606,
    localidad: "LOS CUARTOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 607,
    localidad: "LOS CRESPO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 608,
    localidad: "LOS CORPITOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 609,
    localidad: "LOS CORDONES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 610,
    localidad: "LOS CORDOBA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 611,
    localidad: "LOS COLORADOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 612,
    localidad: "LOS CHAMICOS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 613,
    localidad: "LOS CAMPEROS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 614,
    localidad: "LOS CALLEJONES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 615,
    localidad: "LOS BULACIOS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 616,
    localidad: "LOS BULACIOS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 617,
    localidad: "LOS BRITOS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 618,
    localidad: "LOS BORDOS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 619,
    localidad: "LOS BATEONES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 620,
    localidad: "LOS BAJOS",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 621,
    localidad: "LOS ARROYO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 622,
    localidad: "LOS ARRIETAS",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 623,
    localidad: "LOS ANGELES",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 624,
    localidad: "LOS ALISOS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 625,
    localidad: "LOS ALCARACES",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 626,
    localidad: "LOS ALAMOS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 627,
    localidad: "LOS ALAMITOS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 628,
    localidad: "LOS AGUIRRE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 629,
    localidad: "LOS AGUIRRE",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 630,
    localidad: "LOS AGUEROS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 631,
    localidad: "LOS AGUDOS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 632,
    localidad: "LOS AGUDOS",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 633,
    localidad: "LOPEZ DOMINGUEZ",
    cp: 4184,
    provincia_id: 24,
  },
  {
    id: 634,
    localidad: "LOMAS DE IMBAUD",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 635,
    localidad: "LOMA VERDE",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 636,
    localidad: "LOMA REDONDA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 637,
    localidad: "LOMA NEGRA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 638,
    localidad: "LOMA GRANDE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 639,
    localidad: "LOMA GRANDE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 640,
    localidad: "LOMA DEL MEDIO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 641,
    localidad: "LOLITA NUEVA",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 642,
    localidad: "LOLITA",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 643,
    localidad: "LOA DIAZ",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 644,
    localidad: "LESCANO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 645,
    localidad: "LEON ROUGES",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 646,
    localidad: "LEOCADIO PAZ",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 647,
    localidad: "LEO HUASI",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 648,
    localidad: "LEALES",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 649,
    localidad: "LAZARTE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 650,
    localidad: "LAURELES SUR",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 651,
    localidad: "LAURELES NORTE",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 652,
    localidad: "LAURELES",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 653,
    localidad: "LAUREL YACO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 654,
    localidad: "LASTENIA",
    cp: 4116,
    provincia_id: 24,
  },
  {
    id: 655,
    localidad: "LASTENIA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 656,
    localidad: "LAS ZORRAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 657,
    localidad: "LAS ZANJAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 658,
    localidad: "LAS TUSCAS TUSCAL",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 659,
    localidad: "LAS TRES FLORES",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 660,
    localidad: "LAS TIPAS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 661,
    localidad: "LAS TALITAS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 662,
    localidad: "LAS TALITAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 663,
    localidad: "LAS TALAS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 664,
    localidad: "LAS TALAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 665,
    localidad: "LAS TACANAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 666,
    localidad: "LAS TABLITAS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 667,
    localidad: "LAS TABLAS",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 668,
    localidad: "LAS T TRANQUITAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 669,
    localidad: "LAS T DE COLALAO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 670,
    localidad: "LAS SALINAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 671,
    localidad: "LAS RATAS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 672,
    localidad: "LAS PIRVAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 673,
    localidad: "LAS PIRCAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 674,
    localidad: "LAS PIEDRITAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 675,
    localidad: "LAS PECHOSAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 676,
    localidad: "LAS PAVAS",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 677,
    localidad: "LAS PAMPITAS",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 678,
    localidad: "LAS PALOMITAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 679,
    localidad: "LAS PALMITAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 680,
    localidad: "LAS MORITAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 681,
    localidad: "LAS MORERAS",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 682,
    localidad: "LAS MESADAS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 683,
    localidad: "LAS MERCEDES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 684,
    localidad: "LAS MELLIZAS",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 685,
    localidad: "LAS LEGUAS",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 686,
    localidad: "LAS L LAS LEGUAS",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 687,
    localidad: "LAS JUNTAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 688,
    localidad: "LAS JUNTAS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 689,
    localidad: "LAS JUNTAS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 690,
    localidad: "LAS HIGUERITAS",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 691,
    localidad: "LAS HIGUERILLAS",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 692,
    localidad: "LAS G LOS GUCHEA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 693,
    localidad: "LAS FALDAS",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 694,
    localidad: "LAS ENCRUCIJADAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 695,
    localidad: "LAS CUEVAS",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 696,
    localidad: "LAS CRIOLLAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 697,
    localidad: "LAS COLONIAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 698,
    localidad: "LAS CIENAGAS",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 699,
    localidad: "LAS CHACRAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 700,
    localidad: "LAS CELAYAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 701,
    localidad: "LAS CEJAS",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 702,
    localidad: "LAS CEJAS",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 703,
    localidad: "LAS CARRERAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 704,
    localidad: "LAS CANTINAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 705,
    localidad: "LAS BURRAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 706,
    localidad: "LAS BRISAS",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 707,
    localidad: "LAS BOTIJAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 708,
    localidad: "LAS BOLSAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 709,
    localidad: "LAS BARRANCAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 710,
    localidad: "LAS BANDERITAS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 711,
    localidad: "LAS ARCAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 712,
    localidad: "LAS ANIMAS",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 713,
    localidad: "LAS ACOSTILLAS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 714,
    localidad: "LARA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 715,
    localidad: "LAMPARCITO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 716,
    localidad: "LAGUNA GRANDE",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 717,
    localidad: "LAGUNA DE ROBLES",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 718,
    localidad: "LAGUNA BLANCA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 719,
    localidad: "LAGARTE",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 720,
    localidad: "LACHICO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 721,
    localidad: "LACAVERA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 722,
    localidad: "LA ZANJA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 723,
    localidad: "LA VERDE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 724,
    localidad: "LA UNION",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 725,
    localidad: "LA TUNA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 726,
    localidad: "LA TUNA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 727,
    localidad: "LA TRINIDAD",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 728,
    localidad: "LA TRANCA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 729,
    localidad: "LA TOMA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 730,
    localidad: "LA TOMA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 731,
    localidad: "LA TIPA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 732,
    localidad: "LA TAPIA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 733,
    localidad: "LA TALA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 734,
    localidad: "LA SOLEDAD",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 735,
    localidad: "LA SILLA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 736,
    localidad: "LA SALAMANCA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 737,
    localidad: "LA SALA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 738,
    localidad: "LA SALA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 739,
    localidad: "LA SALA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 740,
    localidad: "LA RUDA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 741,
    localidad: "LA RINCONADA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 742,
    localidad: "LA REINA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 743,
    localidad: "LA REDUCCION",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 744,
    localidad: "LA REDUCCION",
    cp: 4131,
    provincia_id: 24,
  },
  {
    id: 745,
    localidad: "LA RAMADITA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 746,
    localidad: "LA RAMADA DE ABAJO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 747,
    localidad: "LA RAMADA",
    cp: 4123,
    provincia_id: 24,
  },
  {
    id: 748,
    localidad: "LA RAMADA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 749,
    localidad: "LA QUINTA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 750,
    localidad: "LA QUESERIA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 751,
    localidad: "LA QUEBRADA",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 752,
    localidad: "LA PUNTILLA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 753,
    localidad: "LA PUERTA DE LUCA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 754,
    localidad: "LA PUERTA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 755,
    localidad: "LA PRINCESA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 756,
    localidad: "LA POSTA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 757,
    localidad: "LA POLA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 758,
    localidad: "LA PLANTA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 759,
    localidad: "LA PINTA Y LA 40",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 760,
    localidad: "LA PICADA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 761,
    localidad: "LA P DE MARAPA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 762,
    localidad: "LA MESADA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 763,
    localidad: "LA MEDIA AGUA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 764,
    localidad: "LA MARTA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 765,
    localidad: "LA MARAVILLA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 766,
    localidad: "LA MARAVILLA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 767,
    localidad: "LA MANGA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 768,
    localidad: "LA LOMA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 769,
    localidad: "LA LOMA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 770,
    localidad: "LA LAGUNITA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 771,
    localidad: "LA LAGUNILLA",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 772,
    localidad: "LA LAGUNA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 773,
    localidad: "LA JUNTA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 774,
    localidad: "LA ISLA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 775,
    localidad: "LA INVERNADA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 776,
    localidad: "LA IGUANA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 777,
    localidad: "LA HUERTA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 778,
    localidad: "LA HOYADA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 779,
    localidad: "LA HIGUERA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 780,
    localidad: "LA HELADERA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 781,
    localidad: "LA GUILLERMINA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 782,
    localidad: "LA GRAMA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 783,
    localidad: "LA FRONTERITA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 784,
    localidad: "LA FRONTERITA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 785,
    localidad: "LA FORTUNA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 786,
    localidad: "LA FLORIDA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 787,
    localidad: "LA FLORIDA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 788,
    localidad: "LA FLORIDA",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 789,
    localidad: "LA FLORIDA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 790,
    localidad: "LA FLOR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 791,
    localidad: "LA FAVORITA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 792,
    localidad: "LA FALDA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 793,
    localidad: "LA ESQUINA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 794,
    localidad: "LA ESPERANZA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 795,
    localidad: "LA ERCILIA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 796,
    localidad: "LA ENCANTADA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 797,
    localidad: "LA EMPATADA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 798,
    localidad: "LA DORITA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 799,
    localidad: "LA DONOSA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 800,
    localidad: "LA CRUZ DE ARRIBA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 801,
    localidad: "LA CRUZ",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 802,
    localidad: "LA CORZUELA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 803,
    localidad: "LA CORNELIA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 804,
    localidad: "LA COMBADA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 805,
    localidad: "LA COLONIA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 806,
    localidad: "LA COCHA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 807,
    localidad: "LA CIENAGA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 808,
    localidad: "LA CIENAGA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 809,
    localidad: "LA CHILCA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 810,
    localidad: "LA CAVERA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 811,
    localidad: "LA CAUTIVA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 812,
    localidad: "LA CAQADA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 813,
    localidad: "LA CAPILLA",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 814,
    localidad: "LA CAÑADA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 815,
    localidad: "LA CANTINA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 816,
    localidad: "LA CALERA - TUCUMAN",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 817,
    localidad: "LA CALERA - TUCUMAN",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 818,
    localidad: "LA BOMBA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 819,
    localidad: "LA BOLSA",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 820,
    localidad: "LA BOLSA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 821,
    localidad: "LA BANDERITA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 822,
    localidad: "LA BANDA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 823,
    localidad: "LA BANDA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 824,
    localidad: "LA BANDA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 825,
    localidad: "LA BANDA",
    cp: 4133,
    provincia_id: 24,
  },
  {
    id: 826,
    localidad: "LA ARGENTINA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 827,
    localidad: "LA ANGOSTURA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 828,
    localidad: "LA AGUITA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 829,
    localidad: "LA AGUADITA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 830,
    localidad: "LA AGUADA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 831,
    localidad: "LA AGUADA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 832,
    localidad: "KILOMETRO 99",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 833,
    localidad: "KILOMETRO 94",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 834,
    localidad: "KILOMETRO 93",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 835,
    localidad: "KILOMETRO 925",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 836,
    localidad: "KILOMETRO 847",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 837,
    localidad: "KILOMETRO 808",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 838,
    localidad: "KILOMETRO 80",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 839,
    localidad: "KILOMETRO 792",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 840,
    localidad: "KILOMETRO 771",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 841,
    localidad: "KILOMETRO 66",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 842,
    localidad: "KILOMETRO 55",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 843,
    localidad: "KILOMETRO 5",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 844,
    localidad: "KILOMETRO 46",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 845,
    localidad: "KILOMETRO 36",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 846,
    localidad: "KILOMETRO 35",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 847,
    localidad: "KILOMETRO 34",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 848,
    localidad: "KILOMETRO 1455",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 849,
    localidad: "KILOMETRO 1340",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 850,
    localidad: "KILOMETRO 1270",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 851,
    localidad: "KILOMETRO 1260",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 852,
    localidad: "KILOMETRO 1256",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 853,
    localidad: "KILOMETRO 1248",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 854,
    localidad: "KILOMETRO 1244",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 855,
    localidad: "KILOMETRO 1240",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 856,
    localidad: "KILOMETRO 1235",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 857,
    localidad: "KILOMETRO 1231",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 858,
    localidad: "KILOMETRO 1220",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 859,
    localidad: "KILOMETRO 1213",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 860,
    localidad: "KILOMETRO 1207",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 861,
    localidad: "KILOMETRO 1194",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 862,
    localidad: "KILOMETRO 1185",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 863,
    localidad: "KILOMETRO 108",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 864,
    localidad: "KILOMETRO 102",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 865,
    localidad: "KILOMETRO 10 FCGB",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 866,
    localidad: "JUSCO POZO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 867,
    localidad: "JUNTA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 868,
    localidad: "JUMIALITO",
    cp: 4300,
    provincia_id: 24,
  },
  {
    id: 869,
    localidad: "JULIANA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 870,
    localidad: "JUAN POSSE",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 871,
    localidad: "JUAN BAUTISTA ALBERDI",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 872,
    localidad: "JAYA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 873,
    localidad: "JAVA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 874,
    localidad: "JAGUEL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 875,
    localidad: "J. B. ALBERDI",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 876,
    localidad: "ISLA SAN JOSE SUD",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 877,
    localidad: "ISLA SAN JOSE",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 878,
    localidad: "ISCHILLON",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 879,
    localidad: "INVERNADA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 880,
    localidad: "INGENIO SANTA ROSA",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 881,
    localidad: "INGENIO SANTA LUCIA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 882,
    localidad: "INGENIO SANTA ANA",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 883,
    localidad: "INGENIO SAN JUAN",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 884,
    localidad: "INGENIO S BARBARA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 885,
    localidad: "INGENIO MARAPA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 886,
    localidad: "INGENIO LULES",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 887,
    localidad: "INGENIO LOS RALOS",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 888,
    localidad: "INGENIO LEALES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 889,
    localidad: "INGENIO LA FLORIDA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 890,
    localidad: "INGENIO LA CORONA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 891,
    localidad: "INGENIO CRUZ ALTA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 892,
    localidad: "INGENIO CONCEPCION",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 893,
    localidad: "INGENIO B VISTA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 894,
    localidad: "INGENIERO S LUCIA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 895,
    localidad: "INGAS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 896,
    localidad: "ING MERCEDES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 897,
    localidad: "ING LA TRINIDAD",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 898,
    localidad: "ING LA PROVIDENCIA",
    cp: 4145,
    provincia_id: 24,
  },
  {
    id: 899,
    localidad: "ING LA FRONTERITA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 900,
    localidad: "INDIA MUERTA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 901,
    localidad: "INDEPENDENCIA",
    cp: 4143,
    provincia_id: 24,
  },
  {
    id: 902,
    localidad: "ILTICO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 903,
    localidad: "IGUANA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 904,
    localidad: "IGLESIAS",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 905,
    localidad: "ICHIPUCA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 906,
    localidad: "IBATIN",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 907,
    localidad: "IAPES",
    cp: 4139,
    provincia_id: 24,
  },
  {
    id: 908,
    localidad: "HUMAITA 2",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 909,
    localidad: "HUMAITA 1",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 910,
    localidad: "HUASAMAYO SUD",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 911,
    localidad: "HUASAMAYO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 912,
    localidad: "HUASA RINCON",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 913,
    localidad: "HUASA PAMPA SUR",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 914,
    localidad: "HUASA PAMPA NORTE",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 915,
    localidad: "HUASA PAMPA",
    cp: 4163,
    provincia_id: 24,
  },
  {
    id: 916,
    localidad: "HUASA PAMPA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 917,
    localidad: "HUALINCHAY",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 918,
    localidad: "HUACRA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 919,
    localidad: "HOYADA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 920,
    localidad: "HORCO MOLLE",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 921,
    localidad: "HITACHI",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 922,
    localidad: "HIGUERITAS",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 923,
    localidad: "GUZMAN E FCGB",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 924,
    localidad: "GUEMES",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 925,
    localidad: "GUANACO MUERTO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 926,
    localidad: "GRANJA MODELO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 927,
    localidad: "GRANEROS",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 928,
    localidad: "GRAMILLA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 929,
    localidad: "GRAMAJOS",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 930,
    localidad: "GONZALO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 931,
    localidad: "GOMEZ CHICO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 932,
    localidad: "GOBERNADOR NOUGES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 933,
    localidad: "GASTONILLA",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 934,
    localidad: "GASTONA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 935,
    localidad: "GARMENDIA",
    cp: 4181,
    provincia_id: 24,
  },
  {
    id: 936,
    localidad: "G ANSELMO ROJO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 937,
    localidad: "FUERTE QUEMADO",
    cp: 4150,
    provincia_id: 24,
  },
  {
    id: 938,
    localidad: "FUERTE QUEMADO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 939,
    localidad: "FUERTE ALTO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 940,
    localidad: "FRONTERITAS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 941,
    localidad: "FINCA TULIO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 942,
    localidad: "FINCA TINTA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 943,
    localidad: "FINCA TINA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 944,
    localidad: "FINCA SAN LUIS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 945,
    localidad: "FINCA PEREYRA",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 946,
    localidad: "FINCA PACARA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 947,
    localidad: "FINCA MAYO",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 948,
    localidad: "FINCA LOS LLANOS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 949,
    localidad: "FINCA LOPEZ",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 950,
    localidad: "FINCA LEILA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 951,
    localidad: "FINCA ENTRE RIOS",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 952,
    localidad: "FINCA ELISA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 953,
    localidad: "FINCA EL CEIBO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 954,
    localidad: "FINCA CRISTINA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 955,
    localidad: "FINCA ARAOZ",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 956,
    localidad: "FINCA ANCHORENA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 957,
    localidad: "FIN DEL MUNDO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 958,
    localidad: "FAVORINA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 959,
    localidad: "FAMAILLA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 960,
    localidad: "FALDA DE ARCADIA",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 961,
    localidad: "FAGSA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 962,
    localidad: "F PIEDRA BLANCA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 963,
    localidad: "ESTANQUE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 964,
    localidad: "ESTANCIA SURI YACO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 965,
    localidad: "ESTACION ARAOZ",
    cp: 4177,
    provincia_id: 24,
  },
  {
    id: 966,
    localidad: "ESQUINA NORTE",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 967,
    localidad: "ESQUINA DEL VALLE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 968,
    localidad: "ESQUINA DEL LLANO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 969,
    localidad: "ESQUINA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 970,
    localidad: "ESPIADERO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 971,
    localidad: "ESCUELA W POSSE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 972,
    localidad: "ESCUELA V GENERALA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 973,
    localidad: "ESCUELA R ROJAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 974,
    localidad: "ESCUELA R J FREYRE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 975,
    localidad: "ESCUELA N VERGARA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 976,
    localidad: "ESCUELA MATIENZO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 977,
    localidad: "ESCUELA MALVINAS",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 978,
    localidad: "ESCUELA LUGONES",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 979,
    localidad: "ESCUELA L BLANCO",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 980,
    localidad: "ESCUELA JOSE POSSE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 981,
    localidad: "ESCUELA ING BERTRE",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 982,
    localidad: "ESCUELA GREGORIA LAMADRID",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 983,
    localidad: "ESCUELA GRANILLO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 984,
    localidad: "ESCUELA GOMEZ",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 985,
    localidad: "ESCUELA G DE VEGA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 986,
    localidad: "ESCUELA F NOGUES",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 987,
    localidad: "ESCUELA E DE LUCAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 988,
    localidad: "ESCUELA E CANTON",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 989,
    localidad: "ESCUELA ARENALES",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 990,
    localidad: "ESCUELA ALMAFUERTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 991,
    localidad: "ESCUELA 99",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 992,
    localidad: "ESCUELA 97",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 993,
    localidad: "ESCUELA 95",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 994,
    localidad: "ESCUELA 94",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 995,
    localidad: "ESCUELA 93",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 996,
    localidad: "ESCUELA 9",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 997,
    localidad: "ESCUELA 88",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 998,
    localidad: "ESCUELA 86",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 999,
    localidad: "ESCUELA 85",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1000,
    localidad: "ESCUELA 84",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1001,
    localidad: "ESCUELA 82",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1002,
    localidad: "ESCUELA 81",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1003,
    localidad: "ESCUELA 80",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1004,
    localidad: "ESCUELA 79",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1005,
    localidad: "ESCUELA 78",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1006,
    localidad: "ESCUELA 77",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1007,
    localidad: "ESCUELA 76",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1008,
    localidad: "ESCUELA 70",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1009,
    localidad: "ESCUELA 69",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1010,
    localidad: "ESCUELA 68",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1011,
    localidad: "ESCUELA 67",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1012,
    localidad: "ESCUELA 66",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1013,
    localidad: "ESCUELA 65",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1014,
    localidad: "ESCUELA 64",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1015,
    localidad: "ESCUELA 63",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1016,
    localidad: "ESCUELA 6",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1017,
    localidad: "ESCUELA 59",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1018,
    localidad: "ESCUELA 56",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1019,
    localidad: "ESCUELA 55",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1020,
    localidad: "ESCUELA 52",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1021,
    localidad: "ESCUELA 51",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1022,
    localidad: "ESCUELA 48",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1023,
    localidad: "ESCUELA 43",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1024,
    localidad: "ESCUELA 41",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1025,
    localidad: "ESCUELA 393",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1026,
    localidad: "ESCUELA 391",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1027,
    localidad: "ESCUELA 39",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1028,
    localidad: "ESCUELA 387",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1029,
    localidad: "ESCUELA 376",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1030,
    localidad: "ESCUELA 373",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1031,
    localidad: "ESCUELA 370",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1032,
    localidad: "ESCUELA 365",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1033,
    localidad: "ESCUELA 364",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1034,
    localidad: "ESCUELA 36",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1035,
    localidad: "ESCUELA 356",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1036,
    localidad: "ESCUELA 354",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1037,
    localidad: "ESCUELA 352",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1038,
    localidad: "ESCUELA 350",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1039,
    localidad: "ESCUELA 348",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1040,
    localidad: "ESCUELA 345",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1041,
    localidad: "ESCUELA 335",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1042,
    localidad: "ESCUELA 334",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1043,
    localidad: "ESCUELA 333",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1044,
    localidad: "ESCUELA 332",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1045,
    localidad: "ESCUELA 330",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1046,
    localidad: "ESCUELA 329",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1047,
    localidad: "ESCUELA 328",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1048,
    localidad: "ESCUELA 327",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1049,
    localidad: "ESCUELA 324",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1050,
    localidad: "ESCUELA 323",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1051,
    localidad: "ESCUELA 322",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1052,
    localidad: "ESCUELA 321",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1053,
    localidad: "ESCUELA 320",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1054,
    localidad: "ESCUELA 318",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1055,
    localidad: "ESCUELA 317",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1056,
    localidad: "ESCUELA 312",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1057,
    localidad: "ESCUELA 311",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1058,
    localidad: "ESCUELA 308",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1059,
    localidad: "ESCUELA 307",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1060,
    localidad: "ESCUELA 3",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1061,
    localidad: "ESCUELA 299",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1062,
    localidad: "ESCUELA 298",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1063,
    localidad: "ESCUELA 297",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1064,
    localidad: "ESCUELA 293",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1065,
    localidad: "ESCUELA 291",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1066,
    localidad: "ESCUELA 289",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1067,
    localidad: "ESCUELA 288",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1068,
    localidad: "ESCUELA 287",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1069,
    localidad: "ESCUELA 286",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1070,
    localidad: "ESCUELA 284",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1071,
    localidad: "ESCUELA 283",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1072,
    localidad: "ESCUELA 280",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1073,
    localidad: "ESCUELA 279",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1074,
    localidad: "ESCUELA 277",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1075,
    localidad: "ESCUELA 274",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1076,
    localidad: "ESCUELA 273",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1077,
    localidad: "ESCUELA 272",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1078,
    localidad: "ESCUELA 270",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1079,
    localidad: "ESCUELA 27",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1080,
    localidad: "ESCUELA 269",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1081,
    localidad: "ESCUELA 268",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1082,
    localidad: "ESCUELA 267",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1083,
    localidad: "ESCUELA 266",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1084,
    localidad: "ESCUELA 264",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1085,
    localidad: "ESCUELA 263",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1086,
    localidad: "ESCUELA 261",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1087,
    localidad: "ESCUELA 260",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1088,
    localidad: "ESCUELA 26",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1089,
    localidad: "ESCUELA 257",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1090,
    localidad: "ESCUELA 256",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1091,
    localidad: "ESCUELA 255",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1092,
    localidad: "ESCUELA 254",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1093,
    localidad: "ESCUELA 253",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1094,
    localidad: "ESCUELA 251",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1095,
    localidad: "ESCUELA 25 DE MAYO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1096,
    localidad: "ESCUELA 247",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1097,
    localidad: "ESCUELA 241",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1098,
    localidad: "ESCUELA 239",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1099,
    localidad: "ESCUELA 238",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1100,
    localidad: "ESCUELA 237",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1101,
    localidad: "ESCUELA 235",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1102,
    localidad: "ESCUELA 234",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1103,
    localidad: "ESCUELA 232",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1104,
    localidad: "ESCUELA 231",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1105,
    localidad: "ESCUELA 229",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1106,
    localidad: "ESCUELA 228",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1107,
    localidad: "ESCUELA 227",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1108,
    localidad: "ESCUELA 226",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1109,
    localidad: "ESCUELA 225",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1110,
    localidad: "ESCUELA 224",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1111,
    localidad: "ESCUELA 222",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1112,
    localidad: "ESCUELA 220",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1113,
    localidad: "ESCUELA 219",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1114,
    localidad: "ESCUELA 218",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1115,
    localidad: "ESCUELA 215",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1116,
    localidad: "ESCUELA 212",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1117,
    localidad: "ESCUELA 210",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1118,
    localidad: "ESCUELA 21",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1119,
    localidad: "ESCUELA 209",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1120,
    localidad: "ESCUELA 207",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1121,
    localidad: "ESCUELA 206",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1122,
    localidad: "ESCUELA 203",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1123,
    localidad: "ESCUELA 201",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1124,
    localidad: "ESCUELA 200",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1125,
    localidad: "ESCUELA 20",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1126,
    localidad: "ESCUELA 199",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1127,
    localidad: "ESCUELA 198",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1128,
    localidad: "ESCUELA 197",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1129,
    localidad: "ESCUELA 195",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1130,
    localidad: "ESCUELA 194",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1131,
    localidad: "ESCUELA 193",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1132,
    localidad: "ESCUELA 192",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1133,
    localidad: "ESCUELA 190",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1134,
    localidad: "ESCUELA 19",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1135,
    localidad: "ESCUELA 186",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1136,
    localidad: "ESCUELA 185",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1137,
    localidad: "ESCUELA 183",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1138,
    localidad: "ESCUELA 18",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1139,
    localidad: "ESCUELA 176",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1140,
    localidad: "ESCUELA 175",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1141,
    localidad: "ESCUELA 174",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1142,
    localidad: "ESCUELA 17",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1143,
    localidad: "ESCUELA 168",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1144,
    localidad: "ESCUELA 164",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1145,
    localidad: "ESCUELA 163",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1146,
    localidad: "ESCUELA 162",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1147,
    localidad: "ESCUELA 160",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1148,
    localidad: "ESCUELA 16",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1149,
    localidad: "ESCUELA 157",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1150,
    localidad: "ESCUELA 156",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1151,
    localidad: "ESCUELA 154",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1152,
    localidad: "ESCUELA 153",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1153,
    localidad: "ESCUELA 15",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1154,
    localidad: "ESCUELA 147",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1155,
    localidad: "ESCUELA 146",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1156,
    localidad: "ESCUELA 145",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1157,
    localidad: "ESCUELA 142",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1158,
    localidad: "ESCUELA 141",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1159,
    localidad: "ESCUELA 140",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1160,
    localidad: "ESCUELA 138",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1161,
    localidad: "ESCUELA 137",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1162,
    localidad: "ESCUELA 134",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1163,
    localidad: "ESCUELA 133",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1164,
    localidad: "ESCUELA 132",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1165,
    localidad: "ESCUELA 131",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1166,
    localidad: "ESCUELA 130",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1167,
    localidad: "ESCUELA 129",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1168,
    localidad: "ESCUELA 126",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1169,
    localidad: "ESCUELA 124",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1170,
    localidad: "ESCUELA 123",
    cp: 4176,
    provincia_id: 24,
  },
  {
    id: 1171,
    localidad: "ESCUELA 12",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1172,
    localidad: "ESCUELA 118",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1173,
    localidad: "ESCUELA 116",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1174,
    localidad: "ESCUELA 115",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1175,
    localidad: "ESCUELA 114",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1176,
    localidad: "ESCUELA 113",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1177,
    localidad: "ESCUELA 111",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1178,
    localidad: "ESCUELA 110",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1179,
    localidad: "ESCUELA 11",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1180,
    localidad: "ESCUELA 109",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1181,
    localidad: "ESCUELA 108",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1182,
    localidad: "ESCUELA 107",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1183,
    localidad: "ESCUELA 106",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1184,
    localidad: "ESCUELA 105",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1185,
    localidad: "ESCUELA 104",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1186,
    localidad: "ESCUELA 103",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1187,
    localidad: "ESCUELA 102",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1188,
    localidad: "ESCUELA 100",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1189,
    localidad: "ESCUELA 1 JUNTA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1190,
    localidad: "ESCUELA 1",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1191,
    localidad: "ESCUELA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1192,
    localidad: "ESCOBAS",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 1193,
    localidad: "ESCABA DE ARRIBA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1194,
    localidad: "ESCABA DE ABAJO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1195,
    localidad: "ESCABA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1196,
    localidad: "ENTRE RIOS",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1197,
    localidad: "ENTRE RIOS",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 1198,
    localidad: "ENTRE RIOS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1199,
    localidad: "ENSENADA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1200,
    localidad: "ENCRUCIJADA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1201,
    localidad: "EMPALME AGUA DULCE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1202,
    localidad: "EL ZAUZAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1203,
    localidad: "EL ZAPALLAR",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1204,
    localidad: "EL ZANJON",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1205,
    localidad: "EL VALLECITO",
    cp: 4176,
    provincia_id: 24,
  },
  {
    id: 1206,
    localidad: "EL TUSCAL",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 1207,
    localidad: "EL TROPEZON",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1208,
    localidad: "EL TRIUNFO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1209,
    localidad: "EL TOSTADO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1210,
    localidad: "EL TORO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1211,
    localidad: "EL TOBAR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1212,
    localidad: "EL TIRO ARGENTINO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1213,
    localidad: "EL TIPAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1214,
    localidad: "EL TIMBO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1215,
    localidad: "EL TALAR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1216,
    localidad: "EL TALAR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1217,
    localidad: "EL TAJAMAR",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1218,
    localidad: "EL SUNCHO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1219,
    localidad: "EL SUNCHO",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 1220,
    localidad: "EL SUNCHAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1221,
    localidad: "EL SUNCAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1222,
    localidad: "EL SINQUIAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1223,
    localidad: "EL SIAMBON",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1224,
    localidad: "EL SAUZAL",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1225,
    localidad: "EL ROSARIO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1226,
    localidad: "EL RODEO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1227,
    localidad: "EL RODEO",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 1228,
    localidad: "EL RODEO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1229,
    localidad: "EL RINCON",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 1230,
    localidad: "EL QUIMIL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1231,
    localidad: "EL QUEBRACHITO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1232,
    localidad: "EL QUEBRACHAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1233,
    localidad: "EL PUESTO",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1234,
    localidad: "EL PUESTO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1235,
    localidad: "EL PUESTITO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1236,
    localidad: "EL PUESTITO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1237,
    localidad: "EL PUERTO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1238,
    localidad: "EL POZO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1239,
    localidad: "EL POZO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1240,
    localidad: "EL POTRERO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1241,
    localidad: "EL POTRERO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1242,
    localidad: "EL POTRERILLO",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1243,
    localidad: "EL POTRERILLO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1244,
    localidad: "EL PORVENIR",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1245,
    localidad: "EL PORVENIR",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1246,
    localidad: "EL PORVENIR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1247,
    localidad: "EL PORVENIR",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1248,
    localidad: "EL PORTEZUELO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1249,
    localidad: "EL POLEAR",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1250,
    localidad: "EL POLEAR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1251,
    localidad: "EL PILA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1252,
    localidad: "EL PELADO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1253,
    localidad: "EL PELADO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1254,
    localidad: "EL PAYANAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1255,
    localidad: "EL PAVON",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1256,
    localidad: "EL PARAISO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1257,
    localidad: "EL PARAISO",
    cp: 4300,
    provincia_id: 24,
  },
  {
    id: 1258,
    localidad: "EL PALCARA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1259,
    localidad: "EL PAJAL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1260,
    localidad: "EL PACARA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1261,
    localidad: "EL PABELLON",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1262,
    localidad: "EL P DE PARANILLO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1263,
    localidad: "EL ONCE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1264,
    localidad: "EL OJO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1265,
    localidad: "EL OBRAJE",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 1266,
    localidad: "EL OBRAJE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1267,
    localidad: "EL NOGALITO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1268,
    localidad: "EL NOGALAR",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 1269,
    localidad: "EL NOGAL",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1270,
    localidad: "EL NARANJO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1271,
    localidad: "EL NARANJO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1272,
    localidad: "EL NARANJITO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1273,
    localidad: "EL NARANJITO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1274,
    localidad: "EL NARANJAL",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1275,
    localidad: "EL MUTUL",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1276,
    localidad: "EL MOYAR",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1277,
    localidad: "EL MORADO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1278,
    localidad: "EL MOLLE VIEJO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1279,
    localidad: "EL MOLLE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1280,
    localidad: "EL MOLLAR",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1281,
    localidad: "EL MOLLAR",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1282,
    localidad: "EL MOLLAR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1283,
    localidad: "EL MOLLAR",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 1284,
    localidad: "EL MOLINO",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1285,
    localidad: "EL MOLINO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1286,
    localidad: "EL MOLINO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1287,
    localidad: "EL MOJON",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1288,
    localidad: "EL MOJON",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1289,
    localidad: "EL MISTOLAR",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1290,
    localidad: "EL MISTOL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1291,
    localidad: "EL MILAGRO",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1292,
    localidad: "EL MILAGRO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1293,
    localidad: "EL MELON",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1294,
    localidad: "EL MATAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1295,
    localidad: "EL MATADERO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1296,
    localidad: "EL MANANTIAL",
    cp: 4104,
    provincia_id: 24,
  },
  {
    id: 1297,
    localidad: "EL LAMPARAZO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1298,
    localidad: "EL LAMEDERO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1299,
    localidad: "EL LAMEDERO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1300,
    localidad: "EL JUNQUILLAR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1301,
    localidad: "EL JARDIN",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1302,
    localidad: "EL JARDIN",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1303,
    localidad: "EL JARDIN",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1304,
    localidad: "EL INTERES",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1305,
    localidad: "EL INFIERNILLO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1306,
    localidad: "EL HUAICO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1307,
    localidad: "EL GUAYACAN",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1308,
    localidad: "EL GUARDAMONTE",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1309,
    localidad: "EL FRASQUILLO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1310,
    localidad: "EL ESTABLO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1311,
    localidad: "EL ESPINILLO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1312,
    localidad: "EL ESPINAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1313,
    localidad: "EL DURAZNO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1314,
    localidad: "EL DURAZNO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1315,
    localidad: "EL DURAZNITO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1316,
    localidad: "EL DURAZNITO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1317,
    localidad: "EL DURAZNILLO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1318,
    localidad: "EL DIVISADERO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1319,
    localidad: "EL DERRUMBE",
    cp: 4139,
    provincia_id: 24,
  },
  {
    id: 1320,
    localidad: "EL CUIBAL",
    cp: 4126,
    provincia_id: 24,
  },
  {
    id: 1321,
    localidad: "EL CUARTEADERO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1322,
    localidad: "EL CUADRO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1323,
    localidad: "EL CRUCE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1324,
    localidad: "EL CRUCE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1325,
    localidad: "EL CORTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1326,
    localidad: "EL CORTADERAL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1327,
    localidad: "EL CORRALITO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1328,
    localidad: "EL COLMENAR",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1329,
    localidad: "EL COLCOLAR",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1330,
    localidad: "EL COCHUCHAL",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1331,
    localidad: "EL CHURQUIS",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1332,
    localidad: "EL CHURQUI",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1333,
    localidad: "EL CHURQUI",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1334,
    localidad: "EL CHORRO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1335,
    localidad: "EL CHORRO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1336,
    localidad: "EL CHILCAR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1337,
    localidad: "EL CHAÑAR",
    cp: 4120,
    provincia_id: 24,
  },
  {
    id: 1338,
    localidad: "EL CEVILAR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1339,
    localidad: "EL CEIBAL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1340,
    localidad: "EL CEIBAL",
    cp: 4153,
    provincia_id: 24,
  },
  {
    id: 1341,
    localidad: "EL CEIBAL",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1342,
    localidad: "EL CEDRO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1343,
    localidad: "EL CEBIL",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1344,
    localidad: "EL CATORCE",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1345,
    localidad: "EL CASTORAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1346,
    localidad: "EL CASTORAL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1347,
    localidad: "EL CASIALITO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1348,
    localidad: "EL CASIAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1349,
    localidad: "EL CARMEN P ALTO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1350,
    localidad: "EL CARMEN",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1351,
    localidad: "EL CARMEN",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1352,
    localidad: "EL CARMEN",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 1353,
    localidad: "EL CARDENAL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1354,
    localidad: "EL CAJON",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1355,
    localidad: "EL CADILLAL",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1356,
    localidad: "EL CADILLAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1357,
    localidad: "EL C COLOMBRES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1358,
    localidad: "EL BRETE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1359,
    localidad: "EL BRACHO",
    cp: 4112,
    provincia_id: 24,
  },
  {
    id: 1360,
    localidad: "EL BOYERO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1361,
    localidad: "EL BATIRUANO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1362,
    localidad: "EL BARRIALITO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1363,
    localidad: "EL BARRANQUERO",
    cp: 4176,
    provincia_id: 24,
  },
  {
    id: 1364,
    localidad: "EL BARCO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1365,
    localidad: "EL BAJO",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 1366,
    localidad: "EL BAGUAL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1367,
    localidad: "EL AZUL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1368,
    localidad: "EL ATACAT",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1369,
    localidad: "EL ATACAL",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1370,
    localidad: "EL ASERRADERO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1371,
    localidad: "EL ASERRADERO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1372,
    localidad: "EL ARQUEAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1373,
    localidad: "EL ARENAL",
    cp: 4166,
    provincia_id: 24,
  },
  {
    id: 1374,
    localidad: "EL ARENAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1375,
    localidad: "EL ANTIGAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1376,
    localidad: "EL ALPIZAR",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1377,
    localidad: "EL AGUILAR",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1378,
    localidad: "EL 25 DE MAYO",
    cp: 4283,
    provincia_id: 24,
  },
  {
    id: 1379,
    localidad: "E VELEZ SARSFIELD",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1380,
    localidad: "E T DE TACUARI",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1381,
    localidad: "E STIAGO GALLO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1382,
    localidad: "E SARGENTO CABRAL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1383,
    localidad: "E S AGRICOLA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1384,
    localidad: "E RAMON CARRILLO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1385,
    localidad: "E PEDRO MEDRANO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1386,
    localidad: "E PEDRO ECHEVERRY",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1387,
    localidad: "E P DE MENDOZA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1388,
    localidad: "E OTILDE DE TORO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1389,
    localidad: "E OLEGARIO ANDRADE",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1390,
    localidad: "E MONTE GRANDE",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1391,
    localidad: "E MIGUEL CERVANTES",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1392,
    localidad: "E MIGUEL AZCUENAGA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1393,
    localidad: "E MERCEDES PACHECO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1394,
    localidad: "E MARIO BRAVO",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1395,
    localidad: "E MANUELA PEDRAZA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1396,
    localidad: "E MANUEL SAVIO",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1397,
    localidad: "E MANUEL BORDA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1398,
    localidad: "E M DGO BASSAIL",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1399,
    localidad: "E M DE PUEYRREDON",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1400,
    localidad: "E LUIS GIANNEO",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1401,
    localidad: "E LOPEZ Y PLANES",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1402,
    localidad: "E LAS CS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1403,
    localidad: "E LA PRINCESA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1404,
    localidad: "E LA ASUNCION",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1405,
    localidad: "E JUANA MANSO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1406,
    localidad: "E JUAN JOSE PASO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1407,
    localidad: "E JOSE COLOMBRES",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1408,
    localidad: "E J V GONZALEZ",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1409,
    localidad: "E J CASTELLANO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1410,
    localidad: "E ING BASCARY",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1411,
    localidad: "E IGNACIO BAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1412,
    localidad: "E I COLOMBRES",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1413,
    localidad: "E HERNAN MIRAVAL",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1414,
    localidad: "E GUIDO SPANO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1415,
    localidad: "E GOB J M SILVA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1416,
    localidad: "E G MOREIRA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1417,
    localidad: "E G MIGUEL NOGU",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1418,
    localidad: "E G LAMADRID",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1419,
    localidad: "E G DE S MART",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1420,
    localidad: "E FRAY M ESQUIU",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1421,
    localidad: "E FORTUNATA GARCIA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1422,
    localidad: "E FLORENCIO VARELA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1423,
    localidad: "E F N LAPRIDA",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1424,
    localidad: "E EXPER AGRICOLA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1425,
    localidad: "E ESTANIO ZEBALLOS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1426,
    localidad: "E ESQUINA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1427,
    localidad: "E EL DIAMANTE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1428,
    localidad: "E EL CADILLAL",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1429,
    localidad: "E DOMINGO GARCIA",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1430,
    localidad: "E DEAN SALCEDO",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1431,
    localidad: "E DE MANUALIDADES",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1432,
    localidad: "E DE M OUANTA",
    cp: 4128,
    provincia_id: 24,
  },
  {
    id: 1433,
    localidad: "E CORONEL ROCA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1434,
    localidad: "E CORN SAAVEDRA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1435,
    localidad: "E CNEL I MURGA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1436,
    localidad: "E CNEL GERONIMO H",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1437,
    localidad: "E CAPITAN G DE MED",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1438,
    localidad: "E CACIQUE MANAMICO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1439,
    localidad: "E C TUCUMANOS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1440,
    localidad: "E C PELLEGRINI",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1441,
    localidad: "E BLAS PARERA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1442,
    localidad: "E B DE TUCUMAN",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1443,
    localidad: "E ARAOZ ALFARO",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1444,
    localidad: "E ANTONIO MEDINA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1445,
    localidad: "E ANGEL PADILLA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1446,
    localidad: "E ALMIRANTE BROWN",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1447,
    localidad: "E ALFONSINA STORNI",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1448,
    localidad: "E AGUEDA DE POSSE",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1449,
    localidad: "E A HEREDIA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1450,
    localidad: "E 300 LA PICADA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1451,
    localidad: "E 12 DE OCTUBRE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1452,
    localidad: "DURAZNOS BLANCOS",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 1453,
    localidad: "DURAZNO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1454,
    localidad: "DONATO ALVAREZ",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1455,
    localidad: "DOMINGO MILLAN",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1456,
    localidad: "DOLAVON",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1457,
    localidad: "DIQUE ESCABA",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1458,
    localidad: "DESMONTE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1459,
    localidad: "DESMONTE",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1460,
    localidad: "DESCANSO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1461,
    localidad: "DELFIN GALLO",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1462,
    localidad: "CURVA DE LOS VEGAS",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1463,
    localidad: "CUESTA DE CHILCA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1464,
    localidad: "CUCHILLAS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1465,
    localidad: "CUATRO SAUCES",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1466,
    localidad: "CUATRO GATOS",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 1467,
    localidad: "CRUZ DEL N E FCGM",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1468,
    localidad: "CRUZ DE ABAJO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1469,
    localidad: "CRIOLLAS",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1470,
    localidad: "COSTILLA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1471,
    localidad: "COSTA DEL RIO SECO",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1472,
    localidad: "COSTA A ESQUINA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1473,
    localidad: "COSSIO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1474,
    localidad: "CORTADERAS",
    cp: 4153,
    provincia_id: 24,
  },
  {
    id: 1475,
    localidad: "CORTADERAS",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1476,
    localidad: "CORTADERAL",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1477,
    localidad: "CORRAL VIEJO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1478,
    localidad: "CORRAL GRANDE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1479,
    localidad: "CORRAL BLANCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1480,
    localidad: "CORONA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1481,
    localidad: "COROMAMA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1482,
    localidad: "COOP AGRONOMICA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1483,
    localidad: "CONSIMO",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1484,
    localidad: "CONDOR HUASI",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1485,
    localidad: "CONCEPCION",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1486,
    localidad: "CONCEPCION",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1487,
    localidad: "COLONIA TACAPUNCO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1488,
    localidad: "COLONIA SOBRECASA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1489,
    localidad: "COLONIA SARMIENTO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1490,
    localidad: "COLONIA SANTA RITA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1491,
    localidad: "COLONIA SANTA RITA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1492,
    localidad: "COLONIA SAN RAMON",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1493,
    localidad: "COLONIA SAN MIGUEL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1494,
    localidad: "COLONIA SAN LUIS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1495,
    localidad: "COLONIA PACARA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1496,
    localidad: "COLONIA NASCHI",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1497,
    localidad: "COLONIA MONTEROS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1498,
    localidad: "COLONIA MISTOL",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1499,
    localidad: "COLONIA MERCEDES",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1500,
    localidad: "COLONIA MARULL",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1501,
    localidad: "COLONIA LOS HILLS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1502,
    localidad: "COLONIA LA ROCA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1503,
    localidad: "COLONIA LA ORTIZ",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1504,
    localidad: "COLONIA LA BONARIA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1505,
    localidad: "COLONIA FELIPE",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1506,
    localidad: "COLONIA FARA",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 1507,
    localidad: "COLONIA EL TARCO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1508,
    localidad: "COLONIA EL SUNCHAL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1509,
    localidad: "COLONIA BASCARY",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1510,
    localidad: "COLONIA ARGENTINA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1511,
    localidad: "COLONIA AGRICOLA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1512,
    localidad: "COLONIA ACEVEDO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1513,
    localidad: "COLONIA 6",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1514,
    localidad: "COLOMBRES",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1515,
    localidad: "COLMENA LOLITA",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 1516,
    localidad: "COHIGAC",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1517,
    localidad: "COCO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1518,
    localidad: "COCHUNA",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1519,
    localidad: "COCHAMOLLE",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1520,
    localidad: "CIUDACITA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1521,
    localidad: "CIENAGUITA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1522,
    localidad: "CHUSCA",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1523,
    localidad: "CHURQUI",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1524,
    localidad: "CHULCA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1525,
    localidad: "CHORRILOS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1526,
    localidad: "CHORRILLOS",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1527,
    localidad: "CHOROMORO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1528,
    localidad: "CHILCAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1529,
    localidad: "CHILCAR",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1530,
    localidad: "CHILCAR",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1531,
    localidad: "CHILCAL",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1532,
    localidad: "CHILCA",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1533,
    localidad: "CHICLIGASTA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1534,
    localidad: "CHAVARRIA",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 1535,
    localidad: "CHASQUIVIL",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1536,
    localidad: "CHARCO VIEJO",
    cp: 4184,
    provincia_id: 24,
  },
  {
    id: 1537,
    localidad: "CHAMICO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1538,
    localidad: "CHALCHACITO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1539,
    localidad: "CHABELA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1540,
    localidad: "CEVILARCITO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1541,
    localidad: "CEVILARCITO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1542,
    localidad: "CEVIL SOLO",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 1543,
    localidad: "CEVIL REDONDO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1544,
    localidad: "CEVIL GRANDE",
    cp: 4155,
    provincia_id: 24,
  },
  {
    id: 1545,
    localidad: "CERVALITO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1546,
    localidad: "CERRO COLORADO",
    cp: 4139,
    provincia_id: 24,
  },
  {
    id: 1547,
    localidad: "CEJAS DE AROCA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1548,
    localidad: "CASTILLAS",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1549,
    localidad: "CASPINCHANGO",
    cp: 4135,
    provincia_id: 24,
  },
  {
    id: 1550,
    localidad: "CASPICHANGO VIEJO",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1551,
    localidad: "CASAS VIEJAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1552,
    localidad: "CASAS VIEJAS",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1553,
    localidad: "CASALES",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1554,
    localidad: "CASA VIEJA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1555,
    localidad: "CASA ROSADA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1556,
    localidad: "CASA DEL ALTO",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1557,
    localidad: "CASA DEL ALTO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1558,
    localidad: "CASA DE ZINC",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1559,
    localidad: "CASA DE PIEDRAS",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1560,
    localidad: "CASA DE PIEDRA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1561,
    localidad: "CASA DE CAMPO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1562,
    localidad: "CARRICHANGO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1563,
    localidad: "CARRETA QUEMADA",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1564,
    localidad: "CAROLINAS BAJAS",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1565,
    localidad: "CARBON POZO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1566,
    localidad: "CARAPUNCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1567,
    localidad: "CARANCHO POZO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1568,
    localidad: "CAPITAN CACERES",
    cp: 4160,
    provincia_id: 24,
  },
  {
    id: 1569,
    localidad: "CAPITAN CACERES",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1570,
    localidad: "CAPILLA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1571,
    localidad: "CAÑADA DE ALZOGARAY",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1572,
    localidad: "CANDELILLAL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1573,
    localidad: "CANDELILLAL",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1574,
    localidad: "CAMPO ZAUZAL",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1575,
    localidad: "CAMPO VOLANTE",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1576,
    localidad: "CAMPO REDONDO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1577,
    localidad: "CAMPO REDONDO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1578,
    localidad: "CAMPO REDONDO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1579,
    localidad: "CAMPO LA FLOR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1580,
    localidad: "CAMPO LA CRUZ",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1581,
    localidad: "CAMPO HERRERA",
    cp: 4110,
    provincia_id: 24,
  },
  {
    id: 1582,
    localidad: "CAMPO GRANDE",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 1583,
    localidad: "CAMPO EL MOLLAR",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1584,
    localidad: "CAMPO EL LUISITO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1585,
    localidad: "CAMPO DE TALAMAYO",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1586,
    localidad: "CAMPO BLANCO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1587,
    localidad: "CAMPO BELLO",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 1588,
    localidad: "CAMPO AZUL",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1589,
    localidad: "CAMPO AZUL",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1590,
    localidad: "CAMPANA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1591,
    localidad: "CAMPANA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1592,
    localidad: "CAMINO DEL PERU",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1593,
    localidad: "CAMAS AMONTONADAS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1594,
    localidad: "CAMAS AMONTONADAS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1595,
    localidad: "CALIMAYO",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1596,
    localidad: "CALIFORNIA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1597,
    localidad: "CALERA ACONQUIJA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1598,
    localidad: "CAJAS VIEJAS",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1599,
    localidad: "CACHIYACO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1600,
    localidad: "CACHI YACO",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1601,
    localidad: "CACHI YACO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1602,
    localidad: "CACHI YACO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1603,
    localidad: "CACHI HUASI",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1604,
    localidad: "CACHI HUASI",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1605,
    localidad: "C Y APEADERO FCGB",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1606,
    localidad: "C SANTA MARINA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1607,
    localidad: "C SANTA LUCIA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1608,
    localidad: "C SANTA CLARA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1609,
    localidad: "C S LS COCHAMOLLES",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1610,
    localidad: "C P LEON CORNET",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 1611,
    localidad: "C NUEVA TRINIDAD",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1612,
    localidad: "C MARIA LUISA",
    cp: 4300,
    provincia_id: 24,
  },
  {
    id: 1613,
    localidad: "C MARIA ELENA",
    cp: 4129,
    provincia_id: 24,
  },
  {
    id: 1614,
    localidad: "C LOS CHASALES",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1615,
    localidad: "C LOLITA NORTE",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 1616,
    localidad: "C LA F LOS RALOS",
    cp: 4182,
    provincia_id: 24,
  },
  {
    id: 1617,
    localidad: "C LA ESPERANZA",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1618,
    localidad: "C J J IRAMAIN",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 1619,
    localidad: "C HUMAITA PRIMERA",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1620,
    localidad: "C DE LOS NEGROS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1621,
    localidad: "C DE LOS CARDONES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1622,
    localidad: "C DE LAS GALLINAS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1623,
    localidad: "C DE LA CHILCA",
    cp: 4153,
    provincia_id: 24,
  },
  {
    id: 1624,
    localidad: "C DE CHIRIMAYO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1625,
    localidad: "BURRUYACU",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1626,
    localidad: "BUENA YERBA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1627,
    localidad: "BUENA VISTA OESTE",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1628,
    localidad: "BUENA VISTA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1629,
    localidad: "BUENA VISTA",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1630,
    localidad: "BUEN RETIRO",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1631,
    localidad: "BOCA DEL TIGRE",
    cp: 4184,
    provincia_id: 24,
  },
  {
    id: 1632,
    localidad: "BOCA DEL TIGRE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1633,
    localidad: "BOBA YACU",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1634,
    localidad: "BLANCO POZO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1635,
    localidad: "BJO DE LOS SUELDOS",
    cp: 4151,
    provincia_id: 24,
  },
  {
    id: 1636,
    localidad: "BILCA POZO",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1637,
    localidad: "BENJAMIN PAZ",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1638,
    localidad: "BENJAMIN PAZ",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1639,
    localidad: "BENJAMIN ARAOZ",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1640,
    localidad: "BELTRAN",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1641,
    localidad: "BELLO HORIZONTE",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1642,
    localidad: "BELLA VISTA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1643,
    localidad: "BELICHA HUAICO",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1644,
    localidad: "BATIRUANA",
    cp: 4162,
    provincia_id: 24,
  },
  {
    id: 1645,
    localidad: "BARRIO TEXTIL",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1646,
    localidad: "BARRIO RIVADAVIA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1647,
    localidad: "BARRIO ESTE",
    cp: 4300,
    provincia_id: 24,
  },
  {
    id: 1648,
    localidad: "BARRIO DIAGONAL",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1649,
    localidad: "BARRIO CASINO",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 1650,
    localidad: "BARRIO BELGRANO",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 1651,
    localidad: "BARRIO BELGRANO",
    cp: 4146,
    provincia_id: 24,
  },
  {
    id: 1652,
    localidad: "BARREALITO",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1653,
    localidad: "BARBORIN",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1654,
    localidad: "BARADERO",
    cp: 4126,
    provincia_id: 24,
  },
  {
    id: 1655,
    localidad: "BANDA DEL RIO SALI",
    cp: 4109,
    provincia_id: 24,
  },
  {
    id: 1656,
    localidad: "BANDA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1657,
    localidad: "BALDERRAMA SUR",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1658,
    localidad: "BALDERRAMA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1659,
    localidad: "BAJO GRANDE",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1660,
    localidad: "BAJO GRANDE",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1661,
    localidad: "BAJO DE RACO",
    cp: 4103,
    provincia_id: 24,
  },
  {
    id: 1662,
    localidad: "BAJASTINE",
    cp: 4164,
    provincia_id: 24,
  },
  {
    id: 1663,
    localidad: "B ZORRILLA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1664,
    localidad: "B COLORADAS",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1665,
    localidad: "AVESTILLA",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1666,
    localidad: "ATAHONA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1667,
    localidad: "ASNA YACO",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1668,
    localidad: "ASERRADERO",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1669,
    localidad: "ARROYO MAL PASO",
    cp: 4157,
    provincia_id: 24,
  },
  {
    id: 1670,
    localidad: "ARROYO DE LA CRUZ",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1671,
    localidad: "ARROYO BARRIENTO",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1672,
    localidad: "ARROYO ATAHONA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1673,
    localidad: "AROCAS",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1674,
    localidad: "ARENILLA",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 1675,
    localidad: "ARCADIA",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 1676,
    localidad: "ARBOL SOLO",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1677,
    localidad: "ARAOZ",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1678,
    localidad: "ARANILLA",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 1679,
    localidad: "ARAN",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1680,
    localidad: "ARAGONES",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1681,
    localidad: "ARAGON",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1682,
    localidad: "ANTU MAPU",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1683,
    localidad: "ANTILLAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1684,
    localidad: "ANTIGUO QUILMES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1685,
    localidad: "ANTAMA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1686,
    localidad: "ANTA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1687,
    localidad: "ANIMAS",
    cp: 4184,
    provincia_id: 24,
  },
  {
    id: 1688,
    localidad: "ANGOSTURA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1689,
    localidad: "ANGOSTURA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1690,
    localidad: "ANFANA",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1691,
    localidad: "ANEGADOS",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1692,
    localidad: "ANDRES FERREYRA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1693,
    localidad: "ANDAHUALA",
    cp: 4139,
    provincia_id: 24,
  },
  {
    id: 1694,
    localidad: "ANCAJULLI",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1695,
    localidad: "AMUNPA",
    cp: 4176,
    provincia_id: 24,
  },
  {
    id: 1696,
    localidad: "AMPIMPA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1697,
    localidad: "AMPATILLA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1698,
    localidad: "AMPATA",
    cp: 4174,
    provincia_id: 24,
  },
  {
    id: 1699,
    localidad: "AMBERES",
    cp: 4144,
    provincia_id: 24,
  },
  {
    id: 1700,
    localidad: "AMAICHA DEL VALLE",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1701,
    localidad: "AMAICHA DEL LLANO",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1702,
    localidad: "ALURRALDE",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1703,
    localidad: "ALTO VERDE",
    cp: 4153,
    provincia_id: 24,
  },
  {
    id: 1704,
    localidad: "ALTO VERDE",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1705,
    localidad: "ALTO VERDE",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1706,
    localidad: "ALTO LOS CARDONES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1707,
    localidad: "ALTO LAS LECHUZAS",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1708,
    localidad: "ALTO LAS LECHUZAS",
    cp: 4147,
    provincia_id: 24,
  },
  {
    id: 1709,
    localidad: "ALTO LAS FLORES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1710,
    localidad: "ALTO LA TOTORA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1711,
    localidad: "ALTO EL PUESTO",
    cp: 4159,
    provincia_id: 24,
  },
  {
    id: 1712,
    localidad: "ALTO DEL LAMPAZO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1713,
    localidad: "ALTO DEL HUASCHO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1714,
    localidad: "ALTO DE MEDINA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1715,
    localidad: "ALTO DE LOS REALES",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1716,
    localidad: "ALTO DE LEIVA",
    cp: 4142,
    provincia_id: 24,
  },
  {
    id: 1717,
    localidad: "ALTO DE ANFAMA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1718,
    localidad: "ALTO CAZADERA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1719,
    localidad: "ALPACHIRI",
    cp: 4149,
    provincia_id: 24,
  },
  {
    id: 1720,
    localidad: "ALONGO",
    cp: 4161,
    provincia_id: 24,
  },
  {
    id: 1721,
    localidad: "ALIZAL",
    cp: 4122,
    provincia_id: 24,
  },
  {
    id: 1722,
    localidad: "ALISOS",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1723,
    localidad: "ALBERDI",
    cp: 4158,
    provincia_id: 24,
  },
  {
    id: 1724,
    localidad: "ALABAMA",
    cp: 4117,
    provincia_id: 24,
  },
  {
    id: 1725,
    localidad: "AHI VEREMOS",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1726,
    localidad: "AGUILARES",
    cp: 4152,
    provincia_id: 24,
  },
  {
    id: 1727,
    localidad: "AGUAS BLANCAS",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1728,
    localidad: "AGUADITA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1729,
    localidad: "AGUADITA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1730,
    localidad: "AGUADA DE JORGE",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1731,
    localidad: "AGUADA",
    cp: 4111,
    provincia_id: 24,
  },
  {
    id: 1732,
    localidad: "AGUADA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1733,
    localidad: "AGUA SALADA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1734,
    localidad: "AGUA SALADA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1735,
    localidad: "AGUA ROSADA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1736,
    localidad: "AGUA NEGRA",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1737,
    localidad: "AGUA EL SIMBO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1738,
    localidad: "AGUA DULCE",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1739,
    localidad: "AGUA DULCE",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1740,
    localidad: "AGUA DE CASTILLA",
    cp: 4172,
    provincia_id: 24,
  },
  {
    id: 1741,
    localidad: "AGUA COLORADA",
    cp: 4119,
    provincia_id: 24,
  },
  {
    id: 1742,
    localidad: "AGUA BLANCA",
    cp: 4168,
    provincia_id: 24,
  },
  {
    id: 1743,
    localidad: "AGUA BLANCA",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1744,
    localidad: "AGUA AZUL",
    cp: 4115,
    provincia_id: 24,
  },
  {
    id: 1745,
    localidad: "AGUA AZUL",
    cp: 4132,
    provincia_id: 24,
  },
  {
    id: 1746,
    localidad: "ACOSTILLA",
    cp: 4113,
    provincia_id: 24,
  },
  {
    id: 1747,
    localidad: "ACONQUIJA",
    cp: 4107,
    provincia_id: 24,
  },
  {
    id: 1748,
    localidad: "ACHERAL",
    cp: 4134,
    provincia_id: 24,
  },
  {
    id: 1749,
    localidad: "ACEQUIONES",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1750,
    localidad: "ABRA RICA",
    cp: 4178,
    provincia_id: 24,
  },
  {
    id: 1751,
    localidad: "ABRA EL CANDADO",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1752,
    localidad: "ABRA DEL TAFI",
    cp: 4105,
    provincia_id: 24,
  },
  {
    id: 1753,
    localidad: "ABRA DE YARETA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1754,
    localidad: "ABRA DE LA PICAZA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1755,
    localidad: "ABRA BAYA",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1756,
    localidad: "A G D V BURRUYACU",
    cp: 4101,
    provincia_id: 24,
  },
  {
    id: 1757,
    localidad: "A DEL INFIERNILLO",
    cp: 4137,
    provincia_id: 24,
  },
  {
    id: 1758,
    localidad: "A DE LOS GIMENEZ",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1759,
    localidad: "A DE LA ANGOSTURA",
    cp: 4124,
    provincia_id: 24,
  },
  {
    id: 1760,
    localidad: "9 DE JULIO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1761,
    localidad: "7 DE ABRIL",
    cp: 4194,
    provincia_id: 24,
  },
  {
    id: 1762,
    localidad: "3 FLORES",
    cp: 4184,
    provincia_id: 24,
  },
  {
    id: 1763,
    localidad: "25 DE MAYO",
    cp: 4242,
    provincia_id: 24,
  },
  {
    id: 1764,
    localidad: "USHUAIA",
    cp: 9410,
    provincia_id: 23,
  },
  {
    id: 1765,
    localidad: "USHUAIA",
    cp: 9413,
    provincia_id: 23,
  },
  {
    id: 1766,
    localidad: "TOLHUIN",
    cp: 9412,
    provincia_id: 23,
  },
  {
    id: 1767,
    localidad: "SANTA INES",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1768,
    localidad: "SAN SEBASTIAN",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1769,
    localidad: "RIO GRANDE",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1770,
    localidad: "PUERTO S ANT ESTE",
    cp: 8524,
    provincia_id: 23,
  },
  {
    id: 1771,
    localidad: "PUERTO LOBOS",
    cp: 8532,
    provincia_id: 23,
  },
  {
    id: 1772,
    localidad: "PUERTO ARGENTINO",
    cp: 9409,
    provincia_id: 23,
  },
  {
    id: 1773,
    localidad: "LAS MALVINAS",
    cp: 2046,
    provincia_id: 23,
  },
  {
    id: 1774,
    localidad: "LA FRATERNIDAD",
    cp: 2045,
    provincia_id: 23,
  },
  {
    id: 1775,
    localidad: "FRIGORIFICO CAP",
    cp: 9421,
    provincia_id: 23,
  },
  {
    id: 1776,
    localidad: "FRIGORIFICO CAP",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1777,
    localidad: "ESTANCIA VIAMONTE",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1778,
    localidad: "ESTANCIA SANTA ANA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1779,
    localidad: "ESTANCIA SAN JOSE",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1780,
    localidad: "ESTANCIA ROSITA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1781,
    localidad: "ESTANCIA ROLITO",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1782,
    localidad: "ESTANCIA PIRINAICA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1783,
    localidad: "ESTANCIA LIBERTAD",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1784,
    localidad: "ESTANCIA LAURA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1785,
    localidad: "ESTANCIA EL ROBLE",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1786,
    localidad: "ESTANCIA DESPEDIDA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1787,
    localidad: "ESTANCIA COSTANCIA",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1788,
    localidad: "EMPALME P LOBOS",
    cp: 8532,
    provincia_id: 23,
  },
  {
    id: 1789,
    localidad: "CERRO RADAL",
    cp: 9431,
    provincia_id: 23,
  },
  {
    id: 1790,
    localidad: "CAMPANA MAHUIDA",
    cp: 8532,
    provincia_id: 23,
  },
  {
    id: 1791,
    localidad: "CAMP CENTRAL Y P F",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1792,
    localidad: "C L CHORRILLOS Y P",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1793,
    localidad: "BARRIO SAN VICENTE DE PAUL",
    cp: 9412,
    provincia_id: 23,
  },
  {
    id: 1794,
    localidad: "ASERRADERO ARROYO",
    cp: 9420,
    provincia_id: 23,
  },
  {
    id: 1795,
    localidad: "ARROYO VERDE",
    cp: 8532,
    provincia_id: 23,
  },
  {
    id: 1796,
    localidad: "ARROYO SALADO",
    cp: 8532,
    provincia_id: 23,
  },
  {
    id: 1797,
    localidad: "ANTARTIDA E ISLAS DEL SUR",
    cp: 9411,
    provincia_id: 23,
  },
  {
    id: 1798,
    localidad: "ZUBERBUHLER",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 1799,
    localidad: "ZAPI POZO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 1800,
    localidad: "ZANJON",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1801,
    localidad: "ZANJA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 1802,
    localidad: "YUTU YACO",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 1803,
    localidad: "YUTO YACA",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 1804,
    localidad: "YUNTA POZO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 1805,
    localidad: "YUMAMPA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 1806,
    localidad: "YULU HUASI",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1807,
    localidad: "YUCHANCITO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 1808,
    localidad: "YUCHAN",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 1809,
    localidad: "YOLOHUASI",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1810,
    localidad: "YLUMAMPA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 1811,
    localidad: "YESO ALTO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1812,
    localidad: "YASO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 1813,
    localidad: "YANTA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 1814,
    localidad: "YANDA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1815,
    localidad: "YALAN",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 1816,
    localidad: "YALAN",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1817,
    localidad: "YACUCHIRI",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1818,
    localidad: "YACUCHIRI",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 1819,
    localidad: "YACU HURMANA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 1820,
    localidad: "YACU HURMANA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 1821,
    localidad: "YACU HICHACUNA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1822,
    localidad: "YACO MISQUI",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 1823,
    localidad: "YACASNIOJ",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 1824,
    localidad: "YACANO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 1825,
    localidad: "WIÑANO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 1826,
    localidad: "WIÑANO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 1827,
    localidad: "WEISBURD",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 1828,
    localidad: "VTA D LA BARRANCA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1829,
    localidad: "VIZCACHERAL",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1830,
    localidad: "VIVA MERCEDES",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1831,
    localidad: "VITEACA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1832,
    localidad: "VIRGINIA",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 1833,
    localidad: "VINARA",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 1834,
    localidad: "VINAL VIEJO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1835,
    localidad: "VINAL POZO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 1836,
    localidad: "VILMER",
    cp: 4303,
    provincia_id: 22,
  },
  {
    id: 1837,
    localidad: "VILLARES",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 1838,
    localidad: "VILLANI",
    cp: 2400,
    provincia_id: 22,
  },
  {
    id: 1839,
    localidad: "VILLA ZANJON",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1840,
    localidad: "VILLA YOLANDA",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 1841,
    localidad: "VILLA UNION",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 1842,
    localidad: "VILLA UNION",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 1843,
    localidad: "VILLA TOLOJNA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 1844,
    localidad: "VILLA SAN ANTONIO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1845,
    localidad: "VILLA ROBLES",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1846,
    localidad: "VILLA RIO HONDO",
    cp: 4226,
    provincia_id: 22,
  },
  {
    id: 1847,
    localidad: "VILLA QUEBRACHOS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 1848,
    localidad: "VILLA PUJIO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 1849,
    localidad: "VILLA PALMAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1850,
    localidad: "VILLA OJO DE AGUA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 1851,
    localidad: "VILLA NUEVA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1852,
    localidad: "VILLA NUEVA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1853,
    localidad: "VILLA MONTE CRISTO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1854,
    localidad: "VILLA MERCEDES",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 1855,
    localidad: "VILLA MERCEDES",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1856,
    localidad: "VILLA MATILDE",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 1857,
    localidad: "VILLA MATARA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 1858,
    localidad: "VILLA MARIA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1859,
    localidad: "VILLA LA TUNA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1860,
    localidad: "VILLA LA SOLEDAD",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1861,
    localidad: "VILLA LA PUNTA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1862,
    localidad: "VILLA JIMENEZ",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 1863,
    localidad: "VILLA ISLA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 1864,
    localidad: "VILLA HIPOLITA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1865,
    localidad: "VILLA GUASAYAN",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 1866,
    localidad: "VILLA GUAÑUNA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 1867,
    localidad: "VILLA GUAÑUNA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1868,
    localidad: "VILLA FIGUEROA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1869,
    localidad: "VILLA FANNY",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 1870,
    localidad: "VILLA ESQUINA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 1871,
    localidad: "VILLA ELVIRA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 1872,
    localidad: "VILLA ELENA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1873,
    localidad: "VILLA EL RETIRO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1874,
    localidad: "VILLA EL BACHE",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1875,
    localidad: "VILLA CONSTANTINA",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 1876,
    localidad: "VILLA COLMENA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1877,
    localidad: "VILLA COINOR",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 1878,
    localidad: "VILLA BRANA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 1879,
    localidad: "VILLA BALNEARIA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 1880,
    localidad: "VILLA ATAMISQUI",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 1881,
    localidad: "VILLA ADELA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 1882,
    localidad: "VILLA ABREGU",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 1883,
    localidad: "VILELAS",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 1884,
    localidad: "VILELAS",
    cp: 3753,
    provincia_id: 22,
  },
  {
    id: 1885,
    localidad: "VILA ISLA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 1886,
    localidad: "VIBORAS",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 1887,
    localidad: "VERON",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1888,
    localidad: "VENTURA PAMPA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 1889,
    localidad: "VARAS CUCHUNA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1890,
    localidad: "VALDIVIA",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 1891,
    localidad: "VACA HUMAN",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1892,
    localidad: "VACA HUAÑUNA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1893,
    localidad: "V S R DE NVA TRIN",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1894,
    localidad: "V DESIERTO DE LUZ",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1895,
    localidad: "V DE LA BARRACA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1896,
    localidad: "UTURUNO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1897,
    localidad: "UTURUNCO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1898,
    localidad: "UTRUNJOS",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1899,
    localidad: "URUTAU",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 1900,
    localidad: "URUNDEL",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 1901,
    localidad: "URUNDEL",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 1902,
    localidad: "URUGUAY",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 1903,
    localidad: "URIZAR",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1904,
    localidad: "UPIANITA",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 1905,
    localidad: "UPIANITA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1906,
    localidad: "UCLAR",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1907,
    localidad: "TUSCAL REDONDO",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 1908,
    localidad: "TUSCAJOJ",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1909,
    localidad: "TUSCA POZO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1910,
    localidad: "TUSCA POZO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1911,
    localidad: "TUSCA POZO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1912,
    localidad: "TUSCA POZO",
    cp: 4212,
    provincia_id: 22,
  },
  {
    id: 1913,
    localidad: "TUSCA POZO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 1914,
    localidad: "TUSCA BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1915,
    localidad: "TURENA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 1916,
    localidad: "TUNAS PUNCO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1917,
    localidad: "TUNALES",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 1918,
    localidad: "TUNALES",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 1919,
    localidad: "TULUN",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1920,
    localidad: "TULUM",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1921,
    localidad: "TROZO POZO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 1922,
    localidad: "TRONCOS QUEMADOS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 1923,
    localidad: "TRONCO QUEMADO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 1924,
    localidad: "TRONCO JURAS",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 1925,
    localidad: "TRONCO BLANCO",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 1926,
    localidad: "TRONCAL",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 1927,
    localidad: "TRINIDAD",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 1928,
    localidad: "TRES VARONES",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 1929,
    localidad: "TRES QUEBRACHOS",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 1930,
    localidad: "TRES POZOS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 1931,
    localidad: "TRES POZOS",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 1932,
    localidad: "TRES POZOS",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 1933,
    localidad: "TRES MOJONES",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 1934,
    localidad: "TRES LAGUNAS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 1935,
    localidad: "TRES LAGUNAS",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 1936,
    localidad: "TRES JAZMINES",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1937,
    localidad: "TRES HERMANAS",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 1938,
    localidad: "TRES FLORES",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1939,
    localidad: "TRES DE MARZO",
    cp: 3763,
    provincia_id: 22,
  },
  {
    id: 1940,
    localidad: "TRES CRUCES",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 1941,
    localidad: "TRES CHAÑARES",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 1942,
    localidad: "TRES CHAÑARES",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 1943,
    localidad: "TRES CERROS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 1944,
    localidad: "TRES BAJOS",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 1945,
    localidad: "TRES BAJADAS",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 1946,
    localidad: "TRES BAJADAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 1947,
    localidad: "TRASLADO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1948,
    localidad: "TRANCAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 1949,
    localidad: "TRANCAS",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 1950,
    localidad: "TRAMO VEINTISEIS",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 1951,
    localidad: "TRAMO 20",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 1952,
    localidad: "TRAMO 16",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1953,
    localidad: "TOTORILLA NUEVO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 1954,
    localidad: "TOTORAS",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1955,
    localidad: "TOTORA PAMPA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1956,
    localidad: "TOTORA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1957,
    localidad: "TOROPAN",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1958,
    localidad: "TORO POZO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 1959,
    localidad: "TORO POZO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 1960,
    localidad: "TORO PAMPA",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 1961,
    localidad: "TORO HUMAN",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 1962,
    localidad: "TORO CHARQUINA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1963,
    localidad: "TONZU",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 1964,
    localidad: "TONTOLA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1965,
    localidad: "TOME Y TRAIGA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 1966,
    localidad: "TOMAS YOUNG",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 1967,
    localidad: "TOLOZAS",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1968,
    localidad: "TIUN PUNCO",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 1969,
    localidad: "TIPIRO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 1970,
    localidad: "TIPIRO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 1971,
    localidad: "TIO POZO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 1972,
    localidad: "TIO CHACRA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1973,
    localidad: "TIO ALTO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 1974,
    localidad: "TINTINA",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 1975,
    localidad: "TINCO COLONIA",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 1976,
    localidad: "TINAP JERAYOJ",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 1977,
    localidad: "TINAJEROS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 1978,
    localidad: "TINAJERALOJ",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 1979,
    localidad: "TIGRE MUERTO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 1980,
    localidad: "TIESTITUYOS",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 1981,
    localidad: "TIBILAR",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 1982,
    localidad: "TERMAS DE RIO HONDO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 1983,
    localidad: "TERMA DE RIO HONDO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 1984,
    localidad: "TENTI TACO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 1985,
    localidad: "TENENE",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 1986,
    localidad: "TARUY",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 1987,
    localidad: "TARPUNA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 1988,
    localidad: "TAQUETUYOJ",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1989,
    localidad: "TAQUELLO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 1990,
    localidad: "TAPERAS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 1991,
    localidad: "TAPERAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 1992,
    localidad: "TALA YACU",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 1993,
    localidad: "TALA SACHA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 1994,
    localidad: "TALA POZO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 1995,
    localidad: "TALA POZO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 1996,
    localidad: "TALA POZO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 1997,
    localidad: "TALA CAIDA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 1998,
    localidad: "TALA ATUN",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 1999,
    localidad: "TALA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2000,
    localidad: "TALA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2001,
    localidad: "TAJAMAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2002,
    localidad: "TAGAN",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2003,
    localidad: "TACUARITAS",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 2004,
    localidad: "TACOYOJ",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2005,
    localidad: "TACON ESQUINA",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 2006,
    localidad: "TACO TOTARAYOL",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2007,
    localidad: "TACO SUYO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2008,
    localidad: "TACO QUINKA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2009,
    localidad: "TACO PUNCO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2010,
    localidad: "TACO PUJIO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2011,
    localidad: "TACO POZO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2012,
    localidad: "TACO POZO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2013,
    localidad: "TACO POZO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2014,
    localidad: "TACO POZO",
    cp: 3717,
    provincia_id: 22,
  },
  {
    id: 2015,
    localidad: "TACO POZO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2016,
    localidad: "TACO POZO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2017,
    localidad: "TACO PALTA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2018,
    localidad: "TACO MISQUI",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2019,
    localidad: "TACO ISLA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2020,
    localidad: "TACO HUACO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2021,
    localidad: "TACO FURA",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 2022,
    localidad: "TACO BAJADA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2023,
    localidad: "TACO ATUN",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2024,
    localidad: "TACAÑITAS",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 2025,
    localidad: "TACANITAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2026,
    localidad: "TACANITAS",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2027,
    localidad: "TACANILLAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2028,
    localidad: "TACANAS",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2029,
    localidad: "TACAMAMPA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2030,
    localidad: "TABOADA ESTACION",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2031,
    localidad: "TABLEADO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2032,
    localidad: "TABIANA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2033,
    localidad: "TABEANITA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2034,
    localidad: "T DEL BOQUERON",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2035,
    localidad: "SURIHUAYA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2036,
    localidad: "SURI POZO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2037,
    localidad: "SURI POZO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2038,
    localidad: "SURI POZO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2039,
    localidad: "SURI",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2040,
    localidad: "SUPERINT. LEDESMA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2041,
    localidad: "SUNCHO PUJIO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2042,
    localidad: "SUNCHO PUJIO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2043,
    localidad: "SUNCHO POZO",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 2044,
    localidad: "SUNCHO POZO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2045,
    localidad: "SUNCHO POZO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2046,
    localidad: "SUNCHO CORRAL",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2047,
    localidad: "SUNCHITUYOJ",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 2048,
    localidad: "SUMAMPA VIEJO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2049,
    localidad: "SUMAMPA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2050,
    localidad: "SUMAMAO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2051,
    localidad: "STIAGO DEL ESTERO",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 2052,
    localidad: "STAYLE",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2053,
    localidad: "SOTELOS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 2054,
    localidad: "SOTELILLOS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 2055,
    localidad: "SORIA BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2056,
    localidad: "SOLEDAD",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2057,
    localidad: "SOLEDAD",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2058,
    localidad: "SOLARI MNO I LOZA",
    cp: 3476,
    provincia_id: 22,
  },
  {
    id: 2059,
    localidad: "SOL DE MAYO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2060,
    localidad: "SOL DE MAYO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2061,
    localidad: "SOL DE MAYO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2062,
    localidad: "SOL DE MAYO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2063,
    localidad: "SOL DE MAYO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2064,
    localidad: "SOL DE JULIO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2065,
    localidad: "SOCONCHO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2066,
    localidad: "SINQUEN PUNCO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2067,
    localidad: "SINCHI CAÑA   E",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2068,
    localidad: "SIN DESCANSO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2069,
    localidad: "SIMBOLAR",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2070,
    localidad: "SIMBOLAR",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2071,
    localidad: "SIMBOLAR",
    cp: 4340,
    provincia_id: 22,
  },
  {
    id: 2072,
    localidad: "SIMBOLAR",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2073,
    localidad: "SIMBOL POZO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2074,
    localidad: "SIMBOL POZO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2075,
    localidad: "SIMBOL POZO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2076,
    localidad: "SIMBOL HUASI",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2077,
    localidad: "SIMBOL CAÑADA",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2078,
    localidad: "SIMBOL",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2079,
    localidad: "SILIPICA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2080,
    localidad: "SIETE ARBOLES",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2081,
    localidad: "SIEMPRE VERDE",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2082,
    localidad: "SHISHI POZO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2083,
    localidad: "SESTEADERO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2084,
    localidad: "SERRANO MUERTO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2085,
    localidad: "SEPULTURAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2086,
    localidad: "SEPULTURAS",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2087,
    localidad: "SEPULTURA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2088,
    localidad: "SEÑORA PUJIO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2089,
    localidad: "SEÑORA PUJIO",
    cp: 4339,
    provincia_id: 22,
  },
  {
    id: 2090,
    localidad: "SELVA BLANCA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 2091,
    localidad: "SELVA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2092,
    localidad: "SEGUNDO POZO",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2093,
    localidad: "SAYACO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2094,
    localidad: "SAUZAL",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2095,
    localidad: "SAUZAL",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2096,
    localidad: "SAUCIOJ",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2097,
    localidad: "SAUCES",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2098,
    localidad: "SAUCEN",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2099,
    localidad: "SAUCE SOLO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2100,
    localidad: "SAUCE SOLO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2101,
    localidad: "SAUCE ESQUINA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2102,
    localidad: "SAUCE BAJADA",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2103,
    localidad: "SARMIENTO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2104,
    localidad: "SANTOS LUGARES",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2105,
    localidad: "SANTOS LUGARES",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2106,
    localidad: "SANTOS LUGARES",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2107,
    localidad: "SANTO DOMINGO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2108,
    localidad: "SANTO DOMINGO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2109,
    localidad: "SANTO DOMINGO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2110,
    localidad: "SANTO DOMINGO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2111,
    localidad: "SANTO DOMINGO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2112,
    localidad: "SANTO DOMINGO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2113,
    localidad: "SANTO DOMINGO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2114,
    localidad: "SANTO DOMINGO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2115,
    localidad: "SANTILLAN",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2116,
    localidad: "SANTIAGO DEL ESTERO",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 2117,
    localidad: "SANTIAGO DEL ESTERO",
    cp: 4202,
    provincia_id: 22,
  },
  {
    id: 2118,
    localidad: "SANTIAGO DEL ESTERO",
    cp: 4204,
    provincia_id: 22,
  },
  {
    id: 2119,
    localidad: "SANTA ROSA COPO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2120,
    localidad: "SANTA ROSA  ARRAGA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2121,
    localidad: "SANTA ROSA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2122,
    localidad: "SANTA ROSA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2123,
    localidad: "SANTA ROSA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2124,
    localidad: "SANTA ROSA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2125,
    localidad: "SANTA ROSA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2126,
    localidad: "SANTA ROSA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2127,
    localidad: "SANTA ROSA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2128,
    localidad: "SANTA ROSA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2129,
    localidad: "SANTA ROSA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2130,
    localidad: "SANTA ROSA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2131,
    localidad: "SANTA ROSA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2132,
    localidad: "SANTA ROSA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2133,
    localidad: "SANTA ROSA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2134,
    localidad: "SANTA RITA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2135,
    localidad: "SANTA RITA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2136,
    localidad: "SANTA RITA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2137,
    localidad: "SANTA RITA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2138,
    localidad: "SANTA PAULA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2139,
    localidad: "SANTA MARIA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2140,
    localidad: "SANTA MARIA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2141,
    localidad: "SANTA MARIA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2142,
    localidad: "SANTA MARIA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2143,
    localidad: "SANTA MARIA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2144,
    localidad: "SANTA MARIA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2145,
    localidad: "SANTA MARIA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2146,
    localidad: "SANTA MARIA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2147,
    localidad: "SANTA MARIA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2148,
    localidad: "SANTA MARIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2149,
    localidad: "SANTA LUISA",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2150,
    localidad: "SANTA LUCIA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2151,
    localidad: "SANTA LUCIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2152,
    localidad: "SANTA LUCIA",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2153,
    localidad: "SANTA JUSTINA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2154,
    localidad: "SANTA ISABEL",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2155,
    localidad: "SANTA ISABEL",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2156,
    localidad: "SANTA ISABEL",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2157,
    localidad: "SANTA IRENE",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 2158,
    localidad: "SANTA INES",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2159,
    localidad: "SANTA FELISA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2160,
    localidad: "SANTA ELENA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2161,
    localidad: "SANTA ELENA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2162,
    localidad: "SANTA ELENA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2163,
    localidad: "SANTA ELENA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2164,
    localidad: "SANTA CRUZ",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2165,
    localidad: "SANTA CRUZ",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2166,
    localidad: "SANTA CRUZ",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2167,
    localidad: "SANTA CRUZ",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2168,
    localidad: "SANTA CRUZ",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2169,
    localidad: "SANTA CLARA SUD",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2170,
    localidad: "SANTA CATALINA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2171,
    localidad: "SANTA CATALINA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 2172,
    localidad: "SANTA BRIGIDA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2173,
    localidad: "SANTA BRIGIDA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2174,
    localidad: "SANTA BARBARA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2175,
    localidad: "SANTA ANA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2176,
    localidad: "SANTA ANA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2177,
    localidad: "SANTA ANA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2178,
    localidad: "SANTA ANA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2179,
    localidad: "SANTA ANA",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 2180,
    localidad: "SANTA AGUEDA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2181,
    localidad: "SANSIOJ",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2182,
    localidad: "SANDIA HUAJCHU",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2183,
    localidad: "SANAVIRONES",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 2184,
    localidad: "SAN VICENTE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2185,
    localidad: "SAN VICENTE",
    cp: 4212,
    provincia_id: 22,
  },
  {
    id: 2186,
    localidad: "SAN VICENTE",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2187,
    localidad: "SAN VICENTE",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2188,
    localidad: "SAN VICENTE",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2189,
    localidad: "SAN TELMO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2190,
    localidad: "SAN SIMON",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2191,
    localidad: "SAN SEBASTIAN",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2192,
    localidad: "SAN SEBASTIAN",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2193,
    localidad: "SAN SALVADOR",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2194,
    localidad: "SAN SALVADOR",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2195,
    localidad: "SAN RUFINO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2196,
    localidad: "SAN ROQUE",
    cp: 5266,
    provincia_id: 22,
  },
  {
    id: 2197,
    localidad: "SAN ROQUE",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2198,
    localidad: "SAN ROQUE",
    cp: 4320,
    provincia_id: 22,
  },
  {
    id: 2199,
    localidad: "SAN ROQUE",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2200,
    localidad: "SAN ROQUE",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2201,
    localidad: "SAN ROQUE",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2202,
    localidad: "SAN ROQUE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2203,
    localidad: "SAN ROMANO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2204,
    localidad: "SAN RAMON",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2205,
    localidad: "SAN RAMON",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2206,
    localidad: "SAN RAMON",
    cp: 4339,
    provincia_id: 22,
  },
  {
    id: 2207,
    localidad: "SAN RAMON",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2208,
    localidad: "SAN RAMON",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2209,
    localidad: "SAN RAMON",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2210,
    localidad: "SAN RAMON",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2211,
    localidad: "SAN RAMON",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2212,
    localidad: "SAN RAMON",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2213,
    localidad: "SAN RAMON",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2214,
    localidad: "SAN RAMON",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2215,
    localidad: "SAN PEDRO KM 49",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2216,
    localidad: "SAN PEDRO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2217,
    localidad: "SAN PEDRO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2218,
    localidad: "SAN PEDRO",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2219,
    localidad: "SAN PEDRO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2220,
    localidad: "SAN PEDRO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2221,
    localidad: "SAN PEDRO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2222,
    localidad: "SAN PEDRO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2223,
    localidad: "SAN PEDRO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2224,
    localidad: "SAN PEDRO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2225,
    localidad: "SAN PEDRO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2226,
    localidad: "SAN PEDRO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2227,
    localidad: "SAN PEDRO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2228,
    localidad: "SAN PEDRO",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2229,
    localidad: "SAN PATRICIO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2230,
    localidad: "SAN PASTOR",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2231,
    localidad: "SAN PASCUAL",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2232,
    localidad: "SAN PABLO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2233,
    localidad: "SAN PABLO",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2234,
    localidad: "SAN PABLO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2235,
    localidad: "SAN PABLO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2236,
    localidad: "SAN PABLO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2237,
    localidad: "SAN PABLO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2238,
    localidad: "SAN NICOLAS",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2239,
    localidad: "SAN NICOLAS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2240,
    localidad: "SAN NICOLAS",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2241,
    localidad: "SAN NICOLAS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2242,
    localidad: "SAN NICOLAS",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2243,
    localidad: "SAN NICOLAS",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2244,
    localidad: "SAN MIGUEL",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 2245,
    localidad: "SAN MIGUEL",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2246,
    localidad: "SAN MIGUEL",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2247,
    localidad: "SAN MIGUEL",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2248,
    localidad: "SAN MATEO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2249,
    localidad: "SAN MARTIN",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2250,
    localidad: "SAN MARTIN",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2251,
    localidad: "SAN MARTIN",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2252,
    localidad: "SAN MARTIN",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2253,
    localidad: "SAN MARTIN",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2254,
    localidad: "SAN MARTIN",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2255,
    localidad: "SAN MARTIN",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2256,
    localidad: "SAN MARTIN",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2257,
    localidad: "SAN MARCOS",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2258,
    localidad: "SAN MANUEL",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2259,
    localidad: "SAN LUIS",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2260,
    localidad: "SAN LUIS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2261,
    localidad: "SAN LUIS",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2262,
    localidad: "SAN LUIS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2263,
    localidad: "SAN LUIS",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2264,
    localidad: "SAN LUIS",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2265,
    localidad: "SAN LUIS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2266,
    localidad: "SAN LUIS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2267,
    localidad: "SAN LUIS",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2268,
    localidad: "SAN LUIS",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2269,
    localidad: "SAN LORENZO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2270,
    localidad: "SAN LORENZO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2271,
    localidad: "SAN LORENZO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2272,
    localidad: "SAN LORENZO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2273,
    localidad: "SAN LORENZO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2274,
    localidad: "SAN JUSTO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2275,
    localidad: "SAN JUANCITO",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2276,
    localidad: "SAN JUAN",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2277,
    localidad: "SAN JUAN",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2278,
    localidad: "SAN JUAN",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2279,
    localidad: "SAN JUAN",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2280,
    localidad: "SAN JUAN",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2281,
    localidad: "SAN JUAN",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2282,
    localidad: "SAN JOSE DEL BOQUERON",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2283,
    localidad: "SAN JOSE DE FLORES",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2284,
    localidad: "SAN JOSE",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2285,
    localidad: "SAN JOSE",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2286,
    localidad: "SAN JOSE",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2287,
    localidad: "SAN JOSE",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2288,
    localidad: "SAN JOSE",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2289,
    localidad: "SAN JOSE",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2290,
    localidad: "SAN JOSE",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2291,
    localidad: "SAN JOSE",
    cp: 4237,
    provincia_id: 22,
  },
  {
    id: 2292,
    localidad: "SAN JOSE",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2293,
    localidad: "SAN JOSE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2294,
    localidad: "SAN JOSE",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2295,
    localidad: "SAN JORGE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2296,
    localidad: "SAN JORGE",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2297,
    localidad: "SAN JORGE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2298,
    localidad: "SAN JERONIMO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2299,
    localidad: "SAN JAVIER",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2300,
    localidad: "SAN JAVIER",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2301,
    localidad: "SAN JAVIER",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2302,
    localidad: "SAN JAVIER",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2303,
    localidad: "SAN JAVIER",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2304,
    localidad: "SAN ISIDRO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2305,
    localidad: "SAN ISIDRO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2306,
    localidad: "SAN ISIDRO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2307,
    localidad: "SAN ISIDRO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2308,
    localidad: "SAN ISIDRO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2309,
    localidad: "SAN ISIDRO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2310,
    localidad: "SAN ISIDRO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2311,
    localidad: "SAN ISIDRO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2312,
    localidad: "SAN ISIDRO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2313,
    localidad: "SAN IGNACIO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2314,
    localidad: "SAN IGNACIO",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 2315,
    localidad: "SAN IGNACIO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2316,
    localidad: "SAN IGNACIO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2317,
    localidad: "SAN IGNACIO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2318,
    localidad: "SAN IGNACIO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2319,
    localidad: "SAN HORACIO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2320,
    localidad: "SAN GUILLERMO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2321,
    localidad: "SAN GREGORIO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2322,
    localidad: "SAN GREGORIO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2323,
    localidad: "SAN GREGORIO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2324,
    localidad: "SAN GREGORIO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2325,
    localidad: "SAN GERMAN",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 2326,
    localidad: "SAN FRANCISCO DEL CHAÑAR",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2327,
    localidad: "SAN FRANCISCO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2328,
    localidad: "SAN FRANCISCO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2329,
    localidad: "SAN FRANCISCO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2330,
    localidad: "SAN FRANCISCO",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2331,
    localidad: "SAN FERNANDO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2332,
    localidad: "SAN FELIX",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2333,
    localidad: "SAN FELIX",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2334,
    localidad: "SAN FELIPE",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2335,
    localidad: "SAN FELIPE",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2336,
    localidad: "SAN FEDERICO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2337,
    localidad: "SAN ENRIQUE",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2338,
    localidad: "SAN DIONISIO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2339,
    localidad: "SAN DIONISIO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2340,
    localidad: "SAN DELFIN",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2341,
    localidad: "SAN CRISTOBAL",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2342,
    localidad: "SAN COSME",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2343,
    localidad: "SAN CAYETANO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2344,
    localidad: "SAN CAYETANO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2345,
    localidad: "SAN CARLOS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 2346,
    localidad: "SAN CARLOS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2347,
    localidad: "SAN CARLOS",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2348,
    localidad: "SAN CARLOS",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2349,
    localidad: "SAN CARLOS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2350,
    localidad: "SAN CARLOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2351,
    localidad: "SAN CARLOS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2352,
    localidad: "SAN CARLOS",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2353,
    localidad: "SAN CARLOS",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2354,
    localidad: "SAN CARLOS",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2355,
    localidad: "SAN CARLOS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2356,
    localidad: "SAN BERNARDO B",
    cp: 4000,
    provincia_id: 22,
  },
  {
    id: 2357,
    localidad: "SAN BENITO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2358,
    localidad: "SAN BENITO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2359,
    localidad: "SAN BARTOLO",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2360,
    localidad: "SAN ARTURO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2361,
    localidad: "SAN ANTONIO",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 2362,
    localidad: "SAN ANTONIO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2363,
    localidad: "SAN ANTONIO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2364,
    localidad: "SAN ANTONIO",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 2365,
    localidad: "SAN ANTONIO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2366,
    localidad: "SAN ANTONIO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2367,
    localidad: "SAN ANTONIO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2368,
    localidad: "SAN ANTONIO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2369,
    localidad: "SAN ANTONIO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2370,
    localidad: "SAN ANDRES",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2371,
    localidad: "SAN ANDRES",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2372,
    localidad: "SAN ANDRES",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2373,
    localidad: "SAN ANDRES",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2374,
    localidad: "SAN ALBERTO",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2375,
    localidad: "SAN AGUSTIN",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2376,
    localidad: "SAN AGUSTIN",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2377,
    localidad: "SAN AGUSTIN",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2378,
    localidad: "SAN AGUSTIN",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2379,
    localidad: "SAN AGUSTIN",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2380,
    localidad: "SALVIAIOJ GAITAN",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2381,
    localidad: "SALINAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2382,
    localidad: "SALINAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2383,
    localidad: "SALDIVAR",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2384,
    localidad: "SALAVINA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2385,
    localidad: "SALADILLO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2386,
    localidad: "SALADILLO",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 2387,
    localidad: "SALADILLO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2388,
    localidad: "SALADILLO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2389,
    localidad: "SAINQUEN PUNCO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2390,
    localidad: "SACHAYOJ",
    cp: 3731,
    provincia_id: 22,
  },
  {
    id: 2391,
    localidad: "SABAGASTA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2392,
    localidad: "S TERESA D CARBALL",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2393,
    localidad: "S ROSA DE VITERVO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2394,
    localidad: "S ROSA DE CORONEL",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2395,
    localidad: "S ROSA DE CORONEL",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2396,
    localidad: "S RAMON QUEBRACHOS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2397,
    localidad: "S M DTO FIGUEROA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2398,
    localidad: "S M DEL MATARA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2399,
    localidad: "S M DE SOBREMONTE",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2400,
    localidad: "S M DE LAS CHACRAS",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2401,
    localidad: "S J DTO FIGUEROA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2402,
    localidad: "S J DEL BOQUERON",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2403,
    localidad: "S FRANCISCO LVLLE",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2404,
    localidad: "S F DEL CHAQAR",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2405,
    localidad: "S DOMINGO ROBLES",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2406,
    localidad: "S DOMINGO CHICO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2407,
    localidad: "S DGO PELLEGRINI",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2408,
    localidad: "S BARBARA FERREIRA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2409,
    localidad: "S ANTONIO DE COPO",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2410,
    localidad: "S ANT DE QUISCA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2411,
    localidad: "S ANT DE LAS FLOR",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2412,
    localidad: "S ANT D LS CACERES",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2413,
    localidad: "S ANT D L CACERES",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2414,
    localidad: "RUTA provincia_idL 8",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2415,
    localidad: "RUTA provincia_idL 5",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2416,
    localidad: "RUTA provincia_idL 40",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2417,
    localidad: "RUTA provincia_idL 17",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2418,
    localidad: "RUTA provincia_idL 11",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2419,
    localidad: "RUTA NACIONAL 34",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2420,
    localidad: "RUTA NACIONAL 157",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2421,
    localidad: "RUMIOS",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2422,
    localidad: "RUMI JACO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2423,
    localidad: "RUMI JACO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2424,
    localidad: "RUMI HUASI",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2425,
    localidad: "RUMI ESQUINA",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 2426,
    localidad: "RUMI COCHA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2427,
    localidad: "RUMI",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2428,
    localidad: "RUMI",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2429,
    localidad: "RUBIA MORENO",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2430,
    localidad: "ROVERSI",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 2431,
    localidad: "ROSIYULLOJ",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2432,
    localidad: "ROSARIO DE LA FRON",
    cp: 4190,
    provincia_id: 22,
  },
  {
    id: 2433,
    localidad: "ROSARIO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2434,
    localidad: "ROSARIO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2435,
    localidad: "ROSADA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2436,
    localidad: "ROMANOS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2437,
    localidad: "ROLDAN",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2438,
    localidad: "RODEO DE VALDEZ",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2439,
    localidad: "RODEO DE VALDEZ",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2440,
    localidad: "RODEO DE SORIA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2441,
    localidad: "RODEO DE SORIA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2442,
    localidad: "RODEO BAJADA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2443,
    localidad: "RODEO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2444,
    localidad: "RODEITO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2445,
    localidad: "ROBLES",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2446,
    localidad: "RIVADAVIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2447,
    localidad: "RIVADAVIA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2448,
    localidad: "RIO VIEJO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2449,
    localidad: "RIO SALADILLO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2450,
    localidad: "RIO NAMBI",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2451,
    localidad: "RIO MUERTO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2452,
    localidad: "RIO HONDITO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2453,
    localidad: "RIO DE GALLO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2454,
    localidad: "RIO DE GALLO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2455,
    localidad: "RINCON ESPERANZA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2456,
    localidad: "RINCON DE LA ESPZA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2457,
    localidad: "RINCON",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2458,
    localidad: "RINCON",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2459,
    localidad: "RINCON",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2460,
    localidad: "REY VIEJO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2461,
    localidad: "REY VIEJO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2462,
    localidad: "RETIRO SAN PABLO",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 2463,
    localidad: "RETIRO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2464,
    localidad: "RETIRO",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 2465,
    localidad: "RETIRO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2466,
    localidad: "RETIRO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2467,
    localidad: "RETIRO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2468,
    localidad: "REPRESA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2469,
    localidad: "REPECHO MONTENEGRO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2470,
    localidad: "REPECHO DIAZ",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2471,
    localidad: "REPARO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2472,
    localidad: "REMES",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2473,
    localidad: "REMANSOS",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2474,
    localidad: "REMANSOS",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2475,
    localidad: "REMANSO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2476,
    localidad: "REMANSITO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2477,
    localidad: "REMANSITO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2478,
    localidad: "REMANSITO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2479,
    localidad: "REMANSITO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2480,
    localidad: "REMANSITO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2481,
    localidad: "REAL SAYANA",
    cp: 4335,
    provincia_id: 22,
  },
  {
    id: 2482,
    localidad: "RAPELLI",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2483,
    localidad: "RANCHITOS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2484,
    localidad: "RAMIREZ DE VELAZCO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2485,
    localidad: "RAMI YACU",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2486,
    localidad: "RAMI YACU",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2487,
    localidad: "RAMADITAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2488,
    localidad: "RAMADITAS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2489,
    localidad: "RAMADITA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2490,
    localidad: "RAMADITA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2491,
    localidad: "RAMADITA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2492,
    localidad: "RAMA PASO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2493,
    localidad: "RACEDO",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2494,
    localidad: "R provincia_idL 334",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2495,
    localidad: "R provincia_idL 333",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2496,
    localidad: "R provincia_idL 130",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2497,
    localidad: "R DE LAS MANZANAS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 2498,
    localidad: "QUITA PUNCO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2499,
    localidad: "QUITA PUNCO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2500,
    localidad: "QUISÑA LORO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2501,
    localidad: "QUISHCA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2502,
    localidad: "QUISHCA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2503,
    localidad: "QUIROS",
    cp: 5266,
    provincia_id: 22,
  },
  {
    id: 2504,
    localidad: "QUIMILLOJ",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2505,
    localidad: "QUIMILIOJ",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2506,
    localidad: "QUIMILI PASO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2507,
    localidad: "QUIMILI",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2508,
    localidad: "QUILUMPA",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2509,
    localidad: "QUERA",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2510,
    localidad: "QUENTI TACO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2511,
    localidad: "QUEBRADA ESQUINA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2512,
    localidad: "QUEBRACHOS",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2513,
    localidad: "QUEBRACHOS",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2514,
    localidad: "QUEBRACHO YACU",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2515,
    localidad: "QUEBRACHO PINTADO",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 2516,
    localidad: "QUEBRACHO HERRADO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2517,
    localidad: "QUEBRACHITOS",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2518,
    localidad: "QUEBRACHITO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2519,
    localidad: "QUEBRACHAL",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2520,
    localidad: "QUEBRACHAL",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2521,
    localidad: "PUNTA RIELES",
    cp: 3731,
    provincia_id: 22,
  },
  {
    id: 2522,
    localidad: "PUNTA POZO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2523,
    localidad: "PUNTA POZO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2524,
    localidad: "PUNTA POZO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2525,
    localidad: "PUNTA DEL MONTE",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2526,
    localidad: "PUNTA DEL GARABATO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2527,
    localidad: "PUNTA DEL AGUA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2528,
    localidad: "PUNTA DE RIELES",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2529,
    localidad: "PUNTA CORRAL",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2530,
    localidad: "PUNITA SUD",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2531,
    localidad: "PUNITA NORTE",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2532,
    localidad: "PUNI TAJO",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 2533,
    localidad: "PUNCO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2534,
    localidad: "PUMITAYOJ",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2535,
    localidad: "PUMA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2536,
    localidad: "PUESTO NUEVO",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2537,
    localidad: "PUESTO NUEVO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2538,
    localidad: "PUESTO NUEVO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2539,
    localidad: "PUESTO LOS MARCOS",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2540,
    localidad: "PUESTO LOS GOMEZ",
    cp: 4231,
    provincia_id: 22,
  },
  {
    id: 2541,
    localidad: "PUESTO LOS GALVEZ",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2542,
    localidad: "PUESTO LOS BARRAZA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2543,
    localidad: "PUESTO DEL SIMBOL",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2544,
    localidad: "PUESTO DEL ROSARIO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2545,
    localidad: "PUESTO DEL RETIRO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 2546,
    localidad: "PUESTO DEL MEDIO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2547,
    localidad: "PUESTO DEL MEDIO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2548,
    localidad: "PUESTO DEL MEDIO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2549,
    localidad: "PUESTO DEL MEDIO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2550,
    localidad: "PUESTO DEL MEDIO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2551,
    localidad: "PUESTO DEL MEDIO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2552,
    localidad: "PUESTO DEL MEDIO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2553,
    localidad: "PUESTO DEL MEDIO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2554,
    localidad: "PUESTO DE VIEYRA",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2555,
    localidad: "PUESTO DE MENA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2556,
    localidad: "PUESTO DE MENA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2557,
    localidad: "PUESTO DE JUANES",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2558,
    localidad: "PUESTO DE GALVANES",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2559,
    localidad: "PUESTO DE DIAZ",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2560,
    localidad: "PUESTO DE DIAZ",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2561,
    localidad: "PUESTO DE DIAZ",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2562,
    localidad: "PUESTO DE ARRIBA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2563,
    localidad: "PUESTO CORDOBA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2564,
    localidad: "PUESTO BELEN",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2565,
    localidad: "PUESTO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2566,
    localidad: "PUESTO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2567,
    localidad: "PUESTITO DE S ANT",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2568,
    localidad: "PUESTITO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2569,
    localidad: "PUERTA GRANDE",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2570,
    localidad: "PUERTA DEL MONTE",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2571,
    localidad: "PUERTA DEL CIELO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2572,
    localidad: "PUERTA CHIQUITA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2573,
    localidad: "PUERTA ALEGRE",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2574,
    localidad: "PUENTE RIO SALADO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2575,
    localidad: "PUENTE NEGRO",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 2576,
    localidad: "PUENTE DEL SALADO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2577,
    localidad: "PUENTE DEL SALADO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2578,
    localidad: "PUENTE BAJADA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2579,
    localidad: "PUEDA SER",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2580,
    localidad: "PUEBLO PUCA",
    cp: 3732,
    provincia_id: 22,
  },
  {
    id: 2581,
    localidad: "PUEBLO NUEVO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2582,
    localidad: "PUEBLITO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2583,
    localidad: "PTA DE LAS PIEDRAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2584,
    localidad: "PROVIRU",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2585,
    localidad: "PROVIDENCIA",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2586,
    localidad: "PROGRESO DE JUME",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2587,
    localidad: "PROGRESO DE JUME",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2588,
    localidad: "PRIMAVERA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2589,
    localidad: "PRIMAVERA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2590,
    localidad: "POZUELOS",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 2591,
    localidad: "POZO VIL",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2592,
    localidad: "POZO VERDE",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2593,
    localidad: "POZO VERDE",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2594,
    localidad: "POZO VERDE",
    cp: 4198,
    provincia_id: 22,
  },
  {
    id: 2595,
    localidad: "POZO VERDE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2596,
    localidad: "POZO SALADO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2597,
    localidad: "POZO REDONDO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2598,
    localidad: "POZO MUERTO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2599,
    localidad: "POZO MOSOJ",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2600,
    localidad: "POZO MORO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2601,
    localidad: "POZO MARCADO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2602,
    localidad: "POZO LINDO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2603,
    localidad: "POZO LIMPIO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2604,
    localidad: "POZO LERDO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2605,
    localidad: "POZO LAPACHO",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2606,
    localidad: "POZO HUASCHO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2607,
    localidad: "POZO HONDO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2608,
    localidad: "POZO HONDO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2609,
    localidad: "POZO HERRERA",
    cp: 3761,
    provincia_id: 22,
  },
  {
    id: 2610,
    localidad: "POZO GRANDE",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2611,
    localidad: "POZO GRANDE",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2612,
    localidad: "POZO GRANDE",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2613,
    localidad: "POZO GRANDE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2614,
    localidad: "POZO GRANDE",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2615,
    localidad: "POZO ESCONDIDO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2616,
    localidad: "POZO EL QUEBRACHO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2617,
    localidad: "POZO DULCE",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 2618,
    localidad: "POZO DEL TOBA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2619,
    localidad: "POZO DEL TIGRE",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2620,
    localidad: "POZO DEL SIMBOL",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2621,
    localidad: "POZO DEL SIMBOL",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2622,
    localidad: "POZO DEL MONTE",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2623,
    localidad: "POZO DEL MONTE",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2624,
    localidad: "POZO DEL MACHO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2625,
    localidad: "POZO DEL GARABATO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2626,
    localidad: "POZO DEL CHAÑAR",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2627,
    localidad: "POZO DEL CHAÑAR",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2628,
    localidad: "POZO DEL CASTAÑO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2629,
    localidad: "POZO DEL CAMPO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 2630,
    localidad: "POZO DEL ARBOLITO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2631,
    localidad: "POZO DEL ALTO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2632,
    localidad: "POZO DEL ALGARROBO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2633,
    localidad: "POZO DE MOLINA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2634,
    localidad: "POZO DE LAS OLLAS",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2635,
    localidad: "POZO DE LA PUERTA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2636,
    localidad: "POZO CIEGO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2637,
    localidad: "POZO CERCADO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2638,
    localidad: "POZO CERCADO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2639,
    localidad: "POZO CERCADO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2640,
    localidad: "POZO CASTAÑO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2641,
    localidad: "POZO CABADO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2642,
    localidad: "POZO CABADO",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 2643,
    localidad: "POZO CABADO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2644,
    localidad: "POZO BETBEDER",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2645,
    localidad: "POZO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2646,
    localidad: "POZANCONES",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2647,
    localidad: "POZANCON",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2648,
    localidad: "POTRERO BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2649,
    localidad: "PORTEZUELO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2650,
    localidad: "PORTALIS",
    cp: 3066,
    provincia_id: 22,
  },
  {
    id: 2651,
    localidad: "PORTALIS",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2652,
    localidad: "PORTALIS",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2653,
    localidad: "PORONGOS",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2654,
    localidad: "PORONGOS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2655,
    localidad: "PORONGAL",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2656,
    localidad: "PORONGAL",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2657,
    localidad: "POLVAREDAS",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2658,
    localidad: "POLVAREDA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2659,
    localidad: "POLEO POZO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2660,
    localidad: "POLEO POZO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 2661,
    localidad: "POCITOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2662,
    localidad: "POCITO DE LA LOMA",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2663,
    localidad: "PIRUITAS",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2664,
    localidad: "PIRHUAS",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2665,
    localidad: "PIRHUAS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2666,
    localidad: "PINTO - VILLA GENARAL MITRE",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2667,
    localidad: "PINTO - V G MITRE",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2668,
    localidad: "PINTO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2669,
    localidad: "PINEDA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2670,
    localidad: "PIEDRITAS",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2671,
    localidad: "PIEDRA BUENA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2672,
    localidad: "PIEDRA BUENA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2673,
    localidad: "PIEDRA BLANCA",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2674,
    localidad: "PEREZ DE ZURITA",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 2675,
    localidad: "PERCHIL BAJO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2676,
    localidad: "PERCAS",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2677,
    localidad: "PERALTA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2678,
    localidad: "PENAL provincia_idL",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2679,
    localidad: "PELUDO WARCUNA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2680,
    localidad: "PATILLO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2681,
    localidad: "PATAY",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 2682,
    localidad: "PASO REDUCIDO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2683,
    localidad: "PASO MOSOJ",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2684,
    localidad: "PASO GRANDE",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2685,
    localidad: "PASO GRANDE",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2686,
    localidad: "PASO DEL SALADILLO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2687,
    localidad: "PASO DE OSCARES",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2688,
    localidad: "PASO DE LOS NIEVAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2689,
    localidad: "PASO DE LA PATRIA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2690,
    localidad: "PARANA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2691,
    localidad: "PARANA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2692,
    localidad: "PARAJE V YOLANDA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2693,
    localidad: "PARAJE SANTA CRUZ",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2694,
    localidad: "PARAJE OJO DE AGUA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2695,
    localidad: "PARAJE MILAGRO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2696,
    localidad: "PARAJE LILO VIEJO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2697,
    localidad: "PARAJE LA PAMPA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2698,
    localidad: "PARAJE GAUNA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2699,
    localidad: "PARAJE EL PRADO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2700,
    localidad: "PARADA KM 101",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2701,
    localidad: "PAMPALLAJTA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2702,
    localidad: "PAMPA VIRGEN",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2703,
    localidad: "PAMPA ROSA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2704,
    localidad: "PAMPA RALERA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2705,
    localidad: "PAMPA QUIMILI",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2706,
    localidad: "PAMPA POZO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2707,
    localidad: "PAMPA POZO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2708,
    localidad: "PAMPA POZO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2709,
    localidad: "PAMPA POZO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2710,
    localidad: "PAMPA POZO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2711,
    localidad: "PAMPA POZO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2712,
    localidad: "PAMPA PEREYRA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2713,
    localidad: "PAMPA PELADO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2714,
    localidad: "PAMPA OCULTA",
    cp: 3716,
    provincia_id: 22,
  },
  {
    id: 2715,
    localidad: "PAMPA MUYOJ",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2716,
    localidad: "PAMPA MUYOJ",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2717,
    localidad: "PAMPA MUYO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2718,
    localidad: "PAMPA MAYO",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2719,
    localidad: "PAMPA GRANDE",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2720,
    localidad: "PAMPA EL MOLLAR",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2721,
    localidad: "PAMPA EL MANGRULLO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2722,
    localidad: "PAMPA EL FOSFORITO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2723,
    localidad: "PAMPA DE LOS GUANACOS",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2724,
    localidad: "PAMPA CABURE",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2725,
    localidad: "PAMPA BOLSA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2726,
    localidad: "PAMPA ATUN",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2727,
    localidad: "PAMP D L GUANACOS",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2728,
    localidad: "PALOS QUEMADOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2729,
    localidad: "PALOS QUEMADOS",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2730,
    localidad: "PALOMAR",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2731,
    localidad: "PALOMAR",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2732,
    localidad: "PALO SECO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2733,
    localidad: "PALO PARADO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2734,
    localidad: "PALO NEGRO",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2735,
    localidad: "PALO LINDO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2736,
    localidad: "PALO BLANCO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2737,
    localidad: "PALO A PIQUE",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2738,
    localidad: "PALMITAS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2739,
    localidad: "PALMITAS",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2740,
    localidad: "PALMAS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2741,
    localidad: "PALMARES",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2742,
    localidad: "PALMA REDONDA",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 2743,
    localidad: "PALMA POZO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2744,
    localidad: "PALMA LARGA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2745,
    localidad: "PALMA FLOR",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2746,
    localidad: "PALIZAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2747,
    localidad: "PALERMO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2748,
    localidad: "PALERMO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2749,
    localidad: "PALERMO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2750,
    localidad: "PALERMO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2751,
    localidad: "PALERMO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2752,
    localidad: "PAJARO BLANCO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2753,
    localidad: "PAJA COLORADA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2754,
    localidad: "PADUA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2755,
    localidad: "PACIENCIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2756,
    localidad: "PAAJ RODEO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2757,
    localidad: "PAAJ POZO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2758,
    localidad: "PAAJ MUYO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2759,
    localidad: "PAAJ MUYO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 2760,
    localidad: "P. SITARIA POCITOS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2761,
    localidad: "P OBRAJE M ANGE",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2762,
    localidad: "P KILOMETRO 77",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2763,
    localidad: "P INDEPENDENCIA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2764,
    localidad: "P DEL SALADILLO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2765,
    localidad: "P DE LOS ALAMOS",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2766,
    localidad: "OVEJEROS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2767,
    localidad: "OTUMPA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2768,
    localidad: "OSCURO",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 2769,
    localidad: "ORO PAMPA",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 2770,
    localidad: "ORATORIO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2771,
    localidad: "ONCAN",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2772,
    localidad: "ONCAJAN",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2773,
    localidad: "OJO DE AGUA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2774,
    localidad: "OCTAVIA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2775,
    localidad: "OBRAJE MAILIN",
    cp: 3763,
    provincia_id: 22,
  },
  {
    id: 2776,
    localidad: "OBRAJE M ANGELICA",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 2777,
    localidad: "OBRAJE LOS TIGRES",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2778,
    localidad: "OBRAJE IRIONDO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2779,
    localidad: "NUEVO SIMBOLAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2780,
    localidad: "NUEVO LUJAN",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2781,
    localidad: "NUEVO LIBANO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2782,
    localidad: "NUEVE MISTOLES",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2783,
    localidad: "NUEVA YORK",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2784,
    localidad: "NUEVA TRINIDAD",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2785,
    localidad: "NUEVA TRINIDAD",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2786,
    localidad: "NUEVA INDUSTRIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2787,
    localidad: "NUEVA GRANADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2788,
    localidad: "NUEVA GRANADA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2789,
    localidad: "NUEVA FRANCIA",
    cp: 4207,
    provincia_id: 22,
  },
  {
    id: 2790,
    localidad: "NUEVA ESPERANZA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2791,
    localidad: "NUEVA ESPERANZA",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 2792,
    localidad: "NUEVA ESPERANZA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2793,
    localidad: "NUEVA COLONIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2794,
    localidad: "NUEVA AURORA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 2795,
    localidad: "NUEVA ANTAJE",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2796,
    localidad: "NUEVA ALZA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2797,
    localidad: "NOVILLO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2798,
    localidad: "NORQUEOJ",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2799,
    localidad: "NOGE",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 2800,
    localidad: "NOGALES",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2801,
    localidad: "NOGALES",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2802,
    localidad: "NEGRA MUERTA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2803,
    localidad: "NEGRA MUERTA",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 2804,
    localidad: "NEGRA MUERTA",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 2805,
    localidad: "NAVARRO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2806,
    localidad: "NARANJITOS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2807,
    localidad: "NARANJITO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2808,
    localidad: "NARANJITO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2809,
    localidad: "NAQUITO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2810,
    localidad: "NANDA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2811,
    localidad: "MORON",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2812,
    localidad: "MORELLO",
    cp: 4312,
    provincia_id: 22,
  },
  {
    id: 2813,
    localidad: "MORCILLO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2814,
    localidad: "MORAYOS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2815,
    localidad: "MORAMPA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 2816,
    localidad: "MORALES",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2817,
    localidad: "MORADITO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2818,
    localidad: "MONTUOSO",
    cp: 4242,
    provincia_id: 22,
  },
  {
    id: 2819,
    localidad: "MONTEVIDEO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2820,
    localidad: "MONTESINO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2821,
    localidad: "MONTEAGUDO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2822,
    localidad: "MONTE VERDE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2823,
    localidad: "MONTE VERDE",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 2824,
    localidad: "MONTE RICO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2825,
    localidad: "MONTE RICO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2826,
    localidad: "MONTE REDONDO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2827,
    localidad: "MONTE REDONDO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2828,
    localidad: "MONTE REDONDO",
    cp: 4212,
    provincia_id: 22,
  },
  {
    id: 2829,
    localidad: "MONTE QUEMADO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2830,
    localidad: "MONTE POTRERO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2831,
    localidad: "MONTE POTRERO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2832,
    localidad: "MONTE CRISTO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2833,
    localidad: "MONTE CRISTO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2834,
    localidad: "MONTE CRECIDO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2835,
    localidad: "MONTE ALTO",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 2836,
    localidad: "MOLLES",
    cp: 4242,
    provincia_id: 22,
  },
  {
    id: 2837,
    localidad: "MOLLES",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2838,
    localidad: "MOLLEOJ",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2839,
    localidad: "MOLLE POZO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2840,
    localidad: "MOLLE",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 2841,
    localidad: "MOLINOS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 2842,
    localidad: "MOJONCITO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 2843,
    localidad: "MOJON",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2844,
    localidad: "MOCONZA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2845,
    localidad: "MISTOLES",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 2846,
    localidad: "MISTOL POZO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2847,
    localidad: "MISTOL PAMPA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2848,
    localidad: "MISTOL MUYOJ",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 2849,
    localidad: "MISTOL LOMA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2850,
    localidad: "MISTOL LOMA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2851,
    localidad: "MIRCA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 2852,
    localidad: "MIRANDAS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2853,
    localidad: "MIRANDAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2854,
    localidad: "MIRAMONTE",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2855,
    localidad: "MIRAMONTE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2856,
    localidad: "MINERVA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2857,
    localidad: "MINERVA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2858,
    localidad: "MILI",
    cp: 4312,
    provincia_id: 22,
  },
  {
    id: 2859,
    localidad: "MILAGRO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2860,
    localidad: "MILAGRO",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 2861,
    localidad: "MIEL DE PALO",
    cp: 3761,
    provincia_id: 22,
  },
  {
    id: 2862,
    localidad: "MERCEDES",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2863,
    localidad: "MELERO",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2864,
    localidad: "MEDIO MUNDO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 2865,
    localidad: "MEDIA LUNA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2866,
    localidad: "MEDIA FLOR",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2867,
    localidad: "MEDELLIN",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2868,
    localidad: "MEDANOS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2869,
    localidad: "MATE PAMPA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2870,
    localidad: "MATARA",
    cp: 4357,
    provincia_id: 22,
  },
  {
    id: 2871,
    localidad: "MARTINEZ",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 2872,
    localidad: "MARTIN PASO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2873,
    localidad: "MARIA IDALINA",
    cp: 3476,
    provincia_id: 22,
  },
  {
    id: 2874,
    localidad: "MARIA DELICIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2875,
    localidad: "MARIA DEL CARMEN",
    cp: 3476,
    provincia_id: 22,
  },
  {
    id: 2876,
    localidad: "MARIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2877,
    localidad: "MARIA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2878,
    localidad: "MARAVILLA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 2879,
    localidad: "MARAVILLA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 2880,
    localidad: "MARAVILLA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2881,
    localidad: "MARAVILLA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2882,
    localidad: "MARAVILLA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2883,
    localidad: "MARAVILLA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2884,
    localidad: "MAQUITO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2885,
    localidad: "MAQUITIS",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2886,
    localidad: "MAQUITA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2887,
    localidad: "MAQUIJATA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2888,
    localidad: "MANSUPA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2889,
    localidad: "MANOGASTA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2890,
    localidad: "MANOGASTA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2891,
    localidad: "MANGRULLO",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 2892,
    localidad: "MANGA BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2893,
    localidad: "MANFLOA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2894,
    localidad: "MANCHIN",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2895,
    localidad: "MANCHIN",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 2896,
    localidad: "MANCHIN",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2897,
    localidad: "MANANTIALES",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 2898,
    localidad: "MANANTIALES",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2899,
    localidad: "MALOTA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2900,
    localidad: "MALLIN VIEJO",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2901,
    localidad: "MALBRAN",
    cp: 2355,
    provincia_id: 22,
  },
  {
    id: 2902,
    localidad: "MAL PASO",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 2903,
    localidad: "MAL PASO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 2904,
    localidad: "MAJANCITO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2905,
    localidad: "MAJADAS SUD",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2906,
    localidad: "MAJADAS",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 2907,
    localidad: "MAILIN",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2908,
    localidad: "MAIDANA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 2909,
    localidad: "MAGUITO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2910,
    localidad: "MAGDALENA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2911,
    localidad: "MADRE DE DIOS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2912,
    localidad: "MADERAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2913,
    localidad: "MADERAS",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2914,
    localidad: "MACO YANDA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2915,
    localidad: "MACO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 2916,
    localidad: "MACO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2917,
    localidad: "MACO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2918,
    localidad: "LUJAN",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 2919,
    localidad: "LUJAN",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2920,
    localidad: "LUJAN",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 2921,
    localidad: "LUJAN",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 2922,
    localidad: "LUJAN",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2923,
    localidad: "LUGONES",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 2924,
    localidad: "LOTE S",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 2925,
    localidad: "LOTE F",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2926,
    localidad: "LOTE 42",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 2927,
    localidad: "LOTE 33",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2928,
    localidad: "LOTE 29",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 2929,
    localidad: "LOTE 27 E 286",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 2930,
    localidad: "LOTE 15",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 2931,
    localidad: "LOTE 15",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 2932,
    localidad: "LOS TOBAS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2933,
    localidad: "LOS TIGRES",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2934,
    localidad: "LOS TIGRES",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2935,
    localidad: "LOS TELARES",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2936,
    localidad: "LOS SUNCHOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2937,
    localidad: "LOS SOTELO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2938,
    localidad: "LOS SORAIRE",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2939,
    localidad: "LOS SAUCES",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2940,
    localidad: "LOS SAUCES",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2941,
    localidad: "LOS SARACHO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2942,
    localidad: "LOS RUIZ",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2943,
    localidad: "LOS ROBLES",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2944,
    localidad: "LOS ROBLES",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 2945,
    localidad: "LOS REMANSOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2946,
    localidad: "LOS REMANSOS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2947,
    localidad: "LOS RALOS",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 2948,
    localidad: "LOS RALOS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 2949,
    localidad: "LOS RALOS",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 2950,
    localidad: "LOS QUIROGA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2951,
    localidad: "LOS QUIROGA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 2952,
    localidad: "LOS QUIROGA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2953,
    localidad: "LOS QUEBRACHOS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2954,
    localidad: "LOS QUEBRACHOS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 2955,
    localidad: "LOS QUEBRACHOS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 2956,
    localidad: "LOS QUEBRACHITOS",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 2957,
    localidad: "LOS PUNTOS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2958,
    localidad: "LOS PUNTOS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2959,
    localidad: "LOS PUESTOS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2960,
    localidad: "LOS PUESTOS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2961,
    localidad: "LOS PUENTES",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 2962,
    localidad: "LOS PUENTES",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2963,
    localidad: "LOS POZOS",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 2964,
    localidad: "LOS POZOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2965,
    localidad: "LOS PORTEÑOS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2966,
    localidad: "LOS PORONGOS",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 2967,
    localidad: "LOS POCITOS",
    cp: 4190,
    provincia_id: 22,
  },
  {
    id: 2968,
    localidad: "LOS POCITOS",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 2969,
    localidad: "LOS PIRPINTOS",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2970,
    localidad: "LOS PINOS B",
    cp: 4000,
    provincia_id: 22,
  },
  {
    id: 2971,
    localidad: "LOS PEREYRA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 2972,
    localidad: "LOS PENSAMIENTOS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 2973,
    localidad: "LOS PECARIEL",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2974,
    localidad: "LOS PAREDONES",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 2975,
    localidad: "LOS PARAISOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2976,
    localidad: "LOS PARAISOS",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 2977,
    localidad: "LOS PANCHILLOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 2978,
    localidad: "LOS NUÑEZ",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 2979,
    localidad: "LOS NARANJOS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 2980,
    localidad: "LOS MOYAS",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2981,
    localidad: "LOS MORTEROS",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 2982,
    localidad: "LOS MOLLES",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 2983,
    localidad: "LOS MOLLES",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 2984,
    localidad: "LOS MOLLES",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 2985,
    localidad: "LOS MOLLARES",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 2986,
    localidad: "LOS MILAGROS",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 2987,
    localidad: "LOS MILAGROS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 2988,
    localidad: "LOS MARCOS",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2989,
    localidad: "LOS MAGOS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 2990,
    localidad: "LOS LINARES",
    cp: 3761,
    provincia_id: 22,
  },
  {
    id: 2991,
    localidad: "LOS LATERALES",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 2992,
    localidad: "LOS JURIES",
    cp: 3763,
    provincia_id: 22,
  },
  {
    id: 2993,
    localidad: "LOS HERREROS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 2994,
    localidad: "LOS HERREROS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2995,
    localidad: "LOS HERRERAS",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 2996,
    localidad: "LOS HARDOY",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 2997,
    localidad: "LOS GUTIERREZ",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 2998,
    localidad: "LOS GUERREROS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 2999,
    localidad: "LOS GUERREROS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3000,
    localidad: "LOS GODOY",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3001,
    localidad: "LOS GATOS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3002,
    localidad: "LOS GALLARDOS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3003,
    localidad: "LOS GALLARDOS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3004,
    localidad: "LOS FORTINES",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3005,
    localidad: "LOS FIERROS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3006,
    localidad: "LOS ENCANTOS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3007,
    localidad: "LOS DIAZ",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3008,
    localidad: "LOS DIAZ",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3009,
    localidad: "LOS DECIMAS",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 3010,
    localidad: "LOS CRUCES",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3011,
    localidad: "LOS CORREAS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3012,
    localidad: "LOS COBRES",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3013,
    localidad: "LOS CHORRILLOS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3014,
    localidad: "LOS CHAÑARES",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3015,
    localidad: "LOS CERROS",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3016,
    localidad: "LOS CERRILLOS",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 3017,
    localidad: "LOS CERRILLOS",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3018,
    localidad: "LOS CERRILLOS",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 3019,
    localidad: "LOS CERCOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3020,
    localidad: "LOS CASTILLOS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3021,
    localidad: "LOS CARRIZOS",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3022,
    localidad: "LOS CAÑOS",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3023,
    localidad: "LOS CAÑOS",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3024,
    localidad: "LOS BORDOS",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 3025,
    localidad: "LOS BARRIALITOS",
    cp: 5274,
    provincia_id: 22,
  },
  {
    id: 3026,
    localidad: "LOS ARIAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3027,
    localidad: "LOS ARBOLITOS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3028,
    localidad: "LOS ARBOLITOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3029,
    localidad: "LOS ANGELES",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3030,
    localidad: "LOS ANGELES",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 3031,
    localidad: "LOS ALGARROBOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3032,
    localidad: "LOS ALDERETES",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3033,
    localidad: "LOS ALDERETE",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3034,
    localidad: "LOS 12 QUEBRACHOS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3035,
    localidad: "LOS 12 QUEBRACHOS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3036,
    localidad: "LORO HUASI",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3037,
    localidad: "LORO BLANCO",
    cp: 3716,
    provincia_id: 22,
  },
  {
    id: 3038,
    localidad: "LORETO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3039,
    localidad: "LORENA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3040,
    localidad: "LOMITAS BLANCAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3041,
    localidad: "LOMITAS",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 3042,
    localidad: "LOMITAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3043,
    localidad: "LOMITAS",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3044,
    localidad: "LOMITAS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3045,
    localidad: "LOMITAS",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3046,
    localidad: "LOMITAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3047,
    localidad: "LOMITAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3048,
    localidad: "LOMITAS",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3049,
    localidad: "LOMA NEGRA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3050,
    localidad: "LOMA NEGRA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3051,
    localidad: "LOMA GRANDE",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3052,
    localidad: "LOMA DEL MEDIO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 3053,
    localidad: "LOMA DE YESO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3054,
    localidad: "LOMA COLORADA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3055,
    localidad: "LOJLO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3056,
    localidad: "LLAMA PAMPA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3057,
    localidad: "LLAJTA MAUCA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3058,
    localidad: "LINTON",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3059,
    localidad: "LINDERO",
    cp: 4235,
    provincia_id: 22,
  },
  {
    id: 3060,
    localidad: "LINCHO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3061,
    localidad: "LIMACHE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3062,
    localidad: "LILO VIEJO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3063,
    localidad: "LIBERTAD",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3064,
    localidad: "LIBERTAD",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3065,
    localidad: "LIBERTAD",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3066,
    localidad: "LIBANESA",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 3067,
    localidad: "LEZCANOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3068,
    localidad: "LEZCANOS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3069,
    localidad: "LESCANO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3070,
    localidad: "LEIVA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3071,
    localidad: "LEIVA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3072,
    localidad: "LEDESMA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3073,
    localidad: "LECHUZAS",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 3074,
    localidad: "LAVALLE",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3075,
    localidad: "LAVALLE",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3076,
    localidad: "LAURELES",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3077,
    localidad: "LASPA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3078,
    localidad: "LAS ZANJITAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3079,
    localidad: "LAS ZANJAS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3080,
    localidad: "LAS VIBORITAS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3081,
    localidad: "LAS TRINCHERAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3082,
    localidad: "LAS TINAJAS",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3083,
    localidad: "LAS TIGRERAS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3084,
    localidad: "LAS TIGRERAS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 3085,
    localidad: "LAS TERESAS",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3086,
    localidad: "LAS TEJAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3087,
    localidad: "LAS TALITAS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3088,
    localidad: "LAS TALAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3089,
    localidad: "LAS SIERRAS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3090,
    localidad: "LAS SALINAS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3091,
    localidad: "LAS ROSAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3092,
    localidad: "LAS RANDAS",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3093,
    localidad: "LAS RAMADITAS",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 3094,
    localidad: "LAS QUEBRADAS",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3095,
    localidad: "LAS PUERTAS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3096,
    localidad: "LAS PUERTAS",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3097,
    localidad: "LAS PORTEÑAS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3098,
    localidad: "LAS PIEDRAS ANCHAS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3099,
    localidad: "LAS PERFORACIONES",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3100,
    localidad: "LAS PERFORACIONES",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3101,
    localidad: "LAS PEÑAS",
    cp: 4237,
    provincia_id: 22,
  },
  {
    id: 3102,
    localidad: "LAS PARVAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3103,
    localidad: "LAS PARRITAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3104,
    localidad: "LAS PALOMITAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3105,
    localidad: "LAS PALMITAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3106,
    localidad: "LAS PALMERAS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3107,
    localidad: "LAS PALMAS",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3108,
    localidad: "LAS ORELLANAS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3109,
    localidad: "LAS ORELLANAS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 3110,
    localidad: "LAS MOSTAZAS",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 3111,
    localidad: "LAS MOJARRAS",
    cp: 4198,
    provincia_id: 22,
  },
  {
    id: 3112,
    localidad: "LAS MOCHAS",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3113,
    localidad: "LAS MINAS",
    cp: 4235,
    provincia_id: 22,
  },
  {
    id: 3114,
    localidad: "LAS MERCEDES",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3115,
    localidad: "LAS MARAVILLAS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3116,
    localidad: "LAS LOMITAS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3117,
    localidad: "LAS LOMITAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3118,
    localidad: "LAS LOMITAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3119,
    localidad: "LAS LOMAS",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3120,
    localidad: "LAS LOMAS",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3121,
    localidad: "LAS LOMAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3122,
    localidad: "LAS LAJAS",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3123,
    localidad: "LAS JUNTAS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3124,
    localidad: "LAS ISLETAS",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3125,
    localidad: "LAS ISLAS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3126,
    localidad: "LAS HORQUETAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3127,
    localidad: "LAS HERMANAS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3128,
    localidad: "LAS GAMAS",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3129,
    localidad: "LAS FLORES",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3130,
    localidad: "LAS FLORES",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 3131,
    localidad: "LAS FLORES",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3132,
    localidad: "LAS FLORES",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3133,
    localidad: "LAS FLORES",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3134,
    localidad: "LAS FLORES",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3135,
    localidad: "LAS FLORES",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3136,
    localidad: "LAS FLORES",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3137,
    localidad: "LAS FLORES",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3138,
    localidad: "LAS DOS FLORES",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3139,
    localidad: "LAS DOS FLORES",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3140,
    localidad: "LAS DELICIAS",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3141,
    localidad: "LAS DELICIAS",
    cp: 4339,
    provincia_id: 22,
  },
  {
    id: 3142,
    localidad: "LAS DELICIAS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3143,
    localidad: "LAS CRUCES",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3144,
    localidad: "LAS CORTADERAS",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3145,
    localidad: "LAS CORTADERAS",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3146,
    localidad: "LAS COLONIAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3147,
    localidad: "LAS COLONIAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3148,
    localidad: "LAS COLONIAS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3149,
    localidad: "LAS COLINAS",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3150,
    localidad: "LAS CIENAGAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3151,
    localidad: "LAS CHILCAS",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3152,
    localidad: "LAS CHACRAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3153,
    localidad: "LAS CHACRAS",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3154,
    localidad: "LAS CHACRAS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3155,
    localidad: "LAS CHACRAS",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3156,
    localidad: "LAS CHACRAS",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3157,
    localidad: "LAS CEJAS",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3158,
    localidad: "LAS CEJAS",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 3159,
    localidad: "LAS CEJAS",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3160,
    localidad: "LAS CARPAS",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3161,
    localidad: "LAS CARPAS",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 3162,
    localidad: "LAS CAÑAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3163,
    localidad: "LAS CAÑAS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3164,
    localidad: "LAS ANIMAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3165,
    localidad: "LAS ALMAS",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3166,
    localidad: "LAS AGUILAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3167,
    localidad: "LAS AGUILAS",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3168,
    localidad: "LAS AGUADAS",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3169,
    localidad: "LAS ABRAS DE S ANT",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3170,
    localidad: "LAS ABRAS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3171,
    localidad: "LAS ABRAS",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 3172,
    localidad: "LAS ABRAS",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 3173,
    localidad: "LAPRIDA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3174,
    localidad: "LAPACHITOS",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3175,
    localidad: "LAPA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3176,
    localidad: "LAMADRID",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3177,
    localidad: "LAGUNO DEL SUNCHO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3178,
    localidad: "LAGUNITAS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3179,
    localidad: "LAGUNILLA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3180,
    localidad: "LAGUNA LARGA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3181,
    localidad: "LAGUNA LARGA",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3182,
    localidad: "LAGUNA DEL SUNCHO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3183,
    localidad: "LAGUNA BLANCA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3184,
    localidad: "LAGUNA BAYA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3185,
    localidad: "LAGUNA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3186,
    localidad: "LAGO MUYOJ",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 3187,
    localidad: "LA YERBA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3188,
    localidad: "LA VUELTA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3189,
    localidad: "LA VUELTA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3190,
    localidad: "LA VIUDA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3191,
    localidad: "LA VIRTUD",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3192,
    localidad: "LA VICTORIA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3193,
    localidad: "LA VERDE",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3194,
    localidad: "LA VALENTINA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3195,
    localidad: "LA UNION",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3196,
    localidad: "LA UNION",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3197,
    localidad: "LA TUSCA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3198,
    localidad: "LA TUSCA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3199,
    localidad: "LA TUNA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3200,
    localidad: "LA TRANQUILIDAD",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3201,
    localidad: "LA TRAMPA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3202,
    localidad: "LA TRAMPA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3203,
    localidad: "LA TOTORILLA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3204,
    localidad: "LA TERESA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3205,
    localidad: "LA TAPA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3206,
    localidad: "LA TALA",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3207,
    localidad: "LA SUSANA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3208,
    localidad: "LA SOLEDAD",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3209,
    localidad: "LA SOLEDAD",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3210,
    localidad: "LA SOLEDAD",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3211,
    localidad: "LA SOLEDAD",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 3212,
    localidad: "LA SOLEDAD",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3213,
    localidad: "LA SIMONA",
    cp: 3763,
    provincia_id: 22,
  },
  {
    id: 3214,
    localidad: "LA SIMONA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3215,
    localidad: "LA SELVA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3216,
    localidad: "LA SELVA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3217,
    localidad: "LA SARITA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3218,
    localidad: "LA SARA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3219,
    localidad: "LA SANTAFECINA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3220,
    localidad: "LA ROSILLA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3221,
    localidad: "LA ROMELIA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3222,
    localidad: "LA RIVERA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3223,
    localidad: "LA RINCONADA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3224,
    localidad: "LA REVANCHA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3225,
    localidad: "LA RESBALOSA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3226,
    localidad: "LA REPRESA",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3227,
    localidad: "LA REDUCCION",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3228,
    localidad: "LA RECONQUISTA",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3229,
    localidad: "LA RECONQUISTA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3230,
    localidad: "LA RECOMPENSA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3231,
    localidad: "LA RAMADITA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3232,
    localidad: "LA RAMADA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3233,
    localidad: "LA RAMADA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3234,
    localidad: "LA QUINTA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3235,
    localidad: "LA QUEBRADA",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3236,
    localidad: "LA PUNTA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3237,
    localidad: "LA PUERTA DEL MTE",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3238,
    localidad: "LA PUERTA DEL MTE",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3239,
    localidad: "LA PUERTA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3240,
    localidad: "LA PROVIDENCIA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3241,
    localidad: "LA PROVIDENCIA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3242,
    localidad: "LA PROTEGIDA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3243,
    localidad: "LA PROTEGIDA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3244,
    localidad: "LA PRIMITIVA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3245,
    localidad: "LA PRIMITIVA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3246,
    localidad: "LA PRIMAVERA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3247,
    localidad: "LA POTOCHA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3248,
    localidad: "LA PORTEÑA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3249,
    localidad: "LA PORFIA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3250,
    localidad: "LA POLVAREDA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3251,
    localidad: "LA PLATA",
    cp: 4198,
    provincia_id: 22,
  },
  {
    id: 3252,
    localidad: "LA PINTADA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3253,
    localidad: "LA PINTA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3254,
    localidad: "LA PETRONILA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3255,
    localidad: "LA PETRONILA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3256,
    localidad: "LA PERLITA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3257,
    localidad: "LA PERLITA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3258,
    localidad: "LA PEQA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3259,
    localidad: "LA PEDRERA",
    cp: 4400,
    provincia_id: 22,
  },
  {
    id: 3260,
    localidad: "LA PAZ",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3261,
    localidad: "LA PAZ",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3262,
    localidad: "LA PANCHITA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3263,
    localidad: "LA PAMPA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3264,
    localidad: "LA PAMPA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3265,
    localidad: "LA PAMPA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3266,
    localidad: "LA PAMPA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3267,
    localidad: "LA PAMPA",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 3268,
    localidad: "LA PALOMA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3269,
    localidad: "LA PALOMA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3270,
    localidad: "LA PALOMA",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 3271,
    localidad: "LA PALOMA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 3272,
    localidad: "LA PALMA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3273,
    localidad: "LA PALIZA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3274,
    localidad: "LA PACIENCIA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3275,
    localidad: "LA OVERA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3276,
    localidad: "LA OSCURIDAD",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3277,
    localidad: "LA NORIA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3278,
    localidad: "LA NORIA",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 3279,
    localidad: "LA NENA",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3280,
    localidad: "LA MILKA",
    cp: 2400,
    provincia_id: 22,
  },
  {
    id: 3281,
    localidad: "LA MESADA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3282,
    localidad: "LA MELADA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3283,
    localidad: "LA MELADA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3284,
    localidad: "LA MELADA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3285,
    localidad: "LA MATILDE",
    cp: 4190,
    provincia_id: 22,
  },
  {
    id: 3286,
    localidad: "LA MARTA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3287,
    localidad: "LA MARAVILLA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3288,
    localidad: "LA MARAVILLA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3289,
    localidad: "LA MANGA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3290,
    localidad: "LA MAGDALENA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3291,
    localidad: "LA MADRID",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3292,
    localidad: "LA LUNA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3293,
    localidad: "LA LOMADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3294,
    localidad: "LA LOMA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3295,
    localidad: "LA LOMA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3296,
    localidad: "LA LOMA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3297,
    localidad: "LA LOMA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3298,
    localidad: "LA LOMA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3299,
    localidad: "LA LOMA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3300,
    localidad: "LA LIBIA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3301,
    localidad: "LA LIBERTAD",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3302,
    localidad: "LA LAURA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3303,
    localidad: "LA LAURA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3304,
    localidad: "LA LAGUNA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3305,
    localidad: "LA JULIANA",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3306,
    localidad: "LA ISLETA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3307,
    localidad: "LA ISLA",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3308,
    localidad: "LA ISLA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3309,
    localidad: "LA INVERNADA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3310,
    localidad: "LA INVERNADA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3311,
    localidad: "LA ILUSION",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3312,
    localidad: "LA HUERTA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3313,
    localidad: "LA HIGUERA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3314,
    localidad: "LA HIGUERA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3315,
    localidad: "LA HIEDRA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3316,
    localidad: "LA GUARDIA",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3317,
    localidad: "LA GUARDIA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3318,
    localidad: "LA GRITERIA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3319,
    localidad: "LA GRINGA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3320,
    localidad: "LA GRINGA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3321,
    localidad: "LA GRANJA",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3322,
    localidad: "LA GRANADA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3323,
    localidad: "LA GRANA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3324,
    localidad: "LA GRAMA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3325,
    localidad: "LA GOLONDRINA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3326,
    localidad: "LA GOLONDRINA",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3327,
    localidad: "LA GERMANIA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3328,
    localidad: "LA GERMANIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3329,
    localidad: "LA FRANCISCA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3330,
    localidad: "LA FRAGUA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3331,
    localidad: "LA FORTUNA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3332,
    localidad: "LA FORTUNA",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 3333,
    localidad: "LA FORTUNA",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 3334,
    localidad: "LA FLORIDA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3335,
    localidad: "LA FLORIDA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3336,
    localidad: "LA FLORIDA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3337,
    localidad: "LA FLORIDA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3338,
    localidad: "LA FLORIDA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3339,
    localidad: "LA FLECHA",
    cp: 3716,
    provincia_id: 22,
  },
  {
    id: 3340,
    localidad: "LA FIRMEZA",
    cp: 5714,
    provincia_id: 22,
  },
  {
    id: 3341,
    localidad: "LA FIRMEZA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3342,
    localidad: "LA FALDA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3343,
    localidad: "LA FALDA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3344,
    localidad: "LA FALDA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3345,
    localidad: "LA FALDA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3346,
    localidad: "LA EULALIA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3347,
    localidad: "LA ESTRELLA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3348,
    localidad: "LA ESTANCIA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3349,
    localidad: "LA ESTACADA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3350,
    localidad: "LA ESQUINA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3351,
    localidad: "LA ESQUINA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3352,
    localidad: "LA ESQUINA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3353,
    localidad: "LA ESPERANZA",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 3354,
    localidad: "LA ESPERANZA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3355,
    localidad: "LA ESPERANZA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3356,
    localidad: "LA ESPERANZA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3357,
    localidad: "LA ESPERANZA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3358,
    localidad: "LA ESPERANZA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3359,
    localidad: "LA ESPERANZA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3360,
    localidad: "LA ESPERANZA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3361,
    localidad: "LA ESPERANZA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3362,
    localidad: "LA ESPERANZA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3363,
    localidad: "LA ESPERANZA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3364,
    localidad: "LA ESMERALDA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3365,
    localidad: "LA ESMERALDA",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 3366,
    localidad: "LA ENSENADA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3367,
    localidad: "LA ENCALADA",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 3368,
    localidad: "LA ELSA",
    cp: 2341,
    provincia_id: 22,
  },
  {
    id: 3369,
    localidad: "LA DORMIDA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3370,
    localidad: "LA DORA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3371,
    localidad: "LA DONOSA",
    cp: 4221,
    provincia_id: 22,
  },
  {
    id: 3372,
    localidad: "LA DOLORES",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3373,
    localidad: "LA DEFENSA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3374,
    localidad: "LA DARSENA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3375,
    localidad: "LA DARSENA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3376,
    localidad: "LA CURVA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3377,
    localidad: "LA CUESTA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3378,
    localidad: "LA CUCHILLA",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3379,
    localidad: "LA CUARTEADA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3380,
    localidad: "LA CUARTEADA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3381,
    localidad: "LA CRUZ",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3382,
    localidad: "LA CRUZ",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3383,
    localidad: "LA CRUZ",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3384,
    localidad: "LA CRUZ",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3385,
    localidad: "LA COSTOSA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3386,
    localidad: "LA COSTA PALAMPA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3387,
    localidad: "LA COSTA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3388,
    localidad: "LA COSTA",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 3389,
    localidad: "LA CORTADERA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3390,
    localidad: "LA CONCEPCION",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3391,
    localidad: "LA CONCEPCION",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3392,
    localidad: "LA COLONIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3393,
    localidad: "LA COLONIA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3394,
    localidad: "LA COLINA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3395,
    localidad: "LA CODICIA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3396,
    localidad: "LA CLEMIRA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3397,
    localidad: "LA CHINA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3398,
    localidad: "LA CHILCA",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3399,
    localidad: "LA CHILCA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3400,
    localidad: "LA CHICHARRA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3401,
    localidad: "LA CHEJCHILLA",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3402,
    localidad: "LA CHACRA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3403,
    localidad: "LA CENTELLA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3404,
    localidad: "LA CELINA",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 3405,
    localidad: "LA CASIMIRA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3406,
    localidad: "LA CAROLINA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3407,
    localidad: "LA CAPILLA",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3408,
    localidad: "LA CAPILLA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3409,
    localidad: "LA CAÑADA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3410,
    localidad: "LA CAÑADA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3411,
    localidad: "LA CAÑADA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3412,
    localidad: "LA CAÑADA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3413,
    localidad: "LA CAÑADA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3414,
    localidad: "LA CAÑADA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3415,
    localidad: "LA CALERA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3416,
    localidad: "LA CALERA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3417,
    localidad: "LA BREA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3418,
    localidad: "LA BRAMA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3419,
    localidad: "LA BOTA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3420,
    localidad: "LA BOTA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3421,
    localidad: "LA BLANCA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3422,
    localidad: "LA BLANCA",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3423,
    localidad: "LA BLANCA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3424,
    localidad: "LA BLANCA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3425,
    localidad: "LA BLANCA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3426,
    localidad: "LA BELLA CRIOLLA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3427,
    localidad: "LA BARROSA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3428,
    localidad: "LA BANDA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3429,
    localidad: "LA BANDA",
    cp: 4190,
    provincia_id: 22,
  },
  {
    id: 3430,
    localidad: "LA BANDA",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3431,
    localidad: "LA BALANZA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3432,
    localidad: "LA BALANZA",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3433,
    localidad: "LA AURORA",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 3434,
    localidad: "LA ARMONIA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3435,
    localidad: "LA ARMONIA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3436,
    localidad: "LA ARGENTINA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3437,
    localidad: "LA ARGENTINA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3438,
    localidad: "LA ARGENTINA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3439,
    localidad: "LA ANGELITA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3440,
    localidad: "LA AMERICA",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 3441,
    localidad: "LA ALOJA",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3442,
    localidad: "LA ALEMANA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3443,
    localidad: "LA AIDA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3444,
    localidad: "LA AGUADITA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3445,
    localidad: "LA AGUADA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3446,
    localidad: "LA AGUADA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3447,
    localidad: "LA AGUADA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3448,
    localidad: "LA AGUADA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3449,
    localidad: "LA AGUADA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3450,
    localidad: "LA ABRITA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3451,
    localidad: "LA ABRA",
    cp: 4237,
    provincia_id: 22,
  },
  {
    id: 3452,
    localidad: "LA ABRA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3453,
    localidad: "KM 443 TABOADA",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3454,
    localidad: "KISKA LORO",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3455,
    localidad: "KISKA HURMANA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3456,
    localidad: "KISKA HURMANA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3457,
    localidad: "KILOMETRO 88",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3458,
    localidad: "KILOMETRO 794",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3459,
    localidad: "KILOMETRO 784",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3460,
    localidad: "KILOMETRO 784",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3461,
    localidad: "KILOMETRO 781",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3462,
    localidad: "KILOMETRO 764",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3463,
    localidad: "KILOMETRO 735",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3464,
    localidad: "KILOMETRO 719",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3465,
    localidad: "KILOMETRO 694",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3466,
    localidad: "KILOMETRO 665",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3467,
    localidad: "KILOMETRO 661",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3468,
    localidad: "KILOMETRO 659",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3469,
    localidad: "KILOMETRO 651",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3470,
    localidad: "KILOMETRO 651",
    cp: 4339,
    provincia_id: 22,
  },
  {
    id: 3471,
    localidad: "KILOMETRO 645",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3472,
    localidad: "KILOMETRO 645",
    cp: 4339,
    provincia_id: 22,
  },
  {
    id: 3473,
    localidad: "KILOMETRO 629",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3474,
    localidad: "KILOMETRO 613",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3475,
    localidad: "KILOMETRO 606",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3476,
    localidad: "KILOMETRO 55",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3477,
    localidad: "KILOMETRO 546",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3478,
    localidad: "KILOMETRO 515",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 3479,
    localidad: "KILOMETRO 499",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 3480,
    localidad: "KILOMETRO 494",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3481,
    localidad: "KILOMETRO 49",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 3482,
    localidad: "KILOMETRO 48",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3483,
    localidad: "KILOMETRO 477",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3484,
    localidad: "KILOMETRO 473",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3485,
    localidad: "KILOMETRO 454",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 3486,
    localidad: "KILOMETRO 450",
    cp: 3763,
    provincia_id: 22,
  },
  {
    id: 3487,
    localidad: "KILOMETRO 443",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3488,
    localidad: "KILOMETRO 437",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3489,
    localidad: "KILOMETRO 436",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3490,
    localidad: "KILOMETRO 433",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 3491,
    localidad: "KILOMETRO 390",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3492,
    localidad: "KILOMETRO 364",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3493,
    localidad: "KILOMETRO 301",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3494,
    localidad: "KILOMETRO 3",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3495,
    localidad: "KILOMETRO 293",
    cp: 3066,
    provincia_id: 22,
  },
  {
    id: 3496,
    localidad: "KILOMETRO 261",
    cp: 3476,
    provincia_id: 22,
  },
  {
    id: 3497,
    localidad: "KILOMETRO 20",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 3498,
    localidad: "KILOMETRO 18",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3499,
    localidad: "KILOMETRO 153",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3500,
    localidad: "KILOMETRO 1391",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3501,
    localidad: "KILOMETRO 1380",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3502,
    localidad: "KILOMETRO 1362",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3503,
    localidad: "KILOMETRO 135",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3504,
    localidad: "KILOMETRO 1314",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3505,
    localidad: "KILOMETRO 1297",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3506,
    localidad: "KILOMETRO 1255",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3507,
    localidad: "KILOMETRO 1210",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3508,
    localidad: "KILOMETRO 120",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3509,
    localidad: "KILOMETRO 12",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3510,
    localidad: "KILOMETRO 1183",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3511,
    localidad: "KILOMETRO 118",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3512,
    localidad: "KILOMETRO 117",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3513,
    localidad: "KILOMETRO 1121",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3514,
    localidad: "KILOMETRO 112",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3515,
    localidad: "KILOMETRO 1098",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3516,
    localidad: "KILOMETRO 1073",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3517,
    localidad: "KILOMETRO 1033",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3518,
    localidad: "KILOMETRO 1008",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3519,
    localidad: "KILOMETRO 10",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3520,
    localidad: "KENTI TACO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3521,
    localidad: "KENTI TACO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3522,
    localidad: "KENTI TACKO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3523,
    localidad: "JUSTINO SOLARI",
    cp: 3476,
    provincia_id: 22,
  },
  {
    id: 3524,
    localidad: "JUNCAL GRANDE",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3525,
    localidad: "JUNCAL GRANDE",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3526,
    localidad: "JUNALITO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3527,
    localidad: "JUMIALITO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3528,
    localidad: "JUMIAL GRANDE",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3529,
    localidad: "JUMIAL",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3530,
    localidad: "JUMI VIEJO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3531,
    localidad: "JUMI POZO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3532,
    localidad: "JUMI POZO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3533,
    localidad: "JUMI POZO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3534,
    localidad: "JUMEAL O JUMIAL",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3535,
    localidad: "JUME ESQUINA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3536,
    localidad: "JUME ESQUINA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3537,
    localidad: "JUME",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3538,
    localidad: "JUME",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3539,
    localidad: "JUANILLO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3540,
    localidad: "JIMENEZ",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3541,
    localidad: "JDIN D L DELICIAS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3542,
    localidad: "JANTA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3543,
    localidad: "JACIMAMPA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3544,
    localidad: "JACIMAMPA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3545,
    localidad: "IUCHAN",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3546,
    localidad: "ISLA VERDE",
    cp: 4212,
    provincia_id: 22,
  },
  {
    id: 3547,
    localidad: "ISLA VERDE",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3548,
    localidad: "ISLA MOTA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3549,
    localidad: "ISLA MOTA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3550,
    localidad: "ISLA DE ARAGONES",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3551,
    localidad: "ISLA DE ARAGONES",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3552,
    localidad: "ISLA BAJA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3553,
    localidad: "ISCA YACU SEMAUL",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3554,
    localidad: "ISCA YACU",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3555,
    localidad: "INTI HUASI",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3556,
    localidad: "INTI HUASI",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3557,
    localidad: "INGENIO ESPERANZA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3558,
    localidad: "INGENIERO FORRES",
    cp: 4312,
    provincia_id: 22,
  },
  {
    id: 3559,
    localidad: "INGENIERO EZCURRA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3560,
    localidad: "INDUSTRIA NUEVA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3561,
    localidad: "ILIAGES",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3562,
    localidad: "ICHIPUCA",
    cp: 4235,
    provincia_id: 22,
  },
  {
    id: 3563,
    localidad: "ICHAGON",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3564,
    localidad: "ICAÑO",
    cp: 4334,
    provincia_id: 22,
  },
  {
    id: 3565,
    localidad: "ICAÑO",
    cp: 5265,
    provincia_id: 22,
  },
  {
    id: 3566,
    localidad: "I DE LOS SOTELOS",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 3567,
    localidad: "I DE LOS CASTILLOS",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3568,
    localidad: "HUYAMAMPA",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 3569,
    localidad: "HUTURUNGO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3570,
    localidad: "HUTCU CHACRA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3571,
    localidad: "HURITU HUASI",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3572,
    localidad: "HUÑAJCITO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3573,
    localidad: "HUMAITA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3574,
    localidad: "HUILLA CATINA",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3575,
    localidad: "HUGENTOBLER",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 3576,
    localidad: "HUCHUPAYANA",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 3577,
    localidad: "HUASCHO  PATILLA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3578,
    localidad: "HUASCAN",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3579,
    localidad: "HUALO CANCANA",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3580,
    localidad: "HUAJIA",
    cp: 4318,
    provincia_id: 22,
  },
  {
    id: 3581,
    localidad: "HUACHANA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3582,
    localidad: "HUACHANA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3583,
    localidad: "HUACHANA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3584,
    localidad: "HUACANITAS",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3585,
    localidad: "HOYON",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3586,
    localidad: "HOYON",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3587,
    localidad: "HOYO CON AGUA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3588,
    localidad: "HOYO CERCO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3589,
    localidad: "HORNILLOS",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3590,
    localidad: "HORNILLOS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3591,
    localidad: "HORCOS TUCUCUNA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3592,
    localidad: "HORCOS TUCUCUNA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3593,
    localidad: "HORCONES",
    cp: 4198,
    provincia_id: 22,
  },
  {
    id: 3594,
    localidad: "HILUMAMPA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3595,
    localidad: "HIGUERILLAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3596,
    localidad: "HIGUERA CHAQUI",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3597,
    localidad: "HERRERA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 3598,
    localidad: "HERNAN MEJIA MIRA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3599,
    localidad: "HAZAN VILLA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3600,
    localidad: "HAHUANCUYOS",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3601,
    localidad: "HAASE",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3602,
    localidad: "GUIÑAO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 3603,
    localidad: "GUERRA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 3604,
    localidad: "GUAYPE",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3605,
    localidad: "GUAYCURU",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3606,
    localidad: "GUATANA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3607,
    localidad: "GUARDIA VIEJA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3608,
    localidad: "GUARDIA ESCOLTA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3609,
    localidad: "GUARDIA DEL NORTE",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3610,
    localidad: "GUARDIA DE LA ESQ",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3611,
    localidad: "GUARDIA",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 3612,
    localidad: "GUARCAN",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3613,
    localidad: "GUAÑAGASTA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 3614,
    localidad: "GUANACUYOJ",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3615,
    localidad: "GUANACO SOMBRIANA",
    cp: 4212,
    provincia_id: 22,
  },
  {
    id: 3616,
    localidad: "GUAMPACHA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3617,
    localidad: "GUALAMBA",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 3618,
    localidad: "GUAIPI",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3619,
    localidad: "GRANADERO GATICA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3620,
    localidad: "GRAMILLAL",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3621,
    localidad: "GRAMILLA",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 3622,
    localidad: "GRACIELA",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 3623,
    localidad: "GOBERNADOR PIEDRABUENA",
    cp: 4188,
    provincia_id: 22,
  },
  {
    id: 3624,
    localidad: "GIRARDET",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 3625,
    localidad: "GIBIALTO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3626,
    localidad: "GENOVEVA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3627,
    localidad: "GENERAL CAPDEVILLA",
    cp: 3732,
    provincia_id: 22,
  },
  {
    id: 3628,
    localidad: "GASPAR SUAREZ",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3629,
    localidad: "GARZON",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3630,
    localidad: "GARZA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3631,
    localidad: "GARCEANO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3632,
    localidad: "GANCEDO",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3633,
    localidad: "GALLEGOS",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3634,
    localidad: "GALEANO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3635,
    localidad: "G PIEDRABUENA",
    cp: 4188,
    provincia_id: 22,
  },
  {
    id: 3636,
    localidad: "FRIAS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3637,
    localidad: "FORTUNA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3638,
    localidad: "FORTIN LA VIUDA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3639,
    localidad: "FORTIN INCA",
    cp: 3063,
    provincia_id: 22,
  },
  {
    id: 3640,
    localidad: "FORTIN ALERTA",
    cp: 3066,
    provincia_id: 22,
  },
  {
    id: 3641,
    localidad: "FLORIDA",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 3642,
    localidad: "FLORESTA",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3643,
    localidad: "FIVIALTOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3644,
    localidad: "FIERRO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3645,
    localidad: "FERNANDEZ",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3646,
    localidad: "FAVORITA",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3647,
    localidad: "FAVORINA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3648,
    localidad: "FAROL",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3649,
    localidad: "FAMATINA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3650,
    localidad: "EZCURRA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3651,
    localidad: "ESTEROS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3652,
    localidad: "ESTELA",
    cp: 4400,
    provincia_id: 22,
  },
  {
    id: 3653,
    localidad: "ESTECO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3654,
    localidad: "ESTANZUELA",
    cp: 4235,
    provincia_id: 22,
  },
  {
    id: 3655,
    localidad: "ESTANCIA VIEJA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3656,
    localidad: "ESTANCIA LA ELSITA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3657,
    localidad: "ESTANCIA GOROSITO",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3658,
    localidad: "ESTANCIA EL CARMEN",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 3659,
    localidad: "ESTANCIA DEL MEDIO",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 3660,
    localidad: "ESTACION DURAZNO",
    cp: 3711,
    provincia_id: 22,
  },
  {
    id: 3661,
    localidad: "ESTACION ATAMISQUI",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 3662,
    localidad: "ESQUINA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3663,
    localidad: "ESPINILLO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3664,
    localidad: "ESPINAL",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3665,
    localidad: "ESPERANZA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3666,
    localidad: "ESPERANZA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3667,
    localidad: "ESPERANZA",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3668,
    localidad: "ESCUELA LEO HUASI",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3669,
    localidad: "ESCUELA 96",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3670,
    localidad: "ESCUELA 92",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3671,
    localidad: "ESCUELA 91",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3672,
    localidad: "ESCUELA 90",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3673,
    localidad: "ESCUELA 879",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3674,
    localidad: "ESCUELA 83",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3675,
    localidad: "ESCUELA 75",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3676,
    localidad: "ESCUELA 74",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3677,
    localidad: "ESCUELA 72",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3678,
    localidad: "ESCUELA 708",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3679,
    localidad: "ESCUELA 7",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3680,
    localidad: "ESCUELA 665",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3681,
    localidad: "ESCUELA 62",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3682,
    localidad: "ESCUELA 61",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3683,
    localidad: "ESCUELA 60",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3684,
    localidad: "ESCUELA 57",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3685,
    localidad: "ESCUELA 57",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3686,
    localidad: "ESCUELA 5",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3687,
    localidad: "ESCUELA 4",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3688,
    localidad: "ESCUELA 386",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3689,
    localidad: "ESCUELA 375",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3690,
    localidad: "ESCUELA 369",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3691,
    localidad: "ESCUELA 368",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3692,
    localidad: "ESCUELA 366",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3693,
    localidad: "ESCUELA 353",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3694,
    localidad: "ESCUELA 347",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3695,
    localidad: "ESCUELA 346",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3696,
    localidad: "ESCUELA 344",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3697,
    localidad: "ESCUELA 343",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3698,
    localidad: "ESCUELA 34",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3699,
    localidad: "ESCUELA 339",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3700,
    localidad: "ESCUELA 331",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3701,
    localidad: "ESCUELA 326",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3702,
    localidad: "ESCUELA 316",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3703,
    localidad: "ESCUELA 314",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3704,
    localidad: "ESCUELA 313",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3705,
    localidad: "ESCUELA 310",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3706,
    localidad: "ESCUELA 306",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3707,
    localidad: "ESCUELA 303",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3708,
    localidad: "ESCUELA 302",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3709,
    localidad: "ESCUELA 30",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3710,
    localidad: "ESCUELA 292",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3711,
    localidad: "ESCUELA 278",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3712,
    localidad: "ESCUELA 276",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3713,
    localidad: "ESCUELA 275",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3714,
    localidad: "ESCUELA 262",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3715,
    localidad: "ESCUELA 250",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3716,
    localidad: "ESCUELA 246",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3717,
    localidad: "ESCUELA 245",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3718,
    localidad: "ESCUELA 242",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3719,
    localidad: "ESCUELA 211",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3720,
    localidad: "ESCUELA 208",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3721,
    localidad: "ESCUELA 205",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3722,
    localidad: "ESCUELA 20",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3723,
    localidad: "ESCUELA 191",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3724,
    localidad: "ESCUELA 189",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3725,
    localidad: "ESCUELA 187",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3726,
    localidad: "ESCUELA 184",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3727,
    localidad: "ESCUELA 182",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3728,
    localidad: "ESCUELA 180",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3729,
    localidad: "ESCUELA 179",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3730,
    localidad: "ESCUELA 178",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3731,
    localidad: "ESCUELA 177",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3732,
    localidad: "ESCUELA 173",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3733,
    localidad: "ESCUELA 172",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3734,
    localidad: "ESCUELA 167",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3735,
    localidad: "ESCUELA 166",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3736,
    localidad: "ESCUELA 158",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3737,
    localidad: "ESCUELA 152",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3738,
    localidad: "ESCUELA 151",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3739,
    localidad: "ESCUELA 150",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3740,
    localidad: "ESCUELA 149",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3741,
    localidad: "ESCUELA 118",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3742,
    localidad: "ESCUELA 112",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3743,
    localidad: "ESCUELA 109",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3744,
    localidad: "ESCUELA 1080",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3745,
    localidad: "ESCUELA 1050",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3746,
    localidad: "ESCALERA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3747,
    localidad: "ENVIDIA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3748,
    localidad: "ENVIDIA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3749,
    localidad: "ENSENADA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3750,
    localidad: "ENSENADA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3751,
    localidad: "ENSENADA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3752,
    localidad: "EMBALSE RIO HONDO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3753,
    localidad: "EL VIZCACHERAL",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3754,
    localidad: "EL VIÑALITO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3755,
    localidad: "EL VINALAR",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 3756,
    localidad: "EL VENTICINCO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3757,
    localidad: "EL VEINTISIETE",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3758,
    localidad: "EL VEINTICINCO DE MAYO",
    cp: 4283,
    provincia_id: 22,
  },
  {
    id: 3759,
    localidad: "EL VEINTICINCO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3760,
    localidad: "EL VEINTE",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 3761,
    localidad: "EL VALLE D ORIENTE",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3762,
    localidad: "EL VALLE",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3763,
    localidad: "EL VALLA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3764,
    localidad: "EL URUNDAY",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 3765,
    localidad: "EL UNCO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3766,
    localidad: "EL UNCO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3767,
    localidad: "EL UCLE",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3768,
    localidad: "EL TRECE",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 3769,
    localidad: "EL TRASLADO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3770,
    localidad: "EL TOBIANO",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3771,
    localidad: "EL TANQUE",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3772,
    localidad: "EL TAMBERO",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3773,
    localidad: "EL TALA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3774,
    localidad: "EL TALA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3775,
    localidad: "EL TACIAL",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3776,
    localidad: "EL SOLITARIO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3777,
    localidad: "EL SIMBOLAR",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 3778,
    localidad: "EL SIMBOLAR",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3779,
    localidad: "EL SIMBOL",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3780,
    localidad: "EL SIMBOL",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3781,
    localidad: "EL SILENCIO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3782,
    localidad: "EL SETENTA",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3783,
    localidad: "EL SESTEADERO",
    cp: 4242,
    provincia_id: 22,
  },
  {
    id: 3784,
    localidad: "EL SEÑUELO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3785,
    localidad: "EL SEGUNDO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3786,
    localidad: "EL SAUZAL",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3787,
    localidad: "EL SAUCE",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3788,
    localidad: "EL SAUCE",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3789,
    localidad: "EL SAUCE",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3790,
    localidad: "EL SALVADOR",
    cp: 4231,
    provincia_id: 22,
  },
  {
    id: 3791,
    localidad: "EL SALADILLO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3792,
    localidad: "EL SALADILLO",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3793,
    localidad: "EL SALADILLO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3794,
    localidad: "EL SALADILLO",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3795,
    localidad: "EL ROSARIO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3796,
    localidad: "EL ROSARIO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3797,
    localidad: "EL ROSARIO",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3798,
    localidad: "EL ROSARIO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3799,
    localidad: "EL ROSARIO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3800,
    localidad: "EL ROSARIO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3801,
    localidad: "EL RODEO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3802,
    localidad: "EL RODEO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3803,
    localidad: "EL RODEO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3804,
    localidad: "EL RINCON",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3805,
    localidad: "EL RINCON",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3806,
    localidad: "EL RINCON",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3807,
    localidad: "EL RINCON",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 3808,
    localidad: "EL RETIRO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3809,
    localidad: "EL RETIRO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 3810,
    localidad: "EL REMANSO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3811,
    localidad: "EL RECREO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3812,
    localidad: "EL REAL",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3813,
    localidad: "EL RAJO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3814,
    localidad: "EL QUILLIN",
    cp: 4234,
    provincia_id: 22,
  },
  {
    id: 3815,
    localidad: "EL QUEMADO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3816,
    localidad: "EL QUEMADO",
    cp: 4198,
    provincia_id: 22,
  },
  {
    id: 3817,
    localidad: "EL QUEMADO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 3818,
    localidad: "EL QUEMADO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3819,
    localidad: "EL QUEBRACHO",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3820,
    localidad: "EL PUMA",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3821,
    localidad: "EL PUESTO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3822,
    localidad: "EL PUESTO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 3823,
    localidad: "EL PUESTITO",
    cp: 4242,
    provincia_id: 22,
  },
  {
    id: 3824,
    localidad: "EL PUESTITO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3825,
    localidad: "EL PUESTITO",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 3826,
    localidad: "EL PUENTE",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3827,
    localidad: "EL PUENTE",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3828,
    localidad: "EL PUENTE",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3829,
    localidad: "EL PUEBLITO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 3830,
    localidad: "EL PUEBLITO",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3831,
    localidad: "EL PRADO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3832,
    localidad: "EL PRADO",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 3833,
    localidad: "EL POTRERO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3834,
    localidad: "EL PORVENIR",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3835,
    localidad: "EL PORVENIR",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3836,
    localidad: "EL PORVENIR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3837,
    localidad: "EL PORVENIR",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3838,
    localidad: "EL PORTEZUELO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3839,
    localidad: "EL PORONGAL",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3840,
    localidad: "EL PIRUCHO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3841,
    localidad: "EL PINTO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3842,
    localidad: "EL PILAR",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3843,
    localidad: "EL PILAR",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3844,
    localidad: "EL PERU",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3845,
    localidad: "EL PERTIGO",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3846,
    localidad: "EL PERAL",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3847,
    localidad: "EL PARANA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3848,
    localidad: "EL PARAISO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3849,
    localidad: "EL PARAISO",
    cp: 4242,
    provincia_id: 22,
  },
  {
    id: 3850,
    localidad: "EL PARAISO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3851,
    localidad: "EL PALOMAR",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3852,
    localidad: "EL PALOMAR",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3853,
    localidad: "EL PALOMAR",
    cp: 4186,
    provincia_id: 22,
  },
  {
    id: 3854,
    localidad: "EL PALOMAR",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3855,
    localidad: "EL PALOMAR",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3856,
    localidad: "EL PALMAR",
    cp: 3732,
    provincia_id: 22,
  },
  {
    id: 3857,
    localidad: "EL PALMAR",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3858,
    localidad: "EL PALANCHO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3859,
    localidad: "EL P DEL MEDIO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3860,
    localidad: "EL OSO",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3861,
    localidad: "EL OSCURO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3862,
    localidad: "EL ONCE",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3863,
    localidad: "EL OLIVAR",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3864,
    localidad: "EL OJO DE AGUA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3865,
    localidad: "EL OJO DE AGUA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3866,
    localidad: "EL OJO DE AGUA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3867,
    localidad: "EL NOVENTA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3868,
    localidad: "EL NOVENTA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3869,
    localidad: "EL NIAL",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3870,
    localidad: "EL NERIO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 3871,
    localidad: "EL NEGRITO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3872,
    localidad: "EL NARANJO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3873,
    localidad: "EL MULATO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 3874,
    localidad: "EL MOLLE",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3875,
    localidad: "EL MOLAR",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3876,
    localidad: "EL MOJON",
    cp: 3820,
    provincia_id: 22,
  },
  {
    id: 3877,
    localidad: "EL MOJON",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3878,
    localidad: "EL MISTOLITO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3879,
    localidad: "EL MISTOL",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3880,
    localidad: "EL MISTOL",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3881,
    localidad: "EL MISTOL",
    cp: 3752,
    provincia_id: 22,
  },
  {
    id: 3882,
    localidad: "EL MILAGRO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3883,
    localidad: "EL MILAGRO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3884,
    localidad: "EL MATACO",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 3885,
    localidad: "EL MARNE",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 3886,
    localidad: "EL MANANTIAL",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 3887,
    localidad: "EL MALACARA",
    cp: 3761,
    provincia_id: 22,
  },
  {
    id: 3888,
    localidad: "EL JUNCAL",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3889,
    localidad: "EL JUME",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3890,
    localidad: "EL JARDIN",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3891,
    localidad: "EL INDIO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3892,
    localidad: "EL IALITA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3893,
    localidad: "EL HUAICO",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 3894,
    localidad: "EL HOYO",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 3895,
    localidad: "EL GUAYACAN",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 3896,
    localidad: "EL GUANACO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 3897,
    localidad: "EL GRAMILLAR",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3898,
    localidad: "EL GRAMILLAR",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3899,
    localidad: "EL GALPON",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3900,
    localidad: "EL GACHO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3901,
    localidad: "EL FUERTE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3902,
    localidad: "EL FUERTE",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3903,
    localidad: "EL FRAILE",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3904,
    localidad: "EL FLORIDO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3905,
    localidad: "EL FISCO DE FATIMA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3906,
    localidad: "EL FISCO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3907,
    localidad: "EL FISCO",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3908,
    localidad: "EL FAVORITO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 3909,
    localidad: "EL ESTANQUE",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3910,
    localidad: "EL ESCONDIDO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 3911,
    localidad: "EL EMPACHADO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3912,
    localidad: "EL DURAZNO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3913,
    localidad: "EL DORADO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 3914,
    localidad: "EL DIVISADERO",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 3915,
    localidad: "EL DIVISADERO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3916,
    localidad: "EL DIAMANTE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3917,
    localidad: "EL DIAMANTE",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 3918,
    localidad: "EL DIABLO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3919,
    localidad: "EL DESTINO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 3920,
    localidad: "EL DESCANSO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3921,
    localidad: "EL DESCANSO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3922,
    localidad: "EL DEAN",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3923,
    localidad: "EL DEAN",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3924,
    localidad: "EL CUELLO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3925,
    localidad: "EL CUELLO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 3926,
    localidad: "EL CUADRADO",
    cp: 3765,
    provincia_id: 22,
  },
  {
    id: 3927,
    localidad: "EL CRUCERO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3928,
    localidad: "EL CRUCERO",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3929,
    localidad: "EL CRUCERO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 3930,
    localidad: "EL CRUCE KM 659",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3931,
    localidad: "EL CRECE",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 3932,
    localidad: "EL CORRIDO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3933,
    localidad: "EL COLORADO",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 3934,
    localidad: "EL COLMENAR",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3935,
    localidad: "EL CINCUENTA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 3936,
    localidad: "EL CHURQUI",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3937,
    localidad: "EL CHURQUI",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 3938,
    localidad: "EL CHINCHILLAR",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3939,
    localidad: "EL CHARCO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 3940,
    localidad: "EL CHAÑAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3941,
    localidad: "EL CERRO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3942,
    localidad: "EL CERRITO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3943,
    localidad: "EL CERCADO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3944,
    localidad: "EL CENTENARIO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3945,
    localidad: "EL CEBOLLIN",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 3946,
    localidad: "EL CEBOLLIIN",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3947,
    localidad: "EL CARMEN",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3948,
    localidad: "EL CARMEN",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 3949,
    localidad: "EL CARMEN",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 3950,
    localidad: "EL CARMEN",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 3951,
    localidad: "EL CARMEN",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3952,
    localidad: "EL CARMEN",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 3953,
    localidad: "EL CARBON",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3954,
    localidad: "EL CAÑON",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 3955,
    localidad: "EL CANDELERO",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 3956,
    localidad: "EL CANAL",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 3957,
    localidad: "EL CAMPO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3958,
    localidad: "EL CAMBIADO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 3959,
    localidad: "EL CAMBIADO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3960,
    localidad: "EL CAMBIADO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 3961,
    localidad: "EL CAJON",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3962,
    localidad: "EL CAJON",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 3963,
    localidad: "EL CADILLO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3964,
    localidad: "EL CACHI",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3965,
    localidad: "EL CABURE",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 3966,
    localidad: "EL BRAVO",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3967,
    localidad: "EL BRAGADO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3968,
    localidad: "EL BOSQUE",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3969,
    localidad: "EL BORDITO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3970,
    localidad: "EL BOBADAL",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3971,
    localidad: "EL BARRIAL",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 3972,
    localidad: "EL BARRIAL",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 3973,
    localidad: "EL BARRIAL",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3974,
    localidad: "EL BARRIAL",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3975,
    localidad: "EL BAQADO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 3976,
    localidad: "EL BAÑADERO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 3977,
    localidad: "EL BALDECITO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3978,
    localidad: "EL BALDE",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 3979,
    localidad: "EL BAJO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 3980,
    localidad: "EL BAJO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 3981,
    localidad: "EL BAJO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 3982,
    localidad: "EL BAJO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 3983,
    localidad: "EL BACHI",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3984,
    localidad: "EL AYBAL",
    cp: 4400,
    provincia_id: 22,
  },
  {
    id: 3985,
    localidad: "EL ASPIRANTE",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 3986,
    localidad: "EL ARBOLITO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3987,
    localidad: "EL ARBOLITO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3988,
    localidad: "EL ARBOLITO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 3989,
    localidad: "EL ARBOLITO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 3990,
    localidad: "EL ARBOLITO",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 3991,
    localidad: "EL ARBOL DE PIEDRA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 3992,
    localidad: "EL ARBOL DE PIEDRA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3993,
    localidad: "EL AÑIL",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 3994,
    localidad: "EL ALGARROBO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 3995,
    localidad: "EL ALAMBRADO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 3996,
    localidad: "EL ALAMBRADO",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 3997,
    localidad: "EL AIBE",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 3998,
    localidad: "EL AIBALITO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 3999,
    localidad: "EL AIBALITO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4000,
    localidad: "EL AIBAL",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 4001,
    localidad: "EL AIBAL",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 4002,
    localidad: "EL AIBAL",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4003,
    localidad: "EL AIBAL",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4004,
    localidad: "EL AGUILA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4005,
    localidad: "EL AGUILA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4006,
    localidad: "EL AGRICULTOR",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 4007,
    localidad: "EL AEROLITO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4008,
    localidad: "EL ABRA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4009,
    localidad: "EL ABRA",
    cp: 4237,
    provincia_id: 22,
  },
  {
    id: 4010,
    localidad: "EL 49",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4011,
    localidad: "EL 25  SUMAMPA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4012,
    localidad: "EL 21",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 4013,
    localidad: "EA LA VERDE",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4014,
    localidad: "E SAN FRANCISCO",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 4015,
    localidad: "E SALVADOR ALONSO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4016,
    localidad: "E PEDRO ARAOZ",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4017,
    localidad: "E PAMPA MUYOJ",
    cp: 3743,
    provincia_id: 22,
  },
  {
    id: 4018,
    localidad: "E P DE ARRIBA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4019,
    localidad: "E NUEVA ESPERANZA",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4020,
    localidad: "E MARIANO SALAS",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4021,
    localidad: "E MANUEL COSSIO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4022,
    localidad: "E LAS SALINAS",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 4023,
    localidad: "E LA AGUSTINA",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 4024,
    localidad: "E DE ZOOTECNIA B",
    cp: 4000,
    provincia_id: 22,
  },
  {
    id: 4025,
    localidad: "E CRISTOBAL COLON",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4026,
    localidad: "E CAP D F PEREYRA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4027,
    localidad: "E CAP CANDELARIA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4028,
    localidad: "E CAMP EL PLUMERIL",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4029,
    localidad: "E C MOLINA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4030,
    localidad: "E ALVAREZ CONDARCO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4031,
    localidad: "E ALBERTO SOLDATI",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4032,
    localidad: "E ADOLFO ALSINA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4033,
    localidad: "E 14 DE SETIEMBR",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4034,
    localidad: "DURAZNITO",
    cp: 4190,
    provincia_id: 22,
  },
  {
    id: 4035,
    localidad: "DOS VARONES",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4036,
    localidad: "DOS REPRESAS",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4037,
    localidad: "DOS POZOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4038,
    localidad: "DOS HERMANOS",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4039,
    localidad: "DOS HERMANAS",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4040,
    localidad: "DOS HERMANAS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4041,
    localidad: "DOS EULALIAS",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4042,
    localidad: "DOS EULACIAS",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4043,
    localidad: "DORMIDA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4044,
    localidad: "DOÑA LUISA",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4045,
    localidad: "DOÑA LORENZA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4046,
    localidad: "DONADEU",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4047,
    localidad: "DON PIETRO",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 4048,
    localidad: "DON BARTOLO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4049,
    localidad: "DOLORES CENTRAL",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4050,
    localidad: "DOLORES",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4051,
    localidad: "DOLORES",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4052,
    localidad: "DOLORES",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4053,
    localidad: "DOBLE TERO",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4054,
    localidad: "DIVISADERO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 4055,
    localidad: "DIQUE LOS QUIROGA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4056,
    localidad: "DIQUE LOS QUIROGA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4057,
    localidad: "DIQUE FIGUEROA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4058,
    localidad: "DIQUE CHICO",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4059,
    localidad: "DIENTE DEL ARADO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4060,
    localidad: "DIASPA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4061,
    localidad: "DESVIO POZO DULCE",
    cp: 3062,
    provincia_id: 22,
  },
  {
    id: 4062,
    localidad: "DESVIO KM 1342",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4063,
    localidad: "CUYOJ",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 4064,
    localidad: "CUQUERO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4065,
    localidad: "CUQUEÑOS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4066,
    localidad: "CUICHICAÑA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4067,
    localidad: "CUCHI CORRAL",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4068,
    localidad: "CUCHI CORRAL",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4069,
    localidad: "CUATRO BOCAS",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 4070,
    localidad: "CRUZ POZO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4071,
    localidad: "CRUZ POZO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4072,
    localidad: "CRUZ MOJADA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 4073,
    localidad: "CRUZ LOMA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4074,
    localidad: "CRUZ GRANDE",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4075,
    localidad: "CRUZ CHULA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4076,
    localidad: "CRUZ ALTA",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4077,
    localidad: "CRRILLOS DE SAN IS",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4078,
    localidad: "COSTA VIEJA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4079,
    localidad: "COSTA RICA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4080,
    localidad: "CORVALANES",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4081,
    localidad: "CORVALANES",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4082,
    localidad: "CORTADERAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4083,
    localidad: "CORTADERA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4084,
    localidad: "CORRALITO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4085,
    localidad: "CORRAL QUEMADO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4086,
    localidad: "CORRAL GRANDE",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4087,
    localidad: "CORRAL DEL REY",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4088,
    localidad: "CORRAL DEL REY",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4089,
    localidad: "CORRAL DEL REY",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 4090,
    localidad: "CORRAL DE CARCOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4091,
    localidad: "CORRAL DE CARCOS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4092,
    localidad: "COROPAMPA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4093,
    localidad: "COROPAMPA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4094,
    localidad: "CORONEL MANUEL LEONCIO RICO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4095,
    localidad: "CORONEL FERNANDEZ",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4096,
    localidad: "CORONEL BARROS",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 4097,
    localidad: "CORO ABRA",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4098,
    localidad: "CORASPINO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4099,
    localidad: "COPO VIEJO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 4100,
    localidad: "CONZO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4101,
    localidad: "CONTRERAS ESTABLECIMIENTO",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 4102,
    localidad: "CONTRERAS E",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 4103,
    localidad: "CONSULÑOJ",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4104,
    localidad: "CONDOR HUASI",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 4105,
    localidad: "CONCHAYOS",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4106,
    localidad: "CONCEPCION",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4107,
    localidad: "COLONIAS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4108,
    localidad: "COLONIAS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4109,
    localidad: "COLONIA TINCO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4110,
    localidad: "COLONIA SIEGEL",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 4111,
    localidad: "COLONIA SAN JUAN",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4112,
    localidad: "COLONIA PINTO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4113,
    localidad: "COLONIA PAZ",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 4114,
    localidad: "COLONIA PAULA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4115,
    localidad: "COLONIA NRO 2",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4116,
    localidad: "COLONIA MERCEDES",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4117,
    localidad: "COLONIA MEDIA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4118,
    localidad: "COLONIA MEDIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4119,
    localidad: "COLONIA MALGRATTI",
    cp: 3716,
    provincia_id: 22,
  },
  {
    id: 4120,
    localidad: "COLONIA MACKINLAY",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 4121,
    localidad: "COLONIA LA TOTA",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 4122,
    localidad: "COLONIA ISLA",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 4123,
    localidad: "COLONIA ESPAÑA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4124,
    localidad: "COLONIA ERMELINDA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4125,
    localidad: "COLONIA EL SIMBOLAR",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4126,
    localidad: "COLONIA EL PUESTO",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4127,
    localidad: "COLONIA EL PELIGRO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4128,
    localidad: "COLONIA DOS ROSAS",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 4129,
    localidad: "COLONIA DORA",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 4130,
    localidad: "COLONIA ANA",
    cp: 2345,
    provincia_id: 22,
  },
  {
    id: 4131,
    localidad: "COLONIA ALSINA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 4132,
    localidad: "COLOMBIA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4133,
    localidad: "COLLUN-LIOJ",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4134,
    localidad: "COLLUJLIOJ",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4135,
    localidad: "COLLERA HURCUNA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4136,
    localidad: "COLLERA HUIRI",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4137,
    localidad: "COLLERA HUIRCUNA",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 4138,
    localidad: "CODO VIEJO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 4139,
    localidad: "CODO POZO",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4140,
    localidad: "CODO BAJADA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4141,
    localidad: "CODO",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4142,
    localidad: "CODO",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 4143,
    localidad: "CODILLO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4144,
    localidad: "COBAS",
    cp: 4400,
    provincia_id: 22,
  },
  {
    id: 4145,
    localidad: "CNEL MANUEL L RICO",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4146,
    localidad: "CLODOMIRA",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 4147,
    localidad: "CLEVELAND",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4148,
    localidad: "CHURQUI ESQUINA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4149,
    localidad: "CHURQUI",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4150,
    localidad: "CHUÑA PALMA",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4151,
    localidad: "CHUÑA ALBARDON",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4152,
    localidad: "CHUIQUI",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4153,
    localidad: "CHUIQUI",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4154,
    localidad: "CHUCHI",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4155,
    localidad: "CHOYA",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 4156,
    localidad: "CHIRA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4157,
    localidad: "CHILQUITAS",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4158,
    localidad: "CHILQUITA I",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4159,
    localidad: "CHILQUITA",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4160,
    localidad: "CHILPA MAYO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4161,
    localidad: "CHILPA MACHO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4162,
    localidad: "CHILENO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 4163,
    localidad: "CHILE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4164,
    localidad: "CHILCAS LA LOMA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4165,
    localidad: "CHILCA JULIANA",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 4166,
    localidad: "CHILCA ALBARDON",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4167,
    localidad: "CHILCA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4168,
    localidad: "CHAVES",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4169,
    localidad: "CHAVARRIA",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 4170,
    localidad: "CHAUPI POZO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4171,
    localidad: "CHAUCHILLAS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4172,
    localidad: "CHARQUINA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4173,
    localidad: "CHARCO VIEJO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 4174,
    localidad: "CHAÑARITOS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4175,
    localidad: "CHAÑARES ALTOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4176,
    localidad: "CHAÑARES ALTOS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4177,
    localidad: "CHAÑAR YACO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4178,
    localidad: "CHAÑAR SUNICHAJ",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 4179,
    localidad: "CHAÑAR PZO D ABAJO",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4180,
    localidad: "CHAÑAR PUJIO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4181,
    localidad: "CHAÑAR PUJIO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4182,
    localidad: "CHAÑAR POZO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4183,
    localidad: "CHAÑAR POZO",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 4184,
    localidad: "CHAÑAR POZO",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4185,
    localidad: "CHAÑAR POZO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4186,
    localidad: "CHAÑAR POZO",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 4187,
    localidad: "CHAÑAR POCITO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4188,
    localidad: "CHAÑAR LAGUNA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4189,
    localidad: "CHAÑAR ESQUINA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4190,
    localidad: "CHAÑAR BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4191,
    localidad: "CHANCHILLOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4192,
    localidad: "CHAINIMA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4193,
    localidad: "CHAGUAR PUNCU",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4194,
    localidad: "CHACRAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4195,
    localidad: "CHACRAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4196,
    localidad: "CEVIL POZO",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4197,
    localidad: "CERRO RICO",
    cp: 4231,
    provincia_id: 22,
  },
  {
    id: 4198,
    localidad: "CERRITO",
    cp: 5258,
    provincia_id: 22,
  },
  {
    id: 4199,
    localidad: "CERRILLOS",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4200,
    localidad: "CERRILLOS",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4201,
    localidad: "CERRILLO",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 4202,
    localidad: "CERCO CUATRO",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4203,
    localidad: "CENTRAL DOLORES",
    cp: 3745,
    provincia_id: 22,
  },
  {
    id: 4204,
    localidad: "CELESTINA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4205,
    localidad: "CEJOLAO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4206,
    localidad: "CEJAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4207,
    localidad: "CEJA POZO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4208,
    localidad: "CEJA POZO",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4209,
    localidad: "CAZADORES",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4210,
    localidad: "CAVADO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4211,
    localidad: "CAVADO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4212,
    localidad: "CAVADITO",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4213,
    localidad: "CAVADITO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4214,
    localidad: "CATORCE QUEBRACHOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4215,
    localidad: "CATITA",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 4216,
    localidad: "CASTELLIN",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4217,
    localidad: "CASPI CORRAL",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4218,
    localidad: "CASILLA DEL MEDIO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 4219,
    localidad: "CASILLA DEL MEDIO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 4220,
    localidad: "CASHICO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 4221,
    localidad: "CASARES",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 4222,
    localidad: "CASA VERDE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4223,
    localidad: "CASA SANTA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4224,
    localidad: "CASA DE DIOS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4225,
    localidad: "CASA ALTA",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 4226,
    localidad: "CARTAVIO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4227,
    localidad: "CARTAVIO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4228,
    localidad: "CARRETERO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4229,
    localidad: "CARRERA VIEJA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4230,
    localidad: "CARDOZOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4231,
    localidad: "CARDON ESQUINA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4232,
    localidad: "CARDAJAL",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4233,
    localidad: "CARDAJAL",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 4234,
    localidad: "CARBON POZO",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4235,
    localidad: "CARBON POZO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4236,
    localidad: "CARANCHI YACO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4237,
    localidad: "CAPILLA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4238,
    localidad: "CAÑITAS",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4239,
    localidad: "CAÑAS PASO",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 4240,
    localidad: "CAÑADA TALA POZO",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 4241,
    localidad: "CAÑADA SAN RAMON",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 4242,
    localidad: "CAÑADA RICA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4243,
    localidad: "CAÑADA LIMPIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4244,
    localidad: "CAÑADA LIMPIA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4245,
    localidad: "CAÑADA HONDA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4246,
    localidad: "CAÑADA ESCOBAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4247,
    localidad: "CAÑADA DEL MEDIO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4248,
    localidad: "CAÑADA DE LA CRUZ",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4249,
    localidad: "CAÑADA DE LA COSTA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4250,
    localidad: "CAÑADA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4251,
    localidad: "CANTEROS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4252,
    localidad: "CANTERA LOS VIERAS",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 4253,
    localidad: "CANTAMAMPA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4254,
    localidad: "CANEINOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4255,
    localidad: "CANDELARIA",
    cp: 5249,
    provincia_id: 22,
  },
  {
    id: 4256,
    localidad: "CANDELARIA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4257,
    localidad: "CANDELARIA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4258,
    localidad: "CANDELARIA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4259,
    localidad: "CANCINOS",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4260,
    localidad: "CANARIO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 4261,
    localidad: "CAMPO VERDE",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4262,
    localidad: "CAMPO VERDE",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4263,
    localidad: "CAMPO VERDE",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4264,
    localidad: "CAMPO VERDE",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4265,
    localidad: "CAMPO VERDE",
    cp: 4351,
    provincia_id: 22,
  },
  {
    id: 4266,
    localidad: "CAMPO RICO",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4267,
    localidad: "CAMPO RICO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4268,
    localidad: "CAMPO NUEVO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4269,
    localidad: "CAMPO MORENO",
    cp: 3734,
    provincia_id: 22,
  },
  {
    id: 4270,
    localidad: "CAMPO LIMPIO",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4271,
    localidad: "CAMPO LIMPIO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4272,
    localidad: "CAMPO LA ANGELITA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4273,
    localidad: "CAMPO GRANDE",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 4274,
    localidad: "CAMPO GRANDE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4275,
    localidad: "CAMPO GRANDE",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4276,
    localidad: "CAMPO GALLO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 4277,
    localidad: "CAMPO EL ROSARIO",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4278,
    localidad: "CAMPO DEL INFIERNO",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4279,
    localidad: "CAMPO DEL CISNE",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4280,
    localidad: "CAMPO DEL CIELO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4281,
    localidad: "CAMPO DEL AGUILA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4282,
    localidad: "CAMPO DE AMOR",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4283,
    localidad: "CAMPO DE AMOR",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4284,
    localidad: "CAMPO BOTTO",
    cp: 2345,
    provincia_id: 22,
  },
  {
    id: 4285,
    localidad: "CAMPO AMARILLO",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 4286,
    localidad: "CAMPO ALEGRE",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4287,
    localidad: "CAMPO ALEGRE",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4288,
    localidad: "CAMPO ALEGRE",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4289,
    localidad: "CAMPO ALEGRE",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4290,
    localidad: "CALOJ",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4291,
    localidad: "CALLEJON BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4292,
    localidad: "CALERAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4293,
    localidad: "CALDERON",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4294,
    localidad: "CAJON",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4295,
    localidad: "CAJON",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4296,
    localidad: "CACHICO",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 4297,
    localidad: "CACHI YACO",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 4298,
    localidad: "CACHI",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4299,
    localidad: "CACHI",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4300,
    localidad: "CABRA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4301,
    localidad: "C.CHRISTIERNSON",
    cp: 5257,
    provincia_id: 22,
  },
  {
    id: 4302,
    localidad: "C S. ROSA AGUIRRE",
    cp: 2357,
    provincia_id: 22,
  },
  {
    id: 4303,
    localidad: "C RAMON LAPLACE",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4304,
    localidad: "C INDEPENDENCIA",
    cp: 3066,
    provincia_id: 22,
  },
  {
    id: 4305,
    localidad: "C EL SIMBOLAR",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4306,
    localidad: "BUSTAMANTE",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4307,
    localidad: "BURRO POZO",
    cp: 4317,
    provincia_id: 22,
  },
  {
    id: 4308,
    localidad: "BURRA HUAÑUNA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4309,
    localidad: "BURRA HUAÑAUANA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4310,
    localidad: "BUEY RODEO",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4311,
    localidad: "BUEY MUERTO",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 4312,
    localidad: "BUEY MUERTO",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 4313,
    localidad: "BUENOS AIRES",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4314,
    localidad: "BUENA VISTA",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4315,
    localidad: "BUENA VISTA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4316,
    localidad: "BUENA VISTA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4317,
    localidad: "BUENA VISTA",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4318,
    localidad: "BUENA VISTA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4319,
    localidad: "BUENA VISTA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4320,
    localidad: "BUENA ESPERANZA",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4321,
    localidad: "BUEN LUGAR",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4322,
    localidad: "BUEN LUGAR",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4323,
    localidad: "BREALOJ",
    cp: 4328,
    provincia_id: 22,
  },
  {
    id: 4324,
    localidad: "BREA PUÑUNA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4325,
    localidad: "BREA POZO VIEJO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4326,
    localidad: "BREA POZO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4327,
    localidad: "BREA CHIMPANA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4328,
    localidad: "BRANDAN",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4329,
    localidad: "BRACHO VIEJO",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4330,
    localidad: "BRACHO",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 4331,
    localidad: "BOTIJA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4332,
    localidad: "BORDO PAMPA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4333,
    localidad: "BOQUERON",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4334,
    localidad: "BOCA DEL TIGRE",
    cp: 4306,
    provincia_id: 22,
  },
  {
    id: 4335,
    localidad: "BOBADAL",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4336,
    localidad: "BLANCO POZO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4337,
    localidad: "BLANCA POZO",
    cp: 4332,
    provincia_id: 22,
  },
  {
    id: 4338,
    localidad: "BLANCA",
    cp: 4324,
    provincia_id: 22,
  },
  {
    id: 4339,
    localidad: "BLANCA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 4340,
    localidad: "BINAL ESQUINA",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 4341,
    localidad: "BELTRANLORETO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4342,
    localidad: "BELTRAN LORETO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4343,
    localidad: "BELTRAN",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 4344,
    localidad: "BELLA VISTA",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4345,
    localidad: "BELLA VISTA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4346,
    localidad: "BELLA VISTA",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4347,
    localidad: "BELLA VISTA",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4348,
    localidad: "BELLA VISTA",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4349,
    localidad: "BELGRANO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4350,
    localidad: "BELGRANO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4351,
    localidad: "BELGRANO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4352,
    localidad: "BELGICA",
    cp: 3712,
    provincia_id: 22,
  },
  {
    id: 4353,
    localidad: "BEJAN",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4354,
    localidad: "BEBIDAS",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4355,
    localidad: "BAYO MUERTO",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 4356,
    localidad: "BAUMAN",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4357,
    localidad: "BARROSA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4358,
    localidad: "BARRIO VILLA COHESA",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 4359,
    localidad: "BARRIO V COHESA",
    cp: 4200,
    provincia_id: 22,
  },
  {
    id: 4360,
    localidad: "BARRIO OBRERO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4361,
    localidad: "BARRIO JARDIN",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4362,
    localidad: "BARRIO HUACO HONDO",
    cp: 4202,
    provincia_id: 22,
  },
  {
    id: 4363,
    localidad: "BARRIO ALGARROBO",
    cp: 3474,
    provincia_id: 22,
  },
  {
    id: 4364,
    localidad: "BARRIALITO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4365,
    localidad: "BARRIALITO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4366,
    localidad: "BARRIAL ALTO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4367,
    localidad: "BARRANQUERAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4368,
    localidad: "BARRANCAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4369,
    localidad: "BARRANCAS",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4370,
    localidad: "BARRANCAS",
    cp: 4319,
    provincia_id: 22,
  },
  {
    id: 4371,
    localidad: "BARRANCA COLORADA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4372,
    localidad: "BANEGAS",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4373,
    localidad: "BANDERA BAJADA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4374,
    localidad: "BANDERA BAJADA",
    cp: 4308,
    provincia_id: 22,
  },
  {
    id: 4375,
    localidad: "BANDERA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4376,
    localidad: "BANDERA",
    cp: 3064,
    provincia_id: 22,
  },
  {
    id: 4377,
    localidad: "BALDE",
    cp: 5260,
    provincia_id: 22,
  },
  {
    id: 4378,
    localidad: "BALBUENA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4379,
    localidad: "BAJO VERDE",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 4380,
    localidad: "BAJO LAS PIEDRAS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4381,
    localidad: "BAJO LAS PIEDRAS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4382,
    localidad: "BAJO HONDO",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4383,
    localidad: "BAJO HONDO",
    cp: 4356,
    provincia_id: 22,
  },
  {
    id: 4384,
    localidad: "BAJO HONDO",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4385,
    localidad: "BAJO GRANDE",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4386,
    localidad: "BAJO GRANDE",
    cp: 4338,
    provincia_id: 22,
  },
  {
    id: 4387,
    localidad: "BAJO GRANDE",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4388,
    localidad: "BAJADITA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4389,
    localidad: "BAJADITA",
    cp: 4315,
    provincia_id: 22,
  },
  {
    id: 4390,
    localidad: "BAHOMA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4391,
    localidad: "BAHIA BLANCA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4392,
    localidad: "BAEZ",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4393,
    localidad: "BABILONIA",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4394,
    localidad: "B DE LOS ESPINOSA",
    cp: 5209,
    provincia_id: 22,
  },
  {
    id: 4395,
    localidad: "AZUCENA",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4396,
    localidad: "AZOGASTA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4397,
    localidad: "AYUNCHA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4398,
    localidad: "AYAPASO",
    cp: 4235,
    provincia_id: 22,
  },
  {
    id: 4399,
    localidad: "AVERIAS",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 4400,
    localidad: "AVE MARIA",
    cp: 4326,
    provincia_id: 22,
  },
  {
    id: 4401,
    localidad: "ATOJ POZO",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4402,
    localidad: "ATAHUALPA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4403,
    localidad: "ASPA SINCHI",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4404,
    localidad: "ASPA SINCHI",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4405,
    localidad: "ARRUFO",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 4406,
    localidad: "ARROYO TALA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4407,
    localidad: "ARRAGA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4408,
    localidad: "ARMONIA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4409,
    localidad: "ARGENTINA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4410,
    localidad: "ARGENTINA",
    cp: 2354,
    provincia_id: 22,
  },
  {
    id: 4411,
    localidad: "ARENALES",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4412,
    localidad: "AREAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4413,
    localidad: "ARDILES D LA COSTA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4414,
    localidad: "ARDILES D L COSTA",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4415,
    localidad: "ARDILES",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4416,
    localidad: "ARBOLITOS",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4417,
    localidad: "ARBOLITOS",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4418,
    localidad: "ARBOLITOS",
    cp: 4354,
    provincia_id: 22,
  },
  {
    id: 4419,
    localidad: "ARBOLITOS",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4420,
    localidad: "ARBOLES VERDES",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4421,
    localidad: "ARBOLES GRANDES",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4422,
    localidad: "ARBOL SOLO",
    cp: 5253,
    provincia_id: 22,
  },
  {
    id: 4423,
    localidad: "ARBOL SOLO",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4424,
    localidad: "ARBOL SOLO",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4425,
    localidad: "ARBOL NEGRO",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4426,
    localidad: "ARAGONES",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4427,
    localidad: "AÑIL",
    cp: 4184,
    provincia_id: 22,
  },
  {
    id: 4428,
    localidad: "AÑATUYA",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 4429,
    localidad: "ANTUCO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4430,
    localidad: "ANTILO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4431,
    localidad: "ANTAJE",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4432,
    localidad: "ANTA CHICA",
    cp: 4187,
    provincia_id: 22,
  },
  {
    id: 4433,
    localidad: "ANJULI",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4434,
    localidad: "ANIMAS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4435,
    localidad: "ANGA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4436,
    localidad: "ANCOCHE",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4437,
    localidad: "ANCOCHA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4438,
    localidad: "ANCHORIGA",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4439,
    localidad: "ANCHILO",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4440,
    localidad: "ANCHANGA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4441,
    localidad: "ANCAJAN",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 4442,
    localidad: "ANCA",
    cp: 4321,
    provincia_id: 22,
  },
  {
    id: 4443,
    localidad: "AMPA",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4444,
    localidad: "AMOLADERAS",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4445,
    localidad: "AMIMPA",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4446,
    localidad: "AMIMAN",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4447,
    localidad: "AMICHA",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4448,
    localidad: "AMBARGASTA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4449,
    localidad: "AMAPOLA",
    cp: 4223,
    provincia_id: 22,
  },
  {
    id: 4450,
    localidad: "AMAMA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4451,
    localidad: "ALZA NUEVA",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4452,
    localidad: "ALTO VERDE",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4453,
    localidad: "ALTO POZO",
    cp: 4322,
    provincia_id: 22,
  },
  {
    id: 4454,
    localidad: "ALTO POZO",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4455,
    localidad: "ALTO DEL PUSTO",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4456,
    localidad: "ALTO BELLO",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4457,
    localidad: "ALTO ALEGRE",
    cp: 4230,
    provincia_id: 22,
  },
  {
    id: 4458,
    localidad: "ALTILLO DEL MEDIO",
    cp: 5274,
    provincia_id: 22,
  },
  {
    id: 4459,
    localidad: "ALTA GRACIA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4460,
    localidad: "ALTA GRACIA",
    cp: 4203,
    provincia_id: 22,
  },
  {
    id: 4461,
    localidad: "ALPAPUCA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4462,
    localidad: "ALPA PUCA",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4463,
    localidad: "ALHUAMPA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4464,
    localidad: "ALGARROBO",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4465,
    localidad: "ALGARROBALES",
    cp: 4304,
    provincia_id: 22,
  },
  {
    id: 4466,
    localidad: "ALGARROBAL VIEJO",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4467,
    localidad: "ALEJITO",
    cp: 4350,
    provincia_id: 22,
  },
  {
    id: 4468,
    localidad: "ALDERETES",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4469,
    localidad: "ALBERDI",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 4470,
    localidad: "ALBARDON CHUÑA",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4471,
    localidad: "ALBARDON",
    cp: 4208,
    provincia_id: 22,
  },
  {
    id: 4472,
    localidad: "ALARCON",
    cp: 2356,
    provincia_id: 22,
  },
  {
    id: 4473,
    localidad: "ALABAMA NUEVA",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4474,
    localidad: "AIBALITO",
    cp: 3747,
    provincia_id: 22,
  },
  {
    id: 4475,
    localidad: "AIBALITO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4476,
    localidad: "AIBAL",
    cp: 4353,
    provincia_id: 22,
  },
  {
    id: 4477,
    localidad: "AIBAL",
    cp: 3740,
    provincia_id: 22,
  },
  {
    id: 4478,
    localidad: "AHI VEREMOS",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4479,
    localidad: "AHI VEREMOS",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4480,
    localidad: "AHI VEREMOS",
    cp: 4300,
    provincia_id: 22,
  },
  {
    id: 4481,
    localidad: "AGUSTINA LIBARONA",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4482,
    localidad: "AGUJEREADO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4483,
    localidad: "AGUAS COLORADAS",
    cp: 4301,
    provincia_id: 22,
  },
  {
    id: 4484,
    localidad: "AGUADITA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4485,
    localidad: "AGUADA",
    cp: 4220,
    provincia_id: 22,
  },
  {
    id: 4486,
    localidad: "AGUA TURBIA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4487,
    localidad: "AGUA TURBIA",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4488,
    localidad: "AGUA SALADA",
    cp: 3736,
    provincia_id: 22,
  },
  {
    id: 4489,
    localidad: "AGUA PINTADA",
    cp: 5212,
    provincia_id: 22,
  },
  {
    id: 4490,
    localidad: "AGUA DULCE",
    cp: 3766,
    provincia_id: 22,
  },
  {
    id: 4491,
    localidad: "AGUA CALIENTE",
    cp: 5251,
    provincia_id: 22,
  },
  {
    id: 4492,
    localidad: "AGUA BUENA",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4493,
    localidad: "AGUA BLANCA",
    cp: 5250,
    provincia_id: 22,
  },
  {
    id: 4494,
    localidad: "AGUA BLANCA",
    cp: 3749,
    provincia_id: 22,
  },
  {
    id: 4495,
    localidad: "AGUA AZUL",
    cp: 4189,
    provincia_id: 22,
  },
  {
    id: 4496,
    localidad: "AGUA AMARGA",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4497,
    localidad: "AEROLITO",
    cp: 3741,
    provincia_id: 22,
  },
  {
    id: 4498,
    localidad: "ACOSTA",
    cp: 4302,
    provincia_id: 22,
  },
  {
    id: 4499,
    localidad: "ACOS",
    cp: 4176,
    provincia_id: 22,
  },
  {
    id: 4500,
    localidad: "ACHAVAL RODRIGUEZ",
    cp: 2344,
    provincia_id: 22,
  },
  {
    id: 4501,
    localidad: "ABRITA GRANDE",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4502,
    localidad: "ABRITA CHICA",
    cp: 4201,
    provincia_id: 22,
  },
  {
    id: 4503,
    localidad: "ABRITA",
    cp: 4206,
    provincia_id: 22,
  },
  {
    id: 4504,
    localidad: "ABRAS DEL MEDIO",
    cp: 4233,
    provincia_id: 22,
  },
  {
    id: 4505,
    localidad: "ABRA GRANDE",
    cp: 4336,
    provincia_id: 22,
  },
  {
    id: 4506,
    localidad: "ABRA DE QUIMIL",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4507,
    localidad: "ABRA DE LA CRUZ",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4508,
    localidad: "A DL MARTIRIZADO",
    cp: 4225,
    provincia_id: 22,
  },
  {
    id: 4509,
    localidad: "9 DE JULIO",
    cp: 5255,
    provincia_id: 22,
  },
  {
    id: 4510,
    localidad: "9 DE JULIO",
    cp: 3714,
    provincia_id: 22,
  },
  {
    id: 4511,
    localidad: "9 DE JULIO",
    cp: 4238,
    provincia_id: 22,
  },
  {
    id: 4512,
    localidad: "7 DE ABRIL",
    cp: 4197,
    provincia_id: 22,
  },
  {
    id: 4513,
    localidad: "3 POZOS",
    cp: 4178,
    provincia_id: 22,
  },
  {
    id: 4514,
    localidad: "3 JAZMINES",
    cp: 4313,
    provincia_id: 22,
  },
  {
    id: 4515,
    localidad: "28 DE MARZO",
    cp: 3760,
    provincia_id: 22,
  },
  {
    id: 4516,
    localidad: "25 DE MAYO SUD",
    cp: 4231,
    provincia_id: 22,
  },
  {
    id: 4517,
    localidad: "25 DE MAYO",
    cp: 4231,
    provincia_id: 22,
  },
  {
    id: 4518,
    localidad: "25 DE MAYO",
    cp: 4352,
    provincia_id: 22,
  },
  {
    id: 4519,
    localidad: "25 DE MAY-BARNEGAS",
    cp: 4205,
    provincia_id: 22,
  },
  {
    id: 4520,
    localidad: "ZENON PEREYRA",
    cp: 2409,
    provincia_id: 21,
  },
  {
    id: 4521,
    localidad: "ZAVALLA",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 4522,
    localidad: "ZAVALLA",
    cp: 2125,
    provincia_id: 21,
  },
  {
    id: 4523,
    localidad: "ZANETTI",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 4524,
    localidad: "ZADOCKHAN",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 4525,
    localidad: "YAMANDU",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4526,
    localidad: "YAGUARETE",
    cp: 3586,
    provincia_id: 21,
  },
  {
    id: 4527,
    localidad: "WILDERMUTH",
    cp: 2257,
    provincia_id: 21,
  },
  {
    id: 4528,
    localidad: "WHEELWRIGHT",
    cp: 2722,
    provincia_id: 21,
  },
  {
    id: 4529,
    localidad: "WALVELBERG",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 4530,
    localidad: "VUELTA DEL PIRATA",
    cp: 3058,
    provincia_id: 21,
  },
  {
    id: 4531,
    localidad: "VIRGINIA",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 4532,
    localidad: "VILLADA",
    cp: 2176,
    provincia_id: 21,
  },
  {
    id: 4533,
    localidad: "VILLADA",
    cp: 2173,
    provincia_id: 21,
  },
  {
    id: 4534,
    localidad: "VILLA VIVEROS",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4535,
    localidad: "VILLA TRINIDAD",
    cp: 2345,
    provincia_id: 21,
  },
  {
    id: 4536,
    localidad: "VILLA SARALEGUI",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4537,
    localidad: "VILLA SAN JOSE",
    cp: 2323,
    provincia_id: 21,
  },
  {
    id: 4538,
    localidad: "VILLA SAN DIEGO",
    cp: 2124,
    provincia_id: 21,
  },
  {
    id: 4539,
    localidad: "VILLA ROSELLO",
    cp: 6100,
    provincia_id: 21,
  },
  {
    id: 4540,
    localidad: "VILLA PORUCCI",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 4541,
    localidad: "VILLA OCAMPO",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 4542,
    localidad: "VILLA MUGUETA",
    cp: 2175,
    provincia_id: 21,
  },
  {
    id: 4543,
    localidad: "VILLA MINETTI",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 4544,
    localidad: "VILLA MARIA SELVA",
    cp: 3000,
    provincia_id: 21,
  },
  {
    id: 4545,
    localidad: "VILLA MARGARITA",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 4546,
    localidad: "VILLA LUJAN",
    cp: 3016,
    provincia_id: 21,
  },
  {
    id: 4547,
    localidad: "VILLA LASTENIA",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 4548,
    localidad: "VILLA LA RIBERA",
    cp: 2500,
    provincia_id: 21,
  },
  {
    id: 4549,
    localidad: "VILLA GUILLERMINA",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 4550,
    localidad: "VILLA GUASTALLA",
    cp: 2148,
    provincia_id: 21,
  },
  {
    id: 4551,
    localidad: "VILLA GOBERNADOR GALVEZ",
    cp: 2124,
    provincia_id: 21,
  },
  {
    id: 4552,
    localidad: "VILLA GARIBALDI",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 4553,
    localidad: "VILLA ESTELA",
    cp: 2600,
    provincia_id: 21,
  },
  {
    id: 4554,
    localidad: "VILLA ELOISA",
    cp: 2503,
    provincia_id: 21,
  },
  {
    id: 4555,
    localidad: "VILLA DIVISA DE MAYO",
    cp: 2631,
    provincia_id: 21,
  },
  {
    id: 4556,
    localidad: "VILLA CONSTITUCION",
    cp: 2919,
    provincia_id: 21,
  },
  {
    id: 4557,
    localidad: "VILLA CASSINI",
    cp: 2154,
    provincia_id: 21,
  },
  {
    id: 4558,
    localidad: "VILLA CAÑAS",
    cp: 2607,
    provincia_id: 21,
  },
  {
    id: 4559,
    localidad: "VILLA BIOTA",
    cp: 2147,
    provincia_id: 21,
  },
  {
    id: 4560,
    localidad: "VILLA ANGELICA",
    cp: 2000,
    provincia_id: 21,
  },
  {
    id: 4561,
    localidad: "VILLA ANA",
    cp: 3583,
    provincia_id: 21,
  },
  {
    id: 4562,
    localidad: "VILLA AMELIA",
    cp: 2102,
    provincia_id: 21,
  },
  {
    id: 4563,
    localidad: "VILLA ADELA",
    cp: 3581,
    provincia_id: 21,
  },
  {
    id: 4564,
    localidad: "VILLA ADELA",
    cp: 3596,
    provincia_id: 21,
  },
  {
    id: 4565,
    localidad: "VILA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 4566,
    localidad: "VILA",
    cp: 2306,
    provincia_id: 21,
  },
  {
    id: 4567,
    localidad: "VIDELA",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 4568,
    localidad: "VICUQA MACKENNA",
    cp: 6140,
    provincia_id: 21,
  },
  {
    id: 4569,
    localidad: "VICENTE ECHEVERRIA",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 4570,
    localidad: "VERA Y PINTADO",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 4571,
    localidad: "VERA MUJICA",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 4572,
    localidad: "VERA",
    cp: 3550,
    provincia_id: 21,
  },
  {
    id: 4573,
    localidad: "VENADO TUERTO",
    cp: 2600,
    provincia_id: 21,
  },
  {
    id: 4574,
    localidad: "VEINTICUATRO CASAS",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 4575,
    localidad: "V MANUEL SEGUNDO",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 4576,
    localidad: "URQUIZA",
    cp: 2724,
    provincia_id: 21,
  },
  {
    id: 4577,
    localidad: "URANGA",
    cp: 2112,
    provincia_id: 21,
  },
  {
    id: 4578,
    localidad: "URANGA",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 4579,
    localidad: "TTE H BOUCHARD",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 4580,
    localidad: "TRES POZOS",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 4581,
    localidad: "TRES ESQUINAS",
    cp: 2921,
    provincia_id: 21,
  },
  {
    id: 4582,
    localidad: "TRES COLONIAS",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 4583,
    localidad: "TRES BOCAS",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 4584,
    localidad: "TRAILL",
    cp: 2456,
    provincia_id: 21,
  },
  {
    id: 4585,
    localidad: "TOTORAS",
    cp: 2144,
    provincia_id: 21,
  },
  {
    id: 4586,
    localidad: "TOSTADO",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 4587,
    localidad: "TORTUGAS",
    cp: 2512,
    provincia_id: 21,
  },
  {
    id: 4588,
    localidad: "TOMAS ALVA EDISON",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 4589,
    localidad: "TOBA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4590,
    localidad: "TIRO FEDERAL",
    cp: 3100,
    provincia_id: 21,
  },
  {
    id: 4591,
    localidad: "TIMBUES JOSE MARIA",
    cp: 2204,
    provincia_id: 21,
  },
  {
    id: 4592,
    localidad: "TIMBUES",
    cp: 2204,
    provincia_id: 21,
  },
  {
    id: 4593,
    localidad: "THEOBALD",
    cp: 2922,
    provincia_id: 21,
  },
  {
    id: 4594,
    localidad: "THEOBAD",
    cp: 2118,
    provincia_id: 21,
  },
  {
    id: 4595,
    localidad: "TEODELINA",
    cp: 6009,
    provincia_id: 21,
  },
  {
    id: 4596,
    localidad: "TARTAGAL",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 4597,
    localidad: "TAIS",
    cp: 2535,
    provincia_id: 21,
  },
  {
    id: 4598,
    localidad: "TACURAL",
    cp: 2324,
    provincia_id: 21,
  },
  {
    id: 4599,
    localidad: "TACUARENDI",
    cp: 3588,
    provincia_id: 21,
  },
  {
    id: 4600,
    localidad: "TACUARENDI",
    cp: 3587,
    provincia_id: 21,
  },
  {
    id: 4601,
    localidad: "SUSANA",
    cp: 2302,
    provincia_id: 21,
  },
  {
    id: 4602,
    localidad: "SUNCHALES",
    cp: 2322,
    provincia_id: 21,
  },
  {
    id: 4603,
    localidad: "SUARDI",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 4604,
    localidad: "STEPHENSON",
    cp: 2103,
    provincia_id: 21,
  },
  {
    id: 4605,
    localidad: "SOUTO MAYOR",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4606,
    localidad: "SOLEDAD",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4607,
    localidad: "SOLDINI",
    cp: 2107,
    provincia_id: 21,
  },
  {
    id: 4608,
    localidad: "SOLDINI",
    cp: 2108,
    provincia_id: 21,
  },
  {
    id: 4609,
    localidad: "SOLALE",
    cp: 6064,
    provincia_id: 21,
  },
  {
    id: 4610,
    localidad: "SIN PEREZA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 4611,
    localidad: "SIERRA PEREYRA",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 4612,
    localidad: "SETUBAL",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4613,
    localidad: "SERODINO",
    cp: 2216,
    provincia_id: 21,
  },
  {
    id: 4614,
    localidad: "SEMINO",
    cp: 2138,
    provincia_id: 21,
  },
  {
    id: 4615,
    localidad: "SCHIFFNER",
    cp: 2451,
    provincia_id: 21,
  },
  {
    id: 4616,
    localidad: "SAUCE VIEJO",
    cp: 3017,
    provincia_id: 21,
  },
  {
    id: 4617,
    localidad: "SAUCE VIEJO",
    cp: 3034,
    provincia_id: 21,
  },
  {
    id: 4618,
    localidad: "SASTRE",
    cp: 2440,
    provincia_id: 21,
  },
  {
    id: 4619,
    localidad: "SARMIENTO",
    cp: 3024,
    provincia_id: 21,
  },
  {
    id: 4620,
    localidad: "SARGENTO CABRAL",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 4621,
    localidad: "SARGENTO CABRAL",
    cp: 2106,
    provincia_id: 21,
  },
  {
    id: 4622,
    localidad: "SANTURCE",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 4623,
    localidad: "SANTO TOME",
    cp: 3016,
    provincia_id: 21,
  },
  {
    id: 4624,
    localidad: "SANTO DOMINGO",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4625,
    localidad: "SANTA TERESA",
    cp: 6106,
    provincia_id: 21,
  },
  {
    id: 4626,
    localidad: "SANTA TERESA",
    cp: 2609,
    provincia_id: 21,
  },
  {
    id: 4627,
    localidad: "SANTA TERESA",
    cp: 2111,
    provincia_id: 21,
  },
  {
    id: 4628,
    localidad: "SANTA ROSA DE CALCHINES",
    cp: 3022,
    provincia_id: 21,
  },
  {
    id: 4629,
    localidad: "SANTA ROSA",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4630,
    localidad: "SANTA REGINA",
    cp: 6107,
    provincia_id: 21,
  },
  {
    id: 4631,
    localidad: "SANTA NATALIA",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 4632,
    localidad: "SANTA MARIA",
    cp: 3011,
    provincia_id: 21,
  },
  {
    id: 4633,
    localidad: "SANTA MARGARITA",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 4634,
    localidad: "SANTA MARGARITA",
    cp: 3068,
    provincia_id: 21,
  },
  {
    id: 4635,
    localidad: "SANTA LUCIA",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 4636,
    localidad: "SANTA LUCIA",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 4637,
    localidad: "SANTA ISABEL",
    cp: 2605,
    provincia_id: 21,
  },
  {
    id: 4638,
    localidad: "SANTA FELICIA",
    cp: 2495,
    provincia_id: 21,
  },
  {
    id: 4639,
    localidad: "SANTA FE",
    cp: 3006,
    provincia_id: 21,
  },
  {
    id: 4640,
    localidad: "SANTA FE",
    cp: 3002,
    provincia_id: 21,
  },
  {
    id: 4641,
    localidad: "SANTA FE",
    cp: 3015,
    provincia_id: 21,
  },
  {
    id: 4642,
    localidad: "SANTA FE",
    cp: 3004,
    provincia_id: 21,
  },
  {
    id: 4643,
    localidad: "SANTA FE",
    cp: 3008,
    provincia_id: 21,
  },
  {
    id: 4644,
    localidad: "SANTA FE",
    cp: 3000,
    provincia_id: 21,
  },
  {
    id: 4645,
    localidad: "SANTA EMILIA",
    cp: 2725,
    provincia_id: 21,
  },
  {
    id: 4646,
    localidad: "SANTA CRISTINA",
    cp: 6134,
    provincia_id: 21,
  },
  {
    id: 4647,
    localidad: "SANTA CLARA DE SAGUIER",
    cp: 2405,
    provincia_id: 21,
  },
  {
    id: 4648,
    localidad: "SANTA CLARA DE BUENA VISTA",
    cp: 2258,
    provincia_id: 21,
  },
  {
    id: 4649,
    localidad: "SANTA CLARA",
    cp: 2258,
    provincia_id: 21,
  },
  {
    id: 4650,
    localidad: "SANTA ANA",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 4651,
    localidad: "SANFORD",
    cp: 2174,
    provincia_id: 21,
  },
  {
    id: 4652,
    localidad: "SANFORD",
    cp: 2173,
    provincia_id: 21,
  },
  {
    id: 4653,
    localidad: "SANCTI SPIRITU",
    cp: 2617,
    provincia_id: 21,
  },
  {
    id: 4654,
    localidad: "SAN VICENTE",
    cp: 2447,
    provincia_id: 21,
  },
  {
    id: 4655,
    localidad: "SAN ROQUE",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 4656,
    localidad: "SAN RICARDO",
    cp: 2501,
    provincia_id: 21,
  },
  {
    id: 4657,
    localidad: "SAN PEDRO SUR",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4658,
    localidad: "SAN PEDRO NORTE",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 4659,
    localidad: "SAN PEDRO",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 4660,
    localidad: "SAN MIGUEL",
    cp: 2309,
    provincia_id: 21,
  },
  {
    id: 4661,
    localidad: "SAN MARTIN NORTE",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 4662,
    localidad: "SAN MARTIN DE LAS ESCOBAS",
    cp: 2449,
    provincia_id: 21,
  },
  {
    id: 4663,
    localidad: "SAN MARIANO",
    cp: 3011,
    provincia_id: 21,
  },
  {
    id: 4664,
    localidad: "SAN LORENZO",
    cp: 2200,
    provincia_id: 21,
  },
  {
    id: 4665,
    localidad: "SAN JUSTO",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 4666,
    localidad: "SAN JOSE FRONTERA",
    cp: 2401,
    provincia_id: 21,
  },
  {
    id: 4667,
    localidad: "SAN JOSE DEL RINCON",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4668,
    localidad: "SAN JOSE DEL RINCON",
    cp: 3089,
    provincia_id: 21,
  },
  {
    id: 4669,
    localidad: "SAN JOSE DE LA ESQUINA",
    cp: 2185,
    provincia_id: 21,
  },
  {
    id: 4670,
    localidad: "SAN JOSE DE LA ESQUINA",
    cp: 2186,
    provincia_id: 21,
  },
  {
    id: 4671,
    localidad: "SAN JORGE",
    cp: 2452,
    provincia_id: 21,
  },
  {
    id: 4672,
    localidad: "SAN JORGE",
    cp: 2451,
    provincia_id: 21,
  },
  {
    id: 4673,
    localidad: "SAN JOAQUIN",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4674,
    localidad: "SAN JERONIMO SUD",
    cp: 2136,
    provincia_id: 21,
  },
  {
    id: 4675,
    localidad: "SAN JERONIMO NORTE",
    cp: 3015,
    provincia_id: 21,
  },
  {
    id: 4676,
    localidad: "SAN JERONIMO DEL SAUCE",
    cp: 3012,
    provincia_id: 21,
  },
  {
    id: 4677,
    localidad: "SAN JAVIER",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 4678,
    localidad: "SAN GUILLERMO",
    cp: 2512,
    provincia_id: 21,
  },
  {
    id: 4679,
    localidad: "SAN GUILLERMO",
    cp: 2347,
    provincia_id: 21,
  },
  {
    id: 4680,
    localidad: "SAN GUILLERMO",
    cp: 3020,
    provincia_id: 21,
  },
  {
    id: 4681,
    localidad: "SAN GREGORIO",
    cp: 2613,
    provincia_id: 21,
  },
  {
    id: 4682,
    localidad: "SAN GERONIMO NORTE",
    cp: 3011,
    provincia_id: 21,
  },
  {
    id: 4683,
    localidad: "SAN GERONIMO",
    cp: 2136,
    provincia_id: 21,
  },
  {
    id: 4684,
    localidad: "SAN GENARO NORTE",
    cp: 2147,
    provincia_id: 21,
  },
  {
    id: 4685,
    localidad: "SAN GENARO",
    cp: 2146,
    provincia_id: 21,
  },
  {
    id: 4686,
    localidad: "SAN FRANCISCO DE SANTA FE",
    cp: 2604,
    provincia_id: 21,
  },
  {
    id: 4687,
    localidad: "SAN FABIAN",
    cp: 2243,
    provincia_id: 21,
  },
  {
    id: 4688,
    localidad: "SAN EUGENIO",
    cp: 2256,
    provincia_id: 21,
  },
  {
    id: 4689,
    localidad: "SAN ESTANISLAO",
    cp: 2501,
    provincia_id: 21,
  },
  {
    id: 4690,
    localidad: "SAN EDUARDO",
    cp: 2615,
    provincia_id: 21,
  },
  {
    id: 4691,
    localidad: "SAN CRISTOBAL",
    cp: 3070,
    provincia_id: 21,
  },
  {
    id: 4692,
    localidad: "SAN CARLOS SUD",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 4693,
    localidad: "SAN CARLOS SUD",
    cp: 3017,
    provincia_id: 21,
  },
  {
    id: 4694,
    localidad: "SAN CARLOS NORTE",
    cp: 3009,
    provincia_id: 21,
  },
  {
    id: 4695,
    localidad: "SAN CARLOS NORTE",
    cp: 3010,
    provincia_id: 21,
  },
  {
    id: 4696,
    localidad: "SAN CARLOS CENTRO",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 4697,
    localidad: "SAN BERNARDO",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 4698,
    localidad: "SAN ANTONIO DE OBLIGADO",
    cp: 3587,
    provincia_id: 21,
  },
  {
    id: 4699,
    localidad: "SAN ALBERTO",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 4700,
    localidad: "SAN AGUSTIN",
    cp: 3019,
    provincia_id: 21,
  },
  {
    id: 4701,
    localidad: "SAN AGUSTIN",
    cp: 3043,
    provincia_id: 21,
  },
  {
    id: 4702,
    localidad: "SALTO GRANDE",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 4703,
    localidad: "SALADERO M CABRAL",
    cp: 3079,
    provincia_id: 21,
  },
  {
    id: 4704,
    localidad: "SAGUIER",
    cp: 2321,
    provincia_id: 21,
  },
  {
    id: 4705,
    localidad: "SA PEREYRA",
    cp: 3011,
    provincia_id: 21,
  },
  {
    id: 4706,
    localidad: "S.J. DE LA ESQUINA",
    cp: 2185,
    provincia_id: 21,
  },
  {
    id: 4707,
    localidad: "S ROSA D CALCHINES",
    cp: 3022,
    provincia_id: 21,
  },
  {
    id: 4708,
    localidad: "S MARTIN DE TOURS",
    cp: 2255,
    provincia_id: 21,
  },
  {
    id: 4709,
    localidad: "S M DE V TUERTO",
    cp: 2600,
    provincia_id: 21,
  },
  {
    id: 4710,
    localidad: "S M DE LAS ESCOBAS",
    cp: 2449,
    provincia_id: 21,
  },
  {
    id: 4711,
    localidad: "S JOSE DEL RINCON",
    cp: 3089,
    provincia_id: 21,
  },
  {
    id: 4712,
    localidad: "S JERO DEL SAUCE",
    cp: 3012,
    provincia_id: 21,
  },
  {
    id: 4713,
    localidad: "S F DE S FE",
    cp: 2604,
    provincia_id: 21,
  },
  {
    id: 4714,
    localidad: "S CLARA DE SAGUIER",
    cp: 2405,
    provincia_id: 21,
  },
  {
    id: 4715,
    localidad: "S C DE BUENA VISTA",
    cp: 2258,
    provincia_id: 21,
  },
  {
    id: 4716,
    localidad: "S ANT DE OBLIGADO",
    cp: 3587,
    provincia_id: 21,
  },
  {
    id: 4717,
    localidad: "RUNCIMAN",
    cp: 2611,
    provincia_id: 21,
  },
  {
    id: 4718,
    localidad: "RUFINO",
    cp: 6100,
    provincia_id: 21,
  },
  {
    id: 4719,
    localidad: "RUEDA",
    cp: 2923,
    provincia_id: 21,
  },
  {
    id: 4720,
    localidad: "RUEDA",
    cp: 2921,
    provincia_id: 21,
  },
  {
    id: 4721,
    localidad: "ROSARIO",
    cp: 2010,
    provincia_id: 21,
  },
  {
    id: 4722,
    localidad: "ROSARIO",
    cp: 2131,
    provincia_id: 21,
  },
  {
    id: 4723,
    localidad: "ROSARIO",
    cp: 2005,
    provincia_id: 21,
  },
  {
    id: 4724,
    localidad: "ROSARIO",
    cp: 2130,
    provincia_id: 21,
  },
  {
    id: 4725,
    localidad: "ROSARIO",
    cp: 2127,
    provincia_id: 21,
  },
  {
    id: 4726,
    localidad: "ROSARIO",
    cp: 2108,
    provincia_id: 21,
  },
  {
    id: 4727,
    localidad: "ROSARIO",
    cp: 2006,
    provincia_id: 21,
  },
  {
    id: 4728,
    localidad: "ROSARIO",
    cp: 2014,
    provincia_id: 21,
  },
  {
    id: 4729,
    localidad: "ROSARIO",
    cp: 2009,
    provincia_id: 21,
  },
  {
    id: 4730,
    localidad: "ROSARIO",
    cp: 2015,
    provincia_id: 21,
  },
  {
    id: 4731,
    localidad: "ROSARIO",
    cp: 2011,
    provincia_id: 21,
  },
  {
    id: 4732,
    localidad: "ROSARIO",
    cp: 2012,
    provincia_id: 21,
  },
  {
    id: 4733,
    localidad: "ROSARIO",
    cp: 2013,
    provincia_id: 21,
  },
  {
    id: 4734,
    localidad: "ROSARIO",
    cp: 2001,
    provincia_id: 21,
  },
  {
    id: 4735,
    localidad: "ROSARIO",
    cp: 2008,
    provincia_id: 21,
  },
  {
    id: 4736,
    localidad: "ROSARIO",
    cp: 2000,
    provincia_id: 21,
  },
  {
    id: 4737,
    localidad: "ROSARIO",
    cp: 2143,
    provincia_id: 21,
  },
  {
    id: 4738,
    localidad: "ROSARIO",
    cp: 2002,
    provincia_id: 21,
  },
  {
    id: 4739,
    localidad: "ROSARIO",
    cp: 2007,
    provincia_id: 21,
  },
  {
    id: 4740,
    localidad: "ROSARIO",
    cp: 2003,
    provincia_id: 21,
  },
  {
    id: 4741,
    localidad: "ROSARIO",
    cp: 2004,
    provincia_id: 21,
  },
  {
    id: 4742,
    localidad: "ROSALES",
    cp: 6128,
    provincia_id: 21,
  },
  {
    id: 4743,
    localidad: "ROMANG",
    cp: 3555,
    provincia_id: 21,
  },
  {
    id: 4744,
    localidad: "ROLDAN",
    cp: 2134,
    provincia_id: 21,
  },
  {
    id: 4745,
    localidad: "RODOLFO ALCORTA",
    cp: 2115,
    provincia_id: 21,
  },
  {
    id: 4746,
    localidad: "RIVADAVIA",
    cp: 3081,
    provincia_id: 21,
  },
  {
    id: 4747,
    localidad: "RIO SALADO",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 4748,
    localidad: "RINLON DE AVILA",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 4749,
    localidad: "RINCON POTREROS",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4750,
    localidad: "RINCON NORTE",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4751,
    localidad: "RINCON DEL QUEBRACHO",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4752,
    localidad: "RINCON DEL PINTADO",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4753,
    localidad: "RINCON DE S ANT",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4754,
    localidad: "RINCON DE GRONDONA",
    cp: 2206,
    provincia_id: 21,
  },
  {
    id: 4755,
    localidad: "RIGBY",
    cp: 2255,
    provincia_id: 21,
  },
  {
    id: 4756,
    localidad: "RICARDONE",
    cp: 2201,
    provincia_id: 21,
  },
  {
    id: 4757,
    localidad: "REYNALDO CULLEN",
    cp: 3020,
    provincia_id: 21,
  },
  {
    id: 4758,
    localidad: "REINA MARGARITA",
    cp: 2309,
    provincia_id: 21,
  },
  {
    id: 4759,
    localidad: "RECREO SUR",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4760,
    localidad: "RECREO",
    cp: 3018,
    provincia_id: 21,
  },
  {
    id: 4761,
    localidad: "RECONQUISTA",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 4762,
    localidad: "RASTREADOR FOURNIER",
    cp: 2605,
    provincia_id: 21,
  },
  {
    id: 4763,
    localidad: "RAQUEL",
    cp: 2322,
    provincia_id: 21,
  },
  {
    id: 4764,
    localidad: "RAMAYON",
    cp: 3094,
    provincia_id: 21,
  },
  {
    id: 4765,
    localidad: "RAMAYON",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 4766,
    localidad: "RAFAELA",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 4767,
    localidad: "R. DEL QUEBRACHO",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4768,
    localidad: "R. DE TACURALES",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 4769,
    localidad: "R S FE LA VIEJA",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4770,
    localidad: "R FOURNIER",
    cp: 2605,
    provincia_id: 21,
  },
  {
    id: 4771,
    localidad: "R DEST AER MILITAR",
    cp: 3567,
    provincia_id: 21,
  },
  {
    id: 4772,
    localidad: "R 138 KILOMETRO 1",
    cp: 3100,
    provincia_id: 21,
  },
  {
    id: 4773,
    localidad: "QUINTAS AL SUD",
    cp: 3100,
    provincia_id: 21,
  },
  {
    id: 4774,
    localidad: "PUJATO NORTE",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4775,
    localidad: "PUJATO",
    cp: 2122,
    provincia_id: 21,
  },
  {
    id: 4776,
    localidad: "PUJATO",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 4777,
    localidad: "PUJATO",
    cp: 2323,
    provincia_id: 21,
  },
  {
    id: 4778,
    localidad: "PUERTO RECONQUISTA",
    cp: 3567,
    provincia_id: 21,
  },
  {
    id: 4779,
    localidad: "PUERTO PIRACUACITO",
    cp: 3592,
    provincia_id: 21,
  },
  {
    id: 4780,
    localidad: "PUERTO OCAMPO",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 4781,
    localidad: "PUERTO GRAL SAN MARTIN",
    cp: 2202,
    provincia_id: 21,
  },
  {
    id: 4782,
    localidad: "PUERTO GABOTO",
    cp: 2208,
    provincia_id: 21,
  },
  {
    id: 4783,
    localidad: "PUERTO ARAGON",
    cp: 2246,
    provincia_id: 21,
  },
  {
    id: 4784,
    localidad: "PUENTE COLASTINE",
    cp: 2242,
    provincia_id: 21,
  },
  {
    id: 4785,
    localidad: "PUEBLO TERRAGNI",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 4786,
    localidad: "PUEBLO SAN ANTONIO",
    cp: 2320,
    provincia_id: 21,
  },
  {
    id: 4787,
    localidad: "PUEBLO S BERNARDO",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 4788,
    localidad: "PUEBLO RAMONA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 4789,
    localidad: "PUEBLO NUEVO",
    cp: 2124,
    provincia_id: 21,
  },
  {
    id: 4790,
    localidad: "PUEBLO MUÑOZ",
    cp: 2120,
    provincia_id: 21,
  },
  {
    id: 4791,
    localidad: "PUEBLO MIGUEL TORRES",
    cp: 2631,
    provincia_id: 21,
  },
  {
    id: 4792,
    localidad: "PUEBLO MARINI",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 4793,
    localidad: "PUEBLO MARIA JUANA",
    cp: 2446,
    provincia_id: 21,
  },
  {
    id: 4794,
    localidad: "PUEBLO KIRSTON",
    cp: 2202,
    provincia_id: 21,
  },
  {
    id: 4795,
    localidad: "PUEBLO GOLONDRINA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4796,
    localidad: "PUEBLO ESTHER",
    cp: 2129,
    provincia_id: 21,
  },
  {
    id: 4797,
    localidad: "PUEBLO ESTHER",
    cp: 2126,
    provincia_id: 21,
  },
  {
    id: 4798,
    localidad: "PUEBLO AREQUITO",
    cp: 2183,
    provincia_id: 21,
  },
  {
    id: 4799,
    localidad: "PUEBLO ALCORTA",
    cp: 2117,
    provincia_id: 21,
  },
  {
    id: 4800,
    localidad: "PUEBLO ABC",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4801,
    localidad: "PROVIDENCIA",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4802,
    localidad: "PROGRESO",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 4803,
    localidad: "PRESIDENTE ROCA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 4804,
    localidad: "POZO DE LOS INDIOS",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4805,
    localidad: "POZO BORRADO",
    cp: 3067,
    provincia_id: 21,
  },
  {
    id: 4806,
    localidad: "POTRERO GUASUNCHO",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 4807,
    localidad: "POSTA DE SAN MARTIN",
    cp: 2128,
    provincia_id: 21,
  },
  {
    id: 4808,
    localidad: "PORTUGALETE",
    cp: 3071,
    provincia_id: 21,
  },
  {
    id: 4809,
    localidad: "PORTEÑA",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 4810,
    localidad: "POMPEYA",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4811,
    localidad: "POLCO",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 4812,
    localidad: "PLAZA SAN FRANCISCO",
    cp: 2401,
    provincia_id: 21,
  },
  {
    id: 4813,
    localidad: "PIQUETE",
    cp: 3000,
    provincia_id: 21,
  },
  {
    id: 4814,
    localidad: "PIÑERO",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 4815,
    localidad: "PINO DE S LORENZO",
    cp: 2200,
    provincia_id: 21,
  },
  {
    id: 4816,
    localidad: "PILAR",
    cp: 3085,
    provincia_id: 21,
  },
  {
    id: 4817,
    localidad: "PIEDRAS ANCHAS",
    cp: 2645,
    provincia_id: 21,
  },
  {
    id: 4818,
    localidad: "PIAMONTE",
    cp: 2529,
    provincia_id: 21,
  },
  {
    id: 4819,
    localidad: "PEYRANO",
    cp: 2113,
    provincia_id: 21,
  },
  {
    id: 4820,
    localidad: "PETRONILA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4821,
    localidad: "PERICOTA",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 4822,
    localidad: "PERICOTA",
    cp: 3053,
    provincia_id: 21,
  },
  {
    id: 4823,
    localidad: "PEREZ",
    cp: 2121,
    provincia_id: 21,
  },
  {
    id: 4824,
    localidad: "PEREYRA LUCENA",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 4825,
    localidad: "PEDRO GOMEZ CELLO",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 4826,
    localidad: "PAVON ARRIBA",
    cp: 2110,
    provincia_id: 21,
  },
  {
    id: 4827,
    localidad: "PAVON",
    cp: 2118,
    provincia_id: 21,
  },
  {
    id: 4828,
    localidad: "PAVON",
    cp: 2920,
    provincia_id: 21,
  },
  {
    id: 4829,
    localidad: "PAVENHAN",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 4830,
    localidad: "PASO VINAL",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4831,
    localidad: "PARAJE TRAGNAGHI",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4832,
    localidad: "PARAJE 29",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 4833,
    localidad: "PALACIOS",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 4834,
    localidad: "PAIKIN",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4835,
    localidad: "PADRE P ITURRALDE",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 4836,
    localidad: "P. MIGUEL TORRES",
    cp: 2631,
    provincia_id: 21,
  },
  {
    id: 4837,
    localidad: "P KILOMETRO 12",
    cp: 3550,
    provincia_id: 21,
  },
  {
    id: 4838,
    localidad: "OTTO BEMBERG",
    cp: 2605,
    provincia_id: 21,
  },
  {
    id: 4839,
    localidad: "OROÑO",
    cp: 2253,
    provincia_id: 21,
  },
  {
    id: 4840,
    localidad: "ORDOÑEZ",
    cp: 2595,
    provincia_id: 21,
  },
  {
    id: 4841,
    localidad: "ORATORIO MORANTE",
    cp: 2921,
    provincia_id: 21,
  },
  {
    id: 4842,
    localidad: "OMBU NORTE",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 4843,
    localidad: "OLIVEROS",
    cp: 2206,
    provincia_id: 21,
  },
  {
    id: 4844,
    localidad: "OGILIVE",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4845,
    localidad: "OBRAJE SAN JUAN",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 4846,
    localidad: "OBRAJE I MUERTO",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 4847,
    localidad: "ÑANDUCITA",
    cp: 3072,
    provincia_id: 21,
  },
  {
    id: 4848,
    localidad: "ÑANDU",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 4849,
    localidad: "NUEVO TORINO",
    cp: 3088,
    provincia_id: 21,
  },
  {
    id: 4850,
    localidad: "NUEVA UKRANIA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4851,
    localidad: "NUEVA POMPEYA",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4852,
    localidad: "NUEVA LEHMANN",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 4853,
    localidad: "NUEVA ITALIA",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 4854,
    localidad: "NUEVA CERES",
    cp: 2340,
    provincia_id: 21,
  },
  {
    id: 4855,
    localidad: "NICANOR E MOLINAS",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 4856,
    localidad: "NELSON",
    cp: 3032,
    provincia_id: 21,
  },
  {
    id: 4857,
    localidad: "NARE",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4858,
    localidad: "MUTCHNIK",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 4859,
    localidad: "MURPHY",
    cp: 2601,
    provincia_id: 21,
  },
  {
    id: 4860,
    localidad: "MOUSSY",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 4861,
    localidad: "MONTES DE OCA",
    cp: 2521,
    provincia_id: 21,
  },
  {
    id: 4862,
    localidad: "MONTE VERA",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 4863,
    localidad: "MONTE VERA",
    cp: 3030,
    provincia_id: 21,
  },
  {
    id: 4864,
    localidad: "MONTE OBSCURIDAD",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 4865,
    localidad: "MONTE GRANDE",
    cp: 2417,
    provincia_id: 21,
  },
  {
    id: 4866,
    localidad: "MONTE FLORES",
    cp: 2101,
    provincia_id: 21,
  },
  {
    id: 4867,
    localidad: "MONTE AGUDO",
    cp: 3600,
    provincia_id: 21,
  },
  {
    id: 4868,
    localidad: "MONJE",
    cp: 2212,
    provincia_id: 21,
  },
  {
    id: 4869,
    localidad: "MONIGOTES",
    cp: 2342,
    provincia_id: 21,
  },
  {
    id: 4870,
    localidad: "MONIGOTES",
    cp: 2348,
    provincia_id: 21,
  },
  {
    id: 4871,
    localidad: "MOJON DE FIERRO",
    cp: 3600,
    provincia_id: 21,
  },
  {
    id: 4872,
    localidad: "MOISES VILLE",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 4873,
    localidad: "MIGUEL SALAS",
    cp: 6128,
    provincia_id: 21,
  },
  {
    id: 4874,
    localidad: "MIGUEL ESCALADA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4875,
    localidad: "MERCEDITAS",
    cp: 2725,
    provincia_id: 21,
  },
  {
    id: 4876,
    localidad: "MELINCUE",
    cp: 2728,
    provincia_id: 21,
  },
  {
    id: 4877,
    localidad: "MAXIMO PAZ",
    cp: 2115,
    provincia_id: 21,
  },
  {
    id: 4878,
    localidad: "MASCIAS",
    cp: 3041,
    provincia_id: 21,
  },
  {
    id: 4879,
    localidad: "MARIANO SAAVEDRA",
    cp: 3039,
    provincia_id: 21,
  },
  {
    id: 4880,
    localidad: "MARIA TERESA",
    cp: 2610,
    provincia_id: 21,
  },
  {
    id: 4881,
    localidad: "MARIA TERESA",
    cp: 2609,
    provincia_id: 21,
  },
  {
    id: 4882,
    localidad: "MARIA SUSANA",
    cp: 2527,
    provincia_id: 21,
  },
  {
    id: 4883,
    localidad: "MARIA LUISA CORREA",
    cp: 2502,
    provincia_id: 21,
  },
  {
    id: 4884,
    localidad: "MARIA LUISA",
    cp: 3028,
    provincia_id: 21,
  },
  {
    id: 4885,
    localidad: "MARIA JUANA",
    cp: 2445,
    provincia_id: 21,
  },
  {
    id: 4886,
    localidad: "MARIA EUGENIA",
    cp: 3072,
    provincia_id: 21,
  },
  {
    id: 4887,
    localidad: "MARGARITA",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 4888,
    localidad: "MARCELINO ESCALADA",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 4889,
    localidad: "MANUCHO",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 4890,
    localidad: "MANGORE",
    cp: 2445,
    provincia_id: 21,
  },
  {
    id: 4891,
    localidad: "MALABRIGO",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 4892,
    localidad: "MAIZALES",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 4893,
    localidad: "MAGGIOLO",
    cp: 2622,
    provincia_id: 21,
  },
  {
    id: 4894,
    localidad: "MACIEL",
    cp: 2209,
    provincia_id: 21,
  },
  {
    id: 4895,
    localidad: "MACIEL",
    cp: 2208,
    provincia_id: 21,
  },
  {
    id: 4896,
    localidad: "LUIS PALACIOS",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 4897,
    localidad: "LUCIO V.LOPEZ",
    cp: 2150,
    provincia_id: 21,
  },
  {
    id: 4898,
    localidad: "LUCIO V. LOPEZ",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 4899,
    localidad: "LUCIO V LOPEZ",
    cp: 2150,
    provincia_id: 21,
  },
  {
    id: 4900,
    localidad: "LUCIANO LEIVA",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 4901,
    localidad: "LOTE 2 LA ELINA",
    cp: 6203,
    provincia_id: 21,
  },
  {
    id: 4902,
    localidad: "LOS TABLEROS",
    cp: 3062,
    provincia_id: 21,
  },
  {
    id: 4903,
    localidad: "LOS T D KM 366",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4904,
    localidad: "LOS SEMBRADOS",
    cp: 2447,
    provincia_id: 21,
  },
  {
    id: 4905,
    localidad: "LOS SALADILLOS",
    cp: 3041,
    provincia_id: 21,
  },
  {
    id: 4906,
    localidad: "LOS QUIRQUINCHOS",
    cp: 2537,
    provincia_id: 21,
  },
  {
    id: 4907,
    localidad: "LOS QUIRQUINCHOS",
    cp: 2637,
    provincia_id: 21,
  },
  {
    id: 4908,
    localidad: "LOS PALMARES",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 4909,
    localidad: "LOS OSOS",
    cp: 3051,
    provincia_id: 21,
  },
  {
    id: 4910,
    localidad: "LOS OLIVOS",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4911,
    localidad: "LOS NOGALES",
    cp: 2183,
    provincia_id: 21,
  },
  {
    id: 4912,
    localidad: "LOS NOGALES",
    cp: 2184,
    provincia_id: 21,
  },
  {
    id: 4913,
    localidad: "LOS MUCHACHOS",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 4914,
    localidad: "LOS MOLLES",
    cp: 3070,
    provincia_id: 21,
  },
  {
    id: 4915,
    localidad: "LOS MOLINOS",
    cp: 2181,
    provincia_id: 21,
  },
  {
    id: 4916,
    localidad: "LOS LEONES",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4917,
    localidad: "LOS LEONES",
    cp: 2146,
    provincia_id: 21,
  },
  {
    id: 4918,
    localidad: "LOS LAURELES",
    cp: 3584,
    provincia_id: 21,
  },
  {
    id: 4919,
    localidad: "LOS LAPACHOS",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 4920,
    localidad: "LOS HORNOS",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 4921,
    localidad: "LOS GALPONES",
    cp: 3050,
    provincia_id: 21,
  },
  {
    id: 4922,
    localidad: "LOS CUERVOS",
    cp: 3555,
    provincia_id: 21,
  },
  {
    id: 4923,
    localidad: "LOS CORRALITOS",
    cp: 3051,
    provincia_id: 21,
  },
  {
    id: 4924,
    localidad: "LOS CLAROS",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4925,
    localidad: "LOS CHARABONES",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 4926,
    localidad: "LOS CERRILLOS",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4927,
    localidad: "LOS CARDOS",
    cp: 2533,
    provincia_id: 21,
  },
  {
    id: 4928,
    localidad: "LOS CARDENALES",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 4929,
    localidad: "LOS BORDOS",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 4930,
    localidad: "LOS AMORES",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4931,
    localidad: "LOPEZ",
    cp: 2255,
    provincia_id: 21,
  },
  {
    id: 4932,
    localidad: "LOMA VERDE",
    cp: 2015,
    provincia_id: 21,
  },
  {
    id: 4933,
    localidad: "LOMA VERDE",
    cp: 2117,
    provincia_id: 21,
  },
  {
    id: 4934,
    localidad: "LOMA ALTA",
    cp: 2253,
    provincia_id: 21,
  },
  {
    id: 4935,
    localidad: "LOMA ALTA",
    cp: 2254,
    provincia_id: 21,
  },
  {
    id: 4936,
    localidad: "LOGROÑO",
    cp: 3066,
    provincia_id: 21,
  },
  {
    id: 4937,
    localidad: "LOGROÑO",
    cp: 3075,
    provincia_id: 21,
  },
  {
    id: 4938,
    localidad: "LLAMBI CAMPBELL",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 4939,
    localidad: "LEHMANN",
    cp: 2305,
    provincia_id: 21,
  },
  {
    id: 4940,
    localidad: "LAZZARINO",
    cp: 6103,
    provincia_id: 21,
  },
  {
    id: 4941,
    localidad: "LATAN HALL",
    cp: 2625,
    provincia_id: 21,
  },
  {
    id: 4942,
    localidad: "LASSAGA",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 4943,
    localidad: "LAS TUNAS",
    cp: 3033,
    provincia_id: 21,
  },
  {
    id: 4944,
    localidad: "LAS TROJAS",
    cp: 2500,
    provincia_id: 21,
  },
  {
    id: 4945,
    localidad: "LAS TRES MARIAS",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 4946,
    localidad: "LAS TOSCAS",
    cp: 3586,
    provincia_id: 21,
  },
  {
    id: 4947,
    localidad: "LAS ROSAS",
    cp: 2520,
    provincia_id: 21,
  },
  {
    id: 4948,
    localidad: "LAS QUINTAS",
    cp: 2200,
    provincia_id: 21,
  },
  {
    id: 4949,
    localidad: "LAS PETACAS",
    cp: 2451,
    provincia_id: 21,
  },
  {
    id: 4950,
    localidad: "LAS PAREJAS",
    cp: 2505,
    provincia_id: 21,
  },
  {
    id: 4951,
    localidad: "LAS PALMERAS",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 4952,
    localidad: "LAS PALMAS",
    cp: 3555,
    provincia_id: 21,
  },
  {
    id: 4953,
    localidad: "LAS LIEBRES",
    cp: 2520,
    provincia_id: 21,
  },
  {
    id: 4954,
    localidad: "LAS HIGUERITAS",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 4955,
    localidad: "LAS GARZAS",
    cp: 3577,
    provincia_id: 21,
  },
  {
    id: 4956,
    localidad: "LAS GARSITAS",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 4957,
    localidad: "LAS ENCADENADAS",
    cp: 2607,
    provincia_id: 21,
  },
  {
    id: 4958,
    localidad: "LAS DELICIAS",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4959,
    localidad: "LAS CATALINAS",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 4960,
    localidad: "LAS BANDURRIAS",
    cp: 2153,
    provincia_id: 21,
  },
  {
    id: 4961,
    localidad: "LAS AVISPAS",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 4962,
    localidad: "LAS ARENAS",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 4963,
    localidad: "LAS ANINTAS",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 4964,
    localidad: "LAS 7 provincia_idS",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 4965,
    localidad: "LARRECHEA",
    cp: 2241,
    provincia_id: 21,
  },
  {
    id: 4966,
    localidad: "LARRECHEA",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4967,
    localidad: "LARRECHEA",
    cp: 2253,
    provincia_id: 21,
  },
  {
    id: 4968,
    localidad: "LARGUIA",
    cp: 2144,
    provincia_id: 21,
  },
  {
    id: 4969,
    localidad: "LANTERI",
    cp: 3576,
    provincia_id: 21,
  },
  {
    id: 4970,
    localidad: "LANDETA",
    cp: 2531,
    provincia_id: 21,
  },
  {
    id: 4971,
    localidad: "LAGUNA VERDE",
    cp: 3076,
    provincia_id: 21,
  },
  {
    id: 4972,
    localidad: "LAGUNA PAIVA",
    cp: 3020,
    provincia_id: 21,
  },
  {
    id: 4973,
    localidad: "LAGUNA OSCURA",
    cp: 6144,
    provincia_id: 21,
  },
  {
    id: 4974,
    localidad: "LABORDEBOY",
    cp: 2726,
    provincia_id: 21,
  },
  {
    id: 4975,
    localidad: "LA ZULEMA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4976,
    localidad: "LA VIUDA",
    cp: 2183,
    provincia_id: 21,
  },
  {
    id: 4977,
    localidad: "LA VERDE",
    cp: 3076,
    provincia_id: 21,
  },
  {
    id: 4978,
    localidad: "LA VANGUARDIA",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 4979,
    localidad: "LA VANGUARDIA",
    cp: 2133,
    provincia_id: 21,
  },
  {
    id: 4980,
    localidad: "LA VANGUARDIA",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 4981,
    localidad: "LA UDINE",
    cp: 2413,
    provincia_id: 21,
  },
  {
    id: 4982,
    localidad: "LA TRINCHERA",
    cp: 2417,
    provincia_id: 21,
  },
  {
    id: 4983,
    localidad: "LA SEMENTERA",
    cp: 3038,
    provincia_id: 21,
  },
  {
    id: 4984,
    localidad: "LA SELVA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 4985,
    localidad: "LA SARNOSA",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 4986,
    localidad: "LA SARITA",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 4987,
    localidad: "LA SALADA",
    cp: 2141,
    provincia_id: 21,
  },
  {
    id: 4988,
    localidad: "LA RUBIA",
    cp: 2350,
    provincia_id: 21,
  },
  {
    id: 4989,
    localidad: "LA RUBIA",
    cp: 2342,
    provincia_id: 21,
  },
  {
    id: 4990,
    localidad: "LA ROSA",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 4991,
    localidad: "LA POTASA",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 4992,
    localidad: "LA POLVAREDA",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 4993,
    localidad: "LA PELADA",
    cp: 3027,
    provincia_id: 21,
  },
  {
    id: 4994,
    localidad: "LA OTHILIA",
    cp: 2115,
    provincia_id: 21,
  },
  {
    id: 4995,
    localidad: "LA ORILLA",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 4996,
    localidad: "LA ORIENTAL",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 4997,
    localidad: "LA NORIA",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 4998,
    localidad: "LA MOROCHA",
    cp: 2613,
    provincia_id: 21,
  },
  {
    id: 4999,
    localidad: "LA MERCED",
    cp: 2173,
    provincia_id: 21,
  },
  {
    id: 5000,
    localidad: "LA LUCILA",
    cp: 3072,
    provincia_id: 21,
  },
  {
    id: 5001,
    localidad: "LA LOMA",
    cp: 3555,
    provincia_id: 21,
  },
  {
    id: 5002,
    localidad: "LA LOLA",
    cp: 3567,
    provincia_id: 21,
  },
  {
    id: 5003,
    localidad: "LA JULIA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5004,
    localidad: "LA JOSEFINA",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5005,
    localidad: "LA INGLESITA",
    cp: 2601,
    provincia_id: 21,
  },
  {
    id: 5006,
    localidad: "LA HOSCA",
    cp: 3050,
    provincia_id: 21,
  },
  {
    id: 5007,
    localidad: "LA GUARDIA",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5008,
    localidad: "LA GUAMPITA",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 5009,
    localidad: "LA GERALDINA",
    cp: 2343,
    provincia_id: 21,
  },
  {
    id: 5010,
    localidad: "LA GAMA",
    cp: 2615,
    provincia_id: 21,
  },
  {
    id: 5011,
    localidad: "LA GALLARETA",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 5012,
    localidad: "LA FELICIANA",
    cp: 3062,
    provincia_id: 21,
  },
  {
    id: 5013,
    localidad: "LA ESMERALDA",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 5014,
    localidad: "LA ELINA",
    cp: 6203,
    provincia_id: 21,
  },
  {
    id: 5015,
    localidad: "LA DIAMELA",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5016,
    localidad: "LA DELIA",
    cp: 3062,
    provincia_id: 21,
  },
  {
    id: 5017,
    localidad: "LA CRIOLLA",
    cp: 3052,
    provincia_id: 21,
  },
  {
    id: 5018,
    localidad: "LA CORTADERA",
    cp: 2334,
    provincia_id: 21,
  },
  {
    id: 5019,
    localidad: "LA COLONIA",
    cp: 3600,
    provincia_id: 21,
  },
  {
    id: 5020,
    localidad: "LA CLORINDA",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 5021,
    localidad: "LA CLARITA",
    cp: 3585,
    provincia_id: 21,
  },
  {
    id: 5022,
    localidad: "LA CLARA",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 5023,
    localidad: "LA CIGUEÑA",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 5024,
    localidad: "LA CHISPA",
    cp: 2602,
    provincia_id: 21,
  },
  {
    id: 5025,
    localidad: "LA CELIA",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5026,
    localidad: "LA CELIA",
    cp: 2115,
    provincia_id: 21,
  },
  {
    id: 5027,
    localidad: "LA CATALINA",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5028,
    localidad: "LA CAROLINA",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 5029,
    localidad: "LA CAPILLA",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5030,
    localidad: "LA CAMILA",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 5031,
    localidad: "LA CALIFORNIA",
    cp: 2520,
    provincia_id: 21,
  },
  {
    id: 5032,
    localidad: "LA CABRAL",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 5033,
    localidad: "LA BRAVA",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 5034,
    localidad: "LA BOMBILLA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5035,
    localidad: "LA BLANCA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5036,
    localidad: "KILOMETRO 95",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5037,
    localidad: "KILOMETRO 9",
    cp: 3000,
    provincia_id: 21,
  },
  {
    id: 5038,
    localidad: "KILOMETRO 85",
    cp: 2303,
    provincia_id: 21,
  },
  {
    id: 5039,
    localidad: "KILOMETRO 57",
    cp: 2619,
    provincia_id: 21,
  },
  {
    id: 5040,
    localidad: "KILOMETRO 54",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 5041,
    localidad: "KILOMETRO 501",
    cp: 2409,
    provincia_id: 21,
  },
  {
    id: 5042,
    localidad: "KILOMETRO 49",
    cp: 2506,
    provincia_id: 21,
  },
  {
    id: 5043,
    localidad: "KILOMETRO 49",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 5044,
    localidad: "KILOMETRO 49",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 5045,
    localidad: "KILOMETRO 483",
    cp: 2456,
    provincia_id: 21,
  },
  {
    id: 5046,
    localidad: "KILOMETRO 468",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5047,
    localidad: "KILOMETRO 465",
    cp: 2456,
    provincia_id: 21,
  },
  {
    id: 5048,
    localidad: "KILOMETRO 443",
    cp: 2454,
    provincia_id: 21,
  },
  {
    id: 5049,
    localidad: "KILOMETRO 421",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5050,
    localidad: "KILOMETRO 41",
    cp: 3020,
    provincia_id: 21,
  },
  {
    id: 5051,
    localidad: "KILOMETRO 408",
    cp: 3580,
    provincia_id: 21,
  },
  {
    id: 5052,
    localidad: "KILOMETRO 392",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5053,
    localidad: "KILOMETRO 389",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5054,
    localidad: "KILOMETRO 35",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5055,
    localidad: "KILOMETRO 323",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 5056,
    localidad: "KILOMETRO 320",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5057,
    localidad: "KILOMETRO 319",
    cp: 2154,
    provincia_id: 21,
  },
  {
    id: 5058,
    localidad: "KILOMETRO 302",
    cp: 3097,
    provincia_id: 21,
  },
  {
    id: 5059,
    localidad: "KILOMETRO 30",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5060,
    localidad: "KILOMETRO 28",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5061,
    localidad: "KILOMETRO 235",
    cp: 3076,
    provincia_id: 21,
  },
  {
    id: 5062,
    localidad: "KILOMETRO 23",
    cp: 3589,
    provincia_id: 21,
  },
  {
    id: 5063,
    localidad: "KILOMETRO 213",
    cp: 3050,
    provincia_id: 21,
  },
  {
    id: 5064,
    localidad: "KILOMETRO 17",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5065,
    localidad: "KILOMETRO 113",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5066,
    localidad: "KILEGRUMAN",
    cp: 2625,
    provincia_id: 21,
  },
  {
    id: 5067,
    localidad: "JULIO ARG ROCA",
    cp: 6134,
    provincia_id: 21,
  },
  {
    id: 5068,
    localidad: "JUAN ORTIZ",
    cp: 2154,
    provincia_id: 21,
  },
  {
    id: 5069,
    localidad: "JUAN B. MOLINA",
    cp: 2103,
    provincia_id: 21,
  },
  {
    id: 5070,
    localidad: "JUAN B MOLINA",
    cp: 2104,
    provincia_id: 21,
  },
  {
    id: 5071,
    localidad: "JOSE MACIAS",
    cp: 3041,
    provincia_id: 21,
  },
  {
    id: 5072,
    localidad: "JESUS MARIA",
    cp: 2204,
    provincia_id: 21,
  },
  {
    id: 5073,
    localidad: "J MANUEL ESTRADA",
    cp: 2403,
    provincia_id: 21,
  },
  {
    id: 5074,
    localidad: "ITUZAINGO",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 5075,
    localidad: "ITURRASPE",
    cp: 2523,
    provincia_id: 21,
  },
  {
    id: 5076,
    localidad: "IRIONDO",
    cp: 3018,
    provincia_id: 21,
  },
  {
    id: 5077,
    localidad: "IRIGOYEN",
    cp: 2249,
    provincia_id: 21,
  },
  {
    id: 5078,
    localidad: "INTIYACO",
    cp: 3552,
    provincia_id: 21,
  },
  {
    id: 5079,
    localidad: "INGENIERO GERMANIA",
    cp: 3586,
    provincia_id: 21,
  },
  {
    id: 5080,
    localidad: "INGENIERO BOASI",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 5081,
    localidad: "ING GARMENDIA",
    cp: 3586,
    provincia_id: 21,
  },
  {
    id: 5082,
    localidad: "ING CHANOURDIE",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5083,
    localidad: "INDEPENDENCIA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5084,
    localidad: "IBARLUCEA",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 5085,
    localidad: "IBARLUCEA",
    cp: 2143,
    provincia_id: 21,
  },
  {
    id: 5086,
    localidad: "HUMBOLDT CHICO",
    cp: 3081,
    provincia_id: 21,
  },
  {
    id: 5087,
    localidad: "HUMBOLDT",
    cp: 3081,
    provincia_id: 21,
  },
  {
    id: 5088,
    localidad: "HUMBERTO PRIMERO",
    cp: 2309,
    provincia_id: 21,
  },
  {
    id: 5089,
    localidad: "HUGHES",
    cp: 2725,
    provincia_id: 21,
  },
  {
    id: 5090,
    localidad: "HUGENTOBLER",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5091,
    localidad: "HUANQUEROS",
    cp: 3076,
    provincia_id: 21,
  },
  {
    id: 5092,
    localidad: "HOSPITAL RURAL",
    cp: 3600,
    provincia_id: 21,
  },
  {
    id: 5093,
    localidad: "HIPATIA",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 5094,
    localidad: "HIPATIA",
    cp: 3049,
    provincia_id: 21,
  },
  {
    id: 5095,
    localidad: "HERSILIA",
    cp: 2352,
    provincia_id: 21,
  },
  {
    id: 5096,
    localidad: "HELVECIA",
    cp: 3003,
    provincia_id: 21,
  },
  {
    id: 5097,
    localidad: "GUAYCURU",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5098,
    localidad: "GUARANIES",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 5099,
    localidad: "GUADALUPE NORTE",
    cp: 3574,
    provincia_id: 21,
  },
  {
    id: 5100,
    localidad: "GRUTLY NORTE",
    cp: 3083,
    provincia_id: 21,
  },
  {
    id: 5101,
    localidad: "GRUTLY",
    cp: 3083,
    provincia_id: 21,
  },
  {
    id: 5102,
    localidad: "GREGORIA PEREZ DE DENIS",
    cp: 3069,
    provincia_id: 21,
  },
  {
    id: 5103,
    localidad: "GRANJA SAN MANUEL",
    cp: 2501,
    provincia_id: 21,
  },
  {
    id: 5104,
    localidad: "GRANADEROS",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 5105,
    localidad: "GRANADERO BAIGORRIA",
    cp: 2152,
    provincia_id: 21,
  },
  {
    id: 5106,
    localidad: "GRANADERO B BUSTOS",
    cp: 2257,
    provincia_id: 21,
  },
  {
    id: 5107,
    localidad: "GOLONDRINA",
    cp: 3599,
    provincia_id: 21,
  },
  {
    id: 5108,
    localidad: "GODOY",
    cp: 2921,
    provincia_id: 21,
  },
  {
    id: 5109,
    localidad: "GODEKEN",
    cp: 2640,
    provincia_id: 21,
  },
  {
    id: 5110,
    localidad: "GOBERNADOR CRESPO",
    cp: 3044,
    provincia_id: 21,
  },
  {
    id: 5111,
    localidad: "GOBERNADOR CANDIOTI",
    cp: 2469,
    provincia_id: 21,
  },
  {
    id: 5112,
    localidad: "GESSLER",
    cp: 2254,
    provincia_id: 21,
  },
  {
    id: 5113,
    localidad: "GESSLER",
    cp: 2253,
    provincia_id: 21,
  },
  {
    id: 5114,
    localidad: "GENERAL PUEYRREDON",
    cp: 6140,
    provincia_id: 21,
  },
  {
    id: 5115,
    localidad: "GENERAL LAGOS",
    cp: 2127,
    provincia_id: 21,
  },
  {
    id: 5116,
    localidad: "GAVILAN",
    cp: 6132,
    provincia_id: 21,
  },
  {
    id: 5117,
    localidad: "GATO COLORADO",
    cp: 3647,
    provincia_id: 21,
  },
  {
    id: 5118,
    localidad: "GARIBALDI",
    cp: 2443,
    provincia_id: 21,
  },
  {
    id: 5119,
    localidad: "GARABATO",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5120,
    localidad: "GALVEZ",
    cp: 2252,
    provincia_id: 21,
  },
  {
    id: 5121,
    localidad: "GALISTEO",
    cp: 2307,
    provincia_id: 21,
  },
  {
    id: 5122,
    localidad: "GABOTO",
    cp: 2208,
    provincia_id: 21,
  },
  {
    id: 5123,
    localidad: "G PEREZ DE DENIS",
    cp: 3069,
    provincia_id: 21,
  },
  {
    id: 5124,
    localidad: "FUNES",
    cp: 2132,
    provincia_id: 21,
  },
  {
    id: 5125,
    localidad: "FUENTES",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 5126,
    localidad: "FRONTERA",
    cp: 2438,
    provincia_id: 21,
  },
  {
    id: 5127,
    localidad: "FRAY LUIS BELTRAN",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 5128,
    localidad: "FRAY LUIS BELTRAN",
    cp: 2158,
    provincia_id: 21,
  },
  {
    id: 5129,
    localidad: "FRANCK",
    cp: 3009,
    provincia_id: 21,
  },
  {
    id: 5130,
    localidad: "FRANCISCO PAZ",
    cp: 2111,
    provincia_id: 21,
  },
  {
    id: 5131,
    localidad: "FORTIN TOSTADO",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5132,
    localidad: "FORTIN TACURU",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5133,
    localidad: "FORTIN OLMOS",
    cp: 3562,
    provincia_id: 21,
  },
  {
    id: 5134,
    localidad: "FORTIN OLMOS",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5135,
    localidad: "FORTIN CHILCAS",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5136,
    localidad: "FORTIN CHARRUA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5137,
    localidad: "FORTIN CACIQUE",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5138,
    localidad: "FORTIN ATAHUALPA",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5139,
    localidad: "FORTIN ARGENTINA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5140,
    localidad: "FORTIN ALMAGRO",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5141,
    localidad: "FLORIDA",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5142,
    localidad: "FLOR DE ORO",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5143,
    localidad: "FIVES LILLE",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 5144,
    localidad: "FIRMAT",
    cp: 2630,
    provincia_id: 21,
  },
  {
    id: 5145,
    localidad: "FIGHIERA",
    cp: 2126,
    provincia_id: 21,
  },
  {
    id: 5146,
    localidad: "FELICIA",
    cp: 3087,
    provincia_id: 21,
  },
  {
    id: 5147,
    localidad: "FASSI",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 5148,
    localidad: "F MTAR S LORENZO",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 5149,
    localidad: "F 6 DE CABALLERIA",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5150,
    localidad: "EWALD",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 5151,
    localidad: "EUSTOLIA",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5152,
    localidad: "EUSEBIA",
    cp: 2330,
    provincia_id: 21,
  },
  {
    id: 5153,
    localidad: "EUSEBIA",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5154,
    localidad: "ESTRADA",
    cp: 2409,
    provincia_id: 21,
  },
  {
    id: 5155,
    localidad: "ESTHER",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 5156,
    localidad: "ESTEBAN RAMS",
    cp: 3077,
    provincia_id: 21,
  },
  {
    id: 5157,
    localidad: "ESTANCIA PRUSIA",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5158,
    localidad: "ESTANCIA PAVENHAN",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 5159,
    localidad: "ESTANCIA LAS GAMAS",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 5160,
    localidad: "ESTANCIA ACHALA",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5161,
    localidad: "ESTACION TEODELINA",
    cp: 2600,
    provincia_id: 21,
  },
  {
    id: 5162,
    localidad: "ESTACION SAGUIER",
    cp: 2315,
    provincia_id: 21,
  },
  {
    id: 5163,
    localidad: "ESTACION MATILDE",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 5164,
    localidad: "ESTACION JOSEFINA",
    cp: 2403,
    provincia_id: 21,
  },
  {
    id: 5165,
    localidad: "ESTACION ERASTO",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 5166,
    localidad: "ESTACION CLUCELLAS",
    cp: 2408,
    provincia_id: 21,
  },
  {
    id: 5167,
    localidad: "ESQUINA GRANDE",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5168,
    localidad: "ESPIN",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 5169,
    localidad: "ESPERANZA",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 5170,
    localidad: "ESMERALDA",
    cp: 2456,
    provincia_id: 21,
  },
  {
    id: 5171,
    localidad: "ERASTO",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 5172,
    localidad: "ENCADENADAS",
    cp: 2607,
    provincia_id: 21,
  },
  {
    id: 5173,
    localidad: "EMPALME VILLA CONSTITUCION",
    cp: 2918,
    provincia_id: 21,
  },
  {
    id: 5174,
    localidad: "EMPALME VILLA CONSTITUCION",
    cp: 2118,
    provincia_id: 21,
  },
  {
    id: 5175,
    localidad: "EMPALME SAN CARLOS",
    cp: 3007,
    provincia_id: 21,
  },
  {
    id: 5176,
    localidad: "EMILIA",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 5177,
    localidad: "EMILIA",
    cp: 3092,
    provincia_id: 21,
  },
  {
    id: 5178,
    localidad: "ELORTONDO",
    cp: 2732,
    provincia_id: 21,
  },
  {
    id: 5179,
    localidad: "ELISA",
    cp: 3029,
    provincia_id: 21,
  },
  {
    id: 5180,
    localidad: "EL TROPEZON",
    cp: 3010,
    provincia_id: 21,
  },
  {
    id: 5181,
    localidad: "EL TROPEZON",
    cp: 3009,
    provincia_id: 21,
  },
  {
    id: 5182,
    localidad: "EL TRIANGULO",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5183,
    localidad: "EL TREBOL",
    cp: 2534,
    provincia_id: 21,
  },
  {
    id: 5184,
    localidad: "EL TREBOL",
    cp: 2535,
    provincia_id: 21,
  },
  {
    id: 5185,
    localidad: "EL TRANSITO",
    cp: 2202,
    provincia_id: 21,
  },
  {
    id: 5186,
    localidad: "EL TIMBO",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 5187,
    localidad: "EL TIGRE",
    cp: 6203,
    provincia_id: 21,
  },
  {
    id: 5188,
    localidad: "EL TAPIALITO",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5189,
    localidad: "EL TAJAMAR",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5190,
    localidad: "EL SOMBRERITO",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5191,
    localidad: "EL SOMBRERITO",
    cp: 3585,
    provincia_id: 21,
  },
  {
    id: 5192,
    localidad: "EL SOMBRERERO",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5193,
    localidad: "EL RICARDITO",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5194,
    localidad: "EL RABON",
    cp: 3592,
    provincia_id: 21,
  },
  {
    id: 5195,
    localidad: "EL POZO",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5196,
    localidad: "EL PARA",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5197,
    localidad: "EL PAJARO BLANCO",
    cp: 3051,
    provincia_id: 21,
  },
  {
    id: 5198,
    localidad: "EL OLIVO",
    cp: 6203,
    provincia_id: 21,
  },
  {
    id: 5199,
    localidad: "EL NOCHERO",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5200,
    localidad: "EL MARIANO",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5201,
    localidad: "EL LAUREL",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5202,
    localidad: "EL JARDIN",
    cp: 2732,
    provincia_id: 21,
  },
  {
    id: 5203,
    localidad: "EL GUSANO",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5204,
    localidad: "EL GALPON",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 5205,
    localidad: "EL DIECISIETE",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5206,
    localidad: "EL DESCANSO",
    cp: 2334,
    provincia_id: 21,
  },
  {
    id: 5207,
    localidad: "EL CHARABON",
    cp: 2354,
    provincia_id: 21,
  },
  {
    id: 5208,
    localidad: "EL CEIBO",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5209,
    localidad: "EL CEIBALITO",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5210,
    localidad: "EL CARMEN",
    cp: 2550,
    provincia_id: 21,
  },
  {
    id: 5211,
    localidad: "EL CANTOR",
    cp: 2643,
    provincia_id: 21,
  },
  {
    id: 5212,
    localidad: "EL C DE AVELLANEDA",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 5213,
    localidad: "EL BONETE",
    cp: 3550,
    provincia_id: 21,
  },
  {
    id: 5214,
    localidad: "EL BAYO",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 5215,
    localidad: "EL ARAZA",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 5216,
    localidad: "EL AMARGO",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5217,
    localidad: "EL AGUARA",
    cp: 3070,
    provincia_id: 21,
  },
  {
    id: 5218,
    localidad: "EL 38",
    cp: 3550,
    provincia_id: 21,
  },
  {
    id: 5219,
    localidad: "EGUSQUIZA",
    cp: 2319,
    provincia_id: 21,
  },
  {
    id: 5220,
    localidad: "E MARIA JUANA",
    cp: 2445,
    provincia_id: 21,
  },
  {
    id: 5221,
    localidad: "E LOS PALMARES",
    cp: 3057,
    provincia_id: 21,
  },
  {
    id: 5222,
    localidad: "E LA CONSTANCIA",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5223,
    localidad: "E CHRISTOPHERSEN",
    cp: 2611,
    provincia_id: 21,
  },
  {
    id: 5224,
    localidad: "E BELL VILLE",
    cp: 2550,
    provincia_id: 21,
  },
  {
    id: 5225,
    localidad: "DURHAM",
    cp: 2631,
    provincia_id: 21,
  },
  {
    id: 5226,
    localidad: "DOS ROSAS",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 5227,
    localidad: "DOCE CASAS",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 5228,
    localidad: "DISTRITO 3 ISLETAS",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5229,
    localidad: "DIEGO DE ALVEAR",
    cp: 6036,
    provincia_id: 21,
  },
  {
    id: 5230,
    localidad: "DIAZ",
    cp: 2222,
    provincia_id: 21,
  },
  {
    id: 5231,
    localidad: "DESVIO KM 392",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5232,
    localidad: "DESVIO KM 282",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5233,
    localidad: "DESVIO DR BARROS P",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5234,
    localidad: "DESVIO BOERO",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 5235,
    localidad: "DESVIO ARIJON",
    cp: 2244,
    provincia_id: 21,
  },
  {
    id: 5236,
    localidad: "DESVIO ARIJON",
    cp: 2242,
    provincia_id: 21,
  },
  {
    id: 5237,
    localidad: "DESVIO ARAUZ",
    cp: 3029,
    provincia_id: 21,
  },
  {
    id: 5238,
    localidad: "CURUPAITY",
    cp: 2342,
    provincia_id: 21,
  },
  {
    id: 5239,
    localidad: "CULULU",
    cp: 3023,
    provincia_id: 21,
  },
  {
    id: 5240,
    localidad: "CULLEN",
    cp: 2202,
    provincia_id: 21,
  },
  {
    id: 5241,
    localidad: "CUATRO ESQUINAS",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5242,
    localidad: "CUATRO CASAS",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 5243,
    localidad: "CRISTOLIA",
    cp: 2445,
    provincia_id: 21,
  },
  {
    id: 5244,
    localidad: "CRISPI",
    cp: 2441,
    provincia_id: 21,
  },
  {
    id: 5245,
    localidad: "COSTA DEL TOBA",
    cp: 2494,
    provincia_id: 21,
  },
  {
    id: 5246,
    localidad: "COSTA DEL TOBA",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5247,
    localidad: "CORREA",
    cp: 2136,
    provincia_id: 21,
  },
  {
    id: 5248,
    localidad: "CORREA",
    cp: 2506,
    provincia_id: 21,
  },
  {
    id: 5249,
    localidad: "CORONEL RODRIGUEZ",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 5250,
    localidad: "CORONEL FRAGA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 5251,
    localidad: "CORONEL DOMINGUEZ",
    cp: 2114,
    provincia_id: 21,
  },
  {
    id: 5252,
    localidad: "CORONEL DOMINGUEZ",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 5253,
    localidad: "CORONEL BOGADO",
    cp: 2103,
    provincia_id: 21,
  },
  {
    id: 5254,
    localidad: "CORONEL ARNOLD",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 5255,
    localidad: "CORONEL ARNOLD",
    cp: 2131,
    provincia_id: 21,
  },
  {
    id: 5256,
    localidad: "CORONDA",
    cp: 2240,
    provincia_id: 21,
  },
  {
    id: 5257,
    localidad: "CORA",
    cp: 2631,
    provincia_id: 21,
  },
  {
    id: 5258,
    localidad: "CONSTITUYENTES",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5259,
    localidad: "CONSTANZA",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 5260,
    localidad: "COLONIA YAGUARETE",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 5261,
    localidad: "COLONIA VALENCIA",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5262,
    localidad: "COLONIA VALDEZ",
    cp: 2115,
    provincia_id: 21,
  },
  {
    id: 5263,
    localidad: "COLONIA TRES REYES",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 5264,
    localidad: "COLONIA TERESA",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5265,
    localidad: "COLONIA TACURALES",
    cp: 2324,
    provincia_id: 21,
  },
  {
    id: 5266,
    localidad: "COLONIA SILVA",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 5267,
    localidad: "COLONIA SANTA RITA",
    cp: 2411,
    provincia_id: 21,
  },
  {
    id: 5268,
    localidad: "COLONIA SAN ROQUE",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5269,
    localidad: "COLONIA SAN MANUEL",
    cp: 3563,
    provincia_id: 21,
  },
  {
    id: 5270,
    localidad: "COLONIA SAN JOSE",
    cp: 3016,
    provincia_id: 21,
  },
  {
    id: 5271,
    localidad: "COLONIA SAN ANTONIO",
    cp: 2312,
    provincia_id: 21,
  },
  {
    id: 5272,
    localidad: "COLONIA SAGER",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5273,
    localidad: "COLONIA ROSA",
    cp: 2347,
    provincia_id: 21,
  },
  {
    id: 5274,
    localidad: "COLONIA ROSA",
    cp: 2351,
    provincia_id: 21,
  },
  {
    id: 5275,
    localidad: "COLONIA RIPAMONTI",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 5276,
    localidad: "COLONIA PUJOL",
    cp: 3080,
    provincia_id: 21,
  },
  {
    id: 5277,
    localidad: "COLONIA PIAMONTESA",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5278,
    localidad: "COLONIA PIAGGIO",
    cp: 2252,
    provincia_id: 21,
  },
  {
    id: 5279,
    localidad: "COLONIA ORTIZ",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 5280,
    localidad: "COLONIA MORGAN",
    cp: 2609,
    provincia_id: 21,
  },
  {
    id: 5281,
    localidad: "COLONIA MILESSI",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 5282,
    localidad: "COLONIA MEDICI",
    cp: 2144,
    provincia_id: 21,
  },
  {
    id: 5283,
    localidad: "COLONIA MAUNIER",
    cp: 2349,
    provincia_id: 21,
  },
  {
    id: 5284,
    localidad: "COLONIA MAUA",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 5285,
    localidad: "COLONIA MATILDE",
    cp: 3013,
    provincia_id: 21,
  },
  {
    id: 5286,
    localidad: "COLONIA MASCIAS",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5287,
    localidad: "COLONIA MARGARITA",
    cp: 2443,
    provincia_id: 21,
  },
  {
    id: 5288,
    localidad: "COLONIA MACKINLAY",
    cp: 2347,
    provincia_id: 21,
  },
  {
    id: 5289,
    localidad: "COLONIA LOS ZAPALLOS",
    cp: 3003,
    provincia_id: 21,
  },
  {
    id: 5290,
    localidad: "COLONIA LOS VASCOS",
    cp: 2189,
    provincia_id: 21,
  },
  {
    id: 5291,
    localidad: "COLONIA LA YERBA",
    cp: 2451,
    provincia_id: 21,
  },
  {
    id: 5292,
    localidad: "COLONIA LA PENCA",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 5293,
    localidad: "COLONIA LA NUEVA",
    cp: 3081,
    provincia_id: 21,
  },
  {
    id: 5294,
    localidad: "COLONIA LA NEGRA",
    cp: 3054,
    provincia_id: 21,
  },
  {
    id: 5295,
    localidad: "COLONIA LA MORA",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 5296,
    localidad: "COLONIA LA MARIA",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 5297,
    localidad: "COLONIA LA COSTA",
    cp: 2170,
    provincia_id: 21,
  },
  {
    id: 5298,
    localidad: "COLONIA LA BLANCA",
    cp: 3052,
    provincia_id: 21,
  },
  {
    id: 5299,
    localidad: "COLONIA JOSEFINA",
    cp: 2404,
    provincia_id: 21,
  },
  {
    id: 5300,
    localidad: "COLONIA HANSEN",
    cp: 2637,
    provincia_id: 21,
  },
  {
    id: 5301,
    localidad: "COLONIA GORCHS",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 5302,
    localidad: "COLONIA GOMEZ",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5303,
    localidad: "COLONIA FRANCESA",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5304,
    localidad: "COLONIA FIDELA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 5305,
    localidad: "COLONIA FERNANDEZ",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5306,
    localidad: "COLONIA ELLA",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5307,
    localidad: "COLONIA EL TOBA",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5308,
    localidad: "COLONIA EL SIMBOL",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 5309,
    localidad: "COLONIA EL OCHENTA",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 5310,
    localidad: "COLONIA EL CARMEN",
    cp: 2138,
    provincia_id: 21,
  },
  {
    id: 5311,
    localidad: "COLONIA DURAN",
    cp: 3553,
    provincia_id: 21,
  },
  {
    id: 5312,
    localidad: "COLONIA DOLORES",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 5313,
    localidad: "COLONIA CORONDINA",
    cp: 2240,
    provincia_id: 21,
  },
  {
    id: 5314,
    localidad: "COLONIA CLODOMIRA",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 5315,
    localidad: "COLONIA CLARA",
    cp: 3025,
    provincia_id: 21,
  },
  {
    id: 5316,
    localidad: "COLONIA CELLO",
    cp: 2405,
    provincia_id: 21,
  },
  {
    id: 5317,
    localidad: "COLONIA CEFERINA",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 5318,
    localidad: "COLONIA CASTELLANOS",
    cp: 2310,
    provincia_id: 21,
  },
  {
    id: 5319,
    localidad: "COLONIA CASTELAR",
    cp: 2401,
    provincia_id: 21,
  },
  {
    id: 5320,
    localidad: "COLONIA CANDELARIA",
    cp: 2170,
    provincia_id: 21,
  },
  {
    id: 5321,
    localidad: "COLONIA CALIFORNIA",
    cp: 3005,
    provincia_id: 21,
  },
  {
    id: 5322,
    localidad: "COLONIA BOSSI",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 5323,
    localidad: "COLONIA BIGAND",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5324,
    localidad: "COLONIA BICHA",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5325,
    localidad: "COLONIA BERLIN",
    cp: 2313,
    provincia_id: 21,
  },
  {
    id: 5326,
    localidad: "COLONIA BELGRANO",
    cp: 2257,
    provincia_id: 21,
  },
  {
    id: 5327,
    localidad: "COLONIA ARGENTINA",
    cp: 6140,
    provincia_id: 21,
  },
  {
    id: 5328,
    localidad: "COLONIA ANGELONI",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 5329,
    localidad: "COLONIA ALTHUAUS",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5330,
    localidad: "COLONIA ALDAO",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5331,
    localidad: "COLMENA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5332,
    localidad: "COLASTINE NORTE",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5333,
    localidad: "COLASTINE",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5334,
    localidad: "CLUCELLAS ESTACION",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5335,
    localidad: "CLUCELLAS",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5336,
    localidad: "CLAVEL BLANCO",
    cp: 2341,
    provincia_id: 21,
  },
  {
    id: 5337,
    localidad: "CLASON",
    cp: 2146,
    provincia_id: 21,
  },
  {
    id: 5338,
    localidad: "CLASON",
    cp: 2151,
    provincia_id: 21,
  },
  {
    id: 5339,
    localidad: "CLARKE",
    cp: 2218,
    provincia_id: 21,
  },
  {
    id: 5340,
    localidad: "CICARELLI",
    cp: 2500,
    provincia_id: 21,
  },
  {
    id: 5341,
    localidad: "CHOVET",
    cp: 2633,
    provincia_id: 21,
  },
  {
    id: 5342,
    localidad: "CHARIGUE",
    cp: 2100,
    provincia_id: 21,
  },
  {
    id: 5343,
    localidad: "CHAPUY",
    cp: 2603,
    provincia_id: 21,
  },
  {
    id: 5344,
    localidad: "CHAÑAR LADEADO",
    cp: 2643,
    provincia_id: 21,
  },
  {
    id: 5345,
    localidad: "CHAÑAR LADEADO",
    cp: 2139,
    provincia_id: 21,
  },
  {
    id: 5346,
    localidad: "CHABAS",
    cp: 2173,
    provincia_id: 21,
  },
  {
    id: 5347,
    localidad: "CERES",
    cp: 2340,
    provincia_id: 21,
  },
  {
    id: 5348,
    localidad: "CERANA",
    cp: 2202,
    provincia_id: 21,
  },
  {
    id: 5349,
    localidad: "CEPEDA",
    cp: 2118,
    provincia_id: 21,
  },
  {
    id: 5350,
    localidad: "CENTENO",
    cp: 2148,
    provincia_id: 21,
  },
  {
    id: 5351,
    localidad: "CAYASTACITO",
    cp: 3038,
    provincia_id: 21,
  },
  {
    id: 5352,
    localidad: "CAYASTA",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5353,
    localidad: "CAVOUR",
    cp: 3081,
    provincia_id: 21,
  },
  {
    id: 5354,
    localidad: "CAVOUR",
    cp: 3082,
    provincia_id: 21,
  },
  {
    id: 5355,
    localidad: "CASTELLONES",
    cp: 2302,
    provincia_id: 21,
  },
  {
    id: 5356,
    localidad: "CASILDA",
    cp: 2170,
    provincia_id: 21,
  },
  {
    id: 5357,
    localidad: "CASAS",
    cp: 2149,
    provincia_id: 21,
  },
  {
    id: 5358,
    localidad: "CASALEGNO",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5359,
    localidad: "CASABLANCA",
    cp: 2317,
    provincia_id: 21,
  },
  {
    id: 5360,
    localidad: "CARRIZALES",
    cp: 2218,
    provincia_id: 21,
  },
  {
    id: 5361,
    localidad: "CARRERAS",
    cp: 2729,
    provincia_id: 21,
  },
  {
    id: 5362,
    localidad: "CARMEN DEL SAUCE",
    cp: 2109,
    provincia_id: 21,
  },
  {
    id: 5363,
    localidad: "CARMEN DEL SAUCE",
    cp: 2135,
    provincia_id: 21,
  },
  {
    id: 5364,
    localidad: "CARMEN",
    cp: 2618,
    provincia_id: 21,
  },
  {
    id: 5365,
    localidad: "CARLOS PELLEGRINI",
    cp: 2453,
    provincia_id: 21,
  },
  {
    id: 5366,
    localidad: "CARLOS DOSE",
    cp: 2635,
    provincia_id: 21,
  },
  {
    id: 5367,
    localidad: "CARCEL M CORONDA",
    cp: 2240,
    provincia_id: 21,
  },
  {
    id: 5368,
    localidad: "CARCARAÑA",
    cp: 2138,
    provincia_id: 21,
  },
  {
    id: 5369,
    localidad: "CARAGUATAY",
    cp: 3557,
    provincia_id: 21,
  },
  {
    id: 5370,
    localidad: "CAQADA ROSQUIN",
    cp: 2454,
    provincia_id: 21,
  },
  {
    id: 5371,
    localidad: "CAPIVARA",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 5372,
    localidad: "CAPITAN BERMUDEZ",
    cp: 2154,
    provincia_id: 21,
  },
  {
    id: 5373,
    localidad: "CAPILLA SANTA ROSA",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 5374,
    localidad: "CAPILLA SAN JOSE",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 5375,
    localidad: "CAPILLA S ANTONIO",
    cp: 3600,
    provincia_id: 21,
  },
  {
    id: 5376,
    localidad: "CAPILLA G NORTE",
    cp: 3574,
    provincia_id: 21,
  },
  {
    id: 5377,
    localidad: "CAÑADA ROSQUIN",
    cp: 2454,
    provincia_id: 21,
  },
  {
    id: 5378,
    localidad: "CAÑADA RICA",
    cp: 2105,
    provincia_id: 21,
  },
  {
    id: 5379,
    localidad: "CAÑADA DEL UCLE",
    cp: 2635,
    provincia_id: 21,
  },
  {
    id: 5380,
    localidad: "CAÑADA DE GOMEZ",
    cp: 2500,
    provincia_id: 21,
  },
  {
    id: 5381,
    localidad: "CANDIOTI",
    cp: 2469,
    provincia_id: 21,
  },
  {
    id: 5382,
    localidad: "CANDIOTI",
    cp: 3018,
    provincia_id: 21,
  },
  {
    id: 5383,
    localidad: "CANDELARIA SUD",
    cp: 2170,
    provincia_id: 21,
  },
  {
    id: 5384,
    localidad: "CANDELARIA NORTE",
    cp: 2535,
    provincia_id: 21,
  },
  {
    id: 5385,
    localidad: "CAMPO ZURBRIGGEN",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5386,
    localidad: "CAMPO ZAVALLA",
    cp: 3045,
    provincia_id: 21,
  },
  {
    id: 5387,
    localidad: "CAMPO YAGUARETE",
    cp: 3586,
    provincia_id: 21,
  },
  {
    id: 5388,
    localidad: "CAMPO UBAJO",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 5389,
    localidad: "CAMPO TORQUINSTON",
    cp: 2403,
    provincia_id: 21,
  },
  {
    id: 5390,
    localidad: "CAMPO SANTA ISABEL",
    cp: 2505,
    provincia_id: 21,
  },
  {
    id: 5391,
    localidad: "CAMPO SAN JOSE",
    cp: 3060,
    provincia_id: 21,
  },
  {
    id: 5392,
    localidad: "CAMPO S DOMINGO",
    cp: 3020,
    provincia_id: 21,
  },
  {
    id: 5393,
    localidad: "CAMPO RUEDA",
    cp: 2109,
    provincia_id: 21,
  },
  {
    id: 5394,
    localidad: "CAMPO ROMERO",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5395,
    localidad: "CAMPO RODRIGUEZ",
    cp: 2255,
    provincia_id: 21,
  },
  {
    id: 5396,
    localidad: "CAMPO RAMSEYER",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5397,
    localidad: "CAMPO RAFFO",
    cp: 2216,
    provincia_id: 21,
  },
  {
    id: 5398,
    localidad: "CAMPO QUIRNO",
    cp: 2607,
    provincia_id: 21,
  },
  {
    id: 5399,
    localidad: "CAMPO QUIQONES",
    cp: 2258,
    provincia_id: 21,
  },
  {
    id: 5400,
    localidad: "CAMPO PESOA",
    cp: 2173,
    provincia_id: 21,
  },
  {
    id: 5401,
    localidad: "CAMPO PALETTA",
    cp: 2206,
    provincia_id: 21,
  },
  {
    id: 5402,
    localidad: "CAMPO NUEVO",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5403,
    localidad: "CAMPO MOURE",
    cp: 2240,
    provincia_id: 21,
  },
  {
    id: 5404,
    localidad: "CAMPO MEDINA",
    cp: 2142,
    provincia_id: 21,
  },
  {
    id: 5405,
    localidad: "CAMPO MAGNIN",
    cp: 3011,
    provincia_id: 21,
  },
  {
    id: 5406,
    localidad: "CAMPO LEHMAN",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5407,
    localidad: "CAMPO LA RIVIERE",
    cp: 2505,
    provincia_id: 21,
  },
  {
    id: 5408,
    localidad: "CAMPO LA PAZ",
    cp: 2512,
    provincia_id: 21,
  },
  {
    id: 5409,
    localidad: "CAMPO LA AMISTAD",
    cp: 2508,
    provincia_id: 21,
  },
  {
    id: 5410,
    localidad: "CAMPO ITURRASPE",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5411,
    localidad: "CAMPO HUBER",
    cp: 3555,
    provincia_id: 21,
  },
  {
    id: 5412,
    localidad: "CAMPO HORQUESCO",
    cp: 2144,
    provincia_id: 21,
  },
  {
    id: 5413,
    localidad: "CAMPO HARDY",
    cp: 3592,
    provincia_id: 21,
  },
  {
    id: 5414,
    localidad: "CAMPO GRANDE",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5415,
    localidad: "CAMPO GIMENEZ",
    cp: 2253,
    provincia_id: 21,
  },
  {
    id: 5416,
    localidad: "CAMPO GIMBATTI",
    cp: 2520,
    provincia_id: 21,
  },
  {
    id: 5417,
    localidad: "CAMPO GENERO",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5418,
    localidad: "CAMPO GARCIA",
    cp: 2240,
    provincia_id: 21,
  },
  {
    id: 5419,
    localidad: "CAMPO GARAY",
    cp: 3066,
    provincia_id: 21,
  },
  {
    id: 5420,
    localidad: "CAMPO GARABATO",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5421,
    localidad: "CAMPO GALLOSO",
    cp: 2212,
    provincia_id: 21,
  },
  {
    id: 5422,
    localidad: "CAMPO FURRER",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5423,
    localidad: "CAMPO EL ARAZA",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5424,
    localidad: "CAMPO DEL MEDIO",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5425,
    localidad: "CAMPO DARATTI",
    cp: 2307,
    provincia_id: 21,
  },
  {
    id: 5426,
    localidad: "CAMPO CRESPO",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5427,
    localidad: "CAMPO CRENNA",
    cp: 2185,
    provincia_id: 21,
  },
  {
    id: 5428,
    localidad: "CAMPO COUBERT",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 5429,
    localidad: "CAMPO CLUCELLAS",
    cp: 2407,
    provincia_id: 21,
  },
  {
    id: 5430,
    localidad: "CAMPO CHARO",
    cp: 2520,
    provincia_id: 21,
  },
  {
    id: 5431,
    localidad: "CAMPO CASTRO",
    cp: 2148,
    provincia_id: 21,
  },
  {
    id: 5432,
    localidad: "CAMPO CARIGNANO",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5433,
    localidad: "CAMPO CALVO",
    cp: 2123,
    provincia_id: 21,
  },
  {
    id: 5434,
    localidad: "CAMPO BRARDA",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5435,
    localidad: "CAMPO BERRAZ",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5436,
    localidad: "CAMPO BELGRANO",
    cp: 3062,
    provincia_id: 21,
  },
  {
    id: 5437,
    localidad: "CAMPO ANDINO",
    cp: 3021,
    provincia_id: 21,
  },
  {
    id: 5438,
    localidad: "CALCHINES",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5439,
    localidad: "CALCHAQUI",
    cp: 3050,
    provincia_id: 21,
  },
  {
    id: 5440,
    localidad: "CAFFERATA",
    cp: 2643,
    provincia_id: 21,
  },
  {
    id: 5441,
    localidad: "CACIQUE ARIACAIQUIN",
    cp: 3041,
    provincia_id: 21,
  },
  {
    id: 5442,
    localidad: "CABEZA DE CHANCHO",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5443,
    localidad: "CABAQA EL CISNE",
    cp: 2322,
    provincia_id: 21,
  },
  {
    id: 5444,
    localidad: "CABAL",
    cp: 3091,
    provincia_id: 21,
  },
  {
    id: 5445,
    localidad: "C TRES MARIAS",
    cp: 2216,
    provincia_id: 21,
  },
  {
    id: 5446,
    localidad: "C TOSCANA SEGUNDA",
    cp: 2185,
    provincia_id: 21,
  },
  {
    id: 5447,
    localidad: "C TOSCANA PRIMERA",
    cp: 2185,
    provincia_id: 21,
  },
  {
    id: 5448,
    localidad: "C SOL DE MAYO",
    cp: 3048,
    provincia_id: 21,
  },
  {
    id: 5449,
    localidad: "C SIETE provincia_idS",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5450,
    localidad: "C SANTA NATALIA",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5451,
    localidad: "C SANTA LUCIA",
    cp: 2609,
    provincia_id: 21,
  },
  {
    id: 5452,
    localidad: "C SANTA CATALINA",
    cp: 3572,
    provincia_id: 21,
  },
  {
    id: 5453,
    localidad: "C SANTA ANITA",
    cp: 2451,
    provincia_id: 21,
  },
  {
    id: 5454,
    localidad: "C SAN JOAQUIN",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5455,
    localidad: "C SAN FRANCISCO",
    cp: 2527,
    provincia_id: 21,
  },
  {
    id: 5456,
    localidad: "C SAN ANTONIO",
    cp: 2312,
    provincia_id: 21,
  },
  {
    id: 5457,
    localidad: "C REINA MARGARITA",
    cp: 2309,
    provincia_id: 21,
  },
  {
    id: 5458,
    localidad: "C PALO LABRADO",
    cp: 2415,
    provincia_id: 21,
  },
  {
    id: 5459,
    localidad: "C NUEVA NARCISO",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5460,
    localidad: "C MONTE LA VIRUELA",
    cp: 3551,
    provincia_id: 21,
  },
  {
    id: 5461,
    localidad: "C MANUEL MENCHACA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5462,
    localidad: "C MALHMAN SUD",
    cp: 2347,
    provincia_id: 21,
  },
  {
    id: 5463,
    localidad: "C LOS ZAPALLOS",
    cp: 3003,
    provincia_id: 21,
  },
  {
    id: 5464,
    localidad: "C LAGO DI COMO",
    cp: 2187,
    provincia_id: 21,
  },
  {
    id: 5465,
    localidad: "C LA VICTORIA",
    cp: 2354,
    provincia_id: 21,
  },
  {
    id: 5466,
    localidad: "C LA PALESTINA",
    cp: 2645,
    provincia_id: 21,
  },
  {
    id: 5467,
    localidad: "C LA NICOLASA",
    cp: 3056,
    provincia_id: 21,
  },
  {
    id: 5468,
    localidad: "C LA MAG DE ORO",
    cp: 6132,
    provincia_id: 21,
  },
  {
    id: 5469,
    localidad: "C LA CARMENSITA",
    cp: 6141,
    provincia_id: 21,
  },
  {
    id: 5470,
    localidad: "C LA  PELLEGRINI",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5471,
    localidad: "C LA  PALENCIA",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5472,
    localidad: "C LA  CATALANA",
    cp: 2637,
    provincia_id: 21,
  },
  {
    id: 5473,
    localidad: "C GRAL B O HIGINS",
    cp: 2644,
    provincia_id: 21,
  },
  {
    id: 5474,
    localidad: "C EL VEINTICINCO",
    cp: 3560,
    provincia_id: 21,
  },
  {
    id: 5475,
    localidad: "C CASTELLANOS",
    cp: 2310,
    provincia_id: 21,
  },
  {
    id: 5476,
    localidad: "C CAMPO BOTTO",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 5477,
    localidad: "C BELLA ITALIA",
    cp: 2300,
    provincia_id: 21,
  },
  {
    id: 5478,
    localidad: "C ARIACAIQUIN",
    cp: 3041,
    provincia_id: 21,
  },
  {
    id: 5479,
    localidad: "C ADOLFO ALSINA",
    cp: 3029,
    provincia_id: 21,
  },
  {
    id: 5480,
    localidad: "BUSTINZA",
    cp: 2504,
    provincia_id: 21,
  },
  {
    id: 5481,
    localidad: "BOUQUET",
    cp: 2523,
    provincia_id: 21,
  },
  {
    id: 5482,
    localidad: "BOSSI",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 5483,
    localidad: "BORGHI",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 5484,
    localidad: "BOMBAL",
    cp: 2179,
    provincia_id: 21,
  },
  {
    id: 5485,
    localidad: "BIGAND",
    cp: 2177,
    provincia_id: 21,
  },
  {
    id: 5486,
    localidad: "BERRETTA",
    cp: 2501,
    provincia_id: 21,
  },
  {
    id: 5487,
    localidad: "BERNARDO DE IRIGOYEN",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5488,
    localidad: "BERNARD",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 5489,
    localidad: "BERNA",
    cp: 3569,
    provincia_id: 21,
  },
  {
    id: 5490,
    localidad: "BERABEVU 1",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5491,
    localidad: "BERABEVU",
    cp: 2639,
    provincia_id: 21,
  },
  {
    id: 5492,
    localidad: "BELLA ITALIA",
    cp: 2308,
    provincia_id: 21,
  },
  {
    id: 5493,
    localidad: "BELLA ITALIA",
    cp: 2301,
    provincia_id: 21,
  },
  {
    id: 5494,
    localidad: "BEALISTOCK",
    cp: 2326,
    provincia_id: 21,
  },
  {
    id: 5495,
    localidad: "BAUER Y SIGEL",
    cp: 2403,
    provincia_id: 21,
  },
  {
    id: 5496,
    localidad: "BARRIO CAIMA",
    cp: 2245,
    provincia_id: 21,
  },
  {
    id: 5497,
    localidad: "BARRIO BELGRANO",
    cp: 2550,
    provincia_id: 21,
  },
  {
    id: 5498,
    localidad: "BARRANQUITAS",
    cp: 3000,
    provincia_id: 21,
  },
  {
    id: 5499,
    localidad: "BARRANCAS",
    cp: 2246,
    provincia_id: 21,
  },
  {
    id: 5500,
    localidad: "BARLETT",
    cp: 2175,
    provincia_id: 21,
  },
  {
    id: 5501,
    localidad: "BAJO LAS TUNAS",
    cp: 3017,
    provincia_id: 21,
  },
  {
    id: 5502,
    localidad: "B DE IRIGOYEN",
    cp: 2248,
    provincia_id: 21,
  },
  {
    id: 5503,
    localidad: "AVICHUNCHO",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5504,
    localidad: "AVENA",
    cp: 2449,
    provincia_id: 21,
  },
  {
    id: 5505,
    localidad: "AVELLANEDA",
    cp: 3561,
    provincia_id: 21,
  },
  {
    id: 5506,
    localidad: "AV EJERCITO PARANA",
    cp: 3100,
    provincia_id: 21,
  },
  {
    id: 5507,
    localidad: "AURELIA SUD",
    cp: 2318,
    provincia_id: 21,
  },
  {
    id: 5508,
    localidad: "AURELIA NORTE",
    cp: 2318,
    provincia_id: 21,
  },
  {
    id: 5509,
    localidad: "AURELIA",
    cp: 2318,
    provincia_id: 21,
  },
  {
    id: 5510,
    localidad: "ATALIVA",
    cp: 2307,
    provincia_id: 21,
  },
  {
    id: 5511,
    localidad: "ASUNCION MARIA",
    cp: 3040,
    provincia_id: 21,
  },
  {
    id: 5512,
    localidad: "ASCOCHINGAS",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5513,
    localidad: "ARTEAGA",
    cp: 2187,
    provincia_id: 21,
  },
  {
    id: 5514,
    localidad: "ARROYO SECO",
    cp: 2128,
    provincia_id: 21,
  },
  {
    id: 5515,
    localidad: "ARROYO LEYES",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5516,
    localidad: "ARROYO DEL REY",
    cp: 3565,
    provincia_id: 21,
  },
  {
    id: 5517,
    localidad: "ARROYO CEIBAL",
    cp: 3575,
    provincia_id: 21,
  },
  {
    id: 5518,
    localidad: "ARROYO AGUIAR",
    cp: 3031,
    provincia_id: 21,
  },
  {
    id: 5519,
    localidad: "ARRASCAETA",
    cp: 3046,
    provincia_id: 21,
  },
  {
    id: 5520,
    localidad: "ARON CASTELLANOS",
    cp: 6106,
    provincia_id: 21,
  },
  {
    id: 5521,
    localidad: "AROMOS",
    cp: 3036,
    provincia_id: 21,
  },
  {
    id: 5522,
    localidad: "AROCENA",
    cp: 2242,
    provincia_id: 21,
  },
  {
    id: 5523,
    localidad: "AROCENA",
    cp: 2244,
    provincia_id: 21,
  },
  {
    id: 5524,
    localidad: "ARMSTRONG",
    cp: 2508,
    provincia_id: 21,
  },
  {
    id: 5525,
    localidad: "ARMINDA",
    cp: 2119,
    provincia_id: 21,
  },
  {
    id: 5526,
    localidad: "ARIJON",
    cp: 2242,
    provincia_id: 21,
  },
  {
    id: 5527,
    localidad: "AREQUITO",
    cp: 2183,
    provincia_id: 21,
  },
  {
    id: 5528,
    localidad: "ANTONIO PINI",
    cp: 3061,
    provincia_id: 21,
  },
  {
    id: 5529,
    localidad: "ANGELICA",
    cp: 2303,
    provincia_id: 21,
  },
  {
    id: 5530,
    localidad: "ANGEL GALLARDO",
    cp: 3014,
    provincia_id: 21,
  },
  {
    id: 5531,
    localidad: "ANDINO",
    cp: 2215,
    provincia_id: 21,
  },
  {
    id: 5532,
    localidad: "AMENABAR",
    cp: 6103,
    provincia_id: 21,
  },
  {
    id: 5533,
    localidad: "AMBROSETTI",
    cp: 2339,
    provincia_id: 21,
  },
  {
    id: 5534,
    localidad: "AMBROSETTI",
    cp: 2352,
    provincia_id: 21,
  },
  {
    id: 5535,
    localidad: "ALVEAR",
    cp: 2126,
    provincia_id: 21,
  },
  {
    id: 5536,
    localidad: "ALVEAR",
    cp: 2130,
    provincia_id: 21,
  },
  {
    id: 5537,
    localidad: "ALVAREZ",
    cp: 2107,
    provincia_id: 21,
  },
  {
    id: 5538,
    localidad: "ALTO VERDE",
    cp: 3001,
    provincia_id: 21,
  },
  {
    id: 5539,
    localidad: "ALEJANDRA",
    cp: 3051,
    provincia_id: 21,
  },
  {
    id: 5540,
    localidad: "ALDEA CHALECO",
    cp: 3100,
    provincia_id: 21,
  },
  {
    id: 5541,
    localidad: "ALDAO",
    cp: 2214,
    provincia_id: 21,
  },
  {
    id: 5542,
    localidad: "ALCORTA",
    cp: 2117,
    provincia_id: 21,
  },
  {
    id: 5543,
    localidad: "ALBARELLOS",
    cp: 2101,
    provincia_id: 21,
  },
  {
    id: 5544,
    localidad: "AGUARA GRANDE",
    cp: 3071,
    provincia_id: 21,
  },
  {
    id: 5545,
    localidad: "AGUARA",
    cp: 3074,
    provincia_id: 21,
  },
  {
    id: 5546,
    localidad: "ADOLFO ALSINA",
    cp: 2311,
    provincia_id: 21,
  },
  {
    id: 5547,
    localidad: "ACEBAL",
    cp: 2109,
    provincia_id: 21,
  },
  {
    id: 5548,
    localidad: "ABIPONES",
    cp: 3042,
    provincia_id: 21,
  },
  {
    id: 5549,
    localidad: "A D G S LORENZO",
    cp: 2156,
    provincia_id: 21,
  },
  {
    id: 5550,
    localidad: "YEGUA MUERTA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5551,
    localidad: "VENTISQUERO MORENO",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5552,
    localidad: "TUCU TUCU",
    cp: 9311,
    provincia_id: 20,
  },
  {
    id: 5553,
    localidad: "TRES PUNTAS",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5554,
    localidad: "TRES LAGOS",
    cp: 9301,
    provincia_id: 20,
  },
  {
    id: 5555,
    localidad: "TRES CERROS",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5556,
    localidad: "TOMA DE LOS CES",
    cp: 9107,
    provincia_id: 20,
  },
  {
    id: 5557,
    localidad: "TELLIER",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5558,
    localidad: "TEHUELCHES",
    cp: 9019,
    provincia_id: 20,
  },
  {
    id: 5559,
    localidad: "TAPI AIKE",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5560,
    localidad: "TAMEL AIKE",
    cp: 9315,
    provincia_id: 20,
  },
  {
    id: 5561,
    localidad: "SEC. AVILES  E S J",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5562,
    localidad: "SARAI",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5563,
    localidad: "ROSPENTEK AIKE",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5564,
    localidad: "ROSPENTEK",
    cp: 9398,
    provincia_id: 20,
  },
  {
    id: 5565,
    localidad: "RIO TURBIO",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5566,
    localidad: "RIO MITRE",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5567,
    localidad: "RIO GALLEGOS",
    cp: 9403,
    provincia_id: 20,
  },
  {
    id: 5568,
    localidad: "RIO GALLEGOS",
    cp: 9402,
    provincia_id: 20,
  },
  {
    id: 5569,
    localidad: "RIO GALLEGOS",
    cp: 9400,
    provincia_id: 20,
  },
  {
    id: 5570,
    localidad: "RIO FENIX",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5571,
    localidad: "RIO CHICO",
    cp: 9303,
    provincia_id: 20,
  },
  {
    id: 5572,
    localidad: "RIO CALAFATE",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5573,
    localidad: "RIO BOTE",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5574,
    localidad: "RINCON D L MORROS",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5575,
    localidad: "QUIEN SABE",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5576,
    localidad: "PUNTA MARIA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5577,
    localidad: "PUNTA LOYOLA",
    cp: 9400,
    provincia_id: 20,
  },
  {
    id: 5578,
    localidad: "PUNTA BANDERA",
    cp: 9404,
    provincia_id: 20,
  },
  {
    id: 5579,
    localidad: "PUERTO SANTA CRUZ",
    cp: 9300,
    provincia_id: 20,
  },
  {
    id: 5580,
    localidad: "PUERTO SAN JULIAN",
    cp: 9310,
    provincia_id: 20,
  },
  {
    id: 5581,
    localidad: "PUERTO DESEADO",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5582,
    localidad: "PUENTE BLANCO",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5583,
    localidad: "PUEBLO NUEVO",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5584,
    localidad: "PIEDRA CLAVADA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5585,
    localidad: "PIEDRA CLAVADA",
    cp: 9301,
    provincia_id: 20,
  },
  {
    id: 5586,
    localidad: "PICO TRUNCADO",
    cp: 9015,
    provincia_id: 20,
  },
  {
    id: 5587,
    localidad: "PERITO MORENO",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5588,
    localidad: "PELLEGRINI",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5589,
    localidad: "PASO GREGORES",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5590,
    localidad: "PASO DE LOS INDIOS",
    cp: 9303,
    provincia_id: 20,
  },
  {
    id: 5591,
    localidad: "PASO CHARLES FHUR",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5592,
    localidad: "PAMPA VERDUM",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5593,
    localidad: "NACNTOS DEL PLUMA",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5594,
    localidad: "MORRO CHICO",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5595,
    localidad: "MONTE VERDE",
    cp: 9019,
    provincia_id: 20,
  },
  {
    id: 5596,
    localidad: "MONTE CEBALLOS",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5597,
    localidad: "MISION S M FAGNAN",
    cp: 9410,
    provincia_id: 20,
  },
  {
    id: 5598,
    localidad: "MINA 3",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5599,
    localidad: "MESETA GUENGUE",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5600,
    localidad: "MAZAREDO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5601,
    localidad: "MATA MAGALLANES",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5602,
    localidad: "LOS ANTIGUOS",
    cp: 9041,
    provincia_id: 20,
  },
  {
    id: 5603,
    localidad: "LEANDRO N ALEM",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5604,
    localidad: "LAS PIRAMIDES",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5605,
    localidad: "LAS MASITAS",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5606,
    localidad: "LAS HERAS",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5607,
    localidad: "LAGUNA GRANDE",
    cp: 9303,
    provincia_id: 20,
  },
  {
    id: 5608,
    localidad: "LAGUNA GRANDE",
    cp: 9107,
    provincia_id: 20,
  },
  {
    id: 5609,
    localidad: "LAGO ROCA",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5610,
    localidad: "LAGO POSADAS",
    cp: 9315,
    provincia_id: 20,
  },
  {
    id: 5611,
    localidad: "LAGO KHAMI",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5612,
    localidad: "LAGO CARDIEL",
    cp: 9301,
    provincia_id: 20,
  },
  {
    id: 5613,
    localidad: "LAGO BUENOS AIRES",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5614,
    localidad: "LAGO BUENOS AIRES",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5615,
    localidad: "LAGO ARGENTINO",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5616,
    localidad: "LA VICTORIA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5617,
    localidad: "LA ROSADA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5618,
    localidad: "LA PROTEGIDA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5619,
    localidad: "LA MARIA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5620,
    localidad: "LA MARGARITA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5621,
    localidad: "LA MADRUGADA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5622,
    localidad: "LA FECUNDIDAD",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5623,
    localidad: "LA ESTHER",
    cp: 9011,
    provincia_id: 20,
  },
  {
    id: 5624,
    localidad: "LA ESTELA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5625,
    localidad: "LA DOROTEA",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5626,
    localidad: "LA CENTRAL",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5627,
    localidad: "LA ASTURIANA",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5628,
    localidad: "LA ARGENTINA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5629,
    localidad: "LA AGUADA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5630,
    localidad: "KOLUEL KAIKE",
    cp: 9019,
    provincia_id: 20,
  },
  {
    id: 5631,
    localidad: "KILOMETRO 8",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5632,
    localidad: "JULIA DUFOUR",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5633,
    localidad: "JARAMILLO",
    cp: 9053,
    provincia_id: 20,
  },
  {
    id: 5634,
    localidad: "ISLAS ORCADAS",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5635,
    localidad: "ISLAS GEORGIAS",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5636,
    localidad: "ISLA SOLEDAD",
    cp: 9409,
    provincia_id: 20,
  },
  {
    id: 5637,
    localidad: "ISLA JOINVILLE",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5638,
    localidad: "ISLA GRAN MALVINA",
    cp: 9409,
    provincia_id: 20,
  },
  {
    id: 5639,
    localidad: "ING PALLAVICINI",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5640,
    localidad: "INDIA MUERTA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5641,
    localidad: "I SHETLAND DEL SUR",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5642,
    localidad: "I DE LOS ESTADOS",
    cp: 9410,
    provincia_id: 20,
  },
  {
    id: 5643,
    localidad: "HOSTERIA KAIKEN",
    cp: 9410,
    provincia_id: 20,
  },
  {
    id: 5644,
    localidad: "GUER AIKE",
    cp: 9401,
    provincia_id: 20,
  },
  {
    id: 5645,
    localidad: "GOBERNADOR MOYANO",
    cp: 9050,
    provincia_id: 20,
  },
  {
    id: 5646,
    localidad: "GOBERNADOR GREGORES",
    cp: 9311,
    provincia_id: 20,
  },
  {
    id: 5647,
    localidad: "GLENCROSS",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5648,
    localidad: "GAYPON",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5649,
    localidad: "FUENTES DE COYLE",
    cp: 9401,
    provincia_id: 20,
  },
  {
    id: 5650,
    localidad: "FORTALEZA",
    cp: 9401,
    provincia_id: 20,
  },
  {
    id: 5651,
    localidad: "FITZ ROY",
    cp: 9018,
    provincia_id: 20,
  },
  {
    id: 5652,
    localidad: "ESTANCIA TEPI",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5653,
    localidad: "ESTANCIA SARA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5654,
    localidad: "ESTANCIA SAN PABLO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5655,
    localidad: "ESTANCIA SAN JUSTO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5656,
    localidad: "ESTANCIA SAN JULIO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5657,
    localidad: "ESTANCIA RUBY",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5658,
    localidad: "ESTANCIA RIVADAVIA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5659,
    localidad: "ESTANCIA RIO EWAN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5660,
    localidad: "ESTANCIA RIO CLARO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5661,
    localidad: "ESTANCIA POLICARPO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5662,
    localidad: "ESTANCIA MIRAMONTE",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5663,
    localidad: "ESTANCIA MARINA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5664,
    localidad: "ESTANCIA LAS HIJAS",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5665,
    localidad: "ESTANCIA INES",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5666,
    localidad: "ESTANCIA HERMINITA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5667,
    localidad: "ESTANCIA HARBERTON",
    cp: 9410,
    provincia_id: 20,
  },
  {
    id: 5668,
    localidad: "ESTANCIA GUAZU CUE",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5669,
    localidad: "ESTANCIA EL RODEO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5670,
    localidad: "ESTANCIA CULLEN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5671,
    localidad: "ESTANCIA CAUCHICO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5672,
    localidad: "ESTANCIA CARMEN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5673,
    localidad: "ESTANCIA AURELIA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5674,
    localidad: "ESTACION OSN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5675,
    localidad: "ESTACION AERONAVAL",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5676,
    localidad: "EL ZURDO",
    cp: 9401,
    provincia_id: 20,
  },
  {
    id: 5677,
    localidad: "EL TURBIO",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5678,
    localidad: "EL SALADO",
    cp: 9313,
    provincia_id: 20,
  },
  {
    id: 5679,
    localidad: "EL PORTEZUELO",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5680,
    localidad: "EL POLVORIN",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5681,
    localidad: "EL PLUMA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5682,
    localidad: "EL PARAMO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5683,
    localidad: "EL LORO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5684,
    localidad: "EL HUECO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5685,
    localidad: "EL CHARA",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5686,
    localidad: "EL CHALTEN",
    cp: 9301,
    provincia_id: 20,
  },
  {
    id: 5687,
    localidad: "EL CERRITO",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5688,
    localidad: "EL CALAFATE",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5689,
    localidad: "EBENECER",
    cp: 9107,
    provincia_id: 20,
  },
  {
    id: 5690,
    localidad: "E SAN MARTIN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5691,
    localidad: "E RIO IRIGOYEN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5692,
    localidad: "E MARIA LUISA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5693,
    localidad: "E MARIA CRISTINA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5694,
    localidad: "E MARIA BEHETY",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5695,
    localidad: "E LOS FLAMENCOS",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5696,
    localidad: "E LOS CERROS",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5697,
    localidad: "E LAS VIOLETAS",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5698,
    localidad: "E LA PORTEQA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5699,
    localidad: "E LA INDIANA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5700,
    localidad: "E LA FUEGUINA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5701,
    localidad: "E LA CRIOLLA",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5702,
    localidad: "E JOSE MENENDEZ",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5703,
    localidad: "E EL SALVADOR",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5704,
    localidad: "E DOS HEMANAS",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5705,
    localidad: "E C ALTE BROWN",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5706,
    localidad: "E BUENOS AIRES",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5707,
    localidad: "D MELCHIOR",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5708,
    localidad: "CUEVA DE LAS MANOS",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5709,
    localidad: "CONDOR CLIF",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5710,
    localidad: "COMISARIA RADMAN",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5711,
    localidad: "COMANDANTE LUIS PIEDRABUENA",
    cp: 9303,
    provincia_id: 20,
  },
  {
    id: 5712,
    localidad: "CNEL M IRIGOYEN",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5713,
    localidad: "CERRO VANGUARDIA",
    cp: 9310,
    provincia_id: 20,
  },
  {
    id: 5714,
    localidad: "CERRO SILVA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5715,
    localidad: "CERRO RENZEL",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5716,
    localidad: "CERRO REDONDO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5717,
    localidad: "CERRO PUNTUDO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5718,
    localidad: "CERRO MANGRULLO",
    cp: 9011,
    provincia_id: 20,
  },
  {
    id: 5719,
    localidad: "CERRO LA SETENTA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5720,
    localidad: "CARA MALA",
    cp: 9313,
    provincia_id: 20,
  },
  {
    id: 5721,
    localidad: "CAÑADON SECO",
    cp: 9013,
    provincia_id: 20,
  },
  {
    id: 5722,
    localidad: "CAÑADON PLUMA",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5723,
    localidad: "CAÑADON BOTELLO",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5724,
    localidad: "CAÑADA DE LAS VACAS",
    cp: 9305,
    provincia_id: 20,
  },
  {
    id: 5725,
    localidad: "CANCHA CARRERA",
    cp: 9400,
    provincia_id: 20,
  },
  {
    id: 5726,
    localidad: "CAMPAMENTO DOROTEA",
    cp: 9407,
    provincia_id: 20,
  },
  {
    id: 5727,
    localidad: "CAMP VILLEGAS",
    cp: 9107,
    provincia_id: 20,
  },
  {
    id: 5728,
    localidad: "CAMERON",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5729,
    localidad: "CALETA OLIVIA",
    cp: 9011,
    provincia_id: 20,
  },
  {
    id: 5730,
    localidad: "CABO TRES PUNTAS",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5731,
    localidad: "CABO SAN PABLO",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5732,
    localidad: "CABO BLANCO",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5733,
    localidad: "CABAQA RUBY",
    cp: 9420,
    provincia_id: 20,
  },
  {
    id: 5734,
    localidad: "C LEANDRO N ALEM",
    cp: 9040,
    provincia_id: 20,
  },
  {
    id: 5735,
    localidad: "C C PELLEGRINI",
    cp: 9017,
    provincia_id: 20,
  },
  {
    id: 5736,
    localidad: "BOCA ZANJA SUD",
    cp: 9107,
    provincia_id: 20,
  },
  {
    id: 5737,
    localidad: "BELLA VISTA",
    cp: 9401,
    provincia_id: 20,
  },
  {
    id: 5738,
    localidad: "BAJO CARACOLES",
    cp: 9315,
    provincia_id: 20,
  },
  {
    id: 5739,
    localidad: "BAHIA TRANQUILA",
    cp: 9405,
    provincia_id: 20,
  },
  {
    id: 5740,
    localidad: "BAHIA LAURA",
    cp: 9310,
    provincia_id: 20,
  },
  {
    id: 5741,
    localidad: "BAHIA LAPATAIA",
    cp: 9410,
    provincia_id: 20,
  },
  {
    id: 5742,
    localidad: "BAHIA LANGARA",
    cp: 9011,
    provincia_id: 20,
  },
  {
    id: 5743,
    localidad: "B EJERCITO SOBRAL",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5744,
    localidad: "B EJERCITO ESPZA",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5745,
    localidad: "B E PRIMAVERA",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5746,
    localidad: "B E GRAL S M",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5747,
    localidad: "B E GRAL BELGRANO",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5748,
    localidad: "B E GRAL BELGRAN 3",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5749,
    localidad: "B AEREA V MARAMBIO",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5750,
    localidad: "B AEREA T MATIENZO",
    cp: 9411,
    provincia_id: 20,
  },
  {
    id: 5751,
    localidad: "AGUADA ALEGRE",
    cp: 9310,
    provincia_id: 20,
  },
  {
    id: 5752,
    localidad: "AGUADA A PIQUE",
    cp: 9051,
    provincia_id: 20,
  },
  {
    id: 5753,
    localidad: "28 DE NOVIEMBRE",
    cp: 9408,
    provincia_id: 20,
  },
  {
    id: 5754,
    localidad: "ZAMPAL",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5755,
    localidad: "YACORO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5756,
    localidad: "VIZCACHERAS",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5757,
    localidad: "VIVA LA PATRIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5758,
    localidad: "VISTA HERMOSA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5759,
    localidad: "VISTA ALEGRE",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5760,
    localidad: "VISCACHERAS",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 5761,
    localidad: "VIRARCO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5762,
    localidad: "VILLA SANTIAGO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5763,
    localidad: "VILLA SANTA RITA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 5764,
    localidad: "VILLA REYNOLDS",
    cp: 5733,
    provincia_id: 19,
  },
  {
    id: 5765,
    localidad: "VILLA MERCEDES",
    cp: 5732,
    provincia_id: 19,
  },
  {
    id: 5766,
    localidad: "VILLA MERCEDES",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5767,
    localidad: "VILLA LARCA",
    cp: 5882,
    provincia_id: 19,
  },
  {
    id: 5768,
    localidad: "VILLA LARCA",
    cp: 6334,
    provincia_id: 19,
  },
  {
    id: 5769,
    localidad: "VILLA GENERAL ROCA",
    cp: 5498,
    provincia_id: 19,
  },
  {
    id: 5770,
    localidad: "VILLA DOLORES",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5771,
    localidad: "VILLA DEL CARMEN",
    cp: 5835,
    provincia_id: 19,
  },
  {
    id: 5772,
    localidad: "VILLA DE PRAGA",
    cp: 5762,
    provincia_id: 19,
  },
  {
    id: 5773,
    localidad: "VILLA DE PRAGA",
    cp: 5639,
    provincia_id: 19,
  },
  {
    id: 5774,
    localidad: "VILLA DE LA QUEBRADA",
    cp: 5716,
    provincia_id: 19,
  },
  {
    id: 5775,
    localidad: "VILLA ANGELICA",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 5776,
    localidad: "VIEJA ESTANCIA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5777,
    localidad: "VENTA DE LOS RIOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5778,
    localidad: "VARELA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5779,
    localidad: "VALLECITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5780,
    localidad: "VALLE SAN JOSE",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 5781,
    localidad: "VALLE SAN AGUSTIN",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 5782,
    localidad: "VALLE HERMOSO",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5783,
    localidad: "VACAS MUERTAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5784,
    localidad: "V SIERRAS DE LAGO",
    cp: 5857,
    provincia_id: 19,
  },
  {
    id: 5785,
    localidad: "V RAFAEL BENEGAS",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 5786,
    localidad: "V DE LA QUEBRADA",
    cp: 5716,
    provincia_id: 19,
  },
  {
    id: 5787,
    localidad: "V DE LA PANCANTA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5788,
    localidad: "V A DE LOS REYES",
    cp: 5857,
    provincia_id: 19,
  },
  {
    id: 5789,
    localidad: "UNQUILLO",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5790,
    localidad: "UNION",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5791,
    localidad: "UNIDAD T EMBALSE",
    cp: 5857,
    provincia_id: 19,
  },
  {
    id: 5792,
    localidad: "UCHAIMA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5793,
    localidad: "TUKIROS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5794,
    localidad: "TRES MARIAS",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 5795,
    localidad: "TRES LAGUNAS",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 5796,
    localidad: "TRES CAQADAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5797,
    localidad: "TRES CAQADAS",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5798,
    localidad: "TRECE DE ENERO",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 5799,
    localidad: "TRAVESIA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5800,
    localidad: "TRAPICHE",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5801,
    localidad: "TRANSVAL",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5802,
    localidad: "TOTORILLA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5803,
    localidad: "TOTORAL",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5804,
    localidad: "TOTORAL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5805,
    localidad: "TORO NEGRO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5806,
    localidad: "TORO BAYO",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5807,
    localidad: "TOINGUA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5808,
    localidad: "TOIGUS",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5809,
    localidad: "TINTITACO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5810,
    localidad: "TILISARAO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5811,
    localidad: "TEMERARIA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5812,
    localidad: "TAZA BLANCA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5813,
    localidad: "TASTO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 5814,
    localidad: "TAMBOREO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5815,
    localidad: "TAMASCANES",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5816,
    localidad: "TALARCITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5817,
    localidad: "TALA VERDE",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5818,
    localidad: "TALA CRUZ",
    cp: 5859,
    provincia_id: 19,
  },
  {
    id: 5819,
    localidad: "TA HUILCO",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 5820,
    localidad: "SOLOLOSTA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5821,
    localidad: "SOLOBASTA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5822,
    localidad: "SOL DE ABRIL",
    cp: 5757,
    provincia_id: 19,
  },
  {
    id: 5823,
    localidad: "SOL DE ABRIL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5824,
    localidad: "SOL D ABRIL -SAN M",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5825,
    localidad: "SOCOSCORA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5826,
    localidad: "SNAS D BEBEDERO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 5827,
    localidad: "SERAFINA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5828,
    localidad: "SELCI",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5829,
    localidad: "SEIS DE SEPTIEMBRE",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5830,
    localidad: "SEGUNDA USINA",
    cp: 5857,
    provincia_id: 19,
  },
  {
    id: 5831,
    localidad: "SAUCESITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5832,
    localidad: "SAUCE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5833,
    localidad: "SANTO DOMINGO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5834,
    localidad: "SANTO DOMINGO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5835,
    localidad: "SANTA VICTORIA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5836,
    localidad: "SANTA TERESITA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 5837,
    localidad: "SANTA TERESA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5838,
    localidad: "SANTA SIMONA",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5839,
    localidad: "SANTA RUFINA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 5840,
    localidad: "SANTA ROSA DE CONLARA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 5841,
    localidad: "SANTA ROSA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5842,
    localidad: "SANTA RITA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 5843,
    localidad: "SANTA MARTINA",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5844,
    localidad: "SANTA MARIA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5845,
    localidad: "SANTA MARIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5846,
    localidad: "SANTA LUCIA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5847,
    localidad: "SANTA LUCIA",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 5848,
    localidad: "SANTA ISABEL",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5849,
    localidad: "SANTA ISABEL",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5850,
    localidad: "SANTA ISABEL",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5851,
    localidad: "SANTA FELISA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5852,
    localidad: "SANTA FELICITAS",
    cp: 6220,
    provincia_id: 19,
  },
  {
    id: 5853,
    localidad: "SANTA DIONISIA",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 5854,
    localidad: "SANTA CLARA",
    cp: 5157,
    provincia_id: 19,
  },
  {
    id: 5855,
    localidad: "SANTA CLARA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5856,
    localidad: "SANTA CLARA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5857,
    localidad: "SANTA CLARA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5858,
    localidad: "SANTA CLARA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5859,
    localidad: "SANTA CECILIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5860,
    localidad: "SANTA CATALINA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 5861,
    localidad: "SANTA ANA",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 5862,
    localidad: "SANTA ANA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5863,
    localidad: "SANT ANA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5864,
    localidad: "SAN VICENTE",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5865,
    localidad: "SAN VICENTE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5866,
    localidad: "SAN URBANO",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 5867,
    localidad: "SAN SALVADOR",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5868,
    localidad: "SAN RUFINO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5869,
    localidad: "SAN ROQUE",
    cp: 5718,
    provincia_id: 19,
  },
  {
    id: 5870,
    localidad: "SAN ROQUE",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5871,
    localidad: "SAN RAMON SUD",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 5872,
    localidad: "SAN RAMON",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5873,
    localidad: "SAN RAMON",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5874,
    localidad: "SAN RAIMUNDO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5875,
    localidad: "SAN RAFAEL",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5876,
    localidad: "SAN PEDRO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5877,
    localidad: "SAN PEDRO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5878,
    localidad: "SAN PEDRO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5879,
    localidad: "SAN PEDRO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5880,
    localidad: "SAN PABLO",
    cp: 5774,
    provincia_id: 19,
  },
  {
    id: 5881,
    localidad: "SAN NICOLAS PUNILLA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5882,
    localidad: "SAN MIGUEL",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5883,
    localidad: "SAN MIGUEL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5884,
    localidad: "SAN MIGUEL",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 5885,
    localidad: "SAN MIGUEL",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5886,
    localidad: "SAN MARTIN",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5887,
    localidad: "SAN MARTIN",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5888,
    localidad: "SAN MARTIN",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5889,
    localidad: "SAN LUIS",
    cp: 5886,
    provincia_id: 19,
  },
  {
    id: 5890,
    localidad: "SAN LUIS",
    cp: 5780,
    provincia_id: 19,
  },
  {
    id: 5891,
    localidad: "SAN LUIS",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 5892,
    localidad: "SAN LUIS",
    cp: 5702,
    provincia_id: 19,
  },
  {
    id: 5893,
    localidad: "SAN LORENZO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5894,
    localidad: "SAN LORENZO",
    cp: 5757,
    provincia_id: 19,
  },
  {
    id: 5895,
    localidad: "SAN JUAN DE TASTU",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 5896,
    localidad: "SAN JOSE",
    cp: 5740,
    provincia_id: 19,
  },
  {
    id: 5897,
    localidad: "SAN JOSE",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5898,
    localidad: "SAN JOSE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5899,
    localidad: "SAN JOSE",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5900,
    localidad: "SAN JORGE",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5901,
    localidad: "SAN JORGE",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 5902,
    localidad: "SAN JORGE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5903,
    localidad: "SAN ISIDRO",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5904,
    localidad: "SAN ISIDRO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5905,
    localidad: "SAN ISIDRO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5906,
    localidad: "SAN IGNACIO",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 5907,
    localidad: "SAN GREGORIO",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 5908,
    localidad: "SAN GERONIMO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5909,
    localidad: "SAN GERONIMO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5910,
    localidad: "SAN FERNANDO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5911,
    localidad: "SAN FELIPE",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 5912,
    localidad: "SAN FCO DEL MONTE DE ORO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5913,
    localidad: "SAN CELESTINO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5914,
    localidad: "SAN CAMILO",
    cp: 5743,
    provincia_id: 19,
  },
  {
    id: 5915,
    localidad: "SAN BARTOLOME",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 5916,
    localidad: "SAN ANTONIO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5917,
    localidad: "SAN ANTONIO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5918,
    localidad: "SAN ANTONIO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5919,
    localidad: "SAN ALEJANDRO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5920,
    localidad: "SAN ALBERTO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5921,
    localidad: "SALTO CHICO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5922,
    localidad: "SALITRAL",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5923,
    localidad: "SALINAS DEL BEBEDERO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 5924,
    localidad: "SALINAS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5925,
    localidad: "SALADO DE AMAYA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5926,
    localidad: "SALADO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5927,
    localidad: "SALADO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5928,
    localidad: "SALADILLO",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 5929,
    localidad: "S ROSA DE CONLARA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 5930,
    localidad: "S JOSE DEL DURAZNO",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 5931,
    localidad: "S J DE L CHAQARES",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5932,
    localidad: "S F DEL M. DE ORO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5933,
    localidad: "RUMIGUASI",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5934,
    localidad: "ROSALES",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5935,
    localidad: "RODEO CADENAS",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5936,
    localidad: "RIOJITA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5937,
    localidad: "RIO QUINTO",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 5938,
    localidad: "RIO JUAN GOMEZ",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5939,
    localidad: "RIO GRANDE",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5940,
    localidad: "RINCON DEL CARMEN",
    cp: 5779,
    provincia_id: 19,
  },
  {
    id: 5941,
    localidad: "RIECITO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5942,
    localidad: "RETIRO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5943,
    localidad: "RETAZO DEL MONTE",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 5944,
    localidad: "RETAMO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5945,
    localidad: "REPRESA DEL MONTE",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5946,
    localidad: "REPRESA DEL MONTE",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 5947,
    localidad: "REPRESA DEL CHAQAR",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5948,
    localidad: "REPRESA DEL CARMEN",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5949,
    localidad: "RENCA",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 5950,
    localidad: "REFORMA CHICA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 5951,
    localidad: "RECONQUISTA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 5952,
    localidad: "REAL",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5953,
    localidad: "RANQUELCO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 5954,
    localidad: "RAMON SEGUNDO",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 5955,
    localidad: "RAMON QUINTAS",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 5956,
    localidad: "RAMBLONES",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5957,
    localidad: "RAMADITA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5958,
    localidad: "QURILAY",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 5959,
    localidad: "QUINES",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5960,
    localidad: "QUEBRADA HONDA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 5961,
    localidad: "QUEBRADA DEL TIGRE",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 5962,
    localidad: "QUEBRADA DE SAN VICENTE",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5963,
    localidad: "QUEBRADA DE LA MORA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5964,
    localidad: "QUEBRACHO LADEADO",
    cp: 5874,
    provincia_id: 19,
  },
  {
    id: 5965,
    localidad: "QUEBRACHO LADEADO",
    cp: 5779,
    provincia_id: 19,
  },
  {
    id: 5966,
    localidad: "QUEBRACHITO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5967,
    localidad: "Q DE S VICENTE",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5968,
    localidad: "Q DE LOS POZOS",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 5969,
    localidad: "Q DE LOS BARROSOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5970,
    localidad: "Q DE LA MORA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5971,
    localidad: "Q DE LA BURRA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 5972,
    localidad: "PUNTOS DE LA LINEA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5973,
    localidad: "PUNTOS DE AGUA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5974,
    localidad: "PUNTA DEL CERRO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5975,
    localidad: "PUNTA DEL ALTO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5976,
    localidad: "PUNTA DE LA LOMA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5977,
    localidad: "PUNILLA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5978,
    localidad: "PUESTO TALAR",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5979,
    localidad: "PUESTO ROBERTO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 5980,
    localidad: "PUESTO EL TALA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5981,
    localidad: "PUESTO DE TABARES",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 5982,
    localidad: "PUESTO BELLA VISTA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 5983,
    localidad: "PUESTITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5984,
    localidad: "PUERTO RICO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5985,
    localidad: "PUERTO ALEGRE",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 5986,
    localidad: "PUERTA DE PALO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 5987,
    localidad: "PUERTA DE PALO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 5988,
    localidad: "PUERTA DE LA ISLA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5989,
    localidad: "PUERTA COLORADA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5990,
    localidad: "PUENTE LOS MOLLES",
    cp: 5809,
    provincia_id: 19,
  },
  {
    id: 5991,
    localidad: "PUENTE LA ORQUETA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 5992,
    localidad: "PUENTE HIERRO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 5993,
    localidad: "PTA DEL PORTEZUELO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 5994,
    localidad: "PRIMER AGUA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 5995,
    localidad: "POZO SIMON",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5996,
    localidad: "POZO SECO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 5997,
    localidad: "POZO SANTIAGO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 5998,
    localidad: "POZO FRIO",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 5999,
    localidad: "POZO DEL TALA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6000,
    localidad: "POZO DEL MOLLE",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6001,
    localidad: "POZO DEL MEDIO",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6002,
    localidad: "POZO DEL ESPINILLO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6003,
    localidad: "POZO DEL CARRIL",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6004,
    localidad: "POZO DE LOS RAYOS",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6005,
    localidad: "POZO DE LAS RAICES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6006,
    localidad: "POZO CERCADO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6007,
    localidad: "POZO CAVADO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6008,
    localidad: "POTRERO DE LOS FUNES",
    cp: 5704,
    provincia_id: 19,
  },
  {
    id: 6009,
    localidad: "POTRERO DE LOS FUNES",
    cp: 5714,
    provincia_id: 19,
  },
  {
    id: 6010,
    localidad: "POTRERILLO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6011,
    localidad: "POT DE LOS FUNES",
    cp: 5714,
    provincia_id: 19,
  },
  {
    id: 6012,
    localidad: "POSTA DE FIERRO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6013,
    localidad: "PORTADA DEL SAUCE",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6014,
    localidad: "POLLEDO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6015,
    localidad: "POCITOS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6016,
    localidad: "PLANTA DE SANDIA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6017,
    localidad: "PIZARRAS B VELEZ",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6018,
    localidad: "PISCOYACO",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6019,
    localidad: "PIEDRAS CHATAS",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6020,
    localidad: "PIEDRAS BLANCAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6021,
    localidad: "PIEDRAS ANCHAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6022,
    localidad: "PIEDRA SOLA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6023,
    localidad: "PIEDRA ROSADA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6024,
    localidad: "PIEDRA LARGA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6025,
    localidad: "PIEDRA BOLA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6026,
    localidad: "PIEDRA BLANCA",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6027,
    localidad: "PIE DE LA CUESTA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6028,
    localidad: "PICOS YACU",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6029,
    localidad: "PICHI  HUILCO",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6030,
    localidad: "PESCADORES",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6031,
    localidad: "PERMANENTES",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6032,
    localidad: "PEQON COLORADO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6033,
    localidad: "PENICE",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6034,
    localidad: "PEDERNERA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6035,
    localidad: "PAUNERO",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6036,
    localidad: "PATIO LIMPIO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6037,
    localidad: "PASTAL",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6038,
    localidad: "PASO LAS TROPAS",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6039,
    localidad: "PASO JUAN GOMEZ",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6040,
    localidad: "PASO GRANDE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6041,
    localidad: "PASO GRANDE",
    cp: 5761,
    provincia_id: 19,
  },
  {
    id: 6042,
    localidad: "PASO DEL REY",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6043,
    localidad: "PASO DEL MEDIO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6044,
    localidad: "PASO DEL DURAZNO",
    cp: 5803,
    provincia_id: 19,
  },
  {
    id: 6045,
    localidad: "PASO DE PIEDRA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6046,
    localidad: "PASO DE LOS BAYOS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6047,
    localidad: "PASO DE LAS VACAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6048,
    localidad: "PASO DE LAS TOSCAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6049,
    localidad: "PASO DE LA TIERRA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6050,
    localidad: "PASO DE LA CRUZ",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6051,
    localidad: "PASO DE CUERO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6052,
    localidad: "PASO ANCHO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6053,
    localidad: "PARAISO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6054,
    localidad: "PAPAGAYOS",
    cp: 5883,
    provincia_id: 19,
  },
  {
    id: 6055,
    localidad: "PANTANILLOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6056,
    localidad: "PANTANILLO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6057,
    localidad: "PAMPITA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6058,
    localidad: "PAMPA INVERNADA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6059,
    localidad: "PAMPA GRANDE",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6060,
    localidad: "PAMPA GRANDE",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6061,
    localidad: "PAMPA DEL BAJO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6062,
    localidad: "PAMPA DE LOS GES",
    cp: 5757,
    provincia_id: 19,
  },
  {
    id: 6063,
    localidad: "PAMPA D TAMBORERO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6064,
    localidad: "PAMPA",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6065,
    localidad: "PAMPA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6066,
    localidad: "PALOMAR",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6067,
    localidad: "PALIGUANTA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6068,
    localidad: "PAJE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6069,
    localidad: "PAINES",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6070,
    localidad: "P Q CAL",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6071,
    localidad: "P PAMPA INVERNADA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6072,
    localidad: "P LOS ALGARROBOS",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6073,
    localidad: "P DE LOS JUMES",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6074,
    localidad: "P DE LOS GAUCHOS",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6075,
    localidad: "P DE LAS SALINAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6076,
    localidad: "P DE LAS CARRETAS",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6077,
    localidad: "P DE L ALGARROBOS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6078,
    localidad: "OTRA BANDA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6079,
    localidad: "ONCE DE MAYO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6080,
    localidad: "OJO DEL RIO",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6081,
    localidad: "OJO DE AGUA",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6082,
    localidad: "OJO DE AGUA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6083,
    localidad: "OJO DE AGUA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6084,
    localidad: "NUEVA GALIA",
    cp: 6399,
    provincia_id: 19,
  },
  {
    id: 6085,
    localidad: "NUEVA ESCOCIA",
    cp: 5743,
    provincia_id: 19,
  },
  {
    id: 6086,
    localidad: "NOSSAR",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6087,
    localidad: "NOGOLI",
    cp: 5720,
    provincia_id: 19,
  },
  {
    id: 6088,
    localidad: "NEGRO MUERTO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6089,
    localidad: "NASCHEL",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6090,
    localidad: "NARANJO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6091,
    localidad: "NAHUEL MAPA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6092,
    localidad: "MOYAR",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6093,
    localidad: "MOSMOTA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6094,
    localidad: "MONTE VERDE",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6095,
    localidad: "MONTE LA INVERNADA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6096,
    localidad: "MONTE CHIQUITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6097,
    localidad: "MONTE CARMELO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6098,
    localidad: "MOLLECITO",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6099,
    localidad: "MINA SANTO DOMINGO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6100,
    localidad: "MINA LOS CONDORES",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6101,
    localidad: "MINA CAROLINA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6102,
    localidad: "MILAGRO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6103,
    localidad: "MERLO",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 6104,
    localidad: "MERCEDES",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6105,
    localidad: "MERCEDES",
    cp: 5732,
    provincia_id: 19,
  },
  {
    id: 6106,
    localidad: "MEDIA LUNA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6107,
    localidad: "MEDIA LUNA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6108,
    localidad: "MEDANOS",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6109,
    localidad: "MEDANO GRANDE",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6110,
    localidad: "MEDANO CHICO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6111,
    localidad: "MEDANO BLANCO",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6112,
    localidad: "MEDANO BALLO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6113,
    localidad: "MATACO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6114,
    localidad: "MARTIN DE LOYOLA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6115,
    localidad: "MARMOL VERDE",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6116,
    localidad: "MARLITO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6117,
    localidad: "MARAY",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6118,
    localidad: "MARAVILLA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6119,
    localidad: "MANTILLA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6120,
    localidad: "MANANTIALES",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6121,
    localidad: "MANANTIAL LINDO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6122,
    localidad: "MANANTIAL GRANDE",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6123,
    localidad: "MANANTIAL DE RENCA",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6124,
    localidad: "MANANTIAL DE FLORES",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6125,
    localidad: "MANANTIAL BLANCO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6126,
    localidad: "MANANTIAL",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6127,
    localidad: "MANANTIAL",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6128,
    localidad: "MANANTIAL",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6129,
    localidad: "MAN DE FLORES",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6130,
    localidad: "MALVINAS ARG",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6131,
    localidad: "LUJAN",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6132,
    localidad: "LOS VALLES",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6133,
    localidad: "LOS TIGRES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6134,
    localidad: "LOS TELARIOS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6135,
    localidad: "LOS TAPIALES",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6136,
    localidad: "LOS TAMARIQOS",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6137,
    localidad: "LOS TALAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6138,
    localidad: "LOS TALAS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6139,
    localidad: "LOS SAUCES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6140,
    localidad: "LOS SAUCES",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6141,
    localidad: "LOS ROLDANES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6142,
    localidad: "LOS RAMBLONES",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6143,
    localidad: "LOS QUINIENTOS",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6144,
    localidad: "LOS QUEBRACHOS",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6145,
    localidad: "LOS PUQUIOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6146,
    localidad: "LOS PUESTOS",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6147,
    localidad: "LOS POZOS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6148,
    localidad: "LOS POLEOS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6149,
    localidad: "LOS POLEOS",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6150,
    localidad: "LOS PEROS",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6151,
    localidad: "LOS PEJES",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6152,
    localidad: "LOS PASITOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6153,
    localidad: "LOS NOQUES",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6154,
    localidad: "LOS NOGALES",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6155,
    localidad: "LOS MONTES",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6156,
    localidad: "LOS MOLLES",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6157,
    localidad: "LOS MOLLES",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6158,
    localidad: "LOS MOLLES",
    cp: 5883,
    provincia_id: 19,
  },
  {
    id: 6159,
    localidad: "LOS MOLLES",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6160,
    localidad: "LOS MOLLES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6161,
    localidad: "LOS MOLLECITOS",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6162,
    localidad: "LOS MENDOCINOS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6163,
    localidad: "LOS MENBRILLOS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6164,
    localidad: "LOS MEDANOS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6165,
    localidad: "LOS MEDANITOS",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6166,
    localidad: "LOS MANANTIALES",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6167,
    localidad: "LOS LOBOS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6168,
    localidad: "LOS LECHUZONES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6169,
    localidad: "LOS JAGUELES",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6170,
    localidad: "LOS HUAYCOS",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6171,
    localidad: "LOS HINOJOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6172,
    localidad: "LOS ESQUINEROS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6173,
    localidad: "LOS DURAZNITOS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6174,
    localidad: "LOS DUEROS",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6175,
    localidad: "LOS CORRALITOS",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6176,
    localidad: "LOS CORRALES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6177,
    localidad: "LOS COROS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6178,
    localidad: "LOS CONDORES",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6179,
    localidad: "LOS COMEDORES",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6180,
    localidad: "LOS COMEDEROS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6181,
    localidad: "LOS CLAVELES",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6182,
    localidad: "LOS CISNES",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6183,
    localidad: "LOS CHENAS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6184,
    localidad: "LOS CHAQARITOS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6185,
    localidad: "LOS CHAQARES",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6186,
    localidad: "LOS CHAQARES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6187,
    localidad: "LOS CHAQARES",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6188,
    localidad: "LOS CESARES",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6189,
    localidad: "LOS CERRITOS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6190,
    localidad: "LOS CERRILLOS",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6191,
    localidad: "LOS CERRILLOS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6192,
    localidad: "LOS CERRILLOS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6193,
    localidad: "LOS CARRICITOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6194,
    localidad: "LOS BARRIALES",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6195,
    localidad: "LOS ARROYOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6196,
    localidad: "LOS ARGUELLOS",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6197,
    localidad: "LOS ARCES",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6198,
    localidad: "LOS ARADITOS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6199,
    localidad: "LOS ALMACIGOS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6200,
    localidad: "LOS ALGARROBOS",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6201,
    localidad: "LOS ALGARROBOS",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6202,
    localidad: "LOS ALGARROBOS",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6203,
    localidad: "LOS ALGARROBOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6204,
    localidad: "LOS ALGARROBITOS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6205,
    localidad: "LOS ALAMOS",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6206,
    localidad: "LOS ALAMOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6207,
    localidad: "LOS AGUADOS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6208,
    localidad: "LONGARI",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6209,
    localidad: "LOMITAS",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6210,
    localidad: "LOMAS BLANCAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6211,
    localidad: "LOMA DEL MEDIO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6212,
    localidad: "LOMA BOLA",
    cp: 5879,
    provincia_id: 19,
  },
  {
    id: 6213,
    localidad: "LINDO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6214,
    localidad: "LINCE",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6215,
    localidad: "LIBORIO LUNA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6216,
    localidad: "LAVAISSE",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6217,
    localidad: "LAURA ELISA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6218,
    localidad: "LAS VISCACHERAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6219,
    localidad: "LAS TRES PIEDRAS",
    cp: 5879,
    provincia_id: 19,
  },
  {
    id: 6220,
    localidad: "LAS TRES CAQADAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6221,
    localidad: "LAS TOTORITAS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6222,
    localidad: "LAS TOSCAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6223,
    localidad: "LAS TIGRAS",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6224,
    localidad: "LAS TAPIAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6225,
    localidad: "LAS SALINAS",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6226,
    localidad: "LAS ROSAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6227,
    localidad: "LAS ROSADAS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6228,
    localidad: "LAS RAICES",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6229,
    localidad: "LAS PUERTAS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6230,
    localidad: "LAS PRADERAS",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6231,
    localidad: "LAS PLAYAS ARGS",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6232,
    localidad: "LAS PLAYAS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6233,
    localidad: "LAS PIEDRITAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6234,
    localidad: "LAS PEQAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6235,
    localidad: "LAS PAMPITAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6236,
    localidad: "LAS PALOMAS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6237,
    localidad: "LAS PALMAS",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6238,
    localidad: "LAS NIEVES",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6239,
    localidad: "LAS MORAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6240,
    localidad: "LAS MESIAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6241,
    localidad: "LAS MELADAS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6242,
    localidad: "LAS MARTINETAS",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6243,
    localidad: "LAS MANGAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6244,
    localidad: "LAS MALVINAS",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6245,
    localidad: "LAS LOMAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6246,
    localidad: "LAS LOMAS",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6247,
    localidad: "LAS LAJAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6248,
    localidad: "LAS LAGUNITAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6249,
    localidad: "LAS LAGUNITAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6250,
    localidad: "LAS LAGUNAS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6251,
    localidad: "LAS LAGUNAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6252,
    localidad: "LAS ISLITAS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6253,
    localidad: "LAS HIGUERAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6254,
    localidad: "LAS HIGUERAS",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6255,
    localidad: "LAS GUINDAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6256,
    localidad: "LAS GAMAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6257,
    localidad: "LAS FLORES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6258,
    localidad: "LAS FLORES",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6259,
    localidad: "LAS ENCADENADAS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6260,
    localidad: "LAS DELICIAS",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6261,
    localidad: "LAS DELICIAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6262,
    localidad: "LAS COLONIAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6263,
    localidad: "LAS CLARITAS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6264,
    localidad: "LAS CHIMBAS",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6265,
    localidad: "LAS CHILCAS",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6266,
    localidad: "LAS CHACRITAS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6267,
    localidad: "LAS CHACRAS DE SAN MARTIN",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6268,
    localidad: "LAS CHACRAS",
    cp: 5880,
    provincia_id: 19,
  },
  {
    id: 6269,
    localidad: "LAS CHACRAS",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6270,
    localidad: "LAS CHACRAS",
    cp: 5752,
    provincia_id: 19,
  },
  {
    id: 6271,
    localidad: "LAS CARRETAS",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6272,
    localidad: "LAS CAROLINAS",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6273,
    localidad: "LAS CARITAS",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6274,
    localidad: "LAS CAQITAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6275,
    localidad: "LAS CAQITAS",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6276,
    localidad: "LAS CAQAS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6277,
    localidad: "LAS CANTERAS",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6278,
    localidad: "LAS CALECITAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6279,
    localidad: "LAS CABRAS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6280,
    localidad: "LAS C. DE S MARTIN",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6281,
    localidad: "LAS C DE S MARTIN",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6282,
    localidad: "LAS BARRANQUITAS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6283,
    localidad: "LAS BARRANQUITAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6284,
    localidad: "LAS BARRANCAS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6285,
    localidad: "LAS BAJADAS",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6286,
    localidad: "LAS AGUADAS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6287,
    localidad: "LAS ABAHACAS",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6288,
    localidad: "LAGUNA SECA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6289,
    localidad: "LAGUNA SAYAPE",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6290,
    localidad: "LAGUNA LARGA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6291,
    localidad: "LAGUNA DE PATOS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6292,
    localidad: "LAGUNA CAPELEN",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6293,
    localidad: "LAGUNA BRAVA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6294,
    localidad: "LAGO EPECUEN",
    cp: 6431,
    provincia_id: 19,
  },
  {
    id: 6295,
    localidad: "LAG DE LOS PATOS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6296,
    localidad: "LAG DE LA C",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6297,
    localidad: "LAFINUR",
    cp: 5872,
    provincia_id: 19,
  },
  {
    id: 6298,
    localidad: "LA YESERA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6299,
    localidad: "LA YERBA BUENA",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6300,
    localidad: "LA VOLUNTAD",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6301,
    localidad: "LA VICTORIA",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6302,
    localidad: "LA VERTIENTE",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6303,
    localidad: "LA VERTIENTE",
    cp: 5760,
    provincia_id: 19,
  },
  {
    id: 6304,
    localidad: "LA VERONICA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6305,
    localidad: "LA VERDE",
    cp: 6389,
    provincia_id: 19,
  },
  {
    id: 6306,
    localidad: "LA VERDE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6307,
    localidad: "LA VENECIA",
    cp: 5735,
    provincia_id: 19,
  },
  {
    id: 6308,
    localidad: "LA URUGUAYA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6309,
    localidad: "LA UNION",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6310,
    localidad: "LA UNION",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6311,
    localidad: "LA UNION",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6312,
    localidad: "LA ULBARA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6313,
    localidad: "LA TUSCA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6314,
    localidad: "LA TULA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6315,
    localidad: "LA TOTORA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6316,
    localidad: "LA TOTORA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6317,
    localidad: "LA TOTORA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6318,
    localidad: "LA TOSCA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6319,
    localidad: "LA TOMA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6320,
    localidad: "LA SUIZA",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6321,
    localidad: "LA SIRENA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6322,
    localidad: "LA SILESIA",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6323,
    localidad: "LA SIENA",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6324,
    localidad: "LA SERRANA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6325,
    localidad: "LA SEQA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6326,
    localidad: "LA SEÑA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6327,
    localidad: "LA SELVA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6328,
    localidad: "LA SANDIA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6329,
    localidad: "LA SALVADORA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6330,
    localidad: "LA SALUD",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6331,
    localidad: "LA SALA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6332,
    localidad: "LA ROSALIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6333,
    localidad: "LA ROSADA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6334,
    localidad: "LA RIOJITA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6335,
    localidad: "LA RINCONADA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6336,
    localidad: "LA RIBERA",
    cp: 5734,
    provincia_id: 19,
  },
  {
    id: 6337,
    localidad: "LA RESISTENCIA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6338,
    localidad: "LA RESERVA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6339,
    localidad: "LA REPRESITA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6340,
    localidad: "LA REPRESA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6341,
    localidad: "LA REINA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6342,
    localidad: "LA REFORMA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6343,
    localidad: "LA REALIDAD",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6344,
    localidad: "LA RAMADA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6345,
    localidad: "LA RAMADA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6346,
    localidad: "LA QUEBRADA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6347,
    localidad: "LA PVERA M RIGLOS",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6348,
    localidad: "LA PVERA  S ROSA",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6349,
    localidad: "LA PUNTA",
    cp: 5710,
    provincia_id: 19,
  },
  {
    id: 6350,
    localidad: "LA PUERTA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6351,
    localidad: "LA PROVIDENCIA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6352,
    localidad: "LA PRIMAVERA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6353,
    localidad: "LA PRIMAVERA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6354,
    localidad: "LA PRADERA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6355,
    localidad: "LA PORTADA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6356,
    localidad: "LA PORFIA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6357,
    localidad: "LA PLATA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6358,
    localidad: "LA PETRA",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6359,
    localidad: "LA PEREGRINA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6360,
    localidad: "LA PAULINA",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6361,
    localidad: "LA PATRIA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6362,
    localidad: "LA PAMPEANA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6363,
    localidad: "LA PAMPA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6364,
    localidad: "LA PALMIRA",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6365,
    localidad: "LA NUTRIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6366,
    localidad: "LA NELIDA",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6367,
    localidad: "LA NEGRITA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6368,
    localidad: "LA NEGRA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6369,
    localidad: "LA MODERNA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6370,
    localidad: "LA MINA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6371,
    localidad: "LA MESILLA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6372,
    localidad: "LA MESADA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6373,
    localidad: "LA MELINA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6374,
    localidad: "LA MEDULA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6375,
    localidad: "LA MEDIA LEGUA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6376,
    localidad: "LA MASCOTA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6377,
    localidad: "LA MASCOTA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6378,
    localidad: "LA MAROMA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6379,
    localidad: "LA MARIA",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6380,
    localidad: "LA MARG CARLOTA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6381,
    localidad: "LA MALVINA",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6382,
    localidad: "LA MAJADA",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6383,
    localidad: "LA MAJADA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6384,
    localidad: "LA MAGDALENA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6385,
    localidad: "LA MAGDALENA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6386,
    localidad: "LA LUISA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6387,
    localidad: "LA LUCHA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6388,
    localidad: "LA LINEA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6389,
    localidad: "LA LEGUA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6390,
    localidad: "LA LAURA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6391,
    localidad: "LA JUSTA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6392,
    localidad: "LA JULIA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6393,
    localidad: "LA JUANITA",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6394,
    localidad: "LA JUANITA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6395,
    localidad: "LA JUANA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6396,
    localidad: "LA JOSEFINA",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6397,
    localidad: "LA JOSEFA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6398,
    localidad: "LA JERGA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6399,
    localidad: "LA JAVIERA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6400,
    localidad: "LA ISLA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6401,
    localidad: "LA ISABEL",
    cp: 5743,
    provincia_id: 19,
  },
  {
    id: 6402,
    localidad: "LA IRENE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6403,
    localidad: "LA INVERNADA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6404,
    localidad: "LA INVERNADA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6405,
    localidad: "LA IBERIA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6406,
    localidad: "LA HUERTITA",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6407,
    localidad: "LA HUERTA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6408,
    localidad: "LA HORTENSIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6409,
    localidad: "LA HOLANDA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6410,
    localidad: "LA HIGUERITA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6411,
    localidad: "LA HERMOSURA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6412,
    localidad: "LA GUARDIA",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6413,
    localidad: "LA GRAMILLA",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6414,
    localidad: "LA GITANA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6415,
    localidad: "LA GERMANIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6416,
    localidad: "LA GAVIOTA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6417,
    localidad: "LA GARZA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6418,
    localidad: "LA GARRAPATA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6419,
    localidad: "LA GAMA",
    cp: 5732,
    provincia_id: 19,
  },
  {
    id: 6420,
    localidad: "LA GAMA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6421,
    localidad: "LA FUENTE",
    cp: 5879,
    provincia_id: 19,
  },
  {
    id: 6422,
    localidad: "LA FRAGUA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6423,
    localidad: "LA FORTUNA",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6424,
    localidad: "LA FLORIDA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6425,
    localidad: "LA FLORIDA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6426,
    localidad: "LA FLORIDA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6427,
    localidad: "LA FLORIDA",
    cp: 5726,
    provincia_id: 19,
  },
  {
    id: 6428,
    localidad: "LA FLORIDA",
    cp: 5448,
    provincia_id: 19,
  },
  {
    id: 6429,
    localidad: "LA FLORIDA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6430,
    localidad: "LA FLECHA",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6431,
    localidad: "LA EULOGIA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6432,
    localidad: "LA ESTRELLA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6433,
    localidad: "LA ESTRELLA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6434,
    localidad: "LA ESTANZUELA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6435,
    localidad: "LA ESTANCIA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6436,
    localidad: "LA ESQUINA DEL RIO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6437,
    localidad: "LA ESQUINA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6438,
    localidad: "LA ESQUINA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6439,
    localidad: "LA ESQUINA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6440,
    localidad: "LA ESPESURA",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6441,
    localidad: "LA ESPERANZA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6442,
    localidad: "LA ESPERANZA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6443,
    localidad: "LA ESPERANZA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6444,
    localidad: "LA ESCONDIDA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6445,
    localidad: "LA ESCONDIDA",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6446,
    localidad: "LA ERNESTINA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6447,
    localidad: "LA ENERGIA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6448,
    localidad: "LA EMPAJADA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6449,
    localidad: "LA EMMA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6450,
    localidad: "LA EMILIA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6451,
    localidad: "LA ELVIRA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6452,
    localidad: "LA ELVIRA",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6453,
    localidad: "LA ELISA",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6454,
    localidad: "LA ELIDA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6455,
    localidad: "LA ELENA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6456,
    localidad: "LA DULCE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6457,
    localidad: "LA DUDA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6458,
    localidad: "LA DORA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6459,
    localidad: "LA DOLORES",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6460,
    localidad: "LA DELIA",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6461,
    localidad: "LA CUMBRE",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6462,
    localidad: "LA CRUCECITA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6463,
    localidad: "LA CRISTINA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6464,
    localidad: "LA COSTA",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6465,
    localidad: "LA COSTA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6466,
    localidad: "LA CORTADERA",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6467,
    localidad: "LA CORINA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6468,
    localidad: "LA COLONIA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6469,
    localidad: "LA COLONIA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6470,
    localidad: "LA COLINA",
    cp: 6269,
    provincia_id: 19,
  },
  {
    id: 6471,
    localidad: "LA COCHA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6472,
    localidad: "LA CIENAGA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6473,
    localidad: "LA CHILLA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6474,
    localidad: "LA CHILCA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6475,
    localidad: "LA CHILCA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6476,
    localidad: "LA CHAQARIENTA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6477,
    localidad: "LA CELMIRA",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6478,
    localidad: "LA CELINA",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6479,
    localidad: "LA CAUTIVA",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6480,
    localidad: "LA CASILDA",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6481,
    localidad: "LA CARMENCITA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6482,
    localidad: "LA CARMEN",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6483,
    localidad: "LA CAQADA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6484,
    localidad: "LA CAQADA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6485,
    localidad: "LA CAÑADA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6486,
    localidad: "LA CALERA- SAN LUIS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6487,
    localidad: "LA CALERA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6488,
    localidad: "LA CALDERA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6489,
    localidad: "LA CALAGUALA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6490,
    localidad: "LA CABRA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6491,
    localidad: "LA BRIANZA",
    cp: 5848,
    provincia_id: 19,
  },
  {
    id: 6492,
    localidad: "LA BREA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6493,
    localidad: "LA BREA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6494,
    localidad: "LA BONITA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6495,
    localidad: "LA BERTITA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6496,
    localidad: "LA BAYA",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6497,
    localidad: "LA BAVARIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6498,
    localidad: "LA BAVA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6499,
    localidad: "LA BAJADA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6500,
    localidad: "LA BAJADA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6501,
    localidad: "LA AURORA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6502,
    localidad: "LA AURORA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6503,
    localidad: "LA ATALAYA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6504,
    localidad: "LA ARMONIA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6505,
    localidad: "LA ARBOLEDA",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6506,
    localidad: "LA ARAQA",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6507,
    localidad: "LA ANGELINA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6508,
    localidad: "LA AMARGA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6509,
    localidad: "LA AMALIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6510,
    localidad: "LA ALIANZA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6511,
    localidad: "LA ALCORTEQA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6512,
    localidad: "LA ALAMEDA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6513,
    localidad: "LA AGUEDA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6514,
    localidad: "LA AGUADITA",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6515,
    localidad: "LA AGUADA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6516,
    localidad: "LA AGUADA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6517,
    localidad: "LA AGUADA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6518,
    localidad: "LA AGUADA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6519,
    localidad: "LA AGUADA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6520,
    localidad: "LA AGUA NUEVA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6521,
    localidad: "LA ADELA",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6522,
    localidad: "KILOMETRO 656",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6523,
    localidad: "KILOMETRO 545",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6524,
    localidad: "JUSTO DARACT",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6525,
    localidad: "JUANTE",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6526,
    localidad: "JUANA KOSLAY",
    cp: 5712,
    provincia_id: 19,
  },
  {
    id: 6527,
    localidad: "JUANA KOSLAY",
    cp: 5758,
    provincia_id: 19,
  },
  {
    id: 6528,
    localidad: "JUAN W GEZ",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6529,
    localidad: "JUAN LLERENA",
    cp: 5735,
    provincia_id: 19,
  },
  {
    id: 6530,
    localidad: "JUAN JORBA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6531,
    localidad: "JARILLA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6532,
    localidad: "ISONDU",
    cp: 5735,
    provincia_id: 19,
  },
  {
    id: 6533,
    localidad: "ISLITAS",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6534,
    localidad: "ISLA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6535,
    localidad: "INVERNADA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6536,
    localidad: "INTIGUASI",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6537,
    localidad: "HUERTAS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6538,
    localidad: "HUEJEDA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6539,
    localidad: "HUCLE",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6540,
    localidad: "HUCHISSON",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6541,
    localidad: "HUALTARAN",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6542,
    localidad: "HORNITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6543,
    localidad: "HIPOLITO YRIGOYEN",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6544,
    localidad: "HINOJOS",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6545,
    localidad: "HINOJITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6546,
    localidad: "GUZMAN",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6547,
    localidad: "GUASQUITA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6548,
    localidad: "GUANACO PAMPA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6549,
    localidad: "GUANACO",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6550,
    localidad: "GUALTARAN",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6551,
    localidad: "GRUTA DE INTIHUASI",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6552,
    localidad: "GORGONTA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6553,
    localidad: "GIGANTE",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6554,
    localidad: "GENERAL URQUIZA",
    cp: 5763,
    provincia_id: 19,
  },
  {
    id: 6555,
    localidad: "GENERAL PEDERNERA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6556,
    localidad: "GALLINAO",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6557,
    localidad: "FRAGA",
    cp: 5736,
    provincia_id: 19,
  },
  {
    id: 6558,
    localidad: "FORTUNA DE SAN JUAN",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6559,
    localidad: "FORTUNA DE S JUAN",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6560,
    localidad: "FORTUNA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6561,
    localidad: "FORTIN SALTO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6562,
    localidad: "FORTIN EL PATRIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6563,
    localidad: "FLORIDA",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6564,
    localidad: "FENOGLIO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6565,
    localidad: "ESTANZUELA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6566,
    localidad: "ESTANCIA RIVADAVIA",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6567,
    localidad: "ESTANCIA LA UNION",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6568,
    localidad: "ESTANCIA LA MORENA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6569,
    localidad: "ESTANCIA LA LUCHA",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6570,
    localidad: "ESTANCIA LA BLANCA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6571,
    localidad: "ESTANCIA GRANDE",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6572,
    localidad: "ESTANCIA EL SALADO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6573,
    localidad: "ESTANCIA EL MEDANO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6574,
    localidad: "ESTACION ZANJITAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6575,
    localidad: "EST S FRANCISCO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6576,
    localidad: "ESPINILLO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6577,
    localidad: "ENTRE RIOS",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6578,
    localidad: "ENSENADA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6579,
    localidad: "EMBALSE LA FLORIDA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6580,
    localidad: "ELEODORO LOBOS",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6581,
    localidad: "EL ZAPALLAR",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6582,
    localidad: "EL ZAMPAL",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6583,
    localidad: "EL YACATAN",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6584,
    localidad: "EL VOLCAN",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6585,
    localidad: "EL VERANO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6586,
    localidad: "EL VALLECITO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6587,
    localidad: "EL VALLECITO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6588,
    localidad: "EL VALLE",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6589,
    localidad: "EL VALLE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6590,
    localidad: "EL TOTORAL",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6591,
    localidad: "EL TOTORAL",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6592,
    localidad: "EL TORO MUERTO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6593,
    localidad: "EL TORCIDO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6594,
    localidad: "EL TEMBLEQUE",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6595,
    localidad: "EL TAMBO",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6596,
    localidad: "EL TALITA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6597,
    localidad: "EL TALITA",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6598,
    localidad: "EL TALITA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6599,
    localidad: "EL TALITA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6600,
    localidad: "EL TALA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6601,
    localidad: "EL TAJAMAR",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6602,
    localidad: "EL SOCORRO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6603,
    localidad: "EL SOCORRO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6604,
    localidad: "EL SEMBRADO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6605,
    localidad: "EL SAUZAL",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6606,
    localidad: "EL SAUCE",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6607,
    localidad: "EL SAUCE",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6608,
    localidad: "EL SARCO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6609,
    localidad: "EL SALVADOR",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6610,
    localidad: "EL SALTO NORTE",
    cp: 5854,
    provincia_id: 19,
  },
  {
    id: 6611,
    localidad: "EL SALTO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6612,
    localidad: "EL SALTO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6613,
    localidad: "EL SALADO DE AMAYA",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6614,
    localidad: "EL SALADO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6615,
    localidad: "EL SALADO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6616,
    localidad: "EL ROSARIO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6617,
    localidad: "EL RODEO",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6618,
    localidad: "EL RIO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6619,
    localidad: "EL RINCON",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6620,
    localidad: "EL RINCON",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6621,
    localidad: "EL RIECITO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6622,
    localidad: "EL RETAMO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6623,
    localidad: "EL RECREO",
    cp: 6220,
    provincia_id: 19,
  },
  {
    id: 6624,
    localidad: "EL QUEBRACHO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6625,
    localidad: "EL QUEBRACHO",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6626,
    localidad: "EL PUESTO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6627,
    localidad: "EL PUESTO",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6628,
    localidad: "EL PUESTO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6629,
    localidad: "EL PUESTITO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6630,
    localidad: "EL PUERTO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6631,
    localidad: "EL PUEBLITO",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6632,
    localidad: "EL PUEBLITO",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6633,
    localidad: "EL PROGRESO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6634,
    localidad: "EL POZO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6635,
    localidad: "EL POTRERILLO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6636,
    localidad: "EL POTOSI",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6637,
    localidad: "EL POT DE LEYES",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6638,
    localidad: "EL PORVENIR",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6639,
    localidad: "EL PORVENIR",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6640,
    localidad: "EL PORTEZUELO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6641,
    localidad: "EL POLEO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6642,
    localidad: "EL POLEO",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6643,
    localidad: "EL POCITO",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6644,
    localidad: "EL PLATEADO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6645,
    localidad: "EL PIMPOLLO",
    cp: 5717,
    provincia_id: 19,
  },
  {
    id: 6646,
    localidad: "EL PIGUE",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6647,
    localidad: "EL PERCHEL",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6648,
    localidad: "EL PEJE",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6649,
    localidad: "EL PEJE",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6650,
    localidad: "EL PEDERNAL",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6651,
    localidad: "EL PAYERO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6652,
    localidad: "EL PASTAL",
    cp: 5743,
    provincia_id: 19,
  },
  {
    id: 6653,
    localidad: "EL PASAJERO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6654,
    localidad: "EL PARAISO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6655,
    localidad: "EL PARAISO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6656,
    localidad: "EL PARAGUAY",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6657,
    localidad: "EL PANTANO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6658,
    localidad: "EL PANTANILLO",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6659,
    localidad: "EL PANTANILLO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6660,
    localidad: "EL PAJARETE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6661,
    localidad: "EL OLMO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6662,
    localidad: "EL OASIS",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6663,
    localidad: "EL NEGRO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6664,
    localidad: "EL NASAO",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6665,
    localidad: "EL MTIAL ESCONDIDO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6666,
    localidad: "EL MORRO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6667,
    localidad: "EL MOLLE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6668,
    localidad: "EL MOLLE",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6669,
    localidad: "EL MOLLARCITO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6670,
    localidad: "EL MOLLAR",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6671,
    localidad: "EL MOLINO",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6672,
    localidad: "EL MILAGRO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6673,
    localidad: "EL MATACO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6674,
    localidad: "EL MARTILLO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6675,
    localidad: "EL MANGRULLO",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6676,
    localidad: "EL MANANTIAL",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6677,
    localidad: "EL LEONCITO",
    cp: 5405,
    provincia_id: 19,
  },
  {
    id: 6678,
    localidad: "EL LECHUZO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6679,
    localidad: "EL JARILLAL",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6680,
    localidad: "EL INJERTO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6681,
    localidad: "EL HORNITO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6682,
    localidad: "EL HORMIGUERO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6683,
    localidad: "EL FORTIN",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6684,
    localidad: "EL ESPINILLO",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6685,
    localidad: "EL ESPINILLO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6686,
    localidad: "EL DURAZNO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6687,
    localidad: "EL DURAZNITO",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6688,
    localidad: "EL DIQUE",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6689,
    localidad: "EL CORO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6690,
    localidad: "EL CONDOR",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6691,
    localidad: "EL CHORRILLO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6692,
    localidad: "EL CHORRILLO",
    cp: 5723,
    provincia_id: 19,
  },
  {
    id: 6693,
    localidad: "EL CHARCO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6694,
    localidad: "EL CHARABON",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6695,
    localidad: "EL CHAQAR",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6696,
    localidad: "EL CHAQAR",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6697,
    localidad: "EL CHAQAR",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6698,
    localidad: "EL CHAQAR",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 6699,
    localidad: "EL CERRO",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6700,
    localidad: "EL CERRO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6701,
    localidad: "EL CERRITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6702,
    localidad: "EL CAZADOR",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6703,
    localidad: "EL CAVADO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6704,
    localidad: "EL CARMEN",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6705,
    localidad: "EL CARMEN",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6706,
    localidad: "EL CARDAL",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6707,
    localidad: "EL CAMPAMENTO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6708,
    localidad: "EL CALDEN",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6709,
    localidad: "EL CALDEN",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6710,
    localidad: "EL CALDEN",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6711,
    localidad: "EL CALDEN",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6712,
    localidad: "EL CADILLO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6713,
    localidad: "EL BURRITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6714,
    localidad: "EL BLANCO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6715,
    localidad: "EL BARRIAL",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6716,
    localidad: "EL BAQADO",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6717,
    localidad: "EL BAQADO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6718,
    localidad: "EL BAQADO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6719,
    localidad: "EL BALDECITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6720,
    localidad: "EL BALDE",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6721,
    localidad: "EL BAJO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6722,
    localidad: "EL BAJO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6723,
    localidad: "EL BAGUAL",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6724,
    localidad: "EL ARROYO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6725,
    localidad: "EL ARENAL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6726,
    localidad: "EL ANTOJO",
    cp: 6220,
    provincia_id: 19,
  },
  {
    id: 6727,
    localidad: "EL AMPARO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6728,
    localidad: "EL ALTO",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6729,
    localidad: "EL ALTO",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6730,
    localidad: "EL ALGADOBAL",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6731,
    localidad: "EL AGUILA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6732,
    localidad: "EL  VALLE",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6733,
    localidad: "E TRES ARBOLES",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 6734,
    localidad: "E SAN ANTONIO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6735,
    localidad: "E SAN ALBERTO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6736,
    localidad: "E MIRADOR D JUAREZ",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6737,
    localidad: "E LOS NOGALES",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6738,
    localidad: "E LOS HERMANOS",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6739,
    localidad: "E LAS FLORES",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6740,
    localidad: "E LAS BEBIDAS",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6741,
    localidad: "E LAGO EPECUEN",
    cp: 6431,
    provincia_id: 19,
  },
  {
    id: 6742,
    localidad: "E LA ZULEMITA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6743,
    localidad: "E LA VOLUNTAD",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6744,
    localidad: "E LA RESERVA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6745,
    localidad: "E LA PAMPEANA",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6746,
    localidad: "E LA GUILLERMINA",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6747,
    localidad: "E LA GUARDIA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6748,
    localidad: "E EL SAUCECITO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6749,
    localidad: "E EL QUEBRACHAL",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6750,
    localidad: "E EL DIVISADERO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6751,
    localidad: "E EL CHAMICO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6752,
    localidad: "E DON ARTURO",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6753,
    localidad: "DURAZNITO",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6754,
    localidad: "DURAZNITO",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6755,
    localidad: "DORMIDA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6756,
    localidad: "DIVISADERO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6757,
    localidad: "DIVISADERO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6758,
    localidad: "DIQUE LA FLORIDA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6759,
    localidad: "DANIEL DONOVAN",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6760,
    localidad: "CUEVA DE TIGRE",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6761,
    localidad: "CUATRO ESQUINAS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6762,
    localidad: "CUATRO ESQUINAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6763,
    localidad: "CRUZ DE PIEDRA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6764,
    localidad: "CRUZ DE CAQA",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6765,
    localidad: "CRUZ DE CAQA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6766,
    localidad: "CRUZ DE CAÑA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6767,
    localidad: "CRUZ BRILLANTE",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6768,
    localidad: "CRUCECITAS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6769,
    localidad: "CRAMER",
    cp: 5733,
    provincia_id: 19,
  },
  {
    id: 6770,
    localidad: "CRA LA PRIMAVERA",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6771,
    localidad: "COSTA DEL TAMBO",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6772,
    localidad: "CORTADERAS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6773,
    localidad: "CORTADERAS",
    cp: 5883,
    provincia_id: 19,
  },
  {
    id: 6774,
    localidad: "CORTADERAS",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6775,
    localidad: "CORRALITO S JAVIER",
    cp: 5879,
    provincia_id: 19,
  },
  {
    id: 6776,
    localidad: "CORRALES",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6777,
    localidad: "CORRAL DEL TALA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6778,
    localidad: "CORRAL DE TORRES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6779,
    localidad: "CORRAL DE PIEDRA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6780,
    localidad: "CORONEL SEGOVIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6781,
    localidad: "CORONEL ALZOGARAY",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6782,
    localidad: "CONSULTA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6783,
    localidad: "CONSUELO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6784,
    localidad: "CONLARA",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6785,
    localidad: "CONCARAN",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6786,
    localidad: "COMANDANTE GRANVILLE",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6787,
    localidad: "COM GRANVILLE",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6788,
    localidad: "COLONIA URDANIZ",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6789,
    localidad: "COLONIA TREQUEN",
    cp: 6220,
    provincia_id: 19,
  },
  {
    id: 6790,
    localidad: "COLONIA TRENQUENDA",
    cp: 6220,
    provincia_id: 19,
  },
  {
    id: 6791,
    localidad: "COLONIA SOBADELL",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6792,
    localidad: "COLONIA ROCA",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6793,
    localidad: "COLONIA LUNA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6794,
    localidad: "COLONIA LAGOS",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6795,
    localidad: "COLONIA LA PIEDRA",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6796,
    localidad: "COLONIA LA FLORIDA",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6797,
    localidad: "COLONIA FERRARO",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6798,
    localidad: "COLONIA ECHETA",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6799,
    localidad: "COLONIA DENEVI",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6800,
    localidad: "COLONIA CALZADA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6801,
    localidad: "COLONIA BELLA VISTA",
    cp: 5735,
    provincia_id: 19,
  },
  {
    id: 6802,
    localidad: "COLONIA AGUIRRE",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6803,
    localidad: "CNIA V DE EMPLEADO",
    cp: 5857,
    provincia_id: 19,
  },
  {
    id: 6804,
    localidad: "CHUTUSA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6805,
    localidad: "CHOSMES",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6806,
    localidad: "CHISCHAQUITA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6807,
    localidad: "CHISCHACA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6808,
    localidad: "CHIPICAL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6809,
    localidad: "CHIMBORAZO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6810,
    localidad: "CHIMBAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6811,
    localidad: "CHILCAS",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6812,
    localidad: "CHARLONES",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6813,
    localidad: "CHAQAR DE LA LEGUA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6814,
    localidad: "CHANCARITA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6815,
    localidad: "CHALANTA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6816,
    localidad: "CHACU",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6817,
    localidad: "CHACRAS VIEJAS",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6818,
    localidad: "CHACRA LA CASILDA",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6819,
    localidad: "CHACARITAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6820,
    localidad: "CERROS LARGOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6821,
    localidad: "CERRO VIEJO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6822,
    localidad: "CERRO VERDE",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6823,
    localidad: "CERRO VARELA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6824,
    localidad: "CERRO NEGRO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6825,
    localidad: "CERRO NEGRO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6826,
    localidad: "CERRO DE PIEDRA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6827,
    localidad: "CERRO DE ORO",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 6828,
    localidad: "CERRO DE LA PILA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6829,
    localidad: "CERRO COLORADO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6830,
    localidad: "CERRO BLANCO",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6831,
    localidad: "CERRO BLANCO",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6832,
    localidad: "CERRO BAYO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6833,
    localidad: "CERRITO NEGRO",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6834,
    localidad: "CERRITO NEGRO",
    cp: 5770,
    provincia_id: 19,
  },
  {
    id: 6835,
    localidad: "CERRITO BLANCO",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6836,
    localidad: "CERRITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6837,
    localidad: "CEBOLLAR",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6838,
    localidad: "CASAS VIEJAS",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6839,
    localidad: "CASA DE PIEDRA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6840,
    localidad: "CASA DE PIEDRA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6841,
    localidad: "CASA DE CONDOR",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6842,
    localidad: "CARPINTERIA",
    cp: 5888,
    provincia_id: 19,
  },
  {
    id: 6843,
    localidad: "CARPINTERIA",
    cp: 5883,
    provincia_id: 19,
  },
  {
    id: 6844,
    localidad: "CAROLINA",
    cp: 5725,
    provincia_id: 19,
  },
  {
    id: 6845,
    localidad: "CARMELO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6846,
    localidad: "CAQITAS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6847,
    localidad: "CAQADITAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6848,
    localidad: "CAQADA VERDE",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6849,
    localidad: "CAQADA SAN ANTONIO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6850,
    localidad: "CAQADA QUEMADA",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6851,
    localidad: "CAQADA QUEMADA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6852,
    localidad: "CAQADA LA TIENDA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6853,
    localidad: "CAQADA LA NEGRA",
    cp: 5881,
    provincia_id: 19,
  },
  {
    id: 6854,
    localidad: "CAQADA HONDA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6855,
    localidad: "CAQADA GRANDE",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6856,
    localidad: "CAQADA DEL PASTO",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6857,
    localidad: "CAQADA DE LA NEGRA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6858,
    localidad: "CAQADA DE ATRAS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6859,
    localidad: "CAQADA BLANCA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6860,
    localidad: "CAQADA ANGOSTA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6861,
    localidad: "CAQADA",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6862,
    localidad: "CAQADA",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6863,
    localidad: "CAQA LARGA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6864,
    localidad: "CAPELEN",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6865,
    localidad: "CAÑADITAS",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6866,
    localidad: "CAÑADA HONDA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6867,
    localidad: "CANTERAS S ISABEL",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6868,
    localidad: "CANTANTAL",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6869,
    localidad: "CANDELARIA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6870,
    localidad: "CAMPO DE SAN PEDRO",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6871,
    localidad: "CAMPO DE LA TORRE",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6872,
    localidad: "CAMPANARIO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6873,
    localidad: "CALERAS C GRANDE",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6874,
    localidad: "CALERA ARGENTINA",
    cp: 5759,
    provincia_id: 19,
  },
  {
    id: 6875,
    localidad: "CALDENADAS",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6876,
    localidad: "CAIN DE LOS TIGRES",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6877,
    localidad: "CACHI CORRAL",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6878,
    localidad: "CABEZA DE NOVILLO",
    cp: 5779,
    provincia_id: 19,
  },
  {
    id: 6879,
    localidad: "C SANTA VIRGINIA",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6880,
    localidad: "C SAN VICTORIO",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6881,
    localidad: "C RAMON QUINTAS",
    cp: 6303,
    provincia_id: 19,
  },
  {
    id: 6882,
    localidad: "C PASO CARRIL",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6883,
    localidad: "C MONTES NEGROS",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6884,
    localidad: "C MARIA LUISA",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6885,
    localidad: "C LAS MERCEDES",
    cp: 6221,
    provincia_id: 19,
  },
  {
    id: 6886,
    localidad: "C JARDIN DE S LUIS",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 6887,
    localidad: "C HONDA DE GUZMAN",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6888,
    localidad: "C HILARIOS LAGOS",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 6889,
    localidad: "C EL CARMEN PARAJE",
    cp: 5801,
    provincia_id: 19,
  },
  {
    id: 6890,
    localidad: "C EL CAMPAMENTO",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6891,
    localidad: "C DEL PUESTITO",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6892,
    localidad: "C DE LOS TIGRES",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6893,
    localidad: "C DE LAS LAGS",
    cp: 5773,
    provincia_id: 19,
  },
  {
    id: 6894,
    localidad: "C BELLA VISTA",
    cp: 5735,
    provincia_id: 19,
  },
  {
    id: 6895,
    localidad: "BURMEISTER",
    cp: 6225,
    provincia_id: 19,
  },
  {
    id: 6896,
    localidad: "BUENA VISTA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6897,
    localidad: "BUENA VENTURA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6898,
    localidad: "BUENA ESPERANZA",
    cp: 6277,
    provincia_id: 19,
  },
  {
    id: 6899,
    localidad: "BOTIJAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6900,
    localidad: "BOLICHE LA ARAQA",
    cp: 6301,
    provincia_id: 19,
  },
  {
    id: 6901,
    localidad: "BOCA DEL RIO",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6902,
    localidad: "BOCA DEL RIO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 6903,
    localidad: "BOCA DE LA Q",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6904,
    localidad: "BILLIKEN",
    cp: 6216,
    provincia_id: 19,
  },
  {
    id: 6905,
    localidad: "BELLA VISTA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6906,
    localidad: "BELLA VISTA",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6907,
    localidad: "BELLA VISTA",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6908,
    localidad: "BELLA VISTA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6909,
    localidad: "BECERRA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6910,
    localidad: "BEBIDA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6911,
    localidad: "BEBEDERO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6912,
    localidad: "BEAZLEY",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6913,
    localidad: "BATAVIA",
    cp: 6279,
    provincia_id: 19,
  },
  {
    id: 6914,
    localidad: "BARZOLA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6915,
    localidad: "BARRIO BLANCO",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 6916,
    localidad: "BARRIALES",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6917,
    localidad: "BARRIAL",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6918,
    localidad: "BARRANQUITAS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6919,
    localidad: "BARRANCA COLORADA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6920,
    localidad: "BAQOS ZAPALLAR",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6921,
    localidad: "BAQADO LINDO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6922,
    localidad: "BAQADO DE CAUTANA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6923,
    localidad: "BAQADO",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6924,
    localidad: "BAQADITO VIEJO",
    cp: 5709,
    provincia_id: 19,
  },
  {
    id: 6925,
    localidad: "BAQADITO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6926,
    localidad: "BANDA SUD",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6927,
    localidad: "BANDA DE VARELA",
    cp: 5875,
    provincia_id: 19,
  },
  {
    id: 6928,
    localidad: "BALDECITO LA PAMPA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6929,
    localidad: "BALDECITO",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6930,
    localidad: "BALDE VIEJO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6931,
    localidad: "BALDE RETAMO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6932,
    localidad: "BALDE HONDO",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6933,
    localidad: "BALDE EL CARRIL",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6934,
    localidad: "BALDE DEL ROSARIO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6935,
    localidad: "BALDE DEL ESCUDERO",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6936,
    localidad: "BALDE DE TORRES",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6937,
    localidad: "BALDE DE QUINES",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6938,
    localidad: "BALDE DE PUERTAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6939,
    localidad: "BALDE DE NUEVO",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6940,
    localidad: "BALDE DE MONTE",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6941,
    localidad: "BALDE DE LEDESMA",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6942,
    localidad: "BALDE DE LA LINEA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6943,
    localidad: "BALDE DE LA ISLA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6944,
    localidad: "BALDE DE GUIQAZU",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6945,
    localidad: "BALDE DE GUARDIA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6946,
    localidad: "BALDE DE GARCIA",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6947,
    localidad: "BALDE DE ESCUDERO",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6948,
    localidad: "BALDE DE AZCURRA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6949,
    localidad: "BALDE DE ARRIBA",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 6950,
    localidad: "BALDE DE AMIRA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6951,
    localidad: "BALDE AHUMADA",
    cp: 5713,
    provincia_id: 19,
  },
  {
    id: 6952,
    localidad: "BALDE",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6953,
    localidad: "BALDA",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6954,
    localidad: "BAJOS HONDOS",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6955,
    localidad: "BAJO LA LAGUNA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6956,
    localidad: "BAJO GRANDE",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6957,
    localidad: "BAJO EL MOLINO",
    cp: 5887,
    provincia_id: 19,
  },
  {
    id: 6958,
    localidad: "BAJO DE LA CRUZ",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6959,
    localidad: "BAJO DE CONLARA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6960,
    localidad: "BAJADA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6961,
    localidad: "B COLORADAS",
    cp: 6300,
    provincia_id: 19,
  },
  {
    id: 6962,
    localidad: "AVIADOR ORIGONE",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6963,
    localidad: "AVANZADA",
    cp: 5738,
    provincia_id: 19,
  },
  {
    id: 6964,
    localidad: "AUTO GRANDE",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6965,
    localidad: "ARTURO VATTEONE",
    cp: 6433,
    provincia_id: 19,
  },
  {
    id: 6966,
    localidad: "ARROYO SAN ANTONIO",
    cp: 5859,
    provincia_id: 19,
  },
  {
    id: 6967,
    localidad: "ARROYO LA CAL",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6968,
    localidad: "ARROYO DE VILCHES",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6969,
    localidad: "ARIZONA",
    cp: 6389,
    provincia_id: 19,
  },
  {
    id: 6970,
    localidad: "ARENILLA",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6971,
    localidad: "ARBOLES BLANCOS",
    cp: 5771,
    provincia_id: 19,
  },
  {
    id: 6972,
    localidad: "ARBOLEDA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6973,
    localidad: "ARBOL VERDE",
    cp: 5715,
    provincia_id: 19,
  },
  {
    id: 6974,
    localidad: "ARBOL SOLO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6975,
    localidad: "ANTIHUASI",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6976,
    localidad: "ANGELITA",
    cp: 5711,
    provincia_id: 19,
  },
  {
    id: 6977,
    localidad: "ANCHORENA",
    cp: 6389,
    provincia_id: 19,
  },
  {
    id: 6978,
    localidad: "ANCAMILLA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 6979,
    localidad: "ALTO VERDE",
    cp: 5741,
    provincia_id: 19,
  },
  {
    id: 6980,
    localidad: "ALTO RESBALOSO",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6981,
    localidad: "ALTO PENCOSO",
    cp: 5724,
    provincia_id: 19,
  },
  {
    id: 6982,
    localidad: "ALTO PELADO",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6983,
    localidad: "ALTO GRANDE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6984,
    localidad: "ALTO GRANDE",
    cp: 5700,
    provincia_id: 19,
  },
  {
    id: 6985,
    localidad: "ALTO DEL VALLE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 6986,
    localidad: "ALTO DEL VALLE",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6987,
    localidad: "ALTO DEL MOLLE",
    cp: 5755,
    provincia_id: 19,
  },
  {
    id: 6988,
    localidad: "ALTO DEL LEON",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 6989,
    localidad: "ALTO DE LA LEQA",
    cp: 5750,
    provincia_id: 19,
  },
  {
    id: 6990,
    localidad: "ALTO",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6991,
    localidad: "ALTILLO",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6992,
    localidad: "ALTA GRACIA",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 6993,
    localidad: "ALGARRROBAL",
    cp: 5885,
    provincia_id: 19,
  },
  {
    id: 6994,
    localidad: "ALGARROBITOS",
    cp: 5701,
    provincia_id: 19,
  },
  {
    id: 6995,
    localidad: "ALGARROBAL",
    cp: 5775,
    provincia_id: 19,
  },
  {
    id: 6996,
    localidad: "ALGARROBAL",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 6997,
    localidad: "ALFALAND",
    cp: 5731,
    provincia_id: 19,
  },
  {
    id: 6998,
    localidad: "ALAZANAS",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 6999,
    localidad: "ALANICES",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 7000,
    localidad: "AHI VEREMOS",
    cp: 5703,
    provincia_id: 19,
  },
  {
    id: 7001,
    localidad: "AGUAS DE PIEDRAS",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 7002,
    localidad: "AGUAS BUENAS",
    cp: 6228,
    provincia_id: 19,
  },
  {
    id: 7003,
    localidad: "AGUADITAS",
    cp: 5707,
    provincia_id: 19,
  },
  {
    id: 7004,
    localidad: "AGUADA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 7005,
    localidad: "AGUA SEBALLE",
    cp: 5721,
    provincia_id: 19,
  },
  {
    id: 7006,
    localidad: "AGUA SALADA",
    cp: 5751,
    provincia_id: 19,
  },
  {
    id: 7007,
    localidad: "AGUA LINDA",
    cp: 5753,
    provincia_id: 19,
  },
  {
    id: 7008,
    localidad: "AGUA HEDIONDA",
    cp: 5705,
    provincia_id: 19,
  },
  {
    id: 7009,
    localidad: "AGUA FRIA",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 7010,
    localidad: "AGUA AMARGA",
    cp: 5719,
    provincia_id: 19,
  },
  {
    id: 7011,
    localidad: "ADOLFO RODRIG SAA",
    cp: 5777,
    provincia_id: 19,
  },
  {
    id: 7012,
    localidad: "ACHIRAS",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 7013,
    localidad: "ACASAPE",
    cp: 5722,
    provincia_id: 19,
  },
  {
    id: 7014,
    localidad: "A DEL PORTEZUELO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 7015,
    localidad: "9 DE JULIO",
    cp: 5757,
    provincia_id: 19,
  },
  {
    id: 7016,
    localidad: "9 DE JULIO",
    cp: 5831,
    provincia_id: 19,
  },
  {
    id: 7017,
    localidad: "3 ESQUINAS",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 7018,
    localidad: "20 DE FEBRERO",
    cp: 5730,
    provincia_id: 19,
  },
  {
    id: 7019,
    localidad: "ZONDA",
    cp: 5241,
    provincia_id: 18,
  },
  {
    id: 7020,
    localidad: "ZONDA",
    cp: 5451,
    provincia_id: 18,
  },
  {
    id: 7021,
    localidad: "ZONDA",
    cp: 5401,
    provincia_id: 18,
  },
  {
    id: 7022,
    localidad: "ZANJON AMARILLO",
    cp: 5553,
    provincia_id: 18,
  },
  {
    id: 7023,
    localidad: "YTO DE CBRE PACHON",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7024,
    localidad: "YOCA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7025,
    localidad: "YERBA BUENA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7026,
    localidad: "VOLCAN",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7027,
    localidad: "VILLA SANTA ROSA",
    cp: 5480,
    provincia_id: 18,
  },
  {
    id: 7028,
    localidad: "VILLA SAN ISIDRO",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7029,
    localidad: "VILLA PALERMO",
    cp: 5410,
    provincia_id: 18,
  },
  {
    id: 7030,
    localidad: "VILLA MERCEDES",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7031,
    localidad: "VILLA MEDIA AGUA",
    cp: 5401,
    provincia_id: 18,
  },
  {
    id: 7032,
    localidad: "VILLA LERGA",
    cp: 5424,
    provincia_id: 18,
  },
  {
    id: 7033,
    localidad: "VILLA KRAUSE",
    cp: 5425,
    provincia_id: 18,
  },
  {
    id: 7034,
    localidad: "VILLA GENERAL SAN MARTIN",
    cp: 5419,
    provincia_id: 18,
  },
  {
    id: 7035,
    localidad: "VILLA GENERAL ACHA",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7036,
    localidad: "VILLA DEL SALVADOR",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7037,
    localidad: "VILLA CAROLINA",
    cp: 5400,
    provincia_id: 18,
  },
  {
    id: 7038,
    localidad: "VILLA CARLOTA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7039,
    localidad: "VILLA ABERASTAIN",
    cp: 5427,
    provincia_id: 18,
  },
  {
    id: 7040,
    localidad: "VILLA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7041,
    localidad: "VENILLO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7042,
    localidad: "VEINTICINCO DE MAYO",
    cp: 5417,
    provincia_id: 18,
  },
  {
    id: 7043,
    localidad: "VALLECITO",
    cp: 5443,
    provincia_id: 18,
  },
  {
    id: 7044,
    localidad: "VALLE FERTIL",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7045,
    localidad: "VALLE DEL CURA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7046,
    localidad: "V G SARMIENTO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7047,
    localidad: "USNO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7048,
    localidad: "USINA",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7049,
    localidad: "ULLUN",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7050,
    localidad: "ULLUM",
    cp: 5414,
    provincia_id: 18,
  },
  {
    id: 7051,
    localidad: "TUTIANCA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7052,
    localidad: "TUPELI",
    cp: 5445,
    provincia_id: 18,
  },
  {
    id: 7053,
    localidad: "TUMINICO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7054,
    localidad: "TUMANAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7055,
    localidad: "TUCUNUCO",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7056,
    localidad: "TRINIDAD",
    cp: 5400,
    provincia_id: 18,
  },
  {
    id: 7057,
    localidad: "TRES QUEBRADITAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7058,
    localidad: "TRES ESQUINAS",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7059,
    localidad: "TRAVESIA DE MOGNA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7060,
    localidad: "TRANCAS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7061,
    localidad: "TOTORALITO",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7062,
    localidad: "TONTAL",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7063,
    localidad: "TOCOTA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7064,
    localidad: "TIRA LARGA",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7065,
    localidad: "TERMAS CENTENARIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7066,
    localidad: "TERMA PISMANTA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7067,
    localidad: "TAP GALLARDO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7068,
    localidad: "TAMBERIAS",
    cp: 5401,
    provincia_id: 18,
  },
  {
    id: 7069,
    localidad: "TAMBERIAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7070,
    localidad: "TAMBERIAS",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7071,
    localidad: "TAMBERIAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7072,
    localidad: "TALACASTO",
    cp: 5400,
    provincia_id: 18,
  },
  {
    id: 7073,
    localidad: "T. DE AGUA NEGRA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7074,
    localidad: "T. AGUA HEDIONDA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7075,
    localidad: "SIERRA DE RIVERO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7076,
    localidad: "SIERRA DE ELIZONDO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7077,
    localidad: "SIERRA DE CHAVES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7078,
    localidad: "SIERRA BILLICUM",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7079,
    localidad: "SEGUNDO CUARTEL",
    cp: 5425,
    provincia_id: 18,
  },
  {
    id: 7080,
    localidad: "SANTA LUCIA",
    cp: 5411,
    provincia_id: 18,
  },
  {
    id: 7081,
    localidad: "SANTA CRUZ",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7082,
    localidad: "SANTA CLARA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7083,
    localidad: "SANTA BARBARA",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7084,
    localidad: "SANJUANINO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7085,
    localidad: "SAN VICENTE",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7086,
    localidad: "SAN MARTIN",
    cp: 5418,
    provincia_id: 18,
  },
  {
    id: 7087,
    localidad: "SAN JUAN",
    cp: 5422,
    provincia_id: 18,
  },
  {
    id: 7088,
    localidad: "SAN JUAN",
    cp: 5452,
    provincia_id: 18,
  },
  {
    id: 7089,
    localidad: "SAN JUAN",
    cp: 5402,
    provincia_id: 18,
  },
  {
    id: 7090,
    localidad: "SAN JUAN",
    cp: 5406,
    provincia_id: 18,
  },
  {
    id: 7091,
    localidad: "SAN JUAN",
    cp: 5400,
    provincia_id: 18,
  },
  {
    id: 7092,
    localidad: "SAN ISIDRO",
    cp: 5416,
    provincia_id: 18,
  },
  {
    id: 7093,
    localidad: "SAN ISIDRO",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7094,
    localidad: "SAN CARLOS",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7095,
    localidad: "SAN ANTONIO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7096,
    localidad: "SAN ANTONIO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7097,
    localidad: "RUINAS INDIGENAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7098,
    localidad: "RODEO",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7099,
    localidad: "RIVADAVIA",
    cp: 5406,
    provincia_id: 18,
  },
  {
    id: 7100,
    localidad: "RIO PALO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7101,
    localidad: "RINCONES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7102,
    localidad: "RINCONADA",
    cp: 5429,
    provincia_id: 18,
  },
  {
    id: 7103,
    localidad: "RINCON GRANDE",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7104,
    localidad: "RINCON DEL GATO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7105,
    localidad: "RINCON DE LA BREA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7106,
    localidad: "RINCON COLORADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7107,
    localidad: "RINCON CHICO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7108,
    localidad: "RINCON",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7109,
    localidad: "RICHARD",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7110,
    localidad: "RETAMITO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7111,
    localidad: "REPRESA DEL MONTE",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7112,
    localidad: "REFUGIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7113,
    localidad: "REFUGIO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7114,
    localidad: "RAWSON",
    cp: 5420,
    provincia_id: 18,
  },
  {
    id: 7115,
    localidad: "RAWSON",
    cp: 5423,
    provincia_id: 18,
  },
  {
    id: 7116,
    localidad: "RANCHOS DE FAMACOA",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7117,
    localidad: "RAMBLON",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7118,
    localidad: "R.LS CHINCHILLEROS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7119,
    localidad: "R. DE LA OLLITA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7120,
    localidad: "QUILINQUIL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7121,
    localidad: "PUNTA NORTE",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7122,
    localidad: "PUNTA DEL MONTE",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7123,
    localidad: "PUNTA DEL MONTE",
    cp: 5454,
    provincia_id: 18,
  },
  {
    id: 7124,
    localidad: "PUNTA DEL MEDANO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7125,
    localidad: "PUNTA DEL CERRO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7126,
    localidad: "PUNTA DE AGUA",
    cp: 5463,
    provincia_id: 18,
  },
  {
    id: 7127,
    localidad: "PUNTA BLANCA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7128,
    localidad: "PUESTO SANTA ROSA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7129,
    localidad: "PUESTO OLGUIN",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7130,
    localidad: "PUESTO ISLA CHAQAR",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7131,
    localidad: "PUESTO DE ARRIBA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7132,
    localidad: "PUESTO ANGOSTURA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7133,
    localidad: "PUENTE RUFINO",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7134,
    localidad: "PUENTE RIO SAN JUAN",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7135,
    localidad: "PUENTE NACIONAL",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7136,
    localidad: "PUEBLO LUNA",
    cp: 5632,
    provincia_id: 18,
  },
  {
    id: 7137,
    localidad: "PTO VEGA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7138,
    localidad: "PTO VAREJON",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7139,
    localidad: "PTO VALLECITO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7140,
    localidad: "PTO TRAPICHE",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7141,
    localidad: "PTO SEGOVIA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7142,
    localidad: "PTO SAN ISIDRO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7143,
    localidad: "PTO SABATO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7144,
    localidad: "PTO ROMERO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7145,
    localidad: "PTO RECREO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7146,
    localidad: "PTO PUNILLA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7147,
    localidad: "PTO POTRERILLO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7148,
    localidad: "PTO PIMPA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7149,
    localidad: "PTO PESCADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7150,
    localidad: "PTO PERICO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7151,
    localidad: "PTO PANTANITO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7152,
    localidad: "PTO PAJARITO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7153,
    localidad: "PTO MAJADITA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7154,
    localidad: "PTO LOS POZOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7155,
    localidad: "PTO LOS ALAMOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7156,
    localidad: "PTO LIMA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7157,
    localidad: "PTO LA VIRGENCITA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7158,
    localidad: "PTO LA TUNA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7159,
    localidad: "PTO LA REPRESA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7160,
    localidad: "PTO LA ESPINA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7161,
    localidad: "PTO LA CORTADERA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7162,
    localidad: "PTO LA CHILCA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7163,
    localidad: "PTO HUASI",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7164,
    localidad: "PTO GORDILLO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7165,
    localidad: "PTO GEN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7166,
    localidad: "PTO FIGUEROA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7167,
    localidad: "PTO EL TORO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7168,
    localidad: "PTO EL SARCO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7169,
    localidad: "PTO DURAZNO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7170,
    localidad: "PTO CUMILLANGO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7171,
    localidad: "PTO CHAVEZ",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7172,
    localidad: "PTO CHANQUIA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7173,
    localidad: "PTO ANJULIO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7174,
    localidad: "PTO AGUADITA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7175,
    localidad: "PTO AG DEL BURRO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7176,
    localidad: "PTO A DE ABAJO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7177,
    localidad: "PTA DL INFIERNILLO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7178,
    localidad: "PTA DE LA CHILCA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7179,
    localidad: "PRIMER CUARTEL",
    cp: 5425,
    provincia_id: 18,
  },
  {
    id: 7180,
    localidad: "POZO DE AGUADITA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7181,
    localidad: "POTRERO DE LOS FUNES",
    cp: 5403,
    provincia_id: 18,
  },
  {
    id: 7182,
    localidad: "POTRERILLOS",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7183,
    localidad: "POTRANCA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7184,
    localidad: "PORTON GRANDE",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7185,
    localidad: "PORT SANTA ROSA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7186,
    localidad: "PORT SAN GUILLERMO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7187,
    localidad: "PORT LAS FRANCAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7188,
    localidad: "PORT LAS CHILCAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7189,
    localidad: "PORT DE LONGOMICHE",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7190,
    localidad: "PORT DE LA PUNILLA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7191,
    localidad: "POCITOS",
    cp: 5427,
    provincia_id: 18,
  },
  {
    id: 7192,
    localidad: "POCITO",
    cp: 5428,
    provincia_id: 18,
  },
  {
    id: 7193,
    localidad: "POCITO",
    cp: 5429,
    provincia_id: 18,
  },
  {
    id: 7194,
    localidad: "PO LAS TORTOLAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7195,
    localidad: "PO DEL PORTILLO",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7196,
    localidad: "PO DEL INCA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7197,
    localidad: "PO DEL CHOLLAY",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7198,
    localidad: "PO DE USNO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7199,
    localidad: "PO DE LAS LLARETAS",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7200,
    localidad: "PO DE LA GUARDIA",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7201,
    localidad: "PO CJON D LA BREA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7202,
    localidad: "PLUMERILLO",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7203,
    localidad: "PISMANIA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7204,
    localidad: "PIRCAS NEGRAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7205,
    localidad: "PIRCAS BLANCAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7206,
    localidad: "PIMPA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7207,
    localidad: "PILA DE MACHO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7208,
    localidad: "PIEDRAS BLANCAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7209,
    localidad: "PIEDRA RAJADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7210,
    localidad: "PIEDRA PARADA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7211,
    localidad: "PIEDRA COLORADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7212,
    localidad: "PIEDRA BLANCA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7213,
    localidad: "PIE DE PALO",
    cp: 5444,
    provincia_id: 18,
  },
  {
    id: 7214,
    localidad: "PICHAGUAL",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7215,
    localidad: "PEQASQUITO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7216,
    localidad: "PEQASCO COLORADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7217,
    localidad: "PEDERNAL",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7218,
    localidad: "PASO DEL LAMAR",
    cp: 5463,
    provincia_id: 18,
  },
  {
    id: 7219,
    localidad: "PASO DE OTAROLA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7220,
    localidad: "PASO DE LAMAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7221,
    localidad: "PASO DE FERREIRA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7222,
    localidad: "PASLEAM",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7223,
    localidad: "PAQUITA",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7224,
    localidad: "PAPAGAYOS",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7225,
    localidad: "PANACAN",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7226,
    localidad: "PAMPA VIEJA",
    cp: 5469,
    provincia_id: 18,
  },
  {
    id: 7227,
    localidad: "PAMPA GRANDE",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7228,
    localidad: "PAMPA DEL CHAQAR",
    cp: 5468,
    provincia_id: 18,
  },
  {
    id: 7229,
    localidad: "PAMPA DEL CHAÑAR",
    cp: 5468,
    provincia_id: 18,
  },
  {
    id: 7230,
    localidad: "PAMP D LS CABALLOS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7231,
    localidad: "P PORTEZUELO HONDO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7232,
    localidad: "P LOS CHAQARES",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7233,
    localidad: "P LOS AMADORES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7234,
    localidad: "P LAS CARACACHAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7235,
    localidad: "P L CHILCA DE ABJO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7236,
    localidad: "P EL ARBOL LIGUDO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7237,
    localidad: "P DEL AGUA NEGRA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7238,
    localidad: "OTRA BANDA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7239,
    localidad: "OJOS DE AGUA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7240,
    localidad: "OJOS DE AGUA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7241,
    localidad: "NUEVE DE JULIO",
    cp: 5417,
    provincia_id: 18,
  },
  {
    id: 7242,
    localidad: "NIQUIVIL VIEJO",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7243,
    localidad: "NIQUIVIL",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7244,
    localidad: "NAQUERA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7245,
    localidad: "MORTERITO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7246,
    localidad: "MONDACA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7247,
    localidad: "MOGNA",
    cp: 5412,
    provincia_id: 18,
  },
  {
    id: 7248,
    localidad: "MNA D LAS CARACHAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7249,
    localidad: "MINA SAN ANTONIO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7250,
    localidad: "MINA MONTOSA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7251,
    localidad: "MINA LOS CABALLOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7252,
    localidad: "MINA LA SALAMANTA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7253,
    localidad: "MINA LA ESPERANZA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7254,
    localidad: "MINA LA DELFINA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7255,
    localidad: "MINA LA ABUNDANCIA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7256,
    localidad: "MINA G BELGRANO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7257,
    localidad: "MINA ESCONDIDA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7258,
    localidad: "MINA EL PESCADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7259,
    localidad: "MINA EL ALGARROBO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7260,
    localidad: "MINA DE GUACHI",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7261,
    localidad: "MILAGRO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7262,
    localidad: "MICA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7263,
    localidad: "MESADA AGUADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7264,
    localidad: "MEDIA AGUA",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7265,
    localidad: "MEDANO DE ORO",
    cp: 5322,
    provincia_id: 18,
  },
  {
    id: 7266,
    localidad: "MEDANO COLORADO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7267,
    localidad: "MATAGUSANOS",
    cp: 5419,
    provincia_id: 18,
  },
  {
    id: 7268,
    localidad: "MARQUESADO",
    cp: 5407,
    provincia_id: 18,
  },
  {
    id: 7269,
    localidad: "MARAYES",
    cp: 5446,
    provincia_id: 18,
  },
  {
    id: 7270,
    localidad: "MANANTIALES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7271,
    localidad: "MALIMAN DE ABAJO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7272,
    localidad: "MALIMAN ARRIBA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7273,
    localidad: "MALIMAN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7274,
    localidad: "MAJADITA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7275,
    localidad: "MAIPIRINQUI",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7276,
    localidad: "MACLACASTO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7277,
    localidad: "LS BALDES D ASTICA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7278,
    localidad: "LOTE ALVARADO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7279,
    localidad: "LOS VIÑEDOS",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7280,
    localidad: "LOS TERREMOTOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7281,
    localidad: "LOS SOMBREROS",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7282,
    localidad: "LOS SAPITOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7283,
    localidad: "LOS SANCHEZ",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7284,
    localidad: "LOS RINCONES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7285,
    localidad: "LOS RANCHOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7286,
    localidad: "LOS QUIMBALETES",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7287,
    localidad: "LOS QUILLAY",
    cp: 5417,
    provincia_id: 18,
  },
  {
    id: 7288,
    localidad: "LOS PUESTOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7289,
    localidad: "LOS PORONGOS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7290,
    localidad: "LOS PENITENTES",
    cp: 5553,
    provincia_id: 18,
  },
  {
    id: 7291,
    localidad: "LOS PENITENTES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7292,
    localidad: "LOS OROS",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7293,
    localidad: "LOS OLMOS",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7294,
    localidad: "LOS NOGALES",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7295,
    localidad: "LOS MOLLES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7296,
    localidad: "LOS LOROS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7297,
    localidad: "LOS LAGARES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7298,
    localidad: "LOS HORNOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7299,
    localidad: "LOS DIAGUITAS",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7300,
    localidad: "LOS COMPARTOS",
    cp: 5424,
    provincia_id: 18,
  },
  {
    id: 7301,
    localidad: "LOS COGOTES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7302,
    localidad: "LOS CHAVES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7303,
    localidad: "LOS CHAQARES",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7304,
    localidad: "LOS BERROS",
    cp: 5433,
    provincia_id: 18,
  },
  {
    id: 7305,
    localidad: "LOS BARRIALES",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7306,
    localidad: "LOS BALDES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7307,
    localidad: "LOS BALDECITOS",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7308,
    localidad: "LOMAS DL AGUADITS",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7309,
    localidad: "LOMAS BLANCAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7310,
    localidad: "LOMAS BLANCAS",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7311,
    localidad: "LOMA NEGRA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7312,
    localidad: "LOMA NEGRA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7313,
    localidad: "LOMA LEONES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7314,
    localidad: "LOMA DE COCHO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7315,
    localidad: "LOMA ANCHA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7316,
    localidad: "LAS YEGUAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7317,
    localidad: "LAS TUSCAS",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7318,
    localidad: "LAS TUMANAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7319,
    localidad: "LAS TAPIAS",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7320,
    localidad: "LAS TAPIAS",
    cp: 5440,
    provincia_id: 18,
  },
  {
    id: 7321,
    localidad: "LAS RAMADITAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7322,
    localidad: "LAS PUESTAS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7323,
    localidad: "LAS PEQITAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7324,
    localidad: "LAS LOMITAS",
    cp: 5419,
    provincia_id: 18,
  },
  {
    id: 7325,
    localidad: "LAS LEÑAS",
    cp: 5612,
    provincia_id: 18,
  },
  {
    id: 7326,
    localidad: "LAS JUNTAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7327,
    localidad: "LAS HORNILLAS",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7328,
    localidad: "LAS HIGUERITAS",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7329,
    localidad: "LAS HIGUERAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7330,
    localidad: "LAS HERMANAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7331,
    localidad: "LAS FLORES",
    cp: 5458,
    provincia_id: 18,
  },
  {
    id: 7332,
    localidad: "LAS FLORES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7333,
    localidad: "LAS ESPINAS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7334,
    localidad: "LAS DELICIAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7335,
    localidad: "LAS CUEVAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7336,
    localidad: "LAS CIENAGAS VDES",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7337,
    localidad: "LAS CHACRITAS",
    cp: 5417,
    provincia_id: 18,
  },
  {
    id: 7338,
    localidad: "LAS CHACRAS",
    cp: 5446,
    provincia_id: 18,
  },
  {
    id: 7339,
    localidad: "LAS CASUARINAS",
    cp: 5443,
    provincia_id: 18,
  },
  {
    id: 7340,
    localidad: "LAS CASITAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7341,
    localidad: "LAS C NEGRAS",
    cp: 5632,
    provincia_id: 18,
  },
  {
    id: 7342,
    localidad: "LAS AGUADITAS",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7343,
    localidad: "LAGUNA DEL ROSARIO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7344,
    localidad: "LA VERTIENTE",
    cp: 5432,
    provincia_id: 18,
  },
  {
    id: 7345,
    localidad: "LA VALENTINA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7346,
    localidad: "LA TRANCA",
    cp: 5421,
    provincia_id: 18,
  },
  {
    id: 7347,
    localidad: "LA TOMA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7348,
    localidad: "LA SAL",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7349,
    localidad: "LA ROSITA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7350,
    localidad: "LA RIPIERA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7351,
    localidad: "LA RAMADA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7352,
    localidad: "LA PUNTILLA",
    cp: 5439,
    provincia_id: 18,
  },
  {
    id: 7353,
    localidad: "LA PRIMAVERA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7354,
    localidad: "LA PINTADA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7355,
    localidad: "LA PENCA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7356,
    localidad: "LA OVERA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7357,
    localidad: "LA ORQUETA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7358,
    localidad: "LA MORAL",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7359,
    localidad: "LA MESADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7360,
    localidad: "LA MARZOLINA",
    cp: 5632,
    provincia_id: 18,
  },
  {
    id: 7361,
    localidad: "LA MARAL",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7362,
    localidad: "LA MAJADITA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7363,
    localidad: "LA HULLERA",
    cp: 5549,
    provincia_id: 18,
  },
  {
    id: 7364,
    localidad: "LA HUERTA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7365,
    localidad: "LA FALDA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7366,
    localidad: "LA ESTRECHURA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7367,
    localidad: "LA ESTACA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7368,
    localidad: "LA ESQUINA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7369,
    localidad: "LA ESQUINA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7370,
    localidad: "LA ESCANDINAVA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7371,
    localidad: "LA CRUZ",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7372,
    localidad: "LA CONSULTA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7373,
    localidad: "LA COLONIA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7374,
    localidad: "LA CIENEGUITA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7375,
    localidad: "LA CIENEGUITA",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7376,
    localidad: "LA CIENAGUITA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7377,
    localidad: "LA CIENAGA",
    cp: 5463,
    provincia_id: 18,
  },
  {
    id: 7378,
    localidad: "LA CHIMBERA",
    cp: 5466,
    provincia_id: 18,
  },
  {
    id: 7379,
    localidad: "LA CHILCA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7380,
    localidad: "LA CHILCA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7381,
    localidad: "LA CHIGUA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7382,
    localidad: "LA CERCADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7383,
    localidad: "LA CARRIZANA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7384,
    localidad: "LA CARPA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7385,
    localidad: "LA CAQADA",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7386,
    localidad: "LA CAÑADA",
    cp: 5430,
    provincia_id: 18,
  },
  {
    id: 7387,
    localidad: "LA CAÑADA",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7388,
    localidad: "LA C DE CUMILLANGO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7389,
    localidad: "LA ANGOSTURA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7390,
    localidad: "LA AGUADA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7391,
    localidad: "KILOMETRO 10 650",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7392,
    localidad: "JUNTAS DEL FRIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7393,
    localidad: "JUNTAS DE LA SAL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7394,
    localidad: "JUNTA DE S ROSA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7395,
    localidad: "JTAS DEL GUANDACOL",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7396,
    localidad: "JTAS DE LA JARILLA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7397,
    localidad: "JARILLITO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7398,
    localidad: "JARILLA CHICA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7399,
    localidad: "JAGUELITO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7400,
    localidad: "JACHAL",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7401,
    localidad: "ISCHIGUALASTO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7402,
    localidad: "ING MATIAS G SCHEZ",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7403,
    localidad: "INDIO MUERTO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7404,
    localidad: "ILLISCA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7405,
    localidad: "IGLESIA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7406,
    localidad: "IGLESIA",
    cp: 5610,
    provincia_id: 18,
  },
  {
    id: 7407,
    localidad: "ICHIGUALASTO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7408,
    localidad: "HUESO QUEBRADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7409,
    localidad: "HUERTA DE GUACHI",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7410,
    localidad: "HUAQIZUIL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7411,
    localidad: "HUACO",
    cp: 5463,
    provincia_id: 18,
  },
  {
    id: 7412,
    localidad: "HUACO",
    cp: 5459,
    provincia_id: 18,
  },
  {
    id: 7413,
    localidad: "HOSTERIA EL BALDE",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7414,
    localidad: "HACHANGO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7415,
    localidad: "GUAQIZUL",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7416,
    localidad: "GUANACACHE",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7417,
    localidad: "GUAJA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7418,
    localidad: "GUACHIPAMPA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7419,
    localidad: "GRAN CHINA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7420,
    localidad: "FINCA EL TORO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7421,
    localidad: "FINCA EL MOLINO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7422,
    localidad: "FINCA DEL JAPONES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7423,
    localidad: "FILO DEL MOCHO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7424,
    localidad: "FIERRO VIEJO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7425,
    localidad: "FIERRO NUEVO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7426,
    localidad: "FIERRO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7427,
    localidad: "ESTANCIA SAN ROQUE",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7428,
    localidad: "ESTANCIA RIO VERDE",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7429,
    localidad: "ESTANCIA QUIROGA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7430,
    localidad: "ESTANCIA LEONCITO",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7431,
    localidad: "ESTANCIA LA POSTA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7432,
    localidad: "ESTANCIA LA LATA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7433,
    localidad: "ESTANCIA ELIZONDO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7434,
    localidad: "ESTANCIA EL POLEAR",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7435,
    localidad: "ESTANCIA EL MOLINO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7436,
    localidad: "ESTANCIA EL JUMEAL",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7437,
    localidad: "ESTANCIA ACEQUION",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7438,
    localidad: "ESTACION LA RINCONADA",
    cp: 5433,
    provincia_id: 18,
  },
  {
    id: 7439,
    localidad: "EST NIQUIVIL",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7440,
    localidad: "EST MARAYES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7441,
    localidad: "EST D HERRERA VGAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7442,
    localidad: "EST ALBARDON",
    cp: 5419,
    provincia_id: 18,
  },
  {
    id: 7443,
    localidad: "ENTRE RIOS",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7444,
    localidad: "ENCON",
    cp: 5421,
    provincia_id: 18,
  },
  {
    id: 7445,
    localidad: "EMPALME FRONTERA",
    cp: 5553,
    provincia_id: 18,
  },
  {
    id: 7446,
    localidad: "EL VOLCAN",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7447,
    localidad: "EL TREINTA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7448,
    localidad: "EL TAPON",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7449,
    localidad: "EL SALTO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7450,
    localidad: "EL SALITRE",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7451,
    localidad: "EL SALADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7452,
    localidad: "EL RINCON",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7453,
    localidad: "EL RETIRO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7454,
    localidad: "EL QUEMADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7455,
    localidad: "EL PUERTO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7456,
    localidad: "EL PLUMERITO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7457,
    localidad: "EL MEDANO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7458,
    localidad: "EL LECHUZO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7459,
    localidad: "EL JABONCITO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7460,
    localidad: "EL INFIERNO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7461,
    localidad: "EL GIGANTILLO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7462,
    localidad: "EL FUERTE",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7463,
    localidad: "EL FICAL",
    cp: 5464,
    provincia_id: 18,
  },
  {
    id: 7464,
    localidad: "EL FICAL",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7465,
    localidad: "EL DIVISADERO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7466,
    localidad: "EL CORRALITO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7467,
    localidad: "EL CINCUENTA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7468,
    localidad: "EL CHINGUILLO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7469,
    localidad: "EL CHAQAR",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7470,
    localidad: "EL CHAQAR",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7471,
    localidad: "EL CHAMIZUDO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7472,
    localidad: "EL CHACRERO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7473,
    localidad: "EL CEIBO",
    cp: 5428,
    provincia_id: 18,
  },
  {
    id: 7474,
    localidad: "EL CARRIZAL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7475,
    localidad: "EL BUEN RETIRO",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7476,
    localidad: "EL BOSQUE",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7477,
    localidad: "EL BOSQUE",
    cp: 5455,
    provincia_id: 18,
  },
  {
    id: 7478,
    localidad: "EL BARRIALITO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7479,
    localidad: "EL BARREAL",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7480,
    localidad: "EL BALDE",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7481,
    localidad: "EL ALTO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7482,
    localidad: "EL ALGARROBAL",
    cp: 5541,
    provincia_id: 18,
  },
  {
    id: 7483,
    localidad: "EL ALAMITO",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7484,
    localidad: "EL ABANICO",
    cp: 5429,
    provincia_id: 18,
  },
  {
    id: 7485,
    localidad: "E LA FLORIDA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7486,
    localidad: "E LA ESCALERA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7487,
    localidad: "E EL TOTORAL",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7488,
    localidad: "E EL DURAZNO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7489,
    localidad: "E EL CHAQAR NUEVO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7490,
    localidad: "E CASA RIO BLANCO",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7491,
    localidad: "E BJO D LS TUMANAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7492,
    localidad: "DOS MOJONES",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7493,
    localidad: "DOS ACEQUIAS",
    cp: 5439,
    provincia_id: 18,
  },
  {
    id: 7494,
    localidad: "DOMINGO DE ORO",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7495,
    localidad: "DIQUE CAUQUENES",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7496,
    localidad: "DIFUNTA CORREA",
    cp: 5443,
    provincia_id: 18,
  },
  {
    id: 7497,
    localidad: "DESAMPARADOS",
    cp: 5400,
    provincia_id: 18,
  },
  {
    id: 7498,
    localidad: "D LAS CRUCECITAS",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7499,
    localidad: "D D LA MJER HELADA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7500,
    localidad: "CUMIYANGO",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7501,
    localidad: "CULEBRA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7502,
    localidad: "CUESTA VIEJO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7503,
    localidad: "CUCHILLAZO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7504,
    localidad: "CRUZ DE PIEDRA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7505,
    localidad: "COYON",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7506,
    localidad: "CORRAL DE PIRCA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7507,
    localidad: "CONDOR MUERTO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7508,
    localidad: "CONCOTA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7509,
    localidad: "CONCEPCION",
    cp: 5401,
    provincia_id: 18,
  },
  {
    id: 7510,
    localidad: "COLONIA ZAPATA",
    cp: 5436,
    provincia_id: 18,
  },
  {
    id: 7511,
    localidad: "COLONIA RODAS",
    cp: 5421,
    provincia_id: 18,
  },
  {
    id: 7512,
    localidad: "COLONIA RICHET",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7513,
    localidad: "COLONIA GUTIERREZ",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7514,
    localidad: "COLONIA FISCAL SARMIENTO",
    cp: 5450,
    provincia_id: 18,
  },
  {
    id: 7515,
    localidad: "COLONIA FIORITO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7516,
    localidad: "COLOLA",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7517,
    localidad: "COLL",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7518,
    localidad: "COLANQUI",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7519,
    localidad: "COLANGUIL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7520,
    localidad: "COCHAGUAL",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7521,
    localidad: "CJON D LS TMBILLOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7522,
    localidad: "CIENAGUITA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7523,
    localidad: "CIENAGUILLOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7524,
    localidad: "CIENAGA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7525,
    localidad: "CHUCUMA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7526,
    localidad: "CHISNASCO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7527,
    localidad: "CHIMBAS",
    cp: 5413,
    provincia_id: 18,
  },
  {
    id: 7528,
    localidad: "CHIGUA DE ABAJO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7529,
    localidad: "CHICA NEGRA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7530,
    localidad: "CHEPICAL",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7531,
    localidad: "CHEPES VIEJOS",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7532,
    localidad: "CHAQAR PINTADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7533,
    localidad: "CHAPARRO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7534,
    localidad: "CHANCHOS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7535,
    localidad: "CHAMPONES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7536,
    localidad: "CHACRITAS",
    cp: 5456,
    provincia_id: 18,
  },
  {
    id: 7537,
    localidad: "CERRO TRES PUNTAS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7538,
    localidad: "CERRO SILVO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7539,
    localidad: "CERRO SILVIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7540,
    localidad: "CERRO SENDA AZUL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7541,
    localidad: "CERRO PUNTUDO",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7542,
    localidad: "CERRO POTRERO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7543,
    localidad: "CERRO PINTADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7544,
    localidad: "CERRO OCUCAROS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7545,
    localidad: "CERRO NICO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7546,
    localidad: "CERRO NEGRO",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7547,
    localidad: "CERRO NEGRO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7548,
    localidad: "CERRO MUDADERO",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7549,
    localidad: "CERRO LOS POZOS",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7550,
    localidad: "CERRO LOS POZOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7551,
    localidad: "CERRO LOS PATOS",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7552,
    localidad: "CERRO LOS MOGOTES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7553,
    localidad: "CERRO LAVADEROS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7554,
    localidad: "CERRO LAS YEGUAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7555,
    localidad: "CERRO LAS RAICES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7556,
    localidad: "CERRO LAS MULITAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7557,
    localidad: "CERRO LAS MULAS",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7558,
    localidad: "CERRO LAJITAS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7559,
    localidad: "CERRO LAGUNITA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7560,
    localidad: "CERRO LA ORTIGA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7561,
    localidad: "CERRO LA COLORADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7562,
    localidad: "CERRO LA BOLSA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7563,
    localidad: "CERRO JOAQUIN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7564,
    localidad: "CERRO IMAN",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7565,
    localidad: "CERRO IMAN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7566,
    localidad: "CERRO HEDIONDO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7567,
    localidad: "CERRO HEDIONDO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7568,
    localidad: "CERRO ESPANTAJO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7569,
    localidad: "CERRO EL FRANCES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7570,
    localidad: "CERRO EL DURAZNO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7571,
    localidad: "CERRO EL CEPO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7572,
    localidad: "CERRO EL BRONCE",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7573,
    localidad: "CERRO DEL SALADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7574,
    localidad: "CERRO DEL MEDIO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7575,
    localidad: "CERRO DEL GUANACO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7576,
    localidad: "CERRO DEL ALUMBRE",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7577,
    localidad: "CERRO DE DOLORES",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7578,
    localidad: "CERRO DE CONCONTA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7579,
    localidad: "CERRO CORTADERA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7580,
    localidad: "CERRO COLORADO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7581,
    localidad: "CERRO COLORADO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7582,
    localidad: "CERRO CABALLO BAYO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7583,
    localidad: "CERRO CABALLO ANCA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7584,
    localidad: "CERRO BRAVO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7585,
    localidad: "CERRO BOLEADORA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7586,
    localidad: "CERRO ASPERO",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7587,
    localidad: "CERRO ASILAN",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7588,
    localidad: "CERRO AMARILLO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7589,
    localidad: "CAUCETE",
    cp: 5441,
    provincia_id: 18,
  },
  {
    id: 7590,
    localidad: "CAUCETE",
    cp: 5442,
    provincia_id: 18,
  },
  {
    id: 7591,
    localidad: "CASA VIEJA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7592,
    localidad: "CASA DE JAVIER",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7593,
    localidad: "CARRIZALITO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7594,
    localidad: "CARPINTERIA",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7595,
    localidad: "CARPINTERIA",
    cp: 5437,
    provincia_id: 18,
  },
  {
    id: 7596,
    localidad: "CAROLINA",
    cp: 5462,
    provincia_id: 18,
  },
  {
    id: 7597,
    localidad: "CARBOMETAL",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7598,
    localidad: "CAQADA HONDA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7599,
    localidad: "CAQADA DEL POZO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7600,
    localidad: "CAQADA DE LAGUNA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7601,
    localidad: "CAQADA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7602,
    localidad: "CAPITAN LAZO",
    cp: 5423,
    provincia_id: 18,
  },
  {
    id: 7603,
    localidad: "CAÑADA HONDA",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7604,
    localidad: "CAMPO LOS POZOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7605,
    localidad: "CAMPO LAS LIEBRES",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7606,
    localidad: "CAMPO DE BATALLA",
    cp: 5434,
    provincia_id: 18,
  },
  {
    id: 7607,
    localidad: "CAMPO AFUERA",
    cp: 5426,
    provincia_id: 18,
  },
  {
    id: 7608,
    localidad: "CAMPANARIO NUEVO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7609,
    localidad: "CALLECITA",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7610,
    localidad: "CALLE NACIONAL",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7611,
    localidad: "CALLE AGUILERAS",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7612,
    localidad: "CALINGASTA",
    cp: 5403,
    provincia_id: 18,
  },
  {
    id: 7613,
    localidad: "CACHO ANCHO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7614,
    localidad: "CABEZA DEL TORO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7615,
    localidad: "C SAN ANTONIO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7616,
    localidad: "C RIQUILIPONCHE",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7617,
    localidad: "C PRITO D AGUA BCO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7618,
    localidad: "C PATA DE INDIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7619,
    localidad: "C NEGRO DEL CORRAL",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7620,
    localidad: "C NEGRO DE CHITA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7621,
    localidad: "C LA C AMARILLA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7622,
    localidad: "C DL AGUA D L VCAS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7623,
    localidad: "C DEL COQUIMBITO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7624,
    localidad: "C DEL CACHIYUYAL",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7625,
    localidad: "C DE LOS CABALLOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7626,
    localidad: "C DE LOS BURROS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7627,
    localidad: "C DE LOS BURROS",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7628,
    localidad: "C DE LA SEPULTURA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7629,
    localidad: "C D CUEST D VIENTO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7630,
    localidad: "C ALTO DL DMIENTO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7631,
    localidad: "C  D  L  BAQITOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7632,
    localidad: "BUENA ESPERANZA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7633,
    localidad: "BODEGA SAN ANTONIO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7634,
    localidad: "BOCA DE LA Q",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7635,
    localidad: "BERMEJO",
    cp: 5444,
    provincia_id: 18,
  },
  {
    id: 7636,
    localidad: "BELLA VISTA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7637,
    localidad: "BELLA VISTA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7638,
    localidad: "BELGRANO",
    cp: 5439,
    provincia_id: 18,
  },
  {
    id: 7639,
    localidad: "BAYO MUERTO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7640,
    localidad: "BARRIO RAWSON",
    cp: 5425,
    provincia_id: 18,
  },
  {
    id: 7641,
    localidad: "BARREALITO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7642,
    localidad: "BARREAL",
    cp: 5405,
    provincia_id: 18,
  },
  {
    id: 7643,
    localidad: "BARRANCAS BLANCAS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7644,
    localidad: "BARRANCA COLORADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7645,
    localidad: "BAQOS PISMANTA",
    cp: 5465,
    provincia_id: 18,
  },
  {
    id: 7646,
    localidad: "BAQOS DEL CERRO",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7647,
    localidad: "BAQOS DE S CRISPIN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7648,
    localidad: "BAQOS DE LOS DPBL2",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7649,
    localidad: "BAQOS CENTENARIO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7650,
    localidad: "BALDES DEL TARABAY",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7651,
    localidad: "BALDES DEL SUD",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7652,
    localidad: "BALDES D L CHILCA",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7653,
    localidad: "BALDECITO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7654,
    localidad: "BALDE SAN CARLOS",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7655,
    localidad: "BALDE PLUMERITO",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7656,
    localidad: "BALDE DEL ROSARIO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7657,
    localidad: "BALDE DEL NORTE",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7658,
    localidad: "BALDCITO DL MORADO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7659,
    localidad: "B DE LOS LOROS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7660,
    localidad: "AZUCARERA DE CUYO",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7661,
    localidad: "ASTICA",
    cp: 5496,
    provincia_id: 18,
  },
  {
    id: 7662,
    localidad: "ASTICA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7663,
    localidad: "ASCHICHUSCA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7664,
    localidad: "ARREQUINTIN",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7665,
    localidad: "ANGUALASTO",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7666,
    localidad: "ANGACO SUD",
    cp: 5417,
    provincia_id: 18,
  },
  {
    id: 7667,
    localidad: "ANGACO NORTE",
    cp: 5415,
    provincia_id: 18,
  },
  {
    id: 7668,
    localidad: "ALTO HUACO",
    cp: 5463,
    provincia_id: 18,
  },
  {
    id: 7669,
    localidad: "ALTO DE SIERRA",
    cp: 5438,
    provincia_id: 18,
  },
  {
    id: 7670,
    localidad: "ALTO BAYO",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7671,
    localidad: "ALGARROBO VERDE",
    cp: 5443,
    provincia_id: 18,
  },
  {
    id: 7672,
    localidad: "ALGARROBO GRANDE",
    cp: 5435,
    provincia_id: 18,
  },
  {
    id: 7673,
    localidad: "ALGARROBO DEL CURA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7674,
    localidad: "ALCAUCHA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7675,
    localidad: "ALBARDON",
    cp: 5419,
    provincia_id: 18,
  },
  {
    id: 7676,
    localidad: "AGUAS DEL PAJARO",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7677,
    localidad: "AGUANGO",
    cp: 5449,
    provincia_id: 18,
  },
  {
    id: 7678,
    localidad: "AGUADITAS",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7679,
    localidad: "AGUADA DE LA PEQA",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7680,
    localidad: "AGUA ESCONDIDA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7681,
    localidad: "AGUA DE PIEDRA",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7682,
    localidad: "AGUA DE LA ZORRA",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7683,
    localidad: "AGUA DE LA ZANJA",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7684,
    localidad: "AGUA D LS CABALLOS",
    cp: 5460,
    provincia_id: 18,
  },
  {
    id: 7685,
    localidad: "AGUA CERCADA",
    cp: 5447,
    provincia_id: 18,
  },
  {
    id: 7686,
    localidad: "ADAN QUIROGA",
    cp: 5409,
    provincia_id: 18,
  },
  {
    id: 7687,
    localidad: "ACERILLOS",
    cp: 5467,
    provincia_id: 18,
  },
  {
    id: 7688,
    localidad: "ABRA VERDE",
    cp: 5470,
    provincia_id: 18,
  },
  {
    id: 7689,
    localidad: "ABERASTAIN",
    cp: 5428,
    provincia_id: 18,
  },
  {
    id: 7690,
    localidad: "A GUANACACHE",
    cp: 5431,
    provincia_id: 18,
  },
  {
    id: 7691,
    localidad: "A DL RIO JACHAL",
    cp: 5461,
    provincia_id: 18,
  },
  {
    id: 7692,
    localidad: "ZAPALLITO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7693,
    localidad: "ZANJON",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 7694,
    localidad: "ZANJA HONDA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7695,
    localidad: "YUNCA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7696,
    localidad: "YUCHAN",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 7697,
    localidad: "YAVI CHICO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7698,
    localidad: "YATASTO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7699,
    localidad: "YASYAMAYO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 7700,
    localidad: "YASQUIASME",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7701,
    localidad: "YARIGUARENDA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7702,
    localidad: "YANCHUYA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7703,
    localidad: "YACUY",
    cp: 4564,
    provincia_id: 17,
  },
  {
    id: 7704,
    localidad: "YACORAITE",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 7705,
    localidad: "YACONES",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7706,
    localidad: "YACOCHUYA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7707,
    localidad: "YACIMIENTO TONONO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7708,
    localidad: "YACERA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7709,
    localidad: "YACAY",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7710,
    localidad: "YACARA",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 7711,
    localidad: "WEISBURG",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7712,
    localidad: "VOLCAN HIGUERAS",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7713,
    localidad: "VOLCAN HIGUERA",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7714,
    localidad: "VOLCAN AZUFRE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7715,
    localidad: "VLTA DE LAS TOBAS",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 7716,
    localidad: "VIZCARRA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 7717,
    localidad: "VIZCACHERAL",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7718,
    localidad: "VISCACHANI",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7719,
    localidad: "VIRREY TOLEDO",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 7720,
    localidad: "VIRGILIO TEDIN",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 7721,
    localidad: "VIQAL POZO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7722,
    localidad: "VINALITO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7723,
    localidad: "VINAL POZO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7724,
    localidad: "VINAL MACHO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7725,
    localidad: "VILLITAS",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7726,
    localidad: "VILLA TRANCAS",
    cp: 4127,
    provincia_id: 17,
  },
  {
    id: 7727,
    localidad: "VILLA SOLA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7728,
    localidad: "VILLA SAN LORENZO",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7729,
    localidad: "VILLA SAAVEDRA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7730,
    localidad: "VILLA PETRONA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7731,
    localidad: "VILLA MATOQUE",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7732,
    localidad: "VILLA LOS TARCOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 7733,
    localidad: "VILLA GRIMANESA",
    cp: 4200,
    provincia_id: 17,
  },
  {
    id: 7734,
    localidad: "VILLA FANNY",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7735,
    localidad: "VILLA CORTA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7736,
    localidad: "VILLA AURELIA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 7737,
    localidad: "VILLA ALEM",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7738,
    localidad: "VIEJA POZO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7739,
    localidad: "VICTORICA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7740,
    localidad: "VETA MINA AGUILAR",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 7741,
    localidad: "VESPUCIO",
    cp: 4562,
    provincia_id: 17,
  },
  {
    id: 7742,
    localidad: "VERTIENTES S RITA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7743,
    localidad: "VERA CRUZ",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7744,
    localidad: "VENCIDA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7745,
    localidad: "VELARDES",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7746,
    localidad: "VEGA DE ARIZARO",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7747,
    localidad: "VAQUEROS",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7748,
    localidad: "VAQUERIA",
    cp: 4191,
    provincia_id: 17,
  },
  {
    id: 7749,
    localidad: "VAQUERIA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7750,
    localidad: "VALLE ENCANTADO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7751,
    localidad: "VALLE DELGADO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7752,
    localidad: "VADO HONDO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7753,
    localidad: "VACA PERDIDA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7754,
    localidad: "V R DEL SALADILLO",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 7755,
    localidad: "V MAYOR ZABALETA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7756,
    localidad: "V GENERAL MITRE",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 7757,
    localidad: "V GENERAL GUEMES",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7758,
    localidad: "URUNDEL",
    cp: 4542,
    provincia_id: 17,
  },
  {
    id: 7759,
    localidad: "UCHUYOC",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7760,
    localidad: "UCHOGOL",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7761,
    localidad: "U EMBARCADERO FCGB",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7762,
    localidad: "TUYUNTI",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7763,
    localidad: "TUNALITO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7764,
    localidad: "TUNALITO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7765,
    localidad: "TUITE",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 7766,
    localidad: "TUCTUCA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7767,
    localidad: "TTE GRAL R N FRA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7768,
    localidad: "TRUSUCA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7769,
    localidad: "TRIGO HUAYCO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7770,
    localidad: "TRIGAL",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7771,
    localidad: "TRES YUCHANES",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7772,
    localidad: "TRES POZOS",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7773,
    localidad: "TRES POZOS",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7774,
    localidad: "TRES MORROS",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7775,
    localidad: "TRES CRUCES",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7776,
    localidad: "TRES CRUCES",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7777,
    localidad: "TRES CERRITOS",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 7778,
    localidad: "TRES ACEQUIAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7779,
    localidad: "TRANQUITAS",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 7780,
    localidad: "TOTORITAS",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 7781,
    localidad: "TOTORILLA",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 7782,
    localidad: "TOTORAL",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7783,
    localidad: "TORZALITO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7784,
    localidad: "TOROYOC",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 7785,
    localidad: "TORO YACO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7786,
    localidad: "TORO PAMPA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7787,
    localidad: "TORO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7788,
    localidad: "TOQUERO",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 7789,
    localidad: "TONONO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7790,
    localidad: "TONCO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7791,
    localidad: "TOMUCO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7792,
    localidad: "TOLOMBON",
    cp: 4154,
    provincia_id: 17,
  },
  {
    id: 7793,
    localidad: "TOLLOCHE",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7794,
    localidad: "TOLAR GRANDE",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7795,
    localidad: "TOLAR CHICO",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7796,
    localidad: "TOBANTIRENDA",
    cp: 4564,
    provincia_id: 17,
  },
  {
    id: 7797,
    localidad: "TITICOITE",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7798,
    localidad: "TIPAYOC",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7799,
    localidad: "TIPA SOLA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7800,
    localidad: "TIO FRANCO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 7801,
    localidad: "TILQUIZA",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 7802,
    localidad: "TILIAN",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7803,
    localidad: "TARTAGAL",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7804,
    localidad: "TAMAS CORTADAS",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 7805,
    localidad: "TALAVERA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7806,
    localidad: "TALAS",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7807,
    localidad: "TALAPAMPA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7808,
    localidad: "TALA YACO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7809,
    localidad: "TALA POZO",
    cp: 4200,
    provincia_id: 17,
  },
  {
    id: 7810,
    localidad: "TALA PASO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 7811,
    localidad: "TALA MUYO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7812,
    localidad: "TALA MUYO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7813,
    localidad: "TALA E R L LLANOS",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 7814,
    localidad: "TALA BAJADA",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 7815,
    localidad: "TALA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 7816,
    localidad: "TACUIL",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7817,
    localidad: "TACUARA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7818,
    localidad: "TACO POZO",
    cp: 5235,
    provincia_id: 17,
  },
  {
    id: 7819,
    localidad: "TACO PAMPA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7820,
    localidad: "TACO ESQUINA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7821,
    localidad: "TACIOJ",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7822,
    localidad: "TACA TACA E FCGB",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7823,
    localidad: "TABACO CIMARRON",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 7824,
    localidad: "TABACAL INGENIO",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 7825,
    localidad: "SURIYACU",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 7826,
    localidad: "SURIPUJIO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7827,
    localidad: "SURI PINTADO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 7828,
    localidad: "SURI MICUNA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7829,
    localidad: "SUNCHALITO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7830,
    localidad: "SUMALAO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7831,
    localidad: "SUCHA PERA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7832,
    localidad: "SOMBRERO NEGRO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7833,
    localidad: "SOLEDANI",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7834,
    localidad: "SOLEDAD",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7835,
    localidad: "SOLEDAD",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7836,
    localidad: "SOLAZUTI",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7837,
    localidad: "SOCOMPA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7838,
    localidad: "SIMBOLITO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7839,
    localidad: "SIMBOLAR",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7840,
    localidad: "SIMBOLAR",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7841,
    localidad: "SIMBOL YACO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7842,
    localidad: "SIETE DE ABRIL",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 7843,
    localidad: "SEVILLAR",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7844,
    localidad: "SELVA MARIA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7845,
    localidad: "SECLANTAS",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7846,
    localidad: "SECLANTA ADENTRO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7847,
    localidad: "SEBASTIAN EL CANO",
    cp: 5231,
    provincia_id: 17,
  },
  {
    id: 7848,
    localidad: "SCHNEIDEWIND",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7849,
    localidad: "SAUZAL",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7850,
    localidad: "SAUSALITO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7851,
    localidad: "SAUCELITO",
    cp: 4538,
    provincia_id: 17,
  },
  {
    id: 7852,
    localidad: "SAUCE SOLO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7853,
    localidad: "SAUCE REDONDO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7854,
    localidad: "SAUCE BAJADA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 7855,
    localidad: "SAUCE ALEGRE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7856,
    localidad: "SAUCE",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7857,
    localidad: "SARGENTO CRISTOBAL",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7858,
    localidad: "SAPO QUEMADO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7859,
    localidad: "SANTOS LUGARES",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7860,
    localidad: "SANTO DOMINGO ANTA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7861,
    localidad: "SANTO DOMINGO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7862,
    localidad: "SANTO DOMINGO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7863,
    localidad: "SANTA VICTORIA ESTE",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 7864,
    localidad: "SANTA VICTORIA",
    cp: 4652,
    provincia_id: 17,
  },
  {
    id: 7865,
    localidad: "SANTA VICTORIA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 7866,
    localidad: "SANTA TERESA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 7867,
    localidad: "SANTA TERESA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7868,
    localidad: "SANTA RUFINA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7869,
    localidad: "SANTA ROSA DE TASTIL",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7870,
    localidad: "SANTA ROSA DE ANTA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7871,
    localidad: "SANTA ROSA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 7872,
    localidad: "SANTA ROSA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7873,
    localidad: "SANTA ROSA",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 7874,
    localidad: "SANTA ROSA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7875,
    localidad: "SANTA ROSA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7876,
    localidad: "SANTA ROSA",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 7877,
    localidad: "SANTA ROSA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7878,
    localidad: "SANTA RITA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7879,
    localidad: "SANTA MARINA",
    cp: 4542,
    provincia_id: 17,
  },
  {
    id: 7880,
    localidad: "SANTA MARIA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7881,
    localidad: "SANTA MARIA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7882,
    localidad: "SANTA MARIA",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 7883,
    localidad: "SANTA LUCIA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 7884,
    localidad: "SANTA GERTRUDIS",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7885,
    localidad: "SANTA ELENA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7886,
    localidad: "SANTA ELENA",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 7887,
    localidad: "SANTA ELENA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7888,
    localidad: "SANTA CRUZ",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 7889,
    localidad: "SANTA CRUZ",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7890,
    localidad: "SANTA CRUZ",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7891,
    localidad: "SANTA CLARA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 7892,
    localidad: "SANTA CATALINA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7893,
    localidad: "SANTA BARBARA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7894,
    localidad: "SANTA ANA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7895,
    localidad: "SANTA ANA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7896,
    localidad: "SANTA ANA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7897,
    localidad: "SANSANA",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 7898,
    localidad: "SAN VICENTE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7899,
    localidad: "SAN VICENTE",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 7900,
    localidad: "SAN SEBASTIAN",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 7901,
    localidad: "SAN ROQUE",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 7902,
    localidad: "SAN ROQUE",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7903,
    localidad: "SAN RAMON DE LA NUEVA ORAN",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7904,
    localidad: "SAN RAMON",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7905,
    localidad: "SAN RAFAEL",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7906,
    localidad: "SAN PEDRO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7907,
    localidad: "SAN PATRICIO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 7908,
    localidad: "SAN PABLO DE REYES",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 7909,
    localidad: "SAN NICOLAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7910,
    localidad: "SAN MIGUEL",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 7911,
    localidad: "SAN MIGUEL",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7912,
    localidad: "SAN MARTIN",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 7913,
    localidad: "SAN MARTIN",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7914,
    localidad: "SAN MARTIN",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7915,
    localidad: "SAN MARTIN",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7916,
    localidad: "SAN MARCOS",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7917,
    localidad: "SAN LUIS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7918,
    localidad: "SAN LUIS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7919,
    localidad: "SAN LUCAS",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7920,
    localidad: "SAN LORENZO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7921,
    localidad: "SAN LORENZO",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 7922,
    localidad: "SAN LEON",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7923,
    localidad: "SAN LAURENCIO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7924,
    localidad: "SAN JUAN DE ORO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7925,
    localidad: "SAN JUAN",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7926,
    localidad: "SAN JUAN",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7927,
    localidad: "SAN JOSE DE LULES",
    cp: 4128,
    provincia_id: 17,
  },
  {
    id: 7928,
    localidad: "SAN JOSE DE COLTE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7929,
    localidad: "SAN JOSE DE COLTE",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 7930,
    localidad: "SAN JOSE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7931,
    localidad: "SAN JORGE",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7932,
    localidad: "SAN JOAQUIN",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7933,
    localidad: "SAN JOAQUIN",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7934,
    localidad: "SAN JAVIER",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 7935,
    localidad: "SAN ISIDRO-SALTA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7936,
    localidad: "SAN ISIDRO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7937,
    localidad: "SAN ISIDRO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 7938,
    localidad: "SAN ISIDRO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7939,
    localidad: "SAN ISIDRO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7940,
    localidad: "SAN IGNACIO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7941,
    localidad: "SAN IGNACIO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7942,
    localidad: "SAN GERONIMO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7943,
    localidad: "SAN GABRIEL",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 7944,
    localidad: "SAN FRANCISCO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7945,
    localidad: "SAN FERNANDO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7946,
    localidad: "SAN FELIPE",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 7947,
    localidad: "SAN FELIPE",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7948,
    localidad: "SAN FELIPE",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 7949,
    localidad: "SAN ESTEBAN",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 7950,
    localidad: "SAN CLEMENTE",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 7951,
    localidad: "SAN CARLOS",
    cp: 4428,
    provincia_id: 17,
  },
  {
    id: 7952,
    localidad: "SAN BERNARDO",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 7953,
    localidad: "SAN BERNARDO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7954,
    localidad: "SAN ANTONIO DE LOS COBRES",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 7955,
    localidad: "SAN ANTONIO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7956,
    localidad: "SAN ANTONIO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7957,
    localidad: "SAN ANTONIO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7958,
    localidad: "SAN ANDRES",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7959,
    localidad: "SAN ALEJO",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 7960,
    localidad: "SAN AGUSTIN",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7961,
    localidad: "SAN AGUSTIN",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 7962,
    localidad: "SALVADOR MAZZA",
    cp: 4568,
    provincia_id: 17,
  },
  {
    id: 7963,
    localidad: "SALTA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 7964,
    localidad: "SALAR DE POCITOS",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 7965,
    localidad: "SALADILLO DE OSMA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7966,
    localidad: "SALADILLO  JUAREZ",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 7967,
    localidad: "SALADILLO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7968,
    localidad: "SALADILLO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 7969,
    localidad: "SALADILLO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7970,
    localidad: "S. FORESTAL KM 50",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7971,
    localidad: "S VICTORIA ESTE",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 7972,
    localidad: "S ROSA DE TASTIL",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 7973,
    localidad: "S ROQUE LAS ARRIAS",
    cp: 5231,
    provincia_id: 17,
  },
  {
    id: 7974,
    localidad: "S R DE LA NVA ORAN",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7975,
    localidad: "S R DE L P GRANDE",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 7976,
    localidad: "S PEDRO DE ARANDA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 7977,
    localidad: "S PEDRO DE ARANDA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 7978,
    localidad: "S P. DE L CORRALES",
    cp: 4191,
    provincia_id: 17,
  },
  {
    id: 7979,
    localidad: "S MARTIN LA CUESTA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7980,
    localidad: "S JOSE DE ORQUERAS",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 7981,
    localidad: "S JOSE DE ESCALCHI",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7982,
    localidad: "S JOLLIN",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 7983,
    localidad: "S IGN DE LOYOLA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 7984,
    localidad: "S HACHADA E FCGB",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 7985,
    localidad: "S F. DE ESCOIPE",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 7986,
    localidad: "S DGO S VICTORIA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 7987,
    localidad: "S DE HOMBRE MUERTO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7988,
    localidad: "S ANTONIO DE IRUYA",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 7989,
    localidad: "S ANTONIO CHICOANA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 7990,
    localidad: "S ANT DE B VISTA",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 7991,
    localidad: "S ANT D LOS COBRES",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 7992,
    localidad: "S ALBERTO VLBA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 7993,
    localidad: "RUMIUARCO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 7994,
    localidad: "RUMIHUASI",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 7995,
    localidad: "RUMIHUASI",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 7996,
    localidad: "RUMIARCO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 7997,
    localidad: "RUIZ DE LOS LLANOS",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 7998,
    localidad: "ROSARIO FUNCA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 7999,
    localidad: "ROSARIO DEL DORADO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8000,
    localidad: "ROSARIO DE LERMA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8001,
    localidad: "ROSARIO DE LA FRONTERA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8002,
    localidad: "ROSALES",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8003,
    localidad: "RONTUYOC",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8004,
    localidad: "ROMA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8005,
    localidad: "RODEOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8006,
    localidad: "RODEO PAMPA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8007,
    localidad: "RODEO GRANDE",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8008,
    localidad: "RODEO COLORADO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8009,
    localidad: "RODEO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8010,
    localidad: "ROCA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8011,
    localidad: "RIVADAVIA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8012,
    localidad: "RIO TORO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8013,
    localidad: "RIO SECO",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8014,
    localidad: "RIO PESCADO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8015,
    localidad: "RIO LAVALLEN",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8016,
    localidad: "RIO GRANDE",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 8017,
    localidad: "RIO DEL VALLE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8018,
    localidad: "RIO DE LAS PIEDRAS",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8019,
    localidad: "RIO DE LAS PIEDRAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8020,
    localidad: "RIO COLORADO",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8021,
    localidad: "RIO COLORADO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8022,
    localidad: "RIO CARAPAN",
    cp: 4566,
    provincia_id: 17,
  },
  {
    id: 8023,
    localidad: "RIO BLANCO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8024,
    localidad: "RIO ANCHO",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8025,
    localidad: "RIO ANCHO",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8026,
    localidad: "RIO ALEMANIA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8027,
    localidad: "RINCON DE QUILMES",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8028,
    localidad: "RICARDO GUIRALDES",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8029,
    localidad: "RIARTE",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8030,
    localidad: "RETIRO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8031,
    localidad: "RESISTENCIA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8032,
    localidad: "REDONDO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8033,
    localidad: "REDONDA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8034,
    localidad: "RECREO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8035,
    localidad: "RECAREDO",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8036,
    localidad: "QUISTO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8037,
    localidad: "QUISCA LORO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8038,
    localidad: "QUISCA GRANDE",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8039,
    localidad: "QUILMES",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8040,
    localidad: "QUESERA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8041,
    localidad: "QUERA",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 8042,
    localidad: "QUEQUA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8043,
    localidad: "QUENTI TACO",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8044,
    localidad: "QUEMADITO",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8045,
    localidad: "QUEBRADA MUQANO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8046,
    localidad: "QUEBRADA DEL TORO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8047,
    localidad: "QUEBRADA DEL AGUA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8048,
    localidad: "QUEBRACHO COTO",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8049,
    localidad: "QUEBRACHAL",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8050,
    localidad: "PZO VRDE  I G N JU",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8051,
    localidad: "PZO D LS CHANCHOS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8052,
    localidad: "PUNTA DEL AGUA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8053,
    localidad: "PUNTA DE AGUA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8054,
    localidad: "PUNTA DE AGUA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8055,
    localidad: "PUNCO VISCANA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8056,
    localidad: "PUNCA VISCANA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8057,
    localidad: "PUMAHUASI",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8058,
    localidad: "PULI",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8059,
    localidad: "PULARES",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8060,
    localidad: "PUIL",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8061,
    localidad: "PUESTO VIEJO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8062,
    localidad: "PUESTO GRANDE",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8063,
    localidad: "PUESTO GRANDE",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8064,
    localidad: "PUESTO DEL ROSARIO",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8065,
    localidad: "PUESTO DEL PAQUELO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8066,
    localidad: "PUESTO DEL MEDIO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8067,
    localidad: "PUESTO DEL MEDIO",
    cp: 4200,
    provincia_id: 17,
  },
  {
    id: 8068,
    localidad: "PUESTO DEL MARQUEZ",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8069,
    localidad: "PUESTO DE MOTIJO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8070,
    localidad: "PUESTO DE LA VIUDA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8071,
    localidad: "PUESTO DE JULIPAO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8072,
    localidad: "PUESTO DE CASTRO",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 8073,
    localidad: "PUESTO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8074,
    localidad: "PUERTO YRIGOYEN",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8075,
    localidad: "PUERTO LA PAZ",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8076,
    localidad: "PUERTO DE DIAZ",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8077,
    localidad: "PUERTO BAULES",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8078,
    localidad: "PUERTA DE TASTIL",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8079,
    localidad: "PUERTA DE LA PAYA",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8080,
    localidad: "PUERTA DE JULIPAO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8081,
    localidad: "PUERTA BLANCA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8082,
    localidad: "PUENTE DE PLATA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8083,
    localidad: "PUENTE DE DIAZ",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8084,
    localidad: "PUEBLO VIEJO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8085,
    localidad: "PUEBLO VIEJO",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8086,
    localidad: "PUEBLO VIEJO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8087,
    localidad: "PUEBLO NUEVO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8088,
    localidad: "PUCARA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8089,
    localidad: "PRINGLES",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8090,
    localidad: "POZOS LARGOS",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8091,
    localidad: "POZO VERDE",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8092,
    localidad: "POZO VERDE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8093,
    localidad: "POZO PRINGLES",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8094,
    localidad: "POZO LARGO",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8095,
    localidad: "POZO HONDO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8096,
    localidad: "POZO GRANDE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8097,
    localidad: "POZO GRANDE",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8098,
    localidad: "POZO EL ALGARROBO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8099,
    localidad: "POZO DEL ZORRO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8100,
    localidad: "POZO DEL SAUCE",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8101,
    localidad: "POZO DEL PATO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8102,
    localidad: "POZO DEL MAZA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8103,
    localidad: "POZO DEL GREAL",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8104,
    localidad: "POZO DEL CUICO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8105,
    localidad: "POZO DEL CHAQAR",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8106,
    localidad: "POZO DE LA YEGUA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8107,
    localidad: "POZO DE LA PIEDRA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8108,
    localidad: "POZO CERCADO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8109,
    localidad: "POZO CERCADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8110,
    localidad: "POZO CANTADO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8111,
    localidad: "POZO BRAVO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8112,
    localidad: "POZO BLANCO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8113,
    localidad: "POZO BERMEJO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8114,
    localidad: "POZO AZUL",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8115,
    localidad: "POTRERO DE URIBURU",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8116,
    localidad: "POTRERO DE LINARES",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8117,
    localidad: "POTRERO DE DIAZ",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8118,
    localidad: "POTRERO",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 8119,
    localidad: "POTRERO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8120,
    localidad: "POTRERO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8121,
    localidad: "POTRERO",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8122,
    localidad: "POTRERILLOS",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8123,
    localidad: "POTRERILLOS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8124,
    localidad: "POTRERILLOS",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8125,
    localidad: "POTRERILLO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8126,
    localidad: "POTRERILLO",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8127,
    localidad: "POT DE CASTILLA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8128,
    localidad: "POSO DE ALGARROBO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8129,
    localidad: "POSCAYA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8130,
    localidad: "PORONGAL",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8131,
    localidad: "POMPEYA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8132,
    localidad: "POCOY",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8133,
    localidad: "POCITOS",
    cp: 4568,
    provincia_id: 17,
  },
  {
    id: 8134,
    localidad: "POBLACION",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8135,
    localidad: "POBLACION",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8136,
    localidad: "POB. DE ORTEGA",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8137,
    localidad: "PLUMA DEL PATO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8138,
    localidad: "PLUMA DEL PATO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8139,
    localidad: "PLUMA DE PATO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8140,
    localidad: "PLATERO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8141,
    localidad: "PIZARRO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8142,
    localidad: "PIUL",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8143,
    localidad: "PISCUNO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8144,
    localidad: "PIQUIRENDA",
    cp: 4564,
    provincia_id: 17,
  },
  {
    id: 8145,
    localidad: "PIQUETE DE ANTA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8146,
    localidad: "PIQUETE DE ANTA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8147,
    localidad: "PIQUETE CABADO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8148,
    localidad: "PINAL",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8149,
    localidad: "PIEDRAS MORADAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8150,
    localidad: "PIEDRA DEL MOLINO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8151,
    localidad: "PIEDEMONTE",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8152,
    localidad: "PIE DE LA CUESTA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8153,
    localidad: "PIE DE LA CUESTA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8154,
    localidad: "PICOS DE ARROZ",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8155,
    localidad: "PICOS DE AMOR",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8156,
    localidad: "PICHANAL",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8157,
    localidad: "PERU",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8158,
    localidad: "PEDRO LOZANO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8159,
    localidad: "PEDREGAL",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8160,
    localidad: "PAYOGASTILLA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8161,
    localidad: "PAYOGASTA",
    cp: 4418,
    provincia_id: 17,
  },
  {
    id: 8162,
    localidad: "PAYOGASTA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8163,
    localidad: "PASTOR SEVILLOSA",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8164,
    localidad: "PASTO PAMPA",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8165,
    localidad: "PASTEADERO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8166,
    localidad: "PASO VERDE",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8167,
    localidad: "PASO LA CRUZ",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8168,
    localidad: "PASO DEL RIO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8169,
    localidad: "PASO DEL DURAZNO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8170,
    localidad: "PASO DE LA CRUZ",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8171,
    localidad: "PASO DE BADERRANA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8172,
    localidad: "PASCHA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8173,
    localidad: "PASCALLA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8174,
    localidad: "PASAJES",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8175,
    localidad: "PAREDES",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8176,
    localidad: "PARAMAMAYA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8177,
    localidad: "PARAJE ZANJON",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8178,
    localidad: "PARAJE UNCURU",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8179,
    localidad: "PARAJE PIZCUNO",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8180,
    localidad: "PARAJE PIRCAS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8181,
    localidad: "PARAJE OLACAPATO",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8182,
    localidad: "PARAJE NACIMIENTOS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8183,
    localidad: "PARAJE LAS CUEVAS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8184,
    localidad: "PARAJE CORTADERAS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8185,
    localidad: "PARAJE COBRES",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8186,
    localidad: "PARAJE CERRO NEGRO",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8187,
    localidad: "PARAJE CAMPO LARGO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8188,
    localidad: "PARAISO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8189,
    localidad: "PAPA CHACRA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8190,
    localidad: "PAMPA GRANDE",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8191,
    localidad: "PALOMITAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8192,
    localidad: "PALOMAR",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8193,
    localidad: "PALO SANTO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8194,
    localidad: "PALO PINTADO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8195,
    localidad: "PALO A PIQUE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8196,
    localidad: "PALMIRA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8197,
    localidad: "PALMARCITO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8198,
    localidad: "PALMARCITO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8199,
    localidad: "PALMAR LARGO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8200,
    localidad: "PALMA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8201,
    localidad: "PALERMO OESTE",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8202,
    localidad: "PALERMO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8203,
    localidad: "PAIRIQUE CHICO",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8204,
    localidad: "P. DEL POTRILLO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8205,
    localidad: "P S MAZZA E POCITO",
    cp: 4568,
    provincia_id: 17,
  },
  {
    id: 8206,
    localidad: "P PASTOS GRANDES",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8207,
    localidad: "P MORRO COLORADO",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8208,
    localidad: "P MINA CONCORDIA",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8209,
    localidad: "P ESQ DE GUARDIA",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8210,
    localidad: "P EL MILAGRO SANI",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8211,
    localidad: "P DE POYOGASTA",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8212,
    localidad: "P DE LAS CARRETAS",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8213,
    localidad: "OVEJERIA",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8214,
    localidad: "OVEJERIA",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8215,
    localidad: "OVANDO",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8216,
    localidad: "OTOMANA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8217,
    localidad: "OSMA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8218,
    localidad: "ORNILLO",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8219,
    localidad: "ORAN",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8220,
    localidad: "OLMOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8221,
    localidad: "OLACAPANTO GRANDE",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8222,
    localidad: "OLACAPANTO CHICO",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8223,
    localidad: "OJO DE AGUA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8224,
    localidad: "OJO DE AGUA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8225,
    localidad: "OJO DE AGUA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8226,
    localidad: "NUEVO PORVENIR",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8227,
    localidad: "NUESTRA SEÑORA DE TALAVERA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8228,
    localidad: "NOGALITO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8229,
    localidad: "NAZARENO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8230,
    localidad: "N Sra DE TALAVERA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8231,
    localidad: "MORILLO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8232,
    localidad: "MORENILLO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8233,
    localidad: "MORALITO",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 8234,
    localidad: "MORALES",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8235,
    localidad: "MONTE VIEJO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8236,
    localidad: "MONTE QUEMADO",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8237,
    localidad: "MONTE GRANDE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8238,
    localidad: "MONTE CARMELO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8239,
    localidad: "MONTE",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8240,
    localidad: "MONASTERIOS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8241,
    localidad: "MOLLINEDO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8242,
    localidad: "MOLLE POZO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8243,
    localidad: "MOLLAR",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8244,
    localidad: "MOLINOS",
    cp: 4420,
    provincia_id: 17,
  },
  {
    id: 8245,
    localidad: "MOLINO DE GONGORA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8246,
    localidad: "MOLINO",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8247,
    localidad: "MOLINO",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8248,
    localidad: "MOJOTORO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8249,
    localidad: "MISTOLITO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8250,
    localidad: "MISTOLAR",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8251,
    localidad: "MISTOL MAREADO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8252,
    localidad: "MISTOL MARCADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8253,
    localidad: "MISIONES",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8254,
    localidad: "MISION SANTA LUCIA",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8255,
    localidad: "MISION LA PAZ",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8256,
    localidad: "MISION FRANCISCANA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8257,
    localidad: "MISION FRANCISCANA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8258,
    localidad: "MISION CHAQUEQA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8259,
    localidad: "MIRAFLORES M",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8260,
    localidad: "MIRAFLORES",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8261,
    localidad: "MIRAFLORES",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8262,
    localidad: "MIRAFLORES",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8263,
    localidad: "MINAS YPF",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8264,
    localidad: "MINAS YPF",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8265,
    localidad: "MINAS VICTORIA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8266,
    localidad: "MINA TINCALAYA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8267,
    localidad: "MINA SOL DE MAYO",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8268,
    localidad: "MINA SAN WALTERIO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8269,
    localidad: "MINA SAN GUILLERMO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8270,
    localidad: "MINA SAN ESTEBAN",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8271,
    localidad: "MINA JULIO",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8272,
    localidad: "MINA DON OTTO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8273,
    localidad: "MINA CAROLINA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8274,
    localidad: "MINA BELGICA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8275,
    localidad: "METAN VIEJO",
    cp: 4441,
    provincia_id: 17,
  },
  {
    id: 8276,
    localidad: "METAN",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8277,
    localidad: "MESON",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8278,
    localidad: "MESETA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8279,
    localidad: "MERCEDES",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8280,
    localidad: "MERCEDES",
    cp: 4200,
    provincia_id: 17,
  },
  {
    id: 8281,
    localidad: "MERCED DE ARRIBA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8282,
    localidad: "MEDIA LUNA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8283,
    localidad: "MEDIA LUNA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8284,
    localidad: "MEDIA LUNA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8285,
    localidad: "MEDIA LUNA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8286,
    localidad: "MECOYITA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8287,
    localidad: "MAYO TORITO",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8288,
    localidad: "MAYILTE",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8289,
    localidad: "MATANSILLAS",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8290,
    localidad: "MASUMPA",
    cp: 4197,
    provincia_id: 17,
  },
  {
    id: 8291,
    localidad: "MARTINEZ DEL TINEO",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8292,
    localidad: "MARTINEZ DEL TINCO",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8293,
    localidad: "MARTIN GARCIA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8294,
    localidad: "MARIA LUISA",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 8295,
    localidad: "MARIA JOSE",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8296,
    localidad: "MARIA CRISTINA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8297,
    localidad: "MANUELA PEDRAZA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8298,
    localidad: "MANUEL ELORDI",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8299,
    localidad: "MANGA VIEJA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8300,
    localidad: "MANAGUA",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8301,
    localidad: "MAL PASO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8302,
    localidad: "MAL CANTE",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8303,
    localidad: "MADREJON",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8304,
    localidad: "MADRE VIEJA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8305,
    localidad: "MADARIAGA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8306,
    localidad: "MACUETA",
    cp: 4566,
    provincia_id: 17,
  },
  {
    id: 8307,
    localidad: "MACHO RASTROJO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8308,
    localidad: "MACAPILLO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8309,
    localidad: "LURACATAO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8310,
    localidad: "LUNA MUERTA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8311,
    localidad: "LUMBRERAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8312,
    localidad: "LUIS BURELA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8313,
    localidad: "LOTE SARITA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8314,
    localidad: "LOTE NRO 8",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8315,
    localidad: "LOTE MARCELA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8316,
    localidad: "LOTE LUCRECIA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8317,
    localidad: "LOTE JOSEFINA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8318,
    localidad: "LOTE ESTELA",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 8319,
    localidad: "LOTE 27",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8320,
    localidad: "LOS ZANJONES",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8321,
    localidad: "LOS TOLDOS",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 8322,
    localidad: "LOS TAJAMARES",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 8323,
    localidad: "LOS SAUCES",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8324,
    localidad: "LOS SAUCES",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8325,
    localidad: "LOS SAUCES",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8326,
    localidad: "LOS ROSALES",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8327,
    localidad: "LOS ROSALES",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8328,
    localidad: "LOS RANCHILLOS",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8329,
    localidad: "LOS PATOS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8330,
    localidad: "LOS NOQUES",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8331,
    localidad: "LOS NOGALES",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8332,
    localidad: "LOS MOLLINEDOS",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8333,
    localidad: "LOS MERCADOS",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8334,
    localidad: "LOS LOS",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8335,
    localidad: "LOS LAURELES",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8336,
    localidad: "LOS CORRALES",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8337,
    localidad: "LOS COLORADOS",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8338,
    localidad: "LOS COLORADOS",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8339,
    localidad: "LOS CHURQUIS",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8340,
    localidad: "LOS CHURQUIS",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8341,
    localidad: "LOS CHIFLES",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8342,
    localidad: "LOS CHAGUANCOS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8343,
    localidad: "LOS CASTILLOS",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8344,
    localidad: "LOS CANTEROS",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8345,
    localidad: "LOS BLANCOS",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 8346,
    localidad: "LOS BLANCOS",
    cp: 4555,
    provincia_id: 17,
  },
  {
    id: 8347,
    localidad: "LOS BAQADOS",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8348,
    localidad: "LOS BAÑOS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8349,
    localidad: "LOS BALDES",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8350,
    localidad: "LOS ALAMOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8351,
    localidad: "LOS ALAMOS",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8352,
    localidad: "LOS ALAMOS",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8353,
    localidad: "LOROHUASI",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8354,
    localidad: "LOMAS DE OLMEDO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8355,
    localidad: "LOMAS BLANCAS",
    cp: 4197,
    provincia_id: 17,
  },
  {
    id: 8356,
    localidad: "LOMA DE BURRO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8357,
    localidad: "LOMA COLORADA",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8358,
    localidad: "LLULLUCHAYOC",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8359,
    localidad: "LLUCHA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8360,
    localidad: "LLAMERIA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8361,
    localidad: "LIPEO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8362,
    localidad: "LIMONCITO",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8363,
    localidad: "LIMACHE",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8364,
    localidad: "LESSER",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8365,
    localidad: "LECHIGUANA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8366,
    localidad: "LAS ZANJAS",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8367,
    localidad: "LAS VIQAS",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8368,
    localidad: "LAS VIBORAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8369,
    localidad: "LAS VATEAS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8370,
    localidad: "LAS VARAS",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8371,
    localidad: "LAS TUNILLAS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8372,
    localidad: "LAS TRES MARIAS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8373,
    localidad: "LAS TRANCAS",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8374,
    localidad: "LAS TORTUGAS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8375,
    localidad: "LAS TIENDITAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8376,
    localidad: "LAS SALADAS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8377,
    localidad: "LAS ROSAS",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8378,
    localidad: "LAS PUERTAS",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8379,
    localidad: "LAS PIRCAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8380,
    localidad: "LAS PIEDRITAS",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8381,
    localidad: "LAS PALMAS",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8382,
    localidad: "LAS PALMAS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8383,
    localidad: "LAS PALMAS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8384,
    localidad: "LAS PAILAS",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8385,
    localidad: "LAS MORAS",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8386,
    localidad: "LAS MOJARRITAS",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8387,
    localidad: "LAS MESITAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8388,
    localidad: "LAS MESADAS",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8389,
    localidad: "LAS MERCEDES",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8390,
    localidad: "LAS MARAVILLAS",
    cp: 4538,
    provincia_id: 17,
  },
  {
    id: 8391,
    localidad: "LAS LOMITAS",
    cp: 4562,
    provincia_id: 17,
  },
  {
    id: 8392,
    localidad: "LAS LLAVES",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8393,
    localidad: "LAS LECHUZAS",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8394,
    localidad: "LAS LAJITAS",
    cp: 4450,
    provincia_id: 17,
  },
  {
    id: 8395,
    localidad: "LAS LAJITAS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8396,
    localidad: "LAS JUNTAS",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8397,
    localidad: "LAS J DE ALEMANIA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8398,
    localidad: "LAS HORQUETAS",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8399,
    localidad: "LAS HECHERAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8400,
    localidad: "LAS GARZAS",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8401,
    localidad: "LAS FLORES",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8402,
    localidad: "LAS FLECHAS",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8403,
    localidad: "LAS FLACAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8404,
    localidad: "LAS DELICIAS",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8405,
    localidad: "LAS CURTIEMBRES",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8406,
    localidad: "LAS CUEVAS",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8407,
    localidad: "LAS CUESTITAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8408,
    localidad: "LAS COSTAS",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8409,
    localidad: "LAS CORTDERAS",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8410,
    localidad: "LAS CONCHAS",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8411,
    localidad: "LAS CONCHAS",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8412,
    localidad: "LAS COLAS",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8413,
    localidad: "LAS CEBADAS",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8414,
    localidad: "LAS CATITAS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8415,
    localidad: "LAS CAQITAS",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8416,
    localidad: "LAS CAQAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8417,
    localidad: "LAS CAQAS",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8418,
    localidad: "LAS CAPILLAS",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8419,
    localidad: "LAS CAÑITAS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8420,
    localidad: "LAS BOLSAS",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8421,
    localidad: "LAS BLANCAS",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8422,
    localidad: "LAS BLANCAS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8423,
    localidad: "LAS BATEAS",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8424,
    localidad: "LAS BANDURRIAS",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8425,
    localidad: "LAS ARENAS",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8426,
    localidad: "LAS ARCAS",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8427,
    localidad: "LAS ARCAS",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8428,
    localidad: "LAS ANIMAS",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8429,
    localidad: "LAS ACHERAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8430,
    localidad: "LAGUNITA",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8431,
    localidad: "LAGUNA VERDE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8432,
    localidad: "LAGUNA SECA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8433,
    localidad: "LAGUNA BLANCA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8434,
    localidad: "LAG. N POBLACION",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8435,
    localidad: "LA YESERA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8436,
    localidad: "LA VIQA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8437,
    localidad: "LA VIQA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8438,
    localidad: "LA VIÑA",
    cp: 4424,
    provincia_id: 17,
  },
  {
    id: 8439,
    localidad: "LA VETA",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 8440,
    localidad: "LA UNION",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8441,
    localidad: "LA UNION",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8442,
    localidad: "LA TROJA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8443,
    localidad: "LA TRAMPA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8444,
    localidad: "LA TOMA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8445,
    localidad: "LA TABLADA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8446,
    localidad: "LA SOLEDAD",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8447,
    localidad: "LA SILLETA",
    cp: 4433,
    provincia_id: 17,
  },
  {
    id: 8448,
    localidad: "LA REPRESA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8449,
    localidad: "LA QUESERA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8450,
    localidad: "LA QUENA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8451,
    localidad: "LA PUNTILLA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8452,
    localidad: "LA PUNILLA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8453,
    localidad: "LA PUERTA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8454,
    localidad: "LA POSTA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8455,
    localidad: "LA PORCELANA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8456,
    localidad: "LA POMA",
    cp: 4416,
    provincia_id: 17,
  },
  {
    id: 8457,
    localidad: "LA POBLACION",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8458,
    localidad: "LA POBLACION",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8459,
    localidad: "LA POBLACION",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8460,
    localidad: "LA PAYA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8461,
    localidad: "LA PAMPA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8462,
    localidad: "LA PALMA SOLA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8463,
    localidad: "LA PALATA",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8464,
    localidad: "LA PAJITA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8465,
    localidad: "LA OLIVA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8466,
    localidad: "LA OFELIA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8467,
    localidad: "LA MORA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8468,
    localidad: "LA MONTAQA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8469,
    localidad: "LA MERCED",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8470,
    localidad: "LA MERCED",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8471,
    localidad: "LA MAROMA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8472,
    localidad: "LA MARGARITA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8473,
    localidad: "LA MARAVILLA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8474,
    localidad: "LA MARAVILLA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8475,
    localidad: "LA MANGA",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8476,
    localidad: "LA LOMITA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8477,
    localidad: "LA LAGUNILLA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8478,
    localidad: "LA JUNTA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8479,
    localidad: "LA ISLA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8480,
    localidad: "LA ISLA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8481,
    localidad: "LA ISLA",
    cp: 5235,
    provincia_id: 17,
  },
  {
    id: 8482,
    localidad: "LA HUERTA",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8483,
    localidad: "LA HOYADA",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8484,
    localidad: "LA HIGUERA",
    cp: 4547,
    provincia_id: 17,
  },
  {
    id: 8485,
    localidad: "LA HERRADURA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8486,
    localidad: "LA GUARDIA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8487,
    localidad: "LA FORTUNA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8488,
    localidad: "LA FLORIDA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8489,
    localidad: "LA FLORIDA",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8490,
    localidad: "LA FLORENCIA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8491,
    localidad: "LA FIRMEZA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8492,
    localidad: "LA FAMA",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8493,
    localidad: "LA FALDA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8494,
    localidad: "LA FALDA",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8495,
    localidad: "LA ESTRELLA",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8496,
    localidad: "LA ESTELA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8497,
    localidad: "LA ESQUINITA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8498,
    localidad: "LA ESPERANZA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8499,
    localidad: "LA ESPERANZA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8500,
    localidad: "LA ESPERANZA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8501,
    localidad: "LA ESPERANZA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8502,
    localidad: "LA ENTRADA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8503,
    localidad: "LA DEFENSA",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8504,
    localidad: "LA CURVA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8505,
    localidad: "LA CUEVA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8506,
    localidad: "LA CUESTITA METAN",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8507,
    localidad: "LA CUESTITA ANTA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8508,
    localidad: "LA CUESTA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8509,
    localidad: "LA CRUZ",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8510,
    localidad: "LA CRUZ",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8511,
    localidad: "LA COSTOSA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8512,
    localidad: "LA COSTA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8513,
    localidad: "LA COSTA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8514,
    localidad: "LA CIENEGUITA",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8515,
    localidad: "LA CIENAGA CAPITAL",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8516,
    localidad: "LA CHIQUITA",
    cp: 3700,
    provincia_id: 17,
  },
  {
    id: 8517,
    localidad: "LA CHINACA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8518,
    localidad: "LA CHINA",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8519,
    localidad: "LA CASUALIDAD MINA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8520,
    localidad: "LA CARRETERA",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8521,
    localidad: "LA CANDELARIA",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8522,
    localidad: "LA CANCHA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8523,
    localidad: "LA CALAVERA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8524,
    localidad: "LA BODEGUITA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8525,
    localidad: "LA BODEGA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8526,
    localidad: "LA BANDA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8527,
    localidad: "LA ASUNCION",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8528,
    localidad: "LA ASUNCION",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8529,
    localidad: "LA ARMONIA",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8530,
    localidad: "LA ARMONIA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8531,
    localidad: "LA ARMONIA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8532,
    localidad: "LA ARGENTINA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8533,
    localidad: "LA ARCADIA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8534,
    localidad: "LA ALMONA",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 8535,
    localidad: "LA AGUADITA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8536,
    localidad: "KM 1306 FCGB",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8537,
    localidad: "KILOMETRO 1448",
    cp: 4564,
    provincia_id: 17,
  },
  {
    id: 8538,
    localidad: "KILOMETRO 1424",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8539,
    localidad: "KILOMETRO 1398",
    cp: 4562,
    provincia_id: 17,
  },
  {
    id: 8540,
    localidad: "KILOMETRO 1397",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8541,
    localidad: "KILOMETRO 1373",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8542,
    localidad: "KILOMETRO 1369",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8543,
    localidad: "KILOMETRO 1321",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 8544,
    localidad: "KILOMETRO 1298",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 8545,
    localidad: "KILOMETRO 1291",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8546,
    localidad: "KILOMETRO 1281",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8547,
    localidad: "KILOMETRO 1280",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8548,
    localidad: "KILOMETRO 1176 300",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8549,
    localidad: "KILOMETRO 1172",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8550,
    localidad: "KILOMETRO 1156",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8551,
    localidad: "KILOMETRO 1152",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8552,
    localidad: "KILOMETRO 1125",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8553,
    localidad: "KILOMETRO 1104",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8554,
    localidad: "KILOMETRO 1102",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8555,
    localidad: "KILOMETRO 1094",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8556,
    localidad: "KILOMETRO 1088",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8557,
    localidad: "JURAMENTO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8558,
    localidad: "JUNTAS DE S ANT",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8559,
    localidad: "JUNCALITO",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8560,
    localidad: "JUNCAL",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8561,
    localidad: "JULIPAO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8562,
    localidad: "JOAQUIN V GONZALEZ",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8563,
    localidad: "JERONIMO MATORRAS",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8564,
    localidad: "JACIMANA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8565,
    localidad: "ITUYURO",
    cp: 4566,
    provincia_id: 17,
  },
  {
    id: 8566,
    localidad: "ISONZA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8567,
    localidad: "ISLA DE CAQAS",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 8568,
    localidad: "ISLA DE CAÑAS",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 8569,
    localidad: "IRUYA",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8570,
    localidad: "INTICANCHO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8571,
    localidad: "INGENIO SAN MARTIN",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 8572,
    localidad: "INGENIO SAN ISIDRO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8573,
    localidad: "INGENIO LA ESPZA",
    cp: 4542,
    provincia_id: 17,
  },
  {
    id: 8574,
    localidad: "INGENIO LA ESPERANZA",
    cp: 4542,
    provincia_id: 17,
  },
  {
    id: 8575,
    localidad: "INGENIERO MAURY",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8576,
    localidad: "INGENIERO JUAREZ",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8577,
    localidad: "ING GUILLERMO N JUAREZ",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8578,
    localidad: "ING G. N JUAREZ",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8579,
    localidad: "ING E H FAURE",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8580,
    localidad: "INCAMAYO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8581,
    localidad: "INCAHUASI",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8582,
    localidad: "INCACHULI",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8583,
    localidad: "I DE LA CANDELARIA",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8584,
    localidad: "HUMANAS",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8585,
    localidad: "HUMAITA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8586,
    localidad: "HUGUERILLAS",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8587,
    localidad: "HUAYRA HUASY",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8588,
    localidad: "HUALLATAYOC",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8589,
    localidad: "HUAICONDO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8590,
    localidad: "HOSTERIA JURAMENTO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8591,
    localidad: "HORNILLOS",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8592,
    localidad: "HITO 1",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8593,
    localidad: "HIPOLITO YRIGOYEN",
    cp: 4532,
    provincia_id: 17,
  },
  {
    id: 8594,
    localidad: "HIGUERAS",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8595,
    localidad: "HICKMAN",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8596,
    localidad: "HARAS SAN ANTONIO",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8597,
    localidad: "H MUERTO SALAR",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8598,
    localidad: "GUAYACAN",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8599,
    localidad: "GUAMACHI",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8600,
    localidad: "GUALIANA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8601,
    localidad: "GUALFIN",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8602,
    localidad: "GUADALCAZAR",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8603,
    localidad: "GUACHIPAS",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8604,
    localidad: "GRANDES PASTOS",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 8605,
    localidad: "GONZALEZ",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8606,
    localidad: "GOBERNADOR YALUR",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8607,
    localidad: "GOBERNADOR SARAVIA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8608,
    localidad: "GENERAL PIZARRO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8609,
    localidad: "GENERAL MOSCONI",
    cp: 4582,
    provincia_id: 17,
  },
  {
    id: 8610,
    localidad: "GENERAL GUEMES",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8611,
    localidad: "GENERAL ENRIQUE MOSCONI",
    cp: 4562,
    provincia_id: 17,
  },
  {
    id: 8612,
    localidad: "GENERAL BALLIVIAN",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8613,
    localidad: "GENERAL ALVARADO",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8614,
    localidad: "GAONA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8615,
    localidad: "GALLINATO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8616,
    localidad: "G MANUEL SOLA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8617,
    localidad: "G ENRIQUE MOSCONI",
    cp: 4562,
    provincia_id: 17,
  },
  {
    id: 8618,
    localidad: "FUERTE QUEMADO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8619,
    localidad: "FUERTE EL PITO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8620,
    localidad: "FUERTE ALTO",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8621,
    localidad: "FRONTERA TRES",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8622,
    localidad: "FRONTERA 5",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8623,
    localidad: "FRONTERA 4",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8624,
    localidad: "FORTIN FRIAS",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8625,
    localidad: "FORTIN BELGRANO",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8626,
    localidad: "FLORESTA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8627,
    localidad: "FLORENCIO SANCHEZ",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8628,
    localidad: "FINCA ROCCA",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8629,
    localidad: "FINCA MISION ZENTA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8630,
    localidad: "FINCA MISION ZENTA",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8631,
    localidad: "FINCA LA TOMA",
    cp: 4538,
    provincia_id: 17,
  },
  {
    id: 8632,
    localidad: "FINCA LA ROSA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8633,
    localidad: "FINCA LA CHINA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8634,
    localidad: "FINCA EL COLEGIO",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8635,
    localidad: "FINCA EL CARMEN",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8636,
    localidad: "FINCA COLON",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8637,
    localidad: "FINCA BELGRANO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8638,
    localidad: "FINCA ARMONIA",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8639,
    localidad: "FEDERACION",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8640,
    localidad: "F. J F JUAREZ",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8641,
    localidad: "F N DE LAPRIDA",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8642,
    localidad: "F CNO VALLISIOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8643,
    localidad: "F CNO A COLON",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8644,
    localidad: "ESTEBAN DE URIZAR",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8645,
    localidad: "ESTANCIA VIEJA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8646,
    localidad: "ESTACION ZUVIRIA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8647,
    localidad: "EST FOGUISTA J F",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8648,
    localidad: "ESQUINITAS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8649,
    localidad: "ESQUINA DE QUISTO",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 8650,
    localidad: "ESQUINA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8651,
    localidad: "ESQUINA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8652,
    localidad: "ESPINILLO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8653,
    localidad: "ESCUELA 243",
    cp: 4128,
    provincia_id: 17,
  },
  {
    id: 8654,
    localidad: "ESCOIPE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8655,
    localidad: "ESCALCHI",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8656,
    localidad: "ENTRE RIOS",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8657,
    localidad: "ENCRUCIJADA",
    cp: 5231,
    provincia_id: 17,
  },
  {
    id: 8658,
    localidad: "ENC. DE TASTIL",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8659,
    localidad: "EMBOSCADA",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8660,
    localidad: "EMBARCACION",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8661,
    localidad: "ELTUNAL",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8662,
    localidad: "EL ZORRO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8663,
    localidad: "EL ZAPALLO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8664,
    localidad: "EL ZAPALLAR",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8665,
    localidad: "EL ZAPALLAR",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 8666,
    localidad: "EL ZANJON",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8667,
    localidad: "EL YESO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8668,
    localidad: "EL YACON",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8669,
    localidad: "EL VISMAL",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 8670,
    localidad: "EL VENCIDO",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8671,
    localidad: "EL VALLECITO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8672,
    localidad: "EL TUNALITO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8673,
    localidad: "EL TUNAL",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8674,
    localidad: "EL TRIGAL",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8675,
    localidad: "EL TOTORAL",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8676,
    localidad: "EL TORO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8677,
    localidad: "EL TIPAL",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8678,
    localidad: "EL TIMBO",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8679,
    localidad: "EL TARTAGAL",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8680,
    localidad: "EL TAPIAL",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8681,
    localidad: "EL TANDIL",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8682,
    localidad: "EL TALAR",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8683,
    localidad: "EL TABACAL",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 8684,
    localidad: "EL T V GRAL MITRE",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8685,
    localidad: "EL SUNCHAL",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8686,
    localidad: "EL SUNCHAL",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8687,
    localidad: "EL SUNCHAL",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8688,
    localidad: "EL SOLDADITO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8689,
    localidad: "EL SIMBOLAR",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8690,
    localidad: "EL SIMBOLAR",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8691,
    localidad: "EL SAUZAL",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8692,
    localidad: "EL SAUCE",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8693,
    localidad: "EL SAPO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8694,
    localidad: "EL SALTO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8695,
    localidad: "EL ROSAL",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8696,
    localidad: "EL ROSADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8697,
    localidad: "EL RODEO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8698,
    localidad: "EL REY",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8699,
    localidad: "EL RETIRO",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8700,
    localidad: "EL REMATE",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8701,
    localidad: "EL RECREO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8702,
    localidad: "EL QUEMADO",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8703,
    localidad: "EL QUEMADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8704,
    localidad: "EL QUEBRACHAL",
    cp: 4453,
    provincia_id: 17,
  },
  {
    id: 8705,
    localidad: "EL QATO",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8706,
    localidad: "EL Q. CARBONCITO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8707,
    localidad: "EL PUYIL",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8708,
    localidad: "EL PUESTITO",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8709,
    localidad: "EL PUCARA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8710,
    localidad: "EL PROGRESO",
    cp: 5235,
    provincia_id: 17,
  },
  {
    id: 8711,
    localidad: "EL PRADO",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8712,
    localidad: "EL PRADO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8713,
    localidad: "EL POZO",
    cp: 5233,
    provincia_id: 17,
  },
  {
    id: 8714,
    localidad: "EL POTRERO DE DIAZ",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8715,
    localidad: "EL POTRERO",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8716,
    localidad: "EL POTRERO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8717,
    localidad: "EL POTRERO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8718,
    localidad: "EL POTRERITO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8719,
    localidad: "EL PORVENIR",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8720,
    localidad: "EL PORVENIR",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8721,
    localidad: "EL PORTEZUELO",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8722,
    localidad: "EL PERTIGO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8723,
    localidad: "EL PERICOTE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8724,
    localidad: "EL PEDACITO",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8725,
    localidad: "EL PASO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8726,
    localidad: "EL PARQUE",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8727,
    localidad: "EL PACARA",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8728,
    localidad: "EL OSO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8729,
    localidad: "EL OJO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8730,
    localidad: "EL OJITO",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8731,
    localidad: "EL OBELISCO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8732,
    localidad: "EL NOGALAR",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8733,
    localidad: "EL NARANJO",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8734,
    localidad: "EL NARANJO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8735,
    localidad: "EL NARANJO",
    cp: 4191,
    provincia_id: 17,
  },
  {
    id: 8736,
    localidad: "EL MOYAR",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8737,
    localidad: "EL MORANILLO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8738,
    localidad: "EL MONUMENTO",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 8739,
    localidad: "EL MOLLE",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8740,
    localidad: "EL MOLLAR",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8741,
    localidad: "EL MOLLAR",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8742,
    localidad: "EL MOLINO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8743,
    localidad: "EL MISTOLAR",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8744,
    localidad: "EL MISTOL",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8745,
    localidad: "EL MIRADOR",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8746,
    localidad: "EL MARAY",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8747,
    localidad: "EL MANZANO",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8748,
    localidad: "EL MANANTIAL",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8749,
    localidad: "EL LIBANO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8750,
    localidad: "EL LEONCITO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8751,
    localidad: "EL JARDIN",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8752,
    localidad: "EL JARAVI",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8753,
    localidad: "EL INDIO",
    cp: 4142,
    provincia_id: 17,
  },
  {
    id: 8754,
    localidad: "EL HUAICO",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8755,
    localidad: "EL GUANACO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8756,
    localidad: "EL GOLGOTA",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8757,
    localidad: "EL GALPON",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 8758,
    localidad: "EL GALLINATO",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8759,
    localidad: "EL FRAILE",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8760,
    localidad: "EL ESTANQUE",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8761,
    localidad: "EL ESPINILLO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8762,
    localidad: "EL ESPINILLO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8763,
    localidad: "EL ENCON",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8764,
    localidad: "EL DORADO",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8765,
    localidad: "EL DESTIERRO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8766,
    localidad: "EL DESMONTE",
    cp: 4538,
    provincia_id: 17,
  },
  {
    id: 8767,
    localidad: "EL DESMONTE",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8768,
    localidad: "EL CUINCO POZO DEL",
    cp: 4542,
    provincia_id: 17,
  },
  {
    id: 8769,
    localidad: "EL CUCHO",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 8770,
    localidad: "EL CUCHILLO",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8771,
    localidad: "EL CRESTON",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8772,
    localidad: "EL CRES DE PIEDRA",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8773,
    localidad: "EL CORRALITO",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8774,
    localidad: "EL CORRAL VIEJO",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8775,
    localidad: "EL CONDOR",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8776,
    localidad: "EL CONDOR",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8777,
    localidad: "EL COLGADO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8778,
    localidad: "EL COLEGIO",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8779,
    localidad: "EL CIENEGO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8780,
    localidad: "EL CHURCAL",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8781,
    localidad: "EL CHORRO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8782,
    localidad: "EL CERCADO",
    cp: 4142,
    provincia_id: 17,
  },
  {
    id: 8783,
    localidad: "EL CEIBAL",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8784,
    localidad: "EL CEIBAL",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8785,
    localidad: "EL CEIBAL",
    cp: 4128,
    provincia_id: 17,
  },
  {
    id: 8786,
    localidad: "EL CARRIZAL",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8787,
    localidad: "EL CARRIZAL",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8788,
    localidad: "EL CARRIL",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8789,
    localidad: "EL CARMEN",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8790,
    localidad: "EL CARMEN",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8791,
    localidad: "EL CARMEN",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8792,
    localidad: "EL CARMEN",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8793,
    localidad: "EL CARMEN",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8794,
    localidad: "EL CARMEN",
    cp: 4128,
    provincia_id: 17,
  },
  {
    id: 8795,
    localidad: "EL CARANCHO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8796,
    localidad: "EL CANDADO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8797,
    localidad: "EL BRETE",
    cp: 4456,
    provincia_id: 17,
  },
  {
    id: 8798,
    localidad: "EL BRETE",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8799,
    localidad: "EL BREALITO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8800,
    localidad: "EL BREAL",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8801,
    localidad: "EL BORDO CAMPO S",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8802,
    localidad: "EL BORDO",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8803,
    localidad: "EL BORDO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8804,
    localidad: "EL BORDO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8805,
    localidad: "EL BORDO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8806,
    localidad: "EL BAÑADO",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8807,
    localidad: "EL AZOTADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8808,
    localidad: "EL ARENAL",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 8809,
    localidad: "EL ARENAL",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8810,
    localidad: "EL ALISAL",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8811,
    localidad: "EL ALGARROBO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8812,
    localidad: "EL ALGARROBAL",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8813,
    localidad: "EL ALFREDITO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8814,
    localidad: "EL ALAMBRADO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8815,
    localidad: "EL AGUAY",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8816,
    localidad: "EL ACHERAL",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 8817,
    localidad: "EL ACHERAL",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8818,
    localidad: "EBRO",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8819,
    localidad: "E EMBARC. FCGB",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8820,
    localidad: "DURAZNO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8821,
    localidad: "DURAZNO",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8822,
    localidad: "DRAGONES",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8823,
    localidad: "DR G SAYAGO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8824,
    localidad: "DR FACUNDO ZUVIRIA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8825,
    localidad: "DR FACUNDO ZUVIRIA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8826,
    localidad: "DOS YUCHANES",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8827,
    localidad: "DOS ARBOLES",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8828,
    localidad: "DOCTOR LUIS AGOTE",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8829,
    localidad: "DIVISADERO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8830,
    localidad: "DIQUE ITIRUYO",
    cp: 4568,
    provincia_id: 17,
  },
  {
    id: 8831,
    localidad: "DIEGO DE ALMAGRO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8832,
    localidad: "DIAMANTE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8833,
    localidad: "DIAMANTE",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8834,
    localidad: "DAMIAN N TORINO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8835,
    localidad: "D EMBALSE C ALEGRE",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8836,
    localidad: "CURUZU",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8837,
    localidad: "CUESTA DEL OBISPO",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8838,
    localidad: "CUESTA CHICA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8839,
    localidad: "CUESTA AZUL",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8840,
    localidad: "CUCHIYACO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8841,
    localidad: "CTRO F CLORINDA",
    cp: 3642,
    provincia_id: 17,
  },
  {
    id: 8842,
    localidad: "CRUZ QUEMADA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8843,
    localidad: "CRUZ BAJADA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8844,
    localidad: "COSME",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8845,
    localidad: "CORZUELA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8846,
    localidad: "CORTADERAS",
    cp: 4195,
    provincia_id: 17,
  },
  {
    id: 8847,
    localidad: "CORRIDA DE CORI",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8848,
    localidad: "CORRALITO",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 8849,
    localidad: "CORRALITO",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8850,
    localidad: "CORRALITO",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8851,
    localidad: "CORRAL QUEMADO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8852,
    localidad: "COROPAMPA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8853,
    localidad: "CORONEL VIDT",
    cp: 4448,
    provincia_id: 17,
  },
  {
    id: 8854,
    localidad: "CORONEL OLLEROS",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8855,
    localidad: "CORONEL MOLLINEDO",
    cp: 4451,
    provincia_id: 17,
  },
  {
    id: 8856,
    localidad: "CORONEL MOLDES",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8857,
    localidad: "CORONEL JUAN SOLA",
    cp: 4538,
    provincia_id: 17,
  },
  {
    id: 8858,
    localidad: "CORONEL CORNEJO",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8859,
    localidad: "CORONEL CORNEJO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 8860,
    localidad: "CORANZULLI",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8861,
    localidad: "COPO QUILE",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8862,
    localidad: "CONDOR",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8863,
    localidad: "CONDADO",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8864,
    localidad: "CONCHAS",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8865,
    localidad: "COLTE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8866,
    localidad: "COLORADO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8867,
    localidad: "COLONIA SANTA ROSA",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 8868,
    localidad: "COLONIA OTOMANA",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8869,
    localidad: "COLONIA HURLINGHAM",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8870,
    localidad: "COLONIA EL FUERTE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8871,
    localidad: "COLONIA D",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8872,
    localidad: "COLONIA BAJO HONDO",
    cp: 3700,
    provincia_id: 17,
  },
  {
    id: 8873,
    localidad: "COLONIA A",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8874,
    localidad: "COLON",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8875,
    localidad: "COLOME",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8876,
    localidad: "COLMENAR",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8877,
    localidad: "COLGADAS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8878,
    localidad: "COLALAO DEL VALLE",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8879,
    localidad: "COBOS",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8880,
    localidad: "COBA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8881,
    localidad: "CNEL M MEZ DE HOZ",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8882,
    localidad: "CNEL JUAN SOLA EST MORILLO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8883,
    localidad: "CIERVO CANSADO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8884,
    localidad: "CHUCULAQUI",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8885,
    localidad: "CHORROARIN",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8886,
    localidad: "CHORRO BLANCO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8887,
    localidad: "CHORRILLOS",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8888,
    localidad: "CHORRILLITOS",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8889,
    localidad: "CHOCOITE",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8890,
    localidad: "CHIYAYOC",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8891,
    localidad: "CHIVILME",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8892,
    localidad: "CHIRETE",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8893,
    localidad: "CHILCAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8894,
    localidad: "CHICOANA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8895,
    localidad: "CHICOANA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8896,
    localidad: "CHAQAR MUYO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8897,
    localidad: "CHAQAR",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8898,
    localidad: "CHAÑAR POZO",
    cp: 4197,
    provincia_id: 17,
  },
  {
    id: 8899,
    localidad: "CHAÑAR AGUADA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8900,
    localidad: "CHAMICAL",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8901,
    localidad: "CHAGUARAL",
    cp: 4537,
    provincia_id: 17,
  },
  {
    id: 8902,
    localidad: "CHACRA EXP.",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 8903,
    localidad: "CHACHAS",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8904,
    localidad: "CHACHAPOYAS",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8905,
    localidad: "CEVILAR",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8906,
    localidad: "CERRO NEGRO",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 8907,
    localidad: "CERRO NEGRO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8908,
    localidad: "CERRO MAL CANTO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8909,
    localidad: "CERRO COLORADO",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8910,
    localidad: "CERRO BRAVO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8911,
    localidad: "CERRO BAYO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 8912,
    localidad: "CERRO ALEMANIA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8913,
    localidad: "CERRITO",
    cp: 4653,
    provincia_id: 17,
  },
  {
    id: 8914,
    localidad: "CERRILLOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8915,
    localidad: "CEIBALITO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 8916,
    localidad: "CEIBAL",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 8917,
    localidad: "CEBADOS",
    cp: 4403,
    provincia_id: 17,
  },
  {
    id: 8918,
    localidad: "CAUCHARI",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8919,
    localidad: "CATUA",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8920,
    localidad: "CASTI",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 8921,
    localidad: "CASTELLANOS",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8922,
    localidad: "CASAS VIEJAS",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8923,
    localidad: "CASA GRANDE",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8924,
    localidad: "CASA GRANDE",
    cp: 4634,
    provincia_id: 17,
  },
  {
    id: 8925,
    localidad: "CARRERAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8926,
    localidad: "CARAYOC",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8927,
    localidad: "CARAPARI",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8928,
    localidad: "CARAHUASI",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8929,
    localidad: "CARACARA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8930,
    localidad: "CARABAJAL",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8931,
    localidad: "CAPITAN PAGES",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8932,
    localidad: "CAPITAN JUAN PAGE",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8933,
    localidad: "CAPILLA DE SITON",
    cp: 5231,
    provincia_id: 17,
  },
  {
    id: 8934,
    localidad: "CAPIAZUTTI",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8935,
    localidad: "CANTERA DEL SAUCE",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8936,
    localidad: "CANGREJOS",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8937,
    localidad: "CANGREJILLOS",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8938,
    localidad: "CANGREJILLOS",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 8939,
    localidad: "CANCILLAR",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 8940,
    localidad: "CAMPO SANTO",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8941,
    localidad: "CAMPO QUIJANO",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 8942,
    localidad: "CAMPO LIBRE",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8943,
    localidad: "CAMPO LARGO",
    cp: 4554,
    provincia_id: 17,
  },
  {
    id: 8944,
    localidad: "CAMPO GRANDE",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8945,
    localidad: "CAMPO DURAN",
    cp: 4566,
    provincia_id: 17,
  },
  {
    id: 8946,
    localidad: "CAMPO CASEROS",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8947,
    localidad: "CAMPO AZUL",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8948,
    localidad: "CAMPO ARGENTINO",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8949,
    localidad: "CAMPO ALEGRE",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8950,
    localidad: "CAMPO ALEGRE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 8951,
    localidad: "CAMPO ALEGRE",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 8952,
    localidad: "CAMPICHUELO",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 8953,
    localidad: "CAMPAMENTO VESPUCIO",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8954,
    localidad: "CAMP VESPUCIO",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8955,
    localidad: "CAMP TABLILLA",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8956,
    localidad: "CAMINERA SAN PEDRITO",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8957,
    localidad: "CAMINERA S PEDRITO",
    cp: 4563,
    provincia_id: 17,
  },
  {
    id: 8958,
    localidad: "CAMARA",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 8959,
    localidad: "CAMARA",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 8960,
    localidad: "CALVIMONTE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8961,
    localidad: "CALIMONTE",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 8962,
    localidad: "CALDERILLA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8963,
    localidad: "CALDERA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 8964,
    localidad: "CAJAS",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 8965,
    localidad: "CAIPE",
    cp: 4413,
    provincia_id: 17,
  },
  {
    id: 8966,
    localidad: "CAFAYATE",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8967,
    localidad: "CACHO MOLINO",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8968,
    localidad: "CACHI ADENTRO",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8969,
    localidad: "CACHI",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 8970,
    localidad: "CABRA CORRAL DIQUE",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8971,
    localidad: "CABEZA DE BUEY",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 8972,
    localidad: "CABEZA DE ANTA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8973,
    localidad: "C. DE LAS JUNTAS",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 8974,
    localidad: "C ZANJA DEL TIGRE",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 8975,
    localidad: "C SANTA MARIA",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8976,
    localidad: "C SANTA CATALINA",
    cp: 4142,
    provincia_id: 17,
  },
  {
    id: 8977,
    localidad: "C SAAVEDRA LAMAS",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8978,
    localidad: "C S ROSA DE LIMA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8979,
    localidad: "C DE LAS PIEDRAS",
    cp: 5236,
    provincia_id: 17,
  },
  {
    id: 8980,
    localidad: "C DE LA Z VIEJA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8981,
    localidad: "C D A DE LA FALDA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8982,
    localidad: "C BUENAVENTURA",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8983,
    localidad: "C AGRI S AGUSTIN",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 8984,
    localidad: "BURRO YACO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8985,
    localidad: "BUENA VISTA",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 8986,
    localidad: "BUENA VISTA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 8987,
    localidad: "BUENA VISTA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 8988,
    localidad: "BUENA FE",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 8989,
    localidad: "BUEN LUGAR",
    cp: 4550,
    provincia_id: 17,
  },
  {
    id: 8990,
    localidad: "BREALITO",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 8991,
    localidad: "BOTIJA",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 8992,
    localidad: "BORDE DE S MIGUEL",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8993,
    localidad: "BOLSA DE PALOMO",
    cp: 3636,
    provincia_id: 17,
  },
  {
    id: 8994,
    localidad: "BODEGUITA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 8995,
    localidad: "BETANIA",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 8996,
    localidad: "BELLA VISTA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 8997,
    localidad: "BELLA VISTA",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 8998,
    localidad: "BELLA VISTA",
    cp: 4423,
    provincia_id: 17,
  },
  {
    id: 8999,
    localidad: "BELLA VISTA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 9000,
    localidad: "BELGRANO FORTIN 2",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 9001,
    localidad: "BELGRANO",
    cp: 4400,
    provincia_id: 17,
  },
  {
    id: 9002,
    localidad: "BARRO NEGRO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 9003,
    localidad: "BARRIO SAN ANTONIO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 9004,
    localidad: "BARRIO S CAYETANO",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 9005,
    localidad: "BARRIO PARABOLICA",
    cp: 4417,
    provincia_id: 17,
  },
  {
    id: 9006,
    localidad: "BARRIO LA UNION",
    cp: 4648,
    provincia_id: 17,
  },
  {
    id: 9007,
    localidad: "BARRIO LA LOMA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 9008,
    localidad: "BARRIAL",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 9009,
    localidad: "BARREALITO",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 9010,
    localidad: "BARITU",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 9011,
    localidad: "BARBAYASCO",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 9012,
    localidad: "BANDA GRANDE",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 9013,
    localidad: "BALLENAL",
    cp: 4405,
    provincia_id: 17,
  },
  {
    id: 9014,
    localidad: "BALDERRAMA",
    cp: 4440,
    provincia_id: 17,
  },
  {
    id: 9015,
    localidad: "BALBUENA",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 9016,
    localidad: "BALBOA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 9017,
    localidad: "BAJO GRANDE",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 9018,
    localidad: "BAJADA GRANDE",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 9019,
    localidad: "BAJADA DE CAVI",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 9020,
    localidad: "BAJADA BLANCA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 9021,
    localidad: "BAJADA",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 9022,
    localidad: "BAGUAL MUERTO",
    cp: 4197,
    provincia_id: 17,
  },
  {
    id: 9023,
    localidad: "BACOYA",
    cp: 4644,
    provincia_id: 17,
  },
  {
    id: 9024,
    localidad: "B LA PROVIDENCIA",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 9025,
    localidad: "B GRAL J DE S M",
    cp: 3698,
    provincia_id: 17,
  },
  {
    id: 9026,
    localidad: "B G DESVIO FCGB",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 9027,
    localidad: "B ALTO LA VIQA",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 9028,
    localidad: "AZUL CUESTA",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 9029,
    localidad: "ATUDILLO",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 9030,
    localidad: "ATOCHA",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 9031,
    localidad: "ATALAYA",
    cp: 4600,
    provincia_id: 17,
  },
  {
    id: 9032,
    localidad: "ASTILLERO",
    cp: 4633,
    provincia_id: 17,
  },
  {
    id: 9033,
    localidad: "ARROYO COLORADO",
    cp: 4500,
    provincia_id: 17,
  },
  {
    id: 9034,
    localidad: "ARROCERO ITALIANO",
    cp: 4444,
    provincia_id: 17,
  },
  {
    id: 9035,
    localidad: "ARJUNTAS",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 9036,
    localidad: "ARITA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 9037,
    localidad: "ARENALES",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 9038,
    localidad: "ARENAL",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 9039,
    localidad: "ARENAL",
    cp: 4198,
    provincia_id: 17,
  },
  {
    id: 9040,
    localidad: "ARBOL SOLO",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 9041,
    localidad: "APOLINARIO SARAVIA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 9042,
    localidad: "ANTONIO QUIJARRO",
    cp: 4552,
    provincia_id: 17,
  },
  {
    id: 9043,
    localidad: "ANTONIO ALICE",
    cp: 4401,
    provincia_id: 17,
  },
  {
    id: 9044,
    localidad: "ANTILLA",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 9045,
    localidad: "ANTA",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 9046,
    localidad: "ANJUANA",
    cp: 4141,
    provincia_id: 17,
  },
  {
    id: 9047,
    localidad: "ANIMANA",
    cp: 4429,
    provincia_id: 17,
  },
  {
    id: 9048,
    localidad: "ANIMANA",
    cp: 4427,
    provincia_id: 17,
  },
  {
    id: 9049,
    localidad: "ANGOSTURA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 9050,
    localidad: "ANGOSTURA",
    cp: 4560,
    provincia_id: 17,
  },
  {
    id: 9051,
    localidad: "ANGOSTURA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 9052,
    localidad: "ANGELICA",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 9053,
    localidad: "ANGEL PEREDO",
    cp: 4533,
    provincia_id: 17,
  },
  {
    id: 9054,
    localidad: "ANGASTACO",
    cp: 4426,
    provincia_id: 17,
  },
  {
    id: 9055,
    localidad: "AMPATA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 9056,
    localidad: "AMPASCACHI",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 9057,
    localidad: "AMBLAYO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 9058,
    localidad: "AMBERES",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 9059,
    localidad: "AMAICHA",
    cp: 4419,
    provincia_id: 17,
  },
  {
    id: 9060,
    localidad: "ALTOS H. GUEMES",
    cp: 4432,
    provincia_id: 17,
  },
  {
    id: 9061,
    localidad: "ALTO VERDE",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 9062,
    localidad: "ALTO DEL MISTOL",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 9063,
    localidad: "ALTO DE LA SIERRA",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 9064,
    localidad: "ALTO ALEGRE",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 9065,
    localidad: "ALMONA",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 9066,
    localidad: "ALMIRANTE BROWN",
    cp: 4193,
    provincia_id: 17,
  },
  {
    id: 9067,
    localidad: "ALGARROBAL VIEJO",
    cp: 4446,
    provincia_id: 17,
  },
  {
    id: 9068,
    localidad: "ALGARROBAL",
    cp: 4534,
    provincia_id: 17,
  },
  {
    id: 9069,
    localidad: "ALGARROBAL",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 9070,
    localidad: "ALGARROBAL",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 9071,
    localidad: "ALGARROBAL",
    cp: 4452,
    provincia_id: 17,
  },
  {
    id: 9072,
    localidad: "ALFARCITO",
    cp: 4409,
    provincia_id: 17,
  },
  {
    id: 9073,
    localidad: "ALEMANIA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 9074,
    localidad: "ALEM",
    cp: 4126,
    provincia_id: 17,
  },
  {
    id: 9075,
    localidad: "ALEJO DE ALBERRO",
    cp: 4407,
    provincia_id: 17,
  },
  {
    id: 9076,
    localidad: "AHI VEREMOS",
    cp: 4197,
    provincia_id: 17,
  },
  {
    id: 9077,
    localidad: "AGUAS MUERTAS",
    cp: 4535,
    provincia_id: 17,
  },
  {
    id: 9078,
    localidad: "AGUAS CALIENTES",
    cp: 4430,
    provincia_id: 17,
  },
  {
    id: 9079,
    localidad: "AGUAS CALIENTES",
    cp: 4190,
    provincia_id: 17,
  },
  {
    id: 9080,
    localidad: "AGUAS BLANCAS",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 9081,
    localidad: "AGUAS BLANCAS",
    cp: 4531,
    provincia_id: 17,
  },
  {
    id: 9082,
    localidad: "AGUARAY",
    cp: 4566,
    provincia_id: 17,
  },
  {
    id: 9083,
    localidad: "AGUA VERDE",
    cp: 4561,
    provincia_id: 17,
  },
  {
    id: 9084,
    localidad: "AGUA SUCIA",
    cp: 4449,
    provincia_id: 17,
  },
  {
    id: 9085,
    localidad: "AGUA NEGRA",
    cp: 4415,
    provincia_id: 17,
  },
  {
    id: 9086,
    localidad: "AGUA CALIENTE",
    cp: 4431,
    provincia_id: 17,
  },
  {
    id: 9087,
    localidad: "ACOYTE",
    cp: 4651,
    provincia_id: 17,
  },
  {
    id: 9088,
    localidad: "ACOSTA",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 9089,
    localidad: "ACHARAS",
    cp: 4434,
    provincia_id: 17,
  },
  {
    id: 9090,
    localidad: "ACAZOQUE",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 9091,
    localidad: "ACAMBUCO",
    cp: 4568,
    provincia_id: 17,
  },
  {
    id: 9092,
    localidad: "ABRA GRANDE",
    cp: 4530,
    provincia_id: 17,
  },
  {
    id: 9093,
    localidad: "ABRA DEL GALLO",
    cp: 4411,
    provincia_id: 17,
  },
  {
    id: 9094,
    localidad: "ABRA DE PEQAS",
    cp: 4650,
    provincia_id: 17,
  },
  {
    id: 9095,
    localidad: "ABLOME",
    cp: 4421,
    provincia_id: 17,
  },
  {
    id: 9096,
    localidad: "20 DE FEBRERO",
    cp: 4425,
    provincia_id: 17,
  },
  {
    id: 9097,
    localidad: "YUQUINCHE",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9098,
    localidad: "VILLA TURISMO",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9099,
    localidad: "VILLA REGINA",
    cp: 8336,
    provincia_id: 16,
  },
  {
    id: 9100,
    localidad: "VILLA MASCARDI",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9101,
    localidad: "VILLA MANZANO",
    cp: 8308,
    provincia_id: 16,
  },
  {
    id: 9102,
    localidad: "VILLA LLANQUIN",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9103,
    localidad: "VIEDMA",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9104,
    localidad: "VALLE AZUL",
    cp: 8336,
    provincia_id: 16,
  },
  {
    id: 9105,
    localidad: "VALCHETA",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9106,
    localidad: "VACA LAUQUEN",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9107,
    localidad: "TTE GRAL E FRIAS",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9108,
    localidad: "TROMENIYEU",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9109,
    localidad: "TRES OJOS DE AGUAS",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9110,
    localidad: "TRENETA",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9111,
    localidad: "TRAVESIA CASTRO",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9112,
    localidad: "TERMAS LOS GAUCHOS",
    cp: 8504,
    provincia_id: 16,
  },
  {
    id: 9113,
    localidad: "TENIENTE M E FCGR",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9114,
    localidad: "SIERRA VICTORIA",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9115,
    localidad: "SIERRA PAILEMAN",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9116,
    localidad: "SIERRA GRANDE",
    cp: 8532,
    provincia_id: 16,
  },
  {
    id: 9117,
    localidad: "SIERRA DE LA V",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9118,
    localidad: "SIERRA CUADRADA",
    cp: 9007,
    provincia_id: 16,
  },
  {
    id: 9119,
    localidad: "SIERRA CORRIENTES",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9120,
    localidad: "SIERRA COLORADA",
    cp: 8535,
    provincia_id: 16,
  },
  {
    id: 9121,
    localidad: "SIERRA COLORADA",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9122,
    localidad: "SEGUNDA ANGOSTURA",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9123,
    localidad: "SARGENTO VIDAL",
    cp: 8310,
    provincia_id: 16,
  },
  {
    id: 9124,
    localidad: "SANTA MARIA",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9125,
    localidad: "SANTA ELENA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9126,
    localidad: "SAN SIMON",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9127,
    localidad: "SAN PEDRO",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9128,
    localidad: "SAN LORENZO",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9129,
    localidad: "SAN JUAN",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9130,
    localidad: "SAN JAVIER",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9131,
    localidad: "SAN ANTONIO OESTE",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9132,
    localidad: "SAINUCO",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9133,
    localidad: "SACO VIEJO",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9134,
    localidad: "S O CHICAS Y GDES",
    cp: 9007,
    provincia_id: 16,
  },
  {
    id: 9135,
    localidad: "S NEVADA BUEN PAST",
    cp: 9023,
    provincia_id: 16,
  },
  {
    id: 9136,
    localidad: "RUCU LUAN",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9137,
    localidad: "RIO VILLEGAS",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9138,
    localidad: "RIO CHICO",
    cp: 9000,
    provincia_id: 16,
  },
  {
    id: 9139,
    localidad: "RIO CHICO",
    cp: 8415,
    provincia_id: 16,
  },
  {
    id: 9140,
    localidad: "RINCON GRANDE",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9141,
    localidad: "RINCON DE GASTRE",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9142,
    localidad: "RINCON CHICO",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9143,
    localidad: "RCHORROY ARRIBA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9144,
    localidad: "QUINTA PANAL",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9145,
    localidad: "QUININELIU",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9146,
    localidad: "QUILCA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9147,
    localidad: "QUILA CHANQUIL",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9148,
    localidad: "QUETREQUILE",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9149,
    localidad: "PUNTA DE AGUA",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9150,
    localidad: "PUESTO GAVIÑA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9151,
    localidad: "PUERTO OJO DE AGUA",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9152,
    localidad: "PUERTO BLEST",
    cp: 8411,
    provincia_id: 16,
  },
  {
    id: 9153,
    localidad: "PUERTO ANCHORENA",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9154,
    localidad: "PRAHUANIYEU",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9155,
    localidad: "POZO SALADO",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9156,
    localidad: "POZO MORO",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9157,
    localidad: "POMONA",
    cp: 8367,
    provincia_id: 16,
  },
  {
    id: 9158,
    localidad: "PLAYA BONITA",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9159,
    localidad: "PILQUI NIYEU",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9160,
    localidad: "PILCANIYEU VIEJO",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9161,
    localidad: "PILCANIYEU",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9162,
    localidad: "PILAHUE",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9163,
    localidad: "PICO SALAMANCA",
    cp: 9000,
    provincia_id: 16,
  },
  {
    id: 9164,
    localidad: "PERITO MORENO",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9165,
    localidad: "PERCY H SCOTT",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9166,
    localidad: "PENINSULA HUEMUL",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9167,
    localidad: "PASO DE TORRES",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9168,
    localidad: "PASO CORDOVA",
    cp: 8333,
    provincia_id: 16,
  },
  {
    id: 9169,
    localidad: "PASO CORDOVA",
    cp: 8330,
    provincia_id: 16,
  },
  {
    id: 9170,
    localidad: "PASO COIHUE",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9171,
    localidad: "PASO CHACABUCO",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9172,
    localidad: "PASO CATA TUN",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9173,
    localidad: "PAMPA SALAMANCA",
    cp: 9000,
    provincia_id: 16,
  },
  {
    id: 9174,
    localidad: "PAMPA PELADA",
    cp: 9007,
    provincia_id: 16,
  },
  {
    id: 9175,
    localidad: "PALENQUE NIYEU",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9176,
    localidad: "PAJALTA",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9177,
    localidad: "PADRE ALEJANDRO STEFANELLI",
    cp: 8335,
    provincia_id: 16,
  },
  {
    id: 9178,
    localidad: "P TIGRE I VICTORIA",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9179,
    localidad: "P MORENO E FCGR",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9180,
    localidad: "P DE LONCO LUAN",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9181,
    localidad: "ONELLI",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9182,
    localidad: "OJO DE AGUA EMBARCADERO FCGB",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9183,
    localidad: "OJO DE AGUA E FCGB",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9184,
    localidad: "ÑORQUINCO",
    cp: 8415,
    provincia_id: 16,
  },
  {
    id: 9185,
    localidad: "ÑIRIHUAO E FCGR",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9186,
    localidad: "NUEVO LEON",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9187,
    localidad: "NUEVA CAROLINA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9188,
    localidad: "NILUAN",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9189,
    localidad: "NENEO RUCA",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9190,
    localidad: "NAHUEL NIYEU",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9191,
    localidad: "NAHUEL HUAPI",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9192,
    localidad: "MUSTERS",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9193,
    localidad: "MULANILLO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9194,
    localidad: "MINISTRO RAMOS MEXIA",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9195,
    localidad: "MICHIHUAO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9196,
    localidad: "MI RAMOS MEXIA",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9197,
    localidad: "MENUCOS",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9198,
    localidad: "MENCUE",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9199,
    localidad: "MATA NEGRA",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9200,
    localidad: "MAQUINCHAO",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9201,
    localidad: "MANCULLIQUE",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9202,
    localidad: "MANCHA BLANCA",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9203,
    localidad: "MANANTIAL GRANDE",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9204,
    localidad: "MAMUEL CHOIQUE",
    cp: 8415,
    provincia_id: 16,
  },
  {
    id: 9205,
    localidad: "MALLIN AHOGADO",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9206,
    localidad: "MAINQUE",
    cp: 8327,
    provincia_id: 16,
  },
  {
    id: 9207,
    localidad: "LUIS M ZAGAGLIA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9208,
    localidad: "LUIS BELTRAN",
    cp: 8361,
    provincia_id: 16,
  },
  {
    id: 9209,
    localidad: "LOS TAMARISCOS",
    cp: 9031,
    provincia_id: 16,
  },
  {
    id: 9210,
    localidad: "LOS REPOLLOS",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9211,
    localidad: "LOS QUEBRACHOS",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9212,
    localidad: "LOS POZOS",
    cp: 8512,
    provincia_id: 16,
  },
  {
    id: 9213,
    localidad: "LOS PIRINEOS",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9214,
    localidad: "LOS MENUCOS",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9215,
    localidad: "LOS MANANTIALES",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9216,
    localidad: "LOS JUNCOS",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9217,
    localidad: "LOS COSTEROS",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9218,
    localidad: "LONCO VACA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9219,
    localidad: "LONCO MULA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9220,
    localidad: "LONCO LUAN",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9221,
    localidad: "LOMA BLANCA",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9222,
    localidad: "LLAO LLAO",
    cp: 8409,
    provincia_id: 16,
  },
  {
    id: 9223,
    localidad: "LITRAN",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9224,
    localidad: "LENZANIYEN",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9225,
    localidad: "LAS TRES LAGUNAS",
    cp: 8347,
    provincia_id: 16,
  },
  {
    id: 9226,
    localidad: "LAS PULGAS",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9227,
    localidad: "LAS MERCEDES",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9228,
    localidad: "LAS MELLIZAS",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9229,
    localidad: "LAS MAQUINAS",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9230,
    localidad: "LAS ISLETAS",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9231,
    localidad: "LAS GRUTAS",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9232,
    localidad: "LAS CORTADERAS",
    cp: 8504,
    provincia_id: 16,
  },
  {
    id: 9233,
    localidad: "LAS BAYAS",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9234,
    localidad: "LAPACHAL",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9235,
    localidad: "LANQUIÑEO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9236,
    localidad: "LAMARQUE",
    cp: 8363,
    provincia_id: 16,
  },
  {
    id: 9237,
    localidad: "LAJA",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9238,
    localidad: "LAGUNITA",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9239,
    localidad: "LAGUNA PALACIO",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9240,
    localidad: "LAGUNA LOS JUNCOS",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9241,
    localidad: "LAGUNA FRIAS",
    cp: 8411,
    provincia_id: 16,
  },
  {
    id: 9242,
    localidad: "LAGUNA FRIA",
    cp: 8411,
    provincia_id: 16,
  },
  {
    id: 9243,
    localidad: "LAGUNA DEL MONTE",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9244,
    localidad: "LAGUNA DEL MATE",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9245,
    localidad: "LAGUNA DEL BARRO",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9246,
    localidad: "LAGUNA CORTES",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9247,
    localidad: "LAGOTERA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9248,
    localidad: "LAGO MUSTERS",
    cp: 9023,
    provincia_id: 16,
  },
  {
    id: 9249,
    localidad: "LAG DE LA PRUEBA",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9250,
    localidad: "LA VENCEDORA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9251,
    localidad: "LA SALAMANCA",
    cp: 9007,
    provincia_id: 16,
  },
  {
    id: 9252,
    localidad: "LA RUBIA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9253,
    localidad: "LA RINCONADA",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9254,
    localidad: "LA QUEBRADA",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9255,
    localidad: "LA PRIMAVERA",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9256,
    localidad: "LA PORTEÑA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9257,
    localidad: "LA PIGMEA",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9258,
    localidad: "LA MIMOSA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9259,
    localidad: "LA MESETA",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9260,
    localidad: "LA LOBERIA",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9261,
    localidad: "LA LIPELA",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9262,
    localidad: "LA LEONA",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9263,
    localidad: "LA JULIA",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9264,
    localidad: "LA GRANJA",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9265,
    localidad: "LA FLECHA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9266,
    localidad: "LA EXCURRA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9267,
    localidad: "LA ESTRELLA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9268,
    localidad: "LA ESTANCIA VIEJA",
    cp: 8324,
    provincia_id: 16,
  },
  {
    id: 9269,
    localidad: "LA ESTACADA",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9270,
    localidad: "LA ESPERANZA",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9271,
    localidad: "LA CRIOLLITA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9272,
    localidad: "LA CHILENA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9273,
    localidad: "LA CAROLINA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9274,
    localidad: "LA BOMBILLA",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9275,
    localidad: "LA BARRETA",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9276,
    localidad: "LA ARBOLEDA",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9277,
    localidad: "LA ARAUCARIA",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9278,
    localidad: "LA ANGOSTURA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9279,
    localidad: "LA A DE ICALMA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9280,
    localidad: "KILOMETRO 191",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9281,
    localidad: "KILOMETRO 11",
    cp: 9000,
    provincia_id: 16,
  },
  {
    id: 9282,
    localidad: "KILI MALAL",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9283,
    localidad: "KILCA CASA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9284,
    localidad: "JUAN DE GARAY",
    cp: 8134,
    provincia_id: 16,
  },
  {
    id: 9285,
    localidad: "JITA RUSIA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9286,
    localidad: "JANINUE",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9287,
    localidad: "JAG?EL CAMPO MONTE",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9288,
    localidad: "ISLA VICTORIA",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9289,
    localidad: "INGENIO S LORENZO",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9290,
    localidad: "INGENIERO JACOBACCI",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9291,
    localidad: "INGENIERO HUERGO",
    cp: 8334,
    provincia_id: 16,
  },
  {
    id: 9292,
    localidad: "ING Z RESTA",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9293,
    localidad: "ING JACOBACCI",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9294,
    localidad: "HUILLILON",
    cp: 8347,
    provincia_id: 16,
  },
  {
    id: 9295,
    localidad: "HUAN LUAN",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9296,
    localidad: "HUA MICHE",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9297,
    localidad: "HARAS PATRIA",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9298,
    localidad: "GENERAL ROCA",
    cp: 8332,
    provincia_id: 16,
  },
  {
    id: 9299,
    localidad: "GENERAL LORENZO VINTTER",
    cp: 8514,
    provincia_id: 16,
  },
  {
    id: 9300,
    localidad: "GENERAL LIBORIO BERNAL",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9301,
    localidad: "GENERAL FRANCISCO ORO",
    cp: 8324,
    provincia_id: 16,
  },
  {
    id: 9302,
    localidad: "GENERAL FERNANDEZ ORO",
    cp: 8325,
    provincia_id: 16,
  },
  {
    id: 9303,
    localidad: "GENERAL ENRIQUE GODOY",
    cp: 8338,
    provincia_id: 16,
  },
  {
    id: 9304,
    localidad: "GENERAL CONESA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9305,
    localidad: "GANZU LAUQUEN",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9306,
    localidad: "G LIBORIO BERNAL",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9307,
    localidad: "FUTA RUIN",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9308,
    localidad: "FORTIN UNO",
    cp: 8360,
    provincia_id: 16,
  },
  {
    id: 9309,
    localidad: "FERRI",
    cp: 8130,
    provincia_id: 16,
  },
  {
    id: 9310,
    localidad: "FARO S BARRANCOSA",
    cp: 8504,
    provincia_id: 16,
  },
  {
    id: 9311,
    localidad: "FALCKNER",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9312,
    localidad: "ESTEBAN A GASCON",
    cp: 8185,
    provincia_id: 16,
  },
  {
    id: 9313,
    localidad: "ESTANCIA NEWBERY",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9314,
    localidad: "ESPINAZO DEL ZORRO",
    cp: 8341,
    provincia_id: 16,
  },
  {
    id: 9315,
    localidad: "ENRIQUE HERMITTE",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9316,
    localidad: "EMPALME KM 648",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9317,
    localidad: "EL SALADO",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9318,
    localidad: "EL PORVENIR",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9319,
    localidad: "EL MOLIGUE",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9320,
    localidad: "EL MANSO",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9321,
    localidad: "EL MAITEN",
    cp: 9210,
    provincia_id: 16,
  },
  {
    id: 9322,
    localidad: "EL JARDINERO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9323,
    localidad: "EL GUADAL",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9324,
    localidad: "EL GAUCHO POBRE",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9325,
    localidad: "EL FOYEL",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9326,
    localidad: "EL DIQUE",
    cp: 8500,
    provincia_id: 16,
  },
  {
    id: 9327,
    localidad: "EL CUY",
    cp: 8329,
    provincia_id: 16,
  },
  {
    id: 9328,
    localidad: "EL CUY",
    cp: 8333,
    provincia_id: 16,
  },
  {
    id: 9329,
    localidad: "EL CONDOR ESTANCIA",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9330,
    localidad: "EL CHEIFUL",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9331,
    localidad: "EL CAMARURO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9332,
    localidad: "EL CAIN",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9333,
    localidad: "EL CACIQUE",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9334,
    localidad: "EL BOLSON",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9335,
    localidad: "EL ATRAVESADO",
    cp: 8347,
    provincia_id: 16,
  },
  {
    id: 9336,
    localidad: "E TEQUEL MALAL",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9337,
    localidad: "DINA HUAPI",
    cp: 8402,
    provincia_id: 16,
  },
  {
    id: 9338,
    localidad: "DINA HUAPI",
    cp: 8406,
    provincia_id: 16,
  },
  {
    id: 9339,
    localidad: "DARWIN",
    cp: 8364,
    provincia_id: 16,
  },
  {
    id: 9340,
    localidad: "DARWIN",
    cp: 8369,
    provincia_id: 16,
  },
  {
    id: 9341,
    localidad: "CURA LAUQUEN",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9342,
    localidad: "CULLIN MANZANO",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9343,
    localidad: "CUCHILLO CURA",
    cp: 8347,
    provincia_id: 16,
  },
  {
    id: 9344,
    localidad: "COSTA RIO CHICO",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9345,
    localidad: "COSTA DEL RIO AZUL",
    cp: 8430,
    provincia_id: 16,
  },
  {
    id: 9346,
    localidad: "CORRALITO",
    cp: 8403,
    provincia_id: 16,
  },
  {
    id: 9347,
    localidad: "CORRAL D LOS PINOS",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9348,
    localidad: "CORONEL J.J.GOMEZ",
    cp: 8333,
    provincia_id: 16,
  },
  {
    id: 9349,
    localidad: "CORONEL FRANCISCO SOSA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9350,
    localidad: "CORONEL F SOSA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9351,
    localidad: "CORONEL EUGENIO DEL BUSTO",
    cp: 8138,
    provincia_id: 16,
  },
  {
    id: 9352,
    localidad: "CORONEL BELISLE",
    cp: 8368,
    provincia_id: 16,
  },
  {
    id: 9353,
    localidad: "COQUELEN",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9354,
    localidad: "CONTRALMIRANTE M GUERRICO",
    cp: 8328,
    provincia_id: 16,
  },
  {
    id: 9355,
    localidad: "CONA NIYEU",
    cp: 8522,
    provincia_id: 16,
  },
  {
    id: 9356,
    localidad: "COMI C",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9357,
    localidad: "COMALLO ABAJO",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9358,
    localidad: "COMALLO",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9359,
    localidad: "COLONIA SAN JUAN",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9360,
    localidad: "COLONIA LA LUISA",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9361,
    localidad: "COLONIA JULIA Y ECHARREN",
    cp: 8139,
    provincia_id: 16,
  },
  {
    id: 9362,
    localidad: "COLONIA GERMANIA",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9363,
    localidad: "COLI TORO",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9364,
    localidad: "CODIHUE",
    cp: 8347,
    provincia_id: 16,
  },
  {
    id: 9365,
    localidad: "CLEMENTE ONELLI",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9366,
    localidad: "CIPOLLETTI",
    cp: 8324,
    provincia_id: 16,
  },
  {
    id: 9367,
    localidad: "CINCO SALTOS",
    cp: 8303,
    provincia_id: 16,
  },
  {
    id: 9368,
    localidad: "CINCO CHAÑARES",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9369,
    localidad: "CHONQUE",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9370,
    localidad: "CHOELE CHOEL",
    cp: 8360,
    provincia_id: 16,
  },
  {
    id: 9371,
    localidad: "CHOCORI",
    cp: 8503,
    provincia_id: 16,
  },
  {
    id: 9372,
    localidad: "CHIPAUQUIL",
    cp: 8536,
    provincia_id: 16,
  },
  {
    id: 9373,
    localidad: "CHINCHINA",
    cp: 8379,
    provincia_id: 16,
  },
  {
    id: 9374,
    localidad: "CHIMPAY",
    cp: 8364,
    provincia_id: 16,
  },
  {
    id: 9375,
    localidad: "CHICHINALES",
    cp: 8337,
    provincia_id: 16,
  },
  {
    id: 9376,
    localidad: "CHICHINALES",
    cp: 8326,
    provincia_id: 16,
  },
  {
    id: 9377,
    localidad: "CHICHIHUAO",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9378,
    localidad: "CHELFORO",
    cp: 8366,
    provincia_id: 16,
  },
  {
    id: 9379,
    localidad: "CHASICO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9380,
    localidad: "CHAIFUL",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9381,
    localidad: "CHACAY HUARRUCA",
    cp: 8415,
    provincia_id: 16,
  },
  {
    id: 9382,
    localidad: "CHACABUCO",
    cp: 8379,
    provincia_id: 16,
  },
  {
    id: 9383,
    localidad: "CERVANTES",
    cp: 8326,
    provincia_id: 16,
  },
  {
    id: 9384,
    localidad: "CERRO MORO",
    cp: 9019,
    provincia_id: 16,
  },
  {
    id: 9385,
    localidad: "CERRO DE POLICIA",
    cp: 8333,
    provincia_id: 16,
  },
  {
    id: 9386,
    localidad: "CERRO DE LOS PINOS",
    cp: 8379,
    provincia_id: 16,
  },
  {
    id: 9387,
    localidad: "CERRO CATEDRAL",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9388,
    localidad: "CERRO ABANICO",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9389,
    localidad: "CASA QUEMADA",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9390,
    localidad: "CARRILAUQUEN",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9391,
    localidad: "CARRI YEGUA",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9392,
    localidad: "CARRI LIL",
    cp: 8345,
    provincia_id: 16,
  },
  {
    id: 9393,
    localidad: "CAÑADON TACHO",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9394,
    localidad: "CAÑADON FERRAIS",
    cp: 9001,
    provincia_id: 16,
  },
  {
    id: 9395,
    localidad: "CAÑADON DEL CORRAL",
    cp: 8412,
    provincia_id: 16,
  },
  {
    id: 9396,
    localidad: "CAÑADON CHILENO",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9397,
    localidad: "CAÑADON CARRIL",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9398,
    localidad: "CAÑADON CAMALLO",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9399,
    localidad: "CANTERA VILLALONGA",
    cp: 8504,
    provincia_id: 16,
  },
  {
    id: 9400,
    localidad: "CANLLEQUIN",
    cp: 8417,
    provincia_id: 16,
  },
  {
    id: 9401,
    localidad: "CALTRAUNA",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9402,
    localidad: "CABEZA DE BUEY",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9403,
    localidad: "C. DE PATAGONES",
    cp: 8504,
    provincia_id: 16,
  },
  {
    id: 9404,
    localidad: "C SAN ENRIQUE",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9405,
    localidad: "C P EX V HERMOSO",
    cp: 9009,
    provincia_id: 16,
  },
  {
    id: 9406,
    localidad: "C LUIS PIEDRABUENA",
    cp: 9303,
    provincia_id: 16,
  },
  {
    id: 9407,
    localidad: "C LOS CANTAROS",
    cp: 8411,
    provincia_id: 16,
  },
  {
    id: 9408,
    localidad: "C LOS ALFALFARES",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9409,
    localidad: "C GENERAL FRIAS",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9410,
    localidad: "BUENA PARADA",
    cp: 8140,
    provincia_id: 16,
  },
  {
    id: 9411,
    localidad: "BENJAMIN ZORRILLA",
    cp: 8360,
    provincia_id: 16,
  },
  {
    id: 9412,
    localidad: "BARRIO LAGUNA",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9413,
    localidad: "BARRIL NIYEO",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9414,
    localidad: "BARILOCHE",
    cp: 8402,
    provincia_id: 16,
  },
  {
    id: 9415,
    localidad: "BARILOCHE",
    cp: 8404,
    provincia_id: 16,
  },
  {
    id: 9416,
    localidad: "BARILOCHE",
    cp: 8400,
    provincia_id: 16,
  },
  {
    id: 9417,
    localidad: "BARILOCHE",
    cp: 8405,
    provincia_id: 16,
  },
  {
    id: 9418,
    localidad: "BALNEARIO LAS GRUTAS",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9419,
    localidad: "BALNEARIO EL CONDOR",
    cp: 8445,
    provincia_id: 16,
  },
  {
    id: 9420,
    localidad: "BALNEARIO EL CONDOR",
    cp: 8501,
    provincia_id: 16,
  },
  {
    id: 9421,
    localidad: "BALNEARIO CHAPALCO",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9422,
    localidad: "BAJO LA CANCHA",
    cp: 9031,
    provincia_id: 16,
  },
  {
    id: 9423,
    localidad: "BAJO DEL GUALICHO",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9424,
    localidad: "B SAN ANTONIO",
    cp: 8132,
    provincia_id: 16,
  },
  {
    id: 9425,
    localidad: "B LAS GRUTAS",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9426,
    localidad: "B EL CONDOR",
    cp: 8445,
    provincia_id: 16,
  },
  {
    id: 9427,
    localidad: "ATRAICO",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9428,
    localidad: "ARROYO VERDE",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9429,
    localidad: "ARROYO TEMBRADO",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9430,
    localidad: "ARROYO QUILLA",
    cp: 9020,
    provincia_id: 16,
  },
  {
    id: 9431,
    localidad: "ARROYO LOS BERROS",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9432,
    localidad: "ARROYO LA VENTANA",
    cp: 8521,
    provincia_id: 16,
  },
  {
    id: 9433,
    localidad: "ARROYO GATO",
    cp: 9033,
    provincia_id: 16,
  },
  {
    id: 9434,
    localidad: "ARROYO CHACAY",
    cp: 8401,
    provincia_id: 16,
  },
  {
    id: 9435,
    localidad: "ANECON GRANDE",
    cp: 8416,
    provincia_id: 16,
  },
  {
    id: 9436,
    localidad: "ANECON CHICO",
    cp: 8418,
    provincia_id: 16,
  },
  {
    id: 9437,
    localidad: "ALLEN",
    cp: 8328,
    provincia_id: 16,
  },
  {
    id: 9438,
    localidad: "AGUADA GUZMAN",
    cp: 8333,
    provincia_id: 16,
  },
  {
    id: 9439,
    localidad: "AGUADA ESCONDIDA",
    cp: 9019,
    provincia_id: 16,
  },
  {
    id: 9440,
    localidad: "AGUADA DEL LORO",
    cp: 8520,
    provincia_id: 16,
  },
  {
    id: 9441,
    localidad: "AGUADA DE PIEDRA",
    cp: 8422,
    provincia_id: 16,
  },
  {
    id: 9442,
    localidad: "AGUADA DE GUERRA",
    cp: 8424,
    provincia_id: 16,
  },
  {
    id: 9443,
    localidad: "AGUADA CECILIO",
    cp: 8534,
    provincia_id: 16,
  },
  {
    id: 9444,
    localidad: "ZULEMITA",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9445,
    localidad: "ZINGONE Y CIA M",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9446,
    localidad: "ZAPALA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9447,
    localidad: "VISTA ALEGRE SUR",
    cp: 8317,
    provincia_id: 15,
  },
  {
    id: 9448,
    localidad: "VISTA ALEGRE NORTE",
    cp: 8314,
    provincia_id: 15,
  },
  {
    id: 9449,
    localidad: "VILLA TRAFUL",
    cp: 8408,
    provincia_id: 15,
  },
  {
    id: 9450,
    localidad: "VILLA RAUR",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9451,
    localidad: "VILLA PEHUENIA",
    cp: 8345,
    provincia_id: 15,
  },
  {
    id: 9452,
    localidad: "VILLA MALLIN",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9453,
    localidad: "VILLA LOS COLIHUES",
    cp: 8405,
    provincia_id: 15,
  },
  {
    id: 9454,
    localidad: "VILLA LA ANGOSTURA",
    cp: 8407,
    provincia_id: 15,
  },
  {
    id: 9455,
    localidad: "VILLA EL CHOCON",
    cp: 8311,
    provincia_id: 15,
  },
  {
    id: 9456,
    localidad: "VILLA ALBERDI",
    cp: 8336,
    provincia_id: 15,
  },
  {
    id: 9457,
    localidad: "VARVARCO",
    cp: 8359,
    provincia_id: 15,
  },
  {
    id: 9458,
    localidad: "VACA MUERTA",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9459,
    localidad: "TUNQUELEN",
    cp: 8409,
    provincia_id: 15,
  },
  {
    id: 9460,
    localidad: "TROMPUL",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9461,
    localidad: "TROMEN",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9462,
    localidad: "TRILI",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9463,
    localidad: "TRICAO MALAL",
    cp: 8358,
    provincia_id: 15,
  },
  {
    id: 9464,
    localidad: "TRICACO",
    cp: 8332,
    provincia_id: 15,
  },
  {
    id: 9465,
    localidad: "TRES PIEDRAS",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9466,
    localidad: "TRES PICOS",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9467,
    localidad: "TRES CHORROS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9468,
    localidad: "TRALATUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9469,
    localidad: "TRAHUNCURA",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9470,
    localidad: "TRAFUL",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9471,
    localidad: "TIPILIUKE",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9472,
    localidad: "TIHUE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9473,
    localidad: "TIERRAS BLANCAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9474,
    localidad: "TERCERA ZONA",
    cp: 8336,
    provincia_id: 15,
  },
  {
    id: 9475,
    localidad: "TAQUIMILLAN ABAJO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9476,
    localidad: "TAQUIMILAN",
    cp: 8342,
    provincia_id: 15,
  },
  {
    id: 9477,
    localidad: "TAQUI NILEU",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9478,
    localidad: "SENILLOSA",
    cp: 8320,
    provincia_id: 15,
  },
  {
    id: 9479,
    localidad: "SENILLOSA",
    cp: 8316,
    provincia_id: 15,
  },
  {
    id: 9480,
    localidad: "SAUCE BLANCO",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9481,
    localidad: "SAÑICO",
    cp: 8315,
    provincia_id: 15,
  },
  {
    id: 9482,
    localidad: "SANTO TOMAS",
    cp: 8315,
    provincia_id: 15,
  },
  {
    id: 9483,
    localidad: "SANTA NICOLASA",
    cp: 8364,
    provincia_id: 15,
  },
  {
    id: 9484,
    localidad: "SANTA ISABEL",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9485,
    localidad: "SANTA ISABEL",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9486,
    localidad: "SANTA GREGORIA",
    cp: 8364,
    provincia_id: 15,
  },
  {
    id: 9487,
    localidad: "SANTA GENOVEVA",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9488,
    localidad: "SAN RAMON",
    cp: 8416,
    provincia_id: 15,
  },
  {
    id: 9489,
    localidad: "SAN PATRICIO DEL CHAÑAR",
    cp: 8306,
    provincia_id: 15,
  },
  {
    id: 9490,
    localidad: "SAN MARTIN DE LOS ANDES",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9491,
    localidad: "SAN JUAN RAHUE",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9492,
    localidad: "SAN IGNACIO",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9493,
    localidad: "SAN EDUARDO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9494,
    localidad: "SAN DEMETRIO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9495,
    localidad: "SALQUICO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9496,
    localidad: "SALITRAL NEGRO",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9497,
    localidad: "S M DE LOS ANDES",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9498,
    localidad: "S J J DE LOS ANDES",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9499,
    localidad: "RIO BARRANCAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9500,
    localidad: "RIO AGRIO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9501,
    localidad: "RINCONADA",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9502,
    localidad: "RINCONADA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9503,
    localidad: "RINCON DE LOS SAUCES",
    cp: 8319,
    provincia_id: 15,
  },
  {
    id: 9504,
    localidad: "RICHOIQUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9505,
    localidad: "REQILEO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9506,
    localidad: "REPOLLOS",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9507,
    localidad: "RAYHUAO",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9508,
    localidad: "RANQUILON",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9509,
    localidad: "RANQUILCO",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9510,
    localidad: "RANQUIL VEGA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9511,
    localidad: "RANQUELES",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9512,
    localidad: "RAMICHAL",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9513,
    localidad: "RAHUE",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9514,
    localidad: "QUITA QUINA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9515,
    localidad: "QUINTUCO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9516,
    localidad: "QUINQUIMITREO",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9517,
    localidad: "QUILQUIHUE",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9518,
    localidad: "QUILLEN",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9519,
    localidad: "QUILI MALAL",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9520,
    localidad: "QUILA QUINA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9521,
    localidad: "QUILA QUEHUE",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9522,
    localidad: "QUENTRENQUEN",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9523,
    localidad: "QUEMPU NIYEU",
    cp: 8333,
    provincia_id: 15,
  },
  {
    id: 9524,
    localidad: "QUEMPU LEUFU",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9525,
    localidad: "QUEBRADA HONDA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9526,
    localidad: "QORQUIN",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9527,
    localidad: "QIRECO SUD",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9528,
    localidad: "PUESTO HERNANDEZ  BATERIAS",
    cp: 8319,
    provincia_id: 15,
  },
  {
    id: 9529,
    localidad: "PUESTO FARIA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9530,
    localidad: "PUERTO MANZANO",
    cp: 8407,
    provincia_id: 15,
  },
  {
    id: 9531,
    localidad: "PUENTE PICUN LEUFU",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9532,
    localidad: "PRIMEROS PINOS",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9533,
    localidad: "POZO HUALICHES",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9534,
    localidad: "PORTEZUELO",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9535,
    localidad: "PLOTTIER",
    cp: 8316,
    provincia_id: 15,
  },
  {
    id: 9536,
    localidad: "PLAZA HUINCUL",
    cp: 8318,
    provincia_id: 15,
  },
  {
    id: 9537,
    localidad: "PINO SOLO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9538,
    localidad: "PINO HACHADO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9539,
    localidad: "PILO LIL",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9540,
    localidad: "PILMATUE",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9541,
    localidad: "PIEDRAS BAYAS",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9542,
    localidad: "PIEDRA MALA",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9543,
    localidad: "PIEDRA DEL AGUILA",
    cp: 8315,
    provincia_id: 15,
  },
  {
    id: 9544,
    localidad: "PICUN LEUFU",
    cp: 8313,
    provincia_id: 15,
  },
  {
    id: 9545,
    localidad: "PICHI NEUQUEN",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9546,
    localidad: "PICHI LEUFU ARRIBA",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9547,
    localidad: "PICHI LEUFU ABAJO",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9548,
    localidad: "PICHI LEUFU",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9549,
    localidad: "PICHE PONON",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9550,
    localidad: "PICHAIHUE",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9551,
    localidad: "PEQA COLORADA",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9552,
    localidad: "PASO PIEDRA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9553,
    localidad: "PASO MIRANDA",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9554,
    localidad: "PASO LEZCANO",
    cp: 8361,
    provincia_id: 15,
  },
  {
    id: 9555,
    localidad: "PASO FLORES",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9556,
    localidad: "PASO DEL LIMAY",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9557,
    localidad: "PASO DE S IGNACIO",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9558,
    localidad: "PASO DE LOS MOLLES",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9559,
    localidad: "PASO DE LOS INDIOS",
    cp: 8318,
    provincia_id: 15,
  },
  {
    id: 9560,
    localidad: "PASO BARDA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9561,
    localidad: "PASO ANCHO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9562,
    localidad: "PASO AGUERRE",
    cp: 8313,
    provincia_id: 15,
  },
  {
    id: 9563,
    localidad: "PANQUEHUAO",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9564,
    localidad: "PAMPA FERREIRA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9565,
    localidad: "PAMPA DEL SALADO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9566,
    localidad: "PAMPA DEL MALLEO",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9567,
    localidad: "PAMPA DE TRIL",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9568,
    localidad: "PAMPA COLLON CURA",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9569,
    localidad: "PALITUE",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9570,
    localidad: "PALAU",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9571,
    localidad: "PADRE A STEFANELLI",
    cp: 8335,
    provincia_id: 15,
  },
  {
    id: 9572,
    localidad: "P DE JAGUELITO",
    cp: 8333,
    provincia_id: 15,
  },
  {
    id: 9573,
    localidad: "OJO DE AGUA",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9574,
    localidad: "ÑORQUINCO SUD",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9575,
    localidad: "NIRECO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9576,
    localidad: "NEUQUEN",
    cp: 8304,
    provincia_id: 15,
  },
  {
    id: 9577,
    localidad: "NEUQUEN",
    cp: 8300,
    provincia_id: 15,
  },
  {
    id: 9578,
    localidad: "NEUQUEN",
    cp: 8302,
    provincia_id: 15,
  },
  {
    id: 9579,
    localidad: "NEUQUEN",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9580,
    localidad: "NERECO NORTE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9581,
    localidad: "NEGRO MUERTO",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9582,
    localidad: "NAU NAUCO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9583,
    localidad: "NALAY CULLIN",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9584,
    localidad: "NAHUEVE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9585,
    localidad: "NAHUEL MAPE",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9586,
    localidad: "MULICHINCO",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9587,
    localidad: "MOQUEHUE",
    cp: 8345,
    provincia_id: 15,
  },
  {
    id: 9588,
    localidad: "MOQUEHUA",
    cp: 8344,
    provincia_id: 15,
  },
  {
    id: 9589,
    localidad: "MINA LILEO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9590,
    localidad: "MINA CARRASCOSA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9591,
    localidad: "MILLA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9592,
    localidad: "MENUCO VACA MUERTA",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9593,
    localidad: "MELIQUINA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9594,
    localidad: "MAYAN MAHUIDA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9595,
    localidad: "MARIANO MORENO",
    cp: 8352,
    provincia_id: 15,
  },
  {
    id: 9596,
    localidad: "MARIANO MORENO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9597,
    localidad: "MARIA CRISTINA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9598,
    localidad: "MARI MENUCO",
    cp: 8321,
    provincia_id: 15,
  },
  {
    id: 9599,
    localidad: "MANZANO AMARGO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9600,
    localidad: "MAMUL MALAL",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9601,
    localidad: "MALLIN QUEMADO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9602,
    localidad: "MALLIN DEL TORO",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9603,
    localidad: "MALLIN DEL RUBIO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9604,
    localidad: "MALLIN DE MENA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9605,
    localidad: "MALLIN DE LA CUEVA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9606,
    localidad: "MALLIN CHILENO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9607,
    localidad: "MALLIN BLANCO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9608,
    localidad: "MALAICO",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9609,
    localidad: "MACHICO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9610,
    localidad: "M DE LAS YEGUAS",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9611,
    localidad: "LUIN COCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9612,
    localidad: "LUICOCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9613,
    localidad: "LUBECA",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9614,
    localidad: "LOS TRES CHORROS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9615,
    localidad: "LOS RODILLOS",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9616,
    localidad: "LOS MOLLES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9617,
    localidad: "LOS MOLINOS",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9618,
    localidad: "LOS MICHES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9619,
    localidad: "LOS MENUCOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9620,
    localidad: "LOS GALPONES",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9621,
    localidad: "LOS ENTIERROS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9622,
    localidad: "LOS CISNES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9623,
    localidad: "LOS CHACALES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9624,
    localidad: "LOS CATUTOS",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9625,
    localidad: "LOS CARRIZOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9626,
    localidad: "LOS BOLILLOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9627,
    localidad: "LONCOPUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9628,
    localidad: "LOG LOG",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9629,
    localidad: "LLAMUCO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9630,
    localidad: "LIU CULLIN",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9631,
    localidad: "LIMAY CENTRO",
    cp: 8313,
    provincia_id: 15,
  },
  {
    id: 9632,
    localidad: "LILEO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9633,
    localidad: "LEUTO CABALLO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9634,
    localidad: "LASCAR",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9635,
    localidad: "LAS TOSCAS",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9636,
    localidad: "LAS SALADAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9637,
    localidad: "LAS OVEJAS",
    cp: 8355,
    provincia_id: 15,
  },
  {
    id: 9638,
    localidad: "LAS MERCEDES",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9639,
    localidad: "LAS LAJITAS",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9640,
    localidad: "LAS LAJAS",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9641,
    localidad: "LAS LAGUNAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9642,
    localidad: "LAS CORTADERAS",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9643,
    localidad: "LAS CORTADERAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9644,
    localidad: "LAS COLORADAS",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9645,
    localidad: "LAS CHACRAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9646,
    localidad: "LAS BARDITAS",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9647,
    localidad: "LAS BANDURRIAS",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9648,
    localidad: "LAS ABEJAS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9649,
    localidad: "LAPA",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9650,
    localidad: "LAGUNA BLANCA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9651,
    localidad: "LAGO LOG LOG",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9652,
    localidad: "LA VERDAD",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9653,
    localidad: "LA UNION",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9654,
    localidad: "LA TERESA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9655,
    localidad: "LA SUSANA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9656,
    localidad: "LA SARA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9657,
    localidad: "LA SALADA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9658,
    localidad: "LA RINCONADA",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9659,
    localidad: "LA PRIMAVERA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9660,
    localidad: "LA PORTEQA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9661,
    localidad: "LA PATRIA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9662,
    localidad: "LA PATAGONIA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9663,
    localidad: "LA OFELIA",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9664,
    localidad: "LA NEGRA",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9665,
    localidad: "LA JULIA",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9666,
    localidad: "LA JAPONESA",
    cp: 8336,
    provincia_id: 15,
  },
  {
    id: 9667,
    localidad: "LA FRIA",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9668,
    localidad: "LA FORTUNA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9669,
    localidad: "LA ELVIRA",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9670,
    localidad: "LA CIENEGUITA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9671,
    localidad: "LA CIENAGA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9672,
    localidad: "LA BUITRERA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9673,
    localidad: "LA ATALAYA",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9674,
    localidad: "LA ARGENTINA",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9675,
    localidad: "JUNIN DE LOS ANDES",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9676,
    localidad: "JUARANCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9677,
    localidad: "JECANASCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9678,
    localidad: "ISLA GRANDE",
    cp: 8361,
    provincia_id: 15,
  },
  {
    id: 9679,
    localidad: "ISLA CHICA",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9680,
    localidad: "INVERNADA VIEJA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9681,
    localidad: "HUNCAL",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9682,
    localidad: "HUMIGAMIO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9683,
    localidad: "HUITRIN",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9684,
    localidad: "HUINGANCO",
    cp: 8365,
    provincia_id: 15,
  },
  {
    id: 9685,
    localidad: "HUINCA LU",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9686,
    localidad: "HUECHULAFQUEN",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9687,
    localidad: "HUECHAHUE",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9688,
    localidad: "HUARINCHENQUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9689,
    localidad: "HUARACO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9690,
    localidad: "HUALCUPEN",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9691,
    localidad: "HUA HUM",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9692,
    localidad: "HAYCU",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9693,
    localidad: "HAICHOL",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9694,
    localidad: "GUAQACOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9695,
    localidad: "GUAÑACOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9696,
    localidad: "GOQI CO",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9697,
    localidad: "GENTE GRANDE",
    cp: 8379,
    provincia_id: 15,
  },
  {
    id: 9698,
    localidad: "G ENRIQUE GODOY",
    cp: 8338,
    provincia_id: 15,
  },
  {
    id: 9699,
    localidad: "FRANHUCURA",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9700,
    localidad: "FORTIN GUAQACOS",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9701,
    localidad: "FORTIN 1 DE MAYO",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9702,
    localidad: "FLORES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9703,
    localidad: "FITATIMEN",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9704,
    localidad: "FITAMICHE",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9705,
    localidad: "FITALANCAO",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9706,
    localidad: "FILO HUA HUM",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9707,
    localidad: "FILMATUE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9708,
    localidad: "EL TROMEN",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9709,
    localidad: "EL SAUCE",
    cp: 8313,
    provincia_id: 15,
  },
  {
    id: 9710,
    localidad: "EL SALITRAL",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9711,
    localidad: "EL SALADO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9712,
    localidad: "EL PORVENIR",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9713,
    localidad: "EL PORTON",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9714,
    localidad: "EL PINO ANDINO",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9715,
    localidad: "EL PERALITO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9716,
    localidad: "EL PANTANOSO",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9717,
    localidad: "EL PALAO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9718,
    localidad: "EL OVERO",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9719,
    localidad: "EL OASIS",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9720,
    localidad: "EL MANZANO",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9721,
    localidad: "EL MANZANO",
    cp: 8326,
    provincia_id: 15,
  },
  {
    id: 9722,
    localidad: "EL MACHETE",
    cp: 8407,
    provincia_id: 15,
  },
  {
    id: 9723,
    localidad: "EL HUECU",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9724,
    localidad: "EL HUECU",
    cp: 8350,
    provincia_id: 15,
  },
  {
    id: 9725,
    localidad: "EL GATO",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9726,
    localidad: "EL ESCORIAL",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9727,
    localidad: "EL DURAZNO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9728,
    localidad: "EL DORMIDO",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9729,
    localidad: "EL CURILEO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9730,
    localidad: "EL CHOLAR",
    cp: 8362,
    provincia_id: 15,
  },
  {
    id: 9731,
    localidad: "EL CHOLAR",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9732,
    localidad: "EL CHOCON",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9733,
    localidad: "EL CHINGUE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9734,
    localidad: "EL CERRITO",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9735,
    localidad: "EL BOSQUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9736,
    localidad: "EL ARBOLITO",
    cp: 8407,
    provincia_id: 15,
  },
  {
    id: 9737,
    localidad: "EL ALAMITO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9738,
    localidad: "E LAS JULIAS",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9739,
    localidad: "E LA PRIMAVERA",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9740,
    localidad: "DINA HUAPI",
    cp: 8404,
    provincia_id: 15,
  },
  {
    id: 9741,
    localidad: "CUTRAL CO",
    cp: 8322,
    provincia_id: 15,
  },
  {
    id: 9742,
    localidad: "CURU  LEUVU",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9743,
    localidad: "COYUCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9744,
    localidad: "COVUNCO CENTRO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9745,
    localidad: "COVUNCO ABAJO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9746,
    localidad: "COVUNCO",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9747,
    localidad: "COVUNCO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9748,
    localidad: "COSTA DEL A SALADO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9749,
    localidad: "CORRENTOSO",
    cp: 8407,
    provincia_id: 15,
  },
  {
    id: 9750,
    localidad: "CORRAL DE PIEDRA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9751,
    localidad: "CORONEL J J GOMEZ",
    cp: 8331,
    provincia_id: 15,
  },
  {
    id: 9752,
    localidad: "COPAHUE",
    cp: 8348,
    provincia_id: 15,
  },
  {
    id: 9753,
    localidad: "CONTRALMIRANTE CORDERO",
    cp: 8301,
    provincia_id: 15,
  },
  {
    id: 9754,
    localidad: "CONTRAL M GUERRICO",
    cp: 8328,
    provincia_id: 15,
  },
  {
    id: 9755,
    localidad: "CONFLU DEL AGUIJON",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9756,
    localidad: "COLONIA VALENTINA",
    cp: 8301,
    provincia_id: 15,
  },
  {
    id: 9757,
    localidad: "COLONIA RUSA",
    cp: 8332,
    provincia_id: 15,
  },
  {
    id: 9758,
    localidad: "COLONIA REGINA",
    cp: 8336,
    provincia_id: 15,
  },
  {
    id: 9759,
    localidad: "COLONIA JOSEFA",
    cp: 8363,
    provincia_id: 15,
  },
  {
    id: 9760,
    localidad: "COLLUN CO",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9761,
    localidad: "COLIPILI",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9762,
    localidad: "COLI MALAL",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9763,
    localidad: "COIHUECO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9764,
    localidad: "COCHICO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9765,
    localidad: "CLEMENTE ONELLI",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9766,
    localidad: "CIPOLETTI",
    cp: 8327,
    provincia_id: 15,
  },
  {
    id: 9767,
    localidad: "CHURRIACA",
    cp: 8343,
    provincia_id: 15,
  },
  {
    id: 9768,
    localidad: "CHURQUIÑEO",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9769,
    localidad: "CHOS MALAL",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9770,
    localidad: "CHOCHOY MALLIN",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9771,
    localidad: "CHIQUILLIHUIN",
    cp: 8371,
    provincia_id: 15,
  },
  {
    id: 9772,
    localidad: "CHIMEHUIN",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9773,
    localidad: "CHICHIGUAY",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9774,
    localidad: "CHENQUENIYEU",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9775,
    localidad: "CHENQUECURA",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9776,
    localidad: "CHARAHUILLA",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9777,
    localidad: "CHAPUA ABAJO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9778,
    localidad: "CHAPUA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9779,
    localidad: "CHAPELCO",
    cp: 8372,
    provincia_id: 15,
  },
  {
    id: 9780,
    localidad: "CHAPELCO",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9781,
    localidad: "CHALLACO",
    cp: 8318,
    provincia_id: 15,
  },
  {
    id: 9782,
    localidad: "CHACRAS DE ALLEN",
    cp: 8328,
    provincia_id: 15,
  },
  {
    id: 9783,
    localidad: "CHACAYCO",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9784,
    localidad: "CHACAYAL",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9785,
    localidad: "CHACAY MELEHUE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9786,
    localidad: "CHACAY CO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9787,
    localidad: "CHACAY",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9788,
    localidad: "CHACALHUA RUCA",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9789,
    localidad: "CERRO NEGRO TRICAO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9790,
    localidad: "CERRO NEGRO CHAPUA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9791,
    localidad: "CERRO MESA",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9792,
    localidad: "CERRO GATO",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9793,
    localidad: "CERRO DE LA PARVA",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9794,
    localidad: "CERRO DE LA GRASA",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9795,
    localidad: "CERRO COLORADO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9796,
    localidad: "CERRO ALTO",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9797,
    localidad: "CENTENARIO",
    cp: 8309,
    provincia_id: 15,
  },
  {
    id: 9798,
    localidad: "CAYANTA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9799,
    localidad: "CAVIAHUE",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9800,
    localidad: "CAVIAHUE",
    cp: 8346,
    provincia_id: 15,
  },
  {
    id: 9801,
    localidad: "CATAN LIL",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9802,
    localidad: "CASA DE PIEDRA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9803,
    localidad: "CARRERI",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9804,
    localidad: "CARHUE",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9805,
    localidad: "CAQADON DEL INDIO",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9806,
    localidad: "CAQADA SECA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9807,
    localidad: "CANCHA HUIGANCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9808,
    localidad: "CAMINERA TRAFUL",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9809,
    localidad: "CAMINERA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9810,
    localidad: "CAMALEONES",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9811,
    localidad: "CALIHUE",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9812,
    localidad: "CALEOFU",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9813,
    localidad: "CAJON GRANDE",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9814,
    localidad: "CAJON DEL TORO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9815,
    localidad: "CAJON DE MANZANO",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9816,
    localidad: "CAJON DE LOS PATOS",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9817,
    localidad: "CAJON DE ALMAZA",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9818,
    localidad: "CAICHIHUE",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9819,
    localidad: "CAEPE MALAL",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9820,
    localidad: "C DEL PICHI LEUFU",
    cp: 8412,
    provincia_id: 15,
  },
  {
    id: 9821,
    localidad: "C DE LOS INDIOS",
    cp: 8375,
    provincia_id: 15,
  },
  {
    id: 9822,
    localidad: "C DE CURI LEUVU",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9823,
    localidad: "BUTALON",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9824,
    localidad: "BUTA RANQUIL",
    cp: 8357,
    provincia_id: 15,
  },
  {
    id: 9825,
    localidad: "BUTA RANQUIL",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9826,
    localidad: "BUTA MALLIN",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9827,
    localidad: "BUTA CO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9828,
    localidad: "BUENA ESPERANZA",
    cp: 8370,
    provincia_id: 15,
  },
  {
    id: 9829,
    localidad: "BELLA VISTA",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9830,
    localidad: "BATRE LAUQUEN",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9831,
    localidad: "BARRIO NORTE",
    cp: 8328,
    provincia_id: 15,
  },
  {
    id: 9832,
    localidad: "BARRANCAS",
    cp: 8356,
    provincia_id: 15,
  },
  {
    id: 9833,
    localidad: "BARDAS NEGRAS",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9834,
    localidad: "BARDA DEL MEDIO",
    cp: 8305,
    provincia_id: 15,
  },
  {
    id: 9835,
    localidad: "BARDA COLORADA",
    cp: 8333,
    provincia_id: 15,
  },
  {
    id: 9836,
    localidad: "BARDA ANGUIL",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9837,
    localidad: "BALSA DL RIO AGRIO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9838,
    localidad: "BAJO RICO",
    cp: 8360,
    provincia_id: 15,
  },
  {
    id: 9839,
    localidad: "BAJADA DEL MARUCHO",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9840,
    localidad: "BAJADA DEL AGRIO",
    cp: 8344,
    provincia_id: 15,
  },
  {
    id: 9841,
    localidad: "B DEL RIO AGRIO",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9842,
    localidad: "AUCA PAN",
    cp: 8373,
    provincia_id: 15,
  },
  {
    id: 9843,
    localidad: "AUCA MAHUIDA",
    cp: 8305,
    provincia_id: 15,
  },
  {
    id: 9844,
    localidad: "ARROYO RANQUILCO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9845,
    localidad: "ARROYO QUILLEN",
    cp: 8341,
    provincia_id: 15,
  },
  {
    id: 9846,
    localidad: "ARROYO LAS MINAS",
    cp: 8415,
    provincia_id: 15,
  },
  {
    id: 9847,
    localidad: "ARROYO CAHUNCO",
    cp: 8347,
    provincia_id: 15,
  },
  {
    id: 9848,
    localidad: "ARROYO BLANCO",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9849,
    localidad: "ARROYO BLANCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9850,
    localidad: "ARROYITO",
    cp: 8313,
    provincia_id: 15,
  },
  {
    id: 9851,
    localidad: "AÑELO",
    cp: 8305,
    provincia_id: 15,
  },
  {
    id: 9852,
    localidad: "AÑELO",
    cp: 8312,
    provincia_id: 15,
  },
  {
    id: 9853,
    localidad: "ANQUINCO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9854,
    localidad: "ANDACOLLO",
    cp: 8354,
    provincia_id: 15,
  },
  {
    id: 9855,
    localidad: "ALUMINE",
    cp: 8345,
    provincia_id: 15,
  },
  {
    id: 9856,
    localidad: "ALICURA",
    cp: 8403,
    provincia_id: 15,
  },
  {
    id: 9857,
    localidad: "ALIANZA",
    cp: 8379,
    provincia_id: 15,
  },
  {
    id: 9858,
    localidad: "AGUAS DE LAS MULAS",
    cp: 8349,
    provincia_id: 15,
  },
  {
    id: 9859,
    localidad: "AGUADA FLORENCIO",
    cp: 8340,
    provincia_id: 15,
  },
  {
    id: 9860,
    localidad: "AGUADA CHACAY CO",
    cp: 8353,
    provincia_id: 15,
  },
  {
    id: 9861,
    localidad: "AGRIO BALSA",
    cp: 8351,
    provincia_id: 15,
  },
  {
    id: 9862,
    localidad: "ACHICO",
    cp: 8315,
    provincia_id: 15,
  },
  {
    id: 9863,
    localidad: "YERBAL MAMBORETA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9864,
    localidad: "YAPEYU CENTRO",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9865,
    localidad: "YACUTINGA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9866,
    localidad: "YABEBIRI",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9867,
    localidad: "WANDA",
    cp: 3376,
    provincia_id: 14,
  },
  {
    id: 9868,
    localidad: "VILLALONGA",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9869,
    localidad: "VILLA VILMA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9870,
    localidad: "VILLA VENECIA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9871,
    localidad: "VILLA UNION",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 9872,
    localidad: "VILLA SVEA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9873,
    localidad: "VILLA SARUBBI",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9874,
    localidad: "VILLA SALTO ENCANTADO",
    cp: 3339,
    provincia_id: 14,
  },
  {
    id: 9875,
    localidad: "VILLA ROULET",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9876,
    localidad: "VILLA OJO DE AGUA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 9877,
    localidad: "VILLA LIBERTAD",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 9878,
    localidad: "VILLA LANUS",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9879,
    localidad: "VILLA JUAN DE VERA",
    cp: 3400,
    provincia_id: 14,
  },
  {
    id: 9880,
    localidad: "VILLA FLOR",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9881,
    localidad: "VILLA EMILIA",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9882,
    localidad: "VILLA EL DORADO",
    cp: 3400,
    provincia_id: 14,
  },
  {
    id: 9883,
    localidad: "VILLA DON BOSCO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9884,
    localidad: "VILLA BONITA",
    cp: 3368,
    provincia_id: 14,
  },
  {
    id: 9885,
    localidad: "VILLA ARMONIA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9886,
    localidad: "TRES ESQUINAS",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9887,
    localidad: "TRES CAPONES",
    cp: 3354,
    provincia_id: 14,
  },
  {
    id: 9888,
    localidad: "TRES CAPONES",
    cp: 3353,
    provincia_id: 14,
  },
  {
    id: 9889,
    localidad: "TRES BOCAS",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9890,
    localidad: "TRATADO DE PAZ",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9891,
    localidad: "TORTA QUEMADA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9892,
    localidad: "TORORO",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9893,
    localidad: "TOBUNAS",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9894,
    localidad: "TIRICA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9895,
    localidad: "TIMBAUBA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9896,
    localidad: "TIGRE",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 9897,
    localidad: "TEYUGUARE",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9898,
    localidad: "TARUMA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 9899,
    localidad: "TACUARUZU",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9900,
    localidad: "TACUARA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9901,
    localidad: "STIAGO DE LINIERS",
    cp: 3387,
    provincia_id: 14,
  },
  {
    id: 9902,
    localidad: "SOL DE MAYO",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9903,
    localidad: "SIERRAS SAN JUAN",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 9904,
    localidad: "SIERRA DE SAN JOSE",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9905,
    localidad: "SIERRA DE ORO",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9906,
    localidad: "SEGUNDA ZONA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9907,
    localidad: "SANTO PIPO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9908,
    localidad: "SANTO PIPO",
    cp: 3326,
    provincia_id: 14,
  },
  {
    id: 9909,
    localidad: "SANTIAGO DE LINIERS",
    cp: 3387,
    provincia_id: 14,
  },
  {
    id: 9910,
    localidad: "SANTA ROSA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9911,
    localidad: "SANTA RITA",
    cp: 3377,
    provincia_id: 14,
  },
  {
    id: 9912,
    localidad: "SANTA MARIA MARTIR",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 9913,
    localidad: "SANTA INES",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9914,
    localidad: "SANTA ANA",
    cp: 3316,
    provincia_id: 14,
  },
  {
    id: 9915,
    localidad: "SAN VICENTE",
    cp: 3356,
    provincia_id: 14,
  },
  {
    id: 9916,
    localidad: "SAN VICENTE",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9917,
    localidad: "SAN SEBASTIAN",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9918,
    localidad: "SAN PEDRO",
    cp: 3352,
    provincia_id: 14,
  },
  {
    id: 9919,
    localidad: "SAN MIGUEL",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9920,
    localidad: "SAN LUCAS",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 9921,
    localidad: "SAN JUAN",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9922,
    localidad: "SAN JOSE",
    cp: 3307,
    provincia_id: 14,
  },
  {
    id: 9923,
    localidad: "SAN JAVIER",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9924,
    localidad: "SAN ISIDRO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 9925,
    localidad: "SAN ISIDRO",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9926,
    localidad: "SAN IGNACIO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9927,
    localidad: "SAN GOTARDO",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9928,
    localidad: "SAN CARLOS",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9929,
    localidad: "SAN BORJITA",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9930,
    localidad: "SAN ANTONIO",
    cp: 3383,
    provincia_id: 14,
  },
  {
    id: 9931,
    localidad: "SAN ANTONIO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 9932,
    localidad: "SAN ANDRES",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9933,
    localidad: "SAN ALBERTO",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9934,
    localidad: "SAMAMBAYA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 9935,
    localidad: "SALTO ENCANTADO",
    cp: 3339,
    provincia_id: 14,
  },
  {
    id: 9936,
    localidad: "S MARIA LA MAYOR",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 9937,
    localidad: "S J DE LA SIERRA",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 9938,
    localidad: "S FCISCO D ASIS",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9939,
    localidad: "RUIZ DE MONTOYA",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9940,
    localidad: "RUINAS DE LORETO",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9941,
    localidad: "ROCA CHICA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9942,
    localidad: "RIO YABOTAY",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9943,
    localidad: "RINCON ITAEMBE",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9944,
    localidad: "RINCON DEL ROSARIO",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9945,
    localidad: "RINCON DE MERCEDES",
    cp: 3351,
    provincia_id: 14,
  },
  {
    id: 9946,
    localidad: "RINCON DE LOPEZ",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9947,
    localidad: "RINCON DE CHIMTRAY",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 9948,
    localidad: "RINCON DE BUGRES",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9949,
    localidad: "RINCON DE BONPLAND",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9950,
    localidad: "R. DEL GUERRERO",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9951,
    localidad: "PUERTO YACUY",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9952,
    localidad: "PUERTO YABEBIRI",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9953,
    localidad: "PUERTO WANDA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9954,
    localidad: "PUERTO VIEJO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9955,
    localidad: "PUERTO VICTORIA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9956,
    localidad: "PUERTO URUGUAY",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9957,
    localidad: "PUERTO TIGRE",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9958,
    localidad: "PUERTO TABAY",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9959,
    localidad: "PUERTO SAN MARTIN",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9960,
    localidad: "PUERTO SAN LUCAS",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 9961,
    localidad: "PUERTO SAN IGNACIO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9962,
    localidad: "PUERTO SAN ALBERTO",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9963,
    localidad: "PUERTO RUBEN",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9964,
    localidad: "PUERTO ROSARIO",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 9965,
    localidad: "PUERTO RICO",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9966,
    localidad: "PUERTO PIRAY",
    cp: 3381,
    provincia_id: 14,
  },
  {
    id: 9967,
    localidad: "PUERTO PINARES",
    cp: 3389,
    provincia_id: 14,
  },
  {
    id: 9968,
    localidad: "PUERTO PIEDRA",
    cp: 3340,
    provincia_id: 14,
  },
  {
    id: 9969,
    localidad: "PUERTO PENINSULA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9970,
    localidad: "PUERTO PAULITO",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9971,
    localidad: "PUERTO PATICAA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9972,
    localidad: "PUERTO PARANAY",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9973,
    localidad: "PUERTO PARAISO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 9974,
    localidad: "PUERTO ORO VERDE",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9975,
    localidad: "PUERTO NUEVO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9976,
    localidad: "PUERTO NARANJITO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9977,
    localidad: "PUERTO MINERAL",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9978,
    localidad: "PUERTO MENOCHIO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9979,
    localidad: "PUERTO MBOPICUA",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9980,
    localidad: "PUERTO MADO",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9981,
    localidad: "PUERTO LUJAN",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 9982,
    localidad: "PUERTO LONDERO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9983,
    localidad: "PUERTO LINARES",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9984,
    localidad: "PUERTO LIBERTAD",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9985,
    localidad: "PUERTO LEONI",
    cp: 3333,
    provincia_id: 14,
  },
  {
    id: 9986,
    localidad: "PUERTO LAHARRAGUE",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 9987,
    localidad: "PUERTO LA MINA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 9988,
    localidad: "PUERTO INSUA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 9989,
    localidad: "PUERTO ING MORANDI",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 9990,
    localidad: "PUERTO IGUAZU",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9991,
    localidad: "PUERTO HARDELASTE",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9992,
    localidad: "PUERTO GISELA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9993,
    localidad: "PUERTO ESPERANZA",
    cp: 3378,
    provincia_id: 14,
  },
  {
    id: 9994,
    localidad: "PUERTO ESPAÑA",
    cp: 3320,
    provincia_id: 14,
  },
  {
    id: 9995,
    localidad: "PUERTO ERRECABORDE",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 9996,
    localidad: "PUERTO EL DORADO",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9997,
    localidad: "PUERTO DOCE",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 9998,
    localidad: "PUERTO DELICIA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 9999,
    localidad: "PUERTO CAZADOR",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10000,
    localidad: "PUERTO CAROLINA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10001,
    localidad: "PUERTO CARAGUATAY",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10002,
    localidad: "PUERTO CANOAS",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10003,
    localidad: "PUERTO BOSSETTI",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10004,
    localidad: "PUERTO BEMBERG",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10005,
    localidad: "PUERTO AZARA",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10006,
    localidad: "PUERTO AVELLANEDA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10007,
    localidad: "PUERTO AURORA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10008,
    localidad: "PUERTO ALICIA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10009,
    localidad: "PUERTO ALCAZAR",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10010,
    localidad: "PUERTO AGUIRRE",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10011,
    localidad: "PUERTO  CONCEPCION",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10012,
    localidad: "PUENTE NACIONAL",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10013,
    localidad: "PROFUNDIDAD",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10014,
    localidad: "POSADAS",
    cp: 3303,
    provincia_id: 14,
  },
  {
    id: 10015,
    localidad: "POSADAS",
    cp: 3301,
    provincia_id: 14,
  },
  {
    id: 10016,
    localidad: "POSADAS",
    cp: 3329,
    provincia_id: 14,
  },
  {
    id: 10017,
    localidad: "POSADAS",
    cp: 3305,
    provincia_id: 14,
  },
  {
    id: 10018,
    localidad: "POSADAS",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10019,
    localidad: "POSADAS",
    cp: 3330,
    provincia_id: 14,
  },
  {
    id: 10020,
    localidad: "PIRAY MINI",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10021,
    localidad: "PIRAY",
    cp: 3386,
    provincia_id: 14,
  },
  {
    id: 10022,
    localidad: "PIÑALITO NORTE",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10023,
    localidad: "PINDAYTI",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10024,
    localidad: "PINDAITI",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10025,
    localidad: "PICADA YAPEYU",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10026,
    localidad: "PICADA SUR MECKING",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10027,
    localidad: "PICADA SUECA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10028,
    localidad: "PICADA SAN MARTIN",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10029,
    localidad: "PICADA SAN MARTIN",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10030,
    localidad: "PICADA SAN JAVIER",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10031,
    localidad: "PICADA SAN JAVIER",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10032,
    localidad: "PICADA SAN JAVIER",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10033,
    localidad: "PICADA RUSA",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10034,
    localidad: "PICADA POZO FEO",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10035,
    localidad: "PICADA POLACA",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10036,
    localidad: "PICADA LIBERTAD",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10037,
    localidad: "PICADA IGLESIA",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10038,
    localidad: "PICADA GOBERNADOR LOPEZ",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10039,
    localidad: "PICADA G LOPEZ",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10040,
    localidad: "PICADA FINLANDESA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10041,
    localidad: "PICADA BONPLAND",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10042,
    localidad: "PICADA BELGRANO",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10043,
    localidad: "PERSIGUERO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10044,
    localidad: "PATI CUA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10045,
    localidad: "PASTOREO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10046,
    localidad: "PARQUE SAN MARTIN",
    cp: 3400,
    provincia_id: 14,
  },
  {
    id: 10047,
    localidad: "PARANAY",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10048,
    localidad: "PARAJE VILLA UNION",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10049,
    localidad: "PARAJE LUCERO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10050,
    localidad: "PARAJE GRANADO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10051,
    localidad: "PARAJE ESTELINA",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10052,
    localidad: "PARAJE AZOPARDO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10053,
    localidad: "PARAISO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10054,
    localidad: "PARADA LEIS",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10055,
    localidad: "PANAMBI",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10056,
    localidad: "PANAMBI",
    cp: 3373,
    provincia_id: 14,
  },
  {
    id: 10057,
    localidad: "P INTERCONTINENTAL",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10058,
    localidad: "P DOS HERMANAS",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10059,
    localidad: "P DEL A PERSIGUERO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10060,
    localidad: "P BANDERITA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10061,
    localidad: "OTILIA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10062,
    localidad: "ONCE VUELTAS",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10063,
    localidad: "OLEGARIO V.ANDRADE",
    cp: 3311,
    provincia_id: 14,
  },
  {
    id: 10064,
    localidad: "OJO DE AGUA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10065,
    localidad: "OBLIGADO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10066,
    localidad: "OBERA",
    cp: 3365,
    provincia_id: 14,
  },
  {
    id: 10067,
    localidad: "OBERA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10068,
    localidad: "OBERA",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10069,
    localidad: "OASIS",
    cp: 3329,
    provincia_id: 14,
  },
  {
    id: 10070,
    localidad: "OASIS",
    cp: 3328,
    provincia_id: 14,
  },
  {
    id: 10071,
    localidad: "O VICTOR ANDRADE",
    cp: 3311,
    provincia_id: 14,
  },
  {
    id: 10072,
    localidad: "NUEVE DE JULIO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10073,
    localidad: "NUEVA VALENCIA",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10074,
    localidad: "MONTECARLO",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10075,
    localidad: "MONTEAGUDO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10076,
    localidad: "MOJON GRANDE",
    cp: 3325,
    provincia_id: 14,
  },
  {
    id: 10077,
    localidad: "MOCONA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10078,
    localidad: "MIGUEL LANUS",
    cp: 3310,
    provincia_id: 14,
  },
  {
    id: 10079,
    localidad: "MIGUEL GUEMES",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10080,
    localidad: "MESA REDONDA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10081,
    localidad: "MECKING",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10082,
    localidad: "MBOPICUA",
    cp: 3331,
    provincia_id: 14,
  },
  {
    id: 10083,
    localidad: "MARIANO MORENO",
    cp: 3338,
    provincia_id: 14,
  },
  {
    id: 10084,
    localidad: "MARIA MAGDALENA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10085,
    localidad: "MARIA ANTONIA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10086,
    localidad: "MANIS",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10087,
    localidad: "MANANTIALES",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10088,
    localidad: "MAI BAO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10089,
    localidad: "MACACO",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10090,
    localidad: "MACACA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10091,
    localidad: "LUJAN",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10092,
    localidad: "LOTE 5",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10093,
    localidad: "LOTE 25",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10094,
    localidad: "LOTE 12",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10095,
    localidad: "LOTE 117",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10096,
    localidad: "LOS TEALES",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10097,
    localidad: "LOS HELECHOS",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10098,
    localidad: "LOS GALPONES",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10099,
    localidad: "LOS BRETES",
    cp: 3340,
    provincia_id: 14,
  },
  {
    id: 10100,
    localidad: "LORETO",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10101,
    localidad: "LINEA DE PERAY",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10102,
    localidad: "LINEA CUCHILLA",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10103,
    localidad: "LIBERTAD",
    cp: 3374,
    provincia_id: 14,
  },
  {
    id: 10104,
    localidad: "LIBERTAD",
    cp: 3302,
    provincia_id: 14,
  },
  {
    id: 10105,
    localidad: "LEANDRO N ALEM",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10106,
    localidad: "LAS VERTIENTES",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10107,
    localidad: "LAS TUNAS",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10108,
    localidad: "LAS TRES HERMANAS",
    cp: 3302,
    provincia_id: 14,
  },
  {
    id: 10109,
    localidad: "LAS QUEMADAS",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10110,
    localidad: "LAS MERCEDES",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10111,
    localidad: "LARRAQUE",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10112,
    localidad: "LA ROTONDA",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10113,
    localidad: "LA PUPII",
    cp: 3358,
    provincia_id: 14,
  },
  {
    id: 10114,
    localidad: "LA PUPI",
    cp: 3358,
    provincia_id: 14,
  },
  {
    id: 10115,
    localidad: "LA POSTA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10116,
    localidad: "LA PLANTADORA",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10117,
    localidad: "LA OTILIA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10118,
    localidad: "LA MISIONERA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10119,
    localidad: "LA INVERNADA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10120,
    localidad: "LA HORQUETA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10121,
    localidad: "LA CAPILLA",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10122,
    localidad: "KILOMETRO 78",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10123,
    localidad: "KILOMETRO 60",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10124,
    localidad: "KILOMETRO 34",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10125,
    localidad: "KILOMETRO 286",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10126,
    localidad: "KILOMETRO 26",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10127,
    localidad: "KILOMETRO 26",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10128,
    localidad: "KILOMETRO 17 R 8",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10129,
    localidad: "KILOMETRO 10",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10130,
    localidad: "JARDIN AMERICA",
    cp: 3328,
    provincia_id: 14,
  },
  {
    id: 10131,
    localidad: "ITAEMBRE MINI",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10132,
    localidad: "ITACARUARE",
    cp: 3353,
    provincia_id: 14,
  },
  {
    id: 10133,
    localidad: "ITA CURUZU",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10134,
    localidad: "ISLA SAN LUCAS",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10135,
    localidad: "ISLA ARGENTINA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10136,
    localidad: "INVERNADA GRANDE",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10137,
    localidad: "INVERNADA CHICA",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10138,
    localidad: "INTEGRACION",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10139,
    localidad: "IN D ITACARUARE",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10140,
    localidad: "IGUAZU",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10141,
    localidad: "IBIRITANGAY",
    cp: 3302,
    provincia_id: 14,
  },
  {
    id: 10142,
    localidad: "HIPOLITO YRIGOYEN",
    cp: 3335,
    provincia_id: 14,
  },
  {
    id: 10143,
    localidad: "HEKENAN",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10144,
    localidad: "GUERRERO",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10145,
    localidad: "GUAYABERA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10146,
    localidad: "GUARAYPO",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10147,
    localidad: "GUARANI",
    cp: 3369,
    provincia_id: 14,
  },
  {
    id: 10148,
    localidad: "GUAIBICHU",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10149,
    localidad: "GRAL GUEMES",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10150,
    localidad: "GOBERNADOR RUIZ",
    cp: 3340,
    provincia_id: 14,
  },
  {
    id: 10151,
    localidad: "GOBERNADOR ROCA",
    cp: 3324,
    provincia_id: 14,
  },
  {
    id: 10152,
    localidad: "GOBERNADOR LANUSSE",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10153,
    localidad: "GENERAL ALVEAR",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10154,
    localidad: "GENERAL ALVEAR",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10155,
    localidad: "GARUPA NORTE",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10156,
    localidad: "GARUPA",
    cp: 3304,
    provincia_id: 14,
  },
  {
    id: 10157,
    localidad: "GARUHAPE",
    cp: 3396,
    provincia_id: 14,
  },
  {
    id: 10158,
    localidad: "GARRUCHOS",
    cp: 3391,
    provincia_id: 14,
  },
  {
    id: 10159,
    localidad: "FRONTERAS",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10160,
    localidad: "FRIGO",
    cp: 3337,
    provincia_id: 14,
  },
  {
    id: 10161,
    localidad: "FRANCES",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10162,
    localidad: "FRACRAN",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10163,
    localidad: "FLORIDA",
    cp: 3302,
    provincia_id: 14,
  },
  {
    id: 10164,
    localidad: "FLORENTINO AMEGHINO",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10165,
    localidad: "FILEMON POSE",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10166,
    localidad: "FACHINAL",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10167,
    localidad: "ESTACION APOSTOLES",
    cp: 3359,
    provincia_id: 14,
  },
  {
    id: 10168,
    localidad: "ESPERANZA",
    cp: 3378,
    provincia_id: 14,
  },
  {
    id: 10169,
    localidad: "ENSANCHE NORTE",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10170,
    localidad: "ENSANCHE ESTE",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10171,
    localidad: "ELDORADO",
    cp: 3380,
    provincia_id: 14,
  },
  {
    id: 10172,
    localidad: "ELDORADO",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10173,
    localidad: "EL TROPEZON",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10174,
    localidad: "EL TRIUNFO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10175,
    localidad: "EL TIGRE",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10176,
    localidad: "EL SOCORRO",
    cp: 3358,
    provincia_id: 14,
  },
  {
    id: 10177,
    localidad: "EL SOCORRO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10178,
    localidad: "EL SOBERBIO",
    cp: 3338,
    provincia_id: 14,
  },
  {
    id: 10179,
    localidad: "EL SOBERBIO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10180,
    localidad: "EL SALTITO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10181,
    localidad: "EL REPOSO",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10182,
    localidad: "EL RANCHO",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10183,
    localidad: "EL PORVENIR",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10184,
    localidad: "EL PERSIGUERO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10185,
    localidad: "EL PARAISO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10186,
    localidad: "EL MACACO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10187,
    localidad: "EL DESTIERRO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10188,
    localidad: "EL CHATON",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10189,
    localidad: "EL CENTINELA",
    cp: 3302,
    provincia_id: 14,
  },
  {
    id: 10190,
    localidad: "EL ALCAZAR",
    cp: 3390,
    provincia_id: 14,
  },
  {
    id: 10191,
    localidad: "E SANTA RITA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10192,
    localidad: "E SAN MIGUEL",
    cp: 3340,
    provincia_id: 14,
  },
  {
    id: 10193,
    localidad: "E LA MERCED",
    cp: 3358,
    provincia_id: 14,
  },
  {
    id: 10194,
    localidad: "E EXP DE LORET",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10195,
    localidad: "DR FELIX M GOMEZ",
    cp: 3400,
    provincia_id: 14,
  },
  {
    id: 10196,
    localidad: "DOS HERMANAS",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10197,
    localidad: "DOS DE MAYO",
    cp: 3337,
    provincia_id: 14,
  },
  {
    id: 10198,
    localidad: "DOS ARROYOS",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10199,
    localidad: "DOMINGO BARTHE",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10200,
    localidad: "DESPLAYADA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10201,
    localidad: "DESEADO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10202,
    localidad: "DAMUS",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10203,
    localidad: "D BOSQUES",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10204,
    localidad: "CRUCE LONDERO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10205,
    localidad: "CRUCE CABALLERO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10206,
    localidad: "CPCION D LA SIERRA",
    cp: 3371,
    provincia_id: 14,
  },
  {
    id: 10207,
    localidad: "COSTA PORTERA",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10208,
    localidad: "CORPUS",
    cp: 3327,
    provincia_id: 14,
  },
  {
    id: 10209,
    localidad: "CONCEPCION DE LA SIERRA",
    cp: 3371,
    provincia_id: 14,
  },
  {
    id: 10210,
    localidad: "CONCEPCION DE LA SIERRA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10211,
    localidad: "COMANDANTE ANDRESITO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10212,
    localidad: "COM ANDRESITO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10213,
    localidad: "COLONIA YACUTINGA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10214,
    localidad: "COLONIA YABEBIRI",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10215,
    localidad: "COLONIA VICTORIA",
    cp: 3388,
    provincia_id: 14,
  },
  {
    id: 10216,
    localidad: "COLONIA TARANCO",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10217,
    localidad: "COLONIA SEGUI",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10218,
    localidad: "COLONIA SAN JAVIER",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10219,
    localidad: "COLONIA ROCA CHICA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10220,
    localidad: "COLONIA ROCA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10221,
    localidad: "COLONIA PRIMAVERA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10222,
    localidad: "COLONIA POLANA",
    cp: 3321,
    provincia_id: 14,
  },
  {
    id: 10223,
    localidad: "COLONIA POLACA",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10224,
    localidad: "COLONIA PALMERA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10225,
    localidad: "COLONIA PADUAN",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10226,
    localidad: "COLONIA ORO VERDE",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10227,
    localidad: "COLONIA OASIS",
    cp: 3326,
    provincia_id: 14,
  },
  {
    id: 10228,
    localidad: "COLONIA MONDORI",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10229,
    localidad: "COLONIA MARTIRES",
    cp: 3318,
    provincia_id: 14,
  },
  {
    id: 10230,
    localidad: "COLONIA LEIVA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10231,
    localidad: "COLONIA LA POLACA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10232,
    localidad: "COLONIA LA OTILIA",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10233,
    localidad: "COLONIA LA GRUTA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10234,
    localidad: "COLONIA JUANITA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10235,
    localidad: "COLONIA JAPONESA",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10236,
    localidad: "COLONIA GUARANI",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10237,
    localidad: "COLONIA GRAMADO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10238,
    localidad: "COLONIA FORTALEZA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10239,
    localidad: "COLONIA FLORIDA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10240,
    localidad: "COLONIA FINLANDESA",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10241,
    localidad: "COLONIA EL PESADO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10242,
    localidad: "COLONIA EL ALCAZAR",
    cp: 3390,
    provincia_id: 14,
  },
  {
    id: 10243,
    localidad: "COLONIA DURAN",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10244,
    localidad: "COLONIA DOMINGO SAVIO",
    cp: 3323,
    provincia_id: 14,
  },
  {
    id: 10245,
    localidad: "COLONIA DELICIA",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10246,
    localidad: "COLONIA CUNCI",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10247,
    localidad: "COLONIA CUMANDAY",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10248,
    localidad: "COLONIA CHAPA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10249,
    localidad: "COLONIA CHAFARIZ",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10250,
    localidad: "COLONIA CARAGUATAY",
    cp: 3386,
    provincia_id: 14,
  },
  {
    id: 10251,
    localidad: "COLONIA CAA GUAZU",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10252,
    localidad: "COLONIA AURORA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10253,
    localidad: "COLONIA APOSTOLES",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10254,
    localidad: "COLONIA ALMAFUERTE",
    cp: 3336,
    provincia_id: 14,
  },
  {
    id: 10255,
    localidad: "COLONIA ALICIA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10256,
    localidad: "COLONIA ALEMANA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10257,
    localidad: "COLONIA ALBERDI",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10258,
    localidad: "COLONIA ALBERDI",
    cp: 3393,
    provincia_id: 14,
  },
  {
    id: 10259,
    localidad: "COLONIA 25 DE MAYO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10260,
    localidad: "CITRUS",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10261,
    localidad: "CHIRIMAY",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10262,
    localidad: "CHEROGUITA",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10263,
    localidad: "CERRO CORA",
    cp: 3309,
    provincia_id: 14,
  },
  {
    id: 10264,
    localidad: "CERRO CORA",
    cp: 3319,
    provincia_id: 14,
  },
  {
    id: 10265,
    localidad: "CERRO AZUL",
    cp: 3313,
    provincia_id: 14,
  },
  {
    id: 10266,
    localidad: "CENTINELA",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10267,
    localidad: "CATARATAS DEL IGUAZU",
    cp: 3372,
    provincia_id: 14,
  },
  {
    id: 10268,
    localidad: "CASUALIDAD",
    cp: 3340,
    provincia_id: 14,
  },
  {
    id: 10269,
    localidad: "CARRILLO VIEJO",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10270,
    localidad: "CARAGUATAY",
    cp: 3372,
    provincia_id: 14,
  },
  {
    id: 10271,
    localidad: "CAPUERON",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10272,
    localidad: "CAPIVU",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10273,
    localidad: "CAPIOVY",
    cp: 3332,
    provincia_id: 14,
  },
  {
    id: 10274,
    localidad: "CAPIOVI",
    cp: 3332,
    provincia_id: 14,
  },
  {
    id: 10275,
    localidad: "CANDELARIA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10276,
    localidad: "CAMPOS SALLES",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10277,
    localidad: "CAMPO VIERA",
    cp: 3362,
    provincia_id: 14,
  },
  {
    id: 10278,
    localidad: "CAMPO VIERA",
    cp: 3375,
    provincia_id: 14,
  },
  {
    id: 10279,
    localidad: "CAMPO TORNQUINST",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10280,
    localidad: "CAMPO RAMON",
    cp: 3367,
    provincia_id: 14,
  },
  {
    id: 10281,
    localidad: "CAMPO RAMON",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10282,
    localidad: "CAMPO GRANDE",
    cp: 3362,
    provincia_id: 14,
  },
  {
    id: 10283,
    localidad: "CAMPO ALEGRE",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10284,
    localidad: "CAMPANA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10285,
    localidad: "CAINGUAS",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10286,
    localidad: "CABUREI",
    cp: 3371,
    provincia_id: 14,
  },
  {
    id: 10287,
    localidad: "CABURE",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10288,
    localidad: "CAAPORA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10289,
    localidad: "C TRES MARIAS",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10290,
    localidad: "C SIETE ESTRELLAS",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10291,
    localidad: "C SANTA TERESA",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10292,
    localidad: "C SANTA MARIA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10293,
    localidad: "C SAN IGNACIO",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10294,
    localidad: "C PUERTO ROSALES",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10295,
    localidad: "C PROFUNDIDAD",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10296,
    localidad: "C MARTIR S MARIA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10297,
    localidad: "C MANUEL BELGRANO",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10298,
    localidad: "C LA CHILLITA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10299,
    localidad: "C EL PROGRESO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10300,
    localidad: "C EL DORADILLO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10301,
    localidad: "C DOMINGO SAVIO",
    cp: 3323,
    provincia_id: 14,
  },
  {
    id: 10302,
    localidad: "C DEL IGUAZU",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10303,
    localidad: "C DE AMERICA",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10304,
    localidad: "C CAPON BONITO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10305,
    localidad: "C ANT MORALES",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10306,
    localidad: "C A DEL VALLE",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10307,
    localidad: "BUENA VISTA",
    cp: 3357,
    provincia_id: 14,
  },
  {
    id: 10308,
    localidad: "BRETES MARTIRES",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10309,
    localidad: "BONPLAND NORTE",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10310,
    localidad: "BONPLAND",
    cp: 3317,
    provincia_id: 14,
  },
  {
    id: 10311,
    localidad: "BERNARDO DE IRIGOYEN",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10312,
    localidad: "BERNAR RIVAD",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10313,
    localidad: "BERNA DE IRIGOYEN",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10314,
    localidad: "BELLA VISTA",
    cp: 3309,
    provincia_id: 14,
  },
  {
    id: 10315,
    localidad: "BAYO TRONCHO",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10316,
    localidad: "BARTOLITO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10317,
    localidad: "BARRIO DON STIAGO",
    cp: 3312,
    provincia_id: 14,
  },
  {
    id: 10318,
    localidad: "BARRIO DON SANTIAGO",
    cp: 3312,
    provincia_id: 14,
  },
  {
    id: 10319,
    localidad: "BARRANCON",
    cp: 3384,
    provincia_id: 14,
  },
  {
    id: 10320,
    localidad: "BARRANCON",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10321,
    localidad: "BARRACON",
    cp: 3366,
    provincia_id: 14,
  },
  {
    id: 10322,
    localidad: "BARRACON",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10323,
    localidad: "BARRA CONCEPCION",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10324,
    localidad: "BARRA BONITA",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10325,
    localidad: "BARRA BONITA",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10326,
    localidad: "B DEL TACUARUZU",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10327,
    localidad: "AZARA",
    cp: 3351,
    provincia_id: 14,
  },
  {
    id: 10328,
    localidad: "ARROYO YABEBIRI",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10329,
    localidad: "ARROYO TUNITAS",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10330,
    localidad: "ARROYO SANTA MARIA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10331,
    localidad: "ARROYO PERSIGUERO",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10332,
    localidad: "ARROYO PASTORA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10333,
    localidad: "ARROYO MAGDALENA",
    cp: 3308,
    provincia_id: 14,
  },
  {
    id: 10334,
    localidad: "ARROYO ISABEL",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10335,
    localidad: "ARROYO FEDOR",
    cp: 3360,
    provincia_id: 14,
  },
  {
    id: 10336,
    localidad: "ARROYO DEL MEDIO",
    cp: 3315,
    provincia_id: 14,
  },
  {
    id: 10337,
    localidad: "ARROYO",
    cp: 3300,
    provincia_id: 14,
  },
  {
    id: 10338,
    localidad: "ARRECHEA",
    cp: 3355,
    provincia_id: 14,
  },
  {
    id: 10339,
    localidad: "ARISTOBULO DEL VALLE",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10340,
    localidad: "APOSTOLES",
    cp: 3350,
    provincia_id: 14,
  },
  {
    id: 10341,
    localidad: "APARICIO CUE",
    cp: 3322,
    provincia_id: 14,
  },
  {
    id: 10342,
    localidad: "ALTA UNION",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10343,
    localidad: "ALMIRANTE BROWN",
    cp: 3385,
    provincia_id: 14,
  },
  {
    id: 10344,
    localidad: "ALBA POSSE",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10345,
    localidad: "ACARAGUA",
    cp: 3361,
    provincia_id: 14,
  },
  {
    id: 10346,
    localidad: "A ECHEVERRIA",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10347,
    localidad: "A DEL VALLE",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10348,
    localidad: "9 DE JULIO KM 20",
    cp: 3382,
    provincia_id: 14,
  },
  {
    id: 10349,
    localidad: "9 DE JULIO",
    cp: 3363,
    provincia_id: 14,
  },
  {
    id: 10350,
    localidad: "3 DE MAYO",
    cp: 3334,
    provincia_id: 14,
  },
  {
    id: 10351,
    localidad: "25 DE MAYO",
    cp: 3379,
    provincia_id: 14,
  },
  {
    id: 10352,
    localidad: "22 DE DICIEMBRE",
    cp: 3370,
    provincia_id: 14,
  },
  {
    id: 10353,
    localidad: "2 DE MAYO",
    cp: 3337,
    provincia_id: 14,
  },
  {
    id: 10354,
    localidad: "2 DE MAYO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10355,
    localidad: "1 DE MAYO",
    cp: 3364,
    provincia_id: 14,
  },
  {
    id: 10356,
    localidad: "ZAPATA",
    cp: 5560,
    provincia_id: 13,
  },
  {
    id: 10357,
    localidad: "ZANON CANAL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10358,
    localidad: "VUELTA DEL ZANJON",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10359,
    localidad: "VRA DE LAS VACAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10360,
    localidad: "VIZCACHERAS",
    cp: 5577,
    provincia_id: 13,
  },
  {
    id: 10361,
    localidad: "VIUDA DE OROZCO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10362,
    localidad: "VISTALBA",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 10363,
    localidad: "VISTA FLORES",
    cp: 5665,
    provincia_id: 13,
  },
  {
    id: 10364,
    localidad: "VISTA FLORES",
    cp: 5844,
    provincia_id: 13,
  },
  {
    id: 10365,
    localidad: "VILLAVICENCIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10366,
    localidad: "VILLA VIEJA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10367,
    localidad: "VILLA VEINTICINCO DE MAYO",
    cp: 5625,
    provincia_id: 13,
  },
  {
    id: 10368,
    localidad: "VILLA SECA",
    cp: 5563,
    provincia_id: 13,
  },
  {
    id: 10369,
    localidad: "VILLA SECA",
    cp: 5588,
    provincia_id: 13,
  },
  {
    id: 10370,
    localidad: "VILLA SANTA ISABEL",
    cp: 5673,
    provincia_id: 13,
  },
  {
    id: 10371,
    localidad: "VILLA SAN JOSE",
    cp: 5561,
    provincia_id: 13,
  },
  {
    id: 10372,
    localidad: "VILLA NUEVA DE GUAYMALLEN",
    cp: 5521,
    provincia_id: 13,
  },
  {
    id: 10373,
    localidad: "VILLA NUEVA",
    cp: 5521,
    provincia_id: 13,
  },
  {
    id: 10374,
    localidad: "VILLA LA PAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10375,
    localidad: "VILLA HIPODROMO",
    cp: 5547,
    provincia_id: 13,
  },
  {
    id: 10376,
    localidad: "VILLA COMPARTO",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10377,
    localidad: "VILLA BASTIAS",
    cp: 5562,
    provincia_id: 13,
  },
  {
    id: 10378,
    localidad: "VILLA ATUEL",
    cp: 5622,
    provincia_id: 13,
  },
  {
    id: 10379,
    localidad: "VILLA ANTIGUA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10380,
    localidad: "VICENTE PELETAY",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10381,
    localidad: "VICENTE MUQOZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10382,
    localidad: "VEGA DE LOS BURROS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10383,
    localidad: "VALLE HERMOSO",
    cp: 5587,
    provincia_id: 13,
  },
  {
    id: 10384,
    localidad: "VALLE DE USPALLATA",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10385,
    localidad: "VALLE DE LAS LEQAS",
    cp: 5612,
    provincia_id: 13,
  },
  {
    id: 10386,
    localidad: "V HIPODROMO",
    cp: 5547,
    provincia_id: 13,
  },
  {
    id: 10387,
    localidad: "V D LOS C DE ARAYA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10388,
    localidad: "USPALLATA",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10389,
    localidad: "USIYAL",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10390,
    localidad: "UGARTECHE",
    cp: 5546,
    provincia_id: 13,
  },
  {
    id: 10391,
    localidad: "UGARTECHE",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 10392,
    localidad: "TUPUNGATO",
    cp: 5561,
    provincia_id: 13,
  },
  {
    id: 10393,
    localidad: "TUNUYAN",
    cp: 5560,
    provincia_id: 13,
  },
  {
    id: 10394,
    localidad: "TULUMAYA",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10395,
    localidad: "TUDCUM",
    cp: 5686,
    provincia_id: 13,
  },
  {
    id: 10396,
    localidad: "TTE B MATIENZO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10397,
    localidad: "TROPERO SOSA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10398,
    localidad: "TRINO ROSALESO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10399,
    localidad: "TRES PORTEÑAS",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10400,
    localidad: "TRES ESQUINAS",
    cp: 5866,
    provincia_id: 13,
  },
  {
    id: 10401,
    localidad: "TRES ESQUINAS",
    cp: 5514,
    provincia_id: 13,
  },
  {
    id: 10402,
    localidad: "TREINTA DE OCTUBRE",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10403,
    localidad: "TRAVESIA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10404,
    localidad: "TOSCAL",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10405,
    localidad: "TOMAS MERCADO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10406,
    localidad: "TILIO ALCARAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10407,
    localidad: "TILA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10408,
    localidad: "TERMAS VVICENCIO",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10409,
    localidad: "TEOFILO ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10410,
    localidad: "TEOFILO RUBEN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10411,
    localidad: "TEOFILA ACEVEDO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10412,
    localidad: "TEODORO VILLARUEL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10413,
    localidad: "TEODORO GARRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10414,
    localidad: "TAPON",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 10415,
    localidad: "TAMBITO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10416,
    localidad: "SOPANTA",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10417,
    localidad: "SOITUE",
    cp: 5623,
    provincia_id: 13,
  },
  {
    id: 10418,
    localidad: "SIXTO LEDESMA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10419,
    localidad: "SIERRA DEL TONTAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10420,
    localidad: "SIERRA ANSILTA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10421,
    localidad: "SERVILIANO OJEDA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10422,
    localidad: "SCHEZ D BUSTAMANTE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10423,
    localidad: "SATURNINO ROMERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10424,
    localidad: "SARMIENTO",
    cp: 5513,
    provincia_id: 13,
  },
  {
    id: 10425,
    localidad: "SANTO DOMINGO",
    cp: 5700,
    provincia_id: 13,
  },
  {
    id: 10426,
    localidad: "SANTIAGO ROMERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10427,
    localidad: "SANTA TERESA",
    cp: 5605,
    provincia_id: 13,
  },
  {
    id: 10428,
    localidad: "SANTA TERESA",
    cp: 5640,
    provincia_id: 13,
  },
  {
    id: 10429,
    localidad: "SANTA TERESA",
    cp: 5700,
    provincia_id: 13,
  },
  {
    id: 10430,
    localidad: "SANTA ROSA",
    cp: 5596,
    provincia_id: 13,
  },
  {
    id: 10431,
    localidad: "SANTA MARIA DE ORO",
    cp: 5576,
    provincia_id: 13,
  },
  {
    id: 10432,
    localidad: "SANTA ELENA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10433,
    localidad: "SANTA BLANCA",
    cp: 5655,
    provincia_id: 13,
  },
  {
    id: 10434,
    localidad: "SAN ROQUE",
    cp: 5995,
    provincia_id: 13,
  },
  {
    id: 10435,
    localidad: "SAN ROQUE",
    cp: 5587,
    provincia_id: 13,
  },
  {
    id: 10436,
    localidad: "SAN ROQUE",
    cp: 5650,
    provincia_id: 13,
  },
  {
    id: 10437,
    localidad: "SAN RAFAEL",
    cp: 5602,
    provincia_id: 13,
  },
  {
    id: 10438,
    localidad: "SAN RAFAEL",
    cp: 5600,
    provincia_id: 13,
  },
  {
    id: 10439,
    localidad: "SAN PEDRO DE ATUEL",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10440,
    localidad: "SAN PEDRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10441,
    localidad: "SAN MIGUEL",
    cp: 5537,
    provincia_id: 13,
  },
  {
    id: 10442,
    localidad: "SAN MARTIN",
    cp: 5570,
    provincia_id: 13,
  },
  {
    id: 10443,
    localidad: "SAN JUAN BAUTISTA USNO",
    cp: 5682,
    provincia_id: 13,
  },
  {
    id: 10444,
    localidad: "SAN JOSE DE TUPUNGATO",
    cp: 5667,
    provincia_id: 13,
  },
  {
    id: 10445,
    localidad: "SAN JOSE DE GUAYMALLEN",
    cp: 5519,
    provincia_id: 13,
  },
  {
    id: 10446,
    localidad: "SAN JOSE",
    cp: 5530,
    provincia_id: 13,
  },
  {
    id: 10447,
    localidad: "SAN JOSE",
    cp: 5519,
    provincia_id: 13,
  },
  {
    id: 10448,
    localidad: "SAN JOSE",
    cp: 5535,
    provincia_id: 13,
  },
  {
    id: 10449,
    localidad: "SAN IGNACIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10450,
    localidad: "SAN FRANCISCO DEL MONTE",
    cp: 5503,
    provincia_id: 13,
  },
  {
    id: 10451,
    localidad: "SAN CARLOS",
    cp: 5569,
    provincia_id: 13,
  },
  {
    id: 10452,
    localidad: "SAN CARLOS",
    cp: 5863,
    provincia_id: 13,
  },
  {
    id: 10453,
    localidad: "SAN CARLOS",
    cp: 5512,
    provincia_id: 13,
  },
  {
    id: 10454,
    localidad: "SAN ANTONIO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10455,
    localidad: "SAN ALBERTO",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10456,
    localidad: "SALTO DE LAS ROSAS",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10457,
    localidad: "S J BAUTISTA USNO",
    cp: 5541,
    provincia_id: 13,
  },
  {
    id: 10458,
    localidad: "S DE LAS HIGUERAS",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10459,
    localidad: "S CORTIS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10460,
    localidad: "RUSSELL",
    cp: 5581,
    provincia_id: 13,
  },
  {
    id: 10461,
    localidad: "RUSSELL",
    cp: 5517,
    provincia_id: 13,
  },
  {
    id: 10462,
    localidad: "RUFINO GOMEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10463,
    localidad: "ROSARIO GATICA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10464,
    localidad: "RODRIGUEZ PEÑA",
    cp: 5983,
    provincia_id: 13,
  },
  {
    id: 10465,
    localidad: "RODRIGUEZ PEÑA",
    cp: 5669,
    provincia_id: 13,
  },
  {
    id: 10466,
    localidad: "RODOLFO ISELIN",
    cp: 5628,
    provincia_id: 13,
  },
  {
    id: 10467,
    localidad: "RODEO GRANDE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10468,
    localidad: "RODEO DEL MEDIO",
    cp: 5529,
    provincia_id: 13,
  },
  {
    id: 10469,
    localidad: "RODEO DE LA CRUZ",
    cp: 5525,
    provincia_id: 13,
  },
  {
    id: 10470,
    localidad: "RIVADAVIA",
    cp: 5577,
    provincia_id: 13,
  },
  {
    id: 10471,
    localidad: "RIO BLANCO",
    cp: 5553,
    provincia_id: 13,
  },
  {
    id: 10472,
    localidad: "RINCON DEL ATUEL",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10473,
    localidad: "RICARDO LAVALLE",
    cp: 5582,
    provincia_id: 13,
  },
  {
    id: 10474,
    localidad: "RETAMO PARTIDO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10475,
    localidad: "RETAMO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10476,
    localidad: "RESOLANA",
    cp: 5601,
    provincia_id: 13,
  },
  {
    id: 10477,
    localidad: "REGINO OJEDA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10478,
    localidad: "REDUCCION",
    cp: 5645,
    provincia_id: 13,
  },
  {
    id: 10479,
    localidad: "REAL DEL PADRE",
    cp: 5624,
    provincia_id: 13,
  },
  {
    id: 10480,
    localidad: "RANQUIL NORTE",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10481,
    localidad: "RAMON GIMENEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10482,
    localidad: "RAMON DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10483,
    localidad: "RAMBLON GRANDE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10484,
    localidad: "RAMA CAIDA",
    cp: 5619,
    provincia_id: 13,
  },
  {
    id: 10485,
    localidad: "R DE LA PAMPA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10486,
    localidad: "R BEBEDERA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10487,
    localidad: "QUINTO CUARTEL",
    cp: 5674,
    provincia_id: 13,
  },
  {
    id: 10488,
    localidad: "PUNTOS DE AGUA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10489,
    localidad: "PUNTA DEL AGUA",
    cp: 5627,
    provincia_id: 13,
  },
  {
    id: 10490,
    localidad: "PUNTA DE VACAS",
    cp: 5553,
    provincia_id: 13,
  },
  {
    id: 10491,
    localidad: "PUESTO ZAMPAL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10492,
    localidad: "PUESTO NUERAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10493,
    localidad: "PUESTO LUNINA",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10494,
    localidad: "PUESTO LA SEQA",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10495,
    localidad: "PUESTO LA MOJADA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10496,
    localidad: "PUESTO LA GRUTA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10497,
    localidad: "PUESTO GUAMPARITO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10498,
    localidad: "PUESTO ESCONDIDO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10499,
    localidad: "PUESTO EL TOTORAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10500,
    localidad: "PUESTO EL RETAMITO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10501,
    localidad: "PUESTO EL PERAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10502,
    localidad: "PUESTO DE SOSA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10503,
    localidad: "PUESTO DE PETRA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10504,
    localidad: "PUESTO DE OROZCO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10505,
    localidad: "PUESTO DE OLGUIN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10506,
    localidad: "PUESTO DE GARRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10507,
    localidad: "PUESTO CHAMBON",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10508,
    localidad: "PUESTO CARRIZALITO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10509,
    localidad: "PUERTA DE LA ISLA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10510,
    localidad: "PUENTE VIEJO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10511,
    localidad: "PUENTE DEL INCA",
    cp: 5555,
    provincia_id: 13,
  },
  {
    id: 10512,
    localidad: "PUEBLO ECHEVARRIETA",
    cp: 5618,
    provincia_id: 13,
  },
  {
    id: 10513,
    localidad: "PTO LOS AMARILLOS",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10514,
    localidad: "PRIMAVERA",
    cp: 5641,
    provincia_id: 13,
  },
  {
    id: 10515,
    localidad: "PQUITA EMB CGSM",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10516,
    localidad: "POZO ESCONDIDO",
    cp: 5700,
    provincia_id: 13,
  },
  {
    id: 10517,
    localidad: "POTRERILLOS",
    cp: 5772,
    provincia_id: 13,
  },
  {
    id: 10518,
    localidad: "POTRERILLOS",
    cp: 5552,
    provincia_id: 13,
  },
  {
    id: 10519,
    localidad: "POSTE DE FIERRO",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10520,
    localidad: "POSTA DE HIERRO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10521,
    localidad: "PORTILLO LA PAMPA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10522,
    localidad: "PORTILLO DEL TIGRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10523,
    localidad: "PORTILLO DEL NORTE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10524,
    localidad: "PORTILLO DEL MEDIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10525,
    localidad: "PORTILLO DE INDIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10526,
    localidad: "PORTILLO D QUEMADO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10527,
    localidad: "POLVAREDA",
    cp: 5551,
    provincia_id: 13,
  },
  {
    id: 10528,
    localidad: "PO VALLE HERMOSO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10529,
    localidad: "PO DEL RUBIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10530,
    localidad: "PO DE LA Q HONDA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10531,
    localidad: "PO DE LA CUMBRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10532,
    localidad: "PLUMERITO",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10533,
    localidad: "PLAZA DE MULAS",
    cp: 5500,
    provincia_id: 13,
  },
  {
    id: 10534,
    localidad: "PHILLIPS",
    cp: 5579,
    provincia_id: 13,
  },
  {
    id: 10535,
    localidad: "PHILLIPS",
    cp: 5538,
    provincia_id: 13,
  },
  {
    id: 10536,
    localidad: "PHILIPPS",
    cp: 5538,
    provincia_id: 13,
  },
  {
    id: 10537,
    localidad: "PHILIPPS",
    cp: 5932,
    provincia_id: 13,
  },
  {
    id: 10538,
    localidad: "PERDRIEL",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 10539,
    localidad: "PERDRIEL",
    cp: 5544,
    provincia_id: 13,
  },
  {
    id: 10540,
    localidad: "PEDRO VARGAS",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10541,
    localidad: "PEDRO VARGAS",
    cp: 5617,
    provincia_id: 13,
  },
  {
    id: 10542,
    localidad: "PEDRO PABLO PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10543,
    localidad: "PEDRO CASTELU",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10544,
    localidad: "PEDREGAL",
    cp: 5651,
    provincia_id: 13,
  },
  {
    id: 10545,
    localidad: "PAULINO MATURA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10546,
    localidad: "PASO HONDO",
    cp: 5541,
    provincia_id: 13,
  },
  {
    id: 10547,
    localidad: "PASO DE LAS CARRETAS",
    cp: 5569,
    provincia_id: 13,
  },
  {
    id: 10548,
    localidad: "PASO DE LAS CANOAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10549,
    localidad: "PASCUAL SOSA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10550,
    localidad: "PAREDITAS",
    cp: 5510,
    provincia_id: 13,
  },
  {
    id: 10551,
    localidad: "PAREDITAS",
    cp: 5860,
    provincia_id: 13,
  },
  {
    id: 10552,
    localidad: "PARAJE BEBIDA",
    cp: 5677,
    provincia_id: 13,
  },
  {
    id: 10553,
    localidad: "PANQUEUA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10554,
    localidad: "PAMPITA EMBARCADERO FCGSM",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 10555,
    localidad: "PAMPA YALGUARAZ",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10556,
    localidad: "PALMIRA",
    cp: 5584,
    provincia_id: 13,
  },
  {
    id: 10557,
    localidad: "P VUELTA DL ZANJON",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10558,
    localidad: "P SAN IGNACIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10559,
    localidad: "P S C DE ARRIBA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10560,
    localidad: "P RIQUITIPANCHE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10561,
    localidad: "P LOS PAJARITOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10562,
    localidad: "P LOS ALOJAMIENTOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10563,
    localidad: "P LAS HIGUERAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10564,
    localidad: "P LA CALDENADA",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10565,
    localidad: "P EMB FCGSM",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 10566,
    localidad: "P DEL CHAQACAL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10567,
    localidad: "P DEL BUEN PASTOR",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10568,
    localidad: "P DE LOS GAUCHOS",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10569,
    localidad: "P DE LAS VACAS",
    cp: 5500,
    provincia_id: 13,
  },
  {
    id: 10570,
    localidad: "P DE LAS VACAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10571,
    localidad: "P DE LAS TROPAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10572,
    localidad: "P DE LAS CARRETAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10573,
    localidad: "P DE LA CORONA",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10574,
    localidad: "P D L LAGRIMA VIVA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10575,
    localidad: "P D L COLORADAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10576,
    localidad: "P D CONTRABANDISTA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10577,
    localidad: "P AGUA DE ZANJON",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10578,
    localidad: "P AGUA DE TORO",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10579,
    localidad: "OVEJERIA",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10580,
    localidad: "ONOTRE PUEBLA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10581,
    localidad: "OCHENTA Y CUATRO",
    cp: 5633,
    provincia_id: 13,
  },
  {
    id: 10582,
    localidad: "ÑACUÑAN",
    cp: 5595,
    provincia_id: 13,
  },
  {
    id: 10583,
    localidad: "NUEVA CONSTITUCION",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10584,
    localidad: "NIHUIL",
    cp: 5605,
    provincia_id: 13,
  },
  {
    id: 10585,
    localidad: "NICOLAS ORDOQEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10586,
    localidad: "NESTOR AGUILERA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10587,
    localidad: "NECTO SOSA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10588,
    localidad: "NATALIA DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10589,
    localidad: "MUNDO NUEVO",
    cp: 5579,
    provincia_id: 13,
  },
  {
    id: 10590,
    localidad: "MUNDO NUEVO",
    cp: 5948,
    provincia_id: 13,
  },
  {
    id: 10591,
    localidad: "MUNDO NUEVO",
    cp: 5536,
    provincia_id: 13,
  },
  {
    id: 10592,
    localidad: "MOYANO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10593,
    localidad: "MOSMOTA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10594,
    localidad: "MONUMENTO AL EJER",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10595,
    localidad: "MONTE COMAN",
    cp: 5609,
    provincia_id: 13,
  },
  {
    id: 10596,
    localidad: "MONTE CASEROS",
    cp: 5571,
    provincia_id: 13,
  },
  {
    id: 10597,
    localidad: "MONTE CASEROS",
    cp: 5671,
    provincia_id: 13,
  },
  {
    id: 10598,
    localidad: "MONTE BAYO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10599,
    localidad: "MOJON OCHO",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10600,
    localidad: "MINELLI",
    cp: 5532,
    provincia_id: 13,
  },
  {
    id: 10601,
    localidad: "MINAS SALAGASTA",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10602,
    localidad: "MENDOZA",
    cp: 5504,
    provincia_id: 13,
  },
  {
    id: 10603,
    localidad: "MENDOZA",
    cp: 5500,
    provincia_id: 13,
  },
  {
    id: 10604,
    localidad: "MENDOZA",
    cp: 5502,
    provincia_id: 13,
  },
  {
    id: 10605,
    localidad: "MEDRANO",
    cp: 5585,
    provincia_id: 13,
  },
  {
    id: 10606,
    localidad: "MEDRANO",
    cp: 5633,
    provincia_id: 13,
  },
  {
    id: 10607,
    localidad: "MEDARDO MIRANDA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10608,
    localidad: "MEDANOS COLORADO",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10609,
    localidad: "MEDANO DE ORO",
    cp: 5676,
    provincia_id: 13,
  },
  {
    id: 10610,
    localidad: "MECHANQUIL",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10611,
    localidad: "MAYOR DRUMMOND",
    cp: 5534,
    provincia_id: 13,
  },
  {
    id: 10612,
    localidad: "MAURICIO CALDERON",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10613,
    localidad: "MATIAS GARRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10614,
    localidad: "MATHIEU NORTE",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10615,
    localidad: "MARIO OLGUIN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10616,
    localidad: "MARIA GARCIA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10617,
    localidad: "MARAVILLA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10618,
    localidad: "MAQUINISTA LEVET",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10619,
    localidad: "MALENA",
    cp: 5642,
    provincia_id: 13,
  },
  {
    id: 10620,
    localidad: "MALARGUE",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10621,
    localidad: "MAIPU",
    cp: 5515,
    provincia_id: 13,
  },
  {
    id: 10622,
    localidad: "M VIUDA DE DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10623,
    localidad: "M QUIROGA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10624,
    localidad: "M ESCUDERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10625,
    localidad: "M DE LOS GAUCHOS",
    cp: 5643,
    provincia_id: 13,
  },
  {
    id: 10626,
    localidad: "LUZURIAGA",
    cp: 5516,
    provincia_id: 13,
  },
  {
    id: 10627,
    localidad: "LUZURIAGA",
    cp: 5513,
    provincia_id: 13,
  },
  {
    id: 10628,
    localidad: "LUNLUNTA",
    cp: 5593,
    provincia_id: 13,
  },
  {
    id: 10629,
    localidad: "LUJAN DE CUYO",
    cp: 5507,
    provincia_id: 13,
  },
  {
    id: 10630,
    localidad: "LUIS MARQUEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10631,
    localidad: "LUCAS DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10632,
    localidad: "LOS VILLEGAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10633,
    localidad: "LOS VERDES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10634,
    localidad: "LOS TORDILLOS",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10635,
    localidad: "LOS TAMARINDOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10636,
    localidad: "LOS TAMARINDOS",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10637,
    localidad: "LOS TAMARINDOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10638,
    localidad: "LOS SAUCES",
    cp: 5836,
    provincia_id: 13,
  },
  {
    id: 10639,
    localidad: "LOS SAUCES",
    cp: 5663,
    provincia_id: 13,
  },
  {
    id: 10640,
    localidad: "LOS ROSETI",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10641,
    localidad: "LOS RAMBLONES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10642,
    localidad: "LOS OLMOS",
    cp: 5571,
    provincia_id: 13,
  },
  {
    id: 10643,
    localidad: "LOS MOLLES",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10644,
    localidad: "LOS HUARPES",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10645,
    localidad: "LOS HORCONCITOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10646,
    localidad: "LOS CORRALITOS",
    cp: 5527,
    provincia_id: 13,
  },
  {
    id: 10647,
    localidad: "LOS COMPARTOS",
    cp: 5648,
    provincia_id: 13,
  },
  {
    id: 10648,
    localidad: "LOS COLORADOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10649,
    localidad: "LOS CHACAYES",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10650,
    localidad: "LOS CAVAOS",
    cp: 5614,
    provincia_id: 13,
  },
  {
    id: 10651,
    localidad: "LOS CAMPAMENTOS",
    cp: 5928,
    provincia_id: 13,
  },
  {
    id: 10652,
    localidad: "LOS CAMPAMENTOS",
    cp: 5526,
    provincia_id: 13,
  },
  {
    id: 10653,
    localidad: "LOS BARRIALES",
    cp: 5668,
    provincia_id: 13,
  },
  {
    id: 10654,
    localidad: "LOS BARRIALES",
    cp: 5993,
    provincia_id: 13,
  },
  {
    id: 10655,
    localidad: "LOS ARBOLES DE VILLEGAS",
    cp: 5563,
    provincia_id: 13,
  },
  {
    id: 10656,
    localidad: "LOS ARBOLES",
    cp: 5559,
    provincia_id: 13,
  },
  {
    id: 10657,
    localidad: "LOS ARBOLES",
    cp: 5922,
    provincia_id: 13,
  },
  {
    id: 10658,
    localidad: "LOS ARBOLES",
    cp: 5575,
    provincia_id: 13,
  },
  {
    id: 10659,
    localidad: "LOS ANGELES",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10660,
    localidad: "LOS ALTAMIQUES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10661,
    localidad: "LOS ALGARROBOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10662,
    localidad: "LOS ALAMOS",
    cp: 5656,
    provincia_id: 13,
  },
  {
    id: 10663,
    localidad: "LONCO VACAS",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10664,
    localidad: "LOMAS BAYAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10665,
    localidad: "LOMA SOLA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10666,
    localidad: "LOMA DE LOS BURROS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10667,
    localidad: "LOMA COLORADA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10668,
    localidad: "LLAVE VIEJA",
    cp: 5608,
    provincia_id: 13,
  },
  {
    id: 10669,
    localidad: "LLAVE NUEVA",
    cp: 5608,
    provincia_id: 13,
  },
  {
    id: 10670,
    localidad: "LISANDRO ESCUDERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10671,
    localidad: "LINO PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10672,
    localidad: "LIBERTADOR GRAL SAN MARTIN",
    cp: 5570,
    provincia_id: 13,
  },
  {
    id: 10673,
    localidad: "LIBER GRAL S M",
    cp: 5570,
    provincia_id: 13,
  },
  {
    id: 10674,
    localidad: "LEANDRO N ALEM",
    cp: 5518,
    provincia_id: 13,
  },
  {
    id: 10675,
    localidad: "LAVALLE VILLA TULUMAYA",
    cp: 5664,
    provincia_id: 13,
  },
  {
    id: 10676,
    localidad: "LAVALLE V TULUMAYA",
    cp: 5664,
    provincia_id: 13,
  },
  {
    id: 10677,
    localidad: "LAVALLE",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10678,
    localidad: "LAS VIZCACHAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10679,
    localidad: "LAS VISTAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10680,
    localidad: "LAS VISCACHERAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10681,
    localidad: "LAS VEGAS",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10682,
    localidad: "LAS TOTORITAS",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10683,
    localidad: "LAS ROSAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10684,
    localidad: "LAS MALVINAS",
    cp: 5635,
    provincia_id: 13,
  },
  {
    id: 10685,
    localidad: "LAS LOICAS",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10686,
    localidad: "LAS LAGUNAS",
    cp: 5680,
    provincia_id: 13,
  },
  {
    id: 10687,
    localidad: "LAS HERAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10688,
    localidad: "LAS HERAS",
    cp: 5540,
    provincia_id: 13,
  },
  {
    id: 10689,
    localidad: "LAS FLORES",
    cp: 5612,
    provincia_id: 13,
  },
  {
    id: 10690,
    localidad: "LAS CUEVAS",
    cp: 5557,
    provincia_id: 13,
  },
  {
    id: 10691,
    localidad: "LAS CRUCES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10692,
    localidad: "LAS CORTADERAS",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10693,
    localidad: "LAS COMPUERTAS",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10694,
    localidad: "LAS COLONIAS",
    cp: 5659,
    provincia_id: 13,
  },
  {
    id: 10695,
    localidad: "LAS CATITAS",
    cp: 5594,
    provincia_id: 13,
  },
  {
    id: 10696,
    localidad: "LAS CANTERAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10697,
    localidad: "LADISLAO CAMPOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10698,
    localidad: "LADISLAO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10699,
    localidad: "LA VENTANA",
    cp: 5577,
    provincia_id: 13,
  },
  {
    id: 10700,
    localidad: "LA VALENCIANA",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10701,
    localidad: "LA PUNTILLA",
    cp: 5505,
    provincia_id: 13,
  },
  {
    id: 10702,
    localidad: "LA PRIMAVERA",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10703,
    localidad: "LA PRIMAVERA",
    cp: 5527,
    provincia_id: 13,
  },
  {
    id: 10704,
    localidad: "LA PORTEQA",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10705,
    localidad: "LA POMONA",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10706,
    localidad: "LA PIRATA",
    cp: 5553,
    provincia_id: 13,
  },
  {
    id: 10707,
    localidad: "LA PEGA",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10708,
    localidad: "LA PAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10709,
    localidad: "LA MORA",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10710,
    localidad: "LA MONTILLA",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10711,
    localidad: "LA LLAVE",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10712,
    localidad: "LA LIBERTAD",
    cp: 5558,
    provincia_id: 13,
  },
  {
    id: 10713,
    localidad: "LA LIBERTAD",
    cp: 5939,
    provincia_id: 13,
  },
  {
    id: 10714,
    localidad: "LA LEONA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10715,
    localidad: "LA LEGUA",
    cp: 5679,
    provincia_id: 13,
  },
  {
    id: 10716,
    localidad: "LA LECHUGA",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10717,
    localidad: "LA JUNTA",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10718,
    localidad: "LA JAULA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10719,
    localidad: "LA JACINTITA",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10720,
    localidad: "LA ISLA",
    cp: 5685,
    provincia_id: 13,
  },
  {
    id: 10721,
    localidad: "LA ISLA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10722,
    localidad: "LA HORQUETA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10723,
    localidad: "LA FUNDICION",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10724,
    localidad: "LA FORTUNA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10725,
    localidad: "LA FAVORITA",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10726,
    localidad: "LA ESTANCIA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10727,
    localidad: "LA ESQUINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10728,
    localidad: "LA ESCANDINAVA",
    cp: 5647,
    provincia_id: 13,
  },
  {
    id: 10729,
    localidad: "LA DORMIDA",
    cp: 5592,
    provincia_id: 13,
  },
  {
    id: 10730,
    localidad: "LA CUEVA",
    cp: 5557,
    provincia_id: 13,
  },
  {
    id: 10731,
    localidad: "LA COSTA",
    cp: 5596,
    provincia_id: 13,
  },
  {
    id: 10732,
    localidad: "LA CORTADERA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10733,
    localidad: "LA CORTADERA",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10734,
    localidad: "LA CONSULTA",
    cp: 5567,
    provincia_id: 13,
  },
  {
    id: 10735,
    localidad: "LA COLONIA",
    cp: 5914,
    provincia_id: 13,
  },
  {
    id: 10736,
    localidad: "LA COLONIA",
    cp: 5566,
    provincia_id: 13,
  },
  {
    id: 10737,
    localidad: "LA COLONIA",
    cp: 5572,
    provincia_id: 13,
  },
  {
    id: 10738,
    localidad: "LA COLA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10739,
    localidad: "LA CHAPINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10740,
    localidad: "LA CENTRAL RIVADAVIA",
    cp: 5574,
    provincia_id: 13,
  },
  {
    id: 10741,
    localidad: "LA CENTRAL",
    cp: 5579,
    provincia_id: 13,
  },
  {
    id: 10742,
    localidad: "LA CAUTIVA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10743,
    localidad: "LA CASA DEL TIGRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10744,
    localidad: "LA CAQADA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10745,
    localidad: "LA CAQADA",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10746,
    localidad: "LA CALIFORNIA",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10747,
    localidad: "LA BOVEDA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10748,
    localidad: "LA ANGOSTURA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10749,
    localidad: "KM 935 DVIO FCGSM",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10750,
    localidad: "KILOMETRO 884",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10751,
    localidad: "KILOMETRO 882",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10752,
    localidad: "KILOMETRO 56",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10753,
    localidad: "JUNTA DE LOS RIOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10754,
    localidad: "JUNIN",
    cp: 5573,
    provincia_id: 13,
  },
  {
    id: 10755,
    localidad: "JULIO COMEGLIO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10756,
    localidad: "JUAN ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10757,
    localidad: "JUAN MILLAN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10758,
    localidad: "JUAN H ORTIZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10759,
    localidad: "JUAN B DUFAU",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10760,
    localidad: "JOSE SUAREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10761,
    localidad: "JOSE R MOLINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10762,
    localidad: "JOSE LUCERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10763,
    localidad: "JOSE FERNANDEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10764,
    localidad: "JOSE DIAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10765,
    localidad: "JOCOLI VIEJO",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10766,
    localidad: "JOCOLI",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10767,
    localidad: "JESUS NAZARENO",
    cp: 5638,
    provincia_id: 13,
  },
  {
    id: 10768,
    localidad: "JAIME PRATS",
    cp: 5623,
    provincia_id: 13,
  },
  {
    id: 10769,
    localidad: "J ORTUBIA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10770,
    localidad: "ISLA RETAMITO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10771,
    localidad: "ISLA GRANDE",
    cp: 5672,
    provincia_id: 13,
  },
  {
    id: 10772,
    localidad: "ISLA GRANDE",
    cp: 5587,
    provincia_id: 13,
  },
  {
    id: 10773,
    localidad: "ISLA CHICA",
    cp: 5587,
    provincia_id: 13,
  },
  {
    id: 10774,
    localidad: "IRINEO ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10775,
    localidad: "INGENIERO GUSTAVO ANDRE",
    cp: 5683,
    provincia_id: 13,
  },
  {
    id: 10776,
    localidad: "INGENIERO GIAGNONI",
    cp: 5582,
    provincia_id: 13,
  },
  {
    id: 10777,
    localidad: "INGENIERO BALLOFFET",
    cp: 5630,
    provincia_id: 13,
  },
  {
    id: 10778,
    localidad: "INGENIERO BALLOFET",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10779,
    localidad: "IGNACIO VILLEGAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10780,
    localidad: "IGLESIA",
    cp: 5684,
    provincia_id: 13,
  },
  {
    id: 10781,
    localidad: "HUAICOS DE RUFINO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10782,
    localidad: "HORNOS DE MOYANO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10783,
    localidad: "HORNITO DEL GRINGO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10784,
    localidad: "HONORIO ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10785,
    localidad: "HERMENEGILDO DIAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10786,
    localidad: "H GARZALA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10787,
    localidad: "H DE LOS TORDILLOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10788,
    localidad: "GUILLERMO DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10789,
    localidad: "GUIDO",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 10790,
    localidad: "GUAYMALLEN",
    cp: 5521,
    provincia_id: 13,
  },
  {
    id: 10791,
    localidad: "GUADALES",
    cp: 5609,
    provincia_id: 13,
  },
  {
    id: 10792,
    localidad: "GREGORIO ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10793,
    localidad: "GOUDGE",
    cp: 5631,
    provincia_id: 13,
  },
  {
    id: 10794,
    localidad: "GOICO",
    cp: 5609,
    provincia_id: 13,
  },
  {
    id: 10795,
    localidad: "GODOY CRUZ",
    cp: 5504,
    provincia_id: 13,
  },
  {
    id: 10796,
    localidad: "GODOY CRUZ",
    cp: 5501,
    provincia_id: 13,
  },
  {
    id: 10797,
    localidad: "GOBERNADOR CIVIT",
    cp: 5594,
    provincia_id: 13,
  },
  {
    id: 10798,
    localidad: "GOBERNADOR BENEGAS",
    cp: 5544,
    provincia_id: 13,
  },
  {
    id: 10799,
    localidad: "GILBERTO PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10800,
    localidad: "GERTRUDIS DE OJEDA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10801,
    localidad: "GERMAN MATURANO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10802,
    localidad: "GENERAL ORTEGA",
    cp: 5606,
    provincia_id: 13,
  },
  {
    id: 10803,
    localidad: "GENERAL GUTIERREZ",
    cp: 5511,
    provincia_id: 13,
  },
  {
    id: 10804,
    localidad: "GENERAL ALVEAR",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10805,
    localidad: "GASPAR CAMPOS",
    cp: 5609,
    provincia_id: 13,
  },
  {
    id: 10806,
    localidad: "GARGANTA DEL LEON",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10807,
    localidad: "FRUCTUOSO DIAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10808,
    localidad: "FRAY LUIS BELTRAN",
    cp: 5531,
    provincia_id: 13,
  },
  {
    id: 10809,
    localidad: "FRANCISCO ROJAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10810,
    localidad: "FRANCISCO MOLINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10811,
    localidad: "FLORENCIO ORDOQEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10812,
    localidad: "FLORENCIO MOLINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10813,
    localidad: "FLORENCIO GARRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10814,
    localidad: "FERMIN PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10815,
    localidad: "FELIPE GARRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10816,
    localidad: "FAVELLI",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10817,
    localidad: "FABRICIANO ROJAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10818,
    localidad: "EX FORTIN MALARGUE",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10819,
    localidad: "EVARISTO SALAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10820,
    localidad: "EVARISTO ACEVEDO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10821,
    localidad: "EUGENIO BUSTOS",
    cp: 5569,
    provincia_id: 13,
  },
  {
    id: 10822,
    localidad: "ESTANISLAO ORDOQEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10823,
    localidad: "ESTANCIA YALGUARAZ",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10824,
    localidad: "ESTANCIA VVICENCIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10825,
    localidad: "ESTANCIA USPALLATA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10826,
    localidad: "ESTANCIA LA VARITA",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10827,
    localidad: "ESTANCIA JOCOLI",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10828,
    localidad: "ESTANCIA CHACAICO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10829,
    localidad: "ESTACION USPALLATA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10830,
    localidad: "ESPINO",
    cp: 5657,
    provincia_id: 13,
  },
  {
    id: 10831,
    localidad: "ESPINILLO",
    cp: 5589,
    provincia_id: 13,
  },
  {
    id: 10832,
    localidad: "ESPEJO RESGUARDADO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10833,
    localidad: "ESPEJO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10834,
    localidad: "ERNESTO ALCARAZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10835,
    localidad: "EPIFANIO FERNANDEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10836,
    localidad: "EMPALME RESGUARDO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10837,
    localidad: "EMILIO NIETA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10838,
    localidad: "ELOY FUNES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10839,
    localidad: "EL ZAPATINO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10840,
    localidad: "EL ZAPALLAR",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10841,
    localidad: "EL ZAMPAL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10842,
    localidad: "EL ZAMPAL",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10843,
    localidad: "EL ZAMPAL",
    cp: 5561,
    provincia_id: 13,
  },
  {
    id: 10844,
    localidad: "EL VENTARRON",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10845,
    localidad: "EL VATRO",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10846,
    localidad: "EL VAQUERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10847,
    localidad: "EL TROPEZON",
    cp: 5629,
    provincia_id: 13,
  },
  {
    id: 10848,
    localidad: "EL TOLEDANO",
    cp: 5601,
    provincia_id: 13,
  },
  {
    id: 10849,
    localidad: "EL SOSNEADO",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10850,
    localidad: "EL SAUCE",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10851,
    localidad: "EL SALTO",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 10852,
    localidad: "EL RETIRO",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10853,
    localidad: "EL RETAMO",
    cp: 5537,
    provincia_id: 13,
  },
  {
    id: 10854,
    localidad: "EL RESGUARDO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10855,
    localidad: "EL REGADIO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10856,
    localidad: "EL PUESTITO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10857,
    localidad: "EL PLUMERITO",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10858,
    localidad: "EL PLUMERILLO",
    cp: 5542,
    provincia_id: 13,
  },
  {
    id: 10859,
    localidad: "EL PERINO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10860,
    localidad: "EL PERAL",
    cp: 5561,
    provincia_id: 13,
  },
  {
    id: 10861,
    localidad: "EL PARAISO",
    cp: 5531,
    provincia_id: 13,
  },
  {
    id: 10862,
    localidad: "EL NEVADO",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10863,
    localidad: "EL MIRADOR",
    cp: 5568,
    provincia_id: 13,
  },
  {
    id: 10864,
    localidad: "EL MEDANITO",
    cp: 5675,
    provincia_id: 13,
  },
  {
    id: 10865,
    localidad: "EL MANZANO",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10866,
    localidad: "EL LECHUCITO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10867,
    localidad: "EL JUNCALITO",
    cp: 5620,
    provincia_id: 13,
  },
  {
    id: 10868,
    localidad: "EL JILGUERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10869,
    localidad: "EL INFIERNO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10870,
    localidad: "EL GUERRINO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10871,
    localidad: "EL GONZANO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10872,
    localidad: "EL GIGANTILLO",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10873,
    localidad: "EL DIVISADERO",
    cp: 5583,
    provincia_id: 13,
  },
  {
    id: 10874,
    localidad: "EL DESVIO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10875,
    localidad: "EL CONSUELO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10876,
    localidad: "EL COLORADO",
    cp: 5595,
    provincia_id: 13,
  },
  {
    id: 10877,
    localidad: "EL CHILCAL",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10878,
    localidad: "EL CHALLAO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10879,
    localidad: "EL CHALET",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10880,
    localidad: "EL CHACAY",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 10881,
    localidad: "EL CERCADO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10882,
    localidad: "EL CENTRAL",
    cp: 5580,
    provincia_id: 13,
  },
  {
    id: 10883,
    localidad: "EL CEIBO",
    cp: 5644,
    provincia_id: 13,
  },
  {
    id: 10884,
    localidad: "EL CAVADO CHICO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10885,
    localidad: "EL CARRIZAL DE ABAJO",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 10886,
    localidad: "EL CARANCHITO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10887,
    localidad: "EL CAQITO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10888,
    localidad: "EL BUEN PASTOR",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10889,
    localidad: "EL BORBOLLON",
    cp: 5742,
    provincia_id: 13,
  },
  {
    id: 10890,
    localidad: "EL BORBOLLON",
    cp: 5666,
    provincia_id: 13,
  },
  {
    id: 10891,
    localidad: "EL BANDERON",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 10892,
    localidad: "EL ARBOLITO",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10893,
    localidad: "EL ALTO SALVADOR",
    cp: 5654,
    provincia_id: 13,
  },
  {
    id: 10894,
    localidad: "EL ALTO",
    cp: 5577,
    provincia_id: 13,
  },
  {
    id: 10895,
    localidad: "EL ALGARROBAL",
    cp: 5607,
    provincia_id: 13,
  },
  {
    id: 10896,
    localidad: "EL ALAMBRADO",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10897,
    localidad: "EL AGUILA",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 10898,
    localidad: "E VIUDA DE GOMEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10899,
    localidad: "E SAN MARTIN",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10900,
    localidad: "E ROSALES",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10901,
    localidad: "E PLUMERILLO",
    cp: 5541,
    provincia_id: 13,
  },
  {
    id: 10902,
    localidad: "E LAS VIZCACHERAS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10903,
    localidad: "E LA VIZCACHERA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10904,
    localidad: "E LA SALCEDINA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10905,
    localidad: "E EL CARRIZAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10906,
    localidad: "E CUEVA DEL TORO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10907,
    localidad: "E CASA DE PIEDRA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10908,
    localidad: "DULCE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10909,
    localidad: "DORREGO",
    cp: 5519,
    provincia_id: 13,
  },
  {
    id: 10910,
    localidad: "DORREGO",
    cp: 5526,
    provincia_id: 13,
  },
  {
    id: 10911,
    localidad: "DOROTEO ORDOQEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10912,
    localidad: "DONATO OJEDA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10913,
    localidad: "DOMINGO REAL",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10914,
    localidad: "DOMINGO OGA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10915,
    localidad: "DOMINGO LARA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10916,
    localidad: "DOMINGO GIMENEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10917,
    localidad: "DIONISIO ORTUBIA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10918,
    localidad: "DIONISIO ORDOQEZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10919,
    localidad: "DESAGUADERO",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 10920,
    localidad: "DELGADILLO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10921,
    localidad: "DANIEL MORGAN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10922,
    localidad: "DANIEL LUCERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10923,
    localidad: "DALMIRO ZAPATA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10924,
    localidad: "CUADRO NACIONAL",
    cp: 5607,
    provincia_id: 13,
  },
  {
    id: 10925,
    localidad: "CTO DEL TIGRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10926,
    localidad: "CRUZ LEDESMA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10927,
    localidad: "CRUZ DEL YUGO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10928,
    localidad: "CRUZ DE PIEDRA",
    cp: 5586,
    provincia_id: 13,
  },
  {
    id: 10929,
    localidad: "CRISTO REDENTOR",
    cp: 5557,
    provincia_id: 13,
  },
  {
    id: 10930,
    localidad: "COSTA DEL DIAMANTE",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10931,
    localidad: "COSTA DE ARAUJO",
    cp: 5535,
    provincia_id: 13,
  },
  {
    id: 10932,
    localidad: "CORRAL DEL MOLLE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10933,
    localidad: "CORRAL DE LORCA",
    cp: 5637,
    provincia_id: 13,
  },
  {
    id: 10934,
    localidad: "CORRAL DE CUERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10935,
    localidad: "CORONEL DORREGO",
    cp: 5626,
    provincia_id: 13,
  },
  {
    id: 10936,
    localidad: "CORDON DEL PLATA",
    cp: 5561,
    provincia_id: 13,
  },
  {
    id: 10937,
    localidad: "CORDON DE PLATA",
    cp: 5661,
    provincia_id: 13,
  },
  {
    id: 10938,
    localidad: "COQUIMBITO",
    cp: 5513,
    provincia_id: 13,
  },
  {
    id: 10939,
    localidad: "COQUIMBITO",
    cp: 5522,
    provincia_id: 13,
  },
  {
    id: 10940,
    localidad: "COMPUERTAS NEGRAS",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10941,
    localidad: "COMPUERTAS",
    cp: 5550,
    provincia_id: 13,
  },
  {
    id: 10942,
    localidad: "COMANDANTE SALAS",
    cp: 5594,
    provincia_id: 13,
  },
  {
    id: 10943,
    localidad: "COLONIA SEGOVIA",
    cp: 5525,
    provincia_id: 13,
  },
  {
    id: 10944,
    localidad: "COLONIA SEGOVIA",
    cp: 5646,
    provincia_id: 13,
  },
  {
    id: 10945,
    localidad: "COLONIA SANTA TERESA",
    cp: 5649,
    provincia_id: 13,
  },
  {
    id: 10946,
    localidad: "COLONIA RUSA",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10947,
    localidad: "COLONIA RICHET",
    cp: 5678,
    provincia_id: 13,
  },
  {
    id: 10948,
    localidad: "COLONIA LAS ROSAS",
    cp: 5662,
    provincia_id: 13,
  },
  {
    id: 10949,
    localidad: "COLONIA LAS ROSAS",
    cp: 5565,
    provincia_id: 13,
  },
  {
    id: 10950,
    localidad: "COLONIA LAS ROSAS",
    cp: 5852,
    provincia_id: 13,
  },
  {
    id: 10951,
    localidad: "COLONIA LAMBARE",
    cp: 5653,
    provincia_id: 13,
  },
  {
    id: 10952,
    localidad: "COLONIA ITALIANA",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 10953,
    localidad: "COLONIA ESPAÑOLA",
    cp: 5607,
    provincia_id: 13,
  },
  {
    id: 10954,
    localidad: "COLONIA ELENA",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 10955,
    localidad: "COLONIA EL REGADIO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10956,
    localidad: "COLONIA CANO",
    cp: 5524,
    provincia_id: 13,
  },
  {
    id: 10957,
    localidad: "COLONIA BOUQUET",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10958,
    localidad: "COLONIA BOMBAL",
    cp: 5529,
    provincia_id: 13,
  },
  {
    id: 10959,
    localidad: "COLONIA ALVEAR OESTE",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10960,
    localidad: "COLONIA ALVEAR",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 10961,
    localidad: "COLONIA ALEMANA",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10962,
    localidad: "COLONIA 3 DE MAYO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 10963,
    localidad: "COLON SANDALHO",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 10964,
    localidad: "COIHUECO",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 10965,
    localidad: "COCHICO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10966,
    localidad: "CLARENTINO ROLDAN",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10967,
    localidad: "CIUDAD DE JUNIN",
    cp: 5573,
    provincia_id: 13,
  },
  {
    id: 10968,
    localidad: "CIRILO MAHONA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10969,
    localidad: "CIRCUNVALACION",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 10970,
    localidad: "CHILECITO",
    cp: 5876,
    provincia_id: 13,
  },
  {
    id: 10971,
    localidad: "CHILECITO",
    cp: 5652,
    provincia_id: 13,
  },
  {
    id: 10972,
    localidad: "CHARRAS",
    cp: 5572,
    provincia_id: 13,
  },
  {
    id: 10973,
    localidad: "CHAQARAL REDONDO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10974,
    localidad: "CHAPANAY",
    cp: 5589,
    provincia_id: 13,
  },
  {
    id: 10975,
    localidad: "CHAMUSCAO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10976,
    localidad: "CHACRAS DE LIMA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 10977,
    localidad: "CHACRAS DE CORIA",
    cp: 5505,
    provincia_id: 13,
  },
  {
    id: 10978,
    localidad: "CHACRAS DE CORIA",
    cp: 5528,
    provincia_id: 13,
  },
  {
    id: 10979,
    localidad: "CERROS COLORADOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10980,
    localidad: "CERRO YARETA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10981,
    localidad: "CERRO YALGUARAS",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10982,
    localidad: "CERRO TUNDUQUERA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10983,
    localidad: "CERRO TOLOSA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10984,
    localidad: "CERRO TIGRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10985,
    localidad: "CERRO SAPO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10986,
    localidad: "CERRO SANTA MARIA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10987,
    localidad: "CERRO SAN LORENZO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10988,
    localidad: "CERRO PUQUIOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10989,
    localidad: "CERRO PUNTUDO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10990,
    localidad: "CERRO POZO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10991,
    localidad: "CERRO PONDERADO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10992,
    localidad: "CERRO PELADO",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 10993,
    localidad: "CERRO PANTA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10994,
    localidad: "CERRO PAMPA SECA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10995,
    localidad: "CERRO NEVADO",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 10996,
    localidad: "CERRO NEVADO",
    cp: 5610,
    provincia_id: 13,
  },
  {
    id: 10997,
    localidad: "CERRO MONTURA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10998,
    localidad: "CERRO MEXICO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 10999,
    localidad: "CERRO MELOCOTON",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11000,
    localidad: "CERRO MASILLAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11001,
    localidad: "CERRO MANANTIAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11002,
    localidad: "CERRO LAGAQOSO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11003,
    localidad: "CERRO LA MANO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11004,
    localidad: "CERRO L CORRALES",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11005,
    localidad: "CERRO JUAN POBRE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11006,
    localidad: "CERRO INVERNADA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11007,
    localidad: "CERRO HORQUETA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11008,
    localidad: "CERRO GRANDE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11009,
    localidad: "CERRO FUNDICION",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11010,
    localidad: "CERRO EL GUANACO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11011,
    localidad: "CERRO DURAZNO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11012,
    localidad: "CERRO DEL MEDIO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11013,
    localidad: "CERRO DEL CHACAY",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 11014,
    localidad: "CERRO DE LOS DEDOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11015,
    localidad: "CERRO DE LAS LEQAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11016,
    localidad: "CERRO CUPULA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11017,
    localidad: "CERRO CUERNO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11018,
    localidad: "CERRO CORTADERAS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11019,
    localidad: "CERRO COLOR",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11020,
    localidad: "CERRO CLEMENTINO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11021,
    localidad: "CERRO CIENAGA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11022,
    localidad: "CERRO CIELO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11023,
    localidad: "CERRO CHIQUERO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11024,
    localidad: "CERRO CATEDRAL",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11025,
    localidad: "CERRO BRAVO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11026,
    localidad: "CERRO BONETE",
    cp: 5545,
    provincia_id: 13,
  },
  {
    id: 11027,
    localidad: "CERRO BLANCO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11028,
    localidad: "CERRO BAY",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11029,
    localidad: "CERRO BARAUCA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11030,
    localidad: "CERRO ASPERO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11031,
    localidad: "CERRO ANGOSTURA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11032,
    localidad: "CERRO ALOJAMIENTO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11033,
    localidad: "CERRO AGUADITA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11034,
    localidad: "CERRO AGUA SALADA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11035,
    localidad: "CERRO ACONCAGUA",
    cp: 5500,
    provincia_id: 13,
  },
  {
    id: 11036,
    localidad: "CERRILLOS NEGROS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11037,
    localidad: "CASILLA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11038,
    localidad: "CARTELLONE",
    cp: 5658,
    provincia_id: 13,
  },
  {
    id: 11039,
    localidad: "CARRODILLA LA PUNTILLA",
    cp: 5505,
    provincia_id: 13,
  },
  {
    id: 11040,
    localidad: "CARRODILLA",
    cp: 5505,
    provincia_id: 13,
  },
  {
    id: 11041,
    localidad: "CARRIZAL DE ABAJO",
    cp: 5556,
    provincia_id: 13,
  },
  {
    id: 11042,
    localidad: "CARACOLES",
    cp: 5557,
    provincia_id: 13,
  },
  {
    id: 11043,
    localidad: "CAQADON DE BONILLA",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 11044,
    localidad: "CAPITAN MONTOYA",
    cp: 5601,
    provincia_id: 13,
  },
  {
    id: 11045,
    localidad: "CAPILLA DEL ROSARIO",
    cp: 5523,
    provincia_id: 13,
  },
  {
    id: 11046,
    localidad: "CAPDEVILLE",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 11047,
    localidad: "CAÑADA SECA",
    cp: 5603,
    provincia_id: 13,
  },
  {
    id: 11048,
    localidad: "CANALEJAS",
    cp: 5636,
    provincia_id: 13,
  },
  {
    id: 11049,
    localidad: "CAMPO EL TORO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11050,
    localidad: "CAMPO DE LOS ANDES",
    cp: 5565,
    provincia_id: 13,
  },
  {
    id: 11051,
    localidad: "CAMPO DE BATALLA",
    cp: 5435,
    provincia_id: 13,
  },
  {
    id: 11052,
    localidad: "CAMPAMENTOS",
    cp: 5579,
    provincia_id: 13,
  },
  {
    id: 11053,
    localidad: "CADETES DE CHILE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11054,
    localidad: "CACHEUTA",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 11055,
    localidad: "CA DEL DIABLO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11056,
    localidad: "C.LOS ANDES",
    cp: 5524,
    provincia_id: 13,
  },
  {
    id: 11057,
    localidad: "C RIQUITIPANCHE",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11058,
    localidad: "C PUNTILLA NEGRA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11059,
    localidad: "C PUNTA DE AGUA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11060,
    localidad: "C PAN DE AZUCAR",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11061,
    localidad: "C LOS DIENTITOS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11062,
    localidad: "C GONZALES VIDELA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11063,
    localidad: "C DEL RINCON BAYO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11064,
    localidad: "C DE LOS BURROS",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11065,
    localidad: "C D LS POTRERILLOS",
    cp: 5500,
    provincia_id: 13,
  },
  {
    id: 11066,
    localidad: "C ALVEAR OESTE",
    cp: 5632,
    provincia_id: 13,
  },
  {
    id: 11067,
    localidad: "BUTA BILLON",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 11068,
    localidad: "BUITRERA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11069,
    localidad: "BUENA NUEVA",
    cp: 5523,
    provincia_id: 13,
  },
  {
    id: 11070,
    localidad: "BUEN ORDEN",
    cp: 5670,
    provincia_id: 13,
  },
  {
    id: 11071,
    localidad: "BUEN ORDEN",
    cp: 5906,
    provincia_id: 13,
  },
  {
    id: 11072,
    localidad: "BOYEROS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11073,
    localidad: "BOWEN",
    cp: 5634,
    provincia_id: 13,
  },
  {
    id: 11074,
    localidad: "BLAS PANELO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11075,
    localidad: "BLANCO ENCALADA",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 11076,
    localidad: "BERMEJO",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 11077,
    localidad: "BENEGAS",
    cp: 5544,
    provincia_id: 13,
  },
  {
    id: 11078,
    localidad: "BECERRA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11079,
    localidad: "BARRIO SAN EDUARDO",
    cp: 5578,
    provincia_id: 13,
  },
  {
    id: 11080,
    localidad: "BARRIO RIVADAVIA",
    cp: 5554,
    provincia_id: 13,
  },
  {
    id: 11081,
    localidad: "BARRIO QUIMICOS M.D.C.1",
    cp: 5520,
    provincia_id: 13,
  },
  {
    id: 11082,
    localidad: "BARRIO LENCINA",
    cp: 5564,
    provincia_id: 13,
  },
  {
    id: 11083,
    localidad: "BARRIO JARDIN LUZURIAGA",
    cp: 5513,
    provincia_id: 13,
  },
  {
    id: 11084,
    localidad: "BARRIALES",
    cp: 5585,
    provincia_id: 13,
  },
  {
    id: 11085,
    localidad: "BARRANCAS",
    cp: 5517,
    provincia_id: 13,
  },
  {
    id: 11086,
    localidad: "BARDAS BLANCAS",
    cp: 5611,
    provincia_id: 13,
  },
  {
    id: 11087,
    localidad: "BARCALA",
    cp: 5587,
    provincia_id: 13,
  },
  {
    id: 11088,
    localidad: "BAQADO VERDE",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 11089,
    localidad: "BAÑADO VERDE",
    cp: 5598,
    provincia_id: 13,
  },
  {
    id: 11090,
    localidad: "BAJADA DEL PERRO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11091,
    localidad: "B PAJARO MUERTO",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11092,
    localidad: "B ELENA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11093,
    localidad: "B DE LA PAMPA SECA",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11094,
    localidad: "ASTICA",
    cp: 5681,
    provincia_id: 13,
  },
  {
    id: 11095,
    localidad: "ARROYITO",
    cp: 5537,
    provincia_id: 13,
  },
  {
    id: 11096,
    localidad: "ARISTIDES VILLANUEVA",
    cp: 5609,
    provincia_id: 13,
  },
  {
    id: 11097,
    localidad: "ARENALES",
    cp: 5539,
    provincia_id: 13,
  },
  {
    id: 11098,
    localidad: "ANTONIO SOSA",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11099,
    localidad: "ANDRES PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11100,
    localidad: "ANDRADE",
    cp: 5575,
    provincia_id: 13,
  },
  {
    id: 11101,
    localidad: "ANCHORIS",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 11102,
    localidad: "ANA DE DONAIRE",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11103,
    localidad: "ALVAREZ CONDARCO",
    cp: 5549,
    provincia_id: 13,
  },
  {
    id: 11104,
    localidad: "ALVAREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11105,
    localidad: "ALTO GRANDE",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 11106,
    localidad: "ALTO DEL SALVADOR",
    cp: 5570,
    provincia_id: 13,
  },
  {
    id: 11107,
    localidad: "ALTO DE LOS SAPOS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11108,
    localidad: "ALTO DE LOS PERROS",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11109,
    localidad: "ALTO BLANCO",
    cp: 5700,
    provincia_id: 13,
  },
  {
    id: 11110,
    localidad: "ALPATACAL",
    cp: 5591,
    provincia_id: 13,
  },
  {
    id: 11111,
    localidad: "ALGARROBO",
    cp: 5533,
    provincia_id: 13,
  },
  {
    id: 11112,
    localidad: "ALGARROBAL ARRIBA",
    cp: 5541,
    provincia_id: 13,
  },
  {
    id: 11113,
    localidad: "ALGARROBAL ABAJO",
    cp: 5541,
    provincia_id: 13,
  },
  {
    id: 11114,
    localidad: "ALFREDO LUCERO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11115,
    localidad: "ALEJANDRO PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11116,
    localidad: "AGUA ESCONDIDA",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 11117,
    localidad: "AGUA DE TORRE",
    cp: 5621,
    provincia_id: 13,
  },
  {
    id: 11118,
    localidad: "AGUA BOTADA",
    cp: 5613,
    provincia_id: 13,
  },
  {
    id: 11119,
    localidad: "AGRELO",
    cp: 5509,
    provincia_id: 13,
  },
  {
    id: 11120,
    localidad: "ADRIAN MATURANO",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11121,
    localidad: "A VIUDA DE PEREZ",
    cp: 5590,
    provincia_id: 13,
  },
  {
    id: 11122,
    localidad: "9 DE JULIO",
    cp: 5502,
    provincia_id: 13,
  },
  {
    id: 11123,
    localidad: "3 DE MAYO",
    cp: 5543,
    provincia_id: 13,
  },
  {
    id: 11124,
    localidad: "3 DE MAYO",
    cp: 5548,
    provincia_id: 13,
  },
  {
    id: 11125,
    localidad: "25 DE MAYO",
    cp: 5615,
    provincia_id: 13,
  },
  {
    id: 11126,
    localidad: "12 DE OCTUBRE",
    cp: 5597,
    provincia_id: 13,
  },
  {
    id: 11127,
    localidad: "YACOCHUYO",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11128,
    localidad: "VIQA DEL CERRO",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11129,
    localidad: "VINCHINA",
    cp: 5357,
    provincia_id: 12,
  },
  {
    id: 11130,
    localidad: "VILLA UNION",
    cp: 5350,
    provincia_id: 12,
  },
  {
    id: 11131,
    localidad: "VILLA SANTA RITA",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11132,
    localidad: "VILLA SANAGASTA",
    cp: 5096,
    provincia_id: 12,
  },
  {
    id: 11133,
    localidad: "VILLA SANAGASTA",
    cp: 5379,
    provincia_id: 12,
  },
  {
    id: 11134,
    localidad: "VILLA NIDIA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11135,
    localidad: "VILLA MAZAN",
    cp: 5314,
    provincia_id: 12,
  },
  {
    id: 11136,
    localidad: "VILLA LOS LEONES",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11137,
    localidad: "VILLA CORRAL",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11138,
    localidad: "VILLA CHEPES",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11139,
    localidad: "VILLA CASTELLI",
    cp: 5355,
    provincia_id: 12,
  },
  {
    id: 11140,
    localidad: "VILLA CASANA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11141,
    localidad: "VILLA BUSTOS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11142,
    localidad: "VICHIGASTA",
    cp: 5374,
    provincia_id: 12,
  },
  {
    id: 11143,
    localidad: "VERDE OLIVO",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11144,
    localidad: "VEGA",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11145,
    localidad: "VALLE HERMOSO",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11146,
    localidad: "VALLE HERMOSO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11147,
    localidad: "V S J DE VINCHINA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11148,
    localidad: "ULAPES",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11149,
    localidad: "UDPINANGO",
    cp: 5311,
    provincia_id: 12,
  },
  {
    id: 11150,
    localidad: "TUIZON",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11151,
    localidad: "TUCUMANAO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11152,
    localidad: "TUANI",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11153,
    localidad: "TRES CRUCES",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11154,
    localidad: "TRES CERROS",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11155,
    localidad: "TOTORAL",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11156,
    localidad: "TORRECITAS",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11157,
    localidad: "TMAS DE S TERESITA",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11158,
    localidad: "TINOCAN",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11159,
    localidad: "TILIMUQUI",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11160,
    localidad: "TERMAS",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11161,
    localidad: "TASQUIN",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11162,
    localidad: "TAMA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11163,
    localidad: "TALVA",
    cp: 5381,
    provincia_id: 12,
  },
  {
    id: 11164,
    localidad: "TALAMUYUNA",
    cp: 5304,
    provincia_id: 12,
  },
  {
    id: 11165,
    localidad: "TALA VERDE",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11166,
    localidad: "TABAQUILLO",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11167,
    localidad: "SOROCAYENSE",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11168,
    localidad: "SOLCA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11169,
    localidad: "SNAS DEL LEONCITO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11170,
    localidad: "SIMBOLAR",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11171,
    localidad: "SIERRA BRAVA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11172,
    localidad: "SIEMPRE VERDE",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11173,
    localidad: "SEQOR DE LA PEQA",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11174,
    localidad: "SCHAQUI",
    cp: 5329,
    provincia_id: 12,
  },
  {
    id: 11175,
    localidad: "SAUJIL D TINOGASTA",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11176,
    localidad: "SAQOGASTA",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11177,
    localidad: "SAÑOGASTA",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11178,
    localidad: "SANTO DOMINGO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11179,
    localidad: "SANTO DOMINGO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11180,
    localidad: "SANTO DOMINGO",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11181,
    localidad: "SANTA VERA CRUZ",
    cp: 5326,
    provincia_id: 12,
  },
  {
    id: 11182,
    localidad: "SANTA TERESA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11183,
    localidad: "SANTA FLORENTINA",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11184,
    localidad: "SANTA ELENA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11185,
    localidad: "SANTA CRUZ",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11186,
    localidad: "SANTA CRUZ",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11187,
    localidad: "SANTA CRUZ",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11188,
    localidad: "SANTA CLARA",
    cp: 5351,
    provincia_id: 12,
  },
  {
    id: 11189,
    localidad: "SANTA BARBARA",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11190,
    localidad: "SANTA ANA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11191,
    localidad: "SAN VICENTE",
    cp: 3580,
    provincia_id: 12,
  },
  {
    id: 11192,
    localidad: "SAN SOLANO",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11193,
    localidad: "SAN ROQUE",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11194,
    localidad: "SAN RAMON",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11195,
    localidad: "SAN RAMON",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11196,
    localidad: "SAN RAFAEL",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11197,
    localidad: "SAN RAFAEL",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11198,
    localidad: "SAN PEDRO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11199,
    localidad: "SAN PEDRO",
    cp: 5309,
    provincia_id: 12,
  },
  {
    id: 11200,
    localidad: "SAN NICOLAS",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11201,
    localidad: "SAN NICOLAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11202,
    localidad: "SAN NICOLAS",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11203,
    localidad: "SAN NICOLAS",
    cp: 5360,
    provincia_id: 12,
  },
  {
    id: 11204,
    localidad: "SAN MIGUEL",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11205,
    localidad: "SAN MIGUEL",
    cp: 5364,
    provincia_id: 12,
  },
  {
    id: 11206,
    localidad: "SAN MIGUEL",
    cp: 5308,
    provincia_id: 12,
  },
  {
    id: 11207,
    localidad: "SAN MARTIN",
    cp: 5263,
    provincia_id: 12,
  },
  {
    id: 11208,
    localidad: "SAN LORENZO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11209,
    localidad: "SAN JUAN",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11210,
    localidad: "SAN JOSE DE TINOGASTA",
    cp: 5339,
    provincia_id: 12,
  },
  {
    id: 11211,
    localidad: "SAN JOSE",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11212,
    localidad: "SAN JOSE",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11213,
    localidad: "SAN JOSE",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11214,
    localidad: "SAN JAVIER",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11215,
    localidad: "SAN ISIDRO-RIOJA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11216,
    localidad: "SAN ISIDRO",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11217,
    localidad: "SAN IGNACIO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11218,
    localidad: "SAN BERNARDO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11219,
    localidad: "SAN ANTONIO",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11220,
    localidad: "SAN ANTONIO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11221,
    localidad: "SAN ANTONIO",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11222,
    localidad: "SAN ANTONIO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11223,
    localidad: "SAN ANTONIO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11224,
    localidad: "SAMAY HUASI",
    cp: 5360,
    provincia_id: 12,
  },
  {
    id: 11225,
    localidad: "SALINAS DE BUSTOS",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11226,
    localidad: "SALICAS - SAN BLAS",
    cp: 5327,
    provincia_id: 12,
  },
  {
    id: 11227,
    localidad: "SALANA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11228,
    localidad: "S DGO DEPTO FAM",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11229,
    localidad: "RIVADAVIA",
    cp: 5355,
    provincia_id: 12,
  },
  {
    id: 11230,
    localidad: "RIO GRANDE",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11231,
    localidad: "RIO DE LAS CAQAS",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11232,
    localidad: "RETIRO DE COLANA",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11233,
    localidad: "RETAMAL",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11234,
    localidad: "REPRESA DE LA PUNTA",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11235,
    localidad: "REP DE LA PUNTA",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11236,
    localidad: "REAL DEL CADILLO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11237,
    localidad: "R DE LA POBLACION",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11238,
    localidad: "R 20 KILOMETRO 114",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11239,
    localidad: "QUEBRACHO HERRADO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11240,
    localidad: "QUEBRACHAL",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11241,
    localidad: "QOQUEVES",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11242,
    localidad: "Q. DEL VALLECITO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11243,
    localidad: "PZUELO DE LOS ARCE",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11244,
    localidad: "PUNTA DE LOS LLANOS",
    cp: 5384,
    provincia_id: 12,
  },
  {
    id: 11245,
    localidad: "PUNTA DE LA SIERRA",
    cp: 5719,
    provincia_id: 12,
  },
  {
    id: 11246,
    localidad: "PULUCHAN",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11247,
    localidad: "PUESTO DICHOSO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11248,
    localidad: "PUESTO DE CARRIZO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11249,
    localidad: "PUESTO  TALITA",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11250,
    localidad: "PUERTO DEL VALLE",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11251,
    localidad: "PUERTO ALEGRE",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11252,
    localidad: "PUERTO ALEGRE",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11253,
    localidad: "PUERTA DE LA Q",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11254,
    localidad: "PUCHUZUN",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11255,
    localidad: "PTO S ROS DE ABAJ",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11256,
    localidad: "PTA DE LOS LLANOS",
    cp: 5384,
    provincia_id: 12,
  },
  {
    id: 11257,
    localidad: "POZO DEL BARRIAL",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11258,
    localidad: "POZO DE PIEDRA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11259,
    localidad: "POZO DE LA YEGUA",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11260,
    localidad: "POZO DE LA PIEDRA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11261,
    localidad: "POZO DE LA ORILLA",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11262,
    localidad: "POZO BLANCO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11263,
    localidad: "POTRERO GRANDE",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11264,
    localidad: "PO DE LOS TEATINOS",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11265,
    localidad: "PO DE LAS OJOTAS",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11266,
    localidad: "PO DE BARAHONA",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11267,
    localidad: "PO D LS PIUQUENES",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11268,
    localidad: "PLAZA VIEJA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11269,
    localidad: "PLAZA NUEVA",
    cp: 5365,
    provincia_id: 12,
  },
  {
    id: 11270,
    localidad: "PITUIL",
    cp: 5370,
    provincia_id: 12,
  },
  {
    id: 11271,
    localidad: "PINCHAS",
    cp: 5307,
    provincia_id: 12,
  },
  {
    id: 11272,
    localidad: "PILLAHUASI",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11273,
    localidad: "PIEDRA PINTADA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11274,
    localidad: "PIEDRA LARGA",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11275,
    localidad: "PEQASQUITO",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11276,
    localidad: "PEQAS BLANCAS",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11277,
    localidad: "PAUL GROUSSAC",
    cp: 3585,
    provincia_id: 12,
  },
  {
    id: 11278,
    localidad: "PATQUIA",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11279,
    localidad: "PASO SAN FRANCISCO",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11280,
    localidad: "PAMPA BLANCA",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11281,
    localidad: "PALO LABRADO",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11282,
    localidad: "PALO LABRADO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11283,
    localidad: "PALO CORTADO",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11284,
    localidad: "PAGANZO",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11285,
    localidad: "PAGANCILLO",
    cp: 5369,
    provincia_id: 12,
  },
  {
    id: 11286,
    localidad: "PACHACO",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11287,
    localidad: "PACATALA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11288,
    localidad: "P MONTE GRANDE",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11289,
    localidad: "P DE TALAMPAYA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11290,
    localidad: "P DE LOS SCHEZ",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11291,
    localidad: "OLTA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11292,
    localidad: "OLPAS",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11293,
    localidad: "OLIVARES S NICOLAS",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11294,
    localidad: "NUEVA ESPERANZA",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11295,
    localidad: "NUEVA ESPERANZA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11296,
    localidad: "NONOGASTA",
    cp: 5372,
    provincia_id: 12,
  },
  {
    id: 11297,
    localidad: "NEPES",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11298,
    localidad: "NEGRO HUASI",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11299,
    localidad: "NACATE",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11300,
    localidad: "MONTE GRANDE",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11301,
    localidad: "MOLLACO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11302,
    localidad: "MOCOVI",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11303,
    localidad: "MIRANDA",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11304,
    localidad: "MINA SAN JORGE",
    cp: 5403,
    provincia_id: 12,
  },
  {
    id: 11305,
    localidad: "MILAGRO",
    cp: 5274,
    provincia_id: 12,
  },
  {
    id: 11306,
    localidad: "MICHANGO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11307,
    localidad: "MESILLAS BLANCAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11308,
    localidad: "MESADA GRANDE",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11309,
    localidad: "MAZAN",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11310,
    localidad: "MASCASIN",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11311,
    localidad: "MANANTIALES",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11312,
    localidad: "MALLIGASTA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11313,
    localidad: "MALCASCO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11314,
    localidad: "MALANZAN",
    cp: 5389,
    provincia_id: 12,
  },
  {
    id: 11315,
    localidad: "MACHIGASTA",
    cp: 5311,
    provincia_id: 12,
  },
  {
    id: 11316,
    localidad: "LOS TAMBILLOS",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11317,
    localidad: "LOS SARMIENTOS",
    cp: 5373,
    provincia_id: 12,
  },
  {
    id: 11318,
    localidad: "LOS ROBLES",
    cp: 5329,
    provincia_id: 12,
  },
  {
    id: 11319,
    localidad: "LOS ROBLEDOS",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11320,
    localidad: "LOS PALACIOS",
    cp: 5352,
    provincia_id: 12,
  },
  {
    id: 11321,
    localidad: "LOS MOLINOS",
    cp: 5328,
    provincia_id: 12,
  },
  {
    id: 11322,
    localidad: "LOS MOG COLORADOS",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11323,
    localidad: "LOS MISTOLES",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11324,
    localidad: "LOS MISTOLES",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11325,
    localidad: "LOS HORMIGUEROS",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11326,
    localidad: "LOS CORRALES",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11327,
    localidad: "LOS CORIAS",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11328,
    localidad: "LOS COLORADOS",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11329,
    localidad: "LOS CAJONES",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11330,
    localidad: "LOS BALDES",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11331,
    localidad: "LOS BALDES",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11332,
    localidad: "LOS BALDECITOS",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11333,
    localidad: "LOS ALGARROBOS",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11334,
    localidad: "LOS ALANICES",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11335,
    localidad: "LOS AGUIRRES",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11336,
    localidad: "LOMA LARGA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11337,
    localidad: "LOMA LARGA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11338,
    localidad: "LOMA BLANCA",
    cp: 5382,
    provincia_id: 12,
  },
  {
    id: 11339,
    localidad: "LAS VERTIENTES",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11340,
    localidad: "LAS TUSCAS",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11341,
    localidad: "LAS TUCUMANESAS",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11342,
    localidad: "LAS TOSCAS",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11343,
    localidad: "LAS TALAS",
    cp: 5329,
    provincia_id: 12,
  },
  {
    id: 11344,
    localidad: "LAS SIERRAS BRAVAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11345,
    localidad: "LAS SALINAS",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11346,
    localidad: "LAS PIEDRITAS",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11347,
    localidad: "LAS PEQAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11348,
    localidad: "LAS PEÑAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11349,
    localidad: "LAS PALOMAS",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11350,
    localidad: "LAS PADERCITAS",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11351,
    localidad: "LAS LATAS",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11352,
    localidad: "LAS HIGUERAS",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11353,
    localidad: "LAS GREDAS",
    cp: 5365,
    provincia_id: 12,
  },
  {
    id: 11354,
    localidad: "LAS CIENAGAS",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11355,
    localidad: "LAS CHACRITAS",
    cp: 5304,
    provincia_id: 12,
  },
  {
    id: 11356,
    localidad: "LAS CATAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11357,
    localidad: "LAS CASITAS",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11358,
    localidad: "LAS CAQAS",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11359,
    localidad: "LAS CAÑAS",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11360,
    localidad: "LAS BREAS",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11361,
    localidad: "LAS BOMBAS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11362,
    localidad: "LAS BARRANCAS",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11363,
    localidad: "LA ZANJA",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11364,
    localidad: "LA YESERA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11365,
    localidad: "LA YEGUA MUERTA",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11366,
    localidad: "LA TRAMPA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11367,
    localidad: "LA TORRE",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11368,
    localidad: "LA TORDILLA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11369,
    localidad: "LA TOMA",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11370,
    localidad: "LA SERENA",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11371,
    localidad: "LA ROSILLA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11372,
    localidad: "LA RIOJA",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11373,
    localidad: "LA RIOJA",
    cp: 5302,
    provincia_id: 12,
  },
  {
    id: 11374,
    localidad: "LA RIOJA",
    cp: 5305,
    provincia_id: 12,
  },
  {
    id: 11375,
    localidad: "LA RESERVA",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11376,
    localidad: "LA REPRESA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11377,
    localidad: "LA REPRESA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11378,
    localidad: "LA REFORMA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11379,
    localidad: "LA RAMADITA",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11380,
    localidad: "LA PUNTILLA",
    cp: 5360,
    provincia_id: 12,
  },
  {
    id: 11381,
    localidad: "LA PIRGUA",
    cp: 5325,
    provincia_id: 12,
  },
  {
    id: 11382,
    localidad: "LA PAMPA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11383,
    localidad: "LA PAMPA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11384,
    localidad: "LA MERCED",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11385,
    localidad: "LA LOMITA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11386,
    localidad: "LA LIBERTAD",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11387,
    localidad: "LA LANCHA",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11388,
    localidad: "LA LAGUNA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11389,
    localidad: "LA JARILLA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11390,
    localidad: "LA INVERNADA",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11391,
    localidad: "LA IGUALDAD",
    cp: 5474,
    provincia_id: 12,
  },
  {
    id: 11392,
    localidad: "LA HUERTA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11393,
    localidad: "LA HIGUERA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11394,
    localidad: "LA FLORIDA",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11395,
    localidad: "LA FLOR",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11396,
    localidad: "LA ESQUINA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11397,
    localidad: "LA ESQUINA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11398,
    localidad: "LA ESPERANZA",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11399,
    localidad: "LA ESCONDIDA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11400,
    localidad: "LA ENVIDIA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11401,
    localidad: "LA DORA",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11402,
    localidad: "LA CUADRA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11403,
    localidad: "LA CIENAGA",
    cp: 5381,
    provincia_id: 12,
  },
  {
    id: 11404,
    localidad: "LA CHIMENEA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11405,
    localidad: "LA CHILCA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11406,
    localidad: "LA CARBONERA",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11407,
    localidad: "LA CAPILLA",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11408,
    localidad: "LA CALLANA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11409,
    localidad: "LA CALERA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11410,
    localidad: "LA BUENA SUERTE",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11411,
    localidad: "LA BUENA ESTRELLA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11412,
    localidad: "LA BANDA",
    cp: 5365,
    provincia_id: 12,
  },
  {
    id: 11413,
    localidad: "LA BANDA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11414,
    localidad: "LA ARMONIA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11415,
    localidad: "LA ANTIGUA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11416,
    localidad: "LA AMERICA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11417,
    localidad: "LA ALUMBRERA",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11418,
    localidad: "LA AGUADITA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11419,
    localidad: "LA AGUADITA",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11420,
    localidad: "LA AGUADA GRANDE",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11421,
    localidad: "LA ABRA",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11422,
    localidad: "KILOMETRO 975",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11423,
    localidad: "KILOMETRO 921",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11424,
    localidad: "KILOMETRO 891",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11425,
    localidad: "KILOMETRO 875",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11426,
    localidad: "KILOMETRO 861",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11427,
    localidad: "KILOMETRO 732",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11428,
    localidad: "KILOMETRO 682",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11429,
    localidad: "KILOMETRO 67",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11430,
    localidad: "KILOMETRO 645",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11431,
    localidad: "KILOMETRO 619",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11432,
    localidad: "KILOMETRO 505",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11433,
    localidad: "KILOMETRO 421",
    cp: 3585,
    provincia_id: 12,
  },
  {
    id: 11434,
    localidad: "KILOMETRO 41",
    cp: 3583,
    provincia_id: 12,
  },
  {
    id: 11435,
    localidad: "KILOMETRO 403",
    cp: 3585,
    provincia_id: 12,
  },
  {
    id: 11436,
    localidad: "JOYANGUITO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11437,
    localidad: "JOYANCO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11438,
    localidad: "JAGUE",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11439,
    localidad: "ISMIANGO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11440,
    localidad: "ISLETA",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11441,
    localidad: "ISLA TIGRE",
    cp: 3583,
    provincia_id: 12,
  },
  {
    id: 11442,
    localidad: "ILISCO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11443,
    localidad: "ILIAR",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11444,
    localidad: "HUASCHA",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11445,
    localidad: "HUAJA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11446,
    localidad: "HUACO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11447,
    localidad: "HILARIO",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11448,
    localidad: "GUAYAPA",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11449,
    localidad: "GUASUNCHO",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11450,
    localidad: "GUANDACOL",
    cp: 5353,
    provincia_id: 12,
  },
  {
    id: 11451,
    localidad: "GUACHIN",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11452,
    localidad: "GENDARMERIA NAC",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11453,
    localidad: "G GORDILLO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11454,
    localidad: "FLAMENCO",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11455,
    localidad: "FCISCO ORTIZ DE OC",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11456,
    localidad: "FAMATINA",
    cp: 5365,
    provincia_id: 12,
  },
  {
    id: 11457,
    localidad: "FALDA DE CITAN",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11458,
    localidad: "ESTANCIA DE MAIZ",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11459,
    localidad: "ESTACION POMAN",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11460,
    localidad: "ESTACION MAZAN",
    cp: 5313,
    provincia_id: 12,
  },
  {
    id: 11461,
    localidad: "ESQUINA DEL SUD",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11462,
    localidad: "ESQUINA DEL NORTE",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11463,
    localidad: "EL VERDE",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11464,
    localidad: "EL VALLECITO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11465,
    localidad: "EL VALLE",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11466,
    localidad: "EL VALDECITO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11467,
    localidad: "EL TALA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11468,
    localidad: "EL TALA",
    cp: 5304,
    provincia_id: 12,
  },
  {
    id: 11469,
    localidad: "EL RODEO",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11470,
    localidad: "EL RETAMO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11471,
    localidad: "EL QUEMADO",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11472,
    localidad: "EL QUEMADO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11473,
    localidad: "EL QUEBRACHO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11474,
    localidad: "EL QUEBRACHO",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11475,
    localidad: "EL QUEBRACHAL",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11476,
    localidad: "EL PUESTO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11477,
    localidad: "EL PUEBLITO",
    cp: 5327,
    provincia_id: 12,
  },
  {
    id: 11478,
    localidad: "EL POTRERO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11479,
    localidad: "EL POTRERO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11480,
    localidad: "EL POTRERILLO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11481,
    localidad: "EL POTRERILLO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11482,
    localidad: "EL POTRERILLO",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11483,
    localidad: "EL POTRERIL G ROCA",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11484,
    localidad: "EL PORTEZUELO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11485,
    localidad: "EL PEDREGAL",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11486,
    localidad: "EL MOYANO",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11487,
    localidad: "EL MOLLAR",
    cp: 5381,
    provincia_id: 12,
  },
  {
    id: 11488,
    localidad: "EL JUMEAL",
    cp: 5365,
    provincia_id: 12,
  },
  {
    id: 11489,
    localidad: "EL HUACO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11490,
    localidad: "EL GARABATO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11491,
    localidad: "EL ESCONDIDO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11492,
    localidad: "EL CHUSCO",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11493,
    localidad: "EL CHUSCHIN",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11494,
    localidad: "EL CHORRILLO",
    cp: 5472,
    provincia_id: 12,
  },
  {
    id: 11495,
    localidad: "EL CHOCOY",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11496,
    localidad: "EL CHIFLON",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11497,
    localidad: "EL CHACHO",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11498,
    localidad: "EL CERCO",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11499,
    localidad: "EL CATORCE",
    cp: 5474,
    provincia_id: 12,
  },
  {
    id: 11500,
    localidad: "EL CARRIZAL",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11501,
    localidad: "EL CARRIZAL",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11502,
    localidad: "EL CANTADERO",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11503,
    localidad: "EL CALDEN",
    cp: 5717,
    provincia_id: 12,
  },
  {
    id: 11504,
    localidad: "EL BORDO",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11505,
    localidad: "EL BAYITO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11506,
    localidad: "EL BARRIAL",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11507,
    localidad: "EL BARREAL",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11508,
    localidad: "EL BARRANCO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11509,
    localidad: "EL BALDE",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11510,
    localidad: "EL ALTO",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11511,
    localidad: "EL ABRA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11512,
    localidad: "E MINERO C BLANCO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11513,
    localidad: "E LA PUNTILLA",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11514,
    localidad: "DISTRITO PUEBLO",
    cp: 5371,
    provincia_id: 12,
  },
  {
    id: 11515,
    localidad: "DISTRITO PUEBLO",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11516,
    localidad: "DIQUE DE ANZULON",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11517,
    localidad: "DESIDERIO TELLO",
    cp: 5474,
    provincia_id: 12,
  },
  {
    id: 11518,
    localidad: "CUIPAN",
    cp: 5329,
    provincia_id: 12,
  },
  {
    id: 11519,
    localidad: "CUEVA DEL CHACHO",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11520,
    localidad: "CUATRO ESQUINAS",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11521,
    localidad: "CORTADERAS",
    cp: 5381,
    provincia_id: 12,
  },
  {
    id: 11522,
    localidad: "CORT EMBARC FCGB",
    cp: 5474,
    provincia_id: 12,
  },
  {
    id: 11523,
    localidad: "CORRAL DE ISAAC",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11524,
    localidad: "CORDOBITA",
    cp: 5327,
    provincia_id: 12,
  },
  {
    id: 11525,
    localidad: "COMANDANTE LEAL",
    cp: 5274,
    provincia_id: 12,
  },
  {
    id: 11526,
    localidad: "COLONIA ALFREDO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11527,
    localidad: "COLON",
    cp: 5401,
    provincia_id: 12,
  },
  {
    id: 11528,
    localidad: "CISCO",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11529,
    localidad: "CILLA NIDIA",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11530,
    localidad: "CHUQUIS",
    cp: 5306,
    provincia_id: 12,
  },
  {
    id: 11531,
    localidad: "CHUMBICHA",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11532,
    localidad: "CHULO",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11533,
    localidad: "CHIMENEA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11534,
    localidad: "CHILECITO",
    cp: 5360,
    provincia_id: 12,
  },
  {
    id: 11535,
    localidad: "CHILA",
    cp: 5378,
    provincia_id: 12,
  },
  {
    id: 11536,
    localidad: "CHILA",
    cp: 5239,
    provincia_id: 12,
  },
  {
    id: 11537,
    localidad: "CHEPES",
    cp: 5470,
    provincia_id: 12,
  },
  {
    id: 11538,
    localidad: "CHELCOS",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11539,
    localidad: "CHAUPIHUASI",
    cp: 5327,
    provincia_id: 12,
  },
  {
    id: 11540,
    localidad: "CHAQARMUYO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11541,
    localidad: "CHAQAR",
    cp: 5277,
    provincia_id: 12,
  },
  {
    id: 11542,
    localidad: "CHAÑAR",
    cp: 5277,
    provincia_id: 12,
  },
  {
    id: 11543,
    localidad: "CHAMICAL",
    cp: 5380,
    provincia_id: 12,
  },
  {
    id: 11544,
    localidad: "CERRO PICHEREGUAS",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11545,
    localidad: "CERRO PANTEON",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11546,
    localidad: "CERRO NEGRO",
    cp: 5327,
    provincia_id: 12,
  },
  {
    id: 11547,
    localidad: "CERRO MERCEDARIO",
    cp: 5405,
    provincia_id: 12,
  },
  {
    id: 11548,
    localidad: "CEBOLLAR",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11549,
    localidad: "CEBOLLAR",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11550,
    localidad: "CATUNA",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11551,
    localidad: "CATINZACO",
    cp: 5374,
    provincia_id: 12,
  },
  {
    id: 11552,
    localidad: "CASTRO BARROS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11553,
    localidad: "CASTRO BARROS",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11554,
    localidad: "CASAS VIEJAS",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11555,
    localidad: "CASANGATE",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11556,
    localidad: "CASAGATE",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11557,
    localidad: "CASA PINTADA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11558,
    localidad: "CARRIZALILLO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11559,
    localidad: "CARRIZALILLO",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11560,
    localidad: "CARRIZAL TAMA",
    cp: 5376,
    provincia_id: 12,
  },
  {
    id: 11561,
    localidad: "CARRIZAL E FCGB",
    cp: 5300,
    provincia_id: 12,
  },
  {
    id: 11562,
    localidad: "CARRIZAL",
    cp: 5306,
    provincia_id: 12,
  },
  {
    id: 11563,
    localidad: "CAQADA VERDE",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11564,
    localidad: "CAQADA HONDA",
    cp: 5280,
    provincia_id: 12,
  },
  {
    id: 11565,
    localidad: "CAMPO TRES POZOS",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11566,
    localidad: "CAMPO REDONDO",
    cp: 3581,
    provincia_id: 12,
  },
  {
    id: 11567,
    localidad: "CAMPANAS",
    cp: 5366,
    provincia_id: 12,
  },
  {
    id: 11568,
    localidad: "CALERA LA NORMA",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11569,
    localidad: "CACHIYUYAL",
    cp: 5367,
    provincia_id: 12,
  },
  {
    id: 11570,
    localidad: "C ORTIZ DE OCAMPO",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11571,
    localidad: "C EMBARCADERO FCGB",
    cp: 5374,
    provincia_id: 12,
  },
  {
    id: 11572,
    localidad: "BUENA VISTA",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11573,
    localidad: "BOCA DE LA Q",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11574,
    localidad: "BELLA VISTA",
    cp: 5381,
    provincia_id: 12,
  },
  {
    id: 11575,
    localidad: "BAZAN",
    cp: 5306,
    provincia_id: 12,
  },
  {
    id: 11576,
    localidad: "BARRIO DE GALLI",
    cp: 5375,
    provincia_id: 12,
  },
  {
    id: 11577,
    localidad: "BARRANQUITAS",
    cp: 5474,
    provincia_id: 12,
  },
  {
    id: 11578,
    localidad: "BANDA FLORIDA",
    cp: 5351,
    provincia_id: 12,
  },
  {
    id: 11579,
    localidad: "BALUNGASTA",
    cp: 5340,
    provincia_id: 12,
  },
  {
    id: 11580,
    localidad: "BALDES DE PACHECO",
    cp: 5276,
    provincia_id: 12,
  },
  {
    id: 11581,
    localidad: "BALDE SAN ISIDRO",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11582,
    localidad: "BALDE SALADO",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11583,
    localidad: "BAJO JAGUEL",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11584,
    localidad: "BAJO JAGUE",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11585,
    localidad: "BAJO HONDO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11586,
    localidad: "BAJO GRANDE",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11587,
    localidad: "BAJO DE GALLO",
    cp: 5386,
    provincia_id: 12,
  },
  {
    id: 11588,
    localidad: "BAJO CORRAL DE ISAAC",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11589,
    localidad: "B DEL QUEBRACHO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11590,
    localidad: "B DEL PANTANO",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11591,
    localidad: "B CORRAL DE ISAAC",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11592,
    localidad: "ATILES",
    cp: 5388,
    provincia_id: 12,
  },
  {
    id: 11593,
    localidad: "ARAUCO",
    cp: 5311,
    provincia_id: 12,
  },
  {
    id: 11594,
    localidad: "APUYACO",
    cp: 5315,
    provincia_id: 12,
  },
  {
    id: 11595,
    localidad: "ANTINACO",
    cp: 5362,
    provincia_id: 12,
  },
  {
    id: 11596,
    localidad: "ANTINACO",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11597,
    localidad: "ANJULLON",
    cp: 5303,
    provincia_id: 12,
  },
  {
    id: 11598,
    localidad: "ANILLACO",
    cp: 5330,
    provincia_id: 12,
  },
  {
    id: 11599,
    localidad: "ANILLACO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11600,
    localidad: "ANGULOS",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11601,
    localidad: "ANGUINAN",
    cp: 5363,
    provincia_id: 12,
  },
  {
    id: 11602,
    localidad: "ANCHUMBIL",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11603,
    localidad: "ANCHICO",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11604,
    localidad: "AMINGA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11605,
    localidad: "AMBIL",
    cp: 5274,
    provincia_id: 12,
  },
  {
    id: 11606,
    localidad: "AMANA",
    cp: 5286,
    provincia_id: 12,
  },
  {
    id: 11607,
    localidad: "ALTOS DE LOS QUEB",
    cp: 5281,
    provincia_id: 12,
  },
  {
    id: 11608,
    localidad: "ALTO JAGUEL",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11609,
    localidad: "ALTO JAGUE",
    cp: 5359,
    provincia_id: 12,
  },
  {
    id: 11610,
    localidad: "ALTO CARRIZAL",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11611,
    localidad: "ALPASINCHE",
    cp: 5325,
    provincia_id: 12,
  },
  {
    id: 11612,
    localidad: "ALGARROBO GRANDE",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11613,
    localidad: "ALCAZAR",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11614,
    localidad: "AIMOGASTA",
    cp: 5310,
    provincia_id: 12,
  },
  {
    id: 11615,
    localidad: "AICUQA",
    cp: 5361,
    provincia_id: 12,
  },
  {
    id: 11616,
    localidad: "AGUAYO",
    cp: 5473,
    provincia_id: 12,
  },
  {
    id: 11617,
    localidad: "AGUADITA",
    cp: 5385,
    provincia_id: 12,
  },
  {
    id: 11618,
    localidad: "AGUADA",
    cp: 5301,
    provincia_id: 12,
  },
  {
    id: 11619,
    localidad: "AGUA DE LA PIEDRA",
    cp: 5475,
    provincia_id: 12,
  },
  {
    id: 11620,
    localidad: "AGUA COLORADA",
    cp: 5383,
    provincia_id: 12,
  },
  {
    id: 11621,
    localidad: "AGUA COLORADA",
    cp: 5275,
    provincia_id: 12,
  },
  {
    id: 11622,
    localidad: "AGUA BLANCA",
    cp: 5471,
    provincia_id: 12,
  },
  {
    id: 11623,
    localidad: "AGUA BLANCA",
    cp: 5356,
    provincia_id: 12,
  },
  {
    id: 11624,
    localidad: "AGUA BLANCA",
    cp: 5062,
    provincia_id: 12,
  },
  {
    id: 11625,
    localidad: "ADOLFO E CARRANZA",
    cp: 5263,
    provincia_id: 12,
  },
  {
    id: 11626,
    localidad: "9 DE JULIO",
    cp: 5272,
    provincia_id: 12,
  },
  {
    id: 11627,
    localidad: "ZONA URBANA NORTE",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11628,
    localidad: "ZONA RURAL DORILA",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11629,
    localidad: "ZONA RURAL",
    cp: 6369,
    provincia_id: 11,
  },
  {
    id: 11630,
    localidad: "ZONA RURAL",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11631,
    localidad: "ZAINA YEGUA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11632,
    localidad: "Z RURAL DE VERTIZ",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11633,
    localidad: "Z RURAL DE MIRASOL",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11634,
    localidad: "Z RURAL  METILEO",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11635,
    localidad: "WINIFREDA",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11636,
    localidad: "WATT",
    cp: 6270,
    provincia_id: 11,
  },
  {
    id: 11637,
    localidad: "VISTA ALEGRE",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11638,
    localidad: "VILLA SAURI",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 11639,
    localidad: "VILLA SAN JOSE",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11640,
    localidad: "VILLA MODERNA",
    cp: 6275,
    provincia_id: 11,
  },
  {
    id: 11641,
    localidad: "VILLA MIRASOL",
    cp: 6318,
    provincia_id: 11,
  },
  {
    id: 11642,
    localidad: "VILLA MENCUELLE",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 11643,
    localidad: "VILLA MARGARITA",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 11644,
    localidad: "VILLA BRANDA",
    cp: 6407,
    provincia_id: 11,
  },
  {
    id: 11645,
    localidad: "VILLA ALBA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11646,
    localidad: "VICTORICA",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11647,
    localidad: "VERTIZ",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11648,
    localidad: "VERTIZ",
    cp: 6366,
    provincia_id: 11,
  },
  {
    id: 11649,
    localidad: "VALLE D LOS ALAMOS",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 11650,
    localidad: "VALLE ARGENTINO",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 11651,
    localidad: "VALENTIN GOMEZ",
    cp: 6401,
    provincia_id: 11,
  },
  {
    id: 11652,
    localidad: "V DE LA PLAZA",
    cp: 6411,
    provincia_id: 11,
  },
  {
    id: 11653,
    localidad: "V CAST EST ERIZE",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 11654,
    localidad: "UTRACAN",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11655,
    localidad: "URIBURU",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 11656,
    localidad: "UNANUE",
    cp: 8215,
    provincia_id: 11,
  },
  {
    id: 11657,
    localidad: "UNANUE",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 11658,
    localidad: "TTE GRAL E MITRE",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 11659,
    localidad: "TRUBULUCO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11660,
    localidad: "TRONGE",
    cp: 6407,
    provincia_id: 11,
  },
  {
    id: 11661,
    localidad: "TRILI",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11662,
    localidad: "TRIBULUCI",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11663,
    localidad: "TRES NACIONES",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11664,
    localidad: "TRES BOTONES",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11665,
    localidad: "TRENEL",
    cp: 6369,
    provincia_id: 11,
  },
  {
    id: 11666,
    localidad: "TREBOLARES",
    cp: 6361,
    provincia_id: 11,
  },
  {
    id: 11667,
    localidad: "TRATAYEN",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 11668,
    localidad: "TRAICO GRANDE",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 11669,
    localidad: "TRAICO",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11670,
    localidad: "TOMAS ECHENIQUE",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 11671,
    localidad: "TOAY",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 11672,
    localidad: "TELEN",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11673,
    localidad: "T PIEDRA DL AGUILA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11674,
    localidad: "T M DE ANCHORENA",
    cp: 6322,
    provincia_id: 11,
  },
  {
    id: 11675,
    localidad: "SPELUZZI",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11676,
    localidad: "SOL DE MAYO",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11677,
    localidad: "SIMSON",
    cp: 6212,
    provincia_id: 11,
  },
  {
    id: 11678,
    localidad: "SEC. PRA CONHELLO",
    cp: 6383,
    provincia_id: 11,
  },
  {
    id: 11679,
    localidad: "SAUZAL BONITO",
    cp: 8319,
    provincia_id: 11,
  },
  {
    id: 11680,
    localidad: "SARAH",
    cp: 6229,
    provincia_id: 11,
  },
  {
    id: 11681,
    localidad: "SARAH",
    cp: 6228,
    provincia_id: 11,
  },
  {
    id: 11682,
    localidad: "SAQICO",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11683,
    localidad: "SANTO TOMAS",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 11684,
    localidad: "SANTO DOMINGO",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11685,
    localidad: "SANTO DOMINGO",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 11686,
    localidad: "SANTIAGO ORELLANO",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 11687,
    localidad: "SANTA STELLA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11688,
    localidad: "SANTA ROSA",
    cp: 6300,
    provincia_id: 11,
  },
  {
    id: 11689,
    localidad: "SANTA ROSA",
    cp: 6302,
    provincia_id: 11,
  },
  {
    id: 11690,
    localidad: "SANTA ROSA",
    cp: 6202,
    provincia_id: 11,
  },
  {
    id: 11691,
    localidad: "SANTA ROSA",
    cp: 6304,
    provincia_id: 11,
  },
  {
    id: 11692,
    localidad: "SANTA MARIA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 11693,
    localidad: "SANTA ISABEL",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11694,
    localidad: "SANTA ISABEL",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11695,
    localidad: "SANTA ISABEL",
    cp: 6324,
    provincia_id: 11,
  },
  {
    id: 11696,
    localidad: "SANTA ELVIRA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11697,
    localidad: "SANTA ELENA",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 11698,
    localidad: "SANTA ELENA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11699,
    localidad: "SANTA CLARA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 11700,
    localidad: "SANTA CATALINA",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11701,
    localidad: "SANTA AURELIA",
    cp: 6239,
    provincia_id: 11,
  },
  {
    id: 11702,
    localidad: "SANTA ANA",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 11703,
    localidad: "SAN SALVADOR",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 11704,
    localidad: "SAN RAMON",
    cp: 6383,
    provincia_id: 11,
  },
  {
    id: 11705,
    localidad: "SAN RAMON",
    cp: 6424,
    provincia_id: 11,
  },
  {
    id: 11706,
    localidad: "SAN PEDRO  ROLON",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11707,
    localidad: "SAN PEDRO",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11708,
    localidad: "SAN MIGUEL",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11709,
    localidad: "SAN MIGUEL",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11710,
    localidad: "SAN MANUEL",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11711,
    localidad: "SAN JUSTO",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11712,
    localidad: "SAN JUAN",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 11713,
    localidad: "SAN JUAN",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11714,
    localidad: "SAN JOSE  ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 11715,
    localidad: "SAN JOSE",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 11716,
    localidad: "SAN JOSE",
    cp: 6228,
    provincia_id: 11,
  },
  {
    id: 11717,
    localidad: "SAN JOSE",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11718,
    localidad: "SAN JOSE",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 11719,
    localidad: "SAN JOSE",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11720,
    localidad: "SAN JOSE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11721,
    localidad: "SAN JORGE",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 11722,
    localidad: "SAN JOAQUIN",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 11723,
    localidad: "SAN JOAQUIN",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11724,
    localidad: "SAN ILDEFONSO",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11725,
    localidad: "SAN HUMBERTO",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 11726,
    localidad: "SAN FRANCISCO",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11727,
    localidad: "SAN FERNANDO",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11728,
    localidad: "SAN FELIPE",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11729,
    localidad: "SAN ERNESTO",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11730,
    localidad: "SAN EMILIO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11731,
    localidad: "SAN EDUARDO",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11732,
    localidad: "SAN DIEGO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11733,
    localidad: "SAN CARLOS",
    cp: 6389,
    provincia_id: 11,
  },
  {
    id: 11734,
    localidad: "SAN CARLOS",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 11735,
    localidad: "SAN BERNARDO",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11736,
    localidad: "SAN BERNARDO",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11737,
    localidad: "SAN BENITO",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11738,
    localidad: "SAN AQUILINO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11739,
    localidad: "SAN ANTONIO",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 11740,
    localidad: "SAN ANTONIO",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11741,
    localidad: "SAN ALBERTO",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11742,
    localidad: "S P DEL CHAQAR",
    cp: 8306,
    provincia_id: 11,
  },
  {
    id: 11743,
    localidad: "S MIGUEL ARCANGEL",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 11744,
    localidad: "S MARI MANUEL",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 11745,
    localidad: "S JOAQUIN METILEO",
    cp: 6367,
    provincia_id: 11,
  },
  {
    id: 11746,
    localidad: "S GDES HIDALGO",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11747,
    localidad: "S F DE LA RAMADA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11748,
    localidad: "RUCANELO",
    cp: 6382,
    provincia_id: 11,
  },
  {
    id: 11749,
    localidad: "RUCAHUE",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11750,
    localidad: "ROOSV P RIVADAVIA",
    cp: 6420,
    provincia_id: 11,
  },
  {
    id: 11751,
    localidad: "ROLÓN",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11752,
    localidad: "ROLON",
    cp: 6306,
    provincia_id: 11,
  },
  {
    id: 11753,
    localidad: "ROLITO E FCGB",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 11754,
    localidad: "RIO COLORADO",
    cp: 8138,
    provincia_id: 11,
  },
  {
    id: 11755,
    localidad: "RINCON DE EMILIO",
    cp: 8300,
    provincia_id: 11,
  },
  {
    id: 11756,
    localidad: "RIGLOS",
    cp: 6301,
    provincia_id: 11,
  },
  {
    id: 11757,
    localidad: "RICARDO LAVALLE",
    cp: 6312,
    provincia_id: 11,
  },
  {
    id: 11758,
    localidad: "REMECO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11759,
    localidad: "REMECO",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 11760,
    localidad: "RELMO",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11761,
    localidad: "RELMO",
    cp: 6332,
    provincia_id: 11,
  },
  {
    id: 11762,
    localidad: "REALICO",
    cp: 6200,
    provincia_id: 11,
  },
  {
    id: 11763,
    localidad: "RANCUL",
    cp: 6214,
    provincia_id: 11,
  },
  {
    id: 11764,
    localidad: "R. DE LOS SAUCES",
    cp: 8319,
    provincia_id: 11,
  },
  {
    id: 11765,
    localidad: "QUINTANA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11766,
    localidad: "QUETREQUEN",
    cp: 6212,
    provincia_id: 11,
  },
  {
    id: 11767,
    localidad: "QUEMU QUEMU",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11768,
    localidad: "QUEHUE",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11769,
    localidad: "PUNTA DE SIERRA",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 11770,
    localidad: "PUELEN",
    cp: 8213,
    provincia_id: 11,
  },
  {
    id: 11771,
    localidad: "PUELCHES",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 11772,
    localidad: "PUEBLO QUINTANA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11773,
    localidad: "PUEBLO NUEVO",
    cp: 8322,
    provincia_id: 11,
  },
  {
    id: 11774,
    localidad: "PUEBLO ALASSA",
    cp: 6208,
    provincia_id: 11,
  },
  {
    id: 11775,
    localidad: "PRADERE JUAN A",
    cp: 6231,
    provincia_id: 11,
  },
  {
    id: 11776,
    localidad: "PORTEZUELO GRANDE",
    cp: 8300,
    provincia_id: 11,
  },
  {
    id: 11777,
    localidad: "PORTADA COVUNCO",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 11778,
    localidad: "POITAGUE",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11779,
    localidad: "POCITO",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 11780,
    localidad: "PLANICIE BANDERITA",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 11781,
    localidad: "PIEDRA PINTADA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11782,
    localidad: "PICHI MERICO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11783,
    localidad: "PICHI  HUINCA",
    cp: 6388,
    provincia_id: 11,
  },
  {
    id: 11784,
    localidad: "PICHE CONA LAUQUEN",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11785,
    localidad: "PERU",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11786,
    localidad: "PEQAS BLANCAS",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 11787,
    localidad: "PEHUELCHES",
    cp: 6409,
    provincia_id: 11,
  },
  {
    id: 11788,
    localidad: "PAVON",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11789,
    localidad: "PASO LA RAZON",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11790,
    localidad: "PASO LA BALSA",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 11791,
    localidad: "PASO DE LOS ALGARROBOS",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11792,
    localidad: "PARQUE LURO",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 11793,
    localidad: "PARERA",
    cp: 6213,
    provincia_id: 11,
  },
  {
    id: 11794,
    localidad: "PAPIN",
    cp: 6411,
    provincia_id: 11,
  },
  {
    id: 11795,
    localidad: "PANTANITOS",
    cp: 8313,
    provincia_id: 11,
  },
  {
    id: 11796,
    localidad: "PAMPA DEL TIGRE",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11797,
    localidad: "PADRE BUODO",
    cp: 8200,
    provincia_id: 11,
  },
  {
    id: 11798,
    localidad: "P H  BATERIAS",
    cp: 8319,
    provincia_id: 11,
  },
  {
    id: 11799,
    localidad: "P DE LOS PUNTANOS",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11800,
    localidad: "P D LS ALGARROBOS",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11801,
    localidad: "OJO DE AGUA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11802,
    localidad: "OJO DE AGUA",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 11803,
    localidad: "OJEDA",
    cp: 6207,
    provincia_id: 11,
  },
  {
    id: 11804,
    localidad: "OFICIAL E SEGURA",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 11805,
    localidad: "NUEVA GALIA",
    cp: 6217,
    provincia_id: 11,
  },
  {
    id: 11806,
    localidad: "NOGUEIRA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11807,
    localidad: "NICOLAS VERA",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11808,
    localidad: "NERRE CO",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 11809,
    localidad: "NAZCA",
    cp: 6270,
    provincia_id: 11,
  },
  {
    id: 11810,
    localidad: "NAUPA HUEN",
    cp: 8313,
    provincia_id: 11,
  },
  {
    id: 11811,
    localidad: "NARCISO LEVEN",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11812,
    localidad: "NANQUEL HUITRE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11813,
    localidad: "NAICO",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 11814,
    localidad: "NAHUEL NAPA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11815,
    localidad: "MOORES",
    cp: 6230,
    provincia_id: 11,
  },
  {
    id: 11816,
    localidad: "MONTE RALO",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 11817,
    localidad: "MONTE NIEVAS",
    cp: 6383,
    provincia_id: 11,
  },
  {
    id: 11818,
    localidad: "MONTE COCHEQUINGAN",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11819,
    localidad: "MODESTINO PIZARRO",
    cp: 6273,
    provincia_id: 11,
  },
  {
    id: 11820,
    localidad: "MOCOVI",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 11821,
    localidad: "MINISTRO ORLANDO",
    cp: 6367,
    provincia_id: 11,
  },
  {
    id: 11822,
    localidad: "MINAS DE SAL",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11823,
    localidad: "MIGUEL RIGLOS",
    cp: 6301,
    provincia_id: 11,
  },
  {
    id: 11824,
    localidad: "MIGUEL CANE",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11825,
    localidad: "MICHI HONOCA",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 11826,
    localidad: "METILEO",
    cp: 6367,
    provincia_id: 11,
  },
  {
    id: 11827,
    localidad: "MERIDIANO VO",
    cp: 6239,
    provincia_id: 11,
  },
  {
    id: 11828,
    localidad: "MELIDEO",
    cp: 6270,
    provincia_id: 11,
  },
  {
    id: 11829,
    localidad: "MEDANOS NEGROS",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11830,
    localidad: "MAZA",
    cp: 6343,
    provincia_id: 11,
  },
  {
    id: 11831,
    localidad: "MAYACO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11832,
    localidad: "MAURICIO MAYER",
    cp: 6316,
    provincia_id: 11,
  },
  {
    id: 11833,
    localidad: "MARTIN FIERRO",
    cp: 6400,
    provincia_id: 11,
  },
  {
    id: 11834,
    localidad: "MARIANO MIRO",
    cp: 6228,
    provincia_id: 11,
  },
  {
    id: 11835,
    localidad: "MARIA P MORENO",
    cp: 6337,
    provincia_id: 11,
  },
  {
    id: 11836,
    localidad: "MARIA",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11837,
    localidad: "MARI MARI",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11838,
    localidad: "MANANTIALES",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11839,
    localidad: "MAISONNAVE",
    cp: 6211,
    provincia_id: 11,
  },
  {
    id: 11840,
    localidad: "MACACHIN",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 11841,
    localidad: "M DE LA PAMPA",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 11842,
    localidad: "LUNA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11843,
    localidad: "LUAN TORO",
    cp: 6317,
    provincia_id: 11,
  },
  {
    id: 11844,
    localidad: "LOVENTUEL",
    cp: 6317,
    provincia_id: 11,
  },
  {
    id: 11845,
    localidad: "LOTE 9 ESCUELA 140",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11846,
    localidad: "LOTE 8 ESCUELA 184",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11847,
    localidad: "LOTE 8 ESCUELA 179",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11848,
    localidad: "LOTE 8 ESCUELA 141",
    cp: 6369,
    provincia_id: 11,
  },
  {
    id: 11849,
    localidad: "LOTE 8  E 179",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11850,
    localidad: "LOTE 8",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11851,
    localidad: "LOTE 7 ESCUELA 270",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11852,
    localidad: "LOTE 6 ESCUELA 171",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11853,
    localidad: "LOTE 5 LUAN TORO",
    cp: 6317,
    provincia_id: 11,
  },
  {
    id: 11854,
    localidad: "LOTE 4",
    cp: 6387,
    provincia_id: 11,
  },
  {
    id: 11855,
    localidad: "LOTE 25 E 178",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11856,
    localidad: "LOTE 25 E 146",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 11857,
    localidad: "LOTE 25 CONHELO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 11858,
    localidad: "LOTE 24 SECCION 1A",
    cp: 6383,
    provincia_id: 11,
  },
  {
    id: 11859,
    localidad: "LOTE 23 E 264",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11860,
    localidad: "LOTE 23 E 221",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11861,
    localidad: "LOTE 22 IPIQA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11862,
    localidad: "LOTE 21 C S ELENA",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11863,
    localidad: "LOTE 20 LA CARLOTA",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11864,
    localidad: "LOTE 20",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11865,
    localidad: "LOTE 2 ESCUELA 185",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11866,
    localidad: "LOTE 18 E 158",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11867,
    localidad: "LOTE 17 ESCUELA 95",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 11868,
    localidad: "LOTE 17 E 121",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11869,
    localidad: "LOTE 15 ESCUELA 18",
    cp: 6387,
    provincia_id: 11,
  },
  {
    id: 11870,
    localidad: "LOTE 13 E 173",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11871,
    localidad: "LOTE 12",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 11872,
    localidad: "LOTE 12",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 11873,
    localidad: "LOTE 11 BERNASCONI",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11874,
    localidad: "LOS TURCOS",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11875,
    localidad: "LOS TRES POZOS",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11876,
    localidad: "LOS TOROS",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 11877,
    localidad: "LOS TAJAMARES",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11878,
    localidad: "LOS SAUCES",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 11879,
    localidad: "LOS SAUCES",
    cp: 8313,
    provincia_id: 11,
  },
  {
    id: 11880,
    localidad: "LOS PIRINEOS",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 11881,
    localidad: "LOS OLIVOS",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11882,
    localidad: "LOS MUCHACHOS",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 11883,
    localidad: "LOS MANANTIALES",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11884,
    localidad: "LOS LOBOS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11885,
    localidad: "LOS LAURELES",
    cp: 6230,
    provincia_id: 11,
  },
  {
    id: 11886,
    localidad: "LOS GAUCHOS",
    cp: 6343,
    provincia_id: 11,
  },
  {
    id: 11887,
    localidad: "LOS DURAZNOS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11888,
    localidad: "LOS DOS RIOS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11889,
    localidad: "LOS DOS HERMANOS",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11890,
    localidad: "LOS CHINITOS",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 11891,
    localidad: "LOS CHIHUIDOS",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 11892,
    localidad: "LOS CAMPAMENTOS",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11893,
    localidad: "LOS CALDENES",
    cp: 6230,
    provincia_id: 11,
  },
  {
    id: 11894,
    localidad: "LOS BURGOS",
    cp: 5590,
    provincia_id: 11,
  },
  {
    id: 11895,
    localidad: "LOS AMARILLOS",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11896,
    localidad: "LOS ALFALFARES",
    cp: 6275,
    provincia_id: 11,
  },
  {
    id: 11897,
    localidad: "LOS ALAMOS",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 11898,
    localidad: "LOO CO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 11899,
    localidad: "LONQUIMAY",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11900,
    localidad: "LOMAS OMBU",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11901,
    localidad: "LOMAS DE GATICA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11902,
    localidad: "LOMA REDONDA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11903,
    localidad: "LOMA DE LA LATA",
    cp: 8300,
    provincia_id: 11,
  },
  {
    id: 11904,
    localidad: "LINDO VER",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 11905,
    localidad: "LIHUE CALEL",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 11906,
    localidad: "LERTORA",
    cp: 6400,
    provincia_id: 11,
  },
  {
    id: 11907,
    localidad: "LEONA REDONDA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11908,
    localidad: "LAS TRES HERMANAS",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11909,
    localidad: "LAS QUINTAS",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 11910,
    localidad: "LAS PIEDRITAS",
    cp: 6387,
    provincia_id: 11,
  },
  {
    id: 11911,
    localidad: "LAS PERLAS",
    cp: 8300,
    provincia_id: 11,
  },
  {
    id: 11912,
    localidad: "LAS LAGUNAS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11913,
    localidad: "LAS GUASQUITAS",
    cp: 6400,
    provincia_id: 11,
  },
  {
    id: 11914,
    localidad: "LAS GITANAS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11915,
    localidad: "LAS FELICITAS",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11916,
    localidad: "LAS CORTADERAS",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11917,
    localidad: "LAS CHACRAS",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 11918,
    localidad: "LARSEN",
    cp: 6275,
    provincia_id: 11,
  },
  {
    id: 11919,
    localidad: "LAGUNA REDONDA",
    cp: 6400,
    provincia_id: 11,
  },
  {
    id: 11920,
    localidad: "LAGUNA MIRANDA",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 11921,
    localidad: "LAGO PELLEGRINI",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 11922,
    localidad: "LABAL",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11923,
    localidad: "LA ZOTA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11924,
    localidad: "LA VIOLETA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11925,
    localidad: "LA VICTORIA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11926,
    localidad: "LA VICTORIA",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 11927,
    localidad: "LA VERDE  ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 11928,
    localidad: "LA VERDE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11929,
    localidad: "LA VERDE",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11930,
    localidad: "LA VEINTITRES",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11931,
    localidad: "LA VARITA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11932,
    localidad: "LA VANGUARDIA",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 11933,
    localidad: "LA VACA",
    cp: 6389,
    provincia_id: 11,
  },
  {
    id: 11934,
    localidad: "LA UNION",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11935,
    localidad: "LA UNION",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11936,
    localidad: "LA UNIDA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11937,
    localidad: "LA TRAVESIA",
    cp: 6389,
    provincia_id: 11,
  },
  {
    id: 11938,
    localidad: "LA TORERA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 11939,
    localidad: "LA TINAJERA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11940,
    localidad: "LA TIGRA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11941,
    localidad: "LA TERESITA",
    cp: 6361,
    provincia_id: 11,
  },
  {
    id: 11942,
    localidad: "LA SOLEDAD",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11943,
    localidad: "LA SIN NOMBRE",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 11944,
    localidad: "LA SEQA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11945,
    localidad: "LA SEGUNDA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11946,
    localidad: "LA SARITA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11947,
    localidad: "LA ROSA",
    cp: 8133,
    provincia_id: 11,
  },
  {
    id: 11948,
    localidad: "LA RESERVA  ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 11949,
    localidad: "LA REBECA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11950,
    localidad: "LA RAZON S ISABEL",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11951,
    localidad: "LA RAZON",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11952,
    localidad: "LA R IVANOWSKY",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 11953,
    localidad: "LA PUQALADA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11954,
    localidad: "LA PUNA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11955,
    localidad: "LA PUMA",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 11956,
    localidad: "LA PUMA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11957,
    localidad: "LA PRIMERA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11958,
    localidad: "LA PRIMAVERA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11959,
    localidad: "LA PORTEQA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11960,
    localidad: "LA PORTEQA",
    cp: 6407,
    provincia_id: 11,
  },
  {
    id: 11961,
    localidad: "LA PINTADA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 11962,
    localidad: "LA PIEDAD",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 11963,
    localidad: "LA PERLITA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11964,
    localidad: "LA PERLITA",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 11965,
    localidad: "LA PERLA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11966,
    localidad: "LA PENCOSA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11967,
    localidad: "LA PAZ",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 11968,
    localidad: "LA PASTORIL",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 11969,
    localidad: "LA PALA",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 11970,
    localidad: "LA ORACION",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 11971,
    localidad: "LA OLLA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 11972,
    localidad: "LA NUEVA provincia_id",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11973,
    localidad: "LA NUEVA",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 11974,
    localidad: "LA NACIONAL",
    cp: 6275,
    provincia_id: 11,
  },
  {
    id: 11975,
    localidad: "LA MOROCHA",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 11976,
    localidad: "LA MATILDE",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 11977,
    localidad: "LA MARUJA",
    cp: 6386,
    provincia_id: 11,
  },
  {
    id: 11978,
    localidad: "LA MARIA VERTIZ",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 11979,
    localidad: "LA MARIA ROSA",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 11980,
    localidad: "LA MARIA INES",
    cp: 8138,
    provincia_id: 11,
  },
  {
    id: 11981,
    localidad: "LA MARIA ELISA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11982,
    localidad: "LA MARIA ELENA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11983,
    localidad: "LA MARIA",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 11984,
    localidad: "LA MARIA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 11985,
    localidad: "LA MARGARITA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11986,
    localidad: "LA MARCELA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11987,
    localidad: "LA MARAVILLA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11988,
    localidad: "LA MANUELITA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 11989,
    localidad: "LA LUZ",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11990,
    localidad: "LA LUZ",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 11991,
    localidad: "LA LUNINA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 11992,
    localidad: "LA LUISA",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 11993,
    localidad: "LA LUCINDA",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 11994,
    localidad: "LA LINDA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11995,
    localidad: "LA LEQA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 11996,
    localidad: "LA LAURENTINA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 11997,
    localidad: "LA JUANITA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 11998,
    localidad: "LA JUANITA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 11999,
    localidad: "LA JOSEFINA",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12000,
    localidad: "LA JOSEFA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 12001,
    localidad: "LA ISABEL",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12002,
    localidad: "LA ISABEL",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12003,
    localidad: "LA ISABEL",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 12004,
    localidad: "LA IMARRA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12005,
    localidad: "LA HUMADA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12006,
    localidad: "LA GUEQITA",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 12007,
    localidad: "LA GUADALOSA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12008,
    localidad: "LA GLORIA",
    cp: 6349,
    provincia_id: 11,
  },
  {
    id: 12009,
    localidad: "LA GAVENITA",
    cp: 6361,
    provincia_id: 11,
  },
  {
    id: 12010,
    localidad: "LA FLORIDA",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 12011,
    localidad: "LA FLORIDA",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12012,
    localidad: "LA FLORENCIA",
    cp: 6317,
    provincia_id: 11,
  },
  {
    id: 12013,
    localidad: "LA FE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12014,
    localidad: "LA EULOGIA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12015,
    localidad: "LA ESTRELLA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12016,
    localidad: "LA ESTHER",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12017,
    localidad: "LA ESPZA VERTIZ",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 12018,
    localidad: "LA ESPZA  MACACHIN",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12019,
    localidad: "LA ESPZA  HIDALGO",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12020,
    localidad: "LA ESPZA  ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12021,
    localidad: "LA ESPERANZA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12022,
    localidad: "LA ESPERANZA",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12023,
    localidad: "LA ESMERALDA",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 12024,
    localidad: "LA ESMERALDA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12025,
    localidad: "LA ESMERALDA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12026,
    localidad: "LA ESMER  MACACHIN",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12027,
    localidad: "LA ESCONDIDA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 12028,
    localidad: "LA ENRIQUETA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12029,
    localidad: "LA EMILIA",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 12030,
    localidad: "LA ELVIRA",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12031,
    localidad: "LA ELVA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12032,
    localidad: "LA ELSA",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12033,
    localidad: "LA ELINA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12034,
    localidad: "LA ELIA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12035,
    localidad: "LA ELENITA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12036,
    localidad: "LA DONOSTIA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 12037,
    localidad: "LA DELICIA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12038,
    localidad: "LA DELFINA",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12039,
    localidad: "LA CTANCIA  ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12040,
    localidad: "LA CORONA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12041,
    localidad: "LA COPELINA",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12042,
    localidad: "LA CONSTANCIA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12043,
    localidad: "LA CONSTANCIA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12044,
    localidad: "LA COLORADA GRANDE",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12045,
    localidad: "LA COLORADA CHICA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12046,
    localidad: "LA CLELIA",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12047,
    localidad: "LA CIENAGA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12048,
    localidad: "LA CHIRLANDIA",
    cp: 8203,
    provincia_id: 11,
  },
  {
    id: 12049,
    localidad: "LA CELINA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12050,
    localidad: "LA CELIA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12051,
    localidad: "LA CAUTIVA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12052,
    localidad: "LA CAUTIVA",
    cp: 6403,
    provincia_id: 11,
  },
  {
    id: 12053,
    localidad: "LA CATITA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12054,
    localidad: "LA CATALINITA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12055,
    localidad: "LA CATALINA",
    cp: 6354,
    provincia_id: 11,
  },
  {
    id: 12056,
    localidad: "LA CATALINA",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12057,
    localidad: "LA CAROLA",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12058,
    localidad: "LA CALDENADA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12059,
    localidad: "LA BOTA",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12060,
    localidad: "LA BLANCA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12061,
    localidad: "LA BILBAINA",
    cp: 6345,
    provincia_id: 11,
  },
  {
    id: 12062,
    localidad: "LA BAYA MUERTA",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 12063,
    localidad: "LA BARRANCOSA",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 12064,
    localidad: "LA AVANZADA",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12065,
    localidad: "LA ATALAYA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12066,
    localidad: "LA ANTONIA",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12067,
    localidad: "LA ALIANZA",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 12068,
    localidad: "LA ADMINISTRACION",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12069,
    localidad: "LA ADELINA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12070,
    localidad: "LA ADELA",
    cp: 8138,
    provincia_id: 11,
  },
  {
    id: 12071,
    localidad: "LA ADELA",
    cp: 8134,
    provincia_id: 11,
  },
  {
    id: 12072,
    localidad: "LA  POCHOLA",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 12073,
    localidad: "L PAMPITA  HIDALGO",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12074,
    localidad: "L ESTRELLA DL SUD",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12075,
    localidad: "KILOMETRO 84",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12076,
    localidad: "KILOMETRO 619",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12077,
    localidad: "KILOMETRO 1218",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 12078,
    localidad: "JUZGADO VIEJO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12079,
    localidad: "JUAN V CILLEY",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 12080,
    localidad: "JUAN A PRADERE",
    cp: 8142,
    provincia_id: 11,
  },
  {
    id: 12081,
    localidad: "JOSE MARIA BLANCO",
    cp: 6409,
    provincia_id: 11,
  },
  {
    id: 12082,
    localidad: "JAGUEL DEL MONTE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12083,
    localidad: "JACINTO ARAUZ",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12084,
    localidad: "J DEL ESQUINERO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12085,
    localidad: "IVANOWSKY",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12086,
    localidad: "IRIS",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 12087,
    localidad: "IPIQA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12088,
    localidad: "INTENDENTE ALVEAR",
    cp: 6221,
    provincia_id: 11,
  },
  {
    id: 12089,
    localidad: "INGENIERO LUIGGI",
    cp: 6205,
    provincia_id: 11,
  },
  {
    id: 12090,
    localidad: "INGENIERO FOSTER",
    cp: 6385,
    provincia_id: 11,
  },
  {
    id: 12091,
    localidad: "INES Y CARLOTA",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 12092,
    localidad: "HUELEN",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12093,
    localidad: "HUCAL",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12094,
    localidad: "HIPOLITO YRIGOYEN",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12095,
    localidad: "HIDALGO",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12096,
    localidad: "HEREFORD",
    cp: 6233,
    provincia_id: 11,
  },
  {
    id: 12097,
    localidad: "GUATRACHE",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12098,
    localidad: "GUARACO",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12099,
    localidad: "GUADALOZA",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 12100,
    localidad: "GRAL MANUEL CAMPOS",
    cp: 6310,
    provincia_id: 11,
  },
  {
    id: 12101,
    localidad: "GRACIARENA",
    cp: 6335,
    provincia_id: 11,
  },
  {
    id: 12102,
    localidad: "GOBERNADOR DUVAL",
    cp: 8336,
    provincia_id: 11,
  },
  {
    id: 12103,
    localidad: "GENERAL SAN MARTIN",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12104,
    localidad: "GENERAL PICO",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 12105,
    localidad: "GENERAL ACHA",
    cp: 8200,
    provincia_id: 11,
  },
  {
    id: 12106,
    localidad: "GAVIOTAS",
    cp: 8138,
    provincia_id: 11,
  },
  {
    id: 12107,
    localidad: "G ORTIZ DE ROSAS",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12108,
    localidad: "G FERNANDEZ ORO",
    cp: 8325,
    provincia_id: 11,
  },
  {
    id: 12109,
    localidad: "FRANCISCO CASAL",
    cp: 6230,
    provincia_id: 11,
  },
  {
    id: 12110,
    localidad: "FORTIN PAUNERO",
    cp: 6417,
    provincia_id: 11,
  },
  {
    id: 12111,
    localidad: "FCISCO DE VITORIA",
    cp: 6403,
    provincia_id: 11,
  },
  {
    id: 12112,
    localidad: "FALUCHO",
    cp: 6215,
    provincia_id: 11,
  },
  {
    id: 12113,
    localidad: "ESTACION CAIOMUTA",
    cp: 6339,
    provincia_id: 11,
  },
  {
    id: 12114,
    localidad: "EPU PEL",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12115,
    localidad: "EMILIO MITRE",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12116,
    localidad: "EMBAJADOR MARTINI",
    cp: 6203,
    provincia_id: 11,
  },
  {
    id: 12117,
    localidad: "EL VOLANTE",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 12118,
    localidad: "EL VASQUITO",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12119,
    localidad: "EL TRIUNFO",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12120,
    localidad: "EL TRIGO",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12121,
    localidad: "EL SILENCIO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12122,
    localidad: "EL SAUCE",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12123,
    localidad: "EL SALITRAL",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12124,
    localidad: "EL RUBI",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12125,
    localidad: "EL RODEO",
    cp: 6389,
    provincia_id: 11,
  },
  {
    id: 12126,
    localidad: "EL RETIRO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12127,
    localidad: "EL REFUGIO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12128,
    localidad: "EL PUMA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12129,
    localidad: "EL PORVENIR",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12130,
    localidad: "EL PIMIA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12131,
    localidad: "EL PELUDO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12132,
    localidad: "EL PARQUE",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12133,
    localidad: "EL PARQUE",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 12134,
    localidad: "EL PAMPERO",
    cp: 6273,
    provincia_id: 11,
  },
  {
    id: 12135,
    localidad: "EL PALOMAR",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12136,
    localidad: "EL ODRE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12137,
    localidad: "EL MIRADOR",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12138,
    localidad: "EL MATE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12139,
    localidad: "EL MALACATE",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 12140,
    localidad: "EL LUCERO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12141,
    localidad: "EL GUANACO",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 12142,
    localidad: "EL GUAICURU",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12143,
    localidad: "EL G  WINIFREDA",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12144,
    localidad: "EL FURLONG",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12145,
    localidad: "EL EUCALIPTO",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12146,
    localidad: "EL E CARRO QUEMADO",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 12147,
    localidad: "EL DURAZNO",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 12148,
    localidad: "EL DESTINO  ROLON",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12149,
    localidad: "EL DESTINO",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12150,
    localidad: "EL DESTINO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12151,
    localidad: "EL DESLINDE",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12152,
    localidad: "EL DESCANSO",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12153,
    localidad: "EL D  LONQUIMAY",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12154,
    localidad: "EL CHILLEN",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12155,
    localidad: "EL CENTINELA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12156,
    localidad: "EL CENTENARIO",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12157,
    localidad: "EL BRILLANTE",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12158,
    localidad: "EL BELGICA",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12159,
    localidad: "EL  TROPEZON",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 12160,
    localidad: "EDUARDO CASTEX",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 12161,
    localidad: "E LA CORTADERA",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12162,
    localidad: "E EL CENTINELA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12163,
    localidad: "E EL BALDERON",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12164,
    localidad: "DUHAU",
    cp: 6405,
    provincia_id: 11,
  },
  {
    id: 12165,
    localidad: "DRABBLE",
    cp: 6230,
    provincia_id: 11,
  },
  {
    id: 12166,
    localidad: "DOS VIOLETAS",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12167,
    localidad: "DOS DE IPIQA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12168,
    localidad: "DOS CHAQARES",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12169,
    localidad: "DOS AMIGOS",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12170,
    localidad: "DOS AMIGOS",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12171,
    localidad: "DORILA",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 12172,
    localidad: "DOBLAS",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12173,
    localidad: "CURRU MAHUIDA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12174,
    localidad: "CURILCO",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12175,
    localidad: "CUENCA VIDAL",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 12176,
    localidad: "CUENCA",
    cp: 6231,
    provincia_id: 11,
  },
  {
    id: 12177,
    localidad: "CUCHILLO CO",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12178,
    localidad: "CUCHILLO  CO",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12179,
    localidad: "CUATRO ESQUINAS",
    cp: 8324,
    provincia_id: 11,
  },
  {
    id: 12180,
    localidad: "COVUNCO ARRIBA",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 12181,
    localidad: "COTITA",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12182,
    localidad: "COSTA LIMAY",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 12183,
    localidad: "COSTA DEL SALADO",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12184,
    localidad: "COSTA DEL RIO 5:",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 12185,
    localidad: "COS ZAURES",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12186,
    localidad: "CORONEL VIDAL",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 12187,
    localidad: "CORONEL HILARIO LAGOS",
    cp: 6228,
    provincia_id: 11,
  },
  {
    id: 12188,
    localidad: "CONTRALM CORDERO",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 12189,
    localidad: "CONHELO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12190,
    localidad: "CONA LAUQUEN",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12191,
    localidad: "COLONIAS MURRAY",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12192,
    localidad: "COLONIAS DRYSDALE",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12193,
    localidad: "COLONIA VILLA ALBA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12194,
    localidad: "COLONIA VEINTICINCO DE MAYO",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12195,
    localidad: "COLONIA VASCONGADA",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12196,
    localidad: "COLONIA TORELLO",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12197,
    localidad: "COLONIA SANTA TERESA",
    cp: 6312,
    provincia_id: 11,
  },
  {
    id: 12198,
    localidad: "COLONIA SANTA MARIA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12199,
    localidad: "COLONIA SANTA ANA",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12200,
    localidad: "COLONIA SAN MIGUEL",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12201,
    localidad: "COLONIA SAN JUAN",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12202,
    localidad: "COLONIA SAN JOSE",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 12203,
    localidad: "COLONIA SAN JOSE",
    cp: 6314,
    provincia_id: 11,
  },
  {
    id: 12204,
    localidad: "COLONIA SAN FELIPE",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12205,
    localidad: "COLONIA MURATURE",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 12206,
    localidad: "COLONIA MONTE CASEROS",
    cp: 5582,
    provincia_id: 11,
  },
  {
    id: 12207,
    localidad: "COLONIA MIGLIORI",
    cp: 6367,
    provincia_id: 11,
  },
  {
    id: 12208,
    localidad: "COLONIA LUNA",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12209,
    localidad: "COLONIA LOS TOROS",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12210,
    localidad: "COLONIA LEVEN",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 12211,
    localidad: "COLONIA LA SARA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12212,
    localidad: "COLONIA LA PAZ",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12213,
    localidad: "COLONIA LA ORACION",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12214,
    localidad: "COLONIA LA MUTUA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12215,
    localidad: "COLONIA LA CHISPA",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12216,
    localidad: "COLONIA LA CARLOTA",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 12217,
    localidad: "COLONIA LA AMARGA",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12218,
    localidad: "COLONIA JAPONESA",
    cp: 5621,
    provincia_id: 11,
  },
  {
    id: 12219,
    localidad: "COLONIA HELVECIA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12220,
    localidad: "COLONIA GIUSTI",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12221,
    localidad: "COLONIA ESPAQA",
    cp: 8206,
    provincia_id: 11,
  },
  {
    id: 12222,
    localidad: "COLONIA EL TIGRE",
    cp: 6385,
    provincia_id: 11,
  },
  {
    id: 12223,
    localidad: "COLONIA EL MANZANO",
    cp: 8305,
    provincia_id: 11,
  },
  {
    id: 12224,
    localidad: "COLONIA EL DESTINO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12225,
    localidad: "COLONIA EL BALDE",
    cp: 6403,
    provincia_id: 11,
  },
  {
    id: 12226,
    localidad: "COLONIA DEVOTO",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12227,
    localidad: "COLONIA CAZAUX",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12228,
    localidad: "COLONIA BOERO",
    cp: 6270,
    provincia_id: 11,
  },
  {
    id: 12229,
    localidad: "COLONIA BEAUFORT",
    cp: 6331,
    provincia_id: 11,
  },
  {
    id: 12230,
    localidad: "COLONIA BEATRIZ",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12231,
    localidad: "COLONIA BARON",
    cp: 6315,
    provincia_id: 11,
  },
  {
    id: 12232,
    localidad: "COLONIA ANASAGASTI",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12233,
    localidad: "COLONIA  LA INDIA",
    cp: 6352,
    provincia_id: 11,
  },
  {
    id: 12234,
    localidad: "CIUDAD OESTE",
    cp: 5634,
    provincia_id: 11,
  },
  {
    id: 12235,
    localidad: "CHINA MUERTA",
    cp: 8316,
    provincia_id: 11,
  },
  {
    id: 12236,
    localidad: "CHICALCO",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12237,
    localidad: "CHAPI TALO",
    cp: 6341,
    provincia_id: 11,
  },
  {
    id: 12238,
    localidad: "CHAPALCO",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 12239,
    localidad: "CHANILAO",
    cp: 6201,
    provincia_id: 11,
  },
  {
    id: 12240,
    localidad: "CHAMAICO",
    cp: 6214,
    provincia_id: 11,
  },
  {
    id: 12241,
    localidad: "CHACRAS DE VICTORICA",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 12242,
    localidad: "CHACRA 16",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 12243,
    localidad: "CHACHARRAMENDI",
    cp: 8202,
    provincia_id: 11,
  },
  {
    id: 12244,
    localidad: "CERRO DEL LEON",
    cp: 8313,
    provincia_id: 11,
  },
  {
    id: 12245,
    localidad: "CERRITO",
    cp: 6237,
    provincia_id: 11,
  },
  {
    id: 12246,
    localidad: "CEREALES",
    cp: 6301,
    provincia_id: 11,
  },
  {
    id: 12247,
    localidad: "CEBALLOS",
    cp: 6222,
    provincia_id: 11,
  },
  {
    id: 12248,
    localidad: "CAYUPAN",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12249,
    localidad: "CATRILO",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12250,
    localidad: "CATRIEL",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12251,
    localidad: "CARRO QUEMADO",
    cp: 6320,
    provincia_id: 11,
  },
  {
    id: 12252,
    localidad: "CARRI LAUQUEN",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 12253,
    localidad: "CARRAN CARA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 12254,
    localidad: "CARMENSA",
    cp: 5621,
    provincia_id: 11,
  },
  {
    id: 12255,
    localidad: "CARLOS BERG",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 12256,
    localidad: "CARAMAN",
    cp: 6387,
    provincia_id: 11,
  },
  {
    id: 12257,
    localidad: "CAQADA VERDE",
    cp: 6275,
    provincia_id: 11,
  },
  {
    id: 12258,
    localidad: "CAMPO URDANIZ",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12259,
    localidad: "CAMPO SAN JUAN",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 12260,
    localidad: "CAMPO SALUSSO",
    cp: 6369,
    provincia_id: 11,
  },
  {
    id: 12261,
    localidad: "CAMPO PICO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12262,
    localidad: "CAMPO NICHOLSON",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12263,
    localidad: "CAMPO LUDUEQA",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12264,
    localidad: "CAMPO LA ZULEMA",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 12265,
    localidad: "CAMPO LA FLORIDA",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12266,
    localidad: "CAMPO DE SALAS",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12267,
    localidad: "CAMPO DE LOS TOROS",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12268,
    localidad: "CAMPO CICARE",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12269,
    localidad: "CAMPO CARETTO",
    cp: 6381,
    provincia_id: 11,
  },
  {
    id: 12270,
    localidad: "CAMPAMENTO SOL",
    cp: 8319,
    provincia_id: 11,
  },
  {
    id: 12271,
    localidad: "CALEUFU",
    cp: 6387,
    provincia_id: 11,
  },
  {
    id: 12272,
    localidad: "CALEU CALEU",
    cp: 8138,
    provincia_id: 11,
  },
  {
    id: 12273,
    localidad: "CALCHAHUE",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 12274,
    localidad: "CAIMI",
    cp: 6361,
    provincia_id: 11,
  },
  {
    id: 12275,
    localidad: "CAILOMUTA",
    cp: 6339,
    provincia_id: 11,
  },
  {
    id: 12276,
    localidad: "CAICHUE",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12277,
    localidad: "CACHIRULO",
    cp: 6303,
    provincia_id: 11,
  },
  {
    id: 12278,
    localidad: "C VALENTINA SUR",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 12279,
    localidad: "C SANTA TERESA",
    cp: 6312,
    provincia_id: 11,
  },
  {
    id: 12280,
    localidad: "C SANTA MARIANA",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 12281,
    localidad: "C SANTA MARIA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12282,
    localidad: "C SANTA ELVIRA",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 12283,
    localidad: "C SANTA ELENA",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12284,
    localidad: "C SANTA CLARA",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12285,
    localidad: "C SANTA CECILIA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12286,
    localidad: "C SAN ROSARIO",
    cp: 8208,
    provincia_id: 11,
  },
  {
    id: 12287,
    localidad: "C SAN LORENZO",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 12288,
    localidad: "C SAN IGNACIO",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12289,
    localidad: "C MOISES SEC. 1A",
    cp: 6383,
    provincia_id: 11,
  },
  {
    id: 12290,
    localidad: "C MINISTRO LOBOS",
    cp: 6325,
    provincia_id: 11,
  },
  {
    id: 12291,
    localidad: "C MEDANO COLORADO",
    cp: 8214,
    provincia_id: 11,
  },
  {
    id: 12292,
    localidad: "C LAS VIZCACHERAS",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12293,
    localidad: "C LAS TRES PIEDRAS",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12294,
    localidad: "C LAS PIEDRITAS",
    cp: 6385,
    provincia_id: 11,
  },
  {
    id: 12295,
    localidad: "C LA PASTORIL",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12296,
    localidad: "C LA ESTRELLA",
    cp: 8185,
    provincia_id: 11,
  },
  {
    id: 12297,
    localidad: "C LA ESPERANZA",
    cp: 6311,
    provincia_id: 11,
  },
  {
    id: 12298,
    localidad: "C LA ABUNDANCIA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12299,
    localidad: "C GUIBURG N 2",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12300,
    localidad: "C GOBERNADOR AYALA",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12301,
    localidad: "C ESPIGA DE ORO",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12302,
    localidad: "C EL PORVENIR",
    cp: 6321,
    provincia_id: 11,
  },
  {
    id: 12303,
    localidad: "C DE VICTORICA",
    cp: 6319,
    provincia_id: 11,
  },
  {
    id: 12304,
    localidad: "C ALTE GUERRICO",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12305,
    localidad: "C 17 DE AGOSTO",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12306,
    localidad: "BUTALO",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12307,
    localidad: "BRAVO DEL DOS",
    cp: 6411,
    provincia_id: 11,
  },
  {
    id: 12308,
    localidad: "BOEUF",
    cp: 6380,
    provincia_id: 11,
  },
  {
    id: 12309,
    localidad: "BJO D LAS PALOMAS",
    cp: 6313,
    provincia_id: 11,
  },
  {
    id: 12310,
    localidad: "BJDA DE LOS MOLLES",
    cp: 8340,
    provincia_id: 11,
  },
  {
    id: 12311,
    localidad: "BERNASCONI",
    cp: 8204,
    provincia_id: 11,
  },
  {
    id: 12312,
    localidad: "BERNARDO LARROUDE",
    cp: 6220,
    provincia_id: 11,
  },
  {
    id: 12313,
    localidad: "BELLA VISTA",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12314,
    localidad: "BELGRANO",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12315,
    localidad: "BARRIO PELIGROSO",
    cp: 8322,
    provincia_id: 11,
  },
  {
    id: 12316,
    localidad: "BARRIO EL MOLINO",
    cp: 6360,
    provincia_id: 11,
  },
  {
    id: 12317,
    localidad: "BALSA SENILLOSA",
    cp: 8316,
    provincia_id: 11,
  },
  {
    id: 12318,
    localidad: "BAJADA NUEVA",
    cp: 6216,
    provincia_id: 11,
  },
  {
    id: 12319,
    localidad: "BAJADA COLORADA",
    cp: 8315,
    provincia_id: 11,
  },
  {
    id: 12320,
    localidad: "BAGUAL",
    cp: 6401,
    provincia_id: 11,
  },
  {
    id: 12321,
    localidad: "BAGUAL",
    cp: 6218,
    provincia_id: 11,
  },
  {
    id: 12322,
    localidad: "BADANO",
    cp: 6403,
    provincia_id: 11,
  },
  {
    id: 12323,
    localidad: "B INDIO TROMPA",
    cp: 6400,
    provincia_id: 11,
  },
  {
    id: 12324,
    localidad: "AZTEAZU",
    cp: 6365,
    provincia_id: 11,
  },
  {
    id: 12325,
    localidad: "ATREUCO",
    cp: 6305,
    provincia_id: 11,
  },
  {
    id: 12326,
    localidad: "ATALIVA ROCA",
    cp: 6308,
    provincia_id: 11,
  },
  {
    id: 12327,
    localidad: "ATALIVA ROCA",
    cp: 6301,
    provincia_id: 11,
  },
  {
    id: 12328,
    localidad: "ARTURO ALMARAZ",
    cp: 6330,
    provincia_id: 11,
  },
  {
    id: 12329,
    localidad: "ARROYITO CHALLACO",
    cp: 8313,
    provincia_id: 11,
  },
  {
    id: 12330,
    localidad: "ARG BELVEDERE",
    cp: 6367,
    provincia_id: 11,
  },
  {
    id: 12331,
    localidad: "ARBOL SOLO",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12332,
    localidad: "ARBOL DE LA ESPZA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12333,
    localidad: "ARATA",
    cp: 6385,
    provincia_id: 11,
  },
  {
    id: 12334,
    localidad: "AQELO",
    cp: 8312,
    provincia_id: 11,
  },
  {
    id: 12335,
    localidad: "ANTONIO CATALANO",
    cp: 6271,
    provincia_id: 11,
  },
  {
    id: 12336,
    localidad: "ANGUIL",
    cp: 6326,
    provincia_id: 11,
  },
  {
    id: 12337,
    localidad: "ALTO VERDE",
    cp: 5582,
    provincia_id: 11,
  },
  {
    id: 12338,
    localidad: "ALTA ITALIA",
    cp: 6207,
    provincia_id: 11,
  },
  {
    id: 12339,
    localidad: "ALPACHIRI",
    cp: 6309,
    provincia_id: 11,
  },
  {
    id: 12340,
    localidad: "ALGARROBO GRANDE",
    cp: 5582,
    provincia_id: 11,
  },
  {
    id: 12341,
    localidad: "ALGARROBO DEL AGUILA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12342,
    localidad: "ALGAR DEL AGUILA",
    cp: 6323,
    provincia_id: 11,
  },
  {
    id: 12343,
    localidad: "ALFREDO PEQA",
    cp: 6333,
    provincia_id: 11,
  },
  {
    id: 12344,
    localidad: "ALEGRIA",
    cp: 6389,
    provincia_id: 11,
  },
  {
    id: 12345,
    localidad: "ALBARIQO",
    cp: 6405,
    provincia_id: 11,
  },
  {
    id: 12346,
    localidad: "ALANITOS",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 12347,
    localidad: "AGUSTONI",
    cp: 6361,
    provincia_id: 11,
  },
  {
    id: 12348,
    localidad: "AGUARA",
    cp: 8307,
    provincia_id: 11,
  },
  {
    id: 12349,
    localidad: "ADOLFO VAN PRAET",
    cp: 6212,
    provincia_id: 11,
  },
  {
    id: 12350,
    localidad: "ADOLFO ALSINA",
    cp: 6430,
    provincia_id: 11,
  },
  {
    id: 12351,
    localidad: "ABRAMO",
    cp: 8212,
    provincia_id: 11,
  },
  {
    id: 12352,
    localidad: "A DE LOS PAJARITOS",
    cp: 8301,
    provincia_id: 11,
  },
  {
    id: 12353,
    localidad: "3 HNOS  MACACHIN",
    cp: 6307,
    provincia_id: 11,
  },
  {
    id: 12354,
    localidad: "25 DE MAYO",
    cp: 8201,
    provincia_id: 11,
  },
  {
    id: 12355,
    localidad: "ZAPLA",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12356,
    localidad: "YUTO",
    cp: 4518,
    provincia_id: 10,
  },
  {
    id: 12357,
    localidad: "YOSCABA",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12358,
    localidad: "YERBA B TILCARA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12359,
    localidad: "YERBA B LEDESMA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12360,
    localidad: "YAVI",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12361,
    localidad: "YALA D MTE CARMELO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12362,
    localidad: "YALA",
    cp: 4617,
    provincia_id: 10,
  },
  {
    id: 12363,
    localidad: "YALA",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12364,
    localidad: "VOLCAN",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12365,
    localidad: "VIZACACHAL",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12366,
    localidad: "VINALITO",
    cp: 4518,
    provincia_id: 10,
  },
  {
    id: 12367,
    localidad: "VILLA GORRITI",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12368,
    localidad: "VILLA DEL PERCHEL",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12369,
    localidad: "VILLA ARGENTINA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12370,
    localidad: "VICUQAYOC",
    cp: 4634,
    provincia_id: 10,
  },
  {
    id: 12371,
    localidad: "VENECIAS ARGS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12372,
    localidad: "VARAS",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12373,
    localidad: "VALLECITO",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12374,
    localidad: "VALLE GRANDE",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12375,
    localidad: "VALLE COLORADO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12376,
    localidad: "V PARQUE CHACABUCO",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12377,
    localidad: "V CIUDAD DE NIEVA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12378,
    localidad: "UQUIA",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12379,
    localidad: "TUSAQUILLAS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12380,
    localidad: "TURU TARI",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12381,
    localidad: "TURU TARI",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12382,
    localidad: "TURILARI",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12383,
    localidad: "TUNALITO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12384,
    localidad: "TUMBAYA GRANDE",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12385,
    localidad: "TUMBAYA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12386,
    localidad: "TUMBAYA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12387,
    localidad: "TUCUMANCITO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12388,
    localidad: "TRIUNVIRATO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12389,
    localidad: "TRES MORROS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12390,
    localidad: "TRES CRUCES",
    cp: 4636,
    provincia_id: 10,
  },
  {
    id: 12391,
    localidad: "TREMENTINAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12392,
    localidad: "TRANCAS",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12393,
    localidad: "TOTORITO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12394,
    localidad: "TORO MUERTO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12395,
    localidad: "TOQUILLERA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12396,
    localidad: "TOCOL",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12397,
    localidad: "TOBA",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12398,
    localidad: "TOBA",
    cp: 4648,
    provincia_id: 10,
  },
  {
    id: 12399,
    localidad: "TIRAXI CHICO",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12400,
    localidad: "TIRAXI",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12401,
    localidad: "TIO MAYO",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12402,
    localidad: "TIO MAYO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12403,
    localidad: "TIMON CRUZ",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12404,
    localidad: "TILCARA",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12405,
    localidad: "TEUCO",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12406,
    localidad: "TESORERO",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12407,
    localidad: "TESORERO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12408,
    localidad: "TERMAS DE REYES",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12409,
    localidad: "TEJADAS",
    cp: 4634,
    provincia_id: 10,
  },
  {
    id: 12410,
    localidad: "TARIJITA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12411,
    localidad: "TANQUES",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12412,
    localidad: "TAMBILLOS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12413,
    localidad: "TALAR",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12414,
    localidad: "TALA GRUSA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12415,
    localidad: "TAFNA",
    cp: 4650,
    provincia_id: 10,
  },
  {
    id: 12416,
    localidad: "TACTA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12417,
    localidad: "TABLON",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12418,
    localidad: "TABLADITAS",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12419,
    localidad: "SUSUYACO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12420,
    localidad: "SUSQUES",
    cp: 4642,
    provincia_id: 10,
  },
  {
    id: 12421,
    localidad: "SOYSOLAYTE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12422,
    localidad: "SORCUYO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12423,
    localidad: "SOLEDAD",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12424,
    localidad: "SOLEDAD",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12425,
    localidad: "SOCABON",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12426,
    localidad: "SIJES",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12427,
    localidad: "SIETE AGUAS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12428,
    localidad: "SIBERIA",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12429,
    localidad: "SEY",
    cp: 4411,
    provincia_id: 10,
  },
  {
    id: 12430,
    localidad: "SEPULTURA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12431,
    localidad: "SENADOR PEREZ",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12432,
    localidad: "SAYATE",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12433,
    localidad: "SAUZALITO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12434,
    localidad: "SAUZAL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12435,
    localidad: "SANTUYOC",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12436,
    localidad: "SANTUARIO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12437,
    localidad: "SANTO DOMINGO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12438,
    localidad: "SANTILLO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12439,
    localidad: "SANTA ROSA TUMBAYA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12440,
    localidad: "SANTA RITA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12441,
    localidad: "SANTA RITA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12442,
    localidad: "SANTA MARIA",
    cp: 4561,
    provincia_id: 10,
  },
  {
    id: 12443,
    localidad: "SANTA CLARA",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12444,
    localidad: "SANTA CLARA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12445,
    localidad: "SANTA CATALINA",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12446,
    localidad: "SANTA BARBARA",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12447,
    localidad: "SANTA ANA",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12448,
    localidad: "SANTA ANA",
    cp: 4695,
    provincia_id: 10,
  },
  {
    id: 12449,
    localidad: "SAN VICENTE",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12450,
    localidad: "SAN SALVADOR DE JUJUY",
    cp: 4604,
    provincia_id: 10,
  },
  {
    id: 12451,
    localidad: "SAN SALVADOR DE JUJUY",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12452,
    localidad: "SAN SALVADOR DE JUJUY",
    cp: 4607,
    provincia_id: 10,
  },
  {
    id: 12453,
    localidad: "SAN SALVADOR DE JUJUY",
    cp: 4602,
    provincia_id: 10,
  },
  {
    id: 12454,
    localidad: "SAN SALVADOR DE JUJUY",
    cp: 4609,
    provincia_id: 10,
  },
  {
    id: 12455,
    localidad: "SAN ROQUE",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12456,
    localidad: "SAN RAFAEL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12457,
    localidad: "SAN RAFAEL",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12458,
    localidad: "SAN PEDRO DE IRUYA",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12459,
    localidad: "SAN PEDRO",
    cp: 4502,
    provincia_id: 10,
  },
  {
    id: 12460,
    localidad: "SAN PEDRO",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12461,
    localidad: "SAN PEDRITO",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12462,
    localidad: "SAN LUCAS",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12463,
    localidad: "SAN LUCAS",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12464,
    localidad: "SAN LORENZO",
    cp: 4514,
    provincia_id: 10,
  },
  {
    id: 12465,
    localidad: "SAN LEON",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12466,
    localidad: "SAN JUANCITO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12467,
    localidad: "SAN JUAN DE OROS",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12468,
    localidad: "SAN JUAN DE DIOS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12469,
    localidad: "SAN JUAN (JUJUY)",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12470,
    localidad: "SAN JUAN (JUJUY)",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12471,
    localidad: "SAN JUAN",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12472,
    localidad: "SAN JUAN",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12473,
    localidad: "SAN JOSE DEL CHAQI",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12474,
    localidad: "SAN JOSE DEL BORDO",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12475,
    localidad: "SAN JOSE",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12476,
    localidad: "SAN JAVIER",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12477,
    localidad: "SAN GABRIEL",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12478,
    localidad: "SAN FRANCISCO DE ALFARCITO",
    cp: 4639,
    provincia_id: 10,
  },
  {
    id: 12479,
    localidad: "SAN FRANCISCO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12480,
    localidad: "SAN FRANCISCO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12481,
    localidad: "SAN BERNARDO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12482,
    localidad: "SAN ANTONIO",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12483,
    localidad: "SAN ANTONIO",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12484,
    localidad: "SAN ANTONIO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12485,
    localidad: "SAN ANDRES",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12486,
    localidad: "SAN  PEDRO DE JUJUY",
    cp: 4609,
    provincia_id: 10,
  },
  {
    id: 12487,
    localidad: "SALITRE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12488,
    localidad: "SALADILLO S PEDRO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12489,
    localidad: "SALADILLO LEDESMA",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12490,
    localidad: "SALADILLO LEDESMA",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12491,
    localidad: "SALA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12492,
    localidad: "S. S. DE JUJUY",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12493,
    localidad: "S ROSA VLLE GRANDE",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12494,
    localidad: "S J DE QUILLAGUES",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12495,
    localidad: "S J DE MIRAFLORES",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12496,
    localidad: "S J D LA RINCONADA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12497,
    localidad: "S ISIDRO DE IRUYA",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12498,
    localidad: "S FDO DE CATAMARCA",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12499,
    localidad: "S F DE ALFARCITO",
    cp: 4639,
    provincia_id: 10,
  },
  {
    id: 12500,
    localidad: "S ANT PERICO DE",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12501,
    localidad: "S ANA DE LA PUNA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12502,
    localidad: "RUMI CRUZ",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12503,
    localidad: "RONQUE",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12504,
    localidad: "RODERO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12505,
    localidad: "RODEO CHICO",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12506,
    localidad: "RODEITOS",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12507,
    localidad: "RIVERITO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12508,
    localidad: "RIO SECO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12509,
    localidad: "RIO NEGRO",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12510,
    localidad: "RIO GRANDE",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12511,
    localidad: "RIO DEL TALA",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12512,
    localidad: "RIO BLANCO",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12513,
    localidad: "RIO BLANCO",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12514,
    localidad: "RINCONADILLAS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12515,
    localidad: "RINCONADA",
    cp: 4645,
    provincia_id: 10,
  },
  {
    id: 12516,
    localidad: "REYES VILLA JARDIN",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12517,
    localidad: "REYES",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12518,
    localidad: "RECEPTORIA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12519,
    localidad: "REAL DE LOS TOROS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12520,
    localidad: "RAWSON",
    cp: 4523,
    provincia_id: 10,
  },
  {
    id: 12521,
    localidad: "RASTROJOS",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12522,
    localidad: "RAMALLO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12523,
    localidad: "RAMADA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12524,
    localidad: "RAMADA",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12525,
    localidad: "RACHAITE",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12526,
    localidad: "QUISQUINE",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12527,
    localidad: "QUIMAZO",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12528,
    localidad: "QUICHAGUA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12529,
    localidad: "QUETA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12530,
    localidad: "QUERA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12531,
    localidad: "QUEQOAL",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12532,
    localidad: "QUEBRALEQA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12533,
    localidad: "QUEBRADA HUASAMAYO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12534,
    localidad: "PURMAMARCA",
    cp: 4619,
    provincia_id: 10,
  },
  {
    id: 12535,
    localidad: "PUNTA DEL CAMPO",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12536,
    localidad: "PUNTA DEL AGUA",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12537,
    localidad: "PUNTA CORRAL",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12538,
    localidad: "PUNTA CANAL",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12539,
    localidad: "PUNA DE JUJUY",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12540,
    localidad: "PUESTO VIEJO",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12541,
    localidad: "PUESTO NUEVO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12542,
    localidad: "PUESTO CHICO",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12543,
    localidad: "PUESTO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12544,
    localidad: "PUERTO TOLAVA",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12545,
    localidad: "PUERTA VIEJA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12546,
    localidad: "PUERTA POTRERO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12547,
    localidad: "PUERTA PATACAL",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12548,
    localidad: "PUERTA DE SALAS",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12549,
    localidad: "PUERTA DE LIPAN",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12550,
    localidad: "PUEBLO VIEJO",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12551,
    localidad: "PUEBLO PLA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12552,
    localidad: "PUEBLO NUEVO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12553,
    localidad: "PUEBLO LEDESMA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12554,
    localidad: "PUEBLO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12555,
    localidad: "PUCARA",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12556,
    localidad: "PUCARA",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12557,
    localidad: "PTAS DE COLORADOS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12558,
    localidad: "PTA DE COLORADOS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12559,
    localidad: "PREDILIANA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12560,
    localidad: "POZUELO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12561,
    localidad: "POZO VERDE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12562,
    localidad: "POZO DE LA ESQUINA",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12563,
    localidad: "POZO D L AVISPAS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12564,
    localidad: "POZO COLORADO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12565,
    localidad: "POZO CABADO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12566,
    localidad: "POZO BRAVO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12567,
    localidad: "POTRERO DE LA PUNA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12568,
    localidad: "POTRERO ALEGRE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12569,
    localidad: "POTRERO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12570,
    localidad: "POTRERO",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12571,
    localidad: "POTRERO",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12572,
    localidad: "POTRERILLO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12573,
    localidad: "POSTA DE HORNILLOS",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12574,
    localidad: "POSTA DE HORNILLOS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12575,
    localidad: "PORVENIR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12576,
    localidad: "PISUNGO",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12577,
    localidad: "PISCUNO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12578,
    localidad: "PILCOMAYO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12579,
    localidad: "PIEDRAS BLANCAS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12580,
    localidad: "PIEDRA CHOTA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12581,
    localidad: "PIEDRA BLANCA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12582,
    localidad: "PIE DE LA CUESTA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12583,
    localidad: "PICACHO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12584,
    localidad: "PERICO SAN JUAN",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12585,
    localidad: "PERICO DEL CARMEN",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12586,
    localidad: "PERICO DEL CARMEN",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12587,
    localidad: "PERICO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12588,
    localidad: "PERICO",
    cp: 4610,
    provincia_id: 10,
  },
  {
    id: 12589,
    localidad: "PEQAS BLANCAS",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12590,
    localidad: "PEQA COLORADA",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12591,
    localidad: "PAYO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12592,
    localidad: "PAYABUAYCA",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12593,
    localidad: "PAULINA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12594,
    localidad: "PAULETE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12595,
    localidad: "PASTOS CHICOS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12596,
    localidad: "PARANI",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12597,
    localidad: "PAQO",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12598,
    localidad: "PAN DE AZUCAR MINA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12599,
    localidad: "PAMPICHUELA",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12600,
    localidad: "PAMPA VIEJA",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12601,
    localidad: "PAMPA LARGA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12602,
    localidad: "PAMPA BLANCA",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12603,
    localidad: "PALPALA",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12604,
    localidad: "PALPALA",
    cp: 4613,
    provincia_id: 10,
  },
  {
    id: 12605,
    localidad: "PALO SANTO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12606,
    localidad: "PALO BLANCO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12607,
    localidad: "PALO A PIQUE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12608,
    localidad: "PALMA SOLA",
    cp: 4510,
    provincia_id: 10,
  },
  {
    id: 12609,
    localidad: "PALCA DE APARZO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12610,
    localidad: "PAL TOLCO",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12611,
    localidad: "PAIRIQUE GRANDE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12612,
    localidad: "PAICONE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12613,
    localidad: "P GUARDAP NAC CA",
    cp: 4514,
    provincia_id: 10,
  },
  {
    id: 12614,
    localidad: "P AMONTONADAS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12615,
    localidad: "OVARA",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12616,
    localidad: "OROSMAYO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12617,
    localidad: "OROS SAN JUAN",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12618,
    localidad: "ORATORIO",
    cp: 4656,
    provincia_id: 10,
  },
  {
    id: 12619,
    localidad: "OLAROZ GRANDE",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12620,
    localidad: "OLAROZ CHICO",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12621,
    localidad: "OLACAPATO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12622,
    localidad: "OJO DE AGUA",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12623,
    localidad: "OCUMAZO",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12624,
    localidad: "OCULTO",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12625,
    localidad: "OCLOYAS",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12626,
    localidad: "OBRAJE SAN JOSE",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12627,
    localidad: "NORMENTA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12628,
    localidad: "NOGALITO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12629,
    localidad: "NOGALES",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12630,
    localidad: "NOGAL",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12631,
    localidad: "NAZARENO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12632,
    localidad: "NARANJITO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12633,
    localidad: "MUQAYOC",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12634,
    localidad: "MULLI PUNCO",
    cp: 4650,
    provincia_id: 10,
  },
  {
    id: 12635,
    localidad: "MORRO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12636,
    localidad: "MORRITO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12637,
    localidad: "MORENO CHICO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12638,
    localidad: "MONTE RICO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12639,
    localidad: "MOLULO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12640,
    localidad: "MOLULAR",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12641,
    localidad: "MOLLI PUNCO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12642,
    localidad: "MOLINOS",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12643,
    localidad: "MOJON AZUCENA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12644,
    localidad: "MOCORAITE",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12645,
    localidad: "MIYUYOC",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12646,
    localidad: "MIRES",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12647,
    localidad: "MIRAFLORES DE LA C",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12648,
    localidad: "MIRA FLORES",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12649,
    localidad: "MINIAIO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12650,
    localidad: "MINAS DE BORATO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12651,
    localidad: "MINAS AZULES",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12652,
    localidad: "MINA SAN FRANCISCO",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12653,
    localidad: "MINA PULPERA",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12654,
    localidad: "MINA PIRQUITAS",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12655,
    localidad: "MINA AJEDREZ",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12656,
    localidad: "MINA 9 DE OCTUBRE",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12657,
    localidad: "MILAN",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12658,
    localidad: "MERCO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12659,
    localidad: "MAYINTE",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12660,
    localidad: "MARTA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12661,
    localidad: "MAQUINISTA VERON",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12662,
    localidad: "MAL PASO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12663,
    localidad: "MAIMARA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12664,
    localidad: "LUMARA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12665,
    localidad: "LOZANO",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12666,
    localidad: "LOTE ZORA",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12667,
    localidad: "LOTE SAUZAL",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12668,
    localidad: "LOTE SAN JUANCITO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12669,
    localidad: "LOTE SAN ANTONIO",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12670,
    localidad: "LOTE PREDILIANA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12671,
    localidad: "LOTE PIEDRITAS",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12672,
    localidad: "LOTE PARAPETI",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12673,
    localidad: "LOTE PALMERA",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12674,
    localidad: "LOTE MIRAFLORES",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12675,
    localidad: "LOTE MAIZ NEGRO",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12676,
    localidad: "LOTE LA POSTA",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12677,
    localidad: "LOTE LA CIENAGA",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12678,
    localidad: "LOTE EL PUESTO",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12679,
    localidad: "LOTE DON EMILIO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12680,
    localidad: "LOTE DON DAVID",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12681,
    localidad: "LOS MATOS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12682,
    localidad: "LOS MANANTIALES",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12683,
    localidad: "LOS LAPACHOS",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12684,
    localidad: "LOS CEDROS",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12685,
    localidad: "LOS CATRES",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12686,
    localidad: "LOS BLANCOS",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12687,
    localidad: "LOS BAYOS",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12688,
    localidad: "LOS BAQOS TERMALES",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12689,
    localidad: "LOS BAÑOS TERMALES",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12690,
    localidad: "LOS ALISOS",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12691,
    localidad: "LOS ALISOS",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12692,
    localidad: "LOMA PELADA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12693,
    localidad: "LOMA LARGA",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12694,
    localidad: "LOMA LARGA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12695,
    localidad: "LOMA DEL MEDIO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12696,
    localidad: "LOMA BLANCA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12697,
    localidad: "LIZOITE",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12698,
    localidad: "LIPAN",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12699,
    localidad: "LINDERO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12700,
    localidad: "LIMONCITO",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12701,
    localidad: "LIB GRAL SAN MARTIN",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12702,
    localidad: "LIB GRAL S MARTIN",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12703,
    localidad: "LEON",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12704,
    localidad: "LEDESMA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12705,
    localidad: "LECHERIA",
    cp: 4514,
    provincia_id: 10,
  },
  {
    id: 12706,
    localidad: "LEACHS",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12707,
    localidad: "LAZARETO",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12708,
    localidad: "LAVAYEN",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12709,
    localidad: "LAS QUINTAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12710,
    localidad: "LAS PIRCAS",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12711,
    localidad: "LAS PICHANAS",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12712,
    localidad: "LAS PAMPITAS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12713,
    localidad: "LAS HIGUERITAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12714,
    localidad: "LAS HIGUERILLAS",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12715,
    localidad: "LAS ESCALERAS",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12716,
    localidad: "LAS ESCALERAS",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12717,
    localidad: "LAS CORTADERAS",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12718,
    localidad: "LAS CHICAPENAS",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12719,
    localidad: "LAS CAQADAS",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12720,
    localidad: "LAS CAPILLAS",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12721,
    localidad: "LAS CAPILLAS",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12722,
    localidad: "LAPACHAL S BARBARA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12723,
    localidad: "LAPACHAL LEDESMA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12724,
    localidad: "LAGUNILLAS",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12725,
    localidad: "LAGUNILLA LEDESMA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12726,
    localidad: "LAGUNILLA EL CARM",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12727,
    localidad: "LAGUNAS DEYALA",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12728,
    localidad: "LAGUNAS DE YALA",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12729,
    localidad: "LAGUNAS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12730,
    localidad: "LAGUNA TOTORILLAS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12731,
    localidad: "LAGUNA SAN MIGUEL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12732,
    localidad: "LA VETA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12733,
    localidad: "LA VERTIENTE",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12734,
    localidad: "LA UNION",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12735,
    localidad: "LA TOMA",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12736,
    localidad: "LA TOMA",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12737,
    localidad: "LA SANGA",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12738,
    localidad: "LA SANGA",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12739,
    localidad: "LA RONDA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12740,
    localidad: "LA REDUCCION",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12741,
    localidad: "LA RAMADA",
    cp: 4432,
    provincia_id: 10,
  },
  {
    id: 12742,
    localidad: "LA QUINTA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12743,
    localidad: "LA QUIACA",
    cp: 4650,
    provincia_id: 10,
  },
  {
    id: 12744,
    localidad: "LA PUERTA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12745,
    localidad: "LA PUERTA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12746,
    localidad: "LA OVEJERIA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12747,
    localidad: "LA OLLADA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12748,
    localidad: "LA OLLADA",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12749,
    localidad: "LA MENDIETA",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12750,
    localidad: "LA INTERMEDIA",
    cp: 4650,
    provincia_id: 10,
  },
  {
    id: 12751,
    localidad: "LA FALDA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12752,
    localidad: "LA ESPERANZA",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 12753,
    localidad: "LA CUEVA",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12754,
    localidad: "LA CUESTA",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12755,
    localidad: "LA CUESTA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12756,
    localidad: "LA CRUZ",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12757,
    localidad: "LA CIENAGA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12758,
    localidad: "LA CIENAGA",
    cp: 4650,
    provincia_id: 10,
  },
  {
    id: 12759,
    localidad: "LA CIENAGA",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12760,
    localidad: "LA CALERA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12761,
    localidad: "LA CABAQA",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12762,
    localidad: "LA BANDA",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12763,
    localidad: "LA BANDA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12764,
    localidad: "LA BAJADA",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12765,
    localidad: "LA AGUADITA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12766,
    localidad: "LA AGUADA",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 12767,
    localidad: "KILOMETRO 1369",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12768,
    localidad: "KILOMETRO 1289",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12769,
    localidad: "KILOMETRO 1183",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12770,
    localidad: "KILOMETRO 1129",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12771,
    localidad: "JUELLA",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12772,
    localidad: "JUAN GALAN",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12773,
    localidad: "JARAMILLO",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12774,
    localidad: "J MANUEL DE ROSAS",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12775,
    localidad: "ITURBE",
    cp: 4629,
    provincia_id: 10,
  },
  {
    id: 12776,
    localidad: "ITURBE",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12777,
    localidad: "ITUAICOCHICO",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12778,
    localidad: "ISLA GRANDE",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12779,
    localidad: "ISLA CHICA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12780,
    localidad: "IRIARTE",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12781,
    localidad: "INGENIO LEDESMA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12782,
    localidad: "INGENIO LA ESPERANZA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12783,
    localidad: "INCA HUASI",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12784,
    localidad: "IGNIO LA ESPERANZA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12785,
    localidad: "HUMAHUACA",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12786,
    localidad: "HUICHAIRA",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12787,
    localidad: "HUERTA",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12788,
    localidad: "HUANCAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12789,
    localidad: "HUANCAR",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12790,
    localidad: "HUAICO CHICO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12791,
    localidad: "HUACHICHOCANA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12792,
    localidad: "HUACALERA",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12793,
    localidad: "HORNILLOS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12794,
    localidad: "HORNILLOS",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12795,
    localidad: "HORNADITAS",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12796,
    localidad: "HIPOLITO YRIGOYEN ESTACION ITURBE",
    cp: 4635,
    provincia_id: 10,
  },
  {
    id: 12797,
    localidad: "HIPOL Y ES ITURBE",
    cp: 4635,
    provincia_id: 10,
  },
  {
    id: 12798,
    localidad: "HIGUERITAS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12799,
    localidad: "HIGUERITAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12800,
    localidad: "GUERREROS",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12801,
    localidad: "GUERRERO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12802,
    localidad: "GUEMES",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12803,
    localidad: "GUAYATAYOC",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12804,
    localidad: "GUAYATAYOC",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12805,
    localidad: "GUAYACAN",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12806,
    localidad: "GUACHAN",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12807,
    localidad: "GRANADAS",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12808,
    localidad: "GRAL MANUEL SAVIO",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12809,
    localidad: "GOBERNADOR TELLO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12810,
    localidad: "GOBERNADOR OVEJERO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12811,
    localidad: "GALETA",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12812,
    localidad: "G MANUEL SAVIO",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12813,
    localidad: "FUNDICIONES",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12814,
    localidad: "FRAILE PINTADO",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12815,
    localidad: "FLORENCIA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12816,
    localidad: "FINCA SANTIAGO",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12817,
    localidad: "FINCA SANTA CORNELIA",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12818,
    localidad: "FINCA S CORNELIA",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12819,
    localidad: "FINCA LEACH",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12820,
    localidad: "FINCA LA REALIDAD",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12821,
    localidad: "FINCA LA LUCRECIA",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12822,
    localidad: "FINCA AGUA TAPADA",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12823,
    localidad: "FINCA AGUA SALADA",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12824,
    localidad: "FARILLON",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12825,
    localidad: "FALDA MONTOSA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12826,
    localidad: "FALDA MOJON",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12827,
    localidad: "F DEL QUEBRACHAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12828,
    localidad: "ESTANCIA GRANDE",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12829,
    localidad: "ESTACION PERICO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12830,
    localidad: "ESQUINAS BLANCAS",
    cp: 4634,
    provincia_id: 10,
  },
  {
    id: 12831,
    localidad: "ESQUINA GRANDE",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12832,
    localidad: "ESQUINA DE HUANCAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12833,
    localidad: "ESQUINA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12834,
    localidad: "ESCAYA",
    cp: 4644,
    provincia_id: 10,
  },
  {
    id: 12835,
    localidad: "ENTRE RIOS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12836,
    localidad: "ENSENADA",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12837,
    localidad: "ENCRUCIJADA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12838,
    localidad: "EL TORO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12839,
    localidad: "EL TOBA",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12840,
    localidad: "EL TIPAL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12841,
    localidad: "EL TALAR",
    cp: 4541,
    provincia_id: 10,
  },
  {
    id: 12842,
    localidad: "EL TALAR",
    cp: 4542,
    provincia_id: 10,
  },
  {
    id: 12843,
    localidad: "EL SUNCHAL",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12844,
    localidad: "EL SUNCHAL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12845,
    localidad: "EL SAUCE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12846,
    localidad: "EL SALADILLO",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 12847,
    localidad: "EL RIO NEGRO",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12848,
    localidad: "EL REMATE",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12849,
    localidad: "EL QUEMADO",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12850,
    localidad: "EL QUEMADO",
    cp: 4530,
    provincia_id: 10,
  },
  {
    id: 12851,
    localidad: "EL PORVENIR",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12852,
    localidad: "EL PONGO",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12853,
    localidad: "EL PIQUETE",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12854,
    localidad: "EL PERCHEL",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12855,
    localidad: "EL PALMAR DE S F",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 12856,
    localidad: "EL PALMAR",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12857,
    localidad: "EL P DE LA PUNA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12858,
    localidad: "EL OLVIDO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12859,
    localidad: "EL OLLERO",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12860,
    localidad: "EL OLLERO",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12861,
    localidad: "EL NARANJO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12862,
    localidad: "EL MORRO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12863,
    localidad: "EL MORENO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12864,
    localidad: "EL MOLLAR",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12865,
    localidad: "EL MOLINO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12866,
    localidad: "EL MISTOL",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12867,
    localidad: "EL MANANTIAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12868,
    localidad: "EL FUERTE",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12869,
    localidad: "EL DURAZNO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12870,
    localidad: "EL CUCHO",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12871,
    localidad: "EL COLORADO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12872,
    localidad: "EL CHUCUPAL",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12873,
    localidad: "EL CAULARIO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12874,
    localidad: "EL CARMEN",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12875,
    localidad: "EL CARDONAL",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 12876,
    localidad: "EL CALLEJON",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12877,
    localidad: "EL CADILLAL",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12878,
    localidad: "EL CABRAL",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12879,
    localidad: "EL C PERICO DE",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12880,
    localidad: "EL BRETE",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12881,
    localidad: "EL BANANAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12882,
    localidad: "EL ARENAL",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12883,
    localidad: "EL ANGOSTO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12884,
    localidad: "EL AMANCAY",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12885,
    localidad: "EL ALGARROBAL",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12886,
    localidad: "EL ALGARROBAL",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12887,
    localidad: "EL AIBAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12888,
    localidad: "EL AGUILAR",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12889,
    localidad: "EL AGUILAR",
    cp: 4634,
    provincia_id: 10,
  },
  {
    id: 12890,
    localidad: "E ZOOTECNICA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12891,
    localidad: "DURAZNAL",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12892,
    localidad: "DONCELLAS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12893,
    localidad: "DON JORGE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12894,
    localidad: "DOGLONZO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12895,
    localidad: "CUSI CUSI",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12896,
    localidad: "CRUZ NIDO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12897,
    localidad: "COYAGUAIMA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12898,
    localidad: "COSTILLAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12899,
    localidad: "CORTADERAS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12900,
    localidad: "CORTADERAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12901,
    localidad: "CORTADERAS",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12902,
    localidad: "CORTADERAS",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12903,
    localidad: "CORRAL DE PIEDRAS",
    cp: 4601,
    provincia_id: 10,
  },
  {
    id: 12904,
    localidad: "CORRAL BLANCO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12905,
    localidad: "CORONEL ARIAS",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12906,
    localidad: "CORAYA",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12907,
    localidad: "CONDOR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12908,
    localidad: "CONDOR",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12909,
    localidad: "COLORADOS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12910,
    localidad: "COLANZULI",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12911,
    localidad: "COIRURO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12912,
    localidad: "COCTACA",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12913,
    localidad: "COCHINOCA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12914,
    localidad: "COCHAGATE",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12915,
    localidad: "CINCEL",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12916,
    localidad: "CIENEGUILLAS",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12917,
    localidad: "CIENEGO GRANDE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12918,
    localidad: "CIENAGA GRANDE",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12919,
    localidad: "CIENAGA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12920,
    localidad: "CIANZO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12921,
    localidad: "CHUQUINA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 12922,
    localidad: "CHULIN O INCA NVA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12923,
    localidad: "CHUCALEZNA",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12924,
    localidad: "CHORRO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12925,
    localidad: "CHORRILLOS",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12926,
    localidad: "CHORRILLOS",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12927,
    localidad: "CHORRILLOS",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12928,
    localidad: "CHOROJRA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12929,
    localidad: "CHORCAN",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12930,
    localidad: "CHILCAYOC",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12931,
    localidad: "CHILCAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12932,
    localidad: "CHILCAR",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12933,
    localidad: "CHAUPI RODERO",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12934,
    localidad: "CHAUPI RODERO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12935,
    localidad: "CHAQI CHICO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12936,
    localidad: "CHAQI",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12937,
    localidad: "CHAQARCITO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12938,
    localidad: "CHAQARAL",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12939,
    localidad: "CHAQAR SOLO",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12940,
    localidad: "CHAÑARAL",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12941,
    localidad: "CHAÑAR SOLO",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12942,
    localidad: "CHAMICAL",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12943,
    localidad: "CHALICAN",
    cp: 4504,
    provincia_id: 10,
  },
  {
    id: 12944,
    localidad: "CHACAR",
    cp: 4633,
    provincia_id: 10,
  },
  {
    id: 12945,
    localidad: "CEVILAR",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12946,
    localidad: "CERROS ZAPLA",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12947,
    localidad: "CERRO REDONDO",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12948,
    localidad: "CERRO NEGRO",
    cp: 4605,
    provincia_id: 10,
  },
  {
    id: 12949,
    localidad: "CERRO CHICO",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12950,
    localidad: "CERRILLOS",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12951,
    localidad: "CENTRO FORESTAL",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12952,
    localidad: "CEIBAL",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12953,
    localidad: "CAUTA",
    cp: 4413,
    provincia_id: 10,
  },
  {
    id: 12954,
    localidad: "CAUCHARI",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12955,
    localidad: "CATARI",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 12956,
    localidad: "CATAMONTAQA",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 12957,
    localidad: "CASPALA",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 12958,
    localidad: "CASIRA",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12959,
    localidad: "CASILLAS",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12960,
    localidad: "CASILLA",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12961,
    localidad: "CASAYOCK",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 12962,
    localidad: "CASABINDO",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 12963,
    localidad: "CASA VIEJA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12964,
    localidad: "CASA NEGRA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12965,
    localidad: "CASA COLORADA",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12966,
    localidad: "CARCEL",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12967,
    localidad: "CARAHUNCO",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 12968,
    localidad: "CARAHUASI",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 12969,
    localidad: "CAQAS",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 12970,
    localidad: "CAPLA",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 12971,
    localidad: "CAPILLA FUERTE",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 12972,
    localidad: "CAPACHACRA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12973,
    localidad: "CAP DE LOS REME",
    cp: 4663,
    provincia_id: 10,
  },
  {
    id: 12974,
    localidad: "CANDELARIA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12975,
    localidad: "CANCHUELA",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12976,
    localidad: "CANCHAHUASI",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12977,
    localidad: "CAMPO OCULTO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12978,
    localidad: "CAMPO LA TUNA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12979,
    localidad: "CAMPO COLORADO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12980,
    localidad: "CAMPO BAJO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12981,
    localidad: "CALILEGUA",
    cp: 4514,
    provincia_id: 10,
  },
  {
    id: 12982,
    localidad: "CALETE",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 12983,
    localidad: "CALAHOYO",
    cp: 4655,
    provincia_id: 10,
  },
  {
    id: 12984,
    localidad: "CAIMANCITO",
    cp: 4516,
    provincia_id: 10,
  },
  {
    id: 12985,
    localidad: "CADILLA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12986,
    localidad: "CACHO",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 12987,
    localidad: "CACHIHUAICO",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12988,
    localidad: "CACHI PUNCO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12989,
    localidad: "CABRERIA",
    cp: 4653,
    provincia_id: 10,
  },
  {
    id: 12990,
    localidad: "C LOS LAPACHOS",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 12991,
    localidad: "C 8 DE SEPTIEMBRE",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12992,
    localidad: "BORDO LA ISLA",
    cp: 4608,
    provincia_id: 10,
  },
  {
    id: 12993,
    localidad: "BOMBA",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 12994,
    localidad: "BOLETERIA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 12995,
    localidad: "BELLA VISTA",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 12996,
    localidad: "BELLA VISTA",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 12997,
    localidad: "BELLA VISTA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12998,
    localidad: "BATEAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 12999,
    localidad: "BARRO NEGRO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 13000,
    localidad: "BARRIOS",
    cp: 4649,
    provincia_id: 10,
  },
  {
    id: 13001,
    localidad: "BARRIO SANTA RITA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13002,
    localidad: "BARRIO LUJAN",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13003,
    localidad: "BARRIO CUYAYA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13004,
    localidad: "BARRIO CHIJRA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13005,
    localidad: "BARRIO ALBERDI",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13006,
    localidad: "BARRIO 9 DE JULIO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13007,
    localidad: "BARRANCAS",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 13008,
    localidad: "BARCENA",
    cp: 4616,
    provincia_id: 10,
  },
  {
    id: 13009,
    localidad: "BANDA VARELA",
    cp: 4700,
    provincia_id: 10,
  },
  {
    id: 13010,
    localidad: "BALLIAZO",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 13011,
    localidad: "BALIAZO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 13012,
    localidad: "BAJADA ALTA",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 13013,
    localidad: "B P 19 DE ABRIL",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13014,
    localidad: "B BAJO LA VIQA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13015,
    localidad: "B ALTO LA LOMA",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13016,
    localidad: "AZUL PAMPA",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 13017,
    localidad: "ARROYO DEL MEDIO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 13018,
    localidad: "ARRAYANAL",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 13019,
    localidad: "ARENAL BARROSO",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 13020,
    localidad: "ARBOLITO NUEVO",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 13021,
    localidad: "APARZO",
    cp: 4630,
    provincia_id: 10,
  },
  {
    id: 13022,
    localidad: "APAREJO",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 13023,
    localidad: "ANTUMPA",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 13024,
    localidad: "ANTIGUYOS",
    cp: 4643,
    provincia_id: 10,
  },
  {
    id: 13025,
    localidad: "ANTIGUO",
    cp: 4631,
    provincia_id: 10,
  },
  {
    id: 13026,
    localidad: "ANIMAS",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 13027,
    localidad: "ANGOSTO PESCADO",
    cp: 4651,
    provincia_id: 10,
  },
  {
    id: 13028,
    localidad: "ANGOS DEL PERCHEL",
    cp: 4626,
    provincia_id: 10,
  },
  {
    id: 13029,
    localidad: "AMARGURAS",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 13030,
    localidad: "AMANCAYOC",
    cp: 4513,
    provincia_id: 10,
  },
  {
    id: 13031,
    localidad: "ALTO VERDE",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 13032,
    localidad: "ALTO QUIRQUINCHO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13033,
    localidad: "ALTO QUEMADO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13034,
    localidad: "ALTO POTRERILLO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13035,
    localidad: "ALTO MINERO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13036,
    localidad: "ALTO HUANCAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13037,
    localidad: "ALTO HORNOS ZAPLA",
    cp: 4612,
    provincia_id: 10,
  },
  {
    id: 13038,
    localidad: "ALTO DEL SALADILLO",
    cp: 4522,
    provincia_id: 10,
  },
  {
    id: 13039,
    localidad: "ALTO DEL SALADILLO",
    cp: 4500,
    provincia_id: 10,
  },
  {
    id: 13040,
    localidad: "ALTO DEL ANGOSTO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13041,
    localidad: "ALTO DE MOJON",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13042,
    localidad: "ALTO DE LOZANO",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13043,
    localidad: "ALTO DE CASA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13044,
    localidad: "ALTO COMEDERO",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13045,
    localidad: "ALTO CALILEGUA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 13046,
    localidad: "ALISOS DE ARRIBA",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 13047,
    localidad: "ALISOS  DE ABAJO",
    cp: 4603,
    provincia_id: 10,
  },
  {
    id: 13048,
    localidad: "ALGARROBAL",
    cp: 4600,
    provincia_id: 10,
  },
  {
    id: 13049,
    localidad: "ALFARCITO",
    cp: 4624,
    provincia_id: 10,
  },
  {
    id: 13050,
    localidad: "ALEGRIA",
    cp: 4506,
    provincia_id: 10,
  },
  {
    id: 13051,
    localidad: "AGUAS CALIENTES",
    cp: 4606,
    provincia_id: 10,
  },
  {
    id: 13052,
    localidad: "AGUAS CALIENTES",
    cp: 4518,
    provincia_id: 10,
  },
  {
    id: 13053,
    localidad: "AGUAS CALIENTES",
    cp: 4431,
    provincia_id: 10,
  },
  {
    id: 13054,
    localidad: "AGUAS BLANCAS",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 13055,
    localidad: "AGUA PALOMAR",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13056,
    localidad: "AGUA NEGRA",
    cp: 4512,
    provincia_id: 10,
  },
  {
    id: 13057,
    localidad: "AGUA DE CASTILLA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 13058,
    localidad: "AGUA CHICA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 13059,
    localidad: "AGUA CALIENTE",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 13060,
    localidad: "AGUA C DE LA PUNA",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 13061,
    localidad: "AGUA BENDITA",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13062,
    localidad: "ACHACAMAYOC",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13063,
    localidad: "ABRALAITE",
    cp: 4632,
    provincia_id: 10,
  },
  {
    id: 13064,
    localidad: "ABRA PAMPA",
    cp: 4640,
    provincia_id: 10,
  },
  {
    id: 13065,
    localidad: "ABRA MAYO",
    cp: 4622,
    provincia_id: 10,
  },
  {
    id: 13066,
    localidad: "ABRA DEL TRIGO",
    cp: 4501,
    provincia_id: 10,
  },
  {
    id: 13067,
    localidad: "ABRA DE PIVES",
    cp: 4618,
    provincia_id: 10,
  },
  {
    id: 13068,
    localidad: "ABDON CASTRO TOLAY",
    cp: 4641,
    provincia_id: 10,
  },
  {
    id: 13069,
    localidad: "23 DE AGOSTO",
    cp: 4503,
    provincia_id: 10,
  },
  {
    id: 13070,
    localidad: "ZORRILLA CUE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13071,
    localidad: "VIRASOL",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13072,
    localidad: "VILLA REAL",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13073,
    localidad: "VILLA MERCEDES",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13074,
    localidad: "VILLA LUCERO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13075,
    localidad: "VILLA HERMOSA",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13076,
    localidad: "VILLA ESCOLAR",
    cp: 3548,
    provincia_id: 9,
  },
  {
    id: 13077,
    localidad: "VILLA EMILIA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13078,
    localidad: "VILLA DOS TRECE",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13079,
    localidad: "VILLA DOS TRECE",
    cp: 3602,
    provincia_id: 9,
  },
  {
    id: 13080,
    localidad: "VILLA ADELAIDA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13081,
    localidad: "V GRAL M BELGRANO",
    cp: 3616,
    provincia_id: 9,
  },
  {
    id: 13082,
    localidad: "V GENERAL URQUIZA",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13083,
    localidad: "TTE GRAL J C SCHE",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13084,
    localidad: "TTE CNEL GASPAR CS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13085,
    localidad: "TTE CNEL GASPAR CS",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13086,
    localidad: "TRES POZOS",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13087,
    localidad: "TRES POCITOS",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13088,
    localidad: "TRES MOJONES",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13089,
    localidad: "TRES MARIAS",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13090,
    localidad: "TRES LAGUNAS",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13091,
    localidad: "TRANSITO CUE",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13092,
    localidad: "TORO PASO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13093,
    localidad: "TOMAS GODOY CRUZ",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13094,
    localidad: "TIMBO PORA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13095,
    localidad: "TENIENTE BROWN",
    cp: 3622,
    provincia_id: 9,
  },
  {
    id: 13096,
    localidad: "TATU PIRE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13097,
    localidad: "TATANE",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13098,
    localidad: "SUIPACHA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13099,
    localidad: "SUBTTE R MASAFERRO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13100,
    localidad: "SUBTENIENTE PERIN",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13101,
    localidad: "STO MAYOR B AGUILA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13102,
    localidad: "STO A V SABRIA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13103,
    localidad: "SOLDADO T SCHEZ",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13104,
    localidad: "SOLDADO M TORALES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13105,
    localidad: "SOLDADO I SCHEZ",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13106,
    localidad: "SOLDADO E LUNA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13107,
    localidad: "SOL DE MAYO",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13108,
    localidad: "SIETE PALMAS",
    cp: 3612,
    provincia_id: 9,
  },
  {
    id: 13109,
    localidad: "SEGUNDA PUNTA",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13110,
    localidad: "SATURNINO SEGUROLA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13111,
    localidad: "SARGENTO CABRAL",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13112,
    localidad: "SARGENTO AGRAMONTE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13113,
    localidad: "SANTA ROSA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13114,
    localidad: "SANTA MARIA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13115,
    localidad: "SANTA ISABEL",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13116,
    localidad: "SANTA CATALINA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13117,
    localidad: "SAN SIMON",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13118,
    localidad: "SAN RAMON",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13119,
    localidad: "SAN PEDRO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13120,
    localidad: "SAN MIGUEL",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13121,
    localidad: "SAN MARTIN II",
    cp: 3627,
    provincia_id: 9,
  },
  {
    id: 13122,
    localidad: "SAN MARTIN 1",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13123,
    localidad: "SAN LORENZO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13124,
    localidad: "SAN JUAN",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13125,
    localidad: "SAN JACINTO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13126,
    localidad: "SAN ISIDRO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13127,
    localidad: "SAN HILARIO",
    cp: 3618,
    provincia_id: 9,
  },
  {
    id: 13128,
    localidad: "SAN FRANCISCO DE LAISHI",
    cp: 3605,
    provincia_id: 9,
  },
  {
    id: 13129,
    localidad: "SAN CAYETANO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13130,
    localidad: "SAN CAMILO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13131,
    localidad: "SAN ANTONIO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13132,
    localidad: "SAN ANTONIO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13133,
    localidad: "SALVACION",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13134,
    localidad: "SALADO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13135,
    localidad: "SALADILLO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13136,
    localidad: "S HERIBERTO AVALOS",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13137,
    localidad: "S F DE LAISHI",
    cp: 3605,
    provincia_id: 9,
  },
  {
    id: 13138,
    localidad: "S D SALVATIERRA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13139,
    localidad: "ROZADITO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13140,
    localidad: "RODEO TAPITI",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13141,
    localidad: "RIO CUE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13142,
    localidad: "RINCON ÑARO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13143,
    localidad: "RINCON ÑANDU",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13144,
    localidad: "RIACHO RAMIREZ",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13145,
    localidad: "RIACHO NEGRO",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13146,
    localidad: "RIACHO LINDO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13147,
    localidad: "RIACHO HE HE",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13148,
    localidad: "RED C COQUENA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13149,
    localidad: "RANERO CUE",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13150,
    localidad: "RACEDO ESCOBAR",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13151,
    localidad: "QUEBRACHO MARCADO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13152,
    localidad: "PZO D LS CHANCHOS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13153,
    localidad: "PZO D LAS BOTIJAS",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13154,
    localidad: "PUNTA PORA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13155,
    localidad: "PUNTA GUIA",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13156,
    localidad: "PUESTO AGUARA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13157,
    localidad: "PUERTO SAN CARLOS",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13158,
    localidad: "PUERTO RAMONA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13159,
    localidad: "PUERTO PILCOMAYO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13160,
    localidad: "PUERTO PILCOMAYO",
    cp: 3639,
    provincia_id: 9,
  },
  {
    id: 13161,
    localidad: "PUERTO DALMACIA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13162,
    localidad: "PRIMERA JUNTA",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13163,
    localidad: "PRIMAVERA",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13164,
    localidad: "PRES YRIGOYEN",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13165,
    localidad: "PRES AVELLANEDA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13166,
    localidad: "POZO VERDE",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13167,
    localidad: "POZO LA NEGRA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13168,
    localidad: "POZO LA CHINA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13169,
    localidad: "POZO HONDO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13170,
    localidad: "POZO EL LECHERON",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13171,
    localidad: "POZO DEL TIGRE",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13172,
    localidad: "POZO DEL MORTERO",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13173,
    localidad: "POZO DE NAVAGAN",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13174,
    localidad: "POZO DE LAS GARZAS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13175,
    localidad: "POTRERO NORTE",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13176,
    localidad: "POSTA S CABRAL",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13177,
    localidad: "POSTA CAMBIO A ZALAZAR",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13178,
    localidad: "PORTON NEGRO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13179,
    localidad: "PORTEÑO VIEJO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13180,
    localidad: "PIRANE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13181,
    localidad: "PILANGA III",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13182,
    localidad: "PIGO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13183,
    localidad: "PAVAO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13184,
    localidad: "PATO MARCADO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13185,
    localidad: "PASO NALTE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13186,
    localidad: "PASO LA CRUZ",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13187,
    localidad: "PASO DE NAITE",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13188,
    localidad: "PASO DE LOS TOBAS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13189,
    localidad: "PARQUE NACIONAL",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13190,
    localidad: "PARA TODO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13191,
    localidad: "PALO SANTO",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13192,
    localidad: "PALMAR CHICO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13193,
    localidad: "PALMA SOLA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13194,
    localidad: "P CAMBIO A ZALAZAR",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13195,
    localidad: "P B FORESTAL IGR L",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13196,
    localidad: "OLEGARIO V ANDRADE",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13197,
    localidad: "NUEVO PILCOMAYO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13198,
    localidad: "NUEVO PILCOMAYO",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13199,
    localidad: "MONTEAGUDO",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13200,
    localidad: "MONTE LINDO",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13201,
    localidad: "MONTE CLARO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13202,
    localidad: "MONSEÑOR DE ANDREA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13203,
    localidad: "MISION TACAAGLE",
    cp: 3614,
    provincia_id: 9,
  },
  {
    id: 13204,
    localidad: "MIGUEL CANE",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13205,
    localidad: "MERCEDES CUE",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13206,
    localidad: "MEDIA LUNA",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13207,
    localidad: "MAYOR V  E VFAÑE",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13208,
    localidad: "MATIAS GULACSI",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13209,
    localidad: "MARIANO BOEDO",
    cp: 3604,
    provincia_id: 9,
  },
  {
    id: 13210,
    localidad: "MARCA M",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13211,
    localidad: "MAESTRA B GOMEZ",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13212,
    localidad: "M L CNIA PASTORIL",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13213,
    localidad: "LUCERO CUE",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13214,
    localidad: "LOTE 4",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13215,
    localidad: "LOS TRES REYES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13216,
    localidad: "LOS SUSPIROS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13217,
    localidad: "LOS PILAGAS",
    cp: 3604,
    provincia_id: 9,
  },
  {
    id: 13218,
    localidad: "LOS INMIGRANTES",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13219,
    localidad: "LOS GALPONES",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13220,
    localidad: "LOS ESTEROS",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13221,
    localidad: "LOS CLAVELES",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13222,
    localidad: "LOS CLAVELES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13223,
    localidad: "LOS CHIRIGUANOS",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13224,
    localidad: "LOS BALDES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13225,
    localidad: "LORO CUE",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13226,
    localidad: "LOMA ZAPATU",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13227,
    localidad: "LOMA SENE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13228,
    localidad: "LOMA HERMOSA",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13229,
    localidad: "LOMA CLAVEL",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13230,
    localidad: "LEGUA A",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13231,
    localidad: "LAZO QUEMADO",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13232,
    localidad: "LAS SALADAS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13233,
    localidad: "LAS MOCHAS",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13234,
    localidad: "LAS LOMITAS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13235,
    localidad: "LAS DELICIAS",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13236,
    localidad: "LAS CHOYAS",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13237,
    localidad: "LAS CAÑITAS",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13238,
    localidad: "LAGUNA NAICK NECK",
    cp: 3640,
    provincia_id: 9,
  },
  {
    id: 13239,
    localidad: "LAGUNA MURUA",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13240,
    localidad: "LAGUNA INES",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13241,
    localidad: "LAGUNA GALLO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13242,
    localidad: "LAGUNA BLANCA",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13243,
    localidad: "LA URBANA",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13244,
    localidad: "LA SOLEDAD",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13245,
    localidad: "LA SIRENA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13246,
    localidad: "LA PICADITA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13247,
    localidad: "LA PASION",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13248,
    localidad: "LA PALOMA",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13249,
    localidad: "LA LUCRECIA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13250,
    localidad: "LA LOMA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13251,
    localidad: "LA INMACULADA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13252,
    localidad: "LA FRONTERA",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13253,
    localidad: "LA FLORIDA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13254,
    localidad: "LA ESPERANZA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13255,
    localidad: "LA ESPERANZA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13256,
    localidad: "LA CHINA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13257,
    localidad: "LA BLANCA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13258,
    localidad: "KM 642 N R BERMEJO",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13259,
    localidad: "KILOMETRO 642",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13260,
    localidad: "KILOMETRO 525",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13261,
    localidad: "KILOMETRO 503",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13262,
    localidad: "KILOMETRO 232",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13263,
    localidad: "KILOMETRO 213",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13264,
    localidad: "KILOMETRO 193",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13265,
    localidad: "KILOMETRO 1895",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13266,
    localidad: "KILOMETRO 1769",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13267,
    localidad: "KILOMETRO 1695",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13268,
    localidad: "KILOMETRO 1695",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13269,
    localidad: "KILOMETRO 15",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13270,
    localidad: "KILOMETRO 142",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13271,
    localidad: "KILOMETRO 128",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13272,
    localidad: "KILOMETRO 109",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13273,
    localidad: "JULIO CUE",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13274,
    localidad: "JUAN JOSE PASO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13275,
    localidad: "JUAN G BAZAN",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13276,
    localidad: "JOSE HERNANDEZ",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13277,
    localidad: "ITUZAINGO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13278,
    localidad: "ISLETA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13279,
    localidad: "ISLA TOLDO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13280,
    localidad: "ISLA PAYAGUA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13281,
    localidad: "ISLA OCA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13282,
    localidad: "ISLA DE PUEN",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13283,
    localidad: "ISLA CARAYA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13284,
    localidad: "ISLA APANGO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13285,
    localidad: "ISLA 9 DE JULIO",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13286,
    localidad: "IBARRETA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13287,
    localidad: "HIPOLITO VIEYTES",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13288,
    localidad: "HERRADURA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13289,
    localidad: "HERMINDO BONAS",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13290,
    localidad: "GUAYCOLEC",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13291,
    localidad: "GRAN GUARDIA",
    cp: 3604,
    provincia_id: 9,
  },
  {
    id: 13292,
    localidad: "GENERAL MOSCONI",
    cp: 3637,
    provincia_id: 9,
  },
  {
    id: 13293,
    localidad: "GENERAL MANUEL BELGRANO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13294,
    localidad: "GENERAL FRANCISCO BASILIANO BOSCH",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13295,
    localidad: "GENERAL ENRIQUE MOSCONI",
    cp: 3636,
    provincia_id: 9,
  },
  {
    id: 13296,
    localidad: "GENDARME V GARCETE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13297,
    localidad: "G PABLO RICCHIERI",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13298,
    localidad: "G MANUEL BELGRANO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13299,
    localidad: "G LUNA OLMOS",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13300,
    localidad: "G JULIO DE VEDIA",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13301,
    localidad: "FRONTERA",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13302,
    localidad: "FRAY M ESQUIU",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13303,
    localidad: "FORTIN PILCOMAYO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13304,
    localidad: "FORTIN LA SOLEDAD",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13305,
    localidad: "FORTIN GUEMES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13306,
    localidad: "FORTIN GALPON",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13307,
    localidad: "FORMOSA",
    cp: 3616,
    provincia_id: 9,
  },
  {
    id: 13308,
    localidad: "FORMOSA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13309,
    localidad: "F CABO 1RO CHAVEZ",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13310,
    localidad: "F AMEGHINO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13311,
    localidad: "ESTERO GRANDE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13312,
    localidad: "ESTERITO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13313,
    localidad: "ESTANISLAO DEL CAMPO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13314,
    localidad: "ESTANIO DEL CAMPO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13315,
    localidad: "ESTANCIA EL CIERVO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13316,
    localidad: "ESPINILLO",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13317,
    localidad: "ESPINILLO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13318,
    localidad: "EL YUCHAN",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13319,
    localidad: "EL YACARE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13320,
    localidad: "EL TOTORAL",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13321,
    localidad: "EL TASTAS",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13322,
    localidad: "EL TACURUZAL",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13323,
    localidad: "EL SILENCIO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13324,
    localidad: "EL SAUCE",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13325,
    localidad: "EL SALADO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13326,
    localidad: "EL RESGUARDO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13327,
    localidad: "EL RECREO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13328,
    localidad: "EL QUEBRANTO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13329,
    localidad: "EL QUEBRACHO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13330,
    localidad: "EL POMBERO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13331,
    localidad: "EL POI",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13332,
    localidad: "EL PINDO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13333,
    localidad: "EL PIMPIN",
    cp: 3632,
    provincia_id: 9,
  },
  {
    id: 13334,
    localidad: "EL PERDIDO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13335,
    localidad: "EL PARAISO",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13336,
    localidad: "EL PALMAR",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13337,
    localidad: "EL OMBU",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13338,
    localidad: "EL OLVIDO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13339,
    localidad: "EL OCULTO",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13340,
    localidad: "EL ÑANDU",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13341,
    localidad: "EL MIRADOR",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13342,
    localidad: "EL GUAJHO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13343,
    localidad: "EL GATO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13344,
    localidad: "EL ESPINILLO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13345,
    localidad: "EL ESPINILLO",
    cp: 3612,
    provincia_id: 9,
  },
  {
    id: 13346,
    localidad: "EL DESCANSO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13347,
    localidad: "EL CORREDERO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13348,
    localidad: "EL CORRALITO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13349,
    localidad: "EL COLORADO",
    cp: 3603,
    provincia_id: 9,
  },
  {
    id: 13350,
    localidad: "EL COATI",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13351,
    localidad: "EL CEIBAL",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13352,
    localidad: "EL ARBOLITO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13353,
    localidad: "EL ARBOL SOLO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13354,
    localidad: "EL ANGELITO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13355,
    localidad: "EL ALGARROBO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13356,
    localidad: "E R M CHIRIGUANOS",
    cp: 3625,
    provincia_id: 9,
  },
  {
    id: 13357,
    localidad: "E LAS HORQUETAS",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13358,
    localidad: "DR E R.MEJIA CHIRIGUANOS",
    cp: 3625,
    provincia_id: 9,
  },
  {
    id: 13359,
    localidad: "DR CARLOS MONTAG",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13360,
    localidad: "DESVIO LOS MATACOS",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13361,
    localidad: "CURUPAY",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13362,
    localidad: "CURTIEMBRE CUE",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13363,
    localidad: "CRIA PTE YRIGOYEN",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13364,
    localidad: "COSTA SALADO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13365,
    localidad: "COSTA RIO NEGRO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13366,
    localidad: "COSTA DL PILCOMAYO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13367,
    localidad: "COSTA DEL LINDO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13368,
    localidad: "CORONEL J I WARNES",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13369,
    localidad: "CORONEL F BOGADO",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13370,
    localidad: "CORONEL E ROSTAGNO",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13371,
    localidad: "COLONIA VILLA RICA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13372,
    localidad: "COLONIA TATANE",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13373,
    localidad: "COLONIA SANTORO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13374,
    localidad: "COLONIA SANTA ROSA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13375,
    localidad: "COLONIA SANTA ROSA",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13376,
    localidad: "COLONIA SAN PABLO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13377,
    localidad: "COLONIA SAN JOSE",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13378,
    localidad: "COLONIA SAN ISIDRO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13379,
    localidad: "COLONIA SABINA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13380,
    localidad: "COLONIA PERIN",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13381,
    localidad: "COLONIA PASTORIL",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13382,
    localidad: "COLONIA LA BRAVA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13383,
    localidad: "COLONIA JUANITA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13384,
    localidad: "COLONIA ISLA SOLA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13385,
    localidad: "COLONIA EL OLVIDO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13386,
    localidad: "COLONIA EL CATORCE",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13387,
    localidad: "COLONIA EL ALBA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13388,
    localidad: "COLONIA DALMACIA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13389,
    localidad: "COLONIA CANO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13390,
    localidad: "COLONIA CAMPO VILLAFAÑE",
    cp: 3609,
    provincia_id: 9,
  },
  {
    id: 13391,
    localidad: "COLONIA BOUVIER",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13392,
    localidad: "COLONIA AQUINO",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13393,
    localidad: "COLONIA ALFONSO",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13394,
    localidad: "COLONIA 25 DE MAYO",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13395,
    localidad: "CLORINDA",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13396,
    localidad: "CHURQUI CUE",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13397,
    localidad: "CHIROCHILAS",
    cp: 3613,
    provincia_id: 9,
  },
  {
    id: 13398,
    localidad: "CHAGADAY",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13399,
    localidad: "CEIBO TRECE",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13400,
    localidad: "CATANEO CUE",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13401,
    localidad: "CASCO CUE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13402,
    localidad: "CAÑADA DOCE",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13403,
    localidad: "CAMPO RIGONATO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13404,
    localidad: "CAMPO REDONDO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13405,
    localidad: "CAMPO OSWALD",
    cp: 3608,
    provincia_id: 9,
  },
  {
    id: 13406,
    localidad: "CAMPO HARDY",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13407,
    localidad: "CAMPO GORETA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13408,
    localidad: "CAMPO DEL CIELO",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13409,
    localidad: "CAMPO AZCURRA",
    cp: 3624,
    provincia_id: 9,
  },
  {
    id: 13410,
    localidad: "CAMPO ALEGRE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13411,
    localidad: "CABO PRO CHAVEZ",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13412,
    localidad: "CABO NOROÑA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13413,
    localidad: "CABO 1RO C BENITEZ",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13414,
    localidad: "C UNION ESCUELA",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13415,
    localidad: "C SIETE QUEBRADOS",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 13416,
    localidad: "C SANTA CATALINA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13417,
    localidad: "C SAN BERNARDO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13418,
    localidad: "C PUENTE URIBURU",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13419,
    localidad: "C PUENTE PUCU",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13420,
    localidad: "C PCIPAL ISMAEL ST",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13421,
    localidad: "C PALMAR GRANDE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13422,
    localidad: "C LOS TRES REYES",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13423,
    localidad: "C LA SOCIEDAD",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13424,
    localidad: "C LA DISCIPLINA",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13425,
    localidad: "C JUAN B ALBERDI",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13426,
    localidad: "C ISLA DE ORO",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13427,
    localidad: "C ISLA ALVAREZ",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13428,
    localidad: "C EL ZAPALLITO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13429,
    localidad: "C CAMPO VFAÑE",
    cp: 3609,
    provincia_id: 9,
  },
  {
    id: 13430,
    localidad: "C BUENA VISTA",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 13431,
    localidad: "C ABORIGEN B B D",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13432,
    localidad: "C A LARRABURE",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 13433,
    localidad: "C 8 DE SETIEMBRE",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13434,
    localidad: "C 5 DE OCTUBRE",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13435,
    localidad: "C  F J MUÑIZ",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13436,
    localidad: "BUENA VISTA",
    cp: 3617,
    provincia_id: 9,
  },
  {
    id: 13437,
    localidad: "BRUCHARD",
    cp: 3622,
    provincia_id: 9,
  },
  {
    id: 13438,
    localidad: "BOCARIN",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13439,
    localidad: "BELLA VISTA",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13440,
    localidad: "BARTOLOME DE LAS CASAS",
    cp: 3622,
    provincia_id: 9,
  },
  {
    id: 13441,
    localidad: "BARRIO SUD AMERICA",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13442,
    localidad: "BARRIO SAN MARTIN",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13443,
    localidad: "BAÑADEROS",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13444,
    localidad: "BANCO PAYAGUA",
    cp: 3601,
    provincia_id: 9,
  },
  {
    id: 13445,
    localidad: "BALLON",
    cp: 3634,
    provincia_id: 9,
  },
  {
    id: 13446,
    localidad: "BAJO VERDE",
    cp: 3634,
    provincia_id: 9,
  },
  {
    id: 13447,
    localidad: "BAJO HONDO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13448,
    localidad: "BAHIA NEGRA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13449,
    localidad: "B S JOSE OBRERO",
    cp: 3606,
    provincia_id: 9,
  },
  {
    id: 13450,
    localidad: "B G PUEYRREDON",
    cp: 3610,
    provincia_id: 9,
  },
  {
    id: 13451,
    localidad: "B DEL R DE PILAGA",
    cp: 3600,
    provincia_id: 9,
  },
  {
    id: 13452,
    localidad: "AYUDANTE PAREDES",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13453,
    localidad: "ARBOL SOLO",
    cp: 3628,
    provincia_id: 9,
  },
  {
    id: 13454,
    localidad: "APAYEREY",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13455,
    localidad: "ANGOSTURA",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13456,
    localidad: "ANDRES FLORES",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 13457,
    localidad: "ALTO ALEGRE",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 13458,
    localidad: "ALTO ALEGRE",
    cp: 3634,
    provincia_id: 9,
  },
  {
    id: 13459,
    localidad: "ALOLAGUE",
    cp: 3626,
    provincia_id: 9,
  },
  {
    id: 13460,
    localidad: "ALFONSINA STORNI",
    cp: 3634,
    provincia_id: 9,
  },
  {
    id: 13461,
    localidad: "AGUAS NEGRAS",
    cp: 3615,
    provincia_id: 9,
  },
  {
    id: 13462,
    localidad: "AGENTE A ALEGRE",
    cp: 3634,
    provincia_id: 9,
  },
  {
    id: 13463,
    localidad: "A OLEGARIO VICTOR",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13464,
    localidad: "3 LAGS  PILCOMAYO",
    cp: 3611,
    provincia_id: 9,
  },
  {
    id: 13465,
    localidad: "3 LAGS  HERRADURA",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13466,
    localidad: "19 DE MARZO",
    cp: 3630,
    provincia_id: 9,
  },
  {
    id: 13467,
    localidad: "YUQUERI",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 13468,
    localidad: "YESO OESTE",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13469,
    localidad: "YESO",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13470,
    localidad: "YERUA",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 13471,
    localidad: "YATAY",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 13472,
    localidad: "YAROS",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13473,
    localidad: "YACARE",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13474,
    localidad: "WALTER MOSS",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13475,
    localidad: "VIZCACHERA",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 13476,
    localidad: "VIRANO",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13477,
    localidad: "VILLAMIL",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 13478,
    localidad: "VILLAGUAYCITO",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 13479,
    localidad: "VILLAGUAY ESTE",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13480,
    localidad: "VILLAGUAY",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13481,
    localidad: "VILLA ZORRAQUIN",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13482,
    localidad: "VILLA URQUIZA",
    cp: 3113,
    provincia_id: 8,
  },
  {
    id: 13483,
    localidad: "VILLA URQUIZA",
    cp: 3126,
    provincia_id: 8,
  },
  {
    id: 13484,
    localidad: "VILLA URANGA",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13485,
    localidad: "VILLA UDINE",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13486,
    localidad: "VILLA SARMIENTO",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13487,
    localidad: "VILLA SAN MIGUEL",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13488,
    localidad: "VILLA SAN MARCIAL",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 13489,
    localidad: "VILLA SAN JUSTO",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13490,
    localidad: "VILLA SAN JOSE",
    cp: 3282,
    provincia_id: 8,
  },
  {
    id: 13491,
    localidad: "VILLA ROMERO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13492,
    localidad: "VILLA PERPER",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 13493,
    localidad: "VILLA PARANACITO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13494,
    localidad: "VILLA MANTERO",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13495,
    localidad: "VILLA LILA",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13496,
    localidad: "VILLA LIBERTADOR SAN MARTIN",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13497,
    localidad: "VILLA LIBERTAD",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13498,
    localidad: "VILLA HERNANDARIAS",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 13499,
    localidad: "VILLA FONTANA",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 13500,
    localidad: "VILLA ERRECABORDE",
    cp: 3250,
    provincia_id: 8,
  },
  {
    id: 13501,
    localidad: "VILLA ELISA",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 13502,
    localidad: "VILLA ELEONORA",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13503,
    localidad: "VILLA DOMINGUEZ",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13504,
    localidad: "VILLA DEL ROSARIO",
    cp: 3233,
    provincia_id: 8,
  },
  {
    id: 13505,
    localidad: "VILLA DEL ROSARIO",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 13506,
    localidad: "VILLA DEL CERRO",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 13507,
    localidad: "VILLA CLARA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13508,
    localidad: "VILLA BOREILO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13509,
    localidad: "VILLA ANTONY",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13510,
    localidad: "VILLA AIDA",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13511,
    localidad: "VICTORIA ESTAF 01",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13512,
    localidad: "VICTORIA",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13513,
    localidad: "VIBORAS",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13514,
    localidad: "VIALE",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 13515,
    localidad: "VERGARA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13516,
    localidad: "VALLE MARIA",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13517,
    localidad: "V TRES DE FEBRERO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13518,
    localidad: "V ORTIZ PEREIRA",
    cp: 3250,
    provincia_id: 8,
  },
  {
    id: 13519,
    localidad: "V LIB SAN MARTIN",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13520,
    localidad: "V G L ETCHEVEHERE",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 13521,
    localidad: "V FTINO M PARERA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13522,
    localidad: "URDINARRAIN",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 13523,
    localidad: "UBAJAY",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 13524,
    localidad: "TTE P BRIG CAINZO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13525,
    localidad: "TRES LAGUNAS",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13526,
    localidad: "TRES ESQUINAS",
    cp: 3156,
    provincia_id: 8,
  },
  {
    id: 13527,
    localidad: "TRES ESQUINAS",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13528,
    localidad: "TRES BOCAS",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13529,
    localidad: "TRES ALDEAS",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13530,
    localidad: "TOMAS ROCAMORA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13531,
    localidad: "TEZANOS PINTO",
    cp: 3131,
    provincia_id: 8,
  },
  {
    id: 13532,
    localidad: "TASES",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13533,
    localidad: "TAPEBICUA",
    cp: 3236,
    provincia_id: 8,
  },
  {
    id: 13534,
    localidad: "TALITAS-GUALEG.",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 13535,
    localidad: "TALITAS",
    cp: 3136,
    provincia_id: 8,
  },
  {
    id: 13536,
    localidad: "TALITA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13537,
    localidad: "TACUARAS YACARE",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13538,
    localidad: "TABOSSI",
    cp: 3124,
    provincia_id: 8,
  },
  {
    id: 13539,
    localidad: "TABOSSI",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13540,
    localidad: "T NORTE CONCORDIA",
    cp: 3200,
    provincia_id: 8,
  },
  {
    id: 13541,
    localidad: "SURST",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13542,
    localidad: "SUBCENTRAL S MARIA",
    cp: 3180,
    provincia_id: 8,
  },
  {
    id: 13543,
    localidad: "STROBEL",
    cp: 3110,
    provincia_id: 8,
  },
  {
    id: 13544,
    localidad: "SPINDOLA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13545,
    localidad: "SOSA",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 13546,
    localidad: "SOLA",
    cp: 3176,
    provincia_id: 8,
  },
  {
    id: 13547,
    localidad: "SIR LEONARD",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13548,
    localidad: "SEXTO DISTRITO",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13549,
    localidad: "SEPTIMO DISTRITO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13550,
    localidad: "SEPTIMO DISTRITO",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13551,
    localidad: "SEGUNDO CUARTEL",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13552,
    localidad: "SEGUI",
    cp: 3117,
    provincia_id: 8,
  },
  {
    id: 13553,
    localidad: "SECCION URQUIZA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13554,
    localidad: "SAXTO DTO COLON",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 13555,
    localidad: "SAUCE SUD",
    cp: 2845,
    provincia_id: 8,
  },
  {
    id: 13556,
    localidad: "SAUCE PINTO",
    cp: 3107,
    provincia_id: 8,
  },
  {
    id: 13557,
    localidad: "SAUCE NORTE",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13558,
    localidad: "SAUCE NORTE",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 13559,
    localidad: "SAUCE MONTRULL",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13560,
    localidad: "SAUCE DE LUNA",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 13561,
    localidad: "SAUCE",
    cp: 3152,
    provincia_id: 8,
  },
  {
    id: 13562,
    localidad: "SARANDI-CORA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13563,
    localidad: "SARANDI",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 13564,
    localidad: "SARANDI",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13565,
    localidad: "SANTA SARA",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 13566,
    localidad: "SANTA ROSA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13567,
    localidad: "SANTA ROSA",
    cp: 3424,
    provincia_id: 8,
  },
  {
    id: 13568,
    localidad: "SANTA ROSA",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 13569,
    localidad: "SANTA MARTA",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13570,
    localidad: "SANTA MARIA",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13571,
    localidad: "SANTA LUISA",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 13572,
    localidad: "SANTA ISABEL",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 13573,
    localidad: "SANTA INES",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13574,
    localidad: "SANTA INES",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13575,
    localidad: "SANTA ELENA",
    cp: 3192,
    provincia_id: 8,
  },
  {
    id: 13576,
    localidad: "SANTA CECILIA",
    cp: 3196,
    provincia_id: 8,
  },
  {
    id: 13577,
    localidad: "SANTA ANITA",
    cp: 3249,
    provincia_id: 8,
  },
  {
    id: 13578,
    localidad: "SANTA ANA",
    cp: 3208,
    provincia_id: 8,
  },
  {
    id: 13579,
    localidad: "SAN VICTOR",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13580,
    localidad: "SAN VICENTE",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13581,
    localidad: "SAN SALVADOR",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 13582,
    localidad: "SAN RAMIREZ",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13583,
    localidad: "SAN PEDRO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13584,
    localidad: "SAN MIGUEL",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13585,
    localidad: "SAN MARTIN",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13586,
    localidad: "SAN MARTIN",
    cp: 3113,
    provincia_id: 8,
  },
  {
    id: 13587,
    localidad: "SAN LORENZO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13588,
    localidad: "SAN JUSTO",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13589,
    localidad: "SAN JULIAN",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13590,
    localidad: "SAN JUAN",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13591,
    localidad: "SAN JOSE DE FELICIANO",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13592,
    localidad: "SAN JOSE",
    cp: 3283,
    provincia_id: 8,
  },
  {
    id: 13593,
    localidad: "SAN JORGE",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13594,
    localidad: "SAN JAIME DE LA FRONTERA",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13595,
    localidad: "SAN JAIME",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13596,
    localidad: "SAN GUSTAVO",
    cp: 3184,
    provincia_id: 8,
  },
  {
    id: 13597,
    localidad: "SAN GUSTAVO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13598,
    localidad: "SAN GREGORIO",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 13599,
    localidad: "SAN GERONIMO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13600,
    localidad: "SAN FRANCISCO",
    cp: 3196,
    provincia_id: 8,
  },
  {
    id: 13601,
    localidad: "SAN FRANCISCO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13602,
    localidad: "SAN FRANCISCO",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 13603,
    localidad: "SAN FERNANDO",
    cp: 3196,
    provincia_id: 8,
  },
  {
    id: 13604,
    localidad: "SAN CIPRIANO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 13605,
    localidad: "SAN BUENAVENTURA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13606,
    localidad: "SAN BENITO",
    cp: 3107,
    provincia_id: 8,
  },
  {
    id: 13607,
    localidad: "SAN ANTONIO",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 13608,
    localidad: "SAN ANTONIO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13609,
    localidad: "SAN ANSELMO",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 13610,
    localidad: "SALADERO SAN JOSE",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13611,
    localidad: "SALADERO CONCORDIA",
    cp: 3200,
    provincia_id: 8,
  },
  {
    id: 13612,
    localidad: "SALADERO ALZUA",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13613,
    localidad: "SAGASTUME",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13614,
    localidad: "SAGASTUME",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13615,
    localidad: "S.J. DE FELICIANO",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13616,
    localidad: "S SEC LUCAS AL SUD",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13617,
    localidad: "S NIC. DE LOS AS",
    cp: 2900,
    provincia_id: 8,
  },
  {
    id: 13618,
    localidad: "S L S J FELICIANO",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13619,
    localidad: "S J LA QUERENCIA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13620,
    localidad: "S J DE LA FRONTERA",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13621,
    localidad: "S APEADERO FCGU",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13622,
    localidad: "S ADVEN DEL PLATA",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13623,
    localidad: "RUTA 14 KM 443",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13624,
    localidad: "ROSPINA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13625,
    localidad: "ROSARIO DEL TALA",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13626,
    localidad: "ROCAMORA",
    cp: 3172,
    provincia_id: 8,
  },
  {
    id: 13627,
    localidad: "RIVAS",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 13628,
    localidad: "RIO PARANACITO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 13629,
    localidad: "RINCON LUCAS SUD",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13630,
    localidad: "RINCON LUCAS NORTE",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13631,
    localidad: "RINCON DEL GATO",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 13632,
    localidad: "RINCON DEL GATO",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13633,
    localidad: "RINCON DEL DOLL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13634,
    localidad: "RINCON DE TUNAS",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13635,
    localidad: "RINCON DE NOGOYA",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13636,
    localidad: "RINCON DE MOJONES",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13637,
    localidad: "RINCON DE CINTO",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 13638,
    localidad: "RAMON A.PARERA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13639,
    localidad: "RAMON A PARERA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13640,
    localidad: "RAMIREZ",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 13641,
    localidad: "RAMBLON",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 13642,
    localidad: "RAICES OESTE",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13643,
    localidad: "RAICES AL NORTE",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13644,
    localidad: "RAICES AL ESTE",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13645,
    localidad: "RAICES",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13646,
    localidad: "RACHEL",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13647,
    localidad: "RACEDO",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 13648,
    localidad: "R DEL NOGOYA SUR",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13649,
    localidad: "R DE LAS GUACHAS",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13650,
    localidad: "QUINTO DISTRITO",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13651,
    localidad: "QUEBRACHO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13652,
    localidad: "QUEBRACHO",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 13653,
    localidad: "QUEBRACHITO",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13654,
    localidad: "PUNTAS DEL PALMAR",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13655,
    localidad: "PUNTAS DE MOREIRA",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 13656,
    localidad: "PUNTA DEL MONTE",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13657,
    localidad: "PUJOL",
    cp: 2907,
    provincia_id: 8,
  },
  {
    id: 13658,
    localidad: "PUIGARI",
    cp: 3104,
    provincia_id: 8,
  },
  {
    id: 13659,
    localidad: "PUIGARI",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13660,
    localidad: "PUERTO YUNQUE",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13661,
    localidad: "PUERTO YERUA",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13662,
    localidad: "PUERTO VILLARRUEL",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 13663,
    localidad: "PUERTO VIEJO",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13664,
    localidad: "PUERTO VIEJO",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13665,
    localidad: "PUERTO VIBORAS",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 13666,
    localidad: "PUERTO UNZUE",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13667,
    localidad: "PUERTO SAN JUAN",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13668,
    localidad: "PUERTO RUIZ",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13669,
    localidad: "PUERTO PERAZZO",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13670,
    localidad: "PUERTO MARQUEZ",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13671,
    localidad: "PUERTO LOPEZ",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13672,
    localidad: "PUERTO LAS CUEVAS",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13673,
    localidad: "PUERTO IBICUY",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13674,
    localidad: "PUERTO ESQUINA",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13675,
    localidad: "PUERTO DIAMANTE",
    cp: 3105,
    provincia_id: 8,
  },
  {
    id: 13676,
    localidad: "PUERTO CURTIEMBRE",
    cp: 3113,
    provincia_id: 8,
  },
  {
    id: 13677,
    localidad: "PUERTO CONSTANZA",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13678,
    localidad: "PUERTO COLORADO",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13679,
    localidad: "PUERTO CADENAS",
    cp: 3192,
    provincia_id: 8,
  },
  {
    id: 13680,
    localidad: "PUERTO BARRILES",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13681,
    localidad: "PUERTO ALMIRON",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 13682,
    localidad: "PUERTO ALGARROBO",
    cp: 3129,
    provincia_id: 8,
  },
  {
    id: 13683,
    localidad: "PUERTO ALGARROBO",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 13684,
    localidad: "PUERTA DE CRESPO",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13685,
    localidad: "PUENTE VICTORIA",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13686,
    localidad: "PUENTE PELLEGRINI",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13687,
    localidad: "PUENTE PARANACITO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13688,
    localidad: "PUENTE OBISPO",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13689,
    localidad: "PUENTE DEL DOLL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13690,
    localidad: "PUENTE DE LUCAS",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13691,
    localidad: "PUENTE CARMONA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13692,
    localidad: "PUEBLO NUEVO",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13693,
    localidad: "PUEBLO NUEVO",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13694,
    localidad: "PUEBLO MORENO",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 13695,
    localidad: "PUEBLO GENERAL PAZ",
    cp: 3123,
    provincia_id: 8,
  },
  {
    id: 13696,
    localidad: "PUEBLO G BELGRANO",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 13697,
    localidad: "PUEBLO FERRE",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13698,
    localidad: "PUEBLO ELLISON",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13699,
    localidad: "PUEBLO DOMINGUEZ",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13700,
    localidad: "PUEBLO COLORADO",
    cp: 3286,
    provincia_id: 8,
  },
  {
    id: 13701,
    localidad: "PUEBLO CAZES",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13702,
    localidad: "PUEBLO BRUGO",
    cp: 3125,
    provincia_id: 8,
  },
  {
    id: 13703,
    localidad: "PUEBLO BELLOCQ",
    cp: 3136,
    provincia_id: 8,
  },
  {
    id: 13704,
    localidad: "PUEBLITO NORTE",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13705,
    localidad: "PUEBLITO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13706,
    localidad: "PTO LA ESMERALDA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13707,
    localidad: "PTO CAMPINCHUELO",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13708,
    localidad: "PRONUNCIAMIENTO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 13709,
    localidad: "PRIMER DISTRITO",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13710,
    localidad: "PRIMER CUARTEL",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13711,
    localidad: "PRIMER CONGRESO",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13712,
    localidad: "PRIA DIAMANTINO",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13713,
    localidad: "PRES AVELLANEDA",
    cp: 3107,
    provincia_id: 8,
  },
  {
    id: 13714,
    localidad: "POS POS",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13715,
    localidad: "PILOTO AVILA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13716,
    localidad: "PIEDRAS BLANCAS",
    cp: 3129,
    provincia_id: 8,
  },
  {
    id: 13717,
    localidad: "PERUCHO VERNA",
    cp: 3283,
    provincia_id: 8,
  },
  {
    id: 13718,
    localidad: "PERDICES",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13719,
    localidad: "PEHUAJO SUD",
    cp: 2854,
    provincia_id: 8,
  },
  {
    id: 13720,
    localidad: "PEHUAJO NORTE",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 13721,
    localidad: "PEDERNAL",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 13722,
    localidad: "PEDERMAR",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 13723,
    localidad: "PASTOR BRITOS",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 13724,
    localidad: "PASO TELEGRAFO",
    cp: 3194,
    provincia_id: 8,
  },
  {
    id: 13725,
    localidad: "PASO SOCIEDAD",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 13726,
    localidad: "PASO POTRILLO",
    cp: 3129,
    provincia_id: 8,
  },
  {
    id: 13727,
    localidad: "PASO MEDINA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13728,
    localidad: "PASO GARIBALDI",
    cp: 3192,
    provincia_id: 8,
  },
  {
    id: 13729,
    localidad: "PASO DEL MOLINO",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13730,
    localidad: "PASO DEL GALLO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13731,
    localidad: "PASO DEL CISNERO",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13732,
    localidad: "PASO DEL ABRA",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 13733,
    localidad: "PASO DE LA LAGUNA",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13734,
    localidad: "PASO DE LA BALZA",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13735,
    localidad: "PASO DE LA ARENA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13736,
    localidad: "PARANACITO RIO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 13737,
    localidad: "PARANACITO",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13738,
    localidad: "PARANA",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13739,
    localidad: "PARANA",
    cp: 3141,
    provincia_id: 8,
  },
  {
    id: 13740,
    localidad: "PARANA",
    cp: 3108,
    provincia_id: 8,
  },
  {
    id: 13741,
    localidad: "PARANA",
    cp: 3106,
    provincia_id: 8,
  },
  {
    id: 13742,
    localidad: "PARANA",
    cp: 3104,
    provincia_id: 8,
  },
  {
    id: 13743,
    localidad: "PARANA",
    cp: 3102,
    provincia_id: 8,
  },
  {
    id: 13744,
    localidad: "PARAJE PORTILLO",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13745,
    localidad: "PARADA YUQUERI",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 13746,
    localidad: "PARADA PUCHETA",
    cp: 3235,
    provincia_id: 8,
  },
  {
    id: 13747,
    localidad: "PARACAO",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13748,
    localidad: "PALO A PIQUE",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13749,
    localidad: "PALMAR YATAY",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 13750,
    localidad: "PALMAR",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13751,
    localidad: "PALAVECINO",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13752,
    localidad: "PALACIO SAN JOSE",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13753,
    localidad: "PAJONAL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13754,
    localidad: "PAJAS BLANCAS",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13755,
    localidad: "PAJA BRAVA",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13756,
    localidad: "P PUERTO AUGUSTO",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13757,
    localidad: "P NACEL PALMAR",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13758,
    localidad: "P DIAZ COLODRERO",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13759,
    localidad: "P DE LAS PIEDRAS",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13760,
    localidad: "P DE LAS PENCAS",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13761,
    localidad: "P DE GUALEGUAYCHU",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 13762,
    localidad: "P DE GUALEGUAYCHU",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13763,
    localidad: "OSVALDO MAGÑASCO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13764,
    localidad: "OSVALDO MAGNASCO",
    cp: 3213,
    provincia_id: 8,
  },
  {
    id: 13765,
    localidad: "ORO VERDE",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 13766,
    localidad: "OMBUES",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13767,
    localidad: "NUEVA VIZCAYA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13768,
    localidad: "NUEVA ESCOCIA",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13769,
    localidad: "NOVIBUCO PRIMERO",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13770,
    localidad: "NOREIRA",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 13771,
    localidad: "NOGOYA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13772,
    localidad: "NICOLAS HERRERA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13773,
    localidad: "MORENO",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 13774,
    localidad: "MONTOYA VICTORIA",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 13775,
    localidad: "MONTOYA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13776,
    localidad: "MONTIEL",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13777,
    localidad: "MONTE VERDE",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13778,
    localidad: "MONTE CHICO",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 13779,
    localidad: "MOLINO DOLL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13780,
    localidad: "MOLINO BOB",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13781,
    localidad: "MOJONES SUR 2DO",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13782,
    localidad: "MOJONES SUD PRIMERO",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13783,
    localidad: "MOJONES SUD 1RO",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13784,
    localidad: "MOJONES NORTE",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13785,
    localidad: "MIRA MONTE",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13786,
    localidad: "MIGUEL J PERLIZA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13787,
    localidad: "MESA",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13788,
    localidad: "MEDANOS",
    cp: 2848,
    provincia_id: 8,
  },
  {
    id: 13789,
    localidad: "MAZARUCA",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13790,
    localidad: "MAURICIO RIBOLE",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13791,
    localidad: "MARTINETTI",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13792,
    localidad: "MART LEGUIZAMON",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13793,
    localidad: "MARIA GRANDE",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 13794,
    localidad: "MARIA G SEGDA SUR",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 13795,
    localidad: "MARIA G PRIMERA",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13796,
    localidad: "MANGRULLO",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 13797,
    localidad: "MANDISOVI",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13798,
    localidad: "MANATIALES",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13799,
    localidad: "MACIA",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13800,
    localidad: "MAC KELLER",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13801,
    localidad: "MAC DOUGALL",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13802,
    localidad: "LUCIENVILLE 4",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13803,
    localidad: "LUCIENVILLE 3",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13804,
    localidad: "LUCIENVILLE 2",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13805,
    localidad: "LUCIENVILLE 1",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13806,
    localidad: "LUCAS SUD",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13807,
    localidad: "LUCAS NORTE",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13808,
    localidad: "LUCAS NORTE",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13809,
    localidad: "LUCAS NORESTE",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13810,
    localidad: "LUCAS GONZALEZ",
    cp: 3145,
    provincia_id: 8,
  },
  {
    id: 13811,
    localidad: "LUCAS GONZALEZ",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 13812,
    localidad: "LOS SAUER",
    cp: 3200,
    provincia_id: 8,
  },
  {
    id: 13813,
    localidad: "LOS PARAISOS",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13814,
    localidad: "LOS PARAISOS",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13815,
    localidad: "LOS OMBUES",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13816,
    localidad: "LOS NARANJOS",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 13817,
    localidad: "LOS GANSOS",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13818,
    localidad: "LOS CONQUISTADORES",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 13819,
    localidad: "LOS CHARRUAS",
    cp: 3215,
    provincia_id: 8,
  },
  {
    id: 13820,
    localidad: "LOS CHARRUAS",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13821,
    localidad: "LOS BRILLANTES",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13822,
    localidad: "LOS B APEAD. FCGU",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 13823,
    localidad: "LOS AMIGOS",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13824,
    localidad: "LOMAS BLANCAS",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13825,
    localidad: "LOMA NEGRA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13826,
    localidad: "LIONEL",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13827,
    localidad: "LINEA 25",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13828,
    localidad: "LINEA 24",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13829,
    localidad: "LINEA 20",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13830,
    localidad: "LINEA 19",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13831,
    localidad: "LIEBIG",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 13832,
    localidad: "LIEBIG",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13833,
    localidad: "LIEBIG",
    cp: 3282,
    provincia_id: 8,
  },
  {
    id: 13834,
    localidad: "LIBERTADOR SAN MARTIN",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13835,
    localidad: "LIBERTADOR S M",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 13836,
    localidad: "LIBERTADOR GRAL SAN MARTIN",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13837,
    localidad: "LIBERTAD",
    cp: 3196,
    provincia_id: 8,
  },
  {
    id: 13838,
    localidad: "LIBER GRAL S M",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13839,
    localidad: "LIBAROS",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13840,
    localidad: "LAZO",
    cp: 2841,
    provincia_id: 8,
  },
  {
    id: 13841,
    localidad: "LAZO",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13842,
    localidad: "LAURENCENA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13843,
    localidad: "LAS TUNAS",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13844,
    localidad: "LAS TOSCAS",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13845,
    localidad: "LAS TEJAS",
    cp: 3165,
    provincia_id: 8,
  },
  {
    id: 13846,
    localidad: "LAS ROSAS",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13847,
    localidad: "LAS PAJITAS",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13848,
    localidad: "LAS MULITAS",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13849,
    localidad: "LAS MOSCAS",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13850,
    localidad: "LAS MOCHAS",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13851,
    localidad: "LAS MERCEDES",
    cp: 2854,
    provincia_id: 8,
  },
  {
    id: 13852,
    localidad: "LAS MERCEDES",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13853,
    localidad: "LAS MASITAS",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 13854,
    localidad: "LAS LAGUNAS",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 13855,
    localidad: "LAS LAGUNAS",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13856,
    localidad: "LAS GAUCHAS",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13857,
    localidad: "LAS GARZAS",
    cp: 3136,
    provincia_id: 8,
  },
  {
    id: 13858,
    localidad: "LAS CUEVAS",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 13859,
    localidad: "LAS COLAS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13860,
    localidad: "LAS BATEAS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13861,
    localidad: "LAS ACHIRAS",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 13862,
    localidad: "LARROQUE",
    cp: 2854,
    provincia_id: 8,
  },
  {
    id: 13863,
    localidad: "LAMARCA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 13864,
    localidad: "LAGUNA LARGA",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 13865,
    localidad: "LAGUNA DEL PESCADO",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 13866,
    localidad: "LA ZELMIRA",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 13867,
    localidad: "LA VIRGINIA",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 13868,
    localidad: "LA VERBENA",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13869,
    localidad: "LA TIGRESA",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13870,
    localidad: "LA SUIZA",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13871,
    localidad: "LA SOLEDAD",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 13872,
    localidad: "LA SESTEADA",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13873,
    localidad: "LA SELVA",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 13874,
    localidad: "LA S DIEZ CASAS",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13875,
    localidad: "LA ROSADA",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13876,
    localidad: "LA QUINTA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13877,
    localidad: "LA QUERENCIA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13878,
    localidad: "LA PICADA NORTE",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13879,
    localidad: "LA PICADA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13880,
    localidad: "LA PERLA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13881,
    localidad: "LA PAZ",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 13882,
    localidad: "LA PAMPA",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 13883,
    localidad: "LA OLLITA",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13884,
    localidad: "LA ODILIA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13885,
    localidad: "LA NOBLEZA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13886,
    localidad: "LA MILAGROSA",
    cp: 3300,
    provincia_id: 8,
  },
  {
    id: 13887,
    localidad: "LA MARUJA A",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 13888,
    localidad: "LA MARIA LUISA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13889,
    localidad: "LA LOMA",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 13890,
    localidad: "LA LLAVE",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 13891,
    localidad: "LA JULIANA",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 13892,
    localidad: "LA JOYA",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13893,
    localidad: "LA INVERNADA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13894,
    localidad: "LA ILUSION",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13895,
    localidad: "LA HIERRA",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 13896,
    localidad: "LA GRANJA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13897,
    localidad: "LA GOYA",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13898,
    localidad: "LA FLORIDA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13899,
    localidad: "LA FLORENCIA",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 13900,
    localidad: "LA FAVORITA",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 13901,
    localidad: "LA ESTRELLA",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 13902,
    localidad: "LA ESMERALDA",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 13903,
    localidad: "LA ESCONDIDA",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 13904,
    localidad: "LA ENCIERRA",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 13905,
    localidad: "LA EMILIA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13906,
    localidad: "LA ELENA",
    cp: 3230,
    provincia_id: 8,
  },
  {
    id: 13907,
    localidad: "LA DILIGENCIA",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13908,
    localidad: "LA CUADRA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13909,
    localidad: "LA CRIOLLA",
    cp: 3217,
    provincia_id: 8,
  },
  {
    id: 13910,
    localidad: "LA COSTA",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 13911,
    localidad: "LA CORVINA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 13912,
    localidad: "LA COLORADA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13913,
    localidad: "LA COLMENA",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 13914,
    localidad: "LA COLINA",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 13915,
    localidad: "LA CLARITA",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13916,
    localidad: "LA CLARITA",
    cp: 3271,
    provincia_id: 8,
  },
  {
    id: 13917,
    localidad: "LA CHILENA ZANJA",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 13918,
    localidad: "LA CHILENA CANAL",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 13919,
    localidad: "LA CHICA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13920,
    localidad: "LA CARLOTA",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 13921,
    localidad: "LA CAPILLA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 13922,
    localidad: "LA CALERA - ENTRE RIOS",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13923,
    localidad: "LA CALERA - ENTRE RIOS",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 13924,
    localidad: "LA BARRACA",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 13925,
    localidad: "LA BALSA PARANA",
    cp: 3113,
    provincia_id: 8,
  },
  {
    id: 13926,
    localidad: "LA BALSA",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 13927,
    localidad: "LA ARGENTINA",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13928,
    localidad: "LA AMISTAD",
    cp: 3196,
    provincia_id: 8,
  },
  {
    id: 13929,
    localidad: "LA AMIGUITA",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 13930,
    localidad: "LA AMELIA",
    cp: 3230,
    provincia_id: 8,
  },
  {
    id: 13931,
    localidad: "LA ALICIA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13932,
    localidad: "KILOMETRO 99",
    cp: 3283,
    provincia_id: 8,
  },
  {
    id: 13933,
    localidad: "KILOMETRO 89",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13934,
    localidad: "KILOMETRO 88",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13935,
    localidad: "KILOMETRO 86",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 13936,
    localidad: "KILOMETRO 84",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 13937,
    localidad: "KILOMETRO 6",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 13938,
    localidad: "KILOMETRO 56",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13939,
    localidad: "KILOMETRO 50",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 13940,
    localidad: "KILOMETRO 49",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13941,
    localidad: "KILOMETRO 45",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 13942,
    localidad: "KILOMETRO 45",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 13943,
    localidad: "KILOMETRO 44",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 13944,
    localidad: "KILOMETRO 43",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 13945,
    localidad: "KILOMETRO 389",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 13946,
    localidad: "KILOMETRO 376",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 13947,
    localidad: "KILOMETRO 373",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 13948,
    localidad: "KILOMETRO 37",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 13949,
    localidad: "KILOMETRO 361",
    cp: 2848,
    provincia_id: 8,
  },
  {
    id: 13950,
    localidad: "KILOMETRO 355",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13951,
    localidad: "KILOMETRO 353",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 13952,
    localidad: "KILOMETRO 344",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13953,
    localidad: "KILOMETRO 344",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13954,
    localidad: "KILOMETRO 343",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 13955,
    localidad: "KILOMETRO 340",
    cp: 2848,
    provincia_id: 8,
  },
  {
    id: 13956,
    localidad: "KILOMETRO 337",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13957,
    localidad: "KILOMETRO 336",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13958,
    localidad: "KILOMETRO 333",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13959,
    localidad: "KILOMETRO 33",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 13960,
    localidad: "KILOMETRO 329",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 13961,
    localidad: "KILOMETRO 325",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 13962,
    localidad: "KILOMETRO 324",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13963,
    localidad: "KILOMETRO 322",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13964,
    localidad: "KILOMETRO 311",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 13965,
    localidad: "KILOMETRO 310",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 13966,
    localidad: "KILOMETRO 306",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 13967,
    localidad: "KILOMETRO 306",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13968,
    localidad: "KILOMETRO 305",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 13969,
    localidad: "KILOMETRO 303",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13970,
    localidad: "KILOMETRO 293",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 13971,
    localidad: "KILOMETRO 290",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 13972,
    localidad: "KILOMETRO 288",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13973,
    localidad: "KILOMETRO 285",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13974,
    localidad: "KILOMETRO 284",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 13975,
    localidad: "KILOMETRO 283",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 13976,
    localidad: "KILOMETRO 28",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 13977,
    localidad: "KILOMETRO 270",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 13978,
    localidad: "KILOMETRO 268",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 13979,
    localidad: "KILOMETRO 253",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13980,
    localidad: "KILOMETRO 25",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13981,
    localidad: "KILOMETRO 244",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13982,
    localidad: "KILOMETRO 242",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 13983,
    localidad: "KILOMETRO 231",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 13984,
    localidad: "KILOMETRO 220",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13985,
    localidad: "KILOMETRO 208",
    cp: 3172,
    provincia_id: 8,
  },
  {
    id: 13986,
    localidad: "KILOMETRO 200",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 13987,
    localidad: "KILOMETRO 192",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13988,
    localidad: "KILOMETRO 189",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13989,
    localidad: "KILOMETRO 183",
    cp: 3176,
    provincia_id: 8,
  },
  {
    id: 13990,
    localidad: "KILOMETRO 180",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 13991,
    localidad: "KILOMETRO 173",
    cp: 3220,
    provincia_id: 8,
  },
  {
    id: 13992,
    localidad: "KILOMETRO 167",
    cp: 3220,
    provincia_id: 8,
  },
  {
    id: 13993,
    localidad: "KILOMETRO 161",
    cp: 3220,
    provincia_id: 8,
  },
  {
    id: 13994,
    localidad: "KILOMETRO 160",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 13995,
    localidad: "KILOMETRO 148",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 13996,
    localidad: "KILOMETRO 147",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 13997,
    localidad: "KILOMETRO 131",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 13998,
    localidad: "KILOMETRO 116",
    cp: 3132,
    provincia_id: 8,
  },
  {
    id: 13999,
    localidad: "KILOMETRO 115",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14000,
    localidad: "KILOMETRO 114",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 14001,
    localidad: "KILOMETRO 112",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14002,
    localidad: "KILOMETRO 11",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14003,
    localidad: "KILOMETRO 108",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14004,
    localidad: "KILMETRO 165",
    cp: 3153,
    provincia_id: 8,
  },
  {
    id: 14005,
    localidad: "JUBILEO",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 14006,
    localidad: "JUAN JORGE",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 14007,
    localidad: "JUAN B MONTI",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14008,
    localidad: "JORGE FINK",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 14009,
    localidad: "ISTHILART",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14010,
    localidad: "ISLETAS",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 14011,
    localidad: "ISLAS ALCARAZ",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14012,
    localidad: "ISLA SAN JOSE",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14013,
    localidad: "ISLA LYNCH",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 14014,
    localidad: "ISLA LA PAZ",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14015,
    localidad: "ISLA EL PILLO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14016,
    localidad: "ISLA DEL IBICUY",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14017,
    localidad: "ISLA CURUZU CHALI",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14018,
    localidad: "IS D L LECHIGUANAS",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14019,
    localidad: "IRAZUSTA ESTACION",
    cp: 2851,
    provincia_id: 8,
  },
  {
    id: 14020,
    localidad: "ING MIGUEL SAJAROFF",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14021,
    localidad: "ING MIG SAJAROFF",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14022,
    localidad: "IBICUY",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14023,
    localidad: "HOLT",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14024,
    localidad: "HOJAS ANCHAS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14025,
    localidad: "HIPODROMO",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14026,
    localidad: "HINOJAL",
    cp: 3162,
    provincia_id: 8,
  },
  {
    id: 14027,
    localidad: "HERVIDERO",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14028,
    localidad: "HERRERA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14029,
    localidad: "HERNANDEZ",
    cp: 3156,
    provincia_id: 8,
  },
  {
    id: 14030,
    localidad: "HERNANDARIAS",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 14031,
    localidad: "HASENKAMP",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 14032,
    localidad: "HAMBIS",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14033,
    localidad: "GUAYAQUIL",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14034,
    localidad: "GUARDAMONTE",
    cp: 3179,
    provincia_id: 8,
  },
  {
    id: 14035,
    localidad: "GUALEYAN",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 14036,
    localidad: "GUALEGUAYCITO",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14037,
    localidad: "GUALEGUAYCHU",
    cp: 2822,
    provincia_id: 8,
  },
  {
    id: 14038,
    localidad: "GUALEGUAYCHU",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 14039,
    localidad: "GUALEGUAY",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14040,
    localidad: "GRUPO PARRERO",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 14041,
    localidad: "GRUPO ACHIRAS",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14042,
    localidad: "GONZALEZ CALDERON",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14043,
    localidad: "GONZALEZ",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14044,
    localidad: "GOBERNADOR URQUIZA",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 14045,
    localidad: "GOBERNADOR SOLA",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14046,
    localidad: "GOBERNADOR MANSILLA",
    cp: 2845,
    provincia_id: 8,
  },
  {
    id: 14047,
    localidad: "GOBERNADOR FEBRE",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14048,
    localidad: "GOBERNADOR ECHAGUE",
    cp: 2847,
    provincia_id: 8,
  },
  {
    id: 14049,
    localidad: "GLIBERTESCRIÑA",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 14050,
    localidad: "GILBERT",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 14051,
    localidad: "GERIBEBUY",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 14052,
    localidad: "GENERAL RAMIREZ",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 14053,
    localidad: "GENERAL RACEDO",
    cp: 3130,
    provincia_id: 8,
  },
  {
    id: 14054,
    localidad: "GENERAL GALARZA",
    cp: 2843,
    provincia_id: 8,
  },
  {
    id: 14055,
    localidad: "GENERAL CAMPOS",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14056,
    localidad: "GENERAL ALVEAR",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14057,
    localidad: "GENERAL ALMADA",
    cp: 2824,
    provincia_id: 8,
  },
  {
    id: 14058,
    localidad: "GENACITO",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14059,
    localidad: "G MANSILLA",
    cp: 2845,
    provincia_id: 8,
  },
  {
    id: 14060,
    localidad: "FRONTERAS",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14061,
    localidad: "FRIG. YUQUERI",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14062,
    localidad: "FORTUNA",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 14063,
    localidad: "FLORIDA",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 14064,
    localidad: "FLORESTA",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14065,
    localidad: "FERNANDEZ",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14066,
    localidad: "FEDERAL",
    cp: 3180,
    provincia_id: 8,
  },
  {
    id: 14067,
    localidad: "FEDERACION",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14068,
    localidad: "FEBRE",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14069,
    localidad: "FAUSTINO M PARERA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14070,
    localidad: "FABRICA COLON",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14071,
    localidad: "ESTANCIAS",
    cp: 2909,
    provincia_id: 8,
  },
  {
    id: 14072,
    localidad: "ESTANCIA SAN JUAN",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14073,
    localidad: "ESTANCIA SAN JOSE",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14074,
    localidad: "ESTANCIA SALINAS",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 14075,
    localidad: "ESTANCIA LOMATORA",
    cp: 3230,
    provincia_id: 8,
  },
  {
    id: 14076,
    localidad: "ESTANCIA LA GAMA",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14077,
    localidad: "ESTANCIA ITAEMBRE",
    cp: 3300,
    provincia_id: 8,
  },
  {
    id: 14078,
    localidad: "ESTANCIA EL TOROPI",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14079,
    localidad: "ESTACION YERUA",
    cp: 3214,
    provincia_id: 8,
  },
  {
    id: 14080,
    localidad: "ESTACION URUGUAY",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14081,
    localidad: "ESTACION URQUIZA",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 14082,
    localidad: "ESTACION SOLA",
    cp: 3176,
    provincia_id: 8,
  },
  {
    id: 14083,
    localidad: "ESTACION SANTA ANA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14084,
    localidad: "ESTACION G RACEDO",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14085,
    localidad: "ESTACION ALCARAZ",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 14086,
    localidad: "ESTACAS",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14087,
    localidad: "ESPINILLO",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14088,
    localidad: "ESCUELA ALBERDI",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14089,
    localidad: "ESCRIÑA",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 14090,
    localidad: "ENRIQUE CARBO",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 14091,
    localidad: "ENRIQUE BERDUC",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14092,
    localidad: "EMPALME NEILD",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 14093,
    localidad: "EMPALME HOLT",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14094,
    localidad: "EMBARC FERRARI",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14095,
    localidad: "EL TROPEZON",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14096,
    localidad: "EL TALLER",
    cp: 3117,
    provincia_id: 8,
  },
  {
    id: 14097,
    localidad: "EL SOLAR",
    cp: 3137,
    provincia_id: 8,
  },
  {
    id: 14098,
    localidad: "EL SAUCE",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14099,
    localidad: "EL SARANDI",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14100,
    localidad: "EL ROSARIO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14101,
    localidad: "EL ROMANCE",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14102,
    localidad: "EL REFUGIO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14103,
    localidad: "EL REDOMON",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14104,
    localidad: "EL RAMBLON",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 14105,
    localidad: "EL QUEBRACHO",
    cp: 3192,
    provincia_id: 8,
  },
  {
    id: 14106,
    localidad: "EL PUEBLITO",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14107,
    localidad: "EL POTRERO",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 14108,
    localidad: "EL PINGO",
    cp: 3132,
    provincia_id: 8,
  },
  {
    id: 14109,
    localidad: "EL PALENQUE",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14110,
    localidad: "EL PAGO APEAD FCGU",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14111,
    localidad: "EL MARTILLO",
    cp: 3166,
    provincia_id: 8,
  },
  {
    id: 14112,
    localidad: "EL GUALEGUAY",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14113,
    localidad: "EL GRAMILLAL",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 14114,
    localidad: "EL GAUCHO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14115,
    localidad: "EL EMPALME PARAJE",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14116,
    localidad: "EL EMBALSADO",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 14117,
    localidad: "EL DURAZNAL",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14118,
    localidad: "EL DIECISIETE",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14119,
    localidad: "EL CORCOVADO",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14120,
    localidad: "EL COLORADO",
    cp: 3192,
    provincia_id: 8,
  },
  {
    id: 14121,
    localidad: "EL CIMARRON",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 14122,
    localidad: "EL CHAJA",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14123,
    localidad: "EL CARMEN",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14124,
    localidad: "EL BRILLANTE",
    cp: 3284,
    provincia_id: 8,
  },
  {
    id: 14125,
    localidad: "EL AVESTRUZ",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14126,
    localidad: "EJIDO SUD",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14127,
    localidad: "EJIDO DIAMANTE",
    cp: 3105,
    provincia_id: 8,
  },
  {
    id: 14128,
    localidad: "EJIDO COLON",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14129,
    localidad: "ECHAGUE",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14130,
    localidad: "EBEN HOROSCHA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14131,
    localidad: "E S MARTIN",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14132,
    localidad: "E S FRANCISCO",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 14133,
    localidad: "E S EUSEBIO",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 14134,
    localidad: "E S EDUARDO",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 14135,
    localidad: "E PUNTA ALTA",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 14136,
    localidad: "E LOS VASCOS",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14137,
    localidad: "E LOS MONIGOTES",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14138,
    localidad: "E LAS MARGARITAS",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14139,
    localidad: "E LA FLORESTA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14140,
    localidad: "E LA ESPERANZA",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14141,
    localidad: "E LA CALERA",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14142,
    localidad: "E EL TALA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14143,
    localidad: "E EL CIMARRON",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14144,
    localidad: "E EL CARMEN",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14145,
    localidad: "E COLONIA EL OMBU",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14146,
    localidad: "E CNIA STA TERESA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14147,
    localidad: "E CNIA SANTA ELENA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14148,
    localidad: "E CNIA L PRIMAVERA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14149,
    localidad: "E C SANTA JUANA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14150,
    localidad: "E C SANTA ELOISA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14151,
    localidad: "E C SAN PEDRO",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14152,
    localidad: "E C PERIBEBUY",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14153,
    localidad: "E C LA TAPERA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14154,
    localidad: "E C EL TOROPI",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14155,
    localidad: "E BELLA VISTA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14156,
    localidad: "DURAZNO",
    cp: 3178,
    provincia_id: 8,
  },
  {
    id: 14157,
    localidad: "DOS HERMANAS",
    cp: 2854,
    provincia_id: 8,
  },
  {
    id: 14158,
    localidad: "DON ROBERTO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14159,
    localidad: "DON HORACIO",
    cp: 3300,
    provincia_id: 8,
  },
  {
    id: 14160,
    localidad: "DON GONZALO",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 14161,
    localidad: "DON CRISTOBAL",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 14162,
    localidad: "DOCTOR GARCIA",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14163,
    localidad: "DMENTO G GUEMES",
    cp: 3125,
    provincia_id: 8,
  },
  {
    id: 14164,
    localidad: "DISTRITO TALA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14165,
    localidad: "DISTRITO ESPINILLO",
    cp: 3107,
    provincia_id: 8,
  },
  {
    id: 14166,
    localidad: "DISTRITO EL SAUCE",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14167,
    localidad: "DIAMANTE",
    cp: 3105,
    provincia_id: 8,
  },
  {
    id: 14168,
    localidad: "DESPARRAMADOS",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14169,
    localidad: "D CRIST 2D SECCION",
    cp: 3162,
    provincia_id: 8,
  },
  {
    id: 14170,
    localidad: "D CRIST 1R SECCION",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14171,
    localidad: "CURUZU CHALI",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14172,
    localidad: "CURUPI",
    cp: 3240,
    provincia_id: 8,
  },
  {
    id: 14173,
    localidad: "CURTIEMBRE",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14174,
    localidad: "CUPALEN",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14175,
    localidad: "CUEVA DEL TIGRE",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14176,
    localidad: "CUCHILLA REDONDA",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 14177,
    localidad: "CUCHILLA",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14178,
    localidad: "CUATRO MANOS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14179,
    localidad: "CUATRO BOCAS P",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 14180,
    localidad: "CUATRO BOCAS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14181,
    localidad: "CUATRO BOCAS",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14182,
    localidad: "CUATRO BOCAS",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14183,
    localidad: "CUARTO DISTRITO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14184,
    localidad: "CTIVA BRAZO LARGO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14185,
    localidad: "CRUCESITAS",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14186,
    localidad: "CRUCECITAS URQUIZA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14187,
    localidad: "CRUCECITAS 8A SECCION",
    cp: 3175,
    provincia_id: 8,
  },
  {
    id: 14188,
    localidad: "CRUC 8A SECCION",
    cp: 3175,
    provincia_id: 8,
  },
  {
    id: 14189,
    localidad: "CRUC 7A SECCION",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 14190,
    localidad: "CRUC 3A SECCION",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14191,
    localidad: "CRESPO NORTE",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14192,
    localidad: "CRESPO",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14193,
    localidad: "COSTAS DE S ANT",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 14194,
    localidad: "COSTA GRANDE DOLL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14195,
    localidad: "COSTA GRANDE",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14196,
    localidad: "COSTA DEL URUGUAY",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 14197,
    localidad: "COSTA DEL PAYTICU",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14198,
    localidad: "COSTA DEL NOGOYA",
    cp: 3155,
    provincia_id: 8,
  },
  {
    id: 14199,
    localidad: "COSTA ARROYO GARAY",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14200,
    localidad: "CORREA",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 14201,
    localidad: "CORRALES NUEVOS",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 14202,
    localidad: "CONSCRIPTO BERNARDI",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 14203,
    localidad: "CONSCRIP BERNARDI",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 14204,
    localidad: "CONCORDIA",
    cp: 3205,
    provincia_id: 8,
  },
  {
    id: 14205,
    localidad: "CONCORDIA",
    cp: 3207,
    provincia_id: 8,
  },
  {
    id: 14206,
    localidad: "CONCORDIA",
    cp: 3202,
    provincia_id: 8,
  },
  {
    id: 14207,
    localidad: "CONCORDIA",
    cp: 3200,
    provincia_id: 8,
  },
  {
    id: 14208,
    localidad: "CONCEPCION DEL URUGUAY",
    cp: 3264,
    provincia_id: 8,
  },
  {
    id: 14209,
    localidad: "CONCEPCION DEL URUGUAY",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14210,
    localidad: "CONCEP DEL URUGUAY",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14211,
    localidad: "COLONIA YERUA",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14212,
    localidad: "COLONIA VIRARO",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14213,
    localidad: "COLONIA VGUAYCITO",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 14214,
    localidad: "COLONIA VELEZ",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14215,
    localidad: "COLONIA VAZQUEZ",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14216,
    localidad: "COLONIA V LIBERTAD",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14217,
    localidad: "COLONIA UBAJAY",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14218,
    localidad: "COLONIA STAUWER",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 14219,
    localidad: "COLONIA SONENFELD",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14220,
    localidad: "COLONIA SAUCE",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14221,
    localidad: "COLONIA SANTA ROSA",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14222,
    localidad: "COLONIA SANTA JUANA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14223,
    localidad: "COLONIA SANTA ANA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14224,
    localidad: "COLONIA SANDOVAL",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14225,
    localidad: "COLONIA SAN RAMON",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 14226,
    localidad: "COLONIA SAN MIGUEL",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 14227,
    localidad: "COLONIA SAN MARTIN",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14228,
    localidad: "COLONIA SAN MANUEL",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14229,
    localidad: "COLONIA SAN JUAN",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14230,
    localidad: "COLONIA SAN JOSE",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 14231,
    localidad: "COLONIA SAN JORGE",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14232,
    localidad: "COLONIA SAN JORGE",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14233,
    localidad: "COLONIA SAGASTUME",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14234,
    localidad: "COLONIA RIVAS",
    cp: 3164,
    provincia_id: 8,
  },
  {
    id: 14235,
    localidad: "COLONIA RIVADAVIA",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14236,
    localidad: "COLONIA REFFINO",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14237,
    localidad: "COLONIA PERLIZA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14238,
    localidad: "COLONIA PERFECCION",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14239,
    localidad: "COLONIA PEREIRA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14240,
    localidad: "COLONIA PALMAR",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14241,
    localidad: "COLONIA PAIRIRI",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14242,
    localidad: "COLONIA OUGRIE",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14243,
    localidad: "COLONIA OFICIAL N 14",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14244,
    localidad: "COLONIA OFICIAL N 13",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14245,
    localidad: "COLONIA NUEVA MONTEVIDEO",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 14246,
    localidad: "COLONIA NUEVA",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14247,
    localidad: "COLONIA NAVARRO",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14248,
    localidad: "COLONIA N 4",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14249,
    localidad: "COLONIA N 3",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14250,
    localidad: "COLONIA N 2",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14251,
    localidad: "COLONIA N 1",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14252,
    localidad: "COLONIA MIGUEL",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14253,
    localidad: "COLONIA MABRAGAÑA",
    cp: 3283,
    provincia_id: 8,
  },
  {
    id: 14254,
    localidad: "COLONIA LUCRECIA",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 14255,
    localidad: "COLONIA LUCA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14256,
    localidad: "COLONIA LOS SAUCES",
    cp: 3200,
    provincia_id: 8,
  },
  {
    id: 14257,
    localidad: "COLONIA LOPEZ",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 14258,
    localidad: "COLONIA LOMA NEGRA",
    cp: 3112,
    provincia_id: 8,
  },
  {
    id: 14259,
    localidad: "COLONIA LOMA NEGRA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14260,
    localidad: "COLONIA LEVEN",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 14261,
    localidad: "COLONIA LAS PEPAS",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14262,
    localidad: "COLONIA LAS GAMAS",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14263,
    localidad: "COLONIA LAMARCA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14264,
    localidad: "COLONIA LA ROSADA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14265,
    localidad: "COLONIA LA QUINTA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14266,
    localidad: "COLONIA LA PAZ",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14267,
    localidad: "COLONIA LA PAMPA",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 14268,
    localidad: "COLONIA LA MORA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14269,
    localidad: "COLONIA LA MATILDE",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14270,
    localidad: "COLONIA LA LLAVE",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 14271,
    localidad: "COLONIA LA GLORIA",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14272,
    localidad: "COLONIA LA GAMA",
    cp: 3136,
    provincia_id: 8,
  },
  {
    id: 14273,
    localidad: "COLONIA LA DELIA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14274,
    localidad: "COLONIA LA ARMONIA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14275,
    localidad: "COLONIA LA ARGENTINA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14276,
    localidad: "COLONIA JORGE FINK",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 14277,
    localidad: "COLONIA ITALIANA",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14278,
    localidad: "COLONIA IDA",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14279,
    localidad: "COLONIA HUGHES",
    cp: 3275,
    provincia_id: 8,
  },
  {
    id: 14280,
    localidad: "COLONIA HOCKER",
    cp: 3270,
    provincia_id: 8,
  },
  {
    id: 14281,
    localidad: "COLONIA HIGUERA",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14282,
    localidad: "COLONIA HEBREA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14283,
    localidad: "COLONIA GUIBURG",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14284,
    localidad: "COLONIA GDOR BASAVILBASO",
    cp: 2824,
    provincia_id: 8,
  },
  {
    id: 14285,
    localidad: "COLONIA FREITAS",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 14286,
    localidad: "COLONIA FRAZER",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14287,
    localidad: "COLONIA FONTANINI",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14288,
    localidad: "COLONIA FLORES",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14289,
    localidad: "COLONIA FEIMBERG",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14290,
    localidad: "COLONIA FALCO",
    cp: 3188,
    provincia_id: 8,
  },
  {
    id: 14291,
    localidad: "COLONIA F SILLEN",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14292,
    localidad: "COLONIA ESPINDOLA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14293,
    localidad: "COLONIA ENSAYO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14294,
    localidad: "COLONIA ELISA",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14295,
    localidad: "COLONIA ELISA",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 14296,
    localidad: "COLONIA ELIA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14297,
    localidad: "COLONIA EL POTRERO",
    cp: 2820,
    provincia_id: 8,
  },
  {
    id: 14298,
    localidad: "COLONIA EL CARMEN",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14299,
    localidad: "COLONIA E.GOUCHON",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14300,
    localidad: "COLONIA DUPORTAL",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14301,
    localidad: "COLONIA DON BOSCO",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14302,
    localidad: "COLONIA CURBELO",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14303,
    localidad: "COLONIA CUPALEN",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14304,
    localidad: "COLONIA CRUCESITAS",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14305,
    localidad: "COLONIA CRESPO",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14306,
    localidad: "COLONIA CENTENARIO",
    cp: 3109,
    provincia_id: 8,
  },
  {
    id: 14307,
    localidad: "COLONIA CELINA",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14308,
    localidad: "COLONIA CARRASCO",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14309,
    localidad: "COLONIA CARMELO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14310,
    localidad: "COLONIA CARMEL",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14311,
    localidad: "COLONIA CARABALLO",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 14312,
    localidad: "COLONIA CAMPOS",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14313,
    localidad: "COLONIA BIZCOCHO",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14314,
    localidad: "COLONIA BERRO",
    cp: 3128,
    provincia_id: 8,
  },
  {
    id: 14315,
    localidad: "COLONIA BELGRANO",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14316,
    localidad: "COLONIA BELEZ",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14317,
    localidad: "COLONIA BASUALDO",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14318,
    localidad: "COLONIA BAILINA",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14319,
    localidad: "COLONIA AVIGDOR",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14320,
    localidad: "COLONIA AVELLANEDA",
    cp: 3107,
    provincia_id: 8,
  },
  {
    id: 14321,
    localidad: "COLONIA ARGENTINA",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14322,
    localidad: "COLONIA ANGELA",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14323,
    localidad: "COLONIA AMBIS",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14324,
    localidad: "COLONIA ALEMANA",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14325,
    localidad: "COLONIA ALCARCITO",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14326,
    localidad: "COLONIA ADELA",
    cp: 3207,
    provincia_id: 8,
  },
  {
    id: 14327,
    localidad: "COLONIA ACHIRAS",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14328,
    localidad: "COLONIA 1 DE MAYO",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14329,
    localidad: "COLON",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 14330,
    localidad: "CNO A DIAMANTE K 1",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 14331,
    localidad: "CNIA J J URQUIZA",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14332,
    localidad: "CLODOMIRO LEDESMA",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14333,
    localidad: "CLARA",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14334,
    localidad: "CHIRCALITO",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 14335,
    localidad: "CHIQUERO",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 14336,
    localidad: "CHILCAS SUD",
    cp: 3162,
    provincia_id: 8,
  },
  {
    id: 14337,
    localidad: "CHAVIYU PDA FCGU",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14338,
    localidad: "CHAVIYU C FLORES",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14339,
    localidad: "CHAMORRO",
    cp: 3427,
    provincia_id: 8,
  },
  {
    id: 14340,
    localidad: "CHAJARI",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14341,
    localidad: "CHACRAS 2A SECCION",
    cp: 3220,
    provincia_id: 8,
  },
  {
    id: 14342,
    localidad: "CHACRAS 1A SECCION",
    cp: 3220,
    provincia_id: 8,
  },
  {
    id: 14343,
    localidad: "CHACRAS",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14344,
    localidad: "CERRITO",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14345,
    localidad: "CENTENARIO PARANA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14346,
    localidad: "CENTENARIO LA PAZ",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14347,
    localidad: "CENTELLA",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14348,
    localidad: "CEIBAS",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14349,
    localidad: "CEIBAL",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14350,
    localidad: "CATALOTTI",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 14351,
    localidad: "CASEROS",
    cp: 3262,
    provincia_id: 8,
  },
  {
    id: 14352,
    localidad: "CARRIZAL",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14353,
    localidad: "CARPINCHORIS",
    cp: 3183,
    provincia_id: 8,
  },
  {
    id: 14354,
    localidad: "CARAGUATA",
    cp: 3248,
    provincia_id: 8,
  },
  {
    id: 14355,
    localidad: "CANAL SAN MARTIN",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14356,
    localidad: "CANAL PRINCIPAL",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14357,
    localidad: "CANAL NUEVO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14358,
    localidad: "CAMPS",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14359,
    localidad: "CAMPO RIQUELME",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14360,
    localidad: "CAMPO MORENO",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14361,
    localidad: "CAMPO ESCALES",
    cp: 3156,
    provincia_id: 8,
  },
  {
    id: 14362,
    localidad: "CAMPO DOMINGUEZ",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14363,
    localidad: "CAMPO DE VILLAMIL",
    cp: 3241,
    provincia_id: 8,
  },
  {
    id: 14364,
    localidad: "CAMBA PASO",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14365,
    localidad: "CALERA",
    cp: 3281,
    provincia_id: 8,
  },
  {
    id: 14366,
    localidad: "CALANDRIA",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14367,
    localidad: "CALABACILLAS",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14368,
    localidad: "CABI MONDA",
    cp: 3228,
    provincia_id: 8,
  },
  {
    id: 14369,
    localidad: "C TRES DE FEBRERO",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 14370,
    localidad: "C SANTA TERESITA",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14371,
    localidad: "C SANTA LUISA",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 14372,
    localidad: "C SANTA JUANA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14373,
    localidad: "C SANTA ELVIRA",
    cp: 3229,
    provincia_id: 8,
  },
  {
    id: 14374,
    localidad: "C SANTA ELOISA",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14375,
    localidad: "C SANTA ELENA",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14376,
    localidad: "C SAN IGNACIO",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14377,
    localidad: "C SAN FRANCISCO",
    cp: 3283,
    provincia_id: 8,
  },
  {
    id: 14378,
    localidad: "C SAN ERNESTO",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 14379,
    localidad: "C SAN CIPRIANO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14380,
    localidad: "C SAN BONIFACIO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14381,
    localidad: "C SAN ANTONIO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14382,
    localidad: "C SAN ANTONIO",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14383,
    localidad: "C SAENZ VALIENTE",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14384,
    localidad: "C OFICIAL NRO 5",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14385,
    localidad: "C OFICIAL N 6",
    cp: 3261,
    provincia_id: 8,
  },
  {
    id: 14386,
    localidad: "C OFICIAL N 4",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 14387,
    localidad: "C OFICIAL N 3",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14388,
    localidad: "C OFICIAL N 11",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14389,
    localidad: "C NUEVA SAN MIGUEL",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14390,
    localidad: "C NUEVA MONTEVIDEO",
    cp: 2828,
    provincia_id: 8,
  },
  {
    id: 14391,
    localidad: "C NUEVA ALEMANIA",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 14392,
    localidad: "C NUEVA AL SUD",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 14393,
    localidad: "C MAXIMO CASTRO",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14394,
    localidad: "C MARIA LUISA",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14395,
    localidad: "C LUCIENVILLE",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14396,
    localidad: "C LA PROVIDENCIA",
    cp: 3137,
    provincia_id: 8,
  },
  {
    id: 14397,
    localidad: "C LA MORENITA",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 14398,
    localidad: "C LA MATILDE S ANA",
    cp: 3208,
    provincia_id: 8,
  },
  {
    id: 14399,
    localidad: "C LA ESPERANZA",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14400,
    localidad: "C LA CONSTANCIA",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14401,
    localidad: "C LA BLANQUITA",
    cp: 3254,
    provincia_id: 8,
  },
  {
    id: 14402,
    localidad: "C HERNANDARIAS",
    cp: 3129,
    provincia_id: 8,
  },
  {
    id: 14403,
    localidad: "C GUALEGUAYCITO",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14404,
    localidad: "C GRAPSCHENTAL",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14405,
    localidad: "C GRAL URQUIZA",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14406,
    localidad: "C GENERAL ROCA",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14407,
    localidad: "C GDOR BASAVILBASO",
    cp: 2824,
    provincia_id: 8,
  },
  {
    id: 14408,
    localidad: "C ENSANCHE MAYO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14409,
    localidad: "C EMILIO GOUCHON",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14410,
    localidad: "C DE LAS OVEJAS",
    cp: 3267,
    provincia_id: 8,
  },
  {
    id: 14411,
    localidad: "C CTARIO CNIA NVO",
    cp: 3118,
    provincia_id: 8,
  },
  {
    id: 14412,
    localidad: "C CARLOS CALVO",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14413,
    localidad: "C BUENA VISTA",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14414,
    localidad: "C BELGA AMERICANA",
    cp: 3244,
    provincia_id: 8,
  },
  {
    id: 14415,
    localidad: "C BARON HIRSCH",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14416,
    localidad: "C AYUI GRANDE",
    cp: 3201,
    provincia_id: 8,
  },
  {
    id: 14417,
    localidad: "C ARROYO URQUIZA",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14418,
    localidad: "C ALGARRABITOS",
    cp: 3156,
    provincia_id: 8,
  },
  {
    id: 14419,
    localidad: "C ADVEN. DEL PLATA",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 14420,
    localidad: "C 5 ENSCHE DE MAYO",
    cp: 3272,
    provincia_id: 8,
  },
  {
    id: 14421,
    localidad: "BUENA VISTA PARAJE",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14422,
    localidad: "BRITOS",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14423,
    localidad: "BRAZO LARGO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14424,
    localidad: "BOVRIL DTO NRO 12",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14425,
    localidad: "BOVRIL",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14426,
    localidad: "BONALDI",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14427,
    localidad: "BOCA GUALEGUAY",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14428,
    localidad: "BOCA DEL TIGRE",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14429,
    localidad: "BIZCOCHO",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14430,
    localidad: "BETBEDER",
    cp: 3156,
    provincia_id: 8,
  },
  {
    id: 14431,
    localidad: "BERISSO DVIO FCGU",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 14432,
    localidad: "BERISSO",
    cp: 2848,
    provincia_id: 8,
  },
  {
    id: 14433,
    localidad: "BERDUC",
    cp: 3285,
    provincia_id: 8,
  },
  {
    id: 14434,
    localidad: "BENITO LEGEREN",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14435,
    localidad: "BENITEZ",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14436,
    localidad: "BELLA UNION PARAJE",
    cp: 3206,
    provincia_id: 8,
  },
  {
    id: 14437,
    localidad: "BELEZ",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14438,
    localidad: "BCAS COLORADAS",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 14439,
    localidad: "BAYGORRIA",
    cp: 3230,
    provincia_id: 8,
  },
  {
    id: 14440,
    localidad: "BASUALDO",
    cp: 3185,
    provincia_id: 8,
  },
  {
    id: 14441,
    localidad: "BASAVILBASO",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14442,
    localidad: "BARON HIRSCH",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14443,
    localidad: "BANDERAS",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14444,
    localidad: "BALNEARIO PELAY",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14445,
    localidad: "BAJADA GRANDE",
    cp: 3100,
    provincia_id: 8,
  },
  {
    id: 14446,
    localidad: "B OF LAS GALARZAS",
    cp: 3137,
    provincia_id: 8,
  },
  {
    id: 14447,
    localidad: "B DEL TIGRE A FCGU",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14448,
    localidad: "AYUI PARADA",
    cp: 3204,
    provincia_id: 8,
  },
  {
    id: 14449,
    localidad: "ATENCIO",
    cp: 3187,
    provincia_id: 8,
  },
  {
    id: 14450,
    localidad: "ARROYO ZAPALLO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14451,
    localidad: "ARROYO VENERATO",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 14452,
    localidad: "ARROYO URQUIZA",
    cp: 3280,
    provincia_id: 8,
  },
  {
    id: 14453,
    localidad: "ARROYO TIROLES",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14454,
    localidad: "ARROYO SS GRANDE",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14455,
    localidad: "ARROYO SS CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14456,
    localidad: "ARROYO SCHEZ CHICO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14457,
    localidad: "ARROYO SALADO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14458,
    localidad: "ARROYO QANCAY",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14459,
    localidad: "ARROYO PRINCIPAL",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14460,
    localidad: "ARROYO PIEDRAS",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14461,
    localidad: "ARROYO PELADO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14462,
    localidad: "ARROYO PANCHO",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14463,
    localidad: "ARROYO PALMAR",
    cp: 3218,
    provincia_id: 8,
  },
  {
    id: 14464,
    localidad: "ARROYO OBISPO",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14465,
    localidad: "ARROYO NEGRO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14466,
    localidad: "ARROYO MOREIRA",
    cp: 3181,
    provincia_id: 8,
  },
  {
    id: 14467,
    localidad: "ARROYO MOLINO",
    cp: 3260,
    provincia_id: 8,
  },
  {
    id: 14468,
    localidad: "ARROYO MARTINEZ",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14469,
    localidad: "ARROYO MARIA",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 14470,
    localidad: "ARROYO MALAMBO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14471,
    localidad: "ARROYO LAS TUNAS",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14472,
    localidad: "ARROYO LA VIRGEN",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14473,
    localidad: "ARROYO LA TINTA",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14474,
    localidad: "ARROYO JACINTO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14475,
    localidad: "ARROYO HONDO",
    cp: 3216,
    provincia_id: 8,
  },
  {
    id: 14476,
    localidad: "ARROYO HONDO",
    cp: 3190,
    provincia_id: 8,
  },
  {
    id: 14477,
    localidad: "ARROYO GRANDE",
    cp: 3203,
    provincia_id: 8,
  },
  {
    id: 14478,
    localidad: "ARROYO EL MOCHO",
    cp: 3212,
    provincia_id: 8,
  },
  {
    id: 14479,
    localidad: "ARROYO DEL MEDIO",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 14480,
    localidad: "ARROYO DEL CURA",
    cp: 2821,
    provincia_id: 8,
  },
  {
    id: 14481,
    localidad: "ARROYO DE LA ROSA",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14482,
    localidad: "ARROYO CUZCO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14483,
    localidad: "ARROYO CORRENTOSO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14484,
    localidad: "ARROYO CONCEPCION",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14485,
    localidad: "ARROYO CLE DESVIO",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14486,
    localidad: "ARROYO CEIBO",
    cp: 3191,
    provincia_id: 8,
  },
  {
    id: 14487,
    localidad: "ARROYO CARPINCHO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14488,
    localidad: "ARROYO CARBONCITO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14489,
    localidad: "ARROYO CARBON",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14490,
    localidad: "ARROYO CARABALLO",
    cp: 3265,
    provincia_id: 8,
  },
  {
    id: 14491,
    localidad: "ARROYO CABALLO",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14492,
    localidad: "ARROYO BURGOS",
    cp: 3133,
    provincia_id: 8,
  },
  {
    id: 14493,
    localidad: "ARROYO BUEN PASTOR",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14494,
    localidad: "ARROYO BRAZO CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14495,
    localidad: "ARROYO BOCA FALSA",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14496,
    localidad: "ARROYO BARU",
    cp: 3269,
    provincia_id: 8,
  },
  {
    id: 14497,
    localidad: "ARROYO BALTAZAR",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14498,
    localidad: "ARANGUREN",
    cp: 3163,
    provincia_id: 8,
  },
  {
    id: 14499,
    localidad: "ARANGUREN",
    cp: 3162,
    provincia_id: 8,
  },
  {
    id: 14500,
    localidad: "ANTONIO TOMAS SUD",
    cp: 3134,
    provincia_id: 8,
  },
  {
    id: 14501,
    localidad: "ANTONIO TOMAS",
    cp: 3122,
    provincia_id: 8,
  },
  {
    id: 14502,
    localidad: "ANTELO",
    cp: 3151,
    provincia_id: 8,
  },
  {
    id: 14503,
    localidad: "ANAHI",
    cp: 2846,
    provincia_id: 8,
  },
  {
    id: 14504,
    localidad: "ALTAMIRANO SUD",
    cp: 3174,
    provincia_id: 8,
  },
  {
    id: 14505,
    localidad: "ALTAMIRANO NORTE",
    cp: 3177,
    provincia_id: 8,
  },
  {
    id: 14506,
    localidad: "ALMIRANTE IGLESIAS",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14507,
    localidad: "ALMACEN C SCHUBERT",
    cp: 3111,
    provincia_id: 8,
  },
  {
    id: 14508,
    localidad: "ALGARROBITO 1RO",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14509,
    localidad: "ALDEA VALLE MARIA",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14510,
    localidad: "ALDEA SPATZENKUTTER",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14511,
    localidad: "ALDEA SANTAFECINA",
    cp: 3103,
    provincia_id: 8,
  },
  {
    id: 14512,
    localidad: "ALDEA SANTA ROSA",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14513,
    localidad: "ALDEA SANTA MARIA",
    cp: 3150,
    provincia_id: 8,
  },
  {
    id: 14514,
    localidad: "ALDEA SANTA MARIA",
    cp: 3123,
    provincia_id: 8,
  },
  {
    id: 14515,
    localidad: "ALDEA SANTA CELIA",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 14516,
    localidad: "ALDEA SAN RAFAEL",
    cp: 3135,
    provincia_id: 8,
  },
  {
    id: 14517,
    localidad: "ALDEA SAN MIGUEL",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14518,
    localidad: "ALDEA SAN JUAN",
    cp: 2826,
    provincia_id: 8,
  },
  {
    id: 14519,
    localidad: "ALDEA SAN JOSE",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14520,
    localidad: "ALDEA SAN JORGE",
    cp: 3252,
    provincia_id: 8,
  },
  {
    id: 14521,
    localidad: "ALDEA SAN GREGORIO",
    cp: 3287,
    provincia_id: 8,
  },
  {
    id: 14522,
    localidad: "ALDEA SAN ANTONIO",
    cp: 2827,
    provincia_id: 8,
  },
  {
    id: 14523,
    localidad: "ALDEA SALTO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14524,
    localidad: "ALDEA S FRANCISCO",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14525,
    localidad: "ALDEA PROTESTANTE",
    cp: 3115,
    provincia_id: 8,
  },
  {
    id: 14526,
    localidad: "ALDEA MEROU",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14527,
    localidad: "ALDEA MARIA LUISA",
    cp: 3114,
    provincia_id: 8,
  },
  {
    id: 14528,
    localidad: "ALDEA EIGENFELD",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14529,
    localidad: "ALDEA CUESTA",
    cp: 3116,
    provincia_id: 8,
  },
  {
    id: 14530,
    localidad: "ALDEA BRASILERA",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14531,
    localidad: "ALDEA ASUNCION",
    cp: 2841,
    provincia_id: 8,
  },
  {
    id: 14532,
    localidad: "ALCETE",
    cp: 3127,
    provincia_id: 8,
  },
  {
    id: 14533,
    localidad: "ALCARAZ SUD",
    cp: 3137,
    provincia_id: 8,
  },
  {
    id: 14534,
    localidad: "ALCARAZ P ARRUA",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14535,
    localidad: "ALCARAZ NORTE",
    cp: 3136,
    provincia_id: 8,
  },
  {
    id: 14536,
    localidad: "ALCARAZ 1RO",
    cp: 3144,
    provincia_id: 8,
  },
  {
    id: 14537,
    localidad: "ALCARAZ 1RO",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14538,
    localidad: "ALCARAZ  2DO",
    cp: 3138,
    provincia_id: 8,
  },
  {
    id: 14539,
    localidad: "ALCARACITO",
    cp: 3142,
    provincia_id: 8,
  },
  {
    id: 14540,
    localidad: "ALBERTO GERCHUNOFF",
    cp: 3170,
    provincia_id: 8,
  },
  {
    id: 14541,
    localidad: "ALBARDON",
    cp: 2840,
    provincia_id: 8,
  },
  {
    id: 14542,
    localidad: "ALARCON",
    cp: 2852,
    provincia_id: 8,
  },
  {
    id: 14543,
    localidad: "AERO CLUB CANAL",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14544,
    localidad: "ACHIRAS",
    cp: 3246,
    provincia_id: 8,
  },
  {
    id: 14545,
    localidad: "A SPATZENKUTTER",
    cp: 3101,
    provincia_id: 8,
  },
  {
    id: 14546,
    localidad: "A SCHEZ GRANDE",
    cp: 2823,
    provincia_id: 8,
  },
  {
    id: 14547,
    localidad: "A SAGASTUME GRANDE",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14548,
    localidad: "A SAGASTUME CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14549,
    localidad: "A PERDIDO MOSQUITO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14550,
    localidad: "A PERDIDO CEIBO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14551,
    localidad: "A PACIENCIA GRANDE",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14552,
    localidad: "A PACIENCIA CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14553,
    localidad: "A GUTIERREZ CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14554,
    localidad: "A DESG DEL SAUCE",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14555,
    localidad: "A DES D GUTIERRES",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14556,
    localidad: "A BZO DE LA TINTA",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14557,
    localidad: "A BRAVO GUTIERREZ",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14558,
    localidad: "A BALTAZAR CHICO",
    cp: 1647,
    provincia_id: 8,
  },
  {
    id: 14559,
    localidad: "20 DE SETIEMBRE",
    cp: 3158,
    provincia_id: 8,
  },
  {
    id: 14560,
    localidad: "1 DE MAYO",
    cp: 3263,
    provincia_id: 8,
  },
  {
    id: 14561,
    localidad: "ZENON ROCA",
    cp: 3240,
    provincia_id: 7,
  },
  {
    id: 14562,
    localidad: "ZAPALLOS",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14563,
    localidad: "ZAPALLAR",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14564,
    localidad: "YURUCUA",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 14565,
    localidad: "YUQUERI",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14566,
    localidad: "YUQUERI",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 14567,
    localidad: "YUQUERI",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14568,
    localidad: "YTA PASO",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 14569,
    localidad: "YAZUCA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14570,
    localidad: "YATAY CORA",
    cp: 3474,
    provincia_id: 7,
  },
  {
    id: 14571,
    localidad: "YATAY",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14572,
    localidad: "YATAITY POI",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14573,
    localidad: "YATAITY CALLE",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14574,
    localidad: "YATAITI SATA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14575,
    localidad: "YAPUCA",
    cp: 3474,
    provincia_id: 7,
  },
  {
    id: 14576,
    localidad: "YAPEYU",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 14577,
    localidad: "YAPEYU",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 14578,
    localidad: "YAHAPE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14579,
    localidad: "YAGUARY",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14580,
    localidad: "YAGUARU",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14581,
    localidad: "YAGUA ROCAU",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 14582,
    localidad: "YAGUA RINCON",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14583,
    localidad: "YACAREY",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14584,
    localidad: "YACARE",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14585,
    localidad: "VUELTA DEL OMBU",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14586,
    localidad: "VIZCAINO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14587,
    localidad: "VIRGEN MARIA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14588,
    localidad: "VILLAGA CUE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14589,
    localidad: "VILLA TESARO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14590,
    localidad: "VILLA SOTO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14591,
    localidad: "VILLA SOLARI",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14592,
    localidad: "VILLA SAN RAMON",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14593,
    localidad: "VILLA SAN JUAN",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 14594,
    localidad: "VILLA SAN ISIDRO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14595,
    localidad: "VILLA ROLON",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 14596,
    localidad: "VILLA ROLLET",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14597,
    localidad: "VILLA PERMANENTE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14598,
    localidad: "VILLA P ARGENTINA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14599,
    localidad: "VILLA ORTIZ",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14600,
    localidad: "VILLA OLIVARI",
    cp: 3486,
    provincia_id: 7,
  },
  {
    id: 14601,
    localidad: "VILLA LUJAN",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14602,
    localidad: "VILLA LIBERTAD",
    cp: 3500,
    provincia_id: 7,
  },
  {
    id: 14603,
    localidad: "VILLA LA FLORIDA",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14604,
    localidad: "VILLA CUE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14605,
    localidad: "VILLA CRISTIA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14606,
    localidad: "VILLA CORDOBA",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 14607,
    localidad: "VILLA BLANQUITA",
    cp: 3360,
    provincia_id: 7,
  },
  {
    id: 14608,
    localidad: "VILLA AQUINO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 14609,
    localidad: "VILLA ALTA",
    cp: 3500,
    provincia_id: 7,
  },
  {
    id: 14610,
    localidad: "VERON CUE",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14611,
    localidad: "VERGARA LOMAS",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14612,
    localidad: "VERGARA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14613,
    localidad: "VELOSO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14614,
    localidad: "VEDOYA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14615,
    localidad: "VECINDAD",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14616,
    localidad: "VALENCIA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14617,
    localidad: "VACA CUA",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14618,
    localidad: "URIBURU",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14619,
    localidad: "ULAJAY",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14620,
    localidad: "UGUAY",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 14621,
    localidad: "TUYUTI",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14622,
    localidad: "TUNITAS",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14623,
    localidad: "TROPEZON",
    cp: 3500,
    provincia_id: 7,
  },
  {
    id: 14624,
    localidad: "TRISTAN CHICO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14625,
    localidad: "TRIPOLI",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14626,
    localidad: "TRES TAPERAS",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14627,
    localidad: "TRES HOJAS",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14628,
    localidad: "TRES HERMANAS",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14629,
    localidad: "TRES CRUCES",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14630,
    localidad: "TRES CERROS",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 14631,
    localidad: "TRES CAPONES",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14632,
    localidad: "TRES BOCAS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14633,
    localidad: "TRES BOCAS",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14634,
    localidad: "TRES BOCAS",
    cp: 3194,
    provincia_id: 7,
  },
  {
    id: 14635,
    localidad: "TRES ARBOLES",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14636,
    localidad: "TORRENT",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14637,
    localidad: "TOROS CORA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14638,
    localidad: "TORO PICHAY",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14639,
    localidad: "TORO I",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14640,
    localidad: "TORO CHIPAY",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14641,
    localidad: "TOPADOR",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14642,
    localidad: "TOLATU",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14643,
    localidad: "TIQUINO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14644,
    localidad: "TINGUI",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14645,
    localidad: "TIMBOY",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14646,
    localidad: "TIMBO PASO",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 14647,
    localidad: "TIMBO CORA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14648,
    localidad: "TIMBO",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14649,
    localidad: "TILITA",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 14650,
    localidad: "TIERRA COLORADA",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14651,
    localidad: "TEBLENARI",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 14652,
    localidad: "TATARE",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14653,
    localidad: "TATACUA",
    cp: 3426,
    provincia_id: 7,
  },
  {
    id: 14654,
    localidad: "TATACUA",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 14655,
    localidad: "TATACUA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14656,
    localidad: "TARTARIA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14657,
    localidad: "TARTAGUITO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14658,
    localidad: "TAREIRI",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14659,
    localidad: "TARANGULLO",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14660,
    localidad: "TAPE RATI",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14661,
    localidad: "TAMBO NUEVO",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14662,
    localidad: "TALLERES",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14663,
    localidad: "TALITA CUE",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14664,
    localidad: "TALATY",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14665,
    localidad: "TALA PASO",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 14666,
    localidad: "TALA CORA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14667,
    localidad: "TAJIBO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14668,
    localidad: "TACURAL MERCEDES",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 14669,
    localidad: "TACURAL",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14670,
    localidad: "TACUAREMBO",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14671,
    localidad: "TACUARAL",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14672,
    localidad: "TACUARAL",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14673,
    localidad: "TACUARACARENDY",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14674,
    localidad: "TACUABE",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14675,
    localidad: "TABLADA",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14676,
    localidad: "TABAY",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 14677,
    localidad: "T OESTE CONCORDIA",
    cp: 3200,
    provincia_id: 7,
  },
  {
    id: 14678,
    localidad: "SOTO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14679,
    localidad: "SOSA CUE",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14680,
    localidad: "SOSA",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14681,
    localidad: "SOMBRERO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14682,
    localidad: "SOLEDAD",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14683,
    localidad: "SOLEDAD",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14684,
    localidad: "SOLEDAD",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14685,
    localidad: "SOLARI",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 14686,
    localidad: "SOCORRO",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14687,
    localidad: "SILVERO CUE",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14688,
    localidad: "SIETE ARBOLES",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14689,
    localidad: "SERIANO CUE",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14690,
    localidad: "SERIANO CUE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14691,
    localidad: "SEC. PRIMERA S J",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14692,
    localidad: "SAUCESITO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14693,
    localidad: "SAUCE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14694,
    localidad: "SAUCE",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14695,
    localidad: "SARANDI",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14696,
    localidad: "SARANDI",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14697,
    localidad: "SANTOS LUGARES",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14698,
    localidad: "SANTO TOME",
    cp: 3341,
    provincia_id: 7,
  },
  {
    id: 14699,
    localidad: "SANTO TOME",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14700,
    localidad: "SANTO TOMAS",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 14701,
    localidad: "SANTO TOMAS",
    cp: 3449,
    provincia_id: 7,
  },
  {
    id: 14702,
    localidad: "SANTO DOMINGO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14703,
    localidad: "SANTO DOMINGO",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14704,
    localidad: "SANTO DOMINGO",
    cp: 3449,
    provincia_id: 7,
  },
  {
    id: 14705,
    localidad: "SANTO DOMINGO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14706,
    localidad: "SANTO DOMINGO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14707,
    localidad: "SANTILLAN",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14708,
    localidad: "SANTIAGO ALCORTA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14709,
    localidad: "SANTA TERESA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14710,
    localidad: "SANTA TERESA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14711,
    localidad: "SANTA TERESA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14712,
    localidad: "SANTA TECLA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14713,
    localidad: "SANTA SINFOROSA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14714,
    localidad: "SANTA ROSA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14715,
    localidad: "SANTA ROSA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14716,
    localidad: "SANTA ROSA",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 14717,
    localidad: "SANTA ROSA",
    cp: 3358,
    provincia_id: 7,
  },
  {
    id: 14718,
    localidad: "SANTA RITA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14719,
    localidad: "SANTA RITA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14720,
    localidad: "SANTA RITA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14721,
    localidad: "SANTA RITA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14722,
    localidad: "SANTA RITA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14723,
    localidad: "SANTA MARTA",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14724,
    localidad: "SANTA MARIA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14725,
    localidad: "SANTA MARIA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14726,
    localidad: "SANTA MARIA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14727,
    localidad: "SANTA MAGDALENA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14728,
    localidad: "SANTA LUCIA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14729,
    localidad: "SANTA LUCIA",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 14730,
    localidad: "SANTA LIBRADA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14731,
    localidad: "SANTA LEA",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14732,
    localidad: "SANTA JUANA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14733,
    localidad: "SANTA JUANA",
    cp: 3228,
    provincia_id: 7,
  },
  {
    id: 14734,
    localidad: "SANTA ISABEL",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14735,
    localidad: "SANTA ISABEL",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 14736,
    localidad: "SANTA ISABEL",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14737,
    localidad: "SANTA ISABEL",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14738,
    localidad: "SANTA ISABEL",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14739,
    localidad: "SANTA EMILIA",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 14740,
    localidad: "SANTA ELISA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14741,
    localidad: "SANTA CATALINA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14742,
    localidad: "SANTA ANA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14743,
    localidad: "SANTA ANA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14744,
    localidad: "SANTA ANA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14745,
    localidad: "SANTA ANA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14746,
    localidad: "SANTA ANA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14747,
    localidad: "SANGARA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14748,
    localidad: "SAN VICENTE",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14749,
    localidad: "SAN VICENTE",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14750,
    localidad: "SAN VICENTE",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14751,
    localidad: "SAN SEBASTIAN",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14752,
    localidad: "SAN SEBASTIAN",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 14753,
    localidad: "SAN SALVADOR",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 14754,
    localidad: "SAN SALVADOR",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 14755,
    localidad: "SAN SALVADOR",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14756,
    localidad: "SAN ROQUITO",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 14757,
    localidad: "SAN ROQUE",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14758,
    localidad: "SAN ROQUE",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14759,
    localidad: "SAN RAFAEL",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14760,
    localidad: "SAN RAFAEL",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 14761,
    localidad: "SAN PEDRO",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14762,
    localidad: "SAN PEDRO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14763,
    localidad: "SAN PEDRO",
    cp: 3474,
    provincia_id: 7,
  },
  {
    id: 14764,
    localidad: "SAN PEDRO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14765,
    localidad: "SAN PEDRO",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 14766,
    localidad: "SAN PEDRO",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 14767,
    localidad: "SAN PALADIO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14768,
    localidad: "SAN NICOLAS",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14769,
    localidad: "SAN NICOLAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14770,
    localidad: "SAN NICOLAS",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 14771,
    localidad: "SAN NICOLAS",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14772,
    localidad: "SAN NICANOR",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14773,
    localidad: "SAN MIGUEL",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 14774,
    localidad: "SAN MIGUEL",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14775,
    localidad: "SAN MARTIN",
    cp: 3360,
    provincia_id: 7,
  },
  {
    id: 14776,
    localidad: "SAN MARTIN",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14777,
    localidad: "SAN MARTIN",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14778,
    localidad: "SAN MARTIN",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14779,
    localidad: "SAN MARCOS",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14780,
    localidad: "SAN MANUEL",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14781,
    localidad: "SAN LUIS DEL PALMAR",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14782,
    localidad: "SAN LUIS CUE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14783,
    localidad: "SAN LUIS",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14784,
    localidad: "SAN LUIS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14785,
    localidad: "SAN LUIS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14786,
    localidad: "SAN LORENZO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14787,
    localidad: "SAN LORENZO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14788,
    localidad: "SAN LORENZO",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14789,
    localidad: "SAN JUSTO",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14790,
    localidad: "SAN JULIAN",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14791,
    localidad: "SAN JUAN",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14792,
    localidad: "SAN JUAN",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14793,
    localidad: "SAN JUAN",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14794,
    localidad: "SAN JUAN",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14795,
    localidad: "SAN JUAN",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14796,
    localidad: "SAN JUAN",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14797,
    localidad: "SAN JUAN",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14798,
    localidad: "SAN JUAN",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14799,
    localidad: "SAN JOSE CAACATI",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14800,
    localidad: "SAN JOSE",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 14801,
    localidad: "SAN JOSE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14802,
    localidad: "SAN JOSE",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14803,
    localidad: "SAN JOSE",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 14804,
    localidad: "SAN JOSE",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14805,
    localidad: "SAN JOSE",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14806,
    localidad: "SAN JOSE",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14807,
    localidad: "SAN JOSE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14808,
    localidad: "SAN JOAQUIN",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14809,
    localidad: "SAN JOAQUIN",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14810,
    localidad: "SAN JERONIMO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14811,
    localidad: "SAN JAVIER",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14812,
    localidad: "SAN JACINTO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14813,
    localidad: "SAN ISIDRO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14814,
    localidad: "SAN ISIDRO",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14815,
    localidad: "SAN ISIDRO",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14816,
    localidad: "SAN ISIDRO",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 14817,
    localidad: "SAN IGNACIO",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 14818,
    localidad: "SAN GUSTAVO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14819,
    localidad: "SAN GUILLERMO",
    cp: 3474,
    provincia_id: 7,
  },
  {
    id: 14820,
    localidad: "SAN GREGORIO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14821,
    localidad: "SAN GREGORIO",
    cp: 3226,
    provincia_id: 7,
  },
  {
    id: 14822,
    localidad: "SAN GABRIEL",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14823,
    localidad: "SAN GABRIEL",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 14824,
    localidad: "SAN FRANCISCO CUE",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 14825,
    localidad: "SAN FRANCISCO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14826,
    localidad: "SAN FRANCISCO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14827,
    localidad: "SAN FRANCISCO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14828,
    localidad: "SAN FRANCISCO",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14829,
    localidad: "SAN FERNANDO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14830,
    localidad: "SAN FERNANDO",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14831,
    localidad: "SAN FERMIN",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14832,
    localidad: "SAN FELIPE",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14833,
    localidad: "SAN EUGENIO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 14834,
    localidad: "SAN EMILIO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14835,
    localidad: "SAN EDUARDO",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14836,
    localidad: "SAN DIONISIO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14837,
    localidad: "SAN DIEGO",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14838,
    localidad: "SAN COSME",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14839,
    localidad: "SAN CELESTINO",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 14840,
    localidad: "SAN CAYETANO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14841,
    localidad: "SAN CARLOS",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 14842,
    localidad: "SAN CARLOS",
    cp: 3345,
    provincia_id: 7,
  },
  {
    id: 14843,
    localidad: "SAN CARLOS",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14844,
    localidad: "SAN CARLOS",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14845,
    localidad: "SAN ANTONIO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14846,
    localidad: "SAN ANTONIO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14847,
    localidad: "SAN ANTONIO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14848,
    localidad: "SAN ANTONIO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14849,
    localidad: "SAN ANTONIO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14850,
    localidad: "SAN ANTONIO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14851,
    localidad: "SAN ANTONIO",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 14852,
    localidad: "SAN ANDRES",
    cp: 3226,
    provincia_id: 7,
  },
  {
    id: 14853,
    localidad: "SAN ALONSO",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 14854,
    localidad: "SAN ALEJO",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14855,
    localidad: "SAN AGUSTIN",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14856,
    localidad: "SAN AGUSTIN",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14857,
    localidad: "SALTO ITA JHASE",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14858,
    localidad: "SALINAS GANDES",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14859,
    localidad: "SALINAS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14860,
    localidad: "SALDANA 9 DE JULIO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14861,
    localidad: "SALDANA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14862,
    localidad: "SALADERO S ANTONIO",
    cp: 3443,
    provincia_id: 7,
  },
  {
    id: 14863,
    localidad: "SALADAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14864,
    localidad: "SAENZ VALIENTE",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14865,
    localidad: "S R PARADA PUCHETA",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 14866,
    localidad: "S M E LIBERTAD",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14867,
    localidad: "S LUIS DEL PALMAR",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14868,
    localidad: "S LUCIA 9 DE JULIO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14869,
    localidad: "S L E LIBERTAD DP",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14870,
    localidad: "S J E LIBERTAD DP",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 14871,
    localidad: "S FGUAVIRARI",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 14872,
    localidad: "S ANT DEL CAIMAN",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 14873,
    localidad: "RUIZ CUE",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14874,
    localidad: "ROSADO GRANDE",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14875,
    localidad: "ROSADITO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14876,
    localidad: "ROMERO CUAZU",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14877,
    localidad: "ROMERO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14878,
    localidad: "ROLON JACINTO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14879,
    localidad: "RODEITO",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14880,
    localidad: "RINCON ZALAZAR",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14881,
    localidad: "RINCON TRANQUERA G",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14882,
    localidad: "RINCON SAN PEDRO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14883,
    localidad: "RINCON MERCEDES E",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14884,
    localidad: "RINCON DEL TIGRE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14885,
    localidad: "RINCON DE YAGUARY",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 14886,
    localidad: "RINCON DE YAGUARY",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14887,
    localidad: "RINCON DE VENCES",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14888,
    localidad: "RINCON DE SOTO",
    cp: 3443,
    provincia_id: 7,
  },
  {
    id: 14889,
    localidad: "RINCON DE SARANDY",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14890,
    localidad: "RINCON DE PAGO",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 14891,
    localidad: "RINCON DE GOMEZ",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14892,
    localidad: "RINCON DE ANIMAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14893,
    localidad: "RINCON DE AMBROSIO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14894,
    localidad: "RINCON CHICO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14895,
    localidad: "RINCON",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14896,
    localidad: "RINCON",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14897,
    localidad: "RIACHUELO SUD",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14898,
    localidad: "RIACHUELO BARDECI",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14899,
    localidad: "RIACHUELO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14900,
    localidad: "RIACHUELITO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14901,
    localidad: "REMANSO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14902,
    localidad: "REDUCCION",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14903,
    localidad: "RECREO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14904,
    localidad: "REAL CUE",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14905,
    localidad: "RANEGAS",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14906,
    localidad: "RAMONES",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 14907,
    localidad: "RAMADA PASO",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 14908,
    localidad: "RALEDA SUD",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14909,
    localidad: "RAICES",
    cp: 3433,
    provincia_id: 7,
  },
  {
    id: 14910,
    localidad: "R. DEL SOMBRERO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14911,
    localidad: "R. DE S LORENZO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14912,
    localidad: "R. DE LAS MERCEDES",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14913,
    localidad: "QUIYATI",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14914,
    localidad: "QUINTA TERESA",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 14915,
    localidad: "PUNTAS DEL TIGRE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14916,
    localidad: "PUNTAS DE F GOMEZ",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14917,
    localidad: "PUNTA MERCEDES",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14918,
    localidad: "PUNTA IFRAN",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 14919,
    localidad: "PUNTA GRANDE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14920,
    localidad: "PUNTA GRANDE",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14921,
    localidad: "PUJOL BEDOYA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14922,
    localidad: "PUISOYE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 14923,
    localidad: "PUESTO LATA",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 14924,
    localidad: "PUESTO DE ISLA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14925,
    localidad: "PUESTO DE ISLA",
    cp: 3487,
    provincia_id: 7,
  },
  {
    id: 14926,
    localidad: "PUERTO VALLE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14927,
    localidad: "PUERTO UBAJAY",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14928,
    localidad: "PUERTO NARANJITO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14929,
    localidad: "PUERTO LAS LAJAS",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14930,
    localidad: "PUERTO HORMIGUERO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14931,
    localidad: "PUERTO GOYA",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 14932,
    localidad: "PUERTO GONZALEZ",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 14933,
    localidad: "PUERTO ARAZA",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 14934,
    localidad: "PUERTA IFRAN",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14935,
    localidad: "PUENTE MACHUCA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14936,
    localidad: "PUENTE BATEL",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14937,
    localidad: "PUENTE AVALOS",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 14938,
    localidad: "PUEBLO SALTO",
    cp: 3360,
    provincia_id: 7,
  },
  {
    id: 14939,
    localidad: "PUEBLO DE JULIO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 14940,
    localidad: "PUEBLITO SAN JUAN",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14941,
    localidad: "PUEBLITO ESPINOSA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 14942,
    localidad: "PUEBLITO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 14943,
    localidad: "PROGRESO",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 14944,
    localidad: "POTRERO GRANDE",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14945,
    localidad: "PORVENIR",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 14946,
    localidad: "PORVENIR",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14947,
    localidad: "PLAYADITO",
    cp: 3358,
    provincia_id: 7,
  },
  {
    id: 14948,
    localidad: "PIRRA PUY",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 14949,
    localidad: "PIRAYU",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14950,
    localidad: "PIRAYU",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14951,
    localidad: "PIRACU",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 14952,
    localidad: "PINDONCITO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14953,
    localidad: "PINDO",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 14954,
    localidad: "PINDAPOY",
    cp: 3350,
    provincia_id: 7,
  },
  {
    id: 14955,
    localidad: "PILINCHO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14956,
    localidad: "PILINCHO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14957,
    localidad: "PIEDRITA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 14958,
    localidad: "PIEDRA ITA PUCU",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14959,
    localidad: "PICADA PORTUGUESA",
    cp: 3308,
    provincia_id: 7,
  },
  {
    id: 14960,
    localidad: "PERUGORRIA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 14961,
    localidad: "PEHUAHO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 14962,
    localidad: "PEDRO R FERNANDEZ",
    cp: 3452,
    provincia_id: 7,
  },
  {
    id: 14963,
    localidad: "PEDRO R FERNANDEZ",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 14964,
    localidad: "PAY UBRE CHICO",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14965,
    localidad: "PASTORES",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14966,
    localidad: "PASO VALLEJOS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14967,
    localidad: "PASO TIRANTE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 14968,
    localidad: "PASO SANTA ROSA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14969,
    localidad: "PASO SAN JUAN",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14970,
    localidad: "PASO RUBIO",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 14971,
    localidad: "PASO ROSARIO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14972,
    localidad: "PASO PUCHETA",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 14973,
    localidad: "PASO POTRERO",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 14974,
    localidad: "PASO PESOA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14975,
    localidad: "PASO NARANJITO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 14976,
    localidad: "PASO MESA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 14977,
    localidad: "PASO LUCERO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14978,
    localidad: "PASO LOVERA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 14979,
    localidad: "PASO LOS ANGELES",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14980,
    localidad: "PASO LOPEZ",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14981,
    localidad: "PASO LEDESMA",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 14982,
    localidad: "PASO IRIBU CUA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 14983,
    localidad: "PASO GALLEGO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14984,
    localidad: "PASO FLORENTIN",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 14985,
    localidad: "PASO ESTERITO",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 14986,
    localidad: "PASO DE MULA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14987,
    localidad: "PASO DE LOS LIBRES",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 14988,
    localidad: "PASO DE LAS PIDRAS",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14989,
    localidad: "PASO DE LA PATRIA",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 14990,
    localidad: "PASO DE LA LEGUA",
    cp: 3240,
    provincia_id: 7,
  },
  {
    id: 14991,
    localidad: "PASO CORONEL",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 14992,
    localidad: "PASO CONCEPCION",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 14993,
    localidad: "PASO CEJAS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14994,
    localidad: "PASO BERMUDEZ",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 14995,
    localidad: "PASO BANDERA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 14996,
    localidad: "PASO ANCHO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 14997,
    localidad: "PASO ALGARROBO",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 14998,
    localidad: "PASO AGUIRRE",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 14999,
    localidad: "PASITO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15000,
    localidad: "PASAJE SANTA JUANA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15001,
    localidad: "PARAJE SAN ISIDRO",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15002,
    localidad: "PARAJE POTON",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15003,
    localidad: "PARAJE IRIBU CUA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15004,
    localidad: "PARAJE GUAYABO",
    cp: 3240,
    provincia_id: 7,
  },
  {
    id: 15005,
    localidad: "PARAJE FLORIDA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15006,
    localidad: "PARAJE EL CARMEN",
    cp: 3199,
    provincia_id: 7,
  },
  {
    id: 15007,
    localidad: "PARAJE AUGUA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15008,
    localidad: "PARAISO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15009,
    localidad: "PARADA LABOUGLE",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15010,
    localidad: "PANCHO CUE",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15011,
    localidad: "PAMPIN",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15012,
    localidad: "PALMITAS",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15013,
    localidad: "PALMITA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15014,
    localidad: "PALMITA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15015,
    localidad: "PALMIRA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15016,
    localidad: "PALMERA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15017,
    localidad: "PALMAR GRANDE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15018,
    localidad: "PALMAR ARERUNGUA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15019,
    localidad: "PALMAR",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15020,
    localidad: "PALMAR",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15021,
    localidad: "PALMAR",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15022,
    localidad: "PALMA SOLA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15023,
    localidad: "PAIMBRE",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15024,
    localidad: "PAGO REDONDO",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15025,
    localidad: "PAGO POI",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15026,
    localidad: "PAGO LARGO",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15027,
    localidad: "PAGO DE LOS DESEOS",
    cp: 3425,
    provincia_id: 7,
  },
  {
    id: 15028,
    localidad: "PAGO ARIAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15029,
    localidad: "PAGO ALEGRE",
    cp: 3425,
    provincia_id: 7,
  },
  {
    id: 15030,
    localidad: "P LAS TACUARITAS",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15031,
    localidad: "P J DE DIOS",
    cp: 3226,
    provincia_id: 7,
  },
  {
    id: 15032,
    localidad: "P BARRANQUITAS",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15033,
    localidad: "ORATORIO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15034,
    localidad: "ORATORIO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15035,
    localidad: "OMBUCITO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15036,
    localidad: "OMBU SOLO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15037,
    localidad: "OMBU LOMAS",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15038,
    localidad: "OMBU",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15039,
    localidad: "OMBU",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15040,
    localidad: "OJO DE AGUA",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 15041,
    localidad: "OCANTO PUE",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15042,
    localidad: "OBRAJE DEL VASCO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15043,
    localidad: "OBRAJE CUE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15044,
    localidad: "ÑATIU",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15045,
    localidad: "NUEVO PORVENIR",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15046,
    localidad: "NUEVO PARAISO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15047,
    localidad: "NUEVA PALMIRA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15048,
    localidad: "NUEVA GRANADA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15049,
    localidad: "NUEVA ESPERANZA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15050,
    localidad: "NTES DEL TUNAR",
    cp: 3350,
    provincia_id: 7,
  },
  {
    id: 15051,
    localidad: "NINA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15052,
    localidad: "NARANJITO S ROQUE",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15053,
    localidad: "NARANJITO",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15054,
    localidad: "NARANJITO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15055,
    localidad: "NARANJITO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15056,
    localidad: "NARANJATY",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15057,
    localidad: "N SRA DEL ROS D CA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15058,
    localidad: "N DEL ISABEL",
    cp: 3308,
    provincia_id: 7,
  },
  {
    id: 15059,
    localidad: "MUCHAS ISLAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15060,
    localidad: "MOTA PIEDRITAS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15061,
    localidad: "MOTA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15062,
    localidad: "MORICA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15063,
    localidad: "MORA",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15064,
    localidad: "MONTEVIDEO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15065,
    localidad: "MONTE HERMOSO",
    cp: 3350,
    provincia_id: 7,
  },
  {
    id: 15066,
    localidad: "MONTE GRANDE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15067,
    localidad: "MONTE FLORIDO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15068,
    localidad: "MONTE CASEROS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15069,
    localidad: "MOJON",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15070,
    localidad: "MOCORETA",
    cp: 3226,
    provincia_id: 7,
  },
  {
    id: 15071,
    localidad: "MNO I L EST SOLAR",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 15072,
    localidad: "MIRUNGA",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15073,
    localidad: "MIRADOR",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15074,
    localidad: "MIRA FLORES",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15075,
    localidad: "MIRA FLORES",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15076,
    localidad: "MIRA FLORES",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15077,
    localidad: "MINUANES",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15078,
    localidad: "MERCEDES",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15079,
    localidad: "MEDIODIA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15080,
    localidad: "MBURUCUYA",
    cp: 3428,
    provincia_id: 7,
  },
  {
    id: 15081,
    localidad: "MBURUCUYA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15082,
    localidad: "MBOI CUA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15083,
    localidad: "MBARIGUI",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15084,
    localidad: "MBALGUIAPU",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15085,
    localidad: "MATRERA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15086,
    localidad: "MATILDE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15087,
    localidad: "MATADERO S CATALIN",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15088,
    localidad: "MASDEU ESCUELA 197",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 15089,
    localidad: "MARUCHITAS",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15090,
    localidad: "MARUCHAS",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15091,
    localidad: "MARTINEZ CUE",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15092,
    localidad: "MARTIN GARCIA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15093,
    localidad: "MARTIN",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15094,
    localidad: "MARIANO I LOSAS",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 15095,
    localidad: "MARIA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15096,
    localidad: "MANUEL FLORENCIO MANTILLA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15097,
    localidad: "MANUEL F MANTILLA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15098,
    localidad: "MANUEL DERQUI",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15099,
    localidad: "MANDINGA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15100,
    localidad: "MANCHITA",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 15101,
    localidad: "MANANTIALES",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15102,
    localidad: "MANANTIALES",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15103,
    localidad: "MALVINAS SUR",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15104,
    localidad: "MALVINAS NORTE",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15105,
    localidad: "MALVINAS CENTRO",
    cp: 3199,
    provincia_id: 7,
  },
  {
    id: 15106,
    localidad: "MALVINAS",
    cp: 3199,
    provincia_id: 7,
  },
  {
    id: 15107,
    localidad: "MALOYITA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15108,
    localidad: "MALOYA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15109,
    localidad: "MALEZAL",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15110,
    localidad: "MALEZAL",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15111,
    localidad: "MADARIAGA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15112,
    localidad: "MACEDO",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15113,
    localidad: "M DE INVIERNO",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15114,
    localidad: "LUJAN",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15115,
    localidad: "LUJAMBIO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15116,
    localidad: "LUIS GOMEZ",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15117,
    localidad: "LOTE 117",
    cp: 3308,
    provincia_id: 7,
  },
  {
    id: 15118,
    localidad: "LOS VENCES",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15119,
    localidad: "LOS TRES HERMANOS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15120,
    localidad: "LOS TRES CERROS",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 15121,
    localidad: "LOS TRES AMIGOS",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15122,
    localidad: "LOS SAUCES",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15123,
    localidad: "LOS PINOS",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15124,
    localidad: "LOS PARAISOS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15125,
    localidad: "LOS MEDIOS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15126,
    localidad: "LOS MANANTIALES",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15127,
    localidad: "LOS LIRIOS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15128,
    localidad: "LOS LAURELES",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15129,
    localidad: "LOS LAURELES",
    cp: 3199,
    provincia_id: 7,
  },
  {
    id: 15130,
    localidad: "LOS GEMELOS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15131,
    localidad: "LOS FLOTADORES",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15132,
    localidad: "LOS EUCALIPTOS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15133,
    localidad: "LOS EUCALIPTOS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15134,
    localidad: "LOS CEIBOS",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15135,
    localidad: "LOS ARBOLES",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15136,
    localidad: "LOS ANGELES DEL B",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15137,
    localidad: "LOS ANGELES",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15138,
    localidad: "LOS ALGARROBOS",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15139,
    localidad: "LORETO",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15140,
    localidad: "LOMAS VAZQUEZ",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15141,
    localidad: "LOMAS SAN JUAN",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15142,
    localidad: "LOMAS SAN CAYETANO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15143,
    localidad: "LOMAS SALADAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15144,
    localidad: "LOMAS REDONDAS",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15145,
    localidad: "LOMAS RAMIREZ",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15146,
    localidad: "LOMAS FLORIDAS",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15147,
    localidad: "LOMAS ESTE",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15148,
    localidad: "LOMAS ESQUIVEL",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15149,
    localidad: "LOMAS DE VERGARA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15150,
    localidad: "LOMAS DE VALLEJOS",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15151,
    localidad: "LOMAS DE GONZALEZ",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15152,
    localidad: "LOMAS DE GALARZA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15153,
    localidad: "LOMAS DE EMPEDRADO",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15154,
    localidad: "LOMAS DE AGUIRRE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15155,
    localidad: "LOMAS",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15156,
    localidad: "LOMAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15157,
    localidad: "LOMA VILLANUEVA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15158,
    localidad: "LOMA POY",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15159,
    localidad: "LOMA NEGRA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15160,
    localidad: "LOMA ALTA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15161,
    localidad: "LOMA ALTA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15162,
    localidad: "LOMA ALTA",
    cp: 3425,
    provincia_id: 7,
  },
  {
    id: 15163,
    localidad: "LOMA ALTA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15164,
    localidad: "LOBORY",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15165,
    localidad: "LINDA VISTA",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15166,
    localidad: "LIMAS CUE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15167,
    localidad: "LIBERTADOR",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15168,
    localidad: "LESCA",
    cp: 3200,
    provincia_id: 7,
  },
  {
    id: 15169,
    localidad: "LEON CUA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15170,
    localidad: "LAVALLE",
    cp: 3443,
    provincia_id: 7,
  },
  {
    id: 15171,
    localidad: "LAURETTI",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15172,
    localidad: "LAUREL",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15173,
    localidad: "LAS VIOLETAS",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15174,
    localidad: "LAS TAPERAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15175,
    localidad: "LAS ROSAS",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15176,
    localidad: "LAS RATAS",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15177,
    localidad: "LAS PALMITAS",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15178,
    localidad: "LAS PALMITAS",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15179,
    localidad: "LAS PALMIRAS",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15180,
    localidad: "LAS PALMAS",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15181,
    localidad: "LAS MERCEDES",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15182,
    localidad: "LAS MATRERAS",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15183,
    localidad: "LAS LOMAS",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15184,
    localidad: "LAS LAGUNAS",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15185,
    localidad: "LAS LAGUNAS",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15186,
    localidad: "LAS GARZAS",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15187,
    localidad: "LAS ELINAS",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15188,
    localidad: "LAS DELICIAS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15189,
    localidad: "LAS CUCHILLAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15190,
    localidad: "LAS CATORCE",
    cp: 3228,
    provincia_id: 7,
  },
  {
    id: 15191,
    localidad: "LAS ANIMAS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15192,
    localidad: "LAPACHO",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15193,
    localidad: "LAGUNA SOTO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15194,
    localidad: "LAGUNA SIRENA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15195,
    localidad: "LAGUNA PUCU",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15196,
    localidad: "LAGUNA PAIVA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15197,
    localidad: "LAGUNA BRAVA",
    cp: 3411,
    provincia_id: 7,
  },
  {
    id: 15198,
    localidad: "LAGUNA AVALOS",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15199,
    localidad: "LAGUNA ALFONSO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15200,
    localidad: "LAGO ARIAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15201,
    localidad: "LABORI",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15202,
    localidad: "LA VERDE",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15203,
    localidad: "LA UNION",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15204,
    localidad: "LA QUERENCIA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15205,
    localidad: "LA PEPITA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15206,
    localidad: "LA PASTORIL",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15207,
    localidad: "LA PARADA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15208,
    localidad: "LA PALMIRA",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15209,
    localidad: "LA PALMERA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15210,
    localidad: "LA PALMA",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 15211,
    localidad: "LA PACHINA",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15212,
    localidad: "LA NENA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15213,
    localidad: "LA MOROCHA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15214,
    localidad: "LA MATILDE",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15215,
    localidad: "LA MAGNOLIA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15216,
    localidad: "LA LUISA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15217,
    localidad: "LA LOMA TORRENT",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15218,
    localidad: "LA LOMA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15219,
    localidad: "LA LOMA",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15220,
    localidad: "LA LOLITA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15221,
    localidad: "LA LOLITA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15222,
    localidad: "LA LEONTINA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15223,
    localidad: "LA LEONOR",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15224,
    localidad: "LA JAULA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15225,
    localidad: "LA ISABEL",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15226,
    localidad: "LA HILEORICA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15227,
    localidad: "LA HERMINIA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15228,
    localidad: "LA HAYDEE",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15229,
    localidad: "LA GARCIA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15230,
    localidad: "LA FORTUNA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15231,
    localidad: "LA FLORIDA",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15232,
    localidad: "LA FLORESTA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15233,
    localidad: "LA FLORENTINA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15234,
    localidad: "LA FLORENCIA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15235,
    localidad: "LA FLOR",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15236,
    localidad: "LA FLOR",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15237,
    localidad: "LA FLECHA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15238,
    localidad: "LA FE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15239,
    localidad: "LA ESTRELLA",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 15240,
    localidad: "LA ESTRELLA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15241,
    localidad: "LA EMILIA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15242,
    localidad: "LA ELVIRA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15243,
    localidad: "LA ELVA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15244,
    localidad: "LA ELSA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15245,
    localidad: "LA ELOISA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15246,
    localidad: "LA DIANA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15247,
    localidad: "LA DELICIA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15248,
    localidad: "LA CRUZ",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 15249,
    localidad: "LA CRUZ",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15250,
    localidad: "LA CRIOLLA",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15251,
    localidad: "LA CONSTANCIA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15252,
    localidad: "LA CONCEPCION",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15253,
    localidad: "LA CONCEPCION",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15254,
    localidad: "LA COLORADA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15255,
    localidad: "LA CHIQUITA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15256,
    localidad: "LA CELIA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15257,
    localidad: "LA CELIA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15258,
    localidad: "LA CELESTE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15259,
    localidad: "LA CAUTIVA",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15260,
    localidad: "LA CASUALIDAD",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15261,
    localidad: "LA CARLOTA",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15262,
    localidad: "LA CARLINA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15263,
    localidad: "LA BOLSA",
    cp: 3443,
    provincia_id: 7,
  },
  {
    id: 15264,
    localidad: "LA BLANQUEADA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15265,
    localidad: "LA BLANQUEADA",
    cp: 3424,
    provincia_id: 7,
  },
  {
    id: 15266,
    localidad: "LA BLANCA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15267,
    localidad: "LA BELERMINA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15268,
    localidad: "LA AURORA",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15269,
    localidad: "LA AURORA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15270,
    localidad: "LA ARMONIA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15271,
    localidad: "LA ARMONIA",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15272,
    localidad: "LA ANGELITA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15273,
    localidad: "LA ANGELA",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15274,
    localidad: "LA AGRIPINA",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 15275,
    localidad: "KM 425",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15276,
    localidad: "KILOMETRO 95",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15277,
    localidad: "KILOMETRO 89",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15278,
    localidad: "KILOMETRO 84",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15279,
    localidad: "KILOMETRO 76",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15280,
    localidad: "KILOMETRO 61",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15281,
    localidad: "KILOMETRO 57",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15282,
    localidad: "KILOMETRO 55",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15283,
    localidad: "KILOMETRO 517",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15284,
    localidad: "KILOMETRO 516",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15285,
    localidad: "KILOMETRO 512",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15286,
    localidad: "KILOMETRO 506",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15287,
    localidad: "KILOMETRO 504",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15288,
    localidad: "KILOMETRO 501",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15289,
    localidad: "KILOMETRO 494",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15290,
    localidad: "KILOMETRO 492",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15291,
    localidad: "KILOMETRO 49",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15292,
    localidad: "KILOMETRO 489",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15293,
    localidad: "KILOMETRO 485",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15294,
    localidad: "KILOMETRO 479",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15295,
    localidad: "KILOMETRO 476",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15296,
    localidad: "KILOMETRO 475",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15297,
    localidad: "KILOMETRO 470",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15298,
    localidad: "KILOMETRO 462",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15299,
    localidad: "KILOMETRO 459",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15300,
    localidad: "KILOMETRO 451",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15301,
    localidad: "KILOMETRO 442",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15302,
    localidad: "KILOMETRO 431",
    cp: 3428,
    provincia_id: 7,
  },
  {
    id: 15303,
    localidad: "KILOMETRO 42",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15304,
    localidad: "KILOMETRO 416",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15305,
    localidad: "KILOMETRO 410",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15306,
    localidad: "KILOMETRO 406",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15307,
    localidad: "KILOMETRO 402",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15308,
    localidad: "KILOMETRO 396",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15309,
    localidad: "KILOMETRO 394",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15310,
    localidad: "KILOMETRO 393",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15311,
    localidad: "KILOMETRO 387",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15312,
    localidad: "KILOMETRO 382",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15313,
    localidad: "KILOMETRO 374",
    cp: 3446,
    provincia_id: 7,
  },
  {
    id: 15314,
    localidad: "KILOMETRO 31",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15315,
    localidad: "KILOMETRO 296",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15316,
    localidad: "KILOMETRO 287 FCGU",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15317,
    localidad: "KILOMETRO 279",
    cp: 3240,
    provincia_id: 7,
  },
  {
    id: 15318,
    localidad: "KILOMETRO 268",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15319,
    localidad: "KILOMETRO 235",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 15320,
    localidad: "KILOMETRO 204",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 15321,
    localidad: "KILOMETRO 182",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 15322,
    localidad: "KILOMETRO 134",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15323,
    localidad: "KILOMETRO 13",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15324,
    localidad: "KILOMETRO 120",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15325,
    localidad: "KILOMETRO 104",
    cp: 3226,
    provincia_id: 7,
  },
  {
    id: 15326,
    localidad: "JUAN RAMON VIDAL",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15327,
    localidad: "JUAN PUJOL",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15328,
    localidad: "JESUS MARIA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15329,
    localidad: "JARDIN FLORIDO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15330,
    localidad: "ITUZAINGO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15331,
    localidad: "ITATI RINCON",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15332,
    localidad: "ITATI",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15333,
    localidad: "ITA PUCU",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15334,
    localidad: "ITA IBATE",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15335,
    localidad: "ITA CURUBI",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15336,
    localidad: "ITA CUA",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15337,
    localidad: "ITA CORA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15338,
    localidad: "ISLA TACUARA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15339,
    localidad: "ISLA SOLA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15340,
    localidad: "ISLA SAN MATEO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15341,
    localidad: "ISLA IBATE",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15342,
    localidad: "ISLA IBATAY",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15343,
    localidad: "ISLA GRANDE",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15344,
    localidad: "ISLA APIPE CHICO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15345,
    localidad: "ISLA ALTA",
    cp: 3449,
    provincia_id: 7,
  },
  {
    id: 15346,
    localidad: "ISLA ALTA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15347,
    localidad: "ISABEL VICTORIA - IFRAN",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 15348,
    localidad: "ISABEL VICTORIA",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 15349,
    localidad: "IPACARAPA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15350,
    localidad: "INVERNADA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15351,
    localidad: "INGA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15352,
    localidad: "ING 1: CORRENTINO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15353,
    localidad: "INFANTE",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15354,
    localidad: "INDEPENDENCIA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15355,
    localidad: "IGUATE PORA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15356,
    localidad: "IFRAN",
    cp: 3453,
    provincia_id: 7,
  },
  {
    id: 15357,
    localidad: "IBIRA PITA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15358,
    localidad: "IBERA",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15359,
    localidad: "IBAVIYU",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15360,
    localidad: "IBAHAY",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15361,
    localidad: "HERLITZKA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15362,
    localidad: "GUAZU CORA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15363,
    localidad: "GUAYU",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15364,
    localidad: "GUAYQUIRARO",
    cp: 3194,
    provincia_id: 7,
  },
  {
    id: 15365,
    localidad: "GUAYCURU",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15366,
    localidad: "GUAY GRANDE",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15367,
    localidad: "GUAVIRAVI",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 15368,
    localidad: "GRANJA AMELIA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15369,
    localidad: "GOYA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15370,
    localidad: "GOMEZ CUE",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15371,
    localidad: "GOBERNADOR VIRASORO",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15372,
    localidad: "GOBERNADOR MARTINEZ",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15373,
    localidad: "GOBERNADOR JUAN E MARTINEZ",
    cp: 3444,
    provincia_id: 7,
  },
  {
    id: 15374,
    localidad: "GENERAL PAZ",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15375,
    localidad: "GDOR J E TORREN",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15376,
    localidad: "GDOR ING VALENTIN VIRASO",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15377,
    localidad: "GDOR ING V. VIRASO",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15378,
    localidad: "GARRUCHOS",
    cp: 3349,
    provincia_id: 7,
  },
  {
    id: 15379,
    localidad: "GARRIDO CUE",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15380,
    localidad: "GARRIDO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15381,
    localidad: "GARCITAS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15382,
    localidad: "GARCETE CUE",
    cp: 3641,
    provincia_id: 7,
  },
  {
    id: 15383,
    localidad: "GARABATA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15384,
    localidad: "GALARZA CUE",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15385,
    localidad: "G JUAN E MARTINEZ",
    cp: 3444,
    provincia_id: 7,
  },
  {
    id: 15386,
    localidad: "FRONTERA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15387,
    localidad: "FRANCISCO GAUNA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15388,
    localidad: "FLORIDA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15389,
    localidad: "FERRO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15390,
    localidad: "FERRET",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15391,
    localidad: "FERNANDEZ",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15392,
    localidad: "FELIPE YOFRE",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15393,
    localidad: "FANEGAS",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15394,
    localidad: "ESTRELLA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15395,
    localidad: "ESTINGANA",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 15396,
    localidad: "ESTERO YATAY",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15397,
    localidad: "ESTERO SAUCE",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15398,
    localidad: "ESTERO PERU",
    cp: 3474,
    provincia_id: 7,
  },
  {
    id: 15399,
    localidad: "ESTERO GRANDE",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15400,
    localidad: "ESTE ARGENTINO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15401,
    localidad: "ESTANCIA TUNAS",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15402,
    localidad: "ESTANCIA SOLEDAD",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15403,
    localidad: "ESTANCIA SAN MATEO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15404,
    localidad: "ESTANCIA SAN JULIO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15405,
    localidad: "ESTANCIA SAN JUAN",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15406,
    localidad: "ESTANCIA ROSARIO",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15407,
    localidad: "ESTANCIA MBOTA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15408,
    localidad: "ESTANCIA MANDURE",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15409,
    localidad: "ESTANCIA LAS TUNAS",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15410,
    localidad: "ESTANCIA LA MARIA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15411,
    localidad: "ESTANCIA LA LOMA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15412,
    localidad: "ESTANCIA LA CALERA",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15413,
    localidad: "ESTANCIA LA AURORA",
    cp: 3500,
    provincia_id: 7,
  },
  {
    id: 15414,
    localidad: "ESTANCIA ITA CAABO",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15415,
    localidad: "ESTANCIA EL OMBU",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15416,
    localidad: "ESTANCIA EL CARMEN",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15417,
    localidad: "ESTANCIA EL CARMEN",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15418,
    localidad: "ESTANCIA DURRUTI",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15419,
    localidad: "ESTANCIA CASURINA",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15420,
    localidad: "ESTANCIA AGUACEROS",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15421,
    localidad: "ESTACION SALADAS",
    cp: 3428,
    provincia_id: 7,
  },
  {
    id: 15422,
    localidad: "ESTACION LIBERTAD",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 15423,
    localidad: "ESQUIVEL CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15424,
    localidad: "ESQUINA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15425,
    localidad: "ESPINILLO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15426,
    localidad: "ESPINILLAR",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15427,
    localidad: "ESPERANZA",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15428,
    localidad: "ESFADAL",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15429,
    localidad: "ENSENADITA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15430,
    localidad: "ENSENADA GRANDE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15431,
    localidad: "EMPEDRADO LIMPIO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15432,
    localidad: "EMPEDRADO LIMPIO",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15433,
    localidad: "EMPEDRADO LIMPIO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15434,
    localidad: "EMPEDRADO",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15435,
    localidad: "EMILIO R CONI",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15436,
    localidad: "EL YAQUERI",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15437,
    localidad: "EL YAPU",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15438,
    localidad: "EL VASCO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15439,
    localidad: "EL TRANSITO",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15440,
    localidad: "EL TORO PI",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15441,
    localidad: "EL TIGRE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15442,
    localidad: "EL TESORO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15443,
    localidad: "EL TATARE",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15444,
    localidad: "EL SOMBRERO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15445,
    localidad: "EL SOCORRO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15446,
    localidad: "EL SALVADOR",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15447,
    localidad: "EL ROSARIO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15448,
    localidad: "EL REMANSO",
    cp: 3476,
    provincia_id: 7,
  },
  {
    id: 15449,
    localidad: "EL PROGRESO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15450,
    localidad: "EL PORVENIR",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15451,
    localidad: "EL PORVENIR",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15452,
    localidad: "EL PONTON",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15453,
    localidad: "EL POLLO",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15454,
    localidad: "EL PLATA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15455,
    localidad: "EL PILAR",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15456,
    localidad: "EL PELON",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15457,
    localidad: "EL PARQUE",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15458,
    localidad: "EL PARAISO",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15459,
    localidad: "EL PALMAR",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15460,
    localidad: "EL PAGO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15461,
    localidad: "EL P C LIBERTAD",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 15462,
    localidad: "EL LOTO",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15463,
    localidad: "EL COQUITO",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15464,
    localidad: "EL CHIRCAL",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15465,
    localidad: "EL CERRO",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15466,
    localidad: "EL CERRITO",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15467,
    localidad: "EL CEIBO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15468,
    localidad: "EL CEIBO",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15469,
    localidad: "EL CARRIZAL",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15470,
    localidad: "EL CARMEN",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15471,
    localidad: "EL CARMEN",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15472,
    localidad: "EL CARMEN",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15473,
    localidad: "EL CARMEN",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15474,
    localidad: "EL BUEN RETIRO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15475,
    localidad: "E SANTA MARIA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15476,
    localidad: "E SANTA CRUZ",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15477,
    localidad: "E SAN SOLANO",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15478,
    localidad: "E SAN ROBERTO",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15479,
    localidad: "E SAN JAVIER",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15480,
    localidad: "E SAN ANTONIO",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15481,
    localidad: "E RINCON GRANDE",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15482,
    localidad: "E POZO CUADRADO",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15483,
    localidad: "E MARQUEZ LUI",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15484,
    localidad: "E LOS PARAISOS",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15485,
    localidad: "E LOS MILAGROS",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15486,
    localidad: "E LAGUNA LIMPIA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15487,
    localidad: "E LA LOMA ALTA",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15488,
    localidad: "E LA CAROLINA",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15489,
    localidad: "E LA CARMENCHA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15490,
    localidad: "E LA ARBOLEDA",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15491,
    localidad: "E GONZALEZ CRUZ",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15492,
    localidad: "E EL PORVENIR",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15493,
    localidad: "E CERRO VERDE",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15494,
    localidad: "E CAFFERATTA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15495,
    localidad: "E BUENA VISTA",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15496,
    localidad: "E AGRONOMICA",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15497,
    localidad: "DOS OMBUES",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15498,
    localidad: "DOS HERMANOS",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15499,
    localidad: "DOS HERMANAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15500,
    localidad: "DON MAXIMO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15501,
    localidad: "DESMOCHADO",
    cp: 3441,
    provincia_id: 7,
  },
  {
    id: 15502,
    localidad: "DESAGUADERO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15503,
    localidad: "CURUZU LAUREL",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15504,
    localidad: "CURUZU CUATIA",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15505,
    localidad: "CURUZU",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15506,
    localidad: "CURUPAYTI",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15507,
    localidad: "CURTIEMBRE",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15508,
    localidad: "CUÑA SUEGRA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15509,
    localidad: "CUAY GRANDE",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15510,
    localidad: "CUAY CHICO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15511,
    localidad: "CUAY CHICO",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15512,
    localidad: "CUATRO BOCAS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15513,
    localidad: "CTIVA G SAN MARTIN",
    cp: 3228,
    provincia_id: 7,
  },
  {
    id: 15514,
    localidad: "CRUZ DE LOS MILAGROS",
    cp: 3441,
    provincia_id: 7,
  },
  {
    id: 15515,
    localidad: "CRUCECITAS S LUCIA",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15516,
    localidad: "CRUCECITAS",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15517,
    localidad: "COSTAS",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15518,
    localidad: "COSTA TOLEDO",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 15519,
    localidad: "COSTA SANTA LUCIA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15520,
    localidad: "COSTA SANTA LUCIA",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15521,
    localidad: "COSTA SAN LORENZO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15522,
    localidad: "COSTA RIO PARANA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15523,
    localidad: "COSTA GUAZU",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15524,
    localidad: "COSTA GUAVIRAVI",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 15525,
    localidad: "COSTA GRANDE",
    cp: 3425,
    provincia_id: 7,
  },
  {
    id: 15526,
    localidad: "COSTA GRANDE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15527,
    localidad: "COSTA DEL BATEL",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15528,
    localidad: "COSTA DE EMPEDRADO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15529,
    localidad: "COSTA DE ARROYO SAN LORENZO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15530,
    localidad: "COSTA BATEL",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15531,
    localidad: "COSTA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15532,
    localidad: "COSTA",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15533,
    localidad: "CORSA CUE",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15534,
    localidad: "CORRIENTES",
    cp: 3400,
    provincia_id: 7,
  },
  {
    id: 15535,
    localidad: "CORRIENTES",
    cp: 3404,
    provincia_id: 7,
  },
  {
    id: 15536,
    localidad: "CORRIENTES",
    cp: 3402,
    provincia_id: 7,
  },
  {
    id: 15537,
    localidad: "CORRIENTES",
    cp: 3408,
    provincia_id: 7,
  },
  {
    id: 15538,
    localidad: "CORRIENTES",
    cp: 3406,
    provincia_id: 7,
  },
  {
    id: 15539,
    localidad: "CORRIENTES",
    cp: 3410,
    provincia_id: 7,
  },
  {
    id: 15540,
    localidad: "CORONEL ABRAHAM SCHWEIZER",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15541,
    localidad: "CORONA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15542,
    localidad: "CONCEPCION",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15543,
    localidad: "CONCEPCION",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15544,
    localidad: "COLONIA VEDOYA",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15545,
    localidad: "COLONIA URDANIZ",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15546,
    localidad: "COLONIA TATACUA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15547,
    localidad: "COLONIA SAUCE",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15548,
    localidad: "COLONIA SANTA ROSA",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 15549,
    localidad: "COLONIA SAN MATEO",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15550,
    localidad: "COLONIA SAN MARTIN",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15551,
    localidad: "COLONIA SAN JOSE",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15552,
    localidad: "COLONIA ROMERO",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15553,
    localidad: "COLONIA PUCHETA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15554,
    localidad: "COLONIA PROGRESO",
    cp: 3433,
    provincia_id: 7,
  },
  {
    id: 15555,
    localidad: "COLONIA PORVENIR",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15556,
    localidad: "COLONIA PANDO",
    cp: 3449,
    provincia_id: 7,
  },
  {
    id: 15557,
    localidad: "COLONIA PALMIRA",
    cp: 3500,
    provincia_id: 7,
  },
  {
    id: 15558,
    localidad: "COLONIA MENDEZ BAR",
    cp: 3443,
    provincia_id: 7,
  },
  {
    id: 15559,
    localidad: "COLONIA MATILDE",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15560,
    localidad: "COLONIA MARTIRES",
    cp: 3308,
    provincia_id: 7,
  },
  {
    id: 15561,
    localidad: "COLONIA MADARIAGA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15562,
    localidad: "COLONIA LUJAN",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15563,
    localidad: "COLONIA LUCERO",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 15564,
    localidad: "COLONIA LLANO",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15565,
    localidad: "COLONIA LIEBIG S",
    cp: 3358,
    provincia_id: 7,
  },
  {
    id: 15566,
    localidad: "COLONIA LIBERTAD",
    cp: 3224,
    provincia_id: 7,
  },
  {
    id: 15567,
    localidad: "COLONIA LA UNION",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15568,
    localidad: "COLONIA LA HABANA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15569,
    localidad: "COLONIA LA CARMEN",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15570,
    localidad: "COLONIA JUAN PUJOL",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15571,
    localidad: "COLONIA GARABI",
    cp: 3343,
    provincia_id: 7,
  },
  {
    id: 15572,
    localidad: "COLONIA GAIMAN",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15573,
    localidad: "COLONIA FLORENCIA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15574,
    localidad: "COLONIA EGIDO",
    cp: 3240,
    provincia_id: 7,
  },
  {
    id: 15575,
    localidad: "COLONIA DURAZNO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15576,
    localidad: "COLONIA DORA ELENA",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 15577,
    localidad: "COLONIA DEL CARMEN",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15578,
    localidad: "COLONIA DANUZZO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15579,
    localidad: "COLONIA CHIRCAL",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15580,
    localidad: "COLONIA CAROLINA",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15581,
    localidad: "COLONIA CARLOS PELLEGRINI",
    cp: 3473,
    provincia_id: 7,
  },
  {
    id: 15582,
    localidad: "COLONIA CAIMAN",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15583,
    localidad: "COLONIA BROUGNES",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15584,
    localidad: "COLONIA BRANCHI",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15585,
    localidad: "COLONIA BERON DE ASTRADA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15586,
    localidad: "COLONIA BARRIENTES",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15587,
    localidad: "COLONIA AZARA",
    cp: 3350,
    provincia_id: 7,
  },
  {
    id: 15588,
    localidad: "COLONIA ARROCERA",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15589,
    localidad: "COLONIA AMADEI",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15590,
    localidad: "COLONIA ALVAREZ",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15591,
    localidad: "COLONIA 3 DE ABRIL",
    cp: 3433,
    provincia_id: 7,
  },
  {
    id: 15592,
    localidad: "COLONIA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15593,
    localidad: "COLONIA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15594,
    localidad: "CNIA O J BAUTISTA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15595,
    localidad: "CHIRCAL",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15596,
    localidad: "CHIRCAL",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15597,
    localidad: "CHILECITO",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15598,
    localidad: "CHAQUITO",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15599,
    localidad: "CHACRAS SUD",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15600,
    localidad: "CHACRAS SEC. EJIDO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15601,
    localidad: "CHACRAS NORTE",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15602,
    localidad: "CHACRAS 4A SECCION",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15603,
    localidad: "CHACRAS 3A SECCION",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15604,
    localidad: "CHACRAS",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15605,
    localidad: "CHACRAS",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15606,
    localidad: "CERRUDO CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15607,
    localidad: "CERRITO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15608,
    localidad: "CERRITO",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15609,
    localidad: "CENTINELA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15610,
    localidad: "CEBOLLAS",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15611,
    localidad: "CAZA PAVA",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15612,
    localidad: "CAZ CORRENTINOS",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15613,
    localidad: "CAVIA CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15614,
    localidad: "CAVI POY",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15615,
    localidad: "CAU GARAY",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15616,
    localidad: "CATALAN CUE",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15617,
    localidad: "CASUARINAS",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15618,
    localidad: "CASUARINA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15619,
    localidad: "CASUALIDAD",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15620,
    localidad: "CASUALIDAD",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15621,
    localidad: "CASUALIDAD",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15622,
    localidad: "CASILLAS",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15623,
    localidad: "CARUSO A FCGU",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15624,
    localidad: "CARRIZAL NORTE",
    cp: 3433,
    provincia_id: 7,
  },
  {
    id: 15625,
    localidad: "CARRIZAL",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15626,
    localidad: "CARRETA PASO",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15627,
    localidad: "CARMAN",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15628,
    localidad: "CARLOS PELLEGRIN",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15629,
    localidad: "CARDOZO PHI",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15630,
    localidad: "CARAYA",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15631,
    localidad: "CARANDAITA",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15632,
    localidad: "CARAMBOLA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15633,
    localidad: "CARABI POY",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15634,
    localidad: "CARABAJAL ESTE",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15635,
    localidad: "CARABAJAL",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15636,
    localidad: "CAQADA QUIROZ",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15637,
    localidad: "CAPITAN JOAQUIN",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15638,
    localidad: "CAPITA MINI",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15639,
    localidad: "CAPITA MINI",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15640,
    localidad: "CAPIT J MADARIAGA",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15641,
    localidad: "CAPIRARI",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15642,
    localidad: "CAPILLITA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15643,
    localidad: "CAPILLA CUE",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15644,
    localidad: "CAPIGUARI",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15645,
    localidad: "CAPI VARI",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15646,
    localidad: "CAÑADITAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15647,
    localidad: "CAMPO SAN JACINTO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15648,
    localidad: "CAMPO ROMERO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15649,
    localidad: "CAMPO RICHARDSON",
    cp: 3350,
    provincia_id: 7,
  },
  {
    id: 15650,
    localidad: "CAMPO POY",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15651,
    localidad: "CAMPO MORATO",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15652,
    localidad: "CAMPO MAIDANA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15653,
    localidad: "CAMPO GRANDE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15654,
    localidad: "CAMPO FERNANDEZ",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15655,
    localidad: "CAMPO ESCALADA",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15656,
    localidad: "CAMPO DE CARLOS",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15657,
    localidad: "CAMPO CARDOZO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15658,
    localidad: "CAMPO CAFFERATA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15659,
    localidad: "CAMPO BORDON",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15660,
    localidad: "CAMPO ARAUJO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15661,
    localidad: "CAMBIRETA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15662,
    localidad: "CAMBARA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15663,
    localidad: "CAMBAL",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15664,
    localidad: "CAMBA CUA",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15665,
    localidad: "CALLEJON",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15666,
    localidad: "CAIMAN",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15667,
    localidad: "CABRED",
    cp: 3232,
    provincia_id: 7,
  },
  {
    id: 15668,
    localidad: "CABRAL",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15669,
    localidad: "CAAYOBAY",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15670,
    localidad: "CAABY POY",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15671,
    localidad: "CAABI POI",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15672,
    localidad: "CAA GUAZU",
    cp: 3472,
    provincia_id: 7,
  },
  {
    id: 15673,
    localidad: "CAA GARAY GDOR V",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15674,
    localidad: "CAA GARAY",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15675,
    localidad: "CAA CATI",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15676,
    localidad: "CAA CARAI",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15677,
    localidad: "C TACUARALITO",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15678,
    localidad: "C SAN EUGENIO",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15679,
    localidad: "C SAN ANTONIO",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15680,
    localidad: "C SAN ANTONIO",
    cp: 3485,
    provincia_id: 7,
  },
  {
    id: 15681,
    localidad: "C ROLON COSSIO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15682,
    localidad: "C OFIC N 1 L FLORI",
    cp: 3228,
    provincia_id: 7,
  },
  {
    id: 15683,
    localidad: "C NUEVA VALENCIA",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15684,
    localidad: "C MERCEDES COSSIO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15685,
    localidad: "C MARIA ESTHER",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15686,
    localidad: "C M ABERASTURY",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 15687,
    localidad: "C JOSE R GOMEZ",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15688,
    localidad: "C JACOBO FINH",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15689,
    localidad: "C GOBERNADOR RUIZ",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15690,
    localidad: "C GENERAL URIBURU",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15691,
    localidad: "C GENERAL FERRE",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15692,
    localidad: "C ENSANCHE SAUCE",
    cp: 3228,
    provincia_id: 7,
  },
  {
    id: 15693,
    localidad: "C DESIDERIO SOSA",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15694,
    localidad: "C DE LOS MILAGROS",
    cp: 3441,
    provincia_id: 7,
  },
  {
    id: 15695,
    localidad: "C DE A S LORENZO",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15696,
    localidad: "C C PELLEGRINI",
    cp: 3473,
    provincia_id: 7,
  },
  {
    id: 15697,
    localidad: "C C ECHEVERRIA",
    cp: 3440,
    provincia_id: 7,
  },
  {
    id: 15698,
    localidad: "C AROCENA INA",
    cp: 3231,
    provincia_id: 7,
  },
  {
    id: 15699,
    localidad: "BUENA VISTA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15700,
    localidad: "BUENA VISTA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15701,
    localidad: "BUENA VISTA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15702,
    localidad: "BUENA VISTA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15703,
    localidad: "BUENA VISTA",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15704,
    localidad: "BUENA VISTA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15705,
    localidad: "BUENA VISTA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15706,
    localidad: "BUENA VENTURA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15707,
    localidad: "BUENA ESPERANZA",
    cp: 3454,
    provincia_id: 7,
  },
  {
    id: 15708,
    localidad: "BUEN RETIRO",
    cp: 3222,
    provincia_id: 7,
  },
  {
    id: 15709,
    localidad: "BROJA CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15710,
    localidad: "BRIGANIS",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15711,
    localidad: "BREGAIN CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15712,
    localidad: "BORANZA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15713,
    localidad: "BOQUERON",
    cp: 3340,
    provincia_id: 7,
  },
  {
    id: 15714,
    localidad: "BOQUERON",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15715,
    localidad: "BOQUERON",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15716,
    localidad: "BONPLAND",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 15717,
    localidad: "BONETE",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15718,
    localidad: "BOMPLAND",
    cp: 3234,
    provincia_id: 7,
  },
  {
    id: 15719,
    localidad: "BOLICHE LATA",
    cp: 3449,
    provincia_id: 7,
  },
  {
    id: 15720,
    localidad: "BLANCO CUE",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15721,
    localidad: "BERON DE ASTRADA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15722,
    localidad: "BERNACHEA",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15723,
    localidad: "BELLA VISTA",
    cp: 3432,
    provincia_id: 7,
  },
  {
    id: 15724,
    localidad: "BEDOYA",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15725,
    localidad: "BATELITO",
    cp: 3451,
    provincia_id: 7,
  },
  {
    id: 15726,
    localidad: "BATEL",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 15727,
    localidad: "BATAY",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15728,
    localidad: "BATARA",
    cp: 3423,
    provincia_id: 7,
  },
  {
    id: 15729,
    localidad: "BATAL",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15730,
    localidad: "BASTIDORES",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15731,
    localidad: "BARTOLOME MITRE",
    cp: 3418,
    provincia_id: 7,
  },
  {
    id: 15732,
    localidad: "BARRIO VILLA CORDOBA",
    cp: 3442,
    provincia_id: 7,
  },
  {
    id: 15733,
    localidad: "BARRIO V CORDOBA",
    cp: 3442,
    provincia_id: 7,
  },
  {
    id: 15734,
    localidad: "BARRANQUERAS",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15735,
    localidad: "BARRANCAS",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15736,
    localidad: "BARGONE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15737,
    localidad: "BALENGO",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15738,
    localidad: "BALENGO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15739,
    localidad: "BAJO GUAZU",
    cp: 3421,
    provincia_id: 7,
  },
  {
    id: 15740,
    localidad: "BAJO GRANDE",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15741,
    localidad: "BAIBIENE",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15742,
    localidad: "BACACAY",
    cp: 3346,
    provincia_id: 7,
  },
  {
    id: 15743,
    localidad: "AYALA CUE",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15744,
    localidad: "ARRROYO SECO",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15745,
    localidad: "ARROYO VEGA",
    cp: 3196,
    provincia_id: 7,
  },
  {
    id: 15746,
    localidad: "ARROYO TOTORAS",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15747,
    localidad: "ARROYO TOMAS",
    cp: 3308,
    provincia_id: 7,
  },
  {
    id: 15748,
    localidad: "ARROYO TIMBOY",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15749,
    localidad: "ARROYO SORO",
    cp: 3194,
    provincia_id: 7,
  },
  {
    id: 15750,
    localidad: "ARROYO SOLIS",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15751,
    localidad: "ARROYO SECO",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15752,
    localidad: "ARROYO SATURNO",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15753,
    localidad: "ARROYO SARANDI",
    cp: 3194,
    provincia_id: 7,
  },
  {
    id: 15754,
    localidad: "ARROYO SAN JUAN",
    cp: 3409,
    provincia_id: 7,
  },
  {
    id: 15755,
    localidad: "ARROYO PONTON",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15756,
    localidad: "ARROYO PELON",
    cp: 3401,
    provincia_id: 7,
  },
  {
    id: 15757,
    localidad: "ARROYO PAISO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15758,
    localidad: "ARROYO MENDEZ",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15759,
    localidad: "ARROYO MANGANGA",
    cp: 3220,
    provincia_id: 7,
  },
  {
    id: 15760,
    localidad: "ARROYO HORQUETA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15761,
    localidad: "ARROYO GRANDE",
    cp: 3470,
    provincia_id: 7,
  },
  {
    id: 15762,
    localidad: "ARROYO GONZALEZ",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15763,
    localidad: "ARROYO GARAY",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15764,
    localidad: "ARROYO CEIBAL",
    cp: 3416,
    provincia_id: 7,
  },
  {
    id: 15765,
    localidad: "ARROYO CASTILLO",
    cp: 3460,
    provincia_id: 7,
  },
  {
    id: 15766,
    localidad: "ARROYO CASCO",
    cp: 3465,
    provincia_id: 7,
  },
  {
    id: 15767,
    localidad: "ARROYO CARANCHO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15768,
    localidad: "ARROYO BALMACEDA",
    cp: 3483,
    provincia_id: 7,
  },
  {
    id: 15769,
    localidad: "ARROYO AMBROSIO",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15770,
    localidad: "ARROYITO",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15771,
    localidad: "ARROYITO",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15772,
    localidad: "ARISTIA",
    cp: 3463,
    provincia_id: 7,
  },
  {
    id: 15773,
    localidad: "ARERUNGUA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15774,
    localidad: "ARBOL SOLO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15775,
    localidad: "APIPE GRANDE",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15776,
    localidad: "ANGUA",
    cp: 3420,
    provincia_id: 7,
  },
  {
    id: 15777,
    localidad: "ANGOSTURA",
    cp: 3481,
    provincia_id: 7,
  },
  {
    id: 15778,
    localidad: "ALVEAR",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15779,
    localidad: "ALTAMORA PARADA",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15780,
    localidad: "ALTAMIRA",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15781,
    localidad: "ALTA MORA",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15782,
    localidad: "ALGARROBO PARAJE",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15783,
    localidad: "ALGARROBO",
    cp: 3441,
    provincia_id: 7,
  },
  {
    id: 15784,
    localidad: "ALGARROBALES",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15785,
    localidad: "ALGARROBAL PUISOYE",
    cp: 3405,
    provincia_id: 7,
  },
  {
    id: 15786,
    localidad: "ALGARROBAL",
    cp: 3480,
    provincia_id: 7,
  },
  {
    id: 15787,
    localidad: "ALGARROBAL",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15788,
    localidad: "ALFONSO LOMAS",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15789,
    localidad: "ALFONSO LOMA",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15790,
    localidad: "ALEN CUE",
    cp: 3471,
    provincia_id: 7,
  },
  {
    id: 15791,
    localidad: "ALEJANDRIA",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15792,
    localidad: "ALBARDONES",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15793,
    localidad: "ALBARDON",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15794,
    localidad: "ALAMO",
    cp: 3448,
    provincia_id: 7,
  },
  {
    id: 15795,
    localidad: "ALAMO",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15796,
    localidad: "AGUIRRE LOMAS",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15797,
    localidad: "AGUIRRE CUE",
    cp: 3403,
    provincia_id: 7,
  },
  {
    id: 15798,
    localidad: "AGUAY",
    cp: 3407,
    provincia_id: 7,
  },
  {
    id: 15799,
    localidad: "AGUAY",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15800,
    localidad: "AGUARA CUA",
    cp: 3302,
    provincia_id: 7,
  },
  {
    id: 15801,
    localidad: "AGUAPEY",
    cp: 3342,
    provincia_id: 7,
  },
  {
    id: 15802,
    localidad: "AGUAPEY",
    cp: 3306,
    provincia_id: 7,
  },
  {
    id: 15803,
    localidad: "ACUÑA",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15804,
    localidad: "ABRA GUAZU",
    cp: 3197,
    provincia_id: 7,
  },
  {
    id: 15805,
    localidad: "ABRA",
    cp: 3427,
    provincia_id: 7,
  },
  {
    id: 15806,
    localidad: "ABRA",
    cp: 3414,
    provincia_id: 7,
  },
  {
    id: 15807,
    localidad: "ABO NEZU",
    cp: 3461,
    provincia_id: 7,
  },
  {
    id: 15808,
    localidad: "ABELI",
    cp: 3466,
    provincia_id: 7,
  },
  {
    id: 15809,
    localidad: "ABALO",
    cp: 3436,
    provincia_id: 7,
  },
  {
    id: 15810,
    localidad: "9 DE JULIO",
    cp: 3445,
    provincia_id: 7,
  },
  {
    id: 15811,
    localidad: "8 DE DICIEMBRE",
    cp: 3450,
    provincia_id: 7,
  },
  {
    id: 15812,
    localidad: "5TA SEC. OMBUCITO",
    cp: 3230,
    provincia_id: 7,
  },
  {
    id: 15813,
    localidad: "4TA SEC. E GRANDE",
    cp: 3412,
    provincia_id: 7,
  },
  {
    id: 15814,
    localidad: "2 DE JULIO",
    cp: 3344,
    provincia_id: 7,
  },
  {
    id: 15815,
    localidad: "ZAPOLOCO",
    cp: 5839,
    provincia_id: 6,
  },
  {
    id: 15816,
    localidad: "ZAPATA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 15817,
    localidad: "YOCSINA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 15818,
    localidad: "YERBA BUENA",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 15819,
    localidad: "YCHO CRUZ SIERRAS",
    cp: 4960,
    provincia_id: 6,
  },
  {
    id: 15820,
    localidad: "YCHO CRUZ SIERRAS",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 15821,
    localidad: "YCHO CRUZ SIERRAS",
    cp: 5161,
    provincia_id: 6,
  },
  {
    id: 15822,
    localidad: "YATAY",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 15823,
    localidad: "YANACATO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 15824,
    localidad: "YACANTO CALAMUCHITA",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 15825,
    localidad: "YACANTO",
    cp: 5877,
    provincia_id: 6,
  },
  {
    id: 15826,
    localidad: "Y CALAMUCHITA",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 15827,
    localidad: "WENCESLAO ESCALANTE",
    cp: 2655,
    provincia_id: 6,
  },
  {
    id: 15828,
    localidad: "WASHINGTON",
    cp: 6144,
    provincia_id: 6,
  },
  {
    id: 15829,
    localidad: "W. ESCALANTE",
    cp: 2655,
    provincia_id: 6,
  },
  {
    id: 15830,
    localidad: "VOLCAN ESTANZUELA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 15831,
    localidad: "VISTA ALEGRE",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 15832,
    localidad: "VISO",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 15833,
    localidad: "VILLA WARCARDE",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 15834,
    localidad: "VILLA VIEJA",
    cp: 2426,
    provincia_id: 6,
  },
  {
    id: 15835,
    localidad: "VILLA VAUDAGNA",
    cp: 2435,
    provincia_id: 6,
  },
  {
    id: 15836,
    localidad: "VILLA VALERIA",
    cp: 6373,
    provincia_id: 6,
  },
  {
    id: 15837,
    localidad: "VILLA VALERIA",
    cp: 6273,
    provincia_id: 6,
  },
  {
    id: 15838,
    localidad: "VILLA TOTORAL (CORDOBA)",
    cp: 5236,
    provincia_id: 6,
  },
  {
    id: 15839,
    localidad: "VILLA TANINGA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 15840,
    localidad: "VILLA SUIZA ARGENTINA",
    cp: 5156,
    provincia_id: 6,
  },
  {
    id: 15841,
    localidad: "VILLA SEGUNDA",
    cp: 5857,
    provincia_id: 6,
  },
  {
    id: 15842,
    localidad: "VILLA SATITE",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 15843,
    localidad: "VILLA SARMIENTO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 15844,
    localidad: "VILLA SARMIENTO",
    cp: 6273,
    provincia_id: 6,
  },
  {
    id: 15845,
    localidad: "VILLA SANTA ROSA",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 15846,
    localidad: "VILLA SANTA MARIA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 15847,
    localidad: "VILLA SANTA CRUZ DEL LAGO",
    cp: 5152,
    provincia_id: 6,
  },
  {
    id: 15848,
    localidad: "VILLA SAN JAVIER",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 15849,
    localidad: "VILLA SAN ESTEBAN",
    cp: 5947,
    provincia_id: 6,
  },
  {
    id: 15850,
    localidad: "VILLA RUMIPAL",
    cp: 5864,
    provincia_id: 6,
  },
  {
    id: 15851,
    localidad: "VILLA ROSSI",
    cp: 6072,
    provincia_id: 6,
  },
  {
    id: 15852,
    localidad: "VILLA ROSSI",
    cp: 6126,
    provincia_id: 6,
  },
  {
    id: 15853,
    localidad: "VILLA RIO YCHO CRUZ",
    cp: 5159,
    provincia_id: 6,
  },
  {
    id: 15854,
    localidad: "VILLA REGULES",
    cp: 2630,
    provincia_id: 6,
  },
  {
    id: 15855,
    localidad: "VILLA QUILLINZO",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 15856,
    localidad: "VILLA QUILINO",
    cp: 5215,
    provincia_id: 6,
  },
  {
    id: 15857,
    localidad: "VILLA PARQUE SIQUIMAN",
    cp: 5152,
    provincia_id: 6,
  },
  {
    id: 15858,
    localidad: "VILLA NUEVA",
    cp: 5903,
    provincia_id: 6,
  },
  {
    id: 15859,
    localidad: "VILLA NATURALEZA",
    cp: 5864,
    provincia_id: 6,
  },
  {
    id: 15860,
    localidad: "VILLA MARIA DE RIO SECO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 15861,
    localidad: "VILLA MARIA",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 15862,
    localidad: "VILLA MARIA",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 15863,
    localidad: "VILLA MAR CHIQUITA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 15864,
    localidad: "VILLA LUISA",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 15865,
    localidad: "VILLA LOS AROMOS",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 15866,
    localidad: "VILLA LAGO AZUL",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 15867,
    localidad: "VILLA LA COBA",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 15868,
    localidad: "VILLA LA BOLSA",
    cp: 5170,
    provincia_id: 6,
  },
  {
    id: 15869,
    localidad: "VILLA HUIDOBRO",
    cp: 6275,
    provincia_id: 6,
  },
  {
    id: 15870,
    localidad: "VILLA HUIDOBRO",
    cp: 6175,
    provincia_id: 6,
  },
  {
    id: 15871,
    localidad: "VILLA GUTIERREZ",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 15872,
    localidad: "VILLA GIARDINO",
    cp: 5176,
    provincia_id: 6,
  },
  {
    id: 15873,
    localidad: "VILLA GENERAL MITRE",
    cp: 5236,
    provincia_id: 6,
  },
  {
    id: 15874,
    localidad: "VILLA GENERAL BELGRANO",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 15875,
    localidad: "VILLA FREDICKSON",
    cp: 2630,
    provincia_id: 6,
  },
  {
    id: 15876,
    localidad: "VILLA FONTANA",
    cp: 5134,
    provincia_id: 6,
  },
  {
    id: 15877,
    localidad: "VILLA FONTANA",
    cp: 4884,
    provincia_id: 6,
  },
  {
    id: 15878,
    localidad: "VILLA FLOR SERRANA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 15879,
    localidad: "VILLA ESQUIU",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 15880,
    localidad: "VILLA EMILIA",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 15881,
    localidad: "VILLA ELENA",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 15882,
    localidad: "VILLA EL TORREON",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 15883,
    localidad: "VILLA EL DESCANSO",
    cp: 5169,
    provincia_id: 6,
  },
  {
    id: 15884,
    localidad: "VILLA EL CORCOVADO",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 15885,
    localidad: "VILLA EL CHACAY",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 15886,
    localidad: "VILLA DOLORES",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 15887,
    localidad: "VILLA DEL TRANSITO",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 15888,
    localidad: "VILLA DEL TOTORAL",
    cp: 5236,
    provincia_id: 6,
  },
  {
    id: 15889,
    localidad: "VILLA DEL TALA",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 15890,
    localidad: "VILLA DEL ROSARIO",
    cp: 5963,
    provincia_id: 6,
  },
  {
    id: 15891,
    localidad: "VILLA DEL PRADO",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 15892,
    localidad: "VILLA DEL PARQUE",
    cp: 5903,
    provincia_id: 6,
  },
  {
    id: 15893,
    localidad: "VILLA DEL PARQUE",
    cp: 5864,
    provincia_id: 6,
  },
  {
    id: 15894,
    localidad: "VILLA DEL DIQUE",
    cp: 5862,
    provincia_id: 6,
  },
  {
    id: 15895,
    localidad: "VILLA DE SOTO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 15896,
    localidad: "VILLA DE MARIA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 15897,
    localidad: "VILLA DE LAS ROSAS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 15898,
    localidad: "VILLA CURA BROCHERO",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 15899,
    localidad: "VILLA COSTA AZUL",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 15900,
    localidad: "VILLA CONCEPCION DEL TIO",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 15901,
    localidad: "VILLA COLIMBA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 15902,
    localidad: "VILLA CLODOMIRA",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 15903,
    localidad: "VILLA CIUDAD DE AMERICA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 15904,
    localidad: "VILLA CERRO NEGRO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 15905,
    localidad: "VILLA CERRO AZUL",
    cp: 5106,
    provincia_id: 6,
  },
  {
    id: 15906,
    localidad: "VILLA CARLOS PAZ",
    cp: 5152,
    provincia_id: 6,
  },
  {
    id: 15907,
    localidad: "VILLA CARLOS PAZ",
    cp: 5154,
    provincia_id: 6,
  },
  {
    id: 15908,
    localidad: "VILLA CAEIRO",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 15909,
    localidad: "VILLA BUSTOS",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 15910,
    localidad: "VILLA AURORA",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 15911,
    localidad: "VILLA ASCASUBI",
    cp: 5935,
    provincia_id: 6,
  },
  {
    id: 15912,
    localidad: "VILLA ANISACATE",
    cp: 5167,
    provincia_id: 6,
  },
  {
    id: 15913,
    localidad: "VILLA AMANCAY",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 15914,
    localidad: "VILLA ALPINA",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 15915,
    localidad: "VILLA ALLENDE",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 15916,
    localidad: "VILLA ALLENDE",
    cp: 5105,
    provincia_id: 6,
  },
  {
    id: 15917,
    localidad: "VILLA ALBERTINA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 15918,
    localidad: "VILLA AHORA",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 15919,
    localidad: "VICUÑA MACKENNA",
    cp: 6140,
    provincia_id: 6,
  },
  {
    id: 15920,
    localidad: "VIAMONTE",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 15921,
    localidad: "VIAMONTE",
    cp: 2671,
    provincia_id: 6,
  },
  {
    id: 15922,
    localidad: "VANGUARDIA",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 15923,
    localidad: "VALLE HERMOSO",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 15924,
    localidad: "VALLE DORADO",
    cp: 5864,
    provincia_id: 6,
  },
  {
    id: 15925,
    localidad: "VACAS BLANCAS",
    cp: 5143,
    provincia_id: 6,
  },
  {
    id: 15926,
    localidad: "V SUIZA ARGENTINA",
    cp: 5156,
    provincia_id: 6,
  },
  {
    id: 15927,
    localidad: "V S. CRUZ DEL LAGO",
    cp: 5147,
    provincia_id: 6,
  },
  {
    id: 15928,
    localidad: "V RIO YCHO CRUZ",
    cp: 4959,
    provincia_id: 6,
  },
  {
    id: 15929,
    localidad: "V PARQUE SIQUIMAN",
    cp: 5152,
    provincia_id: 6,
  },
  {
    id: 15930,
    localidad: "V LA RANCHERITA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 15931,
    localidad: "V GENERAL BELGRANO",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 15932,
    localidad: "V DIVISA DE MAYO",
    cp: 2631,
    provincia_id: 6,
  },
  {
    id: 15933,
    localidad: "V CURA BROCHERO",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 15934,
    localidad: "V CUESTA BLANCA",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 15935,
    localidad: "V C PELLEGRINI",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 15936,
    localidad: "V C DEL TIO",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 15937,
    localidad: "V C DE AMERICA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 15938,
    localidad: "USPARA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 15939,
    localidad: "URITORCO",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 15940,
    localidad: "UNQUILLO",
    cp: 5109,
    provincia_id: 6,
  },
  {
    id: 15941,
    localidad: "UCACHA",
    cp: 2677,
    provincia_id: 6,
  },
  {
    id: 15942,
    localidad: "U NUCLEAR EMBALSE",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 15943,
    localidad: "TUSCAL",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 15944,
    localidad: "TULUMBA",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 15945,
    localidad: "TUCLAME",
    cp: 5286,
    provincia_id: 6,
  },
  {
    id: 15946,
    localidad: "TRINCHERA",
    cp: 5940,
    provincia_id: 6,
  },
  {
    id: 15947,
    localidad: "TRES LOMAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 15948,
    localidad: "TRES ESQUINAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 15949,
    localidad: "TRES CHAQARES",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 15950,
    localidad: "TRES ARBOLES",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 15951,
    localidad: "TRANSITO",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 15952,
    localidad: "TRAMPA DEL TIGRE",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 15953,
    localidad: "TOTRILLA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 15954,
    localidad: "TOTORITAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 15955,
    localidad: "TOTORALEJOS",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 15956,
    localidad: "TOTORAL",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 15957,
    localidad: "TOTORA GUASI",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 15958,
    localidad: "TOSQUITA",
    cp: 6141,
    provincia_id: 6,
  },
  {
    id: 15959,
    localidad: "TOSNO",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 15960,
    localidad: "TORO MUERTO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 15961,
    localidad: "TORO MUERTO",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 15962,
    localidad: "TORDILLA NORTE",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 15963,
    localidad: "TOLEDO",
    cp: 5123,
    provincia_id: 6,
  },
  {
    id: 15964,
    localidad: "TODOS LOS SANTOS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 15965,
    localidad: "TIROLESA",
    cp: 5104,
    provincia_id: 6,
  },
  {
    id: 15966,
    localidad: "TIO PUJIO",
    cp: 5990,
    provincia_id: 6,
  },
  {
    id: 15967,
    localidad: "TIO PUJIO",
    cp: 5936,
    provincia_id: 6,
  },
  {
    id: 15968,
    localidad: "TINTIZACO",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 15969,
    localidad: "TILQUICHO",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 15970,
    localidad: "TIGRE MUERTO",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 15971,
    localidad: "TICINO",
    cp: 5927,
    provincia_id: 6,
  },
  {
    id: 15972,
    localidad: "TICINO",
    cp: 5966,
    provincia_id: 6,
  },
  {
    id: 15973,
    localidad: "TERCERA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 15974,
    localidad: "TEGUA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 15975,
    localidad: "TASMA",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 15976,
    localidad: "TASACUNA",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 15977,
    localidad: "TARUCA PAMPA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 15978,
    localidad: "TANTI NUEVO",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 15979,
    localidad: "TANTI LOMAS",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 15980,
    localidad: "TANTI",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 15981,
    localidad: "TANCACHA",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 15982,
    localidad: "TAMBO NUEVO",
    cp: 2700,
    provincia_id: 6,
  },
  {
    id: 15983,
    localidad: "TALITA",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 15984,
    localidad: "TALAINI",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 15985,
    localidad: "TALA SUD",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 15986,
    localidad: "TALA NORTE",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 15987,
    localidad: "TALA HUASI",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 15988,
    localidad: "TALA DEL RIO SECO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 15989,
    localidad: "TALA CAQADA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 15990,
    localidad: "TALA CAÑADA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 15991,
    localidad: "TAJAMARES",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 15992,
    localidad: "TAJAMARES",
    cp: 5233,
    provincia_id: 6,
  },
  {
    id: 15993,
    localidad: "TABANILLO",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 15994,
    localidad: "SUNCHO HUICO",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 15995,
    localidad: "SUNCHAL",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 15996,
    localidad: "SUCO",
    cp: 5837,
    provincia_id: 6,
  },
  {
    id: 15997,
    localidad: "SORIA",
    cp: 5829,
    provincia_id: 6,
  },
  {
    id: 15998,
    localidad: "SOLEDAD",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 15999,
    localidad: "SOLAR LOS MOLINOS",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16000,
    localidad: "SOCORRO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16001,
    localidad: "SOCONCHO",
    cp: 5191,
    provincia_id: 6,
  },
  {
    id: 16002,
    localidad: "SINSACATE",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16003,
    localidad: "SIMBOLAR",
    cp: 5242,
    provincia_id: 6,
  },
  {
    id: 16004,
    localidad: "SIMBOLAR",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16005,
    localidad: "SIERRAS MORENAS",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16006,
    localidad: "SIERRA DE ABREGU",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16007,
    localidad: "SIERRA BLANCA",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16008,
    localidad: "SEVILLA",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 16009,
    localidad: "SERREZUELA",
    cp: 5270,
    provincia_id: 6,
  },
  {
    id: 16010,
    localidad: "SERRANO",
    cp: 6125,
    provincia_id: 6,
  },
  {
    id: 16011,
    localidad: "SENDAS GRANDES",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16012,
    localidad: "SEEBER",
    cp: 2419,
    provincia_id: 6,
  },
  {
    id: 16013,
    localidad: "SEEBER",
    cp: 2418,
    provincia_id: 6,
  },
  {
    id: 16014,
    localidad: "SAUCE PUNCO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16015,
    localidad: "SAUCE CHIQUITO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16016,
    localidad: "SAUCE ARRIBA",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 16017,
    localidad: "SATUR M LASPIUR",
    cp: 5943,
    provincia_id: 6,
  },
  {
    id: 16018,
    localidad: "SARMIENTO",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16019,
    localidad: "SARMICA",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16020,
    localidad: "SARLACO",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 16021,
    localidad: "SAPANSOTO",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16022,
    localidad: "SANTO DOMINGO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16023,
    localidad: "SANTO DOMINGO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16024,
    localidad: "SANTIAGO TEMPLE (CORDOBA)",
    cp: 5124,
    provincia_id: 6,
  },
  {
    id: 16025,
    localidad: "SANTIAGO TEMPLE",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16026,
    localidad: "SANTIAGO TEMPLE",
    cp: 5124,
    provincia_id: 6,
  },
  {
    id: 16027,
    localidad: "SANTANILLA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16028,
    localidad: "SANTA VICTORIA",
    cp: 2675,
    provincia_id: 6,
  },
  {
    id: 16029,
    localidad: "SANTA TERESA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16030,
    localidad: "SANTA SABINA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16031,
    localidad: "SANTA ROSA DE RIO PRIMERO",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16032,
    localidad: "SANTA ROSA DE CALAMUCHITA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 16033,
    localidad: "SANTA ROSA",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 16034,
    localidad: "SANTA ROSA",
    cp: 4800,
    provincia_id: 6,
  },
  {
    id: 16035,
    localidad: "SANTA ROSA",
    cp: 5913,
    provincia_id: 6,
  },
  {
    id: 16036,
    localidad: "SANTA ROSA",
    cp: 5903,
    provincia_id: 6,
  },
  {
    id: 16037,
    localidad: "SANTA ROSA",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16038,
    localidad: "SANTA RITA",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16039,
    localidad: "SANTA RITA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16040,
    localidad: "SANTA RITA",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16041,
    localidad: "SANTA RITA",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 16042,
    localidad: "SANTA MONICA",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 16043,
    localidad: "SANTA MARIA DE PUNILLA",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 16044,
    localidad: "SANTA MARIA",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16045,
    localidad: "SANTA MARIA",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 16046,
    localidad: "SANTA MAGDALENA",
    cp: 6127,
    provincia_id: 6,
  },
  {
    id: 16047,
    localidad: "SANTA LUCINDA",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 16048,
    localidad: "SANTA LUCIA",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16049,
    localidad: "SANTA ISABEL",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16050,
    localidad: "SANTA GRACIA",
    cp: 6212,
    provincia_id: 6,
  },
  {
    id: 16051,
    localidad: "SANTA EUFEMIA",
    cp: 2671,
    provincia_id: 6,
  },
  {
    id: 16052,
    localidad: "SANTA ELENA",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16053,
    localidad: "SANTA CRUZ",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16054,
    localidad: "SANTA CATALINA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16055,
    localidad: "SANTA CATALINA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16056,
    localidad: "SANTA CATALINA",
    cp: 5825,
    provincia_id: 6,
  },
  {
    id: 16057,
    localidad: "SANTA ANA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16058,
    localidad: "SANTA ANA",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16059,
    localidad: "SANTA ANA",
    cp: 5112,
    provincia_id: 6,
  },
  {
    id: 16060,
    localidad: "SANATORIO SANTA MARIA",
    cp: 5165,
    provincia_id: 6,
  },
  {
    id: 16061,
    localidad: "SANABRIA",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 16062,
    localidad: "SAN VICENTE",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16063,
    localidad: "SAN VICENTE",
    cp: 2550,
    provincia_id: 6,
  },
  {
    id: 16064,
    localidad: "SAN VICENTE",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16065,
    localidad: "SAN SEBASTIAN",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16066,
    localidad: "SAN SALVADOR",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16067,
    localidad: "SAN SALVADOR",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16068,
    localidad: "SAN ROQUE",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16069,
    localidad: "SAN ROQUE",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 16070,
    localidad: "SAN ROQUE",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16071,
    localidad: "SAN ROQUE",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 16072,
    localidad: "SAN RAMON",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16073,
    localidad: "SAN RAFAEL",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 16074,
    localidad: "SAN RAFAEL",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16075,
    localidad: "SAN PELLEGRINO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16076,
    localidad: "SAN PEDRO NORTE",
    cp: 6176,
    provincia_id: 6,
  },
  {
    id: 16077,
    localidad: "SAN PEDRO DE TOYOS",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16078,
    localidad: "SAN PEDRO",
    cp: 2430,
    provincia_id: 6,
  },
  {
    id: 16079,
    localidad: "SAN PEDRO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16080,
    localidad: "SAN PEDRO",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16081,
    localidad: "SAN PABLO",
    cp: 6204,
    provincia_id: 6,
  },
  {
    id: 16082,
    localidad: "SAN NICOLAS",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16083,
    localidad: "SAN NICOLAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16084,
    localidad: "SAN MIGUEL",
    cp: 5117,
    provincia_id: 6,
  },
  {
    id: 16085,
    localidad: "SAN MELITON",
    cp: 2664,
    provincia_id: 6,
  },
  {
    id: 16086,
    localidad: "SAN MARTIN",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16087,
    localidad: "SAN MARCOS SUD",
    cp: 2566,
    provincia_id: 6,
  },
  {
    id: 16088,
    localidad: "SAN MARCOS SIERRA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16089,
    localidad: "SAN MARCELO",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16090,
    localidad: "SAN MARCELO",
    cp: 6009,
    provincia_id: 6,
  },
  {
    id: 16091,
    localidad: "SAN LUCAS NORTE",
    cp: 5837,
    provincia_id: 6,
  },
  {
    id: 16092,
    localidad: "SAN LORENZO",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16093,
    localidad: "SAN LORENZO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16094,
    localidad: "SAN JUAN SIMSON",
    cp: 6212,
    provincia_id: 6,
  },
  {
    id: 16095,
    localidad: "SAN JOSE DEL SALTEÑO",
    cp: 2563,
    provincia_id: 6,
  },
  {
    id: 16096,
    localidad: "SAN JOSE DE LAS SALINAS",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16097,
    localidad: "SAN JOSE DE LA DORMIDA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16098,
    localidad: "SAN JOSE",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16099,
    localidad: "SAN JOSE",
    cp: 2563,
    provincia_id: 6,
  },
  {
    id: 16100,
    localidad: "SAN JOSE",
    cp: 5242,
    provincia_id: 6,
  },
  {
    id: 16101,
    localidad: "SAN JOSE",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 16102,
    localidad: "SAN JOSE",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16103,
    localidad: "SAN JOSE",
    cp: 5865,
    provincia_id: 6,
  },
  {
    id: 16104,
    localidad: "SAN JORGE",
    cp: 5117,
    provincia_id: 6,
  },
  {
    id: 16105,
    localidad: "SAN JOAQUIN",
    cp: 6123,
    provincia_id: 6,
  },
  {
    id: 16106,
    localidad: "SAN JAVIER",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16107,
    localidad: "SAN ISIDRO-CBA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16108,
    localidad: "SAN IGNACIO",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 16109,
    localidad: "SAN IGNACIO",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 16110,
    localidad: "SAN IGANCIO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16111,
    localidad: "SAN HILARIO",
    cp: 6212,
    provincia_id: 6,
  },
  {
    id: 16112,
    localidad: "SAN GUILLERMO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16113,
    localidad: "SAN GERONIMO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16114,
    localidad: "SAN GABRIEL",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16115,
    localidad: "SAN FRANCISCO",
    cp: 2400,
    provincia_id: 6,
  },
  {
    id: 16116,
    localidad: "SAN EUSEBIO",
    cp: 2561,
    provincia_id: 6,
  },
  {
    id: 16117,
    localidad: "SAN ESTEBAN",
    cp: 5075,
    provincia_id: 6,
  },
  {
    id: 16118,
    localidad: "SAN ESTEBAN",
    cp: 5188,
    provincia_id: 6,
  },
  {
    id: 16119,
    localidad: "SAN CRISTOBAL",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 16120,
    localidad: "SAN CLEMENTE",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16121,
    localidad: "SAN CARLOS MINAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16122,
    localidad: "SAN CARLOS",
    cp: 2572,
    provincia_id: 6,
  },
  {
    id: 16123,
    localidad: "SAN BUENAVENTURA",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 16124,
    localidad: "SAN BERNARDO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16125,
    localidad: "SAN BERNARDO",
    cp: 5848,
    provincia_id: 6,
  },
  {
    id: 16126,
    localidad: "SAN BASILIO",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16127,
    localidad: "SAN BASILIO",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 16128,
    localidad: "SAN ANTONIO VIEJO",
    cp: 2560,
    provincia_id: 6,
  },
  {
    id: 16129,
    localidad: "SAN ANTONIO NORTE",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 16130,
    localidad: "SAN ANTONIO DE YUCAT",
    cp: 5936,
    provincia_id: 6,
  },
  {
    id: 16131,
    localidad: "SAN ANTONIO DE LITIN",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16132,
    localidad: "SAN ANTONIO DE LITIN",
    cp: 2560,
    provincia_id: 6,
  },
  {
    id: 16133,
    localidad: "SAN ANTONIO DE ARREDONDO",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 16134,
    localidad: "SAN ANTONIO",
    cp: 5121,
    provincia_id: 6,
  },
  {
    id: 16135,
    localidad: "SAN ANTONIO",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16136,
    localidad: "SAN ANTONIO",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16137,
    localidad: "SAN ANOTNIO",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16138,
    localidad: "SAN AMBROSIO",
    cp: 5848,
    provincia_id: 6,
  },
  {
    id: 16139,
    localidad: "SAN AGUSTIN",
    cp: 5191,
    provincia_id: 6,
  },
  {
    id: 16140,
    localidad: "SAN AGUSTIN",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16141,
    localidad: "SAMPACHO",
    cp: 5829,
    provincia_id: 6,
  },
  {
    id: 16142,
    localidad: "SALTO",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16143,
    localidad: "SALSIPUEDES",
    cp: 5113,
    provincia_id: 6,
  },
  {
    id: 16144,
    localidad: "SALSACATE",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16145,
    localidad: "SALDAN",
    cp: 5150,
    provincia_id: 6,
  },
  {
    id: 16146,
    localidad: "SALDAN",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 16147,
    localidad: "SALDAN",
    cp: 4931,
    provincia_id: 6,
  },
  {
    id: 16148,
    localidad: "SALADILLO",
    cp: 2588,
    provincia_id: 6,
  },
  {
    id: 16149,
    localidad: "SAJON",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16150,
    localidad: "SAIRA",
    cp: 2525,
    provincia_id: 6,
  },
  {
    id: 16151,
    localidad: "SAGRADA FAMILIA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16152,
    localidad: "SAGRADA FAMILIA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16153,
    localidad: "SACANTA",
    cp: 5945,
    provincia_id: 6,
  },
  {
    id: 16154,
    localidad: "S. M DE PUNILLA",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 16155,
    localidad: "S. DE YCHO CRUZ",
    cp: 4952,
    provincia_id: 6,
  },
  {
    id: 16156,
    localidad: "S ROSA HUERTA GDE",
    cp: 5174,
    provincia_id: 6,
  },
  {
    id: 16157,
    localidad: "S ROSA DE RIO 1:",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16158,
    localidad: "S R DE CALAMUCHITA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 16159,
    localidad: "S P. DE S ALBERTO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16160,
    localidad: "S NICOLAS PUNILLA",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16161,
    localidad: "S MIGUEL S VICENTE",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16162,
    localidad: "S MIGUEL  CHANCANI",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16163,
    localidad: "S JOSE D L SALINAS",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16164,
    localidad: "S JOSE D L DORMIDA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16165,
    localidad: "S DE LOS QUEVEDOS",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16166,
    localidad: "S DE LAS PAREDES",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16167,
    localidad: "S ANTONIO DE YUCAT",
    cp: 5936,
    provincia_id: 6,
  },
  {
    id: 16168,
    localidad: "S ANT DE ARREDONDO",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 16169,
    localidad: "RUMIGUASI",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16170,
    localidad: "RUMIACO",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 16171,
    localidad: "ROSSI",
    cp: 6128,
    provincia_id: 6,
  },
  {
    id: 16172,
    localidad: "ROJAS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16173,
    localidad: "RODEO VIEJO",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 16174,
    localidad: "RODEO LAS YEGUAS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16175,
    localidad: "RODEO DE PIEDRA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16176,
    localidad: "RODEITO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16177,
    localidad: "RIO VIEJO",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16178,
    localidad: "RIO TERCERO",
    cp: 5850,
    provincia_id: 6,
  },
  {
    id: 16179,
    localidad: "RIO SEGUNDO",
    cp: 5960,
    provincia_id: 6,
  },
  {
    id: 16180,
    localidad: "RIO SECO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16181,
    localidad: "RIO SECO",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 16182,
    localidad: "RIO SAN MIGUEL",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16183,
    localidad: "RIO PRIMERO",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 16184,
    localidad: "RIO PINTO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16185,
    localidad: "RIO PEDRO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16186,
    localidad: "RIO LOS MOLINOS",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16187,
    localidad: "RIO HONDO",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16188,
    localidad: "RIO HONDO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16189,
    localidad: "RIO GRANDE  AMBOY",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 16190,
    localidad: "RIO GRANDE",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16191,
    localidad: "RIO DULCE",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16192,
    localidad: "RIO DEL DURAZNO",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 16193,
    localidad: "RIO DE LOS TALAS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16194,
    localidad: "RIO DE LOS SAUCES",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16195,
    localidad: "RIO DE LOS SAUCES",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16196,
    localidad: "RIO DE JAIME",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16197,
    localidad: "RIO CUARTO",
    cp: 5800,
    provincia_id: 6,
  },
  {
    id: 16198,
    localidad: "RIO CUARTO",
    cp: 5804,
    provincia_id: 6,
  },
  {
    id: 16199,
    localidad: "RIO CUARTO",
    cp: 5808,
    provincia_id: 6,
  },
  {
    id: 16200,
    localidad: "RIO CUARTO",
    cp: 5806,
    provincia_id: 6,
  },
  {
    id: 16201,
    localidad: "RIO CUARTO",
    cp: 5802,
    provincia_id: 6,
  },
  {
    id: 16202,
    localidad: "RIO CEBALLOS",
    cp: 5111,
    provincia_id: 6,
  },
  {
    id: 16203,
    localidad: "RIO BAMBA",
    cp: 6134,
    provincia_id: 6,
  },
  {
    id: 16204,
    localidad: "RIO ARRIBA",
    cp: 5887,
    provincia_id: 6,
  },
  {
    id: 16205,
    localidad: "RINCON DEL ESTE",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 16206,
    localidad: "RINCON DE LUNA",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 16207,
    localidad: "RINCON",
    cp: 5961,
    provincia_id: 6,
  },
  {
    id: 16208,
    localidad: "REPRESA DE MORALES",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16209,
    localidad: "REDUCCION",
    cp: 5803,
    provincia_id: 6,
  },
  {
    id: 16210,
    localidad: "RECREO VICTORIA",
    cp: 5144,
    provincia_id: 6,
  },
  {
    id: 16211,
    localidad: "RAYO CORTADO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16212,
    localidad: "RARA FORTUNA",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 16213,
    localidad: "RANQUELES",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 16214,
    localidad: "RANGEL",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 16215,
    localidad: "RAMON J CARCANO",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16216,
    localidad: "RAMIREZ",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 16217,
    localidad: "RAMBLON",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16218,
    localidad: "RAMALLO",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16219,
    localidad: "RAFAEL GARCIA",
    cp: 4752,
    provincia_id: 6,
  },
  {
    id: 16220,
    localidad: "RABIOLA",
    cp: 2600,
    provincia_id: 6,
  },
  {
    id: 16221,
    localidad: "R.INDARTE",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 16222,
    localidad: "R DE LOS CABALLOS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16223,
    localidad: "QUISCASACATE",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16224,
    localidad: "QUILINO",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16225,
    localidad: "QUEBRADA DEL HORNO",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16226,
    localidad: "QUEBRADA DE NONA",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16227,
    localidad: "QUEBRADA DE LUNA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16228,
    localidad: "QUEBRACHOS",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 16229,
    localidad: "QUEBRACHO SOLO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16230,
    localidad: "QUEBRACHO HERRADO",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 16231,
    localidad: "QUEBRACHO HERRADO",
    cp: 2422,
    provincia_id: 6,
  },
  {
    id: 16232,
    localidad: "QUEBRACHITOS",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 16233,
    localidad: "PZO DE LOS ARBOLES",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16234,
    localidad: "PZO D LOS TRONCOS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16235,
    localidad: "PUSISUNA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16236,
    localidad: "PUNTA DEL NEGRO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16237,
    localidad: "PUNTA DEL MONTE",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16238,
    localidad: "PUNTA DEL AGUA",
    cp: 5931,
    provincia_id: 6,
  },
  {
    id: 16239,
    localidad: "PUNTA DEL AGUA",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16240,
    localidad: "PUNTA DEL AGUA",
    cp: 5839,
    provincia_id: 6,
  },
  {
    id: 16241,
    localidad: "PUNTA DE AGUA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16242,
    localidad: "PUNILLA",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16243,
    localidad: "PUESTO VIEJO",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16244,
    localidad: "PUESTO SAN JOSE",
    cp: 5242,
    provincia_id: 6,
  },
  {
    id: 16245,
    localidad: "PUESTO MULITA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16246,
    localidad: "PUESTO GUZMAN",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 16247,
    localidad: "PUESTO DEL MEDIO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16248,
    localidad: "PUESTO DEL GALLO",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16249,
    localidad: "PUESTO DE VERA",
    cp: 5271,
    provincia_id: 6,
  },
  {
    id: 16250,
    localidad: "PUESTO DE PUCHETA",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16251,
    localidad: "PUESTO DE LUNA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16252,
    localidad: "PUESTO DE FIERRO",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16253,
    localidad: "PUESTO DE CERRO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16254,
    localidad: "PUESTO DE BATALLA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16255,
    localidad: "PUESTO DE ARRIBA",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16256,
    localidad: "PUESTO DE AFUERA",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 16257,
    localidad: "PUERTA DE LOS RIOS",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16258,
    localidad: "PUERTA DE LA Q",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16259,
    localidad: "PUERTA COLORADA",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 16260,
    localidad: "PUEBLO VIEJO",
    cp: 2555,
    provincia_id: 6,
  },
  {
    id: 16261,
    localidad: "PUEBLO SARMIENTO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16262,
    localidad: "PUEBLO RIO TERCERO",
    cp: 2581,
    provincia_id: 6,
  },
  {
    id: 16263,
    localidad: "PUEBLO PIANELLI",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 16264,
    localidad: "PUEBLO ITALIANO",
    cp: 2653,
    provincia_id: 6,
  },
  {
    id: 16265,
    localidad: "PUEBLO GAMBANDE",
    cp: 2627,
    provincia_id: 6,
  },
  {
    id: 16266,
    localidad: "PUEBLO ARGENTINO",
    cp: 2580,
    provincia_id: 6,
  },
  {
    id: 16267,
    localidad: "PROVIDENCIA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16268,
    localidad: "PRIMAVERA",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 16269,
    localidad: "POZO SOLO",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16270,
    localidad: "POZO SECO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16271,
    localidad: "POZO NUEVO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16272,
    localidad: "POZO LA PIEDRA",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 16273,
    localidad: "POZO ESCONDIDO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16274,
    localidad: "POZO DEL SIMBOL",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16275,
    localidad: "POZO DEL MORO",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16276,
    localidad: "POZO DEL MOLLE",
    cp: 5913,
    provincia_id: 6,
  },
  {
    id: 16277,
    localidad: "POZO DEL MOLLE",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16278,
    localidad: "POZO DEL MEDIO",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 16279,
    localidad: "POZO DEL CHAQAR",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16280,
    localidad: "POZO DEL CHAJA",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 16281,
    localidad: "POZO DEL AVESTRUZ",
    cp: 5947,
    provincia_id: 6,
  },
  {
    id: 16282,
    localidad: "POZO DE LA PAMPA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16283,
    localidad: "POZO DE LA LOMA",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16284,
    localidad: "POZO DE LA ESQUINA",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16285,
    localidad: "POZO DE JUANCHO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16286,
    localidad: "POZO DE AVILA",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16287,
    localidad: "POZO CORREA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16288,
    localidad: "POZO CONCA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16289,
    localidad: "POTRERO DE TUTZER",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16290,
    localidad: "POTRERO DE MARQUES",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16291,
    localidad: "POTRERO DE LUJAN",
    cp: 5191,
    provincia_id: 6,
  },
  {
    id: 16292,
    localidad: "POTRERO DE GARAY",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16293,
    localidad: "POTRERO DE FUNES",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16294,
    localidad: "PORVENIR",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16295,
    localidad: "POCITO DEL CAMPO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16296,
    localidad: "POCHO",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16297,
    localidad: "POCHO",
    cp: 5294,
    provincia_id: 6,
  },
  {
    id: 16298,
    localidad: "PLUJUNTA",
    cp: 5141,
    provincia_id: 6,
  },
  {
    id: 16299,
    localidad: "PLAZA S FRANCISCO",
    cp: 2401,
    provincia_id: 6,
  },
  {
    id: 16300,
    localidad: "PLAZA DE MERCEDES",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16301,
    localidad: "PLAZA BRUNO",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 16302,
    localidad: "PLAYA GRANDE",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 16303,
    localidad: "PLACILLA",
    cp: 6277,
    provincia_id: 6,
  },
  {
    id: 16304,
    localidad: "PITOA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16305,
    localidad: "PISCO HUASI",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16306,
    localidad: "PIQUILLINES",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16307,
    localidad: "PIQUILLIN",
    cp: 5130,
    provincia_id: 6,
  },
  {
    id: 16308,
    localidad: "PIQUILLIN",
    cp: 4788,
    provincia_id: 6,
  },
  {
    id: 16309,
    localidad: "PINCEN",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 16310,
    localidad: "PINCEN",
    cp: 6274,
    provincia_id: 6,
  },
  {
    id: 16311,
    localidad: "PILAR",
    cp: 5972,
    provincia_id: 6,
  },
  {
    id: 16312,
    localidad: "PIEDRITAS ROSADAS",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16313,
    localidad: "PIEDRAS GRANDES",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16314,
    localidad: "PIEDRAS BLANCAS",
    cp: 5174,
    provincia_id: 6,
  },
  {
    id: 16315,
    localidad: "PIEDRAS ANCHAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16316,
    localidad: "PIEDRAS ANCHAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16317,
    localidad: "PIEDRA PINTADA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16318,
    localidad: "PIEDRA PINTADA",
    cp: 5734,
    provincia_id: 6,
  },
  {
    id: 16319,
    localidad: "PIEDRA MOVEDIZA",
    cp: 5174,
    provincia_id: 6,
  },
  {
    id: 16320,
    localidad: "PIEDRA GRANDE",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16321,
    localidad: "PIEDRA BLANCA",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16322,
    localidad: "PIEDRA BLANCA",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 16323,
    localidad: "PIEDRA BLANCA",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 16324,
    localidad: "PICHANAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16325,
    localidad: "PERMANENTES",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16326,
    localidad: "PEDRO E VIVAS",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 16327,
    localidad: "PEDRO E FUNES",
    cp: 2671,
    provincia_id: 6,
  },
  {
    id: 16328,
    localidad: "PDRAS AMONTONADAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16329,
    localidad: "PASO VIEJO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16330,
    localidad: "PASO VIEJO",
    cp: 4525,
    provincia_id: 6,
  },
  {
    id: 16331,
    localidad: "PASO SANDIALITO",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16332,
    localidad: "PASO GRANDE",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16333,
    localidad: "PASO DEL SILVERIO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16334,
    localidad: "PASO DEL SAUCE",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16335,
    localidad: "PASO DE MONTOYA",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16336,
    localidad: "PASO CABRAL",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 16337,
    localidad: "PASCO",
    cp: 5953,
    provincia_id: 6,
  },
  {
    id: 16338,
    localidad: "PASCO",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16339,
    localidad: "PASCANAS",
    cp: 2679,
    provincia_id: 6,
  },
  {
    id: 16340,
    localidad: "PARQUE SIQUIMAN",
    cp: 5158,
    provincia_id: 6,
  },
  {
    id: 16341,
    localidad: "PANAHOLMA",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16342,
    localidad: "PAMPAYASTA SUR",
    cp: 5975,
    provincia_id: 6,
  },
  {
    id: 16343,
    localidad: "PAMPAYASTA SUD",
    cp: 5931,
    provincia_id: 6,
  },
  {
    id: 16344,
    localidad: "PAMPAYASTA NORTE",
    cp: 5973,
    provincia_id: 6,
  },
  {
    id: 16345,
    localidad: "PAMPA DE OLAEN",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 16346,
    localidad: "PALOMA POZO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16347,
    localidad: "PALO QUEMADO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16348,
    localidad: "PALO PARADO",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16349,
    localidad: "PAJONAL",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16350,
    localidad: "PACHANGO",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16351,
    localidad: "P RIO PLUJUNTA",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 16352,
    localidad: "P DE S LORENZO",
    cp: 2200,
    provincia_id: 6,
  },
  {
    id: 16353,
    localidad: "P DE LOS RODRIGUEZ",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16354,
    localidad: "P DE LOS GALLEGOS",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 16355,
    localidad: "P DE LAS SIERRAS",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16356,
    localidad: "P CARLOS SAUVERAN",
    cp: 2581,
    provincia_id: 6,
  },
  {
    id: 16357,
    localidad: "OVERA NEGRA",
    cp: 5951,
    provincia_id: 6,
  },
  {
    id: 16358,
    localidad: "ORO GRUESO",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 16359,
    localidad: "ORDOÑEZ",
    cp: 2555,
    provincia_id: 6,
  },
  {
    id: 16360,
    localidad: "ORCOSUNI",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16361,
    localidad: "ONGAMIRA",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16362,
    localidad: "ONCATIVO",
    cp: 5986,
    provincia_id: 6,
  },
  {
    id: 16363,
    localidad: "ONAGOITY",
    cp: 6227,
    provincia_id: 6,
  },
  {
    id: 16364,
    localidad: "OLMOS",
    cp: 2684,
    provincia_id: 6,
  },
  {
    id: 16365,
    localidad: "OLIVA",
    cp: 5980,
    provincia_id: 6,
  },
  {
    id: 16366,
    localidad: "OLAETA",
    cp: 5824,
    provincia_id: 6,
  },
  {
    id: 16367,
    localidad: "OLAETA",
    cp: 5807,
    provincia_id: 6,
  },
  {
    id: 16368,
    localidad: "OJO DE AGUA",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 16369,
    localidad: "OBREGON",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16370,
    localidad: "OBISPO TREJO",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16371,
    localidad: "NUEVA ESPERANZA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16372,
    localidad: "NONO",
    cp: 5887,
    provincia_id: 6,
  },
  {
    id: 16373,
    localidad: "NOETINGER",
    cp: 2563,
    provincia_id: 6,
  },
  {
    id: 16374,
    localidad: "NIQA PAULA",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16375,
    localidad: "NINALQUIN",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16376,
    localidad: "NIDO DEL AGUILA",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16377,
    localidad: "NICOLAS BRUZONE",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 16378,
    localidad: "NICOLAS BRUZONE",
    cp: 6104,
    provincia_id: 6,
  },
  {
    id: 16379,
    localidad: "NICHO",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16380,
    localidad: "NAVARRO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16381,
    localidad: "MUSSI",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16382,
    localidad: "MTLES GRANDES",
    cp: 2700,
    provincia_id: 6,
  },
  {
    id: 16383,
    localidad: "MOVADO",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16384,
    localidad: "MORTEROS",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 16385,
    localidad: "MORRISON",
    cp: 2568,
    provincia_id: 6,
  },
  {
    id: 16386,
    localidad: "MONTE REDONDO",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 16387,
    localidad: "MONTE REDONDO",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16388,
    localidad: "MONTE RALO",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 16389,
    localidad: "MONTE RALO",
    cp: 5118,
    provincia_id: 6,
  },
  {
    id: 16390,
    localidad: "MONTE MAIZ",
    cp: 2659,
    provincia_id: 6,
  },
  {
    id: 16391,
    localidad: "MONTE LEÑA",
    cp: 2564,
    provincia_id: 6,
  },
  {
    id: 16392,
    localidad: "MONTE GDE R GARCIA",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 16393,
    localidad: "MONTE DEL FRAYLE",
    cp: 5931,
    provincia_id: 6,
  },
  {
    id: 16394,
    localidad: "MONTE DE LOS LAZOS",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16395,
    localidad: "MONTE DE LOS GAUCHOS",
    cp: 5832,
    provincia_id: 6,
  },
  {
    id: 16396,
    localidad: "MONTE DE LOS GAUCHOS",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16397,
    localidad: "MONTE DE LOS GAUCHOS",
    cp: 5643,
    provincia_id: 6,
  },
  {
    id: 16398,
    localidad: "MONTE CRISTO",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16399,
    localidad: "MONTE CASTILLO",
    cp: 2563,
    provincia_id: 6,
  },
  {
    id: 16400,
    localidad: "MONTE BUEY",
    cp: 2589,
    provincia_id: 6,
  },
  {
    id: 16401,
    localidad: "MONSALVO",
    cp: 5851,
    provincia_id: 6,
  },
  {
    id: 16402,
    localidad: "MOLINARI",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 16403,
    localidad: "MOGOTE VERDE",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16404,
    localidad: "MOGIGASTA",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 16405,
    localidad: "MODESTO ACUQA",
    cp: 5823,
    provincia_id: 6,
  },
  {
    id: 16406,
    localidad: "MIRAMAR",
    cp: 5143,
    provincia_id: 6,
  },
  {
    id: 16407,
    localidad: "MIRAFLORES",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16408,
    localidad: "MIQUILOS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16409,
    localidad: "MINA LA BISMUTINA",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16410,
    localidad: "MINA CLAVERO",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16411,
    localidad: "MINA ARAUJO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16412,
    localidad: "MIGUELITO",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16413,
    localidad: "MI GRANJA",
    cp: 5128,
    provincia_id: 6,
  },
  {
    id: 16414,
    localidad: "MESA DE MARIANO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16415,
    localidad: "MENDIOLAZA",
    cp: 5108,
    provincia_id: 6,
  },
  {
    id: 16416,
    localidad: "MENDIOLAZA",
    cp: 5107,
    provincia_id: 6,
  },
  {
    id: 16417,
    localidad: "MELO",
    cp: 6123,
    provincia_id: 6,
  },
  {
    id: 16418,
    localidad: "MEDIO NARANJA",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16419,
    localidad: "MEDANO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16420,
    localidad: "MEDANITOS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16421,
    localidad: "MAYU SUMAJ",
    cp: 5146,
    provincia_id: 6,
  },
  {
    id: 16422,
    localidad: "MAYU SUMAJ",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16423,
    localidad: "MAUNIER",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 16424,
    localidad: "MATTALDI",
    cp: 6272,
    provincia_id: 6,
  },
  {
    id: 16425,
    localidad: "MATTALDI",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 16426,
    localidad: "MATORRALES",
    cp: 5970,
    provincia_id: 6,
  },
  {
    id: 16427,
    localidad: "MATACOS",
    cp: 2659,
    provincia_id: 6,
  },
  {
    id: 16428,
    localidad: "MARIANA",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 16429,
    localidad: "MARIA",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16430,
    localidad: "MARCOS JUAREZ",
    cp: 2580,
    provincia_id: 6,
  },
  {
    id: 16431,
    localidad: "MAR AZUL",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 16432,
    localidad: "MAQUINISTA GALLINI",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16433,
    localidad: "MANGUITAS",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16434,
    localidad: "MANFREDI",
    cp: 5988,
    provincia_id: 6,
  },
  {
    id: 16435,
    localidad: "MANDALA",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16436,
    localidad: "MALVINAS ARGENTINAS",
    cp: 5126,
    provincia_id: 6,
  },
  {
    id: 16437,
    localidad: "MALVINAS ARGENTINAS",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16438,
    localidad: "MALVINAS ARGENTINAS",
    cp: 4784,
    provincia_id: 6,
  },
  {
    id: 16439,
    localidad: "MALLIN",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 16440,
    localidad: "MALENA",
    cp: 5838,
    provincia_id: 6,
  },
  {
    id: 16441,
    localidad: "MALAGUEÑO",
    cp: 4687,
    provincia_id: 6,
  },
  {
    id: 16442,
    localidad: "MALAGUEÑO",
    cp: 5110,
    provincia_id: 6,
  },
  {
    id: 16443,
    localidad: "MALAGUEÑO",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16444,
    localidad: "MAJADILLA",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 16445,
    localidad: "MAJADILLA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16446,
    localidad: "MAJADA DE SANTIAGO",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 16447,
    localidad: "MACHA",
    cp: 5211,
    provincia_id: 6,
  },
  {
    id: 16448,
    localidad: "M DE TORO PUJIO",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 16449,
    localidad: "LUYABA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16450,
    localidad: "LUXARDO",
    cp: 2411,
    provincia_id: 6,
  },
  {
    id: 16451,
    localidad: "LUTTI",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 16452,
    localidad: "LUQUE",
    cp: 5967,
    provincia_id: 6,
  },
  {
    id: 16453,
    localidad: "LUIS SAUZE",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 16454,
    localidad: "LUCIO V MANSILLA",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16455,
    localidad: "LUCA",
    cp: 5918,
    provincia_id: 6,
  },
  {
    id: 16456,
    localidad: "LUCA",
    cp: 5919,
    provincia_id: 6,
  },
  {
    id: 16457,
    localidad: "LOZADA",
    cp: 4686,
    provincia_id: 6,
  },
  {
    id: 16458,
    localidad: "LOZADA",
    cp: 5104,
    provincia_id: 6,
  },
  {
    id: 16459,
    localidad: "LOTE 15",
    cp: 6213,
    provincia_id: 6,
  },
  {
    id: 16460,
    localidad: "LOTE 11 E 107",
    cp: 6213,
    provincia_id: 6,
  },
  {
    id: 16461,
    localidad: "LOS ZORROS",
    cp: 5929,
    provincia_id: 6,
  },
  {
    id: 16462,
    localidad: "LOS UCLES",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16463,
    localidad: "LOS TRONCOS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16464,
    localidad: "LOS TRES POZOS",
    cp: 5851,
    provincia_id: 6,
  },
  {
    id: 16465,
    localidad: "LOS TERRONES",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16466,
    localidad: "LOS TASIS",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16467,
    localidad: "LOS TARTAGOS",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16468,
    localidad: "LOS SURGENTES",
    cp: 2581,
    provincia_id: 6,
  },
  {
    id: 16469,
    localidad: "LOS SOCABONES",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16470,
    localidad: "LOS SAUCES",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16471,
    localidad: "LOS SARMIENTOS",
    cp: 5193,
    provincia_id: 6,
  },
  {
    id: 16472,
    localidad: "LOS RUICES",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16473,
    localidad: "LOS REYUNOS",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16474,
    localidad: "LOS REARTES",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 16475,
    localidad: "LOS QUEBRACHOS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16476,
    localidad: "LOS PUESTITOS",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16477,
    localidad: "LOS PUENTES",
    cp: 5158,
    provincia_id: 6,
  },
  {
    id: 16478,
    localidad: "LOS POZOS",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16479,
    localidad: "LOS POZOS",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16480,
    localidad: "LOS POZOS",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16481,
    localidad: "LOS POZOS",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16482,
    localidad: "LOS POTREROS",
    cp: 5850,
    provincia_id: 6,
  },
  {
    id: 16483,
    localidad: "LOS POCITOS",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16484,
    localidad: "LOS PIQUILLINES",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16485,
    localidad: "LOS PEDERNALES",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16486,
    localidad: "LOS PATOS",
    cp: 2551,
    provincia_id: 6,
  },
  {
    id: 16487,
    localidad: "LOS PAREDONES",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16488,
    localidad: "LOS PARAISOS",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16489,
    localidad: "LOS PANTALLES",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16490,
    localidad: "LOS OLIVARES",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16491,
    localidad: "LOS OJOS DE AGUA",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 16492,
    localidad: "LOS MORTEROS",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16493,
    localidad: "LOS MOLLES",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16494,
    localidad: "LOS MOLLES",
    cp: 2561,
    provincia_id: 6,
  },
  {
    id: 16495,
    localidad: "LOS MOLINOS",
    cp: 5148,
    provincia_id: 6,
  },
  {
    id: 16496,
    localidad: "LOS MOGOTES",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 16497,
    localidad: "LOS MISTOLES",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16498,
    localidad: "LOS MIQUILES",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16499,
    localidad: "LOS MIGUELITOS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16500,
    localidad: "LOS MEDANOS",
    cp: 5815,
    provincia_id: 6,
  },
  {
    id: 16501,
    localidad: "LOS MANSILLAS",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 16502,
    localidad: "LOS MANGUITOS",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16503,
    localidad: "LOS JUSTES",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16504,
    localidad: "LOS JAGUELES",
    cp: 5839,
    provincia_id: 6,
  },
  {
    id: 16505,
    localidad: "LOS HUESOS",
    cp: 6015,
    provincia_id: 6,
  },
  {
    id: 16506,
    localidad: "LOS HOYOS",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16507,
    localidad: "LOS HORNILLOS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16508,
    localidad: "LOS HORNILLOS",
    cp: 5884,
    provincia_id: 6,
  },
  {
    id: 16509,
    localidad: "LOS HELECHOS",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16510,
    localidad: "LOS GUINDOS",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 16511,
    localidad: "LOS GUEVARA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16512,
    localidad: "LOS GIGANTES",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 16513,
    localidad: "LOS ESPINILLOS",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 16514,
    localidad: "LOS DOS POZOS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16515,
    localidad: "LOS DESAGUES",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 16516,
    localidad: "LOS CUADROS",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16517,
    localidad: "LOS COQUITOS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16518,
    localidad: "LOS CONDORES",
    cp: 5823,
    provincia_id: 6,
  },
  {
    id: 16519,
    localidad: "LOS COMETIERRA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16520,
    localidad: "LOS COCOS",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 16521,
    localidad: "LOS COCOS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16522,
    localidad: "LOS COCOS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16523,
    localidad: "LOS CISNES",
    cp: 2684,
    provincia_id: 6,
  },
  {
    id: 16524,
    localidad: "LOS CHAQARITOS",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16525,
    localidad: "LOS CHAQARITOS",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16526,
    localidad: "LOS CHAQARES",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16527,
    localidad: "LOS CHAQARES",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16528,
    localidad: "LOS CHAQARES",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16529,
    localidad: "LOS CHAQARES",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16530,
    localidad: "LOS CHAÑARITOS",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16531,
    localidad: "LOS CHAÑARITOS",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16532,
    localidad: "LOS CERROS NEGROS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16533,
    localidad: "LOS CERROS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16534,
    localidad: "LOS CERRILLOS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16535,
    localidad: "LOS CERRILLOS",
    cp: 5867,
    provincia_id: 6,
  },
  {
    id: 16536,
    localidad: "LOS CERRILLOS",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16537,
    localidad: "LOS CEJAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16538,
    localidad: "LOS CEDROS",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16539,
    localidad: "LOS CASTAQOS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16540,
    localidad: "LOS CALLEJONES",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16541,
    localidad: "LOS CAJONES",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16542,
    localidad: "LOS CAJONES",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16543,
    localidad: "LOS CADILLOS",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16544,
    localidad: "LOS BRINZES",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16545,
    localidad: "LOS BARRIALES",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16546,
    localidad: "LOS BARRIACITOS",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 16547,
    localidad: "LOS AVILES",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16548,
    localidad: "LOS ALVAREZ",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16549,
    localidad: "LOS ALGARROBOS",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16550,
    localidad: "LOS ALGARROBITOS",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16551,
    localidad: "LOS ALGARROBITOS",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 16552,
    localidad: "LOS ALGARROBITOS",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16553,
    localidad: "LOS ALAMOS",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16554,
    localidad: "LOS AGUIRRES",
    cp: 5278,
    provincia_id: 6,
  },
  {
    id: 16555,
    localidad: "LOS AGUIRRES",
    cp: 5029,
    provincia_id: 6,
  },
  {
    id: 16556,
    localidad: "LOMITAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16557,
    localidad: "LOMAS DEL TROZO",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16558,
    localidad: "LOMA REDONDA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16559,
    localidad: "LOMA DE PIEDRA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16560,
    localidad: "LOMA BLANCA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16561,
    localidad: "LOBERA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16562,
    localidad: "LO MACHADO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16563,
    localidad: "LEONES",
    cp: 2594,
    provincia_id: 6,
  },
  {
    id: 16564,
    localidad: "LEGUIZAMON",
    cp: 6128,
    provincia_id: 6,
  },
  {
    id: 16565,
    localidad: "LECUEDER",
    cp: 6273,
    provincia_id: 6,
  },
  {
    id: 16566,
    localidad: "LASPIUR",
    cp: 5943,
    provincia_id: 6,
  },
  {
    id: 16567,
    localidad: "LAS VERTIENTES",
    cp: 5827,
    provincia_id: 6,
  },
  {
    id: 16568,
    localidad: "LAS VERTIENTES",
    cp: 5839,
    provincia_id: 6,
  },
  {
    id: 16569,
    localidad: "LAS VARILLAS",
    cp: 5992,
    provincia_id: 6,
  },
  {
    id: 16570,
    localidad: "LAS VARILLAS",
    cp: 5940,
    provincia_id: 6,
  },
  {
    id: 16571,
    localidad: "LAS VARAS",
    cp: 5941,
    provincia_id: 6,
  },
  {
    id: 16572,
    localidad: "LAS VAQUERIAS",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16573,
    localidad: "LAS TUSCAS",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16574,
    localidad: "LAS TRANCAS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16575,
    localidad: "LAS TOTORITAS",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16576,
    localidad: "LAS TOSCAS",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16577,
    localidad: "LAS TOSCAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16578,
    localidad: "LAS TINAJERAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16579,
    localidad: "LAS TAPIAS",
    cp: 5886,
    provincia_id: 6,
  },
  {
    id: 16580,
    localidad: "LAS TAPIAS",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16581,
    localidad: "LAS TAPIAS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16582,
    localidad: "LAS SIERRITAS",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 16583,
    localidad: "LAS SALADAS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16584,
    localidad: "LAS ROSAS",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16585,
    localidad: "LAS RABONAS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16586,
    localidad: "LAS QUINTAS",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16587,
    localidad: "LAS PLAYAS LOZADA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16588,
    localidad: "LAS PLAYAS",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16589,
    localidad: "LAS PLAYAS",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16590,
    localidad: "LAS PIGUAS",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16591,
    localidad: "LAS PICHANAS",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16592,
    localidad: "LAS PERDICES",
    cp: 5921,
    provincia_id: 6,
  },
  {
    id: 16593,
    localidad: "LAS PEQAS SUD",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16594,
    localidad: "LAS PEQAS NORTE",
    cp: 5823,
    provincia_id: 6,
  },
  {
    id: 16595,
    localidad: "LAS PEQAS",
    cp: 5238,
    provincia_id: 6,
  },
  {
    id: 16596,
    localidad: "LAS PEQAS",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 16597,
    localidad: "LAS PEÑAS",
    cp: 5238,
    provincia_id: 6,
  },
  {
    id: 16598,
    localidad: "LAS PENCAS",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16599,
    localidad: "LAS PAMPILLAS",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 16600,
    localidad: "LAS PALOMITAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16601,
    localidad: "LAS PALOMAS",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16602,
    localidad: "LAS PALMITAS",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 16603,
    localidad: "LAS PALMITAS",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16604,
    localidad: "LAS PALMITAS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16605,
    localidad: "LAS PALMERAS",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16606,
    localidad: "LAS PALMAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16607,
    localidad: "LAS PALMAS",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16608,
    localidad: "LAS PALMAS",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16609,
    localidad: "LAS OVERIAS",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16610,
    localidad: "LAS OSCURAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16611,
    localidad: "LAS MOJARRAS",
    cp: 5909,
    provincia_id: 6,
  },
  {
    id: 16612,
    localidad: "LAS MESTIZAS",
    cp: 6277,
    provincia_id: 6,
  },
  {
    id: 16613,
    localidad: "LAS MERCEDITAS",
    cp: 2572,
    provincia_id: 6,
  },
  {
    id: 16614,
    localidad: "LAS MASITAS",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16615,
    localidad: "LAS MANZANAS",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16616,
    localidad: "LAS LOMITAS",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16617,
    localidad: "LAS LOMITAS",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16618,
    localidad: "LAS LOMAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16619,
    localidad: "LAS LAGUNITAS",
    cp: 2568,
    provincia_id: 6,
  },
  {
    id: 16620,
    localidad: "LAS JUNTURAS",
    cp: 5965,
    provincia_id: 6,
  },
  {
    id: 16621,
    localidad: "LAS JUNTAS",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 16622,
    localidad: "LAS JARILLAS",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16623,
    localidad: "LAS ISLETILLAS",
    cp: 5931,
    provincia_id: 6,
  },
  {
    id: 16624,
    localidad: "LAS HORQUETAS",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16625,
    localidad: "LAS HILERAS",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16626,
    localidad: "LAS HIGUERITAS",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 16627,
    localidad: "LAS HIGUERITAS",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16628,
    localidad: "LAS HIGUERILLAS",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 16629,
    localidad: "LAS HIGUERILLAS",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16630,
    localidad: "LAS HIGUERAS",
    cp: 5805,
    provincia_id: 6,
  },
  {
    id: 16631,
    localidad: "LAS HIGUERAS",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 16632,
    localidad: "LAS GRAMILLAS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16633,
    localidad: "LAS GRAMILLAS",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 16634,
    localidad: "LAS GEMELAS",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16635,
    localidad: "LAS GAMAS",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 16636,
    localidad: "LAS FLORES",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16637,
    localidad: "LAS ENSENADAS",
    cp: 5825,
    provincia_id: 6,
  },
  {
    id: 16638,
    localidad: "LAS ENCRUCIJADAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16639,
    localidad: "LAS DELICIAS",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 16640,
    localidad: "LAS DELICIAS",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16641,
    localidad: "LAS DELICIAS",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16642,
    localidad: "LAS CUATRO ESQS",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16643,
    localidad: "LAS CRUCECITAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16644,
    localidad: "LAS CORTADERAS",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16645,
    localidad: "LAS CORTADERAS",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 16646,
    localidad: "LAS CONANITAS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16647,
    localidad: "LAS CINCO CUADRAS",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 16648,
    localidad: "LAS CHACRAS",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16649,
    localidad: "LAS CHACRAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16650,
    localidad: "LAS CHACRAS",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16651,
    localidad: "LAS CEBOLLAS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16652,
    localidad: "LAS CASITAS",
    cp: 5158,
    provincia_id: 6,
  },
  {
    id: 16653,
    localidad: "LAS CARDAS",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16654,
    localidad: "LAS CAQAS",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16655,
    localidad: "LAS CAQAS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16656,
    localidad: "LAS CAQAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16657,
    localidad: "LAS CAQADAS",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16658,
    localidad: "LAS CAQADAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16659,
    localidad: "LAS CAÑITAS",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 16660,
    localidad: "LAS CANTERAS",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16661,
    localidad: "LAS CALLES",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16662,
    localidad: "LAS CALERAS",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16663,
    localidad: "LAS CABRAS",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 16664,
    localidad: "LAS BARRANCAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16665,
    localidad: "LAS BANDURRIAS N",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16666,
    localidad: "LAS BAJADAS",
    cp: 5851,
    provincia_id: 6,
  },
  {
    id: 16667,
    localidad: "LAS AVERIAS",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 16668,
    localidad: "LAS ARRIAS",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16669,
    localidad: "LAS ARRIAS",
    cp: 5520,
    provincia_id: 6,
  },
  {
    id: 16670,
    localidad: "LAS AROMAS",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16671,
    localidad: "LAS AGUADITAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16672,
    localidad: "LAS ACEQUIAS",
    cp: 5848,
    provincia_id: 6,
  },
  {
    id: 16673,
    localidad: "LAS ACACIAS",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16674,
    localidad: "LAGUNILLA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16675,
    localidad: "LAGUNA SECA",
    cp: 5829,
    provincia_id: 6,
  },
  {
    id: 16676,
    localidad: "LAGUNA LARGA",
    cp: 5974,
    provincia_id: 6,
  },
  {
    id: 16677,
    localidad: "LAGUNA DE GOMEZ",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16678,
    localidad: "LAGUNA CLARA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 16679,
    localidad: "LAGUNA BRAVA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16680,
    localidad: "LADERA YACUS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16681,
    localidad: "LABOULAYE",
    cp: 6120,
    provincia_id: 6,
  },
  {
    id: 16682,
    localidad: "LABOULAYE",
    cp: 5120,
    provincia_id: 6,
  },
  {
    id: 16683,
    localidad: "LABORDE",
    cp: 2657,
    provincia_id: 6,
  },
  {
    id: 16684,
    localidad: "LA ZANJA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16685,
    localidad: "LA VIRGINIA",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16686,
    localidad: "LA VICTORIA",
    cp: 5931,
    provincia_id: 6,
  },
  {
    id: 16687,
    localidad: "LA VICENTA",
    cp: 2417,
    provincia_id: 6,
  },
  {
    id: 16688,
    localidad: "LA VENTANA",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16689,
    localidad: "LA USINA",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16690,
    localidad: "LA TRIBU",
    cp: 6015,
    provincia_id: 6,
  },
  {
    id: 16691,
    localidad: "LA TRAVESIA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16692,
    localidad: "LA TRAMPA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16693,
    localidad: "LA TOTORILLA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16694,
    localidad: "LA TORDILLA NORTE",
    cp: 2429,
    provincia_id: 6,
  },
  {
    id: 16695,
    localidad: "LA TORDILLA",
    cp: 2435,
    provincia_id: 6,
  },
  {
    id: 16696,
    localidad: "LA TIGRA",
    cp: 5949,
    provincia_id: 6,
  },
  {
    id: 16697,
    localidad: "LA TABLADA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16698,
    localidad: "LA SIERRITA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16699,
    localidad: "LA SIERRITA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 16700,
    localidad: "LA SERRANITA",
    cp: 5142,
    provincia_id: 6,
  },
  {
    id: 16701,
    localidad: "LA SEGUNDA",
    cp: 6277,
    provincia_id: 6,
  },
  {
    id: 16702,
    localidad: "LA RUDA",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16703,
    localidad: "LA ROSARINA",
    cp: 5951,
    provincia_id: 6,
  },
  {
    id: 16704,
    localidad: "LA RINCONADA",
    cp: 5233,
    provincia_id: 6,
  },
  {
    id: 16705,
    localidad: "LA REPRESA",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 16706,
    localidad: "LA REINA",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16707,
    localidad: "LA REDUCCION",
    cp: 2594,
    provincia_id: 6,
  },
  {
    id: 16708,
    localidad: "LA RAMONCITA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 16709,
    localidad: "LA RAMADA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16710,
    localidad: "LA RAMADA",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 16711,
    localidad: "LA R.ADA CANDELARI",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16712,
    localidad: "LA QUINTANA",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16713,
    localidad: "LA QUINTA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16714,
    localidad: "LA QUINTA",
    cp: 5136,
    provincia_id: 6,
  },
  {
    id: 16715,
    localidad: "LA QUINTA",
    cp: 5887,
    provincia_id: 6,
  },
  {
    id: 16716,
    localidad: "LA QUEBRADA",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16717,
    localidad: "LA QUEBRADA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 16718,
    localidad: "LA PVERA CHAMAICO",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16719,
    localidad: "LA PUNTA DEL AGUA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16720,
    localidad: "LA PUERTA",
    cp: 5138,
    provincia_id: 6,
  },
  {
    id: 16721,
    localidad: "LA PUERTA",
    cp: 4872,
    provincia_id: 6,
  },
  {
    id: 16722,
    localidad: "LA PUERTA",
    cp: 5102,
    provincia_id: 6,
  },
  {
    id: 16723,
    localidad: "LA PUERTA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16724,
    localidad: "LA PUERTA",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16725,
    localidad: "LA PUERT V DE SOTO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16726,
    localidad: "LA PRIMAVERA",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16727,
    localidad: "LA POSTA CHUQAGUAS",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16728,
    localidad: "LA POSTA",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 16729,
    localidad: "LA PORTEQA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 16730,
    localidad: "LA POMONA",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16731,
    localidad: "LA POBLADORA",
    cp: 5945,
    provincia_id: 6,
  },
  {
    id: 16732,
    localidad: "LA POBLACION",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16733,
    localidad: "LA PLAZA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16734,
    localidad: "LA PLAYOSA",
    cp: 5911,
    provincia_id: 6,
  },
  {
    id: 16735,
    localidad: "LA PLAYA",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 16736,
    localidad: "LA PINTADA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16737,
    localidad: "LA PINTA",
    cp: 6007,
    provincia_id: 6,
  },
  {
    id: 16738,
    localidad: "LA PIEDRA MOVEDIZA",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 16739,
    localidad: "LA PIEDRA BLANCA",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 16740,
    localidad: "LA PENCA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16741,
    localidad: "LA PENCA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16742,
    localidad: "LA PAZ",
    cp: 5117,
    provincia_id: 6,
  },
  {
    id: 16743,
    localidad: "LA PAZ",
    cp: 5879,
    provincia_id: 6,
  },
  {
    id: 16744,
    localidad: "LA PATRIA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16745,
    localidad: "LA PARA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16746,
    localidad: "LA PAQUITA",
    cp: 2416,
    provincia_id: 6,
  },
  {
    id: 16747,
    localidad: "LA PAQUITA",
    cp: 2417,
    provincia_id: 6,
  },
  {
    id: 16748,
    localidad: "LA PAMPA",
    cp: 5117,
    provincia_id: 6,
  },
  {
    id: 16749,
    localidad: "LA PALMA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16750,
    localidad: "LA PALESTINA",
    cp: 5957,
    provincia_id: 6,
  },
  {
    id: 16751,
    localidad: "LA PAISANITA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16752,
    localidad: "LA MUDANA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16753,
    localidad: "LA MOSTAZA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16754,
    localidad: "LA MESILLA",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16755,
    localidad: "LA MESADA",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16756,
    localidad: "LA MERCANTIL",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 16757,
    localidad: "LA MAZA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16758,
    localidad: "LA MARINA",
    cp: 2341,
    provincia_id: 6,
  },
  {
    id: 16759,
    localidad: "LA MARIA ESTHER",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16760,
    localidad: "LA MARGARITA",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16761,
    localidad: "LA MAJADA",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16762,
    localidad: "LA LOMA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16763,
    localidad: "LA LINEA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16764,
    localidad: "LA LILIA",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16765,
    localidad: "LA LATA",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16766,
    localidad: "LA LAGUNILLA",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16767,
    localidad: "LA LAGUNILLA",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 16768,
    localidad: "LA LAGUNA",
    cp: 5283,
    provincia_id: 6,
  },
  {
    id: 16769,
    localidad: "LA LAGUNA",
    cp: 5905,
    provincia_id: 6,
  },
  {
    id: 16770,
    localidad: "LA LAGUNA",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 16771,
    localidad: "LA LAGUNA",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 16772,
    localidad: "LA JUANITA",
    cp: 6208,
    provincia_id: 6,
  },
  {
    id: 16773,
    localidad: "LA JARILLA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16774,
    localidad: "LA ITALIANA",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 16775,
    localidad: "LA ISOLINA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16776,
    localidad: "LA ISLA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 16777,
    localidad: "LA ISLA",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 16778,
    localidad: "LA ISABELA",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16779,
    localidad: "LA INVERNADA",
    cp: 6277,
    provincia_id: 6,
  },
  {
    id: 16780,
    localidad: "LA HUAYQUERIA",
    cp: 6005,
    provincia_id: 6,
  },
  {
    id: 16781,
    localidad: "LA HIGUERITA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16782,
    localidad: "LA HIGUERITA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16783,
    localidad: "LA HERRADURA",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16784,
    localidad: "LA GUARDIA",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 16785,
    localidad: "LA GRANJA",
    cp: 5115,
    provincia_id: 6,
  },
  {
    id: 16786,
    localidad: "LA GRANADILLA",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16787,
    localidad: "LA GRAMILLA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16788,
    localidad: "LA GILDA",
    cp: 5848,
    provincia_id: 6,
  },
  {
    id: 16789,
    localidad: "LA FRONTERA",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 16790,
    localidad: "LA FRONDA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16791,
    localidad: "LA FRANCIA",
    cp: 2426,
    provincia_id: 6,
  },
  {
    id: 16792,
    localidad: "LA FRANCIA",
    cp: 2425,
    provincia_id: 6,
  },
  {
    id: 16793,
    localidad: "LA FLORIDA",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16794,
    localidad: "LA FLORIDA",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16795,
    localidad: "LA FINCA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16796,
    localidad: "LA FELISA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16797,
    localidad: "LA FALDA DEL CARMEN",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16798,
    localidad: "LA FALDA DEL CARM",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16799,
    localidad: "LA FALDA",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16800,
    localidad: "LA ESTRELLA",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16801,
    localidad: "LA ESTANCIA",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16802,
    localidad: "LA ESQUINA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 16803,
    localidad: "LA ESPERANZA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16804,
    localidad: "LA ELENITA",
    cp: 6216,
    provincia_id: 6,
  },
  {
    id: 16805,
    localidad: "LA DORMIDA",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 16806,
    localidad: "LA DORA",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16807,
    localidad: "LA CURVA",
    cp: 2434,
    provincia_id: 6,
  },
  {
    id: 16808,
    localidad: "LA CUMBRECITA",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 16809,
    localidad: "LA CUMBRE",
    cp: 5068,
    provincia_id: 6,
  },
  {
    id: 16810,
    localidad: "LA CUMBRE",
    cp: 5178,
    provincia_id: 6,
  },
  {
    id: 16811,
    localidad: "LA CUMBRE",
    cp: 5180,
    provincia_id: 6,
  },
  {
    id: 16812,
    localidad: "LA CUMBRE",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 16813,
    localidad: "LA CRUZ",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16814,
    localidad: "LA CRUZ",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 16815,
    localidad: "LA COYUNDA",
    cp: 2435,
    provincia_id: 6,
  },
  {
    id: 16816,
    localidad: "LA COSTA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16817,
    localidad: "LA COSTA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 16818,
    localidad: "LA CORTADERA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16819,
    localidad: "LA CORA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16820,
    localidad: "LA CONCEPCION",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16821,
    localidad: "LA COMPASION",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16822,
    localidad: "LA COLORADA",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16823,
    localidad: "LA COLONIA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16824,
    localidad: "LA COCHA",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16825,
    localidad: "LA COCHA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16826,
    localidad: "LA CIENAGA",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 16827,
    localidad: "LA CHOZA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 16828,
    localidad: "LA CHERINDU",
    cp: 6216,
    provincia_id: 6,
  },
  {
    id: 16829,
    localidad: "LA CESIRA",
    cp: 6101,
    provincia_id: 6,
  },
  {
    id: 16830,
    localidad: "LA CELINA",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16831,
    localidad: "LA CELIA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16832,
    localidad: "LA CAUTIVA",
    cp: 6142,
    provincia_id: 6,
  },
  {
    id: 16833,
    localidad: "LA CAUTIBA",
    cp: 5142,
    provincia_id: 6,
  },
  {
    id: 16834,
    localidad: "LA CASCADA",
    cp: 5845,
    provincia_id: 6,
  },
  {
    id: 16835,
    localidad: "LA CARLOTA",
    cp: 2670,
    provincia_id: 6,
  },
  {
    id: 16836,
    localidad: "LA CAQADA S CRUZ",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16837,
    localidad: "LA CAQADA ANGOSTA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16838,
    localidad: "LA CAQADA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 16839,
    localidad: "LA CAQADA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16840,
    localidad: "LA CAQADA",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16841,
    localidad: "LA CAQADA",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16842,
    localidad: "LA CAÑADA",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 16843,
    localidad: "LA CANTERA",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16844,
    localidad: "LA CALERA CALAMUCHIT",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16845,
    localidad: "LA CALERA - CBA",
    cp: 5151,
    provincia_id: 6,
  },
  {
    id: 16846,
    localidad: "LA CALERA - CBA",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 16847,
    localidad: "LA CALERA",
    cp: 5151,
    provincia_id: 6,
  },
  {
    id: 16848,
    localidad: "LA BUENA PARADA",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16849,
    localidad: "LA BOTIJA",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16850,
    localidad: "LA BOLSA",
    cp: 5689,
    provincia_id: 6,
  },
  {
    id: 16851,
    localidad: "LA BOLIVIA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16852,
    localidad: "LA BISMUTINA",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 16853,
    localidad: "LA BETANIA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16854,
    localidad: "LA BATALLA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16855,
    localidad: "LA BARRANQUITA",
    cp: 5833,
    provincia_id: 6,
  },
  {
    id: 16856,
    localidad: "LA BARRANCA",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16857,
    localidad: "LA BARRANCA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16858,
    localidad: "LA AURORA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16859,
    localidad: "LA AURA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16860,
    localidad: "LA AROMA",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16861,
    localidad: "LA ARGENTINA",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 16862,
    localidad: "LA ARGENTINA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 16863,
    localidad: "LA ANGOSTURA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16864,
    localidad: "LA ALEGRIA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16865,
    localidad: "LA AGUADITA",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 16866,
    localidad: "LA AGUADA",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16867,
    localidad: "LA AGUADA",
    cp: 5211,
    provincia_id: 6,
  },
  {
    id: 16868,
    localidad: "LA A DE LAS ANIMAS",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 16869,
    localidad: "L JAGUELES",
    cp: 5839,
    provincia_id: 6,
  },
  {
    id: 16870,
    localidad: "L A PROVIDENCIA",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 16871,
    localidad: "L 5 CALEUFU  E 120",
    cp: 6205,
    provincia_id: 6,
  },
  {
    id: 16872,
    localidad: "KM 57",
    cp: 2619,
    provincia_id: 6,
  },
  {
    id: 16873,
    localidad: "KILOMETRO 931",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16874,
    localidad: "KILOMETRO 907",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 16875,
    localidad: "KILOMETRO 881",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16876,
    localidad: "KILOMETRO 865",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16877,
    localidad: "KILOMETRO 859",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16878,
    localidad: "KILOMETRO 832",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16879,
    localidad: "KILOMETRO 827",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16880,
    localidad: "KILOMETRO 807",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16881,
    localidad: "KILOMETRO 691",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16882,
    localidad: "KILOMETRO 658",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16883,
    localidad: "KILOMETRO 608",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 16884,
    localidad: "KILOMETRO 592",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 16885,
    localidad: "KILOMETRO 581",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 16886,
    localidad: "KILOMETRO 579",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16887,
    localidad: "KILOMETRO 541",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16888,
    localidad: "KILOMETRO 531",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 16889,
    localidad: "KILOMETRO 450",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16890,
    localidad: "KILOMETRO 430",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16891,
    localidad: "KILOMETRO 394",
    cp: 5238,
    provincia_id: 6,
  },
  {
    id: 16892,
    localidad: "KILOMETRO 364",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16893,
    localidad: "KILOMETRO 316",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16894,
    localidad: "KILOMETRO 294",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 16895,
    localidad: "KILOMETRO 271",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 16896,
    localidad: "KILOMETRO 267",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 16897,
    localidad: "JUSTINIANO POSSE",
    cp: 2553,
    provincia_id: 6,
  },
  {
    id: 16898,
    localidad: "JUME",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16899,
    localidad: "JUAREZ CELMAN",
    cp: 5145,
    provincia_id: 6,
  },
  {
    id: 16900,
    localidad: "JUAN GARCIA",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 16901,
    localidad: "JOVITA",
    cp: 6127,
    provincia_id: 6,
  },
  {
    id: 16902,
    localidad: "JOSE DE LA QUINTANA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16903,
    localidad: "JESUS MARIA",
    cp: 5220,
    provincia_id: 6,
  },
  {
    id: 16904,
    localidad: "JESUS MARIA",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16905,
    localidad: "JERONIMO CORTES",
    cp: 5141,
    provincia_id: 6,
  },
  {
    id: 16906,
    localidad: "JEANMAIRE",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 16907,
    localidad: "JARILLAS",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16908,
    localidad: "JARDON",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 16909,
    localidad: "JAMES CRAIK",
    cp: 5984,
    provincia_id: 6,
  },
  {
    id: 16910,
    localidad: "JAIME PETER",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 16911,
    localidad: "J DE LA QUINTANA",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16912,
    localidad: "J BAUTISTA ALBERDI",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 16913,
    localidad: "ITI HUASI",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 16914,
    localidad: "ITALO",
    cp: 6278,
    provincia_id: 6,
  },
  {
    id: 16915,
    localidad: "ITALO",
    cp: 6109,
    provincia_id: 6,
  },
  {
    id: 16916,
    localidad: "ISLETA NEGRA",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 16917,
    localidad: "ISLA VERDE",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 16918,
    localidad: "ISLA VERDE",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 16919,
    localidad: "ISLA VERDE",
    cp: 2660,
    provincia_id: 6,
  },
  {
    id: 16920,
    localidad: "ISLA VERDE",
    cp: 2661,
    provincia_id: 6,
  },
  {
    id: 16921,
    localidad: "ISLA LARGA",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16922,
    localidad: "ISLA DEL CERRO",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 16923,
    localidad: "ISLA DE S ANTONIO",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 16924,
    localidad: "ISLA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 16925,
    localidad: "ISCHILIN",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 16926,
    localidad: "IRIGOYEN",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 16927,
    localidad: "INVERNADA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 16928,
    localidad: "INRIVILLE",
    cp: 2587,
    provincia_id: 6,
  },
  {
    id: 16929,
    localidad: "INGENIERO BERTINI",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 16930,
    localidad: "INDIA MUERTA",
    cp: 5913,
    provincia_id: 6,
  },
  {
    id: 16931,
    localidad: "IMPIRA",
    cp: 5987,
    provincia_id: 6,
  },
  {
    id: 16932,
    localidad: "IDIAZABAL",
    cp: 2557,
    provincia_id: 6,
  },
  {
    id: 16933,
    localidad: "HUNQUILLAL",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 16934,
    localidad: "HUINCA RENANCO",
    cp: 6270,
    provincia_id: 6,
  },
  {
    id: 16935,
    localidad: "HUERTA GRANDE",
    cp: 5174,
    provincia_id: 6,
  },
  {
    id: 16936,
    localidad: "HUASTA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16937,
    localidad: "HUANCHILLA",
    cp: 6121,
    provincia_id: 6,
  },
  {
    id: 16938,
    localidad: "HORNILLOS",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 16939,
    localidad: "HOLMBERG",
    cp: 5825,
    provincia_id: 6,
  },
  {
    id: 16940,
    localidad: "HIPOLITO BOUCHARD",
    cp: 6225,
    provincia_id: 6,
  },
  {
    id: 16941,
    localidad: "HIGUERILLAS",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 16942,
    localidad: "HERNANDO",
    cp: 5968,
    provincia_id: 6,
  },
  {
    id: 16943,
    localidad: "HERNANDO",
    cp: 5929,
    provincia_id: 6,
  },
  {
    id: 16944,
    localidad: "HAM",
    cp: 6005,
    provincia_id: 6,
  },
  {
    id: 16945,
    localidad: "H FLIA DGO FUNES",
    cp: 5165,
    provincia_id: 6,
  },
  {
    id: 16946,
    localidad: "GUTEMBERG",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16947,
    localidad: "GUIÑAZU",
    cp: 5145,
    provincia_id: 6,
  },
  {
    id: 16948,
    localidad: "GUINDAS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16949,
    localidad: "GUATIMOZIN",
    cp: 2627,
    provincia_id: 6,
  },
  {
    id: 16950,
    localidad: "GUASTA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 16951,
    localidad: "GUASAPAMPA",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 16952,
    localidad: "GUARDIA VIEJA",
    cp: 6120,
    provincia_id: 6,
  },
  {
    id: 16953,
    localidad: "GUANACO MUERTO",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 16954,
    localidad: "GUANACO BOLEADO",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 16955,
    localidad: "GUALLASCATE",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 16956,
    localidad: "GR DE SAN ANTONIO",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 16957,
    localidad: "GOLPE DE AGUA",
    cp: 5187,
    provincia_id: 6,
  },
  {
    id: 16958,
    localidad: "GLORIA A DIOS",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 16959,
    localidad: "GIGENA ALCIRA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 16960,
    localidad: "GIGENA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 16961,
    localidad: "GENERAL VIAMONTE",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 16962,
    localidad: "GENERAL SOLER",
    cp: 6142,
    provincia_id: 6,
  },
  {
    id: 16963,
    localidad: "GENERAL ROCA",
    cp: 2592,
    provincia_id: 6,
  },
  {
    id: 16964,
    localidad: "GENERAL LEVALLE",
    cp: 6132,
    provincia_id: 6,
  },
  {
    id: 16965,
    localidad: "GENERAL LAVALLE",
    cp: 6132,
    provincia_id: 6,
  },
  {
    id: 16966,
    localidad: "GENERAL LAS HERAS",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 16967,
    localidad: "GENERAL FOTHERINGHAM",
    cp: 5982,
    provincia_id: 6,
  },
  {
    id: 16968,
    localidad: "GENERAL FOTHERIGHAM",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 16969,
    localidad: "GENERAL DEHEZA",
    cp: 5923,
    provincia_id: 6,
  },
  {
    id: 16970,
    localidad: "GENERAL CABRERA",
    cp: 5809,
    provincia_id: 6,
  },
  {
    id: 16971,
    localidad: "GENERAL BALDISSERA",
    cp: 2583,
    provincia_id: 6,
  },
  {
    id: 16972,
    localidad: "G FOTHERINGHAM",
    cp: 5982,
    provincia_id: 6,
  },
  {
    id: 16973,
    localidad: "G FERNANDEZ",
    cp: 5837,
    provincia_id: 6,
  },
  {
    id: 16974,
    localidad: "FRISIA",
    cp: 6277,
    provincia_id: 6,
  },
  {
    id: 16975,
    localidad: "FREYRE",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 16976,
    localidad: "FRAGUEYRO",
    cp: 5847,
    provincia_id: 6,
  },
  {
    id: 16977,
    localidad: "FLORA",
    cp: 2525,
    provincia_id: 6,
  },
  {
    id: 16978,
    localidad: "FERREYRA (CORDOBA)",
    cp: 5123,
    provincia_id: 6,
  },
  {
    id: 16979,
    localidad: "FERREYRA",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 16980,
    localidad: "FABRICA MILITAR",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16981,
    localidad: "FABRICA MILITAR",
    cp: 5900,
    provincia_id: 6,
  },
  {
    id: 16982,
    localidad: "F MILITAR RIO 3:",
    cp: 5850,
    provincia_id: 6,
  },
  {
    id: 16983,
    localidad: "F DE LOS REARTES",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 16984,
    localidad: "EXPEDICIàN CO",
    cp: 5100,
    provincia_id: 6,
  },
  {
    id: 16985,
    localidad: "EUFRASIO LOZA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 16986,
    localidad: "ETRURIA",
    cp: 2681,
    provincia_id: 6,
  },
  {
    id: 16987,
    localidad: "ESTANCIA PATIQO",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 16988,
    localidad: "ESTANCIA LAS ROSAS",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16989,
    localidad: "ESTANCIA EL TACO",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16990,
    localidad: "ESTANCIA DOS RIOS",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 16991,
    localidad: "ESTANCIA BOTTARO",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 16992,
    localidad: "ESTANCIA",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 16993,
    localidad: "ESTACION SOTO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 16994,
    localidad: "ESTACION GENERAL PAZ",
    cp: 5145,
    provincia_id: 6,
  },
  {
    id: 16995,
    localidad: "ESTACION ASCENSION",
    cp: 6003,
    provincia_id: 6,
  },
  {
    id: 16996,
    localidad: "ESTACION ACHIRAS",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 16997,
    localidad: "ESTACION ACHIRAS",
    cp: 5831,
    provincia_id: 6,
  },
  {
    id: 16998,
    localidad: "ESTACION 69",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 16999,
    localidad: "ESQUINA GRANDE",
    cp: 5275,
    provincia_id: 6,
  },
  {
    id: 17000,
    localidad: "ESQUINA",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 17001,
    localidad: "ESQUINA",
    cp: 4819,
    provincia_id: 6,
  },
  {
    id: 17002,
    localidad: "ESQ DEL ALAMBRE",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 17003,
    localidad: "ESPINILLO",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 17004,
    localidad: "ESPINILLO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17005,
    localidad: "ESPINILLO",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 17006,
    localidad: "ESPINILLO",
    cp: 5811,
    provincia_id: 6,
  },
  {
    id: 17007,
    localidad: "ESPERANZA",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 17008,
    localidad: "ESMERALDITA",
    cp: 2401,
    provincia_id: 6,
  },
  {
    id: 17009,
    localidad: "ESCOBAS",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17010,
    localidad: "ENFERMERA KELLY",
    cp: 2587,
    provincia_id: 6,
  },
  {
    id: 17011,
    localidad: "EMBALSE",
    cp: 5856,
    provincia_id: 6,
  },
  {
    id: 17012,
    localidad: "EMBALSE",
    cp: 5858,
    provincia_id: 6,
  },
  {
    id: 17013,
    localidad: "ELENA",
    cp: 5815,
    provincia_id: 6,
  },
  {
    id: 17014,
    localidad: "EL ZAPATO",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17015,
    localidad: "EL VERGEL",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17016,
    localidad: "EL VENCE",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17017,
    localidad: "EL VEINTICINCO",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 17018,
    localidad: "EL VALLESITO",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17019,
    localidad: "EL VALLECITO",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 17020,
    localidad: "EL VADO",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 17021,
    localidad: "EL TUSCAL",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 17022,
    localidad: "EL TULE",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17023,
    localidad: "EL TRIANGULO",
    cp: 2572,
    provincia_id: 6,
  },
  {
    id: 17024,
    localidad: "EL TRABAJO",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17025,
    localidad: "EL TOSTADO",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17026,
    localidad: "EL TORREON",
    cp: 5864,
    provincia_id: 6,
  },
  {
    id: 17027,
    localidad: "EL TORDILLO",
    cp: 6212,
    provincia_id: 6,
  },
  {
    id: 17028,
    localidad: "EL TIO",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 17029,
    localidad: "EL TESORO",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 17030,
    localidad: "EL TALITA",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 17031,
    localidad: "EL TALA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17032,
    localidad: "EL TALA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 17033,
    localidad: "EL TAJAMAR",
    cp: 6205,
    provincia_id: 6,
  },
  {
    id: 17034,
    localidad: "EL SUNCHAL",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17035,
    localidad: "EL SIMBOLAR",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 17036,
    localidad: "EL SIMBOL",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17037,
    localidad: "EL SILVERIO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17038,
    localidad: "EL SEBIL",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17039,
    localidad: "EL SAUCE",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17040,
    localidad: "EL SAUCE",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 17041,
    localidad: "EL SALTO",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17042,
    localidad: "EL ROSARIO",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 17043,
    localidad: "EL RODEO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17044,
    localidad: "EL RODEO",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17045,
    localidad: "EL RODEO",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17046,
    localidad: "EL RODEITO",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17047,
    localidad: "EL RIO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17048,
    localidad: "EL RINCON",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17049,
    localidad: "EL RINCON",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17050,
    localidad: "EL RINCON",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17051,
    localidad: "EL RINCON",
    cp: 5881,
    provincia_id: 6,
  },
  {
    id: 17052,
    localidad: "EL RECUERDO",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 17053,
    localidad: "EL RECUERDO",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 17054,
    localidad: "EL RASTREADOR",
    cp: 6121,
    provincia_id: 6,
  },
  {
    id: 17055,
    localidad: "EL RANCHITO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17056,
    localidad: "EL QUEBRACHO",
    cp: 5854,
    provincia_id: 6,
  },
  {
    id: 17057,
    localidad: "EL QUEBRACHO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17058,
    localidad: "EL QUEBRACHAL",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17059,
    localidad: "EL PUESTO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17060,
    localidad: "EL PUESTO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17061,
    localidad: "EL PUENTE",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 17062,
    localidad: "EL PRADO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17063,
    localidad: "EL POTRERO",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 17064,
    localidad: "EL POTRERO",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17065,
    localidad: "EL PORVENIR",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 17066,
    localidad: "EL PORVENIR",
    cp: 6216,
    provincia_id: 6,
  },
  {
    id: 17067,
    localidad: "EL PORTILLO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17068,
    localidad: "EL PORTEZUELO",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17069,
    localidad: "EL PORTEQO",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 17070,
    localidad: "EL PLUMERILLO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17071,
    localidad: "EL PINGO",
    cp: 5178,
    provincia_id: 6,
  },
  {
    id: 17072,
    localidad: "EL PILCADO",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17073,
    localidad: "EL PICHE",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 17074,
    localidad: "EL PERUEL",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17075,
    localidad: "EL PERTIGO",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17076,
    localidad: "EL PERCHEL",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 17077,
    localidad: "EL PERCHEL",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17078,
    localidad: "EL PASO",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 17079,
    localidad: "EL PARAISO",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 17080,
    localidad: "EL PARAISO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17081,
    localidad: "EL PANTANO",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17082,
    localidad: "EL PANAL",
    cp: 2580,
    provincia_id: 6,
  },
  {
    id: 17083,
    localidad: "EL P LOS CABRERA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17084,
    localidad: "EL P DE LA PAMPA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17085,
    localidad: "EL P DE LA MONTAQA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17086,
    localidad: "EL OVERO",
    cp: 2563,
    provincia_id: 6,
  },
  {
    id: 17087,
    localidad: "EL OJO DE AGUA",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 17088,
    localidad: "EL MOLINO",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 17089,
    localidad: "EL MOJONCITO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17090,
    localidad: "EL MIRADOR",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 17091,
    localidad: "EL MANZANO",
    cp: 5114,
    provincia_id: 6,
  },
  {
    id: 17092,
    localidad: "EL MANGRULLO",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17093,
    localidad: "EL MANANTIAL",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 17094,
    localidad: "EL MANANTIAL",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 17095,
    localidad: "EL LAUREL",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17096,
    localidad: "EL JUMIAL",
    cp: 5947,
    provincia_id: 6,
  },
  {
    id: 17097,
    localidad: "EL JUME",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17098,
    localidad: "EL JORDAN",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17099,
    localidad: "EL GUINDO",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17100,
    localidad: "EL GUANACO",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17101,
    localidad: "EL GUANACO",
    cp: 6205,
    provincia_id: 6,
  },
  {
    id: 17102,
    localidad: "EL GUAICO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17103,
    localidad: "EL GATEADO",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17104,
    localidad: "EL GALLEGO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17105,
    localidad: "EL GABINO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17106,
    localidad: "EL FUERTECITO",
    cp: 2428,
    provincia_id: 6,
  },
  {
    id: 17107,
    localidad: "EL FRANCES",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17108,
    localidad: "EL FRAILE",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 17109,
    localidad: "EL FORTIN",
    cp: 5951,
    provincia_id: 6,
  },
  {
    id: 17110,
    localidad: "EL FLORIDA",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 17111,
    localidad: "EL FLORENTINO",
    cp: 5951,
    provincia_id: 6,
  },
  {
    id: 17112,
    localidad: "EL ESPINILLAL",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17113,
    localidad: "EL ESPINAL",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 17114,
    localidad: "EL ESCABEL",
    cp: 8201,
    provincia_id: 6,
  },
  {
    id: 17115,
    localidad: "EL EJE",
    cp: 4792,
    provincia_id: 6,
  },
  {
    id: 17116,
    localidad: "EL DURAZNO",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17117,
    localidad: "EL DURAZNO",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 17118,
    localidad: "EL DURAZNO",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17119,
    localidad: "EL DURAZNO",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17120,
    localidad: "EL DURAZNILLO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17121,
    localidad: "EL DORADO",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 17122,
    localidad: "EL DESMONTE",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17123,
    localidad: "EL CUADRADO",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 17124,
    localidad: "EL CRISPIN",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 17125,
    localidad: "EL CORTE",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 17126,
    localidad: "EL CORRALITO",
    cp: 5963,
    provincia_id: 6,
  },
  {
    id: 17127,
    localidad: "EL CORO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17128,
    localidad: "EL CORO",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 17129,
    localidad: "EL CONSUELO",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 17130,
    localidad: "EL CINCO",
    cp: 6216,
    provincia_id: 6,
  },
  {
    id: 17131,
    localidad: "EL CIENAGO",
    cp: 5275,
    provincia_id: 6,
  },
  {
    id: 17132,
    localidad: "EL CHIQUILLAN",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17133,
    localidad: "EL CHANCHITO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17134,
    localidad: "EL CERRITO",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 17135,
    localidad: "EL CARRIZAL",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17136,
    localidad: "EL CARRIZAL",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17137,
    localidad: "EL CARRIZAL",
    cp: 5133,
    provincia_id: 6,
  },
  {
    id: 17138,
    localidad: "EL CARR CHUQAGUASI",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17139,
    localidad: "EL CARMEN",
    cp: 5197,
    provincia_id: 6,
  },
  {
    id: 17140,
    localidad: "EL CARACOL",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17141,
    localidad: "EL CANO",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 17142,
    localidad: "EL CALLEJON",
    cp: 5172,
    provincia_id: 6,
  },
  {
    id: 17143,
    localidad: "EL BOSQUE",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17144,
    localidad: "EL BORDO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17145,
    localidad: "EL BARRIAL",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17146,
    localidad: "EL BARREAL",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17147,
    localidad: "EL BAQADO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17148,
    localidad: "EL BAQADO",
    cp: 5214,
    provincia_id: 6,
  },
  {
    id: 17149,
    localidad: "EL BAÑADO RIO CUARTO",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 17150,
    localidad: "EL BALDECITO",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 17151,
    localidad: "EL BALDECITO",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 17152,
    localidad: "EL BAJO",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 17153,
    localidad: "EL BAGUAL",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17154,
    localidad: "EL ARAQADO",
    cp: 5947,
    provincia_id: 6,
  },
  {
    id: 17155,
    localidad: "EL ARAÑADO",
    cp: 5947,
    provincia_id: 6,
  },
  {
    id: 17156,
    localidad: "EL ALTO",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 17157,
    localidad: "EL ALGARROBO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17158,
    localidad: "EL ALGARROBAL",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17159,
    localidad: "EL ALCALDE",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 17160,
    localidad: "EL AGUILA BLANCA",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17161,
    localidad: "EL  PANTANILLO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17162,
    localidad: "E PUNTA DE AGUA",
    cp: 5843,
    provincia_id: 6,
  },
  {
    id: 17163,
    localidad: "E LAS MERCEDES",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17164,
    localidad: "E LA MOROCHA",
    cp: 2428,
    provincia_id: 6,
  },
  {
    id: 17165,
    localidad: "E LA CHIQUITA",
    cp: 2428,
    provincia_id: 6,
  },
  {
    id: 17166,
    localidad: "E G ARENALES",
    cp: 6005,
    provincia_id: 6,
  },
  {
    id: 17167,
    localidad: "E EL NACIONAL",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17168,
    localidad: "E DE GUADALUPE",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17169,
    localidad: "E DE ARTILLERIA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17170,
    localidad: "E AGRI SALESIANA",
    cp: 6003,
    provincia_id: 6,
  },
  {
    id: 17171,
    localidad: "E 30 DE OCTUBRE",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 17172,
    localidad: "DURAZNO",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17173,
    localidad: "DUARTE QUIROS",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 17174,
    localidad: "DR NICASIO S OROQO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17175,
    localidad: "DOS RIOS",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17176,
    localidad: "DOS RIOS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17177,
    localidad: "DOS LAGUNAS",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17178,
    localidad: "DOS ARROYOS",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17179,
    localidad: "DOMINGUEZ",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 17180,
    localidad: "DOMINGO FUNES",
    cp: 5164,
    provincia_id: 6,
  },
  {
    id: 17181,
    localidad: "DOLORES SAN ESTEBAN",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 17182,
    localidad: "DOLORES S ESTEBAN",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 17183,
    localidad: "DOCE DE AGOSTO",
    cp: 2700,
    provincia_id: 6,
  },
  {
    id: 17184,
    localidad: "DISTRITO PUEBLO",
    cp: 5160,
    provincia_id: 6,
  },
  {
    id: 17185,
    localidad: "DIQUE SAN ROQUE",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 17186,
    localidad: "DIQUE LOS SAUCES",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17187,
    localidad: "DIQUE LOS SAUCES",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 17188,
    localidad: "DIQUE LOS MOLINOS",
    cp: 5192,
    provincia_id: 6,
  },
  {
    id: 17189,
    localidad: "DIQUE LA VIQA",
    cp: 5885,
    provincia_id: 6,
  },
  {
    id: 17190,
    localidad: "DIEZ RIOS",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17191,
    localidad: "DIEGO DE ROJAS",
    cp: 5136,
    provincia_id: 6,
  },
  {
    id: 17192,
    localidad: "DIEGO DE ROJAS",
    cp: 4856,
    provincia_id: 6,
  },
  {
    id: 17193,
    localidad: "DEVOTO",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17194,
    localidad: "DESVIO KM 95",
    cp: 6007,
    provincia_id: 6,
  },
  {
    id: 17195,
    localidad: "DESVIO KM 57",
    cp: 2625,
    provincia_id: 6,
  },
  {
    id: 17196,
    localidad: "DESVIO CHALACEA",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17197,
    localidad: "DESPEQADEROS",
    cp: 5121,
    provincia_id: 6,
  },
  {
    id: 17198,
    localidad: "DESPEÑADEROS",
    cp: 5121,
    provincia_id: 6,
  },
  {
    id: 17199,
    localidad: "DESAGUADERO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17200,
    localidad: "DEL CAMPILLO",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 17201,
    localidad: "DEAN FUNES",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17202,
    localidad: "DE LA SERNA",
    cp: 6271,
    provincia_id: 6,
  },
  {
    id: 17203,
    localidad: "DALMACIO VELEZ SARSFIELD",
    cp: 5919,
    provincia_id: 6,
  },
  {
    id: 17204,
    localidad: "DALMACIO V SARSFI",
    cp: 5919,
    provincia_id: 6,
  },
  {
    id: 17205,
    localidad: "D LAS VAQUERIAS",
    cp: 5168,
    provincia_id: 6,
  },
  {
    id: 17206,
    localidad: "CUESTA BLANCA",
    cp: 5152,
    provincia_id: 6,
  },
  {
    id: 17207,
    localidad: "CUCHILLO YACO",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 17208,
    localidad: "CUCHILLA NEVADA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17209,
    localidad: "CUATRO VIENTOS",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 17210,
    localidad: "CUATRO ESQUINAS",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 17211,
    localidad: "CUATRO CAMINOS",
    cp: 2550,
    provincia_id: 6,
  },
  {
    id: 17212,
    localidad: "CRUZ DEL QUEMADO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17213,
    localidad: "CRUZ DEL EJE",
    cp: 5280,
    provincia_id: 6,
  },
  {
    id: 17214,
    localidad: "CRUZ DE CAÑA",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 17215,
    localidad: "CRUZ DE CAÑA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17216,
    localidad: "CRUZ CHICA",
    cp: 5178,
    provincia_id: 6,
  },
  {
    id: 17217,
    localidad: "CRUZ ALTA",
    cp: 2189,
    provincia_id: 6,
  },
  {
    id: 17218,
    localidad: "CRISTINA",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17219,
    localidad: "COTAGAITA",
    cp: 2419,
    provincia_id: 6,
  },
  {
    id: 17220,
    localidad: "COSTA SACATE",
    cp: 5961,
    provincia_id: 6,
  },
  {
    id: 17221,
    localidad: "COSTA SACATE",
    cp: 6014,
    provincia_id: 6,
  },
  {
    id: 17222,
    localidad: "COSTA DEL CASTAQO",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17223,
    localidad: "COSQUIN",
    cp: 5166,
    provincia_id: 6,
  },
  {
    id: 17224,
    localidad: "CORTADERAS",
    cp: 2661,
    provincia_id: 6,
  },
  {
    id: 17225,
    localidad: "CORRALITO",
    cp: 5853,
    provincia_id: 6,
  },
  {
    id: 17226,
    localidad: "CORRAL VIEJO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17227,
    localidad: "CORRAL DEL BAJO",
    cp: 5913,
    provincia_id: 6,
  },
  {
    id: 17228,
    localidad: "CORRAL DE MULAS",
    cp: 5945,
    provincia_id: 6,
  },
  {
    id: 17229,
    localidad: "CORRAL DE GUARDIA",
    cp: 5940,
    provincia_id: 6,
  },
  {
    id: 17230,
    localidad: "CORRAL DE GOMEZ",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 17231,
    localidad: "CORRAL DE GOMEZ",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 17232,
    localidad: "CORRAL DE CEBALLOS",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 17233,
    localidad: "CORRAL DE BUSTOS",
    cp: 2645,
    provincia_id: 6,
  },
  {
    id: 17234,
    localidad: "CORRAL DE BARRANCA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17235,
    localidad: "CORONEL OLMEDO",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 17236,
    localidad: "CORONEL MOLDES",
    cp: 5847,
    provincia_id: 6,
  },
  {
    id: 17237,
    localidad: "CORONEL BAIGORRIA",
    cp: 5811,
    provincia_id: 6,
  },
  {
    id: 17238,
    localidad: "CORITO",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17239,
    localidad: "CORIMAYO",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17240,
    localidad: "CORDOBA - YOFRE SUD",
    cp: 5013,
    provincia_id: 6,
  },
  {
    id: 17241,
    localidad: "CORDOBA - YOFRE NORTE/SUD",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17242,
    localidad: "CORDOBA - YOFRE NORTE",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17243,
    localidad: "CORDOBA - YAPEYU / A GRAL PAZ",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17244,
    localidad: "CORDOBA - YAPEYU",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17245,
    localidad: "CORDOBA - WALKER",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17246,
    localidad: "CORDOBA - VILLA WARCALDE",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17247,
    localidad: "CORDOBA - VILLA URQUIZA",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17248,
    localidad: "CORDOBA - VILLA SOLFERINO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17249,
    localidad: "CORDOBA - VILLA SILBURU",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17250,
    localidad: "CORDOBA - VILLA SERRANA",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17251,
    localidad: "CORDOBA - VILLA RIVERA INDARTE",
    cp: 5018,
    provincia_id: 6,
  },
  {
    id: 17252,
    localidad: "CORDOBA - VILLA REVOL",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17253,
    localidad: "CORDOBA - VILLA PAEZ",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17254,
    localidad: "CORDOBA - VILLA OFELIA",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17255,
    localidad: "CORDOBA - VILLA MARTA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17256,
    localidad: "CORDOBA - VILLA LOS PINOS",
    cp: 5013,
    provincia_id: 6,
  },
  {
    id: 17257,
    localidad: "CORDOBA - VILLA LOS ANGELES",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17258,
    localidad: "CORDOBA - VILLA EUCARISTICA",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17259,
    localidad: "CORDOBA - VILLA EL LIBERTADOR",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17260,
    localidad: "CORDOBA - VILLA CORNUI",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17261,
    localidad: "CORDOBA - VILLA CORNU",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17262,
    localidad: "CORDOBA - VILLA CLARET",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17263,
    localidad: "CORDOBA - VILLA CABRERA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17264,
    localidad: "CORDOBA - VILLA CABRERA",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17265,
    localidad: "CORDOBA - VILLA CABRERA",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17266,
    localidad: "CORDOBA - VILLA BELGRANO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17267,
    localidad: "CORDOBA - VILLA AZALAIS",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17268,
    localidad: "CORDOBA - VILLA ARGUELLO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17269,
    localidad: "CORDOBA - VILLA ALLENDE PARQUE",
    cp: 5018,
    provincia_id: 6,
  },
  {
    id: 17270,
    localidad: "CORDOBA - VILLA ALBORADA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17271,
    localidad: "CORDOBA - VILLA 9 DE JULIO",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17272,
    localidad: "CORDOBA - VELEZ SARSFIELD",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17273,
    localidad: "CORDOBA - V SAN CARLOS",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17274,
    localidad: "CORDOBA - V CENTENARIO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17275,
    localidad: "CORDOBA - URCA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17276,
    localidad: "CORDOBA - UOCRA",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17277,
    localidad: "CORDOBA - U O C R A",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17278,
    localidad: "CORDOBA - TRANVIARIO",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17279,
    localidad: "CORDOBA - TALLERES SUD",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17280,
    localidad: "CORDOBA - TABLADA PARK",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17281,
    localidad: "CORDOBA - SUQUIA",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17282,
    localidad: "CORDOBA - SUAREZ",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17283,
    localidad: "CORDOBA - SILVANO FUNES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17284,
    localidad: "CORDOBA - SANTA ISABEL 3",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17285,
    localidad: "CORDOBA - SANTA ISABEL 2",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17286,
    localidad: "CORDOBA - SANTA ISABEL 1",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17287,
    localidad: "CORDOBA - SANTA CECILIA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17288,
    localidad: "CORDOBA - SANTA CECILIA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17289,
    localidad: "CORDOBA - SANTA ANA",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17290,
    localidad: "CORDOBA - SAN VICENTE/MAULLER",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17291,
    localidad: "CORDOBA - SAN VICENTE",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17292,
    localidad: "CORDOBA - SAN VICENTE",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17293,
    localidad: "CORDOBA - SAN SALVADOR",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17294,
    localidad: "CORDOBA - SAN ROQUE",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17295,
    localidad: "CORDOBA - SAN PABLO/R S CARLOS",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17296,
    localidad: "CORDOBA - SAN NICOLAS",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17297,
    localidad: "CORDOBA - SAN MARTIN N",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17298,
    localidad: "CORDOBA - SAN MARTIN ANEXO",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17299,
    localidad: "CORDOBA - SAN MARTIN",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17300,
    localidad: "CORDOBA - SAN MARTIN",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17301,
    localidad: "CORDOBA - SAN LUIS DE FRANCIA",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17302,
    localidad: "CORDOBA - SAN LORENZO SUD",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17303,
    localidad: "CORDOBA - SAN JAVIER",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17304,
    localidad: "CORDOBA - SAN FRANCISCO",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17305,
    localidad: "CORDOBA - SALE SITRAM",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17306,
    localidad: "CORDOBA - SALDAN",
    cp: 5018,
    provincia_id: 6,
  },
  {
    id: 17307,
    localidad: "CORDOBA - S FERNAN/ GRAL ARTIGAS",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17308,
    localidad: "CORDOBA - RUCCI",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17309,
    localidad: "CORDOBA - RIVERA INDARTE",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17310,
    localidad: "CORDOBA - RESIDENCIAL SAN JOSE",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17311,
    localidad: "CORDOBA - RESID VELEZ SARSFIELD",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17312,
    localidad: "CORDOBA - RES SAN CARLOS/B MASSE",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17313,
    localidad: "CORDOBA - RES COUNTRY CLUB",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17314,
    localidad: "CORDOBA - RES CH CARRERAS",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17315,
    localidad: "CORDOBA - RES AMERICA",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17316,
    localidad: "CORDOBA - RENACIMIENTO/1 DE MAYO",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17317,
    localidad: "CORDOBA - RENACIMIENTO",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17318,
    localidad: "CORDOBA - RENACIMIENTO",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17319,
    localidad: "CORDOBA - REMEDIOS DE ESCALADA",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17320,
    localidad: "CORDOBA - QUINTAS DE ARGUELLO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17321,
    localidad: "CORDOBA - QUEBRADA LAS ROSAS",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17322,
    localidad: "CORDOBA - PUEYRREDON",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17323,
    localidad: "CORDOBA - PROVIDENCIA - VILLA CABRERA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17324,
    localidad: "CORDOBA - PROVIDENCIA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17325,
    localidad: "CORDOBA - PROVIDENCIA",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17326,
    localidad: "CORDOBA - PQUE LICEO",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17327,
    localidad: "CORDOBA - PQUE ATLANTICA",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17328,
    localidad: "CORDOBA - PORTAL JACARANDA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17329,
    localidad: "CORDOBA - PORTAL DEL JACARANDA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17330,
    localidad: "CORDOBA - POETA LUGONES",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17331,
    localidad: "CORDOBA - PATRICIOS O Y N",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17332,
    localidad: "CORDOBA - PATRICIOS",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17333,
    localidad: "CORDOBA - PASO DE LOS ANDES",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17334,
    localidad: "CORDOBA - PARQUE VELEZ SARSFIELD",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17335,
    localidad: "CORDOBA - PARQUE SARMIENTO",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17336,
    localidad: "CORDOBA - PARQUE SAN VICENTE",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17337,
    localidad: "CORDOBA - PARQUE REPUBLICA",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17338,
    localidad: "CORDOBA - PARQUE MODELO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17339,
    localidad: "CORDOBA - PARQUE LOS MOLINOS",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17340,
    localidad: "CORDOBA - PARQUE LICEO 1 2 3",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17341,
    localidad: "CORDOBA - PARQUE LICEO",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17342,
    localidad: "CORDOBA - PARQUE LATINO",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17343,
    localidad: "CORDOBA - PARQUE DON BOSCO",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17344,
    localidad: "CORDOBA - PARQUE CAPITAL",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17345,
    localidad: "CORDOBA - PALMAR / AMP PALMAR",
    cp: 5013,
    provincia_id: 6,
  },
  {
    id: 17346,
    localidad: "CORDOBA - PALMAR",
    cp: 5013,
    provincia_id: 6,
  },
  {
    id: 17347,
    localidad: "CORDOBA - PALAMARO ONOFRIO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17348,
    localidad: "CORDOBA - OÑA/B MASSE",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17349,
    localidad: "CORDOBA - OLIVOS",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17350,
    localidad: "CORDOBA - OBRERO",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17351,
    localidad: "CORDOBA - NUEVA ITALIA",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17352,
    localidad: "CORDOBA - NUEVA CORDOBA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17353,
    localidad: "CORDOBA - NEWBERY/J L CABRERA",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17354,
    localidad: "CORDOBA - MERCANTIL",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17355,
    localidad: "CORDOBA - MAULLER",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17356,
    localidad: "CORDOBA - MARCELO T DE ALVEAR",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17357,
    localidad: "CORDOBA - MALDONADO",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17358,
    localidad: "CORDOBA - M SOBREMONTE",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17359,
    localidad: "CORDOBA - M DE SOBREMONTE",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17360,
    localidad: "CORDOBA - LOURDES",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17361,
    localidad: "CORDOBA - LOS TALAS",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17362,
    localidad: "CORDOBA - LOS SAUCES",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17363,
    localidad: "CORDOBA - LOS ROBLES",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17364,
    localidad: "CORDOBA - LOS PLATANOS",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17365,
    localidad: "CORDOBA - LOS PLATANOS",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17366,
    localidad: "CORDOBA - LOS PATRICIOS NORTE",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17367,
    localidad: "CORDOBA - LOS PARAISOS",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17368,
    localidad: "CORDOBA - LOS OLMOS SUR",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17369,
    localidad: "CORDOBA - LOS OLMOS",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17370,
    localidad: "CORDOBA - LOS MOLLES",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17371,
    localidad: "CORDOBA - LOS MISTOLES",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17372,
    localidad: "CORDOBA - LOS HUALLACANES",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17373,
    localidad: "CORDOBA - LOS GRANADOS",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17374,
    localidad: "CORDOBA - LOS FRESNOS",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17375,
    localidad: "CORDOBA - LOS CERVECEROS",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17376,
    localidad: "CORDOBA - LOS CEIBOS",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17377,
    localidad: "CORDOBA - LOS CAROLINOS",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17378,
    localidad: "CORDOBA - LOS BOULEVARES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17379,
    localidad: "CORDOBA - LOS ALGARROBOS",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17380,
    localidad: "CORDOBA - LOMAS DE SAN MARTIN",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17381,
    localidad: "CORDOBA - LICEO 2",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17382,
    localidad: "CORDOBA - LEANDRO N ALEM",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17383,
    localidad: "CORDOBA - LASALLE",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17384,
    localidad: "CORDOBA - LAS VIOLETAS",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17385,
    localidad: "CORDOBA - LAS VIOLETAS",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17386,
    localidad: "CORDOBA - LAS ROSAS",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17387,
    localidad: "CORDOBA - LAS MARGARITAS/A S MARTIN",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17388,
    localidad: "CORDOBA - LAS MARGARITAS/A S MARTIN",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17389,
    localidad: "CORDOBA - LAS MARGARITAS",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17390,
    localidad: "CORDOBA - LAS MAGNOLIAS",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17391,
    localidad: "CORDOBA - LAS FLORES",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17392,
    localidad: "CORDOBA - LAS FLORES",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17393,
    localidad: "CORDOBA - LAS DALIAS",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17394,
    localidad: "CORDOBA - LAMADRID",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17395,
    localidad: "CORDOBA - LA TABLADA",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17396,
    localidad: "CORDOBA - LA FRATERNIDAD",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17397,
    localidad: "CORDOBA - KENNEDY",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17398,
    localidad: "CORDOBA - JUNIORS",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17399,
    localidad: "CORDOBA - JOSE IGNACIO DIAZ SECCION 2",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17400,
    localidad: "CORDOBA - JORGE NEWBERY",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17401,
    localidad: "CORDOBA - JARDIN/RES SAN CARLOS",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17402,
    localidad: "CORDOBA - JARDIN/RES SAN CARLOS",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17403,
    localidad: "CORDOBA - JARDIN ESPINOSA",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17404,
    localidad: "CORDOBA - JARDIN DEL PILAR",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17405,
    localidad: "CORDOBA - JARDIN",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17406,
    localidad: "CORDOBA - J L DE LA CABRERA",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17407,
    localidad: "CORDOBA - J L DE CABRERA",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17408,
    localidad: "CORDOBA - J HIPODROMO",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17409,
    localidad: "CORDOBA - J ESPINOSA",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17410,
    localidad: "CORDOBA - IVIFA",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17411,
    localidad: "CORDOBA - ITUZAINGO ANEXO",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17412,
    localidad: "CORDOBA - IPONA/ S FERNANDO",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17413,
    localidad: "CORDOBA - INDUSTRIAL",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17414,
    localidad: "CORDOBA - INDEPENDENCIA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17415,
    localidad: "CORDOBA - HIPOLITO YRIGOYEN",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17416,
    localidad: "CORDOBA - HERMOSO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17417,
    localidad: "CORDOBA - H YRIGOYEN",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17418,
    localidad: "CORDOBA - GUIÑAZU",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17419,
    localidad: "CORDOBA - GUEMES",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17420,
    localidad: "CORDOBA - GUAYAQUIL",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17421,
    localidad: "CORDOBA - GRANJA FUNES-LOS BOULEVARES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17422,
    localidad: "CORDOBA - GRANJA FUNES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17423,
    localidad: "CORDOBA - GRANJA FUNES",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17424,
    localidad: "CORDOBA - GRANJA DE FUNES",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17425,
    localidad: "CORDOBA - GRANJA DE FUNES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17426,
    localidad: "CORDOBA - GRAL URQUIZA",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17427,
    localidad: "CORDOBA - GRAL PAZ / JUNIORS",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17428,
    localidad: "CORDOBA - GENERAL SAVIO",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17429,
    localidad: "CORDOBA - GENERAL DEHEZA",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17430,
    localidad: "CORDOBA - GENERAL BUSTOS",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17431,
    localidad: "CORDOBA - FERROVIARIO MITRE",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17432,
    localidad: "CORDOBA - FERRIVIARIO MITRE",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17433,
    localidad: "CORDOBA - FERREYRA",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17434,
    localidad: "CORDOBA - ESTADIO CHATEAU CARRERAS",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17435,
    localidad: "CORDOBA - ESTACION FLORES",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17436,
    localidad: "CORDOBA - EST FLORES",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17437,
    localidad: "CORDOBA - EMPALME AMP",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17438,
    localidad: "CORDOBA - EL TREBOL",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17439,
    localidad: "CORDOBA - EL RINCON",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17440,
    localidad: "CORDOBA - EL REFUGIO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17441,
    localidad: "CORDOBA - EL MIRADOR DE LA RECTA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17442,
    localidad: "CORDOBA - EL MIRADOR",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17443,
    localidad: "CORDOBA - EL CONDOR",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17444,
    localidad: "CORDOBA - E FLORES",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17445,
    localidad: "CORDOBA - DEAN FUNES",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17446,
    localidad: "CORDOBA - CRISOL NORTE",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17447,
    localidad: "CORDOBA - CRISOL N",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17448,
    localidad: "CORDOBA - CORONEL OLMEDO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17449,
    localidad: "CORDOBA - COMERCIAL",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17450,
    localidad: "CORDOBA - COMANDO 3 CUERPO DE EJERCITO",
    cp: 5023,
    provincia_id: 6,
  },
  {
    id: 17451,
    localidad: "CORDOBA - COLON",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17452,
    localidad: "CORDOBA - COLINAS DEL CERRO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17453,
    localidad: "CORDOBA - COLINA LOS PINOS",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17454,
    localidad: "CORDOBA - COL DEL CERRO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17455,
    localidad: "CORDOBA - CNEL OLMEDO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17456,
    localidad: "CORDOBA - CHATEAU CARRERAS",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17457,
    localidad: "CORDOBA - CERVECEROS",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17458,
    localidad: "CORDOBA - CERRO NORTE",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17459,
    localidad: "CORDOBA - CERRO LAS ROSAS",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17460,
    localidad: "CORDOBA - CERRO CHICO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17461,
    localidad: "CORDOBA - CENTRO AMERICA",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17462,
    localidad: "CORDOBA - CENTRO AMERICA",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17463,
    localidad: "CORDOBA - CENTRO",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17464,
    localidad: "CORDOBA - CELSO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17465,
    localidad: "CORDOBA - CELSO",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17466,
    localidad: "CORDOBA - CDA GENERAL BUSTOS",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17467,
    localidad: "CORDOBA - CARCANO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17468,
    localidad: "CORDOBA - CAÑAVERAL",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17469,
    localidad: "CORDOBA - CABILDO",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17470,
    localidad: "CORDOBA - C DEL CERRO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17471,
    localidad: "CORDOBA - BRIGADIER SAN MARTIN",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17472,
    localidad: "CORDOBA - BOULEVARES",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17473,
    localidad: "CORDOBA - BELLA VISTA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17474,
    localidad: "CORDOBA - BAJADA DE PIEDRA",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17475,
    localidad: "CORDOBA - AYACUCHO",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17476,
    localidad: "CORDOBA - AVENIDA",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17477,
    localidad: "CORDOBA - AVELLANEDA",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17478,
    localidad: "CORDOBA - AUTODROMO",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17479,
    localidad: "CORDOBA - ARGUELLO/LOS NOGALES",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17480,
    localidad: "CORDOBA - ARGUELLO SUR",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17481,
    localidad: "CORDOBA - ARGUELLO NORTE",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17482,
    localidad: "CORDOBA - ARGUELLO",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17483,
    localidad: "CORDOBA - ARGUELLO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17484,
    localidad: "CORDOBA - AMPLIACION SAN PABLO",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17485,
    localidad: "CORDOBA - AMP RESIDENCIAL AMERICA",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17486,
    localidad: "CORDOBA - AMP EMPALME",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17487,
    localidad: "CORDOBA - AMEGHINO NORTE",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17488,
    localidad: "CORDOBA - ALTOS DE VELEZ SARSFIELD",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17489,
    localidad: "CORDOBA - ALTO VERDE",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17490,
    localidad: "CORDOBA - ALTO V CABRERA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17491,
    localidad: "CORDOBA - ALTO HERMOSO",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17492,
    localidad: "CORDOBA - ALTO GRALPAZ",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17493,
    localidad: "CORDOBA - ALTO ALBERDI",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17494,
    localidad: "CORDOBA - ALTO ALBERDI",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17495,
    localidad: "CORDOBA - ALTE BROWN",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17496,
    localidad: "CORDOBA - ALTA CORDOBA",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17497,
    localidad: "CORDOBA - ALTA CORDOBA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17498,
    localidad: "CORDOBA - ALEJANDRO CENTENO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17499,
    localidad: "CORDOBA - ALBORADA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17500,
    localidad: "CORDOBA - ALBORADA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17501,
    localidad: "CORDOBA - ALBERDI",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17502,
    localidad: "CORDOBA - AERONAUTICO",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17503,
    localidad: "CORDOBA - AERONATICO",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17504,
    localidad: "CORDOBA - A CENTENO",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17505,
    localidad: "CORDOBA - A CARBO/COMERCIAL",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17506,
    localidad: "CORDOBA - A ALBERDI / MARECHAL",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17507,
    localidad: "CORDOBA - 9 DE JULIO",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17508,
    localidad: "CORDOBA - 28",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17509,
    localidad: "CORDOBA - 25 DE MAYO",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17510,
    localidad: "CORDOBA - 1 DE MAYO / MIRALTA",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17511,
    localidad: "CORDOBA - 1 DE MAYO",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17512,
    localidad: "CORDOBA",
    cp: 5010,
    provincia_id: 6,
  },
  {
    id: 17513,
    localidad: "CORDOBA",
    cp: 5011,
    provincia_id: 6,
  },
  {
    id: 17514,
    localidad: "CORDOBA",
    cp: 5000,
    provincia_id: 6,
  },
  {
    id: 17515,
    localidad: "CORDOBA",
    cp: 5020,
    provincia_id: 6,
  },
  {
    id: 17516,
    localidad: "CORDOBA",
    cp: 5002,
    provincia_id: 6,
  },
  {
    id: 17517,
    localidad: "CORDOBA",
    cp: 5001,
    provincia_id: 6,
  },
  {
    id: 17518,
    localidad: "CORDOBA",
    cp: 5021,
    provincia_id: 6,
  },
  {
    id: 17519,
    localidad: "CORDOBA",
    cp: 5003,
    provincia_id: 6,
  },
  {
    id: 17520,
    localidad: "CORDOBA",
    cp: 5004,
    provincia_id: 6,
  },
  {
    id: 17521,
    localidad: "CORDOBA",
    cp: 5005,
    provincia_id: 6,
  },
  {
    id: 17522,
    localidad: "CORDOBA",
    cp: 5006,
    provincia_id: 6,
  },
  {
    id: 17523,
    localidad: "CORDOBA",
    cp: 5009,
    provincia_id: 6,
  },
  {
    id: 17524,
    localidad: "CORDOBA",
    cp: 5103,
    provincia_id: 6,
  },
  {
    id: 17525,
    localidad: "CORDOBA",
    cp: 5023,
    provincia_id: 6,
  },
  {
    id: 17526,
    localidad: "CORDOBA",
    cp: 5024,
    provincia_id: 6,
  },
  {
    id: 17527,
    localidad: "CORDOBA",
    cp: 5012,
    provincia_id: 6,
  },
  {
    id: 17528,
    localidad: "CORDOBA",
    cp: 5017,
    provincia_id: 6,
  },
  {
    id: 17529,
    localidad: "CORDOBA",
    cp: 5007,
    provincia_id: 6,
  },
  {
    id: 17530,
    localidad: "CORDOBA",
    cp: 5022,
    provincia_id: 6,
  },
  {
    id: 17531,
    localidad: "CORDOBA",
    cp: 5018,
    provincia_id: 6,
  },
  {
    id: 17532,
    localidad: "CORDOBA",
    cp: 5019,
    provincia_id: 6,
  },
  {
    id: 17533,
    localidad: "CORDOBA",
    cp: 5015,
    provincia_id: 6,
  },
  {
    id: 17534,
    localidad: "CORDOBA",
    cp: 5008,
    provincia_id: 6,
  },
  {
    id: 17535,
    localidad: "CORDOBA",
    cp: 5016,
    provincia_id: 6,
  },
  {
    id: 17536,
    localidad: "CORDOBA",
    cp: 5014,
    provincia_id: 6,
  },
  {
    id: 17537,
    localidad: "CORDOBA",
    cp: 5013,
    provincia_id: 6,
  },
  {
    id: 17538,
    localidad: "COPINA",
    cp: 5153,
    provincia_id: 6,
  },
  {
    id: 17539,
    localidad: "COPACABANA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17540,
    localidad: "CONSTITUCION",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 17541,
    localidad: "CONLARA",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 17542,
    localidad: "CONDOR HUASI",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17543,
    localidad: "CONCEPCION",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17544,
    localidad: "COMECHINGONES",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 17545,
    localidad: "COME TIERRA",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17546,
    localidad: "COLONIAS",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 17547,
    localidad: "COLONIA YUCAT SUD",
    cp: 5917,
    provincia_id: 6,
  },
  {
    id: 17548,
    localidad: "COLONIA YARETA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17549,
    localidad: "COLONIA VIGNAUD",
    cp: 2420,
    provincia_id: 6,
  },
  {
    id: 17550,
    localidad: "COLONIA VALTELINA",
    cp: 2414,
    provincia_id: 6,
  },
  {
    id: 17551,
    localidad: "COLONIA UDINE",
    cp: 2417,
    provincia_id: 6,
  },
  {
    id: 17552,
    localidad: "COLONIA TORO PUJIO",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17553,
    localidad: "COLONIA TIROLESA",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17554,
    localidad: "COLONIA TACURALES",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 17555,
    localidad: "COLONIA SILVIO PELLICO",
    cp: 5910,
    provincia_id: 6,
  },
  {
    id: 17556,
    localidad: "COLONIA SANTA RITA",
    cp: 5936,
    provincia_id: 6,
  },
  {
    id: 17557,
    localidad: "COLONIA SANTA PAULA",
    cp: 5805,
    provincia_id: 6,
  },
  {
    id: 17558,
    localidad: "COLONIA SANTA CATALINA",
    cp: 5851,
    provincia_id: 6,
  },
  {
    id: 17559,
    localidad: "COLONIA SAN RAFAEL",
    cp: 2433,
    provincia_id: 6,
  },
  {
    id: 17560,
    localidad: "COLONIA SAN PEDRO",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 17561,
    localidad: "COLONIA SAN ISIDRO",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17562,
    localidad: "COLONIA SAN BARTOLOME",
    cp: 2426,
    provincia_id: 6,
  },
  {
    id: 17563,
    localidad: "COLONIA PROGRESO",
    cp: 2645,
    provincia_id: 6,
  },
  {
    id: 17564,
    localidad: "COLONIA PRODAMONTE",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 17565,
    localidad: "COLONIA ORCOVI",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 17566,
    localidad: "COLONIA MONTEFIORE",
    cp: 2341,
    provincia_id: 6,
  },
  {
    id: 17567,
    localidad: "COLONIA MASCHI",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 17568,
    localidad: "COLONIA MARINA",
    cp: 2367,
    provincia_id: 6,
  },
  {
    id: 17569,
    localidad: "COLONIA MAIPU",
    cp: 2685,
    provincia_id: 6,
  },
  {
    id: 17570,
    localidad: "COLONIA LUQUE",
    cp: 5850,
    provincia_id: 6,
  },
  {
    id: 17571,
    localidad: "COLONIA LOS HORNOS",
    cp: 6007,
    provincia_id: 6,
  },
  {
    id: 17572,
    localidad: "COLONIA LEDESMA",
    cp: 2662,
    provincia_id: 6,
  },
  {
    id: 17573,
    localidad: "COLONIA LAVARELLO",
    cp: 2415,
    provincia_id: 6,
  },
  {
    id: 17574,
    localidad: "COLONIA LA MOROCHA",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 17575,
    localidad: "COLONIA LA LOLA",
    cp: 2650,
    provincia_id: 6,
  },
  {
    id: 17576,
    localidad: "COLONIA LA CALLE",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17577,
    localidad: "COLONIA LA BEBA",
    cp: 6003,
    provincia_id: 6,
  },
  {
    id: 17578,
    localidad: "COLONIA ITURRASPE",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 17579,
    localidad: "COLONIA ITALIANA",
    cp: 2646,
    provincia_id: 6,
  },
  {
    id: 17580,
    localidad: "COLONIA HAMBURGO",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 17581,
    localidad: "COLONIA GERALDINA",
    cp: 2342,
    provincia_id: 6,
  },
  {
    id: 17582,
    localidad: "COLONIA GARZON",
    cp: 5987,
    provincia_id: 6,
  },
  {
    id: 17583,
    localidad: "COLONIA EUGENIA",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 17584,
    localidad: "COLONIA EL TRABAJO",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17585,
    localidad: "COLONIA EL MILAGRO",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17586,
    localidad: "COLONIA EL FORTIN",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 17587,
    localidad: "COLONIA EL CHAJA",
    cp: 2594,
    provincia_id: 6,
  },
  {
    id: 17588,
    localidad: "COLONIA DOROTEA",
    cp: 6270,
    provincia_id: 6,
  },
  {
    id: 17589,
    localidad: "COLONIA DEAN FUNES",
    cp: 5847,
    provincia_id: 6,
  },
  {
    id: 17590,
    localidad: "COLONIA CRISTINA",
    cp: 2424,
    provincia_id: 6,
  },
  {
    id: 17591,
    localidad: "COLONIA COYUNDA",
    cp: 2435,
    provincia_id: 6,
  },
  {
    id: 17592,
    localidad: "COLONIA COSME SUD",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17593,
    localidad: "COLONIA CORTADERA",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 17594,
    localidad: "COLONIA CAROYA",
    cp: 5223,
    provincia_id: 6,
  },
  {
    id: 17595,
    localidad: "COLONIA CAQADON",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17596,
    localidad: "COLONIA CALCHAQUI",
    cp: 2580,
    provincia_id: 6,
  },
  {
    id: 17597,
    localidad: "COLONIA BREMEN",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 17598,
    localidad: "COLONIA BOTTURI",
    cp: 2419,
    provincia_id: 6,
  },
  {
    id: 17599,
    localidad: "COLONIA BISMARCK",
    cp: 2652,
    provincia_id: 6,
  },
  {
    id: 17600,
    localidad: "COLONIA BISMAR",
    cp: 2651,
    provincia_id: 6,
  },
  {
    id: 17601,
    localidad: "COLONIA BEIRO",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 17602,
    localidad: "COLONIA BARGE",
    cp: 2659,
    provincia_id: 6,
  },
  {
    id: 17603,
    localidad: "COLONIA ANITA",
    cp: 2413,
    provincia_id: 6,
  },
  {
    id: 17604,
    localidad: "COLONIA ANGELITA",
    cp: 5941,
    provincia_id: 6,
  },
  {
    id: 17605,
    localidad: "COLONIA ALPINA",
    cp: 2341,
    provincia_id: 6,
  },
  {
    id: 17606,
    localidad: "COLONIA ALMADA",
    cp: 5987,
    provincia_id: 6,
  },
  {
    id: 17607,
    localidad: "COLONIA ALEMANA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17608,
    localidad: "COLAZO",
    cp: 6028,
    provincia_id: 6,
  },
  {
    id: 17609,
    localidad: "COLAZO",
    cp: 5981,
    provincia_id: 6,
  },
  {
    id: 17610,
    localidad: "COLAZO",
    cp: 5971,
    provincia_id: 6,
  },
  {
    id: 17611,
    localidad: "COCHEQUINGAN",
    cp: 6216,
    provincia_id: 6,
  },
  {
    id: 17612,
    localidad: "CINTRA",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 17613,
    localidad: "CIENAGA DEL CORO",
    cp: 5289,
    provincia_id: 6,
  },
  {
    id: 17614,
    localidad: "CIENAGA DE ALLENDE",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 17615,
    localidad: "CHURQUI CAQADA",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17616,
    localidad: "CHURQUI",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17617,
    localidad: "CHUQA HUASI",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17618,
    localidad: "CHUQA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17619,
    localidad: "CHUÑA",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17620,
    localidad: "CHUCUL",
    cp: 5822,
    provincia_id: 6,
  },
  {
    id: 17621,
    localidad: "CHUCHIRAS",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17622,
    localidad: "CHUA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17623,
    localidad: "CHIPITIN",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17624,
    localidad: "CHILLI CORRAL",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17625,
    localidad: "CHILIBROSTE",
    cp: 2561,
    provincia_id: 6,
  },
  {
    id: 17626,
    localidad: "CHILE C AL AGAUDA",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17627,
    localidad: "CHAZON",
    cp: 2675,
    provincia_id: 6,
  },
  {
    id: 17628,
    localidad: "CHATEAUBRIAND",
    cp: 2600,
    provincia_id: 6,
  },
  {
    id: 17629,
    localidad: "CHARRAS",
    cp: 5814,
    provincia_id: 6,
  },
  {
    id: 17630,
    localidad: "CHARRAS",
    cp: 5807,
    provincia_id: 6,
  },
  {
    id: 17631,
    localidad: "CHARCAS NORTE",
    cp: 5127,
    provincia_id: 6,
  },
  {
    id: 17632,
    localidad: "CHARBONIER",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17633,
    localidad: "CHARACATO",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 17634,
    localidad: "CHAQUINCHUNA",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 17635,
    localidad: "CHAQARITOS",
    cp: 5829,
    provincia_id: 6,
  },
  {
    id: 17636,
    localidad: "CHAQARITOS",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 17637,
    localidad: "CHAQARIACO",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17638,
    localidad: "CHAQAR VIEJO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17639,
    localidad: "CHAMICO",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17640,
    localidad: "CHALACEA",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17641,
    localidad: "CHAJAN",
    cp: 5837,
    provincia_id: 6,
  },
  {
    id: 17642,
    localidad: "CHACRAS VIEJAS",
    cp: 5242,
    provincia_id: 6,
  },
  {
    id: 17643,
    localidad: "CHACRAS DEL SAUCE",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17644,
    localidad: "CHACRAS DEL POTRER",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17645,
    localidad: "CHACRAS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17646,
    localidad: "CHACHA DEL REY",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17647,
    localidad: "CERROS ASPEROS",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 17648,
    localidad: "CERRO SAN LORENZO",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 17649,
    localidad: "CERRO NEGRO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 17650,
    localidad: "CERRO NEGRO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17651,
    localidad: "CERRO DE LA CRUZ",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17652,
    localidad: "CERRO COLORADO",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 17653,
    localidad: "CERRO COLORADO",
    cp: 4298,
    provincia_id: 6,
  },
  {
    id: 17654,
    localidad: "CERRO BOLA",
    cp: 5293,
    provincia_id: 6,
  },
  {
    id: 17655,
    localidad: "CERRO BLANCO",
    cp: 5191,
    provincia_id: 6,
  },
  {
    id: 17656,
    localidad: "CENTENARIO",
    cp: 6279,
    provincia_id: 6,
  },
  {
    id: 17657,
    localidad: "CAYUQUEO",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 17658,
    localidad: "CAVANAGH",
    cp: 2625,
    provincia_id: 6,
  },
  {
    id: 17659,
    localidad: "CASTELLANOS",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 17660,
    localidad: "CASPICUCHANA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 17661,
    localidad: "CASPICHUMA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 17662,
    localidad: "CASIMIRO GOMEZ",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 17663,
    localidad: "CASCADAS",
    cp: 5178,
    provincia_id: 6,
  },
  {
    id: 17664,
    localidad: "CASAS VIEJAS",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17665,
    localidad: "CASAS VEJAS",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17666,
    localidad: "CASA SERRANA",
    cp: 5175,
    provincia_id: 6,
  },
  {
    id: 17667,
    localidad: "CASA NUEVA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17668,
    localidad: "CASA GRANDE",
    cp: 5162,
    provincia_id: 6,
  },
  {
    id: 17669,
    localidad: "CASA DE PIEDRA",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 17670,
    localidad: "CASA BLANCA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 17671,
    localidad: "CAS FFOUSTH E FCGB",
    cp: 5149,
    provincia_id: 6,
  },
  {
    id: 17672,
    localidad: "CARTABEROL",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17673,
    localidad: "CARRIZAL TAMA",
    cp: 5237,
    provincia_id: 6,
  },
  {
    id: 17674,
    localidad: "CARRIZAL",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17675,
    localidad: "CARRIZAL",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17676,
    localidad: "CARRIZAL",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17677,
    localidad: "CARRILOBO",
    cp: 5915,
    provincia_id: 6,
  },
  {
    id: 17678,
    localidad: "CAROYA",
    cp: 5223,
    provincia_id: 6,
  },
  {
    id: 17679,
    localidad: "CAROLINA",
    cp: 5841,
    provincia_id: 6,
  },
  {
    id: 17680,
    localidad: "CARNERO YACO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17681,
    localidad: "CARNERILLO",
    cp: 5805,
    provincia_id: 6,
  },
  {
    id: 17682,
    localidad: "CARLOMAGNO",
    cp: 5925,
    provincia_id: 6,
  },
  {
    id: 17683,
    localidad: "CARAHUASI",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17684,
    localidad: "CAQADAS HONDAS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17685,
    localidad: "CAQADA LARGA",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 17686,
    localidad: "CAQADA HONDA",
    cp: 5227,
    provincia_id: 6,
  },
  {
    id: 17687,
    localidad: "CAQADA GRANDE",
    cp: 5891,
    provincia_id: 6,
  },
  {
    id: 17688,
    localidad: "CAQADA GRANDE",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 17689,
    localidad: "CAQADA DEL TALA",
    cp: 5859,
    provincia_id: 6,
  },
  {
    id: 17690,
    localidad: "CAQADA DEL TALA",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17691,
    localidad: "CAQADA DEL SIMBOL",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17692,
    localidad: "CAQADA DEL SAUCE",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 17693,
    localidad: "CAQADA DEL PUERTO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 17694,
    localidad: "CAQADA DEL DURAZNO",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17695,
    localidad: "CAQADA DE SALAS",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17696,
    localidad: "CAQADA DE POCHO",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17697,
    localidad: "CAQADA DE MAYO",
    cp: 5218,
    provincia_id: 6,
  },
  {
    id: 17698,
    localidad: "CAQADA DE MATEO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17699,
    localidad: "CAQADA DE MACHADO",
    cp: 5961,
    provincia_id: 6,
  },
  {
    id: 17700,
    localidad: "CAQADA DE LUQUE",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17701,
    localidad: "CAQADA DE JUME",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17702,
    localidad: "CAQADA DE CORIA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17703,
    localidad: "CAQADA DE ALVAREZ",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 17704,
    localidad: "CAQA CRUZ",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17705,
    localidad: "CAQ DE LOS MOGOTES",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17706,
    localidad: "CAPILLA S ANTONIO",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 17707,
    localidad: "CAPILLA LA ESPZA",
    cp: 5129,
    provincia_id: 6,
  },
  {
    id: 17708,
    localidad: "CAPILLA LA CANDELARIA",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 17709,
    localidad: "CAPILLA DEL MONTE",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17710,
    localidad: "CAPILLA DEL CARMEN",
    cp: 5963,
    provincia_id: 6,
  },
  {
    id: 17711,
    localidad: "CAPILLA DE TEGUA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17712,
    localidad: "CAPILLA DE S ANT",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 17713,
    localidad: "CAPILLA DE ROMERO",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 17714,
    localidad: "CAPILLA DE DOLORES",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 17715,
    localidad: "CAPILLA DE COSME",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17716,
    localidad: "CAP S ANT DE YUCAT",
    cp: 5936,
    provincia_id: 6,
  },
  {
    id: 17717,
    localidad: "CAP GRAL BERNARDO O HIGINS",
    cp: 2644,
    provincia_id: 6,
  },
  {
    id: 17718,
    localidad: "CAÑADON DE LOS MOGOTES",
    cp: 8184,
    provincia_id: 6,
  },
  {
    id: 17719,
    localidad: "CAÑADA VERDE",
    cp: 6275,
    provincia_id: 6,
  },
  {
    id: 17720,
    localidad: "CAÑADA DEL SAUCE",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 17721,
    localidad: "CAÑADA DE RIO PINTO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17722,
    localidad: "CAÑADA DE LUQUE",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17723,
    localidad: "CAÑADA DE ALVAREZ",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 17724,
    localidad: "CANTERAS KM 428",
    cp: 5200,
    provincia_id: 6,
  },
  {
    id: 17725,
    localidad: "CANTERAS IGUAZU",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17726,
    localidad: "CANTERAS DE QUILPO",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 17727,
    localidad: "CANTERAS ALTA GRAC",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 17728,
    localidad: "CANDONGA",
    cp: 5111,
    provincia_id: 6,
  },
  {
    id: 17729,
    localidad: "CANDELARIA SUD",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17730,
    localidad: "CANDELARIA NORTE",
    cp: 5235,
    provincia_id: 6,
  },
  {
    id: 17731,
    localidad: "CANDELARIA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17732,
    localidad: "CANALS",
    cp: 2650,
    provincia_id: 6,
  },
  {
    id: 17733,
    localidad: "CAMPO SOL DE MAYO",
    cp: 2679,
    provincia_id: 6,
  },
  {
    id: 17734,
    localidad: "CAMPO SAN ANTONIO",
    cp: 5821,
    provincia_id: 6,
  },
  {
    id: 17735,
    localidad: "CAMPO ROSSIANO",
    cp: 5987,
    provincia_id: 6,
  },
  {
    id: 17736,
    localidad: "CAMPO RAMALLO",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 17737,
    localidad: "CAMPO LA TRIBU",
    cp: 6015,
    provincia_id: 6,
  },
  {
    id: 17738,
    localidad: "CAMPO LA PIEDRA",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17739,
    localidad: "CAMPO LA LUISA",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 17740,
    localidad: "CAMPO GRANDE",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17741,
    localidad: "CAMPO GENERAL PAZ",
    cp: 2555,
    provincia_id: 6,
  },
  {
    id: 17742,
    localidad: "CAMPO FAGGIANO",
    cp: 2440,
    provincia_id: 6,
  },
  {
    id: 17743,
    localidad: "CAMPO EL MATACO",
    cp: 2342,
    provincia_id: 6,
  },
  {
    id: 17744,
    localidad: "CAMPO COYUNDA",
    cp: 5139,
    provincia_id: 6,
  },
  {
    id: 17745,
    localidad: "CAMPO COLIQUEO",
    cp: 6015,
    provincia_id: 6,
  },
  {
    id: 17746,
    localidad: "CAMPO CALVO",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 17747,
    localidad: "CAMPO BOERO",
    cp: 2426,
    provincia_id: 6,
  },
  {
    id: 17748,
    localidad: "CAMPO BEIRO",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 17749,
    localidad: "CAMPO BANDILLO",
    cp: 5943,
    provincia_id: 6,
  },
  {
    id: 17750,
    localidad: "CAMPO ALVAREZ",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17751,
    localidad: "CAMPO ALEGRE",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 17752,
    localidad: "CAMPO ALEGRE",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17753,
    localidad: "CAMOATI",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17754,
    localidad: "CAMINIAGA",
    cp: 5245,
    provincia_id: 6,
  },
  {
    id: 17755,
    localidad: "CAMINIAGA",
    cp: 4296,
    provincia_id: 6,
  },
  {
    id: 17756,
    localidad: "CAMILO ALDAO",
    cp: 2586,
    provincia_id: 6,
  },
  {
    id: 17757,
    localidad: "CAMILO ALDAO",
    cp: 2585,
    provincia_id: 6,
  },
  {
    id: 17758,
    localidad: "CAMARONES",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 17759,
    localidad: "CALMAYO",
    cp: 5191,
    provincia_id: 6,
  },
  {
    id: 17760,
    localidad: "CALCHIN OESTE",
    cp: 5964,
    provincia_id: 6,
  },
  {
    id: 17761,
    localidad: "CALCHIN OESTE",
    cp: 5965,
    provincia_id: 6,
  },
  {
    id: 17762,
    localidad: "CALCHIN",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17763,
    localidad: "CALCHIN",
    cp: 5969,
    provincia_id: 6,
  },
  {
    id: 17764,
    localidad: "CALASUYA",
    cp: 5201,
    provincia_id: 6,
  },
  {
    id: 17765,
    localidad: "CALABALUMBA",
    cp: 5282,
    provincia_id: 6,
  },
  {
    id: 17766,
    localidad: "CAJON DEL RIO",
    cp: 5184,
    provincia_id: 6,
  },
  {
    id: 17767,
    localidad: "CACHIYULLO",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17768,
    localidad: "CABINDO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17769,
    localidad: "CABEZA DE BUEY",
    cp: 5229,
    provincia_id: 6,
  },
  {
    id: 17770,
    localidad: "CABALANGO",
    cp: 4966,
    provincia_id: 6,
  },
  {
    id: 17771,
    localidad: "C. DE ANIZACATE",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17772,
    localidad: "C VEINTICINCO",
    cp: 2592,
    provincia_id: 6,
  },
  {
    id: 17773,
    localidad: "C SILVIO PELLICO",
    cp: 5910,
    provincia_id: 6,
  },
  {
    id: 17774,
    localidad: "C SANTA MARIA",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 17775,
    localidad: "C SANTA MARGARITA",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 17776,
    localidad: "C SANTA CATALINA",
    cp: 5851,
    provincia_id: 6,
  },
  {
    id: 17777,
    localidad: "C SAN IGANCIO",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17778,
    localidad: "C SAN BASILIO",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 17779,
    localidad: "C SAN BARTOLOME",
    cp: 2426,
    provincia_id: 6,
  },
  {
    id: 17780,
    localidad: "C SAGRADA FAMILIA",
    cp: 5125,
    provincia_id: 6,
  },
  {
    id: 17781,
    localidad: "C PROSPERIDAD",
    cp: 2423,
    provincia_id: 6,
  },
  {
    id: 17782,
    localidad: "C NUEVO PIAMONTE",
    cp: 2415,
    provincia_id: 6,
  },
  {
    id: 17783,
    localidad: "C LOS MORALES",
    cp: 5238,
    provincia_id: 6,
  },
  {
    id: 17784,
    localidad: "C LOS BOSQUES",
    cp: 6015,
    provincia_id: 6,
  },
  {
    id: 17785,
    localidad: "C LAS CUATRO ESQS",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 17786,
    localidad: "C LA TRINCHERA",
    cp: 2417,
    provincia_id: 6,
  },
  {
    id: 17787,
    localidad: "C LA TORDILLA",
    cp: 2435,
    provincia_id: 6,
  },
  {
    id: 17788,
    localidad: "C LA PRIMAVERA",
    cp: 5933,
    provincia_id: 6,
  },
  {
    id: 17789,
    localidad: "C LA MURIUCHA",
    cp: 2580,
    provincia_id: 6,
  },
  {
    id: 17790,
    localidad: "C LA MARGARITA",
    cp: 6214,
    provincia_id: 6,
  },
  {
    id: 17791,
    localidad: "C LA LEONCITA",
    cp: 2559,
    provincia_id: 6,
  },
  {
    id: 17792,
    localidad: "C LA CELESTINA",
    cp: 5847,
    provincia_id: 6,
  },
  {
    id: 17793,
    localidad: "C LA CANDELARIA",
    cp: 5287,
    provincia_id: 6,
  },
  {
    id: 17794,
    localidad: "C LA ARGENTINA",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17795,
    localidad: "C GENERAL DEHEZA",
    cp: 5945,
    provincia_id: 6,
  },
  {
    id: 17796,
    localidad: "C DOS HERMANOS",
    cp: 2421,
    provincia_id: 6,
  },
  {
    id: 17797,
    localidad: "C DEL BANCO NACION",
    cp: 2428,
    provincia_id: 6,
  },
  {
    id: 17798,
    localidad: "C DE RIO PINTO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17799,
    localidad: "C DE LAS GATIADAS",
    cp: 5291,
    provincia_id: 6,
  },
  {
    id: 17800,
    localidad: "C DE LAS CHACRAS",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 17801,
    localidad: "C BANCO PCIA BS AS",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17802,
    localidad: "C BALLESTEROS",
    cp: 2662,
    provincia_id: 6,
  },
  {
    id: 17803,
    localidad: "C ANCHA S. ROSA",
    cp: 5135,
    provincia_id: 6,
  },
  {
    id: 17804,
    localidad: "C A DE ALVAREZ",
    cp: 2436,
    provincia_id: 6,
  },
  {
    id: 17805,
    localidad: "C 25 LOS SURGENTES",
    cp: 2581,
    provincia_id: 6,
  },
  {
    id: 17806,
    localidad: "BULNES",
    cp: 5845,
    provincia_id: 6,
  },
  {
    id: 17807,
    localidad: "BUEY MUERTO",
    cp: 5131,
    provincia_id: 6,
  },
  {
    id: 17808,
    localidad: "BUENA VISTA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 17809,
    localidad: "BUENA VISTA",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 17810,
    localidad: "BUENA VISTA",
    cp: 5249,
    provincia_id: 6,
  },
  {
    id: 17811,
    localidad: "BUENA VISTA",
    cp: 5121,
    provincia_id: 6,
  },
  {
    id: 17812,
    localidad: "BUEN RETIRO",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17813,
    localidad: "BRINKMANN",
    cp: 2419,
    provincia_id: 6,
  },
  {
    id: 17814,
    localidad: "BOUWER",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 17815,
    localidad: "BOSQUE ALEGRE",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 17816,
    localidad: "BOCA P 25",
    cp: 2600,
    provincia_id: 6,
  },
  {
    id: 17817,
    localidad: "BIALET MASSE",
    cp: 5158,
    provincia_id: 6,
  },
  {
    id: 17818,
    localidad: "BEUCE",
    cp: 5244,
    provincia_id: 6,
  },
  {
    id: 17819,
    localidad: "BERROTARAN",
    cp: 5817,
    provincia_id: 6,
  },
  {
    id: 17820,
    localidad: "BENJAMIN GOULD",
    cp: 2664,
    provincia_id: 6,
  },
  {
    id: 17821,
    localidad: "BENGOLEA",
    cp: 5807,
    provincia_id: 6,
  },
  {
    id: 17822,
    localidad: "BELLA VISTA",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17823,
    localidad: "BELLA VISTA",
    cp: 5835,
    provincia_id: 6,
  },
  {
    id: 17824,
    localidad: "BELL VILLE",
    cp: 2550,
    provincia_id: 6,
  },
  {
    id: 17825,
    localidad: "BATAN",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17826,
    localidad: "BARRIO MULLER",
    cp: 5143,
    provincia_id: 6,
  },
  {
    id: 17827,
    localidad: "BARRIO LA FORTUNA",
    cp: 2594,
    provincia_id: 6,
  },
  {
    id: 17828,
    localidad: "BARRIO LA FERIA",
    cp: 5870,
    provincia_id: 6,
  },
  {
    id: 17829,
    localidad: "BARRIO DEAN FUNES",
    cp: 5123,
    provincia_id: 6,
  },
  {
    id: 17830,
    localidad: "BARRIO DE GALLI",
    cp: 5177,
    provincia_id: 6,
  },
  {
    id: 17831,
    localidad: "BARRIALITOS",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17832,
    localidad: "BARRIAL",
    cp: 5281,
    provincia_id: 6,
  },
  {
    id: 17833,
    localidad: "BARRETO",
    cp: 5246,
    provincia_id: 6,
  },
  {
    id: 17834,
    localidad: "BARRETO",
    cp: 2671,
    provincia_id: 6,
  },
  {
    id: 17835,
    localidad: "BARRANCA YACO",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 17836,
    localidad: "BAQADO DEL FUERTE",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17837,
    localidad: "BAQADO DE SOTO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17838,
    localidad: "BAQADO DE PAJA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17839,
    localidad: "BAÑADO DE SOTO",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17840,
    localidad: "BALNEARIO GUGLIERI",
    cp: 5137,
    provincia_id: 6,
  },
  {
    id: 17841,
    localidad: "BALNEARIA",
    cp: 5141,
    provincia_id: 6,
  },
  {
    id: 17842,
    localidad: "BALLESTEROS SUD",
    cp: 2573,
    provincia_id: 6,
  },
  {
    id: 17843,
    localidad: "BALLESTEROS",
    cp: 2572,
    provincia_id: 6,
  },
  {
    id: 17844,
    localidad: "BALDE SALADO",
    cp: 5274,
    provincia_id: 6,
  },
  {
    id: 17845,
    localidad: "BALDE LINDO",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17846,
    localidad: "BALDE DE LA ORILLA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17847,
    localidad: "BALDE DE LA MORA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17848,
    localidad: "BALDE",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 17849,
    localidad: "BALCARCE",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 17850,
    localidad: "BALBUENA",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17851,
    localidad: "BAJO HONDO",
    cp: 5231,
    provincia_id: 6,
  },
  {
    id: 17852,
    localidad: "BAJO HONDO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17853,
    localidad: "BAJO GRANDE",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17854,
    localidad: "BAJO DEL CARMEN",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17855,
    localidad: "BAJO DEL BURRO",
    cp: 2662,
    provincia_id: 6,
  },
  {
    id: 17856,
    localidad: "BAJO DE OLMOS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17857,
    localidad: "BAJO DE FERNANDEZ",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 17858,
    localidad: "BAJO CHICO",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17859,
    localidad: "BAJADA NUEVA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17860,
    localidad: "B DEL LIBERTADOR",
    cp: 5850,
    provincia_id: 6,
  },
  {
    id: 17861,
    localidad: "B BELGRANO ORTIZ",
    cp: 2440,
    provincia_id: 6,
  },
  {
    id: 17862,
    localidad: "AVELLANEDA",
    cp: 5212,
    provincia_id: 6,
  },
  {
    id: 17863,
    localidad: "AUSONIA",
    cp: 5924,
    provincia_id: 6,
  },
  {
    id: 17864,
    localidad: "ATUMI PAMPA",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17865,
    localidad: "ATOS PAMPA",
    cp: 5194,
    provincia_id: 6,
  },
  {
    id: 17866,
    localidad: "ATAHONA",
    cp: 5225,
    provincia_id: 6,
  },
  {
    id: 17867,
    localidad: "ASSUNTA",
    cp: 2671,
    provincia_id: 6,
  },
  {
    id: 17868,
    localidad: "ASCONCHINGA",
    cp: 5117,
    provincia_id: 6,
  },
  {
    id: 17869,
    localidad: "ARSENAL J M ROJAS",
    cp: 5825,
    provincia_id: 6,
  },
  {
    id: 17870,
    localidad: "ARROYO TOLEDO",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 17871,
    localidad: "ARROYO SECO",
    cp: 5196,
    provincia_id: 6,
  },
  {
    id: 17872,
    localidad: "ARROYO SANTANA",
    cp: 5819,
    provincia_id: 6,
  },
  {
    id: 17873,
    localidad: "ARROYO S CATALINA",
    cp: 5825,
    provincia_id: 6,
  },
  {
    id: 17874,
    localidad: "ARROYO LOS PATOS",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 17875,
    localidad: "ARROYO LA HIGUERA",
    cp: 5889,
    provincia_id: 6,
  },
  {
    id: 17876,
    localidad: "ARROYO DEL PINO",
    cp: 5901,
    provincia_id: 6,
  },
  {
    id: 17877,
    localidad: "ARROYO DE ALVAREZ",
    cp: 2434,
    provincia_id: 6,
  },
  {
    id: 17878,
    localidad: "ARROYO CABRAL",
    cp: 5917,
    provincia_id: 6,
  },
  {
    id: 17879,
    localidad: "ARROYO ALGODON",
    cp: 5909,
    provincia_id: 6,
  },
  {
    id: 17880,
    localidad: "ARROYO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 17881,
    localidad: "ARROYITO",
    cp: 2434,
    provincia_id: 6,
  },
  {
    id: 17882,
    localidad: "ARRIBEQOS",
    cp: 6007,
    provincia_id: 6,
  },
  {
    id: 17883,
    localidad: "AROMITO",
    cp: 2341,
    provincia_id: 6,
  },
  {
    id: 17884,
    localidad: "ARIAS",
    cp: 2624,
    provincia_id: 6,
  },
  {
    id: 17885,
    localidad: "ARGUELLO",
    cp: 5147,
    provincia_id: 6,
  },
  {
    id: 17886,
    localidad: "ARENALES",
    cp: 6005,
    provincia_id: 6,
  },
  {
    id: 17887,
    localidad: "ARCOYO",
    cp: 5297,
    provincia_id: 6,
  },
  {
    id: 17888,
    localidad: "ARBOLES BLANCOS",
    cp: 5873,
    provincia_id: 6,
  },
  {
    id: 17889,
    localidad: "ARBOL CHATO",
    cp: 2432,
    provincia_id: 6,
  },
  {
    id: 17890,
    localidad: "ARBOL BLANCO",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 17891,
    localidad: "ANISACATE",
    cp: 5189,
    provincia_id: 6,
  },
  {
    id: 17892,
    localidad: "ANGOSTURA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17893,
    localidad: "ANA ZUMARAN",
    cp: 5905,
    provincia_id: 6,
  },
  {
    id: 17894,
    localidad: "AMPATA",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17895,
    localidad: "AMILGANCHO",
    cp: 5300,
    provincia_id: 6,
  },
  {
    id: 17896,
    localidad: "AMBUL",
    cp: 5299,
    provincia_id: 6,
  },
  {
    id: 17897,
    localidad: "AMBOY",
    cp: 5199,
    provincia_id: 6,
  },
  {
    id: 17898,
    localidad: "ALTOS DE CHIPION",
    cp: 2417,
    provincia_id: 6,
  },
  {
    id: 17899,
    localidad: "ALTO VERDE",
    cp: 5205,
    provincia_id: 6,
  },
  {
    id: 17900,
    localidad: "ALTO LINDO",
    cp: 5883,
    provincia_id: 6,
  },
  {
    id: 17901,
    localidad: "ALTO GRANDE",
    cp: 5893,
    provincia_id: 6,
  },
  {
    id: 17902,
    localidad: "ALTO DEL TALA",
    cp: 5295,
    provincia_id: 6,
  },
  {
    id: 17903,
    localidad: "ALTO DEL DURAZNO",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 17904,
    localidad: "ALTO DE SAN PEDRO",
    cp: 5174,
    provincia_id: 6,
  },
  {
    id: 17905,
    localidad: "ALTO DE LAS MULAS",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17906,
    localidad: "ALTO DE FLORES",
    cp: 5203,
    provincia_id: 6,
  },
  {
    id: 17907,
    localidad: "ALTO DE FIERRO",
    cp: 5119,
    provincia_id: 6,
  },
  {
    id: 17908,
    localidad: "ALTO CASTRO",
    cp: 5182,
    provincia_id: 6,
  },
  {
    id: 17909,
    localidad: "ALTO ALEGRE",
    cp: 5907,
    provincia_id: 6,
  },
  {
    id: 17910,
    localidad: "ALTO ALEGRE",
    cp: 5121,
    provincia_id: 6,
  },
  {
    id: 17911,
    localidad: "ALTAUTINA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17912,
    localidad: "ALTA GRACIA",
    cp: 5186,
    provincia_id: 6,
  },
  {
    id: 17913,
    localidad: "ALPAPUCA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17914,
    localidad: "ALPA CORRAL",
    cp: 5801,
    provincia_id: 6,
  },
  {
    id: 17915,
    localidad: "ALMAFUERTE",
    cp: 5854,
    provincia_id: 6,
  },
  {
    id: 17916,
    localidad: "ALICIA",
    cp: 5949,
    provincia_id: 6,
  },
  {
    id: 17917,
    localidad: "ALGARROBO",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17918,
    localidad: "ALEJO LEDESMA",
    cp: 2663,
    provincia_id: 6,
  },
  {
    id: 17919,
    localidad: "ALEJO LEDESMA",
    cp: 2662,
    provincia_id: 6,
  },
  {
    id: 17920,
    localidad: "ALEJANDRO ROCA",
    cp: 2686,
    provincia_id: 6,
  },
  {
    id: 17921,
    localidad: "ALEJANDRO",
    cp: 2686,
    provincia_id: 6,
  },
  {
    id: 17922,
    localidad: "ALCIRA ESTACION GIGENA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17923,
    localidad: "ALCIRA ESTACION",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17924,
    localidad: "ALCIRA E GIGENA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17925,
    localidad: "ALCIRA",
    cp: 5813,
    provincia_id: 6,
  },
  {
    id: 17926,
    localidad: "AGUAS DE RAMON",
    cp: 5284,
    provincia_id: 6,
  },
  {
    id: 17927,
    localidad: "AGUADITA",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 17928,
    localidad: "AGUADA DEL MONTE",
    cp: 5209,
    provincia_id: 6,
  },
  {
    id: 17929,
    localidad: "AGUA HEDIONDA",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 17930,
    localidad: "AGUA DEL TALA",
    cp: 5243,
    provincia_id: 6,
  },
  {
    id: 17931,
    localidad: "AGUA DE TALA",
    cp: 5155,
    provincia_id: 6,
  },
  {
    id: 17932,
    localidad: "AGUA DE ORO",
    cp: 5248,
    provincia_id: 6,
  },
  {
    id: 17933,
    localidad: "AGUA DE ORO",
    cp: 5107,
    provincia_id: 6,
  },
  {
    id: 17934,
    localidad: "AGUA DE CRESPIN",
    cp: 5285,
    provincia_id: 6,
  },
  {
    id: 17935,
    localidad: "ADELIA MARIA",
    cp: 5843,
    provincia_id: 6,
  },
  {
    id: 17936,
    localidad: "ADELA MARIA",
    cp: 5843,
    provincia_id: 6,
  },
  {
    id: 17937,
    localidad: "ACOSTILLA",
    cp: 5871,
    provincia_id: 6,
  },
  {
    id: 17938,
    localidad: "ACOLLARADO",
    cp: 5216,
    provincia_id: 6,
  },
  {
    id: 17939,
    localidad: "ACHIRAS",
    cp: 5875,
    provincia_id: 6,
  },
  {
    id: 17940,
    localidad: "ACHIRAS",
    cp: 5833,
    provincia_id: 6,
  },
  {
    id: 17941,
    localidad: "A DE LAS PIEDRAS",
    cp: 5221,
    provincia_id: 6,
  },
  {
    id: 17942,
    localidad: "3 HNOS QUETREQUEN",
    cp: 6212,
    provincia_id: 6,
  },
  {
    id: 17943,
    localidad: "YACIM C VANGUARDIA",
    cp: 9310,
    provincia_id: 5,
  },
  {
    id: 17944,
    localidad: "VILLA INES",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 17945,
    localidad: "VILLA FUTALAUFQUEN",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 17946,
    localidad: "VALLE LOS MARTIRES",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 17947,
    localidad: "VALLE HUEMULES",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 17948,
    localidad: "VALLE HONDO",
    cp: 9221,
    provincia_id: 5,
  },
  {
    id: 17949,
    localidad: "VALLE HERMOSO",
    cp: 9020,
    provincia_id: 5,
  },
  {
    id: 17950,
    localidad: "VALLE GARIN",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 17951,
    localidad: "VALLE FRIO",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 17952,
    localidad: "VALLE DEL TECKA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 17953,
    localidad: "V DEL RIO CHUBUT",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17954,
    localidad: "UZCUDUN",
    cp: 9100,
    provincia_id: 5,
  },
  {
    id: 17955,
    localidad: "UZCUDUM",
    cp: 9007,
    provincia_id: 5,
  },
  {
    id: 17956,
    localidad: "TREVELIN",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 17957,
    localidad: "TRES PICOS",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 17958,
    localidad: "TREORKI",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 17959,
    localidad: "TRELEW",
    cp: 9100,
    provincia_id: 5,
  },
  {
    id: 17960,
    localidad: "TRELEW",
    cp: 9102,
    provincia_id: 5,
  },
  {
    id: 17961,
    localidad: "TORO HOSCO",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 17962,
    localidad: "TELSEN",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17963,
    localidad: "TECKA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 17964,
    localidad: "TAUEL AIKE",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 17965,
    localidad: "TATUEN",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17966,
    localidad: "TAQUETREN",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 17967,
    localidad: "TALAGAPA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17968,
    localidad: "SUNICA",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 17969,
    localidad: "SOL DE MAYO",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 17970,
    localidad: "SIERRA ROSADA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17971,
    localidad: "SIERRA DE TECKA",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 17972,
    localidad: "SIERRA COLORADA",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 17973,
    localidad: "SIERRA CHICA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17974,
    localidad: "SIERRA CHATA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17975,
    localidad: "SIEMPRE VIVA",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 17976,
    localidad: "SEPRUCAL",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17977,
    localidad: "SARMIENTO",
    cp: 9020,
    provincia_id: 5,
  },
  {
    id: 17978,
    localidad: "SAN JOSE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17979,
    localidad: "SALINAS GRANDES",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17980,
    localidad: "SALINAS CHICAS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17981,
    localidad: "SACANANA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17982,
    localidad: "S NEVADA P DE IND",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 17983,
    localidad: "S DE LA VENTANA",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 17984,
    localidad: "RIO PICO",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 17985,
    localidad: "RIO MAYO",
    cp: 9030,
    provincia_id: 5,
  },
  {
    id: 17986,
    localidad: "RIO FRIAS",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 17987,
    localidad: "RIO CORINTO",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 17988,
    localidad: "RAWSON",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 17989,
    localidad: "RANQUIL HUAO",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 17990,
    localidad: "RADA TILLY",
    cp: 9001,
    provincia_id: 5,
  },
  {
    id: 17991,
    localidad: "R 3 KILOMETRO 1711",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 17992,
    localidad: "R 3 KILOMETRO 1646",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 17993,
    localidad: "PUTRACHOIQUE",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 17994,
    localidad: "PUNTA QUIROGA",
    cp: 9120,
    provincia_id: 5,
  },
  {
    id: 17995,
    localidad: "PUNTA NORTE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17996,
    localidad: "PUNTA NINFAS",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 17997,
    localidad: "PUNTA MERCEDES",
    cp: 9313,
    provincia_id: 5,
  },
  {
    id: 17998,
    localidad: "PUNTA DELGADA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 17999,
    localidad: "PUNTA DEL MONTE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18000,
    localidad: "PUNTA BAJOS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18001,
    localidad: "PUERTO SAN ROMAN",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18002,
    localidad: "PUERTO PIRAMIDES",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18003,
    localidad: "PUERTO MADRYN",
    cp: 9120,
    provincia_id: 5,
  },
  {
    id: 18004,
    localidad: "PUENTE HENDRE",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18005,
    localidad: "PTA D LAGO VIEDMA",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18006,
    localidad: "PLAYA UNION",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 18007,
    localidad: "PLANCUNTRE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18008,
    localidad: "PIRRE MAHUIDA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18009,
    localidad: "PIEDRA SHOTEL",
    cp: 9227,
    provincia_id: 5,
  },
  {
    id: 18010,
    localidad: "PIEDRA PARADA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18011,
    localidad: "PENINSULA MAIPU",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18012,
    localidad: "PASTOS BLANCOS",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18013,
    localidad: "PASO ROBALLO",
    cp: 9315,
    provincia_id: 5,
  },
  {
    id: 18014,
    localidad: "PASO RIO LA LEONA",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18015,
    localidad: "PASO MORENO",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18016,
    localidad: "PASO IBAÑEZ",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18017,
    localidad: "PASO DEL SAPO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18018,
    localidad: "PASO DEL MEDIO",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18019,
    localidad: "PASO DEL AGUILA",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18020,
    localidad: "PASO DE INDIOS",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18021,
    localidad: "PARQUE NACIONAL LOS ALERCES",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18022,
    localidad: "PAMPA TEPUEL",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18023,
    localidad: "PAMPA DEL CASTILLO",
    cp: 9000,
    provincia_id: 5,
  },
  {
    id: 18024,
    localidad: "PAMPA DE AGNIA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18025,
    localidad: "PALI AIKE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18026,
    localidad: "PALERMO AIKE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18027,
    localidad: "PAINALUF",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18028,
    localidad: "P NAC LOS ALERCES",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18029,
    localidad: "P DEL RIO S CRUZ",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18030,
    localidad: "P DE QUICHAURA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18031,
    localidad: "NUEVA LUBECKA",
    cp: 9227,
    provincia_id: 5,
  },
  {
    id: 18032,
    localidad: "NIRIGUCE PAMPA",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18033,
    localidad: "NIRIGUAO",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18034,
    localidad: "NAHUEL PAN E FCGR",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18035,
    localidad: "MONTE AYMOND",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18036,
    localidad: "MEDANOS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18037,
    localidad: "MAYOCO",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18038,
    localidad: "MATUCANA",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18039,
    localidad: "MATA GRANDE",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18040,
    localidad: "MANATIALES BHER",
    cp: 9004,
    provincia_id: 5,
  },
  {
    id: 18041,
    localidad: "MALLIN GRANDE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18042,
    localidad: "MALLIN BLANCO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18043,
    localidad: "MALASPINA",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18044,
    localidad: "MAESTEG",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18045,
    localidad: "M GDE CORCOBADO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18046,
    localidad: "LOS MANANTIALES",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18047,
    localidad: "LOS MANANTIALES",
    cp: 9313,
    provincia_id: 5,
  },
  {
    id: 18048,
    localidad: "LOS CORRALITOS",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18049,
    localidad: "LOS CIPRECES",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18050,
    localidad: "LOS ALTARES",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18051,
    localidad: "LORETO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18052,
    localidad: "LOMA REDONDA",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18053,
    localidad: "LENZANILLEO",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18054,
    localidad: "LELEQUE",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 18055,
    localidad: "LELEQUE",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18056,
    localidad: "LEGUA 24",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18057,
    localidad: "LAS SALINAS",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18058,
    localidad: "LAS PLUMAS",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18059,
    localidad: "LAS PAMPAS",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18060,
    localidad: "LAS MULAS",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18061,
    localidad: "LAS HORQUETAS",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18062,
    localidad: "LAS HORQUETAS",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18063,
    localidad: "LAS GOLONDRINAS",
    cp: 8431,
    provincia_id: 5,
  },
  {
    id: 18064,
    localidad: "LAS CORTADERAS",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18065,
    localidad: "LAS CHAPAS",
    cp: 9107,
    provincia_id: 5,
  },
  {
    id: 18066,
    localidad: "LARRALDE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18067,
    localidad: "LANGUIÑEO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18068,
    localidad: "LAGUNITA SALADA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18069,
    localidad: "LAGUNA VERDE",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18070,
    localidad: "LAGUNA TERRAPLEN",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18071,
    localidad: "LAGUNA FRIA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18072,
    localidad: "LAGUNA DE VACAS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18073,
    localidad: "LAGUNA COLORADA",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18074,
    localidad: "LAGUNA BLANCA",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18075,
    localidad: "LAGO VINTTER",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18076,
    localidad: "LAGO VERDE",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18077,
    localidad: "LAGO TAR",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18078,
    localidad: "LAGO STROBEL",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18079,
    localidad: "LAGO SAN MARTIN",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18080,
    localidad: "LAGO ROSARIO",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18081,
    localidad: "LAGO RIVADAVIA",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18082,
    localidad: "LAGO PUEYRREDON",
    cp: 9310,
    provincia_id: 5,
  },
  {
    id: 18083,
    localidad: "LAGO PUELO",
    cp: 9211,
    provincia_id: 5,
  },
  {
    id: 18084,
    localidad: "LAGO PUELO",
    cp: 8431,
    provincia_id: 5,
  },
  {
    id: 18085,
    localidad: "LAGO PAZ",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18086,
    localidad: "LAGO LEZAMA",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18087,
    localidad: "LAGO FONTANA",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18088,
    localidad: "LAGO C PELLEGRINI",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18089,
    localidad: "LAGO BLANCO",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18090,
    localidad: "LAG R. DEL MORO",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18091,
    localidad: "LA VIOLETA",
    cp: 9051,
    provincia_id: 5,
  },
  {
    id: 18092,
    localidad: "LA SIBERIA",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18093,
    localidad: "LA ROSILLA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18094,
    localidad: "LA PRIMAVERA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18095,
    localidad: "LA PORTEÑA",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18096,
    localidad: "LA PEPITA",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18097,
    localidad: "LA PENINSULA",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18098,
    localidad: "LA NICOLASA",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18099,
    localidad: "LA MANCHURIA",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18100,
    localidad: "LA LAURITA",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18101,
    localidad: "LA LANCHA",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18102,
    localidad: "LA FLORIDA",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18103,
    localidad: "LA FEDERICA",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18104,
    localidad: "LA ESPERANZA",
    cp: 9401,
    provincia_id: 5,
  },
  {
    id: 18105,
    localidad: "LA CORONA",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18106,
    localidad: "LA CASTELLANA",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18107,
    localidad: "LA CANCHA",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18108,
    localidad: "LA CANCHA",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18109,
    localidad: "LA BOMBILLA",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18110,
    localidad: "JOSE DE SAN MARTIN",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18111,
    localidad: "ING BRUNO J THOMAE",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18112,
    localidad: "ING ATILIO CAPPA",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18113,
    localidad: "HTEL LAS HORQUETAS",
    cp: 9315,
    provincia_id: 5,
  },
  {
    id: 18114,
    localidad: "HOLDICH",
    cp: 9009,
    provincia_id: 5,
  },
  {
    id: 18115,
    localidad: "HITO 50",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18116,
    localidad: "HITO 45",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18117,
    localidad: "HITO 43",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18118,
    localidad: "GUARDAP FITZ ROY",
    cp: 9301,
    provincia_id: 5,
  },
  {
    id: 18119,
    localidad: "GUALJAINA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18120,
    localidad: "GUALJAINA",
    cp: 9202,
    provincia_id: 5,
  },
  {
    id: 18121,
    localidad: "GOBERNADOR MAYER",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18122,
    localidad: "GOBERNADOR COSTA",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18123,
    localidad: "GLASFRYN",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18124,
    localidad: "GENERAL MOSCONI",
    cp: 9005,
    provincia_id: 5,
  },
  {
    id: 18125,
    localidad: "GASTRE",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18126,
    localidad: "GASTRE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18127,
    localidad: "GARMINUE",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18128,
    localidad: "GARAYALDE",
    cp: 9007,
    provincia_id: 5,
  },
  {
    id: 18129,
    localidad: "GARAYALDE",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18130,
    localidad: "GAN GAN",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18131,
    localidad: "GAIMAN",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18132,
    localidad: "G GREGORES",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18133,
    localidad: "FUTALEUFU",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18134,
    localidad: "FOTOCAHUEL",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 18135,
    localidad: "FLORENTINO AMEGHINO",
    cp: 9113,
    provincia_id: 5,
  },
  {
    id: 18136,
    localidad: "FLORADORA",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18137,
    localidad: "FITIRHUIN",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18138,
    localidad: "FITHEN VERIN",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18139,
    localidad: "FARO CAMPANA",
    cp: 9313,
    provincia_id: 5,
  },
  {
    id: 18140,
    localidad: "FARO CABO GUARDIAN",
    cp: 9313,
    provincia_id: 5,
  },
  {
    id: 18141,
    localidad: "FACUNDO",
    cp: 9031,
    provincia_id: 5,
  },
  {
    id: 18142,
    localidad: "F DE RIO PICO",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18143,
    localidad: "F AMEGHINO",
    cp: 9100,
    provincia_id: 5,
  },
  {
    id: 18144,
    localidad: "ESTANCIA LA MIMOSA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18145,
    localidad: "ESTANCIA EL MORO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18146,
    localidad: "ESQUEL",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18147,
    localidad: "ESCALANTE",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18148,
    localidad: "EPUYEN",
    cp: 9211,
    provincia_id: 5,
  },
  {
    id: 18149,
    localidad: "EMPALME A ASTRA",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18150,
    localidad: "EL TROPEZON",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18151,
    localidad: "EL TRIANA",
    cp: 9037,
    provincia_id: 5,
  },
  {
    id: 18152,
    localidad: "EL SOMBRERO",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18153,
    localidad: "EL SHAMAN",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18154,
    localidad: "EL SALITRAL",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18155,
    localidad: "EL RUANO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18156,
    localidad: "EL QUILIMUAY",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18157,
    localidad: "EL POYO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18158,
    localidad: "EL PORVENIR",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18159,
    localidad: "EL PORTEZUELO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18160,
    localidad: "EL PIQUILLIN",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18161,
    localidad: "EL PASTIZAL",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18162,
    localidad: "EL PASO",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18163,
    localidad: "EL PAN DE AZUCAR",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18164,
    localidad: "EL PAJARITO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18165,
    localidad: "EL MOLLE",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18166,
    localidad: "EL MIRASOL",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18167,
    localidad: "EL MIRADOR",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18168,
    localidad: "EL KAQUEL",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18169,
    localidad: "EL JAGUEL",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18170,
    localidad: "EL HUEMUL",
    cp: 9039,
    provincia_id: 5,
  },
  {
    id: 18171,
    localidad: "EL HOYO",
    cp: 9211,
    provincia_id: 5,
  },
  {
    id: 18172,
    localidad: "EL ESCORIAL",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18173,
    localidad: "EL DESEMPEÑO",
    cp: 9120,
    provincia_id: 5,
  },
  {
    id: 18174,
    localidad: "EL CUCHE",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18175,
    localidad: "EL CRONOMETRO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18176,
    localidad: "EL COYTE",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18177,
    localidad: "EL COIHUE",
    cp: 9211,
    provincia_id: 5,
  },
  {
    id: 18178,
    localidad: "EL CHERQUE",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18179,
    localidad: "EL CHALET",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18180,
    localidad: "EL CANQUEL",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18181,
    localidad: "EL CALAFATE",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18182,
    localidad: "EL CAJON",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18183,
    localidad: "EL C  DPTO GASTRE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18184,
    localidad: "EL BOQUETE",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18185,
    localidad: "EL BARBUCHO",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18186,
    localidad: "EL BAILE",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18187,
    localidad: "EL ARGENTINO",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18188,
    localidad: "EL ALAMO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18189,
    localidad: "E PAMPA CHICA",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18190,
    localidad: "E ING ATILIO CAPPA",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18191,
    localidad: "DR.RICARDO ROJAS",
    cp: 9035,
    provincia_id: 5,
  },
  {
    id: 18192,
    localidad: "DR RICARDO ROJAS",
    cp: 9035,
    provincia_id: 5,
  },
  {
    id: 18193,
    localidad: "DOS POZOS",
    cp: 9100,
    provincia_id: 5,
  },
  {
    id: 18194,
    localidad: "DON BOSCO",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18195,
    localidad: "DOLAVON",
    cp: 9107,
    provincia_id: 5,
  },
  {
    id: 18196,
    localidad: "DIADEMA ARGENTINA",
    cp: 9009,
    provincia_id: 5,
  },
  {
    id: 18197,
    localidad: "DESAMPARADOS",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18198,
    localidad: "D F AMEGHINO",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18199,
    localidad: "CUSHAMEN",
    cp: 9211,
    provincia_id: 5,
  },
  {
    id: 18200,
    localidad: "CUSHAMEN",
    cp: 8211,
    provincia_id: 5,
  },
  {
    id: 18201,
    localidad: "CUSHAMEN",
    cp: 9212,
    provincia_id: 5,
  },
  {
    id: 18202,
    localidad: "CUESTA DEL TERNERO",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18203,
    localidad: "COSTA DEL LEPA",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18204,
    localidad: "COSTA CHUBUT",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18205,
    localidad: "CORRALITOS",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18206,
    localidad: "CORONEL GUARUMBA",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18207,
    localidad: "CORCOVADO",
    cp: 9204,
    provincia_id: 5,
  },
  {
    id: 18208,
    localidad: "CONDOR",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18209,
    localidad: "COMODORO RIVADAVIA",
    cp: 9006,
    provincia_id: 5,
  },
  {
    id: 18210,
    localidad: "COMODORO RIVADAVIA",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18211,
    localidad: "COMODORO RIVADAVIA",
    cp: 9005,
    provincia_id: 5,
  },
  {
    id: 18212,
    localidad: "COMODORO RIVADAVIA",
    cp: 9008,
    provincia_id: 5,
  },
  {
    id: 18213,
    localidad: "COMODORO RIVADAVIA",
    cp: 9009,
    provincia_id: 5,
  },
  {
    id: 18214,
    localidad: "COMODORO RIVADAVIA",
    cp: 9010,
    provincia_id: 5,
  },
  {
    id: 18215,
    localidad: "COMODORO RIVADAVIA",
    cp: 9000,
    provincia_id: 5,
  },
  {
    id: 18216,
    localidad: "COMODORO RIVADAVIA",
    cp: 9002,
    provincia_id: 5,
  },
  {
    id: 18217,
    localidad: "COMODORO RIVADAVIA",
    cp: 9004,
    provincia_id: 5,
  },
  {
    id: 18218,
    localidad: "COLONIA EPULIEF",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18219,
    localidad: "COLONIA CUSHAMEN",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 18220,
    localidad: "COLHUE HUAPI",
    cp: 9021,
    provincia_id: 5,
  },
  {
    id: 18221,
    localidad: "COLELACHE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18222,
    localidad: "COLANCONHUE",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18223,
    localidad: "CHOLILA",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18224,
    localidad: "CHIMEN AIKE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18225,
    localidad: "CHILQUENILAHUE",
    cp: 9227,
    provincia_id: 5,
  },
  {
    id: 18226,
    localidad: "CHASICO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18227,
    localidad: "CHARQUE CHICO",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 18228,
    localidad: "CHACRA DE AUSTIN",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18229,
    localidad: "CHACAY OESTE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18230,
    localidad: "CHACAY ESTE",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18231,
    localidad: "CERRO SANTA ANA",
    cp: 9100,
    provincia_id: 5,
  },
  {
    id: 18232,
    localidad: "CERRO REDONDO",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18233,
    localidad: "CERRO PICHALAO",
    cp: 9120,
    provincia_id: 5,
  },
  {
    id: 18234,
    localidad: "CERRO PALIQUE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18235,
    localidad: "CERRO NEGRO",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18236,
    localidad: "CERRO NEGRO",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18237,
    localidad: "CERRO MALLACO",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18238,
    localidad: "CERRO FOFOCAHUEL",
    cp: 9213,
    provincia_id: 5,
  },
  {
    id: 18239,
    localidad: "CERRO DRAGON",
    cp: 9000,
    provincia_id: 5,
  },
  {
    id: 18240,
    localidad: "CERRO CONDOR",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18241,
    localidad: "CERRO CENTINELA",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18242,
    localidad: "CERRO ALTO",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18243,
    localidad: "CATAICO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18244,
    localidad: "CASA BLANCA",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 18245,
    localidad: "CASA BLANCA",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18246,
    localidad: "CARRENLEUFU",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18247,
    localidad: "CARHUE NIYEO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18248,
    localidad: "CAQADON FABRE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18249,
    localidad: "CAP",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18250,
    localidad: "CAÑADON MOLINARI",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18251,
    localidad: "CAÑADON LEON",
    cp: 9311,
    provincia_id: 5,
  },
  {
    id: 18252,
    localidad: "CAÑADON LAGARTO",
    cp: 9009,
    provincia_id: 5,
  },
  {
    id: 18253,
    localidad: "CAÑADON LA MADERA",
    cp: 9220,
    provincia_id: 5,
  },
  {
    id: 18254,
    localidad: "CAÑADON GRANDE",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18255,
    localidad: "CAÑADON DEL TORO",
    cp: 9303,
    provincia_id: 5,
  },
  {
    id: 18256,
    localidad: "CAÑADON DEL RANCHO",
    cp: 9300,
    provincia_id: 5,
  },
  {
    id: 18257,
    localidad: "CAÑADON CHILENO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18258,
    localidad: "CAÑADON CHACAY",
    cp: 9223,
    provincia_id: 5,
  },
  {
    id: 18259,
    localidad: "CAÑADON CALIENTE",
    cp: 9217,
    provincia_id: 5,
  },
  {
    id: 18260,
    localidad: "CAÑADON BLANCO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18261,
    localidad: "CAÑADON BAGUAL",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18262,
    localidad: "CAÑADA BAGUAL",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18263,
    localidad: "CAMUZU AIKE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18264,
    localidad: "CAMARONES",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18265,
    localidad: "CALETA VALDEZ",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18266,
    localidad: "CALETA CORDOVA",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18267,
    localidad: "CACHEL",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18268,
    localidad: "CABO RASO",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18269,
    localidad: "CABO D L VIRGENES",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18270,
    localidad: "CABEZA DE BUEY",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18271,
    localidad: "CABAÑA DEL VALLE",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18272,
    localidad: "CA DE LAS VACAS",
    cp: 9300,
    provincia_id: 5,
  },
  {
    id: 18273,
    localidad: "C LONCO TRAPIAL",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18274,
    localidad: "C DE LAS VACAS",
    cp: 9300,
    provincia_id: 5,
  },
  {
    id: 18275,
    localidad: "C DE GINEBRA GDE",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18276,
    localidad: "C DE GINEBRA CHICO",
    cp: 9201,
    provincia_id: 5,
  },
  {
    id: 18277,
    localidad: "C 16 DE OCTUBRE",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18278,
    localidad: "C 11 DE SETIEMBRE",
    cp: 9310,
    provincia_id: 5,
  },
  {
    id: 18279,
    localidad: "BUENOS AIRES CHICO",
    cp: 9210,
    provincia_id: 5,
  },
  {
    id: 18280,
    localidad: "BUEN PASTO",
    cp: 9023,
    provincia_id: 5,
  },
  {
    id: 18281,
    localidad: "BRYN GWYN",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18282,
    localidad: "BRYN BROWN",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18283,
    localidad: "BOCA DE LA ZANJA",
    cp: 9107,
    provincia_id: 5,
  },
  {
    id: 18284,
    localidad: "BETESTA",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18285,
    localidad: "BARRIO ASTRA",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18286,
    localidad: "BAJO LAS DAMAJAS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18287,
    localidad: "BAJO FUEGO",
    cp: 9310,
    provincia_id: 5,
  },
  {
    id: 18288,
    localidad: "BAJO DEL GUALICHO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18289,
    localidad: "BAJO DE LOS HUESOS",
    cp: 9103,
    provincia_id: 5,
  },
  {
    id: 18290,
    localidad: "BAJO BARTOLO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18291,
    localidad: "BAJADA MORENO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18292,
    localidad: "BAJADA DEL DIABLO",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18293,
    localidad: "BAHIA SOLANO",
    cp: 9003,
    provincia_id: 5,
  },
  {
    id: 18294,
    localidad: "BAHIA CRACHER",
    cp: 9120,
    provincia_id: 5,
  },
  {
    id: 18295,
    localidad: "BAHIA BUSTAMANTE",
    cp: 9111,
    provincia_id: 5,
  },
  {
    id: 18296,
    localidad: "B A ALMIRANTE ZAR",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18297,
    localidad: "ARROYO VERDE",
    cp: 9123,
    provincia_id: 5,
  },
  {
    id: 18298,
    localidad: "ARROYO PESCADO",
    cp: 9200,
    provincia_id: 5,
  },
  {
    id: 18299,
    localidad: "ARROYO PERCY",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18300,
    localidad: "ARROYO GUILAIA",
    cp: 9207,
    provincia_id: 5,
  },
  {
    id: 18301,
    localidad: "ARROYO CHALIA",
    cp: 9035,
    provincia_id: 5,
  },
  {
    id: 18302,
    localidad: "ARENOSO",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18303,
    localidad: "ANGOSTURA SEGUNDA",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18304,
    localidad: "ANGOSTURA",
    cp: 9105,
    provincia_id: 5,
  },
  {
    id: 18305,
    localidad: "AN AIKE",
    cp: 9400,
    provincia_id: 5,
  },
  {
    id: 18306,
    localidad: "ALTO RIO SENGUER",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18307,
    localidad: "ALTO RIO PICO",
    cp: 9225,
    provincia_id: 5,
  },
  {
    id: 18308,
    localidad: "ALTO RIO MAYO",
    cp: 9037,
    provincia_id: 5,
  },
  {
    id: 18309,
    localidad: "ALTO DE LAS PLUMAS",
    cp: 9101,
    provincia_id: 5,
  },
  {
    id: 18310,
    localidad: "ALDEA ESCOLAR",
    cp: 9203,
    provincia_id: 5,
  },
  {
    id: 18311,
    localidad: "ALDEA BELEIRO",
    cp: 9037,
    provincia_id: 5,
  },
  {
    id: 18312,
    localidad: "ALDEA APELEG",
    cp: 9033,
    provincia_id: 5,
  },
  {
    id: 18313,
    localidad: "AGUADA LA OVEJA",
    cp: 9051,
    provincia_id: 5,
  },
  {
    id: 18314,
    localidad: "AGUADA GRANDE",
    cp: 9053,
    provincia_id: 5,
  },
  {
    id: 18315,
    localidad: "AGUADA DEL PITO",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18316,
    localidad: "AGUAD DE LAS TEJAS",
    cp: 9121,
    provincia_id: 5,
  },
  {
    id: 18317,
    localidad: "28 DE JULIO",
    cp: 9107,
    provincia_id: 5,
  },
  {
    id: 18318,
    localidad: "ZAPARINQUI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18319,
    localidad: "YATAY",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18320,
    localidad: "WICHI",
    cp: 3702,
    provincia_id: 4,
  },
  {
    id: 18321,
    localidad: "WELHERS",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18322,
    localidad: "VILLA SARMIENTO",
    cp: 3657,
    provincia_id: 4,
  },
  {
    id: 18323,
    localidad: "VILLA RIO BERMEJITO",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18324,
    localidad: "VILLA PARANACITO",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18325,
    localidad: "VILLA JALON",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18326,
    localidad: "VILLA GENERAL GUEMES",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18327,
    localidad: "VILLA FORESTACION",
    cp: 3503,
    provincia_id: 4,
  },
  {
    id: 18328,
    localidad: "VILLA EL PALMAR",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18329,
    localidad: "VILLA EL DORADO",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18330,
    localidad: "VILLA DOS",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18331,
    localidad: "VILLA BERTHET",
    cp: 3545,
    provincia_id: 4,
  },
  {
    id: 18332,
    localidad: "VILLA BARBERAN",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18333,
    localidad: "VILLA ANGELA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18334,
    localidad: "VICENTINI",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18335,
    localidad: "VENEZUELA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18336,
    localidad: "VENADOS GRANDES",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 18337,
    localidad: "VELAZQUEZ",
    cp: 3550,
    provincia_id: 4,
  },
  {
    id: 18338,
    localidad: "VELAZ",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18339,
    localidad: "V RIO BERMEJITO",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18340,
    localidad: "V JUAN DE GARAY",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18341,
    localidad: "V GENERAL GUEMES",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18342,
    localidad: "URBANA VIEJA",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18343,
    localidad: "TUCURU",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18344,
    localidad: "TRES POZOS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18345,
    localidad: "TRES POZOS",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18346,
    localidad: "TRES PALMAS",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18347,
    localidad: "TRES NACIONES",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18348,
    localidad: "TRES MONJES",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18349,
    localidad: "TRES MOJONES",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 18350,
    localidad: "TRES LAGUNAS",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18351,
    localidad: "TRES ISLETAS",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18352,
    localidad: "TRES HORQUETAS",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18353,
    localidad: "TRES HORQUETAS",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18354,
    localidad: "TRES ESTACAS",
    cp: 3727,
    provincia_id: 4,
  },
  {
    id: 18355,
    localidad: "TRES BOLICHES",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18356,
    localidad: "TOLDERIAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18357,
    localidad: "TIMBO",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18358,
    localidad: "TIGRE",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18359,
    localidad: "TERMAS DEL CERRITO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18360,
    localidad: "TARTAGAL",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18361,
    localidad: "TACUARI",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18362,
    localidad: "TACO POZO",
    cp: 3713,
    provincia_id: 4,
  },
  {
    id: 18363,
    localidad: "SUMAYEN",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18364,
    localidad: "SOLDADO E SOSA",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18365,
    localidad: "SOLD R A ARRIETA",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18366,
    localidad: "SOLALINDE",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18367,
    localidad: "SOL DE MAYO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18368,
    localidad: "SELVAS DEL RIO DE ORO",
    cp: 3507,
    provincia_id: 4,
  },
  {
    id: 18369,
    localidad: "SANTOS LUGARES",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18370,
    localidad: "SANTO DOMINGO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18371,
    localidad: "SANTA SYLVINA",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18372,
    localidad: "SANTA SYLVINA",
    cp: 3542,
    provincia_id: 4,
  },
  {
    id: 18373,
    localidad: "SANTA RITA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18374,
    localidad: "SANTA MARTA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18375,
    localidad: "SANTA MARIA",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18376,
    localidad: "SANTA ELVIRA",
    cp: 3731,
    provincia_id: 4,
  },
  {
    id: 18377,
    localidad: "SAN MIGUEL",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18378,
    localidad: "SAN LORENZO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18379,
    localidad: "SAN JUANCITO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18380,
    localidad: "SAN ISIDRO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18381,
    localidad: "SAN FERNANDO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18382,
    localidad: "SAN EDUARDO",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18383,
    localidad: "SAN CARLOS",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18384,
    localidad: "SAN BERNARDO",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18385,
    localidad: "SAN BERNARDO",
    cp: 3726,
    provincia_id: 4,
  },
  {
    id: 18386,
    localidad: "SAN ANTONIO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18387,
    localidad: "SAN ANTONIO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18388,
    localidad: "SAN AGUSTIN",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18389,
    localidad: "SAMUHU",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18390,
    localidad: "SALTO DE LA VIEJA",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18391,
    localidad: "S DEL RIO DE ORO",
    cp: 3507,
    provincia_id: 4,
  },
  {
    id: 18392,
    localidad: "ROSALES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18393,
    localidad: "RIO TAPENAGA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18394,
    localidad: "RIO MUERTO",
    cp: 3737,
    provincia_id: 4,
  },
  {
    id: 18395,
    localidad: "RIO MUERTO",
    cp: 3719,
    provincia_id: 4,
  },
  {
    id: 18396,
    localidad: "RIO MUERTO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18397,
    localidad: "RIO DE ORO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18398,
    localidad: "RIO BERMEJO",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18399,
    localidad: "RIO ARAZA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18400,
    localidad: "RINCON FLORIDO",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18401,
    localidad: "RINCON DEL ZORRO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18402,
    localidad: "RIACHO LINDO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18403,
    localidad: "RESISTENCIA",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18404,
    localidad: "RESISTENCIA",
    cp: 3508,
    provincia_id: 4,
  },
  {
    id: 18405,
    localidad: "RESISTENCIA",
    cp: 3506,
    provincia_id: 4,
  },
  {
    id: 18406,
    localidad: "RESISTENCIA",
    cp: 3504,
    provincia_id: 4,
  },
  {
    id: 18407,
    localidad: "RESISTENCIA",
    cp: 3501,
    provincia_id: 4,
  },
  {
    id: 18408,
    localidad: "RESISTENCIA",
    cp: 3502,
    provincia_id: 4,
  },
  {
    id: 18409,
    localidad: "REDUCCION NAPALPI",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18410,
    localidad: "RED DE SAN B EL V",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18411,
    localidad: "RED D L CYE LUGAR",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18412,
    localidad: "RANCHOS VIEJOS",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18413,
    localidad: "R NATURAL FORMOSA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18414,
    localidad: "QUITILIPI",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18415,
    localidad: "QUIA",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18416,
    localidad: "PZO D INDIO E FCGB",
    cp: 3734,
    provincia_id: 4,
  },
  {
    id: 18417,
    localidad: "PUNTA RIELES",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18418,
    localidad: "PUNTA NUEVA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18419,
    localidad: "PUNTA DE RIELES",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18420,
    localidad: "PUNTA DE RIELES",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18421,
    localidad: "PUESTO MENDIZABAL",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18422,
    localidad: "PUESTO COCHERI",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18423,
    localidad: "PUESTO CARRIZO",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18424,
    localidad: "PUERTO ZAPALLAR",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18425,
    localidad: "PUERTO VILELAS",
    cp: 3503,
    provincia_id: 4,
  },
  {
    id: 18426,
    localidad: "PUERTO VILELAS",
    cp: 3512,
    provincia_id: 4,
  },
  {
    id: 18427,
    localidad: "PUERTO VICENTINI",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18428,
    localidad: "PUERTO VELAZ",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18429,
    localidad: "PUERTO URQUIZA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18430,
    localidad: "PUERTO TIROL",
    cp: 3535,
    provincia_id: 4,
  },
  {
    id: 18431,
    localidad: "PUERTO TIROL",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18432,
    localidad: "PUERTO TIROL",
    cp: 3521,
    provincia_id: 4,
  },
  {
    id: 18433,
    localidad: "PUERTO PIRACUA",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18434,
    localidad: "PUERTO LAVALLE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18435,
    localidad: "PUERTO LAS PALMAS",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18436,
    localidad: "PUERTO IRIGOYEN",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18437,
    localidad: "PUERTO BERMEJO",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18438,
    localidad: "PUERTO BASTIANI",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18439,
    localidad: "PUERTO ANTEQUERA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18440,
    localidad: "PUERTA DE LEON",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18441,
    localidad: "PUENTE SVRITZ",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18442,
    localidad: "PUENTE PHILIPPON",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18443,
    localidad: "PUENTE PALOMETA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18444,
    localidad: "PUENTE INE",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18445,
    localidad: "PUEBLO PUCA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18446,
    localidad: "PRESIDENCIA ROQUE SAENZ PEÑA",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18447,
    localidad: "PRESIDENCIA ROCA",
    cp: 3511,
    provincia_id: 4,
  },
  {
    id: 18448,
    localidad: "PRESIDENCIA DE LA PLAZA",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18449,
    localidad: "PRES YRIGOYEN",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18450,
    localidad: "PRES DE LA PLAZA",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18451,
    localidad: "POZO NAVAGAN",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18452,
    localidad: "POZO LA OSCA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18453,
    localidad: "POZO LA BREA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18454,
    localidad: "POZO DEL TORO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18455,
    localidad: "POZO DEL TIGRE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18456,
    localidad: "POZO DEL TALA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18457,
    localidad: "POZO DEL NEGRO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18458,
    localidad: "POZO DEL MOLLE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18459,
    localidad: "POZO DEL LEON",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18460,
    localidad: "POZO DEL GRIS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18461,
    localidad: "POZO DEL GATO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18462,
    localidad: "POZO DEL CUCHILLO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18463,
    localidad: "POZO DEL CINCUENTA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18464,
    localidad: "POZO DE PIEDRA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18465,
    localidad: "POZO DE MARA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18466,
    localidad: "POZO DE LOS SURIS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18467,
    localidad: "POZO DE LAS GARZAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18468,
    localidad: "POZO DE LA TUNA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18469,
    localidad: "POZO DE LA PAVA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18470,
    localidad: "POZO DE LA MULA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18471,
    localidad: "POZO DE LA LINEA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18472,
    localidad: "POSTA SAN MARTIN 2",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18473,
    localidad: "POSTA SAN MARTIN 1",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18474,
    localidad: "POSTA LENCINA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18475,
    localidad: "PONCHO QUEMADO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18476,
    localidad: "PMPA DL REGIMIENTO",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18477,
    localidad: "PINEDO CENTRAL",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18478,
    localidad: "PINEDO",
    cp: 3712,
    provincia_id: 4,
  },
  {
    id: 18479,
    localidad: "PINDO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18480,
    localidad: "PILAR",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18481,
    localidad: "PICADITAS",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18482,
    localidad: "PASO DEL OSO",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18483,
    localidad: "PASO DE LOS LIBRES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18484,
    localidad: "PARALELO 28",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18485,
    localidad: "PARAJE LAS TABLAS",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18486,
    localidad: "PARAJE EL COLORADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18487,
    localidad: "PARAJE EL COLCHON",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18488,
    localidad: "PAMPINI",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18489,
    localidad: "PAMPA ZANATA",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18490,
    localidad: "PAMPA VILLORDO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18491,
    localidad: "PAMPA VERDE",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18492,
    localidad: "PAMPA VARGAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18493,
    localidad: "PAMPA TOLOSA GDE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18494,
    localidad: "PAMPA TOLOSA CHICA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18495,
    localidad: "PAMPA SOMMER",
    cp: 3728,
    provincia_id: 4,
  },
  {
    id: 18496,
    localidad: "PAMPA SAN MARTIN",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18497,
    localidad: "PAMPA MITRE",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18498,
    localidad: "PAMPA MACHETE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18499,
    localidad: "PAMPA LOS BEDOGNI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18500,
    localidad: "PAMPA LOCA",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18501,
    localidad: "PAMPA LEGUA CUATRO",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18502,
    localidad: "PAMPA LARGA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18503,
    localidad: "PAMPA LANDRIEL",
    cp: 3731,
    provincia_id: 4,
  },
  {
    id: 18504,
    localidad: "PAMPA LA PELIGROSA",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18505,
    localidad: "PAMPA JUANITA",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18506,
    localidad: "PAMPA IPORA GUAZU",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18507,
    localidad: "PAMPA HERMOSA",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18508,
    localidad: "PAMPA HERMOSA",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18509,
    localidad: "PAMPA GRANDE",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18510,
    localidad: "PAMPA GRANDE",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18511,
    localidad: "PAMPA GAMBA",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18512,
    localidad: "PAMPA GALPON",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18513,
    localidad: "PAMPA FLORIDA",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18514,
    localidad: "PAMPA FLORES",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18515,
    localidad: "PAMPA EL SILENCIO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18516,
    localidad: "PAMPA EL 12",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18517,
    localidad: "PAMPA EL 11",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18518,
    localidad: "PAMPA DOROTIER",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18519,
    localidad: "PAMPA DEL ZORRO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18520,
    localidad: "PAMPA DEL TORDILLO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18521,
    localidad: "PAMPA DEL INFIERNO",
    cp: 3721,
    provincia_id: 4,
  },
  {
    id: 18522,
    localidad: "PAMPA DEL INFIERNO",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18523,
    localidad: "PAMPA DEL INDIO",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18524,
    localidad: "PAMPA DEL HUEVO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18525,
    localidad: "PAMPA DEL CIELO",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18526,
    localidad: "PAMPA DEL CIELO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18527,
    localidad: "PAMPA DE LOS LOCOS",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18528,
    localidad: "PAMPA D LS FLORES",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18529,
    localidad: "PAMPA CUVALO",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18530,
    localidad: "PAMPA CHICA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18531,
    localidad: "PAMPA CEJAS",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18532,
    localidad: "PAMPA CASTRO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18533,
    localidad: "PAMPA CABRERA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18534,
    localidad: "PAMPA BRUGNOLI",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18535,
    localidad: "PAMPA BORRACHO",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18536,
    localidad: "PAMPA BARRERA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18537,
    localidad: "PAMPA BANDERA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18538,
    localidad: "PAMPA AVILA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18539,
    localidad: "PAMPA ALSINA",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18540,
    localidad: "PAMPA ALMIRON",
    cp: 3507,
    provincia_id: 4,
  },
  {
    id: 18541,
    localidad: "PAMPA ALELAI",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18542,
    localidad: "PAMPA ALEGRIA",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18543,
    localidad: "PAMPA AGUARA",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18544,
    localidad: "PAMPA AGUADO",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18545,
    localidad: "PALO MARCADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18546,
    localidad: "PALMAR NORTE",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18547,
    localidad: "PALMAR CENTRAL",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18548,
    localidad: "PALMA SOLA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18549,
    localidad: "P DE LOS CABALLOS",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18550,
    localidad: "P CLODOMIRO DIAZ",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18551,
    localidad: "O VICTOR ANDRADE",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18552,
    localidad: "NUEVO PILCOMAYO",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18553,
    localidad: "NUEVA UNION",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18554,
    localidad: "NUEVA POBLACION",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18555,
    localidad: "NUEVA ITALIA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18556,
    localidad: "NTRA SRA D CPCION",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18557,
    localidad: "NICORA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18558,
    localidad: "NAPENAY",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18559,
    localidad: "NAPALPI",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18560,
    localidad: "MONTE CASEROS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18561,
    localidad: "MOLLE MARCADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18562,
    localidad: "MISION NVA POMPEYA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18563,
    localidad: "MISION NUEVA POMPEYA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18564,
    localidad: "MISION EL CARMEN",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18565,
    localidad: "MISION ANGELICANA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18566,
    localidad: "MIRAMAR",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18567,
    localidad: "MIRAFLORES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18568,
    localidad: "MIERES",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18569,
    localidad: "MI RAMON GOMEZ",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18570,
    localidad: "MESON DE FIERRO",
    cp: 3729,
    provincia_id: 4,
  },
  {
    id: 18571,
    localidad: "MAYOR M T ROJAS",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18572,
    localidad: "MARTINEZ DE HOZ",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18573,
    localidad: "MARIA SARA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18574,
    localidad: "MARGARITA BELEN",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18575,
    localidad: "MANANTIALES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18576,
    localidad: "MALBALAES",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18577,
    localidad: "MAKALLE",
    cp: 3528,
    provincia_id: 4,
  },
  {
    id: 18578,
    localidad: "MAESTRO F BAEZ",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18579,
    localidad: "MACOMITAS",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18580,
    localidad: "MACHAGAY",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18581,
    localidad: "MACHAGAI",
    cp: 3535,
    provincia_id: 4,
  },
  {
    id: 18582,
    localidad: "M EVANG LAG DE YA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18583,
    localidad: "LS GOLONDRINAS SUR",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18584,
    localidad: "LOTE OCHO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18585,
    localidad: "LOTE 9",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18586,
    localidad: "LOTE 8",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18587,
    localidad: "LOTE 77",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18588,
    localidad: "LOTE 7",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18589,
    localidad: "LOTE 53 C MIXTA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18590,
    localidad: "LOTE 48 C MIXTA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18591,
    localidad: "LOTE 43 E 250",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18592,
    localidad: "LOTE 42",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18593,
    localidad: "LOTE 4 QUITILIPI",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18594,
    localidad: "LOTE 4 C PASTORIL",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18595,
    localidad: "LOTE 34",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18596,
    localidad: "LOTE 3",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18597,
    localidad: "LOTE 25",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18598,
    localidad: "LOTE 24",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18599,
    localidad: "LOTE 23 SAMUHU",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18600,
    localidad: "LOTE 23",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18601,
    localidad: "LOTE 17",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18602,
    localidad: "LOTE 16 E 204",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18603,
    localidad: "LOTE 15 LA SABANA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18604,
    localidad: "LOTE 15 E 268",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18605,
    localidad: "LOTE 14",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18606,
    localidad: "LOTE 12",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18607,
    localidad: "LOTE 11",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18608,
    localidad: "LOTE 11",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18609,
    localidad: "LOTE 10",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18610,
    localidad: "LOTE 10",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18611,
    localidad: "LOTE 1",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18612,
    localidad: "LOS TUNALES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18613,
    localidad: "LOS QUIRQUINCHOS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18614,
    localidad: "LOS POZOS",
    cp: 3511,
    provincia_id: 4,
  },
  {
    id: 18615,
    localidad: "LOS PORONGOS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18616,
    localidad: "LOS POCITOS",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18617,
    localidad: "LOS PALMARES",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18618,
    localidad: "LOS NIDOS",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18619,
    localidad: "LOS GUALCOS",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18620,
    localidad: "LOS GANSOS",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18621,
    localidad: "LOS FRENTONES",
    cp: 3715,
    provincia_id: 4,
  },
  {
    id: 18622,
    localidad: "LOS FORTINES",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18623,
    localidad: "LOS CHINACOS",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18624,
    localidad: "LOS CERRITOS",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18625,
    localidad: "LOS BARRILES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18626,
    localidad: "LOS ALGARROBOS",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18627,
    localidad: "LORO BLANCO",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18628,
    localidad: "LOMA FLORIDA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18629,
    localidad: "LOMA ALTA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18630,
    localidad: "LOMA ALTA",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18631,
    localidad: "LIVA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18632,
    localidad: "LAS VERTIENTES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18633,
    localidad: "LAS TOSCAS",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18634,
    localidad: "LAS ROSAS",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18635,
    localidad: "LAS PIEDRITAS",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18636,
    localidad: "LAS PALMAS",
    cp: 3523,
    provincia_id: 4,
  },
  {
    id: 18637,
    localidad: "LAS MORERAS",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18638,
    localidad: "LAS MERCEDES",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18639,
    localidad: "LAS MARAVILLAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18640,
    localidad: "LAS LOMITAS",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18641,
    localidad: "LAS LOLAS",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18642,
    localidad: "LAS LEONAS",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18643,
    localidad: "LAS HACHERAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18644,
    localidad: "LAS GOLONDRINAS",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18645,
    localidad: "LAS GARCITAS",
    cp: 3533,
    provincia_id: 4,
  },
  {
    id: 18646,
    localidad: "LAS FLORES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18647,
    localidad: "LAS CUCHILLAS",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18648,
    localidad: "LAS CUATRO BOCAS",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18649,
    localidad: "LAS CNIA J MARMOL",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18650,
    localidad: "LAS BREÑAS",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18651,
    localidad: "LAS BREÑAS",
    cp: 3724,
    provincia_id: 4,
  },
  {
    id: 18652,
    localidad: "LAS BOLIVIANAS",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18653,
    localidad: "LAS BLANCAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18654,
    localidad: "LAS BANDERAS",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18655,
    localidad: "LAS AVISPAS",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18656,
    localidad: "LAPACHO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18657,
    localidad: "LAPACHITO",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18658,
    localidad: "LAMADRID",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18659,
    localidad: "LAGUNA YEMA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18660,
    localidad: "LAGUNA PATOS",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18661,
    localidad: "LAGUNA LIMPIA",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18662,
    localidad: "LAGUNA ESCONDIDA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18663,
    localidad: "LAGUNA BLANCA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18664,
    localidad: "LAGUNA BELIGAY",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18665,
    localidad: "LA ZANJA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18666,
    localidad: "LA VIRUELA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18667,
    localidad: "LA VICUÑA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18668,
    localidad: "LA VERDE",
    cp: 3527,
    provincia_id: 4,
  },
  {
    id: 18669,
    localidad: "LA TIGRA",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18670,
    localidad: "LA TAPERA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18671,
    localidad: "LA TAMBORA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18672,
    localidad: "LA SUIZA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18673,
    localidad: "LA SOLEDAD",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18674,
    localidad: "LA SOLEDAD",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18675,
    localidad: "LA SALTARINA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18676,
    localidad: "LA SABANA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18677,
    localidad: "LA RINCONADA",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18678,
    localidad: "LA RINCONADA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18679,
    localidad: "LA REPRESA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18680,
    localidad: "LA RAQUEL",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18681,
    localidad: "LA PRIMAVERA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18682,
    localidad: "LA POSTA",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18683,
    localidad: "LA POBLADORA",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18684,
    localidad: "LA PILAR",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18685,
    localidad: "LA PASTORIL",
    cp: 3517,
    provincia_id: 4,
  },
  {
    id: 18686,
    localidad: "LA PALOMETA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18687,
    localidad: "LA PALMITA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18688,
    localidad: "LA OFELIA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18689,
    localidad: "LA NUEVA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18690,
    localidad: "LA NOBLEZA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18691,
    localidad: "LA NEGRA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18692,
    localidad: "LA MORA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18693,
    localidad: "LA MEDIA LUNA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18694,
    localidad: "LA MEDIA LUNA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18695,
    localidad: "LA MATANZA",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18696,
    localidad: "LA MATANZA",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18697,
    localidad: "LA MASCOTA",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18698,
    localidad: "LA MANUELA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18699,
    localidad: "LA MANIJA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18700,
    localidad: "LA MAGDALENA",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18701,
    localidad: "LA LUCINDA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18702,
    localidad: "LA LOMA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18703,
    localidad: "LA LIGURIA",
    cp: 3501,
    provincia_id: 4,
  },
  {
    id: 18704,
    localidad: "LA LIBERTAD",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18705,
    localidad: "LA LIBERTAD",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18706,
    localidad: "LA LEONESA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18707,
    localidad: "LA LEONESA",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18708,
    localidad: "LA ISLA",
    cp: 3503,
    provincia_id: 4,
  },
  {
    id: 18709,
    localidad: "LA INVERNADA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18710,
    localidad: "LA GRINGA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18711,
    localidad: "LA GRANJA",
    cp: 3712,
    provincia_id: 4,
  },
  {
    id: 18712,
    localidad: "LA GANADERA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18713,
    localidad: "LA FLOJERA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18714,
    localidad: "LA FIDELIDAD",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18715,
    localidad: "LA EVANGELICA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18716,
    localidad: "LA ESTACION",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18717,
    localidad: "LA ESPERANZA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18718,
    localidad: "LA ESPERANZA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18719,
    localidad: "LA ESPERANZA",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18720,
    localidad: "LA ESCONDIDA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18721,
    localidad: "LA ENTRADA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18722,
    localidad: "LA ELABORADORA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18723,
    localidad: "LA EDUVIGES",
    cp: 3507,
    provincia_id: 4,
  },
  {
    id: 18724,
    localidad: "LA EDUVIGES",
    cp: 3648,
    provincia_id: 4,
  },
  {
    id: 18725,
    localidad: "LA ECONOMIA",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18726,
    localidad: "LA DIFICULTAD",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18727,
    localidad: "LA CUCHILLA",
    cp: 3716,
    provincia_id: 4,
  },
  {
    id: 18728,
    localidad: "LA COSTOSA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18729,
    localidad: "LA COLONIA",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18730,
    localidad: "LA CLOTILDE",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18731,
    localidad: "LA CHOZA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18732,
    localidad: "LA CHACO",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18733,
    localidad: "LA ARMONIA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18734,
    localidad: "L 92 LA RINCONADA",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18735,
    localidad: "KM 855 ESTACION",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18736,
    localidad: "KM 76 RIO BERMEJO",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18737,
    localidad: "KILOMETRO 884",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18738,
    localidad: "KILOMETRO 841",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18739,
    localidad: "KILOMETRO 62",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18740,
    localidad: "KILOMETRO 602",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18741,
    localidad: "KILOMETRO 596",
    cp: 3504,
    provincia_id: 4,
  },
  {
    id: 18742,
    localidad: "KILOMETRO 59",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18743,
    localidad: "KILOMETRO 575",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18744,
    localidad: "KILOMETRO 530",
    cp: 3545,
    provincia_id: 4,
  },
  {
    id: 18745,
    localidad: "KILOMETRO 53",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18746,
    localidad: "KILOMETRO 525",
    cp: 3545,
    provincia_id: 4,
  },
  {
    id: 18747,
    localidad: "KILOMETRO 523",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18748,
    localidad: "KILOMETRO 523",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 18749,
    localidad: "KILOMETRO 520",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18750,
    localidad: "KILOMETRO 5",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18751,
    localidad: "KILOMETRO 498",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18752,
    localidad: "KILOMETRO 48",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18753,
    localidad: "KILOMETRO 42",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18754,
    localidad: "KILOMETRO 40",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18755,
    localidad: "KILOMETRO 39",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18756,
    localidad: "KILOMETRO 38",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18757,
    localidad: "KILOMETRO 34",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18758,
    localidad: "KILOMETRO 29",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18759,
    localidad: "KILOMETRO 254",
    cp: 3511,
    provincia_id: 4,
  },
  {
    id: 18760,
    localidad: "KILOMETRO 224",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18761,
    localidad: "KILOMETRO 22",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18762,
    localidad: "KILOMETRO 22",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18763,
    localidad: "KILOMETRO 2 FCGB",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18764,
    localidad: "KILOMETRO 184",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18765,
    localidad: "KILOMETRO 139",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18766,
    localidad: "KILOMETRO 1338",
    cp: 3712,
    provincia_id: 4,
  },
  {
    id: 18767,
    localidad: "KILOMETRO 100",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18768,
    localidad: "JUAN JOSE CASTELLI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18769,
    localidad: "JOSE CANCIO",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18770,
    localidad: "JOAQUIN V GONZALEZ",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18771,
    localidad: "J MANUEL ESTRADA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18772,
    localidad: "ITIN",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 18773,
    localidad: "ISLA DEL CERRITO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18774,
    localidad: "ISLA ANTEQUERA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18775,
    localidad: "IPORA GUAZU",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18776,
    localidad: "INVERNADA",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18777,
    localidad: "INGENIERO BARBET",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18778,
    localidad: "INDIA MUERTA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18779,
    localidad: "HORQUILLA",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18780,
    localidad: "HIVONNAIT",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18781,
    localidad: "HERMOSO CAMPO",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 18782,
    localidad: "HAUMONIA",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18783,
    localidad: "GUAYCURU",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18784,
    localidad: "GUAYAIBI",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18785,
    localidad: "GIRASOL",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18786,
    localidad: "GENERAL VEDIA",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18787,
    localidad: "GENERAL VEDIA",
    cp: 3520,
    provincia_id: 4,
  },
  {
    id: 18788,
    localidad: "GENERAL PINEDO",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18789,
    localidad: "GENERAL OBLIGADO",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18790,
    localidad: "GENERAL NECOCHEA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18791,
    localidad: "GENERAL LUCIO VICTORIO MANSI",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18792,
    localidad: "GENERAL JOSE DE SAN MARTIN",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18793,
    localidad: "GENERAL DONOVAN",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18794,
    localidad: "GENERAL CAPDEVILA",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18795,
    localidad: "GANDOLFI",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18796,
    localidad: "GABRIELA MISTRAL",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18797,
    localidad: "G LUCIO VI MANSILL",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18798,
    localidad: "G JOSE DE S MARTIN",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18799,
    localidad: "G I H FOTHERINGHAM",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18800,
    localidad: "G F B BOSCH",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18801,
    localidad: "FUERTE ESPERANZA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18802,
    localidad: "FORTIN POTRERO",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18803,
    localidad: "FORTIN MEDIA LUNA",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18804,
    localidad: "FORTIN LUGONES",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18805,
    localidad: "FORTIN LAVALLE",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18806,
    localidad: "FORTIN FONTANA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18807,
    localidad: "FORTIN CHAJA",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18808,
    localidad: "FORTIN CARDOSO",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18809,
    localidad: "FORTIN AGUILAR",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18810,
    localidad: "FONTANA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18811,
    localidad: "FLORENCIA",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18812,
    localidad: "FLORADORA",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18813,
    localidad: "F TOTORALITA L H",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 18814,
    localidad: "F SGTO 1RO LEYES",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18815,
    localidad: "F CABO 1RO CHAVES",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18816,
    localidad: "F CABO 1 LUGONES",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18817,
    localidad: "EX POSTA G LAVALLE",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18818,
    localidad: "EX FTIN COM FRIAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18819,
    localidad: "EX FORTIN ZELAYA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18820,
    localidad: "EX FORTIN ZELAVA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18821,
    localidad: "EX FORTIN WILDE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18822,
    localidad: "EX FORTIN SOLA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18823,
    localidad: "EX FORTIN P MILLAN",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18824,
    localidad: "EX FORTIN LAVALLE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18825,
    localidad: "EX FORTIN ARENALES",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18826,
    localidad: "ESTERO REDONDO",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18827,
    localidad: "ESTERO PATIÑO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18828,
    localidad: "ESTANCIA LOMA ALTA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18829,
    localidad: "ESTANCIA EL SABALO",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18830,
    localidad: "ESTADOS UNIDOS",
    cp: 3712,
    provincia_id: 4,
  },
  {
    id: 18831,
    localidad: "ENRIQUE URIEN",
    cp: 3544,
    provincia_id: 4,
  },
  {
    id: 18832,
    localidad: "EL ZAPALLAR",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18833,
    localidad: "EL ZANJON",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18834,
    localidad: "EL YULO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18835,
    localidad: "EL VISCACHERAL",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18836,
    localidad: "EL TUPI KM 474",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18837,
    localidad: "EL TRIANGULO",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18838,
    localidad: "EL TRIANGULO",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18839,
    localidad: "EL TREINTA Y SEIS",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18840,
    localidad: "EL TRAGADERO",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18841,
    localidad: "EL TOTORAL",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18842,
    localidad: "EL TACURUZAL",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18843,
    localidad: "EL SURR",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18844,
    localidad: "EL SOMBRERO NEGRO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18845,
    localidad: "EL SIMBOLAR",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18846,
    localidad: "EL SIMBOLAR",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18847,
    localidad: "EL SAUZALITO",
    cp: 3704,
    provincia_id: 4,
  },
  {
    id: 18848,
    localidad: "EL SAUZAL",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18849,
    localidad: "EL RETIRO",
    cp: 3522,
    provincia_id: 4,
  },
  {
    id: 18850,
    localidad: "EL REMANSO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18851,
    localidad: "EL RECREO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18852,
    localidad: "EL RECOVO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18853,
    localidad: "EL RECOVECO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18854,
    localidad: "EL RAIGONAL",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18855,
    localidad: "EL QUEBRACHAL",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18856,
    localidad: "EL PUCA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18857,
    localidad: "EL PORVENIR",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18858,
    localidad: "EL PORTEÑO",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18859,
    localidad: "EL PORTEÑITO",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18860,
    localidad: "EL PINTADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18861,
    localidad: "EL PINDO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18862,
    localidad: "EL PILON",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18863,
    localidad: "EL PICASO",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18864,
    localidad: "EL PERSEGUIDO",
    cp: 3712,
    provincia_id: 4,
  },
  {
    id: 18865,
    localidad: "EL PERDIDO",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18866,
    localidad: "EL PARAISO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18867,
    localidad: "EL PALO SANTO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18868,
    localidad: "EL PALMAR 3 ISLTAS",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18869,
    localidad: "EL PALMAR",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18870,
    localidad: "EL PALMAR",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18871,
    localidad: "EL PALMAR",
    cp: 3501,
    provincia_id: 4,
  },
  {
    id: 18872,
    localidad: "EL PALMAR",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18873,
    localidad: "EL ORO BLANCO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18874,
    localidad: "EL OBRAJE",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18875,
    localidad: "EL MOJON",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18876,
    localidad: "EL MIRASOL",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18877,
    localidad: "EL MARCADO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18878,
    localidad: "EL LAPACHO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18879,
    localidad: "EL ESTERO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18880,
    localidad: "EL ESTERO",
    cp: 3734,
    provincia_id: 4,
  },
  {
    id: 18881,
    localidad: "EL ESQUINERO",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18882,
    localidad: "EL ESPINILLO",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18883,
    localidad: "EL DESTIERRO",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18884,
    localidad: "EL DESIERTO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18885,
    localidad: "EL CURUNDU",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18886,
    localidad: "EL CUARENTA Y SEIS",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18887,
    localidad: "EL CUADRADO",
    cp: 3734,
    provincia_id: 4,
  },
  {
    id: 18888,
    localidad: "EL CORRALITO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18889,
    localidad: "EL COGOIK",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18890,
    localidad: "EL CINCUENTA",
    cp: 3550,
    provincia_id: 4,
  },
  {
    id: 18891,
    localidad: "EL CAVADO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18892,
    localidad: "EL CATORCE",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 18893,
    localidad: "EL CAÑON",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18894,
    localidad: "EL CAMPAMENTO",
    cp: 3524,
    provincia_id: 4,
  },
  {
    id: 18895,
    localidad: "EL CAJON",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18896,
    localidad: "EL BRAGADO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18897,
    localidad: "EL BORDO SANTO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18898,
    localidad: "EL BOQUERON",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18899,
    localidad: "EL ASUSTADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18900,
    localidad: "EL AIBALITO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18901,
    localidad: "EL AIBAL",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18902,
    localidad: "EL ACHERAL",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18903,
    localidad: "EL 44",
    cp: 3550,
    provincia_id: 4,
  },
  {
    id: 18904,
    localidad: "EL 15",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18905,
    localidad: "EL 15",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18906,
    localidad: "EL 14",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18907,
    localidad: "DOS BOLICHES",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18908,
    localidad: "DIEZ DE MAYO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18909,
    localidad: "DGO F SARMIENTO",
    cp: 3621,
    provincia_id: 4,
  },
  {
    id: 18910,
    localidad: "CURVA DE NOVOA",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18911,
    localidad: "CURANDU",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18912,
    localidad: "CUATRO BOCAS",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 18913,
    localidad: "CUATRO ARBOLES",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18914,
    localidad: "COTE LAI",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18915,
    localidad: "COSTA INE",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18916,
    localidad: "CORZUELA",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 18917,
    localidad: "CORRALITO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18918,
    localidad: "CORONEL DU GRATY",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18919,
    localidad: "CORONEL BRANDSEN",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18920,
    localidad: "CORONEL AVALOS",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18921,
    localidad: "CONCEPCION DEL BERMEJO",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18922,
    localidad: "CONCEP. DL BERMEJO",
    cp: 3708,
    provincia_id: 4,
  },
  {
    id: 18923,
    localidad: "COMANDANTE FONTANA",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18924,
    localidad: "COMANDANCIA FRIAS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18925,
    localidad: "COLONIAS UNIDAS",
    cp: 3532,
    provincia_id: 4,
  },
  {
    id: 18926,
    localidad: "COLONIAS UNIDAS",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18927,
    localidad: "COLONIA WELHERS",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18928,
    localidad: "COLONIA TACUARI",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18929,
    localidad: "COLONIA SCHMIDT",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18930,
    localidad: "COLONIA SANTA ROSA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18931,
    localidad: "COLONIA SAN ISIDRO",
    cp: 3526,
    provincia_id: 4,
  },
  {
    id: 18932,
    localidad: "COLONIA SABINA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18933,
    localidad: "COLONIA RODRIGUEZ PEÑA",
    cp: 3511,
    provincia_id: 4,
  },
  {
    id: 18934,
    localidad: "COLONIA RIO DE ORO",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18935,
    localidad: "COLONIA POPULAR",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18936,
    localidad: "COLONIA MIXTA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18937,
    localidad: "COLONIA MATHEU",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18938,
    localidad: "COLONIA LUCINDA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18939,
    localidad: "COLONIA LOTE 3",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18940,
    localidad: "COLONIA LOTE 12",
    cp: 3543,
    provincia_id: 4,
  },
  {
    id: 18941,
    localidad: "COLONIA LOTE 10",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18942,
    localidad: "COLONIA LOS GANZOS",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 18943,
    localidad: "COLONIA LA LOLA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18944,
    localidad: "COLONIA JUAN PENCO",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18945,
    localidad: "COLONIA JUAN JOSE CASTELLI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18946,
    localidad: "COLONIA JOSE MARMOL",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 18947,
    localidad: "COLONIA INDIGENA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18948,
    localidad: "COLONIA HERRERA",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 18949,
    localidad: "COLONIA GUALTIERI",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18950,
    localidad: "COLONIA FORTUNI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18951,
    localidad: "COLONIA FLORENCIA",
    cp: 3500,
    provincia_id: 4,
  },
  {
    id: 18952,
    localidad: "COLONIA ESPERANZA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18953,
    localidad: "COLONIA ESPERANZA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18954,
    localidad: "COLONIA ELISA",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18955,
    localidad: "COLONIA EL TIGRE",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18956,
    localidad: "COLONIA EL PILAR",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18957,
    localidad: "COLONIA EL FISCAL",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18958,
    localidad: "COLONIA EL CURUPI",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18959,
    localidad: "COLONIA EL CIERVO",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18960,
    localidad: "COLONIA EL ALAZAN",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18961,
    localidad: "COLONIA EL AGUARA",
    cp: 3534,
    provincia_id: 4,
  },
  {
    id: 18962,
    localidad: "COLONIA ECONOMIA",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18963,
    localidad: "COLONIA ECHEGARAY",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 18964,
    localidad: "COLONIA DRYDALE",
    cp: 3734,
    provincia_id: 4,
  },
  {
    id: 18965,
    localidad: "COLONIA CODUTTI",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18966,
    localidad: "COLONIA CABRAL",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18967,
    localidad: "COLONIA BRAVO",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18968,
    localidad: "COLONIA BERMEJO",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18969,
    localidad: "COLONIA BENITEZ",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18970,
    localidad: "COLONIA BENITEZ",
    cp: 3510,
    provincia_id: 4,
  },
  {
    id: 18971,
    localidad: "COLONIA BARRERA",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18972,
    localidad: "COLONIA BARANDA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 18973,
    localidad: "COLONIA ALTO TIGRE",
    cp: 3620,
    provincia_id: 4,
  },
  {
    id: 18974,
    localidad: "COLONIA ALELAY",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 18975,
    localidad: "COLONIA ABORIGEN",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 18976,
    localidad: "COLONIA ABATE",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 18977,
    localidad: "CIERVO PETISO",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18978,
    localidad: "CHOROTIS",
    cp: 3735,
    provincia_id: 4,
  },
  {
    id: 18979,
    localidad: "CHARATA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18980,
    localidad: "CHARADAI",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 18981,
    localidad: "CERRITO",
    cp: 3550,
    provincia_id: 4,
  },
  {
    id: 18982,
    localidad: "CERRITO",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 18983,
    localidad: "CENTRO FRONTERIZO CLORINDA",
    cp: 3642,
    provincia_id: 4,
  },
  {
    id: 18984,
    localidad: "CASTELLI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18985,
    localidad: "CARLOS PELEGRINI",
    cp: 3636,
    provincia_id: 4,
  },
  {
    id: 18986,
    localidad: "CAPITAN SOLARI",
    cp: 3515,
    provincia_id: 4,
  },
  {
    id: 18987,
    localidad: "CAPITAN JUAN SOLA",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 18988,
    localidad: "CAÑADA SAN PEDRO",
    cp: 3636,
    provincia_id: 4,
  },
  {
    id: 18989,
    localidad: "CANCHA LARGA",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 18990,
    localidad: "CAMPO ZAPA",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 18991,
    localidad: "CAMPO WINTER",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 18992,
    localidad: "CAMPO VERGE",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18993,
    localidad: "CAMPO URDANIZ",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 18994,
    localidad: "CAMPO OVEROS",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18995,
    localidad: "CAMPO NUEVO",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18996,
    localidad: "CAMPO LAS PUERTAS",
    cp: 3541,
    provincia_id: 4,
  },
  {
    id: 18997,
    localidad: "CAMPO LARGO",
    cp: 3716,
    provincia_id: 4,
  },
  {
    id: 18998,
    localidad: "CAMPO GRANDE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 18999,
    localidad: "CAMPO GOLA",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 19000,
    localidad: "CAMPO FERRANDO",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 19001,
    localidad: "CAMPO FELDMAN",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 19002,
    localidad: "CAMPO EL SURI",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 19003,
    localidad: "CAMPO EL ONZA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19004,
    localidad: "CAMPO EL JACARANDA",
    cp: 3733,
    provincia_id: 4,
  },
  {
    id: 19005,
    localidad: "CAMPO EL BERMEJO",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 19006,
    localidad: "CAMPO EL AIBAL",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19007,
    localidad: "CAMPO ECHEGARAY",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 19008,
    localidad: "CAMPO DE LA CHOZA",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 19009,
    localidad: "CAMPO DE GALNASI",
    cp: 3501,
    provincia_id: 4,
  },
  {
    id: 19010,
    localidad: "CALIFORNIA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19011,
    localidad: "CACUI",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 19012,
    localidad: "CABRAL CUE",
    cp: 3518,
    provincia_id: 4,
  },
  {
    id: 19013,
    localidad: "CABRAL",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 19014,
    localidad: "CABO ADRIANO AYALA",
    cp: 3519,
    provincia_id: 4,
  },
  {
    id: 19015,
    localidad: "CABEZA DE TIGRE",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 19016,
    localidad: "CABALLO MUERTO",
    cp: 3636,
    provincia_id: 4,
  },
  {
    id: 19017,
    localidad: "C VELEZ SARSFIELD",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 19018,
    localidad: "C TRES LAGUNAS",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 19019,
    localidad: "C SIETE ARBOLES",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 19020,
    localidad: "C SANTA ELENA",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 19021,
    localidad: "C SAN ANTONIO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19022,
    localidad: "C SAN ANTONIO",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 19023,
    localidad: "C RECONQUISTA",
    cp: 3624,
    provincia_id: 4,
  },
  {
    id: 19024,
    localidad: "C PUENTE URIBURU",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 19025,
    localidad: "C PUENTE PHILIPON",
    cp: 3514,
    provincia_id: 4,
  },
  {
    id: 19026,
    localidad: "C NECOCHEA SUD",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 19027,
    localidad: "C MONTE QUEMADO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19028,
    localidad: "C MARIANO SARRATEA",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 19029,
    localidad: "C LAS AVISPAS",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 19030,
    localidad: "C LA MARIA LUISA",
    cp: 3734,
    provincia_id: 4,
  },
  {
    id: 19031,
    localidad: "C LA FLORIDA GDE.",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19032,
    localidad: "C LA FLORIDA CHICA",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19033,
    localidad: "C LA FILOMENA",
    cp: 3509,
    provincia_id: 4,
  },
  {
    id: 19034,
    localidad: "C LA AVANZADA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 19035,
    localidad: "C JUAN LAVALLE",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 19036,
    localidad: "C JUAN LARREA",
    cp: 3730,
    provincia_id: 4,
  },
  {
    id: 19037,
    localidad: "C JUAN JOSE PASO",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 19038,
    localidad: "C JOSE MARMOL",
    cp: 3700,
    provincia_id: 4,
  },
  {
    id: 19039,
    localidad: "C J J CASTELLI",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19040,
    localidad: "C HIPOLITO VIEYTES",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 19041,
    localidad: "C HAMBURGUESA",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 19042,
    localidad: "C GUILLERMINA",
    cp: 3624,
    provincia_id: 4,
  },
  {
    id: 19043,
    localidad: "C GENERAL NECOCHEA",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 19044,
    localidad: "C EL TRIANGULO",
    cp: 3732,
    provincia_id: 4,
  },
  {
    id: 19045,
    localidad: "C EL SILENCIO",
    cp: 3624,
    provincia_id: 4,
  },
  {
    id: 19046,
    localidad: "C EL PARAISAL",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 19047,
    localidad: "C CUERO QUEMADO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 19048,
    localidad: "C CORONEL DORREGO",
    cp: 3511,
    provincia_id: 4,
  },
  {
    id: 19049,
    localidad: "C CORONEL DORREGO",
    cp: 3634,
    provincia_id: 4,
  },
  {
    id: 19050,
    localidad: "C CORONEL BRANDSEN",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 19051,
    localidad: "C CABEZA DE BUEY",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19052,
    localidad: "C BLAS PARERA",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 19053,
    localidad: "C BERNAR RIVAD",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 19054,
    localidad: "C ABORIGEN CHACO",
    cp: 3531,
    provincia_id: 4,
  },
  {
    id: 19055,
    localidad: "C A PAMPA NAPENAY",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 19056,
    localidad: "BUEN LUGAR",
    cp: 3636,
    provincia_id: 4,
  },
  {
    id: 19057,
    localidad: "BOLSA GRANDE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19058,
    localidad: "BOCAS",
    cp: 3536,
    provincia_id: 4,
  },
  {
    id: 19059,
    localidad: "BERLIN",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19060,
    localidad: "BASAIL",
    cp: 3516,
    provincia_id: 4,
  },
  {
    id: 19061,
    localidad: "BARRIO SARMIENTO",
    cp: 3699,
    provincia_id: 4,
  },
  {
    id: 19062,
    localidad: "BARRIO JOSE DE SAN MARTIN",
    cp: 3698,
    provincia_id: 4,
  },
  {
    id: 19063,
    localidad: "BARRANQUERAS",
    cp: 3503,
    provincia_id: 4,
  },
  {
    id: 19064,
    localidad: "BAJO VERDE",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19065,
    localidad: "BAJO HONDO",
    cp: 3705,
    provincia_id: 4,
  },
  {
    id: 19066,
    localidad: "B DE LAS CASAS",
    cp: 3622,
    provincia_id: 4,
  },
  {
    id: 19067,
    localidad: "AVIA TERAI",
    cp: 3706,
    provincia_id: 4,
  },
  {
    id: 19068,
    localidad: "AVANZADA",
    cp: 3540,
    provincia_id: 4,
  },
  {
    id: 19069,
    localidad: "ARROYO QUINTANA",
    cp: 3505,
    provincia_id: 4,
  },
  {
    id: 19070,
    localidad: "ARBOL SOLO",
    cp: 3513,
    provincia_id: 4,
  },
  {
    id: 19071,
    localidad: "ARBOL BLANCO",
    cp: 3731,
    provincia_id: 4,
  },
  {
    id: 19072,
    localidad: "AMAMBAY",
    cp: 3718,
    provincia_id: 4,
  },
  {
    id: 19073,
    localidad: "ALMIRANTE BROWN",
    cp: 3701,
    provincia_id: 4,
  },
  {
    id: 19074,
    localidad: "ALLENDE",
    cp: 3550,
    provincia_id: 4,
  },
  {
    id: 19075,
    localidad: "ALELOY",
    cp: 3703,
    provincia_id: 4,
  },
  {
    id: 19076,
    localidad: "ALDEA FORESTAL",
    cp: 3530,
    provincia_id: 4,
  },
  {
    id: 19077,
    localidad: "AGUA VERDE",
    cp: 3636,
    provincia_id: 4,
  },
  {
    id: 19078,
    localidad: "AGENTE F S IBAÑEZ",
    cp: 3624,
    provincia_id: 4,
  },
  {
    id: 19079,
    localidad: "9 DE JULIO",
    cp: 3643,
    provincia_id: 4,
  },
  {
    id: 19080,
    localidad: "2 DE MAYO",
    cp: 3722,
    provincia_id: 4,
  },
  {
    id: 19081,
    localidad: "ZORRO HUARCUNA",
    cp: 4237,
    provincia_id: 3,
  },
  {
    id: 19082,
    localidad: "ZARZA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19083,
    localidad: "ZARCITO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19084,
    localidad: "ZANJA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19085,
    localidad: "YOCAN",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19086,
    localidad: "YERBA BUENA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19087,
    localidad: "YAQUICHO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19088,
    localidad: "YAPES",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19089,
    localidad: "YAPES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19090,
    localidad: "VISCOTE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19091,
    localidad: "VINQUIS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19092,
    localidad: "VILLAVIL",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19093,
    localidad: "VILLA VIL",
    cp: 4741,
    provincia_id: 3,
  },
  {
    id: 19094,
    localidad: "VILLA SOTOMAYOR",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19095,
    localidad: "VILLA SELEME",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19096,
    localidad: "VILLA SAN ROQUE",
    cp: 5344,
    provincia_id: 3,
  },
  {
    id: 19097,
    localidad: "VILLA OFELIA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19098,
    localidad: "VILLA MACEDO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19099,
    localidad: "VILLA LAS PIRQUITAS",
    cp: 4713,
    provincia_id: 3,
  },
  {
    id: 19100,
    localidad: "VILLA GRACIA",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19101,
    localidad: "VILLA DOLORES",
    cp: 4716,
    provincia_id: 3,
  },
  {
    id: 19102,
    localidad: "VILLA CUBAS",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19103,
    localidad: "VILLA COLLANTES",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19104,
    localidad: "VILLA COLLANTES",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19105,
    localidad: "VILISMAN",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19106,
    localidad: "VICUQA PAMPA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19107,
    localidad: "VEGA TAMBERIA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19108,
    localidad: "VEGA CURUTU",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19109,
    localidad: "VALLECITO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19110,
    localidad: "VALLE VIEJO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19111,
    localidad: "V QUINTIN AHUMADA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19112,
    localidad: "V LAS PIRQUITAS",
    cp: 4713,
    provincia_id: 3,
  },
  {
    id: 19113,
    localidad: "V INDEPENDENCIA",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19114,
    localidad: "V CORONEL ARROYO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19115,
    localidad: "TUYUBIL",
    cp: 5329,
    provincia_id: 3,
  },
  {
    id: 19116,
    localidad: "TULA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19117,
    localidad: "TROYA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19118,
    localidad: "TRES PUERTAS",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19119,
    localidad: "TRES PUENTES",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19120,
    localidad: "TRES LOMAS",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19121,
    localidad: "TRAMPASACHA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19122,
    localidad: "TOTORILLA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19123,
    localidad: "TOTORAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19124,
    localidad: "TOTORA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19125,
    localidad: "TOROYACO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19126,
    localidad: "TORO PUJIO",
    cp: 5139,
    provincia_id: 3,
  },
  {
    id: 19127,
    localidad: "TORO MUERTO",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19128,
    localidad: "TIORCO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19129,
    localidad: "TINTIGASTA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19130,
    localidad: "TINOGASTA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19131,
    localidad: "TINAJERA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19132,
    localidad: "TIERRA VERDE",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19133,
    localidad: "TIERRA VERDE",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19134,
    localidad: "TERMAS VILLA VIL",
    cp: 4755,
    provincia_id: 3,
  },
  {
    id: 19135,
    localidad: "TELARITOS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19136,
    localidad: "TATON",
    cp: 5342,
    provincia_id: 3,
  },
  {
    id: 19137,
    localidad: "TAPSO",
    cp: 4234,
    provincia_id: 3,
  },
  {
    id: 19138,
    localidad: "TAPSO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19139,
    localidad: "TAMBU",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19140,
    localidad: "TAMBERIA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19141,
    localidad: "TALITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19142,
    localidad: "TALEGA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19143,
    localidad: "TALAR",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19144,
    localidad: "TALAMAYO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19145,
    localidad: "TALAGUADA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19146,
    localidad: "TALA ZAPATA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19147,
    localidad: "TALA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19148,
    localidad: "TAJAMARES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19149,
    localidad: "TACOPAMPA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19150,
    localidad: "TACO DE ABAJO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19151,
    localidad: "TACO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19152,
    localidad: "TACO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19153,
    localidad: "TACANA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19154,
    localidad: "TACAHUASI",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19155,
    localidad: "SURUIPIANA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19156,
    localidad: "SURIYACO",
    cp: 5329,
    provincia_id: 3,
  },
  {
    id: 19157,
    localidad: "SUPERI",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19158,
    localidad: "SUNCHO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19159,
    localidad: "SUMAMPA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19160,
    localidad: "SUMALAO",
    cp: 4714,
    provincia_id: 3,
  },
  {
    id: 19161,
    localidad: "SUMALAO",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19162,
    localidad: "SUCUMA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19163,
    localidad: "SOLEDAD",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19164,
    localidad: "SISIGUASI",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19165,
    localidad: "SINGUIL",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19166,
    localidad: "SIJAN",
    cp: 5319,
    provincia_id: 3,
  },
  {
    id: 19167,
    localidad: "SIJAN",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19168,
    localidad: "SIERRA BRAVA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19169,
    localidad: "SICHA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19170,
    localidad: "SHINCAL",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19171,
    localidad: "SEBILA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19172,
    localidad: "SEBILA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19173,
    localidad: "SEBILA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19174,
    localidad: "SAUJIL",
    cp: 5321,
    provincia_id: 3,
  },
  {
    id: 19175,
    localidad: "SAUGIL",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19176,
    localidad: "SAUCE MAYO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19177,
    localidad: "SAUCE HUACHO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19178,
    localidad: "SAUCE HUACHO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19179,
    localidad: "SAUCE DE LOS CEJAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19180,
    localidad: "SAUCE",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19181,
    localidad: "SANTOS LUGARES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19182,
    localidad: "SANTO TOMAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19183,
    localidad: "SANTO DOMINGO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19184,
    localidad: "SANTA ROSA",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19185,
    localidad: "SANTA ROSA",
    cp: 5343,
    provincia_id: 3,
  },
  {
    id: 19186,
    localidad: "SANTA MARIA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19187,
    localidad: "SANTA MARIA",
    cp: 4011,
    provincia_id: 3,
  },
  {
    id: 19188,
    localidad: "SANTA MARIA",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19189,
    localidad: "SANTA LUCIA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19190,
    localidad: "SANTA GERTRUDIS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19191,
    localidad: "SANTA CRUZ",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19192,
    localidad: "SANTA CRUZ",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19193,
    localidad: "SANTA CECILIA",
    cp: 2561,
    provincia_id: 3,
  },
  {
    id: 19194,
    localidad: "SANTA BARBARA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19195,
    localidad: "SANTA ANA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19196,
    localidad: "SANCHO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19197,
    localidad: "SAN VICENTE",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19198,
    localidad: "SAN ROQUE",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19199,
    localidad: "SAN RAFAEL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19200,
    localidad: "SAN PEDRO CAPAYAN",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19201,
    localidad: "SAN PEDRO CAPAYAN",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19202,
    localidad: "SAN PABLO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19203,
    localidad: "SAN PABLO",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19204,
    localidad: "SAN NICOLAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19205,
    localidad: "SAN MIGUEL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19206,
    localidad: "SAN MARTIN",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19207,
    localidad: "SAN MARTIN",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19208,
    localidad: "SAN MANUEL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19209,
    localidad: "SAN LUIS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19210,
    localidad: "SAN LORENZO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19211,
    localidad: "SAN LORENZO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19212,
    localidad: "SAN JUANCITO",
    cp: 4233,
    provincia_id: 3,
  },
  {
    id: 19213,
    localidad: "SAN JOSE NORTE",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19214,
    localidad: "SAN JOSE BANDA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19215,
    localidad: "SAN JOSE",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19216,
    localidad: "SAN JOSE",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19217,
    localidad: "SAN JOSE",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19218,
    localidad: "SAN JOSE",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19219,
    localidad: "SAN JOSE",
    cp: 5319,
    provincia_id: 3,
  },
  {
    id: 19220,
    localidad: "SAN JERONIMO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19221,
    localidad: "SAN ISIDRO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19222,
    localidad: "SAN GERONIMO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19223,
    localidad: "SAN FRANCISCO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19224,
    localidad: "SAN FERNANDO DEL VALLE DE CATAMARCA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19225,
    localidad: "SAN FERNANDO DEL VALLE DE CATAMARCA",
    cp: 4704,
    provincia_id: 3,
  },
  {
    id: 19226,
    localidad: "SAN FERNANDO DEL VALLE DE CATAMARCA",
    cp: 4702,
    provincia_id: 3,
  },
  {
    id: 19227,
    localidad: "SAN FERNANDO DEL VALLE DE CATAMARCA",
    cp: 4703,
    provincia_id: 3,
  },
  {
    id: 19228,
    localidad: "SAN FERNANDO",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19229,
    localidad: "SAN FDO DEL VALLE DE CATAMAR",
    cp: 4702,
    provincia_id: 3,
  },
  {
    id: 19230,
    localidad: "SAN BUENAVENTURA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19231,
    localidad: "SAN BUENAVENTURA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19232,
    localidad: "SAN BERNARDO",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19233,
    localidad: "SAN ANTONIO FRAY M ESQUIU",
    cp: 4708,
    provincia_id: 3,
  },
  {
    id: 19234,
    localidad: "SAN ANTONIO DE PACLIN",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19235,
    localidad: "SAN ANTONIO DE LA PAZ",
    cp: 5264,
    provincia_id: 3,
  },
  {
    id: 19236,
    localidad: "SAN ANTONIO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19237,
    localidad: "SAN ANTONIO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19238,
    localidad: "SAN ANTONIO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19239,
    localidad: "SAN AGUSTIN",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19240,
    localidad: "SALCEDO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19241,
    localidad: "SALAUCA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19242,
    localidad: "SALADO",
    cp: 5332,
    provincia_id: 3,
  },
  {
    id: 19243,
    localidad: "S.ANT. M ESQUIU",
    cp: 4708,
    provincia_id: 3,
  },
  {
    id: 19244,
    localidad: "S ROSA DEL GIGANTE",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19245,
    localidad: "S J DE TINOGASTA",
    cp: 5339,
    provincia_id: 3,
  },
  {
    id: 19246,
    localidad: "S ANT DEL CAJON",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19247,
    localidad: "S ANT DE PANCLIN",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19248,
    localidad: "S ANT DE P BLANCA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19249,
    localidad: "S ANT DE LA PAZ",
    cp: 5264,
    provincia_id: 3,
  },
  {
    id: 19250,
    localidad: "RUMIMONION",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19251,
    localidad: "ROSARIO DE COLANA",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19252,
    localidad: "ROS. DEL SUMALAO",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19253,
    localidad: "ROS DEL SUMALAO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19254,
    localidad: "ROMANCE",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19255,
    localidad: "RODEO GRANDE",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19256,
    localidad: "RODEO GERVAN",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19257,
    localidad: "RODEO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19258,
    localidad: "RIO POTRERO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19259,
    localidad: "RIO LOS MOLINOS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19260,
    localidad: "RIO DE LOS INDIOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19261,
    localidad: "RIO DE LA PLATA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19262,
    localidad: "RIO DE LA DORADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19263,
    localidad: "RIO DE DON DIEGO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19264,
    localidad: "RIO DE BAZANES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19265,
    localidad: "RIO DE AVILA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19266,
    localidad: "RIO COLORADO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19267,
    localidad: "RIO CHICO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19268,
    localidad: "RIO ABAJO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19269,
    localidad: "RINCON GRANDE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19270,
    localidad: "RINCON",
    cp: 5317,
    provincia_id: 3,
  },
  {
    id: 19271,
    localidad: "RINCON",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19272,
    localidad: "RETIRO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19273,
    localidad: "RECREO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19274,
    localidad: "RECREO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19275,
    localidad: "RAMBLONES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19276,
    localidad: "RAFAEL CASTILLO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19277,
    localidad: "QUSTO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19278,
    localidad: "QUISCA CHICA",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 19279,
    localidad: "QUIQUERO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19280,
    localidad: "QUIMILPA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19281,
    localidad: "QUILMES",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19282,
    localidad: "QUEMADO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19283,
    localidad: "QUEMADITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19284,
    localidad: "QUEBRADA HONDA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19285,
    localidad: "QUEBRACHOS BLANCOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19286,
    localidad: "QUEBRACHO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19287,
    localidad: "QUEBRACHAL",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19288,
    localidad: "PUNTA DEL POZO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19289,
    localidad: "PUNTA DE BALASTO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19290,
    localidad: "PUNTA DE AGUA",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19291,
    localidad: "PUESTO SABATTE",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19292,
    localidad: "PUESTO DEL MEDIO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19293,
    localidad: "PUESTO DE VERA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19294,
    localidad: "PUESTO DE LOS MORALES",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19295,
    localidad: "PUESTO DE LA VIUDA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19296,
    localidad: "PUESTO DE FADEL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19297,
    localidad: "PUERTO POTRERO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19298,
    localidad: "PUERTO DE LA PAMPA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19299,
    localidad: "PUERTO CHIPI",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19300,
    localidad: "PUERTO BLANCO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19301,
    localidad: "PUERTA GRANDE",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19302,
    localidad: "PUEBLITO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19303,
    localidad: "PTO ESPINAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19304,
    localidad: "POZUELOS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19305,
    localidad: "POZOS CAVADOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19306,
    localidad: "POZO GRANDE",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19307,
    localidad: "POZO DEL MISTOL",
    cp: 4706,
    provincia_id: 3,
  },
  {
    id: 19308,
    localidad: "POZO DEL CAMPO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19309,
    localidad: "POZO DEL ALGARROBO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19310,
    localidad: "POZO DE PIEDRA",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19311,
    localidad: "POZANCONES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19312,
    localidad: "POTRERO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19313,
    localidad: "POTRERITO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19314,
    localidad: "POSTA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19315,
    localidad: "PORTILLO CHICO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19316,
    localidad: "PORTEZUELO",
    cp: 4716,
    provincia_id: 3,
  },
  {
    id: 19317,
    localidad: "PORTEZUELO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19318,
    localidad: "PORTEZUELO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19319,
    localidad: "POMANCILLO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19320,
    localidad: "POMAN",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19321,
    localidad: "POLCOS",
    cp: 4730,
    provincia_id: 3,
  },
  {
    id: 19322,
    localidad: "POCITOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19323,
    localidad: "PLUMERO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19324,
    localidad: "PLAZA DE SAN PEDRO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19325,
    localidad: "PLANCHADA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19326,
    localidad: "PILCIAO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19327,
    localidad: "PIEDRITA BLANCA",
    cp: 5271,
    provincia_id: 3,
  },
  {
    id: 19328,
    localidad: "PIEDRA LARGA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19329,
    localidad: "PIEDRA BLANCA",
    cp: 4709,
    provincia_id: 3,
  },
  {
    id: 19330,
    localidad: "PIE DEL MEDANO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19331,
    localidad: "PICHAO",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 19332,
    localidad: "PEQON",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19333,
    localidad: "PEQAS BLANCAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19334,
    localidad: "PEQAFLOR",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19335,
    localidad: "PASTOS LARGOS",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19336,
    localidad: "PASTOS AMARILLOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19337,
    localidad: "PASO SAN ISIDRO",
    cp: 5351,
    provincia_id: 3,
  },
  {
    id: 19338,
    localidad: "PARECITAS",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19339,
    localidad: "PARANA",
    cp: 5266,
    provincia_id: 3,
  },
  {
    id: 19340,
    localidad: "PARADA KM 62",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19341,
    localidad: "PAPA CHACRA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19342,
    localidad: "PANTANOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19343,
    localidad: "PAN DE AZUCAR",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19344,
    localidad: "PAMPA POZO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19345,
    localidad: "PAMPA DE ACHALA",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19346,
    localidad: "PAMPA CIENAGA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19347,
    localidad: "PAMPA CHACRA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19348,
    localidad: "PAMPA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19349,
    localidad: "PALOMA YACO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19350,
    localidad: "PALO SECO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19351,
    localidad: "PALO SECO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19352,
    localidad: "PALO PARADO",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19353,
    localidad: "PALO PARADO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19354,
    localidad: "PALO LABRADO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19355,
    localidad: "PALO CRUZ",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19356,
    localidad: "PALO BLANCO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19357,
    localidad: "PALO BLANCO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19358,
    localidad: "PALACIOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19359,
    localidad: "PAJANGUILLO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19360,
    localidad: "PADERCITAS",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19361,
    localidad: "P LOS CHAQARITOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19362,
    localidad: "P DE LOS MORALES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19363,
    localidad: "OYOLA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19364,
    localidad: "OVEJERIA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19365,
    localidad: "OVANTA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19366,
    localidad: "ORELLANO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19367,
    localidad: "OLTA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19368,
    localidad: "OLMOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19369,
    localidad: "OLLITA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19370,
    localidad: "OJO DE LA CORTADER",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19371,
    localidad: "OJO DE AGUA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19372,
    localidad: "OJO DE AGUA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19373,
    localidad: "OJO DE AGUA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19374,
    localidad: "OCHO VADOS",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19375,
    localidad: "OCHO VADOS",
    cp: 4713,
    provincia_id: 3,
  },
  {
    id: 19376,
    localidad: "NUEVA ANDALUCIA",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19377,
    localidad: "NOGALITO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19378,
    localidad: "NEGRO MUERTO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19379,
    localidad: "NAVIGAN",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19380,
    localidad: "NAVAGUIN",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19381,
    localidad: "NAIPA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19382,
    localidad: "NACIMIENTO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19383,
    localidad: "NAC. DEL BOLSON",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19384,
    localidad: "NAC. DE S ANTONIO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19385,
    localidad: "NAC. DE ARRIBA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19386,
    localidad: "NAC. DE ABAJO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19387,
    localidad: "MUTQUIN",
    cp: 5317,
    provincia_id: 3,
  },
  {
    id: 19388,
    localidad: "MOYARCITO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19389,
    localidad: "MOTA BOTELLO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19390,
    localidad: "MONTEGASTA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19391,
    localidad: "MONTE REDONDO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19392,
    localidad: "MONTE POTRERO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19393,
    localidad: "MOLLEGASTA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19394,
    localidad: "MOLLECITO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19395,
    localidad: "MOLLECITO",
    cp: 5319,
    provincia_id: 3,
  },
  {
    id: 19396,
    localidad: "MOLLE QUEMADO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19397,
    localidad: "MISTOL ANCHO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19398,
    localidad: "MIRAFLORES",
    cp: 5272,
    provincia_id: 3,
  },
  {
    id: 19399,
    localidad: "MIRAFLORES",
    cp: 4724,
    provincia_id: 3,
  },
  {
    id: 19400,
    localidad: "MINAS AGUA TAPADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19401,
    localidad: "MINA INCA HUASI",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19402,
    localidad: "MINA DAL",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19403,
    localidad: "MI VALLE",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19404,
    localidad: "MESILLAS",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19405,
    localidad: "MESADA D LS ZARATE",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19406,
    localidad: "MEDANO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19407,
    localidad: "MEDANITOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19408,
    localidad: "MEDANITO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19409,
    localidad: "MATAMBRE",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19410,
    localidad: "MARULL",
    cp: 5139,
    provincia_id: 3,
  },
  {
    id: 19411,
    localidad: "MARIA ELENA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19412,
    localidad: "MARIA DORA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19413,
    localidad: "MANANTIALES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19414,
    localidad: "MALVINAS ARG",
    cp: 4784,
    provincia_id: 3,
  },
  {
    id: 19415,
    localidad: "MALLIN 2",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19416,
    localidad: "MALLIN 1",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19417,
    localidad: "MALAGUEQO",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19418,
    localidad: "MAJADA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19419,
    localidad: "LUNA AGUADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19420,
    localidad: "LOS ZANJONES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19421,
    localidad: "LOS VARELAS",
    cp: 4711,
    provincia_id: 3,
  },
  {
    id: 19422,
    localidad: "LOS VALVEROS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19423,
    localidad: "LOS VALDEZ",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19424,
    localidad: "LOS VALDES",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19425,
    localidad: "LOS TRONCOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19426,
    localidad: "LOS TALAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19427,
    localidad: "LOS SALTOS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19428,
    localidad: "LOS RINCONES",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19429,
    localidad: "LOS RASTROJOS",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19430,
    localidad: "LOS QUINTEROS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19431,
    localidad: "LOS PUESTOS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19432,
    localidad: "LOS PUESTOS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19433,
    localidad: "LOS PUESTOS",
    cp: 5319,
    provincia_id: 3,
  },
  {
    id: 19434,
    localidad: "LOS POZUELOS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19435,
    localidad: "LOS POZUELOS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19436,
    localidad: "LOS POTRERILLOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19437,
    localidad: "LOS POCITOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19438,
    localidad: "LOS POCITOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19439,
    localidad: "LOS PIQUILLINES",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19440,
    localidad: "LOS PINTADOS",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19441,
    localidad: "LOS PINOS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19442,
    localidad: "LOS PEDRAZAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19443,
    localidad: "LOS PALACIOS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19444,
    localidad: "LOS OVEJEROS",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19445,
    localidad: "LOS ORTICES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19446,
    localidad: "LOS ORTICES",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19447,
    localidad: "LOS OLIVARES",
    cp: 5327,
    provincia_id: 3,
  },
  {
    id: 19448,
    localidad: "LOS NOGALES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19449,
    localidad: "LOS NAVARROS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19450,
    localidad: "LOS NARVAEZ",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19451,
    localidad: "LOS NACIMIENTOS",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19452,
    localidad: "LOS NACIMIENTOS",
    cp: 5353,
    provincia_id: 3,
  },
  {
    id: 19453,
    localidad: "LOS MORTEROS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19454,
    localidad: "LOS MORTEROS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19455,
    localidad: "LOS MORTEROS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19456,
    localidad: "LOS MORTERITOS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19457,
    localidad: "LOS MOLLES",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19458,
    localidad: "LOS MOLLES",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19459,
    localidad: "LOS MOLLES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19460,
    localidad: "LOS MOGOTES",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19461,
    localidad: "LOS LOROS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19462,
    localidad: "LOS HUESOS",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19463,
    localidad: "LOS HUAYCOS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19464,
    localidad: "LOS GUINDOS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19465,
    localidad: "LOS GUAYTIMAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19466,
    localidad: "LOS GONZALES",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19467,
    localidad: "LOS GALPONES",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19468,
    localidad: "LOS FRANCES",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19469,
    localidad: "LOS ESTANTES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19470,
    localidad: "LOS ESLABONES",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19471,
    localidad: "LOS CORRALES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19472,
    localidad: "LOS CORDOBESES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19473,
    localidad: "LOS CORDOBESES",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19474,
    localidad: "LOS COLORADOS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19475,
    localidad: "LOS CISTERNAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19476,
    localidad: "LOS CHAQARES",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19477,
    localidad: "LOS CHAÑARITOS",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19478,
    localidad: "LOS CHANCAROS",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19479,
    localidad: "LOS CHANAMPA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19480,
    localidad: "LOS CERRILLOS",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19481,
    localidad: "LOS CAUDILLOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19482,
    localidad: "LOS CASTILLOS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19483,
    localidad: "LOS CADILLOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19484,
    localidad: "LOS BULACIOS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19485,
    localidad: "LOS BAZAN",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19486,
    localidad: "LOS BASTIDORES",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19487,
    localidad: "LOS BALVERDIS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19488,
    localidad: "LOS ANGELES",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19489,
    localidad: "LOS ALTOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19490,
    localidad: "LOS ALAMOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19491,
    localidad: "LOROHUASI",
    cp: 5325,
    provincia_id: 3,
  },
  {
    id: 19492,
    localidad: "LORO HUASI",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19493,
    localidad: "LORO HUASI",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19494,
    localidad: "LONDRES OESTE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19495,
    localidad: "LONDRES ESTE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19496,
    localidad: "LONDRES",
    cp: 4753,
    provincia_id: 3,
  },
  {
    id: 19497,
    localidad: "LOMA SOLA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19498,
    localidad: "LOMA GRANDE",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19499,
    localidad: "LOMA CORTADA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19500,
    localidad: "LOCONTE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19501,
    localidad: "LIEBRE",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19502,
    localidad: "LAVALLE",
    cp: 5347,
    provincia_id: 3,
  },
  {
    id: 19503,
    localidad: "LAS ZANJAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19504,
    localidad: "LAS VARITAS",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19505,
    localidad: "LAS TUNAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19506,
    localidad: "LAS TUNAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19507,
    localidad: "LAS TRILLAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19508,
    localidad: "LAS TRANQUITAS",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19509,
    localidad: "LAS TRANCAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19510,
    localidad: "LAS TOSCAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19511,
    localidad: "LAS TEJAS DE VALLE VIEJO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19512,
    localidad: "LAS TEJAS DE V VIE",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19513,
    localidad: "LAS TEJAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19514,
    localidad: "LAS TEJAS",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19515,
    localidad: "LAS TAPIAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19516,
    localidad: "LAS TAPIAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19517,
    localidad: "LAS RETAMAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19518,
    localidad: "LAS PIEDRAS BCAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19519,
    localidad: "LAS PEQAS",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19520,
    localidad: "LAS PELADAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19521,
    localidad: "LAS PAPAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19522,
    localidad: "LAS PAMPITAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19523,
    localidad: "LAS PAMPITAS",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19524,
    localidad: "LAS PAMPAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19525,
    localidad: "LAS PAMPAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19526,
    localidad: "LAS PALOMAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19527,
    localidad: "LAS PALMITAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19528,
    localidad: "LAS PALMITAS",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19529,
    localidad: "LAS PALMAS",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19530,
    localidad: "LAS PALMAS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19531,
    localidad: "LAS PADERCITAS",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19532,
    localidad: "LAS MOJARRAS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19533,
    localidad: "LAS MANZAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19534,
    localidad: "LAS LOSAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19535,
    localidad: "LAS LOMITAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19536,
    localidad: "LAS LOMITAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19537,
    localidad: "LAS LOMITAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19538,
    localidad: "LAS LATILLAS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19539,
    localidad: "LAS LATILLAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19540,
    localidad: "LAS LAJAS",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19541,
    localidad: "LAS LAJAS",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19542,
    localidad: "LAS LAJAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19543,
    localidad: "LAS JUSTAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19544,
    localidad: "LAS JUNTAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19545,
    localidad: "LAS JUNTAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19546,
    localidad: "LAS IGUANAS",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19547,
    localidad: "LAS IGUANAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19548,
    localidad: "LAS HUERTAS",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19549,
    localidad: "LAS HIGUERITAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19550,
    localidad: "LAS HERAS",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19551,
    localidad: "LAS GALERAS",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19552,
    localidad: "LAS ESTANCIAS",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19553,
    localidad: "LAS ESQUINAS",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19554,
    localidad: "LAS ENSENADAS",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19555,
    localidad: "LAS CUEVAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19556,
    localidad: "LAS CUCHILLAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19557,
    localidad: "LAS CUCHILLAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19558,
    localidad: "LAS CORTADERITAS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19559,
    localidad: "LAS CHACRITAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19560,
    localidad: "LAS CHACRAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19561,
    localidad: "LAS CHACRAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19562,
    localidad: "LAS CAYAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19563,
    localidad: "LAS CAQAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19564,
    localidad: "LAS CAÑAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19565,
    localidad: "LAS CAÑAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19566,
    localidad: "LAS BURRAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19567,
    localidad: "LAS BAYAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19568,
    localidad: "LAS BARRAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19569,
    localidad: "LAS BARRANCAS",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19570,
    localidad: "LAS BARRANCAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19571,
    localidad: "LAS B CASA ARMADA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19572,
    localidad: "LAS ALERAS",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19573,
    localidad: "LAS AGUITAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19574,
    localidad: "LAS AGUADITAS",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19575,
    localidad: "LAS ABRAS",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19576,
    localidad: "LAMPASO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19577,
    localidad: "LAMPASILLO",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19578,
    localidad: "LAMPASILLO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19579,
    localidad: "LAJA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19580,
    localidad: "LAGUNITA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19581,
    localidad: "LAGUNA COLORADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19582,
    localidad: "LAGUNA BLANCA",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19583,
    localidad: "LA ZANJA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19584,
    localidad: "LA VIQA DE ABAJO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19585,
    localidad: "LA VIQA",
    cp: 4722,
    provincia_id: 3,
  },
  {
    id: 19586,
    localidad: "LA VIQA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19587,
    localidad: "LA VIÑA",
    cp: 4722,
    provincia_id: 3,
  },
  {
    id: 19588,
    localidad: "LA VICTORIA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19589,
    localidad: "LA TOTORA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19590,
    localidad: "LA TOMA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19591,
    localidad: "LA TIGRA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19592,
    localidad: "LA TERCENA",
    cp: 4717,
    provincia_id: 3,
  },
  {
    id: 19593,
    localidad: "LA SOLEDAD",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19594,
    localidad: "LA SALVIA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19595,
    localidad: "LA REPRESA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19596,
    localidad: "LA RENOVACION",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19597,
    localidad: "LA RENOVACION",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19598,
    localidad: "LA RAMADITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19599,
    localidad: "LA RAMADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19600,
    localidad: "LA QUEBRADA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19601,
    localidad: "LA QUEBRADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19602,
    localidad: "LA PUNTILLA DE S J",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19603,
    localidad: "LA PUNTILLA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19604,
    localidad: "LA PUNTILLA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19605,
    localidad: "LA PUERTA DE SAN JOSE",
    cp: 4748,
    provincia_id: 3,
  },
  {
    id: 19606,
    localidad: "LA PUERTA DE S J",
    cp: 4748,
    provincia_id: 3,
  },
  {
    id: 19607,
    localidad: "LA PUERTA",
    cp: 4711,
    provincia_id: 3,
  },
  {
    id: 19608,
    localidad: "LA PLAZA",
    cp: 5329,
    provincia_id: 3,
  },
  {
    id: 19609,
    localidad: "LA PINTADA",
    cp: 5271,
    provincia_id: 3,
  },
  {
    id: 19610,
    localidad: "LA PIEDRA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19611,
    localidad: "LA PERLITA",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19612,
    localidad: "LA PEQA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19613,
    localidad: "LA PARAGUAYA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19614,
    localidad: "LA PARADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19615,
    localidad: "LA PALCA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19616,
    localidad: "LA OVEJERIA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19617,
    localidad: "LA OVEJERIA",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19618,
    localidad: "LA OLLADA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19619,
    localidad: "LA MONTOSA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19620,
    localidad: "LA MESADA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19621,
    localidad: "LA MESADA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19622,
    localidad: "LA MERCED",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19623,
    localidad: "LA MERCED",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19624,
    localidad: "LA MARAVILLA",
    cp: 5351,
    provincia_id: 3,
  },
  {
    id: 19625,
    localidad: "LA MARAVILLA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19626,
    localidad: "LA MAJADA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19627,
    localidad: "LA LOMA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19628,
    localidad: "LA LIBERTAD",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19629,
    localidad: "LA LAGUNA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19630,
    localidad: "LA ISLA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19631,
    localidad: "LA ISLA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19632,
    localidad: "LA HUERTA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19633,
    localidad: "LA HUERTA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19634,
    localidad: "LA HOYADA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19635,
    localidad: "LA HOYADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19636,
    localidad: "LA HOYADA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19637,
    localidad: "LA HORQUETA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19638,
    localidad: "LA HIGUERITA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19639,
    localidad: "LA HIGUERITA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19640,
    localidad: "LA HIGUERA",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19641,
    localidad: "LA GUARDIA",
    cp: 5262,
    provincia_id: 3,
  },
  {
    id: 19642,
    localidad: "LA GUARDIA",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19643,
    localidad: "LA GRANJA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19644,
    localidad: "LA FLORIDA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19645,
    localidad: "LA FLORIDA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19646,
    localidad: "LA FALDA DE SAN ANTONIO",
    cp: 4731,
    provincia_id: 3,
  },
  {
    id: 19647,
    localidad: "LA FALDA DE S ANT",
    cp: 4731,
    provincia_id: 3,
  },
  {
    id: 19648,
    localidad: "LA FALDA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19649,
    localidad: "LA FALDA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19650,
    localidad: "LA FALDA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19651,
    localidad: "LA ESTRELLA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19652,
    localidad: "LA ESTANZUELA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19653,
    localidad: "LA ESTANCITA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19654,
    localidad: "LA ESTANCIA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19655,
    localidad: "LA ESTANCIA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19656,
    localidad: "LA ESTANCIA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19657,
    localidad: "LA ESQUINA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19658,
    localidad: "LA DORADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19659,
    localidad: "LA CUESTA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19660,
    localidad: "LA COSTA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19661,
    localidad: "LA COLONIA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19662,
    localidad: "LA CIENAGA",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19663,
    localidad: "LA CIENAGA",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19664,
    localidad: "LA CIENAGA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19665,
    localidad: "LA CHILCA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19666,
    localidad: "LA CHACARITA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19667,
    localidad: "LA CARRERA",
    cp: 4712,
    provincia_id: 3,
  },
  {
    id: 19668,
    localidad: "LA CAQADA LARGA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19669,
    localidad: "LA CAQADA",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19670,
    localidad: "LA CAQADA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19671,
    localidad: "LA CAQADA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19672,
    localidad: "LA CAQADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19673,
    localidad: "LA CAQADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19674,
    localidad: "LA CAPELLANIA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19675,
    localidad: "LA CAPELLANIA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19676,
    localidad: "LA CAÑADA",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19677,
    localidad: "LA CANDELARIA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19678,
    localidad: "LA CAMPANA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19679,
    localidad: "LA CAMPANA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19680,
    localidad: "LA CALERA DL SAUCE",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19681,
    localidad: "LA CALERA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19682,
    localidad: "LA CALERA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19683,
    localidad: "LA CALERA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19684,
    localidad: "LA C DE LOS PADRES",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19685,
    localidad: "LA C D LS ZONDONES",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19686,
    localidad: "LA BUENA ESTRELLA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19687,
    localidad: "LA BREA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19688,
    localidad: "LA BREA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19689,
    localidad: "LA BEBIDA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19690,
    localidad: "LA BATEA",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19691,
    localidad: "LA BARROSA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19692,
    localidad: "LA BARROSA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19693,
    localidad: "LA BARRANCA LARGA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19694,
    localidad: "LA BANDA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19695,
    localidad: "LA BANDA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19696,
    localidad: "LA BANDA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19697,
    localidad: "LA BAJADA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19698,
    localidad: "LA BAJADA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19699,
    localidad: "LA ANTIGUA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19700,
    localidad: "LA ALAMEDA",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19701,
    localidad: "LA AGUITA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19702,
    localidad: "LA AGUADITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19703,
    localidad: "LA AGUADITA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19704,
    localidad: "LA AGUADA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19705,
    localidad: "LA AGUADA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19706,
    localidad: "LA AGUADA",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19707,
    localidad: "LA AGUADA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19708,
    localidad: "L CUCHIL DEL AYBAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19709,
    localidad: "KILOMETRO 999",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19710,
    localidad: "KILOMETRO 997",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19711,
    localidad: "KILOMETRO 99",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19712,
    localidad: "KILOMETRO 969",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19713,
    localidad: "KILOMETRO 955",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19714,
    localidad: "KILOMETRO 38",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19715,
    localidad: "KILOMETRO 128",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19716,
    localidad: "KILOMETRO 1093",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19717,
    localidad: "KILOMETRO 1017",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19718,
    localidad: "KILOMETRO 1006",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19719,
    localidad: "JUNTA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19720,
    localidad: "JUMEAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19721,
    localidad: "JULIPAO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19722,
    localidad: "JESUS MARIA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19723,
    localidad: "JACIPUNCO",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19724,
    localidad: "ISLA LARGA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19725,
    localidad: "IPIZCA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19726,
    localidad: "INFANZON",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19727,
    localidad: "INACILLO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19728,
    localidad: "ILOGA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19729,
    localidad: "IGLESIA VIEJA",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19730,
    localidad: "ICAQO",
    cp: 5265,
    provincia_id: 3,
  },
  {
    id: 19731,
    localidad: "HUMAYA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19732,
    localidad: "HUILLAPIMA",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19733,
    localidad: "HUILLAPIMA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19734,
    localidad: "HUAZAN",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19735,
    localidad: "HUAYCAMA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19736,
    localidad: "HUASI CIENAGA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19737,
    localidad: "HUASCHASCHI",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19738,
    localidad: "HUASAYACO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19739,
    localidad: "HUALFIN",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19740,
    localidad: "HUACRA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19741,
    localidad: "HUACO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19742,
    localidad: "HUACO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19743,
    localidad: "HIGUERA DL ALUMBRE",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19744,
    localidad: "GUINCHO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19745,
    localidad: "GUAYAMBA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19746,
    localidad: "GUANCHIN",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19747,
    localidad: "GUANCHICITO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19748,
    localidad: "GUANACO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19749,
    localidad: "GENTILE",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19750,
    localidad: "GARZON",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19751,
    localidad: "GARAY",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19752,
    localidad: "GALPON",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19753,
    localidad: "FUERTE QUEMADO",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 19754,
    localidad: "FLORIDA",
    cp: 4237,
    provincia_id: 3,
  },
  {
    id: 19755,
    localidad: "FIAMBALA",
    cp: 5345,
    provincia_id: 3,
  },
  {
    id: 19756,
    localidad: "FARALLON NEGRO",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19757,
    localidad: "FAMATANCA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19758,
    localidad: "FAMABALASTRO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19759,
    localidad: "FALDEO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19760,
    localidad: "ESTANQUE",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19761,
    localidad: "ESTANCITO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19762,
    localidad: "ESTANCIA VIEJA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19763,
    localidad: "ESTANCIA VIEJA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19764,
    localidad: "ESTANCIA SAN ROQUE",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19765,
    localidad: "ESTANCIA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19766,
    localidad: "ESQUIU",
    cp: 5261,
    provincia_id: 3,
  },
  {
    id: 19767,
    localidad: "ESPZA D L CERRILLO",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19768,
    localidad: "ENTRE RIOS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19769,
    localidad: "EMPALME SAN CARLOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19770,
    localidad: "EL ZARZO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19771,
    localidad: "EL ZAPALLAR",
    cp: 5353,
    provincia_id: 3,
  },
  {
    id: 19772,
    localidad: "EL ZAPALLAR",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19773,
    localidad: "EL ZAPALLAR",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19774,
    localidad: "EL VIZCACHERAL",
    cp: 5354,
    provincia_id: 3,
  },
  {
    id: 19775,
    localidad: "EL VALLECITO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19776,
    localidad: "EL VALLECITO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19777,
    localidad: "EL VALLECITO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19778,
    localidad: "EL VALLE",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19779,
    localidad: "EL TRAPICHE",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19780,
    localidad: "EL TOTORAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19781,
    localidad: "EL TOTORAL",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19782,
    localidad: "EL TOLAR",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19783,
    localidad: "EL TIO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19784,
    localidad: "EL TAMBILLO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19785,
    localidad: "EL TALA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19786,
    localidad: "EL TALA",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19787,
    localidad: "EL TABIQUE",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19788,
    localidad: "EL SUNCHO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19789,
    localidad: "EL SUNCHO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19790,
    localidad: "EL SIMBOL",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19791,
    localidad: "EL SAUCECITO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19792,
    localidad: "EL SAUCE IPIZCA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19793,
    localidad: "EL SAUCE",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19794,
    localidad: "EL SALTO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19795,
    localidad: "EL SALTITO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19796,
    localidad: "EL SALADILLO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19797,
    localidad: "EL ROSARIO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19798,
    localidad: "EL ROSARIO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19799,
    localidad: "EL ROSARIO",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19800,
    localidad: "EL ROSARIO",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19801,
    localidad: "EL ROSARIO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19802,
    localidad: "EL RODEO GRANDE",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19803,
    localidad: "EL RODEO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19804,
    localidad: "EL RODEITO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19805,
    localidad: "EL RODEITO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19806,
    localidad: "EL RETIRO",
    cp: 5325,
    provincia_id: 3,
  },
  {
    id: 19807,
    localidad: "EL RETIRO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19808,
    localidad: "EL RETIRO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19809,
    localidad: "EL RETIRO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19810,
    localidad: "EL RECREO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19811,
    localidad: "EL RECREO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19812,
    localidad: "EL REALITO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19813,
    localidad: "EL RAMBLON",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19814,
    localidad: "EL QUIMILO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19815,
    localidad: "EL QUICHO",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19816,
    localidad: "EL QUEBRACHO",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19817,
    localidad: "EL QUEBRACHITO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19818,
    localidad: "EL QUEBRACHAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19819,
    localidad: "EL PUESTO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19820,
    localidad: "EL PUESTO",
    cp: 5341,
    provincia_id: 3,
  },
  {
    id: 19821,
    localidad: "EL PUESTITO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19822,
    localidad: "EL PUEBLITO",
    cp: 5331,
    provincia_id: 3,
  },
  {
    id: 19823,
    localidad: "EL PUCARA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19824,
    localidad: "EL POZO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19825,
    localidad: "EL POTRERO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19826,
    localidad: "EL POTRERO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19827,
    localidad: "EL POTRERILLO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19828,
    localidad: "EL POTRER D LS CBA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19829,
    localidad: "EL PORTEZUELO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19830,
    localidad: "EL POLEAR",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19831,
    localidad: "EL POLEAR",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19832,
    localidad: "EL PIE D L CUESTA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19833,
    localidad: "EL PEQON",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19834,
    localidad: "EL PARQUE",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19835,
    localidad: "EL PAJONAL",
    cp: 5315,
    provincia_id: 3,
  },
  {
    id: 19836,
    localidad: "EL NOGAL",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19837,
    localidad: "EL MORENO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19838,
    localidad: "EL MOLLE",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19839,
    localidad: "EL MOLLE",
    cp: 5350,
    provincia_id: 3,
  },
  {
    id: 19840,
    localidad: "EL MOLLAR",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19841,
    localidad: "EL MOLINO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19842,
    localidad: "EL MOLINITO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19843,
    localidad: "EL MOJON",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19844,
    localidad: "EL MILAGRO",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19845,
    localidad: "EL MILAGRO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19846,
    localidad: "EL MILAGRO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19847,
    localidad: "EL MEDIO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19848,
    localidad: "EL MEDANO",
    cp: 5263,
    provincia_id: 3,
  },
  {
    id: 19849,
    localidad: "EL MEDANITO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19850,
    localidad: "EL MEDANITO",
    cp: 5272,
    provincia_id: 3,
  },
  {
    id: 19851,
    localidad: "EL MANZANO",
    cp: 4711,
    provincia_id: 3,
  },
  {
    id: 19852,
    localidad: "EL LINDERO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19853,
    localidad: "EL LINDERO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19854,
    localidad: "EL HUECO",
    cp: 4710,
    provincia_id: 3,
  },
  {
    id: 19855,
    localidad: "EL HORNO",
    cp: 5357,
    provincia_id: 3,
  },
  {
    id: 19856,
    localidad: "EL GARABATO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19857,
    localidad: "EL FUERTE",
    cp: 5351,
    provincia_id: 3,
  },
  {
    id: 19858,
    localidad: "EL ESPINILLO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19859,
    localidad: "EL EJE",
    cp: 4754,
    provincia_id: 3,
  },
  {
    id: 19860,
    localidad: "EL DURAZNO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19861,
    localidad: "EL DURAZNILLO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19862,
    localidad: "EL DICHOSO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19863,
    localidad: "EL DESMONTE",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19864,
    localidad: "EL DESMONTE",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19865,
    localidad: "EL DESMONTE",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19866,
    localidad: "EL CRUCE",
    cp: 4138,
    provincia_id: 3,
  },
  {
    id: 19867,
    localidad: "EL CONTADOR",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19868,
    localidad: "EL CONTADOR",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19869,
    localidad: "EL CONDADO",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19870,
    localidad: "EL COLEGIO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19871,
    localidad: "EL CIFLON",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19872,
    localidad: "EL CIFLON",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19873,
    localidad: "EL CIENEGO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19874,
    localidad: "EL CHORRO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19875,
    localidad: "EL CHORRO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19876,
    localidad: "EL CHAQARAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19877,
    localidad: "EL CHAQARAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19878,
    localidad: "EL CHAÑAR",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19879,
    localidad: "EL CHAMICO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19880,
    localidad: "EL CHAMICO",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 19881,
    localidad: "EL CEVILARCITO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19882,
    localidad: "EL CEVIL",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19883,
    localidad: "EL CERRITO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19884,
    localidad: "EL CERRITO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19885,
    localidad: "EL CERCADO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19886,
    localidad: "EL CERCADO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19887,
    localidad: "EL CENTENARIO",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19888,
    localidad: "EL CARRIZAL",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19889,
    localidad: "EL CARRIZAL",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19890,
    localidad: "EL CARRIZAL",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19891,
    localidad: "EL CARRIZAL",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19892,
    localidad: "EL CARMEN",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19893,
    localidad: "EL CAMPILLO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19894,
    localidad: "EL CALCHAQUI",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19895,
    localidad: "EL CAJON",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19896,
    localidad: "EL CAJON",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19897,
    localidad: "EL CACHO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19898,
    localidad: "EL CACHIYUYO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19899,
    localidad: "EL BOLSON",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19900,
    localidad: "EL BISCOTE",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19901,
    localidad: "EL BELLO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19902,
    localidad: "EL BASTIDOR",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19903,
    localidad: "EL BARRIALITO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19904,
    localidad: "EL BARRIALITO",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 19905,
    localidad: "EL BARRIAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19906,
    localidad: "EL BARREAL",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 19907,
    localidad: "EL BARREAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19908,
    localidad: "EL BARREAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19909,
    localidad: "EL BAQADO",
    cp: 4732,
    provincia_id: 3,
  },
  {
    id: 19910,
    localidad: "EL BAQADO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19911,
    localidad: "EL BAÑADO",
    cp: 4732,
    provincia_id: 3,
  },
  {
    id: 19912,
    localidad: "EL BALDE",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19913,
    localidad: "EL AYBAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19914,
    localidad: "EL ATOYAL",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19915,
    localidad: "EL ARROYO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19916,
    localidad: "EL ARENAL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19917,
    localidad: "EL ARBOLITO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19918,
    localidad: "EL ARBOLITO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19919,
    localidad: "EL ARBOLAR",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 19920,
    localidad: "EL ARBOL SOLO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19921,
    localidad: "EL ALTO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 19922,
    localidad: "EL ALTO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19923,
    localidad: "EL ALTILLO",
    cp: 5355,
    provincia_id: 3,
  },
  {
    id: 19924,
    localidad: "EL ALAMITO",
    cp: 4743,
    provincia_id: 3,
  },
  {
    id: 19925,
    localidad: "EL ABRA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19926,
    localidad: "E LA INVERNADA",
    cp: 4735,
    provincia_id: 3,
  },
  {
    id: 19927,
    localidad: "DURAZNO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19928,
    localidad: "DURAZNILLO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19929,
    localidad: "DOS TRONCOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19930,
    localidad: "DOS POCITOS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19931,
    localidad: "DIVISADERO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19932,
    localidad: "DISTRITO ESPINILLO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19933,
    localidad: "CUMBRE DEL LAUDO",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 19934,
    localidad: "CULAMPAJA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19935,
    localidad: "CUEVA BLANCA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19936,
    localidad: "COTAGUA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19937,
    localidad: "COSTA DE REYES",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19938,
    localidad: "CORTADERAS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19939,
    localidad: "CORTADERAS",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 19940,
    localidad: "CORTADERA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19941,
    localidad: "CORTADERA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19942,
    localidad: "CORRALITOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19943,
    localidad: "CORRALITO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19944,
    localidad: "CORRALITO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19945,
    localidad: "CORRALITA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19946,
    localidad: "CORRAL VIEJO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19947,
    localidad: "CORRAL VIEJO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19948,
    localidad: "CORRAL QUEMADO",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19949,
    localidad: "CORRAL DE PIEDRA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19950,
    localidad: "CORRAL DE PIEDRA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19951,
    localidad: "CORDOBITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19952,
    localidad: "COPACABANA",
    cp: 5333,
    provincia_id: 3,
  },
  {
    id: 19953,
    localidad: "CONETA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19954,
    localidad: "CONETA",
    cp: 4724,
    provincia_id: 3,
  },
  {
    id: 19955,
    localidad: "CONDOR HUASI",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19956,
    localidad: "CONDOR H DE BELEN",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 19957,
    localidad: "CONCEPCION",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19958,
    localidad: "CONCEPCION",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19959,
    localidad: "CONCEPCION",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19960,
    localidad: "COMECHINGONES",
    cp: 5153,
    provincia_id: 3,
  },
  {
    id: 19961,
    localidad: "COLPES",
    cp: 4711,
    provincia_id: 3,
  },
  {
    id: 19962,
    localidad: "COLPES",
    cp: 5319,
    provincia_id: 3,
  },
  {
    id: 19963,
    localidad: "COLONIA NUEVA CONETA",
    cp: 4724,
    provincia_id: 3,
  },
  {
    id: 19964,
    localidad: "COLONIA DEL VALLE",
    cp: 4725,
    provincia_id: 3,
  },
  {
    id: 19965,
    localidad: "COLONIA DEL VALLE",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 19966,
    localidad: "COLLAGASTA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19967,
    localidad: "CNO A P DEL AGUA",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 19968,
    localidad: "CIENAGUITA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19969,
    localidad: "CIENAGA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19970,
    localidad: "CIENAGA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19971,
    localidad: "CHUMBICHA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19972,
    localidad: "CHUCOLAY",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19973,
    localidad: "CHUCHUCARUANA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19974,
    localidad: "CHOYA",
    cp: 4741,
    provincia_id: 3,
  },
  {
    id: 19975,
    localidad: "CHIQUERITO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 19976,
    localidad: "CHILLIMO",
    cp: 4237,
    provincia_id: 3,
  },
  {
    id: 19977,
    localidad: "CHILCA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19978,
    localidad: "CHIFLON",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19979,
    localidad: "CHICHAGASTA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19980,
    localidad: "CHAVERO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19981,
    localidad: "CHAVARRIA",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19982,
    localidad: "CHARLONE",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 19983,
    localidad: "CHAQUIAGO",
    cp: 4742,
    provincia_id: 3,
  },
  {
    id: 19984,
    localidad: "CHAQARYACO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 19985,
    localidad: "CHAQARITOS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19986,
    localidad: "CHAQARITOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19987,
    localidad: "CHAQARCITO",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 19988,
    localidad: "CHAÑARITOS",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19989,
    localidad: "CHANERO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19990,
    localidad: "CHAMORRO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 19991,
    localidad: "CHACRITAS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 19992,
    localidad: "CHACABUCO",
    cp: 4700,
    provincia_id: 3,
  },
  {
    id: 19993,
    localidad: "CERVIQO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 19994,
    localidad: "CERRO NEGRO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 19995,
    localidad: "CERRO COLORADO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19996,
    localidad: "CERRILLOS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 19997,
    localidad: "CERRILLOS",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 19998,
    localidad: "CERRILLADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 19999,
    localidad: "CERDAS",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20000,
    localidad: "CASTAQAR",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20001,
    localidad: "CASA VIEJAS",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 20002,
    localidad: "CASA VIEJA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20003,
    localidad: "CASA GRANDE",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20004,
    localidad: "CASA GRANDE",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20005,
    localidad: "CASA DE PIEDRA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20006,
    localidad: "CASA DE PIEDRA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20007,
    localidad: "CASA DE LA CUMBRE",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20008,
    localidad: "CASA DE ALTO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20009,
    localidad: "CASA ARMADA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20010,
    localidad: "CARRIZAL DE LA COS",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20011,
    localidad: "CARRIZAL DE ABAJO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20012,
    localidad: "CARRIZAL",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20013,
    localidad: "CARRIZAL",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20014,
    localidad: "CARPINCHANGO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20015,
    localidad: "CARIDAD",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20016,
    localidad: "CARAPUNCO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20017,
    localidad: "CAQADA LARGA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20018,
    localidad: "CAQADA DE VILAN",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20019,
    localidad: "CAQADA DE PAEZ",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20020,
    localidad: "CAQADA DE IPIZCA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20021,
    localidad: "CAQADA DE CUEVAS",
    cp: 5101,
    provincia_id: 3,
  },
  {
    id: 20022,
    localidad: "CAQADA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20023,
    localidad: "CAPIHUAS",
    cp: 5327,
    provincia_id: 3,
  },
  {
    id: 20024,
    localidad: "CAPAYAN",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 20025,
    localidad: "CAPAYAN",
    cp: 4726,
    provincia_id: 3,
  },
  {
    id: 20026,
    localidad: "CANTERA ROTA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20027,
    localidad: "CANDELARIA",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 20028,
    localidad: "CANDELARIA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20029,
    localidad: "CANDELARIA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20030,
    localidad: "CAMPO BLANCO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20031,
    localidad: "CAMPO BELLO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20032,
    localidad: "CAMPITOS",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20033,
    localidad: "CALERA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20034,
    localidad: "CALACIO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20035,
    localidad: "CACHUAN",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20036,
    localidad: "CACHIJAN",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20037,
    localidad: "CACHI",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20038,
    localidad: "CABRERA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20039,
    localidad: "CABANA",
    cp: 4722,
    provincia_id: 3,
  },
  {
    id: 20040,
    localidad: "CABALLA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20041,
    localidad: "C NUEVA CONETA",
    cp: 4724,
    provincia_id: 3,
  },
  {
    id: 20042,
    localidad: "C DEL CANTARO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20043,
    localidad: "BUEY MUERTO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20044,
    localidad: "BUENA VISTA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20045,
    localidad: "BUENA VISTA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 20046,
    localidad: "BUEN RETIRO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20047,
    localidad: "BREA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20048,
    localidad: "BQADO D DIVISADERO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20049,
    localidad: "BISCOTAL",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 20050,
    localidad: "BELLA VISTA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 20051,
    localidad: "BELLA ESTANCIA",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20052,
    localidad: "BELEN",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20053,
    localidad: "BEBIDA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 20054,
    localidad: "BAVIANO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20055,
    localidad: "BAVIANO",
    cp: 5265,
    provincia_id: 3,
  },
  {
    id: 20056,
    localidad: "BASTIDOR",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 20057,
    localidad: "BARRO NEGRO",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 20058,
    localidad: "BARRANQUITAS",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 20059,
    localidad: "BARRANCA LARGA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20060,
    localidad: "BAQOS TERMALES",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20061,
    localidad: "BAQADO DE OVANTA",
    cp: 4721,
    provincia_id: 3,
  },
  {
    id: 20062,
    localidad: "BAÑADO DE OVANTA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20063,
    localidad: "BAÑADO DE OVANTA",
    cp: 4721,
    provincia_id: 3,
  },
  {
    id: 20064,
    localidad: "BANDA DE LUCERO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20065,
    localidad: "BANDA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20066,
    localidad: "BALSA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 20067,
    localidad: "BALDE POZO",
    cp: 4237,
    provincia_id: 3,
  },
  {
    id: 20068,
    localidad: "BALDE NUEVO",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 20069,
    localidad: "BALDE LA PUNTA",
    cp: 4728,
    provincia_id: 3,
  },
  {
    id: 20070,
    localidad: "BALDE DE ESCUDERO",
    cp: 5715,
    provincia_id: 3,
  },
  {
    id: 20071,
    localidad: "BALCOSNA DE AFUERA",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 20072,
    localidad: "BALCOSNA DE AFUERA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 20073,
    localidad: "BALCOSNA",
    cp: 4719,
    provincia_id: 3,
  },
  {
    id: 20074,
    localidad: "BALCOSNA",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 20075,
    localidad: "BAJO LINDO",
    cp: 5270,
    provincia_id: 3,
  },
  {
    id: 20076,
    localidad: "ASERR EL PILCIO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20077,
    localidad: "ASAMPAY",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20078,
    localidad: "ARIMA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20079,
    localidad: "APOCANGO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20080,
    localidad: "ANTOFAGASTA DE LA SIERRA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 20081,
    localidad: "ANTO. DE LA SIERRA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 20082,
    localidad: "ANTAPOCA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 20083,
    localidad: "ANQUINCILA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20084,
    localidad: "ANJULI",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 20085,
    localidad: "ANILLACO",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20086,
    localidad: "ANGOSTURA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20087,
    localidad: "ANGELINA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20088,
    localidad: "ANDOLUCAS",
    cp: 5329,
    provincia_id: 3,
  },
  {
    id: 20089,
    localidad: "ANDALUCIA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20090,
    localidad: "ANDALHUALA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20091,
    localidad: "ANDALGALA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20092,
    localidad: "ANCHOCA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20093,
    localidad: "ANCHILLOS",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 20094,
    localidad: "ANCASTILLO",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 20095,
    localidad: "ANCASTI",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20096,
    localidad: "AMUSCHINA",
    cp: 5329,
    provincia_id: 3,
  },
  {
    id: 20097,
    localidad: "AMPUJACO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20098,
    localidad: "AMPOLLA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20099,
    localidad: "AMPAJANGO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20100,
    localidad: "AMBATO",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 20101,
    localidad: "AMANCALA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20102,
    localidad: "AMANAO",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20103,
    localidad: "AMANA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20104,
    localidad: "AMADORES",
    cp: 4718,
    provincia_id: 3,
  },
  {
    id: 20105,
    localidad: "ALUMBRERA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20106,
    localidad: "ALTO NEGRO",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20107,
    localidad: "ALTO EL BOLSON",
    cp: 4751,
    provincia_id: 3,
  },
  {
    id: 20108,
    localidad: "ALTO DEL ROSARIO",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20109,
    localidad: "ALTO DE LA JUNTA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20110,
    localidad: "ALTO BELLO",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20111,
    localidad: "ALTA GRACIA",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20112,
    localidad: "ALMIGAUCHO",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20113,
    localidad: "ALIJILAN",
    cp: 4723,
    provincia_id: 3,
  },
  {
    id: 20114,
    localidad: "ALGARROBOS GRANDES",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20115,
    localidad: "ALGARROBAL",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20116,
    localidad: "ALBIGASTA",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 20117,
    localidad: "ALBIGASTA",
    cp: 4231,
    provincia_id: 3,
  },
  {
    id: 20118,
    localidad: "AGUAS CALIENTES",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20119,
    localidad: "AGUADITA",
    cp: 4705,
    provincia_id: 3,
  },
  {
    id: 20120,
    localidad: "AGUADITA",
    cp: 5719,
    provincia_id: 3,
  },
  {
    id: 20121,
    localidad: "AGUADA",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20122,
    localidad: "AGUA VERDE",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20123,
    localidad: "AGUA VERDE",
    cp: 4715,
    provincia_id: 3,
  },
  {
    id: 20124,
    localidad: "AGUA SALADA",
    cp: 4141,
    provincia_id: 3,
  },
  {
    id: 20125,
    localidad: "AGUA SALADA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20126,
    localidad: "AGUA LOS MATOS",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20127,
    localidad: "AGUA GRANDE",
    cp: 5340,
    provincia_id: 3,
  },
  {
    id: 20128,
    localidad: "AGUA ESCONDIDA",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20129,
    localidad: "AGUA DEL SIMBOL",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20130,
    localidad: "AGUA DEL CAMPO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20131,
    localidad: "AGUA DE LAS PALOMAS",
    cp: 4741,
    provincia_id: 3,
  },
  {
    id: 20132,
    localidad: "AGUA DE DIONISIO",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20133,
    localidad: "AGUA D LS PALOMAS",
    cp: 4741,
    provincia_id: 3,
  },
  {
    id: 20134,
    localidad: "AGUA COLORADA",
    cp: 4707,
    provincia_id: 3,
  },
  {
    id: 20135,
    localidad: "AGUA COLORADA",
    cp: 4750,
    provincia_id: 3,
  },
  {
    id: 20136,
    localidad: "ACOSTILLA",
    cp: 4701,
    provincia_id: 3,
  },
  {
    id: 20137,
    localidad: "ACONQUIJA",
    cp: 4740,
    provincia_id: 3,
  },
  {
    id: 20138,
    localidad: "ACHERAL",
    cp: 5260,
    provincia_id: 3,
  },
  {
    id: 20139,
    localidad: "ACHALCO",
    cp: 4235,
    provincia_id: 3,
  },
  {
    id: 20140,
    localidad: "A A PTA DE BALASTO",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20141,
    localidad: "A A LA HOYADA",
    cp: 4139,
    provincia_id: 3,
  },
  {
    id: 20142,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1023,
    provincia_id: 2,
  },
  {
    id: 20143,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1058,
    provincia_id: 2,
  },
  {
    id: 20144,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1252,
    provincia_id: 2,
  },
  {
    id: 20145,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1022,
    provincia_id: 2,
  },
  {
    id: 20146,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1064,
    provincia_id: 2,
  },
  {
    id: 20147,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1063,
    provincia_id: 2,
  },
  {
    id: 20148,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1062,
    provincia_id: 2,
  },
  {
    id: 20149,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1061,
    provincia_id: 2,
  },
  {
    id: 20150,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1060,
    provincia_id: 2,
  },
  {
    id: 20151,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1059,
    provincia_id: 2,
  },
  {
    id: 20152,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1050,
    provincia_id: 2,
  },
  {
    id: 20153,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1025,
    provincia_id: 2,
  },
  {
    id: 20154,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1026,
    provincia_id: 2,
  },
  {
    id: 20155,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1027,
    provincia_id: 2,
  },
  {
    id: 20156,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1089,
    provincia_id: 2,
  },
  {
    id: 20157,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1090,
    provincia_id: 2,
  },
  {
    id: 20158,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1091,
    provincia_id: 2,
  },
  {
    id: 20159,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1049,
    provincia_id: 2,
  },
  {
    id: 20160,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1004,
    provincia_id: 2,
  },
  {
    id: 20161,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1057,
    provincia_id: 2,
  },
  {
    id: 20162,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1051,
    provincia_id: 2,
  },
  {
    id: 20163,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1052,
    provincia_id: 2,
  },
  {
    id: 20164,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1053,
    provincia_id: 2,
  },
  {
    id: 20165,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1054,
    provincia_id: 2,
  },
  {
    id: 20166,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1055,
    provincia_id: 2,
  },
  {
    id: 20167,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1056,
    provincia_id: 2,
  },
  {
    id: 20168,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1024,
    provincia_id: 2,
  },
  {
    id: 20169,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1350,
    provincia_id: 2,
  },
  {
    id: 20170,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1407,
    provincia_id: 2,
  },
  {
    id: 20171,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1408,
    provincia_id: 2,
  },
  {
    id: 20172,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1409,
    provincia_id: 2,
  },
  {
    id: 20173,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1410,
    provincia_id: 2,
  },
  {
    id: 20174,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1347,
    provincia_id: 2,
  },
  {
    id: 20175,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1047,
    provincia_id: 2,
  },
  {
    id: 20176,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1068,
    provincia_id: 2,
  },
  {
    id: 20177,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1348,
    provincia_id: 2,
  },
  {
    id: 20178,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1256,
    provincia_id: 2,
  },
  {
    id: 20179,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1349,
    provincia_id: 2,
  },
  {
    id: 20180,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1255,
    provincia_id: 2,
  },
  {
    id: 20181,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1361,
    provincia_id: 2,
  },
  {
    id: 20182,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1253,
    provincia_id: 2,
  },
  {
    id: 20183,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1339,
    provincia_id: 2,
  },
  {
    id: 20184,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1351,
    provincia_id: 2,
  },
  {
    id: 20185,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1352,
    provincia_id: 2,
  },
  {
    id: 20186,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1353,
    provincia_id: 2,
  },
  {
    id: 20187,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1354,
    provincia_id: 2,
  },
  {
    id: 20188,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1355,
    provincia_id: 2,
  },
  {
    id: 20189,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1356,
    provincia_id: 2,
  },
  {
    id: 20190,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1357,
    provincia_id: 2,
  },
  {
    id: 20191,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1358,
    provincia_id: 2,
  },
  {
    id: 20192,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1359,
    provincia_id: 2,
  },
  {
    id: 20193,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1278,
    provincia_id: 2,
  },
  {
    id: 20194,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1254,
    provincia_id: 2,
  },
  {
    id: 20195,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1393,
    provincia_id: 2,
  },
  {
    id: 20196,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1420,
    provincia_id: 2,
  },
  {
    id: 20197,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1279,
    provincia_id: 2,
  },
  {
    id: 20198,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1237,
    provincia_id: 2,
  },
  {
    id: 20199,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1217,
    provincia_id: 2,
  },
  {
    id: 20200,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1216,
    provincia_id: 2,
  },
  {
    id: 20201,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1238,
    provincia_id: 2,
  },
  {
    id: 20202,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1239,
    provincia_id: 2,
  },
  {
    id: 20203,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1258,
    provincia_id: 2,
  },
  {
    id: 20204,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1283,
    provincia_id: 2,
  },
  {
    id: 20205,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1284,
    provincia_id: 2,
  },
  {
    id: 20206,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1391,
    provincia_id: 2,
  },
  {
    id: 20207,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1341,
    provincia_id: 2,
  },
  {
    id: 20208,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1392,
    provincia_id: 2,
  },
  {
    id: 20209,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1340,
    provincia_id: 2,
  },
  {
    id: 20210,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1394,
    provincia_id: 2,
  },
  {
    id: 20211,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1395,
    provincia_id: 2,
  },
  {
    id: 20212,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1396,
    provincia_id: 2,
  },
  {
    id: 20213,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1397,
    provincia_id: 2,
  },
  {
    id: 20214,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1398,
    provincia_id: 2,
  },
  {
    id: 20215,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1399,
    provincia_id: 2,
  },
  {
    id: 20216,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1400,
    provincia_id: 2,
  },
  {
    id: 20217,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1401,
    provincia_id: 2,
  },
  {
    id: 20218,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1338,
    provincia_id: 2,
  },
  {
    id: 20219,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1362,
    provincia_id: 2,
  },
  {
    id: 20220,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1257,
    provincia_id: 2,
  },
  {
    id: 20221,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1599,
    provincia_id: 2,
  },
  {
    id: 20222,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1360,
    provincia_id: 2,
  },
  {
    id: 20223,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1327,
    provincia_id: 2,
  },
  {
    id: 20224,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1430,
    provincia_id: 2,
  },
  {
    id: 20225,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1431,
    provincia_id: 2,
  },
  {
    id: 20226,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1432,
    provincia_id: 2,
  },
  {
    id: 20227,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1433,
    provincia_id: 2,
  },
  {
    id: 20228,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1434,
    provincia_id: 2,
  },
  {
    id: 20229,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1435,
    provincia_id: 2,
  },
  {
    id: 20230,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1436,
    provincia_id: 2,
  },
  {
    id: 20231,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1437,
    provincia_id: 2,
  },
  {
    id: 20232,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1439,
    provincia_id: 2,
  },
  {
    id: 20233,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1325,
    provincia_id: 2,
  },
  {
    id: 20234,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1453,
    provincia_id: 2,
  },
  {
    id: 20235,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1324,
    provincia_id: 2,
  },
  {
    id: 20236,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1402,
    provincia_id: 2,
  },
  {
    id: 20237,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1405,
    provincia_id: 2,
  },
  {
    id: 20238,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1406,
    provincia_id: 2,
  },
  {
    id: 20239,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1411,
    provincia_id: 2,
  },
  {
    id: 20240,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1412,
    provincia_id: 2,
  },
  {
    id: 20241,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1413,
    provincia_id: 2,
  },
  {
    id: 20242,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1414,
    provincia_id: 2,
  },
  {
    id: 20243,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1416,
    provincia_id: 2,
  },
  {
    id: 20244,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1417,
    provincia_id: 2,
  },
  {
    id: 20245,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1419,
    provincia_id: 2,
  },
  {
    id: 20246,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1440,
    provincia_id: 2,
  },
  {
    id: 20247,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1375,
    provincia_id: 2,
  },
  {
    id: 20248,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1363,
    provincia_id: 2,
  },
  {
    id: 20249,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1364,
    provincia_id: 2,
  },
  {
    id: 20250,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1365,
    provincia_id: 2,
  },
  {
    id: 20251,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1366,
    provincia_id: 2,
  },
  {
    id: 20252,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1367,
    provincia_id: 2,
  },
  {
    id: 20253,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1368,
    provincia_id: 2,
  },
  {
    id: 20254,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1369,
    provincia_id: 2,
  },
  {
    id: 20255,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1306,
    provincia_id: 2,
  },
  {
    id: 20256,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1307,
    provincia_id: 2,
  },
  {
    id: 20257,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1308,
    provincia_id: 2,
  },
  {
    id: 20258,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1326,
    provincia_id: 2,
  },
  {
    id: 20259,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1374,
    provincia_id: 2,
  },
  {
    id: 20260,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1277,
    provincia_id: 2,
  },
  {
    id: 20261,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1376,
    provincia_id: 2,
  },
  {
    id: 20262,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1378,
    provincia_id: 2,
  },
  {
    id: 20263,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1316,
    provincia_id: 2,
  },
  {
    id: 20264,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1317,
    provincia_id: 2,
  },
  {
    id: 20265,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1318,
    provincia_id: 2,
  },
  {
    id: 20266,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1319,
    provincia_id: 2,
  },
  {
    id: 20267,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1320,
    provincia_id: 2,
  },
  {
    id: 20268,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1321,
    provincia_id: 2,
  },
  {
    id: 20269,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1322,
    provincia_id: 2,
  },
  {
    id: 20270,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1323,
    provincia_id: 2,
  },
  {
    id: 20271,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1309,
    provincia_id: 2,
  },
  {
    id: 20272,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1228,
    provincia_id: 2,
  },
  {
    id: 20273,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1218,
    provincia_id: 2,
  },
  {
    id: 20274,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1235,
    provincia_id: 2,
  },
  {
    id: 20275,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1220,
    provincia_id: 2,
  },
  {
    id: 20276,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1328,
    provincia_id: 2,
  },
  {
    id: 20277,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1234,
    provincia_id: 2,
  },
  {
    id: 20278,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1233,
    provincia_id: 2,
  },
  {
    id: 20279,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1232,
    provincia_id: 2,
  },
  {
    id: 20280,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1329,
    provincia_id: 2,
  },
  {
    id: 20281,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1330,
    provincia_id: 2,
  },
  {
    id: 20282,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1231,
    provincia_id: 2,
  },
  {
    id: 20283,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1331,
    provincia_id: 2,
  },
  {
    id: 20284,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1219,
    provincia_id: 2,
  },
  {
    id: 20285,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1229,
    provincia_id: 2,
  },
  {
    id: 20286,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1195,
    provincia_id: 2,
  },
  {
    id: 20287,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1227,
    provincia_id: 2,
  },
  {
    id: 20288,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1226,
    provincia_id: 2,
  },
  {
    id: 20289,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1332,
    provincia_id: 2,
  },
  {
    id: 20290,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1333,
    provincia_id: 2,
  },
  {
    id: 20291,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1225,
    provincia_id: 2,
  },
  {
    id: 20292,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1334,
    provincia_id: 2,
  },
  {
    id: 20293,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1224,
    provincia_id: 2,
  },
  {
    id: 20294,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1335,
    provincia_id: 2,
  },
  {
    id: 20295,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1336,
    provincia_id: 2,
  },
  {
    id: 20296,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1223,
    provincia_id: 2,
  },
  {
    id: 20297,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1230,
    provincia_id: 2,
  },
  {
    id: 20298,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1240,
    provincia_id: 2,
  },
  {
    id: 20299,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1202,
    provincia_id: 2,
  },
  {
    id: 20300,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1203,
    provincia_id: 2,
  },
  {
    id: 20301,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1204,
    provincia_id: 2,
  },
  {
    id: 20302,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1205,
    provincia_id: 2,
  },
  {
    id: 20303,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1206,
    provincia_id: 2,
  },
  {
    id: 20304,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1201,
    provincia_id: 2,
  },
  {
    id: 20305,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1207,
    provincia_id: 2,
  },
  {
    id: 20306,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1208,
    provincia_id: 2,
  },
  {
    id: 20307,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1209,
    provincia_id: 2,
  },
  {
    id: 20308,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1242,
    provincia_id: 2,
  },
  {
    id: 20309,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1236,
    provincia_id: 2,
  },
  {
    id: 20310,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1241,
    provincia_id: 2,
  },
  {
    id: 20311,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1281,
    provincia_id: 2,
  },
  {
    id: 20312,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1211,
    provincia_id: 2,
  },
  {
    id: 20313,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1197,
    provincia_id: 2,
  },
  {
    id: 20314,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1196,
    provincia_id: 2,
  },
  {
    id: 20315,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1212,
    provincia_id: 2,
  },
  {
    id: 20316,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1213,
    provincia_id: 2,
  },
  {
    id: 20317,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1214,
    provincia_id: 2,
  },
  {
    id: 20318,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1215,
    provincia_id: 2,
  },
  {
    id: 20319,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1174,
    provincia_id: 2,
  },
  {
    id: 20320,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1175,
    provincia_id: 2,
  },
  {
    id: 20321,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1176,
    provincia_id: 2,
  },
  {
    id: 20322,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1210,
    provincia_id: 2,
  },
  {
    id: 20323,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1270,
    provincia_id: 2,
  },
  {
    id: 20324,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1337,
    provincia_id: 2,
  },
  {
    id: 20325,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1260,
    provincia_id: 2,
  },
  {
    id: 20326,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1261,
    provincia_id: 2,
  },
  {
    id: 20327,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1310,
    provincia_id: 2,
  },
  {
    id: 20328,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1311,
    provincia_id: 2,
  },
  {
    id: 20329,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1312,
    provincia_id: 2,
  },
  {
    id: 20330,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1313,
    provincia_id: 2,
  },
  {
    id: 20331,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1314,
    provincia_id: 2,
  },
  {
    id: 20332,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1265,
    provincia_id: 2,
  },
  {
    id: 20333,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1266,
    provincia_id: 2,
  },
  {
    id: 20334,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1267,
    provincia_id: 2,
  },
  {
    id: 20335,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1305,
    provincia_id: 2,
  },
  {
    id: 20336,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1269,
    provincia_id: 2,
  },
  {
    id: 20337,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1304,
    provincia_id: 2,
  },
  {
    id: 20338,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1222,
    provincia_id: 2,
  },
  {
    id: 20339,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1271,
    provincia_id: 2,
  },
  {
    id: 20340,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1272,
    provincia_id: 2,
  },
  {
    id: 20341,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1273,
    provincia_id: 2,
  },
  {
    id: 20342,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1274,
    provincia_id: 2,
  },
  {
    id: 20343,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1264,
    provincia_id: 2,
  },
  {
    id: 20344,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1263,
    provincia_id: 2,
  },
  {
    id: 20345,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1262,
    provincia_id: 2,
  },
  {
    id: 20346,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1275,
    provincia_id: 2,
  },
  {
    id: 20347,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1276,
    provincia_id: 2,
  },
  {
    id: 20348,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1268,
    provincia_id: 2,
  },
  {
    id: 20349,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1291,
    provincia_id: 2,
  },
  {
    id: 20350,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1315,
    provincia_id: 2,
  },
  {
    id: 20351,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1282,
    provincia_id: 2,
  },
  {
    id: 20352,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1342,
    provincia_id: 2,
  },
  {
    id: 20353,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1343,
    provincia_id: 2,
  },
  {
    id: 20354,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1344,
    provincia_id: 2,
  },
  {
    id: 20355,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1345,
    provincia_id: 2,
  },
  {
    id: 20356,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1346,
    provincia_id: 2,
  },
  {
    id: 20357,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1286,
    provincia_id: 2,
  },
  {
    id: 20358,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1287,
    provincia_id: 2,
  },
  {
    id: 20359,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1288,
    provincia_id: 2,
  },
  {
    id: 20360,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1259,
    provincia_id: 2,
  },
  {
    id: 20361,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1290,
    provincia_id: 2,
  },
  {
    id: 20362,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1280,
    provincia_id: 2,
  },
  {
    id: 20363,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1292,
    provincia_id: 2,
  },
  {
    id: 20364,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1293,
    provincia_id: 2,
  },
  {
    id: 20365,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1294,
    provincia_id: 2,
  },
  {
    id: 20366,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1295,
    provincia_id: 2,
  },
  {
    id: 20367,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1296,
    provincia_id: 2,
  },
  {
    id: 20368,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1298,
    provincia_id: 2,
  },
  {
    id: 20369,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1300,
    provincia_id: 2,
  },
  {
    id: 20370,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1301,
    provincia_id: 2,
  },
  {
    id: 20371,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1302,
    provincia_id: 2,
  },
  {
    id: 20372,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1303,
    provincia_id: 2,
  },
  {
    id: 20373,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1289,
    provincia_id: 2,
  },
  {
    id: 20374,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1100,
    provincia_id: 2,
  },
  {
    id: 20375,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1073,
    provincia_id: 2,
  },
  {
    id: 20376,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1072,
    provincia_id: 2,
  },
  {
    id: 20377,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1071,
    provincia_id: 2,
  },
  {
    id: 20378,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1112,
    provincia_id: 2,
  },
  {
    id: 20379,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1111,
    provincia_id: 2,
  },
  {
    id: 20380,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1110,
    provincia_id: 2,
  },
  {
    id: 20381,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1067,
    provincia_id: 2,
  },
  {
    id: 20382,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1066,
    provincia_id: 2,
  },
  {
    id: 20383,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1065,
    provincia_id: 2,
  },
  {
    id: 20384,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1106,
    provincia_id: 2,
  },
  {
    id: 20385,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1105,
    provincia_id: 2,
  },
  {
    id: 20386,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1104,
    provincia_id: 2,
  },
  {
    id: 20387,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1103,
    provincia_id: 2,
  },
  {
    id: 20388,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1193,
    provincia_id: 2,
  },
  {
    id: 20389,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1101,
    provincia_id: 2,
  },
  {
    id: 20390,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1076,
    provincia_id: 2,
  },
  {
    id: 20391,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1099,
    provincia_id: 2,
  },
  {
    id: 20392,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1098,
    provincia_id: 2,
  },
  {
    id: 20393,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1097,
    provincia_id: 2,
  },
  {
    id: 20394,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1096,
    provincia_id: 2,
  },
  {
    id: 20395,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1095,
    provincia_id: 2,
  },
  {
    id: 20396,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1094,
    provincia_id: 2,
  },
  {
    id: 20397,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1093,
    provincia_id: 2,
  },
  {
    id: 20398,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1092,
    provincia_id: 2,
  },
  {
    id: 20399,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1134,
    provincia_id: 2,
  },
  {
    id: 20400,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1133,
    provincia_id: 2,
  },
  {
    id: 20401,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1132,
    provincia_id: 2,
  },
  {
    id: 20402,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1088,
    provincia_id: 2,
  },
  {
    id: 20403,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1102,
    provincia_id: 2,
  },
  {
    id: 20404,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1045,
    provincia_id: 2,
  },
  {
    id: 20405,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1192,
    provincia_id: 2,
  },
  {
    id: 20406,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1191,
    provincia_id: 2,
  },
  {
    id: 20407,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1190,
    provincia_id: 2,
  },
  {
    id: 20408,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1189,
    provincia_id: 2,
  },
  {
    id: 20409,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1188,
    provincia_id: 2,
  },
  {
    id: 20410,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1187,
    provincia_id: 2,
  },
  {
    id: 20411,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1186,
    provincia_id: 2,
  },
  {
    id: 20412,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1185,
    provincia_id: 2,
  },
  {
    id: 20413,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1184,
    provincia_id: 2,
  },
  {
    id: 20414,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1183,
    provincia_id: 2,
  },
  {
    id: 20415,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1421,
    provincia_id: 2,
  },
  {
    id: 20416,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1181,
    provincia_id: 2,
  },
  {
    id: 20417,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1377,
    provincia_id: 2,
  },
  {
    id: 20418,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1074,
    provincia_id: 2,
  },
  {
    id: 20419,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1221,
    provincia_id: 2,
  },
  {
    id: 20420,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1075,
    provincia_id: 2,
  },
  {
    id: 20421,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1044,
    provincia_id: 2,
  },
  {
    id: 20422,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1043,
    provincia_id: 2,
  },
  {
    id: 20423,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1085,
    provincia_id: 2,
  },
  {
    id: 20424,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1084,
    provincia_id: 2,
  },
  {
    id: 20425,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1083,
    provincia_id: 2,
  },
  {
    id: 20426,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1082,
    provincia_id: 2,
  },
  {
    id: 20427,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1081,
    provincia_id: 2,
  },
  {
    id: 20428,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1080,
    provincia_id: 2,
  },
  {
    id: 20429,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1079,
    provincia_id: 2,
  },
  {
    id: 20430,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1078,
    provincia_id: 2,
  },
  {
    id: 20431,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1077,
    provincia_id: 2,
  },
  {
    id: 20432,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1128,
    provincia_id: 2,
  },
  {
    id: 20433,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1180,
    provincia_id: 2,
  },
  {
    id: 20434,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1038,
    provincia_id: 2,
  },
  {
    id: 20435,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1010,
    provincia_id: 2,
  },
  {
    id: 20436,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1009,
    provincia_id: 2,
  },
  {
    id: 20437,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1008,
    provincia_id: 2,
  },
  {
    id: 20438,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1007,
    provincia_id: 2,
  },
  {
    id: 20439,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1006,
    provincia_id: 2,
  },
  {
    id: 20440,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1005,
    provincia_id: 2,
  },
  {
    id: 20441,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1048,
    provincia_id: 2,
  },
  {
    id: 20442,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1046,
    provincia_id: 2,
  },
  {
    id: 20443,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1003,
    provincia_id: 2,
  },
  {
    id: 20444,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1002,
    provincia_id: 2,
  },
  {
    id: 20445,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1001,
    provincia_id: 2,
  },
  {
    id: 20446,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1042,
    provincia_id: 2,
  },
  {
    id: 20447,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1041,
    provincia_id: 2,
  },
  {
    id: 20448,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1087,
    provincia_id: 2,
  },
  {
    id: 20449,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1039,
    provincia_id: 2,
  },
  {
    id: 20450,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1013,
    provincia_id: 2,
  },
  {
    id: 20451,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1037,
    provincia_id: 2,
  },
  {
    id: 20452,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1036,
    provincia_id: 2,
  },
  {
    id: 20453,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1035,
    provincia_id: 2,
  },
  {
    id: 20454,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1034,
    provincia_id: 2,
  },
  {
    id: 20455,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1033,
    provincia_id: 2,
  },
  {
    id: 20456,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1032,
    provincia_id: 2,
  },
  {
    id: 20457,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1031,
    provincia_id: 2,
  },
  {
    id: 20458,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1030,
    provincia_id: 2,
  },
  {
    id: 20459,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1029,
    provincia_id: 2,
  },
  {
    id: 20460,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1028,
    provincia_id: 2,
  },
  {
    id: 20461,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1070,
    provincia_id: 2,
  },
  {
    id: 20462,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1069,
    provincia_id: 2,
  },
  {
    id: 20463,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1040,
    provincia_id: 2,
  },
  {
    id: 20464,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1113,
    provincia_id: 2,
  },
  {
    id: 20465,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1182,
    provincia_id: 2,
  },
  {
    id: 20466,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1127,
    provincia_id: 2,
  },
  {
    id: 20467,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1126,
    provincia_id: 2,
  },
  {
    id: 20468,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1125,
    provincia_id: 2,
  },
  {
    id: 20469,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1124,
    provincia_id: 2,
  },
  {
    id: 20470,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1123,
    provincia_id: 2,
  },
  {
    id: 20471,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1122,
    provincia_id: 2,
  },
  {
    id: 20472,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1121,
    provincia_id: 2,
  },
  {
    id: 20473,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1120,
    provincia_id: 2,
  },
  {
    id: 20474,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1119,
    provincia_id: 2,
  },
  {
    id: 20475,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1118,
    provincia_id: 2,
  },
  {
    id: 20476,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1117,
    provincia_id: 2,
  },
  {
    id: 20477,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1116,
    provincia_id: 2,
  },
  {
    id: 20478,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1011,
    provincia_id: 2,
  },
  {
    id: 20479,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1114,
    provincia_id: 2,
  },
  {
    id: 20480,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1012,
    provincia_id: 2,
  },
  {
    id: 20481,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1157,
    provincia_id: 2,
  },
  {
    id: 20482,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1156,
    provincia_id: 2,
  },
  {
    id: 20483,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1155,
    provincia_id: 2,
  },
  {
    id: 20484,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1021,
    provincia_id: 2,
  },
  {
    id: 20485,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1020,
    provincia_id: 2,
  },
  {
    id: 20486,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1019,
    provincia_id: 2,
  },
  {
    id: 20487,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1018,
    provincia_id: 2,
  },
  {
    id: 20488,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1017,
    provincia_id: 2,
  },
  {
    id: 20489,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1016,
    provincia_id: 2,
  },
  {
    id: 20490,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1015,
    provincia_id: 2,
  },
  {
    id: 20491,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1014,
    provincia_id: 2,
  },
  {
    id: 20492,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1086,
    provincia_id: 2,
  },
  {
    id: 20493,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1115,
    provincia_id: 2,
  },
  {
    id: 20494,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1107,
    provincia_id: 2,
  },
  {
    id: 20495,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1144,
    provincia_id: 2,
  },
  {
    id: 20496,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1145,
    provincia_id: 2,
  },
  {
    id: 20497,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1146,
    provincia_id: 2,
  },
  {
    id: 20498,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1147,
    provincia_id: 2,
  },
  {
    id: 20499,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1148,
    provincia_id: 2,
  },
  {
    id: 20500,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1149,
    provincia_id: 2,
  },
  {
    id: 20501,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1245,
    provincia_id: 2,
  },
  {
    id: 20502,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1151,
    provincia_id: 2,
  },
  {
    id: 20503,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1141,
    provincia_id: 2,
  },
  {
    id: 20504,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1108,
    provincia_id: 2,
  },
  {
    id: 20505,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1109,
    provincia_id: 2,
  },
  {
    id: 20506,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1285,
    provincia_id: 2,
  },
  {
    id: 20507,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1243,
    provincia_id: 2,
  },
  {
    id: 20508,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1171,
    provincia_id: 2,
  },
  {
    id: 20509,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1150,
    provincia_id: 2,
  },
  {
    id: 20510,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1135,
    provincia_id: 2,
  },
  {
    id: 20511,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1172,
    provincia_id: 2,
  },
  {
    id: 20512,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1173,
    provincia_id: 2,
  },
  {
    id: 20513,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1129,
    provincia_id: 2,
  },
  {
    id: 20514,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1130,
    provincia_id: 2,
  },
  {
    id: 20515,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1131,
    provincia_id: 2,
  },
  {
    id: 20516,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1177,
    provincia_id: 2,
  },
  {
    id: 20517,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1143,
    provincia_id: 2,
  },
  {
    id: 20518,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1179,
    provincia_id: 2,
  },
  {
    id: 20519,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1142,
    provincia_id: 2,
  },
  {
    id: 20520,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1137,
    provincia_id: 2,
  },
  {
    id: 20521,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1138,
    provincia_id: 2,
  },
  {
    id: 20522,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1139,
    provincia_id: 2,
  },
  {
    id: 20523,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1140,
    provincia_id: 2,
  },
  {
    id: 20524,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1246,
    provincia_id: 2,
  },
  {
    id: 20525,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1178,
    provincia_id: 2,
  },
  {
    id: 20526,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1427,
    provincia_id: 2,
  },
  {
    id: 20527,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1244,
    provincia_id: 2,
  },
  {
    id: 20528,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1379,
    provincia_id: 2,
  },
  {
    id: 20529,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1373,
    provincia_id: 2,
  },
  {
    id: 20530,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1372,
    provincia_id: 2,
  },
  {
    id: 20531,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1371,
    provincia_id: 2,
  },
  {
    id: 20532,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1370,
    provincia_id: 2,
  },
  {
    id: 20533,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1381,
    provincia_id: 2,
  },
  {
    id: 20534,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1428,
    provincia_id: 2,
  },
  {
    id: 20535,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1382,
    provincia_id: 2,
  },
  {
    id: 20536,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1426,
    provincia_id: 2,
  },
  {
    id: 20537,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1425,
    provincia_id: 2,
  },
  {
    id: 20538,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1424,
    provincia_id: 2,
  },
  {
    id: 20539,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1423,
    provincia_id: 2,
  },
  {
    id: 20540,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1422,
    provincia_id: 2,
  },
  {
    id: 20541,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1429,
    provincia_id: 2,
  },
  {
    id: 20542,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1136,
    provincia_id: 2,
  },
  {
    id: 20543,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1383,
    provincia_id: 2,
  },
  {
    id: 20544,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1384,
    provincia_id: 2,
  },
  {
    id: 20545,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1385,
    provincia_id: 2,
  },
  {
    id: 20546,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1386,
    provincia_id: 2,
  },
  {
    id: 20547,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1387,
    provincia_id: 2,
  },
  {
    id: 20548,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1388,
    provincia_id: 2,
  },
  {
    id: 20549,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1389,
    provincia_id: 2,
  },
  {
    id: 20550,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1390,
    provincia_id: 2,
  },
  {
    id: 20551,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1251,
    provincia_id: 2,
  },
  {
    id: 20552,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1250,
    provincia_id: 2,
  },
  {
    id: 20553,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1249,
    provincia_id: 2,
  },
  {
    id: 20554,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1248,
    provincia_id: 2,
  },
  {
    id: 20555,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1247,
    provincia_id: 2,
  },
  {
    id: 20556,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1380,
    provincia_id: 2,
  },
  {
    id: 20557,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1200,
    provincia_id: 2,
  },
  {
    id: 20558,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1167,
    provincia_id: 2,
  },
  {
    id: 20559,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1166,
    provincia_id: 2,
  },
  {
    id: 20560,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1165,
    provincia_id: 2,
  },
  {
    id: 20561,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1164,
    provincia_id: 2,
  },
  {
    id: 20562,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1163,
    provincia_id: 2,
  },
  {
    id: 20563,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1161,
    provincia_id: 2,
  },
  {
    id: 20564,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1160,
    provincia_id: 2,
  },
  {
    id: 20565,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1168,
    provincia_id: 2,
  },
  {
    id: 20566,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1158,
    provincia_id: 2,
  },
  {
    id: 20567,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1162,
    provincia_id: 2,
  },
  {
    id: 20568,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1199,
    provincia_id: 2,
  },
  {
    id: 20569,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1198,
    provincia_id: 2,
  },
  {
    id: 20570,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1154,
    provincia_id: 2,
  },
  {
    id: 20571,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1153,
    provincia_id: 2,
  },
  {
    id: 20572,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1152,
    provincia_id: 2,
  },
  {
    id: 20573,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1194,
    provincia_id: 2,
  },
  {
    id: 20574,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1159,
    provincia_id: 2,
  },
  {
    id: 20575,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1169,
    provincia_id: 2,
  },
  {
    id: 20576,
    localidad: "CIUDAD AUTONOMA DE BUENOS AIRES",
    cp: 1170,
    provincia_id: 2,
  },
  {
    id: 20577,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1244,
    provincia_id: 2,
  },
  {
    id: 20578,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1292,
    provincia_id: 2,
  },
  {
    id: 20579,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1331,
    provincia_id: 2,
  },
  {
    id: 20580,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1332,
    provincia_id: 2,
  },
  {
    id: 20581,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1261,
    provincia_id: 2,
  },
  {
    id: 20582,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1333,
    provincia_id: 2,
  },
  {
    id: 20583,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1334,
    provincia_id: 2,
  },
  {
    id: 20584,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1329,
    provincia_id: 2,
  },
  {
    id: 20585,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1285,
    provincia_id: 2,
  },
  {
    id: 20586,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1294,
    provincia_id: 2,
  },
  {
    id: 20587,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1245,
    provincia_id: 2,
  },
  {
    id: 20588,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1335,
    provincia_id: 2,
  },
  {
    id: 20589,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1240,
    provincia_id: 2,
  },
  {
    id: 20590,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1239,
    provincia_id: 2,
  },
  {
    id: 20591,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1238,
    provincia_id: 2,
  },
  {
    id: 20592,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1336,
    provincia_id: 2,
  },
  {
    id: 20593,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1311,
    provincia_id: 2,
  },
  {
    id: 20594,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1284,
    provincia_id: 2,
  },
  {
    id: 20595,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1296,
    provincia_id: 2,
  },
  {
    id: 20596,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1322,
    provincia_id: 2,
  },
  {
    id: 20597,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1301,
    provincia_id: 2,
  },
  {
    id: 20598,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1323,
    provincia_id: 2,
  },
  {
    id: 20599,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1303,
    provincia_id: 2,
  },
  {
    id: 20600,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1300,
    provincia_id: 2,
  },
  {
    id: 20601,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1298,
    provincia_id: 2,
  },
  {
    id: 20602,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1324,
    provincia_id: 2,
  },
  {
    id: 20603,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1330,
    provincia_id: 2,
  },
  {
    id: 20604,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1325,
    provincia_id: 2,
  },
  {
    id: 20605,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1246,
    provincia_id: 2,
  },
  {
    id: 20606,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1326,
    provincia_id: 2,
  },
  {
    id: 20607,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1327,
    provincia_id: 2,
  },
  {
    id: 20608,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1305,
    provincia_id: 2,
  },
  {
    id: 20609,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1295,
    provincia_id: 2,
  },
  {
    id: 20610,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1328,
    provincia_id: 2,
  },
  {
    id: 20611,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1259,
    provincia_id: 2,
  },
  {
    id: 20612,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1260,
    provincia_id: 2,
  },
  {
    id: 20613,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1304,
    provincia_id: 2,
  },
  {
    id: 20614,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1286,
    provincia_id: 2,
  },
  {
    id: 20615,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1337,
    provincia_id: 2,
  },
  {
    id: 20616,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1321,
    provincia_id: 2,
  },
  {
    id: 20617,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1266,
    provincia_id: 2,
  },
  {
    id: 20618,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1289,
    provincia_id: 2,
  },
  {
    id: 20619,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1288,
    provincia_id: 2,
  },
  {
    id: 20620,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1267,
    provincia_id: 2,
  },
  {
    id: 20621,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1287,
    provincia_id: 2,
  },
  {
    id: 20622,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1421,
    provincia_id: 2,
  },
  {
    id: 20623,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1302,
    provincia_id: 2,
  },
  {
    id: 20624,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1265,
    provincia_id: 2,
  },
  {
    id: 20625,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1269,
    provincia_id: 2,
  },
  {
    id: 20626,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1275,
    provincia_id: 2,
  },
  {
    id: 20627,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1293,
    provincia_id: 2,
  },
  {
    id: 20628,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1270,
    provincia_id: 2,
  },
  {
    id: 20629,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1274,
    provincia_id: 2,
  },
  {
    id: 20630,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1271,
    provincia_id: 2,
  },
  {
    id: 20631,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1272,
    provincia_id: 2,
  },
  {
    id: 20632,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1268,
    provincia_id: 2,
  },
  {
    id: 20633,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1283,
    provincia_id: 2,
  },
  {
    id: 20634,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1273,
    provincia_id: 2,
  },
  {
    id: 20635,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1280,
    provincia_id: 2,
  },
  {
    id: 20636,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1281,
    provincia_id: 2,
  },
  {
    id: 20637,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1312,
    provincia_id: 2,
  },
  {
    id: 20638,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1282,
    provincia_id: 2,
  },
  {
    id: 20639,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1343,
    provincia_id: 2,
  },
  {
    id: 20640,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1278,
    provincia_id: 2,
  },
  {
    id: 20641,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1290,
    provincia_id: 2,
  },
  {
    id: 20642,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1277,
    provincia_id: 2,
  },
  {
    id: 20643,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1279,
    provincia_id: 2,
  },
  {
    id: 20644,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1344,
    provincia_id: 2,
  },
  {
    id: 20645,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1291,
    provincia_id: 2,
  },
  {
    id: 20646,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1276,
    provincia_id: 2,
  },
  {
    id: 20647,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1345,
    provincia_id: 2,
  },
  {
    id: 20648,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1313,
    provincia_id: 2,
  },
  {
    id: 20649,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1314,
    provincia_id: 2,
  },
  {
    id: 20650,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1346,
    provincia_id: 2,
  },
  {
    id: 20651,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1383,
    provincia_id: 2,
  },
  {
    id: 20652,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1427,
    provincia_id: 2,
  },
  {
    id: 20653,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1428,
    provincia_id: 2,
  },
  {
    id: 20654,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1429,
    provincia_id: 2,
  },
  {
    id: 20655,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1430,
    provincia_id: 2,
  },
  {
    id: 20656,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1370,
    provincia_id: 2,
  },
  {
    id: 20657,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1371,
    provincia_id: 2,
  },
  {
    id: 20658,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1372,
    provincia_id: 2,
  },
  {
    id: 20659,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1373,
    provincia_id: 2,
  },
  {
    id: 20660,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1374,
    provincia_id: 2,
  },
  {
    id: 20661,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1379,
    provincia_id: 2,
  },
  {
    id: 20662,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1380,
    provincia_id: 2,
  },
  {
    id: 20663,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1395,
    provincia_id: 2,
  },
  {
    id: 20664,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1382,
    provincia_id: 2,
  },
  {
    id: 20665,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1424,
    provincia_id: 2,
  },
  {
    id: 20666,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1384,
    provincia_id: 2,
  },
  {
    id: 20667,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1385,
    provincia_id: 2,
  },
  {
    id: 20668,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1386,
    provincia_id: 2,
  },
  {
    id: 20669,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1387,
    provincia_id: 2,
  },
  {
    id: 20670,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1388,
    provincia_id: 2,
  },
  {
    id: 20671,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1389,
    provincia_id: 2,
  },
  {
    id: 20672,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1390,
    provincia_id: 2,
  },
  {
    id: 20673,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1391,
    provincia_id: 2,
  },
  {
    id: 20674,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1392,
    provincia_id: 2,
  },
  {
    id: 20675,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1393,
    provincia_id: 2,
  },
  {
    id: 20676,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1423,
    provincia_id: 2,
  },
  {
    id: 20677,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1381,
    provincia_id: 2,
  },
  {
    id: 20678,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1405,
    provincia_id: 2,
  },
  {
    id: 20679,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1172,
    provincia_id: 2,
  },
  {
    id: 20680,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1431,
    provincia_id: 2,
  },
  {
    id: 20681,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1432,
    provincia_id: 2,
  },
  {
    id: 20682,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1433,
    provincia_id: 2,
  },
  {
    id: 20683,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1434,
    provincia_id: 2,
  },
  {
    id: 20684,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1435,
    provincia_id: 2,
  },
  {
    id: 20685,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1436,
    provincia_id: 2,
  },
  {
    id: 20686,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1437,
    provincia_id: 2,
  },
  {
    id: 20687,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1439,
    provincia_id: 2,
  },
  {
    id: 20688,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1440,
    provincia_id: 2,
  },
  {
    id: 20689,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1453,
    provincia_id: 2,
  },
  {
    id: 20690,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1426,
    provincia_id: 2,
  },
  {
    id: 20691,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1402,
    provincia_id: 2,
  },
  {
    id: 20692,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1425,
    provincia_id: 2,
  },
  {
    id: 20693,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1406,
    provincia_id: 2,
  },
  {
    id: 20694,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1411,
    provincia_id: 2,
  },
  {
    id: 20695,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1412,
    provincia_id: 2,
  },
  {
    id: 20696,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1413,
    provincia_id: 2,
  },
  {
    id: 20697,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1414,
    provincia_id: 2,
  },
  {
    id: 20698,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1416,
    provincia_id: 2,
  },
  {
    id: 20699,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1417,
    provincia_id: 2,
  },
  {
    id: 20700,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1419,
    provincia_id: 2,
  },
  {
    id: 20701,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1420,
    provincia_id: 2,
  },
  {
    id: 20702,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1422,
    provincia_id: 2,
  },
  {
    id: 20703,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1396,
    provincia_id: 2,
  },
  {
    id: 20704,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1599,
    provincia_id: 2,
  },
  {
    id: 20705,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1308,
    provincia_id: 2,
  },
  {
    id: 20706,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1394,
    provincia_id: 2,
  },
  {
    id: 20707,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1360,
    provincia_id: 2,
  },
  {
    id: 20708,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1361,
    provincia_id: 2,
  },
  {
    id: 20709,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1362,
    provincia_id: 2,
  },
  {
    id: 20710,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1363,
    provincia_id: 2,
  },
  {
    id: 20711,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1364,
    provincia_id: 2,
  },
  {
    id: 20712,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1365,
    provincia_id: 2,
  },
  {
    id: 20713,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1366,
    provincia_id: 2,
  },
  {
    id: 20714,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1367,
    provincia_id: 2,
  },
  {
    id: 20715,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1368,
    provincia_id: 2,
  },
  {
    id: 20716,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1369,
    provincia_id: 2,
  },
  {
    id: 20717,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1358,
    provincia_id: 2,
  },
  {
    id: 20718,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1307,
    provincia_id: 2,
  },
  {
    id: 20719,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1357,
    provincia_id: 2,
  },
  {
    id: 20720,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1309,
    provincia_id: 2,
  },
  {
    id: 20721,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1310,
    provincia_id: 2,
  },
  {
    id: 20722,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1375,
    provincia_id: 2,
  },
  {
    id: 20723,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1376,
    provincia_id: 2,
  },
  {
    id: 20724,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1377,
    provincia_id: 2,
  },
  {
    id: 20725,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1378,
    provincia_id: 2,
  },
  {
    id: 20726,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1315,
    provincia_id: 2,
  },
  {
    id: 20727,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1316,
    provincia_id: 2,
  },
  {
    id: 20728,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1317,
    provincia_id: 2,
  },
  {
    id: 20729,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1318,
    provincia_id: 2,
  },
  {
    id: 20730,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1319,
    provincia_id: 2,
  },
  {
    id: 20731,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1306,
    provincia_id: 2,
  },
  {
    id: 20732,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1410,
    provincia_id: 2,
  },
  {
    id: 20733,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1397,
    provincia_id: 2,
  },
  {
    id: 20734,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1398,
    provincia_id: 2,
  },
  {
    id: 20735,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1399,
    provincia_id: 2,
  },
  {
    id: 20736,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1400,
    provincia_id: 2,
  },
  {
    id: 20737,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1401,
    provincia_id: 2,
  },
  {
    id: 20738,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1338,
    provincia_id: 2,
  },
  {
    id: 20739,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1339,
    provincia_id: 2,
  },
  {
    id: 20740,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1340,
    provincia_id: 2,
  },
  {
    id: 20741,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1341,
    provincia_id: 2,
  },
  {
    id: 20742,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1342,
    provincia_id: 2,
  },
  {
    id: 20743,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1407,
    provincia_id: 2,
  },
  {
    id: 20744,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1359,
    provincia_id: 2,
  },
  {
    id: 20745,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1409,
    provincia_id: 2,
  },
  {
    id: 20746,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1320,
    provincia_id: 2,
  },
  {
    id: 20747,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1347,
    provincia_id: 2,
  },
  {
    id: 20748,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1348,
    provincia_id: 2,
  },
  {
    id: 20749,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1349,
    provincia_id: 2,
  },
  {
    id: 20750,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1350,
    provincia_id: 2,
  },
  {
    id: 20751,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1351,
    provincia_id: 2,
  },
  {
    id: 20752,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1352,
    provincia_id: 2,
  },
  {
    id: 20753,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1353,
    provincia_id: 2,
  },
  {
    id: 20754,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1354,
    provincia_id: 2,
  },
  {
    id: 20755,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1355,
    provincia_id: 2,
  },
  {
    id: 20756,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1356,
    provincia_id: 2,
  },
  {
    id: 20757,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1408,
    provincia_id: 2,
  },
  {
    id: 20758,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1181,
    provincia_id: 2,
  },
  {
    id: 20759,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1166,
    provincia_id: 2,
  },
  {
    id: 20760,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1189,
    provincia_id: 2,
  },
  {
    id: 20761,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1188,
    provincia_id: 2,
  },
  {
    id: 20762,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1187,
    provincia_id: 2,
  },
  {
    id: 20763,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1186,
    provincia_id: 2,
  },
  {
    id: 20764,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1185,
    provincia_id: 2,
  },
  {
    id: 20765,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1184,
    provincia_id: 2,
  },
  {
    id: 20766,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1191,
    provincia_id: 2,
  },
  {
    id: 20767,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1182,
    provincia_id: 2,
  },
  {
    id: 20768,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1192,
    provincia_id: 2,
  },
  {
    id: 20769,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1180,
    provincia_id: 2,
  },
  {
    id: 20770,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1221,
    provincia_id: 2,
  },
  {
    id: 20771,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1220,
    provincia_id: 2,
  },
  {
    id: 20772,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1219,
    provincia_id: 2,
  },
  {
    id: 20773,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1176,
    provincia_id: 2,
  },
  {
    id: 20774,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1175,
    provincia_id: 2,
  },
  {
    id: 20775,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1183,
    provincia_id: 2,
  },
  {
    id: 20776,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1199,
    provincia_id: 2,
  },
  {
    id: 20777,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1222,
    provincia_id: 2,
  },
  {
    id: 20778,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1164,
    provincia_id: 2,
  },
  {
    id: 20779,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1163,
    provincia_id: 2,
  },
  {
    id: 20780,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1162,
    provincia_id: 2,
  },
  {
    id: 20781,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1161,
    provincia_id: 2,
  },
  {
    id: 20782,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1160,
    provincia_id: 2,
  },
  {
    id: 20783,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1190,
    provincia_id: 2,
  },
  {
    id: 20784,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1200,
    provincia_id: 2,
  },
  {
    id: 20785,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1214,
    provincia_id: 2,
  },
  {
    id: 20786,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1198,
    provincia_id: 2,
  },
  {
    id: 20787,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1155,
    provincia_id: 2,
  },
  {
    id: 20788,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1154,
    provincia_id: 2,
  },
  {
    id: 20789,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1153,
    provincia_id: 2,
  },
  {
    id: 20790,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1194,
    provincia_id: 2,
  },
  {
    id: 20791,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1193,
    provincia_id: 2,
  },
  {
    id: 20792,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1159,
    provincia_id: 2,
  },
  {
    id: 20793,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1229,
    provincia_id: 2,
  },
  {
    id: 20794,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1174,
    provincia_id: 2,
  },
  {
    id: 20795,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1237,
    provincia_id: 2,
  },
  {
    id: 20796,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1236,
    provincia_id: 2,
  },
  {
    id: 20797,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1235,
    provincia_id: 2,
  },
  {
    id: 20798,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1234,
    provincia_id: 2,
  },
  {
    id: 20799,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1233,
    provincia_id: 2,
  },
  {
    id: 20800,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1232,
    provincia_id: 2,
  },
  {
    id: 20801,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1196,
    provincia_id: 2,
  },
  {
    id: 20802,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1230,
    provincia_id: 2,
  },
  {
    id: 20803,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1197,
    provincia_id: 2,
  },
  {
    id: 20804,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1228,
    provincia_id: 2,
  },
  {
    id: 20805,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1227,
    provincia_id: 2,
  },
  {
    id: 20806,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1226,
    provincia_id: 2,
  },
  {
    id: 20807,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1225,
    provincia_id: 2,
  },
  {
    id: 20808,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1224,
    provincia_id: 2,
  },
  {
    id: 20809,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1223,
    provincia_id: 2,
  },
  {
    id: 20810,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1231,
    provincia_id: 2,
  },
  {
    id: 20811,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1204,
    provincia_id: 2,
  },
  {
    id: 20812,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1167,
    provincia_id: 2,
  },
  {
    id: 20813,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1213,
    provincia_id: 2,
  },
  {
    id: 20814,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1212,
    provincia_id: 2,
  },
  {
    id: 20815,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1211,
    provincia_id: 2,
  },
  {
    id: 20816,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1210,
    provincia_id: 2,
  },
  {
    id: 20817,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1209,
    provincia_id: 2,
  },
  {
    id: 20818,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1195,
    provincia_id: 2,
  },
  {
    id: 20819,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1206,
    provincia_id: 2,
  },
  {
    id: 20820,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1215,
    provincia_id: 2,
  },
  {
    id: 20821,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1203,
    provincia_id: 2,
  },
  {
    id: 20822,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1202,
    provincia_id: 2,
  },
  {
    id: 20823,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1201,
    provincia_id: 2,
  },
  {
    id: 20824,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1243,
    provincia_id: 2,
  },
  {
    id: 20825,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1242,
    provincia_id: 2,
  },
  {
    id: 20826,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1241,
    provincia_id: 2,
  },
  {
    id: 20827,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1208,
    provincia_id: 2,
  },
  {
    id: 20828,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1120,
    provincia_id: 2,
  },
  {
    id: 20829,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1158,
    provincia_id: 2,
  },
  {
    id: 20830,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1128,
    provincia_id: 2,
  },
  {
    id: 20831,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1127,
    provincia_id: 2,
  },
  {
    id: 20832,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1126,
    provincia_id: 2,
  },
  {
    id: 20833,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1125,
    provincia_id: 2,
  },
  {
    id: 20834,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1124,
    provincia_id: 2,
  },
  {
    id: 20835,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1123,
    provincia_id: 2,
  },
  {
    id: 20836,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1087,
    provincia_id: 2,
  },
  {
    id: 20837,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1121,
    provincia_id: 2,
  },
  {
    id: 20838,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1088,
    provincia_id: 2,
  },
  {
    id: 20839,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1119,
    provincia_id: 2,
  },
  {
    id: 20840,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1118,
    provincia_id: 2,
  },
  {
    id: 20841,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1117,
    provincia_id: 2,
  },
  {
    id: 20842,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1116,
    provincia_id: 2,
  },
  {
    id: 20843,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1115,
    provincia_id: 2,
  },
  {
    id: 20844,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1165,
    provincia_id: 2,
  },
  {
    id: 20845,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1122,
    provincia_id: 2,
  },
  {
    id: 20846,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1095,
    provincia_id: 2,
  },
  {
    id: 20847,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1103,
    provincia_id: 2,
  },
  {
    id: 20848,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1102,
    provincia_id: 2,
  },
  {
    id: 20849,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1101,
    provincia_id: 2,
  },
  {
    id: 20850,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1100,
    provincia_id: 2,
  },
  {
    id: 20851,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1099,
    provincia_id: 2,
  },
  {
    id: 20852,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1098,
    provincia_id: 2,
  },
  {
    id: 20853,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1086,
    provincia_id: 2,
  },
  {
    id: 20854,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1096,
    provincia_id: 2,
  },
  {
    id: 20855,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1157,
    provincia_id: 2,
  },
  {
    id: 20856,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1094,
    provincia_id: 2,
  },
  {
    id: 20857,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1093,
    provincia_id: 2,
  },
  {
    id: 20858,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1092,
    provincia_id: 2,
  },
  {
    id: 20859,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1135,
    provincia_id: 2,
  },
  {
    id: 20860,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1134,
    provincia_id: 2,
  },
  {
    id: 20861,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1133,
    provincia_id: 2,
  },
  {
    id: 20862,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1097,
    provincia_id: 2,
  },
  {
    id: 20863,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1131,
    provincia_id: 2,
  },
  {
    id: 20864,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1139,
    provincia_id: 2,
  },
  {
    id: 20865,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1138,
    provincia_id: 2,
  },
  {
    id: 20866,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1137,
    provincia_id: 2,
  },
  {
    id: 20867,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1136,
    provincia_id: 2,
  },
  {
    id: 20868,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1179,
    provincia_id: 2,
  },
  {
    id: 20869,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1178,
    provincia_id: 2,
  },
  {
    id: 20870,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1114,
    provincia_id: 2,
  },
  {
    id: 20871,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1132,
    provincia_id: 2,
  },
  {
    id: 20872,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1142,
    provincia_id: 2,
  },
  {
    id: 20873,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1130,
    provincia_id: 2,
  },
  {
    id: 20874,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1129,
    provincia_id: 2,
  },
  {
    id: 20875,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1173,
    provincia_id: 2,
  },
  {
    id: 20876,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1171,
    provincia_id: 2,
  },
  {
    id: 20877,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1169,
    provincia_id: 2,
  },
  {
    id: 20878,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1168,
    provincia_id: 2,
  },
  {
    id: 20879,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1177,
    provincia_id: 2,
  },
  {
    id: 20880,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1148,
    provincia_id: 2,
  },
  {
    id: 20881,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1156,
    provincia_id: 2,
  },
  {
    id: 20882,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1110,
    provincia_id: 2,
  },
  {
    id: 20883,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1109,
    provincia_id: 2,
  },
  {
    id: 20884,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1108,
    provincia_id: 2,
  },
  {
    id: 20885,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1152,
    provincia_id: 2,
  },
  {
    id: 20886,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1151,
    provincia_id: 2,
  },
  {
    id: 20887,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1140,
    provincia_id: 2,
  },
  {
    id: 20888,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1149,
    provincia_id: 2,
  },
  {
    id: 20889,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1141,
    provincia_id: 2,
  },
  {
    id: 20890,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1147,
    provincia_id: 2,
  },
  {
    id: 20891,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1146,
    provincia_id: 2,
  },
  {
    id: 20892,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1145,
    provincia_id: 2,
  },
  {
    id: 20893,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1144,
    provincia_id: 2,
  },
  {
    id: 20894,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1143,
    provincia_id: 2,
  },
  {
    id: 20895,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1205,
    provincia_id: 2,
  },
  {
    id: 20896,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1150,
    provincia_id: 2,
  },
  {
    id: 20897,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1207,
    provincia_id: 2,
  },
  {
    id: 20898,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1045,
    provincia_id: 2,
  },
  {
    id: 20899,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1064,
    provincia_id: 2,
  },
  {
    id: 20900,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1022,
    provincia_id: 2,
  },
  {
    id: 20901,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1023,
    provincia_id: 2,
  },
  {
    id: 20902,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1024,
    provincia_id: 2,
  },
  {
    id: 20903,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1068,
    provincia_id: 2,
  },
  {
    id: 20904,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1069,
    provincia_id: 2,
  },
  {
    id: 20905,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1062,
    provincia_id: 2,
  },
  {
    id: 20906,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1028,
    provincia_id: 2,
  },
  {
    id: 20907,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1061,
    provincia_id: 2,
  },
  {
    id: 20908,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1030,
    provincia_id: 2,
  },
  {
    id: 20909,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1031,
    provincia_id: 2,
  },
  {
    id: 20910,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1032,
    provincia_id: 2,
  },
  {
    id: 20911,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1033,
    provincia_id: 2,
  },
  {
    id: 20912,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1034,
    provincia_id: 2,
  },
  {
    id: 20913,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1035,
    provincia_id: 2,
  },
  {
    id: 20914,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1264,
    provincia_id: 2,
  },
  {
    id: 20915,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1054,
    provincia_id: 2,
  },
  {
    id: 20916,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1046,
    provincia_id: 2,
  },
  {
    id: 20917,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1089,
    provincia_id: 2,
  },
  {
    id: 20918,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1090,
    provincia_id: 2,
  },
  {
    id: 20919,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1091,
    provincia_id: 2,
  },
  {
    id: 20920,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1050,
    provincia_id: 2,
  },
  {
    id: 20921,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1051,
    provincia_id: 2,
  },
  {
    id: 20922,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1063,
    provincia_id: 2,
  },
  {
    id: 20923,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1053,
    provincia_id: 2,
  },
  {
    id: 20924,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1038,
    provincia_id: 2,
  },
  {
    id: 20925,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1055,
    provincia_id: 2,
  },
  {
    id: 20926,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1056,
    provincia_id: 2,
  },
  {
    id: 20927,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1057,
    provincia_id: 2,
  },
  {
    id: 20928,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1058,
    provincia_id: 2,
  },
  {
    id: 20929,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1059,
    provincia_id: 2,
  },
  {
    id: 20930,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1060,
    provincia_id: 2,
  },
  {
    id: 20931,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1052,
    provincia_id: 2,
  },
  {
    id: 20932,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1018,
    provincia_id: 2,
  },
  {
    id: 20933,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1036,
    provincia_id: 2,
  },
  {
    id: 20934,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1010,
    provincia_id: 2,
  },
  {
    id: 20935,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1011,
    provincia_id: 2,
  },
  {
    id: 20936,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1012,
    provincia_id: 2,
  },
  {
    id: 20937,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1013,
    provincia_id: 2,
  },
  {
    id: 20938,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1014,
    provincia_id: 2,
  },
  {
    id: 20939,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1015,
    provincia_id: 2,
  },
  {
    id: 20940,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1008,
    provincia_id: 2,
  },
  {
    id: 20941,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1017,
    provincia_id: 2,
  },
  {
    id: 20942,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1007,
    provincia_id: 2,
  },
  {
    id: 20943,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1019,
    provincia_id: 2,
  },
  {
    id: 20944,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1020,
    provincia_id: 2,
  },
  {
    id: 20945,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1021,
    provincia_id: 2,
  },
  {
    id: 20946,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1025,
    provincia_id: 2,
  },
  {
    id: 20947,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1026,
    provincia_id: 2,
  },
  {
    id: 20948,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1027,
    provincia_id: 2,
  },
  {
    id: 20949,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1016,
    provincia_id: 2,
  },
  {
    id: 20950,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1003,
    provincia_id: 2,
  },
  {
    id: 20951,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1070,
    provincia_id: 2,
  },
  {
    id: 20952,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1039,
    provincia_id: 2,
  },
  {
    id: 20953,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1040,
    provincia_id: 2,
  },
  {
    id: 20954,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1041,
    provincia_id: 2,
  },
  {
    id: 20955,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1042,
    provincia_id: 2,
  },
  {
    id: 20956,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1043,
    provincia_id: 2,
  },
  {
    id: 20957,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1009,
    provincia_id: 2,
  },
  {
    id: 20958,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1002,
    provincia_id: 2,
  },
  {
    id: 20959,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1037,
    provincia_id: 2,
  },
  {
    id: 20960,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1047,
    provincia_id: 2,
  },
  {
    id: 20961,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1048,
    provincia_id: 2,
  },
  {
    id: 20962,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1049,
    provincia_id: 2,
  },
  {
    id: 20963,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1004,
    provincia_id: 2,
  },
  {
    id: 20964,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1005,
    provincia_id: 2,
  },
  {
    id: 20965,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1006,
    provincia_id: 2,
  },
  {
    id: 20966,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1001,
    provincia_id: 2,
  },
  {
    id: 20967,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1044,
    provincia_id: 2,
  },
  {
    id: 20968,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1106,
    provincia_id: 2,
  },
  {
    id: 20969,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1105,
    provincia_id: 2,
  },
  {
    id: 20970,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1170,
    provincia_id: 2,
  },
  {
    id: 20971,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1104,
    provincia_id: 2,
  },
  {
    id: 20972,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1247,
    provincia_id: 2,
  },
  {
    id: 20973,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1248,
    provincia_id: 2,
  },
  {
    id: 20974,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1249,
    provincia_id: 2,
  },
  {
    id: 20975,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1250,
    provincia_id: 2,
  },
  {
    id: 20976,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1251,
    provincia_id: 2,
  },
  {
    id: 20977,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1107,
    provincia_id: 2,
  },
  {
    id: 20978,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1253,
    provincia_id: 2,
  },
  {
    id: 20979,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1254,
    provincia_id: 2,
  },
  {
    id: 20980,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1255,
    provincia_id: 2,
  },
  {
    id: 20981,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1029,
    provincia_id: 2,
  },
  {
    id: 20982,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1257,
    provincia_id: 2,
  },
  {
    id: 20983,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1258,
    provincia_id: 2,
  },
  {
    id: 20984,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1216,
    provincia_id: 2,
  },
  {
    id: 20985,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1217,
    provincia_id: 2,
  },
  {
    id: 20986,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1218,
    provincia_id: 2,
  },
  {
    id: 20987,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1262,
    provincia_id: 2,
  },
  {
    id: 20988,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1263,
    provincia_id: 2,
  },
  {
    id: 20989,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1252,
    provincia_id: 2,
  },
  {
    id: 20990,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1081,
    provincia_id: 2,
  },
  {
    id: 20991,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1113,
    provincia_id: 2,
  },
  {
    id: 20992,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1071,
    provincia_id: 2,
  },
  {
    id: 20993,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1073,
    provincia_id: 2,
  },
  {
    id: 20994,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1075,
    provincia_id: 2,
  },
  {
    id: 20995,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1076,
    provincia_id: 2,
  },
  {
    id: 20996,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1077,
    provincia_id: 2,
  },
  {
    id: 20997,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1078,
    provincia_id: 2,
  },
  {
    id: 20998,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1112,
    provincia_id: 2,
  },
  {
    id: 20999,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1080,
    provincia_id: 2,
  },
  {
    id: 21000,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1072,
    provincia_id: 2,
  },
  {
    id: 21001,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1082,
    provincia_id: 2,
  },
  {
    id: 21002,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1083,
    provincia_id: 2,
  },
  {
    id: 21003,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1084,
    provincia_id: 2,
  },
  {
    id: 21004,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1085,
    provincia_id: 2,
  },
  {
    id: 21005,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1065,
    provincia_id: 2,
  },
  {
    id: 21006,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1256,
    provincia_id: 2,
  },
  {
    id: 21007,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1079,
    provincia_id: 2,
  },
  {
    id: 21008,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1066,
    provincia_id: 2,
  },
  {
    id: 21009,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1067,
    provincia_id: 2,
  },
  {
    id: 21010,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1111,
    provincia_id: 2,
  },
  {
    id: 21011,
    localidad: "CIUDAD AUTONOMA BUENOS AIRES",
    cp: 1074,
    provincia_id: 2,
  },
  {
    id: 21012,
    localidad: "CABA - ZONA PUERTO",
    cp: 1104,
    provincia_id: 2,
  },
  {
    id: 21013,
    localidad: "CABA - ZONA PUERTO",
    cp: 1107,
    provincia_id: 2,
  },
  {
    id: 21014,
    localidad: "CABA - ZONA PUERTO",
    cp: 1106,
    provincia_id: 2,
  },
  {
    id: 21015,
    localidad: "CABA - VILLA URQUIZA",
    cp: 1431,
    provincia_id: 2,
  },
  {
    id: 21016,
    localidad: "CABA - VILLA ORTUZAR",
    cp: 1430,
    provincia_id: 2,
  },
  {
    id: 21017,
    localidad: "CABA - VILLA LUGANO",
    cp: 1439,
    provincia_id: 2,
  },
  {
    id: 21018,
    localidad: "CABA - VILLA GRAL. MITRE",
    cp: 1416,
    provincia_id: 2,
  },
  {
    id: 21019,
    localidad: "CABA - VILLA DEVOTO",
    cp: 1417,
    provincia_id: 2,
  },
  {
    id: 21020,
    localidad: "CABA - VILLA CRESPO",
    cp: 1405,
    provincia_id: 2,
  },
  {
    id: 21021,
    localidad: "CABA - VELEZ SARFIELD",
    cp: 1407,
    provincia_id: 2,
  },
  {
    id: 21022,
    localidad: "CABA - SAN TELMO",
    cp: 1063,
    provincia_id: 2,
  },
  {
    id: 21023,
    localidad: "CABA - SAN TELMO",
    cp: 1069,
    provincia_id: 2,
  },
  {
    id: 21024,
    localidad: "CABA - SAN TELMO",
    cp: 1155,
    provincia_id: 2,
  },
  {
    id: 21025,
    localidad: "CABA - SAN TELMO",
    cp: 1156,
    provincia_id: 2,
  },
  {
    id: 21026,
    localidad: "CABA - SAN TELMO",
    cp: 1150,
    provincia_id: 2,
  },
  {
    id: 21027,
    localidad: "CABA - SAN TELMO",
    cp: 1147,
    provincia_id: 2,
  },
  {
    id: 21028,
    localidad: "CABA - SAN TELMO",
    cp: 1103,
    provincia_id: 2,
  },
  {
    id: 21029,
    localidad: "CABA - SAN TELMO",
    cp: 1158,
    provincia_id: 2,
  },
  {
    id: 21030,
    localidad: "CABA - SAN TELMO",
    cp: 1098,
    provincia_id: 2,
  },
  {
    id: 21031,
    localidad: "CABA - SAN TELMO",
    cp: 1102,
    provincia_id: 2,
  },
  {
    id: 21032,
    localidad: "CABA - SAN TELMO",
    cp: 1066,
    provincia_id: 2,
  },
  {
    id: 21033,
    localidad: "CABA - SAN TELMO",
    cp: 1101,
    provincia_id: 2,
  },
  {
    id: 21034,
    localidad: "CABA - SAN TELMO",
    cp: 1099,
    provincia_id: 2,
  },
  {
    id: 21035,
    localidad: "CABA - SAN TELMO",
    cp: 1070,
    provincia_id: 2,
  },
  {
    id: 21036,
    localidad: "CABA - SAN TELMO",
    cp: 1064,
    provincia_id: 2,
  },
  {
    id: 21037,
    localidad: "CABA - SAN TELMO",
    cp: 1157,
    provincia_id: 2,
  },
  {
    id: 21038,
    localidad: "CABA - SAN TELMO",
    cp: 1065,
    provincia_id: 2,
  },
  {
    id: 21039,
    localidad: "CABA - SAN NICOLAS",
    cp: 1037,
    provincia_id: 2,
  },
  {
    id: 21040,
    localidad: "CABA - SAN NICOLAS",
    cp: 1038,
    provincia_id: 2,
  },
  {
    id: 21041,
    localidad: "CABA - SAN NICOLAS",
    cp: 1017,
    provincia_id: 2,
  },
  {
    id: 21042,
    localidad: "CABA - SAN NICOLAS",
    cp: 1004,
    provincia_id: 2,
  },
  {
    id: 21043,
    localidad: "CABA - SAN NICOLAS",
    cp: 1042,
    provincia_id: 2,
  },
  {
    id: 21044,
    localidad: "CABA - SAN NICOLAS",
    cp: 1043,
    provincia_id: 2,
  },
  {
    id: 21045,
    localidad: "CABA - SAN NICOLAS",
    cp: 1003,
    provincia_id: 2,
  },
  {
    id: 21046,
    localidad: "CABA - SAN NICOLAS",
    cp: 1047,
    provincia_id: 2,
  },
  {
    id: 21047,
    localidad: "CABA - SAN NICOLAS",
    cp: 1048,
    provincia_id: 2,
  },
  {
    id: 21048,
    localidad: "CABA - SAN NICOLAS",
    cp: 1049,
    provincia_id: 2,
  },
  {
    id: 21049,
    localidad: "CABA - SAN NICOLAS",
    cp: 1041,
    provincia_id: 2,
  },
  {
    id: 21050,
    localidad: "CABA - SAN NICOLAS",
    cp: 1005,
    provincia_id: 2,
  },
  {
    id: 21051,
    localidad: "CABA - SAN NICOLAS",
    cp: 1009,
    provincia_id: 2,
  },
  {
    id: 21052,
    localidad: "CABA - SAN NICOLAS",
    cp: 1053,
    provincia_id: 2,
  },
  {
    id: 21053,
    localidad: "CABA - SAN NICOLAS",
    cp: 1013,
    provincia_id: 2,
  },
  {
    id: 21054,
    localidad: "CABA - SAN NICOLAS",
    cp: 1015,
    provincia_id: 2,
  },
  {
    id: 21055,
    localidad: "CABA - SAN NICOLAS",
    cp: 1062,
    provincia_id: 2,
  },
  {
    id: 21056,
    localidad: "CABA - SAN NICOLAS",
    cp: 1055,
    provincia_id: 2,
  },
  {
    id: 21057,
    localidad: "CABA - SAN NICOLAS",
    cp: 1036,
    provincia_id: 2,
  },
  {
    id: 21058,
    localidad: "CABA - SAN NICOLAS",
    cp: 1010,
    provincia_id: 2,
  },
  {
    id: 21059,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1231,
    provincia_id: 2,
  },
  {
    id: 21060,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1251,
    provincia_id: 2,
  },
  {
    id: 21061,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1259,
    provincia_id: 2,
  },
  {
    id: 21062,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1260,
    provincia_id: 2,
  },
  {
    id: 21063,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1244,
    provincia_id: 2,
  },
  {
    id: 21064,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1245,
    provincia_id: 2,
  },
  {
    id: 21065,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1246,
    provincia_id: 2,
  },
  {
    id: 21066,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1247,
    provincia_id: 2,
  },
  {
    id: 21067,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1220,
    provincia_id: 2,
  },
  {
    id: 21068,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1249,
    provincia_id: 2,
  },
  {
    id: 21069,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1222,
    provincia_id: 2,
  },
  {
    id: 21070,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1252,
    provincia_id: 2,
  },
  {
    id: 21071,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1230,
    provincia_id: 2,
  },
  {
    id: 21072,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1248,
    provincia_id: 2,
  },
  {
    id: 21073,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1232,
    provincia_id: 2,
  },
  {
    id: 21074,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1253,
    provincia_id: 2,
  },
  {
    id: 21075,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1229,
    provincia_id: 2,
  },
  {
    id: 21076,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1227,
    provincia_id: 2,
  },
  {
    id: 21077,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1225,
    provincia_id: 2,
  },
  {
    id: 21078,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1219,
    provincia_id: 2,
  },
  {
    id: 21079,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1258,
    provincia_id: 2,
  },
  {
    id: 21080,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1254,
    provincia_id: 2,
  },
  {
    id: 21081,
    localidad: "CABA - SAN CRISTOBAL",
    cp: 1221,
    provincia_id: 2,
  },
  {
    id: 21082,
    localidad: "CABA - SAAVEDRA",
    cp: 1419,
    provincia_id: 2,
  },
  {
    id: 21083,
    localidad: "CABA - RETIRO",
    cp: 1058,
    provincia_id: 2,
  },
  {
    id: 21084,
    localidad: "CABA - RETIRO",
    cp: 1006,
    provincia_id: 2,
  },
  {
    id: 21085,
    localidad: "CABA - RETIRO",
    cp: 1057,
    provincia_id: 2,
  },
  {
    id: 21086,
    localidad: "CABA - RETIRO",
    cp: 1054,
    provincia_id: 2,
  },
  {
    id: 21087,
    localidad: "CABA - RETIRO",
    cp: 1011,
    provincia_id: 2,
  },
  {
    id: 21088,
    localidad: "CABA - RETIRO",
    cp: 1059,
    provincia_id: 2,
  },
  {
    id: 21089,
    localidad: "CABA - RETIRO",
    cp: 1001,
    provincia_id: 2,
  },
  {
    id: 21090,
    localidad: "CABA - RETIRO",
    cp: 1007,
    provincia_id: 2,
  },
  {
    id: 21091,
    localidad: "CABA - RECOLETA",
    cp: 1021,
    provincia_id: 2,
  },
  {
    id: 21092,
    localidad: "CABA - RECOLETA",
    cp: 1125,
    provincia_id: 2,
  },
  {
    id: 21093,
    localidad: "CABA - RECOLETA",
    cp: 1111,
    provincia_id: 2,
  },
  {
    id: 21094,
    localidad: "CABA - RECOLETA",
    cp: 1127,
    provincia_id: 2,
  },
  {
    id: 21095,
    localidad: "CABA - RECOLETA",
    cp: 1128,
    provincia_id: 2,
  },
  {
    id: 21096,
    localidad: "CABA - RECOLETA",
    cp: 1112,
    provincia_id: 2,
  },
  {
    id: 21097,
    localidad: "CABA - RECOLETA",
    cp: 1113,
    provincia_id: 2,
  },
  {
    id: 21098,
    localidad: "CABA - RECOLETA",
    cp: 1056,
    provincia_id: 2,
  },
  {
    id: 21099,
    localidad: "CABA - RECOLETA",
    cp: 1022,
    provincia_id: 2,
  },
  {
    id: 21100,
    localidad: "CABA - RECOLETA",
    cp: 1124,
    provincia_id: 2,
  },
  {
    id: 21101,
    localidad: "CABA - RECOLETA",
    cp: 1020,
    provincia_id: 2,
  },
  {
    id: 21102,
    localidad: "CABA - RECOLETA",
    cp: 1019,
    provincia_id: 2,
  },
  {
    id: 21103,
    localidad: "CABA - RECOLETA",
    cp: 1014,
    provincia_id: 2,
  },
  {
    id: 21104,
    localidad: "CABA - RECOLETA",
    cp: 1016,
    provincia_id: 2,
  },
  {
    id: 21105,
    localidad: "CABA - RECOLETA",
    cp: 1018,
    provincia_id: 2,
  },
  {
    id: 21106,
    localidad: "CABA - RECOLETA",
    cp: 1118,
    provincia_id: 2,
  },
  {
    id: 21107,
    localidad: "CABA - RECOLETA",
    cp: 1123,
    provincia_id: 2,
  },
  {
    id: 21108,
    localidad: "CABA - RECOLETA",
    cp: 1122,
    provincia_id: 2,
  },
  {
    id: 21109,
    localidad: "CABA - RECOLETA",
    cp: 1121,
    provincia_id: 2,
  },
  {
    id: 21110,
    localidad: "CABA - RECOLETA",
    cp: 1126,
    provincia_id: 2,
  },
  {
    id: 21111,
    localidad: "CABA - RECOLETA",
    cp: 1119,
    provincia_id: 2,
  },
  {
    id: 21112,
    localidad: "CABA - RECOLETA",
    cp: 1060,
    provincia_id: 2,
  },
  {
    id: 21113,
    localidad: "CABA - RECOLETA",
    cp: 1117,
    provincia_id: 2,
  },
  {
    id: 21114,
    localidad: "CABA - RECOLETA",
    cp: 1116,
    provincia_id: 2,
  },
  {
    id: 21115,
    localidad: "CABA - RECOLETA",
    cp: 1115,
    provincia_id: 2,
  },
  {
    id: 21116,
    localidad: "CABA - RECOLETA",
    cp: 1114,
    provincia_id: 2,
  },
  {
    id: 21117,
    localidad: "CABA - RECOLETA",
    cp: 1129,
    provincia_id: 2,
  },
  {
    id: 21118,
    localidad: "CABA - RECOLETA",
    cp: 1025,
    provincia_id: 2,
  },
  {
    id: 21119,
    localidad: "CABA - RECOLETA",
    cp: 1061,
    provincia_id: 2,
  },
  {
    id: 21120,
    localidad: "CABA - RECOLETA",
    cp: 1120,
    provincia_id: 2,
  },
  {
    id: 21121,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1264,
    provincia_id: 2,
  },
  {
    id: 21122,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1263,
    provincia_id: 2,
  },
  {
    id: 21123,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1262,
    provincia_id: 2,
  },
  {
    id: 21124,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1256,
    provincia_id: 2,
  },
  {
    id: 21125,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1255,
    provincia_id: 2,
  },
  {
    id: 21126,
    localidad: "CABA - PARQUE PATRICIOS",
    cp: 1261,
    provincia_id: 2,
  },
  {
    id: 21127,
    localidad: "CABA - PARQUE CHACABUCO",
    cp: 1424,
    provincia_id: 2,
  },
  {
    id: 21128,
    localidad: "CABA - PALERMO",
    cp: 1425,
    provincia_id: 2,
  },
  {
    id: 21129,
    localidad: "CABA - NUÑEZ",
    cp: 1429,
    provincia_id: 2,
  },
  {
    id: 21130,
    localidad: "CABA - NUEVA POMPEYA",
    cp: 1437,
    provincia_id: 2,
  },
  {
    id: 21131,
    localidad: "CABA - MONSERRAT",
    cp: 1079,
    provincia_id: 2,
  },
  {
    id: 21132,
    localidad: "CABA - MONSERRAT",
    cp: 1088,
    provincia_id: 2,
  },
  {
    id: 21133,
    localidad: "CABA - MONSERRAT",
    cp: 1089,
    provincia_id: 2,
  },
  {
    id: 21134,
    localidad: "CABA - MONSERRAT",
    cp: 1093,
    provincia_id: 2,
  },
  {
    id: 21135,
    localidad: "CABA - MONSERRAT",
    cp: 1095,
    provincia_id: 2,
  },
  {
    id: 21136,
    localidad: "CABA - MONSERRAT",
    cp: 1096,
    provincia_id: 2,
  },
  {
    id: 21137,
    localidad: "CABA - MONSERRAT",
    cp: 1067,
    provincia_id: 2,
  },
  {
    id: 21138,
    localidad: "CABA - MONSERRAT",
    cp: 1084,
    provincia_id: 2,
  },
  {
    id: 21139,
    localidad: "CABA - MONSERRAT",
    cp: 1085,
    provincia_id: 2,
  },
  {
    id: 21140,
    localidad: "CABA - MONSERRAT",
    cp: 1086,
    provincia_id: 2,
  },
  {
    id: 21141,
    localidad: "CABA - MONSERRAT",
    cp: 1091,
    provincia_id: 2,
  },
  {
    id: 21142,
    localidad: "CABA - MONSERRAT",
    cp: 1033,
    provincia_id: 2,
  },
  {
    id: 21143,
    localidad: "CABA - MONSERRAT",
    cp: 1035,
    provincia_id: 2,
  },
  {
    id: 21144,
    localidad: "CABA - MONSERRAT",
    cp: 1002,
    provincia_id: 2,
  },
  {
    id: 21145,
    localidad: "CABA - MONSERRAT",
    cp: 1008,
    provincia_id: 2,
  },
  {
    id: 21146,
    localidad: "CABA - MONSERRAT",
    cp: 1012,
    provincia_id: 2,
  },
  {
    id: 21147,
    localidad: "CABA - MONSERRAT",
    cp: 1108,
    provincia_id: 2,
  },
  {
    id: 21148,
    localidad: "CABA - MONSERRAT",
    cp: 1109,
    provincia_id: 2,
  },
  {
    id: 21149,
    localidad: "CABA - MONSERRAT",
    cp: 1110,
    provincia_id: 2,
  },
  {
    id: 21150,
    localidad: "CABA - MONSERRAT",
    cp: 1087,
    provincia_id: 2,
  },
  {
    id: 21151,
    localidad: "CABA - MONSERRAT",
    cp: 1092,
    provincia_id: 2,
  },
  {
    id: 21152,
    localidad: "CABA - MONSERRAT",
    cp: 1097,
    provincia_id: 2,
  },
  {
    id: 21153,
    localidad: "CABA - MATADEROS",
    cp: 1440,
    provincia_id: 2,
  },
  {
    id: 21154,
    localidad: "CABA - LINIERS",
    cp: 1408,
    provincia_id: 2,
  },
  {
    id: 21155,
    localidad: "CABA - LA BOCA",
    cp: 1163,
    provincia_id: 2,
  },
  {
    id: 21156,
    localidad: "CABA - LA BOCA",
    cp: 1162,
    provincia_id: 2,
  },
  {
    id: 21157,
    localidad: "CABA - LA BOCA",
    cp: 1161,
    provincia_id: 2,
  },
  {
    id: 21158,
    localidad: "CABA - LA BOCA",
    cp: 1160,
    provincia_id: 2,
  },
  {
    id: 21159,
    localidad: "CABA - LA BOCA",
    cp: 1164,
    provincia_id: 2,
  },
  {
    id: 21160,
    localidad: "CABA - LA BOCA",
    cp: 1167,
    provincia_id: 2,
  },
  {
    id: 21161,
    localidad: "CABA - LA BOCA",
    cp: 1166,
    provincia_id: 2,
  },
  {
    id: 21162,
    localidad: "CABA - LA BOCA",
    cp: 1165,
    provincia_id: 2,
  },
  {
    id: 21163,
    localidad: "CABA - LA BOCA",
    cp: 1169,
    provincia_id: 2,
  },
  {
    id: 21164,
    localidad: "CABA - LA BOCA",
    cp: 1159,
    provincia_id: 2,
  },
  {
    id: 21165,
    localidad: "CABA - LA BOCA",
    cp: 1168,
    provincia_id: 2,
  },
  {
    id: 21166,
    localidad: "CABA - FLORES",
    cp: 1406,
    provincia_id: 2,
  },
  {
    id: 21167,
    localidad: "CABA - CONSTITUCION",
    cp: 1151,
    provincia_id: 2,
  },
  {
    id: 21168,
    localidad: "CABA - CONSTITUCION",
    cp: 1075,
    provincia_id: 2,
  },
  {
    id: 21169,
    localidad: "CABA - CONSTITUCION",
    cp: 1076,
    provincia_id: 2,
  },
  {
    id: 21170,
    localidad: "CABA - CONSTITUCION",
    cp: 1077,
    provincia_id: 2,
  },
  {
    id: 21171,
    localidad: "CABA - CONSTITUCION",
    cp: 1078,
    provincia_id: 2,
  },
  {
    id: 21172,
    localidad: "CABA - CONSTITUCION",
    cp: 1153,
    provincia_id: 2,
  },
  {
    id: 21173,
    localidad: "CABA - CONSTITUCION",
    cp: 1154,
    provincia_id: 2,
  },
  {
    id: 21174,
    localidad: "CABA - CONSTITUCION",
    cp: 1068,
    provincia_id: 2,
  },
  {
    id: 21175,
    localidad: "CABA - CONSTITUCION",
    cp: 1148,
    provincia_id: 2,
  },
  {
    id: 21176,
    localidad: "CABA - CONSTITUCION",
    cp: 1072,
    provincia_id: 2,
  },
  {
    id: 21177,
    localidad: "CABA - CONSTITUCION",
    cp: 1267,
    provincia_id: 2,
  },
  {
    id: 21178,
    localidad: "CABA - CONSTITUCION",
    cp: 1266,
    provincia_id: 2,
  },
  {
    id: 21179,
    localidad: "CABA - CONSTITUCION",
    cp: 1265,
    provincia_id: 2,
  },
  {
    id: 21180,
    localidad: "CABA - CONSTITUCION",
    cp: 1283,
    provincia_id: 2,
  },
  {
    id: 21181,
    localidad: "CABA - CONSTITUCION",
    cp: 1134,
    provincia_id: 2,
  },
  {
    id: 21182,
    localidad: "CABA - CONSTITUCION",
    cp: 1143,
    provincia_id: 2,
  },
  {
    id: 21183,
    localidad: "CABA - CONSTITUCION",
    cp: 1133,
    provincia_id: 2,
  },
  {
    id: 21184,
    localidad: "CABA - CONSTITUCION",
    cp: 1268,
    provincia_id: 2,
  },
  {
    id: 21185,
    localidad: "CABA - CONSTITUCION",
    cp: 1136,
    provincia_id: 2,
  },
  {
    id: 21186,
    localidad: "CABA - CONSTITUCION",
    cp: 1137,
    provincia_id: 2,
  },
  {
    id: 21187,
    localidad: "CABA - CONSTITUCION",
    cp: 1138,
    provincia_id: 2,
  },
  {
    id: 21188,
    localidad: "CABA - CONSTITUCION",
    cp: 1139,
    provincia_id: 2,
  },
  {
    id: 21189,
    localidad: "CABA - CONSTITUCION",
    cp: 1140,
    provincia_id: 2,
  },
  {
    id: 21190,
    localidad: "CABA - CONSTITUCION",
    cp: 1074,
    provincia_id: 2,
  },
  {
    id: 21191,
    localidad: "CABA - CONSTITUCION",
    cp: 1275,
    provincia_id: 2,
  },
  {
    id: 21192,
    localidad: "CABA - CONSTITUCION",
    cp: 1073,
    provincia_id: 2,
  },
  {
    id: 21193,
    localidad: "CABA - CONSTITUCION",
    cp: 1152,
    provincia_id: 2,
  },
  {
    id: 21194,
    localidad: "CABA - CONSTITUCION",
    cp: 1135,
    provincia_id: 2,
  },
  {
    id: 21195,
    localidad: "CABA - CONSTITUCION",
    cp: 1100,
    provincia_id: 2,
  },
  {
    id: 21196,
    localidad: "CABA - CONSTITUCION",
    cp: 1071,
    provincia_id: 2,
  },
  {
    id: 21197,
    localidad: "CABA - CONSTITUCION",
    cp: 1130,
    provincia_id: 2,
  },
  {
    id: 21198,
    localidad: "CABA - CONSTITUCION",
    cp: 1141,
    provincia_id: 2,
  },
  {
    id: 21199,
    localidad: "CABA - COMUNICACIONES",
    cp: 1105,
    provincia_id: 2,
  },
  {
    id: 21200,
    localidad: "CABA - COLEGIALES",
    cp: 1426,
    provincia_id: 2,
  },
  {
    id: 21201,
    localidad: "CABA - CHACARITA",
    cp: 1427,
    provincia_id: 2,
  },
  {
    id: 21202,
    localidad: "CABA - CABALLITO",
    cp: 1414,
    provincia_id: 2,
  },
  {
    id: 21203,
    localidad: "CABA - BOEDO",
    cp: 1238,
    provincia_id: 2,
  },
  {
    id: 21204,
    localidad: "CABA - BOEDO",
    cp: 1241,
    provincia_id: 2,
  },
  {
    id: 21205,
    localidad: "CABA - BOEDO",
    cp: 1237,
    provincia_id: 2,
  },
  {
    id: 21206,
    localidad: "CABA - BOEDO",
    cp: 1235,
    provincia_id: 2,
  },
  {
    id: 21207,
    localidad: "CABA - BOEDO",
    cp: 1242,
    provincia_id: 2,
  },
  {
    id: 21208,
    localidad: "CABA - BOEDO",
    cp: 1228,
    provincia_id: 2,
  },
  {
    id: 21209,
    localidad: "CABA - BOEDO",
    cp: 1239,
    provincia_id: 2,
  },
  {
    id: 21210,
    localidad: "CABA - BOEDO",
    cp: 1233,
    provincia_id: 2,
  },
  {
    id: 21211,
    localidad: "CABA - BOEDO",
    cp: 1240,
    provincia_id: 2,
  },
  {
    id: 21212,
    localidad: "CABA - BOEDO",
    cp: 1226,
    provincia_id: 2,
  },
  {
    id: 21213,
    localidad: "CABA - BOEDO",
    cp: 1236,
    provincia_id: 2,
  },
  {
    id: 21214,
    localidad: "CABA - BOEDO",
    cp: 1243,
    provincia_id: 2,
  },
  {
    id: 21215,
    localidad: "CABA - BELGRANO",
    cp: 1428,
    provincia_id: 2,
  },
  {
    id: 21216,
    localidad: "CABA - BARRACAS",
    cp: 1294,
    provincia_id: 2,
  },
  {
    id: 21217,
    localidad: "CABA - BARRACAS",
    cp: 1289,
    provincia_id: 2,
  },
  {
    id: 21218,
    localidad: "CABA - BARRACAS",
    cp: 1271,
    provincia_id: 2,
  },
  {
    id: 21219,
    localidad: "CABA - BARRACAS",
    cp: 1296,
    provincia_id: 2,
  },
  {
    id: 21220,
    localidad: "CABA - BARRACAS",
    cp: 1295,
    provincia_id: 2,
  },
  {
    id: 21221,
    localidad: "CABA - BARRACAS",
    cp: 1278,
    provincia_id: 2,
  },
  {
    id: 21222,
    localidad: "CABA - BARRACAS",
    cp: 1269,
    provincia_id: 2,
  },
  {
    id: 21223,
    localidad: "CABA - BARRACAS",
    cp: 1272,
    provincia_id: 2,
  },
  {
    id: 21224,
    localidad: "CABA - BARRACAS",
    cp: 1273,
    provincia_id: 2,
  },
  {
    id: 21225,
    localidad: "CABA - BARRACAS",
    cp: 1293,
    provincia_id: 2,
  },
  {
    id: 21226,
    localidad: "CABA - BARRACAS",
    cp: 1291,
    provincia_id: 2,
  },
  {
    id: 21227,
    localidad: "CABA - BARRACAS",
    cp: 1270,
    provincia_id: 2,
  },
  {
    id: 21228,
    localidad: "CABA - BARRACAS",
    cp: 1290,
    provincia_id: 2,
  },
  {
    id: 21229,
    localidad: "CABA - BARRACAS",
    cp: 1274,
    provincia_id: 2,
  },
  {
    id: 21230,
    localidad: "CABA - BARRACAS",
    cp: 1292,
    provincia_id: 2,
  },
  {
    id: 21231,
    localidad: "CABA - BARRACAS",
    cp: 1276,
    provincia_id: 2,
  },
  {
    id: 21232,
    localidad: "CABA - BARRACAS",
    cp: 1288,
    provincia_id: 2,
  },
  {
    id: 21233,
    localidad: "CABA - BARRACAS",
    cp: 1287,
    provincia_id: 2,
  },
  {
    id: 21234,
    localidad: "CABA - BARRACAS",
    cp: 1282,
    provincia_id: 2,
  },
  {
    id: 21235,
    localidad: "CABA - BARRACAS",
    cp: 1281,
    provincia_id: 2,
  },
  {
    id: 21236,
    localidad: "CABA - BARRACAS",
    cp: 1286,
    provincia_id: 2,
  },
  {
    id: 21237,
    localidad: "CABA - BARRACAS",
    cp: 1285,
    provincia_id: 2,
  },
  {
    id: 21238,
    localidad: "CABA - BARRACAS",
    cp: 1284,
    provincia_id: 2,
  },
  {
    id: 21239,
    localidad: "CABA - BARRACAS",
    cp: 1280,
    provincia_id: 2,
  },
  {
    id: 21240,
    localidad: "CABA - BARRACAS",
    cp: 1279,
    provincia_id: 2,
  },
  {
    id: 21241,
    localidad: "CABA - BARRACAS",
    cp: 1277,
    provincia_id: 2,
  },
  {
    id: 21242,
    localidad: "CABA - BALVANERA",
    cp: 1190,
    provincia_id: 2,
  },
  {
    id: 21243,
    localidad: "CABA - BALVANERA",
    cp: 1223,
    provincia_id: 2,
  },
  {
    id: 21244,
    localidad: "CABA - BALVANERA",
    cp: 1173,
    provincia_id: 2,
  },
  {
    id: 21245,
    localidad: "CABA - BALVANERA",
    cp: 1211,
    provincia_id: 2,
  },
  {
    id: 21246,
    localidad: "CABA - BALVANERA",
    cp: 1213,
    provincia_id: 2,
  },
  {
    id: 21247,
    localidad: "CABA - BALVANERA",
    cp: 1206,
    provincia_id: 2,
  },
  {
    id: 21248,
    localidad: "CABA - BALVANERA",
    cp: 1214,
    provincia_id: 2,
  },
  {
    id: 21249,
    localidad: "CABA - BALVANERA",
    cp: 1188,
    provincia_id: 2,
  },
  {
    id: 21250,
    localidad: "CABA - BALVANERA",
    cp: 1186,
    provincia_id: 2,
  },
  {
    id: 21251,
    localidad: "CABA - BALVANERA",
    cp: 1027,
    provincia_id: 2,
  },
  {
    id: 21252,
    localidad: "CABA - BALVANERA",
    cp: 1187,
    provincia_id: 2,
  },
  {
    id: 21253,
    localidad: "CABA - BALVANERA",
    cp: 1030,
    provincia_id: 2,
  },
  {
    id: 21254,
    localidad: "CABA - BALVANERA",
    cp: 1203,
    provincia_id: 2,
  },
  {
    id: 21255,
    localidad: "CABA - BALVANERA",
    cp: 1209,
    provincia_id: 2,
  },
  {
    id: 21256,
    localidad: "CABA - BALVANERA",
    cp: 1026,
    provincia_id: 2,
  },
  {
    id: 21257,
    localidad: "CABA - BALVANERA",
    cp: 1082,
    provincia_id: 2,
  },
  {
    id: 21258,
    localidad: "CABA - BALVANERA",
    cp: 1081,
    provincia_id: 2,
  },
  {
    id: 21259,
    localidad: "CABA - BALVANERA",
    cp: 1207,
    provincia_id: 2,
  },
  {
    id: 21260,
    localidad: "CABA - BALVANERA",
    cp: 1028,
    provincia_id: 2,
  },
  {
    id: 21261,
    localidad: "CABA - BALVANERA",
    cp: 1215,
    provincia_id: 2,
  },
  {
    id: 21262,
    localidad: "CABA - BALVANERA",
    cp: 1050,
    provincia_id: 2,
  },
  {
    id: 21263,
    localidad: "CABA - BALVANERA",
    cp: 1193,
    provincia_id: 2,
  },
  {
    id: 21264,
    localidad: "CABA - BALVANERA",
    cp: 1032,
    provincia_id: 2,
  },
  {
    id: 21265,
    localidad: "CABA - BALVANERA",
    cp: 1083,
    provincia_id: 2,
  },
  {
    id: 21266,
    localidad: "CABA - BALVANERA",
    cp: 1044,
    provincia_id: 2,
  },
  {
    id: 21267,
    localidad: "CABA - BALVANERA",
    cp: 1045,
    provincia_id: 2,
  },
  {
    id: 21268,
    localidad: "CABA - BALVANERA",
    cp: 1046,
    provincia_id: 2,
  },
  {
    id: 21269,
    localidad: "CABA - BALVANERA",
    cp: 1039,
    provincia_id: 2,
  },
  {
    id: 21270,
    localidad: "CABA - BALVANERA",
    cp: 1031,
    provincia_id: 2,
  },
  {
    id: 21271,
    localidad: "CABA - BALVANERA",
    cp: 1040,
    provincia_id: 2,
  },
  {
    id: 21272,
    localidad: "CABA - BALVANERA",
    cp: 1051,
    provincia_id: 2,
  },
  {
    id: 21273,
    localidad: "CABA - BALVANERA",
    cp: 1052,
    provincia_id: 2,
  },
  {
    id: 21274,
    localidad: "CABA - BALVANERA",
    cp: 1023,
    provincia_id: 2,
  },
  {
    id: 21275,
    localidad: "CABA - BALVANERA",
    cp: 1024,
    provincia_id: 2,
  },
  {
    id: 21276,
    localidad: "CABA - BALVANERA",
    cp: 1029,
    provincia_id: 2,
  },
  {
    id: 21277,
    localidad: "CABA - BALVANERA",
    cp: 1090,
    provincia_id: 2,
  },
  {
    id: 21278,
    localidad: "CABA - BALVANERA",
    cp: 1080,
    provincia_id: 2,
  },
  {
    id: 21279,
    localidad: "CABA - BALVANERA",
    cp: 1208,
    provincia_id: 2,
  },
  {
    id: 21280,
    localidad: "CABA - BALVANERA",
    cp: 1198,
    provincia_id: 2,
  },
  {
    id: 21281,
    localidad: "CABA - BALVANERA",
    cp: 1170,
    provincia_id: 2,
  },
  {
    id: 21282,
    localidad: "CABA - BALVANERA",
    cp: 1171,
    provincia_id: 2,
  },
  {
    id: 21283,
    localidad: "CABA - BALVANERA",
    cp: 1034,
    provincia_id: 2,
  },
  {
    id: 21284,
    localidad: "CABA - BALVANERA",
    cp: 1224,
    provincia_id: 2,
  },
  {
    id: 21285,
    localidad: "CABA - BALVANERA",
    cp: 1189,
    provincia_id: 2,
  },
  {
    id: 21286,
    localidad: "CABA - BALVANERA",
    cp: 1094,
    provincia_id: 2,
  },
  {
    id: 21287,
    localidad: "CABA - ALMAGRO",
    cp: 1194,
    provincia_id: 2,
  },
  {
    id: 21288,
    localidad: "CABA - ALMAGRO",
    cp: 1199,
    provincia_id: 2,
  },
  {
    id: 21289,
    localidad: "CABA - ALMAGRO",
    cp: 1200,
    provincia_id: 2,
  },
  {
    id: 21290,
    localidad: "CABA - ALMAGRO",
    cp: 1172,
    provincia_id: 2,
  },
  {
    id: 21291,
    localidad: "CABA - ALMAGRO",
    cp: 1191,
    provincia_id: 2,
  },
  {
    id: 21292,
    localidad: "CABA - ALMAGRO",
    cp: 1177,
    provincia_id: 2,
  },
  {
    id: 21293,
    localidad: "CABA - ALMAGRO",
    cp: 1192,
    provincia_id: 2,
  },
  {
    id: 21294,
    localidad: "CABA - ALMAGRO",
    cp: 1210,
    provincia_id: 2,
  },
  {
    id: 21295,
    localidad: "CABA - ALMAGRO",
    cp: 1257,
    provincia_id: 2,
  },
  {
    id: 21296,
    localidad: "CABA - ALMAGRO",
    cp: 1217,
    provincia_id: 2,
  },
  {
    id: 21297,
    localidad: "CABA - ALMAGRO",
    cp: 1218,
    provincia_id: 2,
  },
  {
    id: 21298,
    localidad: "CABA - ALMAGRO",
    cp: 1234,
    provincia_id: 2,
  },
  {
    id: 21299,
    localidad: "CABA - ALMAGRO",
    cp: 1195,
    provincia_id: 2,
  },
  {
    id: 21300,
    localidad: "CABA - ALMAGRO",
    cp: 1196,
    provincia_id: 2,
  },
  {
    id: 21301,
    localidad: "CABA - ALMAGRO",
    cp: 1197,
    provincia_id: 2,
  },
  {
    id: 21302,
    localidad: "CABA - ALMAGRO",
    cp: 1201,
    provincia_id: 2,
  },
  {
    id: 21303,
    localidad: "CABA - ALMAGRO",
    cp: 1202,
    provincia_id: 2,
  },
  {
    id: 21304,
    localidad: "CABA - ALMAGRO",
    cp: 1216,
    provincia_id: 2,
  },
  {
    id: 21305,
    localidad: "CABA - ALMAGRO",
    cp: 1205,
    provincia_id: 2,
  },
  {
    id: 21306,
    localidad: "CABA - ALMAGRO",
    cp: 1178,
    provincia_id: 2,
  },
  {
    id: 21307,
    localidad: "CABA - ALMAGRO",
    cp: 1212,
    provincia_id: 2,
  },
  {
    id: 21308,
    localidad: "CABA - ALMAGRO",
    cp: 1174,
    provincia_id: 2,
  },
  {
    id: 21309,
    localidad: "CABA - ALMAGRO",
    cp: 1183,
    provincia_id: 2,
  },
  {
    id: 21310,
    localidad: "CABA - ALMAGRO",
    cp: 1179,
    provincia_id: 2,
  },
  {
    id: 21311,
    localidad: "CABA - ALMAGRO",
    cp: 1250,
    provincia_id: 2,
  },
  {
    id: 21312,
    localidad: "CABA - ALMAGRO",
    cp: 1185,
    provincia_id: 2,
  },
  {
    id: 21313,
    localidad: "CABA - ALMAGRO",
    cp: 1204,
    provincia_id: 2,
  },
  {
    id: 21314,
    localidad: "CABA - ALMAGRO",
    cp: 1175,
    provincia_id: 2,
  },
  {
    id: 21315,
    localidad: "CABA - ALMAGRO",
    cp: 1184,
    provincia_id: 2,
  },
  {
    id: 21316,
    localidad: "CABA - ALMAGRO",
    cp: 1182,
    provincia_id: 2,
  },
  {
    id: 21317,
    localidad: "CABA - ALMAGRO",
    cp: 1181,
    provincia_id: 2,
  },
  {
    id: 21318,
    localidad: "CABA - ALMAGRO",
    cp: 1180,
    provincia_id: 2,
  },
  {
    id: 21319,
    localidad: "CABA - ALMAGRO",
    cp: 1176,
    provincia_id: 2,
  },
  {
    id: 21320,
    localidad: "ZUBIAURRE",
    cp: 8153,
    provincia_id: 1,
  },
  {
    id: 21321,
    localidad: "ZUBIARRE",
    cp: 8153,
    provincia_id: 1,
  },
  {
    id: 21322,
    localidad: "ZOILO PERALTA",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 21323,
    localidad: "ZENTENA",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 21324,
    localidad: "ZENON VIDELA DORNA",
    cp: 7226,
    provincia_id: 1,
  },
  {
    id: 21325,
    localidad: "ZELAYA",
    cp: 1627,
    provincia_id: 1,
  },
  {
    id: 21326,
    localidad: "ZELAYA",
    cp: 2020,
    provincia_id: 1,
  },
  {
    id: 21327,
    localidad: "ZEBALLOS",
    cp: 1863,
    provincia_id: 1,
  },
  {
    id: 21328,
    localidad: "ZAVALIA",
    cp: 6018,
    provincia_id: 1,
  },
  {
    id: 21329,
    localidad: "ZARATE",
    cp: 2800,
    provincia_id: 1,
  },
  {
    id: 21330,
    localidad: "ZAPIOLA",
    cp: 7249,
    provincia_id: 1,
  },
  {
    id: 21331,
    localidad: "ZANJON DEL PESCADO",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 21332,
    localidad: "Z DELTA S FERNANDO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 21333,
    localidad: "YUTUYACO",
    cp: 6443,
    provincia_id: 1,
  },
  {
    id: 21334,
    localidad: "YRAZOZ",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 21335,
    localidad: "YERBAS",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 21336,
    localidad: "WILLIAM MORRIS",
    cp: 1686,
    provincia_id: 1,
  },
  {
    id: 21337,
    localidad: "WILDE",
    cp: 1875,
    provincia_id: 1,
  },
  {
    id: 21338,
    localidad: "WARNES",
    cp: 6637,
    provincia_id: 1,
  },
  {
    id: 21339,
    localidad: "VUELTA DE ZAPATA",
    cp: 6435,
    provincia_id: 1,
  },
  {
    id: 21340,
    localidad: "VUELTA DE OBLIGADO",
    cp: 2948,
    provincia_id: 1,
  },
  {
    id: 21341,
    localidad: "VOLUNTAD",
    cp: 7412,
    provincia_id: 1,
  },
  {
    id: 21342,
    localidad: "VOLTA",
    cp: 6064,
    provincia_id: 1,
  },
  {
    id: 21343,
    localidad: "VIVORATA",
    cp: 7798,
    provincia_id: 1,
  },
  {
    id: 21344,
    localidad: "VIVORATA",
    cp: 7612,
    provincia_id: 1,
  },
  {
    id: 21345,
    localidad: "VIVERO",
    cp: 6128,
    provincia_id: 1,
  },
  {
    id: 21346,
    localidad: "VITEL",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 21347,
    localidad: "VISTA ALEGRE",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 21348,
    localidad: "VIRREYES",
    cp: 1645,
    provincia_id: 1,
  },
  {
    id: 21349,
    localidad: "VIRREY DEL PINO",
    cp: 1764,
    provincia_id: 1,
  },
  {
    id: 21350,
    localidad: "VIRREY DEL PINO",
    cp: 1763,
    provincia_id: 1,
  },
  {
    id: 21351,
    localidad: "VIÑA",
    cp: 2755,
    provincia_id: 1,
  },
  {
    id: 21352,
    localidad: "VILLARS",
    cp: 1731,
    provincia_id: 1,
  },
  {
    id: 21353,
    localidad: "VILLANUEVA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 21354,
    localidad: "VILLALONGA",
    cp: 8512,
    provincia_id: 1,
  },
  {
    id: 21355,
    localidad: "VILLAIGRILLO",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21356,
    localidad: "VILLAFAQE",
    cp: 6740,
    provincia_id: 1,
  },
  {
    id: 21357,
    localidad: "VILLA ZAGALA",
    cp: 1651,
    provincia_id: 1,
  },
  {
    id: 21358,
    localidad: "VILLA YORK",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21359,
    localidad: "VILLA YAPEYU",
    cp: 3000,
    provincia_id: 1,
  },
  {
    id: 21360,
    localidad: "VILLA VIGNOLO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 21361,
    localidad: "VILLA VATTEONE",
    cp: 1853,
    provincia_id: 1,
  },
  {
    id: 21362,
    localidad: "VILLA TRINIDAD",
    cp: 2346,
    provincia_id: 1,
  },
  {
    id: 21363,
    localidad: "VILLA TRIANGULO",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21364,
    localidad: "VILLA TRANSRADIO",
    cp: 1776,
    provincia_id: 1,
  },
  {
    id: 21365,
    localidad: "VILLA TESSEI",
    cp: 1689,
    provincia_id: 1,
  },
  {
    id: 21366,
    localidad: "VILLA TESEY",
    cp: 1688,
    provincia_id: 1,
  },
  {
    id: 21367,
    localidad: "VILLA TERESA",
    cp: 2944,
    provincia_id: 1,
  },
  {
    id: 21368,
    localidad: "VILLA TALLERES",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21369,
    localidad: "VILLA SOLDATI",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21370,
    localidad: "VILLA SERRA",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 21371,
    localidad: "VILLA SENA",
    cp: 6403,
    provincia_id: 1,
  },
  {
    id: 21372,
    localidad: "VILLA SAUCE",
    cp: 6235,
    provincia_id: 1,
  },
  {
    id: 21373,
    localidad: "VILLA SARITA",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21374,
    localidad: "VILLA SANZ",
    cp: 6511,
    provincia_id: 1,
  },
  {
    id: 21375,
    localidad: "VILLA SANTOS TESEI",
    cp: 1688,
    provincia_id: 1,
  },
  {
    id: 21376,
    localidad: "VILLA SANGUINETTI",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 21377,
    localidad: "VILLA SANCHEZ ELIA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21378,
    localidad: "VILLA SAN PEDRO",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 21379,
    localidad: "VILLA SAN JOSE",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 21380,
    localidad: "VILLA SAN ALBERTO",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 21381,
    localidad: "VILLA SAENZ PEÑA",
    cp: 1674,
    provincia_id: 1,
  },
  {
    id: 21382,
    localidad: "VILLA SABOYA",
    cp: 6139,
    provincia_id: 1,
  },
  {
    id: 21383,
    localidad: "VILLA SABOYA",
    cp: 6102,
    provincia_id: 1,
  },
  {
    id: 21384,
    localidad: "VILLA SABOYA",
    cp: 6101,
    provincia_id: 1,
  },
  {
    id: 21385,
    localidad: "VILLA RUIZ",
    cp: 6705,
    provincia_id: 1,
  },
  {
    id: 21386,
    localidad: "VILLA ROSAS",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 21387,
    localidad: "VILLA ROSA",
    cp: 1631,
    provincia_id: 1,
  },
  {
    id: 21388,
    localidad: "VILLA ROCH",
    cp: 7101,
    provincia_id: 1,
  },
  {
    id: 21389,
    localidad: "VILLA RIO CHICO",
    cp: 8146,
    provincia_id: 1,
  },
  {
    id: 21390,
    localidad: "VILLA RAMALLO",
    cp: 2915,
    provincia_id: 1,
  },
  {
    id: 21391,
    localidad: "VILLA RAMALLO",
    cp: 2914,
    provincia_id: 1,
  },
  {
    id: 21392,
    localidad: "VILLA RAFFO",
    cp: 1675,
    provincia_id: 1,
  },
  {
    id: 21393,
    localidad: "VILLA PUEBLO NUEVO",
    cp: 7414,
    provincia_id: 1,
  },
  {
    id: 21394,
    localidad: "VILLA PROGRESO",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21395,
    localidad: "VILLA PROGRESO",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 21396,
    localidad: "VILLA PENOTTI",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21397,
    localidad: "VILLA ORTIZ",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 21398,
    localidad: "VILLA ORTEGA",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21399,
    localidad: "VILLA OLGA GRUMBEIN",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21400,
    localidad: "VILLA OBRERA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21401,
    localidad: "VILLA NUMANCIA",
    cp: 1858,
    provincia_id: 1,
  },
  {
    id: 21402,
    localidad: "VILLA NOCITO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21403,
    localidad: "VILLA MOQUEHUA",
    cp: 6625,
    provincia_id: 1,
  },
  {
    id: 21404,
    localidad: "VILLA MONICA",
    cp: 7318,
    provincia_id: 1,
  },
  {
    id: 21405,
    localidad: "VILLA MITRE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21406,
    localidad: "VILLA MAZA",
    cp: 6343,
    provincia_id: 1,
  },
  {
    id: 21407,
    localidad: "VILLA MAYOR",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21408,
    localidad: "VILLA MARTELLI",
    cp: 1603,
    provincia_id: 1,
  },
  {
    id: 21409,
    localidad: "VILLA MARIA",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 21410,
    localidad: "VILLA MAIPU",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 21411,
    localidad: "VILLA MAIO",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 21412,
    localidad: "VILLA LYNCH",
    cp: 1672,
    provincia_id: 1,
  },
  {
    id: 21413,
    localidad: "VILLA LUZURIAGA",
    cp: 1753,
    provincia_id: 1,
  },
  {
    id: 21414,
    localidad: "VILLA LORETO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21415,
    localidad: "VILLA LOMA",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 21416,
    localidad: "VILLA LIBRE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21417,
    localidad: "VILLA LIBERTAD",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 21418,
    localidad: "VILLA LIA",
    cp: 2761,
    provincia_id: 1,
  },
  {
    id: 21419,
    localidad: "VILLA LEZA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21420,
    localidad: "VILLA LEANDRA",
    cp: 2946,
    provincia_id: 1,
  },
  {
    id: 21421,
    localidad: "VILLA LAZA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21422,
    localidad: "VILLA LAURA",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 21423,
    localidad: "VILLA LA FLORIDA",
    cp: 1855,
    provincia_id: 1,
  },
  {
    id: 21424,
    localidad: "VILLA LA FLORIDA",
    cp: 1881,
    provincia_id: 1,
  },
  {
    id: 21425,
    localidad: "VILLA ITALIA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21426,
    localidad: "VILLA ITALIA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21427,
    localidad: "VILLA IRIS",
    cp: 8126,
    provincia_id: 1,
  },
  {
    id: 21428,
    localidad: "VILLA INSUPERABLE",
    cp: 1751,
    provincia_id: 1,
  },
  {
    id: 21429,
    localidad: "VILLA IGOLLO",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21430,
    localidad: "VILLA HERMINIA",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 21431,
    localidad: "VILLA GODOY",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21432,
    localidad: "VILLA GOBERNADOR UDAONDO",
    cp: 1713,
    provincia_id: 1,
  },
  {
    id: 21433,
    localidad: "VILLA GESELL",
    cp: 7165,
    provincia_id: 1,
  },
  {
    id: 21434,
    localidad: "VILLA GENERAL SAVIO",
    cp: 2913,
    provincia_id: 1,
  },
  {
    id: 21435,
    localidad: "VILLA GENERAL ARIAS",
    cp: 8050,
    provincia_id: 1,
  },
  {
    id: 21436,
    localidad: "VILLA GALICIA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21437,
    localidad: "VILLA FRANCIA",
    cp: 6706,
    provincia_id: 1,
  },
  {
    id: 21438,
    localidad: "VILLA FRANCIA",
    cp: 6058,
    provincia_id: 1,
  },
  {
    id: 21439,
    localidad: "VILLA FORTABAT",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 21440,
    localidad: "VILLA FLORESTA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21441,
    localidad: "VILLA FLANDRIA",
    cp: 6706,
    provincia_id: 1,
  },
  {
    id: 21442,
    localidad: "VILLA FIORITO",
    cp: 1831,
    provincia_id: 1,
  },
  {
    id: 21443,
    localidad: "VILLA ESPIL",
    cp: 6712,
    provincia_id: 1,
  },
  {
    id: 21444,
    localidad: "VILLA ESPAÑA",
    cp: 1860,
    provincia_id: 1,
  },
  {
    id: 21445,
    localidad: "VILLA ELISA",
    cp: 1894,
    provincia_id: 1,
  },
  {
    id: 21446,
    localidad: "VILLA ELENA",
    cp: 8512,
    provincia_id: 1,
  },
  {
    id: 21447,
    localidad: "VILLA DUFAU",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21448,
    localidad: "VILLA DON BOSCO",
    cp: 3000,
    provincia_id: 1,
  },
  {
    id: 21449,
    localidad: "VILLA DOMINICO",
    cp: 1874,
    provincia_id: 1,
  },
  {
    id: 21450,
    localidad: "VILLA DIAZ VELEZ",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 21451,
    localidad: "VILLA DIAMANTINA",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 21452,
    localidad: "VILLA DIAMANTE",
    cp: 1822,
    provincia_id: 1,
  },
  {
    id: 21453,
    localidad: "VILLA DEPIETRI",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21454,
    localidad: "VILLA DELFINA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21455,
    localidad: "VILLA DEL MAR",
    cp: 8071,
    provincia_id: 1,
  },
  {
    id: 21456,
    localidad: "VILLA DE MAYO",
    cp: 1614,
    provincia_id: 1,
  },
  {
    id: 21457,
    localidad: "VILLA DAZA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21458,
    localidad: "VILLA DA FONTE",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 21459,
    localidad: "VILLA COPACABANA",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 21460,
    localidad: "VILLA CLELIA",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 21461,
    localidad: "VILLA CERRITO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21462,
    localidad: "VILLA CENTENARIO",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21463,
    localidad: "VILLA CELINA",
    cp: 1772,
    provincia_id: 1,
  },
  {
    id: 21464,
    localidad: "VILLA CARUCHA",
    cp: 7505,
    provincia_id: 1,
  },
  {
    id: 21465,
    localidad: "VILLA CAROLA",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 21466,
    localidad: "VILLA CARAZA",
    cp: 1822,
    provincia_id: 1,
  },
  {
    id: 21467,
    localidad: "VILLA CACIQUE BARKER",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 21468,
    localidad: "VILLA CACIQUE",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 21469,
    localidad: "VILLA BURGOS",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 21470,
    localidad: "VILLA BUENOS AIRES",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21471,
    localidad: "VILLA BROWN",
    cp: 1892,
    provincia_id: 1,
  },
  {
    id: 21472,
    localidad: "VILLA BOSCH",
    cp: 1682,
    provincia_id: 1,
  },
  {
    id: 21473,
    localidad: "VILLA BORDEAU",
    cp: 8001,
    provincia_id: 1,
  },
  {
    id: 21474,
    localidad: "VILLA BALLESTER",
    cp: 1653,
    provincia_id: 1,
  },
  {
    id: 21475,
    localidad: "VILLA ASTOLFI",
    cp: 1633,
    provincia_id: 1,
  },
  {
    id: 21476,
    localidad: "VILLA ASTOLFI",
    cp: 2032,
    provincia_id: 1,
  },
  {
    id: 21477,
    localidad: "VILLA ARCADIA",
    cp: 7540,
    provincia_id: 1,
  },
  {
    id: 21478,
    localidad: "VILLA ALDEANITA",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 21479,
    localidad: "VILLA ALBERTINA",
    cp: 1829,
    provincia_id: 1,
  },
  {
    id: 21480,
    localidad: "VILLA ADRIANA",
    cp: 1816,
    provincia_id: 1,
  },
  {
    id: 21481,
    localidad: "VILLA ADELINA",
    cp: 1607,
    provincia_id: 1,
  },
  {
    id: 21482,
    localidad: "VILELA",
    cp: 7208,
    provincia_id: 1,
  },
  {
    id: 21483,
    localidad: "VIGELENCIA",
    cp: 6070,
    provincia_id: 1,
  },
  {
    id: 21484,
    localidad: "VIEYTES",
    cp: 1915,
    provincia_id: 1,
  },
  {
    id: 21485,
    localidad: "VICTORINO DE LA PLAZA",
    cp: 6411,
    provincia_id: 1,
  },
  {
    id: 21486,
    localidad: "VICTORIA",
    cp: 1644,
    provincia_id: 1,
  },
  {
    id: 21487,
    localidad: "VICENTE PEREDA",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 21488,
    localidad: "VICENTE LOPEZ",
    cp: 1638,
    provincia_id: 1,
  },
  {
    id: 21489,
    localidad: "VICENTE CASARES",
    cp: 1808,
    provincia_id: 1,
  },
  {
    id: 21490,
    localidad: "VIBORAS",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 21491,
    localidad: "VERONICA",
    cp: 1917,
    provincia_id: 1,
  },
  {
    id: 21492,
    localidad: "VERGARA",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 21493,
    localidad: "VENANCIO",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 21494,
    localidad: "VELLOSO",
    cp: 7305,
    provincia_id: 1,
  },
  {
    id: 21495,
    localidad: "VELA",
    cp: 7003,
    provincia_id: 1,
  },
  {
    id: 21496,
    localidad: "VEINTICINCO DE MAYO",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 21497,
    localidad: "VEDIA",
    cp: 6030,
    provincia_id: 1,
  },
  {
    id: 21498,
    localidad: "VECINO",
    cp: 7118,
    provincia_id: 1,
  },
  {
    id: 21499,
    localidad: "VASQUEZ",
    cp: 7519,
    provincia_id: 1,
  },
  {
    id: 21500,
    localidad: "VAQA",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 21501,
    localidad: "VALLIMANCA",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 21502,
    localidad: "VALLE DAZA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 21503,
    localidad: "VALLE ARGENTINO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 21504,
    localidad: "VALERIA DEL MAR",
    cp: 7166,
    provincia_id: 1,
  },
  {
    id: 21505,
    localidad: "VALERIA DEL MAR",
    cp: 7367,
    provincia_id: 1,
  },
  {
    id: 21506,
    localidad: "VALENZUELA ANTON",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 21507,
    localidad: "VALENTIN ALSINA",
    cp: 1822,
    provincia_id: 1,
  },
  {
    id: 21508,
    localidad: "VALDEZ",
    cp: 6717,
    provincia_id: 1,
  },
  {
    id: 21509,
    localidad: "VALDES",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 21510,
    localidad: "VAGUES",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 21511,
    localidad: "V RAMALLO E FFCC",
    cp: 2914,
    provincia_id: 1,
  },
  {
    id: 21512,
    localidad: "V PUERTO QUEQUEN",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 21513,
    localidad: "V OLGA GRUMBEIN",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21514,
    localidad: "V LYNCH PUEYRREDON",
    cp: 6553,
    provincia_id: 1,
  },
  {
    id: 21515,
    localidad: "V HARDING GREEN",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 21516,
    localidad: "V GRAL S EX SCHEZ",
    cp: 2913,
    provincia_id: 1,
  },
  {
    id: 21517,
    localidad: "V GENERAL ARIAS",
    cp: 8050,
    provincia_id: 1,
  },
  {
    id: 21518,
    localidad: "V DOMINGO PRONSATO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21519,
    localidad: "V BELG. DTO JUNIN",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21520,
    localidad: "URQUIZA",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 21521,
    localidad: "URQUIZA",
    cp: 2261,
    provincia_id: 1,
  },
  {
    id: 21522,
    localidad: "URIBELARREA",
    cp: 1815,
    provincia_id: 1,
  },
  {
    id: 21523,
    localidad: "URDAMPILLETA",
    cp: 6553,
    provincia_id: 1,
  },
  {
    id: 21524,
    localidad: "UNION FERROVIARIA",
    cp: 1804,
    provincia_id: 1,
  },
  {
    id: 21525,
    localidad: "UDAQUIOLA",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 21526,
    localidad: "UBALLES",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 21527,
    localidad: "U TUR CHAPADMALAL",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21528,
    localidad: "TUYUTI",
    cp: 6721,
    provincia_id: 1,
  },
  {
    id: 21529,
    localidad: "TURDERA",
    cp: 1833,
    provincia_id: 1,
  },
  {
    id: 21530,
    localidad: "TRUJUI",
    cp: 1664,
    provincia_id: 1,
  },
  {
    id: 21531,
    localidad: "TRUJUI",
    cp: 1736,
    provincia_id: 1,
  },
  {
    id: 21532,
    localidad: "TROPEZON",
    cp: 6501,
    provincia_id: 1,
  },
  {
    id: 21533,
    localidad: "TRONCOS DEL TALAR",
    cp: 1608,
    provincia_id: 1,
  },
  {
    id: 21534,
    localidad: "TRIUNVIRATO",
    cp: 6071,
    provincia_id: 1,
  },
  {
    id: 21535,
    localidad: "TRISTAN SUAREZ",
    cp: 1806,
    provincia_id: 1,
  },
  {
    id: 21536,
    localidad: "TRIGALES",
    cp: 6053,
    provincia_id: 1,
  },
  {
    id: 21537,
    localidad: "TRES SARGENTOS",
    cp: 6727,
    provincia_id: 1,
  },
  {
    id: 21538,
    localidad: "TRES PICOS",
    cp: 8162,
    provincia_id: 1,
  },
  {
    id: 21539,
    localidad: "TRES LOMAS",
    cp: 6409,
    provincia_id: 1,
  },
  {
    id: 21540,
    localidad: "TRES LEGUAS",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 21541,
    localidad: "TRES DE FEBRERO",
    cp: 1652,
    provincia_id: 1,
  },
  {
    id: 21542,
    localidad: "TRES CUERVOS",
    cp: 8183,
    provincia_id: 1,
  },
  {
    id: 21543,
    localidad: "TRES ARROYOS",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 21544,
    localidad: "TRES ALGARROBOS",
    cp: 6231,
    provincia_id: 1,
  },
  {
    id: 21545,
    localidad: "TRES  LAGUNAS",
    cp: 6443,
    provincia_id: 1,
  },
  {
    id: 21546,
    localidad: "TRENQUE LAUQUEN",
    cp: 6400,
    provincia_id: 1,
  },
  {
    id: 21547,
    localidad: "TREINTA DE AGOSTO",
    cp: 6405,
    provincia_id: 1,
  },
  {
    id: 21548,
    localidad: "TORTUGUITAS",
    cp: 1667,
    provincia_id: 1,
  },
  {
    id: 21549,
    localidad: "TORRES",
    cp: 6703,
    provincia_id: 1,
  },
  {
    id: 21550,
    localidad: "TORNQUIST",
    cp: 8160,
    provincia_id: 1,
  },
  {
    id: 21551,
    localidad: "TORDILLO",
    cp: 7101,
    provincia_id: 1,
  },
  {
    id: 21552,
    localidad: "TOMAS JOFRE",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 21553,
    localidad: "TOLOSA",
    cp: 1900,
    provincia_id: 1,
  },
  {
    id: 21554,
    localidad: "TOLDOS VIEJOS",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 21555,
    localidad: "TODD",
    cp: 2754,
    provincia_id: 1,
  },
  {
    id: 21556,
    localidad: "TOBAS",
    cp: 3752,
    provincia_id: 1,
  },
  {
    id: 21557,
    localidad: "TNTE CNEL MIQANA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 21558,
    localidad: "TIO DOMINGO",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21559,
    localidad: "TIMOTE",
    cp: 6457,
    provincia_id: 1,
  },
  {
    id: 21560,
    localidad: "TIGRE",
    cp: 1648,
    provincia_id: 1,
  },
  {
    id: 21561,
    localidad: "TIGRE",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 21562,
    localidad: "THAMES",
    cp: 6343,
    provincia_id: 1,
  },
  {
    id: 21563,
    localidad: "TENIENTE ORIGONE",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 21564,
    localidad: "TEMPERLEY",
    cp: 1834,
    provincia_id: 1,
  },
  {
    id: 21565,
    localidad: "TEMPERLEY",
    cp: 1809,
    provincia_id: 1,
  },
  {
    id: 21566,
    localidad: "TEMPERLEY",
    cp: 1835,
    provincia_id: 1,
  },
  {
    id: 21567,
    localidad: "TEJO GALETA",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 21568,
    localidad: "TEDIN URIBURU",
    cp: 7066,
    provincia_id: 1,
  },
  {
    id: 21569,
    localidad: "TATAY",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 21570,
    localidad: "TARRAGONA",
    cp: 6103,
    provincia_id: 1,
  },
  {
    id: 21571,
    localidad: "TAPIALES",
    cp: 1770,
    provincia_id: 1,
  },
  {
    id: 21572,
    localidad: "TAPALQUE",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 21573,
    localidad: "TANDIL",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 21574,
    localidad: "TANDIL",
    cp: 7001,
    provincia_id: 1,
  },
  {
    id: 21575,
    localidad: "TAMANGUEYU",
    cp: 7633,
    provincia_id: 1,
  },
  {
    id: 21576,
    localidad: "TACUREL",
    cp: 6123,
    provincia_id: 1,
  },
  {
    id: 21577,
    localidad: "TACUARI",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 21578,
    localidad: "TABLADA",
    cp: 1766,
    provincia_id: 1,
  },
  {
    id: 21579,
    localidad: "SUNDBLAD",
    cp: 6401,
    provincia_id: 1,
  },
  {
    id: 21580,
    localidad: "SUIPACHA",
    cp: 6612,
    provincia_id: 1,
  },
  {
    id: 21581,
    localidad: "STROEDER",
    cp: 8508,
    provincia_id: 1,
  },
  {
    id: 21582,
    localidad: "STEGMANN",
    cp: 7536,
    provincia_id: 1,
  },
  {
    id: 21583,
    localidad: "SPURR",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 21584,
    localidad: "SPERONI",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21585,
    localidad: "SPERATTI",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 21586,
    localidad: "SOURIGUES",
    cp: 1837,
    provincia_id: 1,
  },
  {
    id: 21587,
    localidad: "SOLIS",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 21588,
    localidad: "SOLIS",
    cp: 2763,
    provincia_id: 1,
  },
  {
    id: 21589,
    localidad: "SOLANO",
    cp: 1843,
    provincia_id: 1,
  },
  {
    id: 21590,
    localidad: "SOLANET",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 21591,
    localidad: "SOL DE MAYO",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 21592,
    localidad: "SOL DE MAYO",
    cp: 2709,
    provincia_id: 1,
  },
  {
    id: 21593,
    localidad: "SMITH",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 21594,
    localidad: "SMITH",
    cp: 6536,
    provincia_id: 1,
  },
  {
    id: 21595,
    localidad: "SIMBOL BAJO",
    cp: 3760,
    provincia_id: 1,
  },
  {
    id: 21596,
    localidad: "SIERRAS BAYAS",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 21597,
    localidad: "SIERRA DE LOS PADRES",
    cp: 7750,
    provincia_id: 1,
  },
  {
    id: 21598,
    localidad: "SIERRA DE LOS PADRES",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 21599,
    localidad: "SIERRA DE LA VENTANA",
    cp: 8168,
    provincia_id: 1,
  },
  {
    id: 21600,
    localidad: "SIERRA CHICA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 21601,
    localidad: "SIERRA CHICA",
    cp: 7421,
    provincia_id: 1,
  },
  {
    id: 21602,
    localidad: "SIEMPRE VERDE",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21603,
    localidad: "SHAW",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 21604,
    localidad: "SEVIGNE",
    cp: 7186,
    provincia_id: 1,
  },
  {
    id: 21605,
    localidad: "SEMINARIO PIO XII",
    cp: 6600,
    provincia_id: 1,
  },
  {
    id: 21606,
    localidad: "SEGUROLA",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 21607,
    localidad: "SAUZALES",
    cp: 6030,
    provincia_id: 1,
  },
  {
    id: 21608,
    localidad: "SAUCE RIO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 21609,
    localidad: "SAUCE GRANDE",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 21610,
    localidad: "SAUCE CORTO",
    cp: 7540,
    provincia_id: 1,
  },
  {
    id: 21611,
    localidad: "SAUCE CHICO",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 21612,
    localidad: "SAUCE BLANCO",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 21613,
    localidad: "SATURNO",
    cp: 6417,
    provincia_id: 1,
  },
  {
    id: 21614,
    localidad: "SARASA",
    cp: 2721,
    provincia_id: 1,
  },
  {
    id: 21615,
    localidad: "SARANDI",
    cp: 1872,
    provincia_id: 1,
  },
  {
    id: 21616,
    localidad: "SANTOS UNZUE",
    cp: 6507,
    provincia_id: 1,
  },
  {
    id: 21617,
    localidad: "SANTOS LUGARES",
    cp: 1676,
    provincia_id: 1,
  },
  {
    id: 21618,
    localidad: "SANTO TOMAS CHICO",
    cp: 6538,
    provincia_id: 1,
  },
  {
    id: 21619,
    localidad: "SANTO TOMAS",
    cp: 6530,
    provincia_id: 1,
  },
  {
    id: 21620,
    localidad: "SANTO DOMINGO",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 21621,
    localidad: "SANTIAGO LARRE",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 21622,
    localidad: "SANTIAGO GARBARINI",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 21623,
    localidad: "SANTA TRINIDAD",
    cp: 7570,
    provincia_id: 1,
  },
  {
    id: 21624,
    localidad: "SANTA TERESITA",
    cp: 7107,
    provincia_id: 1,
  },
  {
    id: 21625,
    localidad: "SANTA TERESA",
    cp: 2912,
    provincia_id: 1,
  },
  {
    id: 21626,
    localidad: "SANTA TERESA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21627,
    localidad: "SANTA ROSA",
    cp: 1867,
    provincia_id: 1,
  },
  {
    id: 21628,
    localidad: "SANTA ROSA",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 21629,
    localidad: "SANTA RITA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21630,
    localidad: "SANTA REGINA",
    cp: 6105,
    provincia_id: 1,
  },
  {
    id: 21631,
    localidad: "SANTA REGINA",
    cp: 6138,
    provincia_id: 1,
  },
  {
    id: 21632,
    localidad: "SANTA PAULA",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 21633,
    localidad: "SANTA MARIA BELLOQ",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 21634,
    localidad: "SANTA MARIA",
    cp: 6071,
    provincia_id: 1,
  },
  {
    id: 21635,
    localidad: "SANTA LUISA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 21636,
    localidad: "SANTA LUCIA",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 21637,
    localidad: "SANTA ISABEL",
    cp: 6550,
    provincia_id: 1,
  },
  {
    id: 21638,
    localidad: "SANTA IRENE",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 21639,
    localidad: "SANTA INES",
    cp: 6360,
    provincia_id: 1,
  },
  {
    id: 21640,
    localidad: "SANTA INES",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 21641,
    localidad: "SANTA INES",
    cp: 6459,
    provincia_id: 1,
  },
  {
    id: 21642,
    localidad: "SANTA FELICIA",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 21643,
    localidad: "SANTA ELEODORA",
    cp: 6241,
    provincia_id: 1,
  },
  {
    id: 21644,
    localidad: "SANTA ELENA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 21645,
    localidad: "SANTA ELENA",
    cp: 6700,
    provincia_id: 1,
  },
  {
    id: 21646,
    localidad: "SANTA ELENA",
    cp: 7414,
    provincia_id: 1,
  },
  {
    id: 21647,
    localidad: "SANTA ELENA",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21648,
    localidad: "SANTA COLOMA",
    cp: 2761,
    provincia_id: 1,
  },
  {
    id: 21649,
    localidad: "SANTA CLEMENTINA",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 21650,
    localidad: "SANTA CLARA DEL MAR",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21651,
    localidad: "SANTA CLARA",
    cp: 6123,
    provincia_id: 1,
  },
  {
    id: 21652,
    localidad: "SANTA CECILIA SUD",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 21653,
    localidad: "SANTA CATALINA",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 21654,
    localidad: "SANTA ALICIA",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 21655,
    localidad: "SANSINENA",
    cp: 6233,
    provincia_id: 1,
  },
  {
    id: 21656,
    localidad: "SANCHEZ",
    cp: 2913,
    provincia_id: 1,
  },
  {
    id: 21657,
    localidad: "SANCHEZ",
    cp: 2912,
    provincia_id: 1,
  },
  {
    id: 21658,
    localidad: "SAN VICENTE",
    cp: 1865,
    provincia_id: 1,
  },
  {
    id: 21659,
    localidad: "SAN VALENTIN",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 21660,
    localidad: "SAN URBANO",
    cp: 2728,
    provincia_id: 1,
  },
  {
    id: 21661,
    localidad: "SAN SIMON",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 21662,
    localidad: "SAN SIMON",
    cp: 7621,
    provincia_id: 1,
  },
  {
    id: 21663,
    localidad: "SAN SEVERO",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 21664,
    localidad: "SAN SEBASTIAN",
    cp: 6623,
    provincia_id: 1,
  },
  {
    id: 21665,
    localidad: "SAN ROQUE",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 21666,
    localidad: "SAN ROMAN",
    cp: 8154,
    provincia_id: 1,
  },
  {
    id: 21667,
    localidad: "SAN ROBERTO",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 21668,
    localidad: "SAN RAMON",
    cp: 2754,
    provincia_id: 1,
  },
  {
    id: 21669,
    localidad: "SAN RAMON",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 21670,
    localidad: "SAN RAFAEL",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 21671,
    localidad: "SAN QUILCO",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 21672,
    localidad: "SAN PEDRO",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21673,
    localidad: "SAN PATRICIO",
    cp: 6734,
    provincia_id: 1,
  },
  {
    id: 21674,
    localidad: "SAN PASCUAL",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 21675,
    localidad: "SAN NICOLAS DE LOS ARROYOS",
    cp: 2902,
    provincia_id: 1,
  },
  {
    id: 21676,
    localidad: "SAN NICOLAS DE LOS ARROYOS",
    cp: 2901,
    provincia_id: 1,
  },
  {
    id: 21677,
    localidad: "SAN NICOLAS DE LOS ARROYOS",
    cp: 2900,
    provincia_id: 1,
  },
  {
    id: 21678,
    localidad: "SAN NICOLAS",
    cp: 2900,
    provincia_id: 1,
  },
  {
    id: 21679,
    localidad: "SAN MIGUEL DEL MONTE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 21680,
    localidad: "SAN MIGUEL",
    cp: 1663,
    provincia_id: 1,
  },
  {
    id: 21681,
    localidad: "SAN MAYOL",
    cp: 7519,
    provincia_id: 1,
  },
  {
    id: 21682,
    localidad: "SAN MAURICIO",
    cp: 6239,
    provincia_id: 1,
  },
  {
    id: 21683,
    localidad: "SAN MARTIN",
    cp: 1654,
    provincia_id: 1,
  },
  {
    id: 21684,
    localidad: "SAN MARTIN",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 21685,
    localidad: "SAN MANUEL",
    cp: 7048,
    provincia_id: 1,
  },
  {
    id: 21686,
    localidad: "SAN LEON",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 21687,
    localidad: "SAN LAUREANO",
    cp: 7150,
    provincia_id: 1,
  },
  {
    id: 21688,
    localidad: "SAN JUSTO",
    cp: 1754,
    provincia_id: 1,
  },
  {
    id: 21689,
    localidad: "SAN JULIAN",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 21690,
    localidad: "SAN JUAN DE NELSON",
    cp: 6530,
    provincia_id: 1,
  },
  {
    id: 21691,
    localidad: "SAN JUAN",
    cp: 2754,
    provincia_id: 1,
  },
  {
    id: 21692,
    localidad: "SAN JUAN",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 21693,
    localidad: "SAN JUAN",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 21694,
    localidad: "SAN JUAN",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 21695,
    localidad: "SAN JOSE DE GALI",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 21696,
    localidad: "SAN JOSE",
    cp: 3000,
    provincia_id: 1,
  },
  {
    id: 21697,
    localidad: "SAN JOSE",
    cp: 1844,
    provincia_id: 1,
  },
  {
    id: 21698,
    localidad: "SAN JOSE",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 21699,
    localidad: "SAN JOSE",
    cp: 1846,
    provincia_id: 1,
  },
  {
    id: 21700,
    localidad: "SAN JOSE",
    cp: 6643,
    provincia_id: 1,
  },
  {
    id: 21701,
    localidad: "SAN JOSE",
    cp: 6665,
    provincia_id: 1,
  },
  {
    id: 21702,
    localidad: "SAN JOSE",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 21703,
    localidad: "SAN JOSE",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 21704,
    localidad: "SAN JORGE",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 21705,
    localidad: "SAN JACINTO",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 21706,
    localidad: "SAN JACINTO",
    cp: 6614,
    provincia_id: 1,
  },
  {
    id: 21707,
    localidad: "SAN ISIDRO",
    cp: 1642,
    provincia_id: 1,
  },
  {
    id: 21708,
    localidad: "SAN IGNACIO",
    cp: 6360,
    provincia_id: 1,
  },
  {
    id: 21709,
    localidad: "SAN IGNACIO",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 21710,
    localidad: "SAN GERVACIO",
    cp: 7305,
    provincia_id: 1,
  },
  {
    id: 21711,
    localidad: "SAN GERMAN",
    cp: 8124,
    provincia_id: 1,
  },
  {
    id: 21712,
    localidad: "SAN FRANCISCO SOLANO",
    cp: 1881,
    provincia_id: 1,
  },
  {
    id: 21713,
    localidad: "SAN FERNANDO",
    cp: 1646,
    provincia_id: 1,
  },
  {
    id: 21714,
    localidad: "SAN FERMIN",
    cp: 6417,
    provincia_id: 1,
  },
  {
    id: 21715,
    localidad: "SAN FELIPE",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 21716,
    localidad: "SAN FEDERICO",
    cp: 2711,
    provincia_id: 1,
  },
  {
    id: 21717,
    localidad: "SAN ERNESTO",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 21718,
    localidad: "SAN ENRIQUE",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 21719,
    localidad: "SAN ENRIQUE",
    cp: 6661,
    provincia_id: 1,
  },
  {
    id: 21720,
    localidad: "SAN EMILIO",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 21721,
    localidad: "SAN EMILIO",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 21722,
    localidad: "SAN EMILIO",
    cp: 6019,
    provincia_id: 1,
  },
  {
    id: 21723,
    localidad: "SAN EMILIO",
    cp: 7004,
    provincia_id: 1,
  },
  {
    id: 21724,
    localidad: "SAN ELADIO",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 21725,
    localidad: "SAN DANIEL",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 21726,
    localidad: "SAN CORNELIO",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 21727,
    localidad: "SAN CLEMENTE DEL TUYU",
    cp: 7105,
    provincia_id: 1,
  },
  {
    id: 21728,
    localidad: "SAN CAYETANO",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 21729,
    localidad: "SAN CARLOS",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 21730,
    localidad: "SAN CARLOS",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 21731,
    localidad: "SAN CALA",
    cp: 7011,
    provincia_id: 1,
  },
  {
    id: 21732,
    localidad: "SAN BERNARDO DEL TUYU",
    cp: 7111,
    provincia_id: 1,
  },
  {
    id: 21733,
    localidad: "SAN BERNARDO",
    cp: 6561,
    provincia_id: 1,
  },
  {
    id: 21734,
    localidad: "SAN BERNARDO",
    cp: 6476,
    provincia_id: 1,
  },
  {
    id: 21735,
    localidad: "SAN BENITO",
    cp: 7261,
    provincia_id: 1,
  },
  {
    id: 21736,
    localidad: "SAN ANTONIO DE PADUA",
    cp: 1718,
    provincia_id: 1,
  },
  {
    id: 21737,
    localidad: "SAN ANTONIO DE ARECO",
    cp: 2760,
    provincia_id: 1,
  },
  {
    id: 21738,
    localidad: "SAN ANTONIO",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 21739,
    localidad: "SAN ANDRES DE GILES",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 21740,
    localidad: "SAN ANDRES",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 21741,
    localidad: "SAN ANDRES",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 21742,
    localidad: "SAN ANDRES",
    cp: 1651,
    provincia_id: 1,
  },
  {
    id: 21743,
    localidad: "SAN ALBERTO",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 21744,
    localidad: "SAN ALBERTO",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 21745,
    localidad: "SAN AGUSTIN",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 21746,
    localidad: "SAN ADOLFO",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 21747,
    localidad: "SAMBOROMBON",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 21748,
    localidad: "SAMBOROMBON",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 21749,
    localidad: "SALVADOR MARIA",
    cp: 7241,
    provincia_id: 1,
  },
  {
    id: 21750,
    localidad: "SALTO",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 21751,
    localidad: "SALLIQUELO",
    cp: 6339,
    provincia_id: 1,
  },
  {
    id: 21752,
    localidad: "SALINAS CHICAS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 21753,
    localidad: "SALINAS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 21754,
    localidad: "SALINA DE PIEDRA",
    cp: 8506,
    provincia_id: 1,
  },
  {
    id: 21755,
    localidad: "SALGUERO",
    cp: 6120,
    provincia_id: 1,
  },
  {
    id: 21756,
    localidad: "SALDUNGARAY",
    cp: 8166,
    provincia_id: 1,
  },
  {
    id: 21757,
    localidad: "SALAZAR",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 21758,
    localidad: "SALADILLO NORTE",
    cp: 7261,
    provincia_id: 1,
  },
  {
    id: 21759,
    localidad: "SALADILLO",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 21760,
    localidad: "SALADA GRANDE",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21761,
    localidad: "SALADA CHICA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21762,
    localidad: "SAFORCADA",
    cp: 6022,
    provincia_id: 1,
  },
  {
    id: 21763,
    localidad: "SAAVEDRA",
    cp: 8174,
    provincia_id: 1,
  },
  {
    id: 21764,
    localidad: "S. DE LIHUEL CALEL",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 21765,
    localidad: "S. DE LA VENTANA",
    cp: 8168,
    provincia_id: 1,
  },
  {
    id: 21766,
    localidad: "S TSITA PERGAMINO",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 21767,
    localidad: "S RITA PDO GUAMINI",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 21768,
    localidad: "S R DE MINELLONO",
    cp: 7212,
    provincia_id: 1,
  },
  {
    id: 21769,
    localidad: "S R DE ANCHORENA",
    cp: 7311,
    provincia_id: 1,
  },
  {
    id: 21770,
    localidad: "S MIGUEL DEL MORO",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 21771,
    localidad: "S MIGUEL DEL MONTE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 21772,
    localidad: "S MARTIN DE TOURS",
    cp: 8164,
    provincia_id: 1,
  },
  {
    id: 21773,
    localidad: "S JOSE DE OTAMENDI",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 21774,
    localidad: "S J DE L QUINTEROS",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 21775,
    localidad: "S GDES  ANZOATEGUI",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 21776,
    localidad: "S FCISCO D BELLOCQ",
    cp: 7520,
    provincia_id: 1,
  },
  {
    id: 21777,
    localidad: "S EDUARDO DEL MAR",
    cp: 7783,
    provincia_id: 1,
  },
  {
    id: 21778,
    localidad: "S DE LOS PADRES",
    cp: 7750,
    provincia_id: 1,
  },
  {
    id: 21779,
    localidad: "S CLEMENTE DE TUYU",
    cp: 7105,
    provincia_id: 1,
  },
  {
    id: 21780,
    localidad: "S CLARA DEL MAR",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21781,
    localidad: "S CECILIA NORTE",
    cp: 6472,
    provincia_id: 1,
  },
  {
    id: 21782,
    localidad: "S CECILIA CENTRO",
    cp: 6463,
    provincia_id: 1,
  },
  {
    id: 21783,
    localidad: "S BRDO DEL TUYU",
    cp: 7111,
    provincia_id: 1,
  },
  {
    id: 21784,
    localidad: "S ANTONIO DE ARECO",
    cp: 2760,
    provincia_id: 1,
  },
  {
    id: 21785,
    localidad: "S ANDRES DE GILES",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 21786,
    localidad: "S AND DE TAPALQUE",
    cp: 7342,
    provincia_id: 1,
  },
  {
    id: 21787,
    localidad: "RUTA 3 KILOMETRO 75 700",
    cp: 1816,
    provincia_id: 1,
  },
  {
    id: 21788,
    localidad: "RUTA 205 KILOMETRO 57",
    cp: 1816,
    provincia_id: 1,
  },
  {
    id: 21789,
    localidad: "RUIZ SOLIS",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 21790,
    localidad: "ROVIRA",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 21791,
    localidad: "ROSAS",
    cp: 7205,
    provincia_id: 1,
  },
  {
    id: 21792,
    localidad: "ROQUE PEREZ",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 21793,
    localidad: "ROOSEVELT PARTIDO RIVADAVIA",
    cp: 6420,
    provincia_id: 1,
  },
  {
    id: 21794,
    localidad: "ROMAN BAEZ",
    cp: 6612,
    provincia_id: 1,
  },
  {
    id: 21795,
    localidad: "ROJAS",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 21796,
    localidad: "ROCHA",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 21797,
    localidad: "ROBERTS",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 21798,
    localidad: "ROBERTS",
    cp: 6122,
    provincia_id: 1,
  },
  {
    id: 21799,
    localidad: "ROBERTS",
    cp: 6079,
    provincia_id: 1,
  },
  {
    id: 21800,
    localidad: "ROBERTO PAYRO",
    cp: 1915,
    provincia_id: 1,
  },
  {
    id: 21801,
    localidad: "RIVERA",
    cp: 6441,
    provincia_id: 1,
  },
  {
    id: 21802,
    localidad: "RIVADEO",
    cp: 8127,
    provincia_id: 1,
  },
  {
    id: 21803,
    localidad: "RIVADAVIA",
    cp: 6239,
    provincia_id: 1,
  },
  {
    id: 21804,
    localidad: "RIO TALAVERA",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21805,
    localidad: "RIO TALA",
    cp: 2944,
    provincia_id: 1,
  },
  {
    id: 21806,
    localidad: "RIO SAUCE",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21807,
    localidad: "RIO SALADO",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 21808,
    localidad: "RIO PARANA GUAZU",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21809,
    localidad: "RIO CEIBO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21810,
    localidad: "RIO ALFEREZ N PAGE",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21811,
    localidad: "RINGUELET",
    cp: 1901,
    provincia_id: 1,
  },
  {
    id: 21812,
    localidad: "RINCON NORTE",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 21813,
    localidad: "RINCON DE VIVOT",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 21814,
    localidad: "RINCON DE NOARIO",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 21815,
    localidad: "RINCON DE MILBERG",
    cp: 1624,
    provincia_id: 1,
  },
  {
    id: 21816,
    localidad: "RINCON DE BAUDRIX",
    cp: 7621,
    provincia_id: 1,
  },
  {
    id: 21817,
    localidad: "RICARDO ROJAS",
    cp: 1610,
    provincia_id: 1,
  },
  {
    id: 21818,
    localidad: "RICARDO GAVIQA",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 21819,
    localidad: "RICARDO CANO",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 21820,
    localidad: "RETA",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 21821,
    localidad: "REQUENA",
    cp: 7307,
    provincia_id: 1,
  },
  {
    id: 21822,
    localidad: "REMEDIOS DE ESCALADA - TRES DE FEBRERO",
    cp: 1657,
    provincia_id: 1,
  },
  {
    id: 21823,
    localidad: "REMEDIOS DE ESCALADA",
    cp: 1826,
    provincia_id: 1,
  },
  {
    id: 21824,
    localidad: "REMEDIOS DE ESCALADA",
    cp: 1689,
    provincia_id: 1,
  },
  {
    id: 21825,
    localidad: "REGINALDO J NEILD",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 21826,
    localidad: "RECALDE",
    cp: 6559,
    provincia_id: 1,
  },
  {
    id: 21827,
    localidad: "REAL AUDIENCIA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 21828,
    localidad: "RAWSON",
    cp: 6734,
    provincia_id: 1,
  },
  {
    id: 21829,
    localidad: "RAULET",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 21830,
    localidad: "RAUCH",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 21831,
    localidad: "RANELAGH",
    cp: 1886,
    provincia_id: 1,
  },
  {
    id: 21832,
    localidad: "RANCHOS",
    cp: 1987,
    provincia_id: 1,
  },
  {
    id: 21833,
    localidad: "RANCAGUA",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 21834,
    localidad: "RANCAGUA",
    cp: 2704,
    provincia_id: 1,
  },
  {
    id: 21835,
    localidad: "RAMOS OTERO",
    cp: 7621,
    provincia_id: 1,
  },
  {
    id: 21836,
    localidad: "RAMOS MEJIA",
    cp: 1704,
    provincia_id: 1,
  },
  {
    id: 21837,
    localidad: "RAMON SANTAMARINA",
    cp: 7641,
    provincia_id: 1,
  },
  {
    id: 21838,
    localidad: "RAMON J NEILD",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 21839,
    localidad: "RAMON BIAUS",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 21840,
    localidad: "RAMALLO",
    cp: 2915,
    provincia_id: 1,
  },
  {
    id: 21841,
    localidad: "RAFAEL OBLIGADO",
    cp: 6001,
    provincia_id: 1,
  },
  {
    id: 21842,
    localidad: "RAFAEL CASTILLO",
    cp: 1755,
    provincia_id: 1,
  },
  {
    id: 21843,
    localidad: "RAFAEL CALZADA",
    cp: 1847,
    provincia_id: 1,
  },
  {
    id: 21844,
    localidad: "R DIAZ DE GUZMAN",
    cp: 6120,
    provincia_id: 1,
  },
  {
    id: 21845,
    localidad: "R 9 KM 169 5",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 21846,
    localidad: "R 9 KILOMETRO 72",
    cp: 2804,
    provincia_id: 1,
  },
  {
    id: 21847,
    localidad: "QUIROGA",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 21848,
    localidad: "QUIROGA",
    cp: 6560,
    provincia_id: 1,
  },
  {
    id: 21849,
    localidad: "QUIRNO COSTA",
    cp: 6018,
    provincia_id: 1,
  },
  {
    id: 21850,
    localidad: "QUIQIHUAL ESTACION",
    cp: 7533,
    provincia_id: 1,
  },
  {
    id: 21851,
    localidad: "QUIQI MALAL",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 21852,
    localidad: "QUIÑIHUAL ESTACION",
    cp: 7533,
    provincia_id: 1,
  },
  {
    id: 21853,
    localidad: "QUILMES OESTE",
    cp: 1879,
    provincia_id: 1,
  },
  {
    id: 21854,
    localidad: "QUILMES",
    cp: 1876,
    provincia_id: 1,
  },
  {
    id: 21855,
    localidad: "QUILMES",
    cp: 1878,
    provincia_id: 1,
  },
  {
    id: 21856,
    localidad: "QUILCO",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 21857,
    localidad: "QUEQUEN",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 21858,
    localidad: "QUENUMA",
    cp: 6335,
    provincia_id: 1,
  },
  {
    id: 21859,
    localidad: "PUNTA MOGOTES",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 21860,
    localidad: "PUNTA MEDANOS",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 21861,
    localidad: "PUNTA LARA",
    cp: 1931,
    provincia_id: 1,
  },
  {
    id: 21862,
    localidad: "PUNTA INDIO",
    cp: 1919,
    provincia_id: 1,
  },
  {
    id: 21863,
    localidad: "PUNTA INDIO",
    cp: 1952,
    provincia_id: 1,
  },
  {
    id: 21864,
    localidad: "PUNTA ALTA",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 21865,
    localidad: "PUNA",
    cp: 3752,
    provincia_id: 1,
  },
  {
    id: 21866,
    localidad: "PUESTO DEL MEDIO",
    cp: 2763,
    provincia_id: 1,
  },
  {
    id: 21867,
    localidad: "PUERTO WASSERMANN",
    cp: 8506,
    provincia_id: 1,
  },
  {
    id: 21868,
    localidad: "PUERTO ROSALES",
    cp: 8111,
    provincia_id: 1,
  },
  {
    id: 21869,
    localidad: "PUERTO NECOCHEA",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 21870,
    localidad: "PUERTO GALVAN",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 21871,
    localidad: "PUERTO COLOMA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 21872,
    localidad: "PUERTO BELGRANO",
    cp: 8111,
    provincia_id: 1,
  },
  {
    id: 21873,
    localidad: "PUENTE EL OCHENTA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 21874,
    localidad: "PUENTE CASTEX",
    cp: 2760,
    provincia_id: 1,
  },
  {
    id: 21875,
    localidad: "PUENTE BATALLA",
    cp: 6620,
    provincia_id: 1,
  },
  {
    id: 21876,
    localidad: "PUEBLO SANTA MARIA",
    cp: 7569,
    provincia_id: 1,
  },
  {
    id: 21877,
    localidad: "PUEBLO SAN JOSE",
    cp: 7541,
    provincia_id: 1,
  },
  {
    id: 21878,
    localidad: "PUEBLO SAN ESTEBAN",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 21879,
    localidad: "PUEBLO OTERO",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21880,
    localidad: "PUEBLO NUEVO",
    cp: 6700,
    provincia_id: 1,
  },
  {
    id: 21881,
    localidad: "PUEBLO NUEVO",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 21882,
    localidad: "PUEBLO MARTINEZ DE HOZ",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 21883,
    localidad: "PUEBLO CANDIOTI",
    cp: 3000,
    provincia_id: 1,
  },
  {
    id: 21884,
    localidad: "PUEBLO B RETA",
    cp: 7525,
    provincia_id: 1,
  },
  {
    id: 21885,
    localidad: "PUEBLITOS",
    cp: 6661,
    provincia_id: 1,
  },
  {
    id: 21886,
    localidad: "PUAN",
    cp: 8120,
    provincia_id: 1,
  },
  {
    id: 21887,
    localidad: "PUAN",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 21888,
    localidad: "PTO TRES BONETES",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 21889,
    localidad: "PTO BAHIA BLANCA",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 21890,
    localidad: "PRIMERA JUNTA",
    cp: 6422,
    provincia_id: 1,
  },
  {
    id: 21891,
    localidad: "PRIMERA ANGOSTURA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 21892,
    localidad: "PRETOT FREYRE",
    cp: 6140,
    provincia_id: 1,
  },
  {
    id: 21893,
    localidad: "PRESIDENTE DERQUI",
    cp: 1635,
    provincia_id: 1,
  },
  {
    id: 21894,
    localidad: "PRES QUINTANA",
    cp: 6621,
    provincia_id: 1,
  },
  {
    id: 21895,
    localidad: "POZO COLORADO",
    cp: 3752,
    provincia_id: 1,
  },
  {
    id: 21896,
    localidad: "POURTALE",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 21897,
    localidad: "PORVENIR",
    cp: 6137,
    provincia_id: 1,
  },
  {
    id: 21898,
    localidad: "PORVENIR",
    cp: 6063,
    provincia_id: 1,
  },
  {
    id: 21899,
    localidad: "PONTEVEDRA",
    cp: 1761,
    provincia_id: 1,
  },
  {
    id: 21900,
    localidad: "PONTAUT",
    cp: 7535,
    provincia_id: 1,
  },
  {
    id: 21901,
    localidad: "POLVAREDAS",
    cp: 7267,
    provincia_id: 1,
  },
  {
    id: 21902,
    localidad: "POLVAREDAS",
    cp: 7266,
    provincia_id: 1,
  },
  {
    id: 21903,
    localidad: "POBLET",
    cp: 1905,
    provincia_id: 1,
  },
  {
    id: 21904,
    localidad: "PLUMACHO",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 21905,
    localidad: "PLOMER",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 21906,
    localidad: "PLOMER",
    cp: 1733,
    provincia_id: 1,
  },
  {
    id: 21907,
    localidad: "PLAZA MONTERO",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 21908,
    localidad: "PLAYA SERENA",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 21909,
    localidad: "PLAYA CHAPADMALAL",
    cp: 7619,
    provincia_id: 1,
  },
  {
    id: 21910,
    localidad: "PLATANOS",
    cp: 1861,
    provincia_id: 1,
  },
  {
    id: 21911,
    localidad: "PLA Y RAGNONI",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 21912,
    localidad: "PLA",
    cp: 6691,
    provincia_id: 1,
  },
  {
    id: 21913,
    localidad: "PJE AL AGUILA RIO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21914,
    localidad: "PIRUCO",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 21915,
    localidad: "PIROVANO",
    cp: 6551,
    provincia_id: 1,
  },
  {
    id: 21916,
    localidad: "PIQEYRO",
    cp: 7548,
    provincia_id: 1,
  },
  {
    id: 21917,
    localidad: "PIPINAS",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 21918,
    localidad: "PIPINAS",
    cp: 1954,
    provincia_id: 1,
  },
  {
    id: 21919,
    localidad: "PIÑEYRO",
    cp: 1868,
    provincia_id: 1,
  },
  {
    id: 21920,
    localidad: "PINZON",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 21921,
    localidad: "PINZON",
    cp: 2223,
    provincia_id: 1,
  },
  {
    id: 21922,
    localidad: "PINAMAR",
    cp: 7167,
    provincia_id: 1,
  },
  {
    id: 21923,
    localidad: "PILLAHUINCO",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 21924,
    localidad: "PILAR",
    cp: 1630,
    provincia_id: 1,
  },
  {
    id: 21925,
    localidad: "PILAR",
    cp: 1629,
    provincia_id: 1,
  },
  {
    id: 21926,
    localidad: "PILAR",
    cp: 1634,
    provincia_id: 1,
  },
  {
    id: 21927,
    localidad: "PILA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 21928,
    localidad: "PILA",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 21929,
    localidad: "PIGUE",
    cp: 8170,
    provincia_id: 1,
  },
  {
    id: 21930,
    localidad: "PIERINI",
    cp: 7517,
    provincia_id: 1,
  },
  {
    id: 21931,
    localidad: "PIERES",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 21932,
    localidad: "PIEDRITAS",
    cp: 6241,
    provincia_id: 1,
  },
  {
    id: 21933,
    localidad: "PIEDRITAS",
    cp: 6249,
    provincia_id: 1,
  },
  {
    id: 21934,
    localidad: "PIEDRA ANCHA",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 21935,
    localidad: "PICHINCHA",
    cp: 6051,
    provincia_id: 1,
  },
  {
    id: 21936,
    localidad: "PICHI MAHUIDA",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 21937,
    localidad: "PESSAGNO",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 21938,
    localidad: "PERGAMINO",
    cp: 2702,
    provincia_id: 1,
  },
  {
    id: 21939,
    localidad: "PERGAMINO",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 21940,
    localidad: "PEREZ MILLAN",
    cp: 2933,
    provincia_id: 1,
  },
  {
    id: 21941,
    localidad: "PEREYRA",
    cp: 1857,
    provincia_id: 1,
  },
  {
    id: 21942,
    localidad: "PEREYRA",
    cp: 1894,
    provincia_id: 1,
  },
  {
    id: 21943,
    localidad: "PEQAFLOR",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 21944,
    localidad: "PELLEGRINI",
    cp: 6346,
    provincia_id: 1,
  },
  {
    id: 21945,
    localidad: "PELICURA",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 21946,
    localidad: "PEHUENCO",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 21947,
    localidad: "PEHUEN CO",
    cp: 8070,
    provincia_id: 1,
  },
  {
    id: 21948,
    localidad: "PEHUAJO",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 21949,
    localidad: "PEDRO N ESCRIBANO",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 21950,
    localidad: "PEDRO LURO",
    cp: 8148,
    provincia_id: 1,
  },
  {
    id: 21951,
    localidad: "PEDRO LASALLE",
    cp: 7515,
    provincia_id: 1,
  },
  {
    id: 21952,
    localidad: "PEDRO GAMEN",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 21953,
    localidad: "PEDERNALES",
    cp: 6665,
    provincia_id: 1,
  },
  {
    id: 21954,
    localidad: "PEARSON",
    cp: 2711,
    provincia_id: 1,
  },
  {
    id: 21955,
    localidad: "PAZOS KANKI",
    cp: 6058,
    provincia_id: 1,
  },
  {
    id: 21956,
    localidad: "PAYRO R",
    cp: 1913,
    provincia_id: 1,
  },
  {
    id: 21957,
    localidad: "PAULA",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 21958,
    localidad: "PATRICIOS",
    cp: 6503,
    provincia_id: 1,
  },
  {
    id: 21959,
    localidad: "PASTEUR",
    cp: 6077,
    provincia_id: 1,
  },
  {
    id: 21960,
    localidad: "PASOS",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 21961,
    localidad: "PASO MAYOR",
    cp: 8115,
    provincia_id: 1,
  },
  {
    id: 21962,
    localidad: "PASO GRANDE",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 21963,
    localidad: "PASO DEL REY",
    cp: 1742,
    provincia_id: 1,
  },
  {
    id: 21964,
    localidad: "PASO DEL MEDANO",
    cp: 7511,
    provincia_id: 1,
  },
  {
    id: 21965,
    localidad: "PASO CRAMER",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 21966,
    localidad: "PASO ALSINA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 21967,
    localidad: "PASMAN",
    cp: 7547,
    provincia_id: 1,
  },
  {
    id: 21968,
    localidad: "PASAJE TALAVERA",
    cp: 2804,
    provincia_id: 1,
  },
  {
    id: 21969,
    localidad: "PARRAVICHINI",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 21970,
    localidad: "PARQUE TAILLADE",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 21971,
    localidad: "PARQUE SAN MARTIN",
    cp: 1721,
    provincia_id: 1,
  },
  {
    id: 21972,
    localidad: "PARQUE MUQOZ",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 21973,
    localidad: "PARQUE CARILO",
    cp: 7167,
    provincia_id: 1,
  },
  {
    id: 21974,
    localidad: "PARISH",
    cp: 7316,
    provincia_id: 1,
  },
  {
    id: 21975,
    localidad: "PARDO",
    cp: 7212,
    provincia_id: 1,
  },
  {
    id: 21976,
    localidad: "PARANA BRAVO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21977,
    localidad: "PARAJE STARACHE",
    cp: 1915,
    provincia_id: 1,
  },
  {
    id: 21978,
    localidad: "PARAJE SANTA ROSA",
    cp: 2711,
    provincia_id: 1,
  },
  {
    id: 21979,
    localidad: "PARAJE PALAVEROI",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 21980,
    localidad: "PARAJE LA VASCA",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 21981,
    localidad: "PARAJE LA AURORA",
    cp: 8158,
    provincia_id: 1,
  },
  {
    id: 21982,
    localidad: "PARAG?IL",
    cp: 7412,
    provincia_id: 1,
  },
  {
    id: 21983,
    localidad: "PARADA TATAY",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 21984,
    localidad: "PARADA LOS ROBLES",
    cp: 6753,
    provincia_id: 1,
  },
  {
    id: 21985,
    localidad: "PARADA KM 76",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 21986,
    localidad: "PARADA KM 158",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 21987,
    localidad: "PANCHO DIAZ",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 21988,
    localidad: "PANAME",
    cp: 2931,
    provincia_id: 1,
  },
  {
    id: 21989,
    localidad: "PALMITAS",
    cp: 7221,
    provincia_id: 1,
  },
  {
    id: 21990,
    localidad: "PALEMON HUERGO",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 21991,
    localidad: "PALANTELEN",
    cp: 6434,
    provincia_id: 1,
  },
  {
    id: 21992,
    localidad: "PALANTELEN",
    cp: 6640,
    provincia_id: 1,
  },
  {
    id: 21993,
    localidad: "PACHAN",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 21994,
    localidad: "PABLO PODESTA",
    cp: 1687,
    provincia_id: 1,
  },
  {
    id: 21995,
    localidad: "PABLO PODESTA",
    cp: 1657,
    provincia_id: 1,
  },
  {
    id: 21996,
    localidad: "PABLO NOGUES",
    cp: 1616,
    provincia_id: 1,
  },
  {
    id: 21997,
    localidad: "PABLO ACOSTA",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 21998,
    localidad: "P NUEVO DTO JUNIN",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 21999,
    localidad: "P MARTINEZ DE HOZ",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 22000,
    localidad: "P LAS MARGARITAS",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 22001,
    localidad: "OTOQO",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 22002,
    localidad: "OTAMENDI",
    cp: 2802,
    provincia_id: 1,
  },
  {
    id: 22003,
    localidad: "OSTENDE",
    cp: 7364,
    provincia_id: 1,
  },
  {
    id: 22004,
    localidad: "OSTENDE",
    cp: 7164,
    provincia_id: 1,
  },
  {
    id: 22005,
    localidad: "ORTIZ DE ROSAS",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 22006,
    localidad: "ORTIZ BASUALDO",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 22007,
    localidad: "ORIENTE",
    cp: 7509,
    provincia_id: 1,
  },
  {
    id: 22008,
    localidad: "ORENSE",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 22009,
    localidad: "ORDOQUI",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 22010,
    localidad: "OPEN DOOR",
    cp: 6708,
    provincia_id: 1,
  },
  {
    id: 22011,
    localidad: "ONCE DE SETIEMBRE",
    cp: 1690,
    provincia_id: 1,
  },
  {
    id: 22012,
    localidad: "OMBUCTA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 22013,
    localidad: "OMBU",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 22014,
    localidad: "OLIVOS",
    cp: 1636,
    provincia_id: 1,
  },
  {
    id: 22015,
    localidad: "OLIVOS",
    cp: 1637,
    provincia_id: 1,
  },
  {
    id: 22016,
    localidad: "OLIVIERA CESAR",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 22017,
    localidad: "OLIVERA",
    cp: 6608,
    provincia_id: 1,
  },
  {
    id: 22018,
    localidad: "OLIDEN",
    cp: 1972,
    provincia_id: 1,
  },
  {
    id: 22019,
    localidad: "OLAVARRIA",
    cp: 7436,
    provincia_id: 1,
  },
  {
    id: 22020,
    localidad: "OLAVARRIA",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 22021,
    localidad: "OLASCOAGA",
    cp: 6652,
    provincia_id: 1,
  },
  {
    id: 22022,
    localidad: "ODORQUI",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 22023,
    localidad: "OCHANDIO",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 22024,
    localidad: "O HIGGINS",
    cp: 6748,
    provincia_id: 1,
  },
  {
    id: 22025,
    localidad: "NUEVE DE JULIO",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 22026,
    localidad: "NUEVA SUIZA",
    cp: 6077,
    provincia_id: 1,
  },
  {
    id: 22027,
    localidad: "NUEVA ROMA",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 22028,
    localidad: "NUEVA PLATA",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22029,
    localidad: "NUEVA ESPAQA",
    cp: 6553,
    provincia_id: 1,
  },
  {
    id: 22030,
    localidad: "NUEVA ATLANTIS",
    cp: 7113,
    provincia_id: 1,
  },
  {
    id: 22031,
    localidad: "NORUMBEGA",
    cp: 6501,
    provincia_id: 1,
  },
  {
    id: 22032,
    localidad: "NORDELTA TIGRE",
    cp: 1670,
    provincia_id: 1,
  },
  {
    id: 22033,
    localidad: "NORBERTO DE LA RIESTRA",
    cp: 6663,
    provincia_id: 1,
  },
  {
    id: 22034,
    localidad: "NILINAST",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22035,
    localidad: "NIEVES",
    cp: 7316,
    provincia_id: 1,
  },
  {
    id: 22036,
    localidad: "NICOLAS LEVALLE",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22037,
    localidad: "NICOLAS DESCALZI",
    cp: 8151,
    provincia_id: 1,
  },
  {
    id: 22038,
    localidad: "NICANOR OLIVERA",
    cp: 7637,
    provincia_id: 1,
  },
  {
    id: 22039,
    localidad: "NEWTON",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22040,
    localidad: "NECOL E FCGM",
    cp: 6077,
    provincia_id: 1,
  },
  {
    id: 22041,
    localidad: "NECOCHEA",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 22042,
    localidad: "NECOCHEA",
    cp: 7632,
    provincia_id: 1,
  },
  {
    id: 22043,
    localidad: "NAVIA",
    cp: 6467,
    provincia_id: 1,
  },
  {
    id: 22044,
    localidad: "NAVARRO",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22045,
    localidad: "NASALO",
    cp: 3752,
    provincia_id: 1,
  },
  {
    id: 22046,
    localidad: "NAPOSTA",
    cp: 8122,
    provincia_id: 1,
  },
  {
    id: 22047,
    localidad: "NAPALEOFU",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22048,
    localidad: "NAHUEL RUCA",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 22049,
    localidad: "N DE LA RIESTRA",
    cp: 6663,
    provincia_id: 1,
  },
  {
    id: 22050,
    localidad: "MUTTI",
    cp: 2909,
    provincia_id: 1,
  },
  {
    id: 22051,
    localidad: "MUQOZ",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 22052,
    localidad: "MUÑOZ",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 22053,
    localidad: "MUÑIZ",
    cp: 1662,
    provincia_id: 1,
  },
  {
    id: 22054,
    localidad: "MUNRO",
    cp: 1605,
    provincia_id: 1,
  },
  {
    id: 22055,
    localidad: "MULCAHY",
    cp: 6501,
    provincia_id: 1,
  },
  {
    id: 22056,
    localidad: "MOURAS",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 22057,
    localidad: "MORSE",
    cp: 6049,
    provincia_id: 1,
  },
  {
    id: 22058,
    localidad: "MORON",
    cp: 1708,
    provincia_id: 1,
  },
  {
    id: 22059,
    localidad: "MORENO",
    cp: 1743,
    provincia_id: 1,
  },
  {
    id: 22060,
    localidad: "MORENO",
    cp: 1744,
    provincia_id: 1,
  },
  {
    id: 22061,
    localidad: "MOREA",
    cp: 6507,
    provincia_id: 1,
  },
  {
    id: 22062,
    localidad: "MONTES DE OCA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22063,
    localidad: "MONTECARLO",
    cp: 7365,
    provincia_id: 1,
  },
  {
    id: 22064,
    localidad: "MONTE VELOZ",
    cp: 1917,
    provincia_id: 1,
  },
  {
    id: 22065,
    localidad: "MONTE HERMOSO",
    cp: 8153,
    provincia_id: 1,
  },
  {
    id: 22066,
    localidad: "MONTE GRANDE",
    cp: 1841,
    provincia_id: 1,
  },
  {
    id: 22067,
    localidad: "MONTE GRANDE",
    cp: 1842,
    provincia_id: 1,
  },
  {
    id: 22068,
    localidad: "MONTE FIORE",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 22069,
    localidad: "MONTE CRESPO",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22070,
    localidad: "MONTE CHINGOLO",
    cp: 1825,
    provincia_id: 1,
  },
  {
    id: 22071,
    localidad: "MONTE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22072,
    localidad: "MONSALVO",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 22073,
    localidad: "MONROE",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 22074,
    localidad: "MONES CAZON",
    cp: 6469,
    provincia_id: 1,
  },
  {
    id: 22075,
    localidad: "MONASTERIO",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 22076,
    localidad: "MOLL",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 22077,
    localidad: "MOLINO GALILEO",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22078,
    localidad: "MOCTEZUMA",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 22079,
    localidad: "MIRANDA",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 22080,
    localidad: "MIRAMONTE",
    cp: 7214,
    provincia_id: 1,
  },
  {
    id: 22081,
    localidad: "MIRAMAR",
    cp: 6550,
    provincia_id: 1,
  },
  {
    id: 22082,
    localidad: "MIRAMAR",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 22083,
    localidad: "MIRA PAMPA",
    cp: 6403,
    provincia_id: 1,
  },
  {
    id: 22084,
    localidad: "MINISTRO RIVADAVIA",
    cp: 1848,
    provincia_id: 1,
  },
  {
    id: 22085,
    localidad: "MINERALES",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 22086,
    localidad: "MIGUELETES",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 22087,
    localidad: "MICAELA CASCALLARES",
    cp: 7507,
    provincia_id: 1,
  },
  {
    id: 22088,
    localidad: "MERLO",
    cp: 1722,
    provincia_id: 1,
  },
  {
    id: 22089,
    localidad: "MERCEDES",
    cp: 6604,
    provincia_id: 1,
  },
  {
    id: 22090,
    localidad: "MERCEDES",
    cp: 6602,
    provincia_id: 1,
  },
  {
    id: 22091,
    localidad: "MERCEDES",
    cp: 6600,
    provincia_id: 1,
  },
  {
    id: 22092,
    localidad: "MERCADO CENTRAL",
    cp: 1771,
    provincia_id: 1,
  },
  {
    id: 22093,
    localidad: "MEMBRILLAR",
    cp: 6748,
    provincia_id: 1,
  },
  {
    id: 22094,
    localidad: "MELCHOR ROMERO",
    cp: 1903,
    provincia_id: 1,
  },
  {
    id: 22095,
    localidad: "MEDANOS NEGROS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22096,
    localidad: "MEDANOS",
    cp: 8132,
    provincia_id: 1,
  },
  {
    id: 22097,
    localidad: "MEDANO COLORADO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22098,
    localidad: "MEDANO BLANCO",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 22099,
    localidad: "MEDALAND",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22100,
    localidad: "MECHONGUE",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 22101,
    localidad: "MECHITA",
    cp: 6648,
    provincia_id: 1,
  },
  {
    id: 22102,
    localidad: "MECHA",
    cp: 6648,
    provincia_id: 1,
  },
  {
    id: 22103,
    localidad: "MCO DE VICTORIA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 22104,
    localidad: "MAYOR J ORELLANO",
    cp: 6053,
    provincia_id: 1,
  },
  {
    id: 22105,
    localidad: "MAYOR BURATOVICH",
    cp: 8146,
    provincia_id: 1,
  },
  {
    id: 22106,
    localidad: "MAXIMO PAZ",
    cp: 1809,
    provincia_id: 1,
  },
  {
    id: 22107,
    localidad: "MAXIMO PAZ",
    cp: 1812,
    provincia_id: 1,
  },
  {
    id: 22108,
    localidad: "MAXIMO FERNANDEZ",
    cp: 6645,
    provincia_id: 1,
  },
  {
    id: 22109,
    localidad: "MAURICIO HIRSCH",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 22110,
    localidad: "MAURAS",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 22111,
    localidad: "MATHEU",
    cp: 1627,
    provincia_id: 1,
  },
  {
    id: 22112,
    localidad: "MASUREL",
    cp: 6438,
    provincia_id: 1,
  },
  {
    id: 22113,
    localidad: "MASUREL",
    cp: 6439,
    provincia_id: 1,
  },
  {
    id: 22114,
    localidad: "MARUCHA",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22115,
    localidad: "MARTINEZ",
    cp: 1639,
    provincia_id: 1,
  },
  {
    id: 22116,
    localidad: "MARTINEZ",
    cp: 1640,
    provincia_id: 1,
  },
  {
    id: 22117,
    localidad: "MARTIN FIERRO",
    cp: 7311,
    provincia_id: 1,
  },
  {
    id: 22118,
    localidad: "MARTIN CORONADO",
    cp: 1682,
    provincia_id: 1,
  },
  {
    id: 22119,
    localidad: "MARTIN CORONADO",
    cp: 1683,
    provincia_id: 1,
  },
  {
    id: 22120,
    localidad: "MARTIN BERRAONDO",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 22121,
    localidad: "MARIANO UNZUE",
    cp: 6551,
    provincia_id: 1,
  },
  {
    id: 22122,
    localidad: "MARIANO ROLDAN",
    cp: 7517,
    provincia_id: 1,
  },
  {
    id: 22123,
    localidad: "MARIANO H ALFONZO",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 22124,
    localidad: "MARIANO BENITEZ PERGAMINO",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 22125,
    localidad: "MARIANO ACOSTA",
    cp: 1724,
    provincia_id: 1,
  },
  {
    id: 22126,
    localidad: "MARIANO ACOSTA",
    cp: 1723,
    provincia_id: 1,
  },
  {
    id: 22127,
    localidad: "MARIA LUCILA",
    cp: 6465,
    provincia_id: 1,
  },
  {
    id: 22128,
    localidad: "MARIA IGNACIA",
    cp: 7003,
    provincia_id: 1,
  },
  {
    id: 22129,
    localidad: "MARI LAUQUEN",
    cp: 6442,
    provincia_id: 1,
  },
  {
    id: 22130,
    localidad: "MARCOS PAZ B EL ZORZAL",
    cp: 1793,
    provincia_id: 1,
  },
  {
    id: 22131,
    localidad: "MARCOS PAZ",
    cp: 1727,
    provincia_id: 1,
  },
  {
    id: 22132,
    localidad: "MARCELINO UGARTE",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 22133,
    localidad: "MARACO CHICO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22134,
    localidad: "MARACO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22135,
    localidad: "MAR DEL TUYU",
    cp: 7108,
    provincia_id: 1,
  },
  {
    id: 22136,
    localidad: "MAR DEL SUD",
    cp: 7774,
    provincia_id: 1,
  },
  {
    id: 22137,
    localidad: "MAR DEL PLATAS",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22138,
    localidad: "MAR DEL PLATA - BARRIO TIRO FEDERAL",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22139,
    localidad: "MAR DEL PLATA - BARRIO TIERRA DE ORO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22140,
    localidad: "MAR DEL PLATA - BARRIO SAN JOSE",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22141,
    localidad: "MAR DEL PLATA - BARRIO SAN CAYETANO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22142,
    localidad: "MAR DEL PLATA - BARRIO SAN CARLOS",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22143,
    localidad: "MAR DEL PLATA - BARRIO PUEBLO NUEVO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22144,
    localidad: "MAR DEL PLATA - BARRIO PRIMERA JUNTA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22145,
    localidad: "MAR DEL PLATA - BARRIO PINARES",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22146,
    localidad: "MAR DEL PLATA - BARRIO LOS ANDES",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22147,
    localidad: "MAR DEL PLATA - BARRIO JURAMENTO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22148,
    localidad: "MAR DEL PLATA - BARRIO JOSE M ESTRADA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22149,
    localidad: "MAR DEL PLATA - BARRIO GENERAL ROCA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22150,
    localidad: "MAR DEL PLATA - BARRIO GASTRONOMICO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22151,
    localidad: "MAR DEL PLATA - BARRIO EMIR RAMON JUAREZ",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22152,
    localidad: "MAR DEL PLATA - BARRIO CAISAMAR",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22153,
    localidad: "MAR DEL PLATA - BARRIO BATAN",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 22154,
    localidad: "MAR DEL PLATA",
    cp: 7604,
    provincia_id: 1,
  },
  {
    id: 22155,
    localidad: "MAR DEL PLATA",
    cp: 7610,
    provincia_id: 1,
  },
  {
    id: 22156,
    localidad: "MAR DEL PLATA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22157,
    localidad: "MAR DEL PLATA",
    cp: 7602,
    provincia_id: 1,
  },
  {
    id: 22158,
    localidad: "MAR DEL PLATA",
    cp: 7608,
    provincia_id: 1,
  },
  {
    id: 22159,
    localidad: "MAR DEL PLATA",
    cp: 7611,
    provincia_id: 1,
  },
  {
    id: 22160,
    localidad: "MAR DEL PLATA",
    cp: 7606,
    provincia_id: 1,
  },
  {
    id: 22161,
    localidad: "MAR DEL PLATA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22162,
    localidad: "MAR DEL PLATA",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 22163,
    localidad: "MAR DE LAS PAMPAS",
    cp: 7165,
    provincia_id: 1,
  },
  {
    id: 22164,
    localidad: "MAR DE COBO",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 22165,
    localidad: "MAR DE AJO",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 22166,
    localidad: "MAR CHIQUITA",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 22167,
    localidad: "MAR AZUL",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22168,
    localidad: "MAQUINISTA F SAVIO",
    cp: 1620,
    provincia_id: 1,
  },
  {
    id: 22169,
    localidad: "MAPIS",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 22170,
    localidad: "MAORI",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 22171,
    localidad: "MANZONE",
    cp: 1633,
    provincia_id: 1,
  },
  {
    id: 22172,
    localidad: "MANZANARES",
    cp: 2025,
    provincia_id: 1,
  },
  {
    id: 22173,
    localidad: "MANZANARES",
    cp: 1632,
    provincia_id: 1,
  },
  {
    id: 22174,
    localidad: "MANUEL OCAMPO",
    cp: 2713,
    provincia_id: 1,
  },
  {
    id: 22175,
    localidad: "MANUEL JOSE GARCIA",
    cp: 6608,
    provincia_id: 1,
  },
  {
    id: 22176,
    localidad: "MANUEL B GONNET",
    cp: 1897,
    provincia_id: 1,
  },
  {
    id: 22177,
    localidad: "MANUEL ALBERTI",
    cp: 1664,
    provincia_id: 1,
  },
  {
    id: 22178,
    localidad: "MANANTIALES GRANDES",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 22179,
    localidad: "MANANTIALES",
    cp: 2717,
    provincia_id: 1,
  },
  {
    id: 22180,
    localidad: "MAMAGUITA",
    cp: 6661,
    provincia_id: 1,
  },
  {
    id: 22181,
    localidad: "MALVINAS ARGENTINAS",
    cp: 1916,
    provincia_id: 1,
  },
  {
    id: 22182,
    localidad: "MALVINAS ARGENTINAS",
    cp: 1616,
    provincia_id: 1,
  },
  {
    id: 22183,
    localidad: "MALVINAS ARGENTINAS",
    cp: 1851,
    provincia_id: 1,
  },
  {
    id: 22184,
    localidad: "MALECON GARDELIA",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 22185,
    localidad: "MALAVER",
    cp: 1653,
    provincia_id: 1,
  },
  {
    id: 22186,
    localidad: "MALABIA",
    cp: 6443,
    provincia_id: 1,
  },
  {
    id: 22187,
    localidad: "MAIPU",
    cp: 7160,
    provincia_id: 1,
  },
  {
    id: 22188,
    localidad: "MAIPU",
    cp: 7162,
    provincia_id: 1,
  },
  {
    id: 22189,
    localidad: "MAGUIRRE",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 22190,
    localidad: "MAGDALENA",
    cp: 1913,
    provincia_id: 1,
  },
  {
    id: 22191,
    localidad: "MAGDALA",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22192,
    localidad: "MAGALLANES",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 22193,
    localidad: "MACHAO",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22194,
    localidad: "MACEDO",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22195,
    localidad: "M PAZ BARRIO URIOSTE",
    cp: 1788,
    provincia_id: 1,
  },
  {
    id: 22196,
    localidad: "M PAZ BARRIO MARTIN FIERRO",
    cp: 1782,
    provincia_id: 1,
  },
  {
    id: 22197,
    localidad: "M PAZ BARRIO LA MILAGROSA",
    cp: 1784,
    provincia_id: 1,
  },
  {
    id: 22198,
    localidad: "M PAZ BARRIO LA LONJA",
    cp: 1783,
    provincia_id: 1,
  },
  {
    id: 22199,
    localidad: "M PAZ BARRIO EL MORO",
    cp: 1787,
    provincia_id: 1,
  },
  {
    id: 22200,
    localidad: "M PAZ BARRIO EL MARTILLO",
    cp: 1790,
    provincia_id: 1,
  },
  {
    id: 22201,
    localidad: "M PAZ BARRIO BERNASCONI",
    cp: 1791,
    provincia_id: 1,
  },
  {
    id: 22202,
    localidad: "M CASCALLARES",
    cp: 7507,
    provincia_id: 1,
  },
  {
    id: 22203,
    localidad: "M B PERGAMINO",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 22204,
    localidad: "LURO",
    cp: 6439,
    provincia_id: 1,
  },
  {
    id: 22205,
    localidad: "LUMB",
    cp: 7639,
    provincia_id: 1,
  },
  {
    id: 22206,
    localidad: "LUJAN",
    cp: 6700,
    provincia_id: 1,
  },
  {
    id: 22207,
    localidad: "LUJAN",
    cp: 6702,
    provincia_id: 1,
  },
  {
    id: 22208,
    localidad: "LUIS GUILLON",
    cp: 1838,
    provincia_id: 1,
  },
  {
    id: 22209,
    localidad: "LUIS CHICO",
    cp: 1917,
    provincia_id: 1,
  },
  {
    id: 22210,
    localidad: "LUGANO",
    cp: 6550,
    provincia_id: 1,
  },
  {
    id: 22211,
    localidad: "LUCAS MONTEVERDE",
    cp: 6661,
    provincia_id: 1,
  },
  {
    id: 22212,
    localidad: "LOZANO",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 22213,
    localidad: "LOURDES",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 22214,
    localidad: "LOUGE",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 22215,
    localidad: "LOTE 8",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22216,
    localidad: "LOTE 7",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22217,
    localidad: "LOTE 6",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22218,
    localidad: "LOTE 3",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22219,
    localidad: "LOTE 24",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22220,
    localidad: "LOTE 23",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22221,
    localidad: "LOTE 22",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22222,
    localidad: "LOTE 22",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22223,
    localidad: "LOTE 21",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22224,
    localidad: "LOTE 19  C N LEVEN",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22225,
    localidad: "LOTE 19",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22226,
    localidad: "LOTE 18",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22227,
    localidad: "LOTE 18",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22228,
    localidad: "LOTE 17",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22229,
    localidad: "LOTE 14",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22230,
    localidad: "LOTE 13",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22231,
    localidad: "LOTE 12",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22232,
    localidad: "LOTE 11",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22233,
    localidad: "LOTE 10",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22234,
    localidad: "LOS TOLDOS",
    cp: 6015,
    provincia_id: 1,
  },
  {
    id: 22235,
    localidad: "LOS TALAS",
    cp: 1942,
    provincia_id: 1,
  },
  {
    id: 22236,
    localidad: "LOS SANTOS VIEJOS",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 22237,
    localidad: "LOS POLVORINES",
    cp: 1613,
    provincia_id: 1,
  },
  {
    id: 22238,
    localidad: "LOS PINOS",
    cp: 7412,
    provincia_id: 1,
  },
  {
    id: 22239,
    localidad: "LOS PINOS",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 22240,
    localidad: "LOS PATOS",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22241,
    localidad: "LOS OSCUROS",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22242,
    localidad: "LOS ORTIZ",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 22243,
    localidad: "LOS MORROS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22244,
    localidad: "LOS MOLLES",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 22245,
    localidad: "LOS MERINOS",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 22246,
    localidad: "LOS LEONES",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 22247,
    localidad: "LOS INDIOS",
    cp: 2709,
    provincia_id: 1,
  },
  {
    id: 22248,
    localidad: "LOS INDIOS",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22249,
    localidad: "LOS HORNOS",
    cp: 1900,
    provincia_id: 1,
  },
  {
    id: 22250,
    localidad: "LOS GUACHOS DE GUE",
    cp: 6127,
    provincia_id: 1,
  },
  {
    id: 22251,
    localidad: "LOS EUCALIPTOS",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22252,
    localidad: "LOS CUATRO CAMINOS",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 22253,
    localidad: "LOS COLONIALES",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 22254,
    localidad: "LOS CHAQARES",
    cp: 6475,
    provincia_id: 1,
  },
  {
    id: 22255,
    localidad: "LOS CERROS",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 22256,
    localidad: "LOS CERRILLOS",
    cp: 7226,
    provincia_id: 1,
  },
  {
    id: 22257,
    localidad: "LOS CARDOS",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 22258,
    localidad: "LOS CARDALES",
    cp: 2814,
    provincia_id: 1,
  },
  {
    id: 22259,
    localidad: "LOS CALLEJONES",
    cp: 6062,
    provincia_id: 1,
  },
  {
    id: 22260,
    localidad: "LOS BOSQUES",
    cp: 6018,
    provincia_id: 1,
  },
  {
    id: 22261,
    localidad: "LOS AROMOS",
    cp: 1816,
    provincia_id: 1,
  },
  {
    id: 22262,
    localidad: "LOS ARCOS",
    cp: 2720,
    provincia_id: 1,
  },
  {
    id: 22263,
    localidad: "LOS ANGELES",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 22264,
    localidad: "LOS ALTOS",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 22265,
    localidad: "LOS ACANTILADOS",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 22266,
    localidad: "LOPEZ MOLINARI",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 22267,
    localidad: "LOPEZ LECUBE",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 22268,
    localidad: "LOPEZ CAMELO",
    cp: 1622,
    provincia_id: 1,
  },
  {
    id: 22269,
    localidad: "LOPEZ",
    cp: 7021,
    provincia_id: 1,
  },
  {
    id: 22270,
    localidad: "LONGCHAMPS",
    cp: 1854,
    provincia_id: 1,
  },
  {
    id: 22271,
    localidad: "LOMAS DEL MIRADOR",
    cp: 1752,
    provincia_id: 1,
  },
  {
    id: 22272,
    localidad: "LOMAS DE ZAMORA",
    cp: 1832,
    provincia_id: 1,
  },
  {
    id: 22273,
    localidad: "LOMA VERDE",
    cp: 1628,
    provincia_id: 1,
  },
  {
    id: 22274,
    localidad: "LOMA VERDE",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 22275,
    localidad: "LOMA PARTIDA",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 22276,
    localidad: "LOMA NEGRA",
    cp: 7436,
    provincia_id: 1,
  },
  {
    id: 22277,
    localidad: "LOMA NEGRA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22278,
    localidad: "LOMA NEGRA",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 22279,
    localidad: "LOMA HERMOSA",
    cp: 1657,
    provincia_id: 1,
  },
  {
    id: 22280,
    localidad: "LOMA DEL INDIO",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 22281,
    localidad: "LOMA DE SALOMON",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 22282,
    localidad: "LOBOS ARROYO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 22283,
    localidad: "LOBOS",
    cp: 7420,
    provincia_id: 1,
  },
  {
    id: 22284,
    localidad: "LOBOS",
    cp: 7240,
    provincia_id: 1,
  },
  {
    id: 22285,
    localidad: "LOBERIA",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 22286,
    localidad: "LLAVALLOL",
    cp: 1836,
    provincia_id: 1,
  },
  {
    id: 22287,
    localidad: "LISANDRO OLMOS ETCHEVERRY",
    cp: 1901,
    provincia_id: 1,
  },
  {
    id: 22288,
    localidad: "LINCOLN",
    cp: 6070,
    provincia_id: 1,
  },
  {
    id: 22289,
    localidad: "LIN CALEL",
    cp: 7505,
    provincia_id: 1,
  },
  {
    id: 22290,
    localidad: "LIMAY MAHUIDA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22291,
    localidad: "LIMA",
    cp: 2806,
    provincia_id: 1,
  },
  {
    id: 22292,
    localidad: "LIERRA ADJEMIRO",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 22293,
    localidad: "LICENCIADO MATIENZO",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22294,
    localidad: "LIBRES DEL SUD",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 22295,
    localidad: "LIBERTAD",
    cp: 1716,
    provincia_id: 1,
  },
  {
    id: 22296,
    localidad: "LIBANO",
    cp: 7407,
    provincia_id: 1,
  },
  {
    id: 22297,
    localidad: "LEZAMA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22298,
    localidad: "LEUBUCO",
    cp: 6338,
    provincia_id: 1,
  },
  {
    id: 22299,
    localidad: "LEGASA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22300,
    localidad: "LEGARISTI",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22301,
    localidad: "LEANDRO N ALEM",
    cp: 6032,
    provincia_id: 1,
  },
  {
    id: 22302,
    localidad: "LCIADO MATIENZO",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22303,
    localidad: "LAZZARINO",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 22304,
    localidad: "LASTRA",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 22305,
    localidad: "LAS VIBORAS",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 22306,
    localidad: "LAS VAQUERIAS",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22307,
    localidad: "LAS TRES FLORES",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 22308,
    localidad: "LAS TOSCAS",
    cp: 6453,
    provincia_id: 1,
  },
  {
    id: 22309,
    localidad: "LAS TORTUGAS",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22310,
    localidad: "LAS TONINAS",
    cp: 7106,
    provincia_id: 1,
  },
  {
    id: 22311,
    localidad: "LAS TAHONAS",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 22312,
    localidad: "LAS SULTANAS",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 22313,
    localidad: "LAS SUIZAS",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22314,
    localidad: "LAS SALADAS",
    cp: 2707,
    provincia_id: 1,
  },
  {
    id: 22315,
    localidad: "LAS ROSAS",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 22316,
    localidad: "LAS PIEDRTAS",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 22317,
    localidad: "LAS PIEDRITAS",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 22318,
    localidad: "LAS PIEDRAS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22319,
    localidad: "LAS PARVAS",
    cp: 6022,
    provincia_id: 1,
  },
  {
    id: 22320,
    localidad: "LAS PALMAS",
    cp: 2806,
    provincia_id: 1,
  },
  {
    id: 22321,
    localidad: "LAS OSCURAS",
    cp: 8115,
    provincia_id: 1,
  },
  {
    id: 22322,
    localidad: "LAS NUTRIAS",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 22323,
    localidad: "LAS NIEVES",
    cp: 7316,
    provincia_id: 1,
  },
  {
    id: 22324,
    localidad: "LAS NEGRAS",
    cp: 6507,
    provincia_id: 1,
  },
  {
    id: 22325,
    localidad: "LAS MULAS",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22326,
    localidad: "LAS MOSTAZAS",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 22327,
    localidad: "LAS MERCEDES",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 22328,
    localidad: "LAS MARTINETAS",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 22329,
    localidad: "LAS MARIANAS",
    cp: 6607,
    provincia_id: 1,
  },
  {
    id: 22330,
    localidad: "LAS MALVINAS",
    cp: 1750,
    provincia_id: 1,
  },
  {
    id: 22331,
    localidad: "LAS MALVINAS",
    cp: 1748,
    provincia_id: 1,
  },
  {
    id: 22332,
    localidad: "LAS LOMAS",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22333,
    localidad: "LAS JUANITAS",
    cp: 6476,
    provincia_id: 1,
  },
  {
    id: 22334,
    localidad: "LAS HERMANAS",
    cp: 7414,
    provincia_id: 1,
  },
  {
    id: 22335,
    localidad: "LAS GAVIOTAS",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22336,
    localidad: "LAS FLORES",
    cp: 7200,
    provincia_id: 1,
  },
  {
    id: 22337,
    localidad: "LAS FLORES",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 22338,
    localidad: "LAS ESCOBAS",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22339,
    localidad: "LAS DOS NACIONES",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22340,
    localidad: "LAS DOS ANGELITAS",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 22341,
    localidad: "LAS CUATRO PUERTAS",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 22342,
    localidad: "LAS CTRO HERMANAS",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 22343,
    localidad: "LAS CORTADERAS",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 22344,
    localidad: "LAS CHILCAS",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22345,
    localidad: "LAS CHILCAS",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 22346,
    localidad: "LAS CHACRAS",
    cp: 7241,
    provincia_id: 1,
  },
  {
    id: 22347,
    localidad: "LAS CHACRAS",
    cp: 6051,
    provincia_id: 1,
  },
  {
    id: 22348,
    localidad: "LAS BRUSCAS",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22349,
    localidad: "LAS BANDURRIAS",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 22350,
    localidad: "LAS BAHAMAS",
    cp: 2916,
    provincia_id: 1,
  },
  {
    id: 22351,
    localidad: "LAS AROMAS",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22352,
    localidad: "LAS ARMAS",
    cp: 7176,
    provincia_id: 1,
  },
  {
    id: 22353,
    localidad: "LAS ARMAS",
    cp: 7370,
    provincia_id: 1,
  },
  {
    id: 22354,
    localidad: "LAS ARMAS",
    cp: 7172,
    provincia_id: 1,
  },
  {
    id: 22355,
    localidad: "LAS ACHIRAS",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 22356,
    localidad: "LAS ACACIAS",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22357,
    localidad: "LARTIGAU",
    cp: 7531,
    provincia_id: 1,
  },
  {
    id: 22358,
    localidad: "LARREA",
    cp: 6834,
    provincia_id: 1,
  },
  {
    id: 22359,
    localidad: "LARRAMENDY",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22360,
    localidad: "LAPRIDA",
    cp: 7414,
    provincia_id: 1,
  },
  {
    id: 22361,
    localidad: "LAPLACETTE",
    cp: 6013,
    provincia_id: 1,
  },
  {
    id: 22362,
    localidad: "LANUS",
    cp: 1824,
    provincia_id: 1,
  },
  {
    id: 22363,
    localidad: "LANGUEYU",
    cp: 7151,
    provincia_id: 1,
  },
  {
    id: 22364,
    localidad: "LAGUNA MEDINA",
    cp: 7214,
    provincia_id: 1,
  },
  {
    id: 22365,
    localidad: "LAGUNA LAS MULITAS",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 22366,
    localidad: "LAGUNA DEL SOLDADO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 22367,
    localidad: "LAGUNA DEL MONTE",
    cp: 6435,
    provincia_id: 1,
  },
  {
    id: 22368,
    localidad: "LAGUNA DEL CURA",
    cp: 6501,
    provincia_id: 1,
  },
  {
    id: 22369,
    localidad: "LAGUNA DE LOBOS",
    cp: 7240,
    provincia_id: 1,
  },
  {
    id: 22370,
    localidad: "LAGUNA DE GOMEZ",
    cp: 6001,
    provincia_id: 1,
  },
  {
    id: 22371,
    localidad: "LAGUNA CHASICO",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22372,
    localidad: "LAGUNA BRAVA",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 22373,
    localidad: "LAGUNA ALSINA",
    cp: 6477,
    provincia_id: 1,
  },
  {
    id: 22374,
    localidad: "LAG DE LOS PADRES",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 22375,
    localidad: "LABARDEN",
    cp: 7161,
    provincia_id: 1,
  },
  {
    id: 22376,
    localidad: "LA ZARATEQA",
    cp: 6077,
    provincia_id: 1,
  },
  {
    id: 22377,
    localidad: "LA ZANJA",
    cp: 6400,
    provincia_id: 1,
  },
  {
    id: 22378,
    localidad: "LA YESCA",
    cp: 6513,
    provincia_id: 1,
  },
  {
    id: 22379,
    localidad: "LA VITICOLA",
    cp: 8122,
    provincia_id: 1,
  },
  {
    id: 22380,
    localidad: "LA VIRGINIA",
    cp: 8115,
    provincia_id: 1,
  },
  {
    id: 22381,
    localidad: "LA VIOLETA",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 22382,
    localidad: "LA VICTORIA DESVIO",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 22383,
    localidad: "LA VICTORIA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 22384,
    localidad: "LA VICTORIA",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 22385,
    localidad: "LA VERDE",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 22386,
    localidad: "LA VERDE",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22387,
    localidad: "LA VASCONGADA",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 22388,
    localidad: "LA VANGUARDIA",
    cp: 2715,
    provincia_id: 1,
  },
  {
    id: 22389,
    localidad: "LA VALEROSA",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 22390,
    localidad: "LA UNION",
    cp: 1803,
    provincia_id: 1,
  },
  {
    id: 22391,
    localidad: "LA TRINIDAD",
    cp: 6003,
    provincia_id: 1,
  },
  {
    id: 22392,
    localidad: "LA TRINIDAD",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22393,
    localidad: "LA TRIBU",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 22394,
    localidad: "LA TOSCA",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22395,
    localidad: "LA TORRECITA",
    cp: 6553,
    provincia_id: 1,
  },
  {
    id: 22396,
    localidad: "LA TOMASA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22397,
    localidad: "LA TOBIANA",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 22398,
    localidad: "LA TIGRA",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22399,
    localidad: "LA TALINA",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 22400,
    localidad: "LA TABLADA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22401,
    localidad: "LA SUIZA",
    cp: 6050,
    provincia_id: 1,
  },
  {
    id: 22402,
    localidad: "LA SUERTE",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22403,
    localidad: "LA SORTIJA",
    cp: 7517,
    provincia_id: 1,
  },
  {
    id: 22404,
    localidad: "LA SORPRESA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22405,
    localidad: "LA SOMBRA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22406,
    localidad: "LA SOFIA",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 22407,
    localidad: "LA SOBERANIA",
    cp: 8154,
    provincia_id: 1,
  },
  {
    id: 22408,
    localidad: "LA SIRENA",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 22409,
    localidad: "LA SAUDADE",
    cp: 8174,
    provincia_id: 1,
  },
  {
    id: 22410,
    localidad: "LA SARITA",
    cp: 2715,
    provincia_id: 1,
  },
  {
    id: 22411,
    localidad: "LA SARA",
    cp: 7621,
    provincia_id: 1,
  },
  {
    id: 22412,
    localidad: "LA SARA",
    cp: 6612,
    provincia_id: 1,
  },
  {
    id: 22413,
    localidad: "LA SALADA",
    cp: 1774,
    provincia_id: 1,
  },
  {
    id: 22414,
    localidad: "LA RUBIA",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 22415,
    localidad: "LA ROSINA",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22416,
    localidad: "LA ROSALIA",
    cp: 8187,
    provincia_id: 1,
  },
  {
    id: 22417,
    localidad: "LA ROSA",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 22418,
    localidad: "LA ROSA",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22419,
    localidad: "LA RINCONADA",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 22420,
    localidad: "LA RICA",
    cp: 6623,
    provincia_id: 1,
  },
  {
    id: 22421,
    localidad: "LA RESERVA",
    cp: 7536,
    provincia_id: 1,
  },
  {
    id: 22422,
    localidad: "LA REJA",
    cp: 1738,
    provincia_id: 1,
  },
  {
    id: 22423,
    localidad: "LA REJA",
    cp: 1745,
    provincia_id: 1,
  },
  {
    id: 22424,
    localidad: "LA REFORMA VIEJA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22425,
    localidad: "LA REFORMA",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 22426,
    localidad: "LA REFORMA",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 22427,
    localidad: "LA REFORMA",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22428,
    localidad: "LA REFORMA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22429,
    localidad: "LA RAZON",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22430,
    localidad: "LA RAMADA",
    cp: 6123,
    provincia_id: 1,
  },
  {
    id: 22431,
    localidad: "LA RABIA",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 22432,
    localidad: "LA QUERENCIA",
    cp: 2912,
    provincia_id: 1,
  },
  {
    id: 22433,
    localidad: "LA PROVIDENCIA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22434,
    localidad: "LA PROTEGIDA",
    cp: 6561,
    provincia_id: 1,
  },
  {
    id: 22435,
    localidad: "LA PROTEGIDA",
    cp: 7311,
    provincia_id: 1,
  },
  {
    id: 22436,
    localidad: "LA PROTECCION",
    cp: 7112,
    provincia_id: 1,
  },
  {
    id: 22437,
    localidad: "LA PRIMITIVA",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 22438,
    localidad: "LA PRIMAVERA",
    cp: 7543,
    provincia_id: 1,
  },
  {
    id: 22439,
    localidad: "LA PRIMAVERA",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 22440,
    localidad: "LA PRADERA",
    cp: 6453,
    provincia_id: 1,
  },
  {
    id: 22441,
    localidad: "LA POSTA",
    cp: 7112,
    provincia_id: 1,
  },
  {
    id: 22442,
    localidad: "LA POSADA",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 22443,
    localidad: "LA PORTEQA",
    cp: 7241,
    provincia_id: 1,
  },
  {
    id: 22444,
    localidad: "LA PORTEQA",
    cp: 7207,
    provincia_id: 1,
  },
  {
    id: 22445,
    localidad: "LA PORTEÑA",
    cp: 6407,
    provincia_id: 1,
  },
  {
    id: 22446,
    localidad: "LA POCHOLA",
    cp: 8124,
    provincia_id: 1,
  },
  {
    id: 22447,
    localidad: "LA PLAYA",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 22448,
    localidad: "LA PLATA",
    cp: 1907,
    provincia_id: 1,
  },
  {
    id: 22449,
    localidad: "LA PLATA",
    cp: 1914,
    provincia_id: 1,
  },
  {
    id: 22450,
    localidad: "LA PLATA",
    cp: 1912,
    provincia_id: 1,
  },
  {
    id: 22451,
    localidad: "LA PLATA",
    cp: 1916,
    provincia_id: 1,
  },
  {
    id: 22452,
    localidad: "LA PLATA",
    cp: 1908,
    provincia_id: 1,
  },
  {
    id: 22453,
    localidad: "LA PLATA",
    cp: 1910,
    provincia_id: 1,
  },
  {
    id: 22454,
    localidad: "LA PLATA",
    cp: 1900,
    provincia_id: 1,
  },
  {
    id: 22455,
    localidad: "LA PLATA",
    cp: 1902,
    provincia_id: 1,
  },
  {
    id: 22456,
    localidad: "LA PLATA",
    cp: 1906,
    provincia_id: 1,
  },
  {
    id: 22457,
    localidad: "LA PLATA",
    cp: 1904,
    provincia_id: 1,
  },
  {
    id: 22458,
    localidad: "LA PIEDRA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22459,
    localidad: "LA PICASA",
    cp: 6036,
    provincia_id: 1,
  },
  {
    id: 22460,
    localidad: "LA PEREGRINA",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 22461,
    localidad: "LA PAZ CHICA",
    cp: 7247,
    provincia_id: 1,
  },
  {
    id: 22462,
    localidad: "LA PAZ",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 22463,
    localidad: "LA PASTORA",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22464,
    localidad: "LA PASTORA",
    cp: 7001,
    provincia_id: 1,
  },
  {
    id: 22465,
    localidad: "LA PARA",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 22466,
    localidad: "LA PAMPITA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22467,
    localidad: "LA PAMPA",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 22468,
    localidad: "LA PALOMA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22469,
    localidad: "LA PALMIRA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22470,
    localidad: "LA PALMA",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22471,
    localidad: "LA ORIENTAL",
    cp: 6022,
    provincia_id: 1,
  },
  {
    id: 22472,
    localidad: "LA NUTRIA",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 22473,
    localidad: "LA NUMANCIA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 22474,
    localidad: "LA NIQA",
    cp: 6513,
    provincia_id: 1,
  },
  {
    id: 22475,
    localidad: "LA NIÑA",
    cp: 6513,
    provincia_id: 1,
  },
  {
    id: 22476,
    localidad: "LA NILDA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22477,
    localidad: "LA NEVADA",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 22478,
    localidad: "LA NELIDA",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 22479,
    localidad: "LA NEGRA",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 22480,
    localidad: "LA NAVARRA",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 22481,
    localidad: "LA NARCISA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22482,
    localidad: "LA NACION",
    cp: 2707,
    provincia_id: 1,
  },
  {
    id: 22483,
    localidad: "LA MODERNA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22484,
    localidad: "LA MATILDE",
    cp: 2931,
    provincia_id: 1,
  },
  {
    id: 22485,
    localidad: "LA MASCOTA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 22486,
    localidad: "LA MASCOTA",
    cp: 8132,
    provincia_id: 1,
  },
  {
    id: 22487,
    localidad: "LA MASCOTA",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 22488,
    localidad: "LA MARTINA",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 22489,
    localidad: "LA MARIANITA",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22490,
    localidad: "LA MARIA LUISA",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22491,
    localidad: "LA MARIA",
    cp: 6640,
    provincia_id: 1,
  },
  {
    id: 22492,
    localidad: "LA MARGARITA",
    cp: 2715,
    provincia_id: 1,
  },
  {
    id: 22493,
    localidad: "LA MARGARITA",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22494,
    localidad: "LA MARGARITA",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 22495,
    localidad: "LA MANUELA",
    cp: 6439,
    provincia_id: 1,
  },
  {
    id: 22496,
    localidad: "LA MANTEQUERIA",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 22497,
    localidad: "LA MAGDALENA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22498,
    localidad: "LA MADRECITA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22499,
    localidad: "LA LUZ",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 22500,
    localidad: "LA LUNA",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 22501,
    localidad: "LA LUISA",
    cp: 2753,
    provincia_id: 1,
  },
  {
    id: 22502,
    localidad: "LA LUCILA DEL MAR",
    cp: 7113,
    provincia_id: 1,
  },
  {
    id: 22503,
    localidad: "LA LUCILA",
    cp: 1637,
    provincia_id: 1,
  },
  {
    id: 22504,
    localidad: "LA LUCIA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22505,
    localidad: "LA LONJA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22506,
    localidad: "LA LOMA",
    cp: 6700,
    provincia_id: 1,
  },
  {
    id: 22507,
    localidad: "LA LIMPIA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22508,
    localidad: "LA LIMPIA",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22509,
    localidad: "LA LIMPIA",
    cp: 6645,
    provincia_id: 1,
  },
  {
    id: 22510,
    localidad: "LA LATA",
    cp: 2820,
    provincia_id: 1,
  },
  {
    id: 22511,
    localidad: "LA LARGA NUEVA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22512,
    localidad: "LA LARGA",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 22513,
    localidad: "LA L LA REFORMA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22514,
    localidad: "LA ISABEL",
    cp: 7212,
    provincia_id: 1,
  },
  {
    id: 22515,
    localidad: "LA INVENCIBLE",
    cp: 2745,
    provincia_id: 1,
  },
  {
    id: 22516,
    localidad: "LA INES",
    cp: 6100,
    provincia_id: 1,
  },
  {
    id: 22517,
    localidad: "LA HORQUETA",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 22518,
    localidad: "LA HORQUETA",
    cp: 7133,
    provincia_id: 1,
  },
  {
    id: 22519,
    localidad: "LA HORQUETA",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22520,
    localidad: "LA HIGUERA",
    cp: 6475,
    provincia_id: 1,
  },
  {
    id: 22521,
    localidad: "LA HERMINIA",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 22522,
    localidad: "LA GUARDIA",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 22523,
    localidad: "LA GREGORIA",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 22524,
    localidad: "LA GLORIA",
    cp: 6665,
    provincia_id: 1,
  },
  {
    id: 22525,
    localidad: "LA GLEVA",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22526,
    localidad: "LA GAVIOTA",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22527,
    localidad: "LA GAVIOTA",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 22528,
    localidad: "LA FLORIDA",
    cp: 7110,
    provincia_id: 1,
  },
  {
    id: 22529,
    localidad: "LA FLORIDA",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 22530,
    localidad: "LA FLORIDA",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 22531,
    localidad: "LA FELICIANA",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 22532,
    localidad: "LA EVA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22533,
    localidad: "LA ETHEL",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22534,
    localidad: "LA ESTRELLA",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 22535,
    localidad: "LA ESTRELLA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22536,
    localidad: "LA ESTANCIA",
    cp: 3760,
    provincia_id: 1,
  },
  {
    id: 22537,
    localidad: "LA ESPZA P LAS F",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 22538,
    localidad: "LA ESPERANZA",
    cp: 2915,
    provincia_id: 1,
  },
  {
    id: 22539,
    localidad: "LA ESPERANZA",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22540,
    localidad: "LA ESPERANZA",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22541,
    localidad: "LA ESP G MADARIAGA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22542,
    localidad: "LA ESMERALDA",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22543,
    localidad: "LA ESMERALDA",
    cp: 3760,
    provincia_id: 1,
  },
  {
    id: 22544,
    localidad: "LA ESCONDIDA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22545,
    localidad: "LA EMILIA",
    cp: 2901,
    provincia_id: 1,
  },
  {
    id: 22546,
    localidad: "LA ELMA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22547,
    localidad: "LA DULCE",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 22548,
    localidad: "LA DULCE",
    cp: 7637,
    provincia_id: 1,
  },
  {
    id: 22549,
    localidad: "LA DORMILONA",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 22550,
    localidad: "LA DORITA",
    cp: 6538,
    provincia_id: 1,
  },
  {
    id: 22551,
    localidad: "LA DESPIERTA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22552,
    localidad: "LA DELIA",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 22553,
    localidad: "LA DELFINA",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 22554,
    localidad: "LA CUMBRE",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22555,
    localidad: "LA COTORRA",
    cp: 6461,
    provincia_id: 1,
  },
  {
    id: 22556,
    localidad: "LA CORINCO",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 22557,
    localidad: "LA CORINA",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 22558,
    localidad: "LA CORA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 22559,
    localidad: "LA COPETA",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 22560,
    localidad: "LA CONSTANCIA",
    cp: 6103,
    provincia_id: 1,
  },
  {
    id: 22561,
    localidad: "LA CONSTANCIA",
    cp: 7153,
    provincia_id: 1,
  },
  {
    id: 22562,
    localidad: "LA COLORADA CHICA",
    cp: 8126,
    provincia_id: 1,
  },
  {
    id: 22563,
    localidad: "LA COLORADA",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 22564,
    localidad: "LA COLORADA",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 22565,
    localidad: "LA COLMENA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22566,
    localidad: "LA COLINA",
    cp: 7408,
    provincia_id: 1,
  },
  {
    id: 22567,
    localidad: "LA CHUMBEADA",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22568,
    localidad: "LA CHUMBEADA",
    cp: 7316,
    provincia_id: 1,
  },
  {
    id: 22569,
    localidad: "LA CHOZA",
    cp: 1737,
    provincia_id: 1,
  },
  {
    id: 22570,
    localidad: "LA CHITA PUELCHES",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22571,
    localidad: "LA CHITA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22572,
    localidad: "LA CHAPELLE",
    cp: 6360,
    provincia_id: 1,
  },
  {
    id: 22573,
    localidad: "LA CENTRAL",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 22574,
    localidad: "LA CELINA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22575,
    localidad: "LA CELIA",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 22576,
    localidad: "LA CARRETA",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 22577,
    localidad: "LA CARMEN",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 22578,
    localidad: "LA CARLOTA",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 22579,
    localidad: "LA CAMPANA",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22580,
    localidad: "LA CALMA",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 22581,
    localidad: "LA CALIFORNIA ARG",
    cp: 6616,
    provincia_id: 1,
  },
  {
    id: 22582,
    localidad: "LA CALETA",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 22583,
    localidad: "LA CALERA - BS AS",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22584,
    localidad: "LA BUANA MOZA",
    cp: 2930,
    provincia_id: 1,
  },
  {
    id: 22585,
    localidad: "LA BRAVA",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 22586,
    localidad: "LA BOLSA",
    cp: 2933,
    provincia_id: 1,
  },
  {
    id: 22587,
    localidad: "LA BLANQUEADA",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 22588,
    localidad: "LA BLANCA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22589,
    localidad: "LA BEBA",
    cp: 6003,
    provincia_id: 1,
  },
  {
    id: 22590,
    localidad: "LA BARRANCOSA",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22591,
    localidad: "LA BANDERITA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22592,
    localidad: "LA BALLENERA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 22593,
    localidad: "LA BALLENA",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 22594,
    localidad: "LA AZUCENA",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 22595,
    localidad: "LA AZOTEA GRANDE",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22596,
    localidad: "LA AZOTEA",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22597,
    localidad: "LA AURORA",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22598,
    localidad: "LA AURORA",
    cp: 8151,
    provincia_id: 1,
  },
  {
    id: 22599,
    localidad: "LA AURORA",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 22600,
    localidad: "LA ASTURIANA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22601,
    localidad: "LA ASTURIANA",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 22602,
    localidad: "LA ARMONIA",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 22603,
    localidad: "LA ANTONIA",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 22604,
    localidad: "LA ANGELITA",
    cp: 6003,
    provincia_id: 1,
  },
  {
    id: 22605,
    localidad: "LA AMORILLA",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 22606,
    localidad: "LA AMISTAD",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22607,
    localidad: "LA AMALIA",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22608,
    localidad: "LA ALCIRA",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22609,
    localidad: "LA ALAMEDA",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 22610,
    localidad: "LA ADELAIDA",
    cp: 6103,
    provincia_id: 1,
  },
  {
    id: 22611,
    localidad: "LA ADELAIDA",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 22612,
    localidad: "LA ADELA",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 22613,
    localidad: "LA  AURORA",
    cp: 7009,
    provincia_id: 1,
  },
  {
    id: 22614,
    localidad: "KRABBE",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 22615,
    localidad: "KM. 45",
    cp: 1635,
    provincia_id: 1,
  },
  {
    id: 22616,
    localidad: "KM. 40",
    cp: 1889,
    provincia_id: 1,
  },
  {
    id: 22617,
    localidad: "KM 77",
    cp: 1737,
    provincia_id: 1,
  },
  {
    id: 22618,
    localidad: "KILOMETRO 95",
    cp: 6031,
    provincia_id: 1,
  },
  {
    id: 22619,
    localidad: "KILOMETRO 92",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 22620,
    localidad: "KILOMETRO 90",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22621,
    localidad: "KILOMETRO 9 SUD",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 22622,
    localidad: "KILOMETRO 88",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22623,
    localidad: "KILOMETRO 83",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22624,
    localidad: "KILOMETRO 82",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 22625,
    localidad: "KILOMETRO 79",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 22626,
    localidad: "KILOMETRO 77",
    cp: 1737,
    provincia_id: 1,
  },
  {
    id: 22627,
    localidad: "KILOMETRO 70",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 22628,
    localidad: "KILOMETRO 697",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 22629,
    localidad: "KILOMETRO 666",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 22630,
    localidad: "KILOMETRO 652",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 22631,
    localidad: "KILOMETRO 58",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 22632,
    localidad: "KILOMETRO 563",
    cp: 8151,
    provincia_id: 1,
  },
  {
    id: 22633,
    localidad: "KILOMETRO 5",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 22634,
    localidad: "KILOMETRO 440",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 22635,
    localidad: "KILOMETRO 44",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 22636,
    localidad: "KILOMETRO 433",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 22637,
    localidad: "KILOMETRO 404",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22638,
    localidad: "KILOMETRO 396",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 22639,
    localidad: "KILOMETRO 393",
    cp: 6467,
    provincia_id: 1,
  },
  {
    id: 22640,
    localidad: "KILOMETRO 386",
    cp: 6457,
    provincia_id: 1,
  },
  {
    id: 22641,
    localidad: "KILOMETRO 36",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 22642,
    localidad: "KILOMETRO 356",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 22643,
    localidad: "KILOMETRO 352",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 22644,
    localidad: "KILOMETRO 333",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 22645,
    localidad: "KILOMETRO 322",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 22646,
    localidad: "KILOMETRO 321",
    cp: 6070,
    provincia_id: 1,
  },
  {
    id: 22647,
    localidad: "KILOMETRO 311",
    cp: 2820,
    provincia_id: 1,
  },
  {
    id: 22648,
    localidad: "KILOMETRO 282",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 22649,
    localidad: "KILOMETRO 212",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 22650,
    localidad: "KILOMETRO 187",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 22651,
    localidad: "KILOMETRO 184",
    cp: 2946,
    provincia_id: 1,
  },
  {
    id: 22652,
    localidad: "KILOMETRO 172",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 22653,
    localidad: "KILOMETRO 146",
    cp: 7226,
    provincia_id: 1,
  },
  {
    id: 22654,
    localidad: "KILOMETRO 128",
    cp: 7221,
    provincia_id: 1,
  },
  {
    id: 22655,
    localidad: "KILOMETRO 125",
    cp: 6600,
    provincia_id: 1,
  },
  {
    id: 22656,
    localidad: "KILOMETRO 125",
    cp: 6720,
    provincia_id: 1,
  },
  {
    id: 22657,
    localidad: "KILOMETRO 117",
    cp: 6603,
    provincia_id: 1,
  },
  {
    id: 22658,
    localidad: "KILOMETRO 116",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22659,
    localidad: "KILOMETRO 112",
    cp: 7240,
    provincia_id: 1,
  },
  {
    id: 22660,
    localidad: "KILOMETRO 11",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 22661,
    localidad: "KILOMETRO 108",
    cp: 6723,
    provincia_id: 1,
  },
  {
    id: 22662,
    localidad: "KILOMETRO 103",
    cp: 1915,
    provincia_id: 1,
  },
  {
    id: 22663,
    localidad: "KILOMETRO 102",
    cp: 2763,
    provincia_id: 1,
  },
  {
    id: 22664,
    localidad: "KENNY",
    cp: 2745,
    provincia_id: 1,
  },
  {
    id: 22665,
    localidad: "JUNIN",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 22666,
    localidad: "JUNIN",
    cp: 6002,
    provincia_id: 1,
  },
  {
    id: 22667,
    localidad: "JUNCAL",
    cp: 2723,
    provincia_id: 1,
  },
  {
    id: 22668,
    localidad: "JULIO ARDITI",
    cp: 1913,
    provincia_id: 1,
  },
  {
    id: 22669,
    localidad: "JULIAN A MANSILLA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22670,
    localidad: "JUANCHO",
    cp: 7169,
    provincia_id: 1,
  },
  {
    id: 22671,
    localidad: "JUANA A DE LA PEÑA",
    cp: 2717,
    provincia_id: 1,
  },
  {
    id: 22672,
    localidad: "JUAN VELA",
    cp: 6663,
    provincia_id: 1,
  },
  {
    id: 22673,
    localidad: "JUAN TRONCONI",
    cp: 7247,
    provincia_id: 1,
  },
  {
    id: 22674,
    localidad: "JUAN N FERNANDEZ",
    cp: 7011,
    provincia_id: 1,
  },
  {
    id: 22675,
    localidad: "JUAN JOSE PASO",
    cp: 6474,
    provincia_id: 1,
  },
  {
    id: 22676,
    localidad: "JUAN JOSE ALMEYRA",
    cp: 6603,
    provincia_id: 1,
  },
  {
    id: 22677,
    localidad: "JUAN JOSE ALMEYRA",
    cp: 6623,
    provincia_id: 1,
  },
  {
    id: 22678,
    localidad: "JUAN G PUJOL",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 22679,
    localidad: "JUAN F IBARRA",
    cp: 6551,
    provincia_id: 1,
  },
  {
    id: 22680,
    localidad: "JUAN E BARRA",
    cp: 7517,
    provincia_id: 1,
  },
  {
    id: 22681,
    localidad: "JUAN COUSTE",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22682,
    localidad: "JUAN COUSTE",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 22683,
    localidad: "JUAN BLAQUIER",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22684,
    localidad: "JUAN BAUTISTA ALBERDI",
    cp: 6034,
    provincia_id: 1,
  },
  {
    id: 22685,
    localidad: "JUAN ATUCHA",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 22686,
    localidad: "JUAN A PRADERE",
    cp: 8255,
    provincia_id: 1,
  },
  {
    id: 22687,
    localidad: "JOSE SOJO",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22688,
    localidad: "JOSE MARMOL",
    cp: 1845,
    provincia_id: 1,
  },
  {
    id: 22689,
    localidad: "JOSE MARIA GUTIERREZ",
    cp: 1890,
    provincia_id: 1,
  },
  {
    id: 22690,
    localidad: "JOSE LEON SUAREZ",
    cp: 1655,
    provincia_id: 1,
  },
  {
    id: 22691,
    localidad: "JOSE INGENIEROS",
    cp: 1703,
    provincia_id: 1,
  },
  {
    id: 22692,
    localidad: "JOSE HERNANDEZ",
    cp: 1903,
    provincia_id: 1,
  },
  {
    id: 22693,
    localidad: "JOSE FERRARI",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 22694,
    localidad: "JOSE CLEMENTE PAZ",
    cp: 1660,
    provincia_id: 1,
  },
  {
    id: 22695,
    localidad: "JOSE CLEMENTE PAZ",
    cp: 1666,
    provincia_id: 1,
  },
  {
    id: 22696,
    localidad: "JOSE CLEMENTE PAZ",
    cp: 1665,
    provincia_id: 1,
  },
  {
    id: 22697,
    localidad: "JOSE B CASAS",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 22698,
    localidad: "JOSE A GUISASOLA",
    cp: 8156,
    provincia_id: 1,
  },
  {
    id: 22699,
    localidad: "JOAQUIN GORINA",
    cp: 1898,
    provincia_id: 1,
  },
  {
    id: 22700,
    localidad: "JEPPENER",
    cp: 1986,
    provincia_id: 1,
  },
  {
    id: 22701,
    localidad: "JELAINA",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 22702,
    localidad: "JAUREGUI JOSE MARIA",
    cp: 6706,
    provincia_id: 1,
  },
  {
    id: 22703,
    localidad: "JARRILLA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 22704,
    localidad: "J M MICHEO",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 22705,
    localidad: "J BAUTISTA ALBERDI",
    cp: 6034,
    provincia_id: 1,
  },
  {
    id: 22706,
    localidad: "J B JUSTO IN WHITE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 22707,
    localidad: "ITUZAINGO",
    cp: 1714,
    provincia_id: 1,
  },
  {
    id: 22708,
    localidad: "ITURREGUI",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 22709,
    localidad: "ISONDU",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22710,
    localidad: "ISLAS",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 22711,
    localidad: "ISLA VERDE",
    cp: 8146,
    provincia_id: 1,
  },
  {
    id: 22712,
    localidad: "ISLA SANTIAGO",
    cp: 1929,
    provincia_id: 1,
  },
  {
    id: 22713,
    localidad: "ISLA LOS LAURELES",
    cp: 2931,
    provincia_id: 1,
  },
  {
    id: 22714,
    localidad: "ISLA EL DORADO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 22715,
    localidad: "ISLA DE LOS LAURELES",
    cp: 2931,
    provincia_id: 1,
  },
  {
    id: 22716,
    localidad: "ISLA CATARELLI",
    cp: 8111,
    provincia_id: 1,
  },
  {
    id: 22717,
    localidad: "ISIDRO CASNOVA",
    cp: 1756,
    provincia_id: 1,
  },
  {
    id: 22718,
    localidad: "ISIDRO CASANOVA",
    cp: 1765,
    provincia_id: 1,
  },
  {
    id: 22719,
    localidad: "IRIARTE",
    cp: 6042,
    provincia_id: 1,
  },
  {
    id: 22720,
    localidad: "IRENEO PORTELA",
    cp: 2943,
    provincia_id: 1,
  },
  {
    id: 22721,
    localidad: "IRENE",
    cp: 7507,
    provincia_id: 1,
  },
  {
    id: 22722,
    localidad: "IRAOLA",
    cp: 7009,
    provincia_id: 1,
  },
  {
    id: 22723,
    localidad: "IRALA",
    cp: 6047,
    provincia_id: 1,
  },
  {
    id: 22724,
    localidad: "INVERNADAS",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22725,
    localidad: "INOCENCIO SOSA",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22726,
    localidad: "INGENIERO WILLIAMS",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22727,
    localidad: "INGENIERO WILLIAMS",
    cp: 6603,
    provincia_id: 1,
  },
  {
    id: 22728,
    localidad: "INGENIERO WHITE",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 22729,
    localidad: "INGENIERO THOMPSON",
    cp: 6337,
    provincia_id: 1,
  },
  {
    id: 22730,
    localidad: "INGENIERO SILVEYRA",
    cp: 6743,
    provincia_id: 1,
  },
  {
    id: 22731,
    localidad: "INGENIERO MONETA",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 22732,
    localidad: "INGENIERO MASCHWITZ",
    cp: 1623,
    provincia_id: 1,
  },
  {
    id: 22733,
    localidad: "INGENIERO DE MADRID",
    cp: 6651,
    provincia_id: 1,
  },
  {
    id: 22734,
    localidad: "INGENIERO BUDGE",
    cp: 1773,
    provincia_id: 1,
  },
  {
    id: 22735,
    localidad: "INGENIERO BEAUGEY",
    cp: 6457,
    provincia_id: 1,
  },
  {
    id: 22736,
    localidad: "INGENIERO BALBIN",
    cp: 6051,
    provincia_id: 1,
  },
  {
    id: 22737,
    localidad: "INGENIERO ALLAN",
    cp: 1891,
    provincia_id: 1,
  },
  {
    id: 22738,
    localidad: "INGENIERO ADOLFO SOURDEAUX",
    cp: 1612,
    provincia_id: 1,
  },
  {
    id: 22739,
    localidad: "ING DE MADRID",
    cp: 6651,
    provincia_id: 1,
  },
  {
    id: 22740,
    localidad: "INES INDART",
    cp: 2747,
    provincia_id: 1,
  },
  {
    id: 22741,
    localidad: "INDIO RICO",
    cp: 7501,
    provincia_id: 1,
  },
  {
    id: 22742,
    localidad: "INDIA MUERTA",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 22743,
    localidad: "INDACOCHEA",
    cp: 6623,
    provincia_id: 1,
  },
  {
    id: 22744,
    localidad: "IGARZABAL",
    cp: 8512,
    provincia_id: 1,
  },
  {
    id: 22745,
    localidad: "IBAQEZ",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22746,
    localidad: "HUSARES",
    cp: 6455,
    provincia_id: 1,
  },
  {
    id: 22747,
    localidad: "HURLINGHAM",
    cp: 1686,
    provincia_id: 1,
  },
  {
    id: 22748,
    localidad: "HUNTER",
    cp: 2707,
    provincia_id: 1,
  },
  {
    id: 22749,
    localidad: "HUETEL",
    cp: 6511,
    provincia_id: 1,
  },
  {
    id: 22750,
    localidad: "HUESO CLAVADO",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22751,
    localidad: "HUANGUELEN",
    cp: 7545,
    provincia_id: 1,
  },
  {
    id: 22752,
    localidad: "HOSPITAL NECOCHEA",
    cp: 7630,
    provincia_id: 1,
  },
  {
    id: 22753,
    localidad: "HORTENSIA",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 22754,
    localidad: "HOGAR M O BASUALDO",
    cp: 7172,
    provincia_id: 1,
  },
  {
    id: 22755,
    localidad: "HINOJO",
    cp: 7318,
    provincia_id: 1,
  },
  {
    id: 22756,
    localidad: "HINOJALES",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22757,
    localidad: "HILARIO ASCASUBI",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 22758,
    localidad: "HERRERA VEGAS",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 22759,
    localidad: "HENRY BELL",
    cp: 6621,
    provincia_id: 1,
  },
  {
    id: 22760,
    localidad: "HENDERSON",
    cp: 6465,
    provincia_id: 1,
  },
  {
    id: 22761,
    localidad: "HEAVY",
    cp: 6723,
    provincia_id: 1,
  },
  {
    id: 22762,
    localidad: "HARAS TRUJUI",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 22763,
    localidad: "HARAS SAN JACINTO",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 22764,
    localidad: "HARAS SAN IGNACIO",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 22765,
    localidad: "HARAS OJO DEL AGUA",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 22766,
    localidad: "HARAS NACIONAL",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 22767,
    localidad: "HARAS LOS CARDALES",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 22768,
    localidad: "HARAS LA LULA",
    cp: 7011,
    provincia_id: 1,
  },
  {
    id: 22769,
    localidad: "HARAS LA ELVIRA",
    cp: 6612,
    provincia_id: 1,
  },
  {
    id: 22770,
    localidad: "HARAS EL SALASO",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 22771,
    localidad: "HARAS EL OMBU",
    cp: 2916,
    provincia_id: 1,
  },
  {
    id: 22772,
    localidad: "HARAS EL MORO",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 22773,
    localidad: "HARAS EL CISNE",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22774,
    localidad: "HARAS EL CENTINELA",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 22775,
    localidad: "HARAS EL CATORCE",
    cp: 6050,
    provincia_id: 1,
  },
  {
    id: 22776,
    localidad: "HARAS EL CARMEN",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 22777,
    localidad: "HARAS CHAPADMALAL",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 22778,
    localidad: "HARAS CHACABUCO",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22779,
    localidad: "HARAS 1 DE MAYO",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 22780,
    localidad: "HALE",
    cp: 6511,
    provincia_id: 1,
  },
  {
    id: 22781,
    localidad: "HALCEY",
    cp: 6064,
    provincia_id: 1,
  },
  {
    id: 22782,
    localidad: "HAEDO",
    cp: 1706,
    provincia_id: 1,
  },
  {
    id: 22783,
    localidad: "H S ANT DE LA LLA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 22784,
    localidad: "H R DE LA PARVA",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 22785,
    localidad: "GUNTHER",
    cp: 6053,
    provincia_id: 1,
  },
  {
    id: 22786,
    localidad: "GUIRONDO",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22787,
    localidad: "GUILLERMO E HUDSON",
    cp: 1885,
    provincia_id: 1,
  },
  {
    id: 22788,
    localidad: "GUIDO SPANO",
    cp: 2707,
    provincia_id: 1,
  },
  {
    id: 22789,
    localidad: "GUERRICO",
    cp: 2717,
    provincia_id: 1,
  },
  {
    id: 22790,
    localidad: "GUERRICO",
    cp: 2706,
    provincia_id: 1,
  },
  {
    id: 22791,
    localidad: "GUERRERO",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 22792,
    localidad: "GUERNICA",
    cp: 1862,
    provincia_id: 1,
  },
  {
    id: 22793,
    localidad: "GUARDIA MITRE",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 22794,
    localidad: "GUARDIA DEL MONTE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22795,
    localidad: "GUANACO",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 22796,
    localidad: "GUANACO",
    cp: 6476,
    provincia_id: 1,
  },
  {
    id: 22797,
    localidad: "GUAMINI",
    cp: 6435,
    provincia_id: 1,
  },
  {
    id: 22798,
    localidad: "GRUNBEIN",
    cp: 8049,
    provincia_id: 1,
  },
  {
    id: 22799,
    localidad: "GRISOLIA",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22800,
    localidad: "GRISOLIA",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 22801,
    localidad: "GREGORIO VILLAFAQE",
    cp: 6740,
    provincia_id: 1,
  },
  {
    id: 22802,
    localidad: "GREGORIO DE LAFERRERE",
    cp: 1757,
    provincia_id: 1,
  },
  {
    id: 22803,
    localidad: "GREGORIO DE LA FERRERE",
    cp: 1757,
    provincia_id: 1,
  },
  {
    id: 22804,
    localidad: "GRAND BOURG",
    cp: 1615,
    provincia_id: 1,
  },
  {
    id: 22805,
    localidad: "GRACIARENA",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 22806,
    localidad: "GOYENECHE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22807,
    localidad: "GOYENA",
    cp: 8175,
    provincia_id: 1,
  },
  {
    id: 22808,
    localidad: "GOWLAND",
    cp: 6608,
    provincia_id: 1,
  },
  {
    id: 22809,
    localidad: "GOUIN",
    cp: 6727,
    provincia_id: 1,
  },
  {
    id: 22810,
    localidad: "GOROSTIAGA",
    cp: 6632,
    provincia_id: 1,
  },
  {
    id: 22811,
    localidad: "GOROSO",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22812,
    localidad: "GORNATTI",
    cp: 2717,
    provincia_id: 1,
  },
  {
    id: 22813,
    localidad: "GORCHS",
    cp: 7226,
    provincia_id: 1,
  },
  {
    id: 22814,
    localidad: "GOQI",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22815,
    localidad: "GONZALEZ RISOS",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 22816,
    localidad: "GONZALEZ MORENO",
    cp: 6239,
    provincia_id: 1,
  },
  {
    id: 22817,
    localidad: "GONZALEZ CATAN",
    cp: 1759,
    provincia_id: 1,
  },
  {
    id: 22818,
    localidad: "GONZALEZ CATAN",
    cp: 1758,
    provincia_id: 1,
  },
  {
    id: 22819,
    localidad: "GONNET",
    cp: 1898,
    provincia_id: 1,
  },
  {
    id: 22820,
    localidad: "GONNET",
    cp: 1897,
    provincia_id: 1,
  },
  {
    id: 22821,
    localidad: "GONDRA",
    cp: 6241,
    provincia_id: 1,
  },
  {
    id: 22822,
    localidad: "GOMEZ  DE LA VEGA",
    cp: 1983,
    provincia_id: 1,
  },
  {
    id: 22823,
    localidad: "GOMEZ  DE LA VEGA",
    cp: 1983,
    provincia_id: 1,
  },
  {
    id: 22824,
    localidad: "GOMEZ",
    cp: 1983,
    provincia_id: 1,
  },
  {
    id: 22825,
    localidad: "GOLDNEY",
    cp: 6614,
    provincia_id: 1,
  },
  {
    id: 22826,
    localidad: "GOBOS",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22827,
    localidad: "GOBERNADOR UGARTE",
    cp: 6621,
    provincia_id: 1,
  },
  {
    id: 22828,
    localidad: "GOBERNADOR UDAONDO",
    cp: 7221,
    provincia_id: 1,
  },
  {
    id: 22829,
    localidad: "GOBERNADOR COSTA",
    cp: 1859,
    provincia_id: 1,
  },
  {
    id: 22830,
    localidad: "GOBERNADOR CASTRO",
    cp: 2946,
    provincia_id: 1,
  },
  {
    id: 22831,
    localidad: "GOBERNADOR AYALA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22832,
    localidad: "GOBERNADOR ARIAS",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 22833,
    localidad: "GOBERNADOR ANDONAEGHI",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 22834,
    localidad: "GNECCO",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 22835,
    localidad: "GLORIALDO",
    cp: 8129,
    provincia_id: 1,
  },
  {
    id: 22836,
    localidad: "GLEW",
    cp: 1856,
    provincia_id: 1,
  },
  {
    id: 22837,
    localidad: "GIRODIAS",
    cp: 6407,
    provincia_id: 1,
  },
  {
    id: 22838,
    localidad: "GIL",
    cp: 8151,
    provincia_id: 1,
  },
  {
    id: 22839,
    localidad: "GERMANIA",
    cp: 6053,
    provincia_id: 1,
  },
  {
    id: 22840,
    localidad: "GERLI",
    cp: 1823,
    provincia_id: 1,
  },
  {
    id: 22841,
    localidad: "GERLI",
    cp: 1869,
    provincia_id: 1,
  },
  {
    id: 22842,
    localidad: "GERENTE CILLEY",
    cp: 6507,
    provincia_id: 1,
  },
  {
    id: 22843,
    localidad: "GENERAL VILLEGAS",
    cp: 6230,
    provincia_id: 1,
  },
  {
    id: 22844,
    localidad: "GENERAL VIAMONTE",
    cp: 6015,
    provincia_id: 1,
  },
  {
    id: 22845,
    localidad: "GENERAL SAN MARTIN",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 22846,
    localidad: "GENERAL RONDEAU",
    cp: 8124,
    provincia_id: 1,
  },
  {
    id: 22847,
    localidad: "GENERAL ROJO",
    cp: 2905,
    provincia_id: 1,
  },
  {
    id: 22848,
    localidad: "GENERAL RODRIGUEZ",
    cp: 1748,
    provincia_id: 1,
  },
  {
    id: 22849,
    localidad: "GENERAL RODRIGUEZ",
    cp: 1747,
    provincia_id: 1,
  },
  {
    id: 22850,
    localidad: "GENERAL RODRIGUEZ",
    cp: 1749,
    provincia_id: 1,
  },
  {
    id: 22851,
    localidad: "GENERAL RODRIGUEZ",
    cp: 1717,
    provincia_id: 1,
  },
  {
    id: 22852,
    localidad: "GENERAL RIVAS",
    cp: 6736,
    provincia_id: 1,
  },
  {
    id: 22853,
    localidad: "GENERAL RIVAS",
    cp: 6614,
    provincia_id: 1,
  },
  {
    id: 22854,
    localidad: "GENERAL PIRAN",
    cp: 7172,
    provincia_id: 1,
  },
  {
    id: 22855,
    localidad: "GENERAL PINTO",
    cp: 6050,
    provincia_id: 1,
  },
  {
    id: 22856,
    localidad: "GENERAL PACHECO",
    cp: 1617,
    provincia_id: 1,
  },
  {
    id: 22857,
    localidad: "GENERAL O BRIEN",
    cp: 6646,
    provincia_id: 1,
  },
  {
    id: 22858,
    localidad: "GENERAL MANSILLA",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 22859,
    localidad: "GENERAL MADARIAGA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22860,
    localidad: "GENERAL LAVALLE",
    cp: 7103,
    provincia_id: 1,
  },
  {
    id: 22861,
    localidad: "GENERAL LAS HERAS",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 22862,
    localidad: "GENERAL LAMADRID",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 22863,
    localidad: "GENERAL HORNOS",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 22864,
    localidad: "GENERAL GUIDO",
    cp: 7118,
    provincia_id: 1,
  },
  {
    id: 22865,
    localidad: "GENERAL GELLY",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 22866,
    localidad: "GENERAL DANIEL CERRI",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 22867,
    localidad: "GENERAL CONESA",
    cp: 2907,
    provincia_id: 1,
  },
  {
    id: 22868,
    localidad: "GENERAL CONESA",
    cp: 7101,
    provincia_id: 1,
  },
  {
    id: 22869,
    localidad: "GENERAL BELGRANO",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 22870,
    localidad: "GENERAL ARENALES",
    cp: 6005,
    provincia_id: 1,
  },
  {
    id: 22871,
    localidad: "GENERAL ALVEAR",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 22872,
    localidad: "GENERAL ALVARADO",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 22873,
    localidad: "GENERA VALDEZ",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 22874,
    localidad: "GARRO",
    cp: 8103,
    provincia_id: 1,
  },
  {
    id: 22875,
    localidad: "GARRE",
    cp: 6411,
    provincia_id: 1,
  },
  {
    id: 22876,
    localidad: "GARIN",
    cp: 1619,
    provincia_id: 1,
  },
  {
    id: 22877,
    localidad: "GARDEY",
    cp: 7030,
    provincia_id: 1,
  },
  {
    id: 22878,
    localidad: "GARDEY",
    cp: 7009,
    provincia_id: 1,
  },
  {
    id: 22879,
    localidad: "GARCIA DEL RIO",
    cp: 8162,
    provincia_id: 1,
  },
  {
    id: 22880,
    localidad: "GANDARA",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 22881,
    localidad: "GALVAN",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 22882,
    localidad: "GALO LLORENTE",
    cp: 6513,
    provincia_id: 1,
  },
  {
    id: 22883,
    localidad: "GALERA DE TORRES",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 22884,
    localidad: "GAHAN",
    cp: 2745,
    provincia_id: 1,
  },
  {
    id: 22885,
    localidad: "GAHAN",
    cp: 2744,
    provincia_id: 1,
  },
  {
    id: 22886,
    localidad: "G ORTIZ DE ROSAS",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22887,
    localidad: "G OBLIGADO",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 22888,
    localidad: "G DANIEL CERRI",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 22889,
    localidad: "G ANDONAEGHI",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 22890,
    localidad: "FUNKE",
    cp: 7220,
    provincia_id: 1,
  },
  {
    id: 22891,
    localidad: "FULTON",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 22892,
    localidad: "FUERTE BARRAGAN",
    cp: 1925,
    provincia_id: 1,
  },
  {
    id: 22893,
    localidad: "FUERTE ARGENTINO",
    cp: 8160,
    provincia_id: 1,
  },
  {
    id: 22894,
    localidad: "FRENCH",
    cp: 6516,
    provincia_id: 1,
  },
  {
    id: 22895,
    localidad: "FRAY CAYETANO ROD.",
    cp: 6120,
    provincia_id: 1,
  },
  {
    id: 22896,
    localidad: "FRANKLIN",
    cp: 6614,
    provincia_id: 1,
  },
  {
    id: 22897,
    localidad: "FRANCISCO MURATURE",
    cp: 6341,
    provincia_id: 1,
  },
  {
    id: 22898,
    localidad: "FRANCISCO MAGNANO",
    cp: 6475,
    provincia_id: 1,
  },
  {
    id: 22899,
    localidad: "FRANCISCO MADERO",
    cp: 6472,
    provincia_id: 1,
  },
  {
    id: 22900,
    localidad: "FRANCISCO J MEEKS",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 22901,
    localidad: "FRANCISCO BERRA",
    cp: 7221,
    provincia_id: 1,
  },
  {
    id: 22902,
    localidad: "FRANCISCO AYERZA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 22903,
    localidad: "FRANCISCO ALVAREZ",
    cp: 1746,
    provincia_id: 1,
  },
  {
    id: 22904,
    localidad: "FORTIN VIGILANCIA",
    cp: 6073,
    provincia_id: 1,
  },
  {
    id: 22905,
    localidad: "FORTIN VIEJO",
    cp: 8148,
    provincia_id: 1,
  },
  {
    id: 22906,
    localidad: "FORTIN TIBURCIO",
    cp: 6001,
    provincia_id: 1,
  },
  {
    id: 22907,
    localidad: "FORTIN TIBURCIO",
    cp: 6024,
    provincia_id: 1,
  },
  {
    id: 22908,
    localidad: "FORTIN OLAVARRIA",
    cp: 6403,
    provincia_id: 1,
  },
  {
    id: 22909,
    localidad: "FORTIN NECOCHEA",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 22910,
    localidad: "FORTIN MERCEDES",
    cp: 8148,
    provincia_id: 1,
  },
  {
    id: 22911,
    localidad: "FORTIN LAVALLE",
    cp: 7404,
    provincia_id: 1,
  },
  {
    id: 22912,
    localidad: "FORTIN IRENE",
    cp: 7316,
    provincia_id: 1,
  },
  {
    id: 22913,
    localidad: "FORTIN CHACO",
    cp: 8160,
    provincia_id: 1,
  },
  {
    id: 22914,
    localidad: "FORTIN ACHA",
    cp: 6031,
    provincia_id: 1,
  },
  {
    id: 22915,
    localidad: "FORTABAT",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 22916,
    localidad: "FONTEZUELA",
    cp: 2718,
    provincia_id: 1,
  },
  {
    id: 22917,
    localidad: "FLORIDA OESTE",
    cp: 1604,
    provincia_id: 1,
  },
  {
    id: 22918,
    localidad: "FLORIDA",
    cp: 1602,
    provincia_id: 1,
  },
  {
    id: 22919,
    localidad: "FLORENTINO AMEGHINO",
    cp: 6064,
    provincia_id: 1,
  },
  {
    id: 22920,
    localidad: "FLORENCIO VARELA",
    cp: 1887,
    provincia_id: 1,
  },
  {
    id: 22921,
    localidad: "FLORENCIO VARELA",
    cp: 1888,
    provincia_id: 1,
  },
  {
    id: 22922,
    localidad: "FLOREN AMEGHINO",
    cp: 6064,
    provincia_id: 1,
  },
  {
    id: 22923,
    localidad: "FLAMENCO",
    cp: 2763,
    provincia_id: 1,
  },
  {
    id: 22924,
    localidad: "FERRE",
    cp: 6027,
    provincia_id: 1,
  },
  {
    id: 22925,
    localidad: "FERRE",
    cp: 6003,
    provincia_id: 1,
  },
  {
    id: 22926,
    localidad: "FERNANDO MARTI",
    cp: 6224,
    provincia_id: 1,
  },
  {
    id: 22927,
    localidad: "FERNANDEZ MORENO",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 22928,
    localidad: "FELIPE SOLA",
    cp: 8129,
    provincia_id: 1,
  },
  {
    id: 22929,
    localidad: "FAUZON",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 22930,
    localidad: "FATRALO",
    cp: 6430,
    provincia_id: 1,
  },
  {
    id: 22931,
    localidad: "FATIMA ESTACION",
    cp: 1633,
    provincia_id: 1,
  },
  {
    id: 22932,
    localidad: "FATIMA",
    cp: 1633,
    provincia_id: 1,
  },
  {
    id: 22933,
    localidad: "FARO SAN ANTONIO",
    cp: 7103,
    provincia_id: 1,
  },
  {
    id: 22934,
    localidad: "FARO QUERANDI",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22935,
    localidad: "FARO",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 22936,
    localidad: "FAIR",
    cp: 7153,
    provincia_id: 1,
  },
  {
    id: 22937,
    localidad: "EZPELETA OESTE",
    cp: 1840,
    provincia_id: 1,
  },
  {
    id: 22938,
    localidad: "EZPELETA ESTE",
    cp: 1882,
    provincia_id: 1,
  },
  {
    id: 22939,
    localidad: "EZEIZA",
    cp: 1804,
    provincia_id: 1,
  },
  {
    id: 22940,
    localidad: "EXALTACION DE LA CRUZ",
    cp: 6703,
    provincia_id: 1,
  },
  {
    id: 22941,
    localidad: "EXALTACION DE LA CRUZ",
    cp: 2812,
    provincia_id: 1,
  },
  {
    id: 22942,
    localidad: "EXALTACION DE LA CRUZ",
    cp: 6730,
    provincia_id: 1,
  },
  {
    id: 22943,
    localidad: "EX E HOGAR NRO 5",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 22944,
    localidad: "EUSKADI",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 22945,
    localidad: "ESTRUGAMOU",
    cp: 7207,
    provincia_id: 1,
  },
  {
    id: 22946,
    localidad: "ESTRELLA NACIENTE",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 22947,
    localidad: "ESTOMBA",
    cp: 8118,
    provincia_id: 1,
  },
  {
    id: 22948,
    localidad: "ESTHER",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 22949,
    localidad: "ESTELA",
    cp: 8127,
    provincia_id: 1,
  },
  {
    id: 22950,
    localidad: "ESTEBAN ECHEVERRIA",
    cp: 1842,
    provincia_id: 1,
  },
  {
    id: 22951,
    localidad: "ESTEBAN DIAZ",
    cp: 6607,
    provincia_id: 1,
  },
  {
    id: 22952,
    localidad: "ESTEBAN DE LUCA",
    cp: 6475,
    provincia_id: 1,
  },
  {
    id: 22953,
    localidad: "ESTEBAN A. GASCON",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 22954,
    localidad: "ESTANCIA VIEJA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 22955,
    localidad: "ESTANCIA LAS GAMAS",
    cp: 2723,
    provincia_id: 1,
  },
  {
    id: 22956,
    localidad: "ESTANCIA CHAPAR",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 22957,
    localidad: "ESTACION RIVADAVIA",
    cp: 6237,
    provincia_id: 1,
  },
  {
    id: 22958,
    localidad: "ESTACION MORENO",
    cp: 1901,
    provincia_id: 1,
  },
  {
    id: 22959,
    localidad: "ESTACION MORENO",
    cp: 1935,
    provincia_id: 1,
  },
  {
    id: 22960,
    localidad: "ESTACION MITIKILI",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 22961,
    localidad: "ESTACION LINCOLN",
    cp: 6070,
    provincia_id: 1,
  },
  {
    id: 22962,
    localidad: "ESTACION LAZZARINO",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 22963,
    localidad: "ESTACION LAGO EPECUEY",
    cp: 6431,
    provincia_id: 1,
  },
  {
    id: 22964,
    localidad: "ESTACION GOMEZ",
    cp: 1918,
    provincia_id: 1,
  },
  {
    id: 22965,
    localidad: "ESTACION GENERAL HORNOS",
    cp: 1739,
    provincia_id: 1,
  },
  {
    id: 22966,
    localidad: "ESTACION BARROW",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 22967,
    localidad: "ESTACION BARADERO",
    cp: 2942,
    provincia_id: 1,
  },
  {
    id: 22968,
    localidad: "ESQUINA DE CROTTO",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 22969,
    localidad: "ESPORA",
    cp: 8107,
    provincia_id: 1,
  },
  {
    id: 22970,
    localidad: "ESPORA",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 22971,
    localidad: "ESPIGAS",
    cp: 6561,
    provincia_id: 1,
  },
  {
    id: 22972,
    localidad: "ESPARTILLAR",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 22973,
    localidad: "ESPARTILLAR",
    cp: 8171,
    provincia_id: 1,
  },
  {
    id: 22974,
    localidad: "ESPARTILLAR",
    cp: 1987,
    provincia_id: 1,
  },
  {
    id: 22975,
    localidad: "ESPADAQA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 22976,
    localidad: "ESCUELA NAVAL MILITAR RIO SANTIAGO",
    cp: 1927,
    provincia_id: 1,
  },
  {
    id: 22977,
    localidad: "ESCRI P NICOLAS",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 22978,
    localidad: "ESCOBAR",
    cp: 1625,
    provincia_id: 1,
  },
  {
    id: 22979,
    localidad: "ESCALADA",
    cp: 2801,
    provincia_id: 1,
  },
  {
    id: 22980,
    localidad: "ES S FCISCO BELLOQ",
    cp: 7505,
    provincia_id: 1,
  },
  {
    id: 22981,
    localidad: "ERNESTINA",
    cp: 6665,
    provincia_id: 1,
  },
  {
    id: 22982,
    localidad: "ERIZE",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 22983,
    localidad: "EREZCANO",
    cp: 2903,
    provincia_id: 1,
  },
  {
    id: 22984,
    localidad: "EPUMER",
    cp: 6443,
    provincia_id: 1,
  },
  {
    id: 22985,
    localidad: "ENSENADA",
    cp: 1926,
    provincia_id: 1,
  },
  {
    id: 22986,
    localidad: "ENSENADA",
    cp: 1925,
    provincia_id: 1,
  },
  {
    id: 22987,
    localidad: "ENRIQUE LAVALLE",
    cp: 6467,
    provincia_id: 1,
  },
  {
    id: 22988,
    localidad: "ENRIQUE FYNN",
    cp: 1741,
    provincia_id: 1,
  },
  {
    id: 22989,
    localidad: "ENERGIA",
    cp: 7640,
    provincia_id: 1,
  },
  {
    id: 22990,
    localidad: "ENERGIA",
    cp: 7641,
    provincia_id: 1,
  },
  {
    id: 22991,
    localidad: "ENCINA",
    cp: 6077,
    provincia_id: 1,
  },
  {
    id: 22992,
    localidad: "EMPALME QUERANDIES",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 22993,
    localidad: "EMPALME P ECHADA",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 22994,
    localidad: "EMPALME MAGDALENA",
    cp: 1948,
    provincia_id: 1,
  },
  {
    id: 22995,
    localidad: "EMPALME LOBOS",
    cp: 7249,
    provincia_id: 1,
  },
  {
    id: 22996,
    localidad: "EMPALME CERRO CHATO",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 22997,
    localidad: "EMPALME C CHATO",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 22998,
    localidad: "EMPAL V CONSTITU",
    cp: 2918,
    provincia_id: 1,
  },
  {
    id: 22999,
    localidad: "EMMA",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 23000,
    localidad: "EMITA",
    cp: 6634,
    provincia_id: 1,
  },
  {
    id: 23001,
    localidad: "EMILIO LAMARCA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 23002,
    localidad: "EMILIO BUNGE",
    cp: 6241,
    provincia_id: 1,
  },
  {
    id: 23003,
    localidad: "EMILIO AYARZA",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 23004,
    localidad: "EMILIANO REYNOSO",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 23005,
    localidad: "ELVIRA",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 23006,
    localidad: "ELVIRA",
    cp: 7246,
    provincia_id: 1,
  },
  {
    id: 23007,
    localidad: "ELORDI",
    cp: 6242,
    provincia_id: 1,
  },
  {
    id: 23008,
    localidad: "EL ZORRO",
    cp: 8151,
    provincia_id: 1,
  },
  {
    id: 23009,
    localidad: "EL VOLANTE",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 23010,
    localidad: "EL VIGILANTE",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 23011,
    localidad: "EL VERANO",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 23012,
    localidad: "EL VERANO",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 23013,
    localidad: "EL VERANEO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 23014,
    localidad: "EL VENCE",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 23015,
    localidad: "EL UNO",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23016,
    localidad: "EL TRIUNFO",
    cp: 6073,
    provincia_id: 1,
  },
  {
    id: 23017,
    localidad: "EL TRIO",
    cp: 6467,
    provincia_id: 1,
  },
  {
    id: 23018,
    localidad: "EL TRIGO",
    cp: 7207,
    provincia_id: 1,
  },
  {
    id: 23019,
    localidad: "EL TRIANGULO",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 23020,
    localidad: "EL TRECE",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23021,
    localidad: "EL TREBAQON",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23022,
    localidad: "EL TEJAR",
    cp: 6515,
    provincia_id: 1,
  },
  {
    id: 23023,
    localidad: "EL TARTAGAL",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23024,
    localidad: "EL TALAR",
    cp: 1618,
    provincia_id: 1,
  },
  {
    id: 23025,
    localidad: "EL SOLDADO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23026,
    localidad: "EL SOCORRO",
    cp: 2715,
    provincia_id: 1,
  },
  {
    id: 23027,
    localidad: "EL SOCORRO",
    cp: 7215,
    provincia_id: 1,
  },
  {
    id: 23028,
    localidad: "EL SILENCIO",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23029,
    localidad: "EL SIASGO",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 23030,
    localidad: "EL SAUCE",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 23031,
    localidad: "EL SANTIAGO",
    cp: 6463,
    provincia_id: 1,
  },
  {
    id: 23032,
    localidad: "EL ROSARIO",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 23033,
    localidad: "EL RINCON",
    cp: 8146,
    provincia_id: 1,
  },
  {
    id: 23034,
    localidad: "EL RINCON",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23035,
    localidad: "EL RETIRO",
    cp: 2741,
    provincia_id: 1,
  },
  {
    id: 23036,
    localidad: "EL REFUGIO",
    cp: 6103,
    provincia_id: 1,
  },
  {
    id: 23037,
    localidad: "EL REFUGIO",
    cp: 7612,
    provincia_id: 1,
  },
  {
    id: 23038,
    localidad: "EL RECADO",
    cp: 6474,
    provincia_id: 1,
  },
  {
    id: 23039,
    localidad: "EL R PD G VIAMONTE",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 23040,
    localidad: "EL QUINGUAL",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 23041,
    localidad: "EL QUEMADO",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23042,
    localidad: "EL PORVENIR",
    cp: 6550,
    provincia_id: 1,
  },
  {
    id: 23043,
    localidad: "EL PITO",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 23044,
    localidad: "EL PITO",
    cp: 7641,
    provincia_id: 1,
  },
  {
    id: 23045,
    localidad: "EL PEREGRINO",
    cp: 6053,
    provincia_id: 1,
  },
  {
    id: 23046,
    localidad: "EL PENSAMIENTO",
    cp: 7531,
    provincia_id: 1,
  },
  {
    id: 23047,
    localidad: "EL PELADO",
    cp: 2720,
    provincia_id: 1,
  },
  {
    id: 23048,
    localidad: "EL PARCHE",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 23049,
    localidad: "EL PARAISO",
    cp: 2916,
    provincia_id: 1,
  },
  {
    id: 23050,
    localidad: "EL PARAISO",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 23051,
    localidad: "EL PAMPERO",
    cp: 1866,
    provincia_id: 1,
  },
  {
    id: 23052,
    localidad: "EL PALOMAR",
    cp: 1684,
    provincia_id: 1,
  },
  {
    id: 23053,
    localidad: "EL PALOMAR",
    cp: 1685,
    provincia_id: 1,
  },
  {
    id: 23054,
    localidad: "EL OASIS",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 23055,
    localidad: "EL NUEVO RINCON",
    cp: 2820,
    provincia_id: 1,
  },
  {
    id: 23056,
    localidad: "EL NUEVE",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23057,
    localidad: "EL NOY",
    cp: 6127,
    provincia_id: 1,
  },
  {
    id: 23058,
    localidad: "EL NILO",
    cp: 6435,
    provincia_id: 1,
  },
  {
    id: 23059,
    localidad: "EL NACIONAL",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 23060,
    localidad: "EL MORO",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 23061,
    localidad: "EL MIRADOR",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 23062,
    localidad: "EL MARQUESADO",
    cp: 7784,
    provincia_id: 1,
  },
  {
    id: 23063,
    localidad: "EL MANGRULLO",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 23064,
    localidad: "EL MADRIGAL",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 23065,
    localidad: "EL LUCHADOR",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 23066,
    localidad: "EL LUCERO",
    cp: 7513,
    provincia_id: 1,
  },
  {
    id: 23067,
    localidad: "EL LIBERTADOR",
    cp: 1692,
    provincia_id: 1,
  },
  {
    id: 23068,
    localidad: "EL LENGUARAZ",
    cp: 7635,
    provincia_id: 1,
  },
  {
    id: 23069,
    localidad: "EL JUPITER",
    cp: 2916,
    provincia_id: 1,
  },
  {
    id: 23070,
    localidad: "EL JUNCO",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 23071,
    localidad: "EL JAGUEL",
    cp: 1842,
    provincia_id: 1,
  },
  {
    id: 23072,
    localidad: "EL JAGUEL",
    cp: 1805,
    provincia_id: 1,
  },
  {
    id: 23073,
    localidad: "EL JAGUEL",
    cp: 2707,
    provincia_id: 1,
  },
  {
    id: 23074,
    localidad: "EL JABALI",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 23075,
    localidad: "EL HUITRU",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23076,
    localidad: "EL HERVIDERO",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 23077,
    localidad: "EL GUALICHO",
    cp: 7200,
    provincia_id: 1,
  },
  {
    id: 23078,
    localidad: "EL GALLO",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23079,
    localidad: "EL FISCO DE FATIMA",
    cp: 8184,
    provincia_id: 1,
  },
  {
    id: 23080,
    localidad: "EL FENIX",
    cp: 2804,
    provincia_id: 1,
  },
  {
    id: 23081,
    localidad: "EL EUCALIPTUS",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23082,
    localidad: "EL ESPINILLO",
    cp: 2946,
    provincia_id: 1,
  },
  {
    id: 23083,
    localidad: "EL DURAZNO",
    cp: 1735,
    provincia_id: 1,
  },
  {
    id: 23084,
    localidad: "EL DORADO",
    cp: 6031,
    provincia_id: 1,
  },
  {
    id: 23085,
    localidad: "EL DIVISORIO",
    cp: 7531,
    provincia_id: 1,
  },
  {
    id: 23086,
    localidad: "EL DIEZ Y SIETE",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23087,
    localidad: "EL DIEZ",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23088,
    localidad: "EL DIA",
    cp: 6241,
    provincia_id: 1,
  },
  {
    id: 23089,
    localidad: "EL DESTINO",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 23090,
    localidad: "EL DESCANSO",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 23091,
    localidad: "EL CRISTIANO",
    cp: 7521,
    provincia_id: 1,
  },
  {
    id: 23092,
    localidad: "EL CORTAPIE",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 23093,
    localidad: "EL CINCO",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23094,
    localidad: "EL CHUMBIAO",
    cp: 7263,
    provincia_id: 1,
  },
  {
    id: 23095,
    localidad: "EL CHEIQUE",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 23096,
    localidad: "EL CHALAR",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 23097,
    localidad: "EL CHAJA",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 23098,
    localidad: "EL CENTINELA",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 23099,
    localidad: "EL CARRETERO",
    cp: 7500,
    provincia_id: 1,
  },
  {
    id: 23100,
    localidad: "EL CARPINCHO",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 23101,
    localidad: "EL CARMEN",
    cp: 1907,
    provincia_id: 1,
  },
  {
    id: 23102,
    localidad: "EL CARMEN",
    cp: 2754,
    provincia_id: 1,
  },
  {
    id: 23103,
    localidad: "EL CARBON",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 23104,
    localidad: "EL CARANCHO",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 23105,
    localidad: "EL C DE LANGUEYU",
    cp: 7203,
    provincia_id: 1,
  },
  {
    id: 23106,
    localidad: "EL BOQUERON",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 23107,
    localidad: "EL BOQUERON",
    cp: 7745,
    provincia_id: 1,
  },
  {
    id: 23108,
    localidad: "EL BOMBERO",
    cp: 7507,
    provincia_id: 1,
  },
  {
    id: 23109,
    localidad: "EL BAGUAL",
    cp: 2723,
    provincia_id: 1,
  },
  {
    id: 23110,
    localidad: "EL BAGUAL",
    cp: 8504,
    provincia_id: 1,
  },
  {
    id: 23111,
    localidad: "EL ARBOLITO",
    cp: 2720,
    provincia_id: 1,
  },
  {
    id: 23112,
    localidad: "EL ARBOL",
    cp: 6127,
    provincia_id: 1,
  },
  {
    id: 23113,
    localidad: "EL ARAZA",
    cp: 7249,
    provincia_id: 1,
  },
  {
    id: 23114,
    localidad: "EL ALBERDON",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 23115,
    localidad: "EL ALBA",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 23116,
    localidad: "EL AGUILA",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 23117,
    localidad: "EL A. PERGAMINO",
    cp: 2721,
    provincia_id: 1,
  },
  {
    id: 23118,
    localidad: "EL 60",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 23119,
    localidad: "EGAQA",
    cp: 7013,
    provincia_id: 1,
  },
  {
    id: 23120,
    localidad: "EGAÑA",
    cp: 7013,
    provincia_id: 1,
  },
  {
    id: 23121,
    localidad: "EDUARDO COSTA",
    cp: 6064,
    provincia_id: 1,
  },
  {
    id: 23122,
    localidad: "EDMUNDO PERKINS",
    cp: 6030,
    provincia_id: 1,
  },
  {
    id: 23123,
    localidad: "E V CONSTITUCION",
    cp: 2919,
    provincia_id: 1,
  },
  {
    id: 23124,
    localidad: "E SANTA CATALINA",
    cp: 2761,
    provincia_id: 1,
  },
  {
    id: 23125,
    localidad: "E SAN RAFAEL",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23126,
    localidad: "E SAN CLAUDIO",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 23127,
    localidad: "E SAN ANTONIO",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 23128,
    localidad: "E provincia_idL",
    cp: 6501,
    provincia_id: 1,
  },
  {
    id: 23129,
    localidad: "E LAS ISLETAS",
    cp: 8148,
    provincia_id: 1,
  },
  {
    id: 23130,
    localidad: "E CORONEL PRINGLES",
    cp: 7536,
    provincia_id: 1,
  },
  {
    id: 23131,
    localidad: "E AGRICOLA RURAL",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 23132,
    localidad: "E AGRI SALESIANA",
    cp: 6509,
    provincia_id: 1,
  },
  {
    id: 23133,
    localidad: "DUSSAUD",
    cp: 6050,
    provincia_id: 1,
  },
  {
    id: 23134,
    localidad: "DURAQONA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 23135,
    localidad: "DURAÑONA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 23136,
    localidad: "DUGGAN",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 23137,
    localidad: "DUFAUR",
    cp: 8164,
    provincia_id: 1,
  },
  {
    id: 23138,
    localidad: "DUDIGNAC",
    cp: 6505,
    provincia_id: 1,
  },
  {
    id: 23139,
    localidad: "DUCOS",
    cp: 8170,
    provincia_id: 1,
  },
  {
    id: 23140,
    localidad: "DRYSDALE",
    cp: 6455,
    provincia_id: 1,
  },
  {
    id: 23141,
    localidad: "DR DGO HAROSTEGUY",
    cp: 7212,
    provincia_id: 1,
  },
  {
    id: 23142,
    localidad: "DOYLE",
    cp: 2953,
    provincia_id: 1,
  },
  {
    id: 23143,
    localidad: "DOYHENARD",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 23144,
    localidad: "DOS NACIONES",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 23145,
    localidad: "DOS HERMANOS",
    cp: 6042,
    provincia_id: 1,
  },
  {
    id: 23146,
    localidad: "DON VICENTE",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 23147,
    localidad: "DON TORCUATO",
    cp: 1611,
    provincia_id: 1,
  },
  {
    id: 23148,
    localidad: "DON CIPRIANO",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 23149,
    localidad: "DON BOSCO",
    cp: 1877,
    provincia_id: 1,
  },
  {
    id: 23150,
    localidad: "DOMSELAAR",
    cp: 1984,
    provincia_id: 1,
  },
  {
    id: 23151,
    localidad: "DOMINGO FAUSTINO SARMIENTO",
    cp: 1707,
    provincia_id: 1,
  },
  {
    id: 23152,
    localidad: "DOLORES",
    cp: 7100,
    provincia_id: 1,
  },
  {
    id: 23153,
    localidad: "DOCTOR DOMINGO HAROSTEGUY",
    cp: 7212,
    provincia_id: 1,
  },
  {
    id: 23154,
    localidad: "DOCK SUD",
    cp: 1871,
    provincia_id: 1,
  },
  {
    id: 23155,
    localidad: "DIQUE LUJAN",
    cp: 1622,
    provincia_id: 1,
  },
  {
    id: 23156,
    localidad: "DIONISIA",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 23157,
    localidad: "DIEGO GAYNOR",
    cp: 2812,
    provincia_id: 1,
  },
  {
    id: 23158,
    localidad: "DESVIO SANDRINI",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 23159,
    localidad: "DESVIO SAN ALEJO",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 23160,
    localidad: "DESVIO KM 55",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 23161,
    localidad: "DESVIO KM 234",
    cp: 6503,
    provincia_id: 1,
  },
  {
    id: 23162,
    localidad: "DESVIO GARBARINI",
    cp: 6509,
    provincia_id: 1,
  },
  {
    id: 23163,
    localidad: "DESVIO EL CHINGOLO",
    cp: 6031,
    provincia_id: 1,
  },
  {
    id: 23164,
    localidad: "DESVIO AGUIRRE",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23165,
    localidad: "DESPEQADEROS",
    cp: 7531,
    provincia_id: 1,
  },
  {
    id: 23166,
    localidad: "DENNEHY",
    cp: 6516,
    provincia_id: 1,
  },
  {
    id: 23167,
    localidad: "DELTA SAN FERNANDO",
    cp: 1647,
    provincia_id: 1,
  },
  {
    id: 23168,
    localidad: "DELTA",
    cp: 1621,
    provincia_id: 1,
  },
  {
    id: 23169,
    localidad: "DELGADO",
    cp: 6007,
    provincia_id: 1,
  },
  {
    id: 23170,
    localidad: "DELFIN HUERGO",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23171,
    localidad: "DEL VISO",
    cp: 1669,
    provincia_id: 1,
  },
  {
    id: 23172,
    localidad: "DEL VALLE",
    cp: 6509,
    provincia_id: 1,
  },
  {
    id: 23173,
    localidad: "DEL CARRIL",
    cp: 7265,
    provincia_id: 1,
  },
  {
    id: 23174,
    localidad: "DE LA GARMA",
    cp: 7515,
    provincia_id: 1,
  },
  {
    id: 23175,
    localidad: "DE LA CANAL",
    cp: 7013,
    provincia_id: 1,
  },
  {
    id: 23176,
    localidad: "DE FERRARI",
    cp: 7549,
    provincia_id: 1,
  },
  {
    id: 23177,
    localidad: "DE BRUYN",
    cp: 6030,
    provincia_id: 1,
  },
  {
    id: 23178,
    localidad: "DE BARY",
    cp: 6348,
    provincia_id: 1,
  },
  {
    id: 23179,
    localidad: "DE BARY",
    cp: 7012,
    provincia_id: 1,
  },
  {
    id: 23180,
    localidad: "DARREGUEIRA",
    cp: 8183,
    provincia_id: 1,
  },
  {
    id: 23181,
    localidad: "DAIREAUX",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 23182,
    localidad: "D ORBIGNY",
    cp: 7541,
    provincia_id: 1,
  },
  {
    id: 23183,
    localidad: "CURARU",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 23184,
    localidad: "CURAPALIGUE",
    cp: 6120,
    provincia_id: 1,
  },
  {
    id: 23185,
    localidad: "CURAMALAN",
    cp: 7548,
    provincia_id: 1,
  },
  {
    id: 23186,
    localidad: "CUCULLU",
    cp: 6723,
    provincia_id: 1,
  },
  {
    id: 23187,
    localidad: "CUCHA CUCHA",
    cp: 6746,
    provincia_id: 1,
  },
  {
    id: 23188,
    localidad: "CUATRO DE FEBRERO",
    cp: 2732,
    provincia_id: 1,
  },
  {
    id: 23189,
    localidad: "CUATREROS",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 23190,
    localidad: "CUARTEL V",
    cp: 1740,
    provincia_id: 1,
  },
  {
    id: 23191,
    localidad: "CUARTEL 8",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 23192,
    localidad: "CUARTEL 6",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 23193,
    localidad: "CUARTEL 2",
    cp: 1987,
    provincia_id: 1,
  },
  {
    id: 23194,
    localidad: "CTRA MONTE CRISTO",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23195,
    localidad: "CTRA LA MOVEDIZA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23196,
    localidad: "CTRA LA FEDERACION",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23197,
    localidad: "CRUCESITA",
    cp: 1873,
    provincia_id: 1,
  },
  {
    id: 23198,
    localidad: "CROTTO",
    cp: 7307,
    provincia_id: 1,
  },
  {
    id: 23199,
    localidad: "CRISTINO BENAVIDEZ",
    cp: 1913,
    provincia_id: 1,
  },
  {
    id: 23200,
    localidad: "CRISTIANO MUERTO",
    cp: 7503,
    provincia_id: 1,
  },
  {
    id: 23201,
    localidad: "CRA. SAMBOROMBON",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23202,
    localidad: "COVELLO",
    cp: 7305,
    provincia_id: 1,
  },
  {
    id: 23203,
    localidad: "COSTA DEL ESTE",
    cp: 7104,
    provincia_id: 1,
  },
  {
    id: 23204,
    localidad: "COSTA BRAVA",
    cp: 2914,
    provincia_id: 1,
  },
  {
    id: 23205,
    localidad: "COSTA BONITA B",
    cp: 7631,
    provincia_id: 1,
  },
  {
    id: 23206,
    localidad: "COSTA AZUL",
    cp: 7112,
    provincia_id: 1,
  },
  {
    id: 23207,
    localidad: "COSTA AZUL",
    cp: 7102,
    provincia_id: 1,
  },
  {
    id: 23208,
    localidad: "CORTINES",
    cp: 6712,
    provincia_id: 1,
  },
  {
    id: 23209,
    localidad: "CORTI",
    cp: 8118,
    provincia_id: 1,
  },
  {
    id: 23210,
    localidad: "CORONEL VIDAL",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 23211,
    localidad: "CORONEL SUAREZ",
    cp: 7540,
    provincia_id: 1,
  },
  {
    id: 23212,
    localidad: "CORONEL SEGUI",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 23213,
    localidad: "CORONEL ROSETI",
    cp: 6106,
    provincia_id: 1,
  },
  {
    id: 23214,
    localidad: "CORONEL RODOLFO BUNGE",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 23215,
    localidad: "CORONEL PRINGLES",
    cp: 7530,
    provincia_id: 1,
  },
  {
    id: 23216,
    localidad: "CORONEL MON",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 23217,
    localidad: "CORONEL MARTINEZ DE HOZ",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 23218,
    localidad: "CORONEL MALDONADO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23219,
    localidad: "CORONEL GRANADA",
    cp: 6062,
    provincia_id: 1,
  },
  {
    id: 23220,
    localidad: "CORONEL FALCON",
    cp: 8118,
    provincia_id: 1,
  },
  {
    id: 23221,
    localidad: "CORONEL E DL BUSTO",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 23222,
    localidad: "CORONEL DORREGO",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 23223,
    localidad: "CORONEL CHARLONE",
    cp: 6223,
    provincia_id: 1,
  },
  {
    id: 23224,
    localidad: "CORONEL BRANDSEN",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 23225,
    localidad: "CORONEL BOERR",
    cp: 7200,
    provincia_id: 1,
  },
  {
    id: 23226,
    localidad: "CORBETT",
    cp: 6507,
    provincia_id: 1,
  },
  {
    id: 23227,
    localidad: "CORAZZI",
    cp: 6405,
    provincia_id: 1,
  },
  {
    id: 23228,
    localidad: "CORACEROS",
    cp: 6465,
    provincia_id: 1,
  },
  {
    id: 23229,
    localidad: "COPETONAS",
    cp: 7511,
    provincia_id: 1,
  },
  {
    id: 23230,
    localidad: "COPACABANA",
    cp: 2919,
    provincia_id: 1,
  },
  {
    id: 23231,
    localidad: "COOPER",
    cp: 7639,
    provincia_id: 1,
  },
  {
    id: 23232,
    localidad: "CONDARCO",
    cp: 6233,
    provincia_id: 1,
  },
  {
    id: 23233,
    localidad: "COMODORO PY",
    cp: 6641,
    provincia_id: 1,
  },
  {
    id: 23234,
    localidad: "COMANDANTE NICANOR OTAMENDI",
    cp: 7603,
    provincia_id: 1,
  },
  {
    id: 23235,
    localidad: "COMANDANTE NICANOR OTAMENDI",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 23236,
    localidad: "COMANDANTE GIRIBONE",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 23237,
    localidad: "COMANDANTE ESPORA",
    cp: 8107,
    provincia_id: 1,
  },
  {
    id: 23238,
    localidad: "COMAHUE OESTE",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 23239,
    localidad: "COM N OTAMENDI",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 23240,
    localidad: "COM GIRIBONE",
    cp: 7135,
    provincia_id: 1,
  },
  {
    id: 23241,
    localidad: "COLONIA ZAMBUNGO",
    cp: 6628,
    provincia_id: 1,
  },
  {
    id: 23242,
    localidad: "COLONIA VELEZ",
    cp: 2933,
    provincia_id: 1,
  },
  {
    id: 23243,
    localidad: "COLONIA TAPATTA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23244,
    localidad: "COLONIA STEGMAN",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23245,
    localidad: "COLONIA SERE",
    cp: 6459,
    provincia_id: 1,
  },
  {
    id: 23246,
    localidad: "COLONIA SERE",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 23247,
    localidad: "COLONIA SANTA ROSA",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 23248,
    localidad: "COLONIA SANTA ROSA",
    cp: 1816,
    provincia_id: 1,
  },
  {
    id: 23249,
    localidad: "COLONIA SANTA ANA",
    cp: 6123,
    provincia_id: 1,
  },
  {
    id: 23250,
    localidad: "COLONIA SAN RAMON",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23251,
    localidad: "COLONIA SAN PEDRO",
    cp: 8164,
    provincia_id: 1,
  },
  {
    id: 23252,
    localidad: "COLONIA SAN MIGUEL",
    cp: 7429,
    provincia_id: 1,
  },
  {
    id: 23253,
    localidad: "COLONIA SAN MARTIN",
    cp: 8164,
    provincia_id: 1,
  },
  {
    id: 23254,
    localidad: "COLONIA SAN FRANCISCO",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 23255,
    localidad: "COLONIA RUSA",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 23256,
    localidad: "COLONIA PHILLIPSON N 1",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23257,
    localidad: "COLONIA PALANTELEN",
    cp: 6643,
    provincia_id: 1,
  },
  {
    id: 23258,
    localidad: "COLONIA OCAMPO",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 23259,
    localidad: "COLONIA NIEVES",
    cp: 7318,
    provincia_id: 1,
  },
  {
    id: 23260,
    localidad: "COLONIA NAVIERA",
    cp: 6341,
    provincia_id: 1,
  },
  {
    id: 23261,
    localidad: "COLONIA MAURICIO",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 23262,
    localidad: "COLONIA LOS TOLDOS",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23263,
    localidad: "COLONIA LOS PIOJOS",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23264,
    localidad: "COLONIA LOS HUESOS",
    cp: 6018,
    provincia_id: 1,
  },
  {
    id: 23265,
    localidad: "COLONIA LOS ALAMOS",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23266,
    localidad: "COLONIA LAS YESCAS",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 23267,
    localidad: "COLONIA LAPIN",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23268,
    localidad: "COLONIA LABORDEROY",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23269,
    localidad: "COLONIA LA REINA",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23270,
    localidad: "COLONIA LA NORIA",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23271,
    localidad: "COLONIA LA NENA",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23272,
    localidad: "COLONIA LA MERCED",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 23273,
    localidad: "COLONIA LA GAVIOTA",
    cp: 6354,
    provincia_id: 1,
  },
  {
    id: 23274,
    localidad: "COLONIA LA CELINA",
    cp: 8508,
    provincia_id: 1,
  },
  {
    id: 23275,
    localidad: "COLONIA INCHAUSTI",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 23276,
    localidad: "COLONIA HINOJO",
    cp: 7318,
    provincia_id: 1,
  },
  {
    id: 23277,
    localidad: "COLONIA FERRARI",
    cp: 7172,
    provincia_id: 1,
  },
  {
    id: 23278,
    localidad: "COLONIA EL PINCEN",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 23279,
    localidad: "COLONIA EL GUANACO",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23280,
    localidad: "COLONIA DELTA",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23281,
    localidad: "COLONIA BEETHOVEN",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 23282,
    localidad: "COLONIA BARGA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23283,
    localidad: "COLONIA ALBERDI",
    cp: 6034,
    provincia_id: 1,
  },
  {
    id: 23284,
    localidad: "COLON",
    cp: 2720,
    provincia_id: 1,
  },
  {
    id: 23285,
    localidad: "COLMAN",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 23286,
    localidad: "COLIQUEO",
    cp: 6743,
    provincia_id: 1,
  },
  {
    id: 23287,
    localidad: "COLEGIO SAN PABLO",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23288,
    localidad: "COLAMN",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 23289,
    localidad: "COCHRANE",
    cp: 8118,
    provincia_id: 1,
  },
  {
    id: 23290,
    localidad: "COCHENELOS",
    cp: 6277,
    provincia_id: 1,
  },
  {
    id: 23291,
    localidad: "COBO",
    cp: 7612,
    provincia_id: 1,
  },
  {
    id: 23292,
    localidad: "CNEL RODOLFO BUNGE",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 23293,
    localidad: "CNEL MART DE HOZ",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 23294,
    localidad: "CNEL M FREYRE",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 23295,
    localidad: "CLAYPOLE",
    cp: 1849,
    provincia_id: 1,
  },
  {
    id: 23296,
    localidad: "CLAVERIE",
    cp: 7163,
    provincia_id: 1,
  },
  {
    id: 23297,
    localidad: "CLAUDIO C MOLINA",
    cp: 7515,
    provincia_id: 1,
  },
  {
    id: 23298,
    localidad: "CLARAZ",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 23299,
    localidad: "CIUDADELA",
    cp: 1701,
    provincia_id: 1,
  },
  {
    id: 23300,
    localidad: "CIUDADELA",
    cp: 1702,
    provincia_id: 1,
  },
  {
    id: 23301,
    localidad: "CIUDAD MADERO",
    cp: 1768,
    provincia_id: 1,
  },
  {
    id: 23302,
    localidad: "CIUDAD JARDIN EL PALOMAR",
    cp: 1685,
    provincia_id: 1,
  },
  {
    id: 23303,
    localidad: "CIUDAD JARDIN DEL PALOMAR",
    cp: 1684,
    provincia_id: 1,
  },
  {
    id: 23304,
    localidad: "CIUDAD EVITA",
    cp: 1778,
    provincia_id: 1,
  },
  {
    id: 23305,
    localidad: "CIUDAD DE EDUARDO MADERO",
    cp: 1768,
    provincia_id: 1,
  },
  {
    id: 23306,
    localidad: "CITY BELL",
    cp: 1896,
    provincia_id: 1,
  },
  {
    id: 23307,
    localidad: "CIERRA CHICA",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 23308,
    localidad: "CHURRUCA",
    cp: 1691,
    provincia_id: 1,
  },
  {
    id: 23309,
    localidad: "CHRISTOPHERSEN",
    cp: 6039,
    provincia_id: 1,
  },
  {
    id: 23310,
    localidad: "CHOIQUE",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 23311,
    localidad: "CHOIQUE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23312,
    localidad: "CHIVILCOY",
    cp: 6620,
    provincia_id: 1,
  },
  {
    id: 23313,
    localidad: "CHIVILCOY",
    cp: 6622,
    provincia_id: 1,
  },
  {
    id: 23314,
    localidad: "CHINA MUERTA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 23315,
    localidad: "CHILLAR",
    cp: 7311,
    provincia_id: 1,
  },
  {
    id: 23316,
    localidad: "CHILAVERT",
    cp: 1655,
    provincia_id: 1,
  },
  {
    id: 23317,
    localidad: "CHICLANA",
    cp: 6476,
    provincia_id: 1,
  },
  {
    id: 23318,
    localidad: "CHENAUT",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 23319,
    localidad: "CHASICO",
    cp: 8017,
    provincia_id: 1,
  },
  {
    id: 23320,
    localidad: "CHASICO",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 23321,
    localidad: "CHASCOMUS",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23322,
    localidad: "CHAS",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 23323,
    localidad: "CHAPAR",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 23324,
    localidad: "CHAPALEOUFU",
    cp: 7201,
    provincia_id: 1,
  },
  {
    id: 23325,
    localidad: "CHAPADMALAL",
    cp: 7619,
    provincia_id: 1,
  },
  {
    id: 23326,
    localidad: "CHAPADMALAL",
    cp: 7605,
    provincia_id: 1,
  },
  {
    id: 23327,
    localidad: "CHANCAY",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 23328,
    localidad: "CHALA QUILCA",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 23329,
    localidad: "CHACRA EXP INTA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 23330,
    localidad: "CHACABUCO",
    cp: 6740,
    provincia_id: 1,
  },
  {
    id: 23331,
    localidad: "CEVIGNE",
    cp: 7101,
    provincia_id: 1,
  },
  {
    id: 23332,
    localidad: "CERRO SOTUYO",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 23333,
    localidad: "CERRO NEGRO",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 23334,
    localidad: "CERRO LA BOTA",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23335,
    localidad: "CERRO DEL AIGRE",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23336,
    localidad: "CERRO BAYO",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23337,
    localidad: "CERRO AZUL",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23338,
    localidad: "CERRO AGUILA",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 23339,
    localidad: "CERRI",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 23340,
    localidad: "CENTRO GUERRERO",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 23341,
    localidad: "CENTRO AGRICOLA EL PATO",
    cp: 1893,
    provincia_id: 1,
  },
  {
    id: 23342,
    localidad: "CENTENARIO",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 23343,
    localidad: "CAZON",
    cp: 7283,
    provincia_id: 1,
  },
  {
    id: 23344,
    localidad: "CASTILLA",
    cp: 6616,
    provincia_id: 1,
  },
  {
    id: 23345,
    localidad: "CASTELLI",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 23346,
    localidad: "CASTELAR",
    cp: 1710,
    provincia_id: 1,
  },
  {
    id: 23347,
    localidad: "CASTELAR",
    cp: 1712,
    provincia_id: 1,
  },
  {
    id: 23348,
    localidad: "CASEY",
    cp: 6417,
    provincia_id: 1,
  },
  {
    id: 23349,
    localidad: "CASEROS",
    cp: 1678,
    provincia_id: 1,
  },
  {
    id: 23350,
    localidad: "CASCADA",
    cp: 7547,
    provincia_id: 1,
  },
  {
    id: 23351,
    localidad: "CASBAS",
    cp: 6417,
    provincia_id: 1,
  },
  {
    id: 23352,
    localidad: "CASALINS",
    cp: 7225,
    provincia_id: 1,
  },
  {
    id: 23353,
    localidad: "CARUPA",
    cp: 1648,
    provincia_id: 1,
  },
  {
    id: 23354,
    localidad: "CARPINTERIA",
    cp: 6341,
    provincia_id: 1,
  },
  {
    id: 23355,
    localidad: "CARMEN DE PATAGONES",
    cp: 8504,
    provincia_id: 1,
  },
  {
    id: 23356,
    localidad: "CARMEN DE ARECO",
    cp: 6725,
    provincia_id: 1,
  },
  {
    id: 23357,
    localidad: "CARLOS TEJEDOR",
    cp: 6455,
    provincia_id: 1,
  },
  {
    id: 23358,
    localidad: "CARLOS SPEGAZZINI",
    cp: 1812,
    provincia_id: 1,
  },
  {
    id: 23359,
    localidad: "CARLOS SPEGAZZINI",
    cp: 1813,
    provincia_id: 1,
  },
  {
    id: 23360,
    localidad: "CARLOS SALAS",
    cp: 6453,
    provincia_id: 1,
  },
  {
    id: 23361,
    localidad: "CARLOS MARIA NAON",
    cp: 6515,
    provincia_id: 1,
  },
  {
    id: 23362,
    localidad: "CARLOS KEEN",
    cp: 6701,
    provincia_id: 1,
  },
  {
    id: 23363,
    localidad: "CARLOS CASARES",
    cp: 6530,
    provincia_id: 1,
  },
  {
    id: 23364,
    localidad: "CARLOS BEGUERIE",
    cp: 7247,
    provincia_id: 1,
  },
  {
    id: 23365,
    localidad: "CARILO",
    cp: 7363,
    provincia_id: 1,
  },
  {
    id: 23366,
    localidad: "CARILO",
    cp: 7167,
    provincia_id: 1,
  },
  {
    id: 23367,
    localidad: "CARI LARQUEA",
    cp: 7119,
    provincia_id: 1,
  },
  {
    id: 23368,
    localidad: "CARHUE",
    cp: 6430,
    provincia_id: 1,
  },
  {
    id: 23369,
    localidad: "CARDENAL CAGLIERO",
    cp: 8506,
    provincia_id: 1,
  },
  {
    id: 23370,
    localidad: "CARAPACHAY",
    cp: 1606,
    provincia_id: 1,
  },
  {
    id: 23371,
    localidad: "CARABELAS",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 23372,
    localidad: "CAQADA SECA",
    cp: 6105,
    provincia_id: 1,
  },
  {
    id: 23373,
    localidad: "CAQADA RICA",
    cp: 6620,
    provincia_id: 1,
  },
  {
    id: 23374,
    localidad: "CAQADA MARIANO",
    cp: 8183,
    provincia_id: 1,
  },
  {
    id: 23375,
    localidad: "CAPITAN SARMIENTO",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23376,
    localidad: "CAPITAN CASTRO",
    cp: 6461,
    provincia_id: 1,
  },
  {
    id: 23377,
    localidad: "CAPILLA DEL SEÑOR",
    cp: 2812,
    provincia_id: 1,
  },
  {
    id: 23378,
    localidad: "CAPDEPONT",
    cp: 6612,
    provincia_id: 1,
  },
  {
    id: 23379,
    localidad: "CAÑUELAS",
    cp: 1814,
    provincia_id: 1,
  },
  {
    id: 23380,
    localidad: "CAÑADON LOPEZ",
    cp: 9009,
    provincia_id: 1,
  },
  {
    id: 23381,
    localidad: "CAÑADA SECA",
    cp: 6105,
    provincia_id: 1,
  },
  {
    id: 23382,
    localidad: "CANTERA SAN LUIS",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23383,
    localidad: "CANTERA LA AURORA",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23384,
    localidad: "CANTERA ALBION",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23385,
    localidad: "CANTERA AGUIRRE",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23386,
    localidad: "CANONIGO GORRITI",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23387,
    localidad: "CANNING",
    cp: 1806,
    provincia_id: 1,
  },
  {
    id: 23388,
    localidad: "CANNING",
    cp: 1807,
    provincia_id: 1,
  },
  {
    id: 23389,
    localidad: "CANNING",
    cp: 1801,
    provincia_id: 1,
  },
  {
    id: 23390,
    localidad: "CANGALLO",
    cp: 7153,
    provincia_id: 1,
  },
  {
    id: 23391,
    localidad: "CANCHA DEL POLLO",
    cp: 1987,
    provincia_id: 1,
  },
  {
    id: 23392,
    localidad: "CAMPODONICO",
    cp: 7305,
    provincia_id: 1,
  },
  {
    id: 23393,
    localidad: "CAMPO SAN JUAN",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23394,
    localidad: "CAMPO SALLES",
    cp: 2903,
    provincia_id: 1,
  },
  {
    id: 23395,
    localidad: "CAMPO SABATE",
    cp: 7245,
    provincia_id: 1,
  },
  {
    id: 23396,
    localidad: "CAMPO ROJAS",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 23397,
    localidad: "CAMPO PEQA LOPEZ",
    cp: 6605,
    provincia_id: 1,
  },
  {
    id: 23398,
    localidad: "CAMPO PELAEZ",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 23399,
    localidad: "CAMPO LOS AROMOS",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23400,
    localidad: "CAMPO LOPE SECO",
    cp: 1980,
    provincia_id: 1,
  },
  {
    id: 23401,
    localidad: "CAMPO LEITE",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 23402,
    localidad: "CAMPO LA PLATA",
    cp: 7623,
    provincia_id: 1,
  },
  {
    id: 23403,
    localidad: "CAMPO LA NENA",
    cp: 2764,
    provincia_id: 1,
  },
  {
    id: 23404,
    localidad: "CAMPO LA LIMA",
    cp: 8150,
    provincia_id: 1,
  },
  {
    id: 23405,
    localidad: "CAMPO LA ELISA",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23406,
    localidad: "CAMPO FUNKE",
    cp: 7247,
    provincia_id: 1,
  },
  {
    id: 23407,
    localidad: "CAMPO DE MAYO",
    cp: 1659,
    provincia_id: 1,
  },
  {
    id: 23408,
    localidad: "CAMPO CRISOL",
    cp: 2754,
    provincia_id: 1,
  },
  {
    id: 23409,
    localidad: "CAMPO BUENA VISTA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 23410,
    localidad: "CAMPO ARISTIMUQO",
    cp: 6474,
    provincia_id: 1,
  },
  {
    id: 23411,
    localidad: "CAMPANA",
    cp: 2804,
    provincia_id: 1,
  },
  {
    id: 23412,
    localidad: "CAMPAMENTO",
    cp: 7613,
    provincia_id: 1,
  },
  {
    id: 23413,
    localidad: "CAMINERA NAPALEOFU",
    cp: 7007,
    provincia_id: 1,
  },
  {
    id: 23414,
    localidad: "CAMINERA JUAREZ",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 23415,
    localidad: "CAMINERA G LOPEZ",
    cp: 2720,
    provincia_id: 1,
  },
  {
    id: 23416,
    localidad: "CAMINERA AZUL",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 23417,
    localidad: "CAMET",
    cp: 7612,
    provincia_id: 1,
  },
  {
    id: 23418,
    localidad: "CAMBACERES",
    cp: 6516,
    provincia_id: 1,
  },
  {
    id: 23419,
    localidad: "CAMARON CHICO",
    cp: 7116,
    provincia_id: 1,
  },
  {
    id: 23420,
    localidad: "CAMAOTI",
    cp: 6537,
    provincia_id: 1,
  },
  {
    id: 23421,
    localidad: "CALVO",
    cp: 8158,
    provincia_id: 1,
  },
  {
    id: 23422,
    localidad: "CALFUCURA",
    cp: 7612,
    provincia_id: 1,
  },
  {
    id: 23423,
    localidad: "CALERA AVELLANEDA",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 23424,
    localidad: "CALDERON",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 23425,
    localidad: "CADRET",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 23426,
    localidad: "CACHARI",
    cp: 7214,
    provincia_id: 1,
  },
  {
    id: 23427,
    localidad: "CABO SAN FERMIN",
    cp: 2703,
    provincia_id: 1,
  },
  {
    id: 23428,
    localidad: "CABILDO",
    cp: 8118,
    provincia_id: 1,
  },
  {
    id: 23429,
    localidad: "C. RODOLFO BUNGE",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 23430,
    localidad: "C SANTA MARIA",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 23431,
    localidad: "C SAN FRANCISCO",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23432,
    localidad: "C SAN FRANCISCO",
    cp: 6017,
    provincia_id: 1,
  },
  {
    id: 23433,
    localidad: "C SAN EDUARDO",
    cp: 6646,
    provincia_id: 1,
  },
  {
    id: 23434,
    localidad: "C PUEBLO RUSO",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 23435,
    localidad: "C PHILLIPSON N 1",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23436,
    localidad: "C N ALEM 2A SEC",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23437,
    localidad: "C N ALEM 1A SEC",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23438,
    localidad: "C MONTE LA PLATA",
    cp: 8144,
    provincia_id: 1,
  },
  {
    id: 23439,
    localidad: "C M ESTEVERENA",
    cp: 8508,
    provincia_id: 1,
  },
  {
    id: 23440,
    localidad: "C LOS TRES USARIS",
    cp: 2760,
    provincia_id: 1,
  },
  {
    id: 23441,
    localidad: "C LIA Y ALLENDE",
    cp: 8200,
    provincia_id: 1,
  },
  {
    id: 23442,
    localidad: "C LA VASCONGADA",
    cp: 8183,
    provincia_id: 1,
  },
  {
    id: 23443,
    localidad: "C LA VANGUARDIA",
    cp: 2711,
    provincia_id: 1,
  },
  {
    id: 23444,
    localidad: "C LA PROVIDENCIA",
    cp: 6120,
    provincia_id: 1,
  },
  {
    id: 23445,
    localidad: "C LA MAGDALENA",
    cp: 6621,
    provincia_id: 1,
  },
  {
    id: 23446,
    localidad: "C LA INVERNADA",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23447,
    localidad: "C LA GRACIELA",
    cp: 8142,
    provincia_id: 1,
  },
  {
    id: 23448,
    localidad: "C LA ESPERANZA",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 23449,
    localidad: "C LA CATALINA",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 23450,
    localidad: "C JULIA Y ECHARREN",
    cp: 8139,
    provincia_id: 1,
  },
  {
    id: 23451,
    localidad: "C HIP YRIGOYEN",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 23452,
    localidad: "C E ARGENTINA",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 23453,
    localidad: "C DR GDOR UDAONDO",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 23454,
    localidad: "C DEL N AMERICANO",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23455,
    localidad: "C DE V CHAPADMALAL",
    cp: 7609,
    provincia_id: 1,
  },
  {
    id: 23456,
    localidad: "C DE NOVIEMBRE",
    cp: 2705,
    provincia_id: 1,
  },
  {
    id: 23457,
    localidad: "C DE LOS LEONES",
    cp: 7000,
    provincia_id: 1,
  },
  {
    id: 23458,
    localidad: "C DE GREGORINI",
    cp: 7401,
    provincia_id: 1,
  },
  {
    id: 23459,
    localidad: "C D LA GLORIA C 15",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 23460,
    localidad: "C CUARENTA Y TRES",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 23461,
    localidad: "C BELLA VISTA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23462,
    localidad: "C BARON HIRSCH",
    cp: 6441,
    provincia_id: 1,
  },
  {
    id: 23463,
    localidad: "C 15 C DE LA GLO",
    cp: 7114,
    provincia_id: 1,
  },
  {
    id: 23464,
    localidad: "BURZACO",
    cp: 1852,
    provincia_id: 1,
  },
  {
    id: 23465,
    localidad: "BUCHANAN",
    cp: 1903,
    provincia_id: 1,
  },
  {
    id: 23466,
    localidad: "BRAGADO",
    cp: 6640,
    provincia_id: 1,
  },
  {
    id: 23467,
    localidad: "BOULOGNE",
    cp: 1609,
    provincia_id: 1,
  },
  {
    id: 23468,
    localidad: "BOSQUES",
    cp: 1889,
    provincia_id: 1,
  },
  {
    id: 23469,
    localidad: "BOSCH",
    cp: 7621,
    provincia_id: 1,
  },
  {
    id: 23470,
    localidad: "BORDEU",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23471,
    localidad: "BORDENAVE",
    cp: 8187,
    provincia_id: 1,
  },
  {
    id: 23472,
    localidad: "BONNEMENT",
    cp: 7223,
    provincia_id: 1,
  },
  {
    id: 23473,
    localidad: "BONIFACIO",
    cp: 6439,
    provincia_id: 1,
  },
  {
    id: 23474,
    localidad: "BOLIVAR",
    cp: 6550,
    provincia_id: 1,
  },
  {
    id: 23475,
    localidad: "BOCAYUBA",
    cp: 7008,
    provincia_id: 1,
  },
  {
    id: 23476,
    localidad: "BME BAVIO GRAL MANSILLA",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 23477,
    localidad: "BME B G MANSILLA",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 23478,
    localidad: "BLONDEAU",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23479,
    localidad: "BLAS DURAQONA",
    cp: 6661,
    provincia_id: 1,
  },
  {
    id: 23480,
    localidad: "BLAQUIER",
    cp: 6065,
    provincia_id: 1,
  },
  {
    id: 23481,
    localidad: "BLANDENGUES",
    cp: 6032,
    provincia_id: 1,
  },
  {
    id: 23482,
    localidad: "BLANCAGRANDE",
    cp: 6561,
    provincia_id: 1,
  },
  {
    id: 23483,
    localidad: "BLANCA GRANDE",
    cp: 6999,
    provincia_id: 1,
  },
  {
    id: 23484,
    localidad: "BILLINGHURST",
    cp: 1650,
    provincia_id: 1,
  },
  {
    id: 23485,
    localidad: "BERUTTI",
    cp: 6424,
    provincia_id: 1,
  },
  {
    id: 23486,
    localidad: "BERRAONDO",
    cp: 8124,
    provincia_id: 1,
  },
  {
    id: 23487,
    localidad: "BERNAL OESTE",
    cp: 1876,
    provincia_id: 1,
  },
  {
    id: 23488,
    localidad: "BERNAL OESTE",
    cp: 1883,
    provincia_id: 1,
  },
  {
    id: 23489,
    localidad: "BERNAL ESTE",
    cp: 1876,
    provincia_id: 1,
  },
  {
    id: 23490,
    localidad: "BERMUDEZ",
    cp: 6071,
    provincia_id: 1,
  },
  {
    id: 23491,
    localidad: "BERISSO",
    cp: 1923,
    provincia_id: 1,
  },
  {
    id: 23492,
    localidad: "BERISSO",
    cp: 1924,
    provincia_id: 1,
  },
  {
    id: 23493,
    localidad: "BERISO",
    cp: 1924,
    provincia_id: 1,
  },
  {
    id: 23494,
    localidad: "BERDIER",
    cp: 2282,
    provincia_id: 1,
  },
  {
    id: 23495,
    localidad: "BERAZATEGUI",
    cp: 1884,
    provincia_id: 1,
  },
  {
    id: 23496,
    localidad: "BERAZATEGUI",
    cp: 1880,
    provincia_id: 1,
  },
  {
    id: 23497,
    localidad: "BENITO JUAREZ",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 23498,
    localidad: "BENITEZ",
    cp: 6632,
    provincia_id: 1,
  },
  {
    id: 23499,
    localidad: "BENAVIDEZ",
    cp: 1621,
    provincia_id: 1,
  },
  {
    id: 23500,
    localidad: "BELLOCQ",
    cp: 6535,
    provincia_id: 1,
  },
  {
    id: 23501,
    localidad: "BELLA VISTA",
    cp: 1661,
    provincia_id: 1,
  },
  {
    id: 23502,
    localidad: "BELLA VISTA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23503,
    localidad: "BELEN DE ESCOBAR",
    cp: 1625,
    provincia_id: 1,
  },
  {
    id: 23504,
    localidad: "BECCAR",
    cp: 1643,
    provincia_id: 1,
  },
  {
    id: 23505,
    localidad: "BAYAUCA",
    cp: 6078,
    provincia_id: 1,
  },
  {
    id: 23506,
    localidad: "BAUDRIX",
    cp: 6643,
    provincia_id: 1,
  },
  {
    id: 23507,
    localidad: "BATHURST ESTACION",
    cp: 7540,
    provincia_id: 1,
  },
  {
    id: 23508,
    localidad: "BATERIAS",
    cp: 8113,
    provincia_id: 1,
  },
  {
    id: 23509,
    localidad: "BATAN",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 23510,
    localidad: "BARTOLOME BAVIO",
    cp: 1911,
    provincia_id: 1,
  },
  {
    id: 23511,
    localidad: "BARRIO VILLA MUQIZ",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23512,
    localidad: "BARRIO V SALADILLO",
    cp: 7260,
    provincia_id: 1,
  },
  {
    id: 23513,
    localidad: "BARRIO V ORTEGA",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 23514,
    localidad: "BARRIO V FERNANDEZ",
    cp: 3760,
    provincia_id: 1,
  },
  {
    id: 23515,
    localidad: "BARRIO U O C R A",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 23516,
    localidad: "BARRIO TROCHA",
    cp: 2700,
    provincia_id: 1,
  },
  {
    id: 23517,
    localidad: "BARRIO SARMIENTO DON ROLANDO",
    cp: 1789,
    provincia_id: 1,
  },
  {
    id: 23518,
    localidad: "BARRIO SANTA CATALINA",
    cp: 1781,
    provincia_id: 1,
  },
  {
    id: 23519,
    localidad: "BARRIO SAN ROQUE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23520,
    localidad: "BARRIO SAN MARTIN",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23521,
    localidad: "BARRIO SAN JOSE",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23522,
    localidad: "BARRIO SAN CARLOS",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23523,
    localidad: "BARRIO SAN BLAS",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23524,
    localidad: "BARRIO S CAYETANO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23525,
    localidad: "BARRIO ROSARIO SUD",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23526,
    localidad: "BARRIO PINARES",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23527,
    localidad: "BARRIO PEDRO ROCCO",
    cp: 7109,
    provincia_id: 1,
  },
  {
    id: 23528,
    localidad: "BARRIO PEDRO RICO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23529,
    localidad: "BARRIO PARQUE LELOIR",
    cp: 1715,
    provincia_id: 1,
  },
  {
    id: 23530,
    localidad: "BARRIO OESTE",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 23531,
    localidad: "BARRIO OBRERO",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 23532,
    localidad: "BARRIO NOROESTE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23533,
    localidad: "BARRIO LOS AROMOS SAN PATRICIO",
    cp: 1792,
    provincia_id: 1,
  },
  {
    id: 23534,
    localidad: "BARRIO LOS ANDES",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23535,
    localidad: "BARRIO LA LUISA",
    cp: 7400,
    provincia_id: 1,
  },
  {
    id: 23536,
    localidad: "BARRIO LA LEÑERA",
    cp: 3760,
    provincia_id: 1,
  },
  {
    id: 23537,
    localidad: "BARRIO LA FALDA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23538,
    localidad: "BARRIO LA DOLLY",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 23539,
    localidad: "BARRIO JURAMENTO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23540,
    localidad: "BARRIO JUAREZ",
    cp: 7020,
    provincia_id: 1,
  },
  {
    id: 23541,
    localidad: "BARRIO EL ZORZAL",
    cp: 1793,
    provincia_id: 1,
  },
  {
    id: 23542,
    localidad: "BARRIO EL HUECO",
    cp: 7130,
    provincia_id: 1,
  },
  {
    id: 23543,
    localidad: "BARRIO EL CAZADOR",
    cp: 1625,
    provincia_id: 1,
  },
  {
    id: 23544,
    localidad: "BARRIO EL CAZADOR",
    cp: 1626,
    provincia_id: 1,
  },
  {
    id: 23545,
    localidad: "BARRIO DON ORIONE",
    cp: 1850,
    provincia_id: 1,
  },
  {
    id: 23546,
    localidad: "BARRIO D. ANTONIO",
    cp: 2914,
    provincia_id: 1,
  },
  {
    id: 23547,
    localidad: "BARRIO CHAPADMALAL",
    cp: 7616,
    provincia_id: 1,
  },
  {
    id: 23548,
    localidad: "BARRIO CAROSIO",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 23549,
    localidad: "BARRIO CAISAMAR",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23550,
    localidad: "BARRIO BATAN",
    cp: 7601,
    provincia_id: 1,
  },
  {
    id: 23551,
    localidad: "BARRIO AVELLANEDA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23552,
    localidad: "BARRIO ALMAFUERTE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23553,
    localidad: "BARRIENTOS",
    cp: 7247,
    provincia_id: 1,
  },
  {
    id: 23554,
    localidad: "BARKER",
    cp: 7005,
    provincia_id: 1,
  },
  {
    id: 23555,
    localidad: "BARKER",
    cp: 7033,
    provincia_id: 1,
  },
  {
    id: 23556,
    localidad: "BARADERO",
    cp: 2942,
    provincia_id: 1,
  },
  {
    id: 23557,
    localidad: "BANFIELD",
    cp: 1828,
    provincia_id: 1,
  },
  {
    id: 23558,
    localidad: "BANFIELD",
    cp: 1827,
    provincia_id: 1,
  },
  {
    id: 23559,
    localidad: "BANFIELD",
    cp: 1821,
    provincia_id: 1,
  },
  {
    id: 23560,
    localidad: "BANDERALO",
    cp: 6244,
    provincia_id: 1,
  },
  {
    id: 23561,
    localidad: "BALSA",
    cp: 6070,
    provincia_id: 1,
  },
  {
    id: 23562,
    localidad: "BALO LOS MORROS",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 23563,
    localidad: "BALNEARIO RETA",
    cp: 7511,
    provincia_id: 1,
  },
  {
    id: 23564,
    localidad: "BALNEARIO PARADA",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 23565,
    localidad: "BALNEARIO ORIENTE",
    cp: 8153,
    provincia_id: 1,
  },
  {
    id: 23566,
    localidad: "BALNEARIO ORENSE",
    cp: 7511,
    provincia_id: 1,
  },
  {
    id: 23567,
    localidad: "BALNEARIO OCEANO",
    cp: 7511,
    provincia_id: 1,
  },
  {
    id: 23568,
    localidad: "BALNEARIO CLAROMECO",
    cp: 7505,
    provincia_id: 1,
  },
  {
    id: 23569,
    localidad: "BALCARCE",
    cp: 7620,
    provincia_id: 1,
  },
  {
    id: 23570,
    localidad: "BAJO HONDO",
    cp: 8115,
    provincia_id: 1,
  },
  {
    id: 23571,
    localidad: "BAIGORRITA",
    cp: 6013,
    provincia_id: 1,
  },
  {
    id: 23572,
    localidad: "BAHIA SAN BLAS",
    cp: 8506,
    provincia_id: 1,
  },
  {
    id: 23573,
    localidad: "BAHIA BLANCA",
    cp: 8001,
    provincia_id: 1,
  },
  {
    id: 23574,
    localidad: "BAHIA BLANCA",
    cp: 8003,
    provincia_id: 1,
  },
  {
    id: 23575,
    localidad: "BAHIA BLANCA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23576,
    localidad: "BAHIA BLANCA",
    cp: 8002,
    provincia_id: 1,
  },
  {
    id: 23577,
    localidad: "BAHIA BLANCA",
    cp: 8100,
    provincia_id: 1,
  },
  {
    id: 23578,
    localidad: "BAGUES",
    cp: 2760,
    provincia_id: 1,
  },
  {
    id: 23579,
    localidad: "BACACAY",
    cp: 6516,
    provincia_id: 1,
  },
  {
    id: 23580,
    localidad: "B VISTA ALEGRE",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23581,
    localidad: "B VERA Y PINTADO",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 23582,
    localidad: "B TIRO FEDERAL",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23583,
    localidad: "B TIRO FEDERAL",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23584,
    localidad: "B TIERRA DE ORO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23585,
    localidad: "B SAUCE GRANDE",
    cp: 8153,
    provincia_id: 1,
  },
  {
    id: 23586,
    localidad: "B SARMIENTO DON ROLANDO",
    cp: 1789,
    provincia_id: 1,
  },
  {
    id: 23587,
    localidad: "B SANTA ELENA",
    cp: 7794,
    provincia_id: 1,
  },
  {
    id: 23588,
    localidad: "B PUEBLO NUEVO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23589,
    localidad: "B PRIMERA JUNTA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23590,
    localidad: "B PLAYA DORADA",
    cp: 7796,
    provincia_id: 1,
  },
  {
    id: 23591,
    localidad: "B PARQUE PATAGONIA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23592,
    localidad: "B PARQUE BRISTOL",
    cp: 7607,
    provincia_id: 1,
  },
  {
    id: 23593,
    localidad: "B NUESTRA SEÑORA DE LA PAZ",
    cp: 1780,
    provincia_id: 1,
  },
  {
    id: 23594,
    localidad: "B NAVAL AZOPARDO",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 23595,
    localidad: "B MAR DE COBO",
    cp: 7788,
    provincia_id: 1,
  },
  {
    id: 23596,
    localidad: "B MAR CHIQUITA",
    cp: 7792,
    provincia_id: 1,
  },
  {
    id: 23597,
    localidad: "B LOS ANGELES",
    cp: 7641,
    provincia_id: 1,
  },
  {
    id: 23598,
    localidad: "B LOMA PARAGUAYA",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23599,
    localidad: "B LAS MANDARINAS",
    cp: 1981,
    provincia_id: 1,
  },
  {
    id: 23600,
    localidad: "B LA CALETA",
    cp: 7797,
    provincia_id: 1,
  },
  {
    id: 23601,
    localidad: "B LA BALIZA",
    cp: 7785,
    provincia_id: 1,
  },
  {
    id: 23602,
    localidad: "B JULIO DE VEDIA",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 23603,
    localidad: "B JUAN B JUSTO",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23604,
    localidad: "B JOSE M ESTRADA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23605,
    localidad: "B GENERAL S MARTIN",
    cp: 6000,
    provincia_id: 1,
  },
  {
    id: 23606,
    localidad: "B GENERAL ROCA",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23607,
    localidad: "B GASTRONOMICO",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23608,
    localidad: "B FRENTE MAR",
    cp: 7802,
    provincia_id: 1,
  },
  {
    id: 23609,
    localidad: "B EMIR R JUAREZ",
    cp: 7600,
    provincia_id: 1,
  },
  {
    id: 23610,
    localidad: "B DE LA TRAVESIA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 23611,
    localidad: "B COLORADAS",
    cp: 8201,
    provincia_id: 1,
  },
  {
    id: 23612,
    localidad: "B CLAROMECO",
    cp: 7505,
    provincia_id: 1,
  },
  {
    id: 23613,
    localidad: "B CAMET NORTE",
    cp: 7803,
    provincia_id: 1,
  },
  {
    id: 23614,
    localidad: "B ATLANTIDA",
    cp: 7807,
    provincia_id: 1,
  },
  {
    id: 23615,
    localidad: "B A. CMTE ESPORA",
    cp: 8107,
    provincia_id: 1,
  },
  {
    id: 23616,
    localidad: "B A PUNTA INDIO",
    cp: 1919,
    provincia_id: 1,
  },
  {
    id: 23617,
    localidad: "AZUL",
    cp: 7300,
    provincia_id: 1,
  },
  {
    id: 23618,
    localidad: "AZOPARDO",
    cp: 8181,
    provincia_id: 1,
  },
  {
    id: 23619,
    localidad: "AZCUENAGA",
    cp: 6729,
    provincia_id: 1,
  },
  {
    id: 23620,
    localidad: "AZCUENAGA",
    cp: 6721,
    provincia_id: 1,
  },
  {
    id: 23621,
    localidad: "AYACUCHO",
    cp: 7150,
    provincia_id: 1,
  },
  {
    id: 23622,
    localidad: "AVESTRUZ",
    cp: 8185,
    provincia_id: 1,
  },
  {
    id: 23623,
    localidad: "AVELLANEDA",
    cp: 1870,
    provincia_id: 1,
  },
  {
    id: 23624,
    localidad: "ATUCHA",
    cp: 2800,
    provincia_id: 1,
  },
  {
    id: 23625,
    localidad: "ATUCHA",
    cp: 2808,
    provincia_id: 1,
  },
  {
    id: 23626,
    localidad: "ATALAYA",
    cp: 1947,
    provincia_id: 1,
  },
  {
    id: 23627,
    localidad: "ATAHUALPA",
    cp: 6471,
    provincia_id: 1,
  },
  {
    id: 23628,
    localidad: "ASTURIAS",
    cp: 6469,
    provincia_id: 1,
  },
  {
    id: 23629,
    localidad: "ASCENCION",
    cp: 6003,
    provincia_id: 1,
  },
  {
    id: 23630,
    localidad: "ASAMBLEA",
    cp: 6640,
    provincia_id: 1,
  },
  {
    id: 23631,
    localidad: "ARTURO VATTEGNE",
    cp: 6433,
    provincia_id: 1,
  },
  {
    id: 23632,
    localidad: "ARTURO SEGUI",
    cp: 1895,
    provincia_id: 1,
  },
  {
    id: 23633,
    localidad: "ARROYO ZANJON",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23634,
    localidad: "ARROYO VENADO",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23635,
    localidad: "ARROYO TAJIBER",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23636,
    localidad: "ARROYO PLATITOS",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23637,
    localidad: "ARROYO PITO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23638,
    localidad: "ARROYO PESQUERIA",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23639,
    localidad: "ARROYO PEREYRA",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23640,
    localidad: "ARROYO PAREJA",
    cp: 8111,
    provincia_id: 1,
  },
  {
    id: 23641,
    localidad: "ARROYO NEGRO",
    cp: 2800,
    provincia_id: 1,
  },
  {
    id: 23642,
    localidad: "ARROYO MOSQUITO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23643,
    localidad: "ARROYO MERLO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23644,
    localidad: "ARROYO MANZANO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23645,
    localidad: "ARROYO LOS TIGRES",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23646,
    localidad: "ARROYO LOS PLATOS",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23647,
    localidad: "ARROYO LOS HUESOS",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 23648,
    localidad: "ARROYO LLORONES",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23649,
    localidad: "ARROYO LAS CRUCES",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23650,
    localidad: "ARROYO LAS ANIMAS",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23651,
    localidad: "ARROYO LARA",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23652,
    localidad: "ARROYO IBICUYCITO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23653,
    localidad: "ARROYO GRANDE",
    cp: 7174,
    provincia_id: 1,
  },
  {
    id: 23654,
    localidad: "ARROYO GARCETE",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23655,
    localidad: "ARROYO EL CHINGOLO",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23656,
    localidad: "ARROYO EL AHOGADO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23657,
    localidad: "ARROYO DULCE",
    cp: 2743,
    provincia_id: 1,
  },
  {
    id: 23658,
    localidad: "ARROYO DE LUNA",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23659,
    localidad: "ARROYO DE LOS HUESOS",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 23660,
    localidad: "ARROYO DE LA CRUZ",
    cp: 2812,
    provincia_id: 1,
  },
  {
    id: 23661,
    localidad: "ARROYO DE LA CRUZ",
    cp: 2813,
    provincia_id: 1,
  },
  {
    id: 23662,
    localidad: "ARROYO CUCHARAS",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23663,
    localidad: "ARROYO CORTO",
    cp: 8172,
    provincia_id: 1,
  },
  {
    id: 23664,
    localidad: "ARROYO CHICO",
    cp: 7011,
    provincia_id: 1,
  },
  {
    id: 23665,
    localidad: "ARROYO CEIBITO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23666,
    localidad: "ARROYO CARABELITAS",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23667,
    localidad: "ARROYO BURGOS",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 23668,
    localidad: "ARROYO BRASILERO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23669,
    localidad: "ARROYO BICHO FEO",
    cp: 1649,
    provincia_id: 1,
  },
  {
    id: 23670,
    localidad: "ARROYO ALELI",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23671,
    localidad: "ARRIBEÑOS",
    cp: 6007,
    provincia_id: 1,
  },
  {
    id: 23672,
    localidad: "ARRECIFES",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 23673,
    localidad: "ARQUEDAS",
    cp: 8164,
    provincia_id: 1,
  },
  {
    id: 23674,
    localidad: "ARIEL",
    cp: 7301,
    provincia_id: 1,
  },
  {
    id: 23675,
    localidad: "ARIAS",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 23676,
    localidad: "ARGERICH",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 23677,
    localidad: "AREVALO",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 23678,
    localidad: "ARENAZA",
    cp: 6075,
    provincia_id: 1,
  },
  {
    id: 23679,
    localidad: "ARBUCO",
    cp: 1915,
    provincia_id: 1,
  },
  {
    id: 23680,
    localidad: "ARBOLEDAS",
    cp: 6557,
    provincia_id: 1,
  },
  {
    id: 23681,
    localidad: "ARBOLEDA",
    cp: 6593,
    provincia_id: 1,
  },
  {
    id: 23682,
    localidad: "ARAUJO",
    cp: 6643,
    provincia_id: 1,
  },
  {
    id: 23683,
    localidad: "ARANO",
    cp: 6443,
    provincia_id: 1,
  },
  {
    id: 23684,
    localidad: "ARANA",
    cp: 1909,
    provincia_id: 1,
  },
  {
    id: 23685,
    localidad: "APARICIO",
    cp: 8158,
    provincia_id: 1,
  },
  {
    id: 23686,
    localidad: "ANZOATEGUI",
    cp: 8138,
    provincia_id: 1,
  },
  {
    id: 23687,
    localidad: "ANTONIO CARBONI",
    cp: 7243,
    provincia_id: 1,
  },
  {
    id: 23688,
    localidad: "ANT DE LOS HEROS",
    cp: 7305,
    provincia_id: 1,
  },
  {
    id: 23689,
    localidad: "ANEQUE GRANDE",
    cp: 7011,
    provincia_id: 1,
  },
  {
    id: 23690,
    localidad: "ANDRES VACCOREZZA",
    cp: 6634,
    provincia_id: 1,
  },
  {
    id: 23691,
    localidad: "ANDERSON",
    cp: 6434,
    provincia_id: 1,
  },
  {
    id: 23692,
    localidad: "ANDERSON",
    cp: 6621,
    provincia_id: 1,
  },
  {
    id: 23693,
    localidad: "ANDANT",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 23694,
    localidad: "ANCON",
    cp: 6451,
    provincia_id: 1,
  },
  {
    id: 23695,
    localidad: "ANASAGASTI",
    cp: 6607,
    provincia_id: 1,
  },
  {
    id: 23696,
    localidad: "AMERICA UNIDA",
    cp: 1835,
    provincia_id: 1,
  },
  {
    id: 23697,
    localidad: "AMERICA",
    cp: 6237,
    provincia_id: 1,
  },
  {
    id: 23698,
    localidad: "AMBROSIO P LEZICA",
    cp: 8505,
    provincia_id: 1,
  },
  {
    id: 23699,
    localidad: "AMALIA",
    cp: 6516,
    provincia_id: 1,
  },
  {
    id: 23700,
    localidad: "ALZAGA",
    cp: 7021,
    provincia_id: 1,
  },
  {
    id: 23701,
    localidad: "ALVARO BARROS",
    cp: 7403,
    provincia_id: 1,
  },
  {
    id: 23702,
    localidad: "ALVAREZ JONTE",
    cp: 1921,
    provincia_id: 1,
  },
  {
    id: 23703,
    localidad: "ALVAREZ DE TOLEDO",
    cp: 7282,
    provincia_id: 1,
  },
  {
    id: 23704,
    localidad: "ALTONA",
    cp: 7303,
    provincia_id: 1,
  },
  {
    id: 23705,
    localidad: "ALTO VERDE",
    cp: 2801,
    provincia_id: 1,
  },
  {
    id: 23706,
    localidad: "ALTO LOS CARDALES",
    cp: 2816,
    provincia_id: 1,
  },
  {
    id: 23707,
    localidad: "ALTAMIRANO",
    cp: 1986,
    provincia_id: 1,
  },
  {
    id: 23708,
    localidad: "ALTAMIRA",
    cp: 6601,
    provincia_id: 1,
  },
  {
    id: 23709,
    localidad: "ALTAMIRA",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 23710,
    localidad: "ALTA VISTA",
    cp: 8180,
    provincia_id: 1,
  },
  {
    id: 23711,
    localidad: "ALTA VISTA",
    cp: 8170,
    provincia_id: 1,
  },
  {
    id: 23712,
    localidad: "ALSINA",
    cp: 2938,
    provincia_id: 1,
  },
  {
    id: 23713,
    localidad: "ALMIRANTE SOLIER",
    cp: 8109,
    provincia_id: 1,
  },
  {
    id: 23714,
    localidad: "ALMIRANTE BROWN",
    cp: 1846,
    provincia_id: 1,
  },
  {
    id: 23715,
    localidad: "ALMACEN PIATTI",
    cp: 2711,
    provincia_id: 1,
  },
  {
    id: 23716,
    localidad: "ALMACEN LA COLINA",
    cp: 2740,
    provincia_id: 1,
  },
  {
    id: 23717,
    localidad: "ALMACEN EL CRUCE",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23718,
    localidad: "ALMACEN CASTRO",
    cp: 2751,
    provincia_id: 1,
  },
  {
    id: 23719,
    localidad: "ALMA GRANDE",
    cp: 9015,
    provincia_id: 1,
  },
  {
    id: 23720,
    localidad: "ALGARROBO",
    cp: 8132,
    provincia_id: 1,
  },
  {
    id: 23721,
    localidad: "ALGARROBO",
    cp: 8136,
    provincia_id: 1,
  },
  {
    id: 23722,
    localidad: "ALGARROBO",
    cp: 2935,
    provincia_id: 1,
  },
  {
    id: 23723,
    localidad: "ALGARROBO",
    cp: 6531,
    provincia_id: 1,
  },
  {
    id: 23724,
    localidad: "ALFREDO DEMARCHI",
    cp: 6533,
    provincia_id: 1,
  },
  {
    id: 23725,
    localidad: "ALFEREZ SAN MARTIN",
    cp: 8117,
    provincia_id: 1,
  },
  {
    id: 23726,
    localidad: "ALFALAD",
    cp: 6555,
    provincia_id: 1,
  },
  {
    id: 23727,
    localidad: "ALFA",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23728,
    localidad: "ALEJANDRO PETION",
    cp: 1811,
    provincia_id: 1,
  },
  {
    id: 23729,
    localidad: "ALEJANDRO KORN",
    cp: 1864,
    provincia_id: 1,
  },
  {
    id: 23730,
    localidad: "ALEGRE",
    cp: 1987,
    provincia_id: 1,
  },
  {
    id: 23731,
    localidad: "ALDO BONZI",
    cp: 1770,
    provincia_id: 1,
  },
  {
    id: 23732,
    localidad: "ALDO BONZI",
    cp: 1785,
    provincia_id: 1,
  },
  {
    id: 23733,
    localidad: "ALDECON",
    cp: 7406,
    provincia_id: 1,
  },
  {
    id: 23734,
    localidad: "ALDEA SAN ANDRES",
    cp: 8126,
    provincia_id: 1,
  },
  {
    id: 23735,
    localidad: "ALDEA ROMANA",
    cp: 8101,
    provincia_id: 1,
  },
  {
    id: 23736,
    localidad: "ALBERTI",
    cp: 1667,
    provincia_id: 1,
  },
  {
    id: 23737,
    localidad: "ALBERTI",
    cp: 6634,
    provincia_id: 1,
  },
  {
    id: 23738,
    localidad: "ALBERDI",
    cp: 6034,
    provincia_id: 1,
  },
  {
    id: 23739,
    localidad: "ALAMOS",
    cp: 6437,
    provincia_id: 1,
  },
  {
    id: 23740,
    localidad: "ALAGON",
    cp: 6463,
    provincia_id: 1,
  },
  {
    id: 23741,
    localidad: "AGUSTINA",
    cp: 6001,
    provincia_id: 1,
  },
  {
    id: 23742,
    localidad: "AGUSTINA",
    cp: 6023,
    provincia_id: 1,
  },
  {
    id: 23743,
    localidad: "AGUSTIN ROCA",
    cp: 6026,
    provincia_id: 1,
  },
  {
    id: 23744,
    localidad: "AGUSTIN MOSCONI",
    cp: 6667,
    provincia_id: 1,
  },
  {
    id: 23745,
    localidad: "AGUSTIN FERRARI",
    cp: 1723,
    provincia_id: 1,
  },
  {
    id: 23746,
    localidad: "AGUIRREZABALA",
    cp: 2915,
    provincia_id: 1,
  },
  {
    id: 23747,
    localidad: "AGUILA RIO",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23748,
    localidad: "AGUAS VERDES",
    cp: 7112,
    provincia_id: 1,
  },
  {
    id: 23749,
    localidad: "AGUAS CORRIENTES",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 23750,
    localidad: "AGUARA",
    cp: 8105,
    provincia_id: 1,
  },
  {
    id: 23751,
    localidad: "AGOTE",
    cp: 6600,
    provincia_id: 1,
  },
  {
    id: 23752,
    localidad: "AEROPUERTO EZEIZA",
    cp: 1802,
    provincia_id: 1,
  },
  {
    id: 23753,
    localidad: "ADROGUE",
    cp: 1846,
    provincia_id: 1,
  },
  {
    id: 23754,
    localidad: "ADOLFO GONZALES CHAVES",
    cp: 7513,
    provincia_id: 1,
  },
  {
    id: 23755,
    localidad: "ADELA SAENZ",
    cp: 8129,
    provincia_id: 1,
  },
  {
    id: 23756,
    localidad: "ADELA CORTI",
    cp: 8000,
    provincia_id: 1,
  },
  {
    id: 23757,
    localidad: "ADELA",
    cp: 7136,
    provincia_id: 1,
  },
  {
    id: 23758,
    localidad: "ACHUPALLAS",
    cp: 6627,
    provincia_id: 1,
  },
  {
    id: 23759,
    localidad: "ACEVEDO",
    cp: 2717,
    provincia_id: 1,
  },
  {
    id: 23760,
    localidad: "ACEILAN",
    cp: 7003,
    provincia_id: 1,
  },
  {
    id: 23761,
    localidad: "ACASSUSO",
    cp: 1641,
    provincia_id: 1,
  },
  {
    id: 23762,
    localidad: "ABRA DE HINOJO",
    cp: 8170,
    provincia_id: 1,
  },
  {
    id: 23763,
    localidad: "ABEL",
    cp: 6450,
    provincia_id: 1,
  },
  {
    id: 23764,
    localidad: "ABBOTT",
    cp: 7228,
    provincia_id: 1,
  },
  {
    id: 23765,
    localidad: "ABASTO",
    cp: 1933,
    provincia_id: 1,
  },
  {
    id: 23766,
    localidad: "ABASTO",
    cp: 1903,
    provincia_id: 1,
  },
  {
    id: 23767,
    localidad: "A LA PACIENCIA",
    cp: 2805,
    provincia_id: 1,
  },
  {
    id: 23768,
    localidad: "A GONZ CHAVEZ",
    cp: 7513,
    provincia_id: 1,
  },
  {
    id: 23769,
    localidad: "A EL DESCANSO",
    cp: 2752,
    provincia_id: 1,
  },
  {
    id: 23770,
    localidad: "A AGUAS BLANCAS",
    cp: 8174,
    provincia_id: 1,
  },
  {
    id: 23771,
    localidad: "9 DE JULIO",
    cp: 6500,
    provincia_id: 1,
  },
  {
    id: 23772,
    localidad: "9 DE ABRIL",
    cp: 1839,
    provincia_id: 1,
  },
  {
    id: 23773,
    localidad: "9 DE ABRIL",
    cp: 1735,
    provincia_id: 1,
  },
  {
    id: 23774,
    localidad: "9 DE ABRIL",
    cp: 1776,
    provincia_id: 1,
  },
  {
    id: 23775,
    localidad: "6 DE OCTUBRE",
    cp: 8134,
    provincia_id: 1,
  },
  {
    id: 23776,
    localidad: "30 DE AGOSTO",
    cp: 6405,
    provincia_id: 1,
  },
  {
    id: 23777,
    localidad: "25 DE MAYO",
    cp: 6660,
    provincia_id: 1,
  },
  {
    id: 23778,
    localidad: "20 DE JUNIO",
    cp: 1786,
    provincia_id: 1,
  },
  {
    id: 23779,
    localidad: "17 DE AGOSTO",
    cp: 8129,
    provincia_id: 1,
  },
  {
    id: 23780,
    localidad: "17 DE AGOSTO",
    cp: 8225,
    provincia_id: 1,
  },
  {
    id: 23781,
    localidad: "16 DE JULIO",
    cp: 7313,
    provincia_id: 1,
  },
  {
    id: 23782,
    localidad: "12 DE OCTUBRE",
    cp: 6502,
    provincia_id: 1,
  },
  {
    id: 23783,
    localidad: "12 DE AGOSTO",
    cp: 2701,
    provincia_id: 1,
  },
  {
    id: 23786,
    localidad: "CAPILLA DE LOS REMEDIOS",
    cp: 5101,
    provincia_id: 6,
  },
  {
    id: 23785,
    localidad: "COMANDANTE FONTANA",
    cp: 3620,
    provincia_id: 9,
  },
  {
    id: 23786,
    localidad: "VILLA ADELA",
    cp: 5010,
    provincia_id: 6,
  },
]
