import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  table: {
    maxHeight: "80vh",
    margin: theme.spacing(3),
    overflow: "auto",
    paddingBottom: "64px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "80vh",
      paddingBottom: "84px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "80vh",
      paddingBottom: "114px",
    },
  },

  descripcion: {
    marginLeft: "15px",
  },
}))
