import React, { useState, useContext } from "react"

import { Grid, Button, Tooltip } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"

import { FormContext } from "Components/Hooks/ContextForm"
import Loader from "Components/UI/Atoms/Loader"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { EditContext } from "Components/Hooks/ContextEdit"
import Table from "./Table"
import useStyles from "./styles"
import { printPDF } from "Helpers"
const ContentAppBarTabs = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const [edit] = useContext(EditContext)
  const classes = useStyles()
  const { formEdit } = useContext(FormContext)
  const [loading, setLoading] = useState(false)
  const { wafers } = props

  async function apiFront() {
    await interfaces.vehicleInterface
      .front04D(formEdit.vehicle.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      }, setLoading(true))
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal, puede faltar algún dato!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  async function apiBack() {
    await interfaces.vehicleInterface
      .back04D(formEdit.vehicle.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      }, setLoading(true))
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal, puede faltar algún dato!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const front = () => {
    if (
      !formEdit.vehicle.marca ||
      !formEdit.vehicle.modelo ||
      !formEdit.vehicle.modelo_titulo ||
      !formEdit.vehicle.vehiculo_info.motor_marca ||
      !formEdit.vehicle.vehiculo_info.motor_nro ||
      !formEdit.vehicle.vehiculo_info.chasis_marca ||
      !formEdit.vehicle.vehiculo_info.chasis_nro ||
      !formEdit.vehicle.vehiculo_info.color ||
      !formEdit.vehicle.vehiculo_info.combustible
    ) {
      return "Falta algún dato para poder presionar este botón"
    } else {
      return "Debe guardar los cambios antes de presionar este botón"
    }
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item sm={12}>
          <Table wafers={wafers} />
        </Grid>
        <div className={classes.buttonsWafers}>
          <Grid item>
            <Tooltip title={front()} placement="top">
              <Button
                disabled={edit ? false : true}
                onClick={apiFront}
                variant="contained"
                endIcon={<PrintIcon />}
                style={{
                  marginRight: "15px",
                }}
              >
                04-D FRENTE
              </Button>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip
              title={"Debe guardar los cambios antes de presionar este botón"}
              placement="top"
            >
              <Button
                disabled={edit ? false : true}
                onClick={apiBack}
                variant="contained"
                endIcon={<PrintIcon />}
              >
                04-D Dorso
              </Button>
            </Tooltip>
          </Grid>
        </div>
      </Grid>
      {loading ? <Loader /> : null}
    </div>
  )
}

export default ContentAppBarTabs
