import React, { useState, useRef } from "react"

import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"
import { Typography } from "@material-ui/core"

import { interfaces } from "service/interfaces"
import InputSearch from "Components/UI/Atoms/InputSearch"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import { validationNewProduct } from "Components/Validation/Settings/Various/FamilyAndProducts"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogEdit from "./DialogEdit"
import SetState from "Components/Hooks/SetState"

const Products = (props) => {
  const { data, onClickSelect, family, productId, changeRender } = props

  const [change, setChange] = useState("")
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const refSubmit = useRef(null)

  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  const {
    state: openEdit,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const {
    state: openDelete,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  function handleRef() {
    refSubmit.current.click()
  }
  function handleChange(e) {
    setChange(e.target.value)
  }

  async function handleSubmitNew(values) {
    setLoading(true)
    const obj = {
      producto: values.producto.toUpperCase(),
      familia_id: family,
    }
    await interfaces.configInterface
      .newFamilyOrProduct(obj)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        setLoading(false)
        setCloseNew()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setLoading(false)
        setCloseNew()
      })
  }
  async function handleSubmitDelete() {
    await interfaces.configInterface
      .deleteProduct(productId)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        setCloseDelete()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setCloseDelete()
      })
  }
  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (family)
          return (
            e.familia_id === family && e.producto.includes(change.toUpperCase())
          )
        if (change) return e.producto.includes(change.toUpperCase())
        return e
      })
    } else {
      return []
    }
  }
  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNew}
        onChange={handleChange}
        value={change}
        disabledButton={!family}
      />
      <PaperList
        height={"600px"}
        children={
          !family && (change === "" || change.length < 3) ? (
            <div
              style={{
                height: "500px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle1">
                Seleccione una familia o realice alguna búsqueda
              </Typography>
            </div>
          ) : (
            <ListWithIcon
              data={filtered(data.products)}
              onClickSelect={onClickSelect}
              onClickDelete={setOpenDelete}
              selectedItem={productId}
              onClickEdit={setOpenEdit}
              nameIncludes="producto"
            />
          )
        }
      />
      <DialogComponent
        title="Nuevo producto"
        open={openNew.open}
        handleclose={setCloseNew}
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={
          <Formik
            initialValues={{
              producto: "",
            }}
            onSubmit={(values) => {
              handleSubmitNew(values)
            }}
            validationSchema={validationNewProduct}
          >
            <Form>
              <Field
                component={TextField}
                fullWidth
                name="producto"
                label="Nombre"
                variant="outlined"
              />
              <button
                style={{ display: "none" }}
                ref={refSubmit}
                type="submit"
              />
            </Form>
          </Formik>
        }
        actions={
          <CancelConfirmButtons
            onClickCancel={setCloseNew}
            isLoading={loading}
            onClickSubmit={handleRef}
          />
        }
      />
      <DialogComponent
        open={openDelete.open}
        handleclose={setCloseDelete}
        title="¿Desea eliminar este producto?"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <CancelConfirmButtons
            isLoading={loading}
            onClickCancel={setCloseDelete}
            onClickSubmit={handleSubmitDelete}
          />
        }
      />
      <DialogEdit
        open={openEdit.open}
        products={data.products}
        handleClose={setCloseEdit}
        optionsWafers={data.wafers}
        productGroup={data.productGroup}
        optionsProducts={
          productId ? data.products.find((e) => e.id === productId) : {}
        }
        changeRender={changeRender}
      />
    </>
  )
}

export default Products
