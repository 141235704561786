//user interface, call of axios with sources
import { axiosService } from "../../../index"
import { searchwafersEndpoints } from "../../../sources"

const rudac = (params) => {
  return axiosService.post(searchwafersEndpoints.RUDAC, params)
}

const disarm = (params) => {
  return axiosService.post(searchwafersEndpoints.DISARM, params)
}
const wafersList = (options) => {
  return axiosService.get(searchwafersEndpoints.VEHICLE_WAFERS + options)
}
const oneVehicleWafer = (id) => {
  return axiosService.get(searchwafersEndpoints.VEHICLE_WAFERS + "/" + id)
}

export const wafersInterface = {
  rudac,
  disarm,
  wafersList,
  oneVehicleWafer,
}
