import React from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import useStyles from "../styles"

const PasswordReseted = (props) => {
  const classes = useStyles()
  return (
    <form className={classes.form} noValidate>
      <Grid container>
        <Grid item sm={12}>
          <Typography align="center" className={classes.TextFormBack}>
            Tu contraseña se ha reestablecido al valor por defecto. Vuelva al
            menú anterior y ingrese con la contraseña por defecto.
          </Typography>
        </Grid>
        <Grid item sm={12}>
          <div className={classes.FormFormBack}></div>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={props.onClick}
          >
            Volver
          </Button>
        </Grid>
        <Grid item sm={12} justify="center">
          <div className={classes.FormButtonBack}></div>
        </Grid>
      </Grid>
    </form>
  )
}

export default PasswordReseted
