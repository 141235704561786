import React, { useState } from "react";
import { useMedia, useEffectOnce } from "react-use";
import BarcodeReader from "react-barcode-reader";

import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Hidden,
  Button,
} from "@material-ui/core";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import BuildIcon from "@material-ui/icons/Build";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CancelIcon from "@material-ui/icons/Cancel";
import HomeIcon from "@material-ui/icons/Home";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import HelpIcon from "@material-ui/icons/Help";

import MuiDatables from "Components/UI/Atoms/Table";
import columnsForTable from "Components/UI/Atoms/Table/Columns";
import GrillDialog from "Components/UI/Organisms/Plant/Grill/GrillDialog";
import OrderStart from "Components/UI/Organisms/Plant/Grill/TableGrill/OrderStart";
import SecondNav from "Components/UI/Molecules/SecondNav";
import useCustomSnackbar from "Components/UI/Atoms/SnackBar";
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons";
import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete";
import EditContextProvider from "Components/Hooks/ContextEdit";
import useStyles from "./styles";
import { columnTheme } from "Components/UI/Organisms/Plant/Grill/TableGrill/ColumnTheme";
import { UBICACIONES_MANUAL } from "Constants";
import { interfaces } from "service/interfaces";
import SetState from "Components/Hooks/SetState";
import EndOrder from "Components/UI/Organisms/Plant/Grill/TableGrill/EndOrder";
import { formatter } from "Helpers";
import DialogTable from "./Dialog";

const TableGrill = (props) => {
  const { render, setRender } = props;

  const createDismissableSnackbar = useCustomSnackbar();

  const classes = useStyles();

  const isMobile = useMedia("(max-width: 600px)");

  const {
    state: dialog,
    setOpen: openDialog,
    setClose: closeDialog,
  } = SetState();
  const { state: start, setOpen: openStart, setClose: closeStart } = SetState();
  const { state: end, setOpen: openEnd, setClose: closeEnd } = SetState();

  const [filters, setFilters] = useState({});
  const [openChoose, setOpenChoose] = useState({ open: false });
  const [openHelp, setOpenHelp] = useState(false);
  const [scanField, setScanField] = useState([]);
  const [location, setLocation] = useState({ id: null, open: false });
  const [infoAutocomplete, setInfoAutocomplete] = useState({
    pedido_id: null,
    ubicacion_id: null,
    ubicacion: "",
  });
  const [info, setInfo] = useState({
    rows: [],
    header: 0,
    area: "",
  });

  function handleOpenEnd(id, e, write) {
    e.stopPropagation();
    openEnd({
      id: id,
      write: write ? true : false,
    });
  }

  function handleCloseLocation() {
    setLocation({
      open: false,
      id: null,
    });
  }

  function handleOpenLocation(rows, object) {
    setLocation({
      open: true,
    });
    selectedLocation(rows, object);
  }

  useEffectOnce(() =>
    interfaces.plantOrders.tableFilters().then((result) => {
      setFilters(result.data);
    })
  );

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: 0,
      area: "",
    });
    try {
      const result = await interfaces.plantOrders.tableList(options);
      setInfo({
        rows: result.data.data,
        header: result.header.header.count,
        area: result.header.header.area,
      });
      callback();
    } catch (error) {}
  }

  async function onSubmitField(values, opcion = "") {
    let body = values;
    if (typeof values === "object") {
      body = Object.values(values);
    }
    await interfaces.plantOrders
      .processOrder(body, opcion)
      .then(async (res) => {
        if (res.data.msg === "elegir") {
          setOpenChoose({
            open: true,
            id: res.data.id,
            taller: res.data.taller,
          });
        }
        setRender(!render);
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        });
      });
  }

  async function onSubmitMultipleState(data) {
    await interfaces.plantOrders
      .editMultipleState(data)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        });
        setRender(!render);
        handleCloseLocation();
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        });
      });
  }

  function startOrder(e, file) {
    e.stopPropagation();
    openStart({ info: file });
  }

  const actions = (file) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        {!file.iniciado && file.iniciable ? (
          <Tooltip title="Iniciar">
            <IconButton
              onClick={(e) => startOrder(e, file)}
              disabled={file.estado === "Finalizado" ? true : false}
              style={
                file.estado === "Finalizado"
                  ? { color: "grey" }
                  : { color: "green" }
              }
            >
              <PlayCircleFilledIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Finalizar">
            <IconButton
              onClick={(e) =>
                handleOpenEnd(
                  file.id,
                  e,
                  file.estado !== "Despacho" ? true : false
                )
              }
              style={
                file.finalizable === true ? { color: "red" } : { color: "grey" }
              }
              disabled={file.finalizable === true ? false : true}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };

  const tooltipsStates = (id, estado) => {
    switch (id) {
      case 1:
        return (
          <div style={{ color: "red" }}>
            <b>{estado}</b>
          </div>
        );
      case 2:
        return (
          <div style={{ color: "#FFE400" }}>
            <b>{estado}</b>
          </div>
        );
      case 3:
        return (
          <div style={{ color: "#09BE00" }}>
            <b>{estado}</b>
          </div>
        );
      default:
        return [];
    }
  };

  const rowSelectable = (dataIndex) => {
    return info.rows[dataIndex]
      ? info.rows[dataIndex].estado !== "Finalizado"
      : true;
  };

  const RowsGrill = () =>
    info.rows.map((file) => [
      "",
      file.desde_hasta,
      file.fecha,
      file.codigo,
      tooltipsStates(file.urgencia_id, file.urgencia),
      <div>
        <b>{file.articulo}</b>
        {file.vehiculo ? ` / ${file.vehiculo}` : ""}
        <br />
        <i>
          {info.area === "Planta"
            ? file.planta.observacion_planta
            : file.ventas.observacion_ventas}
        </i>
      </div>,
      file.ventas.importe ? formatter.format(file.ventas.importe) : "",
      file.ubicacion,
      file.usuario,
      <>
        {file.estado} <b>{file.origen}</b>
      </>,
      actions(file),
      rowSelectable(file),
    ]);

  const columns = columnsForTable(
    [
      {
        name: "",
        label: "",
        filterLabel: "Estado",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "fecha_creacion",
        label: "Pedido",
        display: true,
        filter: false,
        sort: true,
        filterName: "descripcion",
        fullWidth: true,
      },
      {
        name: "cod",
        label: "Cód.",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "urgencia",
        label: "Urgencia",
        display: true,
        filter: true,
        sort: false,
        filterLabel: "Tipo",
        filterName: "label",
        options: filters.urgencia,
        customBodyRender: true,
      },

      {
        name: "articulo",
        label: "Artículo / Vehículo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "importe",
        label: "Importe",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "ubicacion",
        label: "Ubicación",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "usuario",
        label: "Usuario",
        display: true,
        filter: true,
        sort: false,
        filterName: "fullName",
        filterLabel: "fullName",
        options: filters.usuarios,
        customBodyRender: true,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: true,
        sort: false,
        filterName: "descripcion",
        options: filters.estados,
        fullWidth: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "origen",
        label: "Desde",
        filterLabel: "Desde",
        display: false,
        filter: true,
        sort: false,
        filterName: "nombre",
        options: filters.origen,
      },
      {
        name: "destino",
        label: "Hasta",
        filterLabel: "Hasta",
        display: false,
        filter: true,
        sort: false,
        filterName: "nombre",
        options: filters.destino,
      },
    ],
    filters
  );

  function selected(rows, object) {
    let data = {};
    let ids = [];
    for (let i = 0; i < rows.length; i++) {
      ids.push(info.rows[rows[i].index].id);
    }
    data = { ...object, pedido_id: ids };
    onSubmitMultipleState(data);
  }

  function selectedLocation(rows, object) {
    let ids = [];
    for (let i = 0; i < rows.length; i++) {
      ids.push(info.rows[rows[i].index].id);
    }
    setInfoAutocomplete({
      ...object,
      pedido_id: ids,
    });
  }

  function onChange(values) {
    setInfoAutocomplete({
      ...infoAutocomplete,
      ubicacion_id: values.id,
      ubicacion: values.nombre,
    });
  }

  const dialogData = async (id) => {
    await interfaces.plantOrders.orderInfo(id).then((res) => {
      openDialog({ data: res.data });
    });
  };

  const customInfo = () => {
    return (
      <Tooltip title="Ayuda">
        <IconButton onClick={() => setOpenHelp(true)}>
          <HelpIcon style={{ color: "#FFF" }} />
        </IconButton>
      </Tooltip>
    );
  };

  const custom = (selectedRows) => {
    return (
      <>
        <div style={{ marginRight: "10px" }}>
          {info.area === "Planta" || info.area === "Depósito" ? (
            <>
              <Tooltip title="Cambiar ubicación">
                <IconButton
                  onClick={() =>
                    handleOpenLocation(selectedRows.data, {
                      ...infoAutocomplete,
                    })
                  }
                >
                  <HomeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Taller">
                <IconButton
                  onClick={() =>
                    selected(selectedRows.data, {
                      estado_id: 4,
                      estado: "Taller",
                    })
                  }
                >
                  <DriveEtaIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Clasificación">
                <IconButton
                  onClick={() =>
                    selected(selectedRows.data, {
                      estado_id: 5,
                      estado: "Clasificación",
                    })
                  }
                >
                  <BuildIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Despacho">
                <IconButton
                  onClick={() =>
                    selected(selectedRows.data, {
                      estado_id: 6,
                      estado: "Despacho",
                    })
                  }
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Finalizado">
              <IconButton
                onClick={() =>
                  selected(selectedRows.data, {
                    estado_id: 7,
                    estado: "Finalizado",
                  })
                }
              >
                <CheckCircleIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </>
    );
  };

  function scanCode(data) {
    onSubmitField(data);
  }

  return (
    <>
      <BarcodeReader onScan={scanCode} />

      {location.open === false ? null : (
        <DialogComponent
          title="Cambiar ubicación"
          open={location.open}
          handleclose={handleCloseLocation}
          children={
            <CustomAutocomplete
              options={UBICACIONES_MANUAL}
              getOptionLabel={(transportes) => transportes.nombre || ""}
              onChange={(_, value) => {
                onChange(value);
              }}
              label={"Ubicacion"}
              name="ubicacion_id"
            />
          }
          actions={
            <CancelConfirmButtons
              onClickSubmit={() => onSubmitMultipleState(infoAutocomplete)}
              onClickCancel={handleCloseLocation}
            />
          }
        />
      )}

      <DialogComponent
        title="Ayuda en grilla"
        open={openHelp}
        widthLarge={600}
        handleclose={() => setOpenHelp(false)}
        tipo={"slide"}
        children={
          <>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  process.env.PUBLIC_URL + "/Images/flujo_grilla.jpg",
                  "_blank"
                )
              }
            >
              Flujo de movimientos de grilla
            </Button>
            <DialogTable />
          </>
        }
      />

      <DialogComponent
        title="¿Confirma envíar la pieza al taller indicado?"
        open={openChoose.open}
        widthLarge="500px"
        handleclose={() => setOpenChoose({ open: false })}
        children={
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Taller elegido por el vendedor"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={openChoose.taller === 1 ? "Taller R7" : "Taller RC"}
          />
        }
        actions={
          <CancelConfirmButtons
            submitText="Enviar al taller"
            onClickSubmit={() => {
              onSubmitField("C" + openChoose.id, `?opcion=2`);
              setOpenChoose({ open: false });
            }}
            cancelText="No enviar"
            onClickCancel={() => {
              onSubmitField("C" + openChoose.id, `?opcion=1`);
              setOpenChoose({ open: false });
            }}
          />
        }
      />

      {dialog.suspense && (
        <EditContextProvider>
          <GrillDialog
            open={dialog.open}
            handleClose={closeDialog}
            render={render}
            setRender={setRender}
            data={dialog.data}
          />
        </EditContextProvider>
      )}

      {start.suspense && (
        <OrderStart
          info={start.info}
          open={start.open}
          handleclose={closeStart}
          render={render}
          setRender={setRender}
        />
      )}

      {end.suspense && (
        <EndOrder
          end={end}
          closeEnd={closeEnd}
          render={render}
          setRender={setRender}
        />
      )}

      {(info.area === "Planta" || info.area === "Depósito") && (
        <Hidden smUp>
          <SecondNav
            onSubmitField={onSubmitField}
            scanField={scanField}
            setScanField={setScanField}
            hiddenProcessCancel
            hiddenColector
            colorlessBtn
          />
        </Hidden>
      )}

      <MuiDatables
        columns={columns}
        data={RowsGrill()}
        textPlaceholder={
          "Buscar por Nº V, Dominio, Nombre de cliente, Teléfono de cliente o Pieza..."
        }
        title={
          <>
            <div className={classes.divFlex}>
              <Hidden xsDown>
                {(info.area === "Planta" || info.area === "Depósito") && (
                  <SecondNav
                    onSubmitField={onSubmitField}
                    scanField={scanField}
                    setScanField={setScanField}
                    hiddenProcessCancel
                    hiddenColector
                    colorlessBtn
                  />
                )}

                <Typography variant="h6">
                  {`Cantidad de pedidos: ${info.header}`}
                </Typography>
              </Hidden>
            </div>
            <Hidden smUp>
              <Typography variant="h6">
                {`Cantidad de pedidos: ${info.header}`}
              </Typography>
            </Hidden>
          </>
        }
        apiCall={apiCall}
        count={info.header}
        render={render}
        setRender={setRender}
        selectableRows={!isMobile ? "multiple" : false}
        customToolbarSelect={custom}
        customToolbar={customInfo}
        columnTheme={columnTheme()}
        padding={!isMobile ? "none" : "medium"}
        isRowSelectable={rowSelectable}
        onRowClick={(_, index) => dialogData(info.rows[index.dataIndex].id)}
        rows={50}
      />
    </>
  );
};
export default TableGrill;
