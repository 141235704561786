import React, { useRef, useState } from "react"
import { Field, Formik } from "formik"
import { TextField } from "formik-material-ui"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import { InputAdornment, Tooltip } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import useStyles from "./styles"

export default function CustomizedInputBase(props) {
  const classes = useStyles()
  const {
    onSubmit,
    initialValues = {
      dominio: "",
    },
    name,
    validationSchema,
    placeholder,
    autoFocus = true,
    loading,
  } = props

  const innerRef = useRef(null)
  const [value, setValue] = useState()

  async function submitButton() {
    await onSubmit({ [name]: value })
    innerRef.current.resetForm({})
  }

  function iconAdornment() {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <Tooltip title="Buscar">
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={() => submitButton()}
              disabled={!value}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      )
    }
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        className={classes.root}
        validateOnChange={false}
        innerRef={innerRef}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Field
            component={TextField}
            value={values[name]}
            name={name}
            fullWidth
            className={classes.input}
            variant="outlined"
            placeholder={placeholder}
            label={placeholder}
            onChange={(e) => {
              e.target.focus()
              setValue(e.target.value.toUpperCase())
              setFieldValue(name, e.target.value.toUpperCase())
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter" && values[name]) {
                onSubmit(values)
                resetForm({})
              }
            }}
            InputProps={{
              endAdornment: iconAdornment(),
            }}
            autoFocus={autoFocus}
          />
        )}
      </Formik>
    </>
  )
}
