import React from "react"
import { Route, Redirect } from "react-router-dom"

import { isAuthenticated, isAuthorized } from "Helpers"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const routes = { ...rest }
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated() ? (
          <Redirect to="/" />
        ) : isAuthorized(routes.path) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/home" />
        )
      }
    />
  )
}

export default PrivateRoute
