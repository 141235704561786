const NEW_PRODUCT = "plant/classification/newproduct"
const GET_LIST = "plant/classification/list"
const STOCK_PRODUCT = "plant/classification/product/"
const UPLOAD_PHOTO = "plant/classification/photo/"
const DELETE_PHOTO = "plant/classification/deletephoto/"
const UPDATE_STOCK = "plant/classification/product/"
const STOCK_STICKER = "plant/classification/sticker/"
const GET_RUDAC = "plant/classification/updaterudac/"
const DUPLICATED = "plant/classification/duplicate"

export const plantEndpoints = {
  NEW_PRODUCT,
  GET_LIST,
  UPLOAD_PHOTO,
  STOCK_PRODUCT,
  DELETE_PHOTO,
  UPDATE_STOCK,
  STOCK_STICKER,
  GET_RUDAC,
  DUPLICATED,
}
