import React, { useState } from 'react'

import ComplexDialog from 'Components/UI/Molecules/ComplexDialog'
import { interfaces } from 'service/interfaces'
import useCustomSnackbar from 'Components/UI/Atoms/SnackBar'
import {
  FORM_HOME,
  PLANT_INFORMATION,
  SALES_INFORMATION,
  RECORD,
} from './Fields'
import useStyles from './styles'
import { printPDF } from 'Helpers'
import CustomButton from 'Components/UI/Atoms/CustomButton'
import InputsWithoutValidate from 'Components/UI/Molecules/InputsWithoutValidate'
import SetState from 'Components/Hooks/SetState'
import CloneGrill from 'Components/UI/Organisms/Plant/Grill/GrillDialog/Clone'
import ComplexDialogActions from 'Components/UI/Molecules/ComplexDialog/Actions'
import ChangeVehicle from 'Components/UI/Organisms/Plant/Grill/GrillDialog/ChangeVehicle'
import ChangeCode from 'Components/UI/Organisms/Plant/Grill/GrillDialog/ChangeCode'
import DialogStock from 'Components/UI/Organisms/Sales/Stock/DialogStock'
import Loader from 'Components/UI/Atoms/Loader'

const GrillDialog = (props) => {
  const { open, handleClose, render, setRender, data: info } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const { state: clone, setOpen: openClone, setClose: closeClone } = SetState()
  const {
    state: changeV,
    setOpen: openChangeV,
    setClose: closeChangeV,
  } = SetState()
  const {
    state: changeCode,
    setOpen: openChangeCode,
    setClose: closeChangeCode,
  } = SetState()
  const { state: stock, setOpen: openStock, setClose: closeStock } = SetState()

  const [data, setData] = useState(info)
  const [loading, setIsLoading] = useState(false)

  const tabs = (disabled) => {
    return [
      {
        id: 0,
        title: 'Inicio',
      },
      {
        id: 1,
        title: 'Información Planta',
      },
      {
        id: 2,
        title: 'Información Ventas',
        disabled: disabled,
      },
      {
        id: 3,
        title: 'Historial',
        hidden: data.historial.length === 0,
      },
    ]
  }

  const classes = useStyles()

  const handleChange = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    })
  }

  const onSubmit = () => {
    interfaces.plantOrders
      .updateOrder(data.data.id, data.data)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: 'success',
        })
        setRender(!render)
        handleClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: 'error',
        })
      })
  }

  async function onSubmitPrint() {
    setIsLoading(true)
    await interfaces.plantOrders
      .printCommand(data.data.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar(`Comanda impresa con éxito!`, {
          variant: 'success',
        })
      })
      .catch(() => {
        createDismissableSnackbar(`Algo salió mal!`, {
          variant: 'error',
        })
      })
    setIsLoading(false)
  }

  const onSubmitWithoutClose = () => {
    interfaces.plantOrders
      .updateOrder(data.data.id, data.data)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: 'success',
        })
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: 'error',
        })
      })
  }

  async function onSubmitPrintWithClose() {
    setIsLoading(true)
    await interfaces.plantOrders
      .printCommand(data.data.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar(`Comanda impresa con éxito!`, {
          variant: 'success',
        })
        handleClose()
      })
      .catch(() => {
        createDismissableSnackbar(`Algo salió mal!`, {
          variant: 'error',
        })
      })
    setIsLoading(false)
  }

  const openStockData = async () => {
    await interfaces.plantOrders
      .getStockData(data.data.codigo[0], data.data.codigo.slice(1))
      .then((res) => {
        openStock({ dataObject: res.data })
      })
      .catch(() => {
        createDismissableSnackbar(`Algo salió mal!`, {
          variant: 'error',
        })
      })
  }

  const renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={classes.home}>
            <InputsWithoutValidate
              fields={FORM_HOME(
                data.data,
                data.ubicaciones,
                handleChange,
                openChangeV,
                openChangeCode
              )}
              spacing={2}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <div
                style={{
                  margin: '0px 10px 0px 0px',
                }}
              >
                <CustomButton
                  text='Ver info de la pieza'
                  onClick={openStockData}
                  disabled={!data.data.codigo}
                />
              </div>
              <div
                style={{
                  margin: '0px 10px 0px 0px',
                }}
              >
                <CustomButton text='Duplicar' onClick={openClone} />
              </div>
              <CustomButton
                text='Imprimir'
                onClick={() => onSubmitPrint()}
                isLoading={loading}
                disabled={loading}
                tooltip='Iniciar pedido desde tabla antes de imprimir'
              />
            </div>
          </div>
        )
      case 1:
        return (
          <div className={classes.home}>
            <InputsWithoutValidate
              fields={PLANT_INFORMATION(
                data.data,
                data.estados,
                data.operarios_taller,
                data.operarios_desmontaje,
                handleChange
              )}
              spacing={2}
              setFieldValue={handleChange}
            />
          </div>
        )
      case 2:
        return (
          <div className={classes.home}>
            <InputsWithoutValidate
              fields={SALES_INFORMATION(data.data, handleChange)}
              spacing={2}
              setFieldValue={handleChange}
            />
          </div>
        )
      case 3:
        return (
          <div>
            <InputsWithoutValidate
              fields={RECORD(data.historial, data.ver_ventas)}
            />
          </div>
        )
      default:
        break
    }
  }

  const subtitle = () => {
    return (
      <>
        <b>ID {data.data.id}</b> - Creado el {data.data.fecha} por{' '}
        {data.data.usuario}
      </>
    )
  }

  function saveAndPrint() {
    onSubmitWithoutClose()
    setTimeout(() => {
      onSubmitPrintWithClose()
    }, 500)
  }

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleClose}
        tabs={tabs(!data.ver_ventas)}
        title={`Pedido por ${data.data.pieza}`}
        subtitle={subtitle()}
        children={renderForms}
        actions={
          <ComplexDialogActions
            firstButton={{
              title: 'Guardar e imprimir',
              color: 'primary',
              variant: 'contained',
              onClick: () => saveAndPrint(),
              disabled: true,
            }}
            onClose={handleClose}
            handleSubmit={onSubmit}
            canEdit={true}
            data={data.data}
            editMode={false}
          />
        }
      />
      {clone.suspense && (
        <CloneGrill
          id={data.data.id}
          open={clone.open}
          onClose={closeClone}
          handleClose={handleClose}
          onSubmit={onSubmit}
          setRender={setRender}
          render={render}
        />
      )}
      {changeV.suspense && (
        <ChangeVehicle
          id={data.data.id}
          open={changeV.open}
          onClose={closeChangeV}
          closeDialog={handleClose}
          setRender={setRender}
          render={render}
        />
      )}
      {changeCode.suspense && (
        <ChangeCode
          id={data.data.id}
          open={changeCode.open}
          onClose={closeChangeCode}
          closeDialog={handleClose}
          setRender={setRender}
          render={render}
        />
      )}
      {stock.suspense && (
        <DialogStock
          open={stock.open}
          handleclose={closeStock}
          data={stock.dataObject}
          setRender={setRender}
          render={render}
        />
      )}
      {loading ? <Loader /> : null}
    </>
  )
}

export default GrillDialog
