import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "#f4f7f4",
    borderRadius: "10px",
    // margin: "10px",
    // [theme.breakpoints.down("sm")]: {
    //   margin: " 10px 0px 10px 0px",
    // },
    overflow: "hidden",
    display: "block",
    border: "1px solid #EBECF0",
  },
  // children: {
  //   margin: "10px",
  //   [theme.breakpoints.down("sm")]: {
  //     margin: "0px",
  //   },
  // },
}))

export default useStyles
