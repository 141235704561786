import { React, forwardRef, useState } from "react"
import { Formik, Form } from "formik"

import { formNew } from "Components/Validation/Vehicles/SearchVehicles"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { interfaces } from "service/interfaces"
import { QuotationFields } from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Quotation/Modals/Fields"

const formEdit = forwardRef((props, ref) => {
  const { data, onSubmit, companies, types } = props

  const [vehicles, setVehicles] = useState([])

  async function getVehiclesForPayment() {
    await interfaces.vehicleInterface.getAllVehicleForPayments().then((res) => {
      setVehicles(res.data)
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        proveedor: data.proveedor,
        fecha_factura: data.fecha_factura,
        fecha_pago: data.fecha_pago,
        suc_factura: data.suc_factura,
        nro_factura: data.nro_factura,
        monto_total: data.monto_total,
        observaciones: data.observaciones,
        tipo: data.tipo,
      }}
      validationSchema={formNew}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={QuotationFields(
              companies,
              types,
              vehicles,
              setFieldValue,
              values,
              getVehiclesForPayment
            )}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />

          <button style={{ display: "none" }} type="submit" ref={ref} />
        </Form>
      )}
    </Formik>
  )
})

export default formEdit
