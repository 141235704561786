import { IconButton, Typography } from "@material-ui/core"
import InputButtonWithOutFormik from "Components/UI/Atoms/InputButtonWithOutValidate"
import { Tooltip } from "@material-ui/core"
import CameraAltIcon from "@material-ui/icons/CameraAlt"

export function STEP_1(values, setOpenModal, photos) {
  return [
    {
      id: 1,
      class: "textfield",
      sm: 12,
      xs: 12,
      name: "dominio",
      value: values.vehicle ? values.vehicle.dominio : "",
      label: "Dominio",
      disabled: true,
      inputProps: {
        readOnly: true,
        endAdornment: (
          <Tooltip title="Ver fotos vehículo">
            <IconButton
              onClick={() => setOpenModal(true)}
              style={photos.length > 0 ? { color: "black" } : null}
              disabled={photos.length > 0 ? false : true}
            >
              <CameraAltIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      id: 2,
      class: "textfield",
      sm: 12,
      xs: 12,
      value: values.vehicle ? values.vehicle.nrodev : "",
      name: "nrodev",
      label: "Nro. de V",
      disabled: true,
    },
    {
      id: 3,
      sm: 12,
      xs: 12,
      class: "textfield",
      name: "marca",
      value: values.vehicle ? values.vehicle.marca : "",
      label: "Marca",
      disabled: true,
    },
    {
      id: 4,
      sm: 12,
      xs: 12,
      class: "textfield",
      name: "modelo",
      value: values.vehicle ? values.vehicle.modelo : "",
      label: "Modelo",
      disabled: true,
    },
    {
      id: 3,
      sm: 12,
      xs: 12,
      class: "textfield",
      name: "año",
      value: values.vehicle ? values.vehicle.año : "",
      label: "Año",
      disabled: true,
    },
  ]
}
export function STEP_2(value, handleChange) {
  return [
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "empresa",
      label: "Empresa",
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
      value: value.empresa,
      sortBy: "optionLabel",
      getOptionDisabled: (options) => options.disabled,
      disabledProp: "disabled",
      onChange: (e) => {
        handleChange({
          empresa: e.id,
        })
      },
      options: [
        {
          id: 1,
          optionLabel: "ECO",
          disabled: value.products.eco_products.length > 0 ? false : true,
        },
        {
          id: 2,
          optionLabel: "OEM",
        },
      ],
    },
  ]
}

export function STEP_2_ECO(options, handleChange, values) {
  return [
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      optionLabel: `options["producto"]`,
      name: "producto_id",
      label: "Producto",
      sortBy: "categoria",
      optionValue: "id",
      value: values.producto_id,
      clearOnEscape: false,
      groupBy: "categoria",
      options: options.eco_products,
      getOptionDisabled: (options) => options.creado,
      disabledProp: "creado",
      onChange: (e) => {
        handleChange({
          producto_id: e.id,
          producto: e.producto,
          oblea: e.oblea,
        })
      },
    },
    {
      id: 2,
      class: "textfield",
      sm: 12,
      xs: 12,
      name: "oblea",
      disabled: true,
      label: "Oblea",
      value: values.oblea,
    },
  ]
}

export const STEP_2_OEM = (options, handleChange, values) => {
  return [
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "familia_id",
      optionLabel: `options["descripcion"]`,
      label: "Familia",
      options: options.oem_families,
      sortBy: "id",
      optionValue: "id",
      value: values.familia_id,
      disableClearable: true,
      onChange: (e, reason) => {
        if (reason === "clear") {
          handleChange({
            familia_id: null,
          })
        } else {
          handleChange({
            familia_id: e.id,
          })
        }
      },
    },
    {
      id: 2,
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "producto_id",
      optionLabel: `options["producto"]`,
      label: "Producto",
      options: !values.familia_id
        ? options.oem_products
        : options.oem_products.filter(
            (familiy) => familiy.familia_id === values.familia_id
          ),
      sortBy: "producto",
      optionValue: "id",
      value: values.producto_id,
      getOptionDisabled: (options) => options.creado,
      disabledProp: "creado",
      onChange: (e) => {
        handleChange({
          producto_id: e.id,
          producto: e.producto,
        })
      },
    },
  ]
}

export function STEP_4(values, data, onSubmitCode, handleChange) {
  return [
    {
      id: 1,
      class: "textfield",
      sm: 12,
      xs: 12,
      name: "vehicle",
      label: "Vehículo",
      value: data.marca + " " + data.modelo + " " + data.año,
      disabled: true,
    },
    {
      id: 2,
      class: "textfield",
      sm: 12,
      xs: 12,
      name: "product",
      value: values.producto,
      label: "Producto",
      disabled: true,
    },

    {
      id: 4,
      sm: 12,
      xs: 12,
      class: "textfield",
      value: values.calidadNombre,
      name: "quality",
      label: "Calidad",
      disabled: true,
    },
    {
      id: 3,
      sm: 12,
      xs: 12,
      class: "textfield",
      name: "comentarios",
      label: "Observaciones",
      onChange: (e) =>
        handleChange({ comentarios: e.target.value.toUpperCase() }),
    },
    {
      id: 4,
      class: "custom",
      component: (
        <Typography style={{ marginBottom: "15px" }}>
          Si desea, puede colocar una ubicación de almacén
        </Typography>
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      sm: 2,
      xs: 2,
      name: "almacen_empresa",
      optionLabel: `options["label"]`,
      label: "",
      options: [
        { id: 1, label: "E" },
        { id: 2, label: "O" },
      ],
      optionValue: "id",
      value: values.almacen_empresa,
      onChange: (e) => {
        handleChange({
          almacen_empresa: e.id,
        })
      },
    },
    {
      class: "custom",
      component: (
        <InputButtonWithOutFormik
          onSubmit={onSubmitCode}
          handleChangeCode={(e) =>
            handleChange({ almacen_id: e.target.value.toUpperCase() })
          }
          name="almacen_id"
          value={values.almacen_id}
          placeholder="Cód. ubicación"
          autoFocus={false}
        />
      ),
      sm: 10,
      xs: 10,
    },
    {
      id: 7,
      sm: 12,
      xs: 12,
      class: "textfield",
      value: values.data_code,
      name: "ubicacion",
      label: "Ubicación",
      disabled: true,
    },
  ]
}
