import { axiosService } from "service"
import { salesStockEndpoints } from "service/sources"

const stockList = (options) => {
  return axiosService.get(salesStockEndpoints.LIST + options)
}

const stockFilters = () => {
  return axiosService.get(salesStockEndpoints.FILTER_LIST)
}

const reserveStock = (id, body) => {
  return axiosService.post(salesStockEndpoints.RESERVE_STOCK + "/" + id, body)
}

const deleteReserve = (id) => {
  return axiosService.del(salesStockEndpoints.RESERVE_STOCK + "/" + id)
}

const beforeExport = () => {
  return axiosService.get(salesStockEndpoints.BEFORE_EXPORT)
}

const exportStock = (body) => {
  return axiosService.post(salesStockEndpoints.EXPORT, body)
}

const updateComment = (id, body) => {
  return axiosService.put(salesStockEndpoints.UPDATE_COMMENT + id, body)
}

const getVehicleData = (id) => {
  return axiosService.get(salesStockEndpoints.VEHICLE_DATA + id)
}

const getEngineData = (engine) => {
  return axiosService.get(salesStockEndpoints.ENGINE_DATA + engine)
}

export const salesStock = {
  stockFilters,
  stockList,
  reserveStock,
  deleteReserve,
  beforeExport,
  exportStock,
  updateComment,
  getVehicleData,
  getEngineData,
}
