import React, { useEffect } from "react"

import RenderTemplateLogin from "Components/UI/Templates/Login"

const Login = () => {
  useEffect(() => {
    document.title = "ECO Autoparts"
  }, [])

  return <RenderTemplateLogin />
}

export default Login
