import React, { useRef, useState } from "react"

import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"
import { brand } from "Components/Validation/Settings/Various/BrandModelVersion"

import { interfaces } from "service/interfaces"
import { Grid, IconButton } from "@material-ui/core"
import useStyles from "./styles"

const Edit = (props) => {
  const { open, handleclose, data, idBrand, changeRender } = props
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()
  const refInput = useRef(null)
  function handleRef() {
    refInput.current.click()
  }

  function brandInfo() {
    return data.filter((e) => {
      return e.id === idBrand
    })[0]
  }

  const info = brandInfo()
  const [newData, setNewData] = useState(info)

  const onSubmit = async (values) => {
    let value = { marca: values.marca.toUpperCase() }
    await interfaces.configInterface
      .updateBrand(idBrand, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
  }
  function handleChange(e) {
    let value = e.target.files[0]
    if (value !== null) {
      interfaces.configInterface
        .uploadBrandPhoto(idBrand, value)
        .then((res) => {
          createDismissableSnackbar("Foto subida con éxito!", {
            variant: "success",
          })
          changeProfile(value)
        })
        .catch(() => {
          createDismissableSnackbar("Algo salió mal!", {
            variant: "error",
          })
        })
    }
  }

  function changeProfile(value) {
    let imgUp = URL.createObjectURL(value)
    setNewData({
      ...newData,
      logo: imgUp,
    })
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        widthLarge={"500px"}
        title="Editar marca"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={
          <>
            <Formik
              enableReinitialize
              initialValues={{
                marca: info.marca,
              }}
              onSubmit={(values) => {
                onSubmit(values)
              }}
              validationSchema={brand}
            >
              {({ values }) => (
                <Form>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                  >
                    <Grid item sm={12} xs={12}>
                      <img
                        alt=""
                        src={newData.logo}
                        className={classes.avatar}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <input
                          accept="image/*"
                          style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor="raised-button-file">
                          <IconButton
                            component="span"
                            style={{
                              bottom: "20px",
                              background: "#3E984C",
                            }}
                          >
                            <AddAPhotoIcon
                              style={{
                                color: "white",
                              }}
                            />
                          </IconButton>
                        </label>
                      </div>
                    </Grid>
                  </Grid>
                  <Field
                    style={{ height: "70px" }}
                    label="Nombre marca"
                    value={values.marca}
                    autoFocus={true}
                    component={TextField}
                    name="marca"
                    fullWidth
                    disabled={false}
                    variant="outlined"
                  />

                  <button
                    style={{ display: "none" }}
                    ref={refInput}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleRef}
            onClickCancel={handleclose}
          />
        }
      />
    </>
  )
}

export default Edit
