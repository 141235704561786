import React, { useState } from "react"

import { IconButton, makeStyles, Tooltip } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import InfoIcon from "@material-ui/icons/Info"
import MuiDatables from "Components/UI/Atoms/Table"
import DialogWafer from "../Dialog"
import { interfaces } from "service/interfaces"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"
import { columnTheme } from "./styles"
import SetState from "Components/Hooks/SetState"

const TableWafers = () => {
  const [render, setRender] = useState(true)
  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  const { state, setOpen, setClose } = SetState()

  const windowSize = useMediaQuery("(min-width:1000px)")

  const useStyles = makeStyles((theme) => ({
    textFieldMaterial: {
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "200px",
      },
    },
  }))

  let parentStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  }
  let cellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
  const classes = useStyles()

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.wafersInterface.wafersList(options)
      setInfo({
        rows: result.data.waferList,
        filters: result.data.filters,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  const actions = (file) => {
    return (
      <>
        {file && (
          <Tooltip title="Oblea comprada">
            <IconButton
              style={{
                width: "0px",
                color: "black",
              }}
              size="small"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    )
  }

  const RowsVehicles = () =>
    info.rows.map((file) => [
      actions(file.oblea_comprada),
      file.oblea,
      file.producto,
      file.dominio,
      file.nrodev,
      file.factura,
      file.comprobante,
      file.cliente,
    ])

  let columns = [
    {
      label: "",
      name: "",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      label: "N° de oblea",
      name: "oblea",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: "producto",
      label: "Pieza",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => `Pieza: ${v}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Pieza"}
                  filterName={"producto"}
                  style={classes.textFieldMaterial}
                  options={info.filters.productos}
                />
              )
            }
          },
        },
      },
    },
    {
      label: "Dominio",
      name: "dominio",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      label: "Nro. de V",
      name: "nrodev",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      label: "Vendida",
      name: "factura",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      label: "Comprobante",
      name: "comprobante",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
      },
    },
    {
      label: "Cliente",
      name: "cliente",
      options: {
        filter: false,
        sort: false,
        sortThirdClickReset: true,
        customBodyRender: (val) => {
          return (
            <div style={{ position: "relative", height: "20px" }}>
              <div style={parentStyle}>
                <div style={cellStyle}>{val}</div>
              </div>
            </div>
          )
        },
      },
    },
  ]
  return (
    <>
      <MuiDatables
        rows={50}
        columns={columns}
        data={RowsVehicles()}
        title={`Total de obleas: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar por oblea, dominio o Nº de V..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        onRowClick={(_, index) =>
          setOpen({
            id: info.rows[index.dataIndex].id,
            info: info.rows[index.dataIndex],
          })
        }
        padding={windowSize ? "10px" : ""}
      />
      {state.suspense && (
        <DialogWafer
          open={state.open}
          onClose={setClose}
          id={state.id}
          infoRow={state.info}
        />
      )}
    </>
  )
}
export default TableWafers
