import React, { useRef } from "react"
import { useKeyPressEvent } from "react-use"

import { Grid, TextField, Button } from "@material-ui/core"
import { Typography } from "@material-ui/core"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Andreani from "./Andreani"
import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"
import useStyles from "./styles"

const Quote = () => {
  const classes = useStyles()

  const cpRef = useRef(null)
  const kgRef = useRef(null)

  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose, setState, setLoading } = SetState()

  useKeyPressEvent("Enter", () =>
    !state.fields ||
    state.fields.cp === undefined ||
    state.fields.kilogramos === undefined
      ? null
      : handleSubmit()
  )

  function handleSubmit() {
    setLoading(true)
    if (
      !state.fields ||
      state.fields.cp === undefined ||
      state.fields.kilogramos === undefined
    ) {
      createDismissableSnackbar("Completar ambos campos", {
        variant: "error",
      })
    } else {
      interfaces.dashboardInterface
        .setAndreani(state.fields)
        .then((res) => {
          setOpen({ data: res.data, fields: {} })
          kgRef.current.value = ""
          cpRef.current.value = ""
        })
        .catch((err) =>
          createDismissableSnackbar(err.response.data.data, {
            variant: "error",
          })
        )
      setLoading(false)
    }
  }

  function handleChange(e) {
    setState({
      fields: {
        ...state.fields,
        [e.target.name]: parseInt(e.target.value),
      },
    })
  }

  return (
    <div style={{ margin: "10px" }}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Cotizador Andreani
          </Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <TextField
            fullWidth
            type="number"
            label="CP"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name="cp"
            inputRef={cpRef}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <TextField
            onChange={handleChange}
            fullWidth
            type="number"
            name="kilogramos"
            label="Kilogramos"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            inputRef={kgRef}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            fullWidth
          >
            Calcular envío
          </Button>
        </Grid>
      </Grid>
      {state.suspense && (
        <Andreani
          info={state.data}
          handleClose={setClose}
          open={state.open}
          isLoading={state.loading}
        />
      )}
    </div>
  )
}

export default Quote
