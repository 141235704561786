import React, { useState } from "react"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import InputButton from "Components/UI/Atoms/InputButton"

const ChangeVehicle = (props) => {
  const { id, open, onClose, render, setRender, closeDialog } = props

  const [loading, setLoading] = useState({ search: false, submit: false })
  const [value, setValue] = useState({ id: null, data: "" })

  const createDismissableSnackbar = useCustomSnackbar()

  async function getVehicle(values) {
    setLoading({ ...loading, search: true })
    await interfaces.plantOrders
      .searchVehicle(values.search)
      .then((res) => {
        setValue(res.data)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading({ ...loading, search: false })
  }

  async function submitVehicle() {
    setLoading({ ...loading, submit: true })
    await interfaces.plantOrders
      .updatehVehicle(id, { vehiculo_id: value.id })
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        onClose()
        closeDialog()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading({ ...loading, submit: false })
  }

  const fieldOrderClone = () => [
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <InputButton
          onSubmit={getVehicle}
          name={"search"}
          placeholder={"Buscar vehículo por Dominio / Nº V"}
          loading={loading.search}
        />
      ),
    },
    {
      class: "textfield",
      label: "Vehículo encontrado",
      name: "vehiculo",
      value: value.data,
      disabled: true,
      sm: 12,
      xs: 12,
    },
  ]

  return (
    <DialogComponent
      open={open}
      title="Cambiar vehículo"
      widthLarge="500px"
      handleclose={!loading.submit ? onClose : null}
      children={
        <InputsWithoutValidate fields={fieldOrderClone()} spacing={1} />
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={onClose}
          onClickSubmit={() => submitVehicle()}
          isLoading={loading.submit}
          disabledCancel={loading.submit}
          disabledSubmit={loading.submit}
        />
      }
    />
  )
}

export default ChangeVehicle
