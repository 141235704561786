import { axiosService } from "../../index"
import { loginEndpoints } from "../../sources"

const login = (user, pass) => {
  return axiosService.authLogin(user, pass)
}
const userRemovePass = (user, config) => {
  return axiosService.postWithoutAuth(loginEndpoints.RESET_PASS, user, config)
}

export const logInInterface = {
  userRemovePass,
  login,
}
