import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"

import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { validationDisarm } from "Components/Validation/Vehicles/SearchWafers"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"

const ModalDisarm = (props) => {
  const { handleclose, open } = props

  const [isLoading, setIsLoading] = useState(false)

  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async (values) => {
    setIsLoading(true)
    await interfaces.wafersInterface
      .disarm(values)
      .then((res) => {
        setTimeout(() => {
          window.location.assign(res.data)
        }, 1000)
        createDismissableSnackbar("Libro de Desarme descargado!", {
          variant: "success",
        })
        handleclose(true)
      })
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal", {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  const location = [
    {
      id: 1,
      value: "Río Cuarto",
    },
    {
      id: 3,
      value: "Ruta 7",
    },
  ]

  const object = [
    {
      class: "textfield",
      label: "Nº de V desde",
      name: "desde",
      type: "number",
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      label: "Nº de V hasta",
      name: "hasta",
      type: "number",
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Sucursal",
      name: "ubicacion",
      options: location,
      optionLabel: `options["value"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
  ]

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        title="Exportar libro de desarme"
        disableChildHeight
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        handleclosedisabled={isLoading}
        children={
          <Formik
            initialValues={{
              desde: "",
              hasta: "",
              ubicacion: "",
            }}
            validationSchema={validationDisarm}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form>
                <InputsWithValidate
                  fields={object}
                  spacing={3}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />

                <button
                  disabled={isSubmitting}
                  style={{ display: "none" }}
                  ref={formSubmitRef}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        }
        actions={
          <CancelConfirmButtons
            onClickCancel={handleclose}
            onClickSubmit={handleSubmit}
            isLoading={isLoading}
            disabledSubmit={isLoading}
            disabledCancel={isLoading}
          />
        }
      />
    </div>
  )
}

export default ModalDisarm
