import React, { useRef, useState } from "react"
import setState from "Components/Hooks/SetState"

import {
  Card,
  CardMedia,
  IconButton,
  CardHeader,
  CardActionArea,
  Typography,
  Tooltip,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import PublishIcon from "@material-ui/icons/Publish"
import GetAppIcon from "@material-ui/icons/GetApp"
import { styles } from "./styles"
import { fileTypeTest } from "Helpers"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ImgZoomStock from "../../ImgZoomStock"
import ReactImageGallery from "react-image-gallery"

const PictureCardWithZoom = (props) => {
  const {
    img,
    index,
    href,
    txt,
    longtxt,
    editIcon,
    editDelete = true,
    handleDelete,
    swipes,
    handleUpload,
    edit,
    onClick,
  } = props
  const classes = styles()

  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const ref = useRef()
  const imgRef = useRef(null)

  const [zoomScale, setZoomScale] = useState(false)
  const { state: fullImg, setState: setFullImg } = setState()
  const { state: modalState, setOpen: setModalOpen, setClose: setModalClose } = setState()

  const handleOpen = (index) => {
    setModalOpen()
    setSelectedImageIndex(index)
  }

  const fileType = fileTypeTest(href)

  function downloadFile(currentImg) {
    if (href) return window.location.assign(href)
    return window.open(currentImg)
  }

  function handleZoom(e) {
    setFullImg({ fullScreen: e })
    if (!e) {
      setZoomScale(false)
    }
  }

  const images = swipes.map((item) => ({ original: item.img }))

  const children = () => {
    return (
      <div>
        <div className={classes.divContentImg}>
          <ReactImageGallery
            ref={ref}
            items={images}
            useBrowserFullscreen={false}
            showBullets={true}
            onClick={() => ref.current.fullScreen()}
            onScreenChange={(e) => handleZoom(e)}
            startIndex={selectedImageIndex}
            onSlide={(currentIndex) => setSelectedImageIndex(currentIndex)}
            renderPlayPauseButton={() => (
              <IconButton
                className={classes.donwloadIcon}
                onClick={() =>
                  downloadFile(images[ref.current.getCurrentIndex()].original)
                }
              >
                <GetAppIcon style={{ width: "35px", height: "35px" }} />
              </IconButton>
            )}
            renderItem={({ original }) => (
              <div
                style={{ cursor: zoomScale ? "zoom-out" : "zoom-in" }}
                onClick={() =>
                  fullImg.fullScreen ? setZoomScale(!zoomScale) : null
                }
              >
                <ImgZoomStock
                  img={original}
                  zoomScale={zoomScale ? 3 : 1}
                  transitionTime={0.5}
                  width={"100%"}
                  height={"515px"}
                  isFullscreen={fullImg.fullScreen}
                />
              </div>
            )}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Tooltip title={longtxt ? longtxt : txt}>
              <Typography
                variant="body1"
                style={{
                  overflow: "hidden",
                  wordWrap: "break-word",
                  textOverflow: "ellipsis",
                }}
                display="block"
              >
                {txt}
              </Typography>
            </Tooltip>
          }
          action={
            edit ? (
              <div>
                {editIcon ? (
                  <IconButton onClick={handleUpload}>
                    <PublishIcon color="primary" />
                  </IconButton>
                ) : null}
                {editDelete ? (
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon className={classes.icondelete} />
                  </IconButton>
                ) : null}
              </div>
            ) : null
          }
        />
        <CardActionArea
          ref={imgRef}
          target="_blank"
          href={fileType === "video" ? null : href}
          onClick={() =>
            fileType === "doc"
              ? null
              : onClick
              ? onClick
              : handleOpen(index - 1)
          }
        >
          <CardMedia
            className={classes.media}
            component="img"
            image={img}
            {...props}
          />
        </CardActionArea>
      </Card>

      <DialogComponent
        ref={imgRef}
        title={txt}
        maxHeight
        disableEscapeKeyDown={fullImg.fullScreen === true ? true : false}
        open={modalState.open}
        handleclose={setModalClose}
        widthLarge="85vw"
        children={children()}
      />
    </>
  )
}

export default PictureCardWithZoom
