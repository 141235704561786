import React from "react"

import DeleteIcon from "@material-ui/icons/Delete"
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  CircularProgress,
} from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import GetAppIcon from "@material-ui/icons/GetApp"

const ListWithIcon = (props) => {
  const {
    data,
    nameIncludes,
    selectedItem,
    onClickSelect,
    onClickEdit,
    onClickDelete,
    onClickExport,
    icon = true,
    secondary,
    loading = false,
    disabledOthers = false,
    hiddenDelete = false,
    disabled,
    multiNames,
  } = props

  return (
    <List component="nav">
      {data.map((item) => (
        <>
          <ListItem
            button
            disabled={
              disabled
                ? disabled
                : disabledOthers
                ? selectedItem && selectedItem !== item.id
                : disabledOthers
            }
            onClick={() => onClickSelect(item)}
            selected={selectedItem === item.id}
          >
            <ListItemText
              style={{ fontWeight: 500 }}
              primary={
                nameIncludes
                  ? item[nameIncludes]
                  : multiNames
                  ? // eslint-disable-next-line
                    eval(multiNames)
                  : null
              }
              //eslint-disable-next-line
              secondary={secondary ? eval(secondary) : null}
            />
            {icon ? (
              <ListItemSecondaryAction>
                {!hiddenDelete && onClickDelete ? (
                  <>
                    {selectedItem === item.id ? (
                      <Tooltip title="Eliminar">
                        <IconButton onClick={() => onClickDelete()}>
                          <DeleteIcon style={{ color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                ) : null}
                {onClickEdit ? (
                  <>
                    {selectedItem === item.id ? (
                      <Tooltip title="Editar">
                        <IconButton onClick={() => onClickEdit()}>
                          <OpenInNewIcon style={{ color: "black" }} />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                ) : null}
                {onClickExport ? (
                  <>
                    {selectedItem === item.id ? (
                      <Tooltip title="Exportar">
                        <IconButton
                          onClick={() => {
                            !loading && onClickExport()
                          }}
                        >
                          {!loading ? (
                            <GetAppIcon style={{ color: "black" }} />
                          ) : (
                            <CircularProgress
                              style={{
                                color: "black",
                                width: "25px",
                                height: "25px",
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </>
                ) : null}
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        </>
      ))}
    </List>
  )
}

export default ListWithIcon
