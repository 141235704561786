import React, { useState, useContext } from "react"

import {
  GENERAL,
  DATA_ORIGIN,
  UBI_DEPOSITO,
  PRICE_SALE,
} from "../../InfoFields"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { EditContext } from "Components/Hooks/ContextEdit"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import useStyles from "./styles"
import CustomButton from "Components/UI/Atoms/CustomButton"
import { printPDF } from "Helpers"
import Loader from "Components/UI/Atoms/Loader"

export const Home = (props) => {
  const { data, setData } = props

  const [edit] = useContext(EditContext)

  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(0)
  const createDismissableSnackbar = useCustomSnackbar()

  const classes = useStyles()

  const listVerticalTabs = [
    {
      id: 0,
      title: "General",
    },
    {
      id: 1,
      title: "Datos del Origen",
    },
    {
      id: 2,
      title: "Ubicación en Depósito",
    },
    {
      id: 3,
      title: "Precio de Venta",
    },
  ]

  function handleChange(object) {
    setData({
      ...data,
      stock: {
        ...data.stock,
        ...object,
      },
    })
  }

  async function handleSubmit() {
    await interfaces.plantStorage
      .searchStorageCode(data.stock.almacen_codigo, data.stock.almacen_empresa)
      .then((res) => {
        setData({
          ...data,
          stock: {
            ...data.stock,
            almacen: res.data,
            almacen_id: res.data.id,
          },
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  async function printId() {
    setLoading(true)
    await interfaces.plantInterfaceClassification
      .stockSticker(data.stock.id)
      .then((res) => {
        printPDF(res).print()
        createDismissableSnackbar("Sticker impreso con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  async function getRudac() {
    setLoading(true)
    await interfaces.plantInterfaceClassification
      .getRudac(data.stock.id)
      .then((res) => {
        setData({ ...data, stock: { ...data.stock, oblea: res.data.wafer } })
        createDismissableSnackbar(res.data.msg, {
          variant: "success",
        })
      })
      .catch((err) => {
        createDismissableSnackbar(err.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }
  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={GENERAL(data.stock, data.inputs, edit, handleChange)}
              spacing={2}
            />
            <div className={classes.buttons}>
              <CustomButton
                buttonEnd
                style={{ marginTop: "10px" }}
                text="Obtener oblea RUDAC"
                onClick={getRudac}
                disabled={
                  !edit ||
                  data.stock.empresa !== "ECO" ||
                  data.stock.oblea === 0
                }
              />
              <CustomButton
                buttonEnd
                style={{ marginTop: "10px", marginLeft: "10px" }}
                text="Imprimir sticker"
                onClick={printId}
                disabled={!edit}
              />
            </div>
          </div>
        )
      case 1:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={DATA_ORIGIN(data.stock)}
              spacing={2}
            />
          </div>
        )
      case 2:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={UBI_DEPOSITO(
                data.stock,
                data.inputs,
                edit,
                handleChange,
                handleSubmit
              )}
              spacing={2}
            />
          </div>
        )
      case 3:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={PRICE_SALE(data, data.stock, edit, handleChange)}
              spacing={2}
            />
          </div>
        )
      default:
        break
    }
  }
  return (
    <>
      <ContentWithSubTabs
        listVerticalTabs={listVerticalTabs}
        value={tab}
        setValue={setTab}
        children={renderTabs(tab)}
      />
      {loading && <Loader />}
    </>
  )
}
