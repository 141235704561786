import React from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableFooter,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"

import useStyles from "./styles"

const InternalUsersTable = ({ col, rows, onChange, deleteRow, addRow }) => {
  const classes = useStyles()
  const deleteUser = () => {
    return (
      <div style={{ display: "flex" }}>
        <div>Si</div>
        {/* <a style={{ color: "green" }}>,&nbsp;¿eliminar?</a> */}
      </div>
    )
  }

  const createUser = () => {
    return (
      <div style={{ display: "flex" }}>
        <div>No</div>
        {/* <a style={{ color: "green" }}>,&nbsp;¿crear?</a> */}
      </div>
    )
  }

  return (
    <Paper>
      <Table style={{ tableLayout: "fixed" }} size="small">
        <TableHead>
          <TableRow className={classes.tableRow}>
            {col.map(({ title, style }) => (
              <TableCell style={style}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={`row${index}`}>
              <TableCell style={col[0].style}>
                <TextField
                  name="nombre"
                  defaultValue={row.nombre}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[1].style}>
                <TextField
                  name="puesto"
                  defaultValue={row.puesto}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[2].style}>
                <TextField
                  name="email"
                  defaultValue={row.email}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[3].style}>
                <TextField
                  name="telefono"
                  defaultValue={row.telefono}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[4].style}>
                <TextField
                  name="zona"
                  defaultValue={row.zona}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[5].style}>
                <TextField
                  name="comentarios"
                  defaultValue={row.comentarios}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[5].style}>
                {row.usuario === 1 ? deleteUser() : createUser()}
              </TableCell>
              <TableCell>
                <Tooltip title="Eliminar">
                  <IconButton onClick={() => deleteRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow
            style={{
              height: "35px",
            }}
          >
            <TableCell colSpan={8}>
              <Tooltip title="Agregar">
                <IconButton onClick={addRow}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default InternalUsersTable
