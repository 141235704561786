import { React, forwardRef } from "react"
import { TextField } from "formik-material-ui"
import { Formik, Field } from "formik"

import { Grid } from "@material-ui/core"
import { validationNewRow } from "Components/Validation/Settings/Various/LotsRow"

const Form = forwardRef((props, ref) => {
  const { onSubmit } = props
  return (
    <>
      <Formik
        initialValues={{
          numero: null,
          ancho: null,
          largo: null
        }}
        validationSchema={validationNewRow}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Nº fila"
                  type="number"
                  name="numero"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Ancho"
                  name="ancho"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "120px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  type="number"
                  label="Largo"
                  name="largo"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        )}
      </Formik>
    </>
  )
})
export default Form