import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  tableRow: {
    backgroundColor: theme.palette.primary.main,
    height: "35px",
  },
}))

export default useStyles
