import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import PaperCompany from "./PaperCompany"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { getNavbar } from "service/helpers/localstorage"

const Insurace = () => {
  const [open, setOpen] = useState(false)
  const [canClose, setCanClose] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  function handleClose() {
    if (canClose === true) {
      setOpen(false)
    } else {
      createDismissableSnackbar("Tiene cambios sin guardar!", {
        variant: "error",
      })
    }
  }
  function handleOpen() {
    setOpen(true)
  }
  return (
    <>
      <ButtonInsideBox
        onClick={handleOpen}
        text="Compañías de seguros"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/building.jpg"}
        disabled={JSON.parse(getNavbar()).insurances ? false : true}
      />
      <DialogComponent
        widthLarge="55vw"
        disableBackdropClick
        title="Compañías de seguros"
        open={open}
        handleclose={handleClose}
        maxHeight
        children={
          <PaperCompany setCanClose={setCanClose} canClose={canClose} />
        }
      />
    </>
  )
}

export default Insurace
