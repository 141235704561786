import { useMemo, useEffect, useState } from "react"
import { getToken } from "service/helpers/localstorage"
import io from "socket.io-client"

export const useSocket = (serverPath, auth) => {
  const socket = useMemo(
    () =>
      io.connect(serverPath, {
        transports: ["websocket"],
        query: {
          auth: getToken(),
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serverPath, auth]
  )
  const [online, setOnline] = useState(false)
  const [notification, setNotification] = useState({})

  useEffect(() => {
    socket.on("connect", () => {
      setOnline(true)
    })
  }, [socket, auth])

  useEffect(() => {
    socket.on("notifications", (noti) => {
      setNotification(noti)
    })
  }, [socket])

  return {
    socket,
    online,
    notification,
  }
}
