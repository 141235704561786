import React from "react"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const VerticalTabs = (props) => {
  const mediumViewport = useMediaQuery("(min-width:600px)")

  return (
    <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
      <Hidden>
        <Tabs
          indicatorColor="primary"
          scrollButtons="on"
          textColor="primary"
          orientation={mediumViewport ? "vertical" : "horizontal"}
          variant="scrollable"
          value={props.value}
          onChange={props.onChange}
        >
          <Tab label="Fotografias Principales" />
          <Tab label="Fotografias Adicionales" />
          <Tab label="Archivos" />
        </Tabs>
      </Hidden>
    </div>
  )
}

export default VerticalTabs
