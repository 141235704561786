import React, { useEffect, useState } from "react"

import { Grid, Hidden, Typography } from "@material-ui/core"

import { interfaces } from "service/interfaces"
import Family from "./Family"
import Products from "./Products"

const FamilyAndProducts = () => {
  const [data, setData] = useState([])
  const [family, setFamily] = useState(null)
  const [productId, setProductId] = useState(null)
  const [render, setRender] = useState(true)
  const changeRender = () => {
    setRender(!render)
  }

  const handleClickItemFamily = (item) => {
    if (family === item.id) {
      setFamily(null)
    } else {
      setFamily(item.id)
      setProductId(null)
    }
  }

  const handleClickItemProduct = (item) => {
    setProductId(item.id)
  }

  useEffect(() => {
    interfaces.configInterface
      .listFamiliAndProducts()
      .then((res) => setData(res.data))
  }, [render])

  return (
    <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
      <Grid item md={4} sm={12} xs={12}>
        <Hidden>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Familias
          </Typography>
        </Hidden>
        <Family
          family={family}
          data={data.familys}
          changeRender={changeRender}
          onClickSelect={handleClickItemFamily}
        />
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Hidden>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Productos
          </Typography>
        </Hidden>
        <Products
          data={data}
          family={family}
          productId={productId}
          changeRender={changeRender}
          onClickSelect={handleClickItemProduct}
        />
      </Grid>
    </Grid>
  )
}

export default FamilyAndProducts
