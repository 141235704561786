import * as yup from "yup"

export const order = yup.object().shape(
  {
    operario_desmontaje_id: yup
      .string()
      .nullable()
      .when(["operario_taller_id"], {
        is: (operario_taller_id) =>
          !operario_taller_id || operario_taller_id.length === null,
        then: yup.string().required("Ingrese uno de los dos campos").nullable(),
      }),
    operario_taller_id: yup
      .string()
      .nullable()
      .when(["operario_desmontaje_id"], {
        is: (operario_desmontaje_id) =>
          !operario_desmontaje_id || operario_desmontaje_id.length === null,
        then: yup.string().required("Ingrese uno de los dos campos").nullable(),
      }),
  },
  [["operario_desmontaje_id", "operario_taller_id"]]
)
