import React from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"

import MuiAutocomplete from "@material-ui/lab/Autocomplete"
import MuiTextField from "@material-ui/core/TextField"
import Chip from "@material-ui/core/Chip"

import useStyles from "./styles"
const CustomAutocomplete = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    defaultValue,
    onChange,
    label,
    name,
    disableClearable = true,
    value,
    getOptionDisabled,
    freeSolo = false,
    sortBy,
    onInputChange,
    onKeyDown,
    heightInput,
    endAdornment = null,
    style,
    noOptionsText = "No se encontraron resultados",
    size,
    onKeyPress,
    autoHighlight,
    inputValue,
    renderOption,
    key,
    groupBy,
    variant = "outlined",
  } = props

  const localClasess = useStyles()

  return (
    <div>
      <MuiAutocomplete
        key={key}
        forcePopupIcon={true}
        inputValue={inputValue}
        autoHighlight={autoHighlight}
        onKeyPress={onKeyPress}
        classes={{ option: localClasess.option }}
        size={size}
        onKeyDown={onKeyDown}
        getOptionDisabled={getOptionDisabled}
        style={style}
        freeSolo={freeSolo}
        onInputChange={onInputChange}
        disableClearable={disableClearable}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        noOptionsText={noOptionsText}
        openText={"Abrir"}
        closeText={"Cerrar"}
        clearText={"Borrar"}
        openOnFocus
        options={options.sort(function (a, b) {
          if (a[sortBy] > b[sortBy]) {
            return 1
          }
          if (a[sortBy] < b[sortBy]) {
            return -1
          }
          return 0
        })}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        renderOption={renderOption}
        groupBy={groupBy}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            style={{ height: heightInput }}
            name={name}
            fullWidth
            label={label}
            variant={variant}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div>
                  {params.InputProps.endAdornment}
                  {endAdornment}
                </div>
              ),
            }}
          />
        )}
      />
    </div>
  )
}
const FormikAutocomplete = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    defaultValue = null,
    onChange,
    label,
    name,
    disableClearable = true,
    value,
    getOptionDisabled,
    freeSolo = false,
    autoSelect = false,
    style,
    sortBy,
    onInputChange,
    groupBy,
    endAdornment = null,
    noOptionsText = "No se encontraron resultados",
    onKeyDown,
    inputValue,
    key,
  } = props

  const classes = useStyles()
  return (
    <div>
      <MuiAutocomplete
        key={key}
        blurOnSelect={"touch"}
        classes={{ option: classes.option }}
        onKeyDown={onKeyDown}
        inputValue={inputValue}
        freeSolo={freeSolo}
        autoSelect={autoSelect}
        getOptionDisabled={getOptionDisabled}
        disableClearable={disableClearable}
        getOptionLabel={getOptionLabel}
        disabled={disabled}
        noOptionsText={noOptionsText}
        openText={"Abrir"}
        closeText={"Cerrar"}
        clearText={"Borrar"}
        options={options.sort(function (a, b) {
          if (a[sortBy] > b[sortBy]) {
            return 1
          }
          if (a[sortBy] < b[sortBy]) {
            return -1
          }
          return 0
        })}
        defaultValue={defaultValue}
        value={value}
        fullWidth
        onInputChange={onInputChange}
        onChange={onChange}
        groupBy={groupBy}
        renderInput={(params) => (
          <Field
            {...params}
            style={style}
            component={TextField}
            className={classes.autocomplete}
            name={name}
            fullWidth
            label={label}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div>
                  {params.InputProps.endAdornment}
                  {endAdornment}
                </div>
              ),
            }}
          />
        )}
      />
    </div>
  )
}
const MultiAutocomplete = (props) => {
  const {
    getOptionLabel,
    disabled = false,
    options,
    onChange,
    label,
    name,
    disableClearable = false,
    value,
    getOptionDisabled,
    autoSelect = false,
    heightInput,
    sortBy,
    tagName,
    noOptionsText = "No se encontraron resultados",
    defaultValue,
    key,
  } = props
  const classes = useStyles()
  return (
    <MuiAutocomplete
      key={key}
      multiple
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip label={option[tagName]} {...getTagProps({ index })} />
        ))
      }
      style={{width: '100%'}}
      onChange={onChange}
      disableClearable={disableClearable}
      getOptionDisabled={getOptionDisabled}
      autoSelect={autoSelect}
      options={options.sort(function (a, b) {
        if (a[sortBy] > b[sortBy]) {
          return 1
        }
        if (a[sortBy] < b[sortBy]) {
          return -1
        }
        return 0
      })}
      value={value}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      disabled={disabled}
      noOptionsText={noOptionsText}
      openText={"Abrir"}
      closeText={"Cerrar"}
      clearText={"Borrar"}
      limitTags={2}
      classes={{ option: classes.option }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          style={{ height: heightInput, minHeight: "80px" }}
          className={classes.autocomplete}
          name={name}
          fullWidth
          label={label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}
export { CustomAutocomplete, FormikAutocomplete, MultiAutocomplete }
