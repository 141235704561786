import React, { useContext, useState } from "react"

import { Formik, Field } from "formik"
import { Grid, Button } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField } from "formik-material-ui"
import TextFieldMaterial from "@material-ui/core/TextField"
import PrintIcon from "@material-ui/icons/Print"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"

import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"
import Loader from "Components/UI/Atoms/Loader"
import { CITIES } from "Constants"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { printPDF } from "Helpers"

const Insured = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const [edit] = useContext(EditContext)
  const [wait, setWait] = useState(false)
  const { formEdit, setFormEdit, formApi, setFormApi, initialData } =
    useContext(FormContext)
  const provincias = formEdit.selectInputs.provincias

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          [e.target.name]: e.target.value,
        },
      },
    })

    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        [e.target.name]: e.target.value,
      },
    })
  }

  function sendWhatsapp() {
    let message = `Buenos días estimado/a - asegurado/a. Como le va? Esperamos muy bien.\n\nNos contactamos de la empresa *ECO Autoparts*, desarmadero legal inscripto en el Registro Único de Desarmaderos de Automotores y Actividades Conexas (RUDAC), con el propósito de informarle que fuimos designados para gestionar el retiro y posterior baja ante el registro del automotor correspondiente referido a la unidad que se detalla a continuación: \n
    •	Rezago: *${formEdit.vehicle.dominio} ${formEdit.vehicle.marca.marca} ${formEdit.vehicle.modelo.modelo} ${formEdit.vehicle.año}*
    •	Compañía aseguradora: *${formEdit.vehicle.cia_seguros.nombre}* 
    •	Baja a realizar:  *${formEdit.vehicle.tipo_baja.descripcion}*\n\nMi nombre es *Georgina Navarro*, responsable del área de Gestoría y estaré a cargo de la gestión de su siniestro. \n\nPara poder llevar a cabo el retiro y baja de los restos agradeceré que nos confirme si la ubicación del rezago es: \n
    •	Domicilio: *${formEdit.vehicle.vehiculo_retiro.a_domicilio}* 
    •	Provincia: *${formEdit.vehicle.vehiculo_retiro.asegurado_provincia.provincia}*
    •	Localidad: *${formEdit.vehicle.vehiculo_retiro.asegurado_localidad.localidad}* \n\nTambién solicitamos nos confirme si estaría en conformidad de hacer entrega de los restos y proceder con la gestión de baja del rezago.\n\nQuedamos al aguardo de una pronta respuesta.\n\nDesde ya muchas gracias\n\nSaludos cordiales.`
    let url =
      "https://api.whatsapp.com/send?phone=549" +
      formEdit.vehicle.vehiculo_retiro.a_telefono +
      "&text=" +
      encodeURIComponent(message)
    window.open(url, "_blank")
  }

  const SubmitApi = async () => {
    await interfaces.vehicleInterface
      .putOneVehicle(formEdit.vehicle.id, formApi)
      .then(() => {
        createDismissableSnackbar("Vehículo editado con éxito!", {
          variant: "success",
        })
      })
      .catch(() =>
        createDismissableSnackbar("Hubo un error!", {
          variant: "error",
        })
      )
  }

  const onSubmitControl = async () => {
    setWait(true)
    await interfaces.vehicleInterface
      .vehicleControl(formEdit.vehicle.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal! Pueden faltar datos", {
          variant: "error",
        })
      })
    setWait(false)
  }

  const onSubmitRetreat = async (id) => {
    setWait(true)
    await interfaces.vehicleInterface
      .vehicleRetreat(formEdit.vehicle.id)
      .then((res) => printPDF(res, 500, 800).print())
      .catch(() => {
        createDismissableSnackbar("Algo salió mal! Pueden faltar datos", {
          variant: "error",
        })
      })
    setWait(false)
  }

  async function submitsControl() {
    await SubmitApi()
    await onSubmitControl()
  }
  async function submitsRetreat() {
    await SubmitApi()
    await onSubmitRetreat()
  }

  const disabledField = () => {
    if (initialData.header.authorization === 1 && edit) {
      return true
    }
    if (initialData.header.authorization === 1 && !edit) {
      return true
    } else return edit ? false : true
  }

  return (
    <div>
      <Grid container spacing={3} justify="center" alignItems="center">
        <Formik>
          <>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                variant="outlined"
                fullWidth
                name="a_apellido_nombre"
                disabled={disabledField()}
                label="Apellido y nombre"
                type="text"
                value={formEdit.vehicle.vehiculo_retiro.a_apellido_nombre}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                variant="outlined"
                disabled={disabledField()}
                fullWidth
                label="Contacto"
                name="a_contacto"
                type="text"
                value={formEdit.vehicle.vehiculo_retiro.a_contacto}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                fullWidth
                variant="outlined"
                disabled={disabledField()}
                type="number"
                name="a_telefono"
                label="Telefono"
                value={formEdit.vehicle.vehiculo_retiro.a_telefono}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                fullWidth
                variant="outlined"
                disabled={disabledField()}
                type="text"
                id="email"
                name="a_correo"
                label="Correo electronico"
                value={formEdit.vehicle.vehiculo_retiro.a_correo}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                fullWidth
                type="text"
                variant="outlined"
                name="a_domicilio"
                disabled={disabledField()}
                label="Domicilio"
                value={formEdit.vehicle.vehiculo_retiro.a_domicilio}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={provincias.sort(function (a, b) {
                  if (a.nombre > b.nombre) {
                    return 1
                  }
                  if (a.nombre < b.nombre) {
                    return -1
                  }
                  return 0
                })}
                getOptionLabel={(provincias) => provincias.provincia}
                disabled={disabledField()}
                defaultValue={provincias.find(
                  (value) =>
                    value.id ===
                    parseInt(formEdit.vehicle.vehiculo_retiro.a_provincia_id)
                )}
                onChange={(event, value) => {
                  handleChange({
                    target: { name: "a_provincia_id", value: value.id },
                  })
                }}
                renderInput={(params) => (
                  <TextFieldMaterial
                    {...params}
                    fullWidth
                    label="Provincia"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                noOptionsText={"No se encontraron resultados"}
                options={CITIES.sort(function (a, b) {
                  if (a.localidad > b.localidad) {
                    return 1
                  }
                  if (a.localidad < b.localidad) {
                    return -1
                  }
                  return 0
                }).filter(
                  (e) =>
                    e.provincia_id ===
                    formEdit.vehicle.vehiculo_retiro.a_provincia_id
                )}
                getOptionLabel={(CITIES) =>
                  CITIES.localidad + " - CP: " + CITIES.cp
                }
                disabled={disabledField()}
                defaultValue={CITIES.find(
                  (value) =>
                    value.id ===
                    parseInt(formEdit.vehicle.vehiculo_retiro.a_localidad_id)
                )}
                onChange={(event, value) => {
                  handleChange({
                    target: { name: "a_localidad_id", value: value.id },
                  })
                }}
                renderInput={(params) => (
                  <TextFieldMaterial
                    {...params}
                    fullWidth
                    label="Localidad"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Field
                component={TextField}
                fullWidth
                variant="outlined"
                disabled={disabledField()}
                name="a_detalle"
                id="DetailAddress"
                label="Detalles del domicilio"
                type="text"
                value={formEdit.vehicle.vehiculo_retiro.a_detalle}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Field
                component={TextField}
                fullWidth
                variant="outlined"
                disabled={disabledField()}
                type="text"
                name="a_observaciones"
                label="Observaciones"
                value={formEdit.vehicle.vehiculo_retiro.a_observaciones}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid
              item
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                disabled={disabledField()}
                style={{ marginRight: "10px" }}
                variant="contained"
                startIcon={<WhatsAppIcon />}
                onClick={() => sendWhatsapp()}
              >
                ENVIAR WHATSAPP
              </Button>

              <Button
                disabled={disabledField()}
                style={{ marginRight: "10px" }}
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={() => submitsControl()}
              >
                TALÓN DE CONTROL
              </Button>

              <Button
                disabled={disabledField()}
                variant="contained"
                endIcon={<PrintIcon />}
                onClick={() => submitsRetreat()}
              >
                ORDEN DE RETIRO
              </Button>
            </Grid>
          </>
        </Formik>
      </Grid>
      {wait ? <Loader /> : null}
    </div>
  )
}

export default Insured
