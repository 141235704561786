import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'

import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'
import useCustomSnackbar from 'Components/UI/Atoms/SnackBar'
import InputsWithValidate from 'Components/UI/Molecules/InputsWithValidate'
import { PROVINCIAS, CITIES, TIPO_BAJA, TIPO_COTIZACION } from 'Constants'
import { sinisterQuote } from 'Components/Validation/Vehicles/SearchVehicles'
import { interfaces } from 'service/interfaces'

const Create = (props) => {
  const { open, handleClose, render, setRender } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [data, setData] = useState({
    marca: [],
    modelo: [],
    compañia: [],
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    interfaces.quotationsInterface.beforeCreate().then((res) => {
      setData(res.data)
    })
  }, [])

  const createQuote = async (values) => {
    setLoading(true)
    values.dominio = values.dominio.toUpperCase()
    values.nro_siniestro = values.nro_siniestro.toUpperCase()
    values.año = parseInt(moment(values.año).format('yyyy'))
    values.vencimiento =
      values.tipo_cotizacion !== 'Compulsa' ? null : values.vencimiento

    await interfaces.quotationsInterface
      .create(values)
      .then((res) => {
        setRender(!render)
        createDismissableSnackbar(res.data, {
          variant: 'success',
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: 'error',
        })
      })
    setLoading(false)
    handleClose()
  }

  const fields = (values) => [
    {
      id: 0,
      label: 'Tipo de cotización',
      name: 'tipo_cotizacion',
      options: TIPO_COTIZACION,
      optionLabel: `options["value"]`,
      optionValue: 'value',
      sm: 12,
      xs: 12,
      class: 'autocomplete',
    },
    {
      label: 'Fecha de vencimiento',
      name: 'vencimiento',
      sm: 12,
      xs: 12,
      class: 'datepicker',
      disableFuture: false,
      views: ['date'],
      format: "dd-MM-yyyy",
      disabled: values.tipo_cotizacion === 'Compulsa' ? false : true,
    },

    {
      id: 1,
      class: 'textfield',
      label: 'Dominio',
      name: 'dominio',
      sm: 6,
      xs: 12,
      inputProps: { maxLength: 7 },
    },
    {
      id: 2,
      class: 'textfield',
      label: 'Nro. de Siniestro',
      name: 'nro_siniestro',
      sm: 6,
      xs: 12,
    },
    {
      id: 3,
      label: 'Compañía',
      name: 'cia_seguros_id',
      options: data.compañia,
      optionLabel: `options["nombre"]`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
      sortBy: 'nombre',
    },
    {
      id: 4,
      label: 'Tipo de baja',
      name: 'tipo_baja_id',
      options: TIPO_BAJA,
      optionLabel: `options["baja"]`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
    },
    {
      id: 5,
      label: 'Marca',
      name: 'marca_id',
      options: data.marca,
      optionLabel: `options["marca"]`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
      sortBy: 'marca',
    },
    {
      id: 6,
      label: 'Modelo',
      name: 'modelo_id',
      options: data.modelo.filter((e) => e.marca_id === values.marca_id),
      optionLabel: `options["modelo"]`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
      sortBy: 'modelo',
    },
    {
      id: 7,
      label: 'Provincia',
      name: 'provincia_id',
      options: PROVINCIAS,
      optionLabel: `options["provincia"]`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
    },
    {
      id: 7,
      label: 'Localidad',
      name: 'localidad_id',
      options: CITIES.filter((e) => e.provincia_id === values.provincia_id),
      optionLabel: `options["localidad"] + " (CP: " + options["cp"] + ")"`,
      optionValue: 'id',
      sm: 6,
      xs: 12,
      class: 'autocomplete',
      sortBy: 'localidad',
    },
    {
      id: 8,
      label: 'Año',
      name: 'año',
      sm: 12,
      xs: 12,
      class: 'datepicker',
      disableFuture: true,
      views: ['year'],
    },
    {
      id: 9,
      type: 'number',
      label: 'Cotización restos (IVA inc.)',
      name: 'cotizacion_restos',
      sm: 12,
      xs: 12,
      class: 'textfield',
      disabled: values.tipo_cotizacion === 'Sin costo',
    },
    {
      id: 9,
      type: 'number',
      label: 'Cotización retiro (IVA inc.)',
      name: 'cotizacion_retiro',
      sm: 12,
      xs: 12,
      class: 'textfield',
    },
  ]

  const children = (
    <Formik
      onSubmit={createQuote}
      initialValues={{
        tipo_cotizacion: null,
        vencimiento: null,
        dominio: '',
        nro_siniestro: '',
        cia_seguros_id: null,
        tipo_baja_id: null,
        provincia_id: null,
        localidad_id: null,
        marca_id: null,
        modelo_id: null,
        año: null,
        cotizacion_restos: null,
        cotizacion_retiro: null,
      }}
      validationSchema={sinisterQuote}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={fields(values)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: 'none' }}
            type='submit'
            ref={formSubmitRef}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      title='Nueva cotización'
      maxHeight
      widthLarge='500px'
      children={children}
      actions={
        <CancelConfirmButtons
          submitText={'Confirmar'}
          onClickCancel={handleClose}
          onClickSubmit={handleSubmit}
          disabledCancel={loading}
          disabledSubmit={loading}
        />
      }
    />
  )
}

export default Create
