import React, { useContext, useState, useEffect } from "react"

import { interfaces } from "service/interfaces"
import BasicTable from "Components/UI/Atoms/BasicTable"

import { EditContext } from "Components/Hooks/ContextEdit"

import TableRemote from "./TableRemote"
import Table from "./TableLocal"
import IconButton from "@material-ui/core/IconButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CloseIcon from "@material-ui/icons/Close"


const PartsTable = ({ data, onDataChange }) => {
 
  const [edit] = useContext(EditContext)
  const [isLoading, setIsLoading] = useState(false)
  const [ formEdit, setFormEdit ] = useState(data.details)
  const createDismissableSnackbar = useCustomSnackbar()

  useEffect(() => {
    setFormEdit(data.details)
  }, [data.details])

  async function apiCall(id) {
      await interfaces.compactionInterface
      .infoOneCompaction(id)
      .then((res) => {
        setFormEdit({ 
          data: res.data.details
        })
      })
    }
    !formEdit && apiCall()
  
  async function deleteDetail(id) {
    setIsLoading(true)
    await interfaces.compactionInterface
      .removePending(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        deleteItem(id)
      })
      setIsLoading(false)
  }

  const deleteItem = (id) => {
    let newItem = formEdit.filter((e) => e.id !== id)
    setFormEdit(newItem)
    onDataChange(newItem)
  }

  const iconDelete = (id) => (
    <IconButton onClick={() => deleteDetail(id)} disabled={!edit || data.act.estado === 1}>
      <CloseIcon />
    </IconButton>
  )

  return (
        <>
          <div >
            {data.act.tipo === "LOCAL"? 
              <BasicTable
                col={[
                  { title: "", style: { width: "20px" } },
                  { title: "Nº de V", style: { width: "100px" } },
                  { title: "Dominio", style: { width: "100px" } },
                  { title: "Marca", style: { width: "100px" } },
                  { title: "Modelo", style: { width: "100px" } },
                  { title: "Año", style: { width: "100px" } },
                  { title: "Compañia", style: { width: "100px" } },
                  { title: "", style: { width: "60px", marginRight: "20px" } }
                ]}
                size={"small"}
                rows={formEdit.map((row) => {
                  return {
                    "": "",
                    nrodev: row.nrodev,
                    dominio: row.dominio,
                    marca: row.marca,
                    modelo: row.modelo,
                    año: row.año,
                    compañia: row.compañia,
                    action: iconDelete(row.id)
                  }
                })}
               /> :
              <BasicTable
                col={[
                  { title: "", style: { width: "20px" } },
                  { title: "Pieza", style: { width: "100px" } },
                  { title: "Nº de V", style: { width: "100px" } },
                  { title: "Dominio", style: { width: "100px" } },
                  { title: "Marca", style: { width: "100px" } },
                  { title: "Modelo", style: { width: "80px" } },
                  { title: "Año", style: { width: "60px" } },
                  { title: "Nº pieza", style: { width: "140px" } },
                  { title: "Compañia", style: { width: "100px" } },
                  { title: "", style: { width: "60px", marginRight: "20px" } }
                ]}
                size={"small"}
                rows={formEdit.map((row) => {
                  return {
                    "": "",
                    pieza: row.pieza,
                    nrodev: row.nrodev,
                    dominio: row.dominio,
                    marca: row.marca,
                    modelo: row.modelo,
                    año: row.año,
                    nro_pieza: row.nro_pieza ? row.nro_pieza : "",
                    compañia: row.compañia,
                    action: iconDelete(row.id)
                  }
                })}
              />
           }
           
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 40px 10px 10px",
            }}>

            {data.act.tipo === "LOCAL"? 
              <Table 
                formEdit={formEdit} 
                isLoading={isLoading}
                edit={edit}
                setFormEdit={setFormEdit}
                data={data}
                onDataChange={onDataChange}
                setIsLoading={setIsLoading}
              /> :
              <TableRemote 
                formEdit={formEdit} 
                isLoading={isLoading}
                edit={edit}
                setFormEdit={setFormEdit}
                data={data}
                onDataChange={onDataChange}
                setIsLoading={setIsLoading}
              />
            }
          </div>
        </>
      )
    }
    
    export default PartsTable    