import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  rootGrid: {
    height: "600px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
  },
  paddingGrids: {
    margin: "28px",
    width: "900px",
    height: "450px",
  },
  styleButtons: {
    marginRight: "10px",
  },
}))

export default useStyles
