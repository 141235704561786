import * as yup from "yup"

export const validateManual = yup.object({
  manual: yup.string().required("Este campo es obligatorio"),
})
export const validateVehicle = yup.object({
  vehiculo: yup
    .string()
    .required("Este campo es obligatorio")
    .max(25, "Escribe menos de 25 caracteres"),
})
export const validateCodigo = yup.object({
  codigo: yup
    .string()
    .required("Este campo es obligatorio")
    .max(25, "Escribe menos de 25 caracteres"),
})

export const validateNewDespatch = [
  yup.object({
    empresa: yup.number().required("Este campo es obligatorio"),
    tipo: yup.number().required("Este campo es obligatorio"),
    cliente_id: yup.number().nullable().required("Este campo es obligatorio"),
  }),
  yup.object({
    empresa: yup.number().required("Este campo es obligatorio"),
    tipo: yup.number().required("Este campo es obligatorio"),
    origen_id: yup.number().nullable().required("Este campo es obligatorio"),
    destino_id: yup.number().nullable().required("Este campo es obligatorio"),
  }),
  yup.object({
    empresa: yup.number().required("Este campo es obligatorio"),
    tipo: yup.number().required("Este campo es obligatorio"),
    destino_id: yup.number().nullable().required("Este campo es obligatorio"),
  }),
]
