import React from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import CustomButton from "Components/UI/Atoms/CustomButton"
import { USUALLY } from "../InfoFields"

import useStyles from "./styles"
import { isValueInArray } from "Helpers"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

export const Home = (props) => {
  const {
    data,
    fields,
    setData,
    printQuote,
    restoreQuote,
    oneQuoteTable,
    openConfirm,
    openDelete,
    openCancel,
    openDuplicate,
    edit,
    disabledDelete,
    loading,
    openNoQuote,
    puesto,
  } = props
  const classes = useStyles()
  const {
    state: rescatar,
    setOpen: openRescatar,
    setClose: closeRescatar,
  } = SetState()

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    })
  }

  const disabledRescatar = () => {
    if (puesto === '"Cotizador/a"') return true
    if (
      oneQuoteTable.estado === "NO ADJUDICADO" ||
      oneQuoteTable.estado === "CANCELADO" ||
      oneQuoteTable.estado === "ADJ. OTRA BAJA" ||
      oneQuoteTable.estado === "NO COTIZA"
    )
      return false

    return true
  }

  const disabledEditButtons = () => {
    if (
      oneQuoteTable.estado === "NO ADJUDICADO" ||
      oneQuoteTable.estado === "CANCELADO" ||
      oneQuoteTable.estado === "NO COTIZA" ||
      oneQuoteTable.estado === "ADJ. OTRA BAJA"
    ) {
      if (data.estado === "NO ADJUDICADO" || data.estado === "CANCELADO")
        return true
      return true
    }
    if (!edit) return true
    return false
  }
  return (
    <>
      <div className={classes.paddingTabs}>
        <InputsWithoutValidate
          fields={USUALLY(data, handleChange, edit, fields)}
          spacing={2}
        />
        <div className={classes.buttons}>
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px" }}
            text="No cotiza"
            onClick={openNoQuote}
            disabled={
              !edit ||
              !isValueInArray(
                ["PENDIENTE", "A COTIZAR", "VENCIDO"],
                oneQuoteTable.estado
              )
            }
            color="#818181"
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Duplicar"
            onClick={openDuplicate}
            disabled={
              !edit ||
              oneQuoteTable.estado === "ADJUDICADO" ||
              puesto === '"Cotizador/a"' ||
              disabledEditButtons()
            }
            color="#818181"
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Rechazar"
            onClick={openCancel}
            disabled={disabledEditButtons() || puesto === '"Cotizador/a"'}
            color="#818181"
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Rescatar"
            onClick={() => openRescatar()}
            disabled={disabledRescatar()}
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Eliminar"
            onClick={(e) => openDelete(e, data.id)}
            disabled={disabledDelete || puesto === '"Cotizador/a"'}
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Confirmar"
            onClick={openConfirm}
            disabled={
              disabledEditButtons() ||
              oneQuoteTable.estado === "A COTIZAR" ||
              oneQuoteTable.estado === "ADJUDICADO" ||
              puesto === '"Cotizador/a"'
            }
          />
          <CustomButton
            buttonEnd
            style={{ marginTop: "10px", marginLeft: "1rem" }}
            text="Imprimir"
            disabled={loading || puesto === '"Cotizador/a"'}
            isLoading={loading}
            onClick={(e) => printQuote(e, data.id)}
          />
        </div>
      </div>

      {rescatar.suspense && (
        <DialogComponent
          open={rescatar.open}
          title="¿Realmente desea rescatar esta cotización?"
          handleclose={closeRescatar}
          widthLarge={"500px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickCancel={() => closeRescatar()}
              onClickSubmit={() => restoreQuote(data)}
            />
          }
        />
      )}
    </>
  )
}
