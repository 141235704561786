import React, { useRef, useState } from "react"
import { Form, Formik } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

const Export = ({ open, close }) => {
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)
  
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
 
  const onSubmit = async (values) => {
  setLoading(true)
    await interfaces.plantOrders
    .exportOrders(values)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Grilla de vehiculos descargada", {
          variant: "success",
        })
        
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
   close()
  }

  const start = new Date("2020-01-02")
  const today = new Date()

  const fields = () => [
   
    {
      class: "datepicker",
      label: "Fecha de incio",
      format: "dd-MM-yyyy",
      name: "inicio",
      value: start,
      sm: 6,
      xs: 12,
    },
    {
      class: "datepicker",
      label: "Fecha final",
      format: "dd-MM-yyyy",
      name: "final",
      value: today,
      sm: 6,
      xs: 12,
    },
  ]

  return (
    <DialogComponent
      open={open}
      handleclose={close}
      title="Exportar grilla"
      disableBackdropClick={true}
      handleclosedisabled={loading}
      disableChildHeight
      disableEscapeKeyDown
      widthLarge={"500px"}
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit} //?
          onClickCancel={close}
          disabledCancel={loading}
          disabledSubmit={loading}
          isLoading={loading}
        />
      }
      children={
        <Formik
          initialValues={{
            inicio: start,
            final: today,
          }}
          onSubmit={onSubmit}
          
           
        >
          {({ setFieldValue,  isSubmitting }) => (
            <Form>
              <InputsWithValidate
                fields={fields()}
                spacing={2}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}

              />
              <button
                style={{ display: "none" }}
                type="submit"
                ref={formSubmitRef}
              />
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export default Export
