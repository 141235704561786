import React, { useState } from "react"
import Search from "Components/UI/Atoms/Search"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"

const Provincies = (props) => {
  const { data, handleListItemClick, idProvincie } = props

  const [ valueProvincie, setValueProvincie ] = useState("")

  const onChangeBrand = (e) => {
    setValueProvincie(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueProvincie) {
          return e.provincia.includes(valueProvincie.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  return (
    <>
      <Search
        placeholder="Buscar"
        onChange={onChangeBrand}
        value={valueProvincie}
      />

      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idProvincie}
            onClickSelect={handleListItemClick}
            nameIncludes="provincia"
          />
        }
        height="500px"
        disabledOn={idProvincie ? false : true}
      />
    </>
  )
}
export default Provincies