import React, { useState } from "react"

import PrintIcon from "@material-ui/icons/Print"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import MuiDatables from "Components/UI/Atoms/Table"
import { interfaces } from "service/interfaces"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import ChecklistDialog from "../ChecklistDialog"
import SetState from "Components/Hooks/SetState"
import EditContextProvider from "Components/Hooks/ContextEdit"
import Loader from "Components/UI/Atoms/Loader"
import { printPDF } from "Helpers"

import { columnTheme } from "./styles"

const ChecklistTable = (props) => {
  const { render, setRender } = props

  const { state, setOpen, setClose } = SetState()
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({
    rows: [],
    header: [],
    filters: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
      filters: [],
    })
    try {
      const result = await interfaces.plantChecklist.list(options)
      setInfo({
        rows: result.data.data,
        header: result.header.header.count,
        filters: result.data.filters,
      })
      callback()
    } catch (error) {}
  }

  async function checklistData(id) {
    await interfaces.plantChecklist.moreInfo(id).then((res) => {
      setOpen({ data: res.data })
    })
  }

  const actions = (id) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        <Tooltip title="Imprimir">
          <IconButton
            onClick={(e) => onSubmit(e, id)}
            style={{ color: "black" }}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  async function onSubmit(e, id) {
    e.stopPropagation()
    setLoading(true)
    await interfaces.plantChecklist
      .print(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const RowsChecklist = () =>
    info.rows.map((file) => [
      "",
      file.codigo,
      file.dominio,
      file.nrodev,
      file.vehiculo,
      file.createdAt,
      file.articulo,
      file.kilometros,
      file.operario,
      actions(file.id),
    ])

  const columns = columnsForTable(
    [
      {
        name: "codigo",
        label: "Código",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "nivel",
        label: "Dominio",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "nrodev",
        label: "Nº de V",
        display: true,
        filter: false,
        sort: true,
        filterName: "label",
        customBodyRender: true,
      },
      {
        name: "vehiculo",
        label: "Vehículo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "createdAt",
        label: "Fecha",
        display: true,
        filter: false,
        sort: true,
        filterName: "label",
        customBodyRender: true,
      },
      {
        name: "articulo",
        label: "Artículo",
        display: true,
        filter: true,
        sort: false,
        options: info.filters.articulos,
        filterLabel: "Artículo",
        filterName: "label",
        fullWidth: true,
        customBodyRender: true,
        style: { width: "300px" },
      },
      {
        name: "kilometros",
        label: "Kilometros",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "operario",
        label: "Operario",
        display: true,
        filter: true,
        sort: false,
        filterLabel: "Operario",
        filterName: "nombre",
        options: info.filters.operarios,
        fullWidth: true,
        customBodyRender: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    info.filters
  )

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <ChecklistDialog
            open={state.open}
            onClose={setClose}
            render={render}
            setRender={setRender}
            info={state.data}
          />
        </EditContextProvider>
      )}

      <MuiDatables
        columns={columns}
        data={RowsChecklist()}
        title={`Total de checklist: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar dominio o Nº de V..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        onRowClick={(_, index) => checklistData(info.rows[index.dataIndex].id)}
      />

      {loading && <Loader />}
    </>
  )
}
export default ChecklistTable
