import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  buttonRed: {
    textTransform: "capitalize",
    color: "#fff",
    background: "#d32f2f",
    "&:hover": {
      background: "#f44336",
    },
  },
  butttonGreen: {
    textTransform: "capitalize",
    color: "primary",
  },
  fullWidth: {
    width: "100%",
  },
}))

export default useStyles
