import React, { useState, useRef, useContext, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { Formik, Form } from 'formik'

import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import useStyles from '../../../../../../../StyleModalNew'
import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'
import { FormContext } from 'Components/Hooks/ContextForm'
import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import InputsWithValidate from 'Components/UI/Molecules/InputsWithValidate'
import { formNew } from 'Components/Validation/Vehicles/SearchVehicles'
import { QuotationFields } from 'Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Quotation/Modals/Fields'
import { interfaces } from 'service/interfaces'

const New = (props) => {
  const { formEdit } = useContext(FormContext)
  const { handleCloseMain } = props
  const { enqueueSnackbar } = useSnackbar()
  const id = formEdit.vehicle.id

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = (values) => {
    interfaces.vehicleInterface.newPayment(id, values).then((res) => {
      enqueueSnackbar(res.data, { variant: 'success' })
    })
    handleCloseMain()
  }

  const [vehicles, setVehicles] = useState([])
  const [companies, setCompanies] = useState([])
  const [types, setTypes] = useState([])

  async function getVehiclesForPayment() {
    await interfaces.vehicleInterface.getAllVehicleForPayments().then((res) => {
      setVehicles(res.data)
    })
  }

  async function getData() {
    await interfaces.vehicleInterface.getCompanies().then((res) => {
      setCompanies(res.data)
    })
    await interfaces.conceptsInterface.getConcepts().then((res) => {
      setTypes(res.data)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          proveedor: '',
          fecha_factura: '',
          fecha_pago: '',
          suc_factura: '',
          nro_factura: '',
          monto_total: '',
          observaciones: '',
          tipo: '',
          vehiculos: [],
        }}
        validationSchema={formNew}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={QuotationFields(companies, types, vehicles, setFieldValue, values, getVehiclesForPayment)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: 'none' }}
              type='submit'
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>
      <Button
        className={classes.textTransformLetter}
        variant='contained'
        color='primary'
        type='submit'
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Nuevo
      </Button>
      <DialogComponent
        customHeight='auto'
        widthLarge='30vw'
        tipo='center'
        open={open}
        disableBackdropClick={true}
        handleclose={handleClose}
        title='Nuevo costo/gasto'
        children={children()}
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: '2px',
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              submitText={'Guardar'}
            />
          </div>
        }
      />
    </div>
  )
}

export default New
