import React, { useEffect } from "react"

import TemplateSearchWafers from "Components/UI/Templates/Vehicles/SearchWafers"

const SearchWafers = () => {
  useEffect(() => {
    document.title = "Listado de obleas"
  }, [])

  return <TemplateSearchWafers />
}

export default SearchWafers
