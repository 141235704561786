import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  colorWhite: {
    color: "white",
  },
  firstDiv: {
    display: "flex",
  },
  secondDiv: {
    display: "flex",
    justifyContent: "center",
  },
  input: {
    color: "white",
    "&::placeholder": {
      fontStyle: "italic",
      color: "#fff",
      textOverflow: "ellipsis !important",
    },
  },
}))

export default useStyles
