import React from "react"

import { Paper, Button, Divider, Grid } from "@material-ui/core"

import useStyles from "./styles"

const PaperList = (props) => {
  const {
    pagination = false,
    height,
    onClickNext,
    onClickPrev,
    children,
    disabledPrev,
    disableNext,
    styles,
  } = props
  const classes = useStyles(height)

  return (
    <Paper
      variant="outlined"
      style={{
        height: height,
        ...styles,
      }}
      className={classes.paper}
      elevation={4}
    >
      <div className={classes.firstDiv}>{children}</div>
      {pagination ? (
        <div className={classes.secondDiv}>
          <Grid container>
            <Grid sm={5} xs={5}>
              <Button
                disabled={disabledPrev}
                onClick={() => onClickPrev()}
                fullWidth
                className={classes.previous}
              >
                Anterior
              </Button>
            </Grid>
            <Grid
              sm={2}
              xs={2}
              container
              justifyContent="center"
              alignContent="center"
            >
              <Divider
                flexItem
                orientation="vertical"
                className={classes.divider}
              />
            </Grid>
            <Grid sm={5} xs={5}>
              <Button
                disabled={disableNext}
                fullWidth
                onClick={() => onClickNext()}
                className={classes.next}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Paper>
  )
}

export default PaperList
