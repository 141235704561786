
import React from 'react';
import { Grid, Table, TableBody, TableCell, TableRow, Checkbox } from '@material-ui/core';

const Step2 = (props) => {
  const { initValues, setInitValues } = props;

  const updatedProducts = initValues.products.map((item) => ({
    ...item,
    checked: item.created ? true : false,
    cantidad: item.cantidad ? item.cantidad : 1,
    limite: item.limite ? item.limite : 1,
  }));

  const initialState = {
    ...initValues,
    products: updatedProducts,
  };

  const setNew = (array) => {
    setInitValues({ ...initValues, products: array });
  };

  const handleChange = (e, position) => {
    const clonedData = [...initialState.products];
    clonedData[position].checked = e.target.checked;
    clonedData[position].created = e.target.checked;
    setNew(clonedData);
  };

  const handleQuantityChange = (e, position) => {
    const clonedData = [...initialState.products];
    clonedData[position].cantidad = parseInt(e.target.value);
    setNew(clonedData);
  };
 
  return (
    <Grid container>
      <Table style={{ tableLayout: "fixed", height:"40px" }} size="medium">
        <TableBody>
          {initialState.products.map((item, index) => (
            <TableRow key={item.id} onClick={(e) => e.stopPropagation()}>
              <TableCell style={{ width: "10%" }}>
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => handleChange(e, index, item.id)}
                />
              </TableCell>
              <TableCell align="left" style={{ width: "20%" }}>
                {item.producto}
              </TableCell>
              <TableCell style={{ width: "10%"}}>
                <select
                    value={item.cantidad !== "" ? item.cantidad : ""}
                    onChange={(e) => handleQuantityChange(e, index)}
                    style={{
                      width: "60px",
                      height: "40px",
                      padding: "10px",
                      marginLeft: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      cursor: "pointer"
                    }}
                  > 
                    
                    {Array.from({ length: item.limite }, (_, i) => (
                      <option key={i + 1} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default Step2;
