import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CircularProgress from "@material-ui/core/CircularProgress"

const SimpleUploadImg = (props) => {
  const {
    handleChange,
    change,
    loading,
    style,
    onClick,
    text,
    textConfirm = "Fotografía(s) seleccionadas",
    disabledBtn,
    color,
    styleText,
  } = props
  return (
    <div style={style}>
      <form>
        <input
          style={{ display: "none" }}
          accept="image/*,video/*"
          id="contained-button-img"
          name="file"
          type="file"
          multiple
          onChange={handleChange}
          disabled={loading ? loading : disabledBtn}
        />
        <label htmlFor="contained-button-img">
          {change.length > 0 ? (
            <Button
              color="primary"
              startIcon={<CheckCircleOutlineIcon />}
              variant="contained"
              fullWidth
              component="span"
              disabled={loading ? loading : disabledBtn}
            >
              {textConfirm}
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              component="span"
              onClick={onClick}
              color={color}
              style={styleText}
              disabled={loading ? loading : disabledBtn}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: "#fff",
                    width: "24px",
                    height: "24px",
                    marginLeft: "21px",
                    marginRight: "21px",
                  }}
                />
              ) : (
                text
              )}
            </Button>
          )}
        </label>
      </form>
    </div>
  )
}

export default SimpleUploadImg
