const UPDATE_PRICE = "/sales/prices/updatestock"
const CONTROL_DATA = "/sales/prices/control"
const CONTROL_LIST = "/sales/prices/list"
const BEFORE_CREATE_CONTROL = "/sales/prices/beforecreate"
const CREATE_CONTROL = "/sales/prices/control"
const EDIT_CONTROL = "/sales/prices/control/"
const DELETE_CONTROL = "/sales/prices/control"

export const priceStockEndpoint = {
  UPDATE_PRICE,
  CONTROL_DATA,
  CONTROL_LIST,
  BEFORE_CREATE_CONTROL,
  CREATE_CONTROL,
  EDIT_CONTROL,
  DELETE_CONTROL,
}
