const LIST = "/sales/stock/stocklist"
const RESERVE_STOCK = "/sales/stock/reserve"
const DELETE_RESERVE = "/sales/stock/reserve"
const BEFORE_EXPORT = "/sales/stock/before"
const EXPORT = "/sales/stock/export"
const UPDATE_COMMENT = "/sales/stock/comment/"
const FILTER_LIST = "/sales/stock/filters"
const VEHICLE_DATA = "/sales/stock/vehicledata/"
const ENGINE_DATA = "/sales/stock/enginedata/"

export const salesStockEndpoints = {
  LIST,
  RESERVE_STOCK,
  DELETE_RESERVE,
  BEFORE_EXPORT,
  EXPORT,
  UPDATE_COMMENT,
  FILTER_LIST,
  VEHICLE_DATA,
  ENGINE_DATA,
}
