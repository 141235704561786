import React, { useState, useContext } from "react"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Loader from "Components/UI/Atoms/Loader"

import { interfaces } from "service/interfaces"
import { EditContext } from "Components/Hooks/ContextEdit"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import { Home } from "Components/UI/Organisms/Vehicles/Quotations/Edit/Fields/Home"
import Files from "Components/UI/Organisms/Vehicles/Quotations/Edit/Fields/Files"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { DATES, VEHICLE_DATA } from "./Fields/InfoFields"
import { CostsAndRetrat } from "./Fields/Costs"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"
import { filterObjectKeys, formatDate } from "Helpers"

const Edit = (props) => {
  const {
    open,
    handleClose,
    dataQuote,
    quoteInfoById,
    setRender,
    render,
    confirmQuote,
    declineQuote,
    duplicateQuote,
    info,
    printQuote,
    restoreQuote,
    oneQuoteTable,
    setOpenDeclineHome,
    openDeclineHome,
    openConfirm,
    openDelete,
    openCancel,
    openDuplicate,
    permissions,
    loading: loadingFromDialog,
    openNoQuote,
    puesto,
  } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState(false)
  const id = dataQuote !== undefined ? dataQuote.quote.id : ""
  const [editValue, setEditValue] = useState(dataQuote.quote)
  const [edit] = useContext(EditContext)
  const infoOneId = info.find((v) => v.id === id)

  const handleChange = (name, value) => {
    setEditValue({
      ...editValue,
      [name]: value,
    })
  }

  const handleChangeMultiple = (values) => {
    setEditValue({
      ...editValue,
      ...values,
    })
  }

  const updateQuoteWithoutClose = async () => {
    setLoading(true)

    const ommitedKeys = [
      "cia_seguros",
      "usuario",
      "fotos",
      "archivos",
      "principales",
      "id",
    ]

    const updateValues = filterObjectKeys(editValue, ommitedKeys)

    await interfaces.quotationsInterface
      .update(id, updateValues)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
    setRender(!render)
    handleClose()
  }

  const disabledThird = () => {
    const estados = [
      "ADJUDICADO",
      "CANCELADO",
      "NO ADJUDICADO",
      "ADJ. OTRA BAJA",
      "NO COTIZA",
    ]

    return dataQuote.editAlways
      ? !dataQuote.editAlways
      : estados.includes(quoteInfoById.estado) || !permissions.allowEdit
  }

  const renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <Home
            data={editValue}
            setData={setEditValue}
            fields={dataQuote.fields}
            confirmQuote={confirmQuote}
            declineQuote={declineQuote}
            duplicateQuote={duplicateQuote}
            printQuote={printQuote}
            infoOneId={infoOneId}
            restoreQuote={restoreQuote}
            dataQuote={dataQuote}
            oneQuoteTable={oneQuoteTable}
            setOpenDeclineHome={setOpenDeclineHome}
            openDeclineHome={openDeclineHome}
            openConfirm={openConfirm}
            openDelete={openDelete}
            openCancel={openCancel}
            openDuplicate={openDuplicate}
            disabledDelete={permissions.allowDelete === false}
            loading={loadingFromDialog}
            openNoQuote={openNoQuote}
            edit={edit}
            puesto={puesto}
          />
        )
      case 1:
        return (
          <div style={{ padding: "4rem" }}>
            <InputsWithoutValidate
              fields={VEHICLE_DATA(
                editValue,
                handleChange,
                dataQuote.fields,
                edit,
                handleChangeMultiple
              )}
              spacing={2}
            />
          </div>
        )
      case 2:
        return <Files data={editValue} id={id} edit={edit} />
      case 3:
        return (
          <CostsAndRetrat
            editValue={editValue}
            handleChange={handleChange}
            edit={edit}
            fieldsUser={dataQuote.fields.usuarios}
            companies={dataQuote.fields.compañia}
            id={dataQuote.quote.vehiculo_id}
            handleChangeMultiple={handleChangeMultiple}
          />
        )
      case 4:
        return (
          <div style={{ padding: "4rem" }}>
            <InputsWithoutValidate
              fields={DATES(editValue, handleChange, edit)}
              spacing={2}
            />
          </div>
        )
      case 5:
        return <ComplexDialogHistory history={dataQuote.historial} />

      default:
        break
    }
  }

  const marca = dataQuote.fields.marca.find(
    (v) => v.id === editValue.marca_id
  ).marca

  const modelo = dataQuote.fields.modelo.find(
    (v) => v.id === editValue.modelo_id
  )?.modelo

  const dominio = dataQuote?.quote?.dominio ?? ""

  const año = dataQuote?.quote?.año ?? ""

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleClose}
        tabs={[
          { title: "Inicio" },
          { title: "Datos del vehículo" },
          { title: "Archivos" },
          { title: "Costos y retiro" },
          { title: "Fechas" },

          ...(dataQuote.historial.length
            ? [
                {
                  title: "Historial",
                },
              ]
            : []),
        ]}
        title={
          <>
            Cotización - {dominio} {marca} {modelo} {año}
          </>
        }
        subtitle={
          <div>
            <b>ID {id}</b> - Creado el {editValue.fecha} por {editValue.usuario}
            <b style={{ color: "red" }}>
              {editValue.vencimiento
                ? "  La cotización vence el día " +
                  formatDate(editValue.vencimiento)
                : null}
            </b>
          </div>
        }
        children={renderForms}
        actions={
          <ComplexDialogActions
            handleSubmit={updateQuoteWithoutClose}
            onClose={handleClose}
            editMode={true}
            canEdit={disabledThird()}
            data={editValue}
          />
        }
      />

      {loading ? <Loader /> : null}
    </>
  )
}

export default Edit
