import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import New from "Components/UI/Organisms/Settings/Users/Permissions/Content/Navbar/New"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"
const Navbars = (props) => {
  const { data, handleListItemClick, idNavbar, changeRender, idTemplate } =
    props

  const [valueNavbar, setValueNavbar] = useState("")

  const {
    state: newNavbar,
    setOpen: setNewNavbar,
    setClose: closeNewNavbar,
  } = SetState()

  const onChangeNavbar = (e) => {
    setValueNavbar(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueNavbar) {
          return e.descripcion.toUpperCase().includes(valueNavbar.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }
  const disabled = () => idTemplate === undefined

  return (
    <>
      <InputSearch
        button={false}
        onClick={setNewNavbar}
        onChange={onChangeNavbar}
        value={valueNavbar}
      />
      {newNavbar.suspense && (
        <New
          open={newNavbar.open}
          handleClose={closeNewNavbar}
          changeRender={changeRender}
        />
      )}

      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idNavbar}
            onClickSelect={handleListItemClick}
            nameIncludes="descripcion"
            disabled={disabled()}
          />
        }
        height="500px"
      />
    </>
  )
}
export default Navbars
