import React, { useEffect } from "react"
import TemplateCompaction from "Components/UI/Templates/Vehicles/ActCompaction"

const ActaCompaction = () => {
  useEffect(() => {
    document.title = "Compactación"
  }, [])
  return <TemplateCompaction />
}

export default ActaCompaction