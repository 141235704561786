import React, {  useRef, useState } from "react"
import { Formik, Form } from "formik"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

import { validationCreateCompaction } from "Components/Validation/Vehicles/Compaction"
import { interfaces } from "service/interfaces"
import { UBICACION_COMPACTACION } from "Constants"

const Create = (props) => {
  const { open, handleClose, render, setRender, data } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  const create = async (values) => {
    setLoading(true)
    await interfaces.compactionInterface
      .create(values)
      .then((res) => {
        setRender(!render)
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Ya existe un acta pendiente para esta ubicación", {
          variant: "error",
        })
      })
    setLoading(false)
    handleClose()
    
  }
  const TIPO = [
    { 
      tipo: "LOCAL",
    },
    {
      tipo: "REMOTA",
    },
  ]

  const fields = (values) => [
    {
      id: 0,
      label: "Nombre",
      name: "nombre",
      sm: 12,
      xs: 12,
      class: "textfield",
    },
    {
      id: 1,
      label: "Tipo",
      name: "tipo",
      options: values.ubicacion_id? [{tipo: "LOCAL"}] : TIPO,
      optionLabel: `options["tipo"]`,
      optionValue: "tipo",
      sm: 12,
      xs: 12,
      class: "autocomplete"
    },
    {
      id: 2,
      label: "Ubicación",
      name: "ubicacion_id",
      options: UBICACION_COMPACTACION,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      class: "autocomplete",
      disabled: values.tipo === "REMOTA"
    },
    {
      id: 3,
      label: "Proveedor compactación",
      name: "proveedor",
      sm: 12,
      xs: 12,
      class: "autocomplete",
      options: data.proveedores ? data.proveedores.slice(1) : [],
      optionLabel: `options["proveedor"]`,
      optionValue: "proveedor"
    },
    {
      id: 4,
      label: "Proveedor disposición final",
      name: "proveedor2",
      sm: 12,
      xs: 12,
      class: "autocomplete",
      options: data.proveedores ? data.proveedores.slice(1) : [],
      optionLabel: `options["proveedor"]`,
      optionValue: "proveedor"
    },
  ]

  const children = (
    <Formik
      onSubmit={create}
      initialValues={{
        nombre: "",
        tipo: null,
        ubicacion_id: null,
        proveedor: null,
        proveedor2: null,
      }}
      validationSchema={validationCreateCompaction}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={fields(values)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: "none" }}
            type="submit"
            ref={formSubmitRef}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        title="Nueva acta"
        maxHeight
        widthLarge="500px"
        children={children}
        actions={
          <CancelConfirmButtons
            submitText={"Confirmar"}
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
          />
        }
      />
    </>
  )
}

export default Create