import React from "react"

import {
  Typography,
  List,
  ListItem,
  Grid,
  ListItemText,
} from "@material-ui/core"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"

const Tab_3 = (props) => {
  const { data, handleChange } = props

  const INPUT = [
    {
      id: 1,
      class: "autocomplete",
      label: "Calidad",
      name: "calidad",
      sm: 12,
      xs: 12,
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
      value: data.calidad,
      onChange: (e) => {
        handleChange({
          calidad: e.id,
          calidadNombre: e.optionLabel,
        })
      },
      options: [
        {
          id: 1,
          optionLabel: "Excelente",
        },
        {
          id: 2,
          optionLabel: "Bueno",
        },
        {
          id: 3,
          optionLabel: "Regular",
        },
        {
          id: 4,
          optionLabel: "Malo",
        },
        {
          id: 5,
          optionLabel: "A preparar",
        },
        {
          id: 6,
          optionLabel: "Scrap",
        },
      ],
    },
  ]

  function STEP_3() {
    const quality = data.products.oem_products.find((index) => {
      return index.id === data.producto_id
    })
    return [
      {
        id: 1,
        title: "EXCELENTE",
        subTitle: quality.calidad ? quality.calidad.excelente_detalle : "",
      },
      {
        id: 2,
        title: "BUENO",
        subTitle: quality.calidad ? quality.calidad.bueno_detalle : "",
      },
      {
        id: 3,
        title: "REGULAR",
        subTitle: quality.calidad ? quality.calidad.regular_detalle : "",
      },
      {
        id: 4,
        title: "MALO",
        subTitle: quality.calidad ? quality.calidad.malo_detalle : "",
      },
      {
        id: 5,
        title: "A PREPARAR",
        subTitle: quality.calidad ? quality.calidad.a_preparar : "",
      },
      {
        id: 6,
        title: "SCRAP",
        subTitle: quality.calidad ? quality.calidad.scrap : "",
      },
    ]
  }

  return (
    <>
      <InputsWithoutValidate fields={INPUT} spacing={2} />
      <Grid container>
        <List dense component="div" role="list">
          {STEP_3().map((value) => (
            <ListItem key={value.id} role="listitem">
              <ListItemText
                primary={value.title}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {value.subTitle}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
          <ListItem />
        </List>
      </Grid>
    </>
  )
}
export default Tab_3
