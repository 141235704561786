import moment from "moment"
import { Form, Formik } from "formik"

import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import { InputAdornment } from "@material-ui/core"

import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

export const formAddRow = (values, setFieldValue, dataAutocomplete) => {
  const onChangeProduct = (e) => {
    setFieldValue("producto_id", e.id)
    setFieldValue("cantidad", e.cantidad_articulos)
  }
  return [
    {
      class: "autocomplete",
      name: "producto_id",
      label: "Pieza",
      sm: 12,
      xs: 12,
      optionLabel: `options["producto"]`,
      options: dataAutocomplete,
      optionValue: "id",
      sortBy: "producto",
      value: values.producto_id,
      onChange: (e) => onChangeProduct(e),
    },
    {
      class: "textfield",
      label: "Cantidad",
      name: "cantidad",
      value: values.cantidad,
      md: 12,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Tipo de cambio",
      name: "tc_cambio",
      type: "number",
      value: values.tc_cambio || "",
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon style={{ color: "grey" }} />
          </InputAdornment>
        ),
      },
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      class: "datepicker",
      label: "Próxima actualización",
      name: "prox_act",
      format: "dd/MM/yyyy",
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "referencia",
      label: "Referencia",
      md: 12,
      sm: 12,
      xs: 12,
    },
  ]
}

export const editRow = (values, data, idEdit, dataAutocomplete) => {
  return [
    {
      class: "textfield",
      name: "producto_id",
      label: "Pieza",
      value: typeof idEdit === "number" ? data[idEdit].producto : null,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "producto_id",
      label: "Pieza",
      sm: 12,
      xs: 12,
      value:
        typeof idEdit === "number" ? data[idEdit].cantidad_productos : null,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Última actualización",
      name: "ult_act",
      format: "dd/MM/yyyy",
      value: typeof idEdit === "number" ? data[idEdit].ult_act : null,
      md: 12,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      label: "Tipo de cambio",
      name: "tc_cambio",
      value: values.tc_cambio,
      type: "number",
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon style={{ color: "grey" }} />
          </InputAdornment>
        ),
      },
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      class: "datepicker",
      label: "Próxima actualización",
      name: "prox_act",
      format: "dd/MM/yyyy",
      value: values.prox_act,
      md: 12,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "referencia",
      label: "Referencia",
      value: values.referencia,
      multiline: true,
      rows: 5,
      md: 12,
      sm: 12,
      xs: 12,
    },
  ]
}

export const col = [
  {
    title: "Pieza.",
    style: { cursor: "pointer" },
  },
  {
    title: "Cant.",
  },
  {
    title: "Últ. Act.",
  },
  {
    title: "TC Últ. Act.",
  },
  {
    title: "Próx. Act.",
  },
  {
    title: "Referencia",
  },
  {
    title: null,
  },
]

export const formNew = (
  handleCreateControl,
  newRowTableUpdate,
  dataAutocomplete,
  formSubmitRef
) => {
  return (
    <Formik
      onSubmit={handleCreateControl}
      initialValues={{
        producto_id: null,
        cantidad: null,
        ult_act: moment(),
        tc_cambio: null,
        prox_act: null,
        referencia: "",
      }}
      validationSchema={newRowTableUpdate}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={formAddRow(values, setFieldValue, dataAutocomplete)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: "none" }}
            type="submit"
            ref={formSubmitRef}
          />
        </Form>
      )}
    </Formik>
  )
}

export const formEdit = (
  handleEditControl,
  data,
  editRowTableUpdate,
  idEdit,
  dataAutocomplete,
  formSubmitRefEdit
) => {
  return (
    <Formik
      onSubmit={handleEditControl}
      initialValues={{
        tc_cambio: typeof idEdit === "number" ? data[idEdit].tc_cambio : null,
        prox_act:
          typeof idEdit === "number"
            ? moment(data[idEdit].prox_act, "DD-MM-YYYY")
            : null,
        referencia: typeof idEdit === "number" ? data[idEdit].referencia : null,
      }}
      validationSchema={editRowTableUpdate}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={editRow(values, data, idEdit, dataAutocomplete)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: "none" }}
            type="submit"
            ref={formSubmitRefEdit}
          />
        </Form>
      )}
    </Formik>
  )
}
