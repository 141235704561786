import React, { useRef, useEffect, useState } from "react"

import { Formik, Form } from "formik"

import { interfaces } from "service/interfaces"
import { TIPO_BAJA } from "Constants"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { NewNavVehicles } from "Components/Validation/Vehicles/SearchVehicles"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const NewNav = (props) => {
  const { open, handleClose, render, setRender } = props
  const [data, setData] = useState({ compañia: [], tipo_vehiculo: [] })

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [isLoading, setIsLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async (values) => {
    setIsLoading(true)
    const value = {
      dominio: values.dominio.toUpperCase(),
      cia_seguros_id: values.cia_seguros_id,
      nro_siniestro: values.nro_siniestro,
      tipo_baja_id: values.tipo_baja_id,
      tipo_id: values.tipo_id,
    }
    await interfaces.vehicleInterface
      .postNewVehicle(value)
      .then((res) => {
        createDismissableSnackbar("Vehículo creado exitosamente!", {
          variant: "success",
        })
        handleClose()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  useEffect(() => {
    const getData = async () => {
      await interfaces.vehicleInterface.getNewVehicle().then((res) =>
        setData({
          compañia: res.data.compañias,
          tipo_vehiculo: res.data.tipo_vehiculo,
        })
      )
    }
    getData()
  }, [])

  const fields = (values) => [
    {
      class: "textfield",
      name: "dominio",
      label: "Dominio",
      sm: 12,
      xs: 12,
      value: values.dominio.replace(/ /g, "").toUpperCase(),
    },
    {
      class: "autocomplete",
      name: "cia_seguros_id",
      label: "Compañia",
      sm: 12,
      xs: 12,
      options: data.compañia,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
    },
    {
      class: "textfield",
      name: "nro_siniestro",
      label: "Nro. de Siniestro",
      sm: 12,
      xs: 12,
      value: values.nro_siniestro.replace(/ /g, ""),
    },
    {
      class: "autocomplete",
      name: "tipo_baja_id",
      label: "Tipo de baja",
      sm: 12,
      xs: 12,
      options: TIPO_BAJA,
      optionLabel: `options["baja"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      name: "tipo_id",
      label: "Tipo de vehículo",
      sm: 12,
      xs: 12,
      options: data.tipo_vehiculo,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
    },
  ]

  const children = (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        dominio: "",
        cia_seguros_id: "",
        nro_siniestro: "",
        tipo_baja_id: "",
        tipo_id: "",
      }}
      validationSchema={NewNavVehicles}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={fields(values)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            values={values}
          />
          <button
            style={{ display: "none" }}
            type="submit"
            ref={formSubmitRef}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      handleclosedisabled={isLoading}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      title="Nuevo vehículo"
      maxHeight
      children={children}
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            isLoading={isLoading}
            disabledSubmit={isLoading}
            disabledCancel={isLoading}
          />
        </div>
      }
    />
  )
}

export default NewNav
