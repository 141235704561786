import React from "react"

import { TabPanel } from "Helpers"
import Main from "../Tabs/Main"
import Data from "../Tabs/Data"
import Dates from "../Tabs/Dates"
import Extra from "../Tabs/Extra"
import styles from "./styles"

const SideTabs = (props) => {
  const { setRenderTable, renderTable, renderModal, setRenderModal } = props

  const classes = styles()
  return (
    <div className={classes.style}>
      <TabPanel value={props.value} index={0}>
        <Main
          setRenderTable={setRenderTable}
          renderTable={renderTable}
          renderModal={renderModal}
          setRenderModal={setRenderModal}
        />
      </TabPanel>
      <TabPanel value={props.value} index={1}>
        <Data />
      </TabPanel>
      <TabPanel value={props.value} index={2}>
        <Dates />
      </TabPanel>
      <TabPanel value={props.value} index={3}>
        <Extra />
      </TabPanel>
    </div>
  )
}

export default SideTabs
