import * as yup from 'yup'

export const NewNavVehicles = yup.object({
  dominio: yup
    .string()
    .min(6, 'Introduzca al menos 6 caracteres')
    .max(8, 'No ingrese mas de 8 caracteres')
    .required('Este campo es obligatorio'),
  cia_seguros_id: yup.string().required('Este campo es obligatorio').nullable(),
  nro_siniestro: yup
    .string()
    .required('Este campo es obligatorio')
    .min(2, 'Al menos 2 caracteres'),
  tipo_baja_id: yup.string().required('Este campo es obligatorio').nullable(),
  tipo_id: yup.string().required('Este campo es obligatorio').nullable(),
})

export const sinisterQuote = yup.object({
  dominio: yup
    .string()
    .max(7, 'No ingrese mas de 7 caracteres')
    .required('Este campo es obligatorio'),
  nro_siniestro: yup
    .string()
    .max(49, 'No ingrese mas de 50 caracteres')
    .required('Este campo es requerido'),
  cia_seguros_id: yup.number().required('Este campo es requerido').nullable(),
  tipo_baja_id: yup.number().required('Este campo es requerido').nullable(),
  marca_id: yup.string().required('Este campo es requerido').nullable(),
  modelo_id: yup.number().required('Este campo es requerido').nullable(),
  provincia_id: yup.string().required('Este campo es requerido').nullable(),
  localidad_id: yup.number().required('Este campo es requerido').nullable(),
  año: yup.date().required('Este campo es requerido').nullable(),
  vencimiento: yup.date().when('tipo_cotizacion', {
    is: (tipoCotizacion) => tipoCotizacion === 'Compulsa',
    then: yup.date().required('Este campo es requerido').nullable(),
    otherwise: yup.date().nullable(),
  }),
  tipo_cotizacion: yup.string().required('Este campo es requerido').nullable(),
})

export const cancelQuote = yup.object({
  motivo_cancelacion: yup
    .string()
    .required('Este campo es requerido')
    .nullable(),
})

export const validationDuplicateQuote = yup.object({
  tipo_baja_id: yup.number().required('Este campo es requerido').nullable(),
})

export const putQuote = yup.object({
  cotizacion_restos: yup.number().required('Este campo es requerido'),
})

export const formNewAdditionalInformation = yup.object({
  destinatario: yup
    .string()
    .max(99, 'No ingrese mas de 100 caracteres')
    .required('Este campo es obligatorio'),
  nombre_asegurado: yup
    .string()
    .max(99, 'No ingrese mas de 100 caracteres')
    .required('Este campo es requerido'),
  provincia_id: yup.string().required('Este campo es requerido'),
  localidad_id: yup.string().required('Este campo es requerido'),
  direccion: yup
    .string()
    .max(99, 'No ingrese mas de 100 caracteres')
    .required('Este campo es requerido'),
  medio: yup
    .string()
    .max(99, 'No ingrese mas de 100 caracteres')
    .required('Este campo es obligatorio'),
})

export const formNew = yup.object({
  proveedor: yup.string().required('Este campo es requerido'),
  monto_total: yup.number().required('Este campo es requerido'),
  observaciones: yup.string().max(100, 'No ingrese mas de 50 caracteres'),
  tipo: yup.string().required('Este campo es requerido'),
})
