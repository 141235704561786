import React, { useEffect, useState } from "react"

import { useSnackbar } from "notistack"

import Table from "../Table"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { ENGINE_ALIMENTATION } from "Constants"

const FormEngine = (props) => {
  const { setCanClose } = props

  const { data, id, handleReset, handleRender, index } = props

  const [change, setChange] = useState(data[index])
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState({
    open: false,
    currentValues: null,
  })

  ;(function test() {
    if (id !== null) {
      if (JSON.stringify(data[index]) === JSON.stringify(change)) {
        setCanClose(true)
      } else {
        setCanClose(false)
      }
    }
  })()

  function handleOpen() {
    setOpen({
      open: true,
      currentValues: id,
    })
  }

  function handleClose() {
    setOpen({
      open: false,
      currentValues: null,
    })
  }

  function handleSubmit() {
    interfaces.interfaceEngine
      .updateEngine(id, change)
      .then((res) => {
        enqueueSnackbar(res.data, {
          variant: "success",
        })
        handleRender()
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
  }

  useEffect(() => {
    setChange(data[index])
  }, [index, data])

  function handlechangeForm(e) {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    })
  }

  const onChangeValvulasComando = (item) => {
    setChange({ ...change, valvulas_comando: item.id })
  }

  const onChangeCombustible = (item) => {
    setChange({ ...change, combustible: item.id })
  }

  const onChangeTurbo = (item) => {
    setChange({ ...change, turbo: item.id })
  }

  const onChangeAlimentacion = (item) => {
    setChange({ ...change, alimentacion: item.id })
  }

  const onChangeAcelerador = (item) => {
    setChange({ ...change, acelerador: item.id })
  }

  const onChangeDistribucion = (item) => {
    setChange({ ...change, distribucion: item.id })
  }

  const onChangeMotorPosicion = (item) => {
    setChange({ ...change, motor_posicion: item.id })
  }

  const OBJETO = () => [
    {
      class: "textfield",
      label: "Código de motor",
      value: change.codigo,
      name: "codigo",
      onChange: handlechangeForm,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      label: "Nombre del motor",
      name: "nombre",
      value: change.nombre,
      onChange: handlechangeForm,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      label: "CV",
      name: "cv",
      value: change.cv,
      onChange: handlechangeForm,
      sm: 3,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      label: "KW",
      name: "kw",
      value: (change.cv / 1.341).toFixed(2),
      onChange: handlechangeForm,
      sm: 3,
      xs: 12,
    },
    {
      id: 5,
      class: "textfield",
      type: "number",
      label: "Válvulas",
      name: "valvulas",
      value: change.valvulas,
      onChange: handlechangeForm,
      sm: 3,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Comando V.",
      name: "valvulas_comando",
      value: parseInt(change.valvulas_comando),
      onChange: onChangeValvulasComando,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "DOHC",
        },
        {
          id: 2,
          optionLabel: "SOHC",
        },
        {
          id: 3,
          optionLabel: "OHV",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "textfield",
      type: "number",
      label: "Cilindrada",
      name: "cilindrada",
      value: change.cilindrada,
      onChange: handlechangeForm,
      sm: 3,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      label: "Cilindros",
      name: "cilindros",
      value: change.cilindros,
      onChange: handlechangeForm,
      sm: 3,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Combustible",
      name: "combustible",
      value: parseInt(change.combustible),
      onChange: onChangeCombustible,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "NAFTA",
        },
        {
          id: 2,
          optionLabel: "DÍESEL",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      name: "turbo",
      label: "Turbo",
      value: parseInt(change.turbo),
      onChange: onChangeTurbo,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 0,
          optionLabel: "NO",
        },
        {
          id: 1,
          optionLabel: "SI",
        },
        {
          id: 2,
          optionLabel: "BI-TURBO",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      label: "Alimentación",
      name: "alimentacion",
      value: change.alimentacion,
      onChange: onChangeAlimentacion,
      sm: 3,
      xs: 12,
      options: ENGINE_ALIMENTATION,
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      label: "Acelerador",
      name: "acelerador",
      value: parseInt(change.acelerador),
      onChange: onChangeAcelerador,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "CABLE",
        },
        {
          id: 2,
          optionLabel: "ELECTRÓNICO",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      label: "Distribución",
      name: "distribucion",
      value: parseInt(change.distribucion),
      onChange: onChangeDistribucion,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "CADENA",
        },
        {
          id: 2,
          optionLabel: "CORREA",
        },
        {
          id: 3,
          optionLabel: "ENGRANAJES",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "autocomplete",
      label: "Posición motor",
      name: "motor_posicion",
      value: parseInt(change.motor_posicion),
      onChange: onChangeMotorPosicion,
      sm: 3,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "LINEAL",
        },
        {
          id: 2,
          optionLabel: "TRANSVERSAL",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      class: "textfield",
      type: "number",
      label: "Motor completo",
      name: "precio1",
      value: change.precio1,
      sm: 3,
      xs: 12,
      onChange: (e) =>
        setChange({
          ...change,
          precio1: e.target.value,
          precio2: Math.round((e.target.value * 0.8) / 1000) * 1000,
          precio3: Math.round((e.target.value * 0.8 * 0.75) / 1000) * 1000,
          precio4:
            Math.round((e.target.value * 0.8 * 0.75 * 0.7) / 1000) * 1000,
        }),
    },
    {
      class: "textfield",
      type: "number",
      label: "Motor parcial",
      name: "precio2",
      value: change.precio2,
      sm: 3,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      label: "Block semiarmado",
      name: "precio3",
      value: change.precio3,
      sm: 3,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      label: "Block motor",
      name: "precio4",
      value: change.precio4,
      sm: 3,
      xs: 12,
    },
    {
      id: 19,
      class: "textfield",
      select: false,
      label: "Sugerencia",
      name: "sugerencia1",
      value: change.sugerencia1,
      onChange: handlechangeForm,
      sm: 6,
      xs: 12,
    },
    {
      id: 20,
      class: "textfield",
      select: false,
      label: "Observaciones",
      name: "sugerencia2",
      value: change.sugerencia2,
      onChange: handlechangeForm,
      sm: 6,
      xs: 12,
    },
  ]

  return (
    <div style={{ padding: "25px" }}>
      <InputsWithoutValidate fields={OBJETO()} spacing={3} />
      <div style={{ marginTop: "12px " }}>
        <CancelConfirmButtons
          leftButton
          leftButtonNoMargin
          leftButtonText="Vehículos"
          leftButtonOnClick={handleOpen}
          onClickCancel={() => {
            handleReset()
            enqueueSnackbar("Los cambios fueron descartados", {
              variant: "warning",
            })
          }}
          onClickSubmit={handleSubmit}
          submitText="Guardar"
          disabledCancel={
            JSON.stringify(index) === JSON.stringify(change) ? true : false
          }
          disabledSubmit={
            JSON.stringify(index) === JSON.stringify(change) ? true : false
          }
        />
      </div>
      {open.open && (
        <Table
          open={open.open}
          id={open.currentValues}
          handleClose={handleClose}
        />
      )}
    </div>
  )
}
export default FormEngine
