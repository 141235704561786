import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  paper: {
    overflow: "auto",
  },
  descripcion: {
    marginLeft: "15px",
  },
}))
