import { makeStyles, withStyles, TableRow, TableCell } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
  },
  stylesTableCell: {
    display: "flex",
    alignItems: "flex-end",
  },
  descripcion: {
    marginLeft: "15px",
  },
  tableHeight: {
    maxHeight: "500px",
  },
  text: {
    marginLeft: "20px",
  },
}))

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    width: "1440px",
  },
}))(TableRow)

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)
