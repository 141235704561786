import React, { useEffect } from "react"

import UsedTemplate from "Components/UI/Templates/Plant/Used"

const Used = () => {
  useEffect(() => {
    document.title = "Nuevos - usados"
  }, [])

  return <UsedTemplate />
}

export default Used
