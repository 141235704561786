import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    overflow: "auto",
    height: ({ height }) => height - 220,
    position: "relative",
  },
  pieza: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "150px",
    cursor: "pointer",
  },
  referencia: {
    maxWidth: "120px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  tableFooter: {
    position: "absolute",
    bottom: 0,
  },
  tableCellFooter: {
    display: "flex",
    justifyContent: "end",
    alignItems: "start",
    width: "100%",
  },
  customWidth: {
    maxWidth: 500,
    fontSize: 15,
  },
}))
