import React, { useEffect, useState } from "react"

import { Grid, Typography } from "@material-ui/core"

import { interfaces } from "service/interfaces"
import Lots from "./Lots"
import Rows from "./Row"

const LotsRow = () => {
  const [data, setData] = useState([])
  const [lotId, setLotId] = useState(null)
  const [rowId, setRowId] = useState(null)
  const [render, setRender] = useState(true)

  const changeRender = () => {
    setRender(!render)
  }

  const handleClickItemLot = (item) => {
    setLotId(item.id)
    setRowId(null)
  }

  const handleClickItemRow = (item) => {
    setRowId(item.id)
  }

  function handleRemoveClick() {
    setRowId(null)
  }

  useEffect(() => {
    interfaces.configInterface.listLotsRows().then((res) => setData(res.data))
  }, [render])

  return (
    <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
      <Grid item md={6} sm={12} xs={12}>
        <Typography
          style={{ textAlign: "center", marginBottom: "20px" }}
          variant="h6"
        >
          Lotes
        </Typography>
        <Lots
          data={data.lots}
          lotId={lotId}
          changeRender={changeRender}
          handleListItemClick={handleClickItemLot}
          setLotId={setLotId}
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Typography
          style={{ textAlign: "center", marginBottom: "20px" }}
          variant="h6"
        >
          Filas
        </Typography>
        <Rows
          data={data.rows}
          handleRemoveClick={handleRemoveClick}
          lotId={lotId}
          rowId={rowId}
          changeRender={changeRender}
          handleListItemClick={handleClickItemRow}
        />
      </Grid>
    </Grid>
  )
}

export default LotsRow
