import { React, useRef, useState } from "react"
import Form from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Create = (props) => {
  const { handleClose, open, changeRender, indexModel, setDataLocalities, handleListItemClickProvincie } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = (values) => {
    const value = {
      localidad: values.localidad.toUpperCase(),
      provincia_id: indexModel,
      cp: values.cp
    }
    setLoading(true)
    interfaces.configInterface
      .newPLA(value)
      .then((res) => {
        setDataLocalities((prevData) => [...prevData, value])
        createDismissableSnackbar("Localidad creada satisfactoriamente", {
          variant: "success",
        })
        handleListItemClickProvincie({ id: indexModel})
        changeRender()
      })
      .catch((error) => {
        createDismissableSnackbar("Error al crear la localidad", {
          variant: "error",
        })
      })
      .finally(() => {
        setLoading(false)
        handleClose()
      })
  }
  
  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        widthLarge="500px"
        title="Nueva localidad"
        children={
          <Form ref={formSubmitRef} onSubmit={onSubmit} />
        }
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default Create