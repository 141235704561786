import React, { useEffect, useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import SetState from "Components/Hooks/SetState"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import New from "./New"
import { interfaces } from "service/interfaces"
import Edit from "./Edit"

const ContentUserInsurance = () => {
  const [loading] = useState(false)
  const [id, setId] = useState()
  const [data, setData] = useState({ list: [], insurances: [] })
  const [render, setRender] = useState(true)

  const { state: edit, setOpen: openEdit, setClose: closeEdit } = SetState()
  const {
    state: newUserInsurance,
    setOpen: openNew,
    setClose: closeNew,
  } = SetState()

  const [valueSearch, setValueSearch] = useState("")

  useEffect(() => {
    interfaces.configInterface
      .listUserInsurace()
      .then((res) => setData(res.data))
  }, [render])

  const changeRender = (e) => {
    e && setValueSearch(e.target.value)
    setRender(!render)
  }
  const handleListItemClick = (id) => {
    setId(id.id)
  }

  const editInfo = () => {
    return data.list.filter((e) => {
      return e.id === id
    })[0]
  }

  const filtered = (data) => {
    if (data) {
      return data.filter((e) => {
        if (valueSearch) {
          return e.fullName.toUpperCase().includes(valueSearch.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  return (
    <>
      <InputSearch
        onClick={() => openNew()}
        onChange={changeRender}
        value={valueSearch}
      />
      <PaperList
        children={
          <ListWithIcon
            data={filtered(data.list)}
            nameIncludes="fullName"
            selectedItem={id}
            onClickSelect={handleListItemClick}
            onClickEdit={openEdit}
            loading={loading}
          />
        }
        height="500px"
        styles={{ marginBottom: "15px" }}
      />
      {edit.suspense && (
        <Edit
          open={edit.open}
          handleclose={closeEdit}
          id={id}
          editInfo={editInfo}
          changeRender={changeRender}
          insurances={data.insurances}
        />
      )}
      {newUserInsurance.suspense && (
        <New
          open={newUserInsurance.open}
          handleclose={closeNew}
          changeRender={changeRender}
          insurances={data.insurances}
        />
      )}
    </>
  )
}

export default ContentUserInsurance
