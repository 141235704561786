import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      width: "325px",
    },
  },
}))
const VerticalTabs = (props) => {
  const mediumViewport = useMediaQuery("(min-width:600px)")
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Hidden>
        <Tabs
          indicatorColor="primary"
          scrollButtons="on"
          textColor="primary"
          orientation={mediumViewport ? "vertical" : "horizontal"}
          variant="scrollable"
          value={props.value}
          onChange={props.onChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Datos del Asegurado" />
          <Tab label="Datos del Tenedor" />
          <Tab label="Transporte Local" />
          <Tab label="Transporte en Ruta" />
        </Tabs>
      </Hidden>
    </div>
  )
}

export default VerticalTabs
