import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  style: {
    height: "600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "30px",
      height: "auto",
    },
  },
}))
