import React, { useEffect, useState } from "react"

import { TextField } from "@material-ui/core"
import { Grid } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import BasicTable from "Components/UI/Atoms/BasicTable"
import FirstTable from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/PerceptionsTable/FirstTable"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const PerceptionsTable = (props) => {
  const { open, handleclose, data, setChanges, changes } = props

  const dataParse = data?.percepciones
  const [rows, setRows] = useState(dataParse)
  const [value, setValue] = useState(1000)

  // eslint-disable-next-line
  useEffect(() => setRows(dataParse), [data])

  const col = [
    {
      title: "Descripción",
      style: { width: "45%", color: "white" },
      edit: true,
    },
    {
      title: "Porcentaje (%)",
      style: { width: "45%", color: "white" },
      edit: true,
    },
    { title: "", style: { width: "10%" } },
  ]

  const editRow = (e, index) => {
    e.preventDefault()
    let newArr = [...rows]
    newArr[index][e.target.name] = e.target.value
    setRows(newArr)
  }

  const addRow = () => {
    setRows([
      ...rows,
      {
        text: "Nueva percepción " + parseInt(rows.length + 1),
        value: 0,
      },
    ])
  }

  const deleteRow = (index) => {
    let newArr = [...rows]
    newArr.splice(index, 1)
    setRows(newArr)
  }

  const submit = () => {
    setChanges({ ...changes, percepciones: JSON.stringify(rows) })
    handleclose()
  }

  const cancel = () => {
    handleclose()
    setTimeout(() => {
      setRows(dataParse)
    }, 250)
  }

  const col2 = [
    { title: "", style: { width: "30%", textAlign: "right" } },
    { title: "Precio siniestro Imp. Incluído", style: { width: "35%" } },
    { title: "Precio siniestro + Imp.", style: { width: "35%" } },
  ]

  const formatter = new Intl.NumberFormat("es-AR", {
    currency: "ARS",
    style: "currency",
  })

  const sum =
    rows.length > 0
      ? rows
          .map((item) => parseFloat(item.value))
          .reduce((prev, next) => prev + next) / 100
      : 0
  let netValue = value / (1.21 + sum)

  const rows2 = [
    { a: "Neto:", b: formatter.format(netValue), c: formatter.format(value) },
    {
      a: "IVA:",
      b: formatter.format(netValue * 0.21),
      c: formatter.format(value * 0.21),
    },
    {
      a: "Total:",
      b: formatter.format(value),
      c: formatter.format(value * (1.21 + sum)),
    },
  ]

  rows.map((e, index) => {
    return rows2.splice(2 + index, 0, {
      a: `${e.text}:`,
      b: formatter.format(netValue * parseFloat(e.value / 100)),
      c: formatter.format(value * parseFloat(e.value / 100)),
    })
  })

  return (
    <DialogComponent
      open={open}
      handleclose={cancel}
      disableBackdropClick
      title={`Percepciones ${data.nombre}`}
      widthLarge={"800px"}
      children={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FirstTable
              col={col}
              rows={rows}
              onChange={editRow}
              deleteRow={deleteRow}
              addRow={addRow}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <h1>Ejemplo cotización siniestro</h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Monto"
              fullWidth
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <BasicTable col={col2} rows={rows2} size="small" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <CancelConfirmButtons
                noMargin={true}
                submitText="Guardar"
                onClickCancel={cancel}
                onClickSubmit={submit}
              />
            </div>
          </Grid>
        </Grid>
      }
    />
  )
}

export default PerceptionsTable
