import React, { useEffect, useState, useContext } from "react"

import { Grid } from "@material-ui/core"

import Title from "./Title"
import DialogTabs from "./Tabs"
import { interfaces } from "service/interfaces"
import { FormContext } from "Components/Hooks/ContextForm"

const Content = (props) => {
  const { id, setRenderTable, renderTable, closeBigDialog } = props

  const [isLoading, setIsLoading] = useState(true)
  const [renderModal, setRenderModal] = useState(false)
  const [editAllow, setEditAllow] = useState(false)

  const { setFormEdit, setInitialData } = useContext(FormContext)
  useEffect(() => {
    async function apiCall() {
      await interfaces.vehicleInterface
        .oneVehicle(id)
        .then((res) => {
          setEditAllow(res.header.header.authorization)
          setFormEdit({ ...res.data, stock: [], stockApi: false })
          setInitialData({ ...res.data, header: res.header.header })
        })
        .finally()
      setIsLoading(false)
    }
    apiCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderModal])

  return (
    <div>
      {isLoading ? null : (
        <Grid container>
          <Grid item xs={12}>
            <Title
              closeBigDialog={closeBigDialog}
              setRenderTable={setRenderTable}
              renderTable={renderTable}
              renderModal={renderModal}
              setRenderModal={setRenderModal}
              editAllow={editAllow}
            />
          </Grid>
          <Grid item xs={12}>
            <DialogTabs
              renderModal={renderModal}
              closeBigDialog={closeBigDialog}
              setRenderTable={setRenderTable}
              renderTable={renderTable}
              setRenderModal={setRenderModal}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Content
