import InputButtonWithOutFormik from "Components/UI/Atoms/InputButtonWithOutValidate"

export const FORM_VEHICLE = (
  initValues,
  handleSubmitCode,
  handleChangeCode,
  code,
  loadingSearch
) => {
  return [
    {
      class: "custom",
      component: (
        <InputButtonWithOutFormik
          onSubmit={handleSubmitCode}
          handleChangeCode={handleChangeCode}
          value={code.code}
          placeholder="Código"
          label="Código"
          loading={loadingSearch}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "producto",
      label: "Producto",
      value: initValues.producto ? initValues.producto : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehiculo",
      value: initValues.vehiculo ? initValues.vehiculo : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      value: initValues.estado ? initValues.estado : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
  ]
}
