import React from 'react';
import DialogComponent from '../Modals/DialogComponent';
import CancelConfirmButtons from '../CancelConfirmButtons';

const DeleteImageDialog = (props) => {
  const { open, onClose, onSubmit, loading } = props

  return (
    <DialogComponent
      open={open}
      handleclose={loading ? null : onClose}
      widthLarge="500px"
      title="¿Desea eliminar esta foto?"
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "15px",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={onClose}
            onClickSubmit={onSubmit}
            isLoading={loading}
            disabledCancel={loading}
            disabledSubmit={loading}
          />
        </div>
      }
    />
  )
}

export default DeleteImageDialog
