import React, { useRef, useState } from 'react'
import { Formik, Form } from 'formik'

import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'
import useCustomSnackbar from 'Components/UI/Atoms/SnackBar'
import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import InputsWithValidate from 'Components/UI/Molecules/InputsWithValidate'
import { interfaces } from 'service/interfaces'

const Edit = (props) => {
  const { open, handleclose, id, editInfo, changeRender } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const info = editInfo()
  const [isLoading, setIsLoading] = useState(false)
  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    await interfaces.interfaceTypeVehicle
      .updateVehicle(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: 'success',
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: 'error',
        })
      )
      .finally(handleclose)
    setIsLoading(false)
  }

  const object = (values) => [
    {
      class: 'textfield',
      label: 'Nombre',
      name: 'nombre',
      value: values.nombre,
      sm: 12,
      xs: 12,
    },
    {
      class: 'textfield',
      label: 'Descripción',
      name: 'descripcion',
      value: values.descripcion,
      sm: 12,
      xs: 12,
    },
  ]
  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          nombre: info.nombre,
          descripcion: info.descripcion,
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={object(values)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
            <button
              style={{ display: 'none' }}
              type='submit'
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <DialogComponent
      title='Editar tipo de vehículo'
      open={open}
      handleclose={handleclose}
      children={children()}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleclose}
          isLoading={isLoading}
          disabledCancel={isLoading}
          disabledSubmit={isLoading}
        />
      }
    />
  )
}

export default Edit
