import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Delete = (props) => {
  const { open, handleClose, selectedIndex, changeRender, setIdBrand } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    await interfaces.configInterface
      .deleteBrand(selectedIndex)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        setIdBrand(null)
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        widthLarge="500px"
        title="¿Desea eliminar esta marca?"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={onSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default Delete
