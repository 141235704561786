import React, { useEffect } from "react"

import StockTemplate from "Components/UI/Templates/Sales/Stock"

const Stock = () => {
  useEffect(() => {
    document.title = "Stock"
  }, [])

  return <StockTemplate />
}

export default Stock
