import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import Provincies from "./Provincies"
import { interfaces } from "service/interfaces"
import Localities from "./Localities"
import Address from "./Address"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const Content = () => {
  const [ data, setData ] = useState([])
  const [ idProvincie, setIdProvincie ] = useState()
  const [ idLocalities, setIdLocalities ] = useState()
  const [ idAddress, setIdAddress ] = useState(null)
  const [render, setRender] = useState(true)
  const [ dataLocalities, setDataLocalities] = useState([])

  const changeRender = () => {
    setRender(!render)
  }

  const createDismissableSnackbar = useCustomSnackbar()

  const handleListItemClickProvincie = async (id) => {
    try {
      const response = await interfaces.configInterface.postLocalities({
        provincia_id: id.id
      })
      setDataLocalities(response.data)
      changeRender()
    } catch (error) {
      createDismissableSnackbar("Error al cargar las localidades", {
        variant: "error",
      });
    }
    setIdProvincie(id.id)
    setIdLocalities(null)
    setIdAddress(null)
  }
  
  const handleListItemLocalities = (id) => {
    setIdLocalities(id.id)
    setIdAddress(null)
  }

  const handleListItemAddress = (id) => {
    setIdAddress(id.id)
  }

  function handleRemoveClick() {
    setIdAddress(null)
  }

  useEffect(() => {
    interfaces.configInterface.listPLA().then((res) => setData(res.data))
  }, [render])

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Provincia
          </Typography>
          <Provincies
            data={data.provincies}
            handleListItemClick={handleListItemClickProvincie}
            idProvincie={idProvincie}
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Localidad
          </Typography>
           <Localities
            data={dataLocalities}
            changeRender={changeRender}
            handleListItemClick={handleListItemLocalities}
            idLocalities={idLocalities}
            idProvincie={idProvincie}
            setDataLocalities={setDataLocalities}
            handleListItemClickProvincie={handleListItemClickProvincie}
          /> 
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Calle
          </Typography>
           <Address
            data={data.address}
            handleRemoveClick={handleRemoveClick}
            changeRender={changeRender}
            handleListItemClick={handleListItemAddress}
            idAddress={idAddress}
          /> 
        </Grid>
      </Grid>
    </>
  )
}

export default Content