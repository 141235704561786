export function columnTheme() {
  return {
    "&:nth-child(0)": {
      width: "15px",
    },
    "&:nth-child(1)": {
      width: "20px",
    },
    "&:nth-child(2)": {
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    "&:nth-child(3)": {
      minWidth: "135px",
      maxWidth: "135px",
      width: "135px",
    },
    "&:nth-child(4)": {
      width: "300px",
      minWidth: "180px",
      maxWidth: "300px",
    },
    "&:nth-child(5)": {
      width: "130px",
      minWidth: "130px",
      maxWidth: "130px",
    },
    "&:nth-child(6)": {
      minWidth: "80px",
      width: "80px",
    },
    "&:nth-child(7)": {
      minWidth: "50px",
    },
    "&:nth-child(8)": {
      minWidth: "200px",
    },
    "&:nth-child(9)": {
      width: "100px",
    },
  }
}
