import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import TransportContent from "./TransportContent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { getNavbar } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"

const Transport = () => {
  const { state, setOpen, setClose } = SetState()
  const [canClose, setCanClose] = useState(true)
  const createDismissableSnackbar = useCustomSnackbar()

  function handleClose() {
    if (canClose === true) {
      setClose()
    } else {
      createDismissableSnackbar("Tiene cambios sin guardar!", {
        variant: "error",
      })
    }
  }

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Transportes de retiro"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/truck.png"}
        disabled={JSON.parse(getNavbar()).retirement ? false : true}
      />
      <DialogComponent
        widthLarge="70%"
        disableBackdropClick
        disableEscapeKeyDown
        title="Transportes de retiro"
        open={state.open}
        handleclose={handleClose}
        maxHeight
        children={
          <TransportContent canClose={canClose} setCanClose={setCanClose} />
        }
      />
    </>
  )
}

export default Transport
