import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  home: {
    margin: "70px",
    paddingTop: "5px",

    [theme.breakpoints.only("xs")]: {
      overflow: "auto ",
      height: "538px",
      margin: "30px 0px 30px 0px",
      padding: "0px 10px 0px 10px",
      paddingTop: "5px",
    },
  },
  table: {
    overflow: "scroll ",
    height: "375px",
    [theme.breakpoints.only("xs")]: {
      overflow: "auto ",
      height: "500px",
    },
  },
}))

export default useStyles
