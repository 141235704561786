export function columnTheme() {
  return {
    "&:nth-child(0)": {
      width: "0px",
    },
    "&:nth-child(1)": {
      minWidth: "0px",
      maxWidth: "0px",
    },
    "&:nth-child(2)": {
      minWidth: "120px",
      maxWidth: "120px",
    },
    "&:nth-child(3)": {
      minWidth: "300px",
      width: "500px",
    },
    "&:nth-child(4)": {
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(5)": {
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(6)": {
      minWidth: "100px",
      width: "100px",
    },
    "&:nth-child(7)": {
      minWidth: "150px",
      width: "150px",
    },
    "&:nth-child(8)": {
      minWidth: "150px",
      width: "500px",
    },
    "&:nth-child(9)": {
      minWidth: "0px",
      width: "100px",
    },
  }
}
