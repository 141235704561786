import React, { useState, useContext } from "react"

import { interfaces } from "service/interfaces"
import { EditContext } from "Components/Hooks/ContextEdit"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import { Home } from "./Tabs/Home"
import Files from "./Tabs/Files"
import DialogSave from "./DialogSave"
import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"
import SetState from "Components/Hooks/SetState"
import SubPiezas from "./Tabs/SubPiezas"

const DialogMoreInfo = (props) => {
  const { open, handleClose, handleRender, data } = props

  const [edit] = useContext(EditContext)

  const [loading, setLoading] = useState(false)
  const [dialogData, setDialogData] = useState(data)

  const { state: openSave, setClose: setCloseSave } = SetState()

  const createDismissableSnackbar = useCustomSnackbar()
  const listVerticalTabs = [
    {
      id: 0,
      title: "Inicio",
    },
    {
      id: 1,
      title: "Fotografias",
    },
    {
      id: 2,
      title: "Subpiezas",
      disabled: dialogData.stock.subpiezas.length === 0,
    },
    {
      id: 3,
      title: "Historial",
      disabled: dialogData.historial.length === 0,
    },
  ]

  const setImg = (img) => {
    setDialogData({
      ...dialogData,
      stock: {
        ...dialogData.stock,
        fotos: img,
      },
    })
  }

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return <Home setData={setDialogData} data={dialogData} />
      case 1:
        return (
          <Files
            edit={edit}
            dialogData={dialogData}
            img={dialogData.stock.fotos}
            setImg={setImg}
            id={dialogData.stock.id}
          />
        )
      case 2:
        return <SubPiezas productos={dialogData.stock.subpiezas} />
      case 3:
        return <ComplexDialogHistory history={dialogData.historial} />
      default:
        break
    }
  }

  async function handleSubmit() {
    let body = {
      calidad_id: dialogData.stock.calidad_id,
      estado_id: dialogData.stock.estado_id,
      almacen_id:
        dialogData.stock.almacen_id !== ""
          ? parseFloat(dialogData.stock.almacen_id)
          : null,
      precio1: dialogData.stock.precio1
        ? parseFloat(dialogData.stock.precio1)
        : null,
      ubicacion_id: dialogData.stock.ubicacion_id,
      comentarios: dialogData.stock.comentarios,
      producto_id: dialogData.stock.producto_id,
    }
    setLoading(true)
    await interfaces.plantInterfaceClassification
      .updateStock(dialogData.stock.id, body)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        if (handleRender) {
          handleRender()
        }
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
      .finally(setCloseSave)
    handleClose()
    setLoading(false)
  }

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleClose}
        title={`${dialogData.stock.producto} ${dialogData.stock.vehiculo}`}
        widthLarge="1000px"
        subtitle={
          <div>
            <b>{dialogData.stock.codigo}</b> - Creado el{" "}
            {dialogData.stock.creado} por {dialogData.stock.usuario}
          </div>
        }
        tabs={listVerticalTabs}
        children={renderTabs}
        actions={
          <ComplexDialogActions
            onClose={handleClose}
            handleSubmit={() => handleSubmit()}
            canEdit={!dialogData.canEdit}
            data={dialogData}
            editMode={true}
            loading={loading}
          />
        }
      />
      <DialogSave
        open={openSave.open}
        handleClose={setCloseSave}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
export default DialogMoreInfo
