import React, { useState } from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import UsersDialog from "Components/UI/Organisms/Settings/Users/UsersDialog"
import { interfaces } from "service/interfaces"
import { columnTheme } from "./styles"
import SetState from "Components/Hooks/SetState"
import EditContextProvider from "Components/Hooks/ContextEdit"

const UsersTable = (props) => {
  const { render, setRender } = props

  const windowSize = useMediaQuery("(min-width:1000px)")

  const { state, setOpen, setClose } = SetState()

  const [info, setInfo] = useState({
    rows: [],
    header: [],
    filters: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
      filters: [],
    })
    try {
      const result = await interfaces.configUsersInterface.userList(options)
      setInfo({
        rows: result.data.mappedList,
        header: result.header.header.count,
        filters: result.data.filters,
      })
      callback()
    } catch (error) {}
  }

  async function getUserInfo(id) {
    await interfaces.configUsersInterface.userInfo(id).then((res) => {
      setOpen({ data: res.data })
    })
  }

  const RowsVehicles = () =>
    info.rows.map((file) => [
      "",
      file.nombre,
      file.apellido,
      file.ubicacion,
      file.area,
      file.ocupacion,
      file.legajo,
      file.email,
    ])

  const columns = columnsForTable(
    [
      {
        name: "nombre",
        label: "Nombre",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
        marginLeft: windowSize ? "10px" : "",
      },
      {
        name: "apellido",
        label: "Apellido",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "ubicacion",
        label: "Ubicación",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        options: info.filters.ubicaciones,
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "area",
        label: "Área",
        display: true,
        filter: true,
        sort: false,
        filterName: "label",
        options: info.filters.areas,
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "puesto",
        label: "Rol",
        display: true,
        filter: true,
        sort: false,
        filterName: "label",
        options: info.filters.roles,
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "legajo",
        label: "Legajo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "email",
        label: "Correo electrónico",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    info.filters
  )

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <UsersDialog
            open={state.open}
            onClose={setClose}
            render={render}
            setRender={setRender}
            data={state.data}
          />
        </EditContextProvider>
      )}

      <MuiDatables
        columns={columns}
        data={RowsVehicles()}
        title={`Total de usuarios: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar nombre, apellido o legajo..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        onRowClick={(_, index) => getUserInfo(info.rows[index.dataIndex].id)}
        padding={windowSize ? "1" : ""}
        rows={50}
      />
    </>
  )
}
export default UsersTable
