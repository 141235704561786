import React, { useState, useContext } from "react"
import { EditContext } from "Components/Hooks/ContextEdit"
import ExitToApp from "@material-ui/icons/ExitToApp"

import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import HomeContent from "./Home"
import PartsTable from "./Parts"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"

const Edit = (props) => {
  const {
    open,
    handleclose,
    data,
    setRender,
    render,
    handleRender,
    printQuote,
    setLoading,
    infoFilters,
    permissions,
  } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose } = SetState()
  const [edit] = useContext(EditContext)

  const [dataInfo, setDataInfo] = useState({
    ...data,
    data: {
      ...data.act,
    },
  })

  const onDataChange = (newData) => {
    setDataInfo((prevDataInfo) => ({
      ...prevDataInfo,
      details: newData,
    }))
  }

  const handleDelete = async () => {
    setLoading(true)
    await interfaces.compactionInterface
      .deleteAct(data.act.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
    setClose()
  }

  const confirmCompaction = async () => {
    setLoading(true)
    const value = dataInfo.details?.map((e) => ({
      dominio: e.dominio,
    }))
    await interfaces.compactionInterface
      .confirmAct(data.act.id, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
    setClose()
  }

  const handleSubmit = async () => {
    await interfaces.compactionInterface
      .update(data.act.id, dataInfo.data)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
        if (handleRender) {
          handleRender()
        }
      })
  }

  const horizontalTabs = [
    {
      id: 0,
      title: "Inicio",
      color: "primary",
    },
    {
      id: 1,
      title: "Piezas",
      color: "primary",
    },
    {
      id: 2,
      title: "Historial",
      color: "primary",
    },
  ]

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <HomeContent
            data={dataInfo}
            setData={setDataInfo}
            printQuote={printQuote}
            confirmCompaction={confirmCompaction}
            edit={edit}
            infoFilters={infoFilters}
            permissions={permissions}
          />
        )
      case 1:
        return <PartsTable data={dataInfo} onDataChange={onDataChange} />
      case 2:
        return <ComplexDialogHistory history={data.history} />
      default:
        break
    }
  }
  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleclose}
        tabs={horizontalTabs}
        title={`Acta ${dataInfo.act.creacion}`}
        subtitle={
          <div>
            {`ID ${dataInfo.act.id} - Creado el ${dataInfo.act.creacion}`}
          </div>
        }
        children={renderTabs}
        actions={
          <ComplexDialogActions
            onClose={handleclose}
            handleSubmit={() => handleSubmit()}
            canEdit={!permissions.allowEdit}
            data={dataInfo}
            editMode={true}
            leftButton={
              permissions.allowDelete
                ? {
                    icon: <ExitToApp fontSize="small" />,
                    title: "Eliminar",
                    variant: "contained",
                    disabled: false,
                    style: { background: "rgb(244, 67, 54)", color: "#fff" },
                    onClick: () => {
                      setOpen()
                    },
                  }
                : undefined
            }
          />
        }
      />

      {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={state.loading ? null : setClose}
          widthLarge={500}
          title="¿Desea eliminar la siguiente acta?"
          subtitle="¡Esta acción es irreversible!"
          disableChildHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickSubmit={() => handleDelete()}
              disabledCancel={state.loading}
              disabledSubmit={state.loading}
              isLoading={state.loading}
            />
          }
        />
      )}
    </>
  )
}

export default Edit
