import { axiosService } from "../../index"
import { dashboardEndpoints } from "../../sources"

const userHistory = (offset) => {
  return axiosService.get(dashboardEndpoints.HISTORY + "?offset=" + offset)
}

const listNotification = (read, offset) => {
  return axiosService.get(
    dashboardEndpoints.LIST_NOTIFICATION +
      "?offset=" +
      offset +
      "&leido=" +
      read
  )
}

const readNotification = () => {
  return axiosService.post(dashboardEndpoints.LIST_NOTIFICATION)
}

const deleteNotification = (id) => {
  return axiosService.del(dashboardEndpoints.DELETE_NOTIFICATION + id)
}

const userChangePass = (pass) => {
  return axiosService.put(dashboardEndpoints.CHANGE_PASS, pass)
}

const readNotiId = (id) => {
  return axiosService.put(dashboardEndpoints.READ_NOTI_ID + id)
}

const userProfile = () => {
  return axiosService.get(dashboardEndpoints.GETINFO_USER)
}

const setAndreani = (body) => {
  return axiosService.post(dashboardEndpoints.ANDREANI, body)
}

const profilePhoto = (body) => {
  return axiosService.uploadSingleFile(dashboardEndpoints.PROFILE_PHOTO, body)
}
const getVehicleData = (body) => {
  return axiosService.post(dashboardEndpoints.VEHICLE_DATA, body)
}
const uploadPhotos = (dominio, body, index) => {
  return axiosService.uploadFiles(
    dashboardEndpoints.UPLOAD_PHOTOS + "?dominio=" + dominio,
    body,
    index
  )
}
const getVehicleInfoAndPhoto = (body) => {
  return axiosService.post(dashboardEndpoints.GET_VEHICLE_DATA, body)
}

const widgetSearchStock = (body) => {
  return axiosService.get(
    dashboardEndpoints.SEARCH_STOCK + "?search=" + body,
    body
  )
}

const updateSettings = (body) => {
  return axiosService.post(dashboardEndpoints.UPDATE_SETTINGS, body)
}
const updateVehicle = (id, body) => {
  return axiosService.put(dashboardEndpoints.UPDATE_INFO_VEHICLE + id, body)
}

const createStationZeroStock = (body) => {
  return axiosService.post(dashboardEndpoints.STATION_ZERO, body)
}

export const dashboardInterface = {
  userHistory,
  listNotification,
  readNotification,
  userChangePass,
  userProfile,
  setAndreani,
  profilePhoto,
  getVehicleData,
  uploadPhotos,
  getVehicleInfoAndPhoto,
  widgetSearchStock,
  updateSettings,
  updateVehicle,
  readNotiId,
  deleteNotification,
  createStationZeroStock,
}
