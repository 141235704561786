import React, { useState } from "react";

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import { useSnackbar } from "notistack";
import { interfaces } from "service/interfaces";
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons";
const Delete = (props) => {
  const {
    handleClose,
    handleReset,
    handleRender,
    open,
    id,
    handleCloseDelete,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  async function handleDelete() {
    setLoading(true);
    await interfaces.interfaceTypeVehicle
      .deleteVehicle(id)
      .then((res) => {
        enqueueSnackbar(res.data, { variant: "success" });
        handleReset();
        handleRender();
        handleCloseDelete();
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, {
          variant: "error",
        })
      );
    setLoading(false);
  }
  return (
    <>
      <DialogComponent
        title="¿Desea eliminar este tipo de vehículo?"
        open={open}
        handleclose={handleClose}
        handleclosedisabled={loading}
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={() => handleDelete()}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        }
      />
    </>
  );
};

export default Delete;
