import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    height: "75px",
  },
  option: {
    '&[data-focus="true"]': {
      color: "#fff",
      fontWeight: 800,
      backgroundColor: theme.palette.primary.light,
      borderColor: "transparent",
    },
    '&[aria-selected="true"]': {
      color: "#fff",
      fontWeight: 800,
      backgroundColor: theme.palette.primary.main,
      borderColor: "transparent",
    },
  },
}))

export default useStyles
