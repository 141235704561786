import React, { useState, useContext } from "react"

import { Grid, Typography, Card, ButtonBase } from "@material-ui/core"

import { useStyles } from "./styles"
import { interfaces } from "service/interfaces"
import PictureCard from "Components/UI/Atoms/PictureCard"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { fileTypeTest } from "Helpers"
import UploadImageDialog from "Components/UI/Molecules/UploadImageDialog"
import Loader from "Components/UI/Atoms/Loader"
import setState from "Components/Hooks/SetState"
import Videoplayer from "Components/UI/Molecules/Videoplayer"
import { EditContext } from "Components/Hooks/ContextEdit"
import DeleteImageDialog from "Components/UI/Molecules/DeleteImageDialog"

export default function ViewImages(props) {
  const { img, setImg, id } = props

  const [edit] = useContext(EditContext)
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [changeUpload, setChangeUpload] = useState([])
  const createDismissableSnackbar = useCustomSnackbar()
  const [startDelete, setStartDelete] = useState({
    open: false,
    id: null,
    index: null,
  })
  const [startEdit, setStartEdit] = useState({
    open: false,
    id: null,
  })

  const {
    state: video,
    setOpen: openVideo,
    setClose: closeVideo,
    setState: setVideo,
  } = setState()

  function handleChangeUpload(e) {
    e.preventDefault()
    let prevData = [...changeUpload]
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    setChangeUpload(prevData)
  }
  function handleOpenDelete(index) {
    setStartDelete({
      open: true,
      id: id,
      index: index,
    })
  }
  function handleOpenUpload() {
    setStartEdit({
      open: true,
      id: id,
    })
  }
  function handleCloseDelete() {
    setChangeUpload([])
    setStartDelete({
      open: false,
      index: null,
    })
  }
  function handleCloseUpload() {
    setChangeUpload([])
    setStartEdit({
      open: false,
    })
  }
  function handleAdd(arr) {
    for (let i = 0; i < arr.length; i++) {
      let imgUp = URL.createObjectURL(arr[i])
      img.push(imgUp)
    }
  }
  function handleDel(index) {
    img.splice(index, 1)
    let newArray = img
    setChangeUpload(newArray)
  }

  async function handleDelete() {
    const index = img.indexOf(startDelete.index)

    setIsLoading(true)
    await interfaces.plantUsed
      .deletePhoto(id, index + 1)
      .then((res) => {
        createDismissableSnackbar(res.data, { variant: "success" })
        handleDel(index)
        handleCloseDelete()
        setIsLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(`Error`, {
          variant: "error",
        })
        handleCloseDelete()
        setIsLoading(false)
      })
  }
  async function handleUpload() {
    setIsLoading(true)
    await interfaces.plantUsed
      .uploadPhoto(id, changeUpload, img.length + 1)
      .then((res) => {
        createDismissableSnackbar(`${res.data.data.msg}`, {
          variant: "success",
        })
        handleAdd(changeUpload)
        handleCloseUpload()
        setIsLoading(false)
        setImg(res.data.data.photos)
      })
      .catch((error) => {
        setIsLoading(false)
        createDismissableSnackbar(`${error.response.data.data} `, {
          variant: "error",
        })
      })
  }
  const swipes = img.map((item, index) => ({
    id: index,
    img: item,
  }))

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {img
          .filter((e) => fileTypeTest(e) === "image")
          .map((item, index) => (
            <Grid item xs={12} xl={4} sm={6} md={4}>
              <PictureCard
                img={item}
                edit={edit}
                txt={`Fotografía ${index + 1}`}
                title={`Fotografía ${index + 1}`}
                index={index + 1}
                swipes={swipes}
                editDelete={edit}
                handleDelete={() => handleOpenDelete(item)}
              />
            </Grid>
          ))}
        {img
          .filter((e) => fileTypeTest(e) === "video")
          .map((item, index) => (
            <Grid item xs={12} xl={4} sm={6} md={4}>
              <PictureCard
                img={process.env.PUBLIC_URL + "/Images/file.png"}
                href={item}
                edit={edit}
                txt={`Video ${index + 1}`}
                title={`Video ${index + 1}`}
                index={index + 1}
                swipes={swipes}
                editDelete={edit}
                onClick={() =>
                  openVideo({
                    url: item,
                    play: true,
                    fullScreen: false,
                    height: "90vh",
                    width: "70vw",
                  })
                }
                handleDelete={() => handleOpenDelete(item)}
              />
            </Grid>
          ))}

        {edit ? (
          <Grid item xs={12} xl={4} sm={6} md={4} style={{ marginTop: "10px" }}>
            <ButtonBase component="a" onClick={() => handleOpenUpload()}>
              <Card className={classes.cardSelectImg}>
                <Typography>Seleccione una fotografía(s)..</Typography>
              </Card>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>


      <DeleteImageDialog
        open={startDelete.open}
        handleClose={handleCloseDelete}
        onSubmit={handleDelete}
      />

      {startEdit.open && (
        <UploadImageDialog
          onChange={handleChangeUpload}
          selected={changeUpload.length > 0}
          multiple
          loading={isLoading}
          onClose={handleCloseUpload}
          onSubmit={() => handleUpload()}
          open={startEdit.open}
        />
      )}
      {video.suspense && (
        <Videoplayer
          video={video}
          setVideo={setVideo}
          closeVideo={closeVideo}
        />
      )}
      {isLoading && <Loader />}
    </>
  )
}
