import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "left",
    marginBottom: "19px",
  },
}))
export default useStyles
