import { axiosService } from "../../../index"
import { plantEndpointsStorge } from "../../../sources"

const storageList = (options) => {
  return axiosService.get(plantEndpointsStorge.STORAGE_LIST + options)
}

const storageInfo = (id) => {
  return axiosService.get(plantEndpointsStorge.STORAGE_INFO + "/" + id)
}

const storageSticker = (id) => {
  return axiosService.getPDF(plantEndpointsStorge.STORAGE_STICKER + "/" + id)
}

const newStorage = (body) => {
  return axiosService.post(plantEndpointsStorge.NEW_STORAGE, body)
}

const editStorage = (id, body) => {
  return axiosService.put(plantEndpointsStorge.EDIT_STORAGE + "/" + id, body)
}

const searchStorageCode = (code, empresa) => {
  return axiosService.get(
    plantEndpointsStorge.SEARCH_STORAGE_CODE +
      "?code=" +
      code +
      "&empresa=" +
      empresa
  )
}

const toUpdate = (body) => {
  return axiosService.post(plantEndpointsStorge.TO_UPDATE, body)
}

const updateMassive = (body) => {
  return axiosService.post(plantEndpointsStorge.UPDATE_MASSIVE, body)
}

const deleteStorage = (id) => {
  return axiosService.del(plantEndpointsStorge.EDIT_STORAGE + "/" + id)
}

export const plantStorage = {
  storageList,
  storageInfo,
  storageSticker,
  newStorage,
  editStorage,
  searchStorageCode,
  deleteStorage,
  toUpdate,
  updateMassive,
}
