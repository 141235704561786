import { React, forwardRef } from "react"
import { Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { editRow } from "Components/Validation/Settings/Various/LotsRow"

const Form = forwardRef((props, ref) => {
  const { onSubmit, data } = props
  const info = data()
  const OBJETO = (values) => {
    return [
      {
        class: "textfield",
        name: "numero",
        label: "Nº fila",
        value: values.numero,
        sm: 12,
        xs: 12,
        type: "number",
        min: 0,
        disabled: true
      },
      {
        class: "textfield",
        name: "ancho",
        label: "Ancho",
        value: values.ancho,
        sm: 12,
        xs: 12,
        type: "number",
        min: 0
      },
      {
        class: "textfield",
        name: "largo",
        label: "Largo",
        value: values.largo,
        sm: 12,
        xs: 12,
        type: "number",
        min: 0
      },
    ]
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          numero: info.numero,
          ancho: info.ancho,
          largo: info.largo    
        }}
        validationSchema={editRow}
        onSubmit={onSubmit}
      >
        {({ submitForm, values, setFieldValue, isSubmitting }) => (
          <>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form