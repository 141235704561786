import React, { useRef, useState } from "react"
import SetState from "Components/Hooks/SetState"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { newUserInsurance } from "Components/Validation/Settings/Various/UserInsurance"
import { Form, Formik } from "formik"
import { interfaces } from "service/interfaces"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const Edit = ({
  open,
  handleclose,
  changeRender,
  id,
  editInfo,
  insurances,
}) => {
  const { state, setOpen, setClose } = SetState()
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const info = editInfo()

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const onSubmit = async (values) => {
    setIsLoading(true)
    await interfaces.configInterface
      .updateUserInsurance(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setIsLoading(false)
    changeRender()
    handleclose()
  }
  const field = (values, setFieldValue) => [
    {
      class: "textfield",
      label: "Nombre de usuario",
      name: "username",
      value: values.username,
      disabled: true,
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Nombre",
      name: "nombre",
      value: values.nombre,
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Apellido",
      name: "apellido",
      value: values.apellido,
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Correo electrónico",
      name: "email",
      value: values.email,
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          name="cia_seguros"
          options={insurances}
          getOptionLabel={(e) => e.nombre}
          defaultValue={values.cia_seguros}
          label={"Compañias asociadas"}
          onChange={(_, value) => {
            setFieldValue("cia_seguros", value)
          }}
          tagName={"nombre"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      label: "Puesto",
      name: "puesto",
      value: values.puesto,
      sm: 12,
      xs: 12,
    },
  ]

  const submitDelete = async () => {
    setIsLoading(true)
    await interfaces.configInterface
      .deleteUserInsurance(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setIsLoading(false)
    changeRender()
    handleclose()
  }

  const formulario = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          username: info?.username,
          nombre: info?.nombre,
          apellido: info?.apellido,
          email: info?.email,
          cia_seguros: insurances.filter((e) =>
            info?.cia_seguros.includes(e.id)
          ),
          puesto: info?.puesto,
        }}
        validationSchema={newUserInsurance}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={field(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        title="Editar usuario"
        children={formulario()}
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleSubmit}
            onClickCancel={handleclose}
            leftButton
            leftButtonOnClick={setOpen}
            leftButtonText="Eliminar"
            leftButtonHiddenFalse
            leftButtonStyle={{ backgroundColor: "red", color: "white" }}
          />
        }
      />
      {state.suspense && (
        <DialogComponent
          title="¿Está seguro de eliminar el siguiente usuario?"
          open={state.open}
          handleclose={setClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          widthLarge={"550px"}
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              onClickSubmit={submitDelete}
              disabledCancel={isLoading}
              disabledSubmit={isLoading}
              isLoading={isLoading}
            />
          }
        />
      )}
    </>
  )
}

export default Edit
