import React, { useState, useRef } from "react"
import ReactImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import { Grid, IconButton } from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import CustomButton from "Components/UI/Atoms/CustomButton"
import setState from "Components/Hooks/SetState"
import useStyles from "./styles"
import { fileTypeTest, titles } from "Helpers"
import ImgZoomStock from "Components/UI/Atoms/ImgZoomStock"
import Videoplayer from "Components/UI/Molecules/Videoplayer";

const Edit = (props) => {
  const { open, handleclose, data, info } = props

  const ref = useRef()
  const imgRef = useRef(null)
  const classes = useStyles()

  const {
    state: video,
    setOpen: openVideo,
    setClose: closeVideo,
    setState: setVideo,
  } = setState()

  const [zoomScale, setZoomScale] = useState(false)
  const { state: fullImg, setState: setFullImg } = setState()

  const fields = () => [
    {
      class: "textfield",
      name: "pieza",
      label: "Pieza",
      sm: 6,
      xs: 12,
      value: data ? data.pieza : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "nrodev",
      label: "Nº V",
      sm: 6,
      xs: 12,
      value: data ? data.nrodev : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "dominio",
      label: "Dominio",
      sm: 6,
      xs: 12,
      value: data ? data.dominio : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "marca",
      label: "Marca",
      sm: 6,
      xs: 12,
      value: data ? data.marca : null,
    },
    {
      class: "textfield",
      name: "modelo",
      label: "Modelo",
      sm: 6,
      xs: 12,
      value: data ? data.modelo : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "año",
      label: "Año",
      sm: 6,
      xs: 12,
      value: data ? data.año : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "color",
      label: "Color",
      sm: 6,
      xs: 12,
      value: data ? data.color : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      sm: 6,
      xs: 12,
      value: data ? data.estado : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "ubicacion",
      label: "Ubicación",
      sm: 6,
      xs: 12,
      value: data ? data.ubicacion : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "lote",
      label: "Lote",
      sm: 3,
      xs: 12,
      value: data ? data.lote : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "fila",
      label: "Fila",
      sm: 3,
      xs: 12,
      value: data ? data.fila : null,
      inputProps: {
        readOnly: true,
      },
    },
  ]

  function closeGallery(e) {
    setFullImg({ fullScreen: e })
    if (!e) {
      setZoomScale(false)
    }
  }

  const children = () => {
    return (
      <div>
        <Grid container>
          <Grid
            sm={12}
            md={5}
            xs={12}
            className={classes.paddingGrid}
            id="fields"
          >
            <InputsWithoutValidate fields={fields()} spacing={2} />
          </Grid>
          <Grid sm={12} md={7} xs={12} className={classes.contentImg}>
            <div className={classes.divContentImg}>
              <ReactImageGallery
                ref={ref}
                items={titles(data.fotografias)}
                useBrowserFullscreen={false}
                showBullets={true}
                onClick={() => ref.current.fullScreen()}
                onScreenChange={(e) => closeGallery(e)}
                stopPropagation={true}
                lazyLoad={true}
                showThumbnails={false}
                onErrorImageURL={
                  process.env.PUBLIC_URL + "/Images/unavailable.jpg"
                }
                renderPlayPauseButton={() => (
                  <IconButton
                    className={classes.donwloadIcon}
                    onClick={() =>
                      window.location.assign(
                        titles(data.fotografias)[ref.current.getCurrentIndex()].original
                      )
                    }
                  >
                    <GetAppIcon style={{ width: "35px", height: "35px" }} />
                  </IconButton>
                )}
                renderItem={({ original }) => (
                  <>
                    <div
                      style={{ cursor: zoomScale ? "zoom-out" : "zoom-in" }}
                      onClick={() =>
                        fullImg.fullScreen ? setZoomScale(!zoomScale) : null
                      }
                    >
                      <ImgZoomStock
                        img={original}
                        zoomScale={zoomScale ? 3 : 1}
                        transitionTime={0.5}
                        width={"100%"}
                        height={"515px"}
                        isFullscreen={fullImg.fullScreen}
                      />
                    </div>
                  </>
                )}
              />
            </div>
          </Grid>
        </Grid>

        <div className={classes.flex} id="buttons">
          <div className={classes.btnVideo}>
            {data.fotografias
              .filter((e) => fileTypeTest(e.fullscreen) === "video")
              .map((item, index) => {
                return (
                  <div className={classes.divInsideVideo}>
                    <CustomButton
                      fullWidth
                      text={`Video Nº ${index + 1}`}
                      color="primary"
                      onClick={() =>
                        openVideo({
                          url: item.fullscreen,
                          play: true,
                          fullScreen: false,
                          height: "90vh",
                          width: "70vw",
                        })
                      }
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <DialogComponent
        ref={imgRef}
        title={info.rows && info.rows.length > 0 ? `ID ${info.rows[0].id}` : ""}
        maxHeight
        disableEscapeKeyDown={fullImg.fullScreen === true ? true : false}
        subtitle={
          data.año ? ` Enviado a compactación ${data.año}` : ""
        }
        open={open}
        handleclose={handleclose}
        widthLarge="80vw"
        children={children()}
      />

      {video.suspense && (
        <Videoplayer
          video={video}
          setVideo={setVideo}
          closeVideo={closeVideo}
        />
      )}
    </>
  )
}

export default Edit
