import { axiosService } from "../../../index"
import { compactionEndpoints } from "service/sources/vehicles/compaction"

const list = (options) => {
  return axiosService.get(compactionEndpoints.LIST + options)
}

const create = (values) => {
  return axiosService.post(compactionEndpoints.CREATE, values)
}

const update = (id, body) => {
  return axiosService.put(compactionEndpoints.UPDATE + "/" + id, body)
}

const print = (id, values) => {
  return axiosService.getPDF(compactionEndpoints.PRINT + id, values)
}

const exportCompaction = () => {
  return axiosService.get(compactionEndpoints.EXPORT_ALL)
}

const infoOneCompaction = (id) => {
  return axiosService.get(compactionEndpoints.COMPACTION_ACT + "/" + id)
}

const deleteAct = (id) => {
  return axiosService.del(compactionEndpoints.DELETE + "/" + id)
}

const confirmAct = (id, body) => {
  return axiosService.post(compactionEndpoints.CONFIRM_ACT + id, body)
}

const pendingList = (tipo, options) => {
  return axiosService.get(compactionEndpoints.PENDING_LIST + tipo + options)
}

const addDetails = (id, body) => {
  return axiosService.post(compactionEndpoints.ADD_DETAILS + id, body)
}

const removePending = (id) => {
  return axiosService.del(compactionEndpoints.REMOVE_PENDING + id)
}

const report = (options) => {
  return axiosService.get(compactionEndpoints.REPORT + options)
}

export const compactionInterface = {
  list,
  create,
  update,
  print,
  exportCompaction,
  infoOneCompaction,
  deleteAct,
  confirmAct,
  pendingList,
  addDetails,
  removePending,
  report,
}
