import { Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

import { URGENCIA } from "Constants"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"

export const FORM_HOME = (
  editData,
  ubicaciones,
  handleChange,
  openChangeV,
  openChangeCode
) => {
  return [
    {
      class: "textfield",
      name: "pieza",
      label: "Pieza",
      sm: 6,
      xs: 12,
      disabled: editData.codigo === "" ? false : true,
      value: editData.pieza,
      onChange: (e) => handleChange(e.target.name, e.target.value),
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      sm: 6,
      xs: 12,
      disabled: true,
      value: editData.vehiculo,
    },
    {
      class: "autocomplete",
      name: "origen_id",
      label: "Desde",
      options: ubicaciones,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: editData.origen_id,
      onChange: (value) => {
        handleChange("origen_id", value.id)
      },
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "destino_id",
      label: "Hasta",
      options: ubicaciones,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: editData.destino_id,
      onChange: (value) => {
        handleChange("destino_id", value.id)
      },
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "codigo",
      label: "Código",
      sm: 6,
      xs: 12,
      disabled: true,
      value: editData.codigo,
      inputProps: {
        endAdornment: (
          <Tooltip title="Cambiar código pieza">
            <IconButton onClick={() => openChangeCode()}>
              <ArrowRightAltIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      class: "textfield",
      name: "nrodev",
      label: "Dominio / Nº de V",
      sm: 6,
      xs: 12,
      disabled: true,
      value: `${editData.dominio} / ${editData.nrodev}`,
      inputProps: {
        endAdornment: (
          <Tooltip title="Cambiar vehículo">
            <IconButton onClick={() => openChangeV()}>
              <ArrowRightAltIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      class: "textfield",
      name: "observacion_planta",
      label: "Observaciones a planta",
      onChange: (e) => handleChange(e.target.name, e.target.value),
      disabled: false,
      sm: 12,
      xs: 12,
      value: editData.observacion_planta,
    },
    {
      class: "textfield",
      name: "observacion_ventas",
      label: "Observaciones a ventas",
      onChange: (e) => handleChange(e.target.name, e.target.value),
      sm: 12,
      xs: 12,
      value: editData.observacion_ventas,
      disabled: false,
    },
  ]
}

export const PLANT_INFORMATION = (
  editData,
  estados,
  operarios_taller,
  operarios_desmontaje,
  handleChange
) => {
  return [
    {
      class: "autocomplete",
      disabled: editData.estado === 9,
      name: "estado",
      label: "Estado",
      options: estados,
      optionLabel: `options["descripcion"]`,
      optionValue: "id",
      value: editData.estado,
      onChange: (value) => handleChange("estado", value.id),
      sm: 6,
      xs: 12,
      getOptionDisabled: (e) => e.id === 9,
      disabledOptions: [9],
    },
    {
      class: "autocomplete",
      name: "urgencia",
      label: "Urgencia",
      options: URGENCIA,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      onChange: (value) => handleChange("urgencia", value.id),
      sm: 6,
      xs: 12,
      value: editData.urgencia,
    },
    {
      class: "autocomplete",
      name: "operario_desmontaje_id",
      label: "Operario desmontaje",
      options: operarios_desmontaje,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: editData.operario_desmontaje_id,
      onChange: (value) => handleChange("operario_desmontaje_id", value.id),
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "operario_taller_id",
      label: "Operario taller",
      options: operarios_taller,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: editData.operario_taller_id,
      onChange: (value) => handleChange("operario_taller_id", value.id),
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "tiempo_desmontaje",
      label: "Tiempo desmontaje",
      sm: 6,
      xs: 12,
      disabled: true,
      value: editData.tiempo_desmontaje,
    },
    {
      class: "textfield",
      name: "tiempo_taller",
      label: "Tiempo taller",
      sm: 6,
      xs: 12,
      disabled: true,
      value: editData.tiempo_taller,
    },
  ]
}

export const SALES_INFORMATION = (editData, handleChange) => {
  return [
    {
      class: "textfield",
      name: "nombre_cliente",
      label: "Nombre cliente",
      onChange: (e) => handleChange(e.target.name, e.target.value),
      sm: 12,
      xs: 12,
      value: editData.nombre_cliente,
      disabled: false,
    },
    {
      class: "textfield",
      name: "telefono_cliente",
      label: "Teléfono cliente",
      type: "number",
      onChange: (e) => handleChange(e.target.name, e.target.value),
      sm: 6,
      xs: 12,
      value: editData.telefono_cliente,
      disabled: false,
    },
    {
      class: "textfield",
      name: "importe",
      label: "Importe",
      type: "number",
      onChange: (e) => handleChange(e.target.name, e.target.value),
      sm: 6,
      xs: 12,
      value: editData.importe,
      disabled: false,
      inputProps: { maxLength: 12 },
    },
  ]
}

export const RECORD = (historial, is_ventas) => {
  return [
    {
      class: "custom",
      component: (
        <ComplexDialogHistory
          history={
            !is_ventas
              ? historial.filter((e) => e.descripcion.indexOf("Importe") === -1)
              : historial
          }
        />
      ),
    },
  ]
}
