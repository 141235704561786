import React, { useRef } from "react"
import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { stockReserve } from "Components/Validation/Sales/Stock"
import { PLAZO_FECHAS } from "Constants"
import { interfaces } from "service/interfaces"

const Reserve = (props) => {
  const {
    reserve,
    setLoading,
    handleCloseDialog,
    handleCloseReserve,
    id,
    setRender,
    render,
    isUsed,
  } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    setLoading(true)
    values.observaciones = values.observaciones.toUpperCase()
    await interfaces.salesStock
      .reserveStock(id, { ...values, isUsed })
      .then((res) => {
        setLoading(false, { open: false })
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setTimeout(() => {
          handleCloseDialog()
          setRender(!render)
        }, 100)
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
  }

  const object = [
    {
      class: "textfield",
      label: "Observaciones",
      name: "observaciones",
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Tiempo de reserva",
      name: "fecha",
      options: PLAZO_FECHAS,
      optionLabel: `options["value"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
  ]

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          observaciones: "",
          fecha: "",
        }}
        validationSchema={stockReserve}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <InputsWithValidate
              fields={object}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <DialogComponent
      open={reserve.open}
      handleclose={!reserve.loading ? handleCloseReserve : false}
      title={"Reservar este producto"}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      children={children()}
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleCloseReserve}
          isLoading={reserve.loading}
          disabledCancel={reserve.loading}
          disabledSubmit={reserve.loading}
        />
      }
    />
  )
}

export default Reserve
