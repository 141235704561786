import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  table: {
    height: ({ height }) => height - 80,
    marginTop: "60px",
    marginLeft: 8,
    width: `calc(100% - ${23}px)`,
    [theme.breakpoints.down("sm")]: {
      height: ({ height }) => height - 100,
      width: `calc(100% - ${30}px)`,
      marginLeft: 15,
      marginTop: 80,
    },
  },
  navbar: {
    background: "green",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

export default useStyles
