import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  card: {
    maxWidth: "60em",
    margin: "10px",
  },
  cardHeader: {
    height: "60px",
  },
  media: {
    height: "165px",
  },
  icondelete: {
    color: "#ff7961",
  },
}))
