import { React, forwardRef } from "react"
import { TextField } from "formik-material-ui"
import { Formik, Field } from "formik"

import { Grid } from "@material-ui/core"

import { newTransport } from "Components/Validation/Settings/Various/Transport"

const Form = forwardRef((props, ref) => {
  const { onSubmit } = props
  return (
    <>
      <Formik
        initialValues={{
          nombre: "",
        }}
        validationSchema={newTransport}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Nombre transporte"
                  name="nombre"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </div>
            </Grid>

            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        )}
      </Formik>
    </>
  )
})
export default Form
