import React from "react"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogWithLoader from "Components/UI/Molecules/Modals/WithLoader"
import { interfaces } from "service/interfaces"

const Payments = (props) => {
  const { open, close } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async () => {
    await interfaces.vehicleInterface
      .exportPayments()
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Tabla de pagos descargada!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
  }

  return (
    <DialogWithLoader
      open={open}
      close={close}
      title={"¿Desea exportar la tabla de pagos de vehículos?"}
      onSubmit={onSubmit}
      awaitMessage={"Espere mientras se genere el Excel"}
      widthLarge={"550px"}
    />
  )
}

export default Payments
