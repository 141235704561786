import React from "react"

import { Button, Box, Typography } from "@material-ui/core"

import useStyles from "./styles"

const ButtonInsideBox = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      <div className={classes.divGreen}></div>
      <div className={classes.divImg}>
        <div className={classes.avatarBackground}>
          <img alt="" src={props.img} className={classes.avatar} />
        </div>
      </div>
      <Typography variant="h6" className={classes.typography}>
        {props.text}{" "}
      </Typography>
      <div className={classes.divButton}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => props.onClick()}
          color="primary"
          disabled={props.disabled}
          {...props}
        >
          {props.button}
        </Button>
      </div>
    </Box>
  )
}

export default ButtonInsideBox
