import React, { useEffect } from "react"
import ClientTemplate from "Components/UI/Templates/Sales/Clients"

const Clients = () => {
  useEffect(() => {
    document.title = "Clientes"
  }, [])
  return <ClientTemplate />
}

export default Clients
