import React, { useState } from 'react'
import { Form, Formik } from 'formik';
import InputsWithValidate from 'Components/UI/Molecules/InputsWithValidate';
import { conceptsForm } from 'Components/Validation/Settings/Various/concepts';

const ACTIVO_OPTIONS = [{
    label: 'SI',
    value: 1
  }, {
    label: 'NO',
    value: 0
},]

const TIPO_OPTIONS = [{
  nombre: 'GASTO',
  value: "Gasto",
  }, {
  nombre: 'INGRESO',
  value: 'Ingreso',
}]

const UpdateCreateForm = (props) => {
  const { concept, formRef } = props

  const [ data, setData ] = useState({
    nombre: concept ? concept.nombre : "",
    tipo: concept ? concept.tipo : "",
    activo: concept ? concept.activo : 1,
    descripcion: concept ? concept.descripcion : "",
  })

  const FormEdit = (values, setFieldValue) => {
    return [
      {
        class: "textfield",
        label: "Nombre concepto",
        name: "nombre",
        value: values.nombre,
        disabled: false,
        sm: 12,
        xs: 12,
      },
      {
        label: "Tipo",
        name: "tipo",
        options: TIPO_OPTIONS,
        value: data.tipo,
        onChange: (tipo) => {
          setData({
            ...data,
            tipo: tipo.value
          })
          setFieldValue('tipo', tipo.value)
        },
        optionLabel: `options["nombre"]`,
        optionValue: "value",
        sm: 12,
        xs: 12,
        class: "autocomplete",
      },
      {
        label: "Activo",
        name: "activo",
        options: ACTIVO_OPTIONS,
        onChange: (activo) => {
          setFieldValue('activo', activo.value)
          setData({
            ...data,
            activo: activo.value
          })
        },
        value: data.activo,
        optionLabel: `options["label"]`,
        optionValue: "value",
        sm: 12,
        xs: 12,
        class: "autocomplete",
      },
      {
        class: "textfield",
        label: "Descripción",
        name: "descripcion",
        value: values.descripcion,
        disabled: false,
        sm: 12,
        xs: 12,
      },
    ];
  }

  const onSubmit = (data) => {
    if (data.activo === "SI") {
      data.activo = 1
    }

    if (data.activo === "NO") {
      data.activo = 0
    }

    props.onSubmit(data)
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={data}
      validationSchema={conceptsForm}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <InputsWithValidate
            fields={FormEdit(values, setFieldValue)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={false}
          />

          <button
            style={{ display: "none" }}
            type="submit"
            ref={formRef}
          />
        </Form>
      )}
    </Formik>
  )
}

export default UpdateCreateForm
