import React from "react"

import { Button } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const CancelEdit = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const onSubmit = () => {
    props.handleclose(true)
    props.handleClosePay()
    createDismissableSnackbar("Los cambios han sido descartados!", {
      variant: "warning",
    })
  }
  const Buttons = () => {
    return (
      <>
        <Button
          onClick={props.handleclose}
          style={{ textTransform: "initial" }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ textTransform: "initial" }}
        >
          Aceptar
        </Button>
      </>
    )
  }

  return (
    <div>
      <DialogComponent
        open={props.open}
        title="Tiene cambios sin guardar, ¿desea salir?"
        subtitle={"Todos sus cambios se perderán!"}
        widthLarge={"450px"}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "15px",
            }}
          >
            <Buttons />
          </div>
        }
      />
    </div>
  )
}

export default CancelEdit
