const LIST = "/settings/vehicletypes/list"
const CREATE = "/settings/vehicletypes/create"
const UPDATE = "/settings/vehicletypes/update"
const DELETE = "/settings/vehicletypes/delete"

export const typeVehicleEndpoints = {
    LIST,
    CREATE,
    UPDATE,
    DELETE
}