export const centerStyles = (theme) => ({
  paper: {
    width: "100%",
    position: "absolute",
    borderRadius: "4px 4px 4px 4px",
    zIndex: "99999",
    border: ({ border }) => (border ? "2px #3d92ff solid" : ""),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: ({ widthLarge }) => (widthLarge ? widthLarge : "400px"),
    },
  },
  title: {
    color: "#6F6F6F",
    padding: ({ paddingReduce }) => (paddingReduce ? "0px 24px" : "16px 24px"),
  },
  dialogContent: {
    padding: ({ paddingReduce }) => (paddingReduce ? "0px 24px" : "8px 24px"),
  },
  dialogActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
})
