import React, { useState } from "react"

import PrintIcon from "@material-ui/icons/Print"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"

import MuiDatables from "Components/UI/Atoms/Table"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import { columnTheme } from "./styles"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import DialogMoreInfo from "Components/UI/Organisms/Plant/Classification/DialogMoreInfo"
import Loader from "Components/UI/Atoms/Loader"
import { printPDF } from "Helpers"
import EditContextProvider from "Components/Hooks/ContextEdit"
import SetState from "Components/Hooks/SetState"
import CustomButton from "Components/UI/Atoms/CustomButton"

const Table = (props) => {
  const { render, setRender, search, setSearch } = props

  const { state, setOpen, setClose } = SetState()

  const [isLoading, setIsLoading] = useState(false)
  const [info, setInfo] = useState({
    rows: [],
    header: [],
  })

  const createDismissableSnackbar = useCustomSnackbar()

  async function printId(id, e) {
    e.stopPropagation()
    setIsLoading(true)
    await interfaces.plantInterfaceClassification
      .stockSticker(id)
      .then((res) => {
        printPDF(res).print()
        createDismissableSnackbar("Sticker impreso con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantInterfaceClassification.getInfoTable(
        options,
        search
      )
      setInfo({
        rows: result.data.data,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {
      createDismissableSnackbar(error.response.data.data, {
        variant: "error",
      })
      setSearch(null)
    }
  }

  async function handleOpenDialogInfo(id) {
    interfaces.plantInterfaceClassification
      .stockProductData(id)
      .then((res) => {
        setOpen({ dataObject: res.data })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  function handleRender() {
    setRender(!render)
  }

  const actions = (id) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        <Tooltip title="Imprimir">
          <IconButton
            style={{ color: "black" }}
            onClick={(e) => printId(id, e)}
            size="small"
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  async function selected(rows) {
    setIsLoading(true)
    let ids = []
    for (let i = 0; i < rows.length; i++) {
      ids.push(info.rows[rows[i].index].id)
    }
    await interfaces.plantInterfaceClassification
      .setAsDuplicated(ids)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleRender()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  const custonToolbar = (selectedRows) => {
    return (
      <div style={{ marginRight: "10px" }}>
        <CustomButton
          text="Duplicado"
          onClick={() => selected(selectedRows.data)}
        />
      </div>
    )
  }

  const RowsVehicles = () =>
    info.rows.map((file) => [
      "",
      file.codigo,
      file.producto,
      file.vehiculo,
      file.motor,
      file.oblea,
      file.nrodev,
      file.calidad,
      file.estado,
      <b>{file.ubicacion}</b>,
      actions(file.id),
      rowSelectable(file),
    ])

  const columns = columnsForTable(
    [
      {
        name: "codigo",
        label: "Código",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
        marginLeft: "10px",
      },
      {
        name: "producto",
        label: "Producto",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "vehiculo",
        label: "Vehiculo",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "motor",
        label: "Motor",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "oblea",
        label: "Oblea",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "nrodev",
        label: "Nº V",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "calidad",
        label: "Calidad",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
      },
      {
        display: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    []
  )

  const rowSelectable = (dataIndex) => {
    return info.rows[dataIndex]
      ? info.rows[dataIndex].estado !== "Papelera"
      : true
  }

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <DialogMoreInfo
            open={state.open}
            handleClose={setClose}
            handleRender={handleRender}
            data={state.dataObject}
          />
        </EditContextProvider>
      )}

      {isLoading && <Loader />}

      <MuiDatables
        columns={columns}
        data={RowsVehicles()}
        title={`Total de piezas: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        searchEnabled={false}
        filterEnabled={false}
        onRowClick={(_, index) =>
          handleOpenDialogInfo(info.rows[index.dataIndex].id)
        }
        padding="none"
        rows={200}
        isRowSelectable={rowSelectable}
        selectableRows={true}
        customToolbarSelect={custonToolbar}
      />
    </>
  )
}
export default Table
