import React from "react"
import { useWindowSize } from "react-use"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import SearchTable from "Components/UI/Atoms/Table/Search"
import ApplyFilters from "Components/UI/Atoms/Table/Filter"

function Options(functions) {
  const {
    render,
    filters,
    setRender,
    setSort,
    setSearch,
    setOffset,
    setFilters,
    isSearching,
    count,
    textPlaceholder,
    searchEnabled = true,
    expandableRows = false,
    filterEnabled = true,
    renderExpandableRow,
    selectableRows = "none",
    customToolbarSelect,
    customToolbar,
    padding,
    isRowSelectable,
    setRowProps,
    rows,
    onRowClick,
    searchFirst,
  } = functions

  let listOfFilters = ""

  // eslint-disable-next-line
  const { width, height } = useWindowSize()

  const mediumViewport = useMediaQuery("(min-width:600px)")

  return {
    onRowClick: onRowClick,
    isRowSelectable: isRowSelectable,
    count: count,
    search: searchEnabled,
    expandableRows: expandableRows,
    renderExpandableRow: renderExpandableRow,
    expandableRowsHeader: false,
    selectableRows: selectableRows,
    customToolbarSelect: (selectedRows) => customToolbarSelect(selectedRows),
    filter: filterEnabled,
    filterType: "dropdown",
    print: false,
    filterArrayFullMatch: true,
    responsive: "standard",
    download: false,
    rowsPerPageOptions: [],
    viewColumns: false,
    tableBodyHeight:
      height -
      (mediumViewport
        ? !filters
          ? 197
          : 237
        : document.title === "Stock"
        ? 376
        : 230),
    tableBodyMaxHeight:
      height -
      (mediumViewport
        ? !filters
          ? 197
          : 237
        : document.title === "Stock"
        ? 376
        : 230),
    rowsPerPage: rows ? rows : 25,
    sort: true,
    serverSide: true,
    setRowProps: setRowProps,
    textLabels: {
      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
        filter: {
          title: "Filtros",
          reset: "Resetear",
        },
      },
      toolbar: {
        filter: "Filtros",
        filterTable: "Filtros",
        search: "Buscador rápido",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      body: {
        noMatch: isSearching
          ? "Buscando resultados..."
          : !searchFirst
          ? "No se encontraron resultados"
          : "Realice alguna búsqueda primero!",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      selectedRows: {
        text: "pedido(s) seleccionado(s)",
      },
    },
    setTableProps: () => {
      return {
        size: "small",
        padding,
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setOffset(tableState.page)
          setRender(!render)
          break
        case "sort":
          if (
            tableState.sortOrder.direction !== "none" &&
            Object.keys(tableState.sortOrder).length > 0
          ) {
            setSort({
              orderBy: tableState.sortOrder.name,
              order: tableState.sortOrder.direction,
            })
            setOffset(0)
            setRender(!render)
          } else {
            setSort({ orderBy: "id", order: "desc" })
            setOffset(0)
            setRender(!render)
          }
          break
        case "onSearchClose":
          setOffset(0)
          setSearch()
          break
        default:
          break
      }
    },
    onFilterChange: (column, filterList, type, index) => {
      if (
        (type === "chip" || type === "custom") &&
        filterList[index].length > 0
      ) {
        listOfFilters = listOfFilters.concat(
          `&${column.name}=${filterList[index][0]}`
        )
        setFilters(listOfFilters)
        setOffset(0)
        setRender(!render)
      }
      if (
        (type === "chip" || type === "custom") &&
        typeof column === "string"
      ) {
        let filtersSplitted = filters.split("&")
        let indexToDelete = filtersSplitted.findIndex((element) =>
          element.includes(column)
        )
        let filtersDeleted = filtersSplitted.splice(indexToDelete, 1)
        let newFilters = filters.replace("&" + filtersDeleted, "")
        setFilters(newFilters)
        setOffset(0)
        setRender(!render)
      }
    },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      function confirmSearch(text) {
        if (text === undefined || text === "") {
          hideSearch()
        } else {
          setOffset(0)
          setSearch(text)
          setRender(!render)
        }
      }

      function discardSearch(text) {
        if (text === undefined) {
          hideSearch()
        } else {
          setOffset(0)
          hideSearch()
          setSearch()
          setRender(!render)
        }
      }
      return (
        <SearchTable
          confirmSearch={confirmSearch}
          discardSearch={discardSearch}
          textPlaceholder={textPlaceholder}
        />
      )
    },
    confirmFilters: true,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return <ApplyFilters applyNewFilters={applyNewFilters} />
    },
    customToolbar: customToolbar,
  }
}

export { Options }
