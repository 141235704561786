import React from "react"

import { Dialog, Typography } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "./styles"

const Loader = () => {
  const classes = useStyles()

  return (
    <div>
      <Dialog open={true} disableBackdropClick disableEscapeKeyDown>
        <Typography align="center" variant="h6" className={classes.typography}>
          AGUARDE!
        </Typography>
        <Typography align="center" variant="subtitle2">
          Esto puede demorar unos instantes
        </Typography>
        <div className={classes.div}>
          <CircularProgress color="primary" className={classes.circular} />
        </div>
      </Dialog>
    </div>
  )
}

export default Loader
