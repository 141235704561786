import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 658,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "linear-gradient(45deg, #00867E 30%, #019966  90%)",
  },
  link: {
    color: "gray",
  },
  FormUpLogin: {
    height: 72,
  },
  FormDownLogin: {
    height: 58,
  },
  TextRemovePass: {
    height: 72,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  FormRemovePass: {
    height: 58,
  },
  TextFormBack: {
    height: 86,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  FormFormBack: {
    height: 68,
  },
  FormButtonBack: {
    height: 20,
  },
  heightContentSpinner: {
    height: "250px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  errorDiv: {
    position: "relative",
    height: "0px",
    color: "red",
    bottom: "20px",
  },
  errorText: {
    bottom: "10px",
    display: "flex",
    justifyContent: "center",
  },
}))

export default useStyles
