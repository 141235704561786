import React, { createContext, useState } from "react"

// eslint-disable-next-line
export default ({ children }) => {
  const [initialData, setInitialData] = useState({})
  const [formEdit, setFormEdit] = useState({})
  const [formApi, setFormApi] = useState({})

  return (
    <FormContext.Provider
      value={{
        formEdit,
        setFormEdit,
        formApi,
        setFormApi,
        initialData,
        setInitialData,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const FormContext = createContext()
