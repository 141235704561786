import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  cancelButton: {
    textTransform: "initial",
    marginRight: "10px",
  },
  confirmButton: {
    textTransform: "initial",
  },
  infoIcon: {
    marginTop: "3px",
    color: "rgba(0, 0, 0, 0.7)",
  },
  alertMessage: {
    color: "rgba(0, 0, 0, 0.7)",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  actionDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "15px",
    marginBottom: "10px",
  },
  alertDiv: {
    width: "58%",
    marginRight: "10px",
    marginLeft: "12px",
    backgroundColor: "rgba(51, 181, 229, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
  },
  buttonsMovile: {
    display: "flex",
    alignItems: "left",
  },
}))

export default useStyles
