const EXPORT_ALL_TRANSITS = "/vehicles/vehicletransit/transitlist"
const ONE_TRANSIT = "/vehicles/vehicletransit"
const DELETE_TRANSIT = "/vehicles/vehicletransit"
const EXPORT_TRANSIT = "/vehicles/vehicletransit/exports"
const NEW_TRANSIT = "/vehicles/vehicletransit/newtransit"
const SEARCH_VEHICLE = "/vehicles/vehicletransit/searchvehicle"

export const vehicletransitEndpoints = {
  EXPORT_ALL_TRANSITS,
  ONE_TRANSIT,
  DELETE_TRANSIT,
  EXPORT_TRANSIT,
  NEW_TRANSIT,
  SEARCH_VEHICLE,
}
