import * as yup from "yup"

export const newStorageValidation = yup.object({
  almacen: yup.number().required("Requerido").nullable(),
  nivel: yup.number().required("Requerido").nullable(),
  estanteria: yup
    .number()
    .nullable()
    .when(["bin"], {
      is: (bin) => !bin || bin.length < 1,
      then: yup.number().required("Requerido").nullable(),
    }),
  columna: yup
    .number()
    .nullable()
    .when(["bin"], {
      is: (bin) => !bin || bin.length < 1,
      then: yup.number().required("Requerido").nullable(),
    }),
  fila: yup
    .number()
    .nullable()
    .when(["bin"], {
      is: (bin) => !bin || bin.length < 1,
      then: yup.number().required("Requerido").nullable(),
    }),
  bin: yup.string().nullable(),
})
