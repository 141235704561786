import * as yup from "yup"

export const newRowTableUpdate = yup.object({
  producto_id: yup.number().required("Requerido").nullable(),
  ult_act: yup.date().required("Requerido").nullable(),
  tc_cambio: yup.number().required("Requerido").nullable(),
  prox_act: yup.date().required("Requerido").nullable(),
})

export const editRowTableUpdate = yup.object({
  tc_cambio: yup.number().required("Requerido").nullable(),
  prox_act: yup.date().required("Requerido").nullable(),
  // referencia: yup.string().required("Requerido").nullable(),
})
