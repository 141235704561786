import React, { useEffect } from "react"
import QuotationsTemplate from "Components/UI/Templates/Vehicles/Quotations"

const Quotations = () => {
  useEffect(() => {
    document.title = "Cotizaciones"
  }, [])
  return <QuotationsTemplate />
}

export default Quotations
