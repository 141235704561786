import React, { useState } from "react"
import { read, utils } from "xlsx"

import { Grid, Typography } from "@material-ui/core"

import CustomButton from "Components/UI/Atoms/CustomButton"
import { ButtonInputXls } from "Components/UI/Atoms/ButtonInputXls"
import BasicTable from "Components/UI/Atoms/BasicTable"
import Loader from "Components/UI/Atoms/Loader"

const DialogUpdateXlsx = ({ dataXlsx, setDataXlsx }) => {
  const [loading, setLoading] = useState(false)

  const readUploadFile = (e) => {
    setLoading(true)
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)
        setDataXlsx(json)
        setLoading(false)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Grid container>
        {dataXlsx.length > 0 ? (
          <BasicTable
            col={[
              { title: "Código", style: { width: "10%" } },
              { title: "Producto", style: { width: "15%" } },
              { title: "Vehículo", style: { width: "15%" } },
              { title: "Ubicación", style: { width: "20%" } },
              { title: "Estado", style: { width: "15%" } },
            ]}
            stylePaper={{
              height: "600px",
              overflow: "auto",
            }}
            size={"small"}
            rows={dataXlsx.map((row) => {
              return {
                codigo: row.codigo,
                producto: row.producto,
                vehiculo: row.vehiculo,
                ubicacion_new: (
                  <>
                    {row.ubicacion_new === row.ubicacion_old ? (
                      row.ubicacion_new
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            textDecoration: "line-through",
                            marginRight: "10px",
                          }}
                        >
                          {row.ubicacion_old}
                        </div>

                        <div>{row.ubicacion_new}</div>
                      </div>
                    )}
                  </>
                ),
                estado: (
                  <>
                    {row.estado_new === row.estado_old ? (
                      row.estado_new
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div style={{ textDecoration: "line-through" }}>
                          {row.estado_old}
                        </div>
                        <div style={{ fontWeight: "bold" }}>
                          {row.estado_new}
                        </div>
                      </div>
                    )}
                  </>
                ),
              }
            })}
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12} md={12}>
              <CustomButton
                text="Excel de referencia"
                fullWidth
                color=""
                href="https://ecoautoparts-prod-frontend-bucket.s3.amazonaws.com/assets/varios/actualizacion_masiva_stock.xlsx"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <CustomButton
                text="Datos de referencia"
                fullWidth
                color=""
                href="https://ecoautoparts-prod-frontend-bucket.s3.amazonaws.com/assets/varios/ubicaciones_y_estados_de_piezas.xlsx"
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Typography align="center">
                Descargar Excel de referencia, insertar los ID a editar y
                utilizar los datos de referencia para colocar los nuevos estados
                y/o ubicaciones (se puede dejar alguno vacío)
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <ButtonInputXls
                name="xls"
                textLater="EXCEL IMPORTADO"
                textBefore="Subir excel"
                value={dataXlsx}
                id={1}
                onChange={readUploadFile}
                colorBefore="primary"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default DialogUpdateXlsx
