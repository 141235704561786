//user interface, call of axios with sources
import { axiosService } from "../../../index"
import { vehicletransitEndpoints } from "../../../sources"

const listAllTransits = (options) => {
  return axiosService.get(vehicletransitEndpoints.EXPORT_ALL_TRANSITS + options)
}

const infoFromOneTransit = (id) => {
  return axiosService.get(vehicletransitEndpoints.ONE_TRANSIT + "/" + id)
}

const deleteTransit = (id) => {
  return axiosService.del(vehicletransitEndpoints.ONE_TRANSIT + "/" + id)
}

const exportTransit = (params) => {
  return axiosService.get(vehicletransitEndpoints.EXPORT_TRANSIT, params)
}

const newTransit = (body, config) => {
  return axiosService.post(vehicletransitEndpoints.NEW_TRANSIT, body)
}

const searchVehicle = (value) => {
  return axiosService.get(vehicletransitEndpoints.SEARCH_VEHICLE + "/" + value)
}

const updateTransit = (id, body) => {
  return axiosService.put(vehicletransitEndpoints.ONE_TRANSIT + "/" + id, body)
}

export const transitInterface = {
  listAllTransits,
  infoFromOneTransit,
  deleteTransit,
  exportTransit,
  newTransit,
  searchVehicle,
  updateTransit,
}
