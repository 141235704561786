import React, { useEffect, useState, useRef } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import SetState from "Components/Hooks/SetState"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { useSnackbar } from "notistack"
import { interfaces } from "service/interfaces"
import UpdateCreateForm from "./UpdateCreateForm"

const ConceptsContent = () => {
  const [concepts, setConcepts] = useState([])
  const [valueSearch, setValueSearch] = useState("")
  const [selectedConcept, setSelectedConcept] = useState(null)
  const [render, setRender] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    state: createConcept,
    setOpen: setOpenCreate,
    setClose: setCloseCreate,
  } = SetState()
  const {
    state: editConcept,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const formSubmitRef = useRef(null)

  const getConcepts = async () => {
    await interfaces.conceptsInterface
      .getConcepts()
      .then((res) => {
        setConcepts(res.data)
      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  const onUpdateConcept = async (data) => {
    const id = selectedConcept?.id

    await interfaces.conceptsInterface
      .updateConcept(id, data)
      .then((res) => {
        setRender(!render)
        setCloseEdit()
        setSelectedConcept(null)
        enqueueSnackbar(res.data, { variant: "success" })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.data, {
          variant: "error",
        })
      })
  }

  const onCreateConcept = async (data) => {
    await interfaces.conceptsInterface
      .createConcept(data)
      .then((res) => {
        setRender(!render)
        setCloseCreate()
        setSelectedConcept(null)
        enqueueSnackbar(res.data, { variant: "success" })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.data, {
          variant: "error",
        })
      })
  }

  const onChangeSearch = (e) => {
    setValueSearch(e.target.value.toLowerCase())
  }

  const search = (data) => {
    return data.filter((concept) => {
      return concept.nombre.toLowerCase().includes(valueSearch)
    })
  }

  const onClickItem = (item) => {
    setSelectedConcept(item)
  }

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  useEffect(() => {
    getConcepts()
  }, [render])

  return (
    <>
      <InputSearch onClick={setOpenCreate} onChange={onChangeSearch} />
      <PaperList
        children={
          <ListWithIcon
            data={search(concepts)}
            nameIncludes="nombre"
            selectedItem={selectedConcept?.id}
            onClickSelect={onClickItem}
            onClickEdit={setOpenEdit}
          />
        }
        height="500px"
        styles={{ marginBottom: "15px" }}
      />

      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        widthLarge="25vw"
        title="Crear concepto"
        open={createConcept.open}
        handleclose={setCloseCreate}
        children={
          <UpdateCreateForm
            onSubmit={onCreateConcept}
            formRef={formSubmitRef}
          />
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleSubmit}
            onClickCancel={setCloseCreate}
          />
        }
        maxHeight
      />

      {editConcept.suspense && (
        <DialogComponent
          disableBackdropClick
          disableEscapeKeyDown
          widthLarge="25vw"
          title="Editar concepto"
          open={editConcept.open}
          handleclose={setCloseEdit}
          children={
            <UpdateCreateForm
              onSubmit={onUpdateConcept}
              formRef={formSubmitRef}
              concept={selectedConcept}
            />
          }
          actions={
            <CancelConfirmButtons
              onClickSubmit={handleSubmit}
              onClickCancel={setCloseEdit}
            />
          }
          maxHeight
        />
      )}
    </>
  )
}

export default ConceptsContent
