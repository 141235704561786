import React from "react"

import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"

import useStyles from "./styles"

const InputSearch = (props) => {
  const {
    fullWidth = true,
    onChange,
    value,
    children,
    onKeyDown,
    placeholder
  } = props

  const classes = useStyles()

  return (
    <div className={classes.grow}>
      <TextField
        fullWidth={fullWidth}
        size="small"
        id="search"
        onKeyDown={onKeyDown ? onKeyDown : null}
        variant="outlined"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {children ? <div style={{ marginLeft: "15px" }}>{children}</div> : null}
    </div>
  )
}

export default InputSearch
