import ReactImageGallery from "react-image-gallery"
import { titles2 } from "Helpers"
export const formFirstStep = (item, ref, setFullImg) => {
  return [
    {
      class: "custom",
      component: (
        <ReactImageGallery
          ref={ref}
          items={titles2(item.allPhotos)}
          onScreenChange={(e) => setFullImg({ fullScreen: e })}
          onClick={(e) => ref.current.fullScreen()}
          useBrowserFullscreen={false}
          showBullets={true}
          showPlayButton={false}
          lazyLoad={true}
          showThumbnails={false}
          onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
        />
      ),
      xs: 12,
      sm: 12,
    },
    {
      class: "textfield",
      name: "comentario",
      label: "Observación ECOSOFT",
      value: item.comentario_ecosoft,
      md: 12,
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "chasis",
      label: "Nº de chasis",
      value: item.chasis_nro,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "nromotor",
      label: "Nº de motor",
      value: item.motor_nro,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "motor",
      label: "Motor",
      value: item.motor_marca,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "combustible",
      label: "Combustible",
      value: item.combustible,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "transmision",
      label: "Transmisión",
      value: item.transmision,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "traccion",
      label: "Tracción",
      value: item.traccion,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "puertas",
      label: "Puertas",
      value: item.puertas,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "color",
      label: "Color",
      value: item.color,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "titulo",
      label: "Título",
      value: item.modelo_titulo,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "ingreso",
      label: "Fecha ingreso",
      value: item.fecha_ingreso,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "baja",
      label: "Ingreso baja",
      value: item.fecha_baja,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
    {
      class: "textfield",
      name: "tramite",
      label: "Fin trámite",
      value: item.fecha_tramite,
      md: 6,
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
    },
  ]
}

export const engineForm = (data) => {
  let distribucionMap = {
    1: "CADENA",
    2: "CORREA",
    3: "ENGRANAJES",
  }

  let posicionMotorMap = {
    1: "LINEAL",
    2: "TRANSVERSAL",
  }

  let valvulasComandoMap = {
    1: "DOHC",
    2: "SOHC",
    3: "OHV",
  }

  let combustibleMap = {
    1: "NAFTA",
    2: "DIÉSEL",
  }

  let turboMap = {
    1: "SI",
    0: "NO",
  }

  let aceleradorMap = {
    1: "CABLE",
    2: "ELECTRÓNICO",
  }

  return [
    {
      disabled: true,
      class: "textfield",
      label: "Código de motor",
      value: data.codigo,
      name: "codigo",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Nombre del motor",
      name: "nombre",
      value: data.nombre,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "CV",
      name: "cv",
      value: data.cv,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "KW",
      name: "kw",
      value: (data.cv / 1.341).toFixed(2),
      sm: 6,
      xs: 12,
    },
    {
      id: 5,
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Válvulas",
      name: "valvulas",
      value: data.valvulas,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Comando V.",
      name: "valvulas_comando",
      value: valvulasComandoMap[parseInt(data.valvulas_comando)] || "",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Cilindrada",
      name: "cilindrada",
      value: data.cilindrada,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Cilindros",
      name: "cilindros",
      value: data.cilindros,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Combustible",
      name: "combustible",
      value: combustibleMap[parseInt(data.combustible)] || "",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      name: "turbo",
      label: "Turbo",
      value: turboMap[parseInt(data.turbo)] || "",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Alimentación",
      name: "alimentacion",
      value: data.alimentacion,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Acelerador",
      name: "acelerador",
      value: aceleradorMap[parseInt(data.acelerador)] || "",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Distribución",
      name: "distribucion",
      value: distribucionMap[parseInt(data.distribucion)] || "",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Posición motor",
      name: "motor_posicion",
      value: posicionMotorMap[parseInt(data.motor_posicion)] || "",
      sm: 6,
      xs: 12,
    },
  ]
}
