import React, { useContext } from "react"

import { Grid, Button } from "@material-ui/core"
import { Formik, Field } from "formik"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField } from "formik-material-ui"
import TextFieldMaterial from "@material-ui/core/TextField"

import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { CITIES } from "Constants"

const Holder = (props) => {
  const [edit] = useContext(EditContext)
  const { formEdit, setFormEdit, formApi, setFormApi, initialData } =
    useContext(FormContext)
  const provincias = formEdit.selectInputs.provincias

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          [e.target.name]: e.target.value,
        },
      },
    })

    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        [e.target.name]: e.target.value,
      },
    })
  }

  const getValues = () => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          t_apellido_nombre: formEdit.vehicle.vehiculo_retiro.a_apellido_nombre,
          t_contacto: formEdit.vehicle.vehiculo_retiro.a_contacto,
          t_telefono: formEdit.vehicle.vehiculo_retiro.a_telefono,
          t_correo: formEdit.vehicle.vehiculo_retiro.a_correo,
          t_domicilio: formEdit.vehicle.vehiculo_retiro.a_domicilio,
          t_provincia_id: formEdit.vehicle.vehiculo_retiro.a_provincia_id,
          t_localidad_id: formEdit.vehicle.vehiculo_retiro.a_localidad_id,
          t_detalle: formEdit.vehicle.vehiculo_retiro.a_detalle,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        t_apellido_nombre: formEdit.vehicle.vehiculo_retiro.a_apellido_nombre,
        t_contacto: formEdit.vehicle.vehiculo_retiro.a_contacto,
        t_telefono: formEdit.vehicle.vehiculo_retiro.a_telefono,
        t_correo: formEdit.vehicle.vehiculo_retiro.a_correo,
        t_domicilio: formEdit.vehicle.vehiculo_retiro.a_domicilio,
        t_provincia_id: formEdit.vehicle.vehiculo_retiro.a_provincia_id,
        t_localidad_id: formEdit.vehicle.vehiculo_retiro.a_localidad_id,
        t_detalle: formEdit.vehicle.vehiculo_retiro.a_detalle,
      },
    })
  }

  const disabledField = () => {
    if (initialData.header.authorization === 1 && edit) {
      return true
    }
    if (initialData.header.authorization === 1 && !edit) {
      return true
    } else return edit ? false : true
  }

  return (
    <div>
      <Formik enableReinitialize>
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              variant="outlined"
              name="t_apellido_nombre"
              disabled={disabledField()}
              fullWidth
              label="Apellido y nombre"
              value={formEdit.vehicle.vehiculo_retiro.t_apellido_nombre}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              disabled={disabledField()}
              variant="outlined"
              fullWidth
              name="t_contacto"
              label="Contacto"
              value={formEdit.vehicle.vehiculo_retiro.t_contacto}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              type="number"
              component={TextField}
              disabled={disabledField()}
              fullWidth
              variant="outlined"
              label="Telefono"
              name="t_telefono"
              value={formEdit.vehicle.vehiculo_retiro.t_telefono}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              disabled={disabledField()}
              fullWidth
              variant="outlined"
              label="Correo electronico"
              value={formEdit.vehicle.vehiculo_retiro.t_correo}
              name="t_correo"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              disabled={disabledField()}
              fullWidth
              variant="outlined"
              label="Domicilio"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              value={formEdit.vehicle.vehiculo_retiro.t_domicilio}
              name="t_domicilio"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={provincias.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                  return 1
                }
                if (a.nombre < b.nombre) {
                  return -1
                }
                return 0
              })}
              getOptionLabel={(provincias) => provincias.provincia}
              disabled={disabledField()}
              defaultValue={provincias.find(
                (value) =>
                  value.id ===
                  parseInt(formEdit.vehicle.vehiculo_retiro.t_provincia_id)
              )}
              onChange={(event, value) => {
                handleChange({
                  target: { name: "t_provincia_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Provincia"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              noOptionsText={"No se encontraron resultados"}
              options={CITIES.sort(function (a, b) {
                if (a.localidad > b.localidad) {
                  return 1
                }
                if (a.localidad < b.localidad) {
                  return -1
                }
                return 0
              }).filter(
                (e) =>
                  e.provincia_id ===
                  formEdit.vehicle.vehiculo_retiro.t_provincia_id
              )}
              getOptionLabel={(CITIES) =>
                CITIES.localidad + " - CP: " + CITIES.cp
              }
              disabled={disabledField()}
              defaultValue={CITIES.find(
                (value) =>
                  value.id ===
                  parseInt(formEdit.vehicle.vehiculo_retiro.t_localidad_id)
              )}
              onChange={(event, value) => {
                handleChange({
                  target: { name: "t_localidad_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Localidad"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              disabled={disabledField()}
              fullWidth
              variant="outlined"
              label="Detalles del domicilio"
              value={formEdit.vehicle.vehiculo_retiro.t_detalle}
              name="t_detalle"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              component={TextField}
              disabled={disabledField()}
              fullWidth
              variant="outlined"
              label="Observaciones"
              value={formEdit.vehicle.vehiculo_retiro.t_observaciones}
              name="t_observaciones"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={disabledField()}
              color="primary"
              variant="contained"
              onClick={getValues}
            >
              Traer datos de asegurado
            </Button>
          </Grid>
        </Grid>
      </Formik>
    </div>
  )
}
export default Holder
