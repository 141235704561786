import React, { useEffect } from "react"
import StockMovementTemplate from "Components/UI/Templates/Plant/StockMovement"

const StockMovement = () => {
  useEffect(() => {
    document.title = "Movimiento de stock"
  }, [])

  return <StockMovementTemplate />
}
export default StockMovement
