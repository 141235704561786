import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { vehicleInterface } from "service/interfaces/vehicles/searchvehicles"

const DeleteSubmit = (props) => {
  const {
    handleclose,
    closeBigDialog,
    setRenderTable,
    renderTable,
    open,
    id,
    idActive,
  } = props
  const [loading, setLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async () => {
    setLoading(true)

    await vehicleInterface
      .deleteVehicle(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "warning",
        })
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })

    setRenderTable(!renderTable)
    closeBigDialog()
    handleclose(true)

    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        title={
          idActive === 1
            ? "Desea eliminar/deshabilitar este vehículo?"
            : "Desea restaurar este vehículo?"
        }
        widthLarge={"550px"}
        handleclose={handleclose}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleclose}
              onClickSubmit={onSubmit}
              submitText="Aceptar"
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default DeleteSubmit
