export const formFirstStep = (data) => {
  return [
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      sm: 12,
      xs: 12,
      value: data.data.vehiculo,
      disabled: true,
    },
    {
      class: "textfield",
      name: "empresa",
      label: "Empresa",
      value: data.data.empresa,
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "codigo",
      value: data.data.codigo,
      label: "Código",
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "pieza",
      label: "Pieza",
      value: data.data.pieza,
      sm: 12,
      xs: 12,
      disabled: true,
    },
  ]
}

export const formThirdStep = (data, handleChangeAutoComplete) => {
  return [
    {
      class: "autocomplete",
      name: "operario_id",
      label: "Operario",
      options: data.operarios,
      style: {
        height: "80px",
        marginTop: "7px",
      },
      sortBy: "nombre",
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      getOptionDisabled: (option) => !option.activo,
      disabledOptions: data.operarios
        .filter((e) => e.activo !== 1)
        .map((e) => e.id),
      onChange: (e) =>
        handleChangeAutoComplete({
          operario_id: e.id,
        }),
    },
    {
      class: "textfield",
      name: "observaciones",
      label: "Observaciones",
      value: data.observaciones,
      onChange: (e) =>
        handleChangeAutoComplete({
          observaciones: e.target.value,
        }),
      disabled: false,
      sm: 12,
      xs: 12,
      multiline: true,
      rows: 10,
      maxRows: 10,
    },
  ]
}
