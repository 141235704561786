import React from "react"

import {
  Grid,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core"

const Step2 = (props) => {
  const { initValues, setInitValues } = props

  const setNew = (array) => {
    setInitValues({ ...initValues, products: array })
  }

  const handleChange = (e, position) => {
    const clonedData = [...initValues.products]
    clonedData[position].checked = e.target.checked
    setNew(clonedData)
  }
  function compare(item) {
    if (!item.grupo_colocado) return false
    return initValues.products
      .filter((e) => e.grupo_colocado === item.grupo_colocado)
      .some((e) => e.checked === true && e.producto !== item.producto)
  }
  return (
    <Grid container>
      <Table style={{ tableLayout: "fixed" }} size="small">
        <TableBody>
          {initValues.products.map((item, index) => (
            <TableRow onClick={(e) => handleChange(e, index, item.id)}>
              <TableCell style={{ width: "10%" }}>
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => handleChange(e, index, item.id)}
                  disabled={compare(item)}
                />
              </TableCell>
              <TableCell align="left" style={{ width: "20%" }}>
                {item.producto}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default Step2
