import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Typography } from "@material-ui/core"

import { styles } from "./styles"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)

const ComplexDialogHistory = (props) => {
  const { history } = props
  const classes = styles()

  return (
    <div className={classes.table}>
      <Table style={{ tableLayout: "fixed" }}>
        <TableBody>
          {history?.map((row) => (
            <StyledTableRow>
              <StyledTableCell width={"80%"}>
                <Typography className={classes.descripcion}>
                  {row.descripcion}
                </Typography>
              </StyledTableCell>
              <StyledTableCell width={"20%"} align="right">
                {row.fecha}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ComplexDialogHistory
