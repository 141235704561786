import React from "react"
import { IconButton } from "@material-ui/core"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import useStyles from "./styles"

const ButtonChangePhoto = (props) => {
  const { id, changePhoto, handleRender } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const classes = useStyles()
  function handleSubmit(e) {
    let value = e.target.files[0]
    if (value !== null) {
      interfaces.configInterface
        .changePhoto(value, id)
        .then((res) => {
          createDismissableSnackbar(`${res.data.msg}`, {
            variant: "success",
          })
          changePhoto(res.data.url)
          handleRender()
        })
        .catch(() => {
          createDismissableSnackbar("Algo salió mal!", {
            variant: "error",
          })
        })
    }
  }

  return (
    <>
      <form>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            name="file"
            type="file"
            onChange={(e) => {
              handleSubmit(e)
            }}
          />
          <label htmlFor="raised-button-file">
            <IconButton component="span" className={classes.iconButton}>
              <AddAPhotoIcon
                style={{
                  color: "white",
                }}
              />
            </IconButton>
          </label>
        </div>
      </form>
    </>
  )
}

export default ButtonChangePhoto
