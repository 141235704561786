import React, { forwardRef } from "react"

import { Grid } from "@material-ui/core"

import SettingsHomeUser from "Components/UI/Organisms/Settings/Users/UsersDialog/Content/Home/SettingsHomeUser"
import HomeForm from "Components/UI/Organisms/Settings/Users/UsersDialog/Content/Home/HomeForm"

import useStyles from "./styles"

const Home = forwardRef((props) => {
  const { data, setData, render, setRender, onClose } = props
  const classes = useStyles()
  const handleChange = (name, value) => {
    setData({
      ...data,
      data: { ...data.data, [name]: value },
    })
  }

  return (
    <Grid container justifyContent="center" className={classes.home}>
      <Grid item sm={4} xs={12} style={{ margin: "15px" }}>
        <SettingsHomeUser
          render={render}
          setRender={setRender}
          data={data}
          setData={setData}
          onClose={onClose}
        />
      </Grid>
      <Grid item sm={6} xs={12} style={{ margin: "10px" }}>
        <HomeForm data={data} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
})

export default Home
