import { Typography } from "@material-ui/core"
import SiempleUploadFile from "Components/UI/Atoms/SimpleUploadFile"
import SimpleUploadImage from "Components/UI/Atoms/SimpleUploadImage"
import { COLORS, FUEL, TRANSSMISION, TIPO, TRACTION } from "Constants"

export const FORM_VEHICLE = (initValues, info, handleChange) => {
  const date = `10/31/${initValues.año}`

  return [
    {
      class: "autocomplete",
      name: "marca_id",
      label: "Marca",
      sm: 12,
      xs: 12,
      value: initValues.marca_id,
      options: info.data.brands,
      optionLabel: `options["marca"]`,
      optionValue: "id",
      onChange: (e) => {
        handleChange("marca_id", e.id)
      },
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "modelo_id",
      label: "Modelo",
      value: initValues.modelo_id,
      options: info.data.models.filter(
        (e) => e.marca_id === initValues.marca_id
      ),
      optionLabel: `options["modelo"]`,
      optionValue: "id",
      onChange: (e) => {
        handleChange("modelo_id", e.id)
      },
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "version_id",
      label: "Versión",
      value: initValues.version_id,
      options: info.data.versions.filter(
        (e) => e.modelo_id === initValues.modelo_id
      ),
      optionLabel: `options["version"] +" ("+ options["inicio"] +"/"+ options["fin"] +")"`,
      optionValue: "id",
      onChange: (e) => {
        handleChange("version_id", e.id)
      },
    },
    {
      class: "datepicker",
      name: "año",
      views: ["year"],
      label: "Año",
      format: "yyyy",
      value: initValues.año === null ? null : date,
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange("año", e)
      },
    },
    {
      class: "autocomplete",
      name: "motor_id",
      label: "Tipo motor",
      options: info.data.motores,
      optionLabel: `options["codigo"]`,
      optionValue: "id",
      value: initValues.motor_id,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("motor_id", e.id)
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "transmision",
      label: "Transmisión",
      options: TRANSSMISION,
      optionLabel: `options["TRANSSMISION"]`,
      optionValue: "TRANSSMISION",
      value: initValues.TRANSSMISION,
      onChange: (e) => {
        handleChange("transmision", e.TRANSSMISION)
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "combustible",
      label: "Combustible",
      options: FUEL,
      optionLabel: `options["combustible"]`,
      optionValue: "combustible",
      value: initValues.combustible,
      onChange: (e) => {
        handleChange("combustible", e.combustible)
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "color",
      label: "Color",
      options: COLORS,
      optionLabel: `options["color"]`,
      optionValue: "color",
      value: initValues.color,
      onChange: (e) => {
        handleChange("color", e.color)
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "traccion",
      label: "Tracción",
      options: TRACTION,
      optionLabel: `options["TRACTION"]`,
      optionValue: "TRACTION",
      value: initValues.traccion,
      onChange: (e) => {
        handleChange("traccion", e.TRACTION)
      },
    },
    {
      class: "textfield",
      name: "puertas",
      label: "Puertas",
      type: "number",
      onChange: (e) => handleChange("puertas", parseInt(e.target.value)),
      value: Math.max(0, parseInt(initValues.puertas) || 0),
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "nro_motor",
      label: "Nº de Motor",
      onChange: (e) => handleChange("nro_motor", e.target.value),
      value: initValues.nro_motor,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "nro_chasis",
      label: "Nº de Chasis",
      onChange: (e) => handleChange("nro_chasis", e.target.value),
      value: initValues.nro_chasis,
      sm: 12,
      xs: 12,
    },
  ]
}

export const FORM_PRODUCT = (
  info,
  initValues,
  handleChange,
  handleChangeProduct
) => {
  return [
    {
      class: "autocomplete",
      name: "tipo",
      label: "Tipo",
      options: TIPO,
      getOptionDisabled: (options) => options.label !== "USADO",
      disabledOptions: ["NUEVO", "REACONDICIONADO"],
      optionLabel: `options["label"]`,
      optionValue: "value",
      sm: 12,
      xs: 12,
      value: initValues.tipo,
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "producto_id",
      label: "Producto",
      options: info.data.productos,
      optionLabel: `options["producto"]`,
      optionValue: "id",
      value: initValues.producto_id,
      onChange: (e) => handleChangeProduct(e.id, e.conf_oblea),
    },
    {
      class: "autocomplete",
      name: "calidad",
      label: "Calidad",
      options: info.data.calidades,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      value: initValues.calidad,
    },
    {
      class: "autocomplete",
      name: "ubicacion_id",
      label: "Almacén",
      options: info.data.ubicaciones,
      optionLabel: `options["nombre"]`,
      getOptionDisabled: (options) => options.id === 6,
      disabledOptions: [6],
      optionValue: "id",
      sm: 12,
      xs: 12,
      value: initValues.ubicacion_id,
    },
    {
      class: "textfield",
      name: "oblea",
      label: "Oblea",
      type: "number",
      onChange: (e) => handleChange("oblea", parseInt(e.target.value)),
      disabled: initValues.conf_oblea ? false : true,
      value: initValues.oblea,
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <Typography align="center">
          Al ingresar un valor de oblea, esta pieza será creada en el servidor
          ECO. Caso contrario será creada en el servidor OEM.
        </Typography>
      ),
      sm: 12,
      xs: 12,
    },
  ]
}

export const FORM_FINALIZE = (
  handleChange,
  handleChangeUpload,
  handleChangeFile,
  initValues
) => {
  return [
    {
      class: "textfield",
      name: "comentarios",
      label: "Comentarios",
      multiline: true,
      rows: 4,
      sm: 12,
      xs: 12,
      onChange: (e) => handleChange("comentarios", e.target.value),
    },
    {
      class: "custom",
      component: (
        <SimpleUploadImage
          handleChange={handleChangeUpload}
          change={initValues.fotos}
          text="Subir fotografías"
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <SiempleUploadFile
          handleChange={handleChangeFile}
          change={initValues.archivos}
          text="Subir documentación"
        />
      ),
      sm: 12,
      xs: 12,
    },
  ]
}
