import React from "react"

import { Button } from "@material-ui/core/"

import useStyles from "../styles"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"

const ButtonDelete = (props) => {
  const { idTransit, setRender, render, closeModal } = props

  const { state, setClose } = SetState()

  const createDismissableSnackbar = useCustomSnackbar()
  const classes = useStyles()

  const deleteVehicle = () => {
    interfaces.transitInterface
      .deleteTransit(idTransit)
      .then(() => {
        createDismissableSnackbar("Tránsito eliminado con éxito!", {
          variant: "success",
        })
        setRender(!render)
      })
      .finally(closeModal(), setRender(!render))
  }

  return (
    <div>
      <Button
        onClick={setClose}
        variant="contained"
        className={classes.buttonDelete}
        disabled={props.disabled}
      >
        Eliminar
      </Button>
      <DialogComponent
        open={state.open}
        handleclose={setClose}
        widthLarge="500px"
        disableChildHeight
        title="¿Desea eliminar este tránsito de vehículo?"
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "15px",
            }}
          >
            <Button
              style={{ textTransform: "capitalize" }}
              onClick={setClose}
            >
              Cancelar
            </Button>
            <Button
              style={{ textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={deleteVehicle}
            >
              Confirmar
            </Button>
          </div>
        }
      />
    </div>
  )
}

export default ButtonDelete
