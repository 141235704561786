import React from "react"

import GetAppIcon from "@material-ui/icons/GetApp"

import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "Components/UI/Templates/Common"
import {
  Table,
  Disarm,
  Rudac,
} from "Components/UI/Organisms/Vehicles/SearchWafers"
import SetState from "Components/Hooks/SetState"

const TemplateSearchWafers = () => {
  const {
    state: openRudac,
    setOpen: setOpenRudac,
    setClose: setCloseRudac,
  } = SetState()
  const {
    state: openDisarm,
    setOpen: setOpenDisarm,
    setClose: setCloseDisarm,
  } = SetState()

  const BUTTONS = [
    {
      onClick: setOpenRudac,
      menuTitle: "Rudac",
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonContent: "Rudac",
      buttonStartIcon: <GetAppIcon />,
    },
    {
      onClick: setOpenDisarm,
      menuTitle: "Desarme",
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonStartIcon: <GetAppIcon />,
      buttonContent: "Desarme",
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Vehículos"
            subTitle="Listado de obleas"
            buttons={BUTTONS}
          />
        }
        table={<Table />}
      />

      {openRudac.suspense && (
        <Rudac open={openRudac} handleclose={setCloseRudac} />
      )}
      {openDisarm.suspense && (
        <Disarm open={openDisarm} handleclose={setCloseDisarm} />
      )}
    </>
  )
}

export default TemplateSearchWafers
