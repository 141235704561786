export const slideStyles = (theme) => ({
  paper: {
    height: "100vh",
    minHeight: "100vh",
    width: "100%",
    maxWidth: "400px",
    position: "absolute",
    borderRadius: "4px 0px 0px 4px",
    margin: 0,
    right: 0,
    zIndex: "99999",
    border: ({ border }) => (border ? "2px #3D92FF solid" : ""),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: ({ widthLarge }) => (widthLarge ? widthLarge : "400px"),
    },
  },
  title: {
    color: "#6F6F6F",
    padding: ({ paddingReduce }) => (paddingReduce ? "0px 24px" : "16px 24px"),
  },
  dialogContent: {
    padding: ({ paddingReduce }) => (paddingReduce ? "0px 24px" : "8px 24px"),
    [theme.breakpoints.down("xs")]: {
      height: ({ disableChildHeight }) =>
        disableChildHeight ? disableChildHeight : "auto",
    },
  },
  dialogActions: {
    position: "sticky",
    bottom: "0px",
    alignSelf: " flex-end",
    backgroundColor: "white",
    zIndex: "99999",
  },
})

export const centerStyles = (theme) => ({
  paper: {
    width: "100%",
    position: "absolute",
    borderRadius: "4px 0px 0px 4px",
    zIndex: "99999",
    border: ({ border }) => (border ? "2px #3d92ff solid" : ""),
    height: ({ customHeight }) => (customHeight ? customHeight : "auto"),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: ({ widthLarge }) => (widthLarge ? widthLarge : "400px"),
    },
    [theme.breakpoints.only("xs")]: {
      minHeight: ({ maxHeight }) => (maxHeight ? "100vh" : "auto"),
    },
  },
  title: {
    color: "#6F6F6F",
    padding: ({ paddingReduce }) => (paddingReduce ? "0px 24px" : "16px 24px"),
  },
  dialogContent: {
    padding: ({ paddingReduce }) =>
      paddingReduce ? "0px 24px" : "8px 24px 8px",
    [theme.breakpoints.down("xs")]: {
      height: ({ disableChildHeight }) =>
        disableChildHeight ? disableChildHeight : "auto",
    },
  },
  dialogActions: {
    position: "sticky",
    bottom: "0px",
    alignSelf: " flex-end",
    backgroundColor: "white",
    zIndex: "99999",
    width: "100%",
  },
})
