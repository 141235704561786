import React, { forwardRef, memo } from "react"
import { useWindowSize } from "react-use"

import {
  DialogActions,
  DialogContent,
  Dialog,
  makeStyles,
  Slide,
} from "@material-ui/core"

import { slideStyles, centerStyles } from "./styles"
import DialogTitle from "./DialogTitle"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

const DialogComponent = forwardRef((props, ref) => {
  const { height } = useWindowSize()

  const {
    open,
    handleclose,
    handleclosedisabled,
    title,
    actions,
    children,
    border = false,
    paddingReduce = false,
    transform = true,
    tipo,
    widthLarge,
    customHeight,
    disableBackdropClick,
    disableEscapeKeyDown,
    subtitle,
    loading,
    id,
    maxHeight,
    disableChildHeight = height - 62 - 96,
  } = props

  // eslint-disable-next-line
  const useStyles = makeStyles(tipo === "slide" ? slideStyles : centerStyles)

  const classes = useStyles({
    border,
    paddingReduce,
    transform,
    widthLarge,
    customHeight,
    maxHeight,
    disableChildHeight,
  })

  const fieldProps = {
    disableBackdropClick,
    disableEscapeKeyDown,
    id,
  }

  if (tipo === "slide") {
    fieldProps.TransitionComponent = Transition
  }

  return (
    <div ref={ref}>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open}
        onClose={handleclose}
        {...fieldProps}
      >
        <DialogTitle
          className={classes.title}
          loading={loading}
          handleClose={handleclose}
          subtitle={subtitle}
          handleclosedisabled={handleclosedisabled}
        >
          {title}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {children}
        </DialogContent>
        {actions ? (
          <DialogActions classes={{ root: classes.dialogActions }}>
            {React.cloneElement(actions, { handleclose })}
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  )
})

export default memo(DialogComponent)
