import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    height: "552px",
    width: "900px",
  },
  cardSelectImg: {
    height: "240px",
    width: "200px",
    padding: "1em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
}))
