import React from "react"
import { Drawer } from "@material-ui/core"
import SideBarItemList from "Components/UI/Molecules/SideBar"
import useStyle from "./styles"

const DrawerSideBar = (props) => {
  const { variant, open, onClose } = props
  const classes = useStyle()
  return (
    <Drawer
      variant={variant}
      open={open}
      onClose={onClose ? onClose : null}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <SideBarItemList />
    </Drawer>
  )
}

export default DrawerSideBar
