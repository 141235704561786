import * as yup from "yup"

export const newUserInsurance = yup.object().shape({
  username: yup.string().required("Este campo es requerido"),
  nombre: yup.string().required("Este campo es requerido"),
  apellido: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .email("Debe ser una dirección de correo electrónico válida")
    .required("Este campo es requerido"),
})
