import React, { useEffect } from "react"

import Home from "Components/UI/Templates/Common/Home"

const Inicio = () => {
  useEffect(() => {
    document.title = "Inicio"
  }, [])

  return <Home />
}

export default Inicio
