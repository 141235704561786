import React, { useState } from "react"
import { useEffectOnce } from "react-use"

import PrintIcon from "@material-ui/icons/Print"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton, Typography } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined"
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import ModalTableEdit from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/"
import MuiDatables from "Components/UI/Atoms/Table"
import Loader from "Components/UI/Atoms/Loader"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"
import { columnTheme } from "./styles"
import { TIPO_BAJA } from "Constants"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import { printPDF } from "Helpers"
import SetState from "Components/Hooks/SetState"
import EditContextProvider from "Components/Hooks/ContextEdit"
import FormContextProvider from "Components/Hooks/ContextForm"

const Table = (props) => {
  const { render, setRender, setActiveButtons } = props

  const { state, setState, setClose } = SetState()

  const windowSize = useMediaQuery("(min-width:1000px)")

  const [info, setInfo] = useState({
    rows: [],
    header: [],
  })
  const [filters, setFilters] = useState({})

  const createDismissableSnackbar = useCustomSnackbar()

  useEffectOnce(() =>
    interfaces.vehicleInterface.tableFilters().then((result) => {
      setFilters(result.data.filters)
      setActiveButtons({
        quotation: result.data.quotation,
        export: result.data.export,
        create: result.data.create,
        payments: result.data.payments,
      })
    })
  )

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.vehicleInterface.vehicleList(options)
      setInfo({
        rows: result.data,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  async function printId(id, e) {
    e.stopPropagation()
    setState({ loading: true })
    await interfaces.vehicleInterface
      .vehicleSticker(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      })
      .catch(() =>
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      )
    setState({ loading: false })
  }

  const openModal = (id) => {
    setState({ id, open: true })
  }

  const actions = (id) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        <Tooltip title="Imprimir">
          <IconButton
            style={{ color: "black" }}
            onClick={(e) => printId(id, e)}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const tooltipsStates = (id, estado) => {
    switch (id) {
      case 1:
      case 2:
        return (
          <Tooltip title={estado}>
            <FiberManualRecordOutlinedIcon
              style={{
                color: "black",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
          </Tooltip>
        )
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return (
          <Tooltip title={estado}>
            <WarningTwoToneIcon
              style={{
                color: "#F1C40F",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
          </Tooltip>
        )
      case 8:
        return (
          <Tooltip title={estado}>
            <InfoIcon
              style={{
                color: "green",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
          </Tooltip>
        )
      case 9:
      case 10:
        return (
          <Tooltip title={estado}>
            <RemoveCircleIcon
              style={{ color: "red", marginLeft: "10px", marginRight: "10px" }}
            />
          </Tooltip>
        )
      case 11:
      case 12:
        return (
          <Tooltip title={estado}>
            <CancelIcon
              style={{ color: "red", marginLeft: "10px", marginRight: "10px" }}
            />
          </Tooltip>
        )
      default:
        return []
    }
  }

  const typographyState = (pagado, dominio) => {
    return (
      <Typography style={pagado ? { color: "green", fontWeight: "bold" } : {}}>
        {dominio} {pagado && "($)"}
      </Typography>
    )
  }

  const RowsVehicles = () =>
    info.rows.map((file) => [
      "",
      tooltipsStates(file.estado_id, file.estado),
      file.tipo,
      typographyState(file.pagado, file.dominio),
      file.cia_seguros,
      file.siniestro,
      file.nrodev,
      file.tipo_baja,
      file.marca,
      file.modelo,
      file.version ? file.version : " ",
      file.año,
      file.ubicacion,
      actions(file.vehiculo_id),
    ])

  const columns = columnsForTable(
    [
      {
        name: "estado",
        label: " ",
        filterLabel: "Estado",
        display: true,
        filter: true,
        sort: false,
        filterName: "descripcion",
        options: filters.estados,
        marginLeft: windowSize ? "10px" : "",
      },
      {
        name: "tipo",
        label: "Tipo de vehículo",
        filterLabel: "Tipo",
        display: false,
        filter: true,
        sort: false,
        filterName: "nombre",
        options: filters.tipos,
      },
      {
        name: "dominio",
        label: "Dominio",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "cia_seguros",
        label: "Compañía",
        display: true,
        filter: true,
        sort: true,
        filterName: "nombre",
        options: filters.compañias,
        customBodyRender: true,
      },
      {
        name: "nro_siniestro",
        label: "Siniestro",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "nrodev",
        label: "Nº V",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "tipo_baja",
        label: "Baja",
        display: true,
        filter: true,
        sort: true,
        filterName: "baja",
        customBodyRender: true,
        options: TIPO_BAJA,
      },
      {
        name: "marca",
        label: "Marca",
        display: true,
        filter: true,
        sort: false,
        filterName: "marca",
        options: filters.marcas,
        customBodyRender: true,
      },
      {
        name: "modelo",
        label: "Modelo",
        display: true,
        filter: true,
        sort: false,
        customBodyRender: true,
        advancedOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Modelo"}
                  filterName={"modelo"}
                  noOptionsText={"Seleccione una marca primero"}
                  options={filters.modelos.filter((item) => {
                    return item.marca.marca === filterList[8][0]
                  })}
                />
              )
            }
          },
        },
      },
      {
        name: "version",
        label: "Versión",
        display: false,
        filter: false,
        sort: false,
        filterName: "version",
        customBodyRender: true,
      },
      {
        name: "año",
        label: "Año",
        display: true,
        filter: false,
        sort: true,
        filterName: "version",
        customBodyRender: true,
      },
      {
        name: "ubicacion",
        label: "Ubicación",
        display: true,
        filter: true,
        sort: true,
        filterName: "nombre",
        options: filters.ubicacion,
        customBodyRender: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    filters
  )

  return (
    <>
      <EditContextProvider>
        <FormContextProvider>
          <ModalTableEdit
            open={state.open}
            id={state.id}
            onClose={setClose}
            setRenderTable={setRender}
            renderTable={render}
          />
        </FormContextProvider>
      </EditContextProvider>

      <MuiDatables
        columns={columns}
        data={RowsVehicles()}
        title={`Total de vehículos: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar dominio, siniestro o Nº de V..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        onRowClick={(_, index) =>
          openModal(info.rows[index.dataIndex].vehiculo_id)
        }
        padding={windowSize ? "none" : ""}
      />
      {state.loading ? <Loader /> : null}
    </>
  )
}
export default Table
