import {
  CITIES,
  PROVINCIAS,
  TIPO_BAJA,
  COLORS,
  FUEL,
  TRANSSMISION,
  TRACTION,
} from "Constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import es from "date-fns/locale/es";

import moment from "moment";
import { InputAdornment } from "@material-ui/core";
import { AttachMoney } from "@material-ui/icons";

export function USUALLY(data, handleChange, edit, fields) {
  return [
    {
      name: "dominio",
      label: "Dominio",
      class: "textfield",
      value: data.dominio,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("dominio", e.target.value);
      },
      disabled: !edit,
    },
    {
      name: "nro_siniestro",
      label: "Nº de siniestro",
      class: "textfield",
      value: data.nro_siniestro,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("nro_siniestro", e.target.value);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "cia_seguros_id",
      label: "Compañia",
      defaultValue: fields.compañia.find((v) => v.id === data.cia_seguros_id),
      options: fields.compañia,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("cia_seguros_id", e.id);
      },
      disabled: !edit,
    },
    {
      
      name: "tipo",
      label: "Tipo de cotización",
      class: "autocomplete", 
      value: data.tipo,
      options: [
        {
          id: 0,
          label: "Directa",
        },
        {
          id: 1,
          label: "Compulsa",
        },
        {
          id: 2,
          label: "Preadjudicacion",
        },
      ],
      optionLabel: `options["label"]`,
      optionValue: "label",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("tipo", e.label);
      },
      disabled: true,
    },
    {
      class: "autocomplete",
      name: "tipo_baja_id",
      label: "Tipo de baja",
      value: data.tipo_baja_id,
      options: TIPO_BAJA,
      optionLabel: `options["baja"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("tipo_baja_id", "");
        handleChange("tipo_baja_id", valor.id);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      value: data.estado,
      sm: 3,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      type: "number",
      name: "monto_base",
      label: "Monto base",
      value: data.monto_base,
      sm: 3,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("monto_base", e.target.value);
      },
    },
    {
      name: "comentarios",
      label: "Comentarios",
      value: data.comentarios,
      multiline: true,
      rows: 6,
      class: "textfield",
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange("comentarios", e.target.value);
      },
      disabled: !edit,
    },
  ];
}

export function VEHICLE_DATA(data, handleChange, fields, edit, handleChangeMultiple) {
  const date = `10/31/${data.año}`
  return [
    {
      class: "autocomplete",
      name: "marca_id",
      label: "Marca",
      defaultValue: fields.marca.find((v) => v.id === data.marca_id),
      options: fields.marca,
      optionLabel: `options["marca"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChangeMultiple({
          marca_id: e.id,
          modelo_id: null,
          version_id: null
        });
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: data.modelo_id,
      label: "Modelo",
      defaultValue: fields.modelo.find((v) => v.id === data.modelo_id),
      options: fields.modelo.filter((e) => e.marca_id === data.marca_id),
      optionLabel: `options["modelo"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChangeMultiple({
          modelo_id: e.id,
          version_id: null
        });
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: data.version_id,
      label: "Versión",
      defaultValue: fields.version.find((v) => v.id === data.version_id),
      options: fields.version.filter((e) => e.modelo_id === data.modelo_id),
      optionLabel: `options["version"] +" ("+ options["inicio"] +"/"+ options["fin"] +")"`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e, reason) => {
        if (reason === "clear") return handleChange("version_id", null)
        handleChange("version_id", e.id);
      },
      disableClearable: true,
      disabled: !edit,
    },
    {
      class: "datepicker",
      name: "año",
      label: "Año",
      format: "yyyy",
      value: data.año === null ? null : date,
      views: ["year"],
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("año", e);
      },
      disabled: !edit,
    },
    {
      name: "kms",
      label: "Kilómetros",
      class: "textfield",
      type: "number",
      value: Math.max(0, parseInt(data.kms) || 0),
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("kms", e.target.value)
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "modelo_titulo",
      label: "Modelo segun título",
      value: data.modelo_titulo,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("modelo_titulo", e.target.value);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "vehiculo_tipo",
      label: "Tipo",
      value: data.vehiculo_tipo,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("vehiculo_tipo", e.target.value);
      },
      disabled: !edit,
    },
    {
      name: "color",
      label: "Color",
      class: "autocomplete",
      value: data.color,
      options: COLORS,
      optionLabel: `options["color"]`,
      optionValue: "color",
      sm: 3,
      xs: 12,
      onChange: (e) => {
        handleChange("color", e.color);
      },
      disabled: !edit,
    },
    {
      name: "puertas",
      label: "Puertas",
      class: "textfield",
      value: Math.max(0, parseInt(data.puertas) || 0),
      type: "number",
      sm: 3,
      xs: 12,
      onChange: (e) => {
        handleChange("puertas", e.target.value);
      },
      disabled: !edit,
    },
    {
      name: "fuel",
      label: "Combustible",
      class: "autocomplete",
      value: data.combustible,
      options: FUEL,
      optionLabel: `options["combustible"]`,
      optionValue: "combustible",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("combustible", e.combustible);
      },
      disabled: !edit,
    },
    {
      name: "transmision",
      label: "Transmisión",
      class: "autocomplete",
      value: data.transmision,
      options: TRANSSMISION,
      optionLabel: `options["TRANSSMISION"]`,
      optionValue: "TRANSSMISION",
      sm: 3,
      xs: 12,
      onChange: (e) => {
        handleChange("transmision", e.TRANSSMISION);
      },
      disabled: !edit,
    },
    {
      name: "traccion",
      label: "Tracción",
      class: "autocomplete",
      options: TRACTION,
      optionLabel: `options["TRACTION"]`,
      optionValue: "TRACTION",
      sm: 3,
      xs: 12,
      value: data.traccion,
      onChange: (e) => {
        handleChange("traccion", e.TRACTION);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "motor_id",
      label: "Motor",
      defaultValue: fields.motor.find((v) => v.id === data.motor_id),
      options: fields.motor,
      optionLabel: `options["codigo"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("motor_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "motor_marca_id",
      label: "Motor marca",
      defaultValue: fields.marca.find((v) => v.id === data.motor_marca_id),
      options: fields.marca,
      optionLabel: `options["marca"]`,
      optionValue: "id",
      sm: 3,
      xs: 12,
      onChange: (e) => {
        handleChange("motor_marca_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "chasis_marca_id",
      label: "Chasis marca",
      defaultValue: fields.marca.find((v) => v.id === data.chasis_marca_id),
      options: fields.marca,
      optionLabel: `options["marca"]`,
      optionValue: "id",
      sm: 3,
      xs: 12,
      onChange: (e) => {
        handleChange("chasis_marca_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "motor_nro",
      label: "Número motor",
      value: data.motor_nro,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("motor_nro", e.target.value);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "chasis_nro",
      label: "Número chasis",
      value: data.chasis_nro,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("chasis_nro", e.target.value);
      },
      disabled: !edit,
    },
  ];
}

export const RESUME = (data, handleChange, edit, payValues, handleChangeMultiple) => {
  return [
    {
      name: "cotizacion_restos",
      label: "Cotización restos (IVA inc.)",
      class: "textfield",
      value: data.cotizacion_restos,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("cotizacion_restos", e.target.value);
      },
      disabled: !edit || data.sin_costo,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      name: "cotizacion_retiro",
      label: "Cotización retiro (IVA inc.)",
      class: "textfield",
      value: data.cotizacion_retiro,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("cotizacion_retiro", e.target.value);
      },
      disabled: !edit,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      id: 7,
      label: "Provincia retiro",
      name: "provincia_id",
      options: PROVINCIAS,
      optionLabel: `options["provincia"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      class: "autocomplete",
      defaultValue: PROVINCIAS.find((e) => {
        return e.id === data.provincia_id;
      }),
      onChange: (e) => {
        handleChangeMultiple({
          provincia_id: e.id,
          localidad_id: null
        })
      },
      disabled: !edit,
    },
    {
      id: 7,
      label: "Localidad retiro",
      name: data.localidad_id,
      options: CITIES.filter((e) => e.provincia_id === data.provincia_id),
      optionLabel: `options["localidad"] +" - "+ options["cp"] `,
      optionValue: "id",
      sm: 6,
      xs: 12,
      class: "autocomplete",
      sortBy: "localidad",
      defaultValue: CITIES.find((e) => {
        return e.id === data.localidad_id;
      }),
      onChange: (e) => {
        handleChange("localidad_id", e.id);
      },
      disabled: !edit,
    },
    {
      label: "Total gastos",
      class: "textfield",
      value: payValues.totalGastos,
      disabled: true,
      sm: 4,
      xs: 12,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      label: "Total ingresos",
      class: "textfield",
      value: payValues.totalIngresos,
      disabled: true,
      sm: 4,
      xs: 12,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
    {
      label: "Costo total siniestro",
      class: "textfield",
      value: payValues.costoTotal,
      disabled: true,
      sm: 4,
      xs: 12,
      inputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoney />
          </InputAdornment>
        ),
      },
    },
  ];
};

export const PaymentForm = (payment) => {
  return [
    {
      label: "Proveedor",
      class: "textfield",
      value: payment.proveedor,
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      label: "Fecha factura",
      class: "textfield",
      value: payment.fecha_factura ? payment.fecha_factura.slice(0, 10) : "",
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      label: "Fecha pago",
      class: "textfield",
      value: payment.fecha_pago ? payment.fecha_pago.slice(0, 10) : "",
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      label: "Sucursal factura",
      class: "textfield",
      value: payment.suc_factura ?? "",
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      label: "Nº factura",
      class: "textfield",
      value: payment.nro_factura ?? "",
      sm: 6,
      xs: 12,
      disabled: true,
    },
    {
      label: "Monto total",
      class: "textfield",
      value: payment.monto_total ?? "",
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      label: "Observaciones",
      class: "textfield",
      value: payment.observaciones ?? "",
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      label: "tipo",
      class: "textfield",
      value: payment.tipo ?? "",
      sm: 12,
      xs: 12,
      disabled: true,
    },
  ];
};

export function DATES(data, handleChange, edit) {
  const receivedDate = data.fecha;
  const formatedDate = receivedDate.split("/").reverse().join("-");
  return [
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            key="fecha"
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("fecha", e);
            }}
            disabled={true}
            name="fecha"
            label="Fecha de creación"
            format="dd/MM/yyyy"
            value={data.fecha ? moment(formatedDate).local() : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 6,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            key="cotizado"
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("cotizado", e);
            }}
            disabled={true}
            name="cotizado"
            label="Fecha de cotización"
            format="dd/MM/yyyy"
            value={data.cotizado ? moment(data.cotizado).local() : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 6,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            key="vencimiento"
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("vencimiento", e);
            }}
            disabled={edit ? false : true}
            name="vencimiento"
            label="Fecha de vencimiento"
            format="dd/MM/yyyy"
            value={data.vencimiento ? moment(data.vencimiento).local() : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 6,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            key="enviado"
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("enviado", e);
            }}
            disabled={edit ? false : true}
            name="enviado"
            label="Fecha de envío de cotización"
            format="dd/MM/yyyy"
            value={data.enviado ? moment(data.enviado).local() : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 6,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("finalizado", e);
            }}
            disabled={true}
            name="finalizado"
            label="Fecha de resolución"
            format="dd/MM/yyyy"
            value={data.finalizado ? moment(data.finalizado).local() : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 12,
      xs: 12,
    },
  ];
}
