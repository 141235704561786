import { useState, useEffect } from "react"

export default function SetState() {
  let delay = 15

  const [state, editState] = useState({
    open: false,
    suspense: false,
    loading: false,
    dataObject: {},
    dataArray: [],
  })

  useEffect(() => {
    if (!state.open) {
      setTimeout(() => {
        editState({ ...state, suspense: false })
      }, 100)
    }
    // eslint-disable-next-line
  }, [state.open])

  const setState = (other) => {
    setTimeout(() => {
      editState({ ...state, ...other })
    }, delay)
  }

  const setOpen = (other) => {
    setTimeout(() => {
      editState({ ...state, open: true, suspense: true, ...other })
    }, delay)
  }

  const setClose = (other) => {
    setTimeout(() => {
      editState({ ...state, open: false, ...other })
    }, delay)
  }

  const setLoading = (value, other) => {
    setTimeout(() => {
      editState({ ...state, loading: value, ...other })
    }, delay)
  }

  const setObject = (data, other) => {
    setTimeout(() => {
      editState({ ...state, dataObject: data, ...other })
    }, delay)
  }

  const clearObject = (other) => {
    setTimeout(() => {
      editState({ ...state, dataObject: {}, ...other })
    }, delay)
  }

  const setArray = (data, other) => {
    setTimeout(() => {
      editState({ ...state, dataArray: data, ...other })
    }, delay)
  }

  const clearArray = (other) => {
    setTimeout(() => {
      editState({ ...state, dataArray: [], ...other })
    }, delay)
  }

  return {
    state,
    setState,
    setOpen,
    setClose,
    setLoading,
    setObject,
    clearObject,
    setArray,
    clearArray,
  }
}
