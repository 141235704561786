import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  buttonRed: {
    textTransform: "capitalize",
    color: "#fff",
    background: "grey",
    "&:hover": {
      background: "grey",
    },
  },
  butttonGreen: {
    textTransform: "capitalize",
    color: "primary",
  },
  fullWidth: {
    width: "100%",
  },
  divider: {
    padding: 0,
    background: "black",
  },

  paddingGrid: {
    padding: "10px 10px 10px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px 10px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px 10px 0px",
    },
  },

  contentImg: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px 10px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0px 10px 0px",
    },
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  divContentImg: {
    position: "static",
    width: "100%",
    height: "100%",
  },

  flex: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "2px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  btnVideo: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  divInsideVideo: {
    padding: "0px 0px 0px 6px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px 2px 2px 0px",
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px",
      margin: "0px 2px 2px 0px",
    },
  },

  btnRandoms: {
    padding: "0px 3px 0px 3px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "3px 0px 3px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "3px 0px 3px 0px",
    },
  },
  donwloadIcon: {
    position: "absolute",
    bottom: "10px",
    right: 60,
    zIndex: 1,
    height: " 50px",
    width: "50px",
    border: "none",
    background: theme.palette.primary.main,
    color: "white",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all linear 0.1s",
  },
}))

export default useStyles
