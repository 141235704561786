import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

import Inicio from "Components/UI/Organisms/Settings/Various/Transport/TransportContent/ContentItemList/NavTabs/Inicio"
import Drivers from "./Drivers"
import Vehicles from "./Vehicles"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function NavTabs(props) {
  const { data, index, idSelected, changeRender, setCanClose } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
          <LinkTab label="Inicio" href="/drafts" {...a11yProps(0)} />
          <LinkTab label="Choferes" href="/trash" {...a11yProps(1)} />
          <LinkTab label="Vehículos" href="/spam" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} style={{ padding: "25px" }}>
        <Inicio
          data={data}
          index={index}
          idSelected={idSelected}
          changeRender={changeRender}
          setCanClose={setCanClose}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Drivers
          idSelected={idSelected}
          changeRender={changeRender}
          index={index}
          data={data}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Vehicles
          idSelected={idSelected}
          changeRender={changeRender}
          index={index}
          data={data}
        />
      </TabPanel>
    </div>
  )
}
