const LIST = "/vehicles/quotations/list"
const BEFORE_CREATE = "/vehicles/quotations/before"
const CREATE_QUOTATION = "/vehicles/quotations/create"
const PRINT = "/vehicles/quotations/print"
const ONE_QUOTATION = "/vehicles/quotations/quote/"
const UPDATE_QUOTE = "/vehicles/quotations/update/"
const DELETE_QUOTE = "/vehicles/quotations/delete/"
const PRINT_QUOTE = "/vehicles/quotations/print/"
const EXPORT = "/vehicles/quotations/export"
const CONFIRM_QUOTE = "/vehicles/quotations/confirm/"
const DECLINE_QUOTE = "/vehicles/quotations/decline/"
const UPLOAD_MAINPHOTOS = "vehicles/quotations/mainphotos/"
const DELETE_MAINPHOTO = "vehicles/quotations/deletemainphoto/"
const UPLOAD_PHOTO = "vehicles/quotations/photos/"
const UPLOAD_FILE = "vehicles/quotations/files/"
const DELETE_PHOTO = "vehicles/quotations/deletephoto/"
const DELETE_FILE = "vehicles/quotations/deletefile/"
const RESTORE_QUOTE = "/vehicles/quotations/restore/"
const DUPLICATE = "/vehicles/quotations/duplicate/"

export const quotationsEndpoints = {
  LIST,
  BEFORE_CREATE,
  CREATE_QUOTATION,
  PRINT,
  ONE_QUOTATION,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  PRINT_QUOTE,
  EXPORT,
  CONFIRM_QUOTE,
  DECLINE_QUOTE,
  UPLOAD_MAINPHOTOS,
  DELETE_MAINPHOTO,
  UPLOAD_PHOTO,
  UPLOAD_FILE,
  DELETE_PHOTO,
  DELETE_FILE,
  RESTORE_QUOTE,
  DUPLICATE,
}
