import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableFooter,
  Tooltip,
  withStyles,
} from "@material-ui/core"

import { formatter, sortArrayObjectsByProperty } from "Helpers"
import { useStyles } from "./styles"
import { useWindowSize } from "react-use"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const TableUpdate = (props) => {
  const { rows: data, col, size = "medium", title } = props
  const { width, height } = useWindowSize()
  const classes = useStyles({ width, height })
  const [click, onClick] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [_, setRows] = useState(data)
  const sortTable = (index) => {
    if (index === 0) {
      setRows(sortArrayObjectsByProperty(data, Object.keys(data[0])[0], click))
      onClick(!click)
    }
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Table size={size} stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {col.map(({ title, style }, index) => (
                <TableCell style={style} onClick={() => sortTable(index)}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <StyledTableRow>
                  <Tooltip
                    title={<div style={{ padding: "5px" }}>{row.pieza}</div>}
                    arrow
                  >
                    <TableCell placement="bottom-start">
                      <div className={classes.pieza}>{row.pieza}</div>
                    </TableCell>
                  </Tooltip>

                  <TableCell style={{ width: "0%" }}>
                    {row.cantidad_productos}
                  </TableCell>
                  <TableCell>{row.ult_act}</TableCell>
                  <TableCell>{formatter.format(row.tc_cambio)}</TableCell>
                  <TableCell>{row.prox_act}</TableCell>

                  <Tooltip
                    title={
                      <div style={{ fontSize: "15px", padding: "5px" }}>
                        {row.referencia}
                      </div>
                    }
                    arrow
                  >
                    <TableCell
                      classes={{ tooltip: classes.customWidth }}
                      placement="bottom-start"
                    >
                      <div className={classes.referencia}>{row.referencia}</div>
                    </TableCell>
                  </Tooltip>

                  <TableCell>{row.edit}</TableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
        <TableFooter className={classes.tableFooter}>
          <TableRow>
            <TableCell style={{ width: width }}>
              <div className={classes.tableCellFooter}>{title}</div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Paper>
    </>
  )
}
export default TableUpdate
