import React, { useState } from "react"
import SwipeableViews from "react-swipeable-views"

import { useTheme } from "@material-ui/core/styles"
import {
  Typography,
  IconButton,
  MobileStepper,
  CardMedia,
} from "@material-ui/core"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { bindKeyboard } from "react-swipeable-views-utils"
import { useStyles } from "./styles"

function SwipeableTextMobileStepper(props) {
  const { index, swipes, title } = props
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(index - 1)
  const maxSteps = swipes.length

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }
  const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews)
  return (
    <div className={classes.root}>
      <BindKeyboardSwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        enableKeyEvents
      >
        {swipes.map((step, index) => (
          <>
            {step.name ? (
              <Typography align="center" className={classes.titleImgSwipe}>
                {step.name ? step.name : title}
              </Typography>
            ) : null}
            {Math.abs(activeStep - index) <= 2 ? (
              <>
                <div className={classes.divImgSwipe} key={step.id}>
                  <CardMedia
                    component="img"
                    className={classes.img}
                    image={step.img}
                    alt={step.name}
                  />
                </div>
              </>
            ) : null}
          </>
        ))}
      </BindKeyboardSwipeableViews>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttons}>
          <IconButton
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
            className={classes.button}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className={classes.button}
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </div>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        className={classes.stepper}
        backButton={<div />}
        nextButton={<div />}
      />
    </div>
  )
}

export default SwipeableTextMobileStepper
