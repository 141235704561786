import React, { useState } from "react"
import BarcodeReader from "react-barcode-reader"

import { Grid, Typography } from "@material-ui/core"
import InputButton from "Components/UI/Atoms/InputButton"
import BasicTable from "Components/UI/Atoms/BasicTable"
import {
  validateManual,
  validateCodigo,
  validateVehicle,
} from "Components/Validation/Plant/Despatch"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

export default function Articles(props) {
  const createDismissableSnackbar = useCustomSnackbar()
  const { id, rows, pushItem, deleteItem } = props
  const [loading, setLoading] = useState({
    codigo: false,
    manual: false,
    vehiculo: false,
  })

  async function handleSubmit(values) {
    let name = Object.keys(values)[0]
    setLoading({ ...loading, [name]: true })
    let body = {
      tipo: name,
      codigo: values[name],
    }
    await interfaces.plantDespatch
      .addDetail(id, body)
      .then((res) => {
        pushItem(res.data)
        setLoading({ ...loading, [name]: false })
      })
      .catch((error) => {
        setLoading({ ...loading, [name]: false })
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  function scanCode(data) {
    handleSubmit(data)
  }

  async function deleteDetail(id) {
    await interfaces.plantDespatch
      .deleteDetail(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        deleteItem(id)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const iconDelete = (id) => (
    <IconButton onClick={() => deleteDetail(id)}>
      <CloseIcon />
    </IconButton>
  )

  return (
    <div>
      <BarcodeReader onScan={scanCode} />

      <Grid container spacing={1}>
        <Grid item sm={4} xs={12}>
          <InputButton
            onSubmit={handleSubmit}
            name="codigo"
            initialValues={{ codigo: "" }}
            validationSchema={validateCodigo}
            placeholder="Código de stock"
            loading={loading.codigo}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputButton
            onSubmit={handleSubmit}
            name="manual"
            initialValues={{ manual: "" }}
            validationSchema={validateManual}
            placeholder="Descripción manual"
            autoFocus={false}
            loading={loading.manual}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputButton
            onSubmit={handleSubmit}
            name="vehiculo"
            initialValues={{ vehiculo: "" }}
            validationSchema={validateVehicle}
            placeholder={"Dominio / Nro. de V"}
            autoFocus={false}
            loading={loading.vehiculo}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography variant="h6">
            Cantidad de artículos en el remito: {rows.length}
          </Typography>
        </Grid>

        <Grid item sm={12} xs={12}>
          <div style={{ overflow: "auto", height: "435px" }}>
            <BasicTable
              col={[
                { title: "Cant.", style: { width: "60px" } },
                { title: "Código", style: { width: "25%" } },
                { title: "Artículo", style: { width: "60%" } },
                { title: "", style: { width: "100px" } },
              ]}
              size={"small"}
              rows={rows.map((row) => {
                return {
                  articulo: row.cantidad,
                  codigo: row.codigo,
                  title: (
                    <div>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "30" }}
                        variant="subtitle1"
                      >
                        {row.descripcion}
                      </Typography>
                      {row.detalle && row.detalle !== "" ? (
                        <Typography
                          style={{ fontSize: "14px" }}
                          variant="subtitle1"
                        >
                          {row.detalle}
                        </Typography>
                      ) : null}
                      {row.detalle2 && row.detalle2 !== "" ? (
                        <>
                          <Typography
                            style={{ fontSize: "14px" }}
                            variant="subtitle1"
                          >
                            <b>ORIGEN: </b>
                            {row.detalle2}
                          </Typography>
                        </>
                      ) : null}
                    </div>
                  ),
                  action: iconDelete(row.id),
                }
              })}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
