import React, { useState, useEffect } from "react"

import Form from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const DialogWafer = (props) => {
  const { open, id, onClose, infoRow } = props

  const [isLoading, setIsLoading] = useState(false)
  const [oneInfo, setOneInfo] = useState([])

  useEffect(() => {
    async function apiCall() {
      setIsLoading(true)
      await interfaces.wafersInterface
        .oneVehicleWafer(id)
        .then((res) => setOneInfo(res.data))
      setIsLoading(false)
    }
    apiCall()
    // eslint-disable-next-line
  }, [])
  return (
    <div>
      {isLoading ? null : (
        <DialogComponent
          handleclose={onClose}
          open={open}
          title={
            <>
              {oneInfo.oblea} <b>{oneInfo.pieza}</b>
            </>
          }
          subtitle={
            <>
              Dominio:
              <b> {oneInfo.dominio}</b> - Número de V: <b> {infoRow.nrodev}</b>
            </>
          }
          widthLarge="500px"
          maxHeight
          children={<Form oneWafer={oneInfo} infoRow={infoRow} />}
          actions={
            <CancelConfirmButtons
              onClickCancel={onClose}
              submitText="Ir al comprobante"
              disabledSubmit={true}
            />
          }
        />
      )}
    </div>
  )
}
export default DialogWafer
