import React, { useState, useContext } from "react"
import { EditContext } from 'Components/Hooks/ContextEdit'
import ExitToApp from "@material-ui/icons/ExitToApp"

import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import HomeContent from "Components/UI/Organisms/Sales/Suppliers/Update/Home"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"

const Edit = (props) => {
  const { open, handleclose, data, setRender, render, handleRender, setLoading, dataBefore } = props
  
  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose } = SetState()
  const [edit] = useContext(EditContext)

  const [dataInfo, setDataInfo] = useState({
    ...data,
    data: {
      ...data.info,
    },
  })
 
  const onDataChange = (newData) => {
    setDataInfo((prevDataInfo) => ({
      ...prevDataInfo,
      details: newData,
    }))
  }

  const handleDelete = async () => {
    setLoading(true)
    await interfaces.salesSuppliers
      .deleteSupplier(data.info.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
    setClose()
  }

  const toggleStatus = async () => {
    setLoading(true)
    await interfaces.salesSuppliers
    .toggleStatus(data.info.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
    setClose()
  }

   const handleSubmit = async () => {
     await interfaces.salesSuppliers
       .update(data.info.id, dataInfo.data)
       .then((res) => {
         createDismissableSnackbar(res.data, {
           variant: "success",
         })
         setRender(!render)
         handleclose()
         if (handleRender) {
           handleRender()
         }
       })
  }

  const horizontalTabs = [
    {
      id: 0,
      title: "Inicio",
      color: "primary",
    },
    {
      id: 1,
      title: "Historial",
      color: "primary",
    },
  ]

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <HomeContent
            data={dataInfo}
            setData={setDataInfo}
            toggleStatus={toggleStatus}
            edit={edit}
            dataBefore={dataBefore}
            onDataChange={onDataChange}
            setRender={setRender}
            render={render}
          />
        ) 
      case 1:
        return <ComplexDialogHistory history={data.history} />
      default:
        break
    }
  }

  const date = dataInfo.info.creacion.split('T')[0]

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleclose}
        tabs={horizontalTabs}
        title={`Proveedor ${dataInfo.info.id} - ${dataInfo.info.proveedor}`}
        subtitle={
          <div>
            {`Creado el ${date}`}
          </div>
        }
        children={renderTabs}
        actions={
          <ComplexDialogActions
            onClose={handleclose}
            handleSubmit={() => handleSubmit()}
            canEdit={dataInfo.canEdit}
            data={dataInfo}
            editMode={true}
            leftButton={{
              icon: <ExitToApp fontSize="small" />,
              title: "Eliminar",
              variant: "contained",
              disabled:  false ,
              style: {background: "rgb(244, 67, 54)", color: "#fff" },
              onClick: () => {
                setOpen()
              },
            }}
          />
        }
      />

       {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={state.loading ? null : setClose}
          widthLarge={500}
          title="¿Desea eliminar el siguiente proveedor?"
          subtitle="¡Esta acción es irreversible!"
          disableChildHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickSubmit={() => handleDelete()}
              disabledCancel={state.loading}
              disabledSubmit={state.loading}
              isLoading={state.loading}
            />
          }
        />
      )}
    </>
  )
}

export default Edit
