import { axiosService } from "service"
import { salesSuppliersEndpoints } from "service/sources"

const suppliersList = (options) => {
  return axiosService.get(salesSuppliersEndpoints.LIST + options)
}

const suppliersBefore = () => {
  return axiosService.get(salesSuppliersEndpoints.BEFORE)
}

const create = (values) => {
  return axiosService.post(salesSuppliersEndpoints.CREATE, values)
}

const infoOneSupplier = (id) => {
  return axiosService.get(salesSuppliersEndpoints.INFO_ONE_SUPPLIER + "/" + id)
}

const createDetail = (values) => {
  return axiosService.post(salesSuppliersEndpoints.CREATE_DETAIL, values)
}

const deleteDetail = (id) => {
  return axiosService.del(salesSuppliersEndpoints.DELETE_DETAIL + "/" + id)
}

const update = (id, body) => {
  return axiosService.put(salesSuppliersEndpoints.UPDATE + "/" + id, body)
}

const deleteSupplier = (id) => {
  return axiosService.del(salesSuppliersEndpoints.DELETE + "/" + id)
} 

const toggleStatus = (id) => {
  return axiosService.put(salesSuppliersEndpoints.TOGGLE_STATUS + "/" + id)
}

export const salesSuppliers = {
  suppliersList,
  suppliersBefore,
  create,
  infoOneSupplier,
  createDetail, 
  deleteDetail,
  update,
  deleteSupplier,
  toggleStatus
}
