import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"

import Layout from "./Layout.js"
import PrivateRoute from "Components/Routing/Private"
import PublicRoute from "Components/Routing/Public"

import RenderPagesLogin from "Components/Pages/Login"
import LoginOem from "Components/Pages/Login/LoginOEM"

import Home from "Components/Pages/Home"
import StockImgPage from "Components/Pages/StockImg"

import TransitVehicles from "Components/Pages/Vehicles/TransitVehicles/"
import SearchWafers from "Components/Pages/Vehicles/SearchWafers"
import SearchVehicles from "Components/Pages/Vehicles/SearchVehicles/"
import Quotations from "Components/Pages/Vehicles/Quotations/index.js"
import ActaCompaction from "Components/Pages/Vehicles/Compaction/index.js"

import VariousPage from "Components/Pages/Settings/Varies"
import Users from "Components/Pages/Settings/Users"

import StockMovement from "Components/Pages/Plant/StockMovement"
import Storages from "Components/Pages/Plant/Warehoueses"
import Checklist from "Components/Pages/Plant/Checklist"
import Classification from "Components/Pages/Plant/Classification"
import Ingress from "Components/UI/Templates/Plant/StockMovement/Entry"
import Compaction from "Components/Pages/Plant/Compaction"
import Despath from "Components/Pages/Plant/Despatch"
import Station from "Components/Pages/Plant/Station/index.js"
import Used from "Components/Pages/Sales/Used"
import Grill from "Components/Pages/Plant/Grill"

import Stock from "Components/Pages/Sales/Stock"
import Prices from "Components/Pages/Sales/Prices/index.js"
import Clients from "./Pages/Sales/Clients/index.js"
import Supplier from "./Pages/Sales/Supplier/index.js"

import Ecosoft from "Components/Pages/Ecosoft"

import { SocketProvider } from "Components/Context/SocketProvider"

const App = () => {
  return (
    <SocketProvider>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={RenderPagesLogin} />
          <PublicRoute exact path="/oem" component={LoginOem} />
          <PrivateRoute exact path="/camara" component={StockImgPage} />

          <Layout>
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute
              exact
              path="/vehicles/search"
              component={SearchVehicles}
            />
            <PrivateRoute
              exact
              path="/vehicles/wafers"
              component={SearchWafers}
            />
            <PrivateRoute
              exact
              path="/vehicles/transit"
              component={TransitVehicles}
            />
            <PrivateRoute
              exact
              path="/vehicles/quotations"
              component={Quotations}
            />
            <PrivateRoute
              exact
              path="/vehicles/compactionActs"
              component={ActaCompaction}
            />

            <PrivateRoute
              exact
              path="/plant/classification"
              component={Classification}
            />
            <PrivateRoute
              exact
              path="/plant/movements"
              component={StockMovement}
            />
            <PrivateRoute
              exact
              path="/plant/movements/ingress"
              component={Ingress}
            />
            <PrivateRoute exact path="/plant/orders" component={Grill} />
            <PrivateRoute
              exact
              path="/plant/compaction"
              component={Compaction}
            />
            <PrivateRoute exact path="/plant/storages" component={Storages} />
            <PrivateRoute exact path="/plant/despatch" component={Despath} />
            <PrivateRoute exact path="/plant/checklist" component={Checklist} />
            <PrivateRoute exact path="/plant/used" component={Used} />
            <PrivateRoute exact path="/plant/zero" component={Station} />

            <PrivateRoute exact path="/sales/stock" component={Stock} />
            <PrivateRoute exact path="/sales/prices" component={Prices} />
            <PrivateRoute exact path="/sales/clients" component={Clients} />
            <PrivateRoute exact path="/sales/suppliers" component={Supplier} />

            <PrivateRoute
              exact
              path="/settings/config"
              component={VariousPage}
            />
            <PrivateRoute exact path="/settings/users" component={Users} />

            <PrivateRoute exact path="/ecosoft" component={Ecosoft} />
          </Layout>
        </Switch>
      </BrowserRouter>
    </SocketProvider>
  )
}

export default App
