import React, { useState, useContext } from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import {
  FORM_HOME,
  ORIGIN_DATA,
  DEPOSIT_LOCATION,
  SALES,
} from "Components/UI/Organisms/Plant/Used/Dialog/Content/Home/Fields"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"
import { EditContext } from "Components/Hooks/ContextEdit"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const HomeContent = (props) => {
  const { data, setData } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [edit] = useContext(EditContext)

  const [tab, setTab] = useState(0)

  const handleChange = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    })
  }

  async function handleSubmitCode() {
    await interfaces.plantStorage
      .searchStorageCode(data.data.almacen_codigo, data.data.almacen_empresa)
      .then((res) => {
        setData({
          ...data,
          data: {
            ...data.data,
            almacen: res.data,
            almacen_id: res.data.id,
          },
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }
  function renderTabs(index) {
    switch (index) {
      case 0:
        return (
          <InputsWithoutValidate
            fields={FORM_HOME(data.data, data.fields, handleChange, edit)}
            spacing={2}
          />
        )
      case 1:
        return (
          <InputsWithoutValidate
            fields={ORIGIN_DATA(data.data, data.fields, handleChange, edit)}
            spacing={2}
          />
        )
      case 2:
        return (
          <InputsWithoutValidate
            fields={DEPOSIT_LOCATION(
              data.data,
              data.fields,
              handleChange,
              edit,
              handleSubmitCode
            )}
            spacing={2}
          />
        )
      case 3:
        return (
          <InputsWithoutValidate
            fields={SALES(data.data, handleChange, edit)}
            spacing={2}
          />
        )
      default:
        break
    }
  }

  const verticalTabs = [
    {
      id: 0,
      title: "General",
    },
    {
      id: 1,
      title: "Datos del Origen",
    },
    {
      id: 2,
      title: "Ubicación en Depósito",
    },
    {
      id: 3,
      title: "Precios",
      disabled: !data.editPrice,
    },
  ]

  return (
    <ContentWithSubTabs
      listVerticalTabs={verticalTabs}
      value={tab}
      setValue={setTab}
      children={<div style={{ margin: "20px" }}>{renderTabs(tab)}</div>}
    />
  )
}

export default HomeContent
