import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"

import NewVersion from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Version/NewVersion"
import Edit from "./Edit"
import DeleteVersion from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Version/DeleteVersion"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"

const Version = (props) => {
  const {
    data,
    types,
    handleRemoveClick,
    idVersion,
    handleListItemClick,
    idModel,
    changeRender,
  } = props
  const [valueVersion, setValueVersion] = useState("")

  const {
    state: newVersionState,
    setOpen: setOpenNewVersion,
    setClose: setCloseNewVersion,
  } = SetState()

  const {
    state: editVersionState,
    setOpen: setOpenEditVersion,
    setClose: setCloseEditVersion,
  } = SetState()

  const {
    state: deleteVersionState,
    setOpen: setOpenDeleteVersion,
    setClose: setCloseDeleteVersion,
  } = SetState()

  const onChangeModel = (e) => {
    setValueVersion(e.target.value)
  }

  function filtered(data, types) {
    if (data) {
      let dataFiltered = data.filter((e) => {
        if (idModel) {
          return (
            e.modelo_id === idModel &&
            e.version.includes(valueVersion.toUpperCase())
          )
        } else {
          return null
        }
      })

      return dataFiltered.map((e) => ({
        ...e,
        tipo: types.find((v) => v.id === e.vehiculo_tipo_id)?.nombre || null,
      }))
    } else {
      return []
    }
  }

  function versionInfo() {
    return data.filter((e) => {
      return e.id === idVersion
    })[0]
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewVersion}
        onChange={onChangeModel}
        value={valueVersion}
        disabledButton={idModel ? false : true}
      />
      <NewVersion
        changeRender={changeRender}
        open={newVersionState.open}
        handleClose={setCloseNewVersion}
        indexModel={idModel}
        types={types}
      />
      <DeleteVersion
        open={deleteVersionState.open}
        handleRemoveClick={handleRemoveClick}
        handleClose={setCloseDeleteVersion}
        idVersion={idVersion}
        changeRender={changeRender}
      />
      <Edit
        idVersion={idVersion}
        changeRender={changeRender}
        open={editVersionState.open}
        handleclose={setCloseEditVersion}
        data={versionInfo}
        types={types}
      />
      <Papers
        children={
          <ListWithIcon
            data={filtered(data, types)}
            search={valueVersion}
            selectedItem={idVersion}
            onClickSelect={handleListItemClick}
            onClickDelete={setOpenDeleteVersion}
            onClickEdit={setOpenEditVersion}
            nameIncludes="version"
            secondary={`item.inicio + ' / ' + item.fin + '  ' + (item.tipo ? item.tipo: '')`}
          />
        }
        height="500px"
        text="Eliminar versión"
        disabledOn={idVersion && handleListItemClick ? false : true}
        onClick={setOpenDeleteVersion}
      />
    </>
  )
}
export default Version
