import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    alignItems: "center",
    heigth: "56px",
    marginBottom: "14px",
  },
  input: {
    marginBottom: theme.spacing(1),
    flex: 1,
    height: "75px",
  },
  iconButton: {
    padding: 10,
    color: "#3E984C",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  error: {
    textAlign: "center",
    color: "red",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1em",
  },
  div: {
    height: "70px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%",
  },
}))

export default useStyles
