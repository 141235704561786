import React from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

import { useStyles } from "./styles"

const Title = (props) => {
  const { actions, subtitle, title } = props

  const classes = useStyles()

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {title}
          <Typography variant="body2">{subtitle}</Typography>
        </Typography>
        {actions}
      </Toolbar>
    </AppBar>
  )
}

export default Title
