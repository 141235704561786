import * as yup from "yup"

export const createSupplier = yup.object({
    cuit: yup
       .number()
       .required("Este campo es requerido")
       .typeError("Este campo es requerido"),
    proveedor: yup
       .string()
       .required("Este campo es requerido")
       .typeError("Este campo es requerido"),
    tipo_responsable: yup
       .number()
       .required("Este campo es requerido")
       .nullable(),
    provincia_id: yup
       .number()
       .required("Este campo es requerido")
       .nullable(),
    localidad_id: yup
       .number()
       .required("Este campo es requerido")
       .nullable(),
    direccion_id: yup
       .number()
       .required("Este campo es requerido")
       .nullable()
 })

 export const editSupplier = yup.object({
    cuit: yup
        .number()
        .required("Este campo es requerido")
        .typeError("Este campo es requerido"),
    proveedor: yup
        .string()
        .required("Este campo es requerido")
        .typeError("Este campo es requerido"),
    tipo_responsable: yup
        .number()
        .required("Este campo es requerido")
        .nullable(),
    provincia_id: yup
        .number()
        .required("Este campo es requerido")
        .nullable(),
    localidad_id: yup
        .number()
        .required("Este campo es requerido")
        .nullable(),
    direccion_id: yup
        .number()
        .required("Este campo es requerido")
        .nullable()
 })

 export const createContact = yup.object({
    concepto: yup
        .string()
        .required("Este campo es requerido")
        .typeError("Este campo es requerido"),
    contenido: yup
        .string()
        .required("Este campo es requerido")
        .typeError("Este campo es requerido"),
 })