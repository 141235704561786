import React, { useState } from "react"

import { Grid } from "@material-ui/core"

import Papers from "Components/UI/Molecules/Papers/PaperList"
import Checklist from "Components/UI/Organisms/Settings/Users/UsersDialog/Content/Permissions/Checklist"
import ListhWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"

const Permissions = (props) => {
  const { data, setData } = props

  const [id, setId] = useState(0)

  const handleListItemClick = (id) => {
    setId(id.id)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (id) {
          return e.navbar_id === id
        } else {
          return null
        }
      })
    } else {
      return []
    }
  }

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        overflowY: "scroll",
        height: "600px",
      }}
    >
      <Grid item sm={4} xs={12} style={{overflowY: "scroll"}}>
        <Papers
         styles={{maxHeight: "430px"}}
          height="430px"
          children={
            <ListhWithIcon
              data={data.data.auth.navegation}
              nameIncludes="descripcion"
              onClickSelect={handleListItemClick}
              selectedItem={id}
              style={{ fontSize: '14px' }}
            />
          }
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Papers
          height="430px"
          children={
            <Checklist
              filteredData={filtered(data.data.auth.auth)}
              setData={setData}
              data={data}
              id={id}
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default Permissions
