import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: "#E4E4E4",
    borderRadius: "15px",
    margin: "5px",
  },
  divGreen: {
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 35%, ${theme.palette.primary.main} 100%)`,
    width: "100%",
    height: "100px",
    borderRadius: " 15px 15px 0px 0px",
  },
  divImg: {
    width: "100%",
    height: " 80px",
    display: " flex",
    justifyContent: "center",
  },
  avatar: {
    width: "75px",
    height: "75px",
  },
  typography: {
    textAlign: "center",
  },
  divButton: {
    display: "flex",
    justifyContent: "center",
    padding: "15px",
  },
  button: {
    width: "90%",
    textTransform: "initial",
  },
  avatarBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100px",
    height: "100px",
    top: "-50px",
    borderRadius: "50%",
    backgroundColor: "#E4E4E4",
  },
}))

export default useStyles
