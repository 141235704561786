import React, { useEffect, useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import { useLocation } from "react-router-dom"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "Components/UI/Templates/Common"
import SuppliersTable from "Components/UI/Organisms/Sales/Suppliers"
import Create from "Components/UI/Organisms/Sales/Suppliers/Create"

import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { oemColorButton } from "Helpers"

import { interfaces } from "service/interfaces"

const SupplierTemplate = () => {
  const [render, setRender] = useState(true)
  const [ dataBefore, setDataBefore ] = useState()
  
  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()
  
  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  async function apiCall() {
    await interfaces.salesSuppliers
    .suppliersBefore()
    .then((res) => {
      setDataBefore({ 
        data: res.data
      })
    })
  }

  useEffect(()=> {
    apiCall()
  }, [render])
  
  const BUTTONS = [
    {
      onClick: setOpenNew,
      menuTitle: "Nuevo",
      menuIcon: <AddIcon fontSize="small" />,
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
    },
  ]

  return (
    <div>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Ventas"
            subTitle="Proveedores"
            buttons={BUTTONS}
          />
        }
        table={<SuppliersTable render={render} setRender={setRender} dataBefore={dataBefore} />}
      />
      {openNew.suspense && (
        <Create
          data={dataBefore.data}
          render={render}
          setRender={setRender}
          open={setOpenNew}
          handleClose={setCloseNew}
        />
      )}
    </div>
  )
}

export default SupplierTemplate
