import SetState from 'Components/Hooks/SetState'
import ButtonInsideBox from 'Components/UI/Atoms/ButtonInsideBox'
import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'
import React from 'react'
import ConceptsContent from './Content'

const Concepts = () => {
  const { setOpen, setClose, state } = SetState()

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Concepto ingreso/gasto"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/vehicle-type-1.png"}
      />

      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        widthLarge="25vw"
        title="Concepto ingreso/gasto"
        open={state.open}
        handleclose={setClose}
        maxHeight
        children={<ConceptsContent />}
      />
    </>
  )
}

export default Concepts
