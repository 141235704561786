import React from "react"

import { styles } from "./styles"
import BasicTable from "Components/UI/Atoms/BasicTable"

const SubPiezas = (productos) => {
  const classes = styles()
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <BasicTable
          col={[
            { title: "Producto", style: { width: "680px" } },
            { title: "Cantidad", style: { width: "100px" }}
          ]}
          size={"small"}
          rows={productos.productos?.map((row) => {
            return {
              producto: row.producto,
              cantidad: row.cantidad
            }
          })}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 5px 10px 10px",
        }}
      ></div>
    </div>
  )
}

export default SubPiezas
