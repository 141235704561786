import React, { useEffect, useState } from "react"

import { Grid } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InternalUsersTable from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/UsersTable/Table"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const UsersTable = (props) => {
  const { open, handleclose, data, changes, setChanges } = props

  const dataParse = data?.contactos
  const [rows, setRows] = useState(dataParse)
  // eslint-disable-next-line
  useEffect(() => setRows(dataParse), [data])

  const submit = () => {
    setChanges({ ...changes, contactos: JSON.stringify(rows) })
    handleclose()
  }

  const cancel = () => {
    handleclose()
    setTimeout(() => {
      setRows(dataParse)
    }, 250)
  }

  const editRow = (e, index) => {
    e.preventDefault()
    let newArr = [...rows]
    newArr[index][e.target.name] = e.target.value
    setRows(newArr)
  }

  const addRow = () => {
    setRows([
      ...rows,
      {
        nombre: "",
        puesto: "",
        email: "",
        telefono: "",
        zona: "",
        comentarios: "",
      },
    ])
  }

  const deleteRow = (index) => {
    let newArr = [...rows]
    newArr.splice(index, 1)
    setRows(newArr)
  }

  const cols = [
    { title: "Nombre", style: { width: "15%" }, edit: true },
    { title: "Puesto", style: { width: "15%" }, edit: true },
    { title: "Correo electrónico", style: { width: "15%" }, edit: true },
    { title: "Teléfono", style: { width: "15%" }, edit: true },
    { title: "Zona", style: { width: "15%x" }, edit: true },
    { title: "Comentarios", style: { width: "15%" }, edit: true },
    { title: "Usuario sistema", style: { width: "150px" } },
    { title: "", style: { width: "80px" } },
  ]

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      title={data.nombre}
      widthLarge={"90vw"}
      children={
        <Grid container>
          <Grid item xs={12} sm={12}>
            <InternalUsersTable
              col={cols}
              rows={rows}
              onChange={editRow}
              deleteRow={deleteRow}
              addRow={addRow}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <CancelConfirmButtons
                noMargin={true}
                submitText="Guardar"
                onClickCancel={cancel}
                onClickSubmit={submit}
              />
            </div>
          </Grid>
        </Grid>
      }
    />
  )
}

export default UsersTable
