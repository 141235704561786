import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "100%",
    height: "85px",
  },
  root: {
    width: "400px",
    height: "100vh",
    padding: theme.spacing(2, 4, 3),
  },
  rootHistorial: {
    width: "400px",
  },
  rootListItemHistorial: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  large: {
    width: "150px",
    height: "150px",
  },
  btnCloseDialog: {
    bottom: 20,
    left: 150,
  },
  inputDialog: {
    height: "70px",
  },

  textTransformButtons: {
    textTransform: "capitalize",
  },
  boxWeather: {
    backgroundColor: "#C4C4C475",
    borderRadius: "15px",
    margin: "5px",
  },
  boxCoin: {
    backgroundColor: "#C4C4C475",
    margin: "5px",
    borderRadius: "15px",
  },
  boxQuote: {
    backgroundColor: "#C4C4C475",
    margin: "5px",
    padding: "5px",
    borderRadius: "15px",
  },
  icon2: {
    [theme.breakpoints.down("sm")]: {
      width: "800px",
      backgroundColor: "red",
    },
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100%",
    backgroundImage: `url(${process.env.PUBLIC_URL + "/Images/imageHome.webp"})`,
  },
}))
export default useStyles
