import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
import CustomButton from "Components/UI/Atoms/CustomButton"

import SimpleUploadImg from "Components/UI/Atoms/SimpleUploadImage"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import InputButton from "Components/UI/Organisms/Plant/Stock/PaperDevolution/DialogDevolution/InputButton"
import DialogWithActions from "Components/UI/Molecules/Modals/DialogWithActions"

import ReactImageGallery from "react-image-gallery"
import { interfaces } from "service/interfaces"
import useStyles from "./styles"
import { titles } from "Helpers"
import { useWindowSize } from "react-use"
const StockImg = () => {
  const classes = useStyles()
  let history = useHistory()
  const ref = useRef()
  const { width } = useWindowSize()
  const [isloading, setIsLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose, setState, setObject, setLoading } =
    SetState()

  const {
    state: deleteState,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  const [img, setImg] = useState([])

  const handleChangeCode = (e) => {
    setState({ code: e.target.value })
  }

  function handleChangeUpload(e) {
    e.preventDefault()
    let prevData = []
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    if (prevData !== {}) {
      handleUpload(prevData)
    }
  }

  async function onSubmitCode(values) {
    setIsLoading(true)
    await interfaces.dashboardInterface
      .widgetSearchStock(values.codigo)
      .then((res) => {
        setObject(res.data)
        setImg(res.data.fotos)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  async function handleUpload(prevData) {
    setLoading(true)
    if (state.dataObject.usado) {
      await interfaces.plantUsed
        .uploadPhoto(state.dataObject.id, prevData, prevData.length + 1)
        .then((res) => {
          createDismissableSnackbar(res.data.data.msg, { variant: "success" })
          setImg(
            res.data.data.photos.map((e) => {
              return { fullscreen: e, original: e }
            })
          )
          ref.current.slideToIndex(res.data.data.photos.length - 1)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
        })
    } else {
      await interfaces.plantInterfaceClassification
        .uploadPhoto(
          state.dataObject.id,
          state.dataObject.empresa,
          prevData,
          prevData.length + 1
        )
        .then((res) => {
          createDismissableSnackbar(res.data.data.msg, { variant: "success" })
          setImg(
            res.data.data.url.map((e) => {
              return { fullscreen: e, original: e }
            })
          )
          ref.current.slideToIndex(res.data.data.url.length - 1)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data, {
            variant: "error",
          })
        })
    }
    setLoading(false)
  }

  async function handleDelete() {
    setLoading(true)
    if (state.dataObject.usado) {
      await interfaces.plantUsed
        .deletePhoto(state.dataObject.id, deleteState.index + 1)
        .then((res) => {
          createDismissableSnackbar(res.data, { variant: "success" })
          img.splice(deleteState.index, 1)
          setCloseDelete({ index: null, id: null })
        })
        .catch(() => {
          createDismissableSnackbar(`Error`, {
            variant: "error",
          })
          setCloseDelete({ index: null, id: null })
        })
    } else {
      await interfaces.plantInterfaceClassification
        .deletePhoto(
          state.dataObject.id,
          deleteState.index + 1,
          state.dataObject.empresa
        )
        .then((res) => {
          createDismissableSnackbar(res.data, { variant: "success" })
          img.splice(deleteState.index, 1)
          setCloseDelete({ index: null, id: null })
        })
        .catch(() => {
          createDismissableSnackbar(`Error`, {
            variant: "error",
          })
          setCloseDelete({ index: null, id: null })
        })
    }
    setLoading(false)
  }

  const INPUTS = [
    {
      class: "custom",
      component: (
        <InputButton
          placeholder="Código"
          loading={isloading}
          name="codigo"
          onSubmit={onSubmitCode}
          onKeyUp={handleChangeCode}
          disabled={!state.code}
          value={state.code}
          resetFormDisabled
          noHeight
        />
      ),
      xs: 12,
      sm: 12,
    },
    {
      class: "textfield",
      name: "producto",
      label: "Producto",
      value: state.dataObject !== {} ? state.dataObject.producto : "",
      disabled: true,
      xs: 12,
      sm: 12,
      size: "small",
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      value: state.dataObject !== {} ? state.dataObject.vehiculo : "",
      disabled: true,
      xs: 12,
      sm: 12,
      size: "small",
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      value: state.dataObject !== {} ? state.dataObject.estado : "",
      disabled: true,
      xs: 12,
      sm: 12,
      size: "small",
    },
    {
      class: "custom",
      component: (
        <ReactImageGallery
          ref={ref}
          items={titles(img)}
          useBrowserFullscreen={false}
          showBullets={true}
          showPlayButton={false}
          onClick={() =>
            !img.length > 0
              ? null
              : setOpenDelete({
                  id: state.dataObject.id,
                  index: ref.current.getCurrentIndex(),
                })
          }
          lazyLoad={true}
          showThumbnails={false}
          onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
        />
      ),
      xs: 12,
      sm: 12,
    },
  ]

  function closeUpload() {
    setClose({ dataObject: {} })
    setImg({})
  }

  function openUploadURL() {
    setOpen(history.push("/camara"))
  }

  function openUpload() {
    setOpen()
  }

  return (
    <div>
      <Grid spacing={1}>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Subir fotos stock
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <CustomButton
              text="Subir fotos"
              fullWidth
              onClick={width > 600 ? openUpload : openUploadURL}
              style={{ textTransform: "uppercase" }}
            />
          </Grid>
        </Grid>
      </Grid>

      {state.suspense && (
        <DialogComponent
          title="Subir fotos stock"
          widthLarge="500px"
          open={state.open}
          handleclosedisabled={state.loading || isloading}
          handleclose={closeUpload}
          disableBackdropClick={true}
          maxHeight
          disableEscapeKeyDown={true}
          children={<InputsWithoutValidate fields={INPUTS} spacing={2} />}
          actions={
            <div
              style={{
                marginBottom: "10px",
                width: "100%",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              <SimpleUploadImg
                handleChange={handleChangeUpload}
                change={{}}
                text="Subir fotografías"
                disabledBtn={
                  !Object.keys(state.dataObject).some(
                    (e) => state.dataObject[e]
                  )
                }
                loading={state.loading}
              />
            </div>
          }
        />
      )}

      <DialogWithActions
        open={deleteState.open}
        onSubmit={handleDelete}
        handleClose={setCloseDelete}
        loading={state.loading}
        title="¿Desea eliminar esta foto?"
      />
    </div>
  )
}

export default StockImg
