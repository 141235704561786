import React, { useContext } from "react"
import { Formik, Field } from "formik"
import { TextField } from "formik-material-ui"

import { Grid } from "@material-ui/core"

import useStyles from "../styles"
import { FormContext } from "Components/Hooks/ContextForm"
import { EditContext } from "Components/Hooks/ContextEdit"

import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const Extra = () => {
  const classes = useStyles()
  const [edit] = useContext(EditContext)
  const { formEdit, setFormEdit, formApi, setFormApi } = useContext(FormContext)

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_info: {
          ...formEdit.vehicle.vehiculo_info,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_info: {
        ...formEdit.vehiculo_info,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeStates = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const ALERTS = [
    {
      value: "",
      label: "NINGUNA",
    },
    {
      value: "OBLEAS EXTRAVÍADAS",
      label: "OBLEAS EXTRAVÍADAS",
    },
    {
      value: "VEHÍCULO COMERCIALIZADO",
      label: "VEHÍCULO COMERCIALIZADO",
    },
  ]

  const COMPACTION_STATES = [
    {
      id: 0,
      label: "NO COMPACTADO",
    },
    {
      id: 1,
      label: "PENDIENTE COMPACTACION",
    },
    {
      id: 2,
      label: "COMPACTADO",
    },
  ]

  const CHASIS_SOLD = [
    {
      id: 0,
      label: "NO VENDIDO",
    },
    {
      id: 1,
      label: "VENDIDO",
    },
  ]

  const BIEN_DE_USO = [
    {
      id: 0,
      label: "NO",
    },
    {
      id: 1,
      label: "SI",
    },
  ]
  return (
    <div className={classes.div}>
      <Formik>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.paddingGrids}
        >
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              id="dominio"
              label="Comentario ECOSOFT"
              name="comentario_ecosoft"
              disabled={edit ? false : true}
              InputLabelProps={{
                shrink: true,
              }}
              value={formEdit.vehicle.vehiculo_info.comentario_ecosoft}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CustomAutocomplete
              name="alerta1"
              label="Alerta"
              disabled={edit ? false : true}
              options={ALERTS}
              value={ALERTS.find(
                (index) =>
                  index.value === formEdit.vehicle.vehiculo_info.alerta1
              )}
              getOptionLabel={(options) => options.label}
              onChange={(event, value) => {
                handleChange({
                  target: { name: "alerta1", value: value.value },
                })
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CustomAutocomplete
              name="compactado"
              label="Estado compactación"
              disabled={edit ? false : true}
              options={COMPACTION_STATES}
              value={COMPACTION_STATES.find(
                (index) => index.id === formEdit.vehicle.compactado
              )}
              getOptionLabel={(options) => options.label}
              onChange={(event, value) => {
                handleChangeStates({
                  target: { name: "compactado", value: value.id },
                })
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CustomAutocomplete
              name="chasis_comercializado"
              label="Chasis comercializado"
              disabled={edit ? false : true}
              options={CHASIS_SOLD}
              value={CHASIS_SOLD.find(
                (index) => index.id === formEdit.vehicle.chasis_comercializado
              )}
              getOptionLabel={(options) => options.label}
              onChange={(event, value) => {
                handleChangeStates({
                  target: { name: "chasis_comercializado", value: value.id },
                })
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CustomAutocomplete
              name="bien_de_uso"
              label="Bien de uso"
              disabled={edit ? false : true}
              options={BIEN_DE_USO}
              value={BIEN_DE_USO.find(
                (index) => index.id === formEdit.vehicle.bien_de_uso
              )}
              getOptionLabel={(options) => options.label}
              onChange={(event, value) => {
                handleChangeStates({
                  target: { name: "bien_de_uso", value: value.id },
                })
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}></Grid>
        </Grid>
      </Formik>
    </div>
  )
}

export default Extra
