import { axiosService } from "../../../index"
import { plantEndpoints } from "../../../sources"

const getNewProduct = (dominio) => {
  return axiosService.get(plantEndpoints.NEW_PRODUCT + "?search=" + dominio)
}

const postNewProduct = (body) => {
  return axiosService.post(plantEndpoints.NEW_PRODUCT, body)
}
const getInfoTable = (options, search) => {
  return axiosService.get(
    plantEndpoints.GET_LIST + options + "&search=" + search
  )
}
const stockProductData = (id) => {
  return axiosService.get(plantEndpoints.STOCK_PRODUCT + id)
}
const uploadPhoto = (id, empresa, files, initialName) => {
  return axiosService.uploadFiles(
    plantEndpoints.UPLOAD_PHOTO + id + "?empresa=" + empresa,
    files,
    initialName
  )
}
const deletePhoto = (id, index, empresa) => {
  return axiosService.post(
    plantEndpoints.DELETE_PHOTO + id + "?index=" + index + "&empresa=" + empresa
  )
}
const updateStock = (id, body) => {
  return axiosService.put(plantEndpoints.UPDATE_STOCK + id, body)
}

const stockSticker = (id) => {
  return axiosService.getPDF(plantEndpoints.STOCK_STICKER + id)
}

const getRudac = (id) => {
  return axiosService.post(plantEndpoints.GET_RUDAC + id)
}

const setAsDuplicated = (list) => {
  return axiosService.post(plantEndpoints.DUPLICATED, list)
}

export const plantInterfaceClassification = {
  getNewProduct,
  postNewProduct,
  getInfoTable,
  stockProductData,
  uploadPhoto,
  deletePhoto,
  updateStock,
  stockSticker,
  getRudac,
  setAsDuplicated,
}
