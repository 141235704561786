const INGRESS_LIST = "/plant/movements/ingresslist"
const INGRESS_ADD = "/plant/movements/add"
const INGRESS_DELETE = "/plant/movements/delete"
const INGRESS_PROCESS = "/plant/movements/ingressprocess"
const RETURN_DATA = "/plant/movements/returnstock"
const RETURN_CONFIRM = "/plant/movements/returnstock"
const TO_OEM_LIST = "/plant/movements/transferlist"
const TO_OEM_CONFIRM = "/plant/movements/transferconfirm"

export const plantEndpointsStock = {
  INGRESS_LIST,
  INGRESS_PROCESS,
  INGRESS_ADD,
  INGRESS_DELETE,
  RETURN_DATA,
  RETURN_CONFIRM,
  TO_OEM_LIST,
  TO_OEM_CONFIRM,
}
