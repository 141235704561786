import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "transparent",
    zIndex: "-1",
  },
  textButton: {
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0",
      marginLeft: "0px",
    },
  },
  grow: {
    flexGrow: 1,
    height: "50px",
    marginTop: "15px",
    background: "#f4f7f4",
    border: "1px solid #EBECF0",
    borderRadius: "15px",
    margin: "0px 10px 0px 10px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  search: {
    position: "relative",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(14),
      width: "100%",
    },
  },
  hello: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    width: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  navRight: {
    color: "grey",
  },
  sectionDesktop: {
    display: "flex",
    borderRadius: "15px",
    width: "auto",
    marginRight: "5px",
    color: "#fff",
    backgroundColor: "transparent",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      color: "#fff",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  btnPerfil: {
    textTransform: "none",
  },

  styleIcons: {
    color: "grey",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnDrawerPerfil: {
    marginBottom: "2em",
    margin: theme.spacing(0, 0, 0, 0),
    width: "352px",
  },
  avatar: {
    background: theme.palette.primary.main,
  },
}))

export default useStyles
