import React, { useRef } from "react"
import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { InputAdornment } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormikRadioGroup from "Components/UI/Atoms/FormikRadio"

import useStyles from "./styles"

export default function FirstStep(props) {
  const classes = useStyles()
  const { onSubmit, validationSchema, loading } = props

  const refInput = useRef(null)
  function handleRef() {
    refInput.current.click()
  }

  function iconAdornment() {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={() => handleRef()}
          >
            <ArrowRightAltIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  const options = [
    { value: "Motor", label: "Motor", color: "primary" },
    { value: "Caja", label: "Caja", color: "primary" },
  ]

  return (
    <>
      <Formik
        initialValues={{
          dominio: "",
          tipo: "Motor",
        }}
        onSubmit={(values) => {
          onSubmit(values)
        }}
        validationSchema={validationSchema}
        className={classes.root}
      >
        <Form>
          <div className={classes.div}>
            <Field name="tipo" component={FormikRadioGroup} options={options} />
          </div>
          <Field
            component={TextField}
            name="dominio"
            fullWidth
            disabled={false}
            className={classes.input}
            variant="outlined"
            placeholder={"Buscar por Dominio o Nº V"}
            InputProps={{
              endAdornment: iconAdornment(),
            }}
            inputProps={{
              maxLength: 9,
            }}
          />
          <button style={{ display: "none" }} ref={refInput} type="submit" />
        </Form>
      </Formik>
    </>
  )
}
