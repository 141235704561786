import React, { useState, useContext } from "react"

import { Button, List, ListItem, ListItemText } from "@material-ui/core"
import { Typography } from "@material-ui/core"

import useStyles from "../../../../../../StyleModalNew"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ModalCostosYGastos from "./Edit"
import New from "./New"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"
import ModalDelete from "./Delete"
import SetState from "Components/Hooks/SetState"

const CostsAndExpenses = (props) => {
  const { disabled } = props
  const { formEdit } = useContext(FormContext)
  const id = formEdit.vehicle.id
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)

  const [data, setData] = useState([])
  const [dense] = useState(false)

  const { state, setClose, setOpen } = SetState()

  async function handleOpen() {
    await apiCall()
    setIsLoading(false)
    setOpen()
  }

  function onDeletePayment(id) {
    setData(data.filter(d => d.id !== id))
  }

  async function apiCall() {
    await interfaces.vehicleInterface
      .getAllPayments(id)
      .then((res) => setData(res.data))
      .catch()
  }

  function finalCost() {
    let cost = 0
    //eslint-disable-next-line
    data.map((e) => {
      if (e.concepto_tipo === "Ingreso") {
        cost = cost + parseInt(e.monto_total)
      } else {
        cost = cost - parseInt(e.monto_total)
      }
    })
    return cost
  }

  return (
    <div>
      <Button
        disabled={disabled}
        color="primary"
        variant="contained"
        onClick={handleOpen}
      >
        Costos y gastos
      </Button>

      {isLoading ? (
        ""
      ) : (
        <DialogComponent
          tipo="center"
          open={state.open}
          handleclose={setClose}
          title="Costos y gastos"
          children={
            <>
              <Typography
                variant="h6"
                align="center"
                style={{ color: finalCost() < 0 ? "red" : "green" }}
              >
                Total: $ {finalCost()}*
              </Typography>

              <List dense={dense}>
                {data &&
                  Object.keys(data).map((item) => {
                    return (
                      <ListItem key={item}>
                        <ListItemText
                          primary={`Pago a proveedor #${item * 1 + 1}`}
                          secondary={
                            <div style={{ display: "flex" }}>
                              <div>{data[item].proveedor} -&nbsp;</div>
                              <b
                                style={{
                                  color:
                                    data[item].concepto_tipo === "Gasto"
                                      ? "red"
                                      : "green",
                                }}
                              >
                                {" "}
                                ${data[item].monto_total}
                              </b>
                            </div>
                          }
                        />
                        <ModalDelete
                          data={data[item]}
                          nro={item * 1 + 1}
                          onDelete={onDeletePayment}
                        />
                        <ModalCostosYGastos
                          nro={item * 1 + 1}
                          data={data[item]}
                          handleClose={setClose}
                        />
                      </ListItem>
                    )
                  })}
              </List>
              <Typography
                variant="h6"
                align="center"
                style={{ fontSize: "15px" }}
              >
                * falta ingreso por venta de piezas
              </Typography>
            </>
          }
          actions={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "15px",
              }}
            >
              <Button
                className={classes.textTransformLetter}
                onClick={setClose}
              >
                Cancelar
              </Button>
              <New handleCloseMain={setClose} />
            </div>
          }
        />
      )}
    </div>
  )
}

export default CostsAndExpenses
