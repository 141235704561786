import React, { useRef, useState } from "react"
import moment from "moment"

import { Formik, Form } from "formik"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { validationRudac } from "Components/Validation/Vehicles/SearchWafers"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import styles from "./styles"
import { interfaces } from "service/interfaces"

const location = [
  {
    id: 1,
    value: "Río Cuarto",
  },
  {
    id: 2,
    value: "25 de Mayo",
  },
  {
    id: 6,
    value: "Ruta 7",
  },
]

const OBJETO = [
  {
    id: 8,
    label: "Fecha inicio",
    name: "desde",
    sm: 6,
    xs: 12,
    class: "datepicker",
    format: "dd-MM-yyyy",
    disableFuture: true,
  },
  {
    id: 8,
    label: "Fecha fin",
    name: "hasta",
    sm: 6,
    xs: 12,
    class: "datepicker",
    format: "dd-MM-yyyy",
    disableFuture: true,
  },
  {
    id: 3,
    label: "Sucursal",
    name: "sucursal",
    options: location,
    optionLabel: `options["value"]`,
    optionValue: "id",
    sm: 12,
    xs: 12,
    class: "autocomplete",
  },
]

const ModalRudac = (props) => {
  const classes = styles()
  const [isLoading, setIsLoading] = useState(false)
  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async (values) => {
    setIsLoading(true)
    const value = {
      sucursal: values.sucursal,
      desde: moment(values.desde).format("yyyy-MM-DD"),
      hasta: moment(values.hasta).format("yyyy-MM-DD"),
    }
    await interfaces.wafersInterface
      .rudac(value)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Libro RUDAC descargado!", {
          variant: "success",
        })
        props.handleclose(true)
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal", {
          variant: "error",
        })
      })
    setIsLoading(false)
  }
  return (
    <div>
      <DialogComponent
        open={props.open}
        handleclose={props.handleclose}
        title="Exportar libro RUDAC"
        disableChildHeight
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        handleclosedisabled={isLoading}
        children={
          <Formik
            initialValues={{
              desde: null,
              hasta: null,
              sucursal: "",
            }}
            validationSchema={validationRudac}
            onSubmit={onSubmit}
          >
            {({ setFieldValue, isSubmitting }) => (
              <Form>
                <InputsWithValidate
                  fields={OBJETO}
                  spacing={1}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                />

                <button
                  style={{ display: "none" }}
                  type="submit"
                  ref={formSubmitRef}
                />
              </Form>
            )}
          </Formik>
        }
        actions={
          <div className={classes.buttons}>
            <CancelConfirmButtons
              onClickCancel={props.handleclose}
              onClickSubmit={handleSubmit}
              isLoading={isLoading}
              disabledSubmit={isLoading}
              disabledCancel={isLoading}
            />
          </div>
        }
      />
    </div>
  )
}

export default ModalRudac
