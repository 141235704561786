import React, { useState } from "react"

import { Button, TablePagination } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"
import BasicTable from "Components/UI/Atoms/BasicTable"
import Search from "Components/UI/Atoms/Search"
import { Checkbox } from "@material-ui/core"

const Table = (props) => {
  const { formEdit, isLoading, edit, setFormEdit, data, onDataChange, setIsLoading } = props
 
  const [loading, setLoading] = useState(true)
  const [listParts, setListParts] = useState([])
  const { state, setClose, setOpen } = SetState()
  const [ checkElement, setCheckElement ] = useState([])
  const [ searchValue, setSearchValue ] = useState("") 
  const [ count, setCount ] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const handleChangeCheckElement = (id) => {
    const selectedPart = listParts.find(part => part.id === id)
    
    if (!checkElement.some(item => item.id === selectedPart.id)) {
        setCheckElement([...checkElement, selectedPart])
    } else {
        setCheckElement(checkElement.filter(item => item.id !== selectedPart.id))
    }
  }

  async function datesApiCall(currentPage, value) {
      const tipo = "LOCAL"
      const options = `?limit=${5}&offset=${currentPage}&search=${value}`
      await interfaces.compactionInterface
      .pendingList(tipo, options)
      .then((res) => {
        setListParts(res.data)
        setCount(res.header.header.count)
      })
  }

  async function apiCall() {
      const tipo = "LOCAL"
      const options = `?limit=${5}&offset=${0}`
      await interfaces.compactionInterface
      .pendingList(tipo, options)
      .then((res) => {
        setListParts(res.data)
        setCount(res.header.header.count)
      })
  }

  async function handleOpen() {
    setCurrentPage(0)
    await apiCall()
    setLoading(false)
    setOpen()
  }
  
  async function addParts() {
    setIsLoading(true)
    const selectedIds = checkElement.map(part => part.id)
    const body = {
      list: selectedIds,
    }
    const actId = data.act.id
  
    const updatedFormEdit = [...formEdit, ...checkElement]
    setFormEdit(updatedFormEdit)
    onDataChange(updatedFormEdit)
    setCheckElement([])
    interfaces.compactionInterface.addDetails(actId, body)
      .then(async () => {
      await apiCall()
      })
  
    setListParts([])
    setClose()
    setIsLoading(false)
  }
  
  async function handleChangeSearch(e) {
    const value = e.target.value.toUpperCase()
    setSearchValue(value)
    setCurrentPage(0)
    await datesApiCall(0, value)
  }

  const handleChangePage = async (event, newPage) => {
    const value = searchValue.toUpperCase()
    setCurrentPage(newPage)
    await datesApiCall(newPage, value)
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpen}
        disabled={isLoading || !edit || data.act.estado === 1}
      >
        AGREGAR
      </Button>

      {loading ? (
        ""
      ) : (
        <DialogComponent
          tipo="center"
          open={state.open}
          handleclose={setClose}
          title="Vehículos pendientes de compactación"
          widthLarge={"800px"}
          customHeight={"600px"}
          children={
            <>
              <Search  
               placeholder="Buscar por número de dominio"
               onChange={handleChangeSearch}
               value={searchValue}
              />
              <BasicTable
                  col={[
                    { title: "", style: { width: "40px"} },
                    { title: "Dominio", style: { width: "80px" } },
                    { title: "Marca", style: { width: "80px" } },
                    { title: "Modelo", style: { width: "80px" } },
                    { title: "Ubicación", style: { width: "80px" } },
                    { title: "Lote", style: { width: "80px" } }
                  ]}
                  size={"small"}
                  rows={listParts.map((row) => {
                    const isDisabled = formEdit.some(editPart => editPart.id === row.id) || row.compactacion_id !== null
                    return {
                      seleccionar: (
                         <Checkbox 
                          onChange={() => handleChangeCheckElement(row.id)}
                          checked={checkElement.some(item => item.id === row.id)}
                          disabled={isDisabled}
                          />
                      ),
                      dominio: row.dominio,
                      marca: row.marca,
                      modelo: row.modelo,
                      ubicacion: row.ubicacion,
                      lote: row.lote
                    }
                  })}
              >          
              </BasicTable>
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={5}
                page={currentPage}
                onPageChange={handleChangePage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
              />
            </>
          }
          actions={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "15px",
              }}
            >
              <Button onClick={setClose}>
                Cancelar
              </Button>
              <Button 
                onClick={addParts}
                color="primary"
                variant="contained"
                disabled={checkElement.length === 0}
              >
                Seleccionar
              </Button>
             
            </div>
          }
        />
      )}
    </div>
  )
}

export default Table
