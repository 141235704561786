import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import CustomButton from "Components/UI/Atoms/CustomButton"
import {
  FUEL,
  TIPO_BAJA,
  ESTADO_REGISTRAL,
  TRANSSMISION,
  EMPRESAS_ECOSOFT,
  OPTIONS_PHOTOS,
  MAIN_LOCATIONS,
  TRACTION,
  BIEN_DE_USO,
} from "Constants"

export const headerEcosoft = (
  initialPosts,
  handleChange,
  handleChangePhoto,
  handleChangeSearch,
  filters,
  handleClick,
  valueFilter,
  search
) => {
  return [
    {
      class: "custom",
      md: 3,
      sm: 12,
      xs: 12,
      component: (
        <>
          <CustomAutocomplete
            name="marca_modelo"
            size="small"
            disableClearable={false}
            options={filters.models_brands}
            getOptionLabel={(options) => options.marca_modelo}
            label="Marca/Modelo"
            style={{ width: "100%" }}
            onChange={(_, e, reason) => {
              if (reason === "clear") return handleChange("marca_modelo", "")
              handleChange("marca_modelo", e.marca_modelo)
            }}
          />
        </>
      ),
    },

    {
      class: "textfield",
      name: "search",
      label: "Buscador rápido",
      value: search,
      md: 3,
      sm: 12,
      xs: 12,
      size: "small",
      onKeyPress: (e) => {
        if (e.key === "Enter") {
          initialPosts(valueFilter)
        }
      },
      onChange: (e) => {
        handleChangeSearch(e.target.value.toUpperCase())
      },
    },
    {
      class: "autocomplete",
      md: 2,
      sm: 12,
      xs: 12,
      name: "tipo",
      label: "Tipo de vehiculo",
      options: filters.filterList.vehicleTypes
        ? filters.filterList.vehicleTypes
        : [],
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      size: "small",
      onChange: (e) => {
        if (e) {
          handleChange("vehiculo_tipo", e.nombre)
        } else {
          handleChange("vehiculo_tipo", "")
        }
      },
      disableClearable: true,
    },

    {
      class: "autocomplete",
      md: 2,
      sm: 12,
      xs: 12,
      name: "fotografias",
      label: "Foto",
      options: OPTIONS_PHOTOS,
      optionLabel: `options["label"]`,
      optionValue: "value",
      defaultValue: { label: "FRENTE" },
      onChange: (e) => {
        handleChangePhoto(e.value)
      },
      size: "small",
    },
    {
      class: "custom",
      md: 2,
      sm: 12,
      xs: 12,
      component: (
        <CustomButton text="Más filtros" fullWidth onClick={handleClick} />
      ),
    },
  ]
}

export const pop = (handleChange, valueFilter, clearFilters, versionFilter) => {
  return [
    {
      class: "autocomplete",
      name: "estado_registral",
      label: "Estado Registral",
      options: ESTADO_REGISTRAL,
      optionLabel: `options["label"]`,
      optionValue: "value",
      size: "small",
      sm: 12,
      xs: 12,
      disableClearable: true,

      value: valueFilter ? valueFilter.estado_registral : null,
      onChange: (e) => {
        handleChange("estado_registral", e.value)
      },
      forceSelect: true,
    },
    {
      class: "autocomplete",
      name: "tipo_baja_id",
      label: "Tipo de baja",
      sm: 6,
      xs: 6,
      value: valueFilter ? valueFilter.tipo_baja_id : null,
      options: TIPO_BAJA.filter((e) => e.id !== 4),
      optionLabel: `options["baja"]`,
      optionValue: "baja",
      disableClearable: true,

      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("tipo_baja_id", "")
        handleChange("tipo_baja_id", valor.baja)
      },
    },
    {
      class: "autocomplete",
      name: "empresa",
      label: "Empresa",
      sm: 6,
      xs: 6,
      disableClearable: true,
      value: valueFilter ? valueFilter.empresa : null,
      options: EMPRESAS_ECOSOFT,
      optionLabel: `options["label"]`,
      optionValue: "label",
      size: "small",
      defaultValue: "ECO",
      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("empresa", "")
        handleChange("empresa", valor.label)
      },
      forceSelect: true,
    },
    {
      class: "autocomplete",
      name: "combustible",
      label: "Combustible",
      options: FUEL,
      value: valueFilter ? valueFilter.combustible : null,
      optionLabel: `options["combustible"]`,
      optionValue: "combustible",
      sm: 6,
      xs: 6,
      disableClearable: true,
      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("combustible", "")
        handleChange("combustible", valor.combustible)
      },
    },
    {
      class: "autocomplete",
      name: "transmision",
      label: "Transmisión",
      options: TRANSSMISION,
      value: valueFilter ? valueFilter.transmision : null,
      optionLabel: `options["TRANSSMISION"]`,
      optionValue: "TRANSSMISION",

      sm: 6,
      xs: 6,
      disableClearable: true,
      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("transmision", "")
        handleChange("transmision", valor.TRANSSMISION)
      },
    },
    {
      class: "autocomplete",
      name: "traccion",
      label: "Tracción",
      options: TRACTION,
      value: valueFilter ? valueFilter.traccion : null,
      optionLabel: `options["TRACTION"]`,
      optionValue: "TRACTION",
      sm: 6,
      xs: 6,
      disableClearable: true,
      onChange: (valor, reason) => {
        if (reason === "clear") return handleChange("traccion", "")
        handleChange("traccion", valor.TRACTION)
      },
    },
    {
      class: "textfield",
      name: "puertas",
      label: "Puertas",
      value: valueFilter ? valueFilter.puertas : null,
      type: "number",
      sm: 6,
      xs: 6,
      onChange: (e) => {
        handleChange("puertas", e.target.value)
      },
      forceSelect: true,
    },
    {
      class: "textfield",
      name: "año",
      label: "Año",
      value: valueFilter ? valueFilter.año : null,
      type: "number",
      sm: 6,
      xs: 6,
      onChange: (e) => {
        handleChange("año", e.target.value)
      },
      forceSelect: true,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 6,
      name: "ubicacion",
      label: "Ubicaciones",
      options: MAIN_LOCATIONS,
      optionLabel: `options["label"]`,
      optionValue: "id",
      value: valueFilter ? valueFilter.ubicacion : null,

      onChange: (e, reason) => {
        if (reason === "clear") return handleChange("ubicacion", "")
        handleChange("ubicacion", e.label)
      },
      disableClearable: true,
    },
    {
      class: "textfield",
      name: "lote",
      label: "Lote",
      value: valueFilter.lote ? valueFilter.lote : null,
      disabled: valueFilter.ubicacion ? false : true,
      type: "number",
      sm: 6,
      xs: 6,
      onChange: (e) => {
        handleChange("lote", e.target.value)
      },
      forceSelect: true,
    },
    {
      class: "textfield",
      name: "fila",
      label: "Fila",
      value: valueFilter.fila ? valueFilter.fila : null,
      disabled: valueFilter.lote ? false : true,
      type: "number",
      sm: 6,
      xs: 6,
      onChange: (e) => {
        handleChange("fila", e.target.value)
      },
      forceSelect: true,
    },
    {
      class: "autocomplete",
      name: "bien_de_uso",
      label: "Bien de uso",
      options: BIEN_DE_USO,
      value: valueFilter ? valueFilter.bien_de_uso : null,
      optionLabel: `options["BIEN_DE_USO"]`,
      optionValue: "BIEN_DE_USO",
      size: "small",
      sm: 6,
      xs: 6,
      disableClearable: true,
      onChange: ({ id, BIEN_DE_USO }) => {
        handleChange("bien_de_uso", BIEN_DE_USO)
      },
      forceSelect: true,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 6,
      name: "version",
      label: "Version",
      options: versionFilter.filter(
        (e) => e.marca_modelo === valueFilter.marca_modelo
      ),
      optionLabel: `options["version"]`,
      optionValue: "version",
      value: valueFilter ? valueFilter.version : null,

      onChange: (e, reason) => {
        if (reason === "clear") return handleChange("version", "")
        handleChange("version", e.version)
      },
      disableClearable: true,
    },
    {
      class: "custom",
      sm: 6,
      xs: 6,
      style: { display: "flex", justifyContent: "end" },
      component: <CustomButton text="Limpiar filtros" onClick={clearFilters} />,
    },
  ]
}
