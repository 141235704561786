import React from "react"
import { FilterList } from "@material-ui/icons"
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

const Filters = (props) => {
  const { selectedOption, setSelectedOption, handleApplyFilter, openDialog, setOpenDialog, selectedLocation, setSelectedLocation } = props
  
  const handleFilter = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const applyFilter = () => {
    handleApplyFilter()
    handleCloseDialog()
  }

  const object = [
    {
      id: 0,
      optionLabel: "NO",
    },
    {
      id: 1,
      optionLabel: "SI",
    },
  ]
  const options = [
    {
      id: 1,
      optionLabel: "ECO Río Cuarto",
    },
    {
      id: 2,
      optionLabel: "ECO Spernanzoni",
    },
    {
      id: 3,
      optionLabel: "ECO Ruta 7",
    },
    {
      id: 4,
      optionLabel: "ECO 25 de Mayo",
    },
    {
      id: 5,
      optionLabel: "OEM Ruta 7",
    },
    {
      id: 7,
      optionLabel: "En tránsito",
    },
  ]

  return (
    <>
      <Button
        onClick={handleFilter}
        color="primary"
        variant="contained"
        size='small'
        style={{height: "40px", alignItems: "center" }}
      >
        <FilterList />
      </Button>
      
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xs'>
        <DialogTitle>Filtros</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={object}
            getOptionLabel={(option) => option.optionLabel}
            value={selectedOption}
            onChange={(event, newValue) => setSelectedOption(newValue)}
            renderInput={(params) => <TextField {...params} label="Es lote compactación?" variant="outlined" style={{ marginBottom: "10px", width: "300px" }} size='small' />}
            size='small'
          />
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.optionLabel}
            value={selectedLocation}
            onChange={(event, newValue) => setSelectedLocation(newValue)}
            renderInput={(params) => <TextField {...params} label="Ubicación" variant="outlined" size='small'/>}
            size='small'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={applyFilter} color="primary">
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Filters
