import React from "react"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns"
import es from "date-fns/locale/es"
import { useWindowSize } from "react-use"

import {
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"

import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

export default function InputsWithoutValidate(props) {
  const { spacing, fields, setFieldValue, style } = props

  // eslint-disable-next-line
  const { width } = useWindowSize()

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        spacing={spacing}
      >
        {fields.map((field, index) => {
          let { display = true } = field
          if (field.class === "textfield") {
            if (field.onChange) {
              return (
                display && (
                  <Grid
                    item
                    sm={field.sm}
                    key={index}
                    xs={field.xs}
                    md={field.md}
                  >
                    <TextField
                      onBlur={field.onBlur}
                      key={field.name}
                      size={field.size}
                      disabled={field.disabled}
                      type={field.type}
                      label={field.label}
                      name={field.name}
                      onChange={field.onChange}
                      variant="outlined"
                      fullWidth
                      value={field.value}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline={field.multiline}
                      rows={field.rows}
                      maxRows={field.maxRows}
                      InputProps={field.inputProps}
                      onKeyPress={field.onKeyPress}
                      onKeyUp={field.onKeyUp}
                      onInput={field.onInput}
                      inputProps={field.inputProps}
                    />
                  </Grid>
                )
              )
            } else {
              return (
                display && (
                  <Grid
                    item
                    sm={field.sm}
                    key={index}
                    xs={field.xs}
                    md={field.md}
                  >
                    <TextField
                      onBlur={field.onBlur}
                      key={field.name}
                      disabled={field.disabled}
                      type={field.type}
                      size={field.size}
                      fullWidth
                      // className={classes.heightsForm}
                      label={field.label}
                      name={field.name}
                      variant="outlined"
                      value={field.value}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={field.inputProps}
                      multiline={field.multiline}
                      rows={field.rows}
                      maxRows={field.maxRows}
                      onKeyPress={field.onKeyPress}
                      onKeyUp={field.onKeyUp}
                      onInput={field.onInput}
                    />
                  </Grid>
                )
              )
            }
          }
          if (field.class === "autocomplete") {
            if (field.forceSelect || (display && width < 960)) {
              let menuItemValue = field.optionLabel.substring(
                field.optionLabel.indexOf('"') + 1,
                field.optionLabel.lastIndexOf('"')
              )

              return (
                <Grid item sm={field.sm} xs={field.xs} md={field.md}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id={field.name}>{field.label}</InputLabel>
                    <Select
                      labelId={`${field.name}-label`}
                      id={field.name}
                      key={field.name}
                      disabled={field.disabled}
                      label={field.label}
                      value={field.value}
                      defaultValue={field.defaultValue}
                      name={field.name}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={(e) => {
                        if (field.onChange) {
                          field.onChange(
                            field.options.find(
                              (index) =>
                                index[field.optionValue] === e.target.value
                            )
                          )
                        } else {
                          setFieldValue(field.name, e.target.value)
                        }
                      }}
                    >
                      {field.options.map((e) => {
                        return (
                          <MenuItem
                            value={e[field.optionValue]}
                            disabled={
                              (field.disabledOptions &&
                                field.disabledOptions.indexOf(
                                  e[field.optionValue]
                                ) >= 0) ||
                              e[field.disabledProp]
                                ? true
                                : false
                            }
                          >
                            {e[menuItemValue]}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
            return (
              display && (
                <Grid item sm={field.sm} xs={field.xs} md={field.md}>
                  <CustomAutocomplete
                    key={field.name}
                    disabled={field.disabled}
                    style={field.style}
                    disableClearable={field.disableClearable ? false : true}
                    name={field.name}
                    size={field.size}
                    options={field.options}
                    freeSolo={field.freeSolo}
                    defaultValue={field.defaultValue}
                    getOptionDisabled={field.getOptionDisabled}
                    // eslint-disable-next-line
                    getOptionLabel={(options) => eval(field.optionLabel)}
                    noOptionsText={field.noOptionsText}
                    endAdornment={field.endAdornment}
                    label={field.label}
                    onChange={(_, value, reason) => {
                      if (field.onChange) {
                        field.onChange(value, reason)
                      } else {
                        setFieldValue(field.name, value[field.optionValue])
                      }
                    }}
                    sortBy={field.sortBy}
                    value={field.options.find(
                      (index) => index[field.optionValue] === field.value
                    )}
                    onInputChange={(_, value) => {
                      if (field.onInputChange) {
                        field.onInputChange(value)
                      }
                    }}
                    groupBy={(options) => options[field.groupBy]}
                    onKeyDown={field.onKeyDown}
                    autoHighlight={field.autoHighlight}
                  />
                </Grid>
              )
            )
          }
          if (field.class === "datepicker") {
            function updateDate(newDate) {
              let formatedDate = moment(newDate).format("yyyy")
              return `10/31/${formatedDate}`
            }
            return (
              <Grid item sm={field.sm} key={index} xs={field.xs}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                  <KeyboardDatePicker
                    okLabel=""
                    label={field.label}
                    disabled={field.disabled}
                    cancelLabel=""
                    clearLabel
                    clearable={field.cleareable}
                    autoOk={true}
                    inputVariant="outlined"
                    variant="inline"
                    fullWidth
                    views={field.views ? field.views : undefined}
                    name={field.name}
                    value={field.value}
                    format={field.format ? field.format : "yyyy"}
                    onChange={(_, value) => {
                      if (field.onChange) {
                        field.onChange(value)
                      } else {
                        setFieldValue(field.name, updateDate(value), true)
                      }
                    }}
                    disableFuture={field.disableFuture}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )
          }
          if (field.class === "custom") {
            return (
              display && (
                <Grid
                  item
                  sm={field.sm}
                  xs={field.xs}
                  style={style || field.style}
                  md={field.md}
                >
                  {field.component}
                </Grid>
              )
            )
          }
          return ""
        })}
      </Grid>
    </div>
  )
}
