import React, { useState } from "react"

import { Button, Typography, Fade, Icon, Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import InfoIcon from "@material-ui/icons/Info"

import useStyles from "./styles"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

const DialogWithLoader = (props) => {
  const {
    title,
    subtitle,
    open,
    close,
    onSubmit,
    afterSubmit = null,
    awaitMessage,
    widthLarge = "600px",
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    await onSubmit()
    close()
    if (afterSubmit) {
      await afterSubmit()
    }
    setIsLoading(false)
  }

  const classes = useStyles()

  const Buttons = () => {
    return (
      <div className={classes.buttonsMovile}>
        <Button
          className={classes.cancelButton}
          onClick={close}
          disabled={isLoading ? true : false}
        >
          Cancelar
        </Button>
        <Button
          className={classes.confirmButton}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={isLoading ? true : false}
        >
          {isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
                width: "24px",
                height: "24px",
                marginLeft: "21px",
                marginRight: "21px",
              }}
            />
          ) : (
            "Confirmar"
          )}
        </Button>
      </div>
    )
  }

  const Alert = (props) => (
    <div>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Icon className={classes.infoIcon}>
            <InfoIcon />
          </Icon>
        </Grid>
        <Grid item>
          <Typography
            className={classes.alertMessage}
            variant={"subtitle2"}
            display={"block"}
          >
            {props.message}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )

  return (
    <div>
      <DialogComponent
        tipo="center"
        open={open}
        disableChildHeight
        handleclose={close}
        handleclosedisabled={isLoading ? true : false}
        disableEscapeKeyDown={isLoading ? true : false}
        disableBackdropClick={isLoading ? true : false}
        title={title}
        subtitle={subtitle}
        widthLarge={widthLarge}
        actions={
          <div className={classes.actionDiv}>
            <Fade in={isLoading} timeout={500}>
              <div className={classes.alertDiv}>
                <Alert
                  message={awaitMessage}
                  visibility={isLoading ? "?" : "hidden"}
                />
              </div>
            </Fade>
            <div>
              <Buttons />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default DialogWithLoader
