const TOKEN_KEY = "token"
const USER = "user"
const FIRST_LOGIN = "first_login"
const EXPIRE_DATE = "expireAt"
const USER_ID = "user_id"
const PROFILE_THUMBNAIL = "profile"
const UBICACION = "ubicacion"
const NAVBAR = "navbar"
const FAST_ACCESS = "fast_access"
const WIDGETS = "widgets"
const PUESTO = "puesto"

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY)
}

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY)
}

export const setUser = (user) => {
  localStorage.setItem(USER, user)
}

export const getUser = () => {
  return localStorage.getItem(USER)
}

export const deleteUser = () => {
  localStorage.removeItem(USER)
}

export const setFirstLogIn = (token) => {
  localStorage.setItem(FIRST_LOGIN, token)
}

export const getFirstLogIn = () => {
  return localStorage.getItem(FIRST_LOGIN)
}

export const deleteFirstLogIn = () => {
  localStorage.removeItem(FIRST_LOGIN)
}

export const setExpireTokenDate = (date) => {
  localStorage.setItem(EXPIRE_DATE, date)
}

export const getExpireTokenDate = () => {
  return localStorage.getItem(EXPIRE_DATE)
}

export const deteleExpireTokenDate = () => {
  localStorage.removeItem(EXPIRE_DATE)
}

export const setUserId = (user) => {
  localStorage.setItem(USER_ID, user)
}

export const getUserId = () => {
  return localStorage.getItem(USER_ID)
}

export const deleteUserId = () => {
  localStorage.removeItem(USER_ID)
}

export const setProfileThumbnail = (thumbnail) => {
  localStorage.setItem(PROFILE_THUMBNAIL, thumbnail)
}

export const getProfileThumbnail = () => {
  return localStorage.getItem(PROFILE_THUMBNAIL)
}

export const deleteProfileThumbnail = () => {
  localStorage.removeItem(PROFILE_THUMBNAIL)
}

export const setUbicacion = (location) => {
  localStorage.setItem(UBICACION, location)
}

export const getUbicacion = () => {
  return localStorage.getItem(UBICACION)
}

export const deleteUbicacion = () => {
  localStorage.removeItem(UBICACION)
}

export const setNavbar = (navbar) => {
  localStorage.setItem(NAVBAR, navbar)
}

export const getNavbar = () => {
  return localStorage.getItem(NAVBAR)
}

export const deleteNavbar = () => {
  localStorage.removeItem(NAVBAR)
}

export const setFastAccess = (fastAccess) => {
  localStorage.setItem(FAST_ACCESS, fastAccess)
}

export const getFastAccess = () => {
  return localStorage.getItem(FAST_ACCESS)
}

export const deleteFastAccess = () => {
  localStorage.removeItem(FAST_ACCESS)
}

export const setWidgets = (widgets) => {
  localStorage.setItem(WIDGETS, widgets)
}

export const getWidgets = () => {
  return localStorage.getItem(WIDGETS)
}

export const deleteWidgets = () => {
  localStorage.removeItem(WIDGETS)
}
export const setPuesto = (puesto) => {
  localStorage.setItem(PUESTO, puesto)
}

export const getPuesto = () => {
  return localStorage.getItem(PUESTO)
}

export const deletePuesto = () => {
  localStorage.removeItem(PUESTO)
}
