import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "start",
  },
  secondaryText: {
    fontSize: "1rem",
    textAlign: "center",
  },
  typography: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "22px",
  },
  styleImg: {
    height: "20px",
    margin: "0px",
  },
}))

export default useStyles
