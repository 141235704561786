import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    color: "#3E984C",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  error: {
    textAlign: "center",
    color: "red",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1em",
  },
  div: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%",
  },
}))

export default useStyles
