import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  textfield2: {
    minWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "200px",
    },
  },
  rootSecondNav: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      margin: "0px 0px 15px 0px",
    },
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
  marginContent: {
    margin: "0px 5px 0px 5px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
}))

export default useStyles
