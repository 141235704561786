import { React, forwardRef } from "react"
import { TextField } from "formik-material-ui"
import { Formik, Field } from "formik"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"

import { Grid } from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import { versionDate } from "Components/Validation/Settings/Various/BrandModelVersion"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"

const DatePickerField = ({ field, form, ...other }) => {
  const currentError = form.errors[field.name]

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabledFuture
        autoOk={true}
        inputVariant="outlined"
        variant="inline"
        fullWidth
        views={["year"]}
        clearable
        name={field.name}
        value={field.value}
        format="yyyy"
        helperText={currentError}
        error={Boolean(currentError)}
        onChange={(date) => form.setFieldValue(field.name, date, true)}
        {...other}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ readOnly: true }}
      />
    </MuiPickersUtilsProvider>
  )
}

const Form = forwardRef((props, ref) => {
  const { onSubmit, types } = props
  return (
    <>
      <Formik
        initialValues={{
          version: "",
          inicio: null,
          fin: null,
          tipo_baja_id: null,
        }}
        validationSchema={versionDate}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Nombre versión"
                  name="version"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  name="inicio"
                  component={DatePickerField}
                  label="Incio"
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field name="fin" component={DatePickerField} label="Fin" />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Autocomplete
                  getOptionLabel={(types) => types.nombre}
                  options={types}
                  onChange={(event, value) => {
                    setFieldValue("tipo_baja_id", value.id)
                  }}
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      fullWidth
                      label="Tipo de vehículo"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </div>
            </Grid>

            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        )}
      </Formik>
    </>
  )
})
export default Form
