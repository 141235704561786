import React, { useEffect } from "react"

import GrillTemplate from "Components/UI/Templates/Plant/Grill"

const Grill = () => {
  useEffect(() => {
    document.title = "Grilla"
  }, [])

  return <GrillTemplate />
}
export default Grill
