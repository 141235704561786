import React, { useRef, useState } from "react"

import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import PrintIcon from "@material-ui/icons/Print"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import DeleteIcon from "@material-ui/icons/Delete"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import SetState from "Components/Hooks/SetState"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Edit from "Components/UI/Organisms/Vehicles/Quotations/Edit"

import { columnTheme } from "./styles"
import { printPDF } from "Helpers"
import { interfaces } from "service/interfaces"
import {
  TIPO_BAJA,
  TIPO_COTIZACION,
  INGRESO_PREDIO,
  CANCELATION_MOTIVES,
} from "Constants"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

import { printDisabled } from "Components/UI/Organisms/Vehicles/Quotations/Content"
import moment from "moment"

import EditContextProvider from "Components/Hooks/ContextEdit"

import { Form, Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import {
  cancelQuote,
  validationDuplicateQuote,
} from "Components/Validation/Vehicles/SearchVehicles"
import { getPuesto } from "service/helpers/localstorage"

const QuotationsTable = (props) => {
  const { render, setRender, info, setInfo, permissions } = props
  const [loading, setLoading] = useState(false)
  const [oneQuoteTable, setOneQuoteTable] = useState([])

  const puesto = getPuesto() || ""

  const windowSize = useMediaQuery("(min-width:1000px)")
  const createDismissableSnackbar = useCustomSnackbar()
  const { state: quote, setOpen: openQuote, setClose: closeQuote } = SetState()
  const {
    state: confirm,
    setOpen: openConfirm,
    setClose: closeConfirm,
  } = SetState()
  const {
    state: cancel,
    setOpen: openCancel,
    setClose: closeCancel,
  } = SetState()
  const {
    state: duplicate,
    setOpen: openDuplicate,
    setClose: closeDuplicate,
  } = SetState()
  const {
    state: remove,
    setOpen: openRemove,
    setClose: closeRemove,
  } = SetState()
  const {
    state: noQuote,
    setOpen: openNoQuote,
    setClose: closeNoQuote,
  } = SetState()

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  function renderList() {
    setRender(!render)
  }

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
      permissions: {},
    })
    try {
      const result = await interfaces.quotationsInterface.list(options)
      setInfo({
        filters: result.data.filters,
        rows: result.data.data,
        header: result.header.header.count,
        permissions: result.data.permissions,
      })
      callback()
    } catch (error) {}
  }

  async function openDialog(infoId) {
    setOneQuoteTable(infoId)
    const findOneId = info.rows.find((item) => item.id === infoId.id)
    await interfaces.quotationsInterface.oneQuotation(infoId.id).then((res) => {
      openQuote({ dataObject: res.data, quoteInfoById: findOneId })
    })
  }

  const printQuote = async (e, id) => {
    e.stopPropagation()
    setLoading(true)
    await interfaces.quotationsInterface
      .print(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar("Cotizaciones impresas con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }
  async function confirmQuote(id) {
    setLoading(true)
    await interfaces.quotationsInterface
      .confirmQuote(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
    setRender(!render)
    closeConfirm()
    closeQuote()
  }
  async function declineQuote(id, values) {
    setLoading(true)
    await interfaces.quotationsInterface
      .declineQuote(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
    setRender(!render)
    closeCancel()
    closeQuote()
    closeNoQuote()
  }
  async function restoreQuote(data) {
    const body = { tipo_baja_id: data.tipo_baja_id, dominio: data.dominio }

    setLoading(true)
    await interfaces.quotationsInterface
      .restoreQuote(data.id, body)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setRender(!render)
    setLoading(false)
    closeQuote()
  }

  const duplicateQuote = async (id, values) => {
    setLoading(true)
    await interfaces.quotationsInterface
      .duplicateQuote(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
    setRender(!render)
    closeDuplicate()
    closeQuote()
  }

  function openConfirmDialog(e, file) {
    e.stopPropagation()

    openConfirm({ id: file })
  }

  function openCancelDialog(e, file) {
    e.stopPropagation()
    openCancel({ id: file })
  }

  function openRemoveDialog(e, id) {
    e.stopPropagation()
    openRemove({ id: id })
  }

  const deleteQuotation = async () => {
    const id = remove.id
    setLoading(true)

    await interfaces.quotationsInterface
      .deleteQuotation(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
    closeRemove()
    closeQuote()
    setRender(!render)
  }

  const actions = (file) => {
    return (
      <div style={{ display: "flex", marginLeft: "0.3rem" }}>
        <Tooltip title="Imprimir">
          <IconButton
            onClick={(e) => printQuote(e, file.id)}
            color={file.estado === "A COTIZAR" ? "" : "primary"}
            size={windowSize ? "medium" : "normal"}
            disabled={printDisabled(file.estado) || puesto === '"Cotizador/a"'}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Confirmar">
          <IconButton
            onClick={(e) => openConfirmDialog(e, file.id)}
            size={windowSize ? "medium" : "normal"}
            disabled={
              !file.confirm ||
              !permissions.allowConfirm ||
              puesto === '"Cotizador/a"'
            }
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Declinar">
          <IconButton
            onClick={(e) => openCancelDialog(e, file.id)}
            size={windowSize ? "medium" : "normal"}
            disabled={
              !file.decline ||
              file.estado === "NO ADJUDICADO" ||
              file.estado === "CANCELADO" ||
              !permissions.allowDecline ||
              file.estado === "NO COTIZA" ||
              puesto === '"Cotizador/a"'
            }
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton
            onClick={(e) => openRemoveDialog(e, file.id)}
            size={windowSize ? "medium" : "normal"}
            disabled={!permissions.allowDelete || puesto === '"Cotizador/a"'}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const colorExpirationDate = (vencimiento) => {
    let today = new Date()
    const format = "DD-MM-YYYY"
    var subtractDay = moment().subtract(1, "day").format("DD/MM/YYYY")

    if (subtractDay === vencimiento) {
      return (
        <span style={{ color: "orange", fontWeight: "bold" }}>
          {vencimiento}
        </span>
      )
    } else if (!moment(today, format).isBefore(moment(vencimiento, format))) {
      return (
        <span style={{ color: "red", fontWeight: "bold" }}>{vencimiento}</span>
      )
    } else {
      return <span>{vencimiento}</span>
    }
  }

  const colorQuoteDate = (estado) => {
    return (
      <span
        style={
          estado === "COTIZADO" || estado === "VEHICULO SIN COSTO"
            ? { color: "green", fontWeight: "bold" }
            : {}
        }
      >
        {estado}
      </span>
    )
  }

  const RowsUsed = () =>
    info.rows.map((file) => [
      "",
      file.dominio,
      file.cia_seguros,
      file.tipo_baja,
      file.vehiculo,
      colorExpirationDate(file.vencimiento),
      file.fecha_creacion,
      file.tipo_cotizacion,
      colorQuoteDate(file.estado),
      actions(file),
      "",
    ])

  const fields = () => [
    {
      label: "Motivo de cancelación",
      name: "motivo_cancelacion",
      options: CANCELATION_MOTIVES,
      optionLabel: `options["label"]`,
      optionValue: "value",
      sm: 12,
      xs: 12,
      class: "autocomplete",
    },
  ]

  const duplicateFields = () => [
    {
      label: "Tipo de baja",
      name: "tipo_baja_id",
      options: TIPO_BAJA,
      optionLabel: `options["baja"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      class: "autocomplete",
      getOptionDisabled: (option) =>
        option.baja === quote?.quoteInfoById.tipo_baja,
    },
    {
      label: "Monto de cotización",
      name: "cotizacion_restos",
      sm: 12,
      xs: 12,
      type: "number",
      class: "textfield",
    },
  ]

  const columns = columnsForTable(
    [
      {
        name: "dominio",
        label: "Dominio",
        display: windowSize ? true : false,
        marginLeft: windowSize ? "10px" : "",
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "cia_seguros",
        label: "Compañía",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        fullWidth: true,
        options: info.filters.compañia,
        customBodyRender: true,
      },
      {
        name: "tipo_baja",
        label: "Tipo de baja",
        display: true,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "baja",
        options: TIPO_BAJA,
      },
      {
        name: "vehiculo",
        label: "Vehículo",
        filterLabel: "Cod",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "fecha_vencimiento",
        label: "Vencimiento",
        display: true,
        filter: false,
        sort: false,
        fullWidth: true,
      },
      {
        name: "fecha_creacion",
        label: "Creacion",
        display: true,
        filter: false,
        sort: false,
        fullWidth: true,
      },
      {
        name: "tipo_cotizacion",
        label: "Tipo de cotización",
        display: true,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "value",
        options: TIPO_COTIZACION,
      },
      {
        label: "Estado",
        name: "estado",
        display: true,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "value",
        options: info.filters.estados,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "marca",
        label: "Marca",
        display: false,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "marca",
        options: info.filters.marca,
        customBodyRender: true,
      },
      {
        name: "modelo",
        label: "Modelo",
        display: false,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "modelo",
        customBodyRender: true,
        advancedOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Modelo"}
                  filterName={"modelo"}
                  noOptionsText={"Seleccione una marca primero"}
                  options={info.filters.modelo.filter((item) => {
                    return item.marca.marca === filterList[10][0]
                  })}
                />
              )
            }
          },
        },
      },
      {
        name: "ingreso_predio",
        label: "Ingreso a predio",
        display: false,
        filter: true,
        sort: false,
        fullWidth: true,
        filterName: "ingreso_predio",
        options: INGRESO_PREDIO,
        customBodyRender: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    info.filters
  )

  return (
    <>
      {quote.suspense && (
        <EditContextProvider>
          <Edit
            open={quote.open}
            handleClose={closeQuote}
            dataQuote={quote.dataObject}
            quoteInfoById={quote.quoteInfoById}
            info={info.rows}
            renderList={renderList}
            setRender={setRender}
            render={render}
            confirmQuote={confirmQuote}
            declineQuote={declineQuote}
            duplicateQuote={duplicateQuote}
            printQuote={printQuote}
            restoreQuote={restoreQuote}
            oneQuoteTable={oneQuoteTable}
            openCancel={openCancel}
            openConfirm={openConfirm}
            openDelete={openRemoveDialog}
            openDuplicate={openDuplicate}
            loading={loading}
            openNoQuote={openNoQuote}
            permissions={permissions}
            puesto={puesto}
          />
        </EditContextProvider>
      )}
      {confirm.suspense && (
        <DialogComponent
          open={confirm.open}
          title="¿Desea confirmar esta cotización?"
          subtitle="Esta acción creará el vehículo con los datos de la cotización."
          handleclose={closeConfirm}
          widthLarge={"500px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickCancel={() => closeConfirm()}
              onClickSubmit={() =>
                confirmQuote(confirm.id ? confirm.id : quote?.quoteInfoById.id)
              }
            />
          }
        />
      )}
      {cancel.suspense && (
        <DialogComponent
          open={cancel.open}
          title="¿Desea rechazar esta cotización?"
          subtitle="Esta acción es irreversible!"
          handleclose={closeCancel}
          widthLarge={"500px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          children={
            <Formik
              onSubmit={(values) =>
                declineQuote(
                  cancel.id ? cancel.id : quote?.quoteInfoById.id,
                  values
                )
              }
              initialValues={{
                motivo_cancelacion: null,
              }}
              validationSchema={cancelQuote}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ setFieldValue, isSubmitting, values }) => (
                <Form>
                  <InputsWithValidate
                    fields={fields(values)}
                    spacing={2}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                  />
                  <button
                    style={{ display: "none" }}
                    type="submit"
                    ref={formSubmitRef}
                  />
                </Form>
              )}
            </Formik>
          }
          actions={
            <CancelConfirmButtons
              onClickCancel={() => closeCancel()}
              onClickSubmit={handleSubmit}
              isLoading={loading}
              disabledSubmit={loading}
            />
          }
        />
      )}
      {duplicate.suspense && (
        <DialogComponent
          open={duplicate.open}
          title="Duplicar esta cotización"
          handleclose={closeDuplicate}
          widthLarge={"500px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          children={
            <Formik
              onSubmit={(values) =>
                duplicateQuote(quote?.quoteInfoById.id, values)
              }
              initialValues={{
                tipo_baja_id: null,
                cotizacion_restos: null,
              }}
              validationSchema={validationDuplicateQuote}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ setFieldValue, isSubmitting, values }) => (
                <Form>
                  <InputsWithValidate
                    fields={duplicateFields(values)}
                    spacing={2}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                  />
                  <button
                    style={{ display: "none" }}
                    type="submit"
                    ref={formSubmitRef}
                  />
                </Form>
              )}
            </Formik>
          }
          actions={
            <CancelConfirmButtons
              onClickCancel={() => closeDuplicate()}
              onClickSubmit={handleSubmit}
              isLoading={loading}
              disabledSubmit={loading}
            />
          }
        />
      )}
      {remove.suspense && (
        <DialogComponent
          open={remove.open}
          title="Eliminar esta cotización"
          handleclose={closeRemove}
          widthLarge={"500px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickCancel={closeRemove}
              onClickSubmit={deleteQuotation}
              isLoading={loading}
              disabledSubmit={loading}
            />
          }
        />
      )}
      {noQuote.suspense && (
        <DialogComponent
          open={noQuote.open}
          title="No cotiza, esta seguro de este cambio?"
          handleclose={closeNoQuote}
          widthLarge={"600px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickCancel={closeNoQuote}
              onClickSubmit={() =>
                declineQuote(quote?.quoteInfoById.id, {
                  motivo_cancelacion: "NO COTIZA",
                })
              }
              isLoading={loading}
              disabledSubmit={loading}
            />
          }
        />
      )}

      <MuiDatables
        columns={columns}
        data={RowsUsed()}
        rows={25}
        title={`Cantidad de cotizaciones: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar dominio, N° siniestro..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        padding={windowSize ? "none" : "medium"}
        onRowClick={(_, index) => openDialog(info.rows[index.dataIndex])}
      />
    </>
  )
}
export default QuotationsTable
