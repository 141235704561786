import React, { useState } from "react"

import GetAppIcon from "@material-ui/icons/GetApp"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import Stock from "Components/UI/Organisms/Sales/Stock"
import ExportStock from "Components/UI/Organisms/Sales/Stock/ExportStock"

const StockTemplate = () => {
  const [openExport, setOpenExport] = useState({ open: false, suspense: false })
  const [canExport, setCanExport] = useState(true)

  const openDialogExport = () => {
    setOpenExport({ open: true, suspense: true })
  }
  const closeDialogExport = () => {
    setOpenExport({ ...openExport, open: false })
  }

  const BUTTONS = [
    {
      menuIcon: <GetAppIcon fontSize="small" />,
      menuTitle: "Exportar",
      buttonContent: <GetAppIcon />,
      disabled: canExport,
      onClick: openDialogExport,
    },
  ]

  return (
    <TemplateGlobal
      navbar={
        <NavBarGlobal title="Ventas" subTitle="Stock" buttons={BUTTONS} />
      }
      table={
        <>
          <Stock setCanExport={setCanExport} />
          {openExport.suspense && (
            <ExportStock open={openExport.open} onClose={closeDialogExport} />
          )}
        </>
      }
    />
  )
}

export default StockTemplate
