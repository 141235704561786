import { axiosService } from "service"
import { plantUsedEndpoints } from "service/sources/used"

const list = (options) => {
  return axiosService.get(plantUsedEndpoints.LIST + options)
}

const usedInfo = (id) => {
  return axiosService.get(plantUsedEndpoints.USED_INFO + "/" + id)
}

const uploadPhoto = (id, files, initialName) => {
  return axiosService.uploadFiles(
    plantUsedEndpoints.UPLOAD_PHOTO + id,
    files,
    initialName
  )
}

const deletePhoto = (id, index) => {
  return axiosService.post(
    plantUsedEndpoints.DELETE_PHOTO + id + "?index=" + index
  )
}

const beforeNew = () => {
  return axiosService.get(plantUsedEndpoints.BEFORE_NEW)
}

const newUsed = (body) => {
  return axiosService.post(plantUsedEndpoints.NEW_USED, body)
}

const uploadFiles = (id, files, name) => {
  return axiosService.uploadSingleFile(
    plantUsedEndpoints.UPLOAD_FILES + id,
    files,
    name
  )
}

const deleteFile = (id, index) => {
  return axiosService.post(
    plantUsedEndpoints.DELETE_FILE + id + "?index=" + index
  )
}

const update = (id, body) => {
  return axiosService.put(plantUsedEndpoints.UPDATE + id, body)
}

const stockSticker = (id) => {
  return axiosService.getPDF(plantUsedEndpoints.STICKER + id)
}

const deleteUsed = (id) => {
  return axiosService.del(plantUsedEndpoints.DELETE_USED + id)
}

export const plantUsed = {
  list,
  usedInfo,
  uploadPhoto,
  deletePhoto,
  beforeNew,
  newUsed,
  uploadFiles,
  deleteFile,
  update,
  stockSticker,
  deleteUsed,
}
