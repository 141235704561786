import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import QuotationsTable from "Components/UI/Organisms/Vehicles/Quotations"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import GetAppIcon from "@material-ui/icons/GetApp"
import { interfaces } from "service/interfaces"

import Create from "Components/UI/Organisms/Vehicles/Quotations/Create"
import Loader from "Components/UI/Atoms/Loader"
import { oemColorButton } from "Helpers"

const QuotationsTemplate = () => {
  const [render, setRender] = useState(false)
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const { state: quote, setOpen: openQuote, setClose: closeQuote } = SetState()
  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
    permissions: {},
  })

  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const permissions = info?.permissions

  const exportQuotation = async () => {
    setLoading(true)
    await interfaces.quotationsInterface
      .exportQuote()
      .then((res) => {
        window.open(res.data, "_self", "width=1200,height=900,scrollbars=yes")
        createDismissableSnackbar("Cotizaciones impresas con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const BUTTONS = [
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: permissions.allowCreate
        ? oemColorButton(pathname, location)
        : {},
      disabled: !permissions.allowCreate,
      onClick: openQuote,
    },
    {
      menuIcon: <GetAppIcon fontSize="small" />,
      menuTitle: "Exportar",
      buttonContent: <GetAppIcon />,
      onClick: exportQuotation,
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="VEHÍCULOS"
            subTitle="Cotizaciones"
            buttons={BUTTONS}
          />
        }
        table={
          <QuotationsTable
            render={render}
            setRender={setRender}
            info={info}
            setInfo={setInfo}
            permissions={permissions}
          />
        }
      />

      {quote.suspense && (
        <Create
          open={quote.open}
          handleClose={closeQuote}
          render={render}
          setRender={setRender}
        />
      )}
      {loading ? <Loader /> : null}
    </>
  )
}

export default QuotationsTemplate
