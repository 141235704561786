import React, { useState } from "react"
import { read, utils } from "xlsx"

import { Grid, Typography } from "@material-ui/core"

import CustomButton from "Components/UI/Atoms/CustomButton"
import { ButtonInputXls } from "Components/UI/Atoms/ButtonInputXls"
import BasicTable from "Components/UI/Atoms/BasicTable"
import { formatter } from "Helpers"
import Loader from "Components/UI/Atoms/Loader"

const ContentPPI = ({ dataXlsx, setDataXlsx }) => {
  const [loading, setLoading] = useState(false)

  const readUploadFile = (e) => {
    setLoading(true)
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)
        setDataXlsx(json)
        setLoading(false)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  return (
    <>
      {loading && <Loader />}
      <Grid container spacing={3}>
        {dataXlsx.length > 0 ? (
          <BasicTable
            col={[
              { title: "Codigo", style: { width: "5%" } },
              { title: "Producto", style: { width: "20%" } },
              { title: "Vehículo", style: { width: "15%" } },
              { title: "Dominio / Nº V", style: { width: "12%" } },
              { title: "Calidad", style: { width: "8%" } },
              {
                title: "USD anterior",
                style: {
                  width: "10%",
                  color: "red",
                  textDecoration: "line-through",
                },
              },
              { title: "USD nuevo", style: { width: "10%", color: "green" } },
              {
                title: "ARS anterior",
                style: {
                  width: "10%",
                  color: "red",
                  textDecoration: "line-through",
                },
              },
              { title: "ARS nuevo", style: { width: "10%", color: "green" } },
            ]}
            stylePaper={{
              height: "490px",
              overflowY: "scroll",
            }}
            size={"small"}
            rows={dataXlsx.map((row) => {
              return {
                codigo: row.Código,
                producto: row.Producto,
                vehiculo: `${row.Marca} ${row.Modelo} ${row.Año} `,
                dominioNrodev:
                  row.Dominio + " / " + (row["Nº V"] ? row["Nº V"] : ""),
                calidad: row.Calidad,
                previousUsd: row.Dolar
                  ? formatter.format(row.Dolar)
                  : formatter.format(0),
                newUsd: row["Dolar nuevo"]
                  ? formatter.format(row["Dolar nuevo"])
                  : formatter.format(0),
                price: row.Precio
                  ? formatter.format(row.Precio)
                  : formatter.format(0),
                newPrice: row["Precio nuevo"]
                  ? formatter.format(row["Precio nuevo"])
                  : formatter.format(0),
              }
            })}
          />
        ) : (
          <>
            <Grid item sm={6} xs={12}>
              <CustomButton text="BÚSQUEDA DE ARTÍCULOS" fullWidth disabled />
              <Typography style={{ marginTop: "50px" }}>
                <b>
                  Esta búsqueda permite la búsqueda de artículos del stock
                  mediante el filtro de producto, marca, modelo, versión, año de
                  inicio y año fin.
                </b>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <b>Esta herramienta estará habilitada próximamente!</b>
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <ButtonInputXls
                name="xls"
                textLater="EXCEL IMPORTADO"
                textBefore="IMPORTAR EXCEL"
                value={dataXlsx}
                id={1}
                onChange={readUploadFile}
              />
              <Typography style={{ marginTop: "50px" }}>
                <b>
                  El archivo a utilizar en este importador tiene que ser el
                  proveniente de la exportación en Ventas / Stock.
                </b>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <b>
                  La columna con los nuevos precios en pesos y en dólares se
                  tienen que llamar:
                </b>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <b>- Precio nuevo</b>
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                <b>- Dolar nuevo</b>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default ContentPPI
