import React from "react"
import ReactPlayer from "react-player"

import { Tooltip, Fab } from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import PauseIcon from "@material-ui/icons/Pause"

import ModalSimple from "Components/UI/Molecules/Modals/Simple"

const Videoplayer = (props) => {
  const { video, setVideo, closeVideo } = props

  function downloadFile() {
    return window.location.assign(video.url)
  }

  function toggleFullscreen() {
    if (!video.fullScreen) {
      setVideo({ fullScreen: true, height: "100vh", width: "100vw" })
    } else {
      setVideo({ fullScreen: false, height: "90vh", width: "70vw" })
    }
  }

  return (
    <ModalSimple
      open={video.open}
      onClose={closeVideo}
      div={
        <div style={{ position: "relative" }}>
          <div onClick={() => setVideo({ play: !video.play })}>
            <ReactPlayer
              url={video.url}
              playing={video.play}
              loop
              height={video.height}
              width={video.width}
            />
          </div>
          <Tooltip title={video.play ? "Pausar" : "Reproducir"}>
            <Fab
              color="primary"
              style={{ position: "absolute", right: 140, bottom: 30 }}
              onClick={() => setVideo({ play: !video.play })}
            >
              {video.play ? <PauseIcon /> : <PlayArrowIcon />}
            </Fab>
          </Tooltip>
          <Tooltip title="Maximizar">
            <Fab
              color="primary"
              style={{ position: "absolute", right: 80, bottom: 30 }}
              onClick={() => toggleFullscreen()}
            >
              {video.fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Fab>
          </Tooltip>
          <Tooltip title="Descargar">
            <Fab
              color="primary"
              style={{ position: "absolute", right: 20, bottom: 30 }}
              onClick={() => downloadFile()}
            >
              <GetAppIcon />
            </Fab>
          </Tooltip>
        </div>
      }
    />
  )
}

export default Videoplayer
