import React from "react"
import { Grid, Tabs, Tab } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useStyles from "./styles"
const ContentWithSubTabs = (props) => {
  const { listVerticalTabs, children, value, setValue } = props
  const mediumViewport = useMediaQuery("(min-width:600px)")

  const classes = useStyles()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Grid container>
        <Grid item sm={3} container justify="center" alignItems="center">
          <Tabs
            indicatorColor="primary"
            scrollButtons="on"
            textColor="primary"
            orientation={mediumViewport ? "vertical" : "horizontal"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            {listVerticalTabs.map((item, index) => (
              <Tab key={index} label={item.title} disabled={item.disabled} />
            ))}
          </Tabs>
        </Grid>
        <Grid item sm={9} xs={12} className={classes.gridScroll}>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}
export default ContentWithSubTabs
