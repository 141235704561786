import React, { useState } from "react"

import ViewImages from "Components/UI/Organisms/Plant/Used/Dialog/Content/Files/ViewImages"
import ViewFiles from "Components/UI/Organisms/Plant/Used/Dialog/Content/Files/ViewFiles"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"

export default function Files(props) {
  const { img, id, file, data, setImg } = props
  const [tab, setTab] = useState(0)
  function _renderForms(number) {
    switch (number) {
      case 0:
        return <ViewImages img={img} id={id} setImg={setImg} data={data} />
      case 1:
        return <ViewFiles file={file} id={id} data={data} />

      default:
        break
    }
  }

  const verticalTabs = [
    {
      id: 0,
      title: "Fotos",
    },
    {
      id: 1,
      title: "Archivos",
    },
  ]

  return (
    <ContentWithSubTabs
      listVerticalTabs={verticalTabs}
      value={tab}
      setValue={setTab}
      children={<div style={{ margin: "20px" }}>{_renderForms(tab)}</div>}
    />
  )
}
