import React, { useContext, useState } from "react"

import { Grid } from "@material-ui/core"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogUpload from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/MainPhotos/DialogUpload"
import DeletePhoto from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/MainPhotos/DialogDelete"
import PictureCardWithZoom from "Components/UI/Atoms/PictureCard/PictureCardWithZoom"
import { interfaces } from "service/interfaces"
import { FormContext } from "Components/Hooks/ContextForm"
import img1 from "../Assets/1.jpg"
import img2 from "../Assets/2.jpg"
import img3 from "../Assets/3.jpg"
import img4 from "../Assets/4.jpg"
import img5 from "../Assets/5.jpg"
import img6 from "../Assets/6.jpg"

import { EditContext } from "Components/Hooks/ContextEdit"

import { styles } from "./styles"

const MainFotograps = () => {
  const { formEdit } = useContext(FormContext)
  const [edit] = useContext(EditContext)
  const createDismissableSnackbar = useCustomSnackbar()

  const [dataActions, setDataActions] = useState({})
  const [change, setChange] = useState()
  const [loading, setLoading] = useState(false)

  const classes = styles()

  function handleOpenActions(data, index, openRemove, openUpload) {
    setDataActions({
      data: data,
      index: index,
      openRemove: openRemove,
      openUpload: openUpload,
      id: formEdit.vehicle.id,
    })
  }
  function handleChange(e) {
    e.preventDefault()
    let img = e.target.files[0]
    setChange({
      ...change,
      file: img,
    })
  }

  function handleCloseActions() {
    setChange()
    setDataActions({
      open: false,
      id: null,
    })
  }
  function handleReplace(index, arr) {
    let imgUp = URL.createObjectURL(arr)
    formEdit.mainPhotos[index] = imgUp
  }
  function handleDeleteContext(index) {
    let position = index - 1
    formEdit.mainPhotos[position] = ""
  }

  async function handleSubmitDelete(arr, index) {
    setLoading(true)
    if (arr !== "" && arr !== undefined) {
      await interfaces.vehicleInterface
        .deleteFile("fotografias", index, formEdit.vehicle.dominio)
        .then((res) => {
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          handleDeleteContext(index)
          handleCloseActions()
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
          handleCloseActions()
        })
    } else {
      createDismissableSnackbar("Esta foto no existe! ", {
        variant: "error",
      })
      handleCloseActions()
    }
    setLoading(false)
  }

  async function handleSubmitUpload(value, index) {
    setLoading(true)
    await interfaces.vehicleInterface
      .uploadPhoto(value, index + 1, formEdit.vehicle.dominio, "fotografias")
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleReplace(index, value)
        handleCloseActions()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        handleCloseActions()
      })
    setLoading(false)
  }

  const titles = [
    {
      id: 1,
      name: "Foto frente",
      img: formEdit.mainPhotos[0] === "" ? img1 : formEdit.mainPhotos[0],
    },
    {
      id: 2,
      name: "Foto izquierda",
      img: formEdit.mainPhotos[1] === "" ? img2 : formEdit.mainPhotos[1],
    },
    {
      id: 3,
      name: "Foto atrás",
      img: formEdit.mainPhotos[2] === "" ? img3 : formEdit.mainPhotos[2],
    },
    {
      id: 4,
      name: "Foto derecha",
      img: formEdit.mainPhotos[3] === "" ? img4 : formEdit.mainPhotos[3],
    },
    {
      id: 5,
      name: "Foto motor",
      img: formEdit.mainPhotos[4] === "" ? img5 : formEdit.mainPhotos[4],
    },
    {
      id: 6,
      name: "Foto interior",
      img: formEdit.mainPhotos[5] === "" ? img6 : formEdit.mainPhotos[5],
    },
  ]

  return (
    <div className={classes.root}>
      <Grid container alignContent="center" justify="center" spacing={2}>
        {titles.map((item, index) => (
          <Grid item xs={12} xl={4} sm={6} md={4}>
            <PictureCardWithZoom
              img={
                formEdit.mainPhotos[index] === ""
                  ? item.img
                  : formEdit.mainPhotos[index]
              }
              editIcon
              alt={item.name}
              txt={item.name}
              edit={edit}
              handleDelete={() =>
                handleOpenActions(
                  formEdit.mainPhotos[index] === ""
                    ? item.img
                    : formEdit.mainPhotos[index],
                  index + 1,
                  true,
                  false
                )
              }
              handleUpload={() => handleOpenActions(item, index, false, true)}
              dataUpload={item}
              index={index + 1}
              id={formEdit.vehicle.id}
              swipes={titles}
            />
          </Grid>
        ))}
        <DeletePhoto
          data={dataActions}
          open={dataActions.openRemove}
          handleClose={handleCloseActions}
          onSubmit={handleSubmitDelete}
          loading={loading}
        />
        <DialogUpload
          handleChange={handleChange}
          change={change}
          data={dataActions}
          onSubmit={handleSubmitUpload}
          handleClose={handleCloseActions}
          open={dataActions.openUpload}
          loading={loading}
        />
      </Grid>
    </div>
  )
}
export default MainFotograps
