import React, { useState, useEffect } from "react"
import { useSnackbar } from "notistack"

import { CardMedia, Grid, Hidden } from "@material-ui/core"

import { Form, Formik } from "formik"
import ButtonVehicle from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/ButtonVehicle"
import ButtonChangePhoto from "../ButtonChangePhoto"
import { interfaces } from "service/interfaces"
import PerceptionsTable from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/PerceptionsTable"

import { useStyles } from "../style"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import CustomButton from "Components/UI/Atoms/CustomButton"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import UsersTable from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/UsersTable"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { TIPO_COTIZACION } from "Constants"
import { newInsuranceValidation } from "Components/Validation/Settings/Various/Insurance"
import SetState from "Components/Hooks/SetState"

const FormCompany = (props) => {
  const classes = useStyles()
  const {
    data,
    id,
    handleRender,
    handleResetSelect,
    changes,
    setChanges,
    listCompany,
    setDisbledOthers,
  } = props

  const [isDirty, setIsDirty] = useState(false)

  const {
    state: openTable,
    setOpen: setOpenTable,
    setClose: setCloseTable
  } = SetState()

  const {
    state: openUsers,
    setOpen: setOpenUsers,
    setClose: setCloseUsers
  } = SetState()

  const {
    state: openVehicle,
    setOpen: setOpenVehicle,
    setClose: setCloseVehicle
  } = SetState()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setChanges(data)
    setIsDirty(false)
    // eslint-disable-next-line
  }, [id])
  useEffect(() => {
    isDirty && setDisbledOthers(true)
    // eslint-disable-next-line
  }, [isDirty])

  function _render() {
    handleRender()
    handleResetSelect()
  }
  const onSubmit = (values) => {
    values.porcentaje_compulsa = Number(
      Math.round(Number(values.porcentaje_compulsa) * 100) / 100
    ).toFixed(2)
    // .substring(0, 3)

    values.percepciones = Array.isArray(changes.percepciones)
      ? JSON.stringify(changes.percepciones)
      : changes.percepciones

    values.contactos = Array.isArray(changes.contactos)
      ? JSON.stringify(changes.contactos)
      : changes.contactos
    interfaces.configInterface
      .updateCompany(id, values)
      .then((res) => {
        enqueueSnackbar(res.data, {
          variant: "success",
        })
        _render()
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setDisbledOthers(false)
  }
  function changePhoto(value) {
    setChanges({
      ...changes,
      foto: value,
    })
  }
  const objeto = (setFieldValue, values) => [
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={3}
        >
          <Grid item>
            <CardMedia
              style={{
                width: "150px",
                height: "150px",
              }}
              component="img"
              image={
                !changes.foto
                  ? process.env.PUBLIC_URL + "/Images/noPhoto.jpg"
                  : changes.foto
              }
            />
            <ButtonChangePhoto
              handleRender={handleRender}
              changePhoto={changePhoto}
              id={id}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: "Nombre",
      name: "nombre",
      class: "textfield",
      sm: 12,
      xs: 12,
      value: values.nombre ? values.nombre : "",
      select: false,
      uppercase: true,
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          key={listCompany.map((e) => e.id)}
          name="tipo_cotizacion"
          options={TIPO_COTIZACION}
          getOptionLabel={(e) => e.label}
          value={values.tipo_cotizacion.length ? values.tipo_cotizacion : []}
          label={"Tipo cotización"}
          onChange={(_, value) => {
            setFieldValue("tipo_cotizacion", value)
          }}
          tagName={"value"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      label: "Nombre plataforma ",
      name: "nombre_plataforma",
      class: "textfield",
      sm: 6,
      xs: 12,
      value: values.nombre_plataforma ? values.nombre_plataforma : "",
      select: false,
      uppercase: true,
    },

    {
      label: "Porcentaje compulsa",
      name: "porcentaje_compulsa",
      class: "textfield",
      sm: 6,
      xs: 12,
      value: values.porcentaje_compulsa ? values.porcentaje_compulsa : 0,
      select: false,
      type: "number",
    },
    {
      class: "custom",
      xs: 12,
      sm: 12,
      component: (
        <CustomButton
          text="Tabla de percepciones"
          style={{ width: "100%" }}
          onClick={setOpenTable}
        />
      ),
    },
    {
      class: "custom",
      xs: 12,
      sm: 12,
      component: (
        <CustomButton
          text="Contactos / Usuarios"
          style={{ width: "100%" }}
          onClick={setOpenUsers}
        />
      ),
    },
    {
      class: "custom",
      xs: 12,
      sm: 12,
      component: (
        <Hidden xsDown>
          <CustomButton
            className={classes.btn}
            style={{ width: "100%" }}
            onClick={setOpenVehicle}
            text={"Vehículos"}
            color="grey"
          />
        </Hidden>
      ),
    },
  ]
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
        padding: "10px",
      }}
    >
      <Formik
        initialValues={{
          nombre: data.nombre,
          nombre_plataforma: data.nombre_plataforma,
          porcentaje_compulsa: data.porcentaje_compulsa,
          tipo_cotizacion: TIPO_COTIZACION.filter((e) =>
            data.tipo_cotizacion.includes(e.value)
          ),
        }}
        enableReinitialize
        validationSchema={newInsuranceValidation}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form onChange={() => setIsDirty(true)}>
            <InputsWithValidate
              fields={objeto(setFieldValue, values)}
              spacing={1}
              setFieldValue={setFieldValue}
            />
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <CancelConfirmButtons
                onClickCancel={handleResetSelect}
                disabledSubmit={
                  JSON.stringify(data) === JSON.stringify(values) ? true : false
                }
                disabledCancel={
                  JSON.stringify(data) === JSON.stringify(values) ? true : false
                }
                noMargin={true}
              />
            </Grid>
          </Form>
        )}
      </Formik>
      {!openVehicle.open ? null : (
        <ButtonVehicle open={openVehicle.open} handleClose={setCloseVehicle} id={id} />
      )}

      <PerceptionsTable
        open={openTable.open}
        handleclose={setCloseTable}
        data={data}
        setChanges={setChanges}
        changes={changes}
      />

      <UsersTable
        open={openUsers.open}
        handleclose={setCloseUsers}
        data={data}
        setChanges={setChanges}
        changes={changes}
      />
    </div>
  )
}

export default FormCompany
