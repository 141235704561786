import React, { useRef, useState } from "react"
import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"
import { useEffectOnce } from "react-use"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { Grid, InputAdornment } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import useStyles from "./styles"

export default function SearchNewOrder(props) {
  const { setDataForm, code, handleclose } = props

  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const refInput = useRef(null)

  const createDismissableSnackbar = useCustomSnackbar()

  useEffectOnce(() => {
    if (code) {
      handleSubmitStock({ code })
    }
  })

  function handleRef() {
    refInput.current.click()
  }

  function checkValue(value) {
    const num = Number(value.code.slice(1))
    const firstLetter = value.code.charAt(0)
    if (["E", "O", "U", "R", "N"].indexOf(firstLetter) > -1) {
      if (Number.isInteger(num) && num > 0) return false
    }
    return true
  }

  async function handleSubmitStock(values) {
    setLoading(true)
    await interfaces.plantOrders
      .searchStockOrVehicle(
        `?empresa=${
          typeof values.code.charAt(0) === "string" &&
          values.code.charAt(0) === "E"
            ? 1
            : values.code.charAt(0) === "O"
            ? 2
            : 3
        }&codigo=${
          typeof values.code.charAt(0) === "string"
            ? values.code.substring(1)
            : values.code
        }&usado=${
          typeof values.code.charAt(0) === "string" &&
          ["U", "R", "N"].indexOf(values.code.charAt(0)) > -1
            ? 1
            : 0
        }`
      )
      .then((res) => {
        setDataForm({
          ...res.data,
          empresa: res.data.usado
            ? 1
            : values.code.charAt(0) === "E"
            ? 1
            : values.code.charAt(0) === "O"
            ? 2
            : 3,
          codigo: values.code,
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        if (code) {
          handleclose()
        }
      })
    setLoading(false)
  }

  async function handleSubmitVehicle(values) {
    setLoading(true)
    await interfaces.plantOrders
      .searchStockOrVehicle(`?search=${values.code.toUpperCase()}`)
      .then((res) => {
        setDataForm({
          ...res.data,
          empresa:
            values.code.charAt(0) === "E" && !checkValue(values)
              ? 1
              : values.code.charAt(0) === "O" && !checkValue(values)
              ? 2
              : 3,
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  function iconAdornment() {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={() => handleRef()}
          >
            <ArrowRightAltIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <Formik
      initialValues={{
        code: null,
        empresa: 3,
      }}
      onSubmit={(values) => {
        checkValue(values)
          ? handleSubmitVehicle(values)
          : handleSubmitStock(values)
      }}
      className={classes.root}
      enableReinitialize
    >
      {({ setFieldValue, handleChange, values }) => (
        <Form>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Field
                component={TextField}
                name="code"
                fullWidth
                disabled={false}
                className={classes.input}
                variant="outlined"
                label="Buscar por código o vehículo"
                placeholder="Código (Exx/Oxx) / Vehículo (Dominio/Nº V)"
                InputProps={{
                  endAdornment: iconAdornment(),
                }}
                inputProps={{
                  maxLength: 9,
                }}
                value={values.code ? values.code.toUpperCase() : null}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.charAt(0) === "E" &&
                    values.code &&
                    values.code.charAt(0) === "E"
                  ) {
                    setFieldValue("empresa", 1)
                  } else if (
                    e.target.value &&
                    e.target.value.charAt(0) === "O" &&
                    values.code &&
                    values.code.charAt(0) === "O"
                  ) {
                    setFieldValue("empresa", 2)
                  } else {
                    setFieldValue("empresa", 3)
                  }
                  handleChange(e)
                }}
              />
            </Grid>
          </Grid>
          <button style={{ display: "none" }} ref={refInput} type="submit" />
        </Form>
      )}
    </Formik>
  )
}
