import React from "react"

import { Typography } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

const StockHelp = (props) => {
  const { onClose, open } = props

  return (
    <DialogComponent
      title="Ayuda en stock"
      open={open}
      widthLarge={600}
      handleclose={onClose}
      tipo={"slide"}
      children={
        <>
          <Typography variant="body1">
            <b>Recuerde! </b>las búsquedas omitirán las piezas en los estados
            "Vendido" y "Papelera" por defecto, deberá seleccionar filtrar por
            "Todos los estados" en caso de querer buscar aquellas piezas con
            esos estados.
            <br />
            <br />
          </Typography>
          <Typography variant="subtitle1">
            Detalles en la búsqueda de piezas:
          </Typography>
          <ol style={{ textAlign: "justify" }}>
            <li>
              A la hora de buscar un producto, se recomienda usar el campo{" "}
              <b>Productos</b> donde tendrán la libertad de elegir una pieza del
              listado o escribir lo necesiten (por ejemplo, pueden escribir
              "FARO" y eso les traerá todas las coincidencias de piezas que
              contengan el nombre "FARO").
            </li>
            <li>
              En el caso de buscar por marca o modelo, deben utilizar el campo{" "}
              <b>Marca/Modelo</b> el cual permite elegir una opción del listado
              o escribir lo que deseen (por ejemplo, pueden buscar "PALIO" y
              traerá todas las opciones de modelos que contengan ese nombre).
            </li>
            <li>
              El campo de búsqueda rápida permite búsquedas compuestas de los
              siguientes datos:
              <ul>
                <li>Marca</li>
                <li>Modelo</li>
                <li>Año</li>
                <li>Dominio</li>
                <li>Nº de V</li>
                <li>Nº de Motor</li>
                <li>Nº de Chasis</li>
                <li>Código de motor</li>
                <li>Código de pieza</li>
                <li>Empresa de la pieza (ECO/OEM)</li>
                <li>Estado</li>
                <li>Calidad</li>
                <li>Ubicación (RC, R7, SP, 25, OP)</li>
              </ul>
            </li>
          </ol>

          <Typography variant="subtitle1">
            Para el filtrado de la tabla:
          </Typography>
          <ol style={{ textAlign: "justify" }}>
            <li>
              Filtrar por <b>Motor</b> traerá todas las piezas con el código de
              motor elegido.
            </li>
            <li>
              Filtrar por <b>Baja</b> filtrará por la baja seleccionada.
            </li>
            <li>
              Filtrar por <b>Calidad</b> filtrará por la calidad seleccionada.
            </li>
            <li>
              Filtrar por <b>Estado</b> filtrará por el estado seleccionado. En
              caso de elegir la opción "Todos los estados" traerá todos los
              estados disponibles.
            </li>
            <li>
              Filtrar por <b>Ubicación</b> filtrará por la ubicación
              seleccionado.
            </li>
          </ol>
        </>
      }
    />
  )
}

export default StockHelp
