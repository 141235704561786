export const confirmDeclineDisabled = (value) => {
  switch (value) {
    case "A COTIZAR":
      return false
    case "PENDIENTE":
      return false
    case "ADJUDICADO":
      return true
    case "PERDIDO":
      return true
    default:
      return ""
  }
}

export const confirmDeclineColor = (value) => {
  switch (value) {
    case "A COTIZAR":
      return { color: "black" }
    case "PENDIENTE":
      return { color: "black" }
    case "ADJUDICADO":
      return { color: "grey" }
    case "PERDIDO":
      return { color: "grey" }
    default:
      return ""
  }
}

export const printDisabled = (value) => {
  switch (value) {
    case "A COTIZAR":
      return true
    case "PENDIENTE":
      return false
    case "ADJUDICADO":
      return false
    default:
      return ""
  }
}
