import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"
import GetAppIcon from "@material-ui/icons/GetApp"
import { useLocation } from "react-router-dom"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "Components/UI/Templates/Common"
import ExportCompaction from "Components/UI/Organisms/Vehicles/ActCompaction/Export"
import CompactionTable from "Components/UI/Organisms/Vehicles/ActCompaction"
import Create from "Components/UI/Organisms/Vehicles/ActCompaction/Create"
import { interfaces } from "service/interfaces"
import Report from "Components/UI/Organisms/Vehicles/ActCompaction/Report"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { oemColorButton } from "Helpers"
import { ListAlt } from "@material-ui/icons"

const TemplateCompaction = () => {
  const [render, setRender] = useState(true)

  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()
  const {
    state: openExport,
    setOpen: setOpenExport,
    setClose: setCloseExport,
  } = SetState()
  const {
    state: openReport,
    setOpen: setOpenReport,
    setClose: setCloseReport,
  } = SetState()

  const [values, setValues] = useState({
    filters: [],
    rows: [],
    header: [],
    permissions: {},
  })

  async function apiCall(options, callback) {
    setValues({
      filters: [],
      rows: [],
      header: [],
      permissions: {},
    })
    try {
      const result = await interfaces.compactionInterface.list(options)
      setValues({
        filters: result.data.filters,
        rows: result.data.data,
        header: result.header.header.count,
        permissions: result.data.permissions,
      })
      callback()
    } catch (error) {}
  }

  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const permissions = values?.permissions

  const BUTTONS = [
    {
      onClick: setOpenExport,
      menuTitle: "Exportar",
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonContent: <GetAppIcon style={{ color: "black" }} />,
    },
    {
      onClick: setOpenReport,
      menuTitle: "Reporte",
      menuIcon: <ListAlt fontSize="small" />,
      buttonContent: "Reporte",
    },
    {
      onClick: setOpenNew,
      menuTitle: "Nuevo",
      menuIcon: <AddIcon fontSize="small" />,
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: permissions.allowCreate
        ? oemColorButton(pathname, location)
        : {},
      disabled: !permissions.allowCreate,
    },
  ]

  return (
    <div>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Vehículos"
            subTitle="Actas Compactación"
            buttons={BUTTONS}
          />
        }
        table={
          <CompactionTable
            render={render}
            setRender={setRender}
            values={values}
            setValues={setValues}
            apiCall={apiCall}
            permissions={permissions}
          />
        }
      />
      {openExport.suspense && (
        <ExportCompaction open={setOpenExport} handleClose={setCloseExport} />
      )}

      {openReport.suspense && (
        <Report open={setOpenReport} handleClose={setCloseReport} />
      )}

      {openNew.suspense && (
        <Create
          render={render}
          setRender={setRender}
          open={setOpenNew}
          handleClose={setCloseNew}
          data={values.filters}
        />
      )}
    </div>
  )
}

export default TemplateCompaction
