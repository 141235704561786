import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const DeleteItemList = (props) => {
  const {
    open,
    handleClose,
    selectedIndex,
    changeRender,
    RenderContentItemList,
  } = props
  const createDismissableSnackbar = useCustomSnackbar()

  function reset() {
    changeRender()
    RenderContentItemList()
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    setLoading(true)
    await interfaces.configInterface
      .deleteTransport(selectedIndex)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        reset()
      })
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        handleclosedisabled={loading}
        widthLarge="500px"
        title="¿Desea eliminar este transporte?"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <>
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={onSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </>
        }
      />
    </>
  )
}

export default DeleteItemList
