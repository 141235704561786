import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { printPDF } from "Helpers"

const PrintCompaction = (props) => {
  const { onClose, open } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState(false)

  const ref = useRef(null)

  const handleSubmit = () => {
    ref.current.click()
  }

  const ESTADO = [
    {
      id: 0,
      optionLabel: "PENDIENTE",
    },
    {
      id: 1,
      optionLabel: "COMPACTADO",
    },
    {
      id: 2,
      optionLabel: "TODAS",
    },
  ]
  const options = [
    {
      id: 1,
      optionLabel: "ECO Río Cuarto",
    },
    {
      id: 2,
      optionLabel: "ECO Spernanzoni",
    },
    {
      id: 3,
      optionLabel: "ECO Ruta 7",
    },
    {
      id: 4,
      optionLabel: "TODAS",
    },
  ]

  async function onSubmit(values) {
    setLoading(true)
    await interfaces.plantCompaction
      .printCompaction(values)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar("Piezas en compactación impresas!", {
          variant: "success",
        })
        onClose()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const fields = (setFieldValue) => [
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={{ id: 0, optionLabel: "PENDIENTE" }}
          label="Estado"
          name="estado"
          options={ESTADO}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("estado", value.optionLabel)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={{ id: 4, optionLabel: "TODAS" }}
          label="Ubicación"
          name="ubicacion"
          options={options}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("ubicacion", value.optionLabel)
          }}
        />
      ),
    },
  ]

  return (
    <DialogComponent
      open={open}
      handleclose={onClose}
      title="Imprimir piezas en compactación"
      disableBackdropClick={true}
      handleclosedisabled={loading}
      disableChildHeight
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={onClose}
          disabledCancel={loading}
          disabledSubmit={loading}
          isLoading={loading}
          submitText="Imprimir"
        />
      }
      children={
        <Formik
          initialValues={{
            estado: "PENDIENTE",
            ubicacion: "TODAS",
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              <InputsWithValidate
                fields={fields(setFieldValue, values)}
                spacing={1}
                setFieldValue={setFieldValue}
              />
              <button style={{ display: "none" }} ref={ref} type="submit" />
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export default PrintCompaction
