import React, { useState } from "react"

import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core/"
import IconButton from "@material-ui/core/IconButton"
import { Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import NotificationsIcon from "@material-ui/icons/Notifications"

import useStyles from "./styles"

import { dinamicsIcon } from "Helpers"

const ListItemNotification = (props) => {
  const { data, deleteNotification } = props

  const classes = useStyles()
  const [showDelete, setShowDelete] = useState(null)

  return (
    <>
      <List>
        {data.length === 0 ? (
          <ListItem key={0} style={{ padding: "0px" }}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <NotificationsIcon />
              </Avatar>
            </ListItemAvatar>

            <Grid item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ListItemText primary="No tienes ninguna notificación!" />
                <ListItemText secondary="Cuando tenga una nueva notificación la veras aquí" />
              </div>
            </Grid>
          </ListItem>
        ) : (
          Object.keys(data).map((option, index) => (
            <div
              onMouseOver={() => setShowDelete(data[option].id)}
              onMouseLeave={() => setShowDelete(null)}
            >
              <ListItem key={index} style={{ padding: "0px" }}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    {dinamicsIcon(data[option].tipo)}
                  </Avatar>
                </ListItemAvatar>
                <Grid item>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ListItemText primary={data[option].descripcion} />
                    <ListItemText secondary={data[option].fecha} />
                  </div>
                </Grid>
                {showDelete === data[option].id && (
                  <div style={{ position: "absolute", right: 0 }}>
                    <Tooltip title="Eliminar">
                      <IconButton
                        style={{
                          background: "	#ff5252",
                          color: "white",
                        }}
                        onClick={() => deleteNotification(data[option].id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </ListItem>
            </div>
          ))
        )}
      </List>
    </>
  )
}

export default ListItemNotification
