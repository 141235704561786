import { axiosService } from "../../../index"
import { plantEndpoints } from "../../../sources/plant/compaction"

const beforePending = (dominio) => {
  return axiosService.get(plantEndpoints.BEFORE_PENDING + `?search=${dominio}`)
}

const infoOneCompaction = (id) => {
  return axiosService.get(plantEndpoints.DETAILS_INFO + "/" + id)
}

const pendingList = (options) => {
  return axiosService.get(plantEndpoints.PENDING_LIST + options)
}

const printList = () => {
  return axiosService.get(plantEndpoints.PRINT_LIST)
}

const deletePending = (id) => {
  return axiosService.del(plantEndpoints.DELETE_PENDING + id)
}

const newPending = (body) => {
  return axiosService.post(plantEndpoints.NEW_PENDING, body)
}
const confirmCompaction = (body) => {
  return axiosService.post(plantEndpoints.CONFIRM_COMPACTION, body)
}
const printCompaction = (body) => {
  return axiosService.getPDF(plantEndpoints.PRINT_COMPACION, body)
}
const exportCompaction = (body) => {
  return axiosService.post(plantEndpoints.EXPORTALL)
}

export const plantCompaction = {
  beforePending,
  printList,
  pendingList,
  deletePending,
  newPending,
  confirmCompaction,
  printCompaction,
  infoOneCompaction,
  exportCompaction,
}
