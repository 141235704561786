import React from "react"

import Table from "@material-ui/core/Table"
import TableContainer from "@material-ui/core/TableContainer"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TableHead from "@material-ui/core/TableHead"
import { Checkbox, Paper } from "@material-ui/core"
import { TextField } from "@material-ui/core"

import { styles } from "./styles"

const ListTable = (props) => {
  const { list, edit, disabled } = props

  const classes = styles()

  const handleChange = (e, position) => {
    const clonedData = [...list]
    clonedData[position].check = e.target.checked
    edit(clonedData)
  }

  const handleChangeText = (e, position) => {
    const clonedData = [...list]
    clonedData[position].observaciones = e.target.value
    edit(clonedData)
  }

  const disabledFields = (item) => {
    if (disabled) return true
    if (item.detalles === "Kilometraje") {
      return true
    }
  }

  return (
    <TableContainer component={Paper} className={classes.paper}>
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#3E984C",
              height: "35px",
            }}
          >
            <TableCell style={{ width: "10%", color: "#fff" }}>SI/NO</TableCell>
            <TableCell align="left" style={{ width: "30%", color: "#fff" }}>
              Detalles
            </TableCell>
            <TableCell align="left" style={{ width: "60%", color: "#fff" }}>
              Observaciones
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>

      <div style={{ overflow: "auto", height: "320px" }}>
        <Table style={{ tableLayout: "fixed" }} size="small">
          <TableBody>
            {list.map((item, index) => (
              <TableRow>
                <TableCell style={{ width: "10%" }}>
                  <Checkbox
                    checked={item.check}
                    onChange={(e) => handleChange(e, index)}
                    disabled={disabledFields(item)}
                  />
                </TableCell>
                <TableCell align="left" style={{ width: "30%" }}>
                  {item.detalles}
                </TableCell>
                <TableCell align="left" style={{ width: "60%" }}>
                  <TextField
                    defaultValue={item.observaciones}
                    name="observaciones"
                    variant="standard"
                    fullWidth
                    onChange={(e) => handleChangeText(e, index)}
                    inputProps={{ maxLength: 100 }}
                    disabled={disabledFields(item)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  )
}

export default ListTable
