import React, { useState } from "react"
import { useEffectOnce, useKeyPressEvent } from "react-use"

import { Step, StepLabel, Stepper } from "@material-ui/core"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import Step2 from "../SearchStock/Fields/Step2/index"
import { FORM_VEHICLE } from "../SearchStock/Fields"
import { interfaces } from "service/interfaces"
import { COMMENTS } from "./Fields/Step3"

const SearchStock = (props) => {
  const { open, handleclose, initialCode = "" } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [code, setCode] = useState({
    code: initialCode,
  })
  const [initValues, setInitValues] = useState({})
  const [step] = useState(0)
  const [tab, setTab] = useState(0)
  const [loadingSearch, setLoadingSearch] = useState(false)

  useEffectOnce(() => {
    if (initialCode) {
      handleSubmitCode(initialCode)
    }
  })

  const steps = ["Piezas", "Productos", "Comentarios"]

  const handleChangeComments = (value) => {
    setInitValues({ ...initValues, comentarios: value, changeComment: true })
  }

  const _renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <InputsWithoutValidate
            fields={FORM_VEHICLE(
              initValues,
              handleSubmitCode,
              handleChangeCode,
              code,
              loadingSearch
            )}
            spacing={3}
          />
        )
      case 1:
        return (
          <Papers
            height="550px"
            children={
              <Step2 initValues={initValues} setInitValues={setInitValues} />
            }
          />
        )
      case 2:
        return (
          <InputsWithoutValidate
            fields={COMMENTS(initValues, handleChangeComments)}
            spacing={3}
          />
        )

      default:
        break
    }
  }

  useKeyPressEvent("Enter", () => (!disabledSubmit() ? handleSubmit() : null))

  async function handleCreateStock() {
    const filterInitValues = initValues.products.filter(
      (e) => e.checked !== undefined || e.created !== undefined
    )
    const values = {
      productos: filterInitValues,
      id: initValues.id,
      empresa: initValues.empresa,
      comentarios: initValues.comentarios,
      changeComment: initValues.changeComment || null,
    }

    return await interfaces.dashboardInterface
      .createStationZeroStock(values)
      .then(async (res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  async function handleSubmitCode() {
    setLoadingSearch(true)
    await interfaces.dashboardInterface
      .widgetSearchStock(code.code)
      .then((res) => {
        setInitValues(res.data)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoadingSearch(false)
  }

  const handleChangeCode = (e) => {
    setCode({ ...code, code: e.target.value.toUpperCase() })
    setInitValues({})
  }
  async function handleSubmit() {
    if (tab === 2) {
      setLoadingSearch(true)
      await handleCreateStock()
      setLoadingSearch(false)
      handleclose()
    } else {
      setTab(tab + 1)
    }
  }

  function handlePrev() {
    if (tab !== 0) {
      setTab(tab - 1)
    } else {
      handleclose()
    }
  }

  function disabledSubmit() {
    switch (tab) {
      case 0:
        return initValues.producto ? false : true
      case 1:
        return !initValues.products.some(
          (e) => e.checked || e.created === false
        )
      case 2:
        return false
      default:
        break
    }
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={tab === 2 ? null : () => handleclose({ code: "" })}
        widthLarge="500px"
        title="Estación cero de piezas "
        maxHeight
        disableBackdropClick={step > 0 ? true : false}
        disableEscapeKeyDown={step > 0 ? true : false}
        children={
          <>
            <Stepper
              alternativeLabel
              activeStep={tab}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 20,
              }}
            >
              {steps
                .filter((e) => e !== "")
                .map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
            </Stepper>
            <div style={{ height: "555px" }}>{_renderForms(tab)}</div>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleSubmit}
            onClickCancel={handlePrev}
            submitText={tab === steps.length - 1 ? "Confirmar" : "Siguiente"}
            cancelText={tab === 0 ? "Cancelar" : "Atrás"}
            disabledSubmit={disabledSubmit()}
          />
        }
      />
    </>
  )
}

export default SearchStock
