import React, { useState } from "react"
import { useEffectOnce } from "react-use"

import { IconButton, Tooltip } from "@material-ui/core"
import LockIcon from "@material-ui/icons/Lock"
import HelpIcon from "@material-ui/icons/Help"
import CameraAltIcon from "@material-ui/icons/CameraAlt"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import DeleteIcon from "@material-ui/icons/Delete"
import AutorenewIcon from "@material-ui/icons/Autorenew"
import BlockIcon from "@material-ui/icons/Block"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import setState from "Components/Hooks/SetState"
import AdvancedSearch from "Components/UI/Organisms/Sales/Stock/AdvancedSearch"
import { interfaces } from "service/interfaces"
import { columnTheme, useStyles } from "./styles"
import { formatter } from "Helpers"
import { TIPO_BAJA } from "Constants"
import StockHelp from "Components/UI/Organisms/Sales/Stock/Help"
import MeliIcon from "Components/UI/Atoms/CustomIcons/meli"

const StockTable = (props) => {
  const { render, setRender, setCanExport, setObject } = props

  const classes = useStyles()
  const windowSize = useMediaQuery("(min-width:960px)")

  const { state: help, setOpen: openHelp, setClose: closeHelp } = setState()

  const [info, setInfo] = useState({
    rows: [],
    header: 0,
    searchFirst: true,
  })
  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState("")

  function updateTable() {
    setRender(!render)
  }

  useEffectOnce(() =>
    interfaces.salesStock.stockFilters().then((result) => {
      setFilters(result.data.filters)
      setCanExport(!result.data.canExport)
    })
  )

  async function apiCall(options, callback) {
    setInfo({
      ...info,
      rows: [],
      header: 0,
      searchFirst: true,
    })
    try {
      const result = await interfaces.salesStock.stockList(options)
      if (result.data.msg) {
        setInfo({
          ...info,
          rows: [],
          header: 0,
          searchFirst: true,
        })
        callback()
      } else {
        setInfo({
          ...info,
          rows: result.data,
          header: result.header.header.count,
          searchFirst: false,
        })
        callback()
      }
    } catch (error) {}
  }

  const actions = (file) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        {/* next icon button is for same height in all rows */}
        <IconButton
          disabled
          size={"small"}
          style={{
            visibility: windowSize ? "hidden" : "initial",
            display: windowSize ? "" : "none",
          }}
        >
          <LockIcon />
        </IconButton>
        {file.bloqueado && (
          <IconButton style={{ color: "black" }} disabled size={"small"}>
            <LocalShippingIcon />
          </IconButton>
        )}
        {file.moreData.fotos.length !== 0 && (
          <IconButton style={{ color: "grey" }} disabled size={"small"}>
            <CameraAltIcon />
          </IconButton>
        )}
        {file.estado === "Papelera" ? (
          <IconButton style={{ color: "grey" }} disabled size={"small"}>
            <DeleteIcon />
          </IconButton>
        ) : null}
        {(file.reservado || file.reservado_grilla) && (
          <IconButton style={{ color: "black" }} disabled size={"small"}>
            {file.reservado_ml ? <MeliIcon /> : <LockIcon />}
          </IconButton>
        )}
      </div>
    )
  }

  function logo(origin) {
    if (origin === "E")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 5,
          }}
        >
          <img src={process.env.PUBLIC_URL + "/Images/logo16.webp"} alt="Logo" />
        </div>
      )
    if (origin === "O")
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 5,
            width: "16px",
            height: "16px",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/Images/oemicon.png"}
            alt="Logo"
          />
        </div>
      )
    else
      return (
        <div
          style={{
            color: "blue",
            fontWeight: 600,
            display: "flex",
            justifyContent: "center",
            marginLeft: 5,
            fontSize: 20,
          }}
        >
          <AutorenewIcon />
        </div>
      )
  }

  const location = (row) => {
    return (
      <div>
        <b>{row.ubicacion}</b>&nbsp;
        <small>
          {row.estado === "Colocado"
            ? row.moreData.ubicacion_vehiculo
              ? row.moreData.ubicacion_vehiculo
              : ""
            : row.moreData.bin
            ? row.moreData.bin
            : row.moreData.almacen
            ? row.moreData.almacen
            : ""}
        </small>
      </div>
    )
  }

  const nrodev = (v, baja, estado_registral) => {
    const blockIconDisabled = (estado_registral) =>
      ![
        "Pendiente de compactación",
        "Compactado",
        "Chasis vendido",
        "Finalizado",
      ].includes(estado_registral)

    function color() {
      switch (baja) {
        case "04-D":
          return (
            <div style={{ color: "green" }}>
              <small>
                {" "}
                <b>D </b>
              </small>
            </div>
          )
        case "04-AM":
          return (
            <div style={{ color: "brown" }}>
              <small>
                <b>AM </b>
              </small>
            </div>
          )
        case "04-BD":
          return (
            <div style={{ color: "red" }}>
              <small>
                <b>BD </b>
              </small>
            </div>
          )
        case "04-D (No apto)":
          return (
            <div style={{ color: "red" }}>
              <small>
                <b>D-NA </b>
              </small>
            </div>
          )
        default:
          return
      }
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {v ? v : 0}&nbsp;
        {color()}
        {blockIconDisabled(estado_registral) && (
          <div style={{ color: "red", display: "flex" }}>
            <BlockIcon style={{ width: "15px" }} />
          </div>
        )}
      </div>
    )
  }

  const RowsStock = () =>
    info.rows.map((row) => [
      "",
      logo(row.codigo[0]),
      row.codigo,
      row.producto,
      row.vehiculo,
      row.motor,
      nrodev(row.nrodev, row.tipo_baja, row.estado_registral),
      row.tipo_baja,
      row.calidad,
      row.estado,
      location(row),
      formatter.format(row.moreData.precio),
      actions(row),
      row.reservado,
      row.bloqueado,
      row.reservado_grilla,
      "",
    ])

  const columns = columnsForTable(
    [
      {
        name: " ",
        label: " ",
        filter: false,
        sort: false,
        display: true,
      },
      {
        name: "codigo",
        label: "Código",
        filterLabel: "Cod",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
        marginLeft: "10px",
      },
      {
        name: "producto",
        label: "Producto",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
      },
      {
        name: "vehiculo",
        label: "Vehículo",
        display: true,
        filter: false,
        sort: true,
        customBodyRender: true,
      },
      {
        name: "motor",
        label: "Motor",
        display: true,
        filter: true,
        sort: true,
        filterName: "codigo",
        options: filters.motor,
        customBodyRender: true,
      },
      {
        name: "nrodev",
        label: "Nº V",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "tipo_baja",
        label: "Baja",
        display: false,
        filter: true,
        sort: false,
        filterName: "baja",
        options: TIPO_BAJA.filter((e) => e.id !== 4),
        customBodyRender: true,
      },
      {
        name: "calidad",
        label: "Calidad",
        display: true,
        filter: true,
        sort: true,
        filterName: "nombre",
        options: filters.calidad,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: true,
        sort: true,
        filterName: "descripcion",
        options: filters.estado,
      },
      {
        label: "Ubicación",
        name: "ubicacion",
        display: true,
        filter: true,
        sort: true,
        filterName: "nombre",
        options: filters.ubicaciones,
      },
      {
        label: "Precio",
        name: "precio",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: " ",
        label: " ",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "reservado",
        label: "Reservado",
        display: false,
        filter: false,
        sort: false,
      },
      {
        name: "bloqueado",
        label: "Bloqueado",
        display: false,
        filter: false,
        sort: false,
      },
      {
        name: "reservado_grilla",
        label: "Reservado",
        display: false,
        filter: false,
        sort: false,
      },
      {
        name: "puertas",
        label: "Puertas",
        display: false,
        filter: true,
        sort: false,
        filterName: "puerta",
        options: [
          { id: 2, puerta: "2" },
          { id: 3, puerta: "3" },
          { id: 4, puerta: "4" },
          { id: 5, puerta: "5" },
        ],
      },
    ],
    filters
  )

  const customInfo = () => {
    return (
      <Tooltip title="Ayuda">
        <IconButton onClick={() => openHelp()}>
          <HelpIcon className={classes.helpButton} />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <>
      {help.suspense && <StockHelp open={help.open} onClose={closeHelp} />}

      <MuiDatables
        columns={columns}
        data={RowsStock()}
        rows={50}
        title={
          <AdvancedSearch
            setSearch={setSearch}
            updateTable={updateTable}
            products={filters.productos}
            models={filters.modelo}
            brands={filters.marca}
          />
        }
        onRowClick={(_, index) =>
          setObject(info.rows[index.dataIndex], {
            open: true,
            suspense: true,
          })
        }
        searchEnabled={false}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar por Nº motor, Nº chasis, Nº V o dominio..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        padding={windowSize ? "none" : ""}
        searchOpen={windowSize ? false : true}
        setRowProps={(row) => {
          if (row[13] || row[14] || row[15]) {
            return {
              style: { background: "#c3c3c3" },
            }
          }
        }}
        advancedSearch={search}
        searchFirst={info.searchFirst}
        customToolbar={customInfo}
      />
    </>
  )
}
export default StockTable
