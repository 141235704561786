import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"
import { withStyles } from "@material-ui/core/styles"
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import { useStyles } from "./styles"
import NewDriver from "./NewDriver"
import DeleteDriver from "./DeleteDriver"
import SetState from "Components/Hooks/SetState"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)

const Drivers = (props) => {
  const { idSelected, changeRender, index, data } = props

  const [idDriver, setIdDriver] = useState()
  const classes = useStyles()

  const {
    state: newDriver,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  const {
    state: deleteDriver,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  const openDeleteDriver = (id) => {
    setOpenDelete()
    setIdDriver(id)
  }

  return (
    <div className={classes.root}>
      <NewDriver
        open={newDriver.open}
        handleClose={setCloseNew}
        idSelected={idSelected}
        changeRender={changeRender}
      />
      <DeleteDriver
        open={deleteDriver.open}
        handleClose={setCloseDelete}
        idSelected={idSelected}
        changeRender={changeRender}
        idDriver={idDriver}
      />
      <div className={classes.table}>
        <Grid item sm={12} xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Chofer</TableCell>
                <TableCell>DNI</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data[index].transporte_choferes.map((row) => (
                <StyledTableRow>
                  <StyledTableCell className={classes.stylesTableCell}>
                    <div className={classes.descripcion}>{row.nombre}</div>
                  </StyledTableCell>
                  <StyledTableCell>{row.dni}</StyledTableCell>

                  <StyledTableCell align="right">
                    <IconButton
                      type="submit"
                      onClick={() => openDeleteDriver(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </div>
      <Grid
        item
        sm={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
        <Button
          type="submit"
          onClick={setOpenNew}
          color="primary"
          variant="contained"
          style={{ textTransform: "initial" }}
          startIcon={<AddIcon />}
        >
          Agregar
        </Button>
      </Grid>
    </div>
  )
}

export default Drivers
