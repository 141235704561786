import React, { useEffect, useState } from "react"

import { Grid, Paper, Hidden, Typography, Tooltip } from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import CloseIcon from "@material-ui/icons/Close"

import FormLoginOem from "Components/UI/Organisms/Login/LoginOem"
import useStyles from "./styles"
import { instances } from "service/instances"

const LoginTemplateOem = () => {
  const classes = useStyles()

  const [apiStatus, setApiStatus] = useState(false)

  useEffect(() => {
    document.title = "OEM Parts"
    instances.instanceCommon
      .get("/")
      .then(() => setApiStatus(true))
      .catch(() => setApiStatus(false))
  }, [])

  const apiTitle = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography>API Status:</Typography>
        {apiStatus ? <DoneIcon /> : <CloseIcon />}
      </div>
    )
  }

  return (
    <Grid container className={classes.root}>
      <Hidden xsDown>
        <Grid item xs={false} sm={6} md={9} className={classes.image} />
      </Hidden>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sm={6}
        md={3}
        component={Paper}
        elevation={6}
      >
        <div style={{ position: "absolute" }}>
          <FormLoginOem />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 30,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {process.env.REACT_APP_ENVIRONMENT === "dev" ? (
            <Typography>AMBIENTE DE PRUEBAS</Typography>
          ) : null}
          <Tooltip title={apiTitle()}>
            <Typography variant="body2" color="textSecondary">
              Ver. {process.env.REACT_APP_VERSION}
            </Typography>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  )
}
export default LoginTemplateOem
