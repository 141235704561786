import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  home: {
    marginTop: "17px",
    width: "100%",
  },
  permissions: {
    marginTop: "17px",
    width: "100%",
  },
  history: {
    margin: "5px",
    height: "580px",
    borderRadius: "10px",
    overflow: "auto",
  },
}))

export default useStyles
