import InputButtonWithOutFormik from "Components/UI/Atoms/InputButtonWithOutValidate"
import { COLORS, FUEL, TRACTION, TRANSSMISION } from "Constants"

export const FORM_VEHICLE = (
  initValues,
  handleSubmitCode,
  handleChangeCode,
  code,
  loadingSearch
) => {
  return [
    {
      class: "custom",
      component: (
        <InputButtonWithOutFormik
          onSubmit={handleSubmitCode}
          handleChangeCode={handleChangeCode}
          name="dominio"
          value={code.code}
          placeholder="Dominio / Nº Siniestro"
          label="Dominio / Nº Siniestro"
          loading={loadingSearch}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "dominio",
      label: "Dominio / Nº Siniestro",
      value: initValues.vehicle ? initValues.vehicle.dominio_siniestro : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "tipo_baja",
      label: "Tipo de baja",
      value: initValues.vehicle ? initValues.vehicle.tipo_baja : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "marca",
      label: "Marca",
      value: initValues.vehicle ? initValues.vehicle.marca : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "modelo",
      label: "Modelo",
      value: initValues.vehicle ? initValues.vehicle.modelo : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "año",
      label: "Año",
      value: initValues.vehicle ? initValues.vehicle.año : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "color",
      label: "Color",
      value: initValues.vehicle ? initValues.vehicle.color : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
  ]
}

export const TECHNICAL_DATA = (initValues, handleChange) => {
  return [
    {
      class: "textfield",
      name: "kms",
      label: "Kilómetros",
      type: "number",
      value: initValues.vehicle ? initValues.vehicle.kms : "",
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange(e.target.name, e.target.value)
      },
      step: 1,
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "color",
      label: "Color",
      options: COLORS,
      optionLabel: `options["color"]`,
      optionValue: "color",
      value: initValues.vehicle ? initValues.vehicle.color : "",
      onChange: (e) => {
        handleChange("color", e.color)
      },
    },
    {
      class: "textfield",
      name: "puertas",
      label: "Puertas",
      type: "number",
      value: initValues.vehicle ? initValues.vehicle.puertas : "",
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange(e.target.name, e.target.value)
      },
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "combustible",
      label: "Combustible",
      options: FUEL,
      optionLabel: `options["combustible"]`,
      optionValue: "combustible",
      value: initValues.vehicle ? initValues.vehicle.combustible : "",
      onChange: (e) => {
        handleChange("combustible", e.combustible)
      },
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "transmision",
      label: "Transmisión",
      options: TRANSSMISION,
      optionLabel: `options["TRANSSMISION"]`,
      optionValue: "TRANSSMISION",
      value: initValues.vehicle ? initValues.vehicle.transmision : "",
      onChange: (e) => {
        handleChange("transmision", e.TRANSSMISION)
      },
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "traccion",
      label: "Tracción",
      options: TRACTION,
      optionLabel: `options["TRACTION"]`,
      optionValue: "TRACTION",
      value: initValues.vehicle ? initValues.vehicle.traccion : "",
      onChange: (e) => {
        handleChange("traccion", e.TRACTION)
      },
    },
  ]
}
