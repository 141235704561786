import React, { useState } from "react"
import { Link } from "react-router-dom"

import { Grid, Typography, ListItemIcon } from "@material-ui/core"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"

import CustomButton from "Components/UI/Atoms/CustomButton"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputButton from "../PaperDevolution/DialogDevolution/InputButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"
import BasicTable from "Components/UI/Atoms/BasicTable"

const Ingreso = () => {
  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose, setState } = SetState()
  const [isLoading, setIsLoading] = useState(false)

  async function onSubmit(params) {
    if (state.dataArray.length === 0) {
      createDismissableSnackbar("No hay códigos!", {
        variant: "error",
      })
    } else {
      setIsLoading(true)
      await interfaces.plantInterfaceStock
        .transferToOEMConfirm(state.dataArray)
        .then((res) => {
          setIsLoading(true)
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          setClose({ dataArray: [] })
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
        })
      setIsLoading(false)
    }
  }

  function checkCode(arr, code) {
    return arr.some(function (e) {
      return e.codigo === code
    })
  }

  async function onSubmitField(value) {
    try {
      if (!value.codigos || value.codigos === "")
        return createDismissableSnackbar("Ingrese algún código primero!", {
          variant: "error",
        })
      if (checkCode(state.dataArray, value.codigos))
        return createDismissableSnackbar("Código ya ingresado!", {
          variant: "error",
        })
      const result = await interfaces.plantInterfaceStock.transferToOEMList({
        codigos: value.codigos,
      })

      setState({ dataArray: [...state.dataArray, result.data] })
    } catch (error) {
      createDismissableSnackbar(error.response.data.data, {
        variant: "error",
      })
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12} xs={12} style={{ marginTop: "5%" }}>
          <CustomButton
            component={Link}
            to="/plant/movements/ingress"
            color="primary"
            text="Ingreso"
          />
        </Grid>
        <ListItemIcon style={{ marginTop: "15%" }}>
          {<ArrowUpwardIcon style={{ fontSize: 100 }} />}
        </ListItemIcon>
        <div style={{ marginTop: "20%" }}>
          <Grid item sm={12} xs={12}>
            <Typography variant="h6" align="center">
              <strong>Ingreso de piezas y almacenado de las mismas</strong>
            </Typography>
          </Grid>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "15vh",
          }}
        >
          <Grid item sm={12} xs={12}>
            <CustomButton
              color="primary"
              text="Pasar piezas a OEM"
              onClick={setOpen}
            />
          </Grid>
        </div>
      </Grid>

      {state.suspense && (
        <DialogComponent
          title="Transferir piezas a OEM"
          widthLarge="500px"
          open={state.open}
          handleclose={setClose}
          disableBackdropClick={state.dataArray.length ? true : false}
          disableEscapeKeyDown={state.dataArray.length ? true : false}
          handleclosedisabled={isLoading}
          children={
            <>
              <InputButton
                name="codigos"
                label="Código de pieza"
                onSubmit={onSubmitField}
              />
              <div style={{ height: "500px" }}>
                <BasicTable
                  size="small"
                  col={[
                    { title: "Código", style: { width: "30%" } },
                    { title: "Descripción", style: { width: "70%" } },
                  ]}
                  rows={state.dataArray}
                />
              </div>
            </>
          }
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              onClickSubmit={onSubmit}
              isLoading={isLoading}
              disabledSubmit={isLoading}
              disabledCancel={isLoading}
            />
          }
        />
      )}
    </>
  )
}

export default Ingreso
