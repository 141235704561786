import React, { useEffect, useState } from "react"

import { Grid, Typography } from "@material-ui/core"

import { useStyles } from "./styles"
import { DialogUpdateFiles } from "Components/UI/Organisms/Home/Widgets/UpdateFiles/Dialog"
import SetState from "Components/Hooks/SetState"

import { interfaces } from "service/interfaces"
import formatDistance from "date-fns/formatDistance"
import es from "date-fns/locale/es"

const DataControl = () => {
  const classes = useStyles()

  const { state, setClose } = SetState()
  const [data, setData] = useState([])
  useEffect(() => {
    async function apiCall() {
      await interfaces.salesPrice.controlData().then((res) => setData(res.data))
    }
    apiCall()
    // eslint-disable-next-line
  }, [])
  function timeDistance(date) {
    const dateGMT = new Date(date)
    const now = new Date()
    const distance = formatDistance(dateGMT, now, { locale: es })
    return distance
  }

  return (
    <div>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Control de datos
          </Typography>
          <hr />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex" }}>
            <h1 style={{ margin: 0, color: "green" }}>ECO</h1>
          </div>
          <Typography variant="body1">
            Artículos totales: {data.ecoStock}
            <br /> Artículos con precio: {data.ecoPrice} <br />% de art. con
            precio: {data.ecoPercent}%
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex" }}>
            <h1 style={{ margin: 0, color: "red" }}>OEM</h1>
          </div>
          <Typography variant="body1">
            Artículos totales: {data.oemStock}
            <br /> Artículos con precio: {data.oemPrice} <br />% de art. con
            precio: {data.oemPercent}%
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <h1 style={{ margin: 0 }}>TOTAL</h1>
          <Typography variant="body1">
            Artículos totales: {data.ecoStock + data.oemStock} <br /> Artículos
            con precio: {data.ecoPrice + data.oemPrice} <br />% de art. con
            precio:{" "}
            {(
              (parseInt(data.ecoPercent) + parseInt(data.oemPercent)) /
              2
            ).toFixed(2)}
            %
          </Typography>
        </Grid>
        <hr />
        <Grid item sm={12} xs={12}>
          <Typography variant="body1" font>
            <i>
              Última actualización:{" "}
              {timeDistance(data.lastUpdate ? data.lastUpdate : 1656449413269)}
            </i>
          </Typography>
        </Grid>
      </Grid>
      {state.suspense && (
        <DialogUpdateFiles start={state} handleClose={setClose} />
      )}
    </div>
  )
}

export default DataControl
