import React, { forwardRef } from "react"
import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import { validationCodigoCollector } from "Components/Validation/Plant/Stock"

const InputCollector = forwardRef((props, ref) => {
  const { onSubmit } = props

  return (
    <>
      <Formik
        validateOnChange
        validateOnBlur={false}
        initialValues={{ codigos: "" }}
        onSubmit={(values) => {
          onSubmit(values)
        }}
        validationSchema={validationCodigoCollector}
      >
        {() => (
          <Form>
            <Field
              autoFocus={true}
              component={TextField}
              name="codigos"
              fullWidth
              disabled={false}
              variant="outlined"
              placeholder={props.placeholder}
              multiline
              rows={16}
              rowsMax={16}
            />

            <button style={{ display: "none" }} ref={ref} type="submit" />
          </Form>
        )}
      </Formik>
    </>
  )
})

export default InputCollector
