import React from "react"
import PropTypes from "prop-types"

import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { Typography, makeStyles, Divider, IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

import { styles } from "./styles"

const useStyles = makeStyles(styles)

const DialogTitle = (props) => {
  const classes = useStyles()
  const {
    children,
    handleClose,
    subtitle,
    handleclosedisabled,
    loading,
    ...rest
  } = props

  function closeButton() {
    if (handleClose) {
      return loading ? null : (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          disabled={handleclosedisabled}
        >
          <CloseIcon />
        </IconButton>
      )
    }
  }

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...rest}>
      <div className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {closeButton()}
      </div>
      <div className={classes.subtitle}>
        <Typography
          variant="h6"
          style={{ fontWeight: "normal", fontSize: "15px" }}
        >
          {subtitle}
        </Typography>
      </div>
      <Divider />
    </MuiDialogTitle>
  )
}

export default DialogTitle

DialogTitle.propTypes = {
  handleClose: PropTypes.func,
}
