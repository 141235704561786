import React, { useEffect } from "react"

import Compaction from "../../../UI/Templates/Plant/Compaction"

const CompactionPage = () => {
  useEffect(() => {
    document.title = "Compactación"
  }, [])

  return <Compaction />
}

export default CompactionPage
