
export const COMMENTS = (initValues, handleChangeComments) => {
    return [
      {
        class: "textfield",
        name: "comentarios",
        label: "Comentarios",
        type: "text",
        sm: 12,
        xs: 12,
        value: initValues.comentarios,
        multiline: true,
        rows: 4,
        onChange: (e) => handleChangeComments(e.target.value),
      }
    ]
  }
  
