import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { Stepper, Step, StepLabel } from "@material-ui/core"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import {
  formFirstStep,
  formThirdStep,
} from "Components/UI/Organisms/Plant/Checklist/NewChecklist/Steps"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import FirstStep from "./Steps/FirstStep"
import SecondStep from "./Steps/SecondStep"
import { interfaces } from "service/interfaces"

const NewChecklist = (props) => {
  const { open, handleclose, render, setRender } = props

  const INIT_VALUES = {
    check: {},
    data: [],
    operarios: {},
    extraData: {
      kilometros: null,
      items: [],
      operario_id: null,
      observaciones: "",
    },
  }

  const [tab, setTab] = useState(0)
  const [data, setData] = useState(INIT_VALUES)
  const [loading, setLoading] = useState(false)
  const [changeMsj, setChangeMsj] = useState()

  const createDismissableSnackbar = useCustomSnackbar()

  const steps = ["Vehículo", "Descripción", "Operario"]

  function handleChangeMsj(e, index) {
    changeMsj[index] = e.target.value
    setChangeMsj({
      ...changeMsj,
    })
    if (e.target.value) {
      data.check[index].check = true
      setData({ ...data })
    } else {
      data.check[index].check = false
      setData({ ...data })
    }
  }

  function handleChange(e) {
    setData({
      ...data,
      extraData: {
        ...data.extraData,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeAutoComplete = (object) => {
    setData({
      ...data,
      extraData: {
        ...data.extraData,
        ...object,
      },
    })
  }

  function _renderForms(index) {
    switch (index) {
      case 1:
        return (
          <SecondStep
            values={changeMsj}
            handleChangeMsj={handleChangeMsj}
            handleChange={handleChange}
            data={data}
            setData={setData}
          />
        )
      case 2:
        return (
          <InputsWithoutValidate
            fields={formThirdStep(data, handleChangeAutoComplete)}
            spacing={2}
          />
        )
      default:
        break
    }
  }

  async function handleSubmit() {
    if (tab === 2) {
      let items = data.check.map((e) => e.check)
      let SUBMIT_DATA = {
        vehiculo_id: data.data.vehiculo_id,
        items: items,
        tipo: parseInt(data.data.tipo),
        operario_id: data.extraData.operario_id,
        items_observacion: changeMsj,
        observaciones: data.extraData.observaciones,
        stock_id: data.data.codigo,
        kilometros: parseInt(
          data.extraData.kilometros.toString().replace(/\./g, "")
        ),
      }
      await interfaces.plantChecklist
        .newChecklist(SUBMIT_DATA)
        .then((res) => {
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          handleclose()
          setRender(!render)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
          handleclose()
        })
    } else {
      setTab(tab + 1)
    }
  }

  function _handlePrev() {
    if (tab !== 0) {
      setTab(tab - 1)
    } else {
      handleclose()
    }
  }

  async function getInfo(values) {
    setLoading(true)
    await interfaces.plantChecklist
      .searchChecklist(values.dominio, values.tipo === "Motor" ? 1 : 2)
      .then((res) => {
        setData({
          ...data,
          check: res.data.check,
          data: res.data.data,
          operarios: res.data.operarios,
        })
        setChangeMsj(Array(res.data.check.length).fill(""))
        setLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setLoading(false)
      })
  }

  async function handleSubmitStep() {
    if (tab === 2) {
      handleSubmit()
    } else {
      setTab(tab + 1)
    }
  }

  function disabledSubmit() {
    switch (tab) {
      case 0:
        if (data.data.length !== 0) return false
        return true
      case 1:
        return null
      case 2:
        if (data.extraData.operario_id) return false
        return true
      default:
        break
    }
  }

  return (
    <>
      <DialogComponent
        open={open}
        widthLarge="500px"
        handleclose={handleclose}
        title={"Nuevo checklist"}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        maxHeight={true}
        children={
          <div style={{ overflow: "hidden" }}>
            <Stepper
              alternativeLabel
              activeStep={tab}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 20,
              }}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div style={{ height: "500px" }}>
              {tab === 0 && (
                <>
                  <FirstStep onSubmit={getInfo} loading={loading} />
                  <br />
                  <InputsWithoutValidate
                    fields={formFirstStep(data)}
                    spacing={2}
                  />
                </>
              )}
              {_renderForms(tab)}
            </div>
          </div>
        }
        actions={
          <CancelConfirmButtons
            disabledSubmit={disabledSubmit()}
            cancelText={tab === 0 ? "Cancelar" : "Volver"}
            submitText={tab !== 2 ? "Siguiente" : "Confirmar"}
            onClickCancel={_handlePrev}
            onClickSubmit={handleSubmitStep}
          />
        }
      />
    </>
  )
}

export default NewChecklist
