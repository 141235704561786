const { axiosService } = require("service")
const { salesClientsEndpoints } = require("service/sources/sales/clients")

const exportClients = (body) => {
  return axiosService.post(salesClientsEndpoints.EXPORT_CLIENTS, body)
}
const exportProviders = (body) => {
  return axiosService.post(salesClientsEndpoints.EXPORT_PROVIDERS, body)
}

export const salesClients = {
  exportClients,
  exportProviders,
}
