import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useFormik } from "formik"

import { Grid, Button, TextField, Link } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import CircularProgress from "@material-ui/core/CircularProgress"

import NewPassword from "Components/UI/Atoms/NewPassword"
import useStyles from "../styles"
import { validationLogin } from "Components/Validation/Login"
import { interfaces } from "service/interfaces"
import { colorBtnUbicacion } from "Helpers"
import SetState from "Components/Hooks/SetState"

const Login = (props) => {
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false)
  const { state: openNewPassword, setOpen: setOpenNewPassword, setClose: setCloseNewPassword } = SetState()

  const handleShowPassword = () => {
    setView(!view)
  }
  const hadnleHidePassword = (event) => {
    event.preventDefault()
  }

  let history = useHistory()
  const classes = useStyles()

  const formik = useFormik({
    initialValues: {
      user: "",
      password: "",
    },
    validationSchema: validationLogin,

    onSubmit: async (values, { resetForm, setErrors, setFieldValue }) => {
      let user = values.user.toLowerCase()
      setLoading(true)
      await interfaces.logInInterface
        .login(user, values.password)
        .then((res) => {
          resetForm({})
          if (res.firstLogIn) {
            setOpenNewPassword()
          } else {
            setTimeout(() => {
              history.push("/home")
            }, 1000)
          }
        })
        .catch((error) => {
          setErrors({
            user:
              error.response.data.data === "Usuario no encontrado"
                ? error.response.data.data
                : null,
            password:
              error.response.data.data === "Contraseña incorrecta"
                ? error.response.data.data
                : " ",
          })
          setFieldValue("password", "", null)
        })
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <>
      {loading ? (
        <div className={classes.heightContentSpinner}>
          <CircularProgress size={70} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <TextField
                className={classes.FormUpLogin}
                margin="normal"
                fullWidth
                autoFocus={false}
                id={"user"}
                name={"user"}
                variant="outlined"
                label={"Usuario"}
                value={formik.values.user.replace(/[^a-zA-ZñÑ]/g, "")}
                inputProps={{
                  maxLength: 25,
                }}
                onChange={formik.handleChange}
                error={formik.touched.user && Boolean(formik.errors.user)}
                helperText={formik.touched.user && formik.errors.user}
                placeholder="Ejemplo: juan"
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <TextField
                className={classes.FormDownLogin}
                fullWidth
                variant="outlined"
                id={"password"}
                name={"password"}
                type={view ? "text" : "password"}
                label={"Contraseña"}
                value={formik.values.password.trim()}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                placeholder="*********"
                inputProps={{
                  maxLength: 21,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleShowPassword}
                        onMouseDown={hadnleHidePassword}
                      >
                        {view ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                type={"submit"}
                style={colorBtnUbicacion(
                  "linear-gradient(45deg, #00867E 30%, #019966  90%)"
                )}
                className={classes.submit}
                to={"/"}
              >
                Iniciar Sesión
              </Button>
            </Grid>
            <Grid item sm={12} xs={12} align="center">
              <Link
                variant="body2"
                className={classes.link}
                onClick={props.onClick}
                href="#"
              >
                ¿Olvidó su contraseña?
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
      {openNewPassword === false ? null : (
        <NewPassword
          open={openNewPassword.open}
          disabled
          subtitle={
            "Es tu primera vez iniciando sesión, debes cambiar la contraseña obligatoriamente"
          }
          firstLogIn
          handleClose={setCloseNewPassword}
        />
      )}
    </>
  )
}

export default Login
