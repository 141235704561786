import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  typography: {
    margin: "20px",
  },
  div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    width: "200px",
    margin: "20px",
    marginTop: "-20px",
  },
  circular: {
    width: "50px",
    height: "50px",
  },
}))
export default useStyles
