import * as yup from "yup"

export const newUser = yup.object({
  nombre: yup.string().required("Este campo es obligatorio"),
  apellido: yup.string().required("Este campo es obligatorio").nullable(),
  username: yup
    .string()
    .required("Este campo es obligatorio")
    .nullable()
    .max(20, "Ingrese menos de 20 caracteres"),
  legajo: yup.string().required("Este campo es obligatorio"),
  ubicacion_id: yup.string().required("Este campo es obligatorio").nullable(),
  area: yup.string().required("Este campo es obligatorio").nullable(),
  puesto: yup.string().required("Este campo es obligatorio"),
})

export const editUser = yup.object({
  nombre: yup
    .string()
    .required("Este campo es obligatorio")
    .matches(/^[aA-zZ\s]+$/, "Solo se permiten letras en este campo"),
  apellido: yup
    .string()
    .required("Este campo es obligatorio")
    .nullable()
    .matches(/^[aA-zZ\s]+$/, "Solo se permiten letras en este campo"),
  legajo: yup.string().required("Este campo es obligatorio"),
  ubicacion_id: yup.string().required("Este campo es obligatorio"),
  area: yup.string().required("Este campo es obligatorio").nullable(),
  puesto: yup.string().required("Este campo es obligatorio"),
})
