import React, { createContext, useState } from "react"

// eslint-disable-next-line
export default ({ children }) => {
  const [openSideBar, setOpenSideBar] = useState(false)
  const [sideBar, setSideBar] = useState({ user: [], widgets: [] })

  return (
    <OpenNavbar.Provider
      value={{ openSideBar, setOpenSideBar, sideBar, setSideBar }}
    >
      {children}
    </OpenNavbar.Provider>
  )
}

export const OpenNavbar = createContext()
