import React, { useContext } from "react"

import { Hidden } from "@material-ui/core"

import DrawerSideBar from "Components/UI/Organisms/Common"
import useStyles from "Components/UI/Organisms/Common/styles"
import { OpenNavbar } from "Components/Hooks/ContextSideBar"

const Layout = (props) => {
  const classes = useStyles()

  const { openSideBar, setOpenSideBar } = useContext(OpenNavbar)

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <DrawerSideBar variant="permanent" open={false} />
      </Hidden>
      <Hidden smUp>
        <DrawerSideBar
          variant="temporary"
          open={openSideBar}
          onClose={handleOpenSideBar}
        />
      </Hidden>
      {props.children}
    </div>
  )
}

export default Layout
