import React, { useContext } from "react"

import { makeStyles } from "@material-ui/core/styles"

import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Hidden from "@material-ui/core/Hidden"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { FormContext } from "Components/Hooks/ContextForm"

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      width: "325px",
    },
  },
}))

const VerticalTabs = (props) => {
  const mediumViewport = useMediaQuery("(min-width:600px)")

  const classes = useStyles()

  const { initialData } = useContext(FormContext)

  return (
    <div className={classes.root}>
      <Hidden>
        <Tabs
          indicatorColor="primary"
          scrollButtons="on"
          textColor="primary"
          orientation={mediumViewport ? "vertical" : "horizontal"}
          variant="scrollable"
          value={props.value}
          onChange={props.onChange}
          className={classes.tabs}
        >
          <Tab label="Informacion General" />
          <Tab label="Datos del Vehiculo" />
          <Tab
            label="Fechas"
            disabled={initialData.header.allInfo === 0 ? true : false}
          />
          <Tab label="Informacion Adicional" />
        </Tabs>
      </Hidden>
    </div>
  )
}

export default VerticalTabs
