import React from "react"
import { Grid } from "@material-ui/core"

import Weather from "Components/UI/Organisms/Home/Widgets/Weather"
import Coin from "Components/UI/Organisms/Home/Widgets/Coin"
import Quote from "Components/UI/Organisms/Home/Widgets/Quote"
import UpdateFiles from "Components/UI/Organisms/Home/Widgets/UpdateFiles"
import StockImg from "Components/UI/Organisms/Home/Widgets/StockImg"
import PaperComponent from "Components/UI/Atoms/PaperCompoent"
import InfoAndLocation from "../Widgets/InfoAndLocation"
import { getWidgets } from "service/helpers/localstorage"
import StockStation from "../Widgets/StockStation"

const TemplateHome = () => {
  const widgets = JSON.parse(getWidgets())
  return (
    <Grid container>
      <Grid item md={4} sm={12} xs={12}></Grid>

      <Grid item md={4} sm={12} xs={12}>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "vehicle_photos") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<UpdateFiles />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "vehicle_info") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<InfoAndLocation />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "stock_photos") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<StockImg />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "stock_station") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<StockStation />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
      </Grid>

      <Grid item md={4} sm={12} xs={12}>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "weather") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<Weather />}
            style={{
              padding: "0px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "exchange") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<Coin />}
            style={{
              padding: "10px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: !widgets.some((e) => e === "andreani") ? "none" : "",
          }}
        >
          <PaperComponent
            children={<Quote />}
            style={{
              padding: "10px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TemplateHome
