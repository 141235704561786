const LIST = "/plant/used/list"
const USED_INFO = "/plant/used"
const UPLOAD_PHOTO = "/plant/used/photos/"
const DELETE_PHOTO = "/plant/used/deletephoto/"
const BEFORE_NEW = "/plant/used/new"
const NEW_USED = "/plant/used/new"
const UPLOAD_FILES = "/plant/used/files/"
const DELETE_FILE = "/plant/used/deletefile/"
const UPDATE = "/plant/used/"
const STICKER = "/plant/used/sticker/"
const DELETE_USED = "/plant/used/"

export const plantUsedEndpoints = {
  LIST,
  USED_INFO,
  UPLOAD_PHOTO,
  DELETE_PHOTO,
  BEFORE_NEW,
  NEW_USED,
  UPLOAD_FILES,
  DELETE_FILE,
  UPDATE,
  STICKER,
  DELETE_USED,
}
