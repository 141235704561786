import React, { useRef, useState } from "react"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import { useSnackbar } from "notistack"
import Form from "./Form"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const ButtonNew = (props) => {
  const { open, handleClose } = props
  const formSubmitRef = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  async function onSubmit(values) {
    setLoading(true)
    const value = { nombre: values.nombre.toUpperCase(), iva: 0 }
    await interfaces.configInterface
      .newCompany(value)
      .then((res) => {
        enqueueSnackbar(res.data, { variant: "success" })
        handleClose()
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, { variant: "error" })
      )
      .finally(handleClose)
    setLoading(false)
  }
  return (
    <DialogComponent
      handleclose={handleClose}
      open={open}
      title="Nueva compañía de seguros"
      children={<Form ref={formSubmitRef} onSubmit={onSubmit} />}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <>
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={() => handleSubmit()}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </>
      }
    />
  )
}

export default ButtonNew
