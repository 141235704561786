import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    height: "552px",
  },
  root2: {
    width: "900px",
    [theme.breakpoints.only("md")]: {
      width: "43rem",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  cardSelectImg: {
    padding: "1em",
    height: "240px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  cardContentSelectImg: {},
}))
