import React, { createContext, useState } from "react"

// eslint-disable-next-line
export default ({ children }) => {
  const [edit, setEdit] = useState(false)
  const [isEdited, setIsEdited] = useState(false)

  return (
    <EditContext.Provider value={[edit, setEdit, isEdited, setIsEdited]}>
      {children}
    </EditContext.Provider>
  )
}

export const EditContext = createContext()
