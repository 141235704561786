export function columnTheme() {
  return {
    "&:nth-child(0)": {
      width: "45px",
    },
    "&:nth-child(1)": {
      minWidth: "40px",
      maxWidth: "40px",
    },
    "&:nth-child(2)": {
      minWidth: "75px",
      maxWidth: "75px",
    },
    "&:nth-child(3)": {
      maxWidth: "95px",
      minWidth: "95px",
    },
    "&:nth-child(4)": {
      minWidth: "75px",
      maxWidth: "75px",
    },
    "&:nth-child(5)": {
      minWidth: "80px",
      maxWidth: "80px",
    },
    "&:nth-child(6)": {
      minWidth: "300px",
      width: "800px",
    },
    "&:nth-child(7)": {
      maxWidth: "85px",
      minWidth: "85px",
    },
    "&:nth-child(8)": {
      maxWidth: "100px",
      minWidth: "100px",
    },
    "&:nth-child(9)": {
      minWidth: "100px",
      width: "280px",
    },
    "&:nth-child(10)": {
      width: "250px",
    },
    "&:nth-child(11)": {
      width: "50px",
    },
  }
}
