import InputButton from "Components/UI/Organisms/Plant/Stock/PaperDevolution/DialogDevolution/InputButton"
import { MAIN_LOCATIONS } from "Constants"
export const Step1 = (
  data,
  handleSubmitCode,
  handleChangeCode,
  code,
  isLoading
) => {
  return [
    {
      class: "custom",
      component: (
        <InputButton
          placeholder="Dominio / Nro de V"
          name="dominio"
          onSubmit={handleSubmitCode}
          onKeyUp={handleChangeCode}
          disabled={!code.code}
          value={code.code}
          resetFormDisabled
          loading={isLoading}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "marca",
      label: "Marca",
      value: data ? data.marca : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "nrodev",
      label: "Nro de V",
      value: data ? data.nrodev : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "modelo",
      label: "Modelo",
      value: data ? data.modelo : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "año",
      label: "Año",
      value: data ? data.año : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
  ]
}

export const Step2 = (data, handleChange) => {
  return [
    {
      class: "textfield",
      name: "ubicacion",
      label: "Desde",
      value: data ? data.data.ubicacion : "",
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "destino_id",
      label: "Hasta",
      options: MAIN_LOCATIONS,
      optionLabel: `options["label"]`,
      optionValue: "id",
      value: data ? data.new.destino_id : "",
      getOptionDisabled: (option) => option.id === data.data.ubicacion_id,
      disabledOptions: data.data.ubicacion_id ? [data.data.ubicacion_id] : [],
      sm: 12,
      xs: 12,
      onChange: (e) => handleChange("destino_id", e.id),
    },
    {
      class: "textfield",
      name: "observaciones",
      label: "Descripción",
      value: data ? data.new.observaciones : "",
      multiline: 6,
      rows: 6,
      sm: 12,
      xs: 12,
      onChange: (e) => handleChange("observaciones", e.target.value),
    },
  ]
}
