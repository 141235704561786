import React, { useEffect, useState } from "react"
import { useAudio } from "react-use"

import {
  Avatar,
  LinearProgress,
  IconButton,
  Snackbar,
  Typography,
  Tooltip,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"

import popNotification from "./Sound/popNotification.mp3"

import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"

export default function PositionedSnackbar({ notification }) {
  const [progress, setProgress] = useState(0)
  const [stop, setStop] = useState(false)
  const { state, setOpen, setClose } = SetState()

  // eslint-disable-next-line
  const [audio, asd, controls, ref] = useAudio({
    src: popNotification,
  })

  useEffect(() => {
    if (notification.descripcion) return controls.play
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  useEffect(() => {
    if (notification.descripcion) return setOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  const handleClose = () => {
    setClose()
    setTimeout(() => {
      setProgress(0)
    }, 150)
  }

  const handleCloseX = () => {
    setClose()
    setTimeout(() => {
      setProgress(0)
    }, 150)
    interfaces.dashboardInterface.readNotiId(notification.id)
  }

  const useStyles = makeStyles({
    colorPrimary: {
      backgroundColor: "rgb(43, 106, 53)",
    },
    barColorPrimary: {
      backgroundColor: "#fff",
    },
    dashedColorPrimary: {
      backgroundImage: "linear-gradient(45deg, #00867E 30%, #019966  90%)",
    },
  })

  useEffect(() => {
    if (state.open && !stop) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setTimeout(() => {
              handleClose()
            }, 250)
          }
          return oldProgress + 1
        })
      }, 50)

      return () => {
        clearInterval(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.open, stop])

  const classes = useStyles()

  return (
    <div>
      {audio}
      <div
        style={{
          maxWidth: "500px",
        }}
        onMouseOver={() => setStop(true)}
        onMouseLeave={() => setStop(false)}
      >
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={state.open}
          onClose={handleClose}
          key={notification.id}
          ClickAwayListenerProps={{ mouseEvent: false }}
        >
          <div
            style={{
              maxWidth: "500px",
              background: "linear-gradient(45deg, #00867E 30%, #019966  90%)",
              padding: "10px",
              borderRadius: "10px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle1" style={{ color: "#fff" }}>
                  <b>Nueva notificación</b>
                </Typography>
                <React.Fragment>
                  <Tooltip title="Descartar">
                    <IconButton onClick={handleCloseX} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              </div>
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar src={notification.photo} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ marginLeft: "20px", color: "#fff" }}>
                    {notification.descripcion}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ marginLeft: "20px", color: "#fff" }}
                  >
                    <i>Hace un momento</i>
                  </Typography>
                </div>
              </div>
            </div>
            <LinearProgress
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
              variant="determinate"
              value={progress}
              classes={{
                colorPrimary: classes.colorPrimary,
                dashedColorPrimary: classes.dashedColorPrimary,
                barColorPrimary: classes.barColorPrimary,
              }}
            />
          </div>
        </Snackbar>
      </div>
    </div>
  )
}
