import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavbarGlobal from "Components/UI/Molecules/NavBar"
import UsersTable from "Components/UI/Organisms/Settings/Users/Table"
import NewUser from "Components/UI/Organisms/Settings/Users/NewUser"
import SetState from "Components/Hooks/SetState"
import { interfaces } from "service/interfaces"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import { oemColorButton } from "Helpers"
import Permissions from "Components/UI/Organisms/Settings/Users/Permissions"

const UsersTemplate = () => {
  const { state, setOpen, setClose } = SetState()
  const {
    state: permissions,
    setOpen: openPermission,
    setClose: closePermission,
  } = SetState()
  const [render, setRender] = useState(false)
  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const beforeNew = async () => {
    await interfaces.configUsersInterface.beforeCreate().then((res) => {
      setOpen({ data: res.data })
    })
  }

  const beforePermissions = async () => {
    await interfaces.configUsersInterface.permissionsList().then((res) => {
      openPermission({ data: res.data })
    })
  }

  const BUTTONS = [
    {
      menuTitle: "Permisos",
      buttonContent: "Permisos",
      onClick: beforePermissions,
      buttonStyle: { display: "none" },
    },
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: beforeNew,
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavbarGlobal
            title="Configuración "
            subTitle="Usuarios"
            buttons={BUTTONS}
          />
        }
        table={<UsersTable render={render} setRender={setRender} />}
      />
      {state.suspense && (
        <NewUser
          open={state.open}
          handleclose={setClose}
          render={render}
          setRender={setRender}
          data={state.data}
        />
      )}
      {permissions.suspense && (
        <Permissions
          open={permissions.open}
          handleClose={closePermission}
          permissions={permissions.data}
          renderPermissions={beforePermissions}
        />
      )}
    </>
  )
}

export default UsersTemplate
