import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import NavbarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import ChecklistTable from "Components/UI/Organisms/Plant/Checklist/Table"
import NewChecklist from "Components/UI/Organisms/Plant/Checklist/NewChecklist"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import { oemColorButton } from "Helpers"

const ChecklistTemplate = () => {
  const [render, setRender] = useState(false)
  const { state, setOpen, setClose } = SetState()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const BUTTONS = [
    {
      id: 1,
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonStartIcon: <AddIcon fontSize="small" />,
      buttonContent: "Nuevo",
      buttonStyle: oemColorButton(pathname, location),
      onClick: setOpen,
    },
  ]

  return (
    <>
      <Template
        navbar={
          <NavbarGlobal title="Planta" subTitle="Checklist" buttons={BUTTONS} />
        }
        table={<ChecklistTable render={render} setRender={setRender} />}
      />
      {state.suspense && (
        <NewChecklist
          open={state.open}
          handleclose={setClose}
          render={render}
          setRender={setRender}
        />
      )}
    </>
  )
}
export default ChecklistTemplate
