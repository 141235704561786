import React from "react"

import NavbarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import TableIngress from "Components/UI/Organisms/Plant/Stock/TableIngress"

const Ingress = () => {
  return (
    <Template
      navbar={
        <NavbarGlobal
          title="Planta"
          subTitle="Movimiento de stock"
          subTitle2="INGRESO"
        />
      }
      table={<TableIngress />}
    />
  )
}
export default Ingress
