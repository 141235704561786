import React from "react"
import { FilterList } from "@material-ui/icons"
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"

const Filters = (props) => {
  const { 
    selectedOption, 
    setSelectedOption, 
    handleApplyFilter, 
    openDialog, 
    setOpenDialog, 
    filterEstado,
    setFilterEstado,
    filterActa,
    setFilterActa
   } = props
  
  const handleFilter = () => {
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const applyFilter = () => {
    handleApplyFilter()
    handleCloseDialog()
  }

  const TIPO_COMPACTACION = [
    {
      id: 0,
      optionLabel: "LOCAL",
    },
    {
      id: 1,
      optionLabel: "REMOTA",
    }
  ]
  const ESTADO = [
    {
      id: 0,
      optionLabel: "PENDIENTE",
    },
    {
      id: 1,
      optionLabel: "COMPACTADO",
    },
    {
      id: 2,
      optionLabel: "TODAS",
    }
  ]
  const ACTA_ID = [
    {
      id: 0,
      optionLabel: "SI",
    },
    {
      id: 1,
      optionLabel: "NO",
    }
  ]

  return (
    <>
      <Button
        onClick={handleFilter}
        color="primary"
        variant="contained"
        size='small'
        style={{height: "40px", alignItems: "center" }}
      >
        <FilterList />
      </Button>
      
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xs'>
        <DialogTitle>Filtros</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={TIPO_COMPACTACION}
            getOptionLabel={(option) => option.optionLabel}
            value={selectedOption}
            onChange={(event, newValue) => setSelectedOption(newValue)}
            renderInput={(params) => <TextField {...params} label="Tipo compactación" variant="outlined" style={{ marginBottom: "10px", width: "300px" }} size='small' />}
            size='small'
          />
          <Autocomplete
            options={ESTADO}
            getOptionLabel={(option) => option.optionLabel}
            value={filterEstado}
            onChange={(event, newValue) => setFilterEstado(newValue)}
            renderInput={(params) => <TextField {...params} label="Estado" variant="outlined" style={{ marginBottom: "10px", width: "300px" }} size='small'/>}
            size='small'
          />
          <Autocomplete
            options={ACTA_ID}
            getOptionLabel={(option) => option.optionLabel}
            value={filterActa}
            onChange={(event, newValue) => setFilterActa(newValue)}
            renderInput={(params) => <TextField {...params} label="Acta ID" variant="outlined" size='small'/>}
            size='small'
          />    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={applyFilter} color="primary">
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Filters