import * as yup from "yup"

export const validateDominio = yup.object().shape({
  dominio: yup
    .string()
    .required("Este campo es obligatorio")
    .max(7, "No puedes ingresar mas de 7 caracteres"),
})

export const validationNewPending = yup.object().shape({
  ubicacion_id: yup
    .number()
    .required("Este campo es obligatorio")
    .typeError("Selecciona una opción"),
  piezas: yup
    .number()
    .required("Este campo es obligatorio")
    .typeError("Selecciona una opción"),
})
