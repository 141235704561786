import React from 'react'

import useCustomSnackbar from 'Components/UI/Atoms/SnackBar'
import { interfaces } from 'service/interfaces'

import { useRef, useState } from 'react'
import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import InputsWithValidate from 'Components/UI/Molecules/InputsWithValidate'
import { Formik, Form } from 'formik'
import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'

const Export = (props) => {
  const { open, close } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values) => {
    setIsLoading(true)

    switch (values.tipo_exportacion) {
      case 'export_all':
        await interfaces.vehicleInterface
          .exportAllVehicles()
          .then((res) => {
            window.location.assign(res.data)
            createDismissableSnackbar('Tabla de vehículos descargada!', {
              variant: 'success',
            })
          })
          .catch(() => {
            createDismissableSnackbar('Algo salió mal!', {
              variant: 'error',
            })
          })
        break

      case 'export_all_shipping':
        await interfaces.vehicleInterface
          .exportAllRetreats()
          .then((res) => {
            window.location.assign(res.data)
            createDismissableSnackbar('Tabla de envíos descargada!', {
              variant: 'success',
            })
          })
          .catch(() => {
            createDismissableSnackbar('Algo salió mal!', {
              variant: 'error',
            })
          })
        break

      case 'export_all_retreats':
        await interfaces.vehicleInterface
          .exportAllShipping()
          .then((res) => {
            window.location.assign(res.data)
            createDismissableSnackbar('Tabla de retiros descargada!', {
              variant: 'success',
            })
          })
          .catch(() => {
            createDismissableSnackbar('Algo salió mal!', {
              variant: 'error',
            })
          })
        break

      case 'export_all_payments':
        await interfaces.vehicleInterface
          .exportPayments()
          .then((res) => {
            window.location.assign(res.data)
            createDismissableSnackbar('Tabla de costos y gastos descargada!', {
              variant: 'success',
            })
          })
          .catch(() => {
            createDismissableSnackbar('Algo salió mal!', {
              variant: 'error',
            })
          })
        break
      default:
        break
    }
    setIsLoading(false)
    close()
  }

  const typeExport = [
    {
      label: 'Datos del vehículo',
      value: 'export_all',
    },
    {
      label: 'Control de envíos',
      value: 'export_all_shipping',
    },
    {
      label: 'Retiros',
      value: 'export_all_retreats',
    },
    {
      label: 'Costos y gastos',
      value: 'export_all_payments',
    },
  ]

  const fields = () => [
    {
      class: 'autocomplete',
      label: 'Exportar',
      sm: 12,
      xs: 12,
      options: typeExport,
      optionLabel: `options["label"]`,
      optionValue: 'value',
      name: 'tipo_exportacion',
    },
  ]

  const children = (
    <Formik onSubmit={onSubmit} initialValues={{ tipo_exportacion: null }}>
      {({ setFieldValue, isSubmitting }) => (
        <Form>
          <InputsWithValidate
            fields={fields()}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: 'none' }}
            type='submit'
            ref={formSubmitRef}
          />
        </Form>
      )}
    </Formik>
  )

  return (
    <DialogComponent
      open={open}
      handleclose={close}
      handleclosedisabled={isLoading}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      title='¿Desea exportar la tabla de vehículos?'
      children={children}
      widthLarge="600px"
      actions={
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <CancelConfirmButtons
            onClickCancel={close}
            onClickSubmit={handleSubmit}
            isLoading={isLoading}
            disabledSubmit={isLoading}
            disabledCancel={isLoading}
          />
        </div>
      }
    />
  )
}

export default Export
