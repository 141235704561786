import React from "react"
import { Grid } from "@material-ui/core"

import SideTabs from "./VerticalTabs"
import VerticalTabs from "./Tabs"

import useStyles from "./VerticalTabs/styles"

const Home = (props) => {
  const { setRenderTable, renderTable, renderModal, setRenderModal } = props
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Grid container className={classes.rootInicio}>
        <Grid item sm={3} container justify="center" alignItems="center">
          <VerticalTabs value={value} onChange={handleChange} />
        </Grid>
        <Grid item sm={9} xs={12} className={classes.heightInicio}>
          <SideTabs
            value={value}
            setRenderTable={setRenderTable}
            renderTable={renderTable}
            renderModal={renderModal}
            setRenderModal={setRenderModal}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
