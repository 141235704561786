import React, { useState } from "react"

import DeleteIcon from "@material-ui/icons/Delete"

import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { interfaces } from "service/interfaces"
import ListTable from "Components/UI/Organisms/Plant/Checklist/ChecklistDialog/ListTable"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import useStyles from "./styles"
import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import CustomButton from "Components/UI/Atoms/CustomButton"
import { printPDF } from "Helpers"
import Loader from "Components/UI/Atoms/Loader"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const ChecklistDialog = (props) => {
  const { open, onClose, render, setRender, info, canEdit = false } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [data, setData] = useState(info)
  const [loading, setLoading] = useState(false)

  const { state, setOpen, setClose, setLoading: setDeleteLoading } = SetState()

  const classes = useStyles()

  const handleChange = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    })
  }

  const handleChangeChecks = (checks) => {
    setData({
      ...data,
      data: {
        ...data.data,
        checks,
      },
    })
  }

  const onSubmit = async () => {
    await interfaces.plantChecklist
      .update(data.data.id, data.data)
      .then((res) => {
        onClose()
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        if (render) {
          setRender(!render)
        }
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }
  async function onSubmitPrint(e) {
    e.stopPropagation()
    setLoading(true)
    await interfaces.plantChecklist
      .print(data.data.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        if (render) {
          setRender(!render)
        }
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  async function deleteChecklist() {
    setDeleteLoading(true)
    await interfaces.plantChecklist
      .deleteChecklist(data.data.id)
      .then(() => {
        onClose()
        createDismissableSnackbar("Checklist eliminado con exito!", {
          variant: "success",
        })
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setDeleteLoading(false)
  }
  const FORM_HOME = () => {
    return [
      {
        class: "textfield",
        name: "nrodev",
        label: "Nº de V",
        type: "number",
        sm: 2,
        xs: 12,
        value: data.data.nrodev,
        disabled: "true",
        onChange: (e) => handleChange(e.target.name, e.target.value),
      },
      {
        class: "textfield",
        name: "vehiculo",
        label: "Vehículo",
        sm: 3,
        xs: 12,
        value: data.data.vehiculo,
        disabled: "true",
      },
      {
        class: "autocomplete",
        name: "operario_id",
        label: "Operario",
        sm: 3,
        xs: 12,
        options: data.operarios,
        optionLabel: `options["nombre"]`,
        optionValue: "id",
        value: data.data.operario_id,
        onChange: (value) => handleChange("operario_id", value.id),
        disabled: canEdit ? true : false,
        getOptionDisabled: (option) => !option.activo,
        disabledOptions: data.operarios
          .filter((e) => !e.activo)
          .map((e) => e.id),
      },
      {
        class: "textfield",
        name: "kilometros",
        label: "Kilometros",
        type: "number",
        sm: 3,
        xs: 12,
        value: data.data.kilometros,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: canEdit ? true : false,
      },
      {
        class: "custom",
        sm: 1,
        xs: 12,
        component: (
          <CustomButton
            fullWidth
            onClick={(e) => onSubmitPrint(e)}
            text="Imprimir"
          />
        ),
      },
      {
        class: "textfield",
        name: "observaciones",
        label: "Observaciones",
        sm: 12,
        xs: 12,
        value: data.data.observaciones,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: canEdit ? true : false,
        multiline: true,
        rows: 3,
      },
      {
        class: "custom",
        component: (
          <ListTable
            list={data.data.checks}
            edit={handleChangeChecks}
            disabled={canEdit ? true : false}
          />
        ),
      },
    ]
  }

  const tabs = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={classes.home}>
            <InputsWithoutValidate fields={FORM_HOME()} spacing={2} />
          </div>
        )
      default:
        break
    }
  }

  return (
    <>
      {state.suspense && (
        <DialogComponent
          open={state.open}
          disableChildHeight
          handleclose={!state.loading ? setClose : null}
          title="¿Desea eliminar este remito?"
          subtitle="¡Esta acción es irreversible!"
          disableBackdropClick={state.loading}
          disableEscapeKeyDown={state.loading}
          widthLarge="500px"
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              disabledCancel={state.loading}
              onClickSubmit={() => deleteChecklist()}
              disabledSubmit={state.loading}
              isLoading={state.loading}
            />
          }
        />
      )}
      <ComplexDialog
        open={open}
        onClose={onClose}
        tabs={[{ title: "Inicio" }]}
        title={
          <div className={classes.div}>
            <b> {data.data.tipo} </b> {data.data.vehiculo}
          </div>
        }
        subtitle={
          <div className={classes.div}>
            <b>ID {data.data.id} </b>- Creado el {data.data.fecha} por{" "}
            {data.data.usuario}
          </div>
        }
        children={tabs}
        actions={
          <ComplexDialogActions
            onClose={onClose}
            handleSubmit={onSubmit}
            canEdit={canEdit}
            data={data.data}
            editMode={canEdit ? true : false}
            extraCompare={canEdit ? false : true}
            leftButton={{
              icon: <DeleteIcon fontSize="small" />,
              title: "Eliminar",
              variant: "contained",
              style: { background: "rgb(244, 67, 54)", color: "#fff" },
              onClick: () => {
                setOpen()
              },
            }}
          />
        }
      />
      {loading && <Loader />}
    </>
  )
}

export default ChecklistDialog
