import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"
import NavbarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"

import NewOrder from "Components/UI/Organisms/Plant/Grill/NewOrder"
import TableGrill from "Components/UI/Organisms/Plant/Grill/TableGrill"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"

import Export from "Components/UI/Organisms/Plant/Grill/Export"
import GetAppIcon from "@material-ui/icons/GetApp"
import { oemColorButton } from "Helpers"

const GrillTemplate = () => {
  const [render, setRender] = useState(false)
  const { state: add, setOpen: openAdd, setClose: closeAdd } = SetState()
  const { state: download, setOpen: openDownload, setClose: close } = SetState()

  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const BUTTONS = [
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: openAdd,
    },
    {
      menuIcon: <GetAppIcon fontSize="small" />,
      menuTitle: "Exportar",
      buttonContent: <GetAppIcon />,
      onClick: openDownload,
    },
  ]

  return (
    <>
      <Template
        navbar={
          <NavbarGlobal title="Planta" subTitle="Grilla" buttons={BUTTONS} />
        }
        table={<TableGrill render={render} setRender={setRender} />}
      />
      {add.suspense && (
        <NewOrder
          open={add.open}
          handleclose={closeAdd}
          render={render}
          setRender={setRender}
        />
      )}
      {download.suspense && (
        <Export
          open={download.open}
          close={close}
          render={render}
          setRender={setRender}
        />
      )}
    </>
  )
}
export default GrillTemplate
