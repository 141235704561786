import { axiosService } from "service"
import { configUsersEndpoints } from "service/sources/config/users"

const userList = (options) => {
  return axiosService.get(configUsersEndpoints.USER_LIST + options)
}
const userInfo = (id) => {
  return axiosService.get(configUsersEndpoints.USER_INFO + "/" + id)
}

const beforeCreate = (data) => {
  return axiosService.get(configUsersEndpoints.BEFORE_CREATE, data)
}

const newUser = (data) => {
  return axiosService.post(configUsersEndpoints.NEW_USER, data)
}

const updateUser = (id, data) => {
  return axiosService.put(configUsersEndpoints.UPDATE_USER + "/" + id, data)
}

const profilePhoto = (id, data) => {
  return axiosService.uploadSingleFile(
    configUsersEndpoints.PROFILE_USER + "/" + id,
    data
  )
}

const resetPassword = (id) => {
  return axiosService.put(configUsersEndpoints.RESET_PASSWORD + "/" + id)
}

const deleteUser = (id) => {
  return axiosService.del(configUsersEndpoints.DELETE_USER + "/" + id)
}

const deactivateUser = (id) => {
  return axiosService.put(configUsersEndpoints.DEACTIVATE_USER + "/" + id)
}

const exportUserData = (id) => {
  return axiosService.get(configUsersEndpoints.EXPORT_USER_DATA + "/" + id)
}

//PERMISOS V2

const permissionsList = () => {
  return axiosService.get(configUsersEndpoints.PERMISSIONS_LIST)
}

const newPermission = (data) => {
  return axiosService.post(configUsersEndpoints.NEW_PERMISSIONS, data)
}

const newNavbar = (data) => {
  return axiosService.post(configUsersEndpoints.NEW_NAVBAR, data)
}

const newTemplate = (data) => {
  return axiosService.post(configUsersEndpoints.TEMPLATE, data)
}

const deleteTemplate = (id) => {
  return axiosService.del(configUsersEndpoints.TEMPLATE + id)
}

const updateTemplate = (data) => {
  return axiosService.put(configUsersEndpoints.UPDATE_TEMPLATE, data)
}

export const configUsersInterface = {
  userList,
  newUser,
  beforeCreate,
  userInfo,
  updateUser,
  profilePhoto,
  resetPassword,
  deleteUser,
  deactivateUser,
  exportUserData,
  permissionsList,
  newPermission,
  newNavbar,
  newTemplate,
  deleteTemplate,
  updateTemplate,
}
