import * as yup from "yup"

export const newTransport = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
})

export const newDriver = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
  dni: yup.number().required("Este campo es requerido"),
})

export const newVehicle = yup.object().shape({
  dominio: yup.string().required("Este campo es requerido"),
  marca: yup.string().required("Este campo es requerido"),
  modelo: yup.string().required("Este campo es requerido"),
  tipo: yup.string().required("Este campo es requerido"),
})
