import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  iconButton: {
    bottom: "20px",
    background: theme.palette.primary.main,
  },
}))

export default useStyles
