import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  weatherColor: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "15px",
  },
  colorLetter: {
    color: "white",
  },
  iconSize: {
    [theme.breakpoints.down("sm")]: {
      width: "50px",
    },
  },
  divider: {
    background: theme.palette.divider,
  },
  currentDay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  heightContentSpinner: {
    height: "305px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
}))

export default useStyles
