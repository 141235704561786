import React, { useState } from "react";
import { useEffectOnce, useKeyPressEvent } from "react-use";

import { Step, StepLabel, Stepper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import useCustomSnackbar from "Components/UI/Atoms/SnackBar";
import Papers from "Components/UI/Molecules/Papers/PaperList";
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons";
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate";
import Step2 from "Components/UI/Organisms/Plant/Station/New/Fields/Step2";
import Step4 from "Components/UI/Organisms/Plant/Station/New/Fields/Step4";
import LoadSteps from "Components/UI/Organisms/Plant/Used/NewUsed/LoadSteps";
import {
  FORM_VEHICLE,
  TECHNICAL_DATA,
} from "Components/UI/Organisms/Plant/Station/New/Fields";
import { interfaces } from "service/interfaces";

const NewStation = (props) => {
  const { open, handleclose, setRender, render, initialCode = "" } = props;

  const createDismissableSnackbar = useCustomSnackbar();

  const [code, setCode] = useState({
    code: initialCode,
  });
  const [initValues, setInitValues] = useState({
    products: [],
    vehicle: null,
    stock: [],
    id: null,
    newProducts: [],
  });
  const [step, setStep] = useState(0);
  const [tab, setTab] = useState(0);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [warning, setWarning] = useState(false);

  const updateState = () => {
    setStep((oldStep) => oldStep + 1);
  };

  useEffectOnce(() => {
    if (initialCode) {
      handleSubmitCode(initialCode);
    }
  });

  const steps = ["Vehículo", "Datos técnicos", "Productos", "", ""];

  const horizontalTabs = [
    {
      id: 0,
      title: "Inicio",
      color: "primary",
    },
    {
      id: 1,
      title: "Archivos",
      color: "primary",
    },
    {
      id: 2,
      title: "Historial",
      color: "primary",
    },
  ];

  const handleChange = (name, value) => {
    setInitValues({
      ...initValues,
      vehicle: {
        ...initValues.vehicle,
        [name]: value,
      },
    });
  };

  const confirmSteps = () => {
    return [
      { step: 1, label: "Creando piezas en el stock" },
      ...initValues.products
        .filter((e) => e.checked === true && e.foto)
        .map((row, index) => {
          return {
            step: index + 2,
            label: `Subiendo ${row.producto}`,
          };
        }),
    ];
  };

  const _renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {warning && (
              <Alert severity="warning" onClose={() => setWarning(false)}>
                <AlertTitle>Atención!</AlertTitle>
                Este vehiculo <strong>posee piezas en stock!</strong> Revise que
                piezas existen para evitar duplicados!
              </Alert>
            )}
            <InputsWithoutValidate
              fields={FORM_VEHICLE(
                initValues,
                handleSubmitCode,
                handleChangeCode,
                code,
                loadingSearch
              )}
              spacing={3}
            />
          </>
        );
      case 1:
        return (
          <InputsWithoutValidate
            fields={TECHNICAL_DATA(initValues, handleChange)}
            spacing={3}
          />
        );
      case 2:
        return (
          <Papers
            height="550px"
            children={
              <Step2 initValues={initValues} setInitValues={setInitValues} />
            }
          />
        );

      case 3:
        return <Step4 initValues={initValues} />;
      case 4:
        return <LoadSteps step={step} steps={confirmSteps()} />;
      default:
        break;
    }
  };

  useKeyPressEvent("Enter", () => (!disabledSubmit() ? handleSubmit() : null));

  async function handleUploadImg(empresa, codigo, fotos) {
    updateState();
    await interfaces.plantStation
      .uploadPhoto(empresa, codigo, fotos)
      .then(() => {})
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        });
      });
  }

  async function handleCreateStock() {
    const filterInitValues = initValues.products.filter(  
      (e) => e.checked === true
    );
    const values = {
      productos: filterInitValues,
      color: initValues.vehicle.color,
      transmision: initValues.vehicle.transmision,
      traccion: initValues.vehicle.traccion,
      combustible: initValues.vehicle.combustible,
      puertas: initValues.vehicle.puertas,
      kms: parseInt(initValues.vehicle.kms.toString().replace(/\./g, "")),
    };

    return await interfaces.plantStation
      .createStock(initValues.vehicle.id, values)
      .then(async (res) => {
        createDismissableSnackbar("Ingreso de vehículo finalizado!", {
          variant: "success",
        });
        for (let i = 0; i < res.data.products.length; i++) {
          await handleUploadImg(
            res.data.products[i].empresa,
            res.data.products[i].codigo,
            initValues.products.find(
              (e) => e.id === res.data.products[i].producto_id
            ).fotos
          );
        }
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        });
      });
  }

  async function handleSubmitCode() {
    setLoadingSearch(true);
    await interfaces.plantStation
      .searchVehicle(code.code)
      .then((res) => {
        if (res.data.alert) {
          setWarning(true);
        }
        setInitValues({
          ...initValues,
          vehicle: res.data.vehicle,
          products: res.data.products.map((item) => ({
            ...item,
            checked: false,
          })),
        });
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        });
      });
    setLoadingSearch(false);
  }

  const handleChangeCode = (e) => {
    setCode({ ...code, code: e.target.value.toUpperCase() });
  };

  async function handleSubmit() {
    if (tab === 3) {
      setTab(tab + 1);
      await handleCreateStock();
      handleclose();
      setRender(!render);
    } else {
      setTab(tab + 1);
    }
  }

  function handlePrev() {
    if (tab !== 0) {
      setTab(tab - 1);
    } else {
      handleclose();
    }
  }

  function disabledSubmit() {
    switch (tab) {
      case 0:
        return initValues.vehicle && initValues.products.length > 0
          ? false
          : true;
      case 1:
        return initValues.vehicle.color &&
          initValues.vehicle.puertas &&
          initValues.vehicle.combustible &&
          initValues.vehicle.transmision &&
          initValues.vehicle.traccion
          ? false
          : true;
      case 2:
        return !initValues.products.some((e) => e.checked);

      case 5:
        return true;
      default:
        break;
    }
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={tab === 4 ? null : () => handleclose({ code: "" })}
        tabs={horizontalTabs}
        widthLarge="500px"
        title="Ingreso vehículo a planta "
        maxHeight
        disableBackdropClick={step > 0 ? true : false}
        disableEscapeKeyDown={step > 0 ? true : false}
        children={
          <>
            <Stepper
              alternativeLabel
              activeStep={tab}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 20,
              }}
            >
              {steps
                .filter((e) => e !== "")
                .map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
            </Stepper>
            <div style={{ height: "555px" }}>{_renderForms(tab)}</div>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleSubmit}
            onClickCancel={handlePrev}
            submitText={tab === 3 ? "Confirmar" : "Siguiente"}
            cancelText={tab === 0 ? "Cancelar" : "Atrás"}
            disabledSubmit={disabledSubmit()}
            disabledCancel={tab === 5 ? true : false}
          />
        }
      />
    </>
  );
};

export default NewStation;
