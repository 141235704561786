import * as yup from "yup"

export const newInsurance = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
})

export const newInsuranceValidation = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
  porcentaje_compulsa: yup.number().max(100, "Ingrese porcentaje menor a 100%"),
})
