import * as yup from "yup"

export const validationRudac = yup.object().shape({
  sucursal: yup.string().required("Este campo es obligatorio"),
  desde: yup.date().required("Este campo es requerido").nullable(),
  hasta: yup
    .date()
    .required("Este campo es requerido")
    .min(yup.ref("desde"), "Rango incorrecto")
    .nullable(),
})

export const validationDisarm = yup.object({
  desde: yup.number().required("Este campo es requerido"),
  hasta: yup
    .number()
    .required("Este campo es requerido")
    .min(yup.ref("desde"), "Rango incorrecto"),
  ubicacion: yup.string().required("Este campo es obligatorio"),
})
