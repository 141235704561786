import React from "react"

import NavBar from "Components/UI/Organisms/Home/NavBar"
import Dashboard from "Components/UI/Organisms/Home/Dashboard"
import { Grid } from "@material-ui/core"

const TemplateHome = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <NavBar />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Dashboard />
      </Grid>
    </Grid>
  )
}

export default TemplateHome
