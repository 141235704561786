import React, { useContext } from "react"
import PropTypes from "prop-types"

import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"

import Home from "./Content/Home"
import AppBarTabsWafers from "./Content/Wafers"
import AppBarTabsFiles from "./Content/Files"
import Retirement from "./Content/Retirement"

import Stock from "./Content/Stock"
import Quotation from "./Content/Quotation"
import { FormContext } from "Components/Hooks/ContextForm"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const DialogTabs = (props) => {
  const [value, setValue] = React.useState(0)
  const { setRenderTable, renderTable, renderModal, setRenderModal } = props

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const { formEdit, initialData } = useContext(FormContext)

  return (
    <div>
      <AppBar position="relative" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Inicio" {...a11yProps(0)} />
          <Tab
            label="Obleas"
            {...a11yProps(1)}
            disabled={
              formEdit.vehicle.tipo_baja_id !== 1 ||
              initialData.header.allInfo === 0
                ? true
                : false
            }
          />
          <Tab
            label="Cotizacion"
            {...a11yProps(2)}
            disabled={initialData.header.allInfo === 0 ? true : false}
          />
          <Tab label="Archivos" {...a11yProps(3)} />
          <Tab
            label="Retiro"
            {...a11yProps(4)}
            disabled={initialData.header.allInfo === 0 ? true : false}
          />
          <Tab label="Stock" {...a11yProps(5)} />
          {initialData.history.length && (
            <Tab label="Historial" {...a11yProps(6)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Home
          setRenderTable={setRenderTable}
          renderTable={renderTable}
          renderModal={renderModal}
          setRenderModal={setRenderModal}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AppBarTabsWafers />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Quotation />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AppBarTabsFiles />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Retirement h />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Stock />
      </TabPanel>
      <TabPanel
        value={value}
        index={6}
        style={{ height: "600px", overflow: "auto" }}
      >
        <ComplexDialogHistory history={initialData.history} />
      </TabPanel>
    </div>
  )
}

export default DialogTabs
