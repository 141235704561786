import * as yup from "yup"

export const template = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
})

export const navbar = yup.object().shape({
  descripcion: yup.string().required("Este campo es requerido"),
})

export const permisos = yup.object().shape({
  descripcion: yup.string().required("Este campo es requerido"),
})
