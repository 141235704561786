import { makeStyles } from "@material-ui/core"
export const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  btn: {
    textTransform: "initial",
  },
  margin: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "15px",
  },
  divPaperPreSelect: {
    height: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "240px",
    },
  },
  stylesTableCell: {
    display: "flex",
    alignItems: "flex-end",
  },
  descripcion: {
    marginLeft: "15px",
  },
  tableHeight: {
    maxHeight: "500px",
  },
}))
