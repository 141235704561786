import React, { useContext } from "react"

import { Dialog, makeStyles } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import Fade from "@material-ui/core/Fade"

import Content from "./Content"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { centerStyles } from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/styles"

const ModalTableEdit = (props) => {
  const { id, setRenderTable, renderTable, open, onClose } = props

  const [edit, setEdit] = useContext(EditContext)
  const { formApi } = useContext(FormContext)

  const useStyles = makeStyles(centerStyles)

  const classes = useStyles({ widthLarge: "1300px" })

  return (
    <div>
      <Dialog
        classes={{ paper: classes.paper }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={open}
        onClose={() =>
          onClose(
            setTimeout(() => {
              setEdit(false)
            }, 500)
          )
        }
        closeAfterTransition
        disableBackdropClick={
          !edit || Object.keys(formApi).length === 0 ? false : true
        }
        disableEscapeKeyDown={
          !edit || Object.keys(formApi).length === 0 ? false : true
        }
      >
        <Fade in={open}>
          <Content
            id={id}
            closeBigDialog={onClose}
            setRenderTable={setRenderTable}
            renderTable={renderTable}
          />
        </Fade>
      </Dialog>
    </div>
  )
}

export default ModalTableEdit
