import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"
import GetAppIcon from "@material-ui/icons/GetApp"
import { useLocation } from "react-router-dom"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "Components/UI/Templates/Common"
import {
  New,
  Table,
  Export,
} from "Components/UI/Organisms/Vehicles/VehicleTransit"

import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { oemColorButton } from "Helpers"
const TemplateTransitVehicle = () => {
  const [render, setRender] = useState(true)

  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()
  const {
    state: openExport,
    setOpen: setOpenExport,
    setClose: setCloseExport,
  } = SetState()

  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const BUTTONS = [
    {
      onClick: setOpenExport,
      menuTitle: "Exportar",
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonContent: <GetAppIcon style={{ color: "black" }} />,
    },
    {
      onClick: setOpenNew,
      menuTitle: "Nuevo",
      menuIcon: <AddIcon fontSize="small" />,
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
    },
  ]

  return (
    <div>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Vehículos"
            subTitle="Tránsito de vehículos"
            buttons={BUTTONS}
          />
        }
        table={<Table render={render} setRender={setRender} />}
      />
      {openExport.suspense && (
        <Export open={setOpenExport} handleClose={setCloseExport} />
      )}
      ,
      {openNew.suspense && (
        <New
          render={render}
          setRender={setRender}
          open={setOpenNew}
          handleClose={setCloseNew}
        />
      )}
    </div>
  )
}

export default TemplateTransitVehicle
