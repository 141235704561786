import React, { useState } from "react"

import PrintIcon from "@material-ui/icons/Print"
import GetAppIcon from "@material-ui/icons/GetApp"

import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "../../Common"
import { interfaces } from "service/interfaces"
import Table from "Components/UI/Organisms/Plant/Compaction/Table"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import PrintCompaction from "Components/UI/Organisms/Plant/Compaction/PrintCompaction"

const Classification = () => {
  const createDismissableSnackbar = useCustomSnackbar()
  const [render, setRender] = useState(false)
  const [info, setInfo] = useState({
    rows: [],
    header: [],
  })
  const [openExport, setOpenExport] = useState({ open: false, suspense: false })

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantCompaction.pendingList(options)
      setInfo({
        rows: result.data,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  const exportAll = async () => {
    await interfaces.plantCompaction
      .exportCompaction()
      .then((res) => {
        window.open(res.data, "_self", "width=1200,height=900,scrollbars=yes")
        createDismissableSnackbar("Compactaciones descargadas con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
  }
  
  const closeDialogExport = () => {
    setOpenExport({ ...openExport, open: false })
  }
  const openDialogExport = () => {
    setOpenExport({ open: true, suspense: true })
  }

  const BUTTONS = [
    {
      menuIcon: <PrintIcon fontSize="small" />,
      menuTitle: "Imprimir",
      buttonContent: <PrintIcon />,
      onClick: openDialogExport
    },
    {
      menuTitle: "Descargar",
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonContent: <GetAppIcon style={{ color: "black" }} />,
      onClick: exportAll, 
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            buttons={BUTTONS}
            title="Planta"
            subTitle="Compactación"
          />
        }
        table={
          <>
            <Table
              render={render}
              setRender={setRender}
              apiCall={apiCall}
              info={info}
            />

            {openExport.suspense && (
              <PrintCompaction open={openExport.open} onClose={closeDialogExport} />
            )}
          </>
        }
      />
    </>
  )
}
export default Classification
