import React, { useState, useContext } from "react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { TextField } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"

import useStyles from "../styles"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"

const CustomTableCell = ({ row, name, onChange, isNotEnabled, isSelected }) => {
  const classes = useStyles()
  const [edit] = useContext(EditContext)
  return (
    <TableCell className={classes.tableCell}>
      {edit ? (
        <TextField
          value={row[name] === 0 ? null : row[name]}
          name={name}
          disabled={isNotEnabled ? isNotEnabled : false}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
          select={isSelected ? isSelected : false}
          style={{ height: "20px" }}
          type={name === "oblea" ? "number" : "string"}
        />
      ) : row[name] === 0 ? null : (
        row[name]
      )}
    </TableCell>
  )
}

const Wafers = () => {
  const { formApi, formEdit, setFormEdit, setFormApi } = useContext(FormContext)
  const [edit] = useContext(EditContext)

  const [rows, setRows] = useState(formEdit.wafers)

  const onChange = (e, row) => {
    const { conf_obleas } = row
    let oldRows = [...rows]
    let addedRow = { ...oldRows[conf_obleas - 1] }
    if (e.target.checked) {
      addedRow.check = e.target.checked
      addedRow.oblea = 0
    } else {
      addedRow.check = e.target.checked
      addedRow.oblea = ""
    }
    oldRows[conf_obleas - 1] = addedRow
    setRows(oldRows)
    setFormEdit({ ...formEdit, wafers: oldRows })
    setFormApi({ ...formApi, wafers: oldRows })
  }

  const classes = useStyles()
  return (
    <div className={classes.div}>
      <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Pieza</TableCell>
              <TableCell>Oblea</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.conf_obleas}>
                <TableCell style={{ width: "50px" }}>
                  <Checkbox
                    name={"check"}
                    checked={
                      (row.oblea === 0 || row.oblea > 0 || row.check) &&
                      row.check !== false
                        ? true
                        : false
                    }
                    disabled={edit ? false : true}
                    onChange={(e) => onChange(e, row)}
                    color="primary"
                    style={{ padding: "0px" }}
                  />
                </TableCell>
                <TableCell
                  style={{
                    color:
                      (row.oblea === 0 || row.oblea > 0 || row.check) &&
                      row.check !== false
                        ? "black"
                        : "grey",
                    fontWeight:
                      (row.oblea === 0 || row.oblea > 0 || row.check) &&
                      row.check !== false
                        ? "bold"
                        : "regular",
                    maxWidth: "500px",
                  }}
                >
                  {row.pieza}
                </TableCell>

                <CustomTableCell {...{ row, name: "oblea", onChange }} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Wafers
