import React, { useState } from "react"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import InputButton from "Components/UI/Atoms/InputButton"

const ChangeCode = (props) => {
  const { id, open, onClose, render, setRender, closeDialog } = props

  const [loading, setLoading] = useState({ search: false, submit: false })
  const [value, setValue] = useState({
    id: null,
    data: "",
    isUsed: false,
    vehiculo_id: null,
  })

  const createDismissableSnackbar = useCustomSnackbar()
  async function getStock(values) {
    setLoading({ ...loading, search: true })
    await interfaces.plantOrders
      .searchStock(values.search)
      .then((res) => {
        setValue(res.data)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading({ ...loading, search: false })
  }

  async function submitCode() {
    setLoading({ ...loading, submit: true })
    await interfaces.plantOrders
      .updateStock(id, {
        articulo_id: value.id,
        isUsed: value.isUsed,
        vehiculo_id: value.vehiculo_id,
      })
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        onClose()
        closeDialog()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading({ ...loading, submit: false })
  }

  const fields = () => [
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <InputButton
          onSubmit={getStock}
          name={"search"}
          placeholder={"Buscar pieza por código"}
          loading={loading.search}
        />
      ),
    },
    {
      class: "textfield",
      label: "Pieza encontrado",
      name: "pieza",
      value: value.data,
      disabled: true,
      sm: 12,
      xs: 12,
    },
  ]

  return (
    <DialogComponent
      open={open}
      title="Cambiar artículo"
      widthLarge="500px"
      handleclose={!loading.submit ? onClose : null}
      children={<InputsWithoutValidate fields={fields()} spacing={1} />}
      actions={
        <CancelConfirmButtons
          onClickCancel={onClose}
          onClickSubmit={() => submitCode()}
          isLoading={loading.submit}
          disabledCancel={loading.submit}
          disabledSubmit={loading.submit}
        />
      }
    />
  )
}

export default ChangeCode
