import { makeStyles } from "@material-ui/core/styles"

export function columnTheme() {
  return {
    toolbarBackground: "#f2f2f2",
    "&:nth-child(1)": {
      width: "20px",
    },
    "&:nth-child(2)": {
      paddingLeft: "10px",
      minWidth: "100px",
      maxWidth: "100px",
      width: "100px",
    },
    "&:nth-child(3)": {
      minWidth: "150px",
      maxWidth: "400px",
      width: "400px",
    },
    "&:nth-child(4)": {
      minWidth: "200px",
      maxWidth: "400px",
      width: "400px",
    },
    "&:nth-child(5)": {
      minWidth: "100px",
      maxWidth: "150px",
      width: "150px",
    },
    "&:nth-child(6)": {
      minWidth: "85px",
      maxWidth: "85px",
      width: "85px",
    },
    "&:nth-child(7)": {
      minWidth: "110px",
      maxWidth: "110px",
      width: "110px",
    },
    "&:nth-child(8)": {
      minWidth: "100px",
      maxWidth: "100px",
      width: "100px",
    },
    "&:nth-child(9)": {
      minWidth: "125px",
      maxWidth: "125px",
      width: "125px",
    },
    "&:nth-child(10)": {
      minWidth: "80px",
      maxWidth: "80px",
      width: "80px",
    },
    "&:nth-child(11)": {
      minWidth: "45px",
      maxWidth: "85px",
      width: "85px",
    },
  }
}

export const useStyles = makeStyles((theme) => ({
  helpButton: {
    color: theme.palette.primary.main,
  },
}))
