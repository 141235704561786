import React, { useEffect } from "react"

import DespatchTemplate from "Components/UI/Templates/Plant/Despatch"

const Grill = () => {
  useEffect(() => {
    document.title = "Remitos"
  }, [])

  return <DespatchTemplate />
}
export default Grill
