export function columnTheme() {
  return {
    "&:nth-child(1)": {
      paddingLeft: "10px",
      width: "90px",
      minWidth: "90px",
      maxWidth: "90px",
    },
    "&:nth-child(2)": {
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(3)": {
      width: "110px",
      minWidth: "110px",
      maxWidth: "110px",
    },
    "&:nth-child(4)": {
      width: "75px",
      minWidth: "75px",
      maxWidth: "75px",
    },
    "&:nth-child(5)": {
      width: "200px",
      minWidth: "200px",
      maxWidth: "300px",
    },
    "&:nth-child(6)": {
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(7)": {
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
    },
    "&:nth-child(8)": {
      minWidth: "100px",
      maxWidth: "100px",
      width: "100px",
    },
    "&:nth-child(9)": {
      width: "200px",
      minWidth: "200px",
      maxWidth: "200px",
    },
    "&:nth-child(10)": {
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
  }
}
