const ENGINE_LIST = "/settings/engines/enginelist"
const NEW_ENGINE = "/settings/engines/newengine"
const UPDATE_ENGINE = "/settings/engines/engine"
const VEHICLE_ENGINE = "/settings/engines/vehicles"

const COMPANY_LIST = "/settings/insurecompany/listcompany"
const VEHICLE_COMPANY = "/settings/insurecompany/listvehicles"
const UPDATE_COMPANY = "/settings/insurecompany/updatecompany"
const DELETE_COMPANY = "/settings/insurecompany/deletecompany"
const NEW_COMPANY = "/settings/insurecompany/newcompany"
const CHANGE_PHOTO = "/settings/insurecompany/changephoto"
const LIST_MODEL_BRAND = "/settings/bmv/listbrandmodelversion"
const NEW_BMV = "/settings/bmv/newbrandmodelversion"
const DELETE_BRAND = "/settings/bmv/deletebrand"
const UPDATE_BRAND = "/settings/bmv/editbrand"
const UPDATE_MODEL = "/settings/bmv/editmodel"
const DELETE_MODEL = "/settings/bmv/deletemodel"
const DELETE_VERSION = "/settings/bmv/deleteversion"
const UPDATE_VERSION = "/settings/bmv/editversion"
const TRANSPORT_LIST = "/settings/transports/transportlist"
const DELETE_TRANSPORT = "/settings/transports/transport"
const NEW_TRANSPORT = "/settings/transports/newtransport"
const UPDATE_TRANSPORT = "/settings/transports/transport"
const NEW_DRIVER = "/settings/transports/newdriver"
const DELETE_DRIVER = "/settings/transports/transportdriver"
const NEW_VEHICLE = "/settings/transports/newvehicle"
const DELETE_VEHICLE = "/settings/transports/transportvehicle"
const EXPORT_MOTORES = "/settings/engines/exportall"
const LIST_FAMILY_PRODUCTS = "/settings/products/listproductfamily"
const NEW_FAMILY_PRODUCT = "/settings/products/newproductfamily"
const UPDATE_PRODUCT = "/settings/products/editproduct/"
const DELETE_FAMILY = "/settings/products/deletefamily/"
const DELETE_PRODUCT = "/settings/products/deleteproduct/"
const UPLOAD_BRAND_PHOTO = "/settings/bmv/upload"
const LIST_USER_INSURANCE = "/settings/users/insurances/list"
const NEW_USER_INSURANCE = "/settings/users/insurances/new"
const UPDATE_USER_INSURANCE = "/settings/users/insurances/"
const DELETE_USER_INSURANCE = "/settings/users/insurances/"

const LIST_LOTS_ROWS = "/settings/lots/listlotsrows"
const NEW_LOT = "/settings/lots/newlot/"
const UPDATE_LOT = "/settings/lots/editlot/"
const DELETE_LOT = "/settings/lots/deletelot/"
const NEW_ROW = "/settings/lots/newrow"
const UPDATE_ROW = "/settings/lots/editrow/"
const DELETE_ROW = "/settings/lots/deleterow/"

const LIST_PLA = "/settings/pla/listpla"
const NEW_PLA = "/settings/pla/newpla"
const DELETE_ADDRESS = "/settings/pla/deleteaddress"
const UPDATE_ADDRESS = "/settings/pla/editaddress"
const DELETE_LOCALITIES = "/settings/pla/deletelocalities"
const UPDATE_LOCALITIES = "/settings/pla/editlocalities"
const POST_LOCALITIES = "/settings/pla/listlocalities"

export const configEndpoints = {
  ENGINE_LIST,
  NEW_ENGINE,
  LIST_MODEL_BRAND,
  NEW_BMV,
  UPDATE_ENGINE,
  VEHICLE_ENGINE,
  UPDATE_BRAND,
  DELETE_BRAND,
  DELETE_MODEL,
  COMPANY_LIST,
  DELETE_VERSION,
  UPDATE_VERSION,
  VEHICLE_COMPANY,
  UPDATE_COMPANY,
  UPDATE_MODEL,
  DELETE_COMPANY,
  NEW_COMPANY,
  CHANGE_PHOTO,
  TRANSPORT_LIST,
  DELETE_TRANSPORT,
  NEW_TRANSPORT,
  UPDATE_TRANSPORT,
  NEW_DRIVER,
  DELETE_DRIVER,
  NEW_VEHICLE,
  DELETE_VEHICLE,
  EXPORT_MOTORES,
  LIST_FAMILY_PRODUCTS,
  NEW_FAMILY_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_FAMILY,
  DELETE_PRODUCT,
  UPLOAD_BRAND_PHOTO,
  LIST_USER_INSURANCE,
  NEW_USER_INSURANCE,
  UPDATE_USER_INSURANCE,
  DELETE_USER_INSURANCE,
  LIST_LOTS_ROWS,
  NEW_LOT,
  UPDATE_LOT,
  DELETE_LOT,
  NEW_ROW,
  UPDATE_ROW,
  DELETE_ROW,
  LIST_PLA,
  NEW_PLA,
  DELETE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_LOCALITIES,
  UPDATE_LOCALITIES,
  POST_LOCALITIES,
}
