import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

export function ButtonInputXls(props) {
  const {
    name,
    value,
    textLater,
    textBefore,
    onChange,
    id,
    multiple = true,
    colorBefore = "",
  } = props

  return (
    <>
      <input
        style={{ display: "none" }}
        id={id}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        multiple={multiple}
        name={name}
        onChange={(e) => onChange(e)}
      />
      <label id={name} name={name} htmlFor={id}>
        {value.length > 0 ? (
          <Button
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            variant="contained"
            fullWidth
            style={{ textTransform: "initial" }}
            component="span"
            size="large"
          >
            {textLater}
          </Button>
        ) : (
          <Button
            color={colorBefore}
            variant="contained"
            fullWidth
            style={{ textTransform: "initial" }}
            component="span"
            size="large"
          >
            {textBefore}
          </Button>
        )}
      </label>
    </>
  )
}
