import React, { useEffect, useState } from "react"
import MUIDataTable from "mui-datatables"
import { useWindowSize } from "react-use"

import { MuiThemeProvider } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { getMuiTheme } from "./styles"

const SimpleTable = (props) => {
  const { count, title, data, columns, apiCall, render, columnTheme, padding } =
    props
  const [isSearching, setIsSearching] = useState(true)

  useEffect(() => {
    apiCall(function () {
      setIsSearching(false)
    })
    // eslint-disable-next-line
  }, [render])

  // eslint-disable-next-line
  const { width, height } = useWindowSize()

  const mediumViewport = useMediaQuery("(min-width:600px)")

  const options = {
    tableBodyHeight:
      height - (mediumViewport ? 197 : document.title === "Stock" ? 376 : 230),
    tableBodyMaxHeight:
      height - (mediumViewport ? 197 : document.title === "Stock" ? 376 : 230),
    count: count,
    search: false,
    filter: false,
    filterType: "dropdown",
    print: false,
    filterArrayFullMatch: false,
    responsive: "standard",
    download: false,
    rowsPerPageOptions: [],
    selectableRows: "none",
    viewColumns: false,
    rowsPerPage: 100,
    sort: false,
    serverSide: true,
    setTableProps: () => {
      return {
        size: "small",
        padding,
      }
    },
    rowHover: false,
    setRowProps: (row) => {
      if (!row[2].includes("↳")) {
        return {
          style: { background: "#3E984C" },
        }
      }
    },
    textLabels: {
      pagination: {
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
        filter: {
          title: "Filtros",
          reset: "Resetear",
        },
      },
      toolbar: {
        filter: "Filtros",
        filterTable: "Filtros",
        search: "Buscador rápido",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Resetear",
      },
      body: {
        noMatch: isSearching
          ? "Buscando resultados..."
          : "No se encontraron resultados",
      },
    },
  }

  return (
    <React.Fragment>
      <MuiThemeProvider theme={getMuiTheme(columnTheme)}>
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </React.Fragment>
  )
}
export default SimpleTable
