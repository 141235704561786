import React, { useContext, useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const CancelSubmit = (props) => {
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line
  const [edit, setEdit] = useContext(EditContext)
  const { setFormEdit, setFormApi, initialData } = useContext(FormContext)

  const createDismissableSnackbar = useCustomSnackbar()

  const onSubmit = async () => {
    setLoading(true)
    setEdit(false)
    setFormApi({})
    setFormEdit(initialData)
    props.handleclose(true)
    createDismissableSnackbar("Los cambios han sido descartados!", {
      variant: "warning",
    })
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={props.open}
        title="Tiene cambios sin guardar, ¿desea salir?"
        subtitle={"Todos sus cambios se perderán!"}
        widthLarge={"450px"}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={props.handleclose}
              onClickSubmit={onSubmit}
              submitText="Aceptar"
              disabledCancel={loading}
              disabledSubmit={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default CancelSubmit
