import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import UsedTable from "Components/UI/Organisms/Plant/Used/Table"
import NewUsed from "Components/UI/Organisms/Plant/Used/NewUsed"
import SetState from "Components/Hooks/SetState"
import { interfaces } from "service/interfaces"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import { oemColorButton } from "Helpers"

const UsedTemplate = () => {
  const [render, setRender] = useState(false)

  const { state, setClose, setOpen } = SetState()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const openNew = async () => {
    await interfaces.plantUsed.beforeNew().then((res) => {
      setOpen({ data: res.data })
    })
  }

  const BUTTONS = [
    {
      icon: <AddIcon fontSize="small" />,
      title: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: openNew,
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Planta"
            subTitle="Nuevos - usados"
            buttons={BUTTONS}
          />
        }
        table={<UsedTable render={render} setRender={setRender} />}
      />

      {state.suspense && (
        <NewUsed
          open={state.open}
          handleclose={setClose}
          data={state}
          render={render}
          setRender={setRender}
        />
      )}
    </>
  )
}

export default UsedTemplate
