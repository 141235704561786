import { createTheme } from "@material-ui/core/styles"

export const ThemeEco = () => {
  return createTheme({
    palette: {
      primary: {
        main: "#3E984C",
        light: "#3D996050",
      },
      secondary: {
        main: "#019966",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "grey",
          },
        },
      },
    },
  })
}

export const ThemeOem = () => {
  return createTheme({
    palette: {
      primary: {
        main: "#b70913",
        light: "#b7091390",
      },
      secondary: {
        main: "#b70913",
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& $notchedOutline": {
            borderColor: "grey",
          },
        },
      },
    },
  })
}
