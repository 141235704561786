import { axiosService } from "../../../index"
import { plantEndpointsStock } from "../../../sources"

const ingressList = (body) => {
  return axiosService.post(plantEndpointsStock.INGRESS_LIST, body)
}

const ingressProcess = (body) => {
  return axiosService.post(plantEndpointsStock.INGRESS_PROCESS, body)
}

const ingressAdd = (body) => {
  return axiosService.post(plantEndpointsStock.INGRESS_ADD, body)
}

const ingressDelete = (id) => {
  return axiosService.del(
    `${plantEndpointsStock.INGRESS_DELETE}${
      typeof id === "number" ? "/" + id : ""
    }`
  )
}

const returnData = (id) => {
  return axiosService.get(plantEndpointsStock.RETURN_DATA + "/" + id)
}

const returnConfirm = (id, body) => {
  return axiosService.post(plantEndpointsStock.RETURN_CONFIRM + "/" + id, body)
}

const transferToOEMList = (body) => {
  return axiosService.post(plantEndpointsStock.TO_OEM_LIST, body)
}

const transferToOEMConfirm = (body) => {
  return axiosService.post(plantEndpointsStock.TO_OEM_CONFIRM, body)
}

export const plantInterfaceStock = {
  ingressList,
  ingressProcess,
  ingressAdd,
  ingressDelete,
  returnData,
  returnConfirm,
  transferToOEMList,
  transferToOEMConfirm,
}
