import React from "react"

import Box from "@material-ui/core/Box"

import MainFotograps from "./MainPhotos"
import AdditionalPhotos from "./AdditionalPhotos"
import FilesOption from "./Files"
import { styles } from "./styles"

const TabPanel = (props) => {
  const { children, value, index } = props
  return (
    <div>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const MostrarContenidoBox = ({ value, data, id, edit }) => {
  const classes = styles()

  return (
    <div className={classes.style}>
      <TabPanel TabPanel value={value} index={0}>
        <MainFotograps edit={edit} data={data} id={id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdditionalPhotos edit={edit} data={data} id={id} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FilesOption edit={edit} data={data} id={id} />
      </TabPanel>
    </div>
  )
}

export default MostrarContenidoBox
