import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import BMVContent from "./BMVContent"
import { getNavbar } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"

const BrandModelVersion = () => {
  const { state, setOpen, setClose } = SetState()

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Marcas, modelos y versiones"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/tickets.png"}
        disabled={JSON.parse(getNavbar()).bmv ? false : true}
      />
      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        widthLarge="90vw"
        title="Marcas, modelos y versiones"
        open={state.open}
        handleclose={setClose}
        maxHeight
        children={<BMVContent />}
      />
    </>
  )
}

export default BrandModelVersion
