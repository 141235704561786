import React, { useEffect } from "react"

import StockImgTemplate from "Components/UI/Templates/Common/StockImgTemplate"

const StockImgPage = () => {
  useEffect(() => {
    document.title = "Test"
  }, [])

  return <StockImgTemplate />
}

export default StockImgPage
