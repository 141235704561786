import { makeStyles } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  divForm: {
    width: "345px",
  },
  formControl: {
    height: "auto",
  },
  input: {
    height: "73px",
    marginButton: theme.spacing(2),
  },
  textTransformLetter: {
    textTransform: "initial",
  },

  marginButtonX: {
    marginLeft: "5px",
    position: "absolute",
  },
}))

export default useStyles
