import { axiosService } from "service";
import { typeVehicleEndpoints } from "service/sources/config/various/vehicletype";


const list = () => {
    return axiosService.get(typeVehicleEndpoints.LIST)
}
const newVehicle = (code) => {
    return axiosService.post(typeVehicleEndpoints.CREATE , code)
}
const updateVehicle = (id, body) => {
    return axiosService.put(typeVehicleEndpoints.UPDATE + `/${id}`, body)
}
const deleteVehicle = (id) => {
    return axiosService.del(typeVehicleEndpoints.DELETE + `/${id}`)
} 

export const interfaceTypeVehicle = {
    list,
    newVehicle,
    updateVehicle,
    deleteVehicle,
}