import { axiosService } from "service"
import { plantStationEndpoints } from "service/sources/plant/Station"

const list = (options) => {
  return axiosService.get(plantStationEndpoints.LIST + options)
}

const searchVehicle = (search) => {
  return axiosService.get(
    plantStationEndpoints.SEARCH_VEHICLE + `?search=${search}`
  )
}

const uploadPhoto = (empresa, id, files) => {
  return axiosService.uploadFiles(
    plantStationEndpoints.UPLOAD_PHOTO +
      "?empresa=" +
      empresa +
      "&codigo=" +
      id,
    files
  )
}

const createStock = (id, body) => {
  return axiosService.post(plantStationEndpoints.CREATE_STOCK + id, body)
}

export const plantStation = {
  list,
  searchVehicle,
  uploadPhoto,
  createStock,
}
