import React from "react"

import GetAppIcon from "@material-ui/icons/GetApp"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "../../Common"
import ExportClients from "Components/UI/Organisms/Sales/Clients/ExportClients"
import SetState from "Components/Hooks/SetState"

const ClientTemplate = () => {
  const { state, setOpen, setClose } = SetState()

  const BUTTONS = [
    {
      menuIcon: <GetAppIcon fontSize="small" />,
      menuTitle: "Saldos",
      buttonContent: <GetAppIcon />,
      onClick: setOpen,
    },
  ]
  return (
    <TemplateGlobal
      navbar={
        <NavBarGlobal title="Ventas" subTitle="Clientes" buttons={BUTTONS} />
      }
      table={
        <>
          {state.suspense && (
            <ExportClients open={state.open} onClose={setClose} />
          )}
        </>
      }
    />
  )
}

export default ClientTemplate
