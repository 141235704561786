import React, { useState } from "react"

import { Popover, Typography } from "@material-ui/core/"

import PaperComponent from "Components/UI/Atoms/PaperCompoent"
import CustomButton from "Components/UI/Atoms/CustomButton"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import NotiHistory from "Components/UI/Organisms/Home/NavBar/NotiHistory"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import ListItemNotification from "Components/UI/Molecules/ListItemNotification"

import { interfaces } from "service/interfaces"
import useStyles from "./styles"

const NavPopover = (props) => {
  const { data, setData, anchorEl, setAnchorEl } = props

  const classes = useStyles()
  const [openHistory, setOpenHistory] = useState(false)

  const open = Boolean(anchorEl)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleClose = () => {
    setAnchorEl(null)
    setData([])
  }

  const handleOpenCloseDrawerHistory = () => {
    setOpenHistory(!openHistory)
  }

  const deleteNotification = (id) => {
    const newArray = [...data].filter((e) => e.id !== id)
    setData(newArray)
    interfaces.dashboardInterface.deleteNotification(id).then((res) => {
      createDismissableSnackbar(res.data, {
        variant: "success",
      })
    })
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transition
        style={{ zIndex: 1 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PaperComponent
          classname={classes.popContent}
          children={
            <>
              <div style={{ margin: "0px 30px 30px 30px", paddingTop: "20px" }}>
                <div
                  style={{
                    position: "sticky",
                    width: "100%",
                    top: "0px",
                    zIndex: "9999",
                  }}
                >
                  <Typography variant="subtitle1">
                    <b>Notificaciones</b>{" "}
                  </Typography>
                </div>
                <ListItemNotification
                  data={data}
                  deleteNotification={deleteNotification}
                />
                <div className={classes.buttonSticky}>
                  <CustomButton
                    fullWidth
                    text="ver todas"
                    onClick={handleOpenCloseDrawerHistory}
                  />
                </div>
              </div>
            </>
          }
        />
      </Popover>

      <DialogComponent
        tipo="slide"
        open={openHistory}
        handleclose={handleOpenCloseDrawerHistory}
        title="Notificaciones"
        children={
          <NotiHistory data={data} deleteNotification={deleteNotification} />
        }
      />
    </>
  )
}

export default NavPopover
