import React, { useState } from "react"

import PrintIcon from "@material-ui/icons/Print"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import Loader from "Components/UI/Atoms/Loader"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import { printPDF } from "Helpers"
import { columnTheme } from "./styles"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import DialogStorages from "../Dialog"
import EditContextProvider from "Components/Hooks/ContextEdit"
import SetState from "Components/Hooks/SetState"
import { EMPRESAS_STOCK } from "Constants"

const WarehouseTable = (props) => {
  const { render, setRender } = props

  const { state, setOpen, setClose } = SetState()

  const [wait, setWait] = useState(false)
  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  const windowSize = useMediaQuery("(min-width:1000px)")

  const createDismissableSnackbar = useCustomSnackbar()

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantStorage.storageList(options)
      setInfo({
        filters: result.data.filters,
        rows: result.data.rows,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  async function printId(id, e) {
    e.stopPropagation()
    setWait(true)
    await interfaces.plantStorage
      .storageSticker(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      })
      .catch((error) =>
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      )
    setWait(false)
  }

  async function getStorageInfo(id) {
    await interfaces.plantStorage.storageInfo(id).then((res) => {
      setOpen({ data: res.data })
    })
  }

  const actions = (id) => {
    return (
      <div style={{ display: "flex", marginLeft: "-15px" }}>
        <Tooltip title="Imprimir">
          <IconButton
            style={{ color: "black" }}
            onClick={(e) => printId(id, e)}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  function logo(origin) {
    if (origin === 1)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 15,
          }}
        >
          <img src={process.env.PUBLIC_URL + "/Images/logo16.webp"} alt="Logo" />
        </div>
      )
    if (origin === 2)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: 5,
            width: "16px",
            height: "16px",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/Images/oemicon.png"}
            alt="Logo"
          />
        </div>
      )
  }

  const RowsStorages = () =>
    info.rows.map((file) => [
      "",
      logo(file.empresa),
      "Z" + file.codigo.toString().padStart(4, 0),
      file.almacen,
      file.nivel,
      file.estanteria,
      file.columna,
      file.fila,
      file.limite,
      file.bin,
      file.descripcion,
      actions(file.id),
    ])

  const columns = columnsForTable(
    [
      {
        name: "empresa",
        label: " ",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "cod",
        label: "Cód.",
        filterLabel: "Cod",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
        marginLeft: windowSize ? "10px" : "",
      },
      {
        name: "almacen",
        label: "Almacén",
        display: true,
        filter: true,
        sort: true,
        filterName: "label",
        options: info.filters.almacen,
        customBodyRender: true,
      },
      {
        name: "nivel",
        label: "Nivel",
        display: true,
        filter: true,
        sort: true,
        filterName: "label",
        options: info.filters.nivel,
        customBodyRender: true,
      },
      {
        name: "estanteria",
        label: "Estanteria",
        display: true,
        filter: true,
        sort: true,
        filterName: "label",
        options: info.filters.estanteria,
        customBodyRender: true,
      },
      {
        name: "columna",
        label: "Columna",
        display: true,
        filter: true,
        sort: true,
        filterName: "label",
        options: info.filters.columna,
        customBodyRender: true,
      },
      {
        name: "fila",
        label: "Fila",
        display: true,
        filter: true,
        sort: true,
        filterName: "label",
        options: info.filters.fila,
        customBodyRender: true,
      },
      {
        name: "limite",
        label: "Límite",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "bin",
        label: "BIN",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "descripcion",
        label: "Descripción",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "empresa",
        label: "Empresa",
        display: false,
        filter: true,
        sort: false,
        filterName: "optionLabel",
        options: EMPRESAS_STOCK,
        customBodyRender: true,
      },
    ],
    info.filters
  )

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <DialogStorages
            open={state.open}
            onClose={setClose}
            render={render}
            setRender={setRender}
            data={state.data}
          />
        </EditContextProvider>
      )}

      <MuiDatables
        columns={columns}
        data={RowsStorages()}
        title={`Total de almacenes: ${info.header}`}
        apiCall={apiCall}
        count={info.header}
        textPlaceholder="Buscar código Z0 (con o sin Z), descripción o Nº de BIN (solo número)..."
        render={render}
        setRender={setRender}
        columnTheme={columnTheme()}
        padding="none"
        onRowClick={(_, index) => getStorageInfo(info.rows[index.dataIndex].id)}
      />
      {wait ? <Loader /> : null}
    </>
  )
}
export default WarehouseTable
