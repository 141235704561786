import { createBrowserHistory } from 'history'
import moment from 'moment'

import { Box } from '@material-ui/core'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import DriveEtaIcon from '@material-ui/icons/DriveEta'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import {
  getExpireTokenDate,
  getUserId,
  getNavbar,
} from 'service/helpers/localstorage'
import { axiosService } from 'service'

const TabPanel = (props) => {
  const { children, value, index } = props
  return (
    <div>
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

const History = createBrowserHistory()

function checkTokenExpire() {
  const timeLeft = moment(getExpireTokenDate()).diff(
    moment(Date.now()),
    'seconds'
  )
  if (timeLeft <= 0) {
    localStorage.clear()
    History.push('/')
    window.location.reload()
  } else if (timeLeft < 600) {
    axiosService.refreshToken(getUserId())
  }
}

const isAuthorized = (path) => {
  const item = path.split('/').pop()
  if (JSON.parse(getNavbar())[item]) {
    return true
  } else {
    return false
  }
}

const isAuthenticated = () => {
  const timeLeft = moment(getExpireTokenDate()).diff(
    moment(Date.now()),
    'seconds'
  )
  try {
    if (timeLeft > 0) {
      return true
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

function objectValuesToUpperCase(data, ignored) {
  let upperCased = {}

  for (var key in data) {
    if (typeof data[key] === 'string' && key !== ignored) {
      upperCased[key] = data[key].toUpperCase()
    } else {
      upperCased[key] = data[key]
    }
  }
  return upperCased
}

function shortenUrl(arr) {
  let string1 = arr.lastIndexOf('/')
  let string2 = arr.slice(string1 + 1)
  return string2
}

function renamedFilesUrl(arr, name) {
  let string1 = arr.lastIndexOf('.')
  let string2 = arr.slice(string1)
  let string3 = name + string2
  return string3
}

function shortenUrlFiles(arr) {
  let string1 = arr.lastIndexOf('/')
  let string2 = arr.slice(string1 + 1)
  if (string2.length > 15) {
    let newArr = string2.substr(0, 15)
    return newArr + '...'
  }
  return string2
}

function fileNameFromURL(str) {
  let string1 = str.lastIndexOf('/')
  let string2 = str.slice(string1 + 1)
  return string2
}

function NoneEmpty(arr) {
  return arr.indexOf('') === -1
}

function printPDF(data, height = 400, width = 800) {
  const blob = new Blob([data], {
    type: 'application/pdf',
  })
  const fileURL = URL.createObjectURL(blob)
  return window.open(
    fileURL,
    '_blank',
    `location=yes,height=${height},width=${width},scrollbars=yes,status=yes`
  )
}

function compareStateValues(oneState, twoState) {
  if (JSON.stringify(oneState) === JSON.stringify(twoState)) return false
  return true
}

function arraysAreSame(x, y) {
  if (x.length !== y.length) return false
  let result = true
  let findDifference = false
  for (let i = 0; i < x.length; i++) {
    let keys = Object.keys(x[i])
    for (let j = 0; j < keys.length; j++) {
      if (x[i][keys[j]] !== y[i][keys[j]]) {
        result = false
        findDifference = true
        break
      }
    }
    if (findDifference) break
  }
  return result
}

const formatter = new Intl.NumberFormat('es-AR', {
  currency: 'ARS',
  style: 'currency',
  minimumFractionDigits: 0,
})

const fileTypeTest = (str) => {
  if (!str) return
  const photoTypes = /jpeg|jpg|png|webp/
  const videoTypes = /avi|mov|mp4|flv|mkv|wmv|webm|mpeg|mpv|ogg/
  const isPhoto = photoTypes.test(fileNameFromURL(str).toLowerCase())
  const isVideo = videoTypes.test(fileNameFromURL(str).toLowerCase())
  if (isPhoto) return 'image'
  if (isVideo) return 'video'
  return 'doc'
}

function titles(imgs) {
  let imgobj = [
    {
      original: process.env.PUBLIC_URL + '/Images/noPhoto.jpg',
    },
  ]
  if (
    imgs.length > 0 &&
    imgs.filter((e) => fileTypeTest(e.fullscreen) === 'image').length > 0
  ) {
    imgobj = imgs.filter((e) => fileTypeTest(e.fullscreen) === 'image')
  }
  return imgobj
}

function titles2(imgs) {
  let imgobj = [
    {
      original: process.env.PUBLIC_URL + '/Images/noPhoto.jpg',
    },
  ]
  if (
    imgs.length > 0 &&
    imgs.filter((e) => fileTypeTest(e) === 'image').length > 0
  ) {
    imgobj = imgs.map((item) => ({ original: item })).filter((e) => e.original)
  }
  return imgobj
}

function colorBtnUbicacion(back) {
  if (!window.location.href.includes('oem')) {
    return {
      background: back ? back : '#00867E',
      color: 'white',
    }
  }
  return { backgroundColor: '#b70913', color: 'white' }
}

const oemColorButton = (pathname, location) => {
  if ((pathname && pathname.includes('oem')) || location.includes('OEM'))
    return { backgroundColor: '#b70913', color: 'white' }
  return {
    background: '#00867E',
    color: 'white',
  }
}

function dinamicsIcon(id) {
  switch (id) {
    case 1:
      return <SupervisorAccountIcon />
    case 2:
      return <DriveEtaIcon />
    case 3:
      return <EmojiTransportationIcon />
    case 4:
      return <LocalOfferIcon />
    case 5:
      return <ShoppingCartIcon />
    case 6:
      return <AttachMoneyIcon />
    case 7:
      return <LocalShippingIcon />
    case 8:
      return <AutorenewIcon />
    default:
      break
  }
}

function sortArrayObjectsByProperty(array, prop, backwards) {
  if (backwards)
    return array.sort(function (a, b) {
      if (a[prop] > b[prop]) {
        return -1
      }
      if (a[prop] < b[prop]) {
        return 1
      }
      return 0
    })
  return array.sort(function (a, b) {
    if (a[prop] > b[prop]) {
      return 1
    }
    if (a[prop] < b[prop]) {
      return -1
    }
    return 0
  })
}

function logo(origin) {
  if (origin === 'E')
    return (
      <span>
        <img
          src={process.env.PUBLIC_URL + '/Images/logo16.webp'}
          alt='Logo'
          style={{
            height: '18px',
            width: '18px',
          }}
        />
      </span>
    )
  if (origin === 'O')
    return (
      <span>
        <img
          src={process.env.PUBLIC_URL + '/Images/oemicon.png'}
          alt='Logo'
          style={{
            height: '18px',
            width: '18px',
          }}
        />
      </span>
    )
  else
    return (
      <span
        style={{
          color: 'blue',
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 5,
          fontSize: 20,
        }}
      >
        <AutorenewIcon />
      </span>
    )
}

const filterObjectKeys = (obj, keys) =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)))

const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

const isValueInArray = (array, value) => {
  return array.includes(value);
};

export {
  TabPanel,
  History,
  shortenUrlFiles,
  fileNameFromURL,
  checkTokenExpire,
  isAuthenticated,
  objectValuesToUpperCase,
  shortenUrl,
  NoneEmpty,
  renamedFilesUrl,
  isAuthorized,
  printPDF,
  compareStateValues,
  arraysAreSame,
  formatter,
  fileTypeTest,
  titles,
  titles2,
  colorBtnUbicacion,
  dinamicsIcon,
  sortArrayObjectsByProperty,
  logo,
  oemColorButton,
  filterObjectKeys,
  formatDate,
  isValueInArray,
}
