import React, { useRef } from "react";

import { Formik, Form } from "formik";

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import useCustomSnackbar from "Components/UI/Atoms/SnackBar";
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons";
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate";

import { interfaces } from "service/interfaces";

const New = (props) => {
  const { open, handleclose, changeRender } = props;
  const createDismissableSnackbar = useCustomSnackbar();

  const formSubmitRef = useRef(null);
  const handleSubmit = () => {
    formSubmitRef.current.click();
  };

  const onSubmit = async (values) => {
    await interfaces.interfaceTypeVehicle
      .newVehicle(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        });
        changeRender();
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose);
  };

  const object = () => [
    {
      class: "textfield",
      label: "Nombre",
      name: "nombre",
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      label: "Descripción",
      name: "descripcion",
      sm: 12,
      xs: 12,
    },
  ];

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          nombre: "",
          descripcion: "",
          activo: 1,
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={object(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      title="Nuevo tipo de vehículo"
      children={children()}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleclose}
        />
      }
    />
  );
};

export default New;
