import React, { useState } from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import { interfaces } from "service/interfaces"
import { columnTheme } from "./styles"

const StationTable = (props) => {
  const { render, setRender, setCanExport, openZero } = props

  const windowSize = useMediaQuery("(min-width:1000px)")

  const [info, setInfo] = useState({
    rows: [],
    header: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantStation.list(options)
      setInfo({
        rows: result.data.data,
        header: result.header.header.count,
      })
      setCanExport(!result.data.canExport)
      callback()
    } catch (error) {}
  }

  const RowsStation = () =>
    info.rows.map((file) => [
      "",
      file.siniestro,
      file.dominio,
      file.baja,
      file.marca,
      file.modelo,
      file.año,
      file.color,
      file.retiro,
      file.ingreso,
      file.estado,
      "",
    ])

  const columns = columnsForTable(
    [
      {
        name: "siniestro",
        label: "Siniestro",
        filterLabel: "Cod",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "dominio",
        label: "Dominio",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "baja",
        label: "Baja",
        display: windowSize ? true : false,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "marca",
        label: "Marca",
        display: windowSize ? true : false,
        filter: true,
        sort: false,
        customBodyRender: true,
        fullWidth: true,
      },
      {
        name: "modelo",
        label: "Modelo",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        fullWidth: true,
      },
      {
        name: "año",
        label: "Año",
        display: true,
        filter: false,
        sort: false,
        filterName: "descripcion",
      },
      {
        name: "color",
        label: "Color",
        display: true,
        filter: false,
        sort: false,
        filterName: "nombre",
      },
      {
        name: "retiro",
        label: "Fecha de retiro",
        display: true,
        filter: true,
        sort: false,
        filterName: "nombre",
        fullWidth: true,
      },
      {
        name: "ingreso",
        label: "Fecha de ingreso",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
    ],
    []
  )

  return (
    <MuiDatables
      columns={columns}
      data={RowsStation()}
      rows={25}
      title={`Vehículos en proceso: ${info.header}`}
      apiCall={apiCall}
      count={info.header}
      textPlaceholder="Buscar por Nº motor, Nº chasis, Nº V o dominio..."
      render={render}
      setRender={setRender}
      columnTheme={columnTheme()}
      filterEnabled={false}
      searchEnabled={false}
      onRowClick={(_, index) =>
        openZero({ code: info.rows[index.dataIndex].dominio })
      }
    />
  )
}
export default StationTable
