import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const SubmitSave = (props) => {
  const { handleClose, handleSubmit, open } = props

  return (
    <div>
      <DialogComponent
        open={open}
        title="¿Desea confirmar los cambios?"
        handleclose={handleClose}
        widthLarge={"450px"}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickSubmit={() => {
                handleSubmit()
              }}
              onClickCancel={() => handleClose()}
            />
          </div>
        }
      />
    </div>
  )
}

export default SubmitSave
