import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

export default function DeletePhoto(props) {
  const { data, open, handleClose, onSubmit, loading } = props
  return (
    <DialogComponent
      open={open}
      handleclose={loading ? null : handleClose}
      widthLarge="500px"
      disableChildHeight
      title="¿Desea eliminar esta foto?"
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={() => handleClose()}
            onClickSubmit={() => onSubmit(data.data, data.index)}
            isLoading={loading}
            disabledCancel={loading}
            disabledSubmit={loading}
          />
        </div>
      }
    />
  )
}