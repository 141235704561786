import React from "react"

import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"

const AutocompleteFilter = (props) => {
  const {
    label,
    options,
    onChange,
    filterList,
    index,
    column,
    style,
    filterName,
    getOptionLabel,
    noOptionsText = "No se encontraron resultados",
    fullWidth,
    defaultOption = null
  } = props

  return (
    <Autocomplete
      fullWidth={fullWidth}
      disableClearable
      getOptionLabel={(options) =>
        // eslint-disable-next-line
        getOptionLabel ? eval(getOptionLabel) : options[filterName]
      }
      style={style}
      options={options}
      noOptionsText={noOptionsText}
      openText={"Abrir"}
      closeText={"Cerrar"}
      clearText={"Borrar"}
      defaultValue={
        filterList[index].length > 0
          ? { [filterName]: filterList[index][0] }
          : defaultOption || { [filterName]: "Seleccionar..." }
      }      
      onChange={(event, value) => {
        filterList[index] = [value[filterName]]
        onChange(filterList[index], index, column)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          // className={style}
          margin="normal"
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  )
}

export default AutocompleteFilter
