import React, { useState } from "react"

import { Hidden, IconButton, Menu } from "@material-ui/core"
import MoreIcon from "@material-ui/icons/MoreVert"

import { CustomMenuItems } from "Components/UI/Atoms/CustomMenuItems"

const MenuItem = (props) => {
  const { buttonsMobile } = props

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchororigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <CustomMenuItems
        menuItems={buttonsMobile.filter((e) => e)}
        onClose={handleMobileMenuClose}
      />
    </Menu>
  )

  return (
    <>
      <Hidden smUp>
        <IconButton
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
          style={{ background: "#fff" }}
        >
          <MoreIcon />
        </IconButton>
      </Hidden>
      {renderMobileMenu}
    </>
  )
}

export default MenuItem
