const ENGINE_LIST = "/settings/engines/enginelist"
const NEW_ENGINE = "/settings/engines/newengine"
const UPDATE_ENGINE = "/settings/engines/engine"
const VEHICLE_ENGINE = "/settings/engines/vehicles"
const EXPORT_MOTORES = "/settings/engines/exportall"
const UPGRADE_ENGINE = "/settings/engines/updatestock"
const UPDATE_ALL_ENGINES = "/settings/engines/updateall"

export const engineEndpoints = {
  ENGINE_LIST,
  NEW_ENGINE,
  UPDATE_ENGINE,
  VEHICLE_ENGINE,
  EXPORT_MOTORES,
  UPGRADE_ENGINE,
  UPDATE_ALL_ENGINES,
}
