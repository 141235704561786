import React, { useState, useRef, useEffect } from "react"

import { IconButton } from "@material-ui/core"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"

import CancelEdit from "./Cancel"
import FormEdit from "./FormEdit"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"

const ModalCostosYGastos = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const { data, handleClose } = props

  const [dataEdit, setDataEdit] = useState({ data })
  const [companies, setCompanies] = useState([])
  const [types, setTypes] = useState([])
  const formSubmitRef = useRef(null)
  const { state: openPay, setOpen: setOpenPay, setClose: setClosePay } = SetState()
  const { state: openCancel, setOpen: setOpenCancel, setClose: setCloseCancel } = SetState()

  const openCancelButton = () => {
    if (data !== dataEdit.data) {
      setOpenCancel()
    } else {
      setClosePay()
    }
  }

  const handleSubmit = () => {
    formSubmitRef.current.click()
    setClosePay()
    handleClose()
  }

  const handleClosePay = () => {
    setClosePay()
    setDataEdit({ data })
  }

  const onSubmit = (values) => {
    interfaces.vehicleInterface
      .updatePayment(data.id, values)
      .then((res) => {
        createDismissableSnackbar(res.data ?? "Pago editado correctamente", {
          variant: "success",
        })
      })
      .catch((err) => {
        createDismissableSnackbar("Hubo un error al editar el pago", {
          variant: "error",
        })
      })
  }

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    await interfaces.vehicleInterface.getCompanies().then((res) => {
      setCompanies(res.data)
    })
    await interfaces.conceptsInterface.getConcepts().then((res) => {
      setTypes(res.data)
    })
  }
  return (
    <div>
      <IconButton onClick={setOpenPay}>
        <OpenInNewIcon />
      </IconButton>
      <DialogComponent
        tipo="center"
        open={openPay.open}
        handleclose={openCancelButton}
        title={`Pago a proveedor #` + props.nro}
        disableBackdropClick={true}
        customHeight="auto"
        widthLarge="30vw"
        children={
          <FormEdit
            ref={formSubmitRef}
            onSubmit={onSubmit}
            data={data}
            change={dataEdit}
            setChange={setDataEdit}
            handleClose={handleClose}
            companies={companies}
            types={types}
          />
        }
        actions={
          <CancelConfirmButtons
            onClickCancel={openCancelButton}
            onClickSubmit={handleSubmit}
            submitText="Guardar"
          />
        }
      />
      <CancelEdit
        open={openCancel.open}
        handleclose={setCloseCancel}
        handleClosePay={handleClosePay}
      />
    </div>
  )
}

export default ModalCostosYGastos
