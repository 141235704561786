import React, { useState } from "react"

import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import columnsForTable from "Components/UI/Atoms/Table/Columns"
import { columnTheme } from "./styles"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"

const TableCompaction = (props) => {
  const { render, setRender, moreInfo, print } = props

  const windowSize = useMediaQuery("(min-width:1000px)")

  const { state, setOpen, setClose } = SetState()

  const [info, setInfo] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  async function apiCall(options, callback) {
    setInfo({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.plantDespatch.list(options)
      setInfo({
        filters: result.data.filters,
        rows: result.data.mappedData,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  const columns = columnsForTable(
    [
      {
        name: "origen_destino",
        label: " ",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
        marginLeft: windowSize ? "20px" : "",
      },
      {
        name: "id",
        label: "Remito",
        filterLabel: "Remito",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "nro",
        label: "Comprobante",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "tipo",
        label: "Tipo",
        display: true,
        filter: true,
        sort: false,
        filterLabel: "Tipo",
        filterName: "label",
        options: info.filters.tipo,
        fullWidth: true,
      },
      {
        name: "cliente",
        label: "Cliente",
        display: true,
        filter: false,
        sort: false,
      },
      {
        name: "fecha",
        label: "Fecha",
        display: true,
        filter: false,
        sort: true,
      },
      {
        name: "origen",
        label: "Origen",
        display: false,
        filter: true,
        sort: true,
        filterLabel: "Origen",
        filterName: "nombre",
        options: info.filters.origen,
        fullWidth: true,
      },
      {
        name: "destino",
        label: "Destino",
        display: false,
        filter: true,
        sort: true,
        customBodyRender: true,
        filterLabel: "Destino",
        filterName: "nombre",
        options: info.filters.destino,
        fullWidth: true,
      },
      {
        name: "observaciones",
        label: "Observaciones",
        display: true,
        filter: false,
        sort: false,
        customBodyRender: true,
      },
      {
        name: "estado",
        label: "Estado",
        display: true,
        filter: true,
        sort: true,
        filterLabel: "Estado",
        filterName: "nombre",
        options: [{ nombre: "Confirmado" }, { nombre: "Pendiente" }],
        fullWidth: true,
      },
      {
        name: "usuario",
        label: "Usuario",
        display: true,
        filter: true,
        sort: false,
        customBodyRender: true,
        filterLabel: "Usuario",
        filterName: "fullName",
        options: info.filters.usuarios,
        fullWidth: true,
      },
      {
        name: "",
        label: "",
        display: true,
        filter: false,
        sort: false,
      },
    ],
    info.filters
  )

  const RowsCompaction = () =>
    info.rows.map((file) => [
      "",
      <b>{file.origen_destino}</b>,
      file.remito,
      file.comprobante,
      file.tipo,
      file.cliente,
      file.fecha,
      "",
      "",
      file.observaciones,
      <b style={{ color: file.estado === "Pendiente" ? "red" : "green" }}>
        {file.estado}
      </b>,
      file.usuario,
      actions(file.id, file.estado),
    ])

  function openPrint(e, id) {
    e.stopPropagation()
    setOpen({ id: id })
  }

  const actions = (id, status) => {
    return (
      <Tooltip title="Imprimir">
        <IconButton
          onClick={(e) =>
            status === "Confirmado" ? print(id, e) : openPrint(e, id)
          }
          style={{ color: "black" }}
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <>
      <MuiDatables
        columns={columns}
        data={RowsCompaction()}
        render={render}
        count={info.header}
        title={`Total de remitos: ${info.header}`}
        setRender={setRender}
        textPlaceholder="Buscar número de remito o cliente..."
        apiCall={apiCall}
        columnTheme={columnTheme()}
        onRowClick={(_, index) => moreInfo(info.rows[index.dataIndex].id)}
        padding={windowSize ? "none" : ""}
      />

      {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={setClose}
          title="¿Desea confirmar e imprimir este remito?"
          widthLarge="500px"
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              onClickSubmit={() => print(state.id)}
            />
          }
        />
      )}
    </>
  )
}

export default TableCompaction
