import React, { useState, useEffect, useRef } from "react"

import { useEffectOnce } from "react-use"

import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"

import TableUpdate from "Components/UI/Organisms/Sales/Prices/Components/UpdateBoard/Table"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { editRowTableUpdate } from "Components/Validation/Sales/Prices"
import {
  col,
  formEdit,
} from "Components/UI/Organisms/Sales/Prices/Components/UpdateBoard/Fields"

import { interfaces } from "service/interfaces"
import { useStyles } from "./styles"
import SetState from "Components/Hooks/SetState"

const UpdateBoard = (props) => {
  const {
    dataAutocomplete,
    setDataAutocomplete,
    idEdit,
    setIdEdit,
    render,
    setRender,
  } = props
  const classes = useStyles()
  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRefEdit = useRef(null)
  const [data, setData] = useState([])

  const {
    state: stateEdit,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const {
    state: stateDelete,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  useEffect(() => {
    async function apiCall() {
      await interfaces.salesPrice.controlList().then((res) => {
        setData(res.data)
      })
    }
    apiCall()
    // eslint-disable-next-line
  }, [render])

  useEffectOnce(() => {
    async function apiCall() {
      await interfaces.salesPrice
        .beforeCreateControl()
        .then((res) => setDataAutocomplete(res.data))
    }
    apiCall()
    // eslint-disable-next-line
  }, [])

  function handleEditControl(values) {
    interfaces.salesPrice
      .editControl(values, data[idEdit].id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleCloseEdit()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const handleDeleteControl = (index) => {
    interfaces.salesPrice
      .deleteControl(data[index].id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setCloseDelete()
        setCloseEdit()
        setIdEdit(null)
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  function handleOpenEdit(index) {
    setIdEdit(typeof idEdit === "number" ? null : index)
    setOpenEdit()
  }

  function handleOpenDelete() {
    setOpenDelete()
  }

  function handleCloseEdit() {
    setCloseEdit()
    setIdEdit(null)
  }

  function handleCloseDelete() {
    setCloseDelete()
  }

  const handleSubmitEdit = () => {
    formSubmitRefEdit.current.click()
  }

  const editButton = (index) => {
    return (
      <Tooltip title="Editar">
        <IconButton onClick={() => handleOpenEdit(index)}>
          <EditIcon style={{ color: "black" }} />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Tablero de actualizaciones
          </Typography>
          <hr />
        </Grid>
        <Grid item sm={12} md={12} xs={12}>
          <TableUpdate
            size="small"
            col={col}
            rows={data.map((row, index) => {
              return {
                pieza: row.producto,
                cantidad_productos: row.cantidad_productos,
                ult_act: row.ult_act,
                tc_cambio: row.tc_cambio,
                prox_act: row.prox_act,
                referencia: row.referencia,
                edit: editButton(index),
              }
            })}
          />
        </Grid>
      </Grid>

      {stateEdit.suspense && (
        <DialogComponent
          title="Editar control de actualización"
          open={stateEdit.open}
          handleclose={handleCloseEdit}
          widthLarge="480px"
          customHeight="770px"
          maxHeight
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          children={formEdit(
            handleEditControl,
            data,
            editRowTableUpdate,
            idEdit,
            dataAutocomplete,
            formSubmitRefEdit
          )}
          actions={
            <CancelConfirmButtons
              leftButtons
              leftButtonOnClick={handleOpenDelete}
              leftButtonText="Eliminar"
              leftButtonHiddenFalse
              leftButtonStyle={{ backgroundColor: "red", color: "white" }}
              onClickCancel={handleCloseEdit}
              onClickSubmit={handleSubmitEdit}
            />
          }
        />
      )}

      {stateDelete.suspense && (
        <DialogComponent
          title="¿Está seguro de eliminar el siguiente control?"
          open={stateDelete.open}
          handleclose={() => handleCloseDelete()}
          widthLarge="600px"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          actions={
            <CancelConfirmButtons
              onClickCancel={() => handleCloseDelete()}
              onClickSubmit={() => handleDeleteControl(idEdit)}
            />
          }
        />
      )}
    </>
  )
}

export default UpdateBoard
