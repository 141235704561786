import React, { useState, useEffect } from "react"

import { Avatar, IconButton, Grid, Typography } from "@material-ui/core"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"

import useStyles from "../styles"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"

const DrawerPerfil = () => {
  const classes = useStyles()
  const [user, setUser] = useState([])
  const createDismissableSnackbar = useCustomSnackbar()
  useEffect(() => {
    async function apiCall() {
      interfaces.dashboardInterface
        .userProfile()
        .then((res) => setUser(res.data))
    }
    apiCall()
  }, [])
  function changeProfile(value) {
    let imgUp = URL.createObjectURL(value)
    setUser({
      ...user,
      perfil: imgUp,
    })
  }
  function handleChange(e) {
    let value = e.target.files[0]
    if (value !== null) {
      interfaces.dashboardInterface
        .profilePhoto(value)
        .then((res) => {
          createDismissableSnackbar("Foto subida con éxito!", {
            variant: "success",
          })
          changeProfile(value)
          localStorage.setItem("profile", res.data.thumbnail)
        })
        .catch(() => {
          createDismissableSnackbar("Algo salió mal!", {
            variant: "error",
          })
        })
    }
  }
  const OBJETO = [
    {
      class: "textfield",
      label: "Nombre de usuario",
      value: user.username,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
    {
      class: "textfield",
      label: "Departamento",
      value: user.departamento,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
    {
      class: "textfield",
      label: "Ocupacion",
      value: user.ocupacion,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
    {
      class: "textfield",
      label: "Ubicacion",
      value: user.ubicacion,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
    {
      class: "textfield",
      label: "Correo electrónico",
      value: user.email,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
    {
      class: "textfield",
      label: "Punto de venta",
      value: user.punto_venta,
      disabled: true,
      sm: 12,
      xs: 12,
      margin: "normal",
    },
  ]
  return (
    <form>
      <Grid container justify="space-evenly" alignItems="center">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Avatar src={user.perfil} className={classes.large} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="raised-button-file">
                <IconButton
                  component="span"
                  style={{
                    bottom: "20px",
                    background: "#3E984C",
                  }}
                >
                  <AddAPhotoIcon
                    style={{
                      color: "white",
                    }}
                  />
                </IconButton>
              </label>
            </div>
          </Grid>
          <Grid item>
            <Typography>
              <strong>{user.nombre}</strong>
            </Typography>
          </Grid>
          <Grid sm={12} xs={12} item>
            <InputsWithoutValidate fields={OBJETO} spacing={2} />
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
export default DrawerPerfil
