import React, { useEffect } from "react"

import ChecklistTemplate from "Components/UI/Templates/Plant/Checklist"

const Checklist = () => {
  useEffect(() => {
    document.title = "Checklist"
  }, [])

  return <ChecklistTemplate />
}

export default Checklist
