import { plantEndpointsOrders } from "service/sources/plant/Orders"
import { axiosService } from "service"

const tableList = (options) => {
  return axiosService.get(plantEndpointsOrders.TABLE_LIST + options)
}

const tableFilters = () => {
  return axiosService.get(plantEndpointsOrders.TABLE_FILTERS)
}

const orderInfo = (id) => {
  return axiosService.get(plantEndpointsOrders.ORDER_INFO + "/" + id)
}

const updateOrder = (id, data) => {
  return axiosService.put(plantEndpointsOrders.UPDATE_ORDER + "/" + id, data)
}

const searchStockOrVehicle = (options) => {
  return axiosService.get(plantEndpointsOrders.SEARCH_STOCK_VEHICLE + options)
}

const newOrder = (body) => {
  return axiosService.post(plantEndpointsOrders.NEW_ORDER, body)
}

const beforeInitOrder = (id) => {
  return axiosService.get(plantEndpointsOrders.BEFORE_INIT_ORDER + "/" + id)
}

const initOrder = (id, body) => {
  return axiosService.getPDF(plantEndpointsOrders.INIT_ORDER + "/" + id, body)
}

const printCommand = (id) => {
  return axiosService.getPDF(plantEndpointsOrders.PRINT_COMMAND + "/" + id)
}

const processOrder = (id, opcion = "") => {
  return axiosService.post(
    plantEndpointsOrders.PROCESS_ORDER + "/" + id + opcion
  )
}

const endOrder = (id, body) => {
  return axiosService.post(plantEndpointsOrders.END_ORDER + "/" + id, body)
}

const editMultipleState = (body) => {
  return axiosService.post(plantEndpointsOrders.EDIT_MULTIPLE_STATE, body)
}

const duplicateOrder = (id, body) => {
  return axiosService.post(
    plantEndpointsOrders.DUPLICATE_ORDER + "/" + id,
    body
  )
}

const searchVehicle = (value) => {
  return axiosService.get(
    plantEndpointsOrders.SEARCH_VEHICLE + "?search=" + value
  )
}

const updatehVehicle = (id, body) => {
  return axiosService.post(plantEndpointsOrders.UPDATE_VEHICLE + id, body)
}

const searchStock = (value) => {
  return axiosService.get(
    plantEndpointsOrders.SEARCH_STOCK + "?codigo=" + value
  )
}

const updateStock = (id, body) => {
  return axiosService.post(plantEndpointsOrders.UPDATE_STOCK + id, body)
}

const getStockData = (enterprise, code) => {
  return axiosService.get(
    `${plantEndpointsOrders.GET_STOCK}?empresa=${enterprise}&codigo=${code}`
  )
}

const exportOrders = (body) => {
  return axiosService.post(plantEndpointsOrders.EXPORT_ORDERS , body)
}
 

export const plantOrders = {
  tableList,
  tableFilters,
  orderInfo,
  updateOrder,
  searchStockOrVehicle,
  newOrder,
  beforeInitOrder,
  initOrder,
  printCommand,
  processOrder,
  endOrder,
  editMultipleState,
  duplicateOrder,
  searchVehicle,
  updatehVehicle,
  searchStock,
  updateStock,
  getStockData,
  exportOrders
}
