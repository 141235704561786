const GETINFO_USER = "/dashboard/getInfo"
const HISTORY = "/dashboard/records"
const LIST_NOTIFICATION = "/dashboard/notifications"
const CHANGE_PASS = "/dashboard/newpassword"
const ANDREANI = "/dashboard/andreani"
const PROFILE_PHOTO = "/dashboard/profilephoto"
const VEHICLE_DATA = "/dashboard/getvehicledata"
const UPLOAD_PHOTOS = "/dashboard/mainphotos"
const GET_VEHICLE_DATA = "/dashboard/getvehicledata"
const SEARCH_STOCK = "/dashboard/searchstock"
const UPDATE_SETTINGS = "/dashboard/updatesettings"
const UPDATE_INFO_VEHICLE = "/dashboard/updatevehicle/"
const READ_NOTI_ID = "/dashboard/notifications/"
const DELETE_NOTIFICATION = "/dashboard/notifications/"
const STATION_ZERO = "/dashboard/stationzerostock"

export const dashboardEndpoints = {
  GETINFO_USER,
  ANDREANI,
  HISTORY,
  LIST_NOTIFICATION,
  CHANGE_PASS,
  PROFILE_PHOTO,
  VEHICLE_DATA,
  UPLOAD_PHOTOS,
  GET_VEHICLE_DATA,
  SEARCH_STOCK,
  UPDATE_SETTINGS,
  UPDATE_INFO_VEHICLE,
  READ_NOTI_ID,
  DELETE_NOTIFICATION,
  STATION_ZERO,
}
