const TABLE_LIST = "/plant/orders/list"
const TABLE_FILTERS = "/plant/orders/filters"
const ORDER_INFO = "/plant/orders/info"
const UPDATE_ORDER = "/plant/orders/info"
const SEARCH_STOCK_VEHICLE = "/plant/orders/neworder"
const NEW_ORDER = "/plant/orders/neworder"
const BEFORE_INIT_ORDER = "/plant/orders/start"
const INIT_ORDER = "/plant/orders/start"
const PRINT_COMMAND = "/plant/orders/print"
const PROCESS_ORDER = "/plant/orders/process"
const EDIT_MULTIPLE_STATE = "/plant/orders/change"
const END_ORDER = "/plant/orders/end"
const DUPLICATE_ORDER = "/plant/orders/duplicate"
const SEARCH_VEHICLE = "/plant/orders/search"
const UPDATE_VEHICLE = "/plant/orders/update/"
const SEARCH_STOCK = "/plant/orders/getstock"
const UPDATE_STOCK = "/plant/orders/updatestock/"
const GET_STOCK = "/plant/orders/getstockdata"
const EXPORT_ORDERS = "/plant/orders/export"

export const plantEndpointsOrders = {
  TABLE_LIST,
  TABLE_FILTERS,
  ORDER_INFO,
  UPDATE_ORDER,
  SEARCH_STOCK_VEHICLE,
  NEW_ORDER,
  BEFORE_INIT_ORDER,
  INIT_ORDER,
  PRINT_COMMAND,
  PROCESS_ORDER,
  EDIT_MULTIPLE_STATE,
  END_ORDER,
  DUPLICATE_ORDER,
  SEARCH_VEHICLE,
  UPDATE_VEHICLE,
  SEARCH_STOCK,
  UPDATE_STOCK,
  GET_STOCK,
  EXPORT_ORDERS
}
