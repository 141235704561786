import React, { useEffect } from "react"
import SupplierTemplate from "Components/UI/Templates/Sales/Suppliers"

const Supplier = () => {
  useEffect(() => {
    document.title = "Proveedores"
  }, [])

  return <SupplierTemplate />
}

export default Supplier
