import { withStyles } from "@material-ui/core/styles"
import MuiListItem from "@material-ui/core/ListItem"

const ListItem = withStyles((theme) => ({
  root: {
    borderRadius: "15px",
    color: "#414141",
    "&$selected": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "&$selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
  },
  selected: {},
}))(MuiListItem)

export default ListItem
