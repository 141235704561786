import { axiosService } from "service"
import { priceStockEndpoint } from "service/sources"

const updatePrices = (data) => {
  return axiosService.put(priceStockEndpoint.UPDATE_PRICE, data)
}

const controlData = () => {
  return axiosService.get(priceStockEndpoint.CONTROL_DATA)
}

const controlList = () => {
  return axiosService.get(priceStockEndpoint.CONTROL_LIST)
}

const beforeCreateControl = () => {
  return axiosService.get(priceStockEndpoint.BEFORE_CREATE_CONTROL)
}

const createControl = (data) => {
  return axiosService.post(priceStockEndpoint.CREATE_CONTROL, data)
}

const editControl = (data, id) => {
  return axiosService.put(priceStockEndpoint.EDIT_CONTROL + id, data)
}

const deleteControl = (id) => {
  return axiosService.del(priceStockEndpoint.DELETE_CONTROL + "/" + id)
}

export const salesPrice = {
  updatePrices,
  controlData,
  controlList,
  beforeCreateControl,
  createControl,
  editControl,
  deleteControl,
}
