import React, { useEffect, useState } from "react"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import PermissionsContent from "./Content"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const Permissions = ({ permissions, open, handleClose, renderPermissions }) => {
  const [data, setData] = useState(permissions)
  const [loading, setLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const changeRender = () => {
    renderPermissions()
  }

  useEffect(() => {
    setData(permissions)
  }, [permissions])

  const onSubmit = async () => {
    setLoading(true)
    await interfaces.configUsersInterface
      .updateTemplate(data.editData)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setData(permissions)
    handleClose()
    setLoading(false)
  }

  return (
    <>
      <DialogComponent
        widthLarge="90vw"
        customHeight="55vh"
        title="Template y permisos"
        open={open}
        maxHeight
        children={
          <PermissionsContent
            data={data}
            setData={setData}
            changeRender={changeRender}
          />
        }
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={onSubmit}
              disabledCancel={loading}
              disabledSubmit={data.editData ? loading : true}
              isLoading={loading}
            />
          </div>
        }
      />
    </>
  )
}

export default Permissions
