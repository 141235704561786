import React, { useState } from "react"

import { Stepper, Step, StepLabel } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

import { Step1, Step2 } from "./Content"
import useStyles from "./style"
import { interfaces } from "service/interfaces"

const steps = ["Selección de vehículo", "Datos para el envío"]

const StepperTransit = (props) => {
  const { open, handleClose, render, setRender } = props
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [info, setInfo] = useState({})
  const [code, setCode] = useState({
    code: "",
  })
  const handleChangeCode = (e) => {
    setCode({ ...code, code: e.target.value })
  }

  const handleChange = (name, value) => {
    setInfo({
      ...info,
      new: {
        ...info.new,
        [name]: value,
      },
    })
  }

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <InputsWithoutValidate
            fields={Step1(
              info.data,
              handleSubmitCode,
              handleChangeCode,
              code,
              isLoading
            )}
            spacing={2}
          />
        )
      case 1:
        return (
          <InputsWithoutValidate
            fields={Step2(info, handleChange)}
            spacing={2}
          />
        )
      default:
        break
    }
  }

  async function handleSubmitCode(values) {
    setIsLoading(true)
    await interfaces.transitInterface
      .searchVehicle(values.dominio.toUpperCase())
      .then((res) =>
        setInfo({
          ...info,
          data: res.data,
          new: {
            ...info.new,
            vehiculo_id: res.data.vehiculo_id,
            origen_id: res.data.ubicacion_id,
            destino_id: null,
            observaciones: "",
          },
        })
      )
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  async function handleSubmit() {
    setIsLoading(true)
    await interfaces.transitInterface
      .newTransit(info.new)
      .then((res) => {
        createDismissableSnackbar(res.data, { variant: "success" })
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setIsLoading(false)
  }

  async function handleOnClick() {
    if (activeStep === 1) {
      await handleSubmit()
      setIsLoading(true)
      handleClose()
      setRender(!render)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  function handleBack() {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1)
    } else {
      handleClose()
    }
  }

  function disabledSubmit() {
    switch (activeStep) {
      case 0:
        if (info.data ? info.data.marca : null) return false
        return true
      case 1:
        if (info.new ? info.new.destino_id && info.new.observaciones : null)
          return false
        return true
      default:
        break
    }
  }

  return (
    <DialogComponent
      title={"Nuevo tránsito"}
      open={open}
      handleclose={handleClose}
      handleclosedisabled={isLoading}
      widthLarge="500px"
      disableBackdropClick
      disableEscapeKeyDown
      maxHeight
      children={
        <>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div style={{ height: "400px" }}>
            {_renderStepContent(activeStep)}
          </div>
        </>
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleOnClick}
          onClickCancel={handleBack}
          submitText={activeStep === 1 ? "Confirmar" : "Siguiente"}
          cancelText={activeStep === 0 ? "Cancelar" : "Atrás"}
          disabledSubmit={isLoading ? true : disabledSubmit()}
          disabledCancel={isLoading}
          isLoading={isLoading}
        />
      }
    />
  )
}
export default StepperTransit
