import * as yup from "yup"

export const validationCodigoCollector = yup.object({
  codigos: yup.string().required("Este campo es obligatorio"),
})

export const formRefund = yup.object({
  producto: yup.string(),
  vehiculo: yup.string(),
  calidad: yup.string().required("Este campo es obligatorio"),
  estado: yup.string().required("Este campo es requerido"),
  comentarios: yup.string().required("Este campo es requerido"),
})

export const validationCode = yup.object().shape({
  codigos: yup.string().nullable().required(""),
})
