import React, { useRef } from "react"
import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
import { InputAdornment } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "./styles"

export default function InputSecondNav(props) {
  const classes = useStyles()
  const { onSubmit, value, colorlessBtn, validationSchema, loading } = props

  const refInput = useRef(null)

  function handleRef() {
    refInput.current.click()
  }

  const IconAdornement = () => {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end">
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color={colorlessBtn ? undefined : "primary"}
            className={classes.iconButton}
            onClick={() => handleRef()}
            size="small"
          >
            <ArrowRightAltIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <div className={classes.root}>
      <Formik
        validateOnChange
        enableReinitialize
        initialValues={{
          codigos: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values)
          resetForm({})
        }}
        validationSchema={validationSchema}
        className={classes.input}
      >
        {({ setFieldValue }) => (
          <Form>
            <Field
              className={classes.inputStyle}
              size="small"
              label="SOLO SCANNER"
              autoFocus={true}
              component={TextField}
              name={"codigos"}
              fullWidth
              disabled={false}
              variant="outlined"
              placeholder={value.slice(-1).pop()}
              onChange={(e) =>
                setFieldValue("codigos", e.target.value.toUpperCase())
              }
              InputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  root: classes.cssLabel,
                },
                endAdornment: <IconAdornement />,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
            />

            <button style={{ display: "none" }} ref={refInput} type="submit" />
          </Form>
        )}
      </Formik>
    </div>
  )
}
