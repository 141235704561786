import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
  },
  expand: {
    marginLeft: "auto",
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#fff",
  },
  parent: {
    width: "100%",
    height: "300px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  photo: {
    maxWidth: "inherit",
    maxHeight: "inherit",
    height: "inherit",
    width: "inherit",
    objectFit: "cover",
  },
  imgCard: {
    width: "100%  ",
    height: "90vh",
    overflow: "hidden",
    display: "flex",
  },
  classTest: {
    position: "absolute",
    bottom: "10px",
    right: 0,
    zIndex: 1,
    height: " 50px",
    width: "50px",
    border: "none",
    color: "white",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all linear 0.1s",
  },
  donwloadIcon: {
    position: "absolute",
    bottom: "10px",
    right: 50,
    zIndex: 1,
    height: " 50px",
    width: "50px",
    border: "none",
    color: "white",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all linear 0.1s",
  },
  subtitle: {
    display: "flex",
    alignItems: "center",
  },
  estadoTramite: {
    marginLeft: "auto",
    color: "blue",
  },
}))

export default useStyles
