import React, { useRef } from "react"

import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import { model } from "Components/Validation/Settings/Various/BrandModelVersion"

import { interfaces } from "service/interfaces"

const Edit = (props) => {
  const { open, handleclose, data, idModel, changeRender } = props

  const createDismissableSnackbar = useCustomSnackbar()
  const refInput = useRef(null)
  function handleRef() {
    refInput.current.click()
  }
  function brandInfo() {
    return data.filter((e) => {
      return e.id === idModel
    })[0]
  }

  const info = brandInfo()

  const onSubmit = (values) => {
    let value = { modelo: values.modelo.toUpperCase() }
    interfaces.configInterface
      .updateModel(idModel, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        widthLarge={"500px"}
        title="Editar modelo"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={
          <>
            <Formik
              enableReinitialize
              initialValues={{
                modelo: info.modelo,
              }}
              onSubmit={(values) => {
                onSubmit(values)
              }}
              validationSchema={model}
            >
              {({ values }) => (
                <Form>
                  <Field
                    style={{ height: "70px" }}
                    label="Nombre modelo"
                    value={values.modelo}
                    autoFocus={true}
                    component={TextField}
                    name="modelo"
                    fullWidth
                    disabled={false}
                    variant="outlined"
                  />

                  <button
                    style={{ display: "none" }}
                    ref={refInput}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleRef}
            onClickCancel={handleclose}
          />
        }
      />
    </>
  )
}

export default Edit
