import React from "react"
import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import { getNavbar } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ContentUserInsurance from "./Content"

const UserInsurance = () => {
  const { state, setOpen, setClose } = SetState()
  return (
    <>
      <ButtonInsideBox
        text="Usuarios aseguradoras"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/Insuraces_users.webp"}
        onClick={setOpen}
        disabled={JSON.parse(getNavbar()).operators ? false : true}
      />
      {state.suspense && (
        <DialogComponent
          disableBackdropClick
          disableEscapeKeyDown
          open={state.open}
          handleclose={setClose}
          widthLarge="50wv"
          title="Usuarios aseguradoras"
          maxHeight
          children={<ContentUserInsurance />}
        />
      )}
    </>
  )
}

export default UserInsurance
