import React from 'react'
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

import { interfaces } from "service/interfaces"
import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import SetState from 'Components/Hooks/SetState'

const ModalDelete = ({ data, nro, onDelete }) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const { state, setOpen, setClose } = SetState()

  async function onConfirm() {
    try {
      const res = await interfaces.vehicleInterface.deletePayment(data.id)

      onDelete(data.id)
      createDismissableSnackbar(res.data ?? "Pago eliminado correctamente", {
        variant: "success",
      })
      setClose()
    } catch (err) {
      createDismissableSnackbar("Hubo un error al eliminar el pago", {
        variant: "error",
      })
    }
  }

  return (
    <div>
      <IconButton onClick={setOpen}>
        <DeleteIcon />
      </IconButton>
      <DialogComponent
        open={state.open}
        disableBackdropClick={true}
        title={`Eliminar pago a proveedor #${nro}?`}
        actions={
          <CancelConfirmButtons
            onClickCancel={setClose}
            onClickSubmit={onConfirm}
          />
        }
      />
    </div>
  )
}

export default ModalDelete
