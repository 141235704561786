import React, { useState } from "react"

import { TextField } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const EndOrder = (props) => {
  const { end, closeEnd, render, setRender } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [endText, setEndText] = useState("")

  async function submitEndOrder() {
    await interfaces.plantOrders
      .endOrder(end.id, { reason: endText })
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        closeEnd()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  return (
    <DialogComponent
      title="¿Desea finalizar este pedido?"
      open={end.open}
      handleclose={closeEnd}
      children={
        end.write && (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Razón de cancelación"
            InputLabelProps={{
              shrink: true,
            }}
            value={endText}
            onChange={(e) => setEndText(e.target.value)}
          />
        )
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={submitEndOrder}
          onClickCancel={closeEnd}
        />
      }
    />
  )
}

export default EndOrder
