import React, { useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const ButtonExportTransit = (props) => {
  const { handleClose, open } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async () => {
    setIsLoading(true)
    await interfaces.transitInterface.exportTransit().then((res) => {
      window.location.assign(res.data)
      createDismissableSnackbar("Tabla de tránsitos descargada!", {
        variant: "success",
      })
      handleClose(true)
    })
    setIsLoading(false)
  }

  return (
    <div>
      <DialogComponent
        tipo="center"
        open={open}
        handleclose={handleClose}
        handleclosedisabled={isLoading}
        title="¿Desea exportar la tabla de tránsito de vehículos?"
        widthLarge="550px"
        disableBackdropClick={isLoading}
        disableEscapeKeyDown={isLoading}
        disableChildHeight
        actions={
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={onSubmit}
            isLoading={isLoading}
            disabledCancel={isLoading}
            disabledSubmit={isLoading}
          />
        }
      />
    </div>
  )
}

export default ButtonExportTransit
