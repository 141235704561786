import React from "react"
import { Grid, makeStyles } from "@material-ui/core"

import VerticalTabsModalRetirement from "./Tabs"
import SideTabs from "./VerticalTabs"

const estilos = makeStyles((theme) => ({
  root: {
    height: "600px",
    overflow: "auto",
  },
}))

const Retirement = (props) => {
  const classes = estilos()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item sm={3} container justify="center" alignItems="center">
          <VerticalTabsModalRetirement value={value} onChange={handleChange} />
        </Grid>
        <Grid item sm={9} xs={12}>
          <SideTabs value={value} />
        </Grid>
      </Grid>
    </div>
  )
}

export default Retirement
