import React, { useContext } from "react"
import { Formik, Field } from "formik"
import { TextField } from "formik-material-ui"

import { Grid } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"

import useStyles from "../styles"
import { FormContext } from "Components/Hooks/ContextForm"
import { EditContext } from "Components/Hooks/ContextEdit"
import { COLORS, FUEL, TRANSSMISION, TRACTION } from "Constants"

const TabsVehicleDataForm = () => {
  const classes = useStyles()
  const [edit] = useContext(EditContext)

  const { formEdit, setFormEdit, setFormApi, formApi } = useContext(FormContext)

  const marcas = formEdit.selectInputs.marcas
  const modelos = formEdit.selectInputs.modelos
  const motores = formEdit.selectInputs.motores
  const versiones = formEdit.selectInputs.versiones

  const handleChangeInfo = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
        vehiculo_info: {
          ...formEdit.vehicle.vehiculo_info,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_info: {
        ...formApi.vehiculo_info,
        [e.target.name]: e.target.value,
      },
    })
  }
  const handleChangeVehicle = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
        vehiculo_info: {
          ...formEdit.vehicle.vehiculo_info,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  })

  return (
    <div className={classes.root}>
      <Formik>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.paddingGrids}
        >
          <Grid item sm={6} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(marcas) => marcas.marca}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={marcas.sort(function (a, b) {
                if (a.marca > b.marca) {
                  return 1
                }
                if (a.marca < b.marca) {
                  return -1
                }
                return 0
              })}
              defaultValue={marcas.find(
                (v) => v.id === formEdit.vehicle.marca_id
              )}
              onChange={(event, value) => {
                handleChangeVehicle({
                  target: { name: "marca_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Marca"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(modelos) => modelos.modelo}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={modelos
                .sort(function (a, b) {
                  if (a.modelo > b.modelo) {
                    return 1
                  }
                  if (a.modelo < b.modelo) {
                    return -1
                  }
                  return 0
                })
                .filter((e) => e.marca_id === formEdit.vehicle.marca_id)}
              defaultValue={modelos.find(
                (v) => v.id === formEdit.vehicle.modelo_id
              )}
              onChange={(event, value) => {
                handleChangeVehicle({
                  target: { name: "modelo_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Modelo"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(version) =>
                `${version.version} (${version.inicio}-${version.fin})`
              }
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={versiones
                .sort(function (a, b) {
                  if (a.version > b.version) {
                    return 1
                  }
                  if (a.version < b.version) {
                    return -1
                  }
                  return 0
                })
                .filter((e) => e.modelo_id === formEdit.vehicle.modelo_id)}
              defaultValue={versiones.find(
                (v) => v.id === formEdit.vehicle.vehiculo_info.version_id
              )}
              onChange={(event, value, reason) => {
                if (reason === "clear")
                  return handleChangeInfo({
                    target: { name: "version_id", value: "" },
                  })
                handleChangeInfo({
                  target: { name: "version_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Versión"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              type="number"
              name="año"
              component={TextField}
              disabled={edit ? false : true}
              fullWidth
              variant="outlined"
              label="Año"
              onChange={handleChangeVehicle}
              value={formEdit.vehicle.año}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              type="number"
              component={TextField}
              disabled={edit ? false : true}
              name="kms"
              fullWidth
              id="kilometros"
              label="Kilómetros"
              variant="outlined"
              onChange={handleChangeVehicle}
              value={formEdit.vehicle.kms}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{min: "0", step: "1" }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled={edit ? false : true}
              fullWidth
              name="modelo_titulo"
              id="pertenece"
              label="Modelo segun título"
              variant="outlined"
              onChange={handleChangeVehicle}
              InputLabelProps={{
                shrink: true,
              }}
              value={formEdit.vehicle.modelo_titulo}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled={edit ? false : true}
              name="tipo"
              fullWidth
              variant="outlined"
              label="Tipo"
              onChange={handleChangeVehicle}
              InputLabelProps={{
                shrink: true,
              }}
              value={formEdit.vehicle.tipo}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(COLORS) => COLORS.color}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={COLORS.sort(function (a, b) {
                if (a.color > b.color) {
                  return 1
                }
                if (a.color < b.color) {
                  return -1
                }
                return 0
              })}
              defaultValue={COLORS.find(
                (v) => v.color === formEdit.vehicle.vehiculo_info.color
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: { name: "color", value: value.color },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Color"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Field
              component={TextField}
              disabled={edit ? false : true}
              fullWidth
              type="number"
              name="puertas"
              variant="outlined"
              label="Puertas"
              onChange={handleChangeInfo}
              value={formEdit.vehicle.vehiculo_info.puertas}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 1)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(FUEL) => FUEL.combustible}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={FUEL}
              defaultValue={FUEL.find(
                (v) =>
                  v.combustible === formEdit.vehicle.vehiculo_info.combustible
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: { name: "combustible", value: value.combustible },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Combustible"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(TRANSSMISION) => TRANSSMISION.TRANSSMISION}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={TRANSSMISION}
              defaultValue={TRANSSMISION.find(
                (v) =>
                  v.TRANSSMISION === formEdit.vehicle.vehiculo_info.transmision
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: {
                    name: "transmision",
                    value: value.TRANSSMISION,
                  },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Transmisión"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(TRACTION) => TRACTION.TRACTION}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={TRACTION}
              defaultValue={TRACTION.find(
                (v) => v.TRACTION === formEdit.vehicle.vehiculo_info.traccion
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: {
                    name: "traccion",
                    value: value.TRACTION,
                  },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Tracción"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(motores) => motores.codigo}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={motores.sort(function (a, b) {
                if (a.motor > b.motor) {
                  return 1
                }
                if (a.motor < b.motor) {
                  return -1
                }
                return 0
              })}
              defaultValue={motores.find(
                (v) => v.id === formEdit.vehicle.vehiculo_info.motor_id
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: { name: "motor_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Motor"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(marcas) => marcas.marca}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={marcas.sort(function (a, b) {
                if (a.marca > b.marca) {
                  return 1
                }
                if (a.marca < b.marca) {
                  return -1
                }
                return 0
              })}
              defaultValue={marcas.find(
                (v) => v.id === formEdit.vehicle.vehiculo_info.motor_marca_id
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: { name: "motor_marca_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Motor marca"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Autocomplete
              filterOptions={filterOptions}
              getOptionLabel={(marcas) => marcas.marca}
              disabled={edit ? false : true}
              noOptionsText={"No se encontraron resultados"}
              options={marcas.sort(function (a, b) {
                if (a.marca > b.marca) {
                  return 1
                }
                if (a.marca < b.marca) {
                  return -1
                }
                return 0
              })}
              defaultValue={marcas.find(
                (v) => v.id === formEdit.vehicle.vehiculo_info.chasis_marca_id
              )}
              onChange={(event, value) => {
                handleChangeInfo({
                  target: { name: "chasis_marca_id", value: value.id },
                })
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Chasis marca"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled={edit ? false : true}
              onChange={handleChangeInfo}
              fullWidth
              name="motor_nro"
              id="certificadoBaja"
              label="Número motor"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={formEdit.vehicle.vehiculo_info.motor_nro}
              style={{ marginBottom: "20px" }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              style={{ marginBottom: "20px" }}
              onChange={handleChangeInfo}
              disabled={edit ? false : true}
              name="chasis_nro"
              fullWidth
              id="formulario"
              label="Número chasis"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 17,
              }}
              value={formEdit.vehicle.vehiculo_info.chasis_nro}
            />
          </Grid>
        </Grid>
      </Formik>
    </div>
  )
}

export default TabsVehicleDataForm
