import * as Yup from "yup"

export const exportClientsValidation = Yup.object().shape({
  empresa: Yup.number().required("Empresa es requerido").nullable(),
  tipo: Yup.string().required("Tipo es requerido"),
  inicio: Yup.date()
    .required("Fecha de inicio es requerido")
    .max(Yup.ref("final"), "Debe ser anterior o igual a fecha final"),
  final: Yup.date()
    .required("Fecha final es requerido")
    .min(Yup.ref("inicio"), "Debe ser posterior o igual a fecha de inicio"),
})
