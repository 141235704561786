import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { Grid } from "@material-ui/core"

export const QuotationFields = (
  companies,
  types,
  vehicles,
  setFieldValue,
  values,
  getVehiclesForPayment
) => {
  return [
    {
      id: 1,
      label: "Proveedor",
      name: "proveedor",
      value: values.proveedor ? values.proveedor : null,
      options: companies,
      optionLabel: `options["nombre"]`,
      optionValue: "nombre",
      onInputChange: (_) => {
        setFieldValue(values.proveedor.toUpperCase())
      },
      sm: 12,
      xs: 12,
      class: "autocomplete",
      sortBy: "nombre",
    },
    {
      id: 2,
      class: "datepicker",
      label: "Fecha de factura",
      format: "dd-MM-yyyy",
      name: "fecha_factura",
      sm: 6,
      xs: 12,
    },
    {
      id: 3,
      class: "datepicker",
      format: "dd-MM-yyyy",
      label: "Fecha de pago",
      name: "fecha_pago",
      sm: 6,
      xs: 12,
    },
    {
      id: 4,
      label: "Sucursal factura",
      name: "suc_factura",
      value: values.suc_factura ? values.suc_factura : null,
      sm: 6,
      xs: 12,
      type: "number",
      class: "textfield",
    },
    {
      id: 5,
      label: "Nº de factura",
      name: "nro_factura",
      value: values.nro_factura ? values.nro_factura : null,

      sm: 6,
      xs: 12,
      type: "number",
      class: "textfield",
    },
    {
      id: 6,
      label: "Monto total",
      name: "monto_total",
      value: values.monto_total ? values.monto_total : null,
      sm: 12,
      xs: 12,
      class: "textfield",
    },
    {
      id: 8,
      label: "Concepto",
      name: "tipo",
      options: types,
      value: values.tipo ? values.tipo : null,
      optionLabel: `options["nombre"]`,
      optionValue: "nombre",
      onChange: (value) => {
        if (value.nombre === "Pago vehículo") {
          getVehiclesForPayment()
        }
        setFieldValue("tipo", value.nombre)
      },
      sm: 12,
      xs: 12,
      class: "autocomplete",
    },
    {
      id: 7,
      label: "Observaciones",
      name: "observaciones",
      value: values.observaciones ? values.observaciones : null,

      sm: 12,
      xs: 12,
      class: "textfield",
      rows: 2,
      multiline: true,
    },
    {
      class: "custom",
      component: (
        <div style={{ paddingTop: "1em" }}>
          <Grid item sm={12} xs={12}>
            {values.tipo === "Pago vehículo" ? (
              <MultiAutocomplete
                disableClearable
                name="vehiculos"
                options={vehicles}
                getOptionLabel={(e) => e.label}
                label={"Seleccione más vehiculos si desee..."}
                onChange={(_, value) => {
                  setFieldValue("vehiculos", value)
                }}
                tagName={"label"}
              />
            ) : null}
          </Grid>
        </div>
      ),
      sm: 12,
      xs: 12,
    },
  ]
}
