import React, { useEffect } from "react"

import Classification from "Components/UI/Templates/Plant/Classification"

const ClassificationPage = () => {
  useEffect(() => {
    document.title = "Clasificación"
  }, [])

  return <Classification />
}

export default ClassificationPage
