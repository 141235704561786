import { axiosService } from "../../../index"
import { plantEndpoints } from "../../../sources/plant/Despatch"

const newDespatch = (body) => {
  return axiosService.post(plantEndpoints.NEW, body)
}
const getData = (id) => {
  return axiosService.get(plantEndpoints.GET_DATA + id)
}
const list = (options) => {
  return axiosService.get(plantEndpoints.LIST + options)
}
const addDetail = (id, body) => {
  return axiosService.post(plantEndpoints.ADD_DETAIL + id, body)
}
const deleteDetail = (id) => {
  return axiosService.del(plantEndpoints.DELETE_DETAIL + id)
}
const confirmAndPrint = (id) => {
  return axiosService.getPDF(plantEndpoints.CONFIRM_PRINT + id)
}
const update = (id, body) => {
  return axiosService.put(plantEndpoints.UPDATE + "/" + id, body)
}

const getClient = (search) => {
  return axiosService.get(plantEndpoints.GET_CLIENT + `?search=${search}`)
}

const deleteRemit = (id) => {
  return axiosService.del(plantEndpoints.CONFIRM_PRINT + id)
}

export const plantDespatch = {
  newDespatch,
  getData,
  list,
  addDetail,
  update,
  deleteDetail,
  confirmAndPrint,
  getClient,
  deleteRemit,
}
