import React, { useRef } from "react"

import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { validateDominio } from "Components/Validation"

export default function DialogSearch(props) {
  const { handleClose, open, handleSubmit } = props
  const refSubmit = useRef(null)
  function handleRef() {
    refSubmit.current.click()
  }

  const INPUTS = (values) => {
    return [
      {
        id: 1,
        name: "dominio",
        class: "textfield",
        sm: 12,
        xs: 12,
        label: "Dominio, Nº de V o Código de pieza",
        autoFocus: true,
        value: values.dominio,
      },
    ]
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      title="Buscador rápido"
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      children={
        <Formik
          validationSchema={validateDominio}
          initialValues={{ dominio: "" }}
          onSubmit={handleSubmit}
        >
          {(values) => (
            <Form>
              <InputsWithValidate fields={INPUTS(values)} spacing={2} />
              <button
                ref={refSubmit}
                type="submit"
                style={{ display: "none" }}
              />
            </Form>
          )}
        </Formik>
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={handleClose}
          onClickSubmit={handleRef}
        />
      }
    />
  )
}
