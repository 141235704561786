import React, { useState, useContext } from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import {
  FORM_HOME,
  DATE,
} from "Components/UI/Organisms/Vehicles/ActCompaction/Edit/Home/Fields"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"
import { EditContext } from "Components/Hooks/ContextEdit"
import CustomButton from "Components/UI/Atoms/CustomButton"
import useStyles from "../../../Quotations/Edit/Fields/Home/styles"

const HomeContent = (props) => {
  const {
    data,
    setData,
    printQuote,
    confirmCompaction,
    infoFilters,
    permissions,
  } = props

  const [edit] = useContext(EditContext)

  const [tab, setTab] = useState(0)

  const handleChange = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    })
  }

  const classes = useStyles()

  const disabledEditButtons = () => {
    const detailsNotEmpty = data.details && data.details.length > 0
    return permissions.allowConfirm && detailsNotEmpty
  }

  function renderTabs(index) {
    switch (index) {
      case 0:
        return (
          <>
            <InputsWithoutValidate
              fields={FORM_HOME(data.data, handleChange, edit, infoFilters)}
              spacing={2}
            />

            <div className={classes.buttons}>
              <CustomButton
                buttonEnd
                style={{
                  marginTop: "10px",
                  marginLeft: "1.5rem",
                  backgroundColor: "#818181",
                }}
                text="Imprimir"
                disabled={false}
                onClick={(e) => printQuote(e, data.act.id)}
              />
              {data.act.estado === 0 ? (
                <CustomButton
                  buttonEnd
                  style={{
                    marginTop: "10px",
                    marginLeft: "1.5rem",
                    backgroundColor: disabledEditButtons()
                      ? "#3e984c"
                      : "#d2d3d6",
                  }}
                  text="Confirmar"
                  disabled={!disabledEditButtons()}
                  onClick={(e) => confirmCompaction(e, data.act.id)}
                />
              ) : (
                ""
              )}
            </div>
          </>
        )
      case 1:
        return (
          <InputsWithoutValidate
            fields={DATE(data.data, handleChange, edit)}
            spacing={2}
          />
        )
      default:
        break
    }
  }

  const verticalTabs = [
    {
      id: 0,
      title: "Información general",
    },
    {
      id: 1,
      title: "Datos de facturación",
    },
  ]

  return (
    <div>
      <ContentWithSubTabs
        listVerticalTabs={verticalTabs}
        value={tab}
        setValue={setTab}
        children={<div style={{ margin: "20px" }}>{renderTabs(tab)}</div>}
      />
    </div>
  )
}

export default HomeContent
