import React, { useState } from "react"

import { TextField } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import { useSnackbar } from "notistack"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
const ButtonNew = (props) => {
  const { handleClose, open } = props
  const { enqueueSnackbar } = useSnackbar()
  const [change, setChange] = useState()

  function handleChange(e) {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    })
  }
  const [loading, setLoading] = useState(false)

  async function handleSubmit() {
    setLoading(true)
    await interfaces.interfaceEngine
      .newEngine(change)
      .then((res) => {
        handleClose()
        enqueueSnackbar(res.data, { variant: "success" })
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, { variant: "error" })
      )
    setLoading(false)
  }

  return (
    <>
      <DialogComponent
        title="Nuevo motor"
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        handleclosedisabled={loading}
        disableChildHeight
        children={
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault()
              }
            }}
          >
            <TextField
              fullWidth
              name="codigo"
              label="Código de motor"
              variant="outlined"
              onChange={handleChange}
            />
          </form>
        }
        handleclose={handleClose}
        actions={
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        }
      />
    </>
  )
}

export default ButtonNew
