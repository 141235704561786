import React from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"

const HomeForm = (props) => {
  const { data, handleChange } = props

  const FORM_HOME = () => {
    return [
      {
        class: "textfield",
        label: "Nombre",
        name: "nombre",
        sm: 6,
        xs: 12,
        value: data.data.nombre,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: data.data.activado === 0,
      },
      {
        class: "textfield",
        label: "Apellido",
        name: "apellido",
        sm: 6,
        xs: 12,
        value: data.data.apellido,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: data.data.activado === 0,
      },
      {
        class: "textfield",
        label: "Nombre de usuario",
        name: "username",
        sm: 12,
        xs: 12,
        value: data.data.username,
        disabled: true,
      },
      {
        class: "textfield",
        type: "number",
        label: "Legajo",
        name: "legajo",
        sm: 12,
        xs: 12,
        value: data.data.legajo,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: data.data.activado === 0,
      },
      {
        class: "autocomplete",
        label: "Ubicación",
        name: "ubicacion_id",
        value: data.data.ubicacion_id,
        options: data.fields.ubicaciones,
        optionLabel: `options["nombre"]`,
        optionValue: "id",
        onChange: (value) => handleChange("ubicacion_id", value.id),
        sm: 6,
        xs: 12,
        disabled: data.data.activado === 0,
      },
      {
        class: "autocomplete",
        label: "Punto de venta",
        name: "punto_venta_id",
        value:
          data.data.punto_venta_id === "" ? null : data.data.punto_venta_id,
        options: data.fields.punto_venta,
        optionLabel: `options["nombre"]`,
        optionValue: "id",
        onChange: (value) => handleChange("punto_venta_id", value.id),
        sm: 6,
        xs: 12,
        disabled: data.data.activado === 0,
      },
      {
        class: "autocomplete",
        label: "Área",
        name: "area",
        value: data.data.area,
        options: data.fields.areas,
        optionLabel: `options["label"]`,
        optionValue: "value",
        onChange: (value) => handleChange("area", value.value),
        sm: 6,
        xs: 12,
        disabled: data.data.activado === 0,
      },
      {
        class: "autocomplete",
        label: "Rol",
        name: "puesto",
        value: data.data.puesto,
        defaultValue: { label: data.data.puesto },
        options: data.fields.roles,
        optionLabel: `options["label"]`,
        optionValue: "value",
        onChange: (value) => handleChange("puesto", value.value),
        onInputChange: (e) => handleChange("puesto", e),
        freeSolo: "true",
        sm: 6,
        xs: 12,
        disabled: data.data.activado === 0,
      },
      {
        label: "Correo electrónico",
        name: "email",
        sm: 12,
        xs: 12,
        class: "textfield",
        value: data.data.email,
        onChange: (e) => handleChange(e.target.name, e.target.value),
        disabled: data.data.activado === 0,
      },
    ]
  }

  return <InputsWithoutValidate fields={FORM_HOME()} spacing={2} />
}

export default HomeForm
