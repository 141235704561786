export function columnTheme() {
  return {
    "&:nth-child(1)": {
      paddingLeft: "25px",
      width: "220px",
    },
    "&:nth-child(2)": {
      width: "220px",
    },
    "&:nth-child(3)": {
      width: "200px",
    },
    "&:nth-child(4)": {
      width: "210px",
    },
    "&:nth-child(5)": {
      width: "250px",
    },
    "&:nth-child(6)": {
      width: "100px",
    },
    "&:nth-child(7)": {
      minWidth: "250px",
    },
    "&:nth-child(8)": {
      minWidth: "0px",
    },
  }
}
