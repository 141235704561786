import React from "react"
import { Grid } from "@material-ui/core"

// import UpdateFiles from "Components/UI/Organisms/Home/Widgets/UpdateFiles"
import PaperComponent from "Components/UI/Atoms/PaperCompoent"

import DataControl from "Components/UI/Organisms/Sales/Prices/Components/DataControl"
import UpdateBoard from "Components/UI/Organisms/Sales/Prices/Components/UpdateBoard"
import PricesPerItem from "Components/UI/Organisms/Sales/Prices/Components/PricesPerItem"
import { useWindowSize } from "react-use"

const OrganismPrice = (props) => {
  const {
    dataAutocomplete,
    setDataAutocomplete,
    idEdit,
    setIdEdit,
    render,
    setRender,
  } = props
  // eslint-disable-next-line
  const { width, height } = useWindowSize()
  return (
    <Grid container>
      <Grid item md={8} sm={12} xs={12}>
        <PaperComponent
          children={
            <UpdateBoard
              dataAutocomplete={dataAutocomplete}
              setDataAutocomplete={setDataAutocomplete}
              idEdit={idEdit}
              setIdEdit={setIdEdit}
              render={render}
              setRender={setRender}
            />
          }
          style={{
            padding: "30px",
            margin: "10px 10px 3px 0px",
            height: height - 100,
          }}
        />
      </Grid>

      <Grid item md={4} sm={12} xs={12}>
        <PaperComponent
          children={<DataControl />}
          style={{
            padding: "30px",
            margin: "10px 10px 3px 10px",
          }}
        />
        <PaperComponent
          children={<PricesPerItem />}
          style={{
            padding: "30px",
            margin: "10px 10px 3px 10px",
          }}
        />
        {/* <Grid item sm={12} xs={12}>
          <PaperComponent
            children={<Reference />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <PaperComponent
            children={<Quality />}
            style={{
              padding: "30px",
              margin: "10px 10px 3px 10px",
            }}
          />
        </Grid> */}
      </Grid>
    </Grid>
  )
}

export default OrganismPrice
