import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import NavbarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import WarehouesesTable from "Components/UI/Organisms/Plant/Storages/Table"
import NewStorage from "Components/UI/Organisms/Plant/Storages/New"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import { oemColorButton } from "Helpers"

const WarehousesTemplate = () => {
  const [render, setRender] = useState(false)

  const { state, setOpen, setClose } = SetState()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const BUTTONS = [
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: setOpen,
    },
  ]

  return (
    <>
      <Template
        navbar={
          <NavbarGlobal title="Planta" subTitle="Almacenes" buttons={BUTTONS} />
        }
        table={<WarehouesesTable render={render} setRender={setRender} />}
      />
      {state.suspense && (
        <NewStorage
          open={state.open}
          handleclose={setClose}
          render={render}
          setRender={setRender}
        />
      )}
    </>
  )
}
export default WarehousesTemplate
