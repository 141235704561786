import React, { useState, useContext } from "react"

import { Card, ButtonBase, Grid, Typography } from "@material-ui/core"
import { styles } from "./styles"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import PictureCardWithZoom from "Components/UI/Atoms/PictureCard/PictureCardWithZoom"

import { interfaces } from "service/interfaces"
import { FormContext } from "Components/Hooks/ContextForm"
import { EditContext } from "Components/Hooks/ContextEdit"
import DialogUpload from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/AdditionalPhotos/DialogUpload"
import DeletePhoto from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/AdditionalPhotos/DialogDelete"

const AdditionalPhotos = () => {
  const [change, setChange] = useState([])
  const [dataActions, setDataActions] = useState({})
  const [loading, setLoading] = useState(false)

  const { formEdit } = useContext(FormContext)
  const [edit] = useContext(EditContext)

  const createDismissableSnackbar = useCustomSnackbar()
  const classes = styles()

  function handleOpenDelete(data, index, openRemove) {
    setDataActions({
      data: data,
      index: index,
      openRemove: openRemove,
      id: formEdit.vehicle.id,
    })
  }
  function handleOpenUpload() {
    setDataActions({
      openUpload: true,
      id: formEdit.vehicle.id,
    })
  }

  function handleChange(e) {
    e.preventDefault()
    let prevData = [...change]
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    setChange(prevData)
  }
  function handleCloseActions() {
    setChange([])
    setDataActions({
      openUpload: false,
      openRemove: false,
      id: null,
    })
  }

  function handleDeleteContext(index) {
    let delet = index - 1
    formEdit.secondPhotos.splice(delet, 1)
  }

  function handleAdd(arr) {
    for (let i = 0; i < arr.length; i++) {
      let imgUp = URL.createObjectURL(arr[i])
      formEdit.secondPhotos.push(imgUp)
    }
  }

  async function handleSubmitUpload(value) {
    setLoading(true)
    let index = formEdit.secondPhotos.length + 1
    await interfaces.vehicleInterface
      .uploadAdditionalPhotos(value, index, formEdit.vehicle.dominio)
      .then((res) => {
        createDismissableSnackbar(`${res.data.data}`, {
          variant: "success",
        })
        handleAdd(value)
        handleCloseActions()
      })
      .catch(() => {
        createDismissableSnackbar("Error", {
          variant: "error",
        })
        handleCloseActions()
      })
    setLoading(false)
  }
  async function handleSubmitDelete(arr, index) {
    setLoading(true)
    if (arr !== "" && arr !== undefined) {
      await interfaces.vehicleInterface
        .deleteFile("iniciales", index, formEdit.vehicle.dominio)
        .then((res) => {
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          handleDeleteContext(index)
          handleCloseActions()
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
          handleCloseActions()
        })
    } else {
      createDismissableSnackbar("Esta foto no existe! ", {
        variant: "error",
      })
      handleCloseActions()
    }
    setLoading(false)
  }

  const swipes = formEdit.secondPhotos.map((item, index) => ({
    id: index,
    img: item,
  }))

  return (
    <div className={classes.root}>
      <Grid container alignContent="center" justify="center" spacing={2}>
        {formEdit.secondPhotos.map((item, index) => (
          <Grid item xs={12} xl={4} sm={6} md={4} className={classes.root2}>
            <PictureCardWithZoom
              img={item}
              edit={edit}
              txt={`Adicional ${index + 1}`}
              title={`Adicional ${index + 1}`}
              index={index + 1}
              swipes={swipes}
              handleDelete={() => handleOpenDelete(item, index + 1, true)}
            />
          </Grid>
        ))}
        {edit ? (
          <Grid item xs={12} xl={4} sm={6} md={4}>
            <ButtonBase component="a" onClick={() => handleOpenUpload()}>
              <Card className={classes.cardSelectImg}>
                <Typography>Seleccione una imagen..</Typography>
              </Card>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>
      <DeletePhoto
        data={dataActions}
        open={dataActions.openRemove}
        handleClose={handleCloseActions}
        onSubmit={handleSubmitDelete}
        loading={loading}
      />
      <DialogUpload
        handleChange={handleChange}
        change={change}
        data={dataActions}
        onSubmit={handleSubmitUpload}
        handleClose={handleCloseActions}
        open={dataActions.openUpload}
        loading={loading}
      />
    </div>
  )
}

export default AdditionalPhotos
