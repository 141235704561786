import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import { Typography } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import AddIcon from "@material-ui/icons/Add"
import UpdateIcon from "@material-ui/icons/Update"

import NavBarGlobal from "Components/UI/Molecules/NavBar"
import TemplateGlobal from "../../Common"
import DialogNew from "Components/UI/Organisms/Plant/Classification/DialogNew"
import Table from "Components/UI/Organisms/Plant/Classification/Table"
import DialogSearch from "Components/UI/Organisms/Plant/Classification/DialogSearch"
import { getUbicacion } from "service/helpers/localstorage"
import DialogUpdate from "Components/UI/Organisms/Plant/Classification/DialogUpdate"
import { oemColorButton } from "Helpers"
import SetState from "Components/Hooks/SetState"

const Classification = () => {
  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()
  const {
    state: openSearch,
    setOpen: setOpenSearch,
    setClose: setCloseSearch,
  } = SetState()
  const {
    state: openUpdate,
    setOpen: setOpenUpdate,
    setClose: setCloseUpdate,
  } = SetState()
  const [render, setRender] = useState(false)
  const [search, setSearch] = useState(null)

  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const BUTTONS = [
    {
      menuIcon: <UpdateIcon fontSize="small" />,
      menuTitle: "Actualizar",
      buttonContent: "Actualizar",
      onClick: setOpenUpdate,
    },
    {
      menuIcon: <SearchIcon fontSize="small" />,
      menuTitle: "Buscar",
      buttonContent: <SearchIcon />,
      onClick: setOpenSearch,
    },
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: setOpenNew,
    },
  ]

  const handleSubmit = (values) => {
    setSearch(values.dominio.toUpperCase())
    setRender(!render)
    setCloseSearch()
  }

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            buttons={BUTTONS}
            title="Planta"
            subTitle="Clasificación"
          />
        }
        table={
          search ? (
            <Table
              render={render}
              setRender={setRender}
              search={search}
              setSearch={setSearch}
            />
          ) : (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <Typography style={{ width: "50%" }} variant="h5">
                Para crear una nueva pieza presione en el botón “Nuevo” o
                presione sobre la lupa para buscar piezas de un vehículo.
              </Typography>
            </div>
          )
        }
      />
      <DialogNew
        open={openNew.open}
        render={render}
        setRender={setRender}
        handleclose={setCloseNew}
        setSearch={setSearch}
      />
      <DialogSearch
        open={openSearch.open}
        handleClose={setCloseSearch}
        handleSubmit={handleSubmit}
      />
      <DialogUpdate
        open={openUpdate.open}
        handleClose={setCloseUpdate}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
export default Classification
