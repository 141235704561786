import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  outline: {
    borderColor: "black !important",
  },
  cssLabel: {
    color: "black",
    "&.Mui-focused": {
      color: "black",
    },
  },
  inputStyle: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default useStyles
