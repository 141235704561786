import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflowX: "hide",
  },
  table: {
    minWidth: 350,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
  image: {
    padding: 0,
  },
  iconButton: {
    padding: 0,
  },
}))

export default useStyles
