const LIST = "/plant/stationzero/list/"
const RESERVE_STOCK = "/sales/stock/reserve"
const DELETE_RESERVE = "/sales/stock/reserve"
const BEFORE_EXPORT = "/sales/stock/before"
const EXPORT = "/sales/stock/export"
const SEARCH_VEHICLE = "/plant/stationzero/search"
const UPLOAD_PHOTO = "/plant/stationzero/upload/"
const CREATE_STOCK = "/plant/stationzero/create/"

export const plantStationEndpoints = {
  LIST,
  RESERVE_STOCK,
  DELETE_RESERVE,
  BEFORE_EXPORT,
  EXPORT,
  SEARCH_VEHICLE,
  UPLOAD_PHOTO,
  CREATE_STOCK,
}
