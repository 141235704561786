import React, { forwardRef } from "react"
import { Formik, Field } from "formik"
import { TextField } from "formik-material-ui"

import { Grid } from "@material-ui/core"

import { formNewAdditionalInformation } from "Components/Validation/Vehicles/SearchVehicles"
import useStyles from "../../styles"
import { CITIES, PROVINCIAS } from "Constants"
import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const FormNew = forwardRef((props, ref) => {
  const classes = useStyles()

  const { onSubmit } = props
  return (
    <>
      <Formik
        initialValues={{
          destinatario: "",
          nombre_asegurado: "",
          provincia_id: "",
          localidad_id: "",
          direccion: "",
          medio: "",
          guia: "",
        }}
        validationSchema={formNewAdditionalInformation}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                fullWidth
                label="Destinatario"
                name="destinatario"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                fullWidth
                name="nombre_asegurado"
                label="Nombre del asegurado"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikAutocomplete
                name="provincia_id"
                options={PROVINCIAS}
                getOptionLabel={(PROVINCIAS) => PROVINCIAS.provincia}
                label={"Provincia"}
                onChange={(_, value) => {
                  setFieldValue("provincia_id", value.id)
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikAutocomplete
                name="localidad_id"
                sortBy={"localidad"}
                options={CITIES.filter(
                  (e) => e.provincia_id === values.provincia_id
                )}
                getOptionLabel={(localidades) =>
                  `${localidades.localidad} (${localidades.cp})`
                }
                label={"Localidad"}
                onChange={(_, value) => {
                  setFieldValue("localidad_id", value.id)
                }}
              />
            </Grid>

            <Grid item sm={12} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                name="direccion"
                fullWidth
                label="Domicilio"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 40,
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                fullWidth
                label="Descripcion"
                variant="outlined"
                name="descripcion"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                fullWidth
                label="Medio"
                variant="outlined"
                name="medio"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                className={classes.inputs}
                component={TextField}
                fullWidth
                label="Guia"
                variant="outlined"
                name="guia"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        )}
      </Formik>
    </>
  )
})

export default FormNew
