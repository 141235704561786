import { React, forwardRef } from "react"
import { Formik } from "formik"

import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { editVersion } from "Components/Validation/Settings/Various/BrandModelVersion"

const Form = forwardRef((props, ref) => {
  const { onSubmit, data, types } = props
  const info = data()
  const OBJETO = (values, setFieldValue) => {
    return [
      {
        class: "textfield",
        name: "version",
        label: "Versión",
        value: values.version,
        sm: 12,
        xs: 12,
      },
      {
        class: "datepicker",
        format: "yyyy",
        views: ["year"],
        name: "inicio",
        label: "Inicio",
        value: values.inicio,
        sm: 12,
        xs: 12,
      },
      {
        class: "datepicker",
        format: "yyyy",
        views: ["year"],
        value: values.fin,
        name: "fin",
        label: "fin",
        sm: 12,
        xs: 12,
      },
      {
        class: "autocomplete",
        name: "vehiculo_tipo_id",
        label: "Tipo de vehículo",
        defaultValue: types.find((v) => v.id === info.vehiculo_tipo_id),
        options: types,
        optionLabel: `options["nombre"]`,
        optionValue: "id",
        onChange: (value) => setFieldValue("vehiculo_tipo_id", value.id),
        sm: 12,
        xs: 12,
      },
    ]
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          version: info.version,
          inicio: `10/31/${info.inicio}`,
          fin: `10/31/${info.fin}`,
          vehiculo_tipo_id: info.vehiculo_tipo_id,
        }}
        validationSchema={editVersion}
        onSubmit={onSubmit}
      >
        {({ submitForm, values, setFieldValue, isSubmitting }) => (
          <>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form
