import { React, useRef, useState } from "react"

import Form from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Edit = (props) => {
  const { changeRender, idLocalities, open, handleclose, data, setDataLocalities } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [loading, setLoading] = useState(false)
  
  const onSubmit = (values) => {
    const value = {
      localidad: values.localidad.toUpperCase(),
      cp: values.cp
    }
    setLoading(true)
    interfaces.configInterface
      .updateLocalities(idLocalities, value)
      .then((res) => {
        setDataLocalities(prevData => {
          return prevData.map(locality => {
            if (locality.id === idLocalities) {
              return {
                ...locality,
                localidad: value.localidad,
                cp: value.cp
              }
            }
            return locality
          })
        })
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
    setLoading(false)
  }
  
  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      widthLarge="500px"
      title="Editar localidad"
      children={
        <Form
          ref={formSubmitRef}
          onSubmit={onSubmit}
          data={data}
        />
      }
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleclose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default Edit