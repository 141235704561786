/* eslint-disable react/jsx-pascal-case */
import React, { useState, useRef } from "react"

import { Step, Stepper, StepLabel, Typography } from "@material-ui/core"

import ReactImageGallery from "react-image-gallery"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputButton from "Components/UI/Atoms/InputButton"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SimpleUploadImg from "Components/UI/Atoms/SimpleUploadImage"
import ModalSimple from "Components/UI/Molecules/Modals/Simple"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { validateDominio } from "Components/Validation"
import { STEP_1, STEP_2, STEP_2_OEM, STEP_2_ECO, STEP_4 } from "./ObjectTabs"
import Tab_3 from "./Tab_3"
import { interfaces } from "service/interfaces"
import { getUbicacion } from "service/helpers/localstorage"
import { titles } from "Helpers"
import { printPDF } from "Helpers"

const DialogNew = (props) => {
  const { open, handleclose, render, setRender, setSearch } = props
  const ref = useRef()

  const location = getUbicacion().includes("ECO") ? 1 : 2

  const INITIAL_VALUES = {
    vehicle: {},
    producto: "",
    empresa: null,
    producto_id: null,
    familia_id: null,
    calidad: null,
    calidadNombre: "",
    oblea: null,
    products: { eco_products: [], oem_products: [] },
    almacen_id: null,
    data_code: [],
    id: null,
    images: [],
    comentarios: "",
    almacen_empresa: location,
  }

  const [img, setImg] = useState([])
  const [photos, setPhotos] = useState([])
  const [fullImg, setFullImg] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState(INITIAL_VALUES)
  const [vehicle, setVehicle] = useState(null)
  const [openModal, setOpenModal] = useState(null)

  const createDismissableSnackbar = useCustomSnackbar()

  const STEPS = [
    {
      id: 1,
      title: "Vehículo",
    },
    {
      id: 2,
      title: "Producto",
      subTitle: "Seleccione la empresa y producto a clasificar",
    },
    {
      id: 3,
      title: "Calidad",
      subTitle: "Según el criterio descripto, seleccione la calidad.",
    },
    {
      id: 4,
      title: "",
      subTitle: "Revise que toda la información seleccionada esta correcta",
      display: true,
    },
    {
      id: 5,
      title: "",
      subTitle: "",
      display: true,
    },
  ]

  function handleCloseDialog() {
    handleclose()
    setData(INITIAL_VALUES)
    setTab(0)
    setPhotos([])
  }

  async function _submitInitialData(values) {
    setLoading(true)
    setVehicle(values.dominio)
    await interfaces.plantInterfaceClassification
      .getNewProduct(values.dominio)
      .then((res) => {
        setPhotos(res.data.vehicle.foto)
        setData({
          ...data,
          vehicle: res.data.vehicle,
          products: res.data.products,
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setData(INITIAL_VALUES)
      })
    setLoading(false)
  }

  function _handleSubmit() {
    if (tab === 3) {
      _submitForm()
      setTab(tab + 1)
    } else if (tab === 4) {
      setTab(1)
    } else {
      setTab(tab + 1)
    }
  }

  function _handlePrev() {
    setTab(tab - 1)
  }

  function handleChange(object) {
    if (object["empresa"]) {
      setData({
        ...data,
        ...object,
        producto_id: null,
        oblea: null,
        familia_id: null,
      })
    } else {
      setData({
        ...data,
        ...object,
      })
    }
  }

  function handleTextSubmit() {
    let text
    if (tab === 3) {
      text = "Guardar"
    } else if (tab === 4) {
      text = "Seguir clasificando"
    } else {
      text = "Siguiente"
    }
    return text
  }

  async function onSubmitCode() {
    await interfaces.plantStorage
      .searchStorageCode(
        data.almacen_id,
        typeof data.almacen_empresa === "object"
          ? data.almacen_empresa.id
          : data.almacen_empresa
      )
      .then((res) => {
        setData({
          ...data,
          almacen_id: res.data.id,
          almacen_empresa: res.data.empresa,
          data_code: `A${res.data.almacen} N${res.data.nivel} ${
            res.data.bin
              ? `BIN ${res.data.bin}`
              : `${res.data.estanteria}-${res.data.columna}-${res.data.fila}`
          }`,
        })
      })
      .catch((error) => {
        setData({ ...data, data_code: "", almacen_id: "" })
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }
  function _renderStep(step) {
    switch (step) {
      case 0:
        return (
          <InputsWithoutValidate
            fields={STEP_1(data, setOpenModal, photos)}
            spacing={2}
          />
        )
      case 1:
        return (
          <div style={{ height: "475px" }}>
            <InputsWithoutValidate
              fields={STEP_2(data, handleChange)}
              spacing={2}
            />
            <br />
            {data.empresa === 1 && (
              <InputsWithoutValidate
                fields={STEP_2_ECO(data.products, handleChange, data)}
                spacing={2}
              />
            )}
            {data.empresa === 2 && (
              <InputsWithoutValidate
                fields={STEP_2_OEM(data.products, handleChange, data)}
                spacing={2}
              />
            )}
          </div>
        )
      case 2:
        return (
          <div style={{ height: "471px" }}>
            <Tab_3 handleChange={handleChange} data={data} />
          </div>
        )
      case 3:
        return (
          <div style={{ height: "480px" }}>
            <InputsWithoutValidate
              fields={STEP_4(data, data.vehicle, onSubmitCode, handleChange)}
              spacing={2}
            />
          </div>
        )
      case 4:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            <>
              <Typography>La pieza</Typography>
              <Typography variant="h5">{data.producto}</Typography>
              <Typography>de</Typography>
              <Typography variant="h5">{`${data.vehicle.marca} ${data.vehicle.modelo} ${data.vehicle.año}`}</Typography>
              <Typography>con calidad</Typography>
              <Typography variant="h5">{`${data.calidadNombre.toUpperCase()}`}</Typography>
              <Typography>ha sido creado con éxito!</Typography>
            </>
          </div>
        )
      default:
        break
    }
  }

  async function printSticker(id) {
    await interfaces.plantInterfaceClassification
      .stockSticker(id)
      .then((res) => {
        printPDF(res).print()
      })
  }

  async function _submitForm() {
    setIsLoading(true)
    let valuesPost = {
      vehiculo_id: data.vehicle ? data.vehicle.vehiculo_id : "",
      producto_id: data.producto_id,
      empresa: data.empresa,
      calidad: data.calidad,
      almacen_id: data.almacen_id,
      comentarios: data.comentarios,
    }
    await interfaces.plantInterfaceClassification
      .postNewProduct(valuesPost)
      .then(async (res) => {
        await printSticker(res.data.id)
        createDismissableSnackbar(`${res.data.msg}`, { variant: "success" })
        setData({
          ...data,
          producto_id: null,
          id: res.data.id,
          empresa: null,
          familia_id: null,
          oblea: null,
          calidad: null,
          products: res.data.products,
          almacen_id: null,
          data_code: [],
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
    if (render) {
      setSearch(vehicle)
      setRender(!render)
    }
  }

  function enableNextStep() {
    switch (tab) {
      case 0:
        if (Object.keys(data.vehicle).length === 0) {
          return true
        } else {
          return false
        }
      case 1:
        if (data.producto_id === null) {
          return true
        } else {
          return false
        }
      case 2:
        if (data.calidad === null) {
          return true
        } else {
          return false
        }
      case 4:
        return isLoading

      default:
        return
    }
  }

  function handleChangeUpload(e) {
    e.preventDefault()
    let prevData = []
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    if (prevData !== {}) {
      handleUploadImg(prevData)
    }
  }

  async function handleUploadImg(prevData) {
    setIsLoading(true)
    await interfaces.plantInterfaceClassification
      .uploadPhoto(
        data.id,
        data.empresa === 1 ? "ECO" : "OEM",
        prevData,
        prevData.length + 1
      )
      .then((res) => {
        createDismissableSnackbar(res.data.data.msg, { variant: "success" })
        setImg(res.data.data.url)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  function closeModalSimple() {
    setOpenModal(false)
  }

  return (
    <>
      <DialogComponent
        open={open}
        widthLarge="500px"
        handleclosedisabled={isLoading}
        handleclose={handleCloseDialog}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        title="Nueva pieza"
        maxHeight={tab < 4 ? true : false}
        children={
          <>
            {tab < 4 ? (
              <Stepper
                alternativeLabel
                activeStep={tab}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                  paddingBottom: 20,
                }}
              >
                {STEPS.filter((e) => e.id < 4).map(({ title, display }) => (
                  <Step
                    style={{ display: display ? "none" : null, color: "red" }}
                    key={title}
                  >
                    <StepLabel>{title}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            ) : null}
            <Typography style={{ marginBottom: "15px" }}>
              {STEPS[tab].subTitle}
            </Typography>
            {tab === 0 ? (
              <InputButton
                placeholder={"Dominio / Nro. de V"}
                name="dominio"
                onSubmit={_submitInitialData}
                loading={loading}
                validationSchema={validateDominio}
              />
            ) : null}

            {_renderStep(tab)}
          </>
        }
        actions={
          <>
            <CancelConfirmButtons
              onClickCancel={
                tab >= 1 && tab < 4 ? _handlePrev : handleCloseDialog
              }
              replaceCancel={
                tab === 4 ? (
                  <SimpleUploadImg
                    handleChange={handleChangeUpload}
                    change={img}
                    text="Subir fotografías"
                    loading={isLoading}
                    color="primary"
                    styleText={{ textTransform: "initial" }}
                  />
                ) : null
              }
              cancelText={tab >= 1 && tab < 4 ? "Anterior" : "Cancelar"}
              submitText={handleTextSubmit()}
              isLoading={isLoading}
              disabledCancel={isLoading}
              onClickSubmit={() => _handleSubmit()}
              disabledSubmit={enableNextStep()}
              cancelStyle={tab === 4 ? { style: { display: "none" } } : null}
            />
          </>
        }
      />

      <ModalSimple
        open={photos.length > 0 ? openModal : null}
        onClose={closeModalSimple}
        disableEscapeKeyDown={fullImg.fullScreen ? true : false}
        div={
          <ReactImageGallery
            ref={ref}
            items={titles(photos)}
            onScreenChange={(e) => setFullImg({ fullScreen: e })}
            useBrowserFullscreen={false}
            onClick={(e) => ref.current.fullScreen()}
            showBullets={true}
            showPlayButton={false}
            lazyLoad={true}
            showThumbnails={false}
            onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
          />
        }
      />
    </>
  )
}

export default DialogNew
