import React from "react"
import ReactDOM from "react-dom"
import { Router, useLocation } from "react-router-dom"
import { SnackbarProvider } from "notistack"

import { CssBaseline, Grow } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ThemeProvider } from "@material-ui/core"
import useGlobalStyles from "./styles"

import App from "Components/App"
import { ThemeEco, ThemeOem } from "Components/Styles"
import { History } from "Helpers"
import OpenNavbarProvider from "Components/Hooks/ContextSideBar"

import { getUbicacion } from "service/helpers/localstorage"

const root = document.getElementById("root")

function getFavicon() {
  return document.getElementById("favicon")
}
function getManifest() {
  return document.getElementById("manifest")
}

function MyThemeProvider({ children }) {
  useGlobalStyles()
  const { pathname } = useLocation()
  const location = getUbicacion() || ""

  const condition =
    (pathname && pathname.includes("oem")) || location.includes("OEM")

  if (condition) {
    const favicon = getFavicon()
    favicon.href = process.env.PUBLIC_URL + "/Images/oemicon.png"
    const manifest = getManifest()
    manifest.href = process.env.PUBLIC_URL + "/manifest2.json"
  }

  const theme = () => {
    if (condition) return ThemeOem()
    return ThemeEco()
  }

  return <ThemeProvider theme={theme()}>{children}</ThemeProvider>
}

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    TransitionComponent={Grow}
    action={(key) => <CloseIcon id={key} />}
  >
    <CssBaseline />
    <OpenNavbarProvider>
      <Router history={History}>
        <MyThemeProvider>
          <App />
        </MyThemeProvider>
      </Router>
    </OpenNavbarProvider>
  </SnackbarProvider>,
  root
)
