import React, { useState, useRef, useCallback } from "react";
import ReactImageGallery from "react-image-gallery";
import { toPng } from "html-to-image";
import "react-image-gallery/styles/css/image-gallery.css";
import { useCopyToClipboard } from "react-use";

import {
  Grid,
  Typography,
  Hidden,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import GetAppIcon from "@material-ui/icons/GetApp";

import useCustomSnackbar from "Components/UI/Atoms/SnackBar";
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate";
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons";
import CustomButton from "Components/UI/Atoms/CustomButton";
import Reserve from "Components/UI/Organisms/Sales/Stock/DialogStock/Reserve";
import DialogMoreInfo from "Components/UI/Organisms/Plant/Classification/DialogMoreInfo";
import EditContextProvider from "Components/Hooks/ContextEdit";
import setState from "Components/Hooks/SetState";
import ChecklistDialog from "Components/UI/Organisms/Plant/Checklist/ChecklistDialog";
import NewOrder from "Components/UI/Organisms/Plant/Grill/NewOrder";
import { interfaces } from "service/interfaces";
import useStyles from "./styles";
import { getUserId } from "service/helpers/localstorage";
import { formatter, fileTypeTest, titles } from "Helpers";
import UsedDialog from "Components/UI/Organisms/Plant/Used/Dialog";
import Videoplayer from "Components/UI/Molecules/Videoplayer";
import VehicleInfo from "Components/UI/Organisms/Ecosoft/Card/VehicleInfo";
import ImgZoomStock from "Components/UI/Atoms/ImgZoomStock";
import "./CssImg.css";
import { ENGINE_ALIMENTATION } from "Constants";

const DialogStock = (props) => {
  const { open, handleclose, data, setRender, render } = props;

  const [loadCapture, setloadCapture] = useState(false);

  const ref = useRef();
  const imgRef = useRef(null);

  const createDismissableSnackbar = useCustomSnackbar();

  const classes = useStyles();

  const {
    state: reserveState,
    setOpen: reserveSetOpen,
    setClose: reserveSetClose,
    setLoading: reserveSetLoading,
  } = setState();

  const {
    state: cancelReserveState,
    setOpen: cancelReserveSetOpen,
    setClose: cancelReserveSetClose,
    setLoading: cancelReserveSetLoading,
  } = setState();

  const {
    state: infoState,
    setState: infoSet,
    setClose: infoSetClose,
  } = setState();

  const {
    state: checklist,
    setOpen: openChecklist,
    setClose: closeChecklist,
  } = setState();

  const {
    state: comment,
    setOpen: openComment,
    setClose: closeComment,
    setState: editComment,
  } = setState();

  const {
    state: video,
    setOpen: openVideo,
    setClose: closeVideo,
    setState: setVideo,
  } = setState();

  const {
    state: openDialogVehicle,
    setOpen: setOpenDialogVehicle,
    setClose: closeDialogVehicle,
  } = setState();

  const {
    state: openEngine,
    setOpen: setOpenEngine,
    setClose: closeEngine,
  } = setState();

  const [fullImgInfo, setFullImgInfo] = useState(false);
  const [zoomScale, setZoomScale] = useState(false);

  const { state: fullImg, setState: setFullImg } = setState();

  const { state: used, setOpen: openUsed, setClose: closeUsed } = setState();

  const { state: order, setOpen: openOrder, setClose: closeOrder } = setState();

  async function checklistData(id) {
    await interfaces.plantChecklist.moreInfo(id).then((res) => {
      openChecklist({ data: res.data });
    });
  }

  const handleOpenDialogInfo = async () => {
    await interfaces.plantInterfaceClassification
      .stockProductData(data.id)
      .then((res) => {
        infoSet({ open: true, suspense: true, data: res.data });
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        });
      });
  };

  async function openDialogUsed() {
    await interfaces.plantUsed.usedInfo(data.id).then((res) => {
      openUsed({ dataObject: res.data });
    });
  }

  async function openVehicleData(id) {
    await interfaces.salesStock.getVehicleData(id).then((res) => {
      setOpenDialogVehicle({ dataObject: res.data });
    });
  }

  async function openEngineData(engine) {
    await interfaces.salesStock.getEngineData(engine).then((res) => {
      setOpenEngine({ dataObject: res.data });
    });
  }

  // eslint-disable-next-line
  const [_, copyToClipboard] = useCopyToClipboard();

  function copy(text) {
    copyToClipboard(text);
    createDismissableSnackbar("Copiado!", {
      variant: "success",
    });
  }
  const fields = () => [
    {
      class: "textfield",
      name: "producto",
      label: "Producto",
      md: 8,
      sm: 8,
      xs: 6,
      value: data ? data.producto : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "custom",
      md: 4,
      sm: 4,
      xs: 6,
      component: (
        <Tooltip title="Copiar" onClick={() => copy(data.codigo)}>
          <TextField
            label="Código"
            variant="outlined"
            value={data ? data.codigo : null}
            inputProps={{ style: { cursor: "copy" }, readOnly: true }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
          />
        </Tooltip>
      ),
    },
    {
      class: "textfield",
      name: "rudac",
      label: "RUDAC",
      md: 8,
      sm: 8,
      xs: 6,
      value: data ? data.moreData.rudac : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "precio",
      label: "Precio",
      md: 4,
      sm: 4,
      xs: 6,
      value: data ? formatter.format(data.moreData.precio) : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      md: 8,
      sm: 8,
      xs: 6,
      value: data ? data.vehiculo : null,
      inputProps: {
        readOnly: true,
        endAdornment: (
          <Tooltip title="Información del vehículo">
            <IconButton
              onClick={() => openVehicleData(data.vehiculo_id)}
              style={{
                color:
                  ["E", "O"].indexOf(data.codigo[0]) > -1 ? "black" : "grey",
              }}
              disabled={["E", "O"].indexOf(data.codigo[0]) > -1 ? false : true}
            >
              <DriveEtaIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      class: "custom",
      md: 4,
      sm: 4,
      xs: 6,
      component: (
        <Tooltip title="Copiar" onClick={() => copy(data.nrodev)}>
          <TextField
            label="Nº V"
            variant="outlined"
            value={data ? data.nrodev : null}
            inputProps={{ style: { cursor: "copy" }, readOnly: true }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
          />
        </Tooltip>
      ),
    },
    {
      class: "textfield",
      name: "calidad",
      label: "Calidad",
      md: 6,
      sm: 6,
      xs: 6,
      value: data ? data.calidad : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      md: 6,
      sm: 6,
      xs: 6,
      value: data ? data.estado : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "almacen",
      label: "Almacén",
      md: 6,
      sm: 6,
      xs: 6,
      value: data
        ? data.moreData.almacen
          ? data.moreData.almacen
          : data.moreData.bin
          ? data.moreData.bin
          : ""
        : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "ubicacion",
      label: "Ubicación",
      md: 6,
      sm: 6,
      xs: 6,
      value: data ? data.ubicacion : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "motor",
      label: "Motor",
      md: 8,
      sm: 8,
      xs: 6,
      value: data ? data.motor : null,
      disabled: !data.motor ? true : false,
      inputProps: {
        readOnly: true,
        endAdornment: (
          <Tooltip title="Información del motor">
            <IconButton
              onClick={() => openEngineData(data.motor_id)}
              style={{ color: data.motor ? "black" : "grey" }}
              disabled={!data.motor ? true : false}
            >
              <LocalGasStationIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      class: "textfield",
      name: "km",
      label: "Kilómetros",
      md: 4,
      sm: 4,
      xs: 6,
      value: data ? data.moreData.kilometros : null,
      inputProps: {
        readOnly: true,
      },
    },
    {
      class: "textfield",
      name: "observaciones",
      label: "Observaciones",
      sm: 12,
      xs: 12,
      value: comment.observaciones
        ? comment.observaciones
        : data.moreData.observaciones,
      multiline: true,
      rows: 3,
      inputProps: {
        readOnly: true,
        endAdornment: (
          <Tooltip title="Editar observación">
            <IconButton
              onClick={() =>
                openComment({ observaciones: data.moreData.observaciones })
              }
              style={{
                color:
                  ["E", "O"].indexOf(data.codigo[0]) > -1 ? "black" : "grey",
              }}
              disabled={["E", "O"].indexOf(data.codigo[0]) > -1 ? false : true}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  const saveComment = async () => {
    if (comment.observaciones === data.moreData.observaciones)
      return closeComment();
    await interfaces.salesStock
      .updateComment(data.id, {
        comentarios: comment.observaciones,
      })
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        });
        closeComment();
        setTimeout(() => {
          setRender(!render);
        }, 100);
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      );
  };

  const onSubmitDelete = async () => {
    cancelReserveSetLoading(true);
    await interfaces.salesStock
      .deleteReserve(data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        });
        setTimeout(() => {
          cancelReserveSetClose({ loading: false });
        }, 100);
        setTimeout(() => {
          handleclose();
          setRender(!render);
        }, 100);
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      );
  };

  const onButtonClick = useCallback(() => {
    setloadCapture(true);
    const filter = (node) => {
      if (node.id === "productName") {
        node.style.minWidth = "350px";
      }
      const exclusionClasses = ["reserva1", "reserva2", "reserva3", "buttons"];
      return !exclusionClasses.some((classname) => {
        return node.id === classname;
      });
    };
    if (!imgRef.current) {
      return;
    }
    toPng(imgRef.current, {
      filter: filter,
      cacheBust: true,
      allowTaint: false,
      useCORS: true,
      backgroundColor: "white",
    }).then(async (dataUrl) => {
      const img = await fetch(dataUrl);
      const imgBlob = await img.blob();
      try {
        navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": imgBlob,
          }),
        ]);
        createDismissableSnackbar("Imagen copiada al portapapeles!", {
          variant: "success",
        });
        setloadCapture(false);
      } catch (error) {}
    });
    // eslint-disable-next-line
  }, []);

  const title = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Typography
            id="productName"
            style={{ display: "inline-block" }}
            variant="h6"
          >
            {data.producto}
          </Typography>

          <Typography
            style={{
              display: data.reservado ? "inline-block" : "none",
            }}
            variant="h6"
            id="reserva1"
          >
            &nbsp;-&nbsp;
          </Typography>

          <Hidden smDown>
            <Typography
              style={{
                display: data.reservado ? "inline-block" : "none",
                color: "red",
                fontSize: "18px",
              }}
              variant="h6"
              id="reserva2"
            >
              Reservado por {data.reserva_usuario} hasta el día{" "}
              {data.reserva_vencimiento}{" "}
            </Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography
              style={{
                display: data.reservado ? "inline-block" : "none",
                color: "red",
                fontSize: "18px",
              }}
              variant="h6"
              id="reserva4"
            >
              &nbsp;Reservado
            </Typography>
          </Hidden>
        </div>
      </div>
    );
  };

  function closeGallery(e) {
    setFullImg({ fullScreen: e });
    if (!e) {
      setZoomScale(false);
    }
  }

  const children = () => {
    return (
      <div>
        <Grid container>
          <Grid
            sm={12}
            md={5}
            xs={12}
            className={classes.paddingGrid}
            id="fields"
          >
            <InputsWithoutValidate fields={fields()} spacing={2} />
          </Grid>
          <Grid sm={12} md={7} xs={12} className={classes.contentImg}>
            <div className={classes.divContentImg}>
              <ReactImageGallery
                ref={ref}
                items={titles(data.moreData.fotos)}
                useBrowserFullscreen={false}
                showBullets={true}
                onClick={() => ref.current.fullScreen()}
                onScreenChange={(e) => closeGallery(e)}
                stopPropagation={true}
                lazyLoad={true}
                showThumbnails={false}
                onErrorImageURL={
                  process.env.PUBLIC_URL + "/Images/unavailable.jpg"
                }
                renderPlayPauseButton={() => (
                  <IconButton
                    className={classes.donwloadIcon}
                    onClick={() =>
                      window.location.assign(
                        titles(data.moreData.fotos)[
                          ref.current.getCurrentIndex()
                        ].original
                      )
                    }
                  >
                    <GetAppIcon style={{ width: "35px", height: "35px" }} />
                  </IconButton>
                )}
                renderItem={({ original }) => (
                  <>
                    <div
                      style={{ cursor: zoomScale ? "zoom-out" : "zoom-in" }}
                      onClick={() =>
                        fullImg.fullScreen ? setZoomScale(!zoomScale) : null
                      }
                    >
                      <ImgZoomStock
                        img={original}
                        zoomScale={zoomScale ? 3 : 1}
                        transitionTime={0.5}
                        width={"100%"}
                        height={"515px"}
                        isFullscreen={fullImg.fullScreen}
                      />
                    </div>
                  </>
                )}
              />
            </div>
          </Grid>
        </Grid>

        <div className={classes.flex} id="buttons">
          <div className={classes.btnVideo}>
            {data.moreData.fotos
              .filter((e) => fileTypeTest(e.fullscreen) === "video")
              .map((item, index) => {
                return (
                  <div className={classes.divInsideVideo}>
                    <CustomButton
                      fullWidth
                      text={`Video Nº ${index + 1}`}
                      color="primary"
                      onClick={() =>
                        openVideo({
                          url: item.fullscreen,
                          play: true,
                          fullScreen: false,
                          height: "90vh",
                          width: "70vw",
                        })
                      }
                    />
                  </div>
                );
              })}
          </div>
          <div className={classes.btnRandoms}>
            {data.checklist && (
              <CustomButton
                fullWidth
                text="Checklist"
                color="primary"
                onClick={() => checklistData(data.checklist.id)}
              />
            )}
          </div>

          <div className={classes.btnRandoms}>
            <CustomButton
              fullWidth
              text={data.reservado === true ? "Cancelar reserva" : "Reservar"}
              style={{ marginRight: "10px" }}
              disabled={
                (data.reserva_usuario_id === "" ||
                  parseInt(getUserId()) === data.reserva_usuario_id) &&
                !data.bloqueado &&
                !data.pedido
                  ? false
                  : true
              }
              color="primary"
              onClick={
                data.reservado === true ? cancelReserveSetOpen : reserveSetOpen
              }
              className={
                data.reservado === true
                  ? classes.buttonRed
                  : classes.butttonGreen
              }
            />
          </div>

          <div className={classes.btnRandoms}>
            <CustomButton
              fullWidth
              text="Ficha técnica"
              color="primary"
              onClick={() =>
                ["O", "E"].indexOf(data.codigo[0]) > -1
                  ? handleOpenDialogInfo()
                  : openDialogUsed()
              }
            />
          </div>

          <div className={classes.btnRandoms}>
            <CustomButton
              fullWidth
              text="Captura de pantalla"
              color="primary"
              onClick={onButtonClick}
              isLoading={loadCapture}
              disabled={loadCapture}
            />
          </div>
          <div className={classes.btnRandoms}>
            <CustomButton
              fullWidth
              text="Pedir por grilla"
              color="primary"
              onClick={() => openOrder({ code: data.codigo })}
            />
          </div>
        </div>
      </div>
    );
  };

  const updateStock = () => {
    setTimeout(() => {
      infoSetClose();
    }, 100);
    setTimeout(() => {
      setRender(!render);
      handleclose();
    }, 100);
  };

  const engineForm = (data) => [
    {
      disabled: true,
      class: "textfield",
      label: "Código de motor",
      value: data.codigo,
      name: "codigo",
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      label: "Nombre del motor",
      name: "nombre",
      value: data.nombre,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "CV",
      name: "cv",
      value: data.cv,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "KW",
      name: "kw",
      value: (data.cv / 1.341).toFixed(2),
      sm: 6,
      xs: 12,
    },
    {
      id: 5,
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Válvulas",
      name: "valvulas",
      value: data.valvulas,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Comando V.",
      name: "valvulas_comando",
      value: parseInt(data.valvulas_comando),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "DOHC",
        },
        {
          id: 2,
          optionLabel: "SOHC",
        },
        {
          id: 3,
          optionLabel: "OHV",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Cilindrada",
      name: "cilindrada",
      value: data.cilindrada,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "textfield",
      type: "number",
      label: "Cilindros",
      name: "cilindros",
      value: data.cilindros,
      sm: 6,
      xs: 12,
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Combustible",
      name: "combustible",
      value: parseInt(data.combustible),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "NAFTA",
        },
        {
          id: 2,
          optionLabel: "DÍESEL",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "autocomplete",
      name: "turbo",
      label: "Turbo",
      value: parseInt(data.turbo),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "SI",
        },
        {
          id: 0,
          optionLabel: "NO",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Alimentación",
      name: "alimentacion",
      value: data.alimentacion,
      sm: 6,
      xs: 12,
      options: ENGINE_ALIMENTATION,
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Acelerador",
      name: "acelerador",
      value: parseInt(data.acelerador),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "CABLE",
        },
        {
          id: 2,
          optionLabel: "ELECTRÓNICO",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Distribución",
      name: "distribucion",
      value: parseInt(data.distribucion),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "CADENA",
        },
        {
          id: 2,
          optionLabel: "CORREA",
        },
        {
          id: 3,
          optionLabel: "ENGRANAJES",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
    {
      disabled: true,
      class: "autocomplete",
      label: "Posición motor",
      name: "motor_posicion",
      value: parseInt(data.motor_posicion),
      sm: 6,
      xs: 12,
      options: [
        {
          id: 1,
          optionLabel: "LINEAL",
        },
        {
          id: 2,
          optionLabel: "TRANSVERSAL",
        },
      ],
      optionLabel: `options["optionLabel"]`,
      optionValue: "id",
    },
  ];

  return (
    <>
      <DialogComponent
        ref={imgRef}
        title={title()}
        maxHeight
        disableEscapeKeyDown={fullImg.fullScreen === true ? true : false}
        subtitle={
          <>
            <b>{data.codigo}</b> - {data.vehiculo}
            {data.nrodev ? ` - Nº V ${data.nrodev}` : ""}
            {data.dominio ? ` - ${data.dominio}` : ""}
            <Hidden mdDown>
              <Typography
                style={{
                  display:
                    data.reservado || data.reserva_detalle
                      ? "inline-block"
                      : "none",
                  color: "red",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
                variant="h6"
                id="reserva3"
              >
                &nbsp;&nbsp;{data.reserva_detalle}
              </Typography>
            </Hidden>
            <Typography
              style={{
                display: "inline-block",
                color:
                  data.estado_registral === "Finalizado"
                    ? "green"
                    : data.estado_registral === "No finalizado"
                    ? "red"
                    : data.estado_registral === "En trámite"
                    ? "orange"
                    : "blue",
                fontSize: "16px",
                fontStyle: "italic",
                position: "absolute",
                right: "10px",
              }}
              variant="h6"
            >
              {"Vehículo " + [data.estado_registral.toLowerCase()]}
            </Typography>
          </>
        }
        open={open}
        handleclose={handleclose}
        widthLarge="80vw"
        children={children()}
      />

      <Reserve
        reserve={reserveState}
        handleCloseDialog={handleclose}
        handleCloseReserve={reserveSetClose}
        setLoading={reserveSetLoading}
        id={data.id}
        setRender={setRender}
        render={render}
        isUsed={["U", "N", "R"].indexOf(data.codigo[0]) > -1 ? true : false}
      />

      {infoState.suspense && (
        <EditContextProvider>
          <DialogMoreInfo
            open={infoState.open}
            data={infoState.data}
            handleClose={infoSetClose}
            handleRender={updateStock}
          />
        </EditContextProvider>
      )}

      {checklist.suspense && (
        <EditContextProvider>
          <ChecklistDialog
            open={checklist.open}
            onClose={closeChecklist}
            info={checklist.data}
            canEdit={true}
          />
        </EditContextProvider>
      )}

      {used.suspense && (
        <EditContextProvider>
          <UsedDialog
            open={used.open}
            handleclose={closeUsed}
            data={used.dataObject}
            setRender={setRender}
            render={render}
            handleRender={handleclose}
          />
        </EditContextProvider>
      )}

      <DialogComponent
        title="¿Desea cancelar la reserva?"
        open={cancelReserveState.open}
        handleclose={
          !cancelReserveState.loading ? cancelReserveSetClose : false
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={onSubmitDelete}
            onClickCancel={cancelReserveSetClose}
            isLoading={cancelReserveState.loading}
            disabledCancel={cancelReserveState.loading}
            disabledSubmit={cancelReserveState.loading}
          />
        }
      />

      {openEngine.suspense && (
        <DialogComponent
          title="Información del motor"
          disableChildHeight
          open={openEngine.open}
          handleclose={closeEngine}
          widthLarge="500px"
          children={
            <div style={{ marginBottom: "20px" }}>
              <InputsWithoutValidate
                fields={engineForm(openEngine.dataObject)}
                spacing={3}
              />
            </div>
          }
        />
      )}

      <DialogComponent
        title="Editar comentario"
        disableChildHeight
        disableBackdropClick
        open={comment.open}
        handleclose={closeComment}
        children={
          <TextField
            name="observaciones"
            label="Observaciones"
            value={comment.observaciones}
            onChange={(e) => editComment({ observaciones: e.target.value })}
            fullWidth
            variant="outlined"
          />
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={saveComment}
            onClickCancel={closeComment}
          />
        }
      />

      {video.suspense && (
        <Videoplayer
          video={video}
          setVideo={setVideo}
          closeVideo={closeVideo}
        />
      )}

      {openDialogVehicle.suspense && (
        <VehicleInfo
          fullImg={fullImgInfo}
          setFullImg={setFullImgInfo}
          open={openDialogVehicle.open}
          handleClose={closeDialogVehicle}
          item={openDialogVehicle.dataObject}
        />
      )}

      {order.suspense && (
        <NewOrder
          open={order.open}
          handleclose={closeOrder}
          render={render}
          setRender={setRender}
          code={order.code}
        />
      )}
    </>
  );
};

export default DialogStock;
