import React, { useState, useEffect, useCallback } from "react"
import axios from "axios"

import { Grid, Typography } from "@material-ui/core"
import SyncAltIcon from "@material-ui/icons/SyncAlt"

import useStyles from "./styles"
import CurrencyRow from "./CurrencyRow"

const DOLLAR_BLUE_API_URL = "https://dolarapi.com/v1/dolares/blue"

const Coin = () => {
  const classes = useStyles()
  const [blue, setBlue] = useState(0)
  const [dollar, setDollar] = useState({
    dollar: 1,
  })

  const getExchange = useCallback(async () => {
    await axios.get(DOLLAR_BLUE_API_URL).then((res) => {
      const blueCompra = res.data.compra
      const blueVenta = res.data.venta

      setDollar({
        dollar: `${(blueCompra + blueVenta) / 2}`,
      })
      setBlue((blueCompra + blueVenta) / 2)
    })
  }, [])

  useEffect(() => {
    getExchange()
  }, [getExchange])

  const [amount, setAmount] = useState(1)
  const [amountInFromCurrency, setAmountInFromCurrency] = useState(true)

  const centShorter = (value) => {
    return value.toFixed(2)
  }

  let toAmount, fromAmount
  if (amountInFromCurrency) {
    fromAmount = amount
    toAmount = amount * dollar.dollar
  } else {
    toAmount = amount
    fromAmount = amount / dollar.dollar
    fromAmount = centShorter(fromAmount)
  }

  function handleFromAmountChange(e) {
    setAmount(e.target.value)
    setAmountInFromCurrency(true)
  }

  function handleToAmountChange(e) {
    setAmount(e.target.value)
    setAmountInFromCurrency(false)
  }

  return (
    <div style={{ margin: "10px" }}>
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12}>
          <Typography variant="h1" className={classes.textPrimary}>
            Moneda extranjera
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography align="start">Dólar blue: 1 U$D = $ {blue}</Typography>
        </Grid>
        <Grid item sm={5} xs={12}>
          <CurrencyRow
            onChangeAmount={handleFromAmountChange}
            amount={fromAmount}
            label="Dólar"
            icon={
              <img
                alt="United States"
                src={process.env.PUBLIC_URL + "/Images/eeuuFlag.webp"}
                className={classes.styleImg}
              />
            }
          />
        </Grid>
        <Grid
          container
          item
          sm={2}
          xs={2}
          alignItems="center"
          justifyContent="center"
        >
          <SyncAltIcon />
        </Grid>
        <Grid item sm={5} xs={12}>
          <CurrencyRow
            onChangeAmount={handleToAmountChange}
            amount={toAmount}
            label="Pesos"
            icon={
              <img
                alt="United States"
                src={process.env.PUBLIC_URL + "/Images/flagArg.webp"}
                className={classes.styleImg}
              />
            }
          />
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.typography}
      >
        Fuente: Dolar SI
      </Typography>{" "}
    </div>
  )
}
export default Coin
