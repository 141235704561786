import React, { useState, useContext, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import {
  List,
  ListItemText,
  Collapse,
  Typography,
  Icon,
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"

import ListItemIcon from "@material-ui/core/ListItemIcon"
import StarIcon from "@material-ui/icons/Star"

import { OpenNavbar } from "Components/Hooks/ContextSideBar"
import useStyles from "./styles"
import PageList from "Components/Routing/Pages"
import { getFastAccess } from "service/helpers/localstorage"

import ListItemStyle from "Components/UI/Molecules/SideBar/ListItemStyle"

import { getUbicacion } from "service/helpers/localstorage"

const SideBarItemList = () => {
  const classes = useStyles()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const { setOpenSideBar } = useContext(OpenNavbar)

  const [menuItems, setMenuItems] = useState({
    vehicles: false,
    plant: false,
    sales: false,
    settings: false,
  })

  const handleClickVehicle = () => {
    setMenuItems({
      vehicles: !menuItems.vehicles,
      plant: false,
      sales: false,
      settings: false,
    })
  }
  const handleClickPlanta = () => {
    setMenuItems({
      vehicles: false,
      plant: !menuItems.plant,
      sales: false,
      settings: false,
    })
  }
  const handleClickSetting = () => {
    setMenuItems({
      vehicles: false,
      plant: false,
      sales: false,
      settings: !menuItems.settings,
    })
  }
  const handleClickSales = () => {
    setMenuItems({
      vehicles: false,
      plant: false,
      sales: !menuItems.sales,
      settings: false,
    })
  }

  function handleClose() {
    setOpenSideBar(false)
  }

  const fastAccessArray = JSON.parse(getFastAccess())
  const pageList = PageList({ handleClose }).filter(
    (e) =>
      ["Vehículos", "Planta", "Configuración", "Ventas"].find(
        (item) => item === e.text
      ) && e.style
  )

  let listOfSubmenu = []
  for (let i = 0; i < pageList.length; i++) {
    listOfSubmenu.push(...pageList[i].submenu)
  }

  listOfSubmenu = listOfSubmenu.filter((e) => fastAccessArray.includes(e.keys))

  useEffect(() => {
    if (
      !listOfSubmenu.some((e) => e.to.includes("vehicles")) &&
      pathname.includes("vehicles")
    ) {
      handleClickVehicle()
    }
    if (
      !listOfSubmenu.some((e) => e.to.includes("plant")) &&
      pathname.includes("plant")
    ) {
      handleClickPlanta()
    }
    if (
      !listOfSubmenu.some((e) => e.to.includes("settings")) &&
      pathname.includes("settings")
    ) {
      handleClickSetting()
    }
    if (
      !listOfSubmenu.some((e) => e.to.includes("sales")) &&
      pathname.includes("sales")
    ) {
      handleClickSales()
    }
    // eslint-disable-next-line
  }, [])

  const itemList = PageList({
    handleClickVehicle,
    handleClickPlanta,
    handleClickSales,
    handleClickSetting,
    menuItems,
    handleClose,
  })

  const ListData = () =>
    itemList.map((item) => {
      const {
        text,
        icon,
        to,
        keys,
        collapse,
        submenu,
        click,
        open,
        style,
        onClick,
      } = item

      return (
        <>
          {collapse ? (
            <>
              <ListItemStyle button onClick={click} style={style} key={keys}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemStyle>
              <Collapse in={open} timeout="auto">
                {submenu.map((subitem) => {
                  return (
                    <List key={subitem.id} component="div" disablePadding>
                      <ListItemStyle
                        button
                        key={subitem.keys}
                        className={classes.nested}
                        selected={pathname === subitem.to ? true : false}
                        component={Link}
                        to={subitem.to}
                        onClick={subitem.onClick}
                        style={subitem.style}
                      >
                        <ListItemText primary={subitem.text} />
                      </ListItemStyle>
                    </List>
                  )
                })}
              </Collapse>
            </>
          ) : (
            <ListItemStyle
              className={classes.listItem}
              button
              key={keys}
              to={to}
              component={Link}
              selected={pathname === to}
              style={style}
              onClick={onClick}
            >
              {icon ? <ListItemIcon>{icon} </ListItemIcon> : null}
              <ListItemText>{text}</ListItemText>
            </ListItemStyle>
          )}
        </>
      )
    })

  const FastAccess = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            padding: "16px",
          }}
        >
          <Icon color="primary">
            <StarIcon />
          </Icon>
          <Typography style={{ marginLeft: "30px" }}>Acceso rápido</Typography>
        </div>

        {listOfSubmenu.map((subitem) => {
          return (
            <List key={subitem.id} component="div" disablePadding>
              <ListItemStyle
                dense
                button
                key={subitem.keys}
                className={classes.nested}
                selected={pathname === subitem.to ? true : false}
                component={Link}
                to={subitem.to}
                onClick={subitem.onClick}
                style={subitem.style}
              >
                <ListItemText primary={subitem.text} />
              </ListItemStyle>
            </List>
          )
        })}
      </>
    )
  }

  const imgSideBar = () => {
    if ((pathname && pathname.includes("oem")) || location.includes("OEM"))
      return process.env.PUBLIC_URL + "/Images/logoOEM.png"
    return process.env.PUBLIC_URL + "/Images/personal.webp"
  }
  return (
    <div className={classes.root} style={{ overflowY: "scroll" }}>
      <List className={classes.lista}>
        <div className={classes.divLogo}>
          <Link to="/home">
            <img src={imgSideBar()} alt="Logo" className={classes.logo} />
          </Link>
        </div>
        <ListData />
      </List>
      <div
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          display: "flex",
        }}
      >
        <List style={{ width: "100%", padding: "4%" }}>
          <FastAccess />
        </List>
      </div>
    </div>
  )
}

export default SideBarItemList
