//user interface, call of axios with sources
import { axiosService } from "../../../index"
import { quotationsEndpoints } from "service/sources/vehicles/quotations"

const list = (options) => {
  return axiosService.get(quotationsEndpoints.LIST + options)
}

const beforeCreate = () => {
  return axiosService.get(quotationsEndpoints.BEFORE_CREATE)
}

const create = (values) => {
  return axiosService.post(quotationsEndpoints.CREATE_QUOTATION, values)
}

const update = (id, values) => {
  return axiosService.put(quotationsEndpoints.UPDATE_QUOTE + id, values)
}

const deleteQuotation = (id) => {
  return axiosService.del(quotationsEndpoints.DELETE_QUOTE + id)
}

const oneQuotation = (id) => {
  return axiosService.get(quotationsEndpoints.ONE_QUOTATION + id)
}

const print = (id, values) => {
  return axiosService.getPDF(quotationsEndpoints.PRINT_QUOTE + id, values)
}

const exportQuote = () => {
  return axiosService.get(quotationsEndpoints.EXPORT)
}

const confirmQuote = (id) => {
  return axiosService.post(quotationsEndpoints.CONFIRM_QUOTE + id)
}

const declineQuote = (id, values) => {
  return axiosService.post(quotationsEndpoints.DECLINE_QUOTE + id, values)
}

const restoreQuote = (id, values) => {
  return axiosService.post(quotationsEndpoints.RESTORE_QUOTE + id, values)
}

const uploadMainPhoto = (file, id, filename, dominio) => {
  return axiosService.uploadSingleFile(
    quotationsEndpoints.UPLOAD_MAINPHOTOS +
      id +
      "?dominio=" +
      dominio +
      "&filename=" +
      filename,
    file
  )
}
const deleteMainPhoto = (id, filename, dominio) => {
  return axiosService.post(
    quotationsEndpoints.DELETE_MAINPHOTO +
      id +
      "?filename=" +
      filename +
      "&dominio=" +
      dominio
  )
}

const uploadPhoto = (id, dominio, file) => {
  return axiosService.uploadFiles(
    quotationsEndpoints.UPLOAD_PHOTO + id + "?dominio=" + dominio,
    file
  )
}

const deletePhoto = (id, index) => {
  return axiosService.post(
    quotationsEndpoints.DELETE_PHOTO + id + "?index=" + index
  )
}

const uploadFile = (id, dominio, file) => {
  return axiosService.uploadSingleFile(
    quotationsEndpoints.UPLOAD_FILE + id + "?dominio=" + dominio,
    file
  )
}
const deleteFile = (id, index) => {
  return axiosService.uploadSingleFile(
    quotationsEndpoints.DELETE_FILE + id + "?index=" + index
  )
}

const duplicateQuote = (id, body) => {
  return axiosService.post(quotationsEndpoints.DUPLICATE + id, body)
}

export const quotationsInterface = {
  list,
  beforeCreate,
  create,
  oneQuotation,
  update,
  deleteQuotation,
  print,
  exportQuote,
  confirmQuote,
  declineQuote,
  uploadMainPhoto,
  deleteMainPhoto,
  uploadPhoto,
  deletePhoto,
  uploadFile,
  deleteFile,
  restoreQuote,
  duplicateQuote,
}
