import React, { useContext, useState } from "react"
import { TextField } from "formik-material-ui"
import { Field, Formik } from "formik"

import { Grid, Button, InputAdornment, Hidden } from "@material-ui/core"
import { Tooltip } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"

import useStyles from "./styles"
import CostsAndExpenses from "./Modals"
import Loader from "Components/UI/Atoms/Loader"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"
import { printPDF } from "Helpers"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const Quotation = (props) => {
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()

  const [wait, setWait] = useState(false)

  const [edit] = useContext(EditContext)

  const { formEdit, setFormEdit, setFormApi, formApi, initialData } =
    useContext(FormContext)

  const DATA_FORM = formEdit.hasOwnProperty("vehicle")
    ? formEdit.vehicle.vehiculo_cotizacion
    : null

  const [form, setForm] = useState(DATA_FORM)

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_cotizacion: {
          ...formEdit.vehicle.vehiculo_cotizacion,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_cotizacion: {
        ...formApi.vehiculo_cotizacion,
        [e.target.name]: e.target.value,
      },
    })
  }

  async function print() {
    setWait(true)
    await interfaces.vehicleInterface
      .vehicleOffer(formEdit.vehicle.id)
      .then((res) => printPDF(res, 1000, 700))
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setWait(false)
  }

  const centShorter = (value) => {
    return value.toFixed(2)
  }
  const disabledField = () => {
    if (initialData.header.authorization === 1 && edit) {
      return true
    }
    if (initialData.header.authorization === 1 && !edit) {
      return true
    } else return edit ? false : true
  }

  const objeto = [
    {
      id: 1,
      label: "Cotización siniestro IVA incluído",
      name: "cotizacion_restos",
      sm: 6,
      xs: 12,
      value: form.cotizacion_restos,
      select: false,
      disabled: disabledField(),
      icon: <AttachMoneyIcon />,
    },
    {
      id: 2,
      label: "Cotización siniestro bruto",
      name: "cotizacion_siniestro",
      sm: 6,
      xs: 12,
      value: centShorter(
        form.cotizacion_restos - form.cotizacion_restos * 0.21
      ),
      select: false,
      disabled: true,
      icon: <AttachMoneyIcon />,
    },
    {
      id: 3,
      label: "Cotización retiro IVA incluído",
      name: "cotizacion_retiro",
      sm: 6,
      xs: 12,
      value: form.cotizacion_retiro,
      select: false,
      disabled: disabledField(),
      icon: <AttachMoneyIcon />,
    },
    {
      id: 4,
      label: "Cotización retiro bruto",
      name: "cotizacion_retiro",
      sm: 6,
      xs: 12,
      value: centShorter(form.cotizacion_retiro / 1.21),
      select: false,
      disabled: true,
      icon: <AttachMoneyIcon />,
    },
    {
      id: 5,
      label: "Estado",
      name: "cotizacion_retiro",
      sm: 12,
      xs: 12,
      value: form.estado_retiro,
      disabled: true,
      icon: <AttachMoneyIcon />,
      select: true,
      options: [
        {
          id: 1,
          label: "Retiro pendiente",
        },
        {
          id: 2,
          label: "Retiro en curso",
        },
        {
          id: 3,
          label: "Retiro realizado",
        },
      ],
    },
    {
      id: 6,
      label: "Observaciones",
      name: "observaciones",
      sm: 12,
      xs: 12,
      value: form.observaciones,
      disabled: disabledField(),
      multiline: true,
      rows: 5,
    },
  ]

  return (
    <div className={classes.rootGrid}>
      <Formik initialValues={form}>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className={classes.paddingGrids}
        >
          {objeto.map((item) => (
            <Grid item sm={item.sm} xs={item.xs}>
              {item.select ? (
                <Autocomplete
                  disabled={item.disabled}
                  noOptionsText={"No se encontraron resultados"}
                  getOptionLabel={(option) => option.label}
                  options={item.options}
                  defaultValue={item.options.find(
                    (status) => status.id === parseInt(form.estado_retiro)
                  )}
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      fullWidth
                      label={item.label}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              ) : (
                <Field
                  component={TextField}
                  disabled={item.disabled}
                  fullWidth
                  variant="outlined"
                  type={item.type}
                  name={item.name}
                  label={item.label}
                  multiline={item.multiline}
                  rows={item.rows}
                  value={item.uppercase ? item.value.toUpperCase() : item.value}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>{item.icon}</InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          ))}

          <Grid container justify="flex-end" item xs={12} sm={12} spacing={1}>
            <Tooltip
              title={"Debe guardar los cambios antes de presionar este botón"}
            >
              <Hidden xsDown>
                <Button
                  className={classes.styleButtons}
                  variant="contained"
                  endIcon={<PrintIcon />}
                  disabled={disabledField()}
                  onClick={print}
                >
                  IMPRIMIR
                </Button>
              </Hidden>
            </Tooltip>

            <CostsAndExpenses disabled={disabledField()} />
          </Grid>
        </Grid>
      </Formik>
      {wait ? <Loader /> : null}
    </div>
  )
}

export default Quotation
