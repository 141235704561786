import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import New from "Components/UI/Organisms/Settings/Users/Permissions/Content/Template/New"
import DeleteTemplate from "./Delete"
import Edit from "./Edit"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"

const Template = (props) => {
  const {
    data,
    handleListItemClick,
    idTemplate,
    changeRender,
    setIdTemplate,
    fullData,
  } = props

  const [valueTemplate, setValueTemplate] = useState("")

  const {
    state: newTemplate,
    setOpen: setNewTemplate,
    setClose: closeNewTemplate,
  } = SetState()

  const {
    state: deleteTemplate,
    setOpen: setDelete,
    setClose: setCloseTemplate,
  } = SetState()

  const {
    state: openEdit,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const onChangeTemplate = (e) => {
    setValueTemplate(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueTemplate) {
          return e.nombre.toUpperCase().includes(valueTemplate.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }
  const disabledOthers = () => (fullData.editData ? true : false)

  return (
    <>
      <InputSearch
        button={false}
        onClick={setNewTemplate}
        onChange={onChangeTemplate}
        value={valueTemplate}
      />
      {newTemplate.suspense && (
        <New
          open={newTemplate.open}
          handleClose={closeNewTemplate}
          changeRender={changeRender}
        />
      )}
      {deleteTemplate.suspense && (
        <DeleteTemplate
          open={deleteTemplate.open}
          handleClose={setCloseTemplate}
          selectedIndex={idTemplate}
          changeRender={changeRender}
          setIdTemplate={setIdTemplate}
        />
      )}

      {openEdit.suspense && (
        <Edit
          open={openEdit.open}
          handleclose={setCloseEdit}
          data={data}
          idTemplate={idTemplate}
          changeRender={changeRender}
        />
      )}

      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idTemplate}
            onClickSelect={handleListItemClick}
            onClickDelete={setDelete}
            onClickEdit={setOpenEdit}
            nameIncludes="nombre"
            disabledOthers={disabledOthers()}
          />
        }
        height="500px"
      />
    </>
  )
}
export default Template
