import React, { useContext } from "react"

import { Grid, TextField } from "@material-ui/core"

import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"

import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const RoadTransport = (props) => {
  const [edit] = useContext(EditContext)
  const { formEdit, setFormEdit, formApi, setFormApi, initialData } =
    useContext(FormContext)
  const transportes = formEdit.selectInputs.transporte_retiro
  const chofer = formEdit.selectInputs.transporte_choferes
  const vehicles = formEdit.selectInputs.transporte_camiones

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeChofer = (value) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          chofer_ruta: value.id,
          dni_ruta: value.dni,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        chofer_ruta: value.id,
        dni_ruta: value.dni,
      },
    })
  }

  const disabledField = () => {
    if (initialData.header.authorization === 1 && edit) {
      return true
    }
    if (initialData.header.authorization === 1 && !edit) {
      return true
    } else return edit ? false : true
  }

  return (
    <Grid
      container
      spacing={2}
      sm={12}
      md={12}
      xs={12}
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item sm={12} xs={12} md={6}>
        <CustomAutocomplete
          options={transportes.sort(function (a, b) {
            if (a.nombre > b.nombre) {
              return 1
            }
            if (a.nombre < b.nombre) {
              return -1
            }
            return 0
          })}
          getOptionLabel={(transportes) => transportes.nombre}
          disabled={disabledField()}
          label={"Transportes"}
          defaultValue={transportes.find(
            (valueId) =>
              valueId.id ===
              parseInt(formEdit.vehicle.vehiculo_retiro.transporte_ruta)
          )}
          onChange={(event, value) => {
            handleChange({
              target: { name: "transporte_ruta", value: value.id },
            })
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <CustomAutocomplete
          options={vehicles
            .sort(function (a, b) {
              if (a.marca > b.marca) {
                return 1
              }
              if (a.marca < b.marca) {
                return -1
              }
              return 0
            })
            .filter(
              (e) =>
                e.transporte_id ===
                formEdit.vehicle.vehiculo_retiro.transporte_ruta
            )}
          getOptionLabel={(vehicles) =>
            vehicles &&
            vehicles.marca + " " + vehicles.modelo + " " + vehicles.dominio
          }
          disabled={disabledField()}
          label={"Vehículo"}
          defaultValue={vehicles.find(
            (valueId) =>
              valueId.id ===
              parseInt(formEdit.vehicle.vehiculo_retiro.vehiculo_ruta)
          )}
          onChange={(event, value) => {
            handleChange({
              target: { name: "vehiculo_ruta", value: value.id },
            })
          }}
        />
      </Grid>
      <Grid item sm={12} xs={12} md={6}>
        <CustomAutocomplete
          options={chofer
            .sort(function (a, b) {
              if (a.nombre > b.nombre) {
                return 1
              }
              if (a.nombre < b.nombre) {
                return -1
              }
              return 0
            })
            .filter(
              (e) =>
                e.transporte_id ===
                formEdit.vehicle.vehiculo_retiro.transporte_ruta
            )}
          getOptionLabel={(chofer) => chofer.nombre}
          disabled={disabledField()}
          defaultValue={chofer.find(
            (valueId) =>
              valueId.id ===
              parseInt(formEdit.vehicle.vehiculo_retiro.chofer_ruta)
          )}
          onChange={(event, value) => {
            handleChangeChofer(value)
          }}
          label="Chofer"
        />
      </Grid>

      <Grid item sm={12} xs={12} md={6}>
        <TextField
          disabled
          label="Número de documento"
          variant="outlined"
          name="dni_ruta"
          fullWidth
          type="number"
          value={formEdit.vehicle.vehiculo_retiro.dni_ruta}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item sm={12} xs={12} md={12}>
        <TextField
          disabled={true}
          label="Costo grúa ruta"
          variant="outlined"
          name="grua_ruta"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value="En desarrollo"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default RoadTransport
