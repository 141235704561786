import React from "react"
import { useHistory } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"

import SetState from "Components/Hooks/SetState"
import CustomButton from "Components/UI/Atoms/CustomButton"

import useStyles from "./styles"

import { useWindowSize } from "react-use"

import SearchStock from "./SearchStock"
const StockStation = () => {
  const classes = useStyles()
  let history = useHistory()

  const { width } = useWindowSize()

  const { state, setClose, setOpen } = SetState()

  function openUploadURL() {
    setOpen(history.push("/camara"))
  }

  function openUpload() {
    setOpen()
  }

  return (
    <div>
      <Grid spacing={1}>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Estación cero de piezas
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <CustomButton
              text="Buscar pieza"
              fullWidth
              onClick={width > 600 ? openUpload : openUploadURL}
              style={{ textTransform: "uppercase" }}
            />
          </Grid>
        </Grid>
      </Grid>

      {state.suspense && (
        <SearchStock open={state.open} handleclose={setClose} />
      )}
    </div>
  )
}

export default StockStation
