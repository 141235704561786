import React, { useRef } from "react"
import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { newOperator } from "Components/Validation/Settings/Various/Operators"
import { interfaces } from "service/interfaces"
import { UBICACIONES_MANUAL, OPERARIOS_ROLES } from "Constants"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const Edit = (props) => {
  const { open, handleclose, id, editInfo, changeRender } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const info = editInfo()

  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    if (typeof values.ubicacion_id === "string") {
      values.ubicacion_id = UBICACIONES_MANUAL.find(
        (e) => e.nombre === values.ubicacion_id
      ).id
    }
    values.nombre = values.nombre.toUpperCase()
    await interfaces.operatorsInterface
      .editOperator(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
  }

  const OPTIONS = [
    { id: 1, label: "SI" },
    { id: 0, label: "NO" },
  ]

  const object = (values, setFieldValue, isSubmitting) => [
    {
      class: "textfield",
      label: "Nombre",
      name: "nombre",
      value: values.nombre,
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      options: UBICACIONES_MANUAL,
      name: "ubicacion_id",
      label: "Ubicación",
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      defaultValue: UBICACIONES_MANUAL.find(
        (v) => v.id === values.ubicacion_id
      ),
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      options: OPTIONS,
      name: "activo",
      label: "Activo",
      optionLabel: `options["label"]`,
      optionValue: "id",
      defaultValue: OPTIONS.find((v) => values.activo === v.id),
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          disabled={isSubmitting}
          name="roles"
          options={OPERARIOS_ROLES}
          getOptionLabel={(e) => e.rol}
          defaultValue={values.roles}
          label={"Roles"}
          onChange={(_, value) => {
            setFieldValue("roles", value)
          }}
          tagName={"rol"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },
  ]
  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          nombre: info.nombre,
          ubicacion_id: info.ubicacion_id,
          activo: info.activo,
          roles: OPERARIOS_ROLES.filter((e) => info.roles.includes(e.rol)),
        }}
        validationSchema={newOperator}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={object(values, setFieldValue, isSubmitting)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <DialogComponent
      title="Editar operario"
      open={open}
      handleclose={handleclose}
      children={children()}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleclose}
        />
      }
    />
  )
}

export default Edit
