import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  firstDiv: {
    overflowY: "scroll",
  },
  secondDiv: {
    backgroundColor: "rgba(219, 219, 219, 0.27)",
  },
  previous: {
    textTransform: "initial",
    color: "rgba(129, 129, 129, 1)",
  },
  next: {
    textTransform: "initial",
    color: "rgba(129, 129, 129, 1)",
  },
  divider: {
    background: "rgba(129, 129, 129, 1)",
    height: "30px",
  },
}))

export default useStyles
