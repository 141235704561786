import { Typography } from "@material-ui/core"
  

const DialogTable = () => {

  
  return (
    <>
          <Typography variant="subtitle1">
            Detalles en el uso de la Grilla:
          </Typography>
          <ol style={{ textAlign: "justify" }}>
            <li>
              Todos los usuarios que se encuentren en el área <b>Planta</b>{" "}
              verán pedidos propios y pedidos realizados a la ubicación del
              usuario.
            </li>
            <li>
              El resto de usuarios, verán pedidos propios con destino a su
              ubicación.
            </li>
            <li>
              Algunos usuarios tendran la posibilidad de visualizar todos los
              pedidos que tienen como destino su ubicación (consultar al
              administrador).
            </li>
            <li>
              Los usuarios con el rol de <b>Despacho</b> y <b>Clasificación</b>{" "}
              verán los pedidos que se encuentren en ese estado.
            </li>
            <li>
              Los pedidos solo se podrán finalizar si se encuentran en estado de
              Despacho o por el usuario que los creó.
            </li>
          </ol>
          <Typography variant="subtitle1">
            Para el filtrado de la tabla:
          </Typography>
          <ol style={{ textAlign: "justify" }}>
            <li>
              Filtrar por estados traerá <b>TODOS</b> los pedidos de todas las
              ubicaciones con el valor elegido.
            </li>
            <li>
              Filtrar por urgencia sólo filtrará los pedidos que se encuentran
              actualmente.
            </li>
            <li>
              El filtro de usuarios devuelve todos los pedidos realizados de ese
              usuario que <b>no</b> estén finalizados.
            </li>
            <li>
              Filtrar por origen o destino traerá la totalidad de pedidos de la
              ubicación seleccionada.
            </li>
            <li>
              Cuando se utilice algún filtro por "Todas", se recomienda combinar
              varios de ellos para obtener información contundente, ej: Los
              pedidos de "Todos los usuarios" con origen "ECO Ruta 7" en estado
              "Taller".
            </li>
          </ol>
        </>
      
    
  );
};
export default DialogTable