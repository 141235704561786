export function columnTheme() {
  return {
    toolbarBackground: "#f2f2f2",
    "&:nth-child(1)": {
      paddingLeft: "10px",
      minWidth: "95px",
      maxWidth: "95px",
      width: "95px",
    },
    "&:nth-child(2)": {
      minWidth: "280px",
      width: "350px",
    },
    "&:nth-child(3)": {
      minWidth: "200px",
      width: "350px",
    },
    "&:nth-child(4)": {
      minWidth: "100px",
      maxWidth: "100px",
      width: "150px",
    },
    "&:nth-child(5)": {
      width: "85px",
      minWidth: "85px",
      maxWidth: "85px",
    },
    "&:nth-child(6)": {
      minWidth: "110px",
      maxWidth: "110px",
      width: "110px",
    },
    "&:nth-child(7)": {
      width: "175px",
      minWidth: "175px",
      maxWidth: "175px",
    },
    "&:nth-child(8)": {
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
  }
}
