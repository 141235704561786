import { axiosService } from "service"
import { configVariousOperators } from "service/sources"

const list = () => {
  return axiosService.get(configVariousOperators.LIST)
}

const newOperator = (body) => {
  return axiosService.post(configVariousOperators.NEW, body)
}

const editOperator = (id, body) => {
  return axiosService.put(configVariousOperators.EDIT + "/" + id, body)
}

const exportOperator = (id) => {
  return axiosService.get(configVariousOperators.EXPORT_OPERATOR + id)
}

export const operatorsInterface = {
  list,
  newOperator,
  editOperator,
  exportOperator
}
