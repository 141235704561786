import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  home: {
    display: "flex",
    justifyContent: "space-evenly",
    overflowY: "auto",
    overflowX: "hidden",
    height: "579px",
  },
}))

export default useStyles
