import React, { useState } from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import ModalTable from "../Dialog"
import SetState from "Components/Hooks/SetState"
import { interfaces } from "service/interfaces"

const Table = (props) => {
  const { render, setRender } = props

  const windowSize = useMediaQuery("(min-width:1000px)")

  const [values, setValues] = useState({
    filters: [],
    rows: [],
    header: [],
  })

  const { state, setOpen, setClose } = SetState()

  async function apiCall(options, callback) {
    setValues({
      filters: [],
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.transitInterface.listAllTransits(options)
      setValues({
        filters: result.data.filters,
        rows: result.data.transits,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }

  async function transitInfo(id) {
    await interfaces.transitInterface
      .infoFromOneTransit(id)
      .then((res) => setOpen({ data: res.data }))
  }

  const RowsTransit = () =>
    values.rows.map((file) => [
      file.desde_hasta,
      file.nrodev,
      file.dominio,
      file.descripcion,
      file.usuario,
      file.estado,
      "",
      "",
    ])

  let columns = [
    {
      name: " ",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Nº V",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Dominio",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Descripción",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Usuario",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        filterOptions: {
          names: [
            "Tránsito pendiente",
            "Tránsito en curso",
            "Tránsito finalizado",
          ],
        },
        customFilterListOptions: {
          render: (v) => {
            return `Estado: ${v}`
          },
        },
      },
    },
    {
      name: "desde",
      label: "Desde",
      options: {
        display: false,
        filter: true,
        sort: false,
        filterOptions: {
          names: values.filters ? values.filters.map((e) => e.nombre) : null,
        },
        customFilterListOptions: {
          render: (v) => {
            return `Desde: ${v}`
          },
        },
      },
    },
    {
      name: "hasta",
      label: "Hasta",
      options: {
        display: false,
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        filterOptions: {
          names: values.filters ? values.filters.map((e) => e.nombre) : null,
        },
        customFilterListOptions: {
          render: (v) => {
            return `Hasta: ${v}`
          },
        },
      },
    },
  ]

  return (
    <>
      {state.suspense && (
        <ModalTable
          open={state.open}
          onClose={setClose}
          oneTransit={state.data}
          setRender={setRender}
          render={render}
          info={state.data}
        />
      )}

      <MuiDatables
        columns={columns}
        data={RowsTransit()}
        title={`Total de tránsitos: ${values.rows.length}`}
        apiCall={apiCall}
        count={values.header}
        textPlaceholder="Buscar por Nro. de V..."
        render={render}
        setRender={setRender}
        onRowClick={(_, index) => transitInfo(values.rows[index.dataIndex].id)}
        padding={windowSize ? "10px" : ""}
      />
    </>
  )
}
export default Table
