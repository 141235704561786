import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  card: {
    maxWidth: "60em",
    margin: "10px",
  },
  cardHeader: {
    height: "60px",
  },
  media: {
    height: "165px",
  },
  icondelete: {
    color: "#ff7961",
  },
  contentImg: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 10px 10px 0px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0px 10px 0px",
    },
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  divContentImg: {
    position: "static",
    width: "100%",
    height: "100%",
  },
  donwloadIcon: {
    position: "absolute",
    bottom: "10px",
    right: 60,
    zIndex: 1,
    height: " 50px",
    width: "50px",
    border: "none",
    background: theme.palette.primary.main,
    color: "white",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all linear 0.1s",
  },
}))
