import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  weatherColor: {
    backgroundColor: "#3E984C75",
    borderRadius: "15px",
  },
  colorLetter: {
    color: "white",
  },
  currentDay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  heightContentSpinner: {
    height: "305px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  textPrimary: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "left",
  },
  typography: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "22px",
  },
  styleImg: {
    height: "20px",
    margin: "0px",
  },
}))
