import React, { useEffect, useState } from "react"

import DirectionsCarIcon from "@material-ui/icons/DirectionsCar"
import { Grid, Table, TableBody, Typography } from "@material-ui/core"

import { useStyles, StyledTableCell, StyledTableRow } from "./style"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"

const TableVehicles = (props) => {
  const classes = useStyles()
  const { id, handleClose, open } = props
  const [history, setHistory] = useState([])
  const [msj, setMsj] = useState("Buscando resultados")
  useEffect(() => {
    if (id !== null) {
      interfaces.interfaceEngine
        .vehicleEngine(id)
        .then((res) => setHistory(res.data))
        .catch(setMsj("No se encontraron resultados"))
    }
  }, [id])

  return (
    <>
      <DialogComponent
        open={open}
        widthLarge="500px"
        handleclose={handleClose}
        title="Vehículos"
        children={
          <div className={classes.table}>
            <Grid item sm={12}>
              <Table>
                <TableBody>
                  {history.length === 0 ? (
                    <div
                      style={{
                        height: "500px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body1">{msj}</Typography>
                    </div>
                  ) : (
                    <div className={classes.tableHeight}>
                      <Table>
                        <TableBody>
                          {history.map((row) => (
                            <StyledTableRow>
                              <StyledTableCell
                                className={classes.stylesTableCell}
                              >
                                <DirectionsCarIcon />
                                <Typography className={classes.descripcion}>
                                  {row.title ? row.title : null}
                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </div>
        }
      />
    </>
  )
}

export default TableVehicles
