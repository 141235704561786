import React, { useState, forwardRef } from "react"
import { useKeyPressEvent } from "react-use"

import { Stepper, Step, StepLabel } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import {
  FORM_2_STEPPER,
  FORM_VEHICLE,
  FORM_HANDBOOK,
} from "Components/UI/Organisms/Plant/Grill/NewOrder/Fields"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { FORM_CODE } from "Components/UI/Organisms/Plant/Grill/NewOrder/Fields"
import { interfaces } from "service/interfaces"
import { TIPO_PEDIDO } from "Constants"
import SearchNewOrder from "Components/UI/Organisms/Plant/Grill/NewOrder/SearchNewOrder"

import { getNavbar } from "service/helpers/localstorage"

const NewOrder = forwardRef((props) => {
  const { open, handleclose, render, setRender, code } = props

  const createDismissableSnackbar = useCustomSnackbar()
  const steps = ["Datos del pedido", "Datos adicionales"]

  const [tipo, setTipo] = useState(1)
  const [tab, setTab] = useState(0)
  const [dataForm, setDataForm] = useState({})
  const [loading, setLoading] = useState(false)

  const navbarPermissions = JSON.parse(getNavbar()).manual_order

  function _handlePrev() {
    if (tab !== 0) {
      setTab(tab - 1)
    } else {
      handleclose()
    }
  }

  const handleChange = (name, value) => {
    setDataForm({ ...dataForm, [name]: value })
  }

  useKeyPressEvent("Enter", () => (!disableSubmit() ? handleSubmit() : null))

  function disableSubmit() {
    switch (tab) {
      case 0:
        if (tipo === 2 && dataForm.tipo && dataForm.descripcion) return false
        if (dataForm.codigo) return false
        if (dataForm.vehiculo_id && dataForm.pieza) return false
        return true
      case 1:
        if (tipo === 2) return false
        if (
          (!dataForm.cliente_nombre && !dataForm.cliente_telefono) ||
          !dataForm.precio
        )
          return true
        return false
      default:
        break
    }
  }

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            {tipo === 1 && !dataForm.productos && (
              <InputsWithoutValidate
                fields={FORM_CODE(dataForm)}
                spacing={3}
                setFieldValue={handleChange}
              />
            )}
            {tipo === 1 && dataForm.productos && (
              <InputsWithoutValidate
                fields={FORM_VEHICLE(dataForm, handleChange)}
                spacing={3}
                setFieldValue={handleChange}
              />
            )}
            {tipo === 2 && tab === 0 ? (
              <InputsWithoutValidate
                fields={FORM_HANDBOOK(dataForm, handleChange)}
                spacing={3}
                setFieldValue={handleChange}
              />
            ) : null}
          </>
        )
      case 1:
        return (
          <InputsWithoutValidate
            fields={FORM_2_STEPPER(dataForm, handleChange)}
            spacing={3}
            setFieldValue={handleChange}
          />
        )
      default:
        break
    }
  }

  async function handleSubmit() {
    if (tab === 1) {
      setLoading(true)
      await interfaces.plantOrders
        .newOrder(dataForm)
        .then((res) => {
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          setRender(!render)
          handleclose()
          setLoading(false)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
        })
    } else {
      setTab(tab + 1)
    }
  }

  function changeOrderType(value) {
    setDataForm({})
    setTipo(value)
  }

  return (
    <DialogComponent
      open={open}
      widthLarge="500px"
      handleclose={!loading && handleclose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxHeight
      title={"Nuevo pedido"}
      children={
        <>
          <Stepper
            alternativeLabel
            activeStep={tab}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 20,
            }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div style={{ height: "530px" }}>
            {tab === 0 && (
              <CustomAutocomplete
                disableClearable={true}
                name="tipo"
                label="Tipo pedido"
                options={TIPO_PEDIDO}
                defaultValue={TIPO_PEDIDO.find((e) => {
                  return e.value === tipo
                })}
                getOptionLabel={(TIPO_PEDIDO) => TIPO_PEDIDO.tipo}
                getOptionDisabled={(TIPO_PEDIDO) => {
                  return navbarPermissions
                    ? false
                    : TIPO_PEDIDO.tipo === "MANUAL"
                }}
                onChange={(_, value) => {
                  changeOrderType(value.value)
                }}
              />
            )}
            {tipo === 1 && <br />}
            {tab === 0 && tipo === 1 && (
              <SearchNewOrder
                setDataForm={setDataForm}
                code={code}
                handleclose={handleclose}
              />
            )}
            <br />
            {renderTabs(tab)}
          </div>
        </>
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={_handlePrev}
          submitText={tab === 1 ? "Confirmar" : "Siguiente"}
          cancelText={tab === 0 ? "Cancelar" : "Atrás"}
          disabledSubmit={loading || disableSubmit()}
          disabledCancel={loading}
          isLoading={loading}
        />
      }
    />
  )
})
export default NewOrder
