import React, { useEffect } from "react"

import UsersTemplate from "Components/UI/Templates/Settings/Users"

const Users = () => {
  useEffect(() => {
    document.title = "Usuarios"
  }, [])

  return <UsersTemplate />
}

export default Users
