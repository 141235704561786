import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

import { interfaces } from "service/interfaces"
import { CITIES, PROVINCIAS } from "Constants"
import { createSupplier } from "Components/Validation/Sales/Suppliers"
import { Button } from "@material-ui/core"
import CreateAddress from "Components/UI/Organisms/Settings/Various/ProvinciesLocalitiesAddress/Content/Address/Create"
import useStyles from "../styles"

const Create = (props) => {
  const { data, open, handleClose, render, setRender } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)
  
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [openCreateAddress, setOpenCreateAddress] = useState(false)

  const create = async (values) => {
    setLoading(true)
    await interfaces.salesSuppliers
      .create(values)
      .then((res) => {
        setRender(!render)
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Error", {
          variant: "error",
        })
      })
    setLoading(false)
    handleClose()
  }

  const fields = (values) => {
    const provinciaId = values.provincia_id
    const filteredCities = CITIES.filter(city => city.provincia_id === provinciaId)
      .sort((a, b) => a.localidad.localeCompare(b.localidad))
      .map(city => ({
        ...city,
        displayName: `${city.localidad} (CP: ${city.cp})`
      }))
    
    return [
      {
        label: "Razón social",
        name: "proveedor",
        sm: 12,
        xs: 12,
        class: "textfield",
      },
      {
        id: 1,
        label: "Denominación",
        name: "denominacion",
        sm: 12,
        xs: 12,
        class: "textfield",
      },
      {
        label: "CUIT/CUIL/DNI",
        name: "cuit",
        sm: 12,
        xs: 12,
        class: "textfield",
        type: "number"
      },
      {
        label: "Tipo responsable",
        name: "tipo_responsable",
        options: data.tipo_responsable,
        optionLabel: `options["name"]`,
        optionValue: "value",
        sm: 12,
        xs: 12,
        class: "autocomplete"
      },
      {
        label: "Provincia",
        name: "provincia_id",
        options: PROVINCIAS,
        optionLabel: `options["provincia"]`,
        optionValue: "id",
        sm: 12,
        xs: 12,
        class: "autocomplete",
      },
      {
        label: "Localidad",
        name: "localidad_id",
        options: filteredCities,
        optionLabel: `options["displayName"]`,
        optionValue: "id",
        sm: 12,
        xs: 12,
        class: "autocomplete",
      },
      {
        label: "Dirección",
        name: "direccion_id",
        options: data.address,
        optionLabel: `options["calle"]`,
        optionValue: "id",
        sm: 6,
        xs: 12,
        class: "autocomplete",
      },
      {
        label: "km",
        name: "km",
        sm: 3,
        xs: 12,
        class: "textfield",
        type: "number",
        disabled: values.direccion_id === null
      },
      {
        label: "Altura",
        name: "altura",
        sm: 3,
        xs: 12,
        class: "textfield",
        type: "number",
        disabled: values.direccion_id === null
      },
    ]
  }

  const children = (
    <Formik
      onSubmit={create}
      initialValues={{
        proveedor: "",
        denominacion: "",
        cuit: null,
        tipo_responsable: null,
        provincia_id: null,
        localidad_id: null,
        direccion_id: null,
        observacion: "",
        estado: 1,
        km: null,
        altura: null
      }}
      validationSchema={createSupplier}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setFieldValue, isSubmitting, values }) => (
        <Form>
          <InputsWithValidate
            fields={fields(values)}
            spacing={2}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
          />
          <button
            style={{ display: "none" }}
            type="submit"
            ref={formSubmitRef}
          />
          <Button
            onClick={() => setOpenCreateAddress(true)}
            disabled={loading}
            color="primary"
            variant="outlined"
            className={classes.button}
          >
            Agregar dirección
          </Button>
        </Form>
      )}
    </Formik>
  )

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        title="Nuevo proveedor"
        maxHeight
        widthLarge="500px"
        children={children}
        actions={
          <CancelConfirmButtons
            submitText={"Confirmar"}
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
          />
        }
      />
      {openCreateAddress && (
        <CreateAddress
          open={openCreateAddress}
          handleClose={() => setOpenCreateAddress(false)}
          changeRender={() => setRender(!render)}
        />
      )}
    </>
  )
}

export default Create
