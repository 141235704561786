import * as yup from "yup"

export const brand = yup.object().shape({
  marca: yup.string().required("Este campo es requerido"),
})

export const model = yup.object().shape({
  modelo: yup.string().required("Este campo es requerido"),
})

export const type = yup.object().shape({
  nombre: yup.string().required("Este campo es requerido"),
})

export const versionDate = yup.object().shape({
  version: yup
    .string()
    .required("Este campo es requerido")
    .typeError("Año incorrecto"),
  inicio: yup
    .date()
    .nullable()
    .required("Este campo es requerido")
    .typeError("Año incorrecto"),
  fin: yup
    .date()
    .nullable()
    .required("Este campo es requerido")
    .min(yup.ref("inicio"), "Rango incorrecto")
    .typeError("Año incorrecto"),
})

export const editVersion = yup.object().shape({
  inicio: yup.date().nullable().typeError("Año incorrecto"),
  fin: yup
    .date()
    .nullable()
    .required("Este campo es requerido")
    .min(yup.ref("inicio"), "Rango incorrecto")
    .typeError("Año incorrecto"),
})

