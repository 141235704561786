import React, { useState } from "react"

import ExitToApp from "@material-ui/icons/ExitToApp"

import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import HomeContent from "Components/UI/Organisms/Plant/Used/Dialog/Content/Home"
import Files from "Components/UI/Organisms/Plant/Used/Dialog/Content/Files"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { getUbicacion } from "service/helpers/localstorage"

import SetState from "Components/Hooks/SetState"
import ComplexDialogHistory from "Components/UI/Molecules/ComplexDialogHistory"
import SubPiezas from "../../Classification/DialogMoreInfo/Tabs/SubPiezas"

const UsedDialog = (props) => {
  const { open, handleclose, data, setRender, render, handleRender } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose, setLoading } = SetState()

  const location = getUbicacion().includes("ECO") ? 1 : 2

  const [dataInfo, setDataInfo] = useState({
    ...data,
    data: {
      ...data.data,
      almacen_empresa: location,
    },
  })
  const setImg = (img) => {
    setDataInfo({
      ...data,
      data: {
        ...data.data,
        fotos: img,
      },
    })
  }

  const handleSubmit = async () => {
    await interfaces.plantUsed
      .update(data.data.id, dataInfo.data)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
        if (handleRender) {
          handleRender()
        }
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
  }

  const handleDelete = async () => {
    setLoading(true)
    await interfaces.plantUsed
      .deleteUsed(data.data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setClose()
        handleclose()
        setRender(!render)
        if (handleRender) {
          handleRender()
        }
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const horizontalTabs = [
    {
      id: 0,
      title: "Inicio",
      color: "primary",
    },

    {
      id: 1,
      title: "Archivos",
      color: "primary",
    },
    {
      id: 2,
      title: "Subpiezas",
      disabled: dataInfo.data.subpiezas.length === 0,
    },
    {
      id: 3,
      title: "Historial",
      color: "primary",
      disabled: data.historial.length === 0,
    },
  ]

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <HomeContent
            data={dataInfo}
            setData={setDataInfo}
            field={dataInfo.fields}
          />
        )
      case 1:
        return (
          <Files
            img={dataInfo.data.fotos}
            setImg={setImg}
            file={dataInfo.data.archivos}
            id={dataInfo.data.id}
            data={dataInfo}
          />
        )
      case 2:
        return <SubPiezas productos={dataInfo.data.subpiezas} />
      case 3:
        return <ComplexDialogHistory history={data.historial} />
      default:
        break
    }
  }

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleclose}
        tabs={horizontalTabs}
        title={`${dataInfo.data.producto} ${dataInfo.data.marca} ${dataInfo.data.modelo}`}
        subtitle={
          <div>
            <b>
              {dataInfo.data.tipo[0]}
              {dataInfo.data.id}
            </b>{" "}
            - {dataInfo.data.creado} por {dataInfo.data.usuario}
          </div>
        }
        children={renderTabs}
        actions={
          <ComplexDialogActions
            onClose={handleclose}
            handleSubmit={() => handleSubmit()}
            canEdit={!dataInfo.canEdit}
            data={dataInfo}
            editMode={true}
            leftButton={{
              icon: <ExitToApp fontSize="small" />,
              title: "Eliminar",
              variant: "contained",
              disabled: data.data.estado_id !== 6 ? true : false,
              style:
                data.data.estado_id === 6
                  ? { background: "rgb(244, 67, 54)", color: "#fff" }
                  : { background: "primary" },
              onClick: () => {
                setOpen()
              },
            }}
          />
        }
      />

      {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={state.loading ? null : setClose}
          widthLarge={500}
          title="¿Desea eliminar la siguiente pieza?"
          subtitle="¡Esta acción es irreversible!"
          disableChildHeight
          disableBackdropClick
          disableEscapeKeyDown
          actions={
            <CancelConfirmButtons
              onClickSubmit={handleDelete}
              disabledCancel={state.loading}
              disabledSubmit={state.loading}
              isLoading={state.loading}
            />
          }
        />
      )}
    </>
  )
}

export default UsedDialog
