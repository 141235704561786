import React, { useEffect } from "react"

import WarehousesTemplate from "Components/UI/Templates/Plant/Storages"

const Storages = () => {
  useEffect(() => {
    document.title = "Almacenes"
  }, [])

  return <WarehousesTemplate />
}
export default Storages
