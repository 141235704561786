import { TextField } from "@material-ui/core"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import React from "react"

function DialogCheckbox(props) {
  const { handleClose, start, values, handleChangeMsj, data } = props

  return (
    <DialogComponent
      handleclose={handleClose}
      open={start.open}
      title={start.open && data.check[start.index].detalles}
      children={
        <TextField
          variant="outlined"
          fullWidth
          name="items_observacion"
          onChange={(e) => handleChangeMsj(e, start.index)}
          disabled={false}
          label="Observaciones"
          value={values[start.index]}
        />
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={() => handleClose()}
          onClickSubmit={() => handleClose()}
        />
      }
    />
  )
}

export default DialogCheckbox
