import { CITIES } from "Constants"
import { PROVINCIAS } from "Constants"

export const FORM_HOME = (data, handleChange, edit, dataBefore) => {
    const provinciaId = data.provincia_id
    const filteredCities = CITIES
      .filter(city => city.provincia_id === provinciaId)
      .sort((a, b) => a.localidad.localeCompare(b.localidad))
      .map(city => ({
        ...city,
        displayName: `${city.localidad} (CP: ${city.cp})`
      }))
   
    return [
      {
        class: "textfield",
        name: "proveedor",
        label: "Razón social",
        value: data.proveedor,
        disabled: !edit,
        sm: 6,
        xs: 12,
        onChange: (e) => {
            handleChange("proveedor", e.target.value);
        }
      },
      {
        class: "textfield",
        name: "denominacion",
        label: "Denominación",
        value: data.denominacion,
        disabled: !edit,
        sm: 6,
        xs: 12,
        onChange: (e) => {
            handleChange("denominacion", e.target.value);
          },
      },
      {
        class: "textfield",
        type: "number",
        name: "cuit",
        label: "CUIT/CUIL/DNI",
        value: data.cuit,
        sm: 6,
        xs: 12,
        disabled: !edit,
        onChange: (e) => {
          handleChange("cuit", e.target.value);
        }
      },
      {
        class: "autocomplete",
        sm: 6,
        xs: 12,
        name: "tipo_responsable",
        label: "Tipo responsable",
        optionValue: "value",
        options: dataBefore.data.tipo_responsable,
        optionLabel: `options["name"]`,
        value: data.tipo_responsable,
        disabled: !edit,
        onChange: (e) =>
        handleChange("tipo_responsable", e.value)
      },
      {
        class: "autocomplete",
        sm: 6,
        xs: 12,
        name: "provincia_id",
        label: "Provincia",
        optionValue: "id",
        options: PROVINCIAS,
        optionLabel: `options["provincia"]`,
        value: data.provincia_id,
        disabled: !edit,
        onChange: (e) =>
          handleChange("provincia_id", e.id)
      },
      {
        class: "autocomplete",
        sm: 6,
        xs: 12,
        name: "localidad_id",
        label: "Localidad",
        optionValue: "id",
        options: filteredCities,
        optionLabel: `options["displayName"]`,
        value: data.localidad_id,
        disabled: !edit,
        onChange: (e) =>
          handleChange("localidad_id", e.id)
      },
      {
        class: "autocomplete",
        sm: 6,
        xs: 12,
        name: "direccion_id",
        label: "Dirección",
        optionValue: "id",
        options: dataBefore.data.address,
        optionLabel: `options["calle"]`,
        value: data.direccion_id,
        disabled: !edit,
        onChange: (e) =>
          handleChange("direccion_id", e.id)
      },
      {
        class: "textfield",
        type: "number",
        name: "km",
        label: "km",
        value: data.km,
        sm: 3,
        xs: 12,
        disabled: !edit,
        onChange: (e) => {
          handleChange("km", e.target.value);
        }
      },
      {
        class: "textfield",
        type: "number",
        name: "altura",
        label: "Altura",
        value: data.altura,
        sm: 3,
        xs: 12,
        disabled: !edit,
        onChange: (e) => {
          handleChange("altura", e.target.value);
        }
      },
      {
        class: "textfield",
        name: "observacion",
        label: "Observación",
        value: data.observacion,
        disabled: !edit,
        sm: 12,
        xs: 12,
        onChange: (e) => {
            handleChange("observacion", e.target.value);
          },
      }
    ]
   
  }