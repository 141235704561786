import React from "react"

import Papers from "Components/UI/Molecules/Papers/PaperList"

import NavTabs from "Components/UI/Organisms/Settings/Various/Transport/TransportContent/ContentItemList/NavTabs"

const ContentItemList = (props) => {
  const { data, index, idSelected, changeRender, setCanClose } = props
  return (
    <>
      <Papers
        height="600px"
        children={
          <NavTabs
            data={data}
            index={index}
            idSelected={idSelected}
            changeRender={changeRender}
            setCanClose={setCanClose}
          />
        }
      />
    </>
  )
}

export default ContentItemList
