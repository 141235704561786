import React from "react";

import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox";
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent";
import ContentType from "Components/UI/Organisms/Settings/Various/TypeVehicle/Content";
import SetState from "Components/Hooks/SetState";

const TypeVehicle = () => {
  const { state, setOpen, setClose } = SetState()

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Tipos de vehículos"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/vehicle-type-1.png"}
      />
      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        widthLarge="25vw"
        title="Tipos de vehículos"
        open={state.open}
        handleclose={setClose}
        maxHeight
        children={<ContentType />}
      />
    </>
  );
};

export default TypeVehicle;
