import React, { useRef } from "react"
import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import CircularProgress from "@material-ui/core/CircularProgress"
import { InputAdornment } from "@material-ui/core"

import useStyles from "./styles"

export default function InputButton(props) {
  const classes = useStyles()
  const {
    onSubmit,
    name,
    label,
    validation,
    disabled,
    onKeyUp,
    value,
    resetFormDisabled,
    noHeight,
    loading,
  } = props
  const refInput = useRef(null)

  function handleRef() {
    refInput.current.click()
  }

  function IconAdornement() {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={() => handleRef()}
            disabled={disabled}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }
  // const IconAdornement = () => (
  //   <InputAdornment position="end" className={classes.endAdornment}>
  //     <Divider className={classes.divider} orientation="vertical" />
  //     <IconButton
  //       color="primary"
  //       className={classes.iconButton}
  //       onClick={() => handleRef()}
  //       disabled={disabled}
  //     >
  //       <ArrowRightAltIcon color="primary" />
  //     </IconButton>
  //   </InputAdornment>
  // )

  return (
    <>
      <Formik
        validateOnChange
        initialValues={{
          [name]: "",
        }}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values)
          if (!resetFormDisabled) {
            resetForm({})
          }
        }}
        validationSchema={validation}
        className={classes.root}
      >
        {() => (
          <Form className={classes.fullWidth}>
            <Field
              component={TextField}
              label={label}
              name={name}
              fullWidth
              onKeyUp={onKeyUp}
              defaultValue={value}
              disabled={false}
              className={noHeight ? null : classes.input}
              variant="outlined"
              placeholder={props.placeholder}
              InputProps={{
                endAdornment: <IconAdornement />,
                shrink: true,
              }}
            />

            <button style={{ display: "none" }} ref={refInput} type="submit" />
          </Form>
        )}
      </Formik>
    </>
  )
}
