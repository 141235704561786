import React, { useState, useContext } from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { FORM_HOME } from "Components/UI/Organisms/Sales/Suppliers/Update/Home/Fields"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"
import { EditContext } from "Components/Hooks/ContextEdit"
import CustomButton from "Components/UI/Atoms/CustomButton"
import useStyles from "Components/UI/Organisms/Vehicles/Quotations/Edit/Fields/Home/styles"
import Contact from "../Contact"

const HomeContent = (props) => {
  const { data, setData, toggleStatus, dataBefore, onDataChange, setRender, render } = props
  const [edit] = useContext(EditContext)

  const [tab, setTab] = useState(0)

  const handleChange = (name, value) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [name]: value,
      },
    })
  }
  const classes = useStyles()

  function renderTabs(index) {
    switch (index) {
      case 0:
        return (
        <>
          <InputsWithoutValidate
            fields={FORM_HOME(data.data, handleChange, edit, dataBefore)}
            spacing={2}
          />
          
          <div className={classes.buttons}>
            <CustomButton
              buttonEnd
              style={{
                marginLeft: "1.5rem",
                backgroundColor: data.info.estado === 1? "#FF8B3A" : "#4BB86A",
              }}              
              text={data.info.estado === 1 ? "Desactivar" : "Activar"}
              onClick={(e) => toggleStatus(e, data.info.id)}
            />  
          </div>
        </>
        )
      case 1:
        return (
            <Contact
             data={data}
             onDataChange={onDataChange}
             setRender={setRender}
             render={render}
          />
        )
      default:
        break
    }
    
  }

  const verticalTabs = [
    {
      id: 0,
      title: "Información general",
    },
    {
      id: 1,
      title: "Datos de contacto",
    }
  ]

  return (
    <div>
      <ContentWithSubTabs
        listVerticalTabs={verticalTabs}
        value={tab}
        setValue={setTab}
        children={<div style={{ margin: "20px" }}>{renderTabs(tab)}</div>}
      />
    </div>
  )
}

export default HomeContent