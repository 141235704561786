import React, { useState, useEffect } from "react"
import { format } from "date-fns"
import { es } from "date-fns/locale"
import axios from "axios"

import { Grid, Box, Typography } from "@material-ui/core"
import { Divider } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import styles from "../styles"
import { useLocation } from "react-router-dom"
import { getUbicacion } from "service/helpers/localstorage"

const Weather = () => {
  const [clima, setClima] = useState([])

  const [loading, setLoading] = useState(true)

  const location = getUbicacion() || ""

  const { pathname } = useLocation()

  useEffect(() => {
    const getWeather = async () => {
      setLoading(true)
      const res = await axios(locationApi())

      setClima(res.data)
      setTimeout(() => setLoading(false), 1000)
    }
    getWeather()
    // eslint-disable-next-line
  }, [])

  const day = new Date()
  const nextDay = new Date(day)
  const day2 = nextDay.setDate(nextDay.getDate() + 1)
  const day3 = nextDay.setDate(nextDay.getDate() + 1)

  const classes = styles()

  const locationWheater = () => {
    if ((pathname && pathname.includes("oem")) || location.includes("OEM"))
      return "Villa Mercedes, San Luis"
    return "Río Cuarto, Córdoba:"
  }

  const locationApi = () => {
    if ((pathname && pathname.includes("oem")) || location.includes("OEM"))
      return "https://api.openweathermap.org/data/2.5/onecall?lat=-33.1243872&lon=-64.4122296&units=metric&appid=91263c25ba91760faeb8f37b7d323348&lang=es&exclude=minutely,hourly,alerts"
    return "https://api.openweathermap.org/data/2.5/onecall?lat=-33.1243872&lon=-64.4122296&units=metric&appid=91263c25ba91760faeb8f37b7d323348&lang=es&exclude=minutely,hourly,alerts"
  }
  return (
    <div>
      <>
        {loading ? (
          <div className={classes.heightContentSpinner}>
            <CircularProgress size={70} color="primary" />
          </div>
        ) : (
          <Grid container>
            <Grid item sm={8} xs={8} className={classes.currentDay}>
              <Typography
                align="center"
                variant="h6"
                style={{ textTransform: "capitalize" }}
              >
                {format(day, "eeee, d 'de' MMMM", { locale: es })}
              </Typography>
              <Box fontSize="fontSize" m={1} fontFamily="fontFamily">
                {locationWheater()}
              </Box>
              <img
                className={classes.iconSize}
                alt="#"
                src={`http://openweathermap.org/img/wn/${clima.current.weather[0].icon}@2x.png`}
              />
              <Typography style={{ textTransform: "capitalize" }} variant="h6">
                {clima.current.weather[0].description}
              </Typography>

              <Box
                m={1}
                fontFamily="fontFamily"
                fontSize="h4.fontSize"
                fontWeight="fontWeightBold"
              >
                {Math.round(clima.current.temp)}
                {"°C"}
              </Box>
            </Grid>

            <Grid item sm={4} xs={4} color="primary">
              <div className={classes.weatherColor}>
                <Typography
                  align="center"
                  className={classes.colorLetter}
                  variant="body1"
                >
                  {format(day2, "d MMMM", { locale: es })}
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="#"
                    src={`http://openweathermap.org/img/wn/${clima.daily[1].weather[0].icon}@2x.png`}
                  />
                </div>
                <Typography align="center" className={classes.colorLetter}>
                  {Math.round(clima.daily[1].temp.min)} /{" "}
                  {Math.round(clima.daily[1].temp.max)}
                  {"°C"}
                </Typography>
                <Divider style={{ background: "white", height: "1px" }} />
                <Typography align="center" className={classes.colorLetter}>
                  {format(day3, "d MMMM", { locale: es })}
                </Typography>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="#"
                    src={`http://openweathermap.org/img/wn/${clima.daily[2].weather[0].icon}@2x.png`}
                  />
                </div>
                <Typography align="center" className={classes.colorLetter}>
                  {Math.round(clima.daily[2].temp.min)} /{" "}
                  {Math.round(clima.daily[2].temp.max)}
                  {"°C"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        )}
      </>
    </div>
  )
}

export default Weather
