import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}))

export default useStyles
