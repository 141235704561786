import React, { useState } from "react"
import { useLocation } from "react-router-dom"

import GetAppIcon from "@material-ui/icons/GetApp"
import AddIcon from "@material-ui/icons/Add"
import PaymentIcon from "@material-ui/icons/Payment"

import NavBarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import TableSearchVehicles from "Components/UI/Organisms/Vehicles/SearchVehicles/Table/index"
import New from "Components/UI/Organisms/Vehicles/SearchVehicles/New"
import Export from "Components/UI/Organisms/Vehicles/SearchVehicles/Export"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import Payments from "Components/UI/Organisms/Vehicles/SearchVehicles/Payments"
import { oemColorButton } from "Helpers"

const TemplateSearchVehicles = () => {
  const {
    state: payments,
    setOpen: openPayments,
    setClose: closePayments,
  } = SetState()
  const { state: add, setOpen: openAdd, setClose: closeAdd } = SetState()
  const {
    state: download,
    setOpen: openDownload,
    setClose: closeDownload,
  } = SetState()

  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const [render, setRender] = useState(true)
  const [activeButtons, setActiveButtons] = useState({})
  const BUTTONS = [
    {
      menuIcon: <PaymentIcon fontSize="small" />,
      menuTitle: "Pagos",
      buttonContent: "Pagos",
      onClick: openPayments,
      disabled: !activeButtons.payments,
    },
    {
      menuIcon: <GetAppIcon fontSize="small" />,
      buttonContent: <GetAppIcon />,
      menuTitle: "Exportar",
      onClick: openDownload,
      disabled: !activeButtons.export,
    },
    {
      menuIcon: <AddIcon fontSize="small" />,
      buttonStartIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      onClick: openAdd,
      buttonContent: "Nuevo",
      buttonStyle: activeButtons.create
        ? oemColorButton(pathname, location)
        : {},
      disabled: !activeButtons.create,
    },
  ]

  return (
    <>
      <Template
        navbar={
          <NavBarGlobal
            buttons={BUTTONS}
            title="Vehículos"
            subTitle="Listado de vehículos"
          />
        }
        table={
          <TableSearchVehicles
            render={render}
            setRender={setRender}
            setActiveButtons={setActiveButtons}
          />
        }
      />

      {payments.suspense && (
        <Payments
          open={payments.open}
          close={closePayments}
          setRender={setRender}
          render={render}
        />
      )}

      {add.suspense && (
        <New
          open={add.open}
          handleClose={closeAdd}
          render={render}
          setRender={setRender}
        />
      )}
      {download.suspense && (
        <Export open={download.open} close={closeDownload} />
      )}
    </>
  )
}

export default TemplateSearchVehicles
