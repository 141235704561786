import {
  UBICACIONES_VACIO,
  PRODUCTOS,
  UBICACIONES_MANUAL,
  URGENCIA,
  MANUAL,
} from "Constants"
import { getUbicacion } from "service/helpers/localstorage"

function _location() {
  let location = null
  const ubicacion = getUbicacion()
  // eslint-disable-next-line
  UBICACIONES_MANUAL.filter((ubi) => {
    if (ubi.nombre === ubicacion) {
      return (location = ubi.id)
    }
  })
  return location
}

export const FORM_CODE = (dataForm) => {
  let fields = [
    {
      class: "textfield",
      name: "pieza",
      label: "Producto",
      sm: 12,
      xs: 12,
      disabled: true,
      value: dataForm.pieza,
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      sm: 12,
      xs: 12,
      disabled: true,
      value: dataForm.vehiculo,
    },
    {
      class: "autocomplete",
      name: "desde",
      label: "Desde",
      sm: 6,
      xs: 12,
      value: dataForm.desde ? dataForm.desde : 0,
      options: dataForm.ubicaciones ? dataForm.ubicaciones : UBICACIONES_VACIO,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: dataForm.ubicaciones ? false : true,
    },
    {
      class: "autocomplete",
      name: "hasta",
      label: "Hasta",
      sm: 6,
      xs: 12,
      value: dataForm.hasta ? dataForm.hasta : 0,
      options: dataForm.ubicaciones ? dataForm.ubicaciones : UBICACIONES_VACIO,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: dataForm.ubicaciones ? false : true,
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      type: "text",
      sm: 6,
      xs: 12,
      value: dataForm.estado,
      disabled: true,
    },
    {
      class: "autocomplete",
      name: "preparacion",
      label: "Taller preparación (OEM)",
      sm: 6,
      xs: 12,
      value: dataForm.preparacion ? dataForm.preparacion : 0,
      disabled: dataForm.empresa === 2 && dataForm.desde === 5 ? false : true,
      options: [
        { id: 0, label: "NO" },
        { id: 1, label: "Planta R7" },
        { id: 2, label: "Planta RC" },
      ],
      optionLabel: `options["label"]`,
      optionValue: "id",
    },
  ]
  return fields
}

export const FORM_VEHICLE = (data, handleChange) => {
  return [
    {
      class: "autocomplete",
      name: "pieza",
      label: "Producto",
      sm: 12,
      xs: 12,
      value: data.pieza,
      options: data.productos ? data.productos : PRODUCTOS,
      optionLabel: `options["producto"]`,
      optionValue: "producto",
      disabled: data.productos ? false : true,
      freeSolo: true,
      onInputChange: (e) => {
        handleChange("pieza", e)
      },
      autoHighlight: true,
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehículo",
      sm: 12,
      xs: 12,
      disabled: true,
      value: data.vehiculo,
    },

    {
      class: "autocomplete",
      name: "desde",
      label: "Desde",
      sm: 6,
      xs: 12,
      value: data.desde ? data.desde : 0,
      options: data.ubicaciones ? data.ubicaciones : UBICACIONES_VACIO,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: data.ubicaciones ? false : true,
    },
    {
      class: "autocomplete",
      name: "hasta",
      label: "Hasta",
      sm: 6,
      xs: 12,
      value: data.hasta ? data.hasta : 0,
      options: data.ubicaciones ? data.ubicaciones : UBICACIONES_VACIO,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: data.ubicaciones ? false : true,
    },
    {
      class: "textfield",
      name: "estado",
      label: "Estado",
      type: "text",
      sm: 12,
      xs: 12,
      value: data.estado,
      disabled: true,
    },
  ]
}

export const FORM_HANDBOOK = (data, handleChange) => {
  return [
    {
      class: "autocomplete",
      name: "tipo",
      label: "Tipo",
      value: data.tipo,
      options: MANUAL,
      optionLabel: `options["tipo"]`,
      optionValue: "tipo",
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "desde",
      label: "Desde",
      sm: 6,
      xs: 12,
      value: data.desde ? data.desde : _location(),
      options: UBICACIONES_MANUAL.filter((e) => e.id < 6),
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: UBICACIONES_MANUAL ? false : true,
    },
    {
      class: "autocomplete",
      name: "hasta",
      label: "Hasta",
      sm: 6,
      xs: 12,
      value: data.hasta ? data.hasta : _location(),
      options: UBICACIONES_MANUAL.filter((e) => e.id < 6),
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      disabled: UBICACIONES_MANUAL ? false : true,
    },
    {
      class: "textfield",
      name: "descripcion",
      label: "Descripción",
      value: data.descripcion,
      sm: 12,
      xs: 12,
      onChange: (e) => handleChange("descripcion", e.target.value),
    },
  ]
}

export const FORM_2_STEPPER = (data, onChange) => {
  return [
    {
      class: "autocomplete",
      name: "urgencia",
      label: "Urgencia",
      sm: 12,
      xs: 12,
      value: data.urgencia || 3,
      options: URGENCIA,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
    },
    {
      class: "textfield",
      name: "cliente_nombre",
      label: "Nombre del cliente",
      sm: 12,
      xs: 12,
      value: data.cliente_nombre,
      onChange: (e) => onChange("cliente_nombre", e.target.value),
    },
    {
      class: "textfield",
      name: "cliente_telefono",
      label: "Número de teléfono",
      type: "number",
      sm: 12,
      xs: 12,
      value: data.cliente_telefono,
      onChange: (e) => onChange("cliente_telefono", e.target.value),
    },
    {
      class: "textfield",
      name: "precio",
      label: "Importe",
      type: "number",
      sm: 12,
      xs: 12,
      value: data.precio,
      onChange: (e) => onChange("precio", e.target.value),
    },
    {
      class: "textfield",
      name: "observacion_ventas",
      label: "Observaciones para ventas",
      type: "text",
      sm: 6,
      xs: 12,
      value: data.observacion_ventas,
      multiline: true,
      rows: 4,
      maxRows: 4,
      onChange: (e) => onChange("observacion_ventas", e.target.value),
    },
    {
      class: "textfield",
      name: "observacion_planta",
      label: "Observaciones para planta",
      type: "text",
      sm: 6,
      xs: 12,
      value: data.observacion_planta,
      multiline: true,
      rows: 4,
      maxRows: 4,
      onChange: (e) => onChange("observacion_planta", e.target.value),
    },
  ]
}
