export function columnTheme() {
  return {
    "&:nth-child(2)": {
      paddingLeft: "10px",
      width: "105px",
      minWidth: "105px",
      maxWidth: "105px",
    },
    "&:nth-child(3)": {
      width: "350px",
      minWidth: "250px",
    },
    "&:nth-child(4)": {
      width: "350px",
      minWidth: "220px",
    },
    "&:nth-child(5)": {
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(6)": {
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(7)": {
      width: "70px",
      minWidth: "70px",
      maxWidth: "70px",
    },
    "&:nth-child(8)": {
      width: "100px",
      minWidth: "100px",
      maxWidth: "100px",
    },
    "&:nth-child(9)": {
      width: "120px",
      minWidth: "120px",
      maxWidth: "120px",
    },
    "&:nth-child(10)": {
      width: "50px",
      minWidth: "50px",
      maxWidth: "50px",
    },
    "&:nth-child(11)": {
      width: "100px",
    },
  }
}
