import React, { useState, useEffect } from "react"

import { Grid } from "@material-ui/core/"

import CardEcosoft from "Components/UI/Organisms/Ecosoft/Card"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { headerEcosoft } from "Components/UI/Organisms/Ecosoft/Body/Fields"
import MoreFilters from "Components/UI/Organisms/Ecosoft/Body/MoreFilters"

import InfiniteScroll from "react-infinite-scroll-component"
import { useEffectOnce, useWindowSize } from "react-use"
import { interfaces } from "service/interfaces"
import useStyles from "./styles"

const ContentEcosoft = (props) => {
  const { count, setCount } = props

  const classes = useStyles()

  const { width } = useWindowSize()

  const [anchorEl, setAnchorEl] = useState(null)
  const [columns, setColumns] = useState(12)
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({
    models_brands: [],
    versionsMapped: [],
    filterList: [{}],
  })
  const [photoIndex, setPhotoIndex] = useState(0)
  const [valueFilter, setValueFilter] = useState({})
  const [search, setSearch] = useState("")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const tableWidth = width - 275
  const widthHeader = width

  useEffect(() => {
    const minWidth = tableWidth / 370
    setColumns(Math.trunc(12 / Math.trunc(minWidth)))
  }, [tableWidth])

  useEffectOnce(async () => {
    const filtersResponse = await interfaces.ecoSoft.filters()
    const listResponse = await interfaces.ecoSoft.list("?limit=25&offset=0&")
    setFilters({
      ...filters,
      models_brands: filtersResponse.data.models_brands,
      versionsMapped: filtersResponse.data.versionsMapped,
      filterList: listResponse.data.filterList,
    })
  })

  const initialFilters = {
    estado_registral: "Finalizado",
    bien_de_uso: "NO",
    vehiculo_tipo: valueFilter.vehiculo_tipo ? valueFilter.vehiculo_tipo : "",
    marca_modelo: valueFilter.marca_modelo ? valueFilter.marca_modelo : "",
  }
  useEffect(() => {
    if (
      (Object.keys(valueFilter).length === 0 ||
        valueFilter === initialFilters) &&
      search === ""
    ) {
      initialPosts(initialFilters)
      setValueFilter(initialFilters)
    } else initialPosts(valueFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFilter])

  const fetchPosts = () => {
    const filtersParams = new URLSearchParams(valueFilter).toString()
    const option = `?limit=25&offset=${page}&${filtersParams}&search=${search}`
    interfaces.ecoSoft.list(option).then((res) => {
      const result = res.data.rows
      setData([...data, ...result])

      setPage((prev) => prev + 1)
    })
  }

  const initialPosts = (initialFilters) => {
    const filtersParams = new URLSearchParams(initialFilters).toString()
    const option = `?limit=25&offset=0&${filtersParams}&search=${search}`
    interfaces.ecoSoft.list(option).then((res) => {
      const result = res.data.rows
      setData(result)
      setCount(res.header.header.count)
      setPage(1)
    })
  }

  const handleChange = (name, value) => {
    if (name === "marca_modelo") {
      setValueFilter({
        ...valueFilter,
        [name]: value,
        version: "",
      })
    } else {
      setValueFilter({
        ...valueFilter,
        [name]: value,
      })
    }
  }

  const handleChangeSearch = (value) => {
    setSearch(value)
  }

  const handleChangePhoto = (value) => {
    setPhotoIndex(value)
  }

  const clearFilters = () => {
    setAnchorEl(null)
    setValueFilter(initialFilters)
  }

  return (
    <>
      <div className={widthHeader < 957 ? null : classes.headerFixed}>
        <InputsWithoutValidate
          fields={headerEcosoft(
            initialPosts,
            handleChange,
            handleChangePhoto,
            handleChangeSearch,
            filters,
            handleClick,
            valueFilter,
            search
          )}
          spacing={2}
        />
      </div>
      <div id="scrollableDiv">
        <InfiniteScroll
          dataLength={data.length}
          next={() => fetchPosts()}
          hasMore={count > 25 && data.length < 100 ? true : false}
          style={{ overflow: "hidden" }}
        >
          <Grid
            container
            spacing={2}
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={
              widthHeader < 957
                ? { marginTop: "10px", marginBottom: "10px" }
                : { marginTop: "60px", marginBottom: "10px" }
            }
          >
            {data.length > 0 &&
              data.map((item) => {
                return (
                  <Grid item xs={columns === Infinity ? 12 : columns}>
                    <CardEcosoft item={item} photoIndex={photoIndex} />
                  </Grid>
                )
              })}
          </Grid>
        </InfiniteScroll>
      </div>
      <MoreFilters
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleChange={handleChange}
        valueFilter={valueFilter}
        clearFilters={clearFilters}
        versionFilter={filters.versionsMapped}
      />
    </>
  )
}

export default ContentEcosoft
