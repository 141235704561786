import React, { useState } from "react"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import SetState from "Components/Hooks/SetState"

import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Create from "./Create"
import Delete from "./Delete"
import Edit from "./Update"

const Localities = (props) => {
  const {
    data,
    changeRender,
    handleListItemClick,
    idLocalities,
    idProvincie,
    setDataLocalities,
    handleListItemClickProvincie
  } = props
  const [ valueLocalities, setValueLocalities ] = useState("")

  const onChangeLocalities = (e) => {
    setValueLocalities(e.target.value)
  }
 
  const {
    state: newLocalitiesState,
    setOpen: setOpenNewLocalities,
    setClose: setCloseNewLocalities,
  } = SetState()

  const {
    state: deleteLocalitiesState,
    setOpen: setOpenDeleteLocalities,
    setClose: setCloseDeleteLocalities,
  } = SetState()
  
  const {
    state: editLocalitiesState,
    setOpen: setOpenEditLocalities,
    setClose: setCloseEditLocalities,
  } = SetState()

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (idProvincie) {
          return (
            e.provincia_id === idProvincie &&
            e.localidad.includes(valueLocalities.toUpperCase())
          )
        } else {
          return null
        }
      })
    .sort((a, b) => {
      if (a.localidad < b.localidad) return -1
      if (a.localidad > b.localidad) return 1
      return 0
    })
    } else {
      return []
    }
  }

  function localitiesInfo() {
    return data.filter((e) => {
      return e.id === idLocalities
    })[0]
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewLocalities}
        onChange={onChangeLocalities}
        value={valueLocalities}
        disabledButton={idProvincie ? false : true}
      />

      <Create
        changeRender={changeRender}
        open={newLocalitiesState.open}
        handleClose={setCloseNewLocalities}
        indexModel={idProvincie}
        setDataLocalities={setDataLocalities}
        handleListItemClickProvincie={handleListItemClickProvincie}
      /> 
      
      <Delete
        open={deleteLocalitiesState.open}
        handleClose={setCloseDeleteLocalities}
        idLocalities={idLocalities}
        changeRender={changeRender}
        setDataLocalities={setDataLocalities}
      />

      <Edit
        idLocalities={idLocalities}
        changeRender={changeRender}
        open={editLocalitiesState.open}
        handleclose={setCloseEditLocalities}
        data={localitiesInfo}
        setDataLocalities={setDataLocalities}
      /> 

      <Papers
        buttonStateList
        children={
          <ListWithIcon
            data={filtered(data)}
            search={valueLocalities}
            selectedItem={idLocalities}
            onClickSelect={handleListItemClick}
            onClickDelete={setOpenDeleteLocalities}
            onClickEdit={setOpenEditLocalities}
            nameIncludes="localidad"
            secondary={`'CP: ' +  item.cp`}
          />
        }
        height="500px"
        disabledOn={idLocalities ? false : true}
        onClick={setOpenDeleteLocalities}
      />
    </>
  )
}
export default Localities
 