import { React, useRef, useState } from "react"

import Form from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import moment from "moment"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Edit = (props) => {
  const { changeRender, idVersion, open, handleclose, data, types } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [loading, setLoading] = useState(false)
  const onSubmit = async (values) => {
    setLoading(true)
    const value = {
      version: values.version.toUpperCase(),
      inicio: moment(values.inicio).format("yyyy"),
      fin: moment(values.fin).format("yyyy"),
      vehiculo_tipo_id: values.vehiculo_tipo_id,
    }
    await interfaces.configInterface
      .updateVersion(idVersion, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
    setLoading(false)
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      widthLarge="500px"
      title="Editar version "
      children={
        <Form
          ref={formSubmitRef}
          onSubmit={onSubmit}
          data={data}
          types={types}
        />
      }
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleclose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default Edit
