import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",

    alignItems: "center",
    heigth: "56px",
  },
  input: {
    marginBottom: theme.spacing(1),
    flex: 1,
    height: "75px",
  },
  fullWidth: {
    width: "100%",
  },
  iconButton: {
    padding: 10,
    // color: "#3E984C",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export default useStyles
