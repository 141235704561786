import { React, useRef, useState } from "react"

import FormNew from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Model/New/Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const New = (props) => {
  const { handleClose, open, changeRender, idBrand } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    setLoading(true)
    const value = {
      modelo: values.modelo.toUpperCase(),
      marca_id: idBrand,
    }

    await interfaces.configInterface
      .newBMV(value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        widthLarge="500px"
        title="Nuevo modelo"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={<FormNew ref={formSubmitRef} onSubmit={onSubmit} />}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default New
