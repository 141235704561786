import InputButtonWithOutFormik from "Components/UI/Atoms/InputButtonWithOutValidate"

export function GENERAL(values, inputs, edit, handleChange) {
  return [
    {
      id: 1,
      class: "autocomplete",
      name: "producto",
      label: "Producto",
      value: values.producto_id,
      optionLabel: `options["producto"]`,
      optionValue: "id",
      sortBy: inputs.productos.producto,
      options: inputs.productos,
      onChange: (e) =>
        handleChange({
          producto_id: e.id,
        }),
      disabled: !edit,
      sm: 6,
      xs: 12,
    },
    {
      id: 2,
      name: "codigo",
      label: "Código",
      class: "textfield",
      value: values.codigo,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 3,
      name: "nrodev",
      label: "Nº de V",
      class: "textfield",
      value: values.vehiculo_info.nrodev,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 4,
      name: "oblea",
      label: "Oblea RUDAC",
      value: values.oblea,
      class: "textfield",
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 5,
      name: "calidad",
      label: "Calidad",
      class: "autocomplete",
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      freeSolo: false,
      sortBy: inputs.calidades.nombre,
      options: inputs.calidades,
      value: values.calidad_id,
      onChange: (e) =>
        handleChange({
          calidad_id: e.id,
        }),
      disabled: !edit,
      sm: 6,
      xs: 12,
    },
    {
      id: 6,
      name: "estado",
      label: "Estado",
      class: "autocomplete",
      sm: 6,
      xs: 12,
      optionLabel: `options["descripcion"]`,
      optionValue: "id",
      value: values.estado_id,
      onChange: (e) => {
        handleChange({
          estado_id: e.id,
        })
      },
      freeSolo: false,
      disabled: !edit,
      sortBy: inputs.estados.descripcion,
      options: inputs.estados,
    },
    {
      id: 7,
      name: "comentarios",
      label: "Comentarios",
      multiline: true,
      rows: 6,
      class: "textfield",
      value: values.comentarios,
      onChange: (e) => {
        handleChange({
          comentarios: e.target.value,
        })
      },
      disabled: !edit,
      sm: 12,
      xs: 12,
    },
  ]
}

export function DATA_ORIGIN(values) {
  return [
    {
      id: 1,
      name: "dominio",
      label: "Dominio",
      class: "textfield",
      value: values.vehiculo_info.dominio,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 2,
      name: "marca",
      label: "Marca",
      class: "textfield",
      value: values.vehiculo_info.marca,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 3,
      name: "modelo",
      label: "Modelo",
      class: "textfield",
      value: values.vehiculo_info.modelo,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 4,
      name: "version",
      label: "Versión",
      class: "textfield",
      value: values.vehiculo_info.version,
      disabled: true,
      sm: 6,
      xs: 12,
    },

    {
      id: 5,
      name: "motor_nro",
      label: "Nro. de Motor",
      class: "textfield",
      value: values.vehiculo_info.motor_nro,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      id: 6,
      name: "chasis_nro",
      label: "Nro. de Chasis",
      class: "textfield",
      value: values.vehiculo_info.chasis_nro,
      disabled: true,
      sm: 6,
      xs: 12,
    },
  ]
}

export const UBI_DEPOSITO = (
  values,
  inputs,
  edit,
  handleChange,
  handleSubmit
) => {
  return [
    {
      id: 1,
      name: "ubicacion_id",
      label: "Depósito",
      class: "autocomplete",
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      freeSolo: false,
      sortBy: inputs.depositos.nombre,
      getOptionDisabled: (options) => options.id === 7,
      disabledOptions: [7],
      options: inputs.depositos,
      value: values.ubicacion_id,
      disabled: !edit,
      onChange: (e) => {
        handleChange({
          ubicacion_id: e.id,
        })
      },
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      name: "almacen_empresa",
      label: "Empresa almacén",
      sm: 2,
      xs: 12,
      optionLabel: `options["label"]`,
      optionValue: "id",
      options: [
        { id: 1, label: "ECO" },
        { id: 2, label: "OEM" },
      ],
      value: values.almacen_empresa,
      disabled: !edit,
      onChange: (e) => {
        handleChange({
          almacen_empresa: e.id,
        })
      },
    },
    {
      class: "custom",
      component: (
        <InputButtonWithOutFormik
          onSubmit={handleSubmit}
          handleChangeCode={(e) =>
            handleChange({ almacen_codigo: e.target.value })
          }
          name="almacen_id"
          code={values.almacen_codigo}
          value={values.almacen_codigo}
          placeholder="Cód. ubicación"
          disabled={!edit}
        />
      ),
      sm: 4,
      xs: 12,
    },
    {
      name: "almacen",
      label: "Almacén",
      class: "textfield",
      value: values.almacen.almacen ? values.almacen.almacen : "",
      disabled: true,
      sm: 2,
      xs: 12,
    },
    {
      name: "nivel",
      value: values.almacen.nivel ? values.almacen.nivel : "",
      label: "Nivel",
      class: "textfield",
      disabled: true,
      sm: 2,
      xs: 12,
    },
    {
      name: "estanteria",
      value: values.almacen.estanteria ? values.almacen.estanteria : "",
      label: "Estantería",
      class: "textfield",
      disabled: true,
      sm: 2,
      xs: 12,
    },
    {
      name: "columna",
      value: values.almacen.columna ? values.almacen.columna : "",
      label: "Columna",
      class: "textfield",
      disabled: true,
      sm: 2,
      xs: 12,
    },
    {
      name: "fila",
      value: values.almacen.fila ? values.almacen.fila : "",
      label: "Fila",
      class: "textfield",
      disabled: true,
      sm: 2,
      xs: 12,
    },
    {
      name: "bin",
      value: values.almacen.bin ? values.almacen.bin : "",
      label: "BIN",
      class: "textfield",
      disabled: true,
      sm: 2,
      xs: 12,
    },
  ]
}

export function PRICE_SALE(data, values, edit, handleChange) {
  return [
    {
      id: 1,
      name: "precio1",
      label: "Precio",
      class: "textfield",
      value: values.precio1,
      type: "number",
      disabled: !edit,
      onChange: (e) => {
        handleChange({
          [e.target.name]: e.target.value,
        })
      },
      sm: data.usd === true ? 6 : 12,
      xs: 12,
    },
    {
      id: 2,
      name: "dolar",
      label: "Precio en USD",
      class: data.usd === true ? "textfield" : null,
      value: values.dolar,
      disabled: true,
      sm: 6,
      xs: 12,
    },
  ]
}
