import React, { useRef } from "react"
import { Grid, Hidden } from "@material-ui/core"
import CustomButton from "Components/UI/Atoms/CustomButton"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

import InputSecondNav from "Components/UI/Molecules/SecondNav/InputSecondNav"
import InputCollector from "Components/UI/Molecules/SecondNav/InputCollector"

import useStyles from "./styles"

import { validationCode } from "Components/Validation/Plant/Stock"

const SecondNav = (props) => {
  const {
    open,
    openDialog,
    closeDialog,
    scanField,
    dataScanField,
    onSubmitField,
    onSubmitCollector,
    onSubmitProcess,
    onSubmitCancel,
    loading,
    hiddenProcessCancel,
    hiddenColector,
    colorlessBtn,
    loadingCode,
  } = props

  const classes = useStyles()
  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  return (
    <div className={classes.marginContent}>
      <Grid container>
        <div className={classes.rootSecondNav}>
          <div className={classes.flexAlignCenter}>
            <Grid item sm={12} xs={12}>
              <InputSecondNav
                onSubmit={open === true ? null : onSubmitField}
                value={scanField}
                colorlessBtn={colorlessBtn}
                validationSchema={validationCode}
                loading={loadingCode}
              />
            </Grid>
            {hiddenProcessCancel ? null : (
              <div className={classes.flexAlignCenter}>
                <Grid item sm={12} xs={12} className={classes.marginContent}>
                  <CustomButton
                    text="Procesar"
                    color="primary"
                    fullWidth
                    onClick={onSubmitProcess}
                    isLoading={loading}
                    disabled={dataScanField.rows.length === 0 ? true : false}
                  />
                </Grid>
                <Grid item sm={12} xs={12} className={classes.marginContent}>
                  <CustomButton
                    text="Limpiar"
                    fullWidth
                    onClick={onSubmitCancel}
                    disabled={dataScanField.rows.length === 0 ? true : false}
                  />
                </Grid>
              </div>
            )}
          </div>
          {hiddenColector ? null : (
            <Hidden xsDown>
              <div className={classes.flexAlignCenter}>
                <Grid item sm={12} xs={12} className={classes.marginContent}>
                  <CustomButton
                    text="Colector"
                    fullWidth
                    onClick={openDialog}
                  />
                </Grid>
              </div>
            </Hidden>
          )}
        </div>
      </Grid>
      {open === true ? (
        <DialogComponent
          open={open}
          handleclose={() => closeDialog()}
          title="Colector"
          subtitle="Pegue aquí los códigos obtenidos por el scanner"
          children={
            <InputCollector onSubmit={onSubmitCollector} ref={formSubmitRef} />
          }
          actions={
            <CancelConfirmButtons
              onClickCancel={() => closeDialog()}
              onClickSubmit={handleSubmit}
            />
          }
        />
      ) : null}
    </div>
  )
}
export default SecondNav
