import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputButtonWithOutFormik from "Components/UI/Atoms/InputButtonWithOutValidate";
import { COLORS, FUEL, TRANSSMISION, TRACTION } from "Constants";

import DateFnsUtils from "@date-io/date-fns";
import es from "date-fns/locale/es";

export const FORM_HOME = (data, field, handleChange, edit) => {
  return [
    {
      sm: 6,
      xs: 12,
      class: "autocomplete",
      name: "producto_id",
      label: "Producto",
      options: field.productos,
      optionLabel: `options["producto"]`,
      optionValue: "id",
      defaultValue: field.productos.find((v) => v.producto === data.producto),
      onChange: (e) => {
        handleChange("producto_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "codigo",
      label: "Código en sistema viejo",
      value: data.codigo_viejo,
      disabled: true,
      sm: data.oblea !== "" ? 3 : 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "oblea",
      label: "Oblea",
      value: data.oblea,
      disabled: true,
      display: data.oblea !== "" ? true : false,
      sm: 3,
      xs: 12,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "calidad",
      label: "Calidad",
      options: field.calidades,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: data.calidad,
      onChange: (e) => {
        handleChange("calidad", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "estado",
      label: "Estado",
      options: field.estados,
      optionLabel: `options["descripcion"]`,
      optionValue: "id",
      value: data.estado_id,
      onChange: (e) => {
        handleChange("estado_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "comentarios",
      label: "Comentarios",
      value: data.comentarios,
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange("comentarios", e.target.value);
      },
      disabled: !edit,
    },
  ];
};

export const ORIGIN_DATA = (data, field, handleChange, edit) => {
  const date = `10/31/${data.año}`;
  return [
    {
      class: "autocomplete",
      name: "marca_id",
      label: "Marca",
      defaultValue: field.marcas.find((v) => v.id === data.marca_id),
      options: field.marcas,
      optionLabel: `options["marca"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("marca_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "modelo_id",
      label: "Modelo",
      defaultValue: field.modelos.find((v) => v.id === data.modelo_id),
      options: field.modelos.filter((e) => e.marca_id === data.marca_id),
      optionLabel: `options["modelo"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("modelo_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "version_id",
      label: "Versión",
      defaultValue: field.versiones.find((v) => v.id === data.version_id),
      options: field.versiones.filter((e) => e.modelo_id === data.modelo_id),
      optionLabel: `options["version"] +" ("+ options["inicio"] +"/"+ options["fin"] +")"`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("version_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "tipo_baja",
      label: "Tipo de baja",
      defaultValue: data.tipo_baja,
      options: field.tipo_baja,
      optionLabel: `options["descripcion"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("tipo_baja_id", e.id);
      },
      disabled: !edit,
    },

    {
      class: "datepicker",
      name: "año",
      label: "Año",
      format: "yyyy",
      value: date,
      views: ["year"],
      sm: 6,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("año", e);
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "motor_id",
      label: "Tipo motor",
      options: field.motores,
      optionLabel: `options["codigo"]`,
      optionValue: "id",
      value: data.motor_id,
      onChange: (e) => {
        handleChange("motor_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "transmision",
      label: "Transmisión",
      options: TRANSSMISION,
      optionLabel: `options["TRANSSMISION"]`,
      optionValue: "TRANSSMISION",
      value: data.transmision,
      onChange: (e) => {
        handleChange("transmision", e.TRANSSMISION);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "combustible",
      label: "Combustible",
      options: FUEL,
      optionLabel: `options["combustible"]`,
      optionValue: "combustible",
      value: data.combustible,
      onChange: (e) => {
        handleChange("combustible", e.combustible);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "color",
      label: "Color",
      options: COLORS,
      optionLabel: `options["color"]`,
      optionValue: "color",
      value: data.color,
      onChange: (e) => {
        handleChange("color", e.color);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "traccion",
      label: "Tracción",
      options: TRACTION,
      optionLabel: `options["TRACTION"]`,
      optionValue: "TRACTION",
      value: data.traccion,
      onChange: (e) => {
        handleChange("traccion", e.TRACTION);
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "puertas",
      label: "Puertas",
      type: "number",
      onChange: (e) => handleChange("puertas", parseInt(e.target.value)),
      value: Math.max(0, parseInt(data.puertas) || 0),
      sm: 6,
      xs: 12,
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "nro_motor",
      label: "Nº de Motor",
      onChange: (e) => handleChange("nro_motor", e.target.value),
      value: data.nro_motor,
      sm: 6,
      xs: 12,
      disabled: !edit,
    },
    {
      class: "textfield",
      name: "nro_chasis",
      label: "Nº de Chasis",
      onChange: (e) => handleChange("nro_chasis", e.target.value),
      value: data.nro_chasis,
      sm: 12,
      xs: 12,
      disabled: !edit,
    },
  ];
};

export const DEPOSIT_LOCATION = (
  data,
  field,
  handleChange,
  edit,
  handleSubmitCode
) => {
  return [
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "ubicacion_id",
      label: "Depósito",
      options: field.ubicaciones,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      value: data.ubicacion_id,
      onChange: (e) => {
        handleChange("ubicacion_id", e.id);
      },
      disabled: !edit,
    },
    {
      class: "autocomplete",
      name: "almacen_empresa",
      label: "Empresa almacén",
      sm: 2,
      xs: 2,
      optionLabel: `options["label"]`,
      optionValue: "id",
      options: [
        { id: 1, label: "ECO" },
        { id: 2, label: "OEM" },
      ],
      value: data.almacen_empresa,
      disabled: !edit,
      onChange: (e) => {
        handleChange("almacen_empresa", e.id);
      },
    },
    {
      class: "custom",
      component: (
        <InputButtonWithOutFormik
          onSubmit={handleSubmitCode}
          handleChangeCode={(e) =>
            handleChange("almacen_codigo", e.target.value.toUpperCase())
          }
          name="almacen_codigo"
          code={data.almacen_codigo}
          value={data.almacen_codigo}
          placeholder="Cód. ubicación"
          disabled={!edit}
        />
      ),
      sm: 4,
      xs: 10,
    },
    {
      class: "textfield",
      name: "almacen",
      label: "Almacén",
      value: data.almacen.almacen ? data.almacen.almacen : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "nivel",
      label: "Nivel",
      value: data.almacen.nivel ? data.almacen.nivel : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "estanteria",
      label: "Estantería",
      value: data.almacen.estanteria ? data.almacen.estanteria : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "columna",
      label: "Columna",
      value: data.almacen.columna ? data.almacen.columna : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "fila",
      label: "Fila",
      value: data.almacen.fila ? data.almacen.fila : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
    {
      class: "textfield",
      name: "bin",
      label: "BIN",
      value: data.almacen.bin ? data.almacen.bin : "",
      sm: 2,
      xs: 12,
      disabled: true,
    },
  ];
};

export const SALES = (data, handleChange, edit) => {
  return [
    {
      class: "textfield",
      name: "proveedor",
      label: "Proveedor",
      value: data.proveedor,
      sm: 12,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("proveedor", e.target.value);
      },
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("fecha_compra", e);
            }}
            disabled={edit ? false : true}
            name="fecha_compra"
            label="Fecha de compra"
            format="dd/MM/yyyy"
            value={data.fecha_compra ? data.fecha_compra : null}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),

      sm: 3,
      xs: 12,
    },
    {
      class: "textfield",
      type: "number",
      name: "compra_suc",
      label: "Factura Suc. Compra",
      value: data.compra_suc,
      sm: 3,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("compra_suc", e.target.value);
      },
    },
    {
      class: "textfield",
      type: "number",
      name: "compra_nro",
      label: "Factura Nro. Compra",
      value: data.compra_nro,
      sm: 3,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("compra_nro", e.target.value);
      },
    },
    {
      class: "autocomplete",
      name: "compra_tipo",
      label: "Tipo Fac. Compra",
      value: data.compra_tipo,
      sm: 3,
      xs: 12,
      optionLabel: `options["label"]`,
      optionValue: "id",
      options: [
        { id: 1, label: "Factura A" },
        { id: 6, label: "Factura B" },
      ],
      disabled: !edit,
      onChange: (e) => {
        handleChange("compra_tipo", e.id);
      },
    },
    {
      class: "textfield",
      type: "number",
      name: "precio_compra",
      label: "Precio de compra",
      value: data.precio_compra,
      sm: 6,
      xs: 12,
      disabled: !edit,
      onChange: (e) => {
        handleChange("precio_compra", e.target.value);
      },
    },
    {
      class: "textfield",
      type: "number",
      name: "precio",
      label: "Precio de venta",
      value: data.precio,
      onChange: (e) => {
        handleChange("precio", e.target.value);
      },
      sm: 6,
      xs: 12,
      disabled: !edit,
    },
  ];
};
