import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overflowDesactivo: {
    overflow: "hidden",
  },
  paddingGrids: {
    padding: "10px",
  },
  heightInicio: {
    height: "600px",
  },
  style: {
    margin: "30px",
  },
}));

export default useStyles;
