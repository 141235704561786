import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  table: {
    height: "552px",
    margin: theme.spacing(3),
    overflow: "auto",
  },
  root: {
    height: "600px",
    overflow: "auto",
  },
}))
