const BEFORE_PENDING = "plant/compaction/vehicle"
const PENDING_LIST = "plant/compaction"
const PRINT_LIST = "plant/compaction/print"
const DELETE_PENDING = "plant/compaction/"
const NEW_PENDING = "plant/compaction/"
const CONFIRM_COMPACTION = "plant/compaction/confirm/"
const PRINT_COMPACION = "plant/compaction/print"
const DETAILS_INFO = "/plant/compaction"
const EXPORTALL = "/plant/compaction/export"

export const plantEndpoints = {
  BEFORE_PENDING,
  PENDING_LIST,
  DELETE_PENDING,
  NEW_PENDING,
  PRINT_COMPACION,
  PRINT_LIST,
  CONFIRM_COMPACTION,
  DETAILS_INFO,
  EXPORTALL,
}
