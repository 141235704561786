import { React, forwardRef } from "react"
import { Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { editLot } from "Components/Validation/Settings/Various/LotsRow"

const Form = forwardRef((props, ref) => {
  const { onSubmit, data } = props
  const info = data()

  const object = [
    {
        id: 0,
        optionLabel: "NO",
      },
      {
        id: 1,
        optionLabel: "SI",
      },
  ]
  const OBJETO = (values, setFieldValue) => {
   
    return [
      {
        class: "textfield",
        name: "numero",
        label: "Nº de Lote",
        value: values.numero,
        sm: 12,
        xs: 12,
        disabled: true,
        type: "number",
        min: 0
      },
      {
        class: "textfield",
        name: "nombre",
        label: "Nombre",
        value: values.nombre,
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        name: "ubicacion",
        label: "Ubicación",
        value: values.ubicacion,
        disabled: true,
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        name: "capacidad",
        label: "Capacidad",
        value: values.capacidad,
        sm: 4,
        xs: 12,
        type: "number",
        min: 0,
        style: {
          marginBottom: "20px"
        }
      },
      {
        class: "textfield",
        name: "ancho",
        label: "Ancho",
        value: values.ancho,
        sm: 4,
        xs: 12,
        type: "number",
        min: 0,
        style: {
          marginBottom: "20px"
        }
      },
      {
        class: "textfield",
        name: "largo",
        label: "Largo",
        value: values.largo,
        sm: 4,
        xs: 12,
        type: "number",
        min: 0,
        style: {
          marginBottom: "20px"
        }
      },
      {
        class: "custom",
        sm: 12,
        xs: 12,
        component: (
          <FormikAutocomplete
            defaultValue={object.find((v) => v.id === values.es_compactacion)}
            label="Es lote compactación?"
            name="es_compactacion"
            options={object}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value, reason) => {
              setFieldValue("es_compactacion", value.id)
            }}
          />
        ),
      },
    ]
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          numero: info.numero,
          nombre: info.nombre,
          capacidad: info.capacidad? info.capacidad : 0,
          ancho: info.ancho? info.ancho : 0,
          largo: info.largo? info.largo : 0,
          ubicacion: info.ubicacion,
          es_compactacion: info.es_compactacion 
        }}
        validationSchema={editLot}
        onSubmit={onSubmit}
      >
        {({ submitForm, values, setFieldValue, isSubmitting }) => (
          <>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form