import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  paddingTabs: {
    padding: "2em",
    overflow: "auto",
    height: "600px",
    [theme.breakpoints.only("xs")]: {
      padding: "1em",
    },
  },
  paddingArticle: {
    padding: "2em",
    overflowY: "hidden",
    height: "600px",
    [theme.breakpoints.only("xs")]: {
      padding: "1em",
    },
  },
  paddingHistory: {
    padding: "1em",
  },
  div: {
    width: "90%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}))

export default useStyles
