import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    [theme.breakpoints.only("xs")]: {
      overflow: "hidden",
    },
  },
  overflowDesactivo: {
    overflow: "hidden",
  },
  paddingGrids: {
    padding: "10px 0px 10px 0px",
  },
}))

export default useStyles
