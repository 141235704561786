import React, { useRef } from "react"
import ReactImageGallery from "react-image-gallery"

import { titles2 } from "Helpers"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

import useStyles from "../styles"

const VehicleInfo = ({ item, fullImg, setFullImg, open, handleClose }) => {
  const classes = useStyles()

  const formFirstStep = (item, ref, setFullImg) => {
    return [
      {
        class: "custom",
        component: (
          <ReactImageGallery
            ref={ref}
            items={titles2(
              [].concat(item.fotografias, item.iniciales.reverse())
            )}
            onScreenChange={(e) => setFullImg({ fullScreen: e })}
            onClick={(e) => ref.current.fullScreen()}
            useBrowserFullscreen={false}
            showBullets={true}
            showPlayButton={false}
            lazyLoad={true}
            showThumbnails={false}
            onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
          />
        ),
        xs: 12,
        sm: 12,
      },
      {
        class: "textfield",
        name: "comentario",
        label: "Observación ECOSOFT",
        value: item.comentario_ecosoft,
        md: 12,
        sm: 12,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "chasis",
        label: "Nº de chasis",
        value: item.chasis_nro,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "nromotor",
        label: "Nº de motor",
        value: item.motor_nro,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "motor",
        label: "Motor",
        value: item.motor_marca,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "combustible",
        label: "Combustible",
        value: item.combustible,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "kms",
        label: "Kilometros",
        value: item.kms,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "transmision",
        label: "Transmisión",
        value: item.transmision,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "traccion",
        label: "Tracción",
        value: item.traccion,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "puertas",
        label: "Puertas",
        value: item.puertas,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "color",
        label: "Color",
        value: item.color,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "titulo",
        label: "Título",
        value: item.modelo_titulo,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "ingreso",
        label: "Fecha ingreso",
        value: item.fecha_ingreso,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "baja",
        label: "Ingreso baja",
        value: item.fecha_baja,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },
      {
        class: "textfield",
        name: "tramite",
        label: "Fin trámite",
        value: item.fecha_tramite,
        md: 6,
        sm: 6,
        xs: 12,
        inputProps: { readOnly: true },
      },

      {
        class: "custom",
        md: 6,
        sm: 6,
        xs: 12,
      },
    ]
  }

  const ref = useRef()

  return (
    <DialogComponent
      title={
        <div>
          {item.marca} {item.modelo_titulo}
        </div>
      }
      subtitle={
        <div className={classes.subtitle}>
          <div>
            <b> Nº V: </b>
            {item.nrodev} - <b> Dominio:</b> {item.dominio}
          </div>
          <div className={classes.estadoTramite}>{item.estado}</div>
        </div>
      }
      disableEscapeKeyDown={fullImg.fullScreen === true ? true : false}
      widthLarge="700px"
      open={open}
      handleclose={handleClose}
      handleclos
      tipo="slide"
      children={
        <InputsWithoutValidate
          fields={formFirstStep(item, ref, setFullImg)}
          spacing={2}
        />
      }
    />
  )
}

export default VehicleInfo
