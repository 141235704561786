import React, { useState } from "react"

import { Grid, Typography, Card, ButtonBase } from "@material-ui/core"

import { useStyles } from "./styles"
import { interfaces } from "service/interfaces"
import PictureCard from "Components/UI/Atoms/PictureCard"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Loader from "Components/UI/Atoms/Loader"
import { fileTypeTest } from "Helpers"
import setState from "Components/Hooks/SetState"
import Videoplayer from "Components/UI/Molecules/Videoplayer"
import UploadImageDialog from "Components/UI/Molecules/UploadImageDialog"
import DeleteImageDialog from "Components/UI/Molecules/DeleteImageDialog"

export default function Files(props) {
  const { img, dialogData, setImg, id, edit } = props

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [changeUpload, setChangeUpload] = useState([])
  const [startDelete, setStartDelete] = useState({
    open: false,
    id: null,
    index: null,
  })
  const [startEdit, setStartEdit] = useState({
    open: false,
    id: null,
  })

  const {
    state: video,
    setOpen: openVideo,
    setClose: closeVideo,
    setState: setVideo,
  } = setState()

  const createDismissableSnackbar = useCustomSnackbar()

  function handleChangeUpload(e) {
    e.preventDefault()
    let prevData = [...changeUpload]
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    setChangeUpload(prevData)
  }
  function handleOpenDelete(index) {
    setStartDelete({
      open: true,
      id: id,
      index: index,
    })
  }
  function handleOpenUpload() {
    setStartEdit({
      open: true,
      id: id,
    })
  }
  function handleCloseDelete() {
    setStartDelete({
      open: false,
      index: null,
    })
  }
  function handleCloseUpload() {
    setChangeUpload([])
    setStartEdit({
      open: false,
    })
  }
  function handleDel(index) {
    img.splice(index, 1)
    let newArray = img
    setImg(newArray)
  }

  async function handleDelete(item) {
    setIsLoading(true)
    let index = img.indexOf(item)
    await interfaces.plantInterfaceClassification
      .deletePhoto(
        dialogData.stock.id,
        index + 1,
        dialogData.stock.empresa
      )
      .then((res) => {
        createDismissableSnackbar(res.data, { variant: "success" })
        handleCloseDelete()
        handleDel(index)
        setIsLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        handleCloseDelete()
        setIsLoading(false)
      })
  }

  async function handleUpload() {
    setIsLoading(true)
    await interfaces.plantInterfaceClassification
      .uploadPhoto(
        dialogData.stock.id,
        dialogData.stock.empresa,
        changeUpload,
        img.length + 1
      )
      .then((res) => {
        setIsLoading(false)
        createDismissableSnackbar(res.data.data.msg, { variant: "success" })
        setImg(res.data.data.url)
        handleCloseUpload()
      })
      .catch((error) => {
        setIsLoading(false)
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }
  const swipes = img
    .filter((e) => fileTypeTest(e) === "image")
    .map((item, index) => ({
      id: index,
      img: item,
    }))

  return (
    <>
      <Grid
        style={{ overflow: "scroll", height: "550px" }}
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
      >
        {img
          .filter((e) => fileTypeTest(e) === "image")
          .map((item, index) => (
            <Grid item xs={12} xl={4} sm={6} md={4}>
              <PictureCard
                img={item}
                edit={edit}
                txt={`Fotografía ${index + 1}`}
                title={`Fotografía ${index + 1}`}
                index={index + 1}
                swipes={swipes}
                editDelete={edit}
                handleDelete={() => handleOpenDelete(item)}
              />
            </Grid>
          ))}
        {img
          .filter((e) => fileTypeTest(e) === "video")
          .map((item, index) => (
            <Grid item xs={12} xl={4} sm={6} md={4}>
              <PictureCard
                img={process.env.PUBLIC_URL + "/Images/file.png"}
                href={item}
                edit={edit}
                txt={`Video ${index + 1}`}
                title={`Video ${index + 1}`}
                index={index + 1}
                swipes={swipes}
                editDelete={edit}
                onClick={() =>
                  openVideo({
                    url: item,
                    play: true,
                    fullScreen: false,
                    height: "90vh",
                    width: "70vw",
                  })
                }
                handleDelete={() => handleOpenDelete(item)}
              />
            </Grid>
          ))}
        {edit ? (
          <Grid item xs={12} xl={4} sm={6} md={4} style={{ marginTop: "10px" }}>
            <ButtonBase component="a" onClick={() => handleOpenUpload()}>
              <Card className={classes.cardSelectImg}>
                <Typography>Seleccione una imagen..</Typography>
              </Card>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>

      {startDelete.open && (
        <DeleteImageDialog
          open={startDelete}
          onSubmit={() => handleDelete(startDelete.index)}
          loading={isLoading}
          handleClose={handleCloseDelete}
        />
      )}

      <UploadImageDialog
        open={startEdit.open}
        loading={isLoading}
        onClose={handleCloseUpload}
        onSubmit={handleUpload}
        onChange={handleChangeUpload}
        selected={changeUpload.length > 0}
      />
      {video.suspense && (
        <Videoplayer
          video={video}
          setVideo={setVideo}
          closeVideo={closeVideo}
        />
      )}
      {isLoading && <Loader />}
    </>
  )
}
