import React from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import BasicTable from "Components/UI/Atoms/BasicTable"
import { Typography } from "@material-ui/core"

const Step4 = (props) => {
  const { initValues } = props
  const field = [
    {
      class: "textfield",
      label: "Dominio / Nº Siniestro",
      name: "dominio",
      value: initValues.vehicle ? initValues.vehicle.dominio_siniestro : "",
      disabled: true,
      xs: 12,
      sm: 12,
    },
    {
      class: "textfield",
      label: "Vehículo",
      name: "vehiculo",
      value: initValues.vehicle
        ? `${initValues.vehicle.marca} ${initValues.vehicle.modelo} ${initValues.vehicle.año}`
        : "",
      disabled: true,
      xs: 12,
      sm: 12,
    },
  ]

  return (
    <div style={{ overflow: "hidden", height: "550px", paddingTop: "5px" }}>
      <InputsWithoutValidate fields={field} spacing={2} />
      <div style={{ marginTop: "10px" }}>
        <BasicTable
          stylePaper={{ height: "480px", overflowY: "scroll" }}
          col={[
            {
              title: `Cant. prod. seleccionados: ${
                initValues.products.filter((e) => e.checked).length
              }`,
              style: { width: "35%" },
            },
            { title: "", style: { width: "10%" } },
          ]}
          rows={initValues.products
            .filter((e) => e.checked)
            .map((row) => {
              return {
                codigo: row.producto,
                title: (
                  <div>
                    <Typography
                      style={{ fontSize: "14px", fontWeight: "30" }}
                      variant="subtitle1"
                    >
                    </Typography>
                  </div>
                ),
              }
            })}
        />
      </div>
    </div>
  )
}

export default Step4