import React from "react"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"

import { Grid, Typography, Button } from "@material-ui/core"

import { useStyles } from "./styles"
import { interfaces } from "service/interfaces"
import { DialogUpdateFiles } from "Components/UI/Organisms/Home/Widgets/UpdateFiles/Dialog"
import { validationDomainDashboard } from "Components/Validation/Home"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"

const UpdateFiles = () => {
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose } = SetState()

  function handleSubmit(values) {
    let vehiculo = { vehiculo: values.dominio.toUpperCase() }
    interfaces.dashboardInterface
      .getVehicleData(vehiculo)
      .then((res) => {
        setOpen({ data: res.data })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  return (
    <div>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Subir fotografías principales
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Formik
            initialValues={{ dominio: "" }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationDomainDashboard}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
              resetForm({})
            }}
          >
            {({ values }) => (
              <Form>
                <Grid item sm={12} xs={12}>
                  <Field
                    style={{ height: "70px" }}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    disabled={false}
                    name="dominio"
                    label="Dominio / Nro. de V"
                    value={values.dominio.toUpperCase()}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Buscar vehículo
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {state.suspense && (
        <DialogUpdateFiles start={state} handleClose={setClose} />
      )}
    </div>
  )
}

export default UpdateFiles
