import * as yup from "yup"

export const validationCreateCompaction = yup.object({
  tipo: yup
    .string()
    .required("Este campo es obligatorio")
    .oneOf(["LOCAL", "REMOTA"], "Selecciona una opción válida").typeError("Este campo es obligatorio"),
  ubicacion_id: yup
    .number()
    .nullable() 
    .when('tipo', {
      is: 'LOCAL',
      then: yup.number().required("Este campo es obligatorio").typeError("Selecciona una opción"),
      otherwise: yup.number().nullable()
    }),
})