import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  controlButton: {
    margin: "5px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
}))
