import React from "react"

import { Grid } from "@material-ui/core"
import PaperComponent from "Components/UI/Atoms/PaperComponent"

import PaperIngress from "Components/UI/Organisms/Plant/Stock/PaperIngress"
import PaperDevolution from "Components/UI/Organisms/Plant/Stock/PaperDevolution"

const WarehouseTable = () => {
  return (
    <Grid container style={{ flexWrap: "nowrap" }}>
      <Grid item sm={12} xs={12}>
        <PaperComponent children={<PaperIngress />} height={"92vh"} />
      </Grid>
      <Grid item sm={12} xs={12}>
        <PaperComponent children={<PaperDevolution />} height={"92vh"} />
      </Grid>
    </Grid>
  )
}
export default WarehouseTable
