const LIST = "/settings/operators/list"
const NEW = "/settings/operators/new"
const EDIT = "/settings/operators/edit"
const EXPORT_OPERATOR = "/settings/operators/export/"

export const configVariousOperators = {
  LIST,
  NEW,
  EDIT,
  EXPORT_OPERATOR,
}
