import React from "react"

import Navbar from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import Varies from "Components/UI/Organisms/Settings/Various"

const Various = () => {
  return (
    <Template
      navbar={<Navbar title="Configuración" subTitle="Varios" />}
      table={<Varies />}
    />
  )
}
export default Various
