import React from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableFooter,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"

import useStyles from "./styles"

const FirstTable = ({ col, rows, onChange, deleteRow, addRow }) => {
  const classes = useStyles()
  return (
    <Paper>
      <Table style={{ tableLayout: "fixed" }} size="small">
        <TableHead>
          <TableRow className={classes.tableRow}>
            {col.map(({ title, style }) => (
              <TableCell style={style}>{title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={`row${index}`}>
              <TableCell style={col[0].style}>
                <TextField
                  name="text"
                  key={`text`}
                  defaultValue={row.text}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell style={col[1].style}>
                <TextField
                  name="value"
                  key={`value`}
                  defaultValue={row.value}
                  onChange={(e) => onChange(e, index)}
                />
              </TableCell>
              <TableCell>
                <Tooltip title="Eliminar">
                  <IconButton onClick={() => deleteRow(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow
            style={{
              height: "35px",
            }}
          >
            <TableCell colSpan={3}>
              <Tooltip title="Agregar">
                <IconButton onClick={addRow}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default FirstTable
