import React, { useEffect } from "react"

import StationTemplate from "Components/UI/Templates/Plant/Station"

const Station = () => {
  useEffect(() => {
    document.title = "Estación cero"
  }, [])
  return <StationTemplate />
}

export default Station
