import { UBICACION_COMPACTACION } from "Constants"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import es from "date-fns/locale/es"

const TIPO = [
  {
    tipo: "LOCAL",
  },
  { tipo: "REMOTA" },
]
const ESTADO = [
  {
    id: 0,
    label: "PENDIENTE",
  },
  {
    id: 1,
    label: "CONFIRMADA",
  },
]
const FAC = [
  {
    id: 0,
    label: "A",
  },
  {
    id: 1,
    label: "B",
  },
]

export const FORM_HOME = (data, handleChange, edit, infoFilters) => {
  return [
    {
      class: "textfield",
      name: "nombre",
      label: "Nombre",
      value: data.nombre,
      disabled: !edit,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("nombre", e.target.value)
      },
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "tipo",
      label: "Tipo",
      optionValue: "tipo",
      options: TIPO,
      optionLabel: `options["tipo"]`,
      value: data.tipo,
      disabled: true,
    },
    {
      class: "autocomplete",
      sm: 6,
      xs: 12,
      name: "ubicacion_id",
      label: "Ubicación",
      optionValue: "id",
      options: UBICACION_COMPACTACION,
      optionLabel: `options["nombre"]`,
      value: data.ubicacion_id,
      disabled: true,
    },
    {
      class: "autocomplete",
      name: "proveedor",
      label: "Proveedor compactación",
      optionValue: "proveedor",
      options: infoFilters.filters?.proveedores
        ? infoFilters.filters?.proveedores.slice(1)
        : [],
      optionLabel: `options["proveedor"]`,
      value: data.proveedor,
      disabled: !edit,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("proveedor", e.proveedor)
      },
    },
    {
      class: "autocomplete",
      name: "proveedor2",
      label: "Proveedor disposición final",
      value: data.proveedor2,
      optionValue: "proveedor",
      options: infoFilters.filters.proveedores
        ? infoFilters.filters.proveedores.slice(1)
        : [],
      optionLabel: `options["proveedor"]`,
      disabled: !edit,
      sm: 6,
      xs: 12,
      onChange: (e) => {
        handleChange("proveedor2", e.proveedor)
      },
    },
    {
      class: "custom",
      component: (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <KeyboardDatePicker
            key="fecha"
            autoOk
            variant="inline"
            inputVariant="outlined"
            PopoverProps={{ style: { ...{ left: "40px" } } }}
            fullWidth
            onChange={(e) => {
              handleChange("fecha", e)
            }}
            disabled={!edit}
            name="fecha"
            label="Fecha confirmación"
            format="dd/MM/yyyy"
            value={data.fecha}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </MuiPickersUtilsProvider>
      ),
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "estado",
      label: "Estado",
      optionValue: "id",
      options: ESTADO,
      optionLabel: `options["label"]`,
      value: data.estado,
      disabled: true,
    },
  ]
}

export const DATE = (data, handleChange, edit) => {
  return [
    {
      class: "autocomplete",
      sm: 12,
      xs: 12,
      name: "fac_tipo",
      label: "Tipo Factura",
      optionValue: "id",
      value: data.fac_tipo,
      options: FAC,
      optionLabel: `options["label"]`,
      onChange: (e) => {
        handleChange("fac_tipo", e.id)
      },
      disabled: !edit,
    },
    {
      class: "textfield",
      type: "number",
      name: "fac_suc",
      label: "Nº sucursal",
      value: isNaN(data.fac_suc) ? 0 : Math.max(data.fac_suc, 0),
      onChange: (e) => {
        handleChange("fac_suc", e.target.value)
      },
      sm: 6,
      xs: 12,
      disabled: !edit,
    },
    {
      class: "textfield",
      type: "number",
      name: "fac_nro",
      label: "Nº factura",
      value: isNaN(data.fac_nro) ? 0 : Math.max(data.fac_nro, 0),
      onChange: (e) => {
        handleChange("fac_nro", e.target.value)
      },
      sm: 6,
      xs: 12,
      disabled: !edit
    },
  ]
}
