import React, { createContext, useState } from "react"
import { useSocket } from "Components/Hooks/UseSocket"
import { getToken } from "service/helpers/localstorage"
import PositionedSnackbar from "Components/UI/Atoms/PositionedSnackbar"

export const SocketContext = createContext()

export const SocketProvider = ({ children }) => {
  const [auth, setAuth] = useState(getToken() ? true : false)

  const { socket, online, notification } = useSocket(
    process.env.REACT_APP_API_URL,
    auth
  )
  return (
    <SocketContext.Provider value={{ socket, online, notification, setAuth }}>
      {children}
      <PositionedSnackbar notification={notification} />
    </SocketContext.Provider>
  )
}
