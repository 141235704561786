import React, { useRef, useState } from "react"

import { Form, Formik } from "formik"

import { Switch, FormControlLabel } from "@material-ui/core"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { Fields } from "./fields"
import { interfaces } from "service/interfaces"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
const DialogEdit = (props) => {
  const {
    open,
    handleClose,
    optionsWafers,
    productGroup,
    optionsProducts,
    changeRender,
    products
  } = props

  const [state, setState] = useState({
    checked: false,
  })
  const handleChange = (event) => {
    setState({ [event.target.name]: event.target.checked })
  }

  const refSubmit = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState()
  function handleRef() {
    refSubmit.current.click()
  }

  async function handleSubmit(values) {
    setLoading(true)
    let body = values
    if (values.orden_colocado === "") {
      body = { ...values, orden_colocado: null }
    }
    await interfaces.configInterface
      .updateProduct(optionsProducts.id, body)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        handleClose()
        setLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        handleClose()
        setLoading(false)
      })
  }
  return (
    <DialogComponent
      open={open}
      title="Editar producto"
      handleclose={handleClose}
      children={
        <>
          <Formik
            initialValues={optionsProducts}
            enableReinitialize
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({ values, setFieldValue }) => (
              <Form
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault()
                  }
                }}
              >
                <InputsWithValidate
                  setFieldValue={setFieldValue}
                  fields={Fields(
                    values,
                    optionsWafers,
                    productGroup,
                    setFieldValue,
                    state,
                    products
                  )}
                  spacing={1}
                />
                <button
                  style={{ display: "none" }}
                  ref={refSubmit}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </>
      }
      actions={
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "20px",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={state.checked}
                  onChange={handleChange}
                  name="checked"
                />
              }
              label="Calidades"
            />
          </div>
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={handleRef}
            disabledSubmit={loading}
            disabledCancel={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default DialogEdit
