import React, { useState } from "react"

import { Grid, Typography } from "@material-ui/core"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
import CustomButton from "Components/UI/Atoms/CustomButton"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ContentPPI from "Components/UI/Organisms/Sales/Prices/Components/PricesPerItem/Content"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

import { interfaces } from "service/interfaces"
import { useStyles } from "./styles"

const PricesPerItem = () => {
  const { state, setOpen, setClose } = SetState()

  const classes = useStyles()
  const [dataXlsx, setDataXlsx] = useState([])
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()

  const onClose = () => {
    setClose()
    setTimeout(() => {
      setDataXlsx([])
    }, 250)
  }

  async function onSubmit() {
    setLoading(true)
    const objectSubmit = dataXlsx.map((item) => {
      return {
        id: item.ID,
        precio: item["Precio nuevo"],
        dolar: item["Dolar nuevo"],
        codigo: item.Código,
      }
    })

    await interfaces.salesPrice
      .updatePrices(objectSubmit)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setDataXlsx([])
      })
      .catch((error) => {
        createDismissableSnackbar(error.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  return (
    <div>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Precios por artículos
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <CustomButton text="Gestionar" fullWidth onClick={setOpen} />
        </Grid>
      </Grid>
      {state.suspense && (
        <DialogComponent
          open={state.open}
          title="Precios por artículos"
          handleclose={onClose}
          widthLarge={dataXlsx.length > 0 ? "100vw" : "600px"}
          maxHeight
          disableBackdropClick
          disableEscapeKeyDown
          children={
            <div
              style={{
                height: "500px",
                display: "flex",
                overflowY: "hidden",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ContentPPI dataXlsx={dataXlsx} setDataXlsx={setDataXlsx} />
            </div>
          }
          actions={
            dataXlsx.length > 0 && (
              <CancelConfirmButtons
                onClickSubmit={() => onSubmit()}
                onClickCancel={onClose}
                isLoading={loading}
                disabledCancel={loading}
                disabledSubmit={loading}
              />
            )
          }
        />
      )}
    </div>
  )
}

export default PricesPerItem
