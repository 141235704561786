import React, { useContext } from "react"
import { useHistory } from "react-router-dom"

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Grid,
  Button,
  Hidden,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core"
import MoreIcon from "@material-ui/icons/MoreVert"
import MenuIcon from "@material-ui/icons/Menu"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Tooltip from "@material-ui/core/Tooltip"

import { OpenNavbar } from "Components/Hooks/ContextSideBar"
import useStyles from "./styles"

const NavBarGlobal = (props) => {
  const { title, subTitle, subTitle2, buttons, ecoSoft } = props

  let history = useHistory()
  const classes = useStyles({ ecoSoft })
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const { openSideBar, setOpenSideBar } = useContext(OpenNavbar)

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar)
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const backToLogin = () => {
    history.goBack()
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchororigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {buttons &&
        buttons.map((button, index) => {
          return (
            <MenuItem
              key={`navbar-item-${index}`}
              style={{ width: "100%" }}
              component="button"
              onClick={() => {
                button.onClick()
                handleMobileMenuClose()
              }}
              disabled={button.disabled}
            >
              {button.menuIcon && (
                <ListItemIcon>{button.menuIcon}</ListItemIcon>
              )}
              <Typography variant="inherit">{button.menuTitle}</Typography>
            </MenuItem>
          )
        })}
    </Menu>
  )

  return (
    <div
      style={
        ecoSoft
          ? { position: "fixed", backgroundColor: "white", zIndex: 1000 }
          : null
      }
    >
      <AppBar
        className={classes.appBar}
        style={{ color: "black" }}
        elevation={0}
        // position={ecoSoft ? null : "absolute"}
      >
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            onClick={handleOpenSideBar}
          >
            <MenuIcon />
          </IconButton>
          <Hidden xsDown>
            <Tooltip title="Volver">
              <Button
                onClick={() => backToLogin()}
                variant="contained"
                color="primary"
                className={classes.buttonLeftBack}
              >
                <ArrowBackIcon />
              </Button>
            </Tooltip>
          </Hidden>
          <Typography className={classes.title} variant="h4" noWrap>
            {title}
          </Typography>
          <Typography
            className={classes.title}
            color="primary"
            variant="h4"
            noWrap
          >
            /
          </Typography>
          <Typography className={classes.subTitle} variant="subtitle2" noWrap>
            {subTitle}
          </Typography>
          {subTitle2 ? (
            <Typography
              className={classes.title}
              color="primary"
              variant="h4"
              noWrap
            >
              /
            </Typography>
          ) : null}

          <Typography className={classes.subTitle} variant="subtitle2" noWrap>
            {subTitle2}
          </Typography>
          <div className={classes.grow} />
          {buttons && (
            <>
              <div className={classes.sectionDesktop}>
                {buttons?.map((item, index) => (
                  <Grid
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    key={`navbar-button-${index}`}
                  >
                    <Grid item style={{ padding: "13px" }}>
                      <Button
                        variant={item.buttonVariant || "contained"}
                        disabled={item.disabled}
                        style={item.buttonStyle}
                        onClick={item.onClick}
                        startIcon={item.buttonStartIcon}
                      >
                        {item.buttonContent}
                      </Button>
                    </Grid>{" "}
                  </Grid>
                ))}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  )
}
export default NavBarGlobal
