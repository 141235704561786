import { React, forwardRef } from "react"
import { Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { updateAddress } from "Components/Validation/Settings/Various/PLA"

const Form = forwardRef((props, ref) => {
  const { onSubmit, data } = props
  const info = data()
  const OBJETO = (values) => {
    return [
      {
        class: "textfield",
        name: "calle",
        label: "Calle",
        value: values.calle,
        sm: 12,
        xs: 12,
      }
    ]
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          calle: info.calle
        }}
        validationSchema={updateAddress}
        onSubmit={onSubmit}
      >
        {({ submitForm, values, setFieldValue, isSubmitting }) => (
          <>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form