import React, { useState } from "react"
import { useSnackbar } from "notistack"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
const ButtonDelete = (props) => {
  const { open, handleClose, id, handleResetSelect } = props
  const { enqueueSnackbar } = useSnackbar()
  function handleReset() {
    handleClose()
    handleResetSelect()
  }

  const [loading, setLoading] = useState(false)

  async function handleDelete(id) {
    setLoading(true)
    await interfaces.configInterface
      .deleteCompany(id)
      .then((res) => {
        enqueueSnackbar(res.data, { variant: "success" })
        handleReset()
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, { variant: "error" })
      )
    setLoading(false)
  }
  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      title="¿Desea eliminar esta compañia?"
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <>
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={() => handleDelete(id)}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </>
      }
    />
  )
}

export default ButtonDelete
