import React, { useState, useRef } from "react"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavBarGlobal from "Components/UI/Molecules/NavBar"
import OrganismPrice from "Components/UI/Organisms/Sales/Prices"

import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"

import AddIcon from "@material-ui/icons/Add"
import { Button } from "@material-ui/core"

import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

import { formNew } from "Components/UI/Organisms/Sales/Prices/Components/UpdateBoard/Fields"

import { newRowTableUpdate } from "Components/Validation/Sales/Prices"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { oemColorButton } from "Helpers"

const PriceTemplate = () => {
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)
  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const [idEdit, setIdEdit] = useState(null)
  const [render, setRender] = useState(false)
  const [dataAutocomplete, setDataAutocomplete] = useState([])

  const {
    state: stateNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  function handleNewControl(values) {
    interfaces.salesPrice
      .createControl(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setIdEdit(null)
        setCloseNew()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar("error", {
          variant: "error",
        })
      })
  }

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Ventas"
            subTitle="Precios"
            buttons={[
              <Button
                onClick={() => {
                  setOpenNew()
                }}
                variant="contained"
                style={oemColorButton(pathname, location)}
                startIcon={<AddIcon />}
              >
                Nuevo
              </Button>,
            ]}
          />
        }
        table={
          <OrganismPrice
            dataAutocomplete={dataAutocomplete}
            setDataAutocomplete={setDataAutocomplete}
            idEdit={idEdit}
            setIdEdit={setIdEdit}
            render={render}
            setRender={setRender}
          />
        }
      />
      {stateNew.suspense && (
        <DialogComponent
          title="Nuevo control"
          open={stateNew.open}
          handleclose={setCloseNew}
          widthLarge="480px"
          children={formNew(
            handleNewControl,
            newRowTableUpdate,
            dataAutocomplete,
            formSubmitRef
          )}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          actions={
            <CancelConfirmButtons
              onClickCancel={setCloseNew}
              onClickSubmit={handleSubmit}
            />
          }
        />
      )}
    </>
  )
}

export default PriceTemplate
