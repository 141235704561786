import React, { useEffect } from "react"

import Various from "Components/UI/Templates/Settings/Various"

const VariousPage = () => {
  useEffect(() => {
    document.title = "Configuración"
  }, [])

  return <Various />
}

export default VariousPage
