import React, { useRef, useState } from "react"

import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import InputSearch from "Components/UI/Atoms/InputSearch"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { validationNewFamily } from "Components/Validation/Settings/Various/FamilyAndProducts"
import SetState from "Components/Hooks/SetState"
const Family = (props) => {
  const { data, onClickSelect, family, changeRender } = props
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const [change, setChange] = useState("")

  const {
    state: newFamily,
    setOpen: setOpenNewFamily,
    setClose: setCloseNewFamily
  } = SetState()

  const {
    state: deleteFamily,
    setOpen: setOpenDeleteFamily,
    setClose: setCloseDeleteFamily,
  } = SetState()

  function handleChange(e) {
    setChange(e.target.value)
  }
  const refSubmit = useRef(null)
  function handleRef() {
    refSubmit.current.click()
  }
  function handleOpenDelete() {
    setOpenDeleteFamily()
  }

  async function handleSubmitDelete() {
    setLoading(true)
    await interfaces.configInterface
      .deleteFamily(family)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        setLoading(false)
        setCloseDeleteFamily()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setLoading(false)
        setCloseDeleteFamily()
      })
  }
  async function handleSubmitNew(values) {
    setLoading(true)
    let rest = { descripcion: values.descripcion.toUpperCase() }
    await interfaces.configInterface
      .newFamilyOrProduct(rest)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
        setLoading(false)
        setCloseNewFamily()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        setLoading(false)
        setCloseNewFamily()
      })
  }
  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (change) {
          return e.descripcion.includes(change.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }
  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewFamily}
        onChange={handleChange}
        value={change}
      />
      <PaperList
        height={"600px"}
        children={
          <ListWithIcon
            data={filtered(data)}
            onClickSelect={onClickSelect}
            selectedItem={family}
            onClickDelete={handleOpenDelete}
            nameIncludes="descripcion"
          />
        }
      />
      <DialogComponent
        open={deleteFamily.open}
        handleclose={setCloseDeleteFamily}
        title="¿Desea eliminar esta familia?"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <CancelConfirmButtons
            onClickCancel={setCloseDeleteFamily}
            onClickSubmit={handleSubmitDelete}
            isLoading={loading}
          />
        }
      />
      <DialogComponent
        title="Nueva familia"
        open={newFamily.open}
        handleclose={setCloseNewFamily}
        children={
          <Formik
            initialValues={{
              descripcion: "",
            }}
            onSubmit={(values) => {
              handleSubmitNew(values)
            }}
            validationSchema={validationNewFamily}
          >
            <Form>
              <Field
                component={TextField}
                fullWidth
                name="descripcion"
                label="Nombre"
                variant="outlined"
              />
              <button
                style={{ display: "none" }}
                ref={refSubmit}
                type="submit"
              />
            </Form>
          </Formik>
        }
        actions={
          <CancelConfirmButtons
            onClickCancel={setCloseNewFamily}
            onClickSubmit={handleRef}
            isLoading={loading}
          />
        }
      />
    </>
  )
}

export default Family
