/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

import DialogUpdateXlsx from "./Inputs"
import { useState } from "react"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

const DialogUpdate = (props) => {
  const { handleClose, open } = props
  const [dataXlsx, setDataXlsx] = useState([])
  const [dataXlsxView, setDataXlsxView] = useState([])
  const [loading, setLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const closeAndClean = () => {
    handleClose()
    setDataXlsx([])
    setDataXlsxView([])
  }
  // eslint-disable-next-line
  useEffect(async () => {
    if (dataXlsx.length > 0) {
      const objectSubmit = dataXlsx.map((item) => {
        return {
          id: item.id,
          codigo: item.codigo,
          ubicacion_id: item.ubicacion_id,
          estado_id: item.estado_id,
        }
      })
      await interfaces.plantStorage
        .toUpdate(objectSubmit)
        .then((res) => {
          setDataXlsxView(res.data)
        })
        .catch((error) => {})
    }
  }, [dataXlsx])

  async function onSubmit() {
    setLoading(true)
    const objectSubmit = dataXlsxView.map((item) => {
      return {
        id: item.id,
        codigo: item.codigo,
        ubicacion_old: item.ubicacion_old,
        ubicacion_new: item.ubicacion_new,
        estado_old: item.estado_old,
        estado_new: item.estado_new,
        ubicacion_id: item.ubicacion_id,
        estado_id: item.estado_id,
      }
    })
    await interfaces.plantStorage
      .updateMassive(objectSubmit)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        closeAndClean()
      })
      .catch((err) => {
        createDismissableSnackbar(err.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const disabledSubmit = () => {
    if (dataXlsx.length === 0) return true
    if (loading) return true
    else return false
  }

  return (
    <DialogComponent
      open={open}
      handleclose={() => closeAndClean()}
      title="Actualizar stock"
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      widthLarge={dataXlsx.length > 0 ? "70vw" : "350px"}
      children={
        <DialogUpdateXlsx dataXlsx={dataXlsxView} setDataXlsx={setDataXlsx} />
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={() => closeAndClean()}
          onClickSubmit={onSubmit}
          isLoading={loading}
          disabledSubmit={disabledSubmit()}
          disabledCancel={loading}
          submitText="Siguiente"
        />
      }
    />
  )
}

export default DialogUpdate
