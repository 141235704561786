import React, { useState, useEffect } from "react"

import { withStyles } from "@material-ui/core/styles"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableFooter,
} from "@material-ui/core"
import { TextField } from "@material-ui/core"

import { sortArrayObjectsByProperty } from "Helpers"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const BasicTable = (props) => {
  const {
    rows: data,
    col,
    size = "medium",
    footer,
    stylePaper,
    fixed = true,
    onClickRow,
  } = props

  const [click, onClick] = useState(false)
  const [rows, setRows] = useState(data)
  const sortTable = (index) => {
    if (rows.length === 0) return

    setRows(
      sortArrayObjectsByProperty(rows, Object.keys(rows[0])[index], click)
    )
    onClick(!click)
  }

  const onClickTableRow = (item, index) => {
    if (onClickRow) {
      onClickRow(item, index)
    }
  }

  useEffect(() => {
    if (rows !== data) {
      setRows(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Paper style={stylePaper}>
      <Table
        style={fixed ? { tableLayout: "fixed" } : ""}
        size={size}
        stickyHeader
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#3E984C",
              height: "35px",
            }}
          >
            {col.map(({ title, style }, index) => (
              <TableCell style={style} onClick={() => sortTable(index)}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rIndex) => {
            let columns = Object.values(row)
            return (
              <StyledTableRow onClick={() => onClickTableRow(row, rIndex)}>
                {columns.map((c, index) => (
                  <TableCell style={col[index].style}>
                    {col[index].edit ? <TextField value={c} /> : c}
                  </TableCell>
                ))}
              </StyledTableRow>
            )
          })}
        </TableBody>
        {footer ? (
          <TableFooter>
            <TableRow
              style={{
                height: "35px",
              }}
            >
              {footer.map(({ title, style }, index) => (
                <TableCell style={style}>{title}</TableCell>
              ))}
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </Paper>
  )
}
export default BasicTable
