import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const DialogUpload = (props) => {
  const { open, handleClose, onSubmit, data, handleChange, change, loading } =
    props
  return (
    <DialogComponent
      open={open}
      handleclose={loading ? null : handleClose}
      title="Subir una foto"
      children={
        <form>
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="contained-button-file"
            name="file"
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="contained-button-file">
            {change !== null && change !== undefined ? (
              <Button
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                variant="contained"
                fullWidth
                component="span"
                disabled={loading}
              >
                Imagen seleccionada
              </Button>
            ) : (
              <Button variant="contained" fullWidth component="span">
                Selecciona una imagen
              </Button>
            )}
          </label>
        </form>
      }
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={() => {
              handleClose()
            }}
            onClickSubmit={() => {
              onSubmit(change.file, data.index)
            }}
            isLoading={loading}
            disabledCancel={loading}
            disabledSubmit={loading || change === null || change === undefined}
          />
        </div>
      }
    />
  )
}

export default DialogUpload
