import React from "react"

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Avatar,
  StepConnector,
} from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"

function LoadSteps(props) {
  const { step, steps } = props
  const iconComponent = (index, element) => {
    if (index < step) {
      return (
        <Avatar sizes="small" style={{ background: "green" }}>
          <DoneIcon />
        </Avatar>
      )
    }
    if (index === step) {
      return <CircularProgress />
    } else {
      return <Avatar>{element.step}</Avatar>
    }
  }
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        activeStep={step}
        orientation="vertical"
        connector={
          <StepConnector
            style={{
              display: "flex",
              color: "green",
              marginLeft: 19,
              marginTop: 13,
            }}
          />
        }
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={() => iconComponent(index, step)}>
              {step === index ? <b>{step.label}</b> : step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default LoadSteps
