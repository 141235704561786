const ONE_VEHICLE = "/vehicles/searchvehicles/onevehicle"
const VEHICLES = "/vehicles/searchvehicles"
const TABLE_FILTERS = "/vehicles/searchvehicles/filters"
const POST_NEW_VEHICLE = "/vehicles/searchvehicles/newvehicle"
const GET_NEW_VEHICLE = "/vehicles/searchvehicles/beforenewvehicle"
const EXPORT_ALL_VEHICLES = "/vehicles/searchvehicles/exportall"
const VEHICLE_STICKER = "/vehicles/searchvehicles/vehiclesticker"
const EDIT_INPUTS = "/vehicles/searchvehicles/editvehicleforms"
const VEHICLE_OFFER = "/vehicles/searchvehicles/offer"
const VEHICLE_CONTROL = "/vehicles/searchvehicles/control"
const VEHICLE_RETREAT = "/vehicles/searchvehicles/retreat"
const NEW_PAYMENT = "/vehicles/searchvehicles/payment"
const GET_ALL_PAYMENTS = "/vehicles/searchvehicles/payments"
const UPDATE_PAYMENT = "/vehicles/searchvehicles/payment"
const DELETE_PAYMENT = "/vehicles/searchvehicles/payment"
const GET_VEHICLES_PAYMENT = "/vehicles/searchvehicles/payment/vehicles"
const GET_COMPANIES = "/vehicles/searchvehicles/payment/companies"
const DNRPA_DATA = "/vehicles/searchvehicles/dnrpa/"
const ALL_SHIPPING = "/vehicles/searchvehicles/allshippings"
const NEW_SHIPPING = "/vehicles/searchVehicles/newshipping"
const SHIPPING_STICKER = "/vehicles/searchvehicles/sticker"
const UPDATE_SHIPPING = "/vehicles/searchVehicles/shipping"
const FRONT_04D = "/vehicles/searchvehicles/04front"
const BACK_04D = "/vehicles/searchvehicles/04back"
const DELETE_FILE = "/vehicles/searchvehicles/delete"
const UPLOAD_FILE = "/vehicles/searchvehicles/upload"
const BEFORE_QUOTATION = "/vehicles/searchvehicles/beforequotation"
const QUOTATION = "/vehicles/searchvehicles/createquotation"
const EXPORT_QUOTATIONS = "/vehicles/searchvehicles/listquotation"
const UPLOAD_ADDITIONAL = "/vehicles/searchvehicles/additionalphotos"
const DELETE_VEHICLE = "/vehicles/searchvehicles/"
const CONVERT_04BD = "/vehicles/searchvehicles/convert04bd"
const CONVERT_04NOT = "/vehicles/searchvehicles/convert04not"
const EXPORT_PAYMENTS = "/vehicles/searchvehicles/payment/export"
const VEHICLES_STOCK = "/vehicles/searchvehicles/vehiclestock"
const TRANSFERED_STOCK = "/vehicles/searchvehicles/transfered/"
const RETURN_VEHICLES = "/vehicles/searchvehicles/return/"
const EXPORT_ALL_SHIPPING = "/vehicles/searchvehicles/exportallshipping"
const EXPORT_ALL_RETREATS = "/vehicles/searchvehicles/exportallretreats"

export const searchvehiclesEndpoints = {
  ONE_VEHICLE,
  VEHICLES,
  TABLE_FILTERS,
  POST_NEW_VEHICLE,
  GET_NEW_VEHICLE,
  EXPORT_ALL_VEHICLES,
  ALL_SHIPPING,
  VEHICLE_STICKER,
  SHIPPING_STICKER,
  EDIT_INPUTS,
  VEHICLE_OFFER,
  VEHICLE_CONTROL,
  VEHICLE_RETREAT,
  NEW_PAYMENT,
  GET_ALL_PAYMENTS,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  DNRPA_DATA,
  NEW_SHIPPING,
  UPDATE_SHIPPING,
  FRONT_04D,
  BACK_04D,
  DELETE_FILE,
  UPLOAD_FILE,
  BEFORE_QUOTATION,
  QUOTATION,
  GET_COMPANIES,
  GET_VEHICLES_PAYMENT,
  EXPORT_QUOTATIONS,
  UPLOAD_ADDITIONAL,
  DELETE_VEHICLE,
  CONVERT_04BD,
  CONVERT_04NOT,
  EXPORT_PAYMENTS,
  VEHICLES_STOCK,
  TRANSFERED_STOCK,
  RETURN_VEHICLES,
  EXPORT_ALL_SHIPPING,
  EXPORT_ALL_RETREATS
}
