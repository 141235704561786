const LIST = "/vehicles/compaction/list"
const CREATE = "/vehicles/compaction/create"
const UPDATE = "/vehicles/compaction"
const PRINT = "/vehicles/compaction/print/"
const EXPORT_ALL = "/vehicles/compaction/export"
const COMPACTION_ACT = "/vehicles/compaction" 
const DELETE = "/vehicles/compaction/"
const CONFIRM_ACT = "/vehicles/compaction/confirm/"
const PENDING_LIST = "/vehicles/compaction/pending/"
const ADD_DETAILS = "/vehicles/compaction/pending/"
const REMOVE_PENDING = "/vehicles/compaction/pending/remove/"
const REPORT = "/vehicles/compaction/report"

export const compactionEndpoints = {
    LIST,
    CREATE,
    UPDATE,
    PRINT,
    EXPORT_ALL,
    COMPACTION_ACT,
    DELETE,
    CONFIRM_ACT,
    PENDING_LIST,
    ADD_DETAILS,
    REMOVE_PENDING,
    REPORT
}