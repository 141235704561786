import React, { useRef } from "react"
import { Formik, Form } from "formik"

import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { OrderClone } from "Components/Validation/Plant/Grill"
import { UBICACIONES_MANUAL } from "Constants"

const CloneGrill = (props) => {
  const { id, open, onClose, onSubmit, render, setRender } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const fieldOrderClone = () => [
    {
      class: "autocomplete",
      label: "Desde",
      name: "origen_id",
      options: UBICACIONES_MANUAL,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Hasta",
      name: "destino_id",
      options: UBICACIONES_MANUAL,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "observacion_ventas",
      label: "Observaciones ventas",
      sm: 12,
      xs: 12,
    },
  ]

  function submitOrderClone(values) {
    interfaces.plantOrders
      .duplicateOrder(id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        onSubmit()
        onClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  return (
    <DialogComponent
      open={open}
      title="Duplicar pedido"
      widthLarge="500px"
      handleclose={onClose}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      children={
        <Formik
          initialValues={{
            origen_id: null,
            destino_id: null,
            observacion_ventas: "",
          }}
          onSubmit={(values) => submitOrderClone(values)}
          validationSchema={OrderClone}
        >
          {({ setFieldValue }) => (
            <Form>
              <InputsWithValidate
                fields={fieldOrderClone()}
                spacing={2}
                setFieldValue={setFieldValue}
              />
              <button
                style={{ display: "none" }}
                type="submit"
                ref={formSubmitRef}
              />
            </Form>
          )}
        </Formik>
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={onClose}
        />
      }
    />
  )
}

export default CloneGrill
