import React, { useRef, useState } from "react"

import { Form, Formik } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

import { TIPOS_REMITO, ORIGEN_DESTINO, EMPRESAS_STOCK } from "Constants"
import { interfaces } from "service/interfaces"
import { getUbicacion } from "service/helpers/localstorage"
import { validateNewDespatch } from "Components/Validation/Plant/Despatch"

export default function DialogNew(props) {
  const { open, handleClose, moreInfo, setRender, render } = props

  const [clients, setClients] = useState([
    { id: 1, nombre: "CONSUMIDOR FINAL" },
  ])
  const [type, setType] = useState(2)

  const [loading, setLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()
  const submitRef = useRef(null)

  function handleRef() {
    submitRef.current.click()
  }

  async function getClient(search) {
    await interfaces.plantDespatch
      .getClient(search)
      .then((res) => {
        setClients(res.data.clients)
      })
      .catch((error) => {
        createDismissableSnackbar(`${error.data}`, {
          variant: "error",
        })
      })
  }

  const location = getUbicacion()

  const onChange = (setFieldValue, id) => {
    setFieldValue("tipo", id)
    setType(id)
  }

  const fields = (values, setFieldValue) => {
    return [
      {
        class: "custom",
        xs: 12,
        sm: 6,
        component: (
          <FormikAutocomplete
            name="empresa"
            defaultValue={EMPRESAS_STOCK.find((e) => {
              return e.id === 1
            })}
            label="Empresa"
            options={EMPRESAS_STOCK}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value) => {
              setFieldValue("empresa", value.id)
            }}
          />
        ),
      },
      {
        class: "custom",
        xs: 12,
        sm: 6,
        component: (
          <FormikAutocomplete
            name="tipo"
            defaultValue={TIPOS_REMITO.find((e) => {
              return e.id === 2
            })}
            label="Tipo"
            options={TIPOS_REMITO}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value) => {
              onChange(setFieldValue, value.id)
            }}
          />
        ),
      },
      {
        class: "custom",
        xs: 12,
        sm: 6,
        display: values.tipo === 2 ? true : false,
        component: (
          <FormikAutocomplete
            name="origen_id"
            defaultValue={ORIGEN_DESTINO.find((e) => {
              return e.optionLabel === location
            })}
            label="Origen"
            options={ORIGEN_DESTINO}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value) => {
              setFieldValue("origen_id", value.id)
            }}
          />
        ),
      },
      {
        class: "custom",
        xs: 12,
        sm: 6,
        display: values.tipo === 2 ? true : false,
        component: (
          <FormikAutocomplete
            name="destino_id"
            label="Destino"
            options={ORIGEN_DESTINO}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value) => {
              setFieldValue("destino_id", value.id)
            }}
          />
        ),
      },
      {
        class: "custom",
        xs: 12,
        sm: 12,
        display: values.tipo === 1 ? true : false,
        component: (
          <FormikAutocomplete
            disabled={true}
            inputValue={values.search}
            name="cliente_id"
            disableClearable={false}
            label="Cliente"
            options={clients}
            onKeyDown={async (event) => {
              if (event.key === "Enter") {
                if (values.cliente_id) return
                setFieldValue("cliente_id", null)
                setFieldValue("search", "")
                await getClient(values.search)
                if (clients.length === 1) {
                  setFieldValue("cliente_id", clients[0].id)
                }
              }
            }}
            defaultValue={clients.find((e) => {
              return e.id === 1
            })}
            noOptionsText={"No hay resultados."}
            getOptionLabel={(e) => e.nombre}
            onChange={(_, value, reason) => {
              if (reason === "clear") {
                setFieldValue("cliente_id", null)
                setFieldValue("search", "")
                setClients([])
              } else {
                setFieldValue("cliente_id", value.id)
                setFieldValue("search", value.nombre)
              }
            }}
          />
        ),
      },
      {
        class: "custom",
        display: values.tipo === 3 ? true : false,
        sm: 12,
        xs: 12,
        component: (
          <FormikAutocomplete
            name="destino_id"
            label="Destino"
            options={ORIGEN_DESTINO}
            getOptionLabel={(e) => e.optionLabel}
            onChange={(_, value) => {
              setFieldValue("destino_id", value.id)
            }}
          />
        ),
      },
    ]
  }

  async function handleSubmit(values, { resetForm }) {
    function renderInitialValues() {
      switch (values.tipo) {
        case 1:
          return {
            empresa: values.empresa,
            tipo: values.tipo,
            cliente_id: values.cliente_id,
          }
        case 2:
          return {
            empresa: values.empresa,
            tipo: values.tipo,
            destino_id: values.destino_id,
            origen_id: values.origen_id,
          }
        case 3:
          return {
            empresa: values.empresa,
            tipo: values.tipo,
            destino_id: values.destino_id,
          }

        default:
          break
      }
    }
    setLoading(true)
    await interfaces.plantDespatch
      .newDespatch(renderInitialValues())
      .then((res) => {
        createDismissableSnackbar(res.data.msg, {
          variant: "success",
        })
        handleClose()
        moreInfo(res.data.id)
        resetForm()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
        handleClose()
      })
    setLoading(false)
  }

  return (
    <DialogComponent
      title="Nuevo remito"
      open={open}
      widthLarge="40em"
      handleclosedisabled={loading}
      handleclose={handleClose}
      maxHeight
      children={
        <div style={{ height: "200px" }}>
          <Formik
            initialValues={{
              empresa: 1,
              tipo: 2,
              destino_id: null,
              cliente_id: 1,
              origen_id: ORIGEN_DESTINO.find((e) => e.optionLabel === location)
                ?.id,
              search: "CONSUMIDOR FINAL",
            }}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={validateNewDespatch[type - 1]}
          >
            {({ setFieldValue, values }) => (
              <Form
                onKeyDown={(e) => {
                  if (
                    e.code === "Enter" &&
                    values.tipo === 1 &&
                    values.cliente_id === null
                  ) {
                    e.preventDefault()
                  } else {
                    return e
                  }
                }}
              >
                <InputsWithoutValidate
                  setFieldValue={setFieldValue}
                  fields={fields(values, setFieldValue)}
                  spacing={3}
                />
                <button
                  ref={submitRef}
                  tipo="submit"
                  style={{ display: "none" }}
                />
              </Form>
            )}
          </Formik>
        </div>
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={handleClose}
          onClickSubmit={handleRef}
          submitText="Crear"
          isLoading={loading}
          disabledCancel={loading}
          disabledSubmit={loading}
        />
      }
    />
  )
}
