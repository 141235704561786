import { vehicleInterface } from "./vehicles/searchvehicles"
import { quotationsInterface } from "service/interfaces/vehicles/quotations"
import { dashboardInterface } from "./dashboard"
import { logInInterface } from "./login"
import { wafersInterface } from "./vehicles/searchwafers"
import { transitInterface } from "./vehicles/vehicletransit"
import { configInterface } from "./config"
import { interfaceEngine } from "service/interfaces/config/engine"
import { interfaceTypeVehicle } from "service/interfaces/config/typevechicle"
import { compactionInterface } from "service/interfaces/vehicles/compaction"

import { plantInterfaceClassification } from "./plant/Classification"
import { plantInterfaceStock } from "service/interfaces/plant/stock"

import { plantCompaction } from "./plant/Compaction"
import { plantStorage } from "service/interfaces/plant/storage"
import { plantChecklist } from "service/interfaces/plant/Checklist"
import { configUsersInterface } from "service/interfaces/config/users"
import { plantOrders } from "service/interfaces/plant/Orders"
import { operatorsInterface } from "service/interfaces/config/various/operators"

import { plantDespatch } from "service/interfaces/plant/Despatch"
import { plantUsed } from "service/interfaces/plant/used"

import { salesStock } from "service/interfaces/sales/stock"
import { salesPrice } from "service/interfaces/sales/price"
import { salesClients } from "service/interfaces/sales/clients"
import { salesSuppliers } from "service/interfaces/sales/suppliers"

import { plantStation } from "service/interfaces/plant/Station"
import { ecoSoft } from "service/interfaces/ecosoft"
import { conceptsInterface } from "./config/various/concepts"

export const interfaces = {
  vehicleInterface,
  quotationsInterface,
  dashboardInterface,
  logInInterface,
  wafersInterface,
  transitInterface,
  configInterface,
  interfaceEngine,
  plantInterfaceClassification,
  plantInterfaceStock,
  plantCompaction,
  plantStorage,
  plantChecklist,
  configUsersInterface,
  plantOrders,
  operatorsInterface,
  plantDespatch,
  salesStock,
  plantUsed,
  plantStation,
  ecoSoft,
  salesPrice,
  salesClients,
  interfaceTypeVehicle,
  conceptsInterface,
  compactionInterface,
  salesSuppliers,
}
