import React, { useRef, useState } from "react"
import { Formik, Form } from "formik"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { newStorageValidation } from "Components/Validation/Plant/Storage"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { printPDF } from "Helpers"
import Loader from "Components/UI/Atoms/Loader"
const NewStorage = (props) => {
  const { render, setRender, open, handleclose } = props
  const formSubmitRef = useRef(null)
  const [wait, setWait] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const createDismissableSnackbar = useCustomSnackbar()
  async function printId(id, e) {
    setWait(true)
    await interfaces.plantStorage
      .storageSticker(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      })
      .catch((error) =>
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      )
    setWait(false)
  }
  const onSubmit = async (values, actions) => {
    setLoading(true)
    let values1 = {
      empresa: values.empresa,
      almacen: values.almacen,
      nivel: values.nivel,
      limite: values.limite,
      estanteria: values.estanteria,
      columna: values.columna,
      fila: values.fila,
      descripcion: values.descripcion,
    }
    let values2 = {
      empresa: values.empresa,
      almacen: values.almacen,
      nivel: values.nivel,
      limite: values.limite,
      bin: values.bin,
      descripcion: values.descripcion,
    }
    await interfaces.plantStorage
      .newStorage(values.bin === null ? values1 : values2)
      .then((res) => {
        createDismissableSnackbar(res.data.msg, {
          variant: "success",
        })
        setRender(!render)
        printId(res.data.id)
        actions.resetForm({
          values: {
            almacen: values.almacen,
            nivel: values.nivel,
            estanteria: null,
            columna: null,
            fila: null,
            limite: null,
            bin: null,
            descripcion: null,
            empresa: values.empresa,
          },
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }
  const OBJETO = (values, setFieldValue) => [
    {
      class: "autocomplete",
      name: "empresa",
      label: "Empresa",
      sm: 12,
      xs: 12,
      optionLabel: `options["nombre"]`,
      options: [
        { id: 1, nombre: "ECO" },
        { id: 2, nombre: "OEM" },
      ],
      optionValue: "id",
      value: values.empresa,
      onChange: (e) => setFieldValue("empresa", e.id),
    },
    {
      class: "textfield",
      name: "almacen",
      label: "Almacén",
      type: "number",
      sm: 6,
      xs: 12,
      inputProps: { maxLength: 12 },
      value: values.almacen || "",
    },
    {
      class: "textfield",
      name: "nivel",
      label: "Nivel",
      type: "number",
      sm: 6,
      xs: 12,
      value: values.nivel || "",
    },
    {
      class: "textfield",
      name: "estanteria",
      label: "Estantería",
      type: "number",
      disabled: values.bin ? true : false,
      sm: 6,
      xs: 12,
      value: values.estanteria || "",
    },
    {
      class: "textfield",
      name: "columna",
      label: "Columna",
      type: "number",
      disabled: values.bin ? true : false,
      sm: 6,
      xs: 12,
      value: values.columna || "",
    },
    {
      class: "textfield",
      name: "fila",
      label: "Fila",
      type: "number",
      disabled: values.bin ? true : false,
      sm: 6,
      xs: 12,
      value: values.fila || "",
    },
    {
      class: "textfield",
      name: "limite",
      label: "Límite",
      type: "number",
      sm: 6,
      xs: 12,
      value: values.limite || "",
    },
    {
      class: "textfield",
      name: "bin",
      label: "BIN",
      disabled:
        values.estanteria || values.columna || values.fila ? true : false,
      sm: 12,
      xs: 12,
      value: values.bin || "",
    },
    {
      class: "textfield",
      name: "descripcion",
      label: "Descripción",
      sm: 12,
      xs: 12,
      value: values.descripcion || "",
    },
  ]
  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          almacen: null,
          nivel: null,
          estanteria: null,
          columna: null,
          fila: null,
          limite: null,
          bin: null,
          descripcion: null,
          empresa: null,
        }}
        validationSchema={newStorageValidation}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        handleclosedisabled={loading}
        disableBackdropClick
        disableEscapeKeyDown
        title="Nuevo almacén"
        maxHeight
        children={children()}
        widthLarge="500px"
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleclose}
              onClickSubmit={handleSubmit}
              isLoading={loading}
              disabledCancel={loading}
              disabledSubmit={loading}
            />
          </div>
        }
      />
      {wait ? <Loader /> : null}
    </>
  )
}
export default NewStorage
