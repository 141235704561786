import React from "react"

import { TextField } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

const Andreani = (props) => {
  const { open, info, handleClose } = props

  const lines = String(info.ubicaciones)

  const text = lines.replaceAll(",", `\n`)

  return (
    <DialogComponent
      open={open}
      widthLarge="500px"
      title="Consulta ANDREANI"
      handleclose={handleClose}
      children={
        <>
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Importe s/IVA"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={info.importe}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Importe c/IVA"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={info.importe_iva}
          />
          <TextField
            margin="normal"
            fullWidth
            variant="outlined"
            label="Frecuencia"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={info.dias}
          />
          <TextField
            rows={8}
            margin="normal"
            fullWidth
            multiline
            variant="outlined"
            label="Posibles localidades"
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={text}
          />
        </>
      }
    />
  )
}
export default Andreani
