import React from "react"

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import { Grid, Typography, ListItemIcon } from "@material-ui/core"

import CustomButton from "Components/UI/Atoms/CustomButton"
import DialogRefund from "./DialogDevolution"
import SetState from "Components/Hooks/SetState"

const Devolucion = () => {
  const { state, setOpen, setClose } = SetState()

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12} xs={12} style={{ marginTop: "5%" }}>
          <CustomButton
            color="primary"
            text="Devolución"
            onClick={() => setOpen()}
          />
        </Grid>
        <ListItemIcon style={{ marginTop: "15%" }}>
          {<ArrowUpwardIcon style={{ fontSize: 100 }} />}
        </ListItemIcon>
        <div style={{ marginTop: "20%" }}>
          <Grid item sm={12} xs={12}>
            <Typography variant="h6" align="center">
              <strong>
                Ingreso de piezas que fueron facturadas y devueltas
              </strong>
            </Typography>
          </Grid>
        </div>
      </Grid>
      {state.suspense && <DialogRefund open={state.open} setClose={setClose} />}
    </>
  )
}

export default Devolucion
