import React, { useContext } from "react"

import { Grid } from "@material-ui/core"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import es from "date-fns/locale/es"

import useStyles from "../styles"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const Dates = () => {
  const [edit] = useContext(EditContext)

  const classes = useStyles()
  const { formEdit, formApi, setFormEdit, setFormApi } = useContext(FormContext)
  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_fecha: {
          ...formEdit.vehicle.vehiculo_fecha,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_fecha: {
        ...formApi.vehiculo_fecha,
        [e.target.name]: e.target.value,
      },
    })
  }
  const handleChangeStates = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const BAJA_EXTRAVIADA = [
    {
      id: 0,
      label: "NO",
    },
    {
      id: 1,
      label: "SI",
    },
  ]
  return (
    <div noValidate autoComplete="off" className={classes.overflowDesactivo}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.paddingGrids}
        >
          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_cotizacion" },
                })
              }
              disabled={edit ? false : true}
              name="fecha_cotizacion"
              label="Cotización"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_cotizacion
                  ? formEdit.vehicle.vehiculo_fecha.fecha_cotizacion
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_adjudicacion" },
                })
              }
              disabled={
                edit && formEdit.vehicle.vehiculo_fecha.fecha_cotizacion
                  ? false
                  : true
              }
              name="fecha_adjudicacion"
              label="Adjudicación"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_adjudicacion
                  ? formEdit.vehicle.vehiculo_fecha.fecha_adjudicacion
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_retiro" },
                })
              }
              disabled={
                edit && formEdit.vehicle.vehiculo_fecha.fecha_adjudicacion
                  ? false
                  : true
              }
              name="fecha_retiro"
              label="Retiro"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_retiro
                  ? formEdit.vehicle.vehiculo_fecha.fecha_retiro
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_ingreso" },
                })
              }
              disabled={true}
              name="fecha_ingreso"
              label="Ingreso predio"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_ingreso
                  ? formEdit.vehicle.vehiculo_fecha.fecha_ingreso
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_baja" },
                })
              }
              disabled={!edit}
              name="fecha_baja"
              label="Ingreso baja"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_baja
                  ? formEdit.vehicle.vehiculo_fecha.fecha_baja
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_obleas" },
                })
              }
              disabled={
                edit && formEdit.vehicle.vehiculo_fecha.fecha_baja
                  ? false
                  : true
              }
              name="fecha_obleas"
              label="Asignación obleas"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_obleas
                  ? formEdit.vehicle.vehiculo_fecha.fecha_obleas
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_planilla" },
                })
              }
              disabled={
                edit && formEdit.vehicle.vehiculo_fecha.fecha_baja
                  ? false
                  : true
              }
              name="fecha_planilla"
              label="Envio planilla registro"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_planilla
                  ? formEdit.vehicle.vehiculo_fecha.fecha_planilla
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              PopoverProps={{ style: { ...{ left: "40px" } } }}
              fullWidth
              onChange={(date) =>
                handleChange({
                  target: { value: date, name: "fecha_tramite" },
                })
              }
              disabled={
                edit && formEdit.vehicle.vehiculo_fecha.fecha_baja
                  ? false
                  : true
              }
              name="fecha_tramite"
              label="Finalización trámite DNRPA"
              format="dd/MM/yyyy"
              value={
                formEdit.vehicle.vehiculo_fecha.fecha_tramite
                  ? formEdit.vehicle.vehiculo_fecha.fecha_tramite
                  : null
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CustomAutocomplete
              name="baja_extraviada"
              label="Baja extraviada"
              disabled={edit ? false : true}
              options={BAJA_EXTRAVIADA}
              value={BAJA_EXTRAVIADA.find(
                (index) => index.id === formEdit.vehicle.baja_extraviada
              )}
              getOptionLabel={(options) => options.label}
              onChange={(event, value) => {
                handleChangeStates({
                  target: { name: "baja_extraviada", value: value.id },
                })
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}></Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default Dates
