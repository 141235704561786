import React, { useState, memo, useRef } from "react"
import PropTypes from "prop-types"
import styles from "./style.css"
import { useWindowSize } from "react-use"

const ImgZoomStock = (props) => {
  const [state, setState] = useState({
    zoom: false,
    mouseX: null,
    mouseY: null,
  })

  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const {
    img,
    transitionTime,
    zoomScale,
    height = windowHeight,
    width = windowWidth,
    isFullscreen,
  } = props

  const outerDivStyle = {
    height: !isFullscreen ? `${height}` : `${windowHeight}px`,
    width: !isFullscreen ? `${width}` : `${windowWidth}px`,
    overflow: "hidden",
    objectFit: "contain",
  }

  const innerDivStyle = {
    height: !isFullscreen ? `${height}` : `${windowHeight}px`,
    width: !isFullscreen ? `${width}` : `${windowWidth}px`,
    objectFit: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundImage: `url('${img}')`,
    transition: `transform ${transitionTime}s ease-out`,
  }

  const imageRef = useRef(null)

  const handleMouseOver = () => {
    setState({
      ...state,
      zoom: true,
    })
  }

  const handleMouseOut = () => {
    setState({
      ...state,
      zoom: false,
    })
  }

  const handleMouseMovement = (e) => {
    const { left: offsetLeft, top: offsetTop } =
      imageRef.current.getBoundingClientRect()

    const {
      current: {
        style: { height, width },
      },
    } = imageRef

    const x = ((e.pageX - offsetLeft) / parseInt(width, 10)) * 100
    const y = ((e.pageY - offsetTop) / parseInt(height, 10)) * 100

    setState({
      ...state,
      mouseX: x,
      mouseY: y,
    })
  }

  const transform = {
    transformOrigin: `${state.mouseX}% ${state.mouseY}%`,
  }

  return (
    <div
      style={outerDivStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageRef}
    >
      <div
        style={{
          ...transform,
          ...innerDivStyle,
          transform: state.zoom ? `scale(${zoomScale})` : "scale(1.0)",
        }}
        className={styles.zoomImg}
      />
    </div>
  )
}

ImgZoomStock.propTypes = {
  img: PropTypes.string.isRequired,
  zoomScale: PropTypes.number.isRequired,
  transitionTime: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

ImgZoomStock.defaultProps = {
  transitionTime: 0.1,
}

export default memo(ImgZoomStock)
