import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "600px",
  },
  div: {
    height: 500,
    margin: theme.spacing(3),
    overflow: "auto",
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
  buttonsWafers: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "20px",
  },
}))

export default useStyles
