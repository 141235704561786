import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1200,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  header: {
    color: "white",
    display: "flex",
    alignItems: "center",
    height: 50,
    width: "100%",
    paddingLeft: theme.spacing(4),
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    position: "absolute",
    top: 0,
    left: 0,
  },
  divImgSwipe: {
    height: "65vh",
    overflowY: "hidden",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
  },
  img: {
    maxWidth: "100vh",
    height: "auto",
    width: "auto",
    maxHeight: "100vh",
  },
  stepper: {
    position: "relative",
    top: 0,
    backgroundColor: "transparent",
  },
  buttonsContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    alignItems: "center",
  },
  button: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    color: "white",
    margin: "0 8px",
  },
}))
