import React, { useState, useContext, useEffect } from "react"

import { Hidden, IconButton, Button, Tooltip } from "@material-ui/core"
import SaveIcon from "@material-ui/icons/Save"
import EditIcon from "@material-ui/icons/Edit"
import ExitToApp from "@material-ui/icons/ExitToApp"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import MenuItem from "Components/UI/Molecules/MenuItem"
import { EditContext } from "Components/Hooks/ContextEdit"
import { useStyles } from "./styles"
import { compareStateValues } from "Helpers"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"

const ComplexDialogActions = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()

  const {
    onClose,
    canEdit,
    editMode,
    data,
    handleSubmit,
    leftButton,
    rightButton,
    extraCompare,
    firstButton,
    loading = false,
  } = props

  const classes = useStyles()

  const [edit, setEdit, isEdited, setIsEdited] = useContext(EditContext)

  const [enableEdit, setEnableEdit] = useState(editMode)
  const { state: openCancel, setOpen: setOpenCancel, setClose: setCloseCancel } = SetState()
  const [originalData] = useState(data)

  const closeAll = () => {
    createDismissableSnackbar("Cambios descartados!", {
      variant: "warning",
    })
    setCloseCancel()
    setTimeout(() => {
      onClose()
    }, 250)
  }

  useEffect(() => {
    if (compareStateValues(data, originalData) || extraCompare) {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
    // eslint-disable-next-line
  }, [data])

  const buttonsMobile = [
    firstButton && {
      icon: firstButton.icon,
      title: firstButton.title,
      onClick: () => {
        firstButton.onClick()
      },
    },
    {
      icon: <ExitToApp fontSize="small" />,
      title: isEdited ? "Cancelar" : "Salir",
      onClick: () => {
        onClose()
      },
    },
    {
      icon: <SaveIcon fontSize="small" />,
      title: "Guardar",
      disabled: isEdited ? false : true,
      onClick: () => {
        handleSubmit()
      },
    },
    leftButton && {
      icon: leftButton.icon,
      title: leftButton.title,
      onClick: () => {
        leftButton.onClick()
      },
    },
    rightButton && {
      icon: rightButton.icon,
      title: rightButton.title,
      onClick: () => {
        rightButton.onClick()
      },
    },
  ]

  const enableEdition = () => {
    setEdit(!edit)
    setEnableEdit(!enableEdit)
  }

  const disabledSubmit = () => {
    if (!isEdited) return true
    if (loading) return true
    else return false
  }

  return (
    <>
      {!enableEdit && <MenuItem buttonsMobile={buttonsMobile} />}
      {enableEdit ? (
        <Tooltip title="Editar">
          <IconButton
            onClick={() => enableEdition()}
            style={
              canEdit
                ? {}
                : {
                    color: "white",
                    backgroundColor: "#3E984C",
                  }
            }
            disabled={canEdit}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Hidden xsDown>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              right: "30px",
              background: "white",
            }}
          >
            {firstButton && (
              <Button
                onClick={() => firstButton.onClick()}
                className={classes.textType}
                disabled={isEdited ? false : true}
                variant={firstButton.variant}
                color={firstButton.color}
                style={firstButton.style}
              >
                {firstButton.title}
              </Button>
            )}
            <CancelConfirmButtons
              onClickSubmit={() => handleSubmit()}
              onClickCancel={isEdited ? () => setOpenCancel(true) : onClose}
              cancelText={isEdited ? "Cancelar" : "Salir"}
              submitText={"Guardar"}
              disabledSubmit={disabledSubmit()}
              isLoading={loading}
            />
            {leftButton && (
              <Button
                onClick={() => leftButton.onClick()}
                className={classes.textType}
                disabled={leftButton.disabled}
                variant={leftButton.variant}
                color={leftButton.color}
                style={leftButton.style}
              >
                {leftButton.title}
              </Button>
            )}
            {rightButton && (
              <Button
                onClick={() => rightButton.onClick()}
                className={classes.textType}
                variant={rightButton.variant}
                color={rightButton.color}
                disabled={rightButton.disabled}
                style={{ marginLeft: "15px" }}
              >
                {rightButton.title}
              </Button>
            )}
          </div>
        </Hidden>
      )}
      <DialogComponent
        open={openCancel.open}
        title={"Tiene cambios sin guardar, ¿desea salir?"}
        subtitle={"Todos sus cambios se perderán!"}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        widthLarge={"500px"}
        actions={
          <CancelConfirmButtons
            onClickSubmit={closeAll}
            onClickCancel={setCloseCancel}
          />
        }
      />
    </>
  )
}

export default ComplexDialogActions
