import * as yup from "yup"

export const validationLogin = yup.object({
  user: yup
    .string()
    .matches(/[a-zA-ZñÑ]/, "Debe contener solo letras")
    .required("Este campo es obligatorio")
    .min(4, "Debe ingresar al menos 4 caracteres ")
    .max(24, "Debe ingresar menos de 24 caracteres"),
  password: yup
    .string()
    .min(6, "Debe ingresar al menos 6 caracteres")
    .max(21, "Debe ingresar menos de 20 caracteres")
    .required("Este campo es obligatorio"),
})

export const validationRemovePass = yup.object({
  username: yup
    .string()
    .min(4, "Usuario demasiado corto")
    .max(20, "Usuario demasiado largo")
    .required("Usuario requerido"),
})

export const validationNewPassword = yup.object({
  password: yup
    .string()
    .min(6, "Al menos 6 caracteres")
    .required("Este campo es obligatorio")
    .when("oldPassword", (oldPassword, field) =>
      oldPassword ? field.required() : field
    ),
  passwordConfirmation: yup
    .string()
    .min(6, "Al menos 6 caracteres")
    .oneOf([yup.ref("password"), null], "La contraseña no coincide")
    .required("Este campo es obligatorio"),
})
