import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "../Images/background_oem.jpg"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100%",
  },
}))

export default useStyles
