const NEW = "plant/despatch/new"
const GET_DATA = "plant/despatch/info/"
const LIST = "plant/despatch"
const ADD_DETAIL = "plant/despatch/detail/"
const DELETE_DETAIL = "plant/despatch/detail/"
const CONFIRM_PRINT = "plant/despatch/"
const UPDATE = "/plant/despatch/info"
const GET_CLIENT = "/plant/despatch/client"

export const plantEndpoints = {
  NEW,
  GET_DATA,
  LIST,
  ADD_DETAIL,
  DELETE_DETAIL,
  CONFIRM_PRINT,
  UPDATE,
  GET_CLIENT,
}
