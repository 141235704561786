import React, { useEffect, useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import NavbarGlobal from "Components/UI/Molecules/NavBar"
import Template from "Components/UI/Templates/Common"
import DialogNew from "Components/UI/Organisms/Plant/Despatch/DialogNew"
import DialogMoreInfo from "Components/UI/Organisms/Plant/Despatch/DialogMoreInfo"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Table from "Components/UI/Organisms/Plant/Despatch/Table"
import Loader from "Components/UI/Atoms/Loader"
import { interfaces } from "service/interfaces"
import { oemColorButton, printPDF } from "Helpers"
import EditContextProvider from "Components/Hooks/ContextEdit"
import SetState from "Components/Hooks/SetState"
import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"

const DespatchTemplate = () => {
  const createDismissableSnackbar = useCustomSnackbar()
  const [render, setRender] = useState(true)
  const [wait, setWait] = useState(false)

  const {
    state: moreInfoState,
    setOpen: setOpenMoreInfo,
    setClose: setCloseMoreInfo,
  } = SetState()
  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  useEffect(() => {}, [])

  async function handleImportData(id) {
    await interfaces.plantDespatch
      .getData(id)
      .then((res) => {
        setOpenMoreInfo({ dataObject: res.data })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  async function saveData(data) {
    await interfaces.plantDespatch
      .update(moreInfoState.dataObject.data.id, data.data)
      .then((res) => {
        setCloseMoreInfo()
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  async function print(id, e, data) {
    if (e) {
      e.stopPropagation()
    }
    setWait(true)
    if (data) {
      saveData(data)
    }
    await interfaces.plantDespatch
      .confirmAndPrint(id)
      .then((res) => {
        printPDF(res)
        createDismissableSnackbar("Remito generado con éxito!", {
          variant: "success",
        })
        setCloseMoreInfo()
        setRender(!render)
      })
      .catch(() => {
        createDismissableSnackbar("Este remito no contiene nada!", {
          variant: "error",
        })
      })
    setWait(false)
  }

  async function deleteDespatch(id, close) {
    await interfaces.plantDespatch
      .deleteRemit(id)
      .then((res) => {
        close()
        setCloseMoreInfo()
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const BUTTONS = [
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Nuevo",
      buttonStyle: oemColorButton(pathname, location),
      buttonStartIcon: <AddIcon />,
      onClick: setOpenNew,
    },
  ]

  return (
    <>
      <Template
        navbar={
          <NavbarGlobal title="Planta" subTitle="Remitos" buttons={BUTTONS} />
        }
        table={
          <Table
            moreInfo={handleImportData}
            setRender={setRender}
            render={render}
            print={print}
          />
        }
      />
      <DialogNew
        open={openNew.open}
        handleClose={setCloseNew}
        moreInfo={handleImportData}
        setRender={setRender}
        render={render}
      />
      {moreInfoState.suspense && (
        <EditContextProvider>
          <DialogMoreInfo
            open={moreInfoState.open}
            editData={moreInfoState.dataObject}
            handleClose={setCloseMoreInfo}
            save={saveData}
            print={print}
            deleteDespatch={deleteDespatch}
          />
        </EditContextProvider>
      )}
      {wait ? <Loader /> : null}
    </>
  )
}
export default DespatchTemplate
