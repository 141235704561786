import React from "react"

import { Grid } from "@material-ui/core"

import PictureCardWithZoom from "Components/UI/Atoms/PictureCard/PictureCardWithZoom"
import img1 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/Assets/1.jpg"
import img2 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/Assets/2.jpg"
import img3 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs//Assets/3.jpg"
import img4 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs//Assets/4.jpg"
import img5 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs//Assets/5.jpg"
import img6 from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs//Assets/6.jpg"

import { styles } from "./styles"

const MainFotograps = ({ edit, data, id }) => {

  const classes = styles()

  const titles = [
    {
      id: 1,
      name: "Foto frente",
      img: data.principales[0] ? data.principales[0] : img1,
    },
    {
      id: 2,
      name: "Foto izquierda",
      img: data.principales[1] ? data.principales[1] : img2,
    },
    {
      id: 3,
      name: "Foto atrás",
      img: data.principales[2] ? data.principales[2] : img3,
    },
    {
      id: 4,
      name: "Foto derecha",
      img: data.principales[3] ? data.principales[3] : img4,
    },
    {
      id: 5,
      name: "Foto motor",
      img: data.principales[4] ? data.principales[4] : img5,
    },
    {
      id: 6,
      name: "Foto interior",
      img: data.principales[5] ? data.principales[5] : img6,
    },
  ]

  return (
    <div className={classes.root}>
      <Grid container alignContent="center" justify="center" spacing={2}>
        {titles.map((item, index) => (
          <Grid item xs={12} xl={4} sm={6} md={4}>
            <PictureCardWithZoom
              img={data.principales[index] ? data.principales[index] : item.img}
              editIcon
              alt={item.name}
              txt={item.name}
              dataUpload={item}
              index={index + 1}
              id={id}
              swipes={titles}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
export default MainFotograps
