import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"
import Create from "./Create"
import Delete from "./Delete"
import Edit from "./Edit"

const Rows = (props) => {
  const {
    data,
    handleRemoveClick,
    lotId,
    rowId,
    changeRender,
    handleListItemClick
  } = props
  const [ valueRow, setValueRow ] = useState("")
  
  const {
    state: newRowState,
    setOpen: setOpenNewRow,
    setClose: setCloseNewRow,
  } = SetState()

  const {
    state: editRowState,
    setOpen: setOpenEditRow,
    setClose: setCloseEditRow,
  } = SetState()

  const {
    state: deleteRowState,
    setOpen: setOpenDeleteRow,
    setClose: setCloseDeleteRow,
  } = SetState()

  const onChangeModel = (e) => {
    setValueRow(e.target.value)
  }

 
  function filtered(data) {
    if (!data) {
      return [];
    }
  
    return data.filter((e) => {
      if (!lotId) {
        return false;
      }
      const numeroString = String(e.numero)
  
      return (
        e.lote_id === lotId &&
        numeroString.includes(valueRow.toUpperCase())
      );
    });
  }

  function addressInfo() {
    return data.filter((e) => {
      return e.id === rowId
    })[0]
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewRow}
        onChange={onChangeModel}
        value={valueRow}
        disabledButton={lotId ? false : true}
      />
      <Create
        changeRender={changeRender}
        open={newRowState.open}
        handleClose={setCloseNewRow}
        indexModel={lotId}
      />
      <Delete
        open={deleteRowState.open}
        handleRemoveClick={handleRemoveClick}
        handleClose={setCloseDeleteRow}
        rowId={rowId}
        changeRender={changeRender}
      />
      <Edit
        rowId={rowId}
        changeRender={changeRender}
        open={editRowState.open}
        handleclose={setCloseEditRow}
        data={addressInfo}
      /> 
      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            search={valueRow}
            selectedItem={rowId}
            onClickSelect={handleListItemClick}
            onClickDelete={setOpenDeleteRow}
            onClickEdit={setOpenEditRow}
            nameIncludes="numero" 
          />

        }
        height="500px"
        text="Eliminar fila"
        disabledOn={rowId && handleListItemClick ? false : true}
        onClick={setOpenDeleteRow}
      />
    </>
  )
}
export default Rows
