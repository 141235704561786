import React, { useRef, useState } from "react"

import { Button } from "@material-ui/core"
import { Formik, Form } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { interfaces } from "service/interfaces"
import SetState from "Components/Hooks/SetState"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { createContact } from "Components/Validation/Sales/Suppliers"

const Table = (props) => {
    const { formEdit, isLoading, edit, setFormEdit, data, onDataChange, setIsLoading, setRender, render } = props
    
    const [loading, setLoading] = useState(true)
    const createDismissableSnackbar = useCustomSnackbar()

    const { state, setClose, setOpen } = SetState()
    const formSubmitRef = useRef(null)

    const handleSubmit = () => {
        formSubmitRef.current.click()
    }

    async function handleOpen() {
        setLoading(false)
        setOpen()
    }
    
    const fields = () => {

      return[
        {
          label: "Concepto",
          name: "concepto",
          sm: 12,
          xs: 12,
          class: "textfield",
        },
        {
          id: 1,
          label: "Contenido",
          name: "contenido",
          sm: 12,
          xs: 12,
          class: "textfield",
        }
    ]
    }

    const create = async (values) => {
        setIsLoading(true)
        await interfaces.salesSuppliers
          .createDetail(values)
          .then((res) => {
            const newDetail = values
            setFormEdit([...formEdit, newDetail])
            onDataChange([...formEdit, newDetail])
            setRender(!render)
            createDismissableSnackbar(res.data, {
              variant: "success",
            })
          })
          
          .catch(() => {
            createDismissableSnackbar("Error", {
              variant: "error",
            })
          })
        setIsLoading(false)
        setClose()
    }

    const children = (
        <Formik
        onSubmit={create}
        initialValues={{
            concepto: "",
            contenido: "",
            proveedor_id: data.info.id
        }}
        validationSchema={createContact}
        validateOnChange={false}
        validateOnBlur={false}
        >
        {({ setFieldValue, isSubmitting, values }) => (
            <Form>
            <InputsWithValidate
                fields={fields(values)}
                spacing={2}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
            />
            <button
                style={{ display: "none" }}
                type="submit"
                ref={formSubmitRef}
            />
            </Form>
        )}
        </Formik>
    )

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpen}
        disabled={isLoading || !edit}
      >
        AGREGAR
      </Button>
          
      <DialogComponent
          tipo="center"
          open={state.open}
          handleclose={setClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          title="Agregar información de contacto"
          widthLarge={"500px"}
          children={children}
          actions={
            <CancelConfirmButtons
            submitText={"Confirmar"}
            onClickCancel={setClose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
          />
          }
        />
    </>
  )
}

export default Table