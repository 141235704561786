import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  home: {
    margin: "30px",
    paddingTop: "5px",
    overflowY: "scroll ",
    height: "560px",
    [theme.breakpoints.only("xs")]: {
      margin: "30px 0px 30px 0px",
      padding: "0px 10px 0px 10px",
      paddingTop: "5px",
    },
  },
  div: {
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}))

export default useStyles
