import { React, useRef, useState } from "react"

import { Formik, Form } from "formik"
import { Grid } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import ButtonDelete from "./Delete"
import { interfaces } from "service/interfaces"
import useStyles from "./styles"
import { getUbicacion, getUserId } from "service/helpers/localstorage"

const DialogTable = (props) => {
  const { render, setRender, open, onClose, info } = props
  const classname = useStyles()

  const [newState, setNewState] = useState()
  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  function handleChangeSelect(value) {
    setNewState(value)
  }

  const onSubmitApi = () => {
    interfaces.transitInterface
      .updateTransit(info.id, {
        estado: newState,
      })
      .then(
        createDismissableSnackbar("Tránsito actualizado con éxito!", {
          variant: "success",
        }),
        setTimeout(() => {
          setRender(!render)
        }, 500)
      )
      .finally(onClose)
  }

  const options = [
    {
      estado: 1,
      label: "Tránsito pendiente",
    },
    {
      estado: 2,
      label: "Tránsito en curso",
    },
    {
      estado: 3,
      label: "Tránsito finalizado",
    },
  ]

  const OBJETO = () => [
    {
      class: "textfield",
      name: "desde",
      label: "Desde",
      value: info.desde,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "hasta",
      label: "Hasta",
      value: info.hasta,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "vehiculo",
      label: "Vehiculo",
      value: info.vehiculo,
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      name: "dominio",
      label: "Dominio",
      value: info.dominio,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "nrodev",
      label: "Nro de V",
      value: info.nrodev,
      disabled: true,
      sm: 6,
      xs: 12,
    },
    {
      class: "textfield",
      name: "descripcion",
      label: "Descripción",
      value: info.descripcion,
      disabled: true,
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      xs: 12,
      sm: 12,
      component: (
        <Grid item sm={12} xs={12}>
          <Autocomplete
            disabled={
              info.usuario_id !== parseInt(getUserId()) &&
              info.hasta !== getUbicacion()
                ? true
                : null
            }
            noOptionsText={"No se encontraron resultados"}
            options={options.filter((e) => {
              return e.estado >= info.estado
            })}
            getOptionLabel={(option) => option.label}
            defaultValue={options.find(
              (index) => index.estado === parseInt(info.estado)
            )}
            onChange={(e, value) => {
              handleChangeSelect(value.estado)
            }}
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                fullWidth
                label="Estado"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
      ),
    },
  ]

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmitApi}
        initialValues={{
          proveedor: "",
          fecha_factura: "",
          fecha_pago: "",
          suc_factura: "",
          nro_factura: "",
          monto_total: "",
          observaciones: "",
          tipo: "",
          vehiculos: [],
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={OBJETO(values)}
              spacing={1}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={onClose}
        title={"Tránsito #" + info.id}
        subtitle={info.hasOwnProperty("subtitulo") ? info.subtitulo : ""}
        widthLarge="600px"
        children={children()}
        maxHeight
        actions={
          <div className={classname.buttons}>
            <div className={classname.marginLeft}>
              <ButtonDelete
                idTransit={info.id}
                closeModal={onClose}
                disabled={
                  info.estado === 2 ||
                  info.estado === 3 ||
                  info.usuario_id !== parseInt(getUserId())
                    ? true
                    : null
                }
                setRender={setRender}
                render={render}
              />
            </div>
            <div className={classname.marginRight}>
              <CancelConfirmButtons
                onClickSubmit={handleSubmit}
                disabledSubmit={
                  newState === info.estado || newState === undefined
                    ? true
                    : false
                }
                onClickCancel={onClose}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default DialogTable
