import React, { useRef, useContext, useState } from "react"

import FormNew from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"
import { useSnackbar } from "notistack"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const TransitionsModal = (props) => {
  const { open, handleClose, handleCloseParent } = props
  const { formEdit } = useContext(FormContext)
  const id = formEdit.vehicle.id
  const { enqueueSnackbar } = useSnackbar()
  const formSubmitRef = useRef(null)

  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = (values) => {
    setLoading(true)
    interfaces.vehicleInterface
      .newShipping(id, values)
      .then((res) => {
        enqueueSnackbar(res.data, { variant: "success" })
        handleClose()
        handleCloseParent()
      })
      .catch((error) =>
        enqueueSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        tipo="center"
        widthLarge="40%"
        open={open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        handleclosedisabled={loading}
        handleclose={handleClose}
        title="Control de envíos"
        children={<FormNew ref={formSubmitRef} onSubmit={onSubmit} />}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default TransitionsModal
