import React, { useEffect, useState } from "react"
import { Formik } from "formik"

import { Grid, Button } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Inicio = (props) => {
  const { data, idSelected, changeRender, index, setCanClose } = props
  const [change, setChange] = useState(data[index])

  const [loading, setLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  function omitProperties(obj) {
    function omit(key, obj) {
      const { [key]: omitted, ...rest } = obj
      return rest
    }
    let object = omit("transporte_camiones", obj)
    object = omit("transporte_choferes", object)
    return JSON.stringify(object)
  }

  function handleChange(e) {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    })
    setChange((values) => {
      if (omitProperties(data[index]) !== omitProperties(values)) {
        setCanClose(false)
      } else {
        setCanClose(true)
      }
      return values
    })
  }
  useEffect(() => {
    setChange(data[index])
  }, [index, data])

  const onSubmit = async (values) => {
    setLoading(true)
    await interfaces.configInterface
      .updateTransit(idSelected, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setCanClose(true)
        changeRender()
      })
    setLoading(false)
  }

  const onSubmitCancel = () => {
    setChange(data[index])
    createDismissableSnackbar("Cambios descartados", {
      variant: "warning",
    })
    setCanClose(true)
  }

  const options = [
    {
      id: 1,
      optionLabel: "SI",
    },
    {
      id: 0,
      optionLabel: "NO",
    },
  ]

  const fields = (submitForm) => [
    {
      id: 1,
      label: "Nombre o razón social",
      name: "nombre",
      sm: 12,
      xs: 12,
      value: change.nombre ? change.nombre : "",
      select: false,
      uppercase: true,
      class: "textfield",
      onChange: handleChange,
    },
    {
      id: 2,
      label: "Teléfono #1",
      name: "telefono1",
      sm: 6,
      xs: 12,
      value: change.telefono1 ? change.telefono1 : "",
      type: "number",
      select: false,
      class: "textfield",
      onChange: handleChange,
    },
    {
      id: 3,
      label: "Teléfono #2",
      name: "telefono2",
      sm: 6,
      xs: 12,
      value: change.telefono2 ? change.telefono2 : "",
      type: "number",
      select: false,
      class: "textfield",
      onChange: handleChange,
    },
    {
      id: 4,
      label: "Domicilio / Localidad / Provincia",
      name: "direccion",
      sm: 6,
      xs: 12,
      value: change.direccion ? change.direccion : "",
      select: false,
      uppercase: true,
      class: "textfield",
      onChange: handleChange,
    },
    {
      id: 5,
      label: "Zona donde opera",
      name: "alcance",
      sm: 6,
      xs: 12,
      value: change.alcance ? change.alcance : "",
      select: false,
      uppercase: true,
      class: "textfield",
      onChange: handleChange,
    },
    {
      id: 6,
      label: "Correo electrónico",
      name: "email",
      sm: 12,
      xs: 12,
      value: change.email ? change.email : "",
      type: "email",
      select: false,
      class: "textfield",
      onChange: handleChange,
    },

    {
      id: 7,
      label: "Notas",
      name: "notas",
      sm: 12,
      xs: 12,
      value: change.notas ? change.notas : "",
      type: "text",
      select: false,
      uppercase: true,
      class: "textfield",
      onChange: handleChange,
    },
    {
      class: "custom",
      xs: "12",
      sm: "4",
      component: (
        <Autocomplete
          noOptionsText={"No se encontraron resultados"}
          getOptionLabel={(option) => option.optionLabel}
          options={options}
          value={options.find(
            (index) =>
              index.id === parseInt(change.select1 ? change.select1 : 0)
          )}
          defaultValue={null}
          onChange={(e, value) => {
            handleChange({
              target: { name: "select1", value: value.id },
            })
          }}
          renderInput={(params) => (
            <TextFieldMaterial
              {...params}
              fullWidth
              label="Auxilio/Grúa/Camilla"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      ),
    },
    {
      class: "custom",
      xs: "12",
      sm: "4",
      component: (
        <Autocomplete
          noOptionsText={"No se encontraron resultados"}
          getOptionLabel={(option) => option.optionLabel}
          options={options}
          value={options.find(
            (index) =>
              index.id === parseInt(change.select2 ? change.select2 : 0)
          )}
          defaultValue={null}
          onChange={(e, value) => {
            handleChange({
              target: { name: "select2", value: value.id },
            })
          }}
          renderInput={(params) => (
            <TextFieldMaterial
              {...params}
              fullWidth
              label="Batea/mosquito"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      ),
    },
    {
      class: "custom",
      xs: "12",
      sm: "4",
      component: (
        <Autocomplete
          noOptionsText={"No se encontraron resultados"}
          getOptionLabel={(option) => option.optionLabel}
          options={options}
          value={options.find(
            (index) =>
              index.id === parseInt(change.select3 ? change.select3 : 0)
          )}
          defaultValue={null}
          onChange={(e, value) => {
            handleChange({
              target: { name: "select3", value: value.id },
            })
          }}
          renderInput={(params) => (
            <TextFieldMaterial
              {...params}
              fullWidth
              label="Depósito"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      ),
    },
    {
      class: "custom",
      xs: "12",
      sm: "12",
      component: (
        <Grid
          item
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Button
              variant="contained"
              disabled
              style={{ textTransform: "initial" }}
            >
              Comprobantes
            </Button>
          </div>
          <div
            style={{
              marginRight: "15px",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={() => onSubmitCancel()}
              disabledCancel={
                JSON.stringify(data[index]) === JSON.stringify(change) ||
                loading
                  ? true
                  : false
              }
              onClickSubmit={() => submitForm()}
              disabledSubmit={
                JSON.stringify(data[index]) === JSON.stringify(change) ||
                loading
                  ? true
                  : false
              }
              isLoading={loading}
              submitText="Guardar"
            />
          </div>
        </Grid>
      ),
    },
  ]
  return (
    <Formik
      enableReinitialize
      initialValues={{
        nombre: change.nombre.toUpperCase(),
        direccion: change.direccion.toUpperCase(),
        telefono1: change.telefono1,
        telefono2: change.telefono2,
        alcance: change.alcance.toUpperCase(),
        email: change.email,
        notas: change.notas.toUpperCase(),
        select1: change.select1,
        select2: change.select2,
        select3: change.select3,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, setFieldValue, values }) => (
        <InputsWithoutValidate
          fields={fields(submitForm)}
          spacing={2}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  )
}
export default Inicio
