import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  MenuList,
  MenuItem,
} from "@material-ui/core"
import { formatter } from "Helpers"
import styles from "./styles"
import { Popover } from "@material-ui/core/"
import NewOrder from "Components/UI/Organisms/Plant/Grill/NewOrder"
import setState from "Components/Hooks/SetState"
import DialogStock from "Components/UI/Organisms/Sales/Stock/DialogStock"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import BuildIcon from "@material-ui/icons/Build"
function ternaryImg(item) {
  if (item.charAt(0) === "O") {
    return process.env.PUBLIC_URL + "/Images/oemicon.png"
  } else {
    return process.env.PUBLIC_URL + "/Images/logo1.webp"
  }
}

const CustomTable = (props) => {
  const { rows, col } = props

  const classes = styles()
  const createDismissableSnackbar = useCustomSnackbar()
  function checkStatusAvaliable(status) {
    return ["Colocado", "Almacenado", "Clasificado"].indexOf(status) > -1
  }

  function checkStatusUnavaliable(status) {
    return ["Vendido", "Despachado", "Reservado", "Pedido"].indexOf(status) > -1
  }
  const { state: order, setOpen: openOrder, setClose: closeOrder } = setState()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [code, setCode] = useState({})
  const [indexStock, setIndexStock] = useState({})
  const { state: stock, setOpen: openStock, setClose: closeStock } = setState()

  const [render, setRender] = useState(false)

  const handleClick = (e, item, index) => {
    setCode(item)
    setAnchorEl(e.currentTarget)
    setIndexStock(index)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const setOpenStock = () => {
    openStockData()
    setAnchorEl(null)
  }

  const setOpenOrder = () => {
    openOrder()
    setAnchorEl(null)
  }

  const openStockData = async () => {
    await interfaces.plantOrders
      .getStockData(
        rows[indexStock].codigo[0],
        rows[indexStock].codigo.slice(1)
      )
      .then((res) => {
        openStock({ dataObject: res.data })
      })
      .catch(() => {
        createDismissableSnackbar(`Algo salió mal!`, {
          variant: "error",
        })
      })
  }

  const open = Boolean(anchorEl)

  const id = open ? "simple-popover" : undefined

  const RenderMenu = () => {
    return (
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper>
            <MenuList
              anchororigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MenuItem onClick={() => setOpenStock()}>
                <InfoOutlinedIcon />
                <div style={{ marginLeft: "10px" }}>
                  &nbsp;Ver info de la pieza
                </div>
              </MenuItem>
              <MenuItem onClick={() => setOpenOrder()}>
                <BuildIcon />
                <div style={{ marginLeft: "10px" }}>&nbsp;Pedir por grilla</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      </>
    )
  }

  return (
    <>
      <Paper className={classes.table}>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {col.map(({ title, style }, index) => (
                <TableCell style={style}>{title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((item, index) => (
              <TableRow
                key={item.id}
                hover
                style={{
                  textDecoration: checkStatusUnavaliable(item.estado)
                    ? "line-through"
                    : "auto",
                  cursor: "pointer",
                  hover: {
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "rgba(87, 197, 111, 0.13) !important",
                    },
                  },
                }}
                onClick={(e) => handleClick(e, item.codigo, index)}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  <div style={{ display: "flex", alignContent: "center" }}>
                    <img
                      src={ternaryImg(item.codigo)}
                      alt="#"
                      height={"15vh"}
                    />
                    &nbsp;{item.codigo}
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {item.producto}
                </TableCell>
                <TableCell numeric className={classes.tableCell}>
                  {formatter.format(item.precio)}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{
                    color: checkStatusAvaliable(item.estado)
                      ? "green"
                      : "black",
                    fontWeight: checkStatusAvaliable(item.estado)
                      ? "600"
                      : "200",
                  }}
                >
                  {item.estado}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {RenderMenu()}

      <NewOrder
        open={order.open}
        handleclose={() => closeOrder(setCode({}))}
        render={render}
        setRender={setRender}
        code={code}
      />

      {stock.suspense && (
        <DialogStock
          open={stock.open}
          handleclose={closeStock}
          data={stock.dataObject}
          setRender={setRender}
          render={render}
        />
      )}
    </>
  )
}
export default CustomTable
