import { React, forwardRef } from "react"
import { Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { updateLocalities } from "Components/Validation/Settings/Various/PLA"

const Form = forwardRef((props, ref) => {
  const { onSubmit, data } = props
  const info = data()
  const OBJETO = (values) => {
    return [
      {
        class: "textfield",
        name: "localidad",
        label: "Localidad",
        value: values.localidad,
        sm: 12,
        xs: 12,
      },
      {
        class: "textfield",
        name: "cp",
        label: "Código postal",
        value: values.cp,
        sm: 12,
        xs: 12,
        type: "number",
        min: 0
      }
    ]
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          localidad: info.localidad,
          cp: info.cp   
        }}
        validationSchema={updateLocalities}
        onSubmit={onSubmit}
      >
        {({ submitForm, values, setFieldValue, isSubmitting }) => (
          <>
            <InputsWithValidate
              fields={OBJETO(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form