const LIST = "/sales/suppliers/supplierslist"
const BEFORE = "/sales/suppliers/before"
const CREATE = "/sales/suppliers/create"
const UPDATE = "/sales/suppliers/edit"
const DELETE = "/sales/suppliers"
const INFO_ONE_SUPPLIER = "/sales/suppliers/oneSupplier"
const CREATE_DETAIL = "/sales/suppliers/createDetail"
const DELETE_DETAIL = "/sales/suppliers/supplierDetail"
const TOGGLE_STATUS = "/sales/suppliers/toggleStatus"

export const salesSuppliersEndpoints = {
  LIST,
  BEFORE,
  CREATE, 
  UPDATE,
  DELETE,
  INFO_ONE_SUPPLIER,
  CREATE_DETAIL,
  DELETE_DETAIL,
  TOGGLE_STATUS
}