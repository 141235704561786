import React from "react"

import { TabPanel } from "Helpers"
import ForkData from "../Tabs/Holder"
import Holder from "../Tabs/Insured"
import Local from "../Tabs/Local"
import RoadTransport from "../Tabs/Road"
import useStyles from "./styles"

const SideTabs = (props) => {
  const { header } = props
  const classes = useStyles()
  return (
    <div className={classes.style}>
      <TabPanel TabPanel value={props.value} index={0}>
        <Holder header={header} />
      </TabPanel>
      <TabPanel value={props.value} index={1}>
        <ForkData header={header} />
      </TabPanel>
      <TabPanel value={props.value} index={2}>
        <Local header={header} />
      </TabPanel>
      <TabPanel value={props.value} index={3}>
        <RoadTransport header={header} />
      </TabPanel>
    </div>
  )
}

export default SideTabs
