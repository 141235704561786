import React, { useState, useContext, useEffect } from "react"

import { Dialog, Grid } from "@material-ui/core"
import Backdrop from "@material-ui/core/Backdrop"
import { makeStyles } from "@material-ui/core"
import Fade from "@material-ui/core/Fade"

import Title from "./Title"
import Content from "./Content"
import { centerStyles } from "./styles"
import { EditContext } from "Components/Hooks/ContextEdit"

const ComplexDialog = (props) => {
  const {
    tabs,
    title,
    subtitle,
    actions,
    open,
    onClose,
    children,
    maxWidth = "lg",
    widthLarge = "lg",
    maxHeight,
  } = props

  // eslint-disable-next-line
  const [edit, setEdit, isEdited] = useContext(EditContext)

  const useStyles = makeStyles(centerStyles)

  const classes = useStyles({
    widthLarge,
    maxHeight,
  })

  const [step, setStep] = useState(0)

  useEffect(
    () =>
      setTimeout(() => {
        setStep(0)
      }, 100),
    [open]
  )

  return (
    <Dialog
      maxWidth={maxWidth}
      classes={{ paper: classes.paper }}
      fullWidth
      BackdropComponent={Backdrop}
      disableBackdropClick={isEdited}
      disableEscapeKeyDown={isEdited}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <div style={{ height: "700px", overflow: "hidden" }}>
        <Fade in={open}>
          <Grid container>
            <Grid item xs={12}>
              <Title title={title} subtitle={subtitle} actions={actions} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Content
                indexStep={step}
                setIndexStep={setStep}
                children={children(step)}
                tabs={tabs}
              />
            </Grid>
          </Grid>
        </Fade>
      </div>
    </Dialog>
  )
}

export default ComplexDialog
