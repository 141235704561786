import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import { withStyles } from "@material-ui/core/styles"
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"

import { useStyles } from "./styles"
import { Button } from "@material-ui/core"
import NewVehicle from "./NewVehicle"
import DeleteVehicle from "./DeleteVehicle"
import SetState from "Components/Hooks/SetState"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)

const Vehicles = (props) => {
  const { idSelected, changeRender, index, data } = props
  // const [open, setOpen] = useState()
  // const [openDelete, setOpenDelete] = useState()
  const [idVehicle, setIdVehicle] = useState()
  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  const {
    state: openDelete,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  const classes = useStyles()

  const openDeleteVehicle = (id) => {
    setOpenDelete()
    setIdVehicle(id)
  }

  return (
    <div className={classes.root}>
      <NewVehicle
        open={openNew.open}
        handleClose={setCloseNew}
        idSelected={idSelected}
        changeRender={changeRender}
      />
      <DeleteVehicle
        open={openDelete.open}
        handleClose={setCloseDelete}
        idSelected={idSelected}
        changeRender={changeRender}
        idVehicle={idVehicle}
      />
      <div className={classes.table}>
        <Grid item sm={12} xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell>Dominio</TableCell>
                <TableCell>Marca</TableCell>
                <TableCell>Modelo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data[index].transporte_camiones.map((row) => (
                <StyledTableRow>
                  <StyledTableCell>
                    {row.tipo < 2 ? "Vehículo" : "Arrastre"}
                  </StyledTableCell>
                  <StyledTableCell>{row.dominio}</StyledTableCell>
                  <StyledTableCell>{row.marca}</StyledTableCell>
                  <StyledTableCell>{row.modelo}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      type="submit"
                      onClick={() => openDeleteVehicle(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "static",
          margin: "10px",
        }}
      >
        <Button
          type="submit"
          onClick={setOpenNew}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          style={{ textTransform: "initial" }}
        >
          Agregar
        </Button>
      </div>
    </div>
  )
}

export default Vehicles
