import React, { useState } from "react"
import { Grid, Typography } from "@material-ui/core"

import Navbar from "./Navbar"
import Permission from "./Permission"
import Templates from "./Template"

const PermissionsContent = ({ data, setData, changeRender }) => {
  const [idTemplate, setIdTemplate] = useState()
  const [idNavbar, setIdNavbar] = useState()

  const handleListItemClickTemplate = (id) => {
    setIdTemplate(id.id)
  }

  const handleListItemNavbar = (id) => {
    setIdNavbar(id.id)
  }

  const editTemplate = (id) =>
    id ? data.templateList.find((e) => e.id === id) || null : {}

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "-10px", display: 'flex', alignItems: 'stretch' }}>
        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "5px" }}
            variant="h6"
          >
            Templates
          </Typography>
          <Templates
            data={data.templateList}
            changeRender={changeRender}
            handleListItemClick={handleListItemClickTemplate}
            idTemplate={idTemplate}
            setIdTemplate={setIdTemplate}
            fullData={data}
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "5px" }}
            variant="h6"
          >
            Navbar
          </Typography>
          <Navbar
            data={data.navbarsList}
            changeRender={changeRender}
            handleListItemClick={handleListItemNavbar}
            idNavbar={idNavbar}
            setIdNavbar={setIdNavbar}
            idTemplate={idTemplate}
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "5px" }}
            variant="h6"
          >
            Permisos
          </Typography>
          <Permission
            data={data.permissionsTypeList}
            changeRender={changeRender}
            idNavbar={idNavbar}
            editTemplate={editTemplate(idTemplate)}
            arrayPermisos={editTemplate(idTemplate).permisos}
            fullData={data}
            setData={setData}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default PermissionsContent
