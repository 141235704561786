import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Checkbox, Paper } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)

const Children = ({
  data,
  setData,
  setSelectedItems,
  selectedItems,
  filtered,
  fullData,
  editTemplate,
}) => {
  const filteredData = filtered(data)

  const handleChange = (id) => {
    let updatedItems

    if (selectedItems.includes(id)) {
      updatedItems = selectedItems.filter((item) => item !== id)
    } else {
      updatedItems = [...selectedItems, id]
    }
    setSelectedItems(updatedItems)
    updateData(updatedItems)
  }

  const updateData = (updatedItems) => {
    setData({
      ...fullData,
      editData: [
        {
          id: editTemplate.id,
          nombre: editTemplate.nombre,
          permisos: updatedItems,
        },
      ],
    })
  }

  return (
    <Paper>
      <div style={{ overflow: "auto", height: "550px" }}>
        <Table style={{ tableLayout: "fixed" }} size="small">
          <TableBody>
            {filteredData.map((item, index) => (
              <StyledTableRow>
                <StyledTableCell style={{ width: "10%" }}>
                  <Checkbox
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleChange(item.id)}
                  />
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: "80%" }}>
                  {item.descripcion}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default Children
