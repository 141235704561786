import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

export function ButtonUploadFile(props) {
  const {
    name,
    value,
    textLater,
    textBefore,
    onChange,
    id,
    multiple = true,
  } = props

  return (
    <>
      <input
        style={{ display: "none" }}
        accept="image/*"
        id={id}
        multiple={multiple}
        name={name}
        type="file"
        onChange={(e) => onChange(e)}
      />
      <label id={name} name={name} htmlFor={id}>
        {value !== "" ? (
          <Button
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            variant="contained"
            fullWidth
            style={{ textTransform: "initial" }}
            component="span"
          >
            {textLater}
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            style={{ textTransform: "initial" }}
            component="span"
          >
            {textBefore}
          </Button>
        )}
      </label>
    </>
  )
}
