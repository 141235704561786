import React from "react"

import { Button } from "@material-ui/core"

import useStyles from "./styles"

const ApplyFilters = (props) => {
  const { applyNewFilters } = props
  const classes = useStyles()

  return (
    <div className={classes.firstDiv}>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => applyNewFilters()}
      >
        Aplicar
      </Button>
    </div>
  )
}
export default ApplyFilters
