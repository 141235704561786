import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import { getNavbar } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"
import Content from "./Content"

const ProvinciesLocalitiesAddress = () => {
  const { state, setOpen, setClose } = SetState()

  return (
    <>
      <ButtonInsideBox
        onClick={setOpen}
        text="Provincia, localidad y calle"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/location.png"}
        disabled={JSON.parse(getNavbar()).bmv ? false : true}
      />
      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        widthLarge="90vw"
        title="Provincia, localidad y calle"
        open={state.open}
        handleclose={setClose}
        maxHeight
        children={<Content />}
      />
    </>
  )
}

export default ProvinciesLocalitiesAddress