import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  popContent: {
    background: "#f4f7f4",
    borderRadius: "10px",
    overflow: "hidden",
    display: "block",
    border: "1px solid #EBECF0",
    zIndex: "2",
    width: "450px",
    padding: "30px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  headerFixed: {
    position: "fixed",
    left: "263px",
    right: 15,
    height: "50px",
    background: "white",
    zIndex: 1000,
    paddingTop: "10px",
    paddingBottom: "45px",
  },
}))

export default useStyles
