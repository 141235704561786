import React, { useRef } from "react"

import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

import { newOperator } from "Components/Validation/Settings/Various/Operators"
import { OPERARIOS_ROLES, UBICACIONES_MANUAL } from "Constants"
import { interfaces } from "service/interfaces"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const New = (props) => {
  const { open, handleclose, changeRender } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    values.nombre = values.nombre.toUpperCase()
    await interfaces.operatorsInterface
      .newOperator(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
  }

  const object = (values, setFieldValue) => [
    {
      class: "textfield",
      label: "Nombre",
      name: "nombre",
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Ubicación",
      name: "ubicacion_id",
      options: UBICACIONES_MANUAL,
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          name="roles"
          options={OPERARIOS_ROLES}
          getOptionLabel={(e) => e.rol}
          defaultValue={values.roles}
          label={"Roles"}
          onChange={(_, value) => {
            setFieldValue("roles", value)
          }}
          tagName={"rol"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },
  ]

  const children = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          nombre: "",
          ubicacion_id: "",
          activo: 1,
        }}
        validationSchema={newOperator}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={object(values, setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      title="Nuevo operario"
      children={children()}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleclose}
        />
      }
    />
  )
}

export default New
