import * as yup from "yup"

export const validationNewFamily = yup.object().shape({
  descripcion: yup.string().required("Este campo es obligatorio"),
})

export const validationNewProduct = yup.object().shape({
  producto: yup.string().required("Este campo es obligatorio"),
})
export const validationUpdateProduct = yup.object().shape({
  nombre: yup.string().required("Este campo es obligatorio"),
})
