import React from "react"
import { Button, Tooltip } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "Components/UI/Atoms/CustomButton/styles"

const CustomButton = (props) => {
  const {
    component,
    to,
    onClick,
    disabled,
    color = "primary",
    text,
    style,
    buttonEnd,
    fullWidth,
    isLoading,
    variant = "contained",
    tooltip = "",
    size,
  } = props
  const classes = useStyles()

  return (
    <div
      style={buttonEnd ? { display: "flex", justifyContent: "flex-end" } : null}
    >
      <Tooltip title={tooltip}>
        <Button
          size={size}
          component={component}
          to={to}
          color={color}
          variant={variant}
          onClick={onClick}
          disabled={disabled}
          style={style}
          className={classes.defaultButton}
          fullWidth={fullWidth}
          {...props}
        >
          {isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
                width: "24px",
                height: "24px",
                marginLeft: "21px",
                marginRight: "21px",
              }}
            />
          ) : (
            text
          )}
        </Button>
      </Tooltip>
    </div>
  )
}

export default CustomButton
