import React from "react"

import { Button, Hidden } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import { useStyles } from "./styles"

const CancelConfirmButtons = (props) => {
  const {
    onClickCancel,
    onClickSubmit,
    cancelText = "Cancelar",
    submitText = "Confirmar",
    disabledCancel,
    disabledSubmit,
    styleSubmit,
    isLoading,
    isLoadingThird,
    leftButton = false,
    leftButtonOnClick,
    leftButtonDisabled,
    leftButtonText,
    leftButtonColor,
    leftButtonStyle,
    leftButtonWidth,
    noMargin = false,
    cancelStyle,
    replaceCancel,
    thirdButton,
    onClickThird,
    disabledThird,
    thirdText,
    leftButtonNoMargin = false,
    leftButtonHiddenFalse = false,
    colorThird = "primary",
    type = "submit",
  } = props
  const classes = useStyles({ noMargin, leftButton, leftButtonNoMargin })
  return (
    <div className={classes.allButtons}>
      <Hidden mdDown={leftButtonHiddenFalse ? false : true}>
        {leftButton ? (
          <Button
            className={classes.textType}
            onClick={leftButtonOnClick}
            disabled={leftButtonDisabled}
            variant="contained"
            color={leftButtonColor}
            fullWidth={leftButtonWidth}
            style={leftButtonStyle}
          >
            {leftButtonText}
          </Button>
        ) : null}
      </Hidden>
      <div className={classes.buttons}>
        {thirdButton ? (
          <Button
            color={colorThird}
            variant="contained"
            className={classes.cancel}
            onClick={onClickThird}
            disabled={disabledThird}
            {...cancelStyle}
          >
            {isLoadingThird ? (
              <CircularProgress
                style={{
                  color: "#fff",
                  width: "24px",
                  height: "24px",
                  marginLeft: "21px",
                  marginRight: "21px",
                }}
              />
            ) : (
              thirdText
            )}
          </Button>
        ) : null}
        {replaceCancel ? (
          <div style={{ marginRight: "4px" }}>
            {replaceCancel ? replaceCancel : null}
          </div>
        ) : (
          <Button
            className={classes.cancel}
            onClick={onClickCancel}
            disabled={disabledCancel}
            {...cancelStyle}
          >
            {cancelText}
          </Button>
        )}

        <Button
          className={classes.textType}
          style={styleSubmit}
          variant="contained"
          color="primary"
          type={type}
          onClick={onClickSubmit}
          disabled={disabledSubmit}
          {...props}
        >
          {isLoading ? (
            <CircularProgress
              style={{
                color: "#fff",
                width: "24px",
                height: "24px",
                marginLeft: "21px",
                marginRight: "21px",
              }}
            />
          ) : (
            submitText
          )}
        </Button>
      </div>
    </div>
  )
}
export default CancelConfirmButtons
