import React, { useState } from "react"

import ButtonInsideBox from "Components/UI/Atoms/ButtonInsideBox"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ContentOperators from "Components/UI/Organisms/Settings/Various/Operators/Content"
import { getNavbar } from "service/helpers/localstorage"

const Operators = () => {
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <>
      <ButtonInsideBox
        onClick={handleOpen}
        text="Operarios"
        button="Configurar"
        img={process.env.PUBLIC_URL + "/Images/Operarios.png"}
        disabled={JSON.parse(getNavbar()).operators ? false : true}
      />
      <DialogComponent
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        handleclose={handleClose}
        widthLarge="50wv"
        title="Operarios"
        maxHeight
        children={<ContentOperators />}
      />
    </>
  )
}

export default Operators
