import React, { useState, useRef, useContext } from "react"

import { IconButton } from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import PrintIcon from "@material-ui/icons/Print"
import DeleteIcon from "@material-ui/icons/Delete"

import { printPDF } from "Helpers"
import Form from "./Form"
import { interfaces } from "service/interfaces"
import CancelEdit from "./Cancel"
import Loader from "Components/UI/Atoms/Loader"
import { FormContext } from "Components/Hooks/ContextForm"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
const FormEdit = (props) => {
  const { data, handleclose, updateList } = props
  const [wait, setWait] = useState(false)
  const [dataEdit, setDataEdit] = useState({ data })
  
  const [loading, setLoading] = useState(false)
  
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)
  const { formEdit } = useContext(FormContext)

  const { state: openCancel, setOpen: setOpenCancel, setClose: setCloseCancel } = SetState()
  const { state: openControl, setOpen: setOpenControl, setClose: setCloseControl } = SetState()

  const openCancelButton = () => {
    if (data !== dataEdit.data) {
      setOpenCancel()
    } else {
      setCloseControl()
    }
  }

  const handleSubmit = () => {
    formSubmitRef.current.click()
    setCloseControl()
    handleclose()
  }

  const handleCloseControl = () => {
    setCloseControl()
    setDataEdit({ data })
  }

  const onSubmit = async (values) => {
    setLoading(true)
    await interfaces.vehicleInterface
      .updateShipping(data.id, values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  async function print() {
    await interfaces.vehicleInterface
      .shippingSticker(data.id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      }, setWait(true))
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setWait(false)
  }

  async function deleteShipping() {
    await interfaces.vehicleInterface
      .deleteShipping(data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      }, setWait(true))
      .catch((err) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    await updateList()
    setWait(false)
  }
  return (
    <div>
      <IconButton onClick={setOpenControl}>
        <OpenInNewIcon />
      </IconButton>
      <IconButton onClick={() => print()}>
        <PrintIcon />
      </IconButton>
      <IconButton onClick={() => deleteShipping()}>
        <DeleteIcon />
      </IconButton>
      <DialogComponent
        tipo="center"
        open={openControl.open}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        handleclosedisabled={loading}
        widthLarge="40%"
        handleclose={openCancelButton}
        title="Control de envíos"
        subtitle={`Aseguradora: ${data.destinatario} - Dominio: ${formEdit.vehicle.dominio}`}
        children={
          <Form
            ref={formSubmitRef}
            onSubmit={onSubmit}
            data={data}
            change={dataEdit}
            setChange={setDataEdit}
          />
        }
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={openCancelButton}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
      <CancelEdit
        open={openCancel.open}
        handleclose={setCloseCancel}
        handleClosePay={handleCloseControl}
      />
      {wait ? <Loader /> : null}
    </div>
  )
}

export default FormEdit
