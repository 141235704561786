import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: "#f2f2f2",
  },
  mainDiv: {
    height: "100%",
    padding: "10px",
    display: "flex",
    borderRadius: "5px",
    opacity: 0.9,
    width: "120%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  },
  products: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  models: {
    marginLeft: "1em",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginTop: "1em",
      width: "100%",
    },
  },
  search: {
    width: "50%",
    marginLeft: "0.7em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginTop: "1em",
      width: "100%",
    },
  },
}))

export default useStyles
