import React, { useState } from "react"

import { Typography } from "@material-ui/core"

import Login from "Components/UI/Organisms/Login/LoginOem/Components/Login"
import Forget from "Components/UI/Organisms/Login/LoginOem/Components/Forget"
import Reset from "Components/UI/Organisms/Login/LoginOem/Components/Reset"
import useStyles from "./styles"

const FormLoginOem = () => {
  const [mostrar, setMostrar] = useState(1)
  const classes = useStyles()

  return (
    <div>
      <div className={classes.paper}>
        <img
          src={process.env.PUBLIC_URL + "./Images/logoOEM.png"}
          alt="#"
          className={classes.logo}
        />
        {mostrar === 1 && <Login onClick={() => setMostrar(2)} />}
        {mostrar === 2 && (
          <Forget
            onClick={() => setMostrar(3)}
            onClickVolver={() => setMostrar(1)}
            onSubmit={() => {
              setMostrar(3)
            }}
          />
        )}
        {mostrar === 3 && <Reset onClick={() => setMostrar(1)} />}
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ margin: 30 }}
        >
          {"OEM Parts SA © "}
          {new Date().getFullYear()}
        </Typography>
      </div>
    </div>
  )
}
export default FormLoginOem
