import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "552px",
  },
  root2: {
    width: "900px",
    [theme.breakpoints.only("md")]: {
      width: "43rem",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  button: {
    justifyContent: "center",
    marginLeft: "10px"
  },
  cardContentSelectImg: {},
}))
