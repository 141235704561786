export function columnTheme() {
  return {
    "&:nth-child(1)": {
      paddingLeft: "10px",
      width: "100px",
    },
    "&:nth-child(2)": {
      width: "200px",
    },
    "&:nth-child(3)": {
      width: "120px",
    },
    "&:nth-child(4)": {
      width: "200px",
    },
    "&:nth-child(5)": {
      width: "120px",
    },
    "&:nth-child(6)": {
      width: "120px",
    },
    "&:nth-child(7)": {
      width: "150px",
    },
    "&:nth-child(8)": {
      width: "140px",
    },
    "&:nth-child(9)": {
      width: "10px",
    },
    "&:nth-child(10)": {
      width: "50px",
    },
  }
}
