import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "800px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperDelete: {
    backgroundColor: theme.palette.background.paper,
    width: "400px",
    height: "100px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 1, 1),
  },
  textDelete: {
    marginBottom: "20px",
  },
  root: {
    width: "400px",
    height: "auto",
  },
  button: {
    textTransform: "capitalize",
    marginRight: "8px",
  },
  buttonDelete: {
    textTransform: "capitalize",
    color: "#fff",
    flex: "start",
    background: "#d32f2f",
    "&:hover": {
      background: "#f44336",
    },
  },
  dialogActions: {
    padding: "0",
  },
  divDelete: {
    marginRight: "288px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  input: {
    height: "80px",
  },
  inputAutocomplete: {
    height: "73px",
  },
  buttons: {
    width: "100%",
    display: "flex",
    marginRight: "15px",
    justifyContent: "space-between",
    marginBottom: "15px",
    marginTop: "10px",
  },
  marginLeft: {
    marginLeft: "15px",
  },
  marginRight: {
    marginRight: "15px",
  },
}))

export default useStyles
