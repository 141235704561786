import React, { useState } from "react"

import AddIcon from "@material-ui/icons/Add"

import TemplateGlobal from "Components/UI/Templates/Common"
import NavBarGlobal from "Components/UI/Molecules/NavBar"

import SetState from "Components/Hooks/SetState"

import StationTable from "Components/UI/Organisms/Plant/Station/Table"

import NewStation from "Components/UI/Organisms/Plant/Station/New"

import { getUbicacion } from "service/helpers/localstorage"
import { useLocation } from "react-router-dom"
import { oemColorButton } from "Helpers"

const StationTemplate = () => {
  const [render, setRender] = useState(false)

  const { state, setClose, setOpen } = SetState()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()
  const BUTTONS = [
    {
      menuIcon: <AddIcon fontSize="small" />,
      menuTitle: "Nuevo",
      buttonContent: "Ingreso",
      buttonStartIcon: <AddIcon />,
      buttonStyle: oemColorButton(pathname, location),
      onClick: setOpen,
    },
  ]

  return (
    <>
      <TemplateGlobal
        navbar={
          <NavBarGlobal
            title="Planta"
            subTitle="Estación cero"
            buttons={BUTTONS}
          />
        }
        table={
          <StationTable
            render={render}
            setRender={setRender}
            openZero={setOpen}
          />
        }
      />

      {state.suspense && (
        <NewStation
          open={state.open}
          handleclose={setClose}
          render={render}
          setRender={setRender}
          initialCode={state.code}
        />
      )}
    </>
  )
}

export default StationTemplate
