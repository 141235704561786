import React, { useContext, useState, useEffect } from "react"

import { interfaces } from "service/interfaces"
import BasicTable from "Components/UI/Atoms/BasicTable"

import { EditContext } from "Components/Hooks/ContextEdit"

import Table from "./Table"
import IconButton from "@material-ui/core/IconButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CloseIcon from "@material-ui/icons/Close"


const Contact = ({ data, onDataChange, setRender, render }) => {
 
  const [edit] = useContext(EditContext)
  const [isLoading, setIsLoading] = useState(false)
  const [ formEdit, setFormEdit ] = useState(data.details)
  
  const createDismissableSnackbar = useCustomSnackbar()

  useEffect(() => {
    setFormEdit(data.details)
  }, [data.details])

  async function apiCall(id) {
      await interfaces.salesSuppliers
      .infoOneSupplier(id)
      .then((res) => {
        setFormEdit({ 
          data: res.data.details
        })
      })
    }
    !formEdit && apiCall()
  
  async function deleteDetail(id) {
    setIsLoading(true)
    await interfaces.salesSuppliers
      .deleteDetail(id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        deleteItem(id)
      })
      setIsLoading(false)
  }

  const deleteItem = (id) => {
    let newItem = formEdit.filter((e) => e.id !== id)
    setFormEdit(newItem)
    onDataChange(newItem)
  }

  const iconDelete = (id) => (
    <IconButton onClick={() => deleteDetail(id)} disabled={!edit}>
      <CloseIcon />
    </IconButton>
  )

  return (
        <>
            <div >
              <BasicTable
                col={[
                  { title: "", style: { width: "10px" } },
                  { title: "Concepto", style: { width: "100px" } },
                  { title: "Contenido", style: { width: "100px" } },
                  { title: "", style: { width: "60px", marginRight: "20px" }}
                ]}
                size={"small"}
                rows={formEdit.map((row) => {
                  return {
                    "": "",
                    concepto: row.concepto,
                    contenido: row.contenido,
                    action: iconDelete(row.id)
                  }
                })}
               /> 
           </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 40px 10px 10px",
            }}>
              <Table 
                formEdit={formEdit} 
                isLoading={isLoading}
                edit={edit}
                setFormEdit={setFormEdit}
                data={data}
                onDataChange={onDataChange}
                setIsLoading={setIsLoading}
                setRender={setRender}
                render={render}
              />
          </div>
        </>
      )
    }
    
    export default Contact    