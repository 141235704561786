import React, { useState, useEffect } from "react"

import { Grid, Typography } from "@material-ui/core"

import ItemList from "./ItemList"
import ContentItemList from "./ContentItemList"
import InputSearch from "Components/UI/Atoms/InputSearch"
import { interfaces } from "service/interfaces"
import NewTransport from "./ItemList/NewTransport"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"

const TransportContent = (props) => {
  const { canClose, setCanClose } = props

  const { state, setOpen, setClose } = SetState()
  const [idSelected, setIdSelected] = useState()
  const [data, setData] = useState([])
  const [render, setRender] = useState(true)
  const [index, setIndex] = useState()
  const [valueTransport, setValueTransport] = useState("")

  const createDismissableSnackbar = useCustomSnackbar()

  const changeRender = () => {
    setRender(!render)
  }

  useEffect(() => {
    async function apiCall() {
      await interfaces.configInterface
        .transportList()
        .then((res) => setData(res.data))
    }
    apiCall()
  }, [render])

  const onChangeBrand = (e) => {
    setValueTransport(e.target.value)
  }

  const handleListItem = (item) => {
    if (canClose === true) {
      setIdSelected(item.id)
      setIndex(data.findIndex((e) => e.id === item.id))
    } else {
      createDismissableSnackbar("Tiene cambios sin guardar!", {
        variant: "error",
      })
    }
  }

  const RenderContentItemList = () => {
    setIdSelected()
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <NewTransport
        open={state.open}
        handleClose={setClose}
        changeRender={changeRender}
      />
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <InputSearch
            onClick={setOpen}
            onChange={onChangeBrand}
            value={valueTransport}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ItemList
            data={data}
            handleListItemClick={handleListItem}
            idSelected={idSelected}
            changeRender={changeRender}
            valueTransport={valueTransport}
            RenderContentItemList={RenderContentItemList}
          />
        </Grid>
        {idSelected >= 0 ? (
          <Grid item sm={8} xs={12}>
            <ContentItemList
              idSelected={idSelected}
              data={data}
              changeRender={changeRender}
              index={index}
              setCanClose={setCanClose}
            />
          </Grid>
        ) : (
          <Grid item sm={8} xs={12}>
            <PaperList
              height="600px"
              children={
                <Typography
                  style={{
                    height: "600px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Seleccione un retiro para editar
                </Typography>
              }
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default TransportContent
