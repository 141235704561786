const STORAGE_LIST = "/plant/storages/list"
const STORAGE_INFO = "/plant/storages/storage"
const STORAGE_STICKER = "/plant/storages/sticker"
const NEW_STORAGE = "/plant/storages/new"
const EDIT_STORAGE = "/plant/storages/storage"
const SEARCH_STORAGE_CODE = "/plant/classification/storage"
const TO_UPDATE = "/plant/classification/to-update"
const UPDATE_MASSIVE = "/plant/classification/updatemassive"

export const plantEndpointsStorge = {
  STORAGE_LIST,
  STORAGE_INFO,
  STORAGE_STICKER,
  NEW_STORAGE,
  EDIT_STORAGE,
  SEARCH_STORAGE_CODE,
  TO_UPDATE,
  UPDATE_MASSIVE,
}
