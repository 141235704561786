import { makeStyles } from "@material-ui/core"

const useGlobalStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff",
    },
  },
}))

export default useGlobalStyles
