import React, { useState } from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import MuiDatables from "Components/UI/Atoms/Table"
import Edit from "./Edit"
import SetState from "Components/Hooks/SetState"
import { interfaces } from "service/interfaces"

import { printPDF } from "Helpers"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Tooltip from "@material-ui/core/Tooltip"
import { IconButton } from "@material-ui/core"
import PrintIcon from "@material-ui/icons/Print"
import EditContextProvider from "Components/Hooks/ContextEdit"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"

const CompactionTable = (props) => {
  const { render, setRender, values, setValues, apiCall, permissions } = props

  const windowSize = useMediaQuery("(min-width:1000px)")
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const { state, setOpen, setClose } = SetState()

  async function compactionInfo(id) {
    await interfaces.compactionInterface.infoOneCompaction(id).then((res) => {
      setOpen({
        data: res.data,
      })
    })
  }

  const printQuote = async (e, id) => {
    e.stopPropagation()
    setLoading(true)
    await interfaces.compactionInterface
      .print(id)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar("Acta impresa con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const actions = (file) => {
    return (
      <div style={{ display: "flex", marginLeft: "0.3rem" }}>
        <Tooltip title="Imprimir">
          <IconButton
            onClick={(e) => printQuote(e, file.id)}
            color={file.tipo ? "" : "primary"}
            size={windowSize ? "medium" : "normal"}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const RowsTransit = () =>
    values.rows.map((file) => [
      file.id,
      file.nombre,
      file.tipo,
      file.ubicacion,
      file.proveedor1,
      file.proveedor2,
      file.fecha_confirmacion,
      file.estado,
      actions(file),
      "",
    ])

  const TIPO = [
    {
      tipo: "LOCAL",
      id: 0,
    },
    {
      tipo: "REMOTA",
      id: 1,
    },
  ]

  let columns = [
    {
      name: "id",
      label: "Nº",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "nombre",
      label: "Nombre",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ubicacion",
      label: "Ubicación",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Ubicación"}
                  filterName={"nombre"}
                  options={values.filters.ubicacion}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "proveedor1",
      label: "Proveedor",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Proveedor"}
                  filterName={"proveedor"}
                  options={values.filters.proveedores}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "proveedor2",
      label: "Proveedor disp final",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Proveedor disp final"}
                  filterName={"proveedor"}
                  options={values.filters.proveedores}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "fecha_confirmacion",
      label: "Fecha conf.",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Estado"}
                  filterName={"label"}
                  options={values.filters.estado}
                  defaultOption={{ label: "Pendiente", value: 0 }}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "usuarios",
      label: "Usuario",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Usuario"}
                  filterName={"fullName"}
                  options={values.filters.usuarios}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: false,
        display: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(values.filters).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Tipo"}
                  filterName={"tipo"}
                  options={TIPO}
                />
              )
            }
          },
        },
      },
    },
  ]
  return (
    <>
      {state.suspense && (
        <EditContextProvider>
          <Edit
            open={state.open}
            handleclose={setClose}
            data={state.data}
            setRender={setRender}
            render={render}
            printQuote={printQuote}
            setLoading={setLoading}
            setOpen={setOpen}
            setValues={setValues}
            loading={loading}
            infoFilters={values}
            permissions={permissions}
          />
        </EditContextProvider>
      )}

      <MuiDatables
        columns={columns}
        data={RowsTransit()}
        title={`Total de actas: ${values.rows.length}`}
        apiCall={apiCall}
        textPlaceholder="Buscar por nombre, número de acta..."
        count={values.header}
        render={render}
        setRender={setRender}
        onRowClick={(_, index) =>
          compactionInfo(values.rows[index.dataIndex].id)
        }
        padding={windowSize ? "10px" : ""}
      />
    </>
  )
}
export default CompactionTable
