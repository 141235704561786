import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: 400,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  buttons: {
    display: "flex",
    alignItems: " flex-end",
    justifyContent: " flex-end",
  },
  textCapitalize: {
    textTransform: "initial",
  },
  inputs: {
    height: "80px"
  }
}));

export default useStyles;
