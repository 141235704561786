import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const CancelEdit = (props) => {
  const createDismissableSnackbar = useCustomSnackbar()
  const onSubmit = () => {
    props.handleclose(true)
    props.handleClosePay()
    createDismissableSnackbar("Los cambios han sido descartados!", {
      variant: "warning",
    })
  }

  return (
    <div>
      <DialogComponent
        open={props.open}
        title="Tiene cambios sin guardar, ¿desea salir?"
        subtitle={"Todos sus cambios se perderán!"}
        widthLarge={"450px"}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={props.handleclose}
              onClickSubmit={onSubmit}
              submitText="Aceptar"
            />
          </div>
        }
      />
    </div>
  )
}

export default CancelEdit
