import { plantEndpointsChecklist } from "service/sources"
import { axiosService } from "service"
const list = (options) => {
  return axiosService.get(plantEndpointsChecklist.LIST + options)
}

const moreInfo = (id) => {
  return axiosService.get(plantEndpointsChecklist.MORE_INFO + "/" + id)
}

const update = (id, body) => {
  return axiosService.put(
    plantEndpointsChecklist.UPDATE_CHECKLIST + "/" + id,
    body
  )
}
const newChecklist = (body) => {
  return axiosService.post(plantEndpointsChecklist.NEW_CHECKLIST, body)
}

const searchChecklist = (dominio, tipo) => {
  return axiosService.get(
    plantEndpointsChecklist.SEARCH_CHECKLIST + `?search=${dominio}&tipo=${tipo}`
  )
}

const print = (id) => {
  return axiosService.getPDF(plantEndpointsChecklist.PRINT + id)
}

const deleteChecklist = (id) => {
  return axiosService.del(plantEndpointsChecklist.MORE_INFO + id)
}

export const plantChecklist = {
  list,
  newChecklist,
  moreInfo,
  update,
  searchChecklist,
  print,
  deleteChecklist,
}
