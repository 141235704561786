import React, { useContext } from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"
import Hidden from "@material-ui/core/Hidden"
import { Button, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import CancelSubmit from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Title/Cancel"
import SubmitSave from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Title/Save"
import DeleteSubmit from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Title/Delete"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { getUserId } from "service/helpers/localstorage"
import SetState from "Components/Hooks/SetState"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    color: "black",
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

const Title = (props) => {
  const {
    setRenderModal,
    renderModal,
    renderTable,
    setRenderTable,
    editAllow,
    closeBigDialog,
  } = props

  const { state: openCancel, setOpen: setOpenCancel, setClose: setCloseCancel } = SetState()
  const { state: openSave, setOpen: setOpenSave, setClose: setCloseSave } = SetState()
  const { state: openDelete, setOpen: setOpenDelete, setClose: setCloseDelete } = SetState()

  const { formApi, formEdit } = useContext(FormContext)
  const [edit, setEdit] = useContext(EditContext)
  const createDismissableSnackbar = useCustomSnackbar()

  const classes = useStyles()

  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleCancel = () => {
    if (Object.keys(formApi).length === 0) {
      handleEdit()
    } else {
      setOpenCancel()
    }
  }

  const handleSave = () => {
    if (Object.keys(formApi).length === 0) {
      createDismissableSnackbar("No hay cambios para guardar!", {
        variant: "info",
      })
    } else {
      setOpenSave()
    }
  }

  return (
    <AppBar position="relative" className={classes.root}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {formEdit.vehicle.dominio +
                " " +
                (formEdit.vehicle.marca ? formEdit.vehicle.marca.marca : "") +
                " " +
                (formEdit.vehicle.modelo
                  ? formEdit.vehicle.modelo.modelo
                  : "") +
                " " +
                (formEdit.vehicle.año ? formEdit.vehicle.año : "")}
              <Typography variant="body2">
                <b>ID {formEdit.vehicle.id}</b> - Creado el{"  "}
                {formEdit.vehicle.creado + " "} por{" "}
                {formEdit.vehicle.usuario.nombre +
                  " " +
                  formEdit.vehicle.usuario.apellido}
              </Typography>
            </div>
            {edit && (
              <Hidden xsDown>
                {[1, 2].indexOf(parseInt(getUserId())) > -1 &&
                  (formEdit.vehicle.activo === 1 ? (
                    <Button
                      onClick={setOpenDelete}
                      style={{
                        marginLeft: "3em",
                        display: "inline-block",
                        background: "rgb(244, 67, 54)",
                        color: "#fff",
                      }}
                      variant="contained"
                      size="small"
                    >
                      Eliminar
                    </Button>
                  ) : (
                    <Button
                      onClick={setOpenDelete}
                      style={{
                        marginLeft: "3em",
                        display: "inline-block",
                      }}
                      color="primary"
                      variant="contained"
                      size="small"
                    >
                      Restaurar
                    </Button>
                  ))}
              </Hidden>
            )}
          </div>
        </Typography>
        {edit ? (
          <Hidden xsDown>
            <div>
              <Button
                style={{ margin: "10px" }}
                onClick={() => {
                  handleCancel()
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  handleSave()
                }}
                variant="contained"
                color="primary"
                style={{ marginRight: "15px" }}
              >
                Guardar
              </Button>
            </div>
          </Hidden>
        ) : (
          <Hidden xsDown>
            <Tooltip
              title={editAllow === 0 ? "Permisos insuficientes" : "Editar"}
            >
              <IconButton
                disabled={editAllow === 0 ? true : false}
                onClick={handleEdit}
                className={classes.icon}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Hidden>
        )}
        <CancelSubmit
          open={openCancel.open}
          onClick={setOpenCancel}
          handleclose={setCloseCancel}
        />
        <SubmitSave
          open={openSave.open}
          onClick={setOpenSave}
          handleclose={setCloseSave}
          renderModal={renderModal}
          setRenderModal={setRenderModal}
          setRenderTable={setRenderTable}
          renderTable={renderTable}
          closeBigDialog={closeBigDialog}
        />
        <DeleteSubmit
          open={openDelete.open}
          handleclose={setCloseDelete}
          closeBigDialog={closeBigDialog}
          setRenderTable={setRenderTable}
          renderTable={renderTable}
          id={formEdit.vehicle.id}
          idActive={formEdit.vehicle.activo}
        />
      </Toolbar>
    </AppBar>
  )
}

export default Title
