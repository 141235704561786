import React from "react"

import { Grid } from "@material-ui/core"

import Insurace from "Components/UI/Organisms/Settings/Various/Insurance"
import BrandModelVersion from "Components/UI/Organisms/Settings/Various/BrandModelVersion"
import Engine from "Components/UI/Organisms/Settings/Various/Engine"
import Transport from "./Transport"
import FamilyAndProducts from "./FamilyAndProducts"
import Operators from "Components/UI/Organisms/Settings/Various/Operators"
import UserInsurance from "./UsersInsurance"
import TypeVehicle from "./TypeVehicle"
import Concepts from "./Concepts"
import LotsRow from "./LotsRow"
import ProvinciesLocalitiesAddress from "./ProvinciesLocalitiesAddress"

const Varies = () => {
  return (
    <>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <BrandModelVersion />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Insurace />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Transport />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Engine />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FamilyAndProducts />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Operators />
        </Grid>
        <Grid item sm={4} xs={12}>
          <UserInsurance />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TypeVehicle />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Concepts />
        </Grid>
        <Grid item sm={4} xs={12}>
          <LotsRow />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ProvinciesLocalitiesAddress />
        </Grid>
      </Grid>
    </>
  )
}
export default Varies
