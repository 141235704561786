import React, { forwardRef } from "react"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"

const FormDialogFormTable = forwardRef((props, ref) => {
  const { oneWafer, infoRow } = props
  const OBJETO = [
    {
      id: 1,
      class: "textfield",
      value: oneWafer.marcamodeloaño ? oneWafer.marcamodeloaño : "",
      label: "Marca/Modelo/Año",
      name: "dates",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 2,
      class: "textfield",
      value: oneWafer.dominio ? oneWafer.dominio : "",
      label: "Dominio",
      name: "domain",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 3,
      class: "textfield",
      value: oneWafer.motor_nro ? oneWafer.motor_nro : "",
      label: "Nº motor",
      name: "engine",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 4,
      class: "textfield",
      value: oneWafer.chasis_nro ? oneWafer.chasis_nro : "",
      label: "Nº Chasis",
      name: "chassis",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 5,
      class: "textfield",
      value: oneWafer.cliente ? oneWafer.cliente : "",
      label: "Cliente",
      name: "client",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 6,
      class: "textfield",
      value: oneWafer.fecha ? oneWafer.fecha : "",
      label: "Fecha",
      name: "date",
      sm: 12,
      xs: 12,
      format: "d MMM, yyyy",
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 7,
      label: "Comprobante",
      value: oneWafer.comprobante ? oneWafer.comprobante : "",
      name: "voucher",
      sm: 12,
      xs: 12,
      class: "textfield",
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
  ]

  const OBJETO_COMPRADO = [
    {
      id: 1,
      class: "textfield",
      value: oneWafer.marcamodeloaño ? oneWafer.marcamodeloaño : "",
      label: "Marca/Modelo/Año",
      name: "dates",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 2,
      class: "textfield",
      value: oneWafer.dominio ? oneWafer.dominio : "",
      label: "Dominio",
      name: "domain",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 3,
      class: "textfield",
      value: oneWafer.motor_nro ? oneWafer.motor_nro : "",
      label: "Nº motor",
      name: "engine",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 4,
      class: "textfield",
      value: oneWafer.chasis_nro ? oneWafer.chasis_nro : "",
      label: "Nº Chasis",
      name: "chasis",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 5,
      class: "textfield",
      value: oneWafer.proveedor ? oneWafer.proveedor : "",
      label: "Proveedor",
      name: "proveedor",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 6,
      class: "textfield",
      value: oneWafer.cliente ? oneWafer.cliente : "",
      label: "Cliente",
      name: "cliente",
      sm: 12,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 7,
      class: "textfield",
      value: oneWafer.compra_comprobante ? oneWafer.compra_comprobante : "",
      label: "Fac. compra",
      name: "facCompra",
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 8,
      class: "textfield",
      value: oneWafer.comprobante ? oneWafer.comprobante : "",
      label: "Fac. venta",
      name: "facVenta",
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 9,
      class: "textfield",
      value: oneWafer.fecha_compra ? oneWafer.fecha_compra : "",
      label: "Fecha compra",
      name: "fechaCompra",
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
    {
      id: 10,
      class: "textfield",
      value: oneWafer.fecha ? oneWafer.fecha : "",
      label: "Fecha venta",
      name: "feachaVenta",
      sm: 6,
      xs: 12,
      inputProps: { readOnly: true },
      style: { height: "60px" },
    },
  ]

  return (
    <>
      <InputsWithoutValidate
        fields={infoRow.oblea_comprada ? OBJETO_COMPRADO : OBJETO}
        spacing={2}
      />
    </>
  )
})

export default FormDialogFormTable
