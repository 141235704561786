import { axiosService } from "service"

const GET_CONCEPTS = '/settings/concepts/list'
const CREATE_CONCEPT = '/settings/concepts/create'
const UPDATE_CONCEPT = '/settings/concepts'

const getConcepts = () => {
  return axiosService.get(GET_CONCEPTS)
}

const createConcept = (concept) => {
  return axiosService.post(CREATE_CONCEPT, concept)
}

const updateConcept = (id, concept) => {
  return axiosService.put(UPDATE_CONCEPT + "/" + id, concept)
}

export const conceptsInterface = {
  getConcepts,
  createConcept,
  updateConcept,
}
