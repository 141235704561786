import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      width: "400px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3,),
  },
    field: {
    width: "350px",
    marginBottom: "10px",
    },
    input: {
      height: "70px"
    },
    root: {
        width: '400px',
        [theme.breakpoints.down("sm")]: {
          width: "340px"
        },
        height: "auto",
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      button: {
        margin: theme.spacing(1)
      },
      divForm1:{
        width: "400px",
        [theme.breakpoints.down("sm")]: {
          width: "340px"
        },
        height: "auto",
      },
      buttonFormCancel: {
        textTransform: "capitalize",
        marginLeft: "50px",
        [theme.breakpoints.down("sm")]: {
          marginLeft: "70px"
        },
      },
      buttonFormSubmit: {
        textTransform: "capitalize",
        marginLeft: "40px"
      },
      buttonStepper: {
        textTransform:"initial",
      },
      buttonStepperBack: {
        textTransform:"initial",
        marginRight:"5px",
      },
      inputDescription: {
        height: "200px",
        
      },
      buttons: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "15px",
    },
      

  
  }));


export default useStyles;