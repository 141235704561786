import React from "react"

import { Paper } from "@material-ui/core"

import useStyles from "./styles"

const PaperComponent = (props) => {
  const { height, children } = props
  const classes = useStyles(height)

  return (
    <Paper
      variant="elevation"
      className={classes.paper}
      elevation={1}
      style={{ height: height }}
    >
      {children}
    </Paper>
  )
}

export default PaperComponent
