import * as yup from "yup"

export const createLocalities = yup.object().shape({
    localidad: yup.string().required("Este campo es requerido"),
    cp: yup.number().required("Este campo es requerido").typeError("Este campo es requerido"),
})

export const updateLocalities = yup.object().shape({
    localidad: yup.string().required("Este campo es requerido"),
    cp: yup.number().required("Este campo es requerido").typeError("Este campo es requerido").min(0, "El valor debe ser mayor o igual a 0"),
})

export const createAddress = yup.object().shape({
    calle: yup.string().required("Este campo es requerido")
})

export const updateAddress = yup.object().shape({
    calle: yup.string().required("Este campo es requerido")
})