import React from "react"
import { useLocation } from "react-router-dom"

import HomeIcon from "@material-ui/icons/Home"
import DriveEtaIcon from "@material-ui/icons/DriveEta"
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import SettingsIcon from "@material-ui/icons/Settings"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"

import { getNavbar } from "service/helpers/localstorage"

const PageList = (props) => {
  const {
    handleClickVehicle = null,
    handleClickPlanta = null,
    handleClickSetting = null,
    handleClickSales = null,
    menuItems = {
      vehicles: false,
      plant: false,
      sales: false,
      settings: false,
    },
    handleClose = null,
  } = props

  const navbarPermissions = JSON.parse(getNavbar())
  const { pathname } = useLocation()

  const closeAndOrUpdate = (route) => {
    if (handleClose) {
      handleClose()
    }
    if (pathname === route) {
      setTimeout(() => {
        window.location.reload()
      }, 150)
    }
  }

  return [
    {
      text: "Inicio",
      icon: <HomeIcon />,
      keys: "home",
      to: "/home",
      collapse: false,
    },
    {
      text: "Vehículos",
      icon: <DriveEtaIcon />,
      keys: "vehicles",
      collapse: true,
      click: handleClickVehicle,
      open: menuItems.vehicles,
      style: !navbarPermissions.vehicles ? { display: "none" } : {},
      submenu: [
        {
          id: 1,
          text: "Listado de vehículos",
          keys: "search",
          to: "/vehicles/search",
          style: !navbarPermissions.search ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/vehicles/search"),
        },
        {
          id: 2,
          text: "Listado de obleas",
          keys: "wafer",
          to: "/vehicles/wafers",
          style: !navbarPermissions.wafers ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/vehicles/wafers"),
        },
        {
          id: 3,
          text: "Tránsito de vehículos",
          keys: "transit",
          to: "/vehicles/transit",
          style: !navbarPermissions.transit ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/vehicles/transit"),
        },
        {
          id: 4,
          text: "Cotizaciones",
          keys: "quotations",
          to: "/vehicles/quotations",
          style: !navbarPermissions.quotations ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/vehicles/quotations"),
        },
        {
          id: 5,
          text: "Actas compactación",
          keys: "compactionActs",
          to: "/vehicles/compactionActs",
          style: !navbarPermissions.compactionActs ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/vehicles/compactionActs"),
        },
      ],
    },
    {
      text: "Planta",
      icon: <EmojiTransportationIcon />,
      keys: "plant",
      click: handleClickPlanta,
      collapse: true,
      open: menuItems.plant,
      style: !navbarPermissions.plant ? { display: "none" } : {},
      submenu: [
        {
          id: 1,
          text: "Clasificación",
          keys: "clasificacion",
          style: !navbarPermissions.classification ? { display: "none" } : {},
          to: "/plant/classification",
          onClick: () => closeAndOrUpdate("/plant/classification"),
        },
        {
          id: 2,
          text: "Movimiento de stock",
          keys: "movements",
          style: !navbarPermissions.movements ? { display: "none" } : {},
          to: "/plant/movements",
          onClick: () => closeAndOrUpdate("/plant/movements"),
        },
        {
          id: 3,
          text: "Grilla",
          keys: "grilla",
          style: !navbarPermissions.orders ? { display: "none" } : {},
          to: "/plant/orders",
          onClick: () => closeAndOrUpdate("/plant/orders"),
        },
        {
          id: 4,
          text: "Checklist",
          keys: "checklist",
          style: !navbarPermissions.checklist ? { display: "none" } : {},
          to: "/plant/checklist",
          onClick: () => closeAndOrUpdate("/plant/checklist"),
        },
        {
          id: 5,
          text: "Almacenes",
          keys: "almacenes",
          to: "/plant/storages",
          style: !navbarPermissions.storages ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/plant/storages"),
        },
        {
          id: 6,
          text: "Compactación",
          keys: "compactacion",
          to: "/plant/compaction",
          style: !navbarPermissions.compaction ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/plant/compaction"),
        },
        {
          id: 7,
          text: "Remitos",
          keys: "remitos",
          to: "/plant/despatch",
          style: !navbarPermissions.despatch ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/plant/despatch"),
        },
        {
          id: 2,
          text: "Nuevos - usados",
          keys: "used",
          to: "/plant/used",
          style: !navbarPermissions.used ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/plant/used"),
        },
        {
          id: 2,
          text: "Estación cero",
          keys: "zero",
          to: "/plant/zero",
          style: !navbarPermissions.zero ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/plant/zero"),
        },
      ],
    },
    {
      text: "Ventas",
      icon: <ShoppingCartIcon />,
      keys: "sales",
      click: handleClickSales,
      to: "/sales",
      collapse: true,
      open: menuItems.sales,
      style: !navbarPermissions.sales ? { display: "none" } : {},
      submenu: [
        {
          id: 1,
          text: "Stock",
          keys: "stock",
          to: "/sales/stock",
          style: !navbarPermissions.stock ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/sales/stock"),
        },
        {
          id: 2,
          text: "Precios",
          keys: "prices",
          to: "/sales/prices",
          style: !navbarPermissions.prices ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/sales/prices"),
        },
        {
          id: 3,
          text: "Clientes",
          keys: "clients",
          to: "/sales/clients",
          style: !navbarPermissions.clients ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/sales/clients"),
        },
        {
          id: 4,
          text: "Proveedores",
          keys: "suppliers",
          to: "/sales/suppliers",
          style: !navbarPermissions.suppliers ? { display: "none" } : {},
          onClick: () => closeAndOrUpdate("/sales/suppliers"),
        },
      ],
    },
    {
      text: "Administración",
      icon: <AttachMoneyIcon />,
      keys: "administacion",
      to: "/administracion",
      collapse: false,
      style: { display: "none" },
    },
    {
      text: "Configuración",
      icon: <SettingsIcon />,
      keys: "settings",
      click: handleClickSetting,
      collapse: true,
      open: menuItems.settings,
      style: !navbarPermissions.settings ? { display: "none" } : {},
      submenu: [
        {
          id: 1,
          text: "Usuarios",
          keys: "usuarios",
          style: !navbarPermissions.users ? { display: "none" } : {},
          to: "/settings/users",
          onClick: () => closeAndOrUpdate("/settings/users"),
        },
        {
          id: 2,
          text: "Varios",
          keys: "varios",
          style: !navbarPermissions.config ? { display: "none" } : {},
          to: "/settings/config",
          onClick: () => closeAndOrUpdate("/settings/config"),
        },
      ],
    },
    {
      text: "ECOSOFT",
      icon: <LocalShippingIcon />,
      keys: "ecosoft",
      to: "/ecosoft",
      collapse: false,
      style: !navbarPermissions.ecosoft ? { display: "none" } : {},
      onClick: () => closeAndOrUpdate("/ecosoft"),
    },
  ]
}

export default PageList
