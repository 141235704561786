import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const UploadImageDialog = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    onChange,
    selected = false,
    loading,
    multiple = false,
  } = props

  return (
    <DialogComponent
      open={open}
      handleclose={loading ? null : onClose}
      title={multiple ? 'Subir imagen/es' : 'Subir imagen'}
      children={
        <form>
          <input
            style={{ display: "none" }}
            accept="image/*,video/*"
            id="contained-button-file"
            name="file"
            type="file"
            multiple={multiple}
            onChange={onChange}
          />
          <label htmlFor="contained-button-file">
            {selected ? (
              <Button
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                variant="contained"
                fullWidth
                component="span"
                disabled={loading}
              >
                Imagenes seleccionadas
              </Button>
            ) : (
              <Button variant="contained" fullWidth component="span">
                Seleccionar
              </Button>
            )}
          </label>
        </form>
      }
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={() => {
              onClose()
            }}
            onClickSubmit={onSubmit}
            disabledCancel={loading}
            disabledSubmit={loading || !selected}
          />
        </div>
      }
    />
  )
}

export default UploadImageDialog
