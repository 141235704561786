import React, { useState, useEffect } from "react";

import InputSearch from "Components/UI/Atoms/InputSearch";
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon";
import PaperList from "Components/UI/Molecules/Papers/PaperList";
import Edit from "Components/UI/Organisms/Settings/Various/TypeVehicle/Content/Edit";
import New from "Components/UI/Organisms/Settings/Various/TypeVehicle/Content/New";
import Delete from "Components/UI/Organisms/Settings/Various/TypeVehicle/Content/Delete";

import { interfaces } from "service/interfaces";
import SetState from "Components/Hooks/SetState";

const ContentType = () => {

  const [data, setData] = useState([]);
  const [render, setRender] = useState(true);
  
  const {
    state: newVehicle,
    setOpen: openNew,
    setClose: closeNew,
  } = SetState();

  const {
    state: deleteVehicle,
    setOpen: openDelete,
    setClose: closeDelete,
  } = SetState();

  const {
    state: edit,
    setOpen: openEdit,
    setClose: closeEdit
  } = SetState();

  const [id, setId] = useState();
  const [valueSearch, setValueSearch] = useState("");
  const [loading] = useState(false);
  const onChangeNew = (e) => {
    setValueSearch(e.target.value);
  };

  const handleListItemClick = (id) => {
    setId(id.id);
  };

  const changeRender = () => {
    setRender(!render);
  };

  function handleReset() {
    setId();
  }

  useEffect(() => {
    interfaces.interfaceTypeVehicle.list().then((res) => setData(res.data));
  }, [render]);

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueSearch) {
          return e.nombre.toUpperCase().includes(valueSearch.toUpperCase());
        } else {
          return e;
        }
      });
    } else {
      return [];
    }
  }

  function editInfo() {
    return data.filter((e) => {
      return e.id === id;
    })[0];
  }
  return (
    <>
      <InputSearch
        onClick={() => openNew()}
        onChange={onChangeNew}
        value={valueSearch}
      />
      <PaperList
        children={
          <ListWithIcon
            data={filtered(data)}
            nameIncludes="nombre"
            selectedItem={id}
            onClickSelect={handleListItemClick}
            onClickEdit={openEdit}
            loading={loading}
            onClickDelete={openDelete}
          />
        }
        height="500px"
        styles={{ marginBottom: "15px" }}
      />

      {deleteVehicle.open && (
        <Delete
          open={deleteVehicle.open}
          id={id}
          handleRender={changeRender}
          handleClose={closeEdit}
          handleReset={handleReset}
          handleCloseDelete={closeDelete}
        />
      )}

      {edit.suspense && (
        <Edit
          open={edit.open}
          handleclose={closeEdit}
          id={id}
          editInfo={editInfo}
          changeRender={changeRender}
        />
      )}
      {newVehicle.suspense && (
        <New
          open={newVehicle.open}
          handleclose={closeNew}
          changeRender={changeRender}
        />
      )}
    </>
  );
};

export default ContentType;
