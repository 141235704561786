import React, { useRef, useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import Form from "Components/UI/Organisms/Settings/Various/Transport/TransportContent/ItemList/NewTransport/Form"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const NewTransport = (props) => {
  const { handleClose, open, changeRender } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [loading, setLoading] = useState(false)
  const onSubmit = async (values) => {
    setLoading(true)
    const value = { nombre: values.nombre.toUpperCase() }
    await interfaces.configInterface
      .newTransport(value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        handleclosedisabled={loading}
        widthLarge="500px"
        title="Nuevo transporte"
        children={<Form ref={formSubmitRef} onSubmit={onSubmit} />}
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <>
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </>
        }
      />
    </div>
  )
}

export default NewTransport
