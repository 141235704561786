import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

import FormPassword from "Components/UI/Atoms/NewPassword/Form"
import { axiosService } from "service"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const NewPassword = (props) => {
  const { open, handleClose, firstLogIn, disabled, subtitle } = props

  const [loading, setLoading] = useState(false)

  let history = useHistory()

  const formSubmitRef = useRef(null)

  const createDismissableSnackbar = useCustomSnackbar()

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  function backToLogin() {
    history.push("/")
    handleClose()
    axiosService.logout()
  }

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      "Access-Control-Allow-Credentials": "true",
    },
  }

  const onSubmit = async (values) => {
    setLoading(true)
    await interfaces.dashboardInterface
      .userChangePass(values, config)
      .then((res) =>
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      )
      .catch((error) => {
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      })
      .finally(handleClose)
    setLoading(false)
    if (firstLogIn) {
      backToLogin()
    }
  }

  function disabledCancel() {
    if (disabled) {
      return !disabled ? false : true
    }
    if (loading) {
      return loading
    }
  }

  return (
    <DialogComponent
      open={open}
      handleclose={!disabled ? handleClose : null}
      title="Cambio de contraseña"
      disableBackdropClick
      disableChildHeight
      disableEscapeKeyDown
      children={
        <FormPassword
          subtitle={subtitle}
          ref={formSubmitRef}
          onSubmit={onSubmit}
        />
      }
      actions={
        <CancelConfirmButtons
          onClickCancel={handleClose}
          onClickSubmit={handleSubmit}
          disabledCancel={disabledCancel()}
          isLoading={loading}
          disabledSubmit={loading}
        />
      }
    />
  )
}

export default NewPassword
