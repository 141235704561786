import React, { useEffect, useState } from "react"

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Collapse,
} from "@material-ui/core"

import CustomButton from "Components/UI/Atoms/CustomButton"
import SetState from "Components/Hooks/SetState"
import NewPassword from "Components/UI/Atoms/NewPassword"
import PageList from "Components/Routing/Pages"
import {
  getFastAccess,
  getWidgets,
  setFastAccess,
  setWidgets,
} from "service/helpers/localstorage"
import { interfaces } from "service/interfaces"

const ProfileSettings = ({ setSettings }) => {
  const {
    state: password,
    setOpen: openPassword,
    setClose: closePassword,
  } = SetState()

  const { state: access, setState: setAccess } = SetState()
  const [user, setUser] = useState({ user: [], widgets: [] })

  useEffect(() => {
    setUser({
      user: JSON.parse(getFastAccess()),
      widgets: JSON.parse(getWidgets()),
    })
  }, [])

  async function updateUser(type, value) {
    if (type === "user") {
      await interfaces.dashboardInterface.updateSettings({ user: value })
    }
    if (type === "widgets") {
      await interfaces.dashboardInterface.updateSettings({ widgets: value })
    }
  }

  const handleChange = async (e, value, type) => {
    setSettings({ isEdited: true })
    if (type === "user") {
      if (e.target.checked) {
        let addedArr = [...user.user]
        addedArr[addedArr.length] = value
        setUser({ ...user, user: addedArr })
        setFastAccess(JSON.stringify(addedArr))
        await updateUser("user", addedArr)
      } else {
        let removedArr = [...user.user]
        removedArr.splice(removedArr.indexOf(value), 1)
        setUser({ ...user, user: removedArr })
        setFastAccess(JSON.stringify(removedArr))
        await updateUser("user", removedArr)
      }
    } else if (type === "widgets") {
      if (e.target.checked) {
        let addedArr = [...user.widgets]
        addedArr[addedArr.length] = value
        setUser({ ...user, widgets: addedArr })
        setWidgets(JSON.stringify(addedArr))
        await updateUser("widgets", addedArr)
      } else {
        let removedArr = [...user.widgets]
        removedArr.splice(removedArr.indexOf(value), 1)
        setUser({ ...user, widgets: removedArr })
        setWidgets(JSON.stringify(removedArr))
        await updateUser("widgets", removedArr)
      }
    }
  }

  const pageList = PageList({}).filter(
    (e) =>
      ["Vehículos", "Planta", "Configuración", "Ventas"].find(
        (item) => item === e.text
      ) && e.style
  )

  let listOfSubmenu = []
  for (let i = 0; i < pageList.length; i++) {
    listOfSubmenu.push(...pageList[i].submenu)
  }

  listOfSubmenu = listOfSubmenu.filter((e) => Object.keys(e.style).length === 0)

  const widgets = [
    { keys: "weather", text: "Clima" },
    { keys: "exchange", text: "Moneda extranjera" },
    { keys: "andreani", text: "Cotizador Andreani" },
    { keys: "vehicle_photos", text: "Subir fotografías principales" },
    { keys: "vehicle_info", text: "Buscar info de vehículo" },
    { keys: "stock_photos", text: "Subir fotos stock" },
    { keys: "stock_station", text: "Estación cero de piezas" },
  ]

  return (
    <>
      <CustomButton
        color="primary"
        onClick={openPassword}
        text={"Cambiar contraseña"}
        style={{ width: "100%" }}
      />
      <CustomButton
        color="primary"
        onClick={() =>
          setAccess({ openWidget: false, openAccess: !access.openAccess })
        }
        text={"Elegir accesos rápidos (Límite: 3)"}
        style={{ marginTop: "20px", width: "100%" }}
      />

      <Collapse
        in={access.openAccess ? access.openAccess : false}
        timeout="auto"
        style={{
          marginTop: access.openAccess ? "20px" : 0,
          border: "3px solid #3E984C",
          borderRadius: "10px",
        }}
      >
        <List style={{ maxHeight: 500, overflow: "auto" }}>
          {listOfSubmenu.map((item) => {
            return (
              <ListItem key={item.keys} dense>
                <ListItemIcon>
                  <Checkbox
                    checked={user.user.some((e) => e === item.keys)}
                    onChange={(e) => handleChange(e, item.keys, "user")}
                    disabled={
                      !user.user.some((e) => e === item.keys) &&
                      user.user.length >= 3
                    }
                  />
                </ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Collapse>

      <CustomButton
        color="primary"
        onClick={() =>
          setAccess({ openAccess: false, openWidget: !access.openWidget })
        }
        text={"Elementos en pantalla principal"}
        style={{ marginTop: "15px", width: "100%" }}
      />

      <Collapse
        in={access.openWidget ? access.openWidget : false}
        timeout="auto"
        style={{
          marginTop: access.openWidget ? "20px" : 0,
          border: "3px solid #3E984C",
          borderRadius: "10px",
        }}
      >
        <List>
          {widgets.map((item) => {
            return (
              <ListItem key={item.keys} dense>
                <ListItemIcon>
                  <Checkbox
                    checked={user.widgets.some((e) => e === item.keys)}
                    onChange={(e) => handleChange(e, item.keys, "widgets")}
                  />
                </ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Collapse>
      {password.suspense && (
        <NewPassword open={password.open} handleClose={closePassword} />
      )}
    </>
  )
}

export default ProfileSettings
