import React from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

export default function DialogWithActions(props) {
  const { open, handleClose, onSubmit, loading, title } = props
  return (
    <DialogComponent
      open={open}
      handleclose={!loading ? handleClose : null}
      handleclosedisabled={loading}
      widthLarge="500px"
      disableChildHeight
      title={title}
      actions={
        <CancelConfirmButtons
          onClickCancel={handleClose}
          onClickSubmit={onSubmit}
          disabledCancel={loading}
          isLoading={loading}
          disabledSubmit={loading}
        />
      }
    />
  )
}
