import React, { memo, useState } from "react"

import SearchIcon from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment"
import { TextField, IconButton, Tooltip } from "@material-ui/core"

import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import useStyles from "./styles"

export default memo((props) => {
  const { products, models, brands, setSearch, updateTable } = props

  const modelsBrands = [].concat(models, brands).sort(function (a, b) {
    if (a["label"] > b["label"]) {
      return 1
    }
    if (a["label"] < b["label"]) {
      return -1
    }
    return 0
  })

  const classes = useStyles()

  const [value, setValue] = useState({
    producto: "",
    modelo: "",
    buscar: "",
    modelBrands: [],
  })

  const handleChange = (field, valor) => {
    setValue({ ...value, [field]: valor.toUpperCase() })
    setSearch(
      `&producto=${field === "producto" ? valor : value.producto}&modelo=${
        field === "modelo" ? valor : value.modelo
      }&search=${field === "search" ? valor : value.buscar}`
    )
    updateTable()
  }

  return (
    <div className={classes.mainDiv}>
      <div className={classes.products}>
        <CustomAutocomplete
          name="producto"
          size="small"
          disableClearable={false}
          options={products ? products : []}
          autoHighlight={true}
          getOptionLabel={(products) =>
            typeof products === "object" ? products.label : value.producto
          }
          label={"Productos"}
          style={{ width: "100%" }}
          onChange={(_, valor, reason) => {
            if (reason === "clear") return handleChange("producto", "")
            handleChange("producto", valor.label.toUpperCase())
          }}
        />
      </div>
      <div className={classes.models}>
        <CustomAutocomplete
          name="modelo"
          size="small"
          disableClearable={false}
          options={modelsBrands ? modelsBrands : []}
          autoHighlight={true}
          getOptionLabel={(brands) =>
            typeof brands === "object" ? brands.label : value.modelo
          }
          label="Marca/Modelo"
          style={{ width: "100%" }}
          onChange={(_, valor, reason) => {
            if (reason === "clear") return handleChange("modelo", "")
            handleChange("modelo", valor.label.toUpperCase())
          }}
        />
      </div>
      <div className={classes.search}>
        <TextField
          variant="outlined"
          style={{ backgroundColor: "#f2f2f2", maxWidth: "500px" }}
          size="small"
          placeholder={"Buscador rápido"}
          onChange={(e) => {
            setValue({ ...value, buscar: e.target.value.toUpperCase() })
          }}
          value={value.buscar}
          fullWidth
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleChange("buscar", value.buscar)
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Buscar">
                  <IconButton
                    size="small"
                    onClick={() => handleChange("buscar", value.buscar)}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  )
})
