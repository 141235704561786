import React, { useState } from "react"

import { Grid } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { ButtonUploadFile } from "Components/UI/Atoms/ButtonUploadFile"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { NoneEmpty } from "Helpers"

export function DialogUpdateFiles(props) {
  const { start, handleClose } = props

  const createDismissableSnackbar = useCustomSnackbar()

  const [change, setChange] = useState(["", "", "", "", "", ""])
  const [isLoading, setIsLoading] = useState(false)

  function handleChange(e) {
    e.preventDefault()
    let prevData = [...change]
    prevData[parseInt(e.target.id) - 1] = e.target.files[0]
    setChange(prevData)
  }

  async function handleSubmit() {
    if (!NoneEmpty(change)) {
      createDismissableSnackbar("Faltan subir fotos", {
        variant: "error",
      })
    } else {
      setIsLoading(true)
      await interfaces.dashboardInterface
        .uploadPhotos(start.data.dominio, change, 1)
        .then((res) => {
          createDismissableSnackbar(`${res.data.data}`, {
            variant: "success",
          })
          handleClose()
          setIsLoading(false)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
          handleClose()
          setIsLoading(false)
        })
    }
  }

  const INPUTS = [
    {
      id: 1,
      class: "textfield",
      name: "dominio",
      label: "Dominio",
      value: start.data.dominio,
      disabled: true,
      sm: 6,
      xs: 6,
    },
    {
      id: 2,
      class: "textfield",
      name: "nrodev",
      value: start.data.nrodev,
      label: "Nº de V",
      disabled: true,
      sm: 6,
      xs: 6,
    },
    {
      id: 3,
      class: "textfield",
      name: "brand",
      value: start.data.marca,
      label: "Marca",
      disabled: true,
      sm: 6,
      xs: 6,
    },
    {
      id: 4,
      class: "textfield",
      name: "modelo",
      value: start.data.modelo,
      label: "Modelo",
      disabled: true,
      sm: 6,
      xs: 6,
    },
    {
      id: 5,
      class: "textfield",
      name: "año",
      value: start.data.año,
      label: "Año",
      disabled: true,
      sm: 6,
      xs: 6,
    },
    {
      id: 6,
      class: "textfield",
      name: "color",
      value: start.data.color,
      label: "Color",
      disabled: true,
      sm: 6,
      xs: 6,
    },
  ]
  const BUTTONS = [
    {
      id: 1,
      textBefore: "Subir foto frente",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "frente",
      onChange: handleChange,
      value: change[0],
    },
    {
      id: 2,
      textBefore: "Subir foto izquierda",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "izquierda",
      onChange: handleChange,
      value: change[1],
    },
    {
      id: 3,
      textBefore: "Subir foto atras",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "atrás",
      onChange: handleChange,
      value: change[2],
    },
    {
      id: 4,
      textBefore: "Subir foto derecha",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "derecha",
      onChange: handleChange,
      value: change[3],
    },
    {
      id: 5,
      textBefore: "Subir foto motor",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "motor",
      onChange: handleChange,
      value: change[4],
    },
    {
      id: 6,
      textBefore: "Subir foto interior",
      textLater: "Imagen seleccionada",
      variant: "contained",
      sm: 12,
      xs: 12,
      name: "interior",
      onChange: handleChange,
      value: change[5],
    },
  ]

  return (
    <>
      <DialogComponent
        open={start.open}
        title="Subir fotografías principales"
        handleclose={handleClose}
        handleclosedisabled={isLoading}
        maxHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={
          <>
            <InputsWithoutValidate spacing={2} fields={INPUTS} />
            <br />
            <form>
              <Grid container spacing={2}>
                {BUTTONS.map(
                  ({
                    onChange,
                    id,
                    value,
                    textLater,
                    textBefore,
                    name,
                    xs,
                    sm,
                  }) => {
                    return (
                      <Grid item sm={sm} xs={xs}>
                        <ButtonUploadFile
                          id={id}
                          name={name}
                          value={value}
                          textLater={textLater}
                          textBefore={textBefore}
                          onChange={onChange}
                          multiple={false}
                        />
                      </Grid>
                    )
                  }
                )}
              </Grid>
            </form>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            disabledCancel={isLoading}
            disabledSubmit={isLoading}
            isLoading={isLoading}
          />
        }
      />
    </>
  )
}
