import { makeStyles } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  rootVerticalTabs: {
    [theme.breakpoints.only("xs")]: {
      width: "330px",
    },
    [theme.breakpoints.only("sm")]: {
      width: "400px",
      marginLeft: "30px",
      marginRight: "40px",
    },
  },
  style: {
    paddingBottom: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "30px",
      width: "270px",
    },
  },
  gridScroll: {
    overflow: "scroll",
    height: "600px",
    [theme.breakpoints.only("xs")]: {
      height: "520px",
    },
  },
}))
export default useStyles
