import { React, useRef, useState } from "react"

import Form from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Version/NewVersion/Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import moment from "moment"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const NewModel = (props) => {
  const { handleClose, open, changeRender, indexModel, types } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    const value = {
      version: values.version.toUpperCase(),
      inicio: moment(values.inicio).format("yyyy"),
      fin: moment(values.fin).format("yyyy"),
      modelo_id: indexModel,
      vehiculo_tipo_id: values.tipo_baja_id,
    }
    setLoading(true)
    await interfaces.configInterface
      .newBMV(value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        widthLarge="500px"
        title="Nueva versión"
        children={
          <Form ref={formSubmitRef} onSubmit={onSubmit} types={types} />
        }
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default NewModel
