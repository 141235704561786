import React, { useEffect, useState, useRef } from "react"

import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import { printPDF } from "Helpers"
import { interfaces } from "service/interfaces"
import { order } from "Components/Validation/Plant/Orders"

const OrderStart = (props) => {
  const { info, open, handleclose, setRender, render } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const [validate, setValidate] = useState()
  const [dataBefore, setDataBefore] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  useEffect(() => {
    async function apiCall() {
      await interfaces.plantOrders.beforeInitOrder(info.id).then((res) => {
        setDataBefore({
          taller: res.data.operarios_taller,
          desmontaje: res.data.operarios_desmontaje,
        })
        setValidate(res.data.validate)
      })
    }
    setLoading(false)
    apiCall()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.id])

  async function submitBefore(body) {
    setLoadingSubmit(true)
    await interfaces.plantOrders
      .initOrder(info.id, body)
      .then((res) => {
        printPDF(res, 500, 800).print()
        createDismissableSnackbar(`Comanda impresa con éxito!`, {
          variant: "success",
        })
        setRender(!render)
        handleclose()
      })
      .catch((error) => {
        createDismissableSnackbar(`Error`, {
          variant: "error",
        })
      })
      .finally()
    setLoadingSubmit(false)
  }

  const OBJECT = () => [
    {
      class: "autocomplete",
      label: "Operario desmontaje",
      name: "operario_desmontaje_id",
      options: dataBefore.desmontaje ? dataBefore.desmontaje : [],
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
    {
      class: "autocomplete",
      label: "Operario taller",
      name: "operario_taller_id",
      options: dataBefore.taller ? dataBefore.taller : [],
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
  ]

  const Children = () => {
    return (
      <Formik
        initialValues={{
          operario_desmontaje_id: null,
          operario_taller_id: null,
        }}
        onSubmit={submitBefore}
        validationSchema={validate === true ? order : null}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <InputsWithValidate
              fields={OBJECT()}
              setFieldValue={setFieldValue}
              spacing={2}
            />
            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <>
      {loading ? null : (
        <DialogComponent
          open={open}
          widthLarge={"500px"}
          handleclose={handleclose}
          handleclosedisabled={loadingSubmit}
          title={`Comienzo del pedido ${info.id}`}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          subtitle={`${info.articulo} - ${info.vehiculo} `}
          actions={
            <CancelConfirmButtons
              onClickSubmit={handleSubmit}
              onClickCancel={handleclose}
              disabledCancel={loadingSubmit}
              disabledSubmit={loadingSubmit}
              isLoading={loadingSubmit}
            />
          }
          children={Children()}
        />
      )}
    </>
  )
}

export default OrderStart
