export function columnTheme() {
  return {
    "&:nth-child(1)": {
      maxWidth: "140px",
      minWidth: "140px",
      width: "140px",
    },
    "&:nth-child(2)": {
      maxWidth: "100px",
      minWidth: "100px",
      width: "100px",
    },
    "&:nth-child(3)": {
      maxWidth: "70px",
      minWidth: "70px",
      width: "70px",
    },
    "&:nth-child(4)": {
      minWidth: "100px",
      width: "160px",
    },
    "&:nth-child(5)": {
      minWidth: "100px",
      width: "160px",
    },
    "&:nth-child(6)": {
      width: "60px",
    },
    "&:nth-child(7)": {
      width: "80px",
    },
    "&:nth-child(8)": {
      width: "140px",
    },
    "&:nth-child(9)": {
      width: "140px",
    },
    "&:nth-child(10)": {
      width: "250px",
    },
  }
}
