import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import Brand from "./Brand"
import Model from "./Model"
import Version from "./Version"
import { interfaces } from "service/interfaces"

const BMVContent = () => {
  const [data, setData] = useState([])
  const [idBrand, setIdBrand] = useState()
  const [idModel, setIdModel] = useState()
  const [idVersion, setIdVersion] = useState(null)
  const [render, setRender] = useState(true)
  const changeRender = () => {
    setRender(!render)
  }
  const handleListItemClickBrand = (id) => {
    setIdBrand(id.id)
    setIdModel(null)
    setIdVersion(null)
  }

  const handleListItemModel = (id) => {
    setIdModel(id.id)
    setIdVersion(null)
  }

  const handleListItemVersion = (id) => {
    setIdVersion(id.id)
  }

  function handleRemoveClick() {
    setIdVersion(null)
  }

  useEffect(() => {
    interfaces.configInterface.listModelBrand().then((res) => setData(res.data))
  }, [render])

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: "20px" }}>
        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Marcas
          </Typography>
          <Brand
            data={data.brands}
            changeRender={changeRender}
            handleListItemClick={handleListItemClickBrand}
            idBrand={idBrand}
            setIdBrand={setIdBrand}
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Modelos
          </Typography>
          <Model
            data={data.models}
            changeRender={changeRender}
            handleListItemClick={handleListItemModel}
            idModel={idModel}
            idBrand={idBrand}
            setIdModel={setIdModel}
          />
        </Grid>

        <Grid item md={4} sm={12} xs={12}>
          <Typography
            style={{ textAlign: "center", marginBottom: "20px" }}
            variant="h6"
          >
            Versiones
          </Typography>
          <Version
            data={data.versions}
            types={data.types}
            handleRemoveClick={handleRemoveClick}
            changeRender={changeRender}
            handleListItemClick={handleListItemVersion}
            idVersion={idVersion}
            idModel={idModel}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default BMVContent
