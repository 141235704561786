export const centerStyles = (theme) => ({
  paper: {
    width: "100%",
    position: "absolute",
    border: ({ border }) => (border ? "2px #3d92ff solid" : ""),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: ({ widthLarge }) => (widthLarge ? widthLarge : "400px"),
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: ({ maxHeight }) => (maxHeight ? "100vh" : "auto"),
    },
  },
})
