import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  paddingTabs: {
    padding: "5em",
    // overflow: "auto",
    [theme.breakpoints.only("xs")]: {
      padding: "1em",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "end",
    marginTop: "2em",
  },
}))

export default useStyles
