import React, { useState } from "react"

import { Avatar, Grid } from "@material-ui/core"

import useStyles from "./styles"

import CustomButton from "Components/UI/Atoms/CustomButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"

const SettingsHomeUser = (props) => {
  const { data, setData, render, setRender, onClose } = props

  const classes = useStyles()
  const createDismissableSnackbar = useCustomSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const { state: deactive, setClose: setCloseDeactive, setOpen: setOpenDeactive } = SetState()
  const { state: openDelete, setClose: setCloseDelete, setOpen: setOpenDelete } = SetState()

  const onSubmitReset = () => {
    interfaces.configUsersInterface
      .resetPassword(data.data.id)
      .then((res) =>
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      )
      .catch((error) => {
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      })
  }

  async function onSubmitDeactive() {
    await interfaces.configUsersInterface
      .deactivateUser(data.data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setCloseDeactive()
        onClose()
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data, {
          variant: "error",
        })
      })
  }

  const onSubmitDelete = () => {
    interfaces.configUsersInterface
      .deleteUser(data.data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        onClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  function changeProfile(value) {
    let imgUp = URL.createObjectURL(value)
    setData({
      ...data,
      data: {
        foto: imgUp,
      },
    })
  }

  function handleChange(e) {
    let value = e.target.files[0]
    if (value !== null) {
      interfaces.configUsersInterface
        .profilePhoto(data.data.id, value)
        .then(() => {
          createDismissableSnackbar("Foto subida con éxito!", {
            variant: "success",
          })
          changeProfile(value)
        })
        .catch(() => {
          createDismissableSnackbar("Algo salió mal!", {
            variant: "error",
          })
        })
    }
  }

  async function exportUserData() {
    setIsLoading(true)
    await interfaces.configUsersInterface
      .exportUserData(data.data.id)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Historial descargado con éxito!", {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item>
          <Avatar
            src={data.data.foto}
            style={{ width: "150px", height: "150px" }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
        </Grid>
        <Grid sm={12} xs={12} item className={classes.fullWidth}>
          <CustomButton
            text={
              <>
                Cambiar fotografía
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  onChange={(e) => handleChange(e)}
                />
              </>
            }
            fullWidth={"true"}
            color="primary"
            component="label"
            disabled={data.data.activado === 0 ? true : false}
          />
        </Grid>
        <Grid sm={12} xs={12} item className={classes.fullWidth}>
          <CustomButton
            text="Reestablecer contraseña"
            fullWidth={"true"}
            className={classes.buttonRed}
            onClick={onSubmitReset}
            disabled={data.data.activado === 0 ? true : false}
          />
        </Grid>
        <Grid sm={12} xs={12} item className={classes.fullWidth}>
          <CustomButton
            text={
              data.data.activado === 0
                ? "Reactivar usuario"
                : "Desactivar usuario"
            }
            fullWidth={"true"}
            className={
              data.data.activado === 1
                ? classes.buttonRed
                : classes.butttonGreen
            }
            onClick={setOpenDeactive}
            color="primary"
          />
        </Grid>
        <Grid sm={12} xs={12} item className={classes.fullWidth}>
          <CustomButton
            text="Eliminar usuario"
            fullWidth={"true"}
            className={classes.buttonRed}
            onClick={setOpenDelete}
          />
        </Grid>
        <Grid sm={12} xs={12} item className={classes.fullWidth}>
          <CustomButton
            text="Exportar historial"
            fullWidth={"true"}
            className={classes.butttonGreen}
            onClick={exportUserData}
            disabled={isLoading || !Boolean(data.historial.length)}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      {deactive.open && (
        <DialogComponent
          disableChildHeight
          disableBackdropClick
          disableEscapeKeyDown
          title={
            data.data.activado === 1
              ? "¿Desactivar este usuario?"
              : "¿Reactivar este usuario?"
          }
          open={deactive.open}
          handleclose={setCloseDeactive}
          actions={
            <CancelConfirmButtons
              onClickSubmit={onSubmitDeactive}
              onClickCancel={setCloseDeactive}
            />
          }
        />
      )}
      {openDelete.open && (
        <DialogComponent
          title="¿Eliminar este usuario?"
          disableChildHeight
          disableBackdropClick
          disableEscapeKeyDown
          open={openDelete.open}
          handleclose={setCloseDelete}
          actions={
            <CancelConfirmButtons
              onClickSubmit={onSubmitDelete}
              onClickCancel={setCloseDelete}
            />
          }
        />
      )}
    </>
  )
}
export default SettingsHomeUser
