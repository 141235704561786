import { axiosService } from "service"
import { configEndpoints } from "service/sources"

const listModelBrand = () => {
  return axiosService.get(configEndpoints.LIST_MODEL_BRAND)
}

const updateBrand = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_BRAND + "/" + id, body)
}

const newBMV = (body) => {
  return axiosService.post(configEndpoints.NEW_BMV, body)
}

const deleteBrand = (id) => {
  return axiosService.del(configEndpoints.DELETE_BRAND + "/" + id)
}

const deleteModel = (id) => {
  return axiosService.del(configEndpoints.DELETE_MODEL + "/" + id)
}

const updateModel = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_MODEL + "/" + id, body)
}

const deleteVersion = (id) => {
  return axiosService.del(configEndpoints.DELETE_VERSION + "/" + id)
}

const updateVersion = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_VERSION + "/" + id, body)
}
const listCompany = () => {
  return axiosService.get(configEndpoints.COMPANY_LIST)
}
const vehicleCompany = (id) => {
  return axiosService.get(configEndpoints.VEHICLE_COMPANY + "/" + id)
}
const updateCompany = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_COMPANY + "/" + id, body)
}
const deleteCompany = (id) => {
  return axiosService.del(configEndpoints.DELETE_COMPANY + "/" + id)
}
const newCompany = (body) => {
  return axiosService.post(configEndpoints.NEW_COMPANY, body)
}

const changePhoto = (body, id) => {
  return axiosService.uploadSingleFile(
    configEndpoints.CHANGE_PHOTO + "?filename=" + id,
    body
  )
}

const transportList = () => {
  return axiosService.get(configEndpoints.TRANSPORT_LIST)
}

const deleteTransport = (id) => {
  return axiosService.del(configEndpoints.DELETE_TRANSPORT + "/" + id)
}

const newTransport = (body) => {
  return axiosService.post(configEndpoints.NEW_TRANSPORT, body)
}

const updateTransit = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_TRANSPORT + "/" + id, body)
}

const newDriver = (body) => {
  return axiosService.post(configEndpoints.NEW_DRIVER, body)
}

const deleteDriver = (id) => {
  return axiosService.del(configEndpoints.DELETE_DRIVER + "/" + id)
}

const newVehicle = (body) => {
  return axiosService.post(configEndpoints.NEW_VEHICLE, body)
}

const deleteVehicle = (id) => {
  return axiosService.del(configEndpoints.DELETE_VEHICLE + "/" + id)
}
const listFamiliAndProducts = () => {
  return axiosService.get(configEndpoints.LIST_FAMILY_PRODUCTS)
}
const deleteFamily = (id) => {
  return axiosService.del(configEndpoints.DELETE_FAMILY + id)
}
const deleteProduct = (id) => {
  return axiosService.del(configEndpoints.DELETE_PRODUCT + id)
}
const newFamilyOrProduct = (body) => {
  return axiosService.post(configEndpoints.NEW_FAMILY_PRODUCT, body)
}
const updateProduct = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_PRODUCT + id, body)
}

const uploadBrandPhoto = (id, body) => {
  return axiosService.uploadSingleFile(
    configEndpoints.UPLOAD_BRAND_PHOTO + "?filename=" + id,
    body
  )
}

const listUserInsurace = () => {
  return axiosService.get(configEndpoints.LIST_USER_INSURANCE)
}
const newUserInsurance = (body) => {
  return axiosService.post(configEndpoints.NEW_USER_INSURANCE, body)
}
const updateUserInsurance = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_USER_INSURANCE + id, body)
}
const deleteUserInsurance = (id) => {
  return axiosService.del(configEndpoints.DELETE_USER_INSURANCE + id)
}

const listLotsRows = () => {
  return axiosService.get(configEndpoints.LIST_LOTS_ROWS)
}
const newLot = (body) => {
  return axiosService.post(configEndpoints.NEW_LOT, body)
}
const updateLot = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_LOT + id, body)
}
const deleteLot = (id) => {
  return axiosService.del(configEndpoints.DELETE_LOT + id)
}
const newRow = (body) => {
  return axiosService.post(configEndpoints.NEW_ROW, body)
}
const updateRow = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_ROW + id, body)
}
const deleteRow = (id) => {
  return axiosService.del(configEndpoints.DELETE_ROW + id)
}

const listPLA = () => {
  return axiosService.get(configEndpoints.LIST_PLA)
}
const newPLA = (body) => {
  return axiosService.post(configEndpoints.NEW_PLA, body)
}
const deleteLocalities = (id) => {
  return axiosService.del(configEndpoints.DELETE_LOCALITIES + "/" + id)
}
const updateLocalities = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_LOCALITIES + "/" + id, body)
}
const deleteAddress = (id) => {
  return axiosService.del(configEndpoints.DELETE_ADDRESS + "/" + id)
}
const updateAddres = (id, body) => {
  return axiosService.put(configEndpoints.UPDATE_ADDRESS + "/" + id, body)
}
const postLocalities = (body) => {
  return axiosService.post(configEndpoints.POST_LOCALITIES, body)
}

export const configInterface = {
  listModelBrand,
  newBMV,
  deleteBrand,
  updateBrand,
  deleteModel,
  deleteVersion,
  updateVersion,
  listCompany,
  vehicleCompany,
  updateCompany,
  updateModel,
  deleteCompany,
  newCompany,
  changePhoto,
  transportList,
  deleteTransport,
  newTransport,
  updateTransit,
  newDriver,
  deleteDriver,
  newVehicle,
  deleteVehicle,
  listFamiliAndProducts,
  deleteFamily,
  newFamilyOrProduct,
  deleteProduct,
  updateProduct,
  uploadBrandPhoto,
  listUserInsurace,
  newUserInsurance,
  updateUserInsurance,
  deleteUserInsurance,
  listLotsRows,
  newLot,
  updateLot,
  deleteLot,
  newRow,
  updateRow,
  deleteRow,
  listPLA,
  newPLA,
  deleteAddress,
  updateAddres,
  deleteLocalities,
  updateLocalities,
  postLocalities,
}
