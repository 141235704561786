import React from "react"

import { Paper } from "@material-ui/core"

import useStyles from "./styles"

const PaperComponent = (props) => {
  const { height, children, style, classname } = props
  const classes = useStyles(height)

  return (
    <Paper
      variant="elevation"
      className={classname ? classname : classes.paper}
      elevation={1}
      style={style}
    >
      <div className={classes.children}>{children}</div>
    </Paper>
  )
}

export default PaperComponent
