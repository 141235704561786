import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import New from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Brand/New"
import DeleteBrand from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Brand/Delete"
import Edit from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Brand/Edit"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"
const Brand = (props) => {
  const { data, handleListItemClick, idBrand, changeRender, setIdBrand } = props

  const [valueBrand, setValueBrand] = useState("")

  const {
    state: newBrand,
    setOpen: setNewBrand,
    setClose: closeNewBrand,
  } = SetState()

  const {
    state: deleteBrand,
    setOpen: setDelete,
    setClose: setCloseBrand,
  } = SetState()

  const {
    state: openEdit,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const onChangeBrand = (e) => {
    setValueBrand(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueBrand) {
          return e.marca.includes(valueBrand.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setNewBrand}
        onChange={onChangeBrand}
        value={valueBrand}
      />
      {newBrand.suspense && (
        <New
          open={newBrand.open}
          handleClose={closeNewBrand}
          changeRender={changeRender}
        />
      )}
      {deleteBrand.suspense && (
        <DeleteBrand
          open={deleteBrand.open}
          handleClose={setCloseBrand}
          selectedIndex={idBrand}
          changeRender={changeRender}
          setIdBrand={setIdBrand}
        />
      )}

      {openEdit.suspense && (
        <Edit
          open={openEdit.open}
          handleclose={setCloseEdit}
          data={data}
          idBrand={idBrand}
          changeRender={changeRender}
        />
      )}

      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idBrand}
            onClickSelect={handleListItemClick}
            onClickDelete={setDelete}
            onClickEdit={setOpenEdit}
            nameIncludes="marca"
          />
        }
        height="500px"
        disabledOn={idBrand ? false : true}
      />
    </>
  )
}
export default Brand
