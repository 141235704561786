import React, { useState } from "react"
import { useFormik } from "formik"
import { Link } from "react-router-dom"

import { Button, Typography, TextField } from "@material-ui/core"
import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "../styles"
import { validationRemovePass } from "Components/Validation/Login"
import { interfaces } from "service/interfaces"

const Forget = (props) => {
  const [loading, setLoading] = useState(false)
  const [err, setErr] = useState({
    error: false,
    helperText: "",
  })
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      "Access-Control-Allow-Credentials": "true",
    },
  }

  const onSubmit = async (values) => {
    const valor = JSON.stringify(values)
    setLoading(true)
    await interfaces.logInInterface
      .userRemovePass(valor, config)
      .then(() =>
        setTimeout(() => {
          props.onClick()
        }, 1000)
      )
      .catch((error) =>
        setErr({ error: true, helperText: error.response.data.data })
      )
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationRemovePass,
    onSubmit: onSubmit,
  })

  const classes = useStyles()
  return (
    <>
      {loading ? (
        <div className={classes.heightContentSpinner}>
          <CircularProgress size={70} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          {err ? (
            <div style={{ position: "relative", height: "0px", color: "red" }}>
              <div
                style={{ position: "absolute", left: "100px", bottom: "0px" }}
              >
                {" "}
                {err.helperText}
              </div>
            </div>
          ) : null}
          <Grid container>
            <Grid item sm={12}>
              <Typography align="center" className={classes.TextRemovePass}>
                Ingresa tu usuario, y en caso de existir ese usuario, enviaremos
                un mail con una nueva contraseña al mail asociado a tu cuenta o
                a un administrador del sistema.
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField
                className={classes.FormRemovePass}
                margin="normal"
                fullWidth
                autoFocus={false}
                variant="outlined"
                id="username"
                name="username"
                label="Usuario"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                type={"submit"}
                className={classes.submit}
              >
                REINICIAR CONTRASEÑA
              </Button>
            </Grid>

            <Grid container item justify="center">
              <Link
                to={"/oem"}
                variant="body2"
                className={classes.link}
                onClick={props.onClickVolver}
              >
                Volver
              </Link>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default Forget
