import React, { useContext } from "react"
import { Formik, Field } from "formik"

import { Grid, TextField } from "@material-ui/core"

import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"

import { CustomAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const Local = (props) => {
  const [edit] = useContext(EditContext)
  const { formEdit, setFormEdit, formApi, setFormApi, initialData } =
    useContext(FormContext)
  const transportes = formEdit.selectInputs.transporte_retiro
  const chofer = formEdit.selectInputs.transporte_choferes
  const vehicles = formEdit.selectInputs.transporte_camiones

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          [e.target.name]: e.target.value,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeChofer = (value) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        vehiculo_retiro: {
          ...formEdit.vehicle.vehiculo_retiro,
          chofer_local: value.id,
          dni_local: value.dni,
        },
      },
    })
    setFormApi({
      ...formApi,
      vehiculo_retiro: {
        ...formApi.vehiculo_retiro,
        chofer_local: value.id,
        dni_local: value.dni,
      },
    })
  }

  const disabledField = () => {
    if (initialData.header.authorization === 1 && edit) {
      return true
    }
    if (initialData.header.authorization === 1 && !edit) {
      return true
    } else return edit ? false : true
  }

  return (
    <>
      <Formik>
        <Grid
          container
          spacing={2}
          sm={12}
          md={12}
          xs={12}
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item sm={12} xs={12} md={6}>
            <CustomAutocomplete
              options={transportes.sort(function (a, b) {
                if (a.nombre > b.nombre) {
                  return 1
                }
                if (a.nombre < b.nombre) {
                  return -1
                }
                return 0
              })}
              getOptionLabel={(transportes) => transportes.nombre}
              disabled={disabledField()}
              label={"Transportes"}
              onChange={(_, value) => {
                handleChange({
                  target: {
                    name: "transporte_local",
                    value: value.id,
                  },
                })
              }}
              defaultValue={transportes.find(
                (valueId) =>
                  valueId.id ===
                  parseInt(formEdit.vehicle.vehiculo_retiro.transporte_local)
              )}
              name="transporte_local"
            />
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <CustomAutocomplete
              options={vehicles
                .sort(function (a, b) {
                  if (a.marca > b.marca) {
                    return 1
                  }
                  if (a.marca < b.marca) {
                    return -1
                  }
                  return 0
                })
                .filter(
                  (e) =>
                    e.transporte_id ===
                    parseInt(formEdit.vehicle.vehiculo_retiro.transporte_local)
                )}
              getOptionLabel={(vehicles) =>
                vehicles &&
                vehicles.marca + " " + vehicles.modelo + " " + vehicles.dominio
              }
              disabled={disabledField()}
              defaultValue={vehicles.find(
                (valueId) =>
                  valueId.id ===
                  parseInt(formEdit.vehicle.vehiculo_retiro.vehiculo_local)
              )}
              onChange={(event, value) => {
                handleChange({
                  target: { name: "vehiculo_local", value: value.id },
                })
              }}
              label="Vehículo"
            />
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <CustomAutocomplete
              options={chofer
                .sort(function (a, b) {
                  if (a.nombre > b.nombre) {
                    return 1
                  }
                  if (a.nombre < b.nombre) {
                    return -1
                  }
                  return 0
                })
                .filter(
                  (e) =>
                    e.transporte_id ===
                    parseInt(formEdit.vehicle.vehiculo_retiro.transporte_local)
                )}
              getOptionLabel={(chofer) => chofer.nombre}
              disabled={disabledField()}
              defaultValue={chofer.find(
                (valueId) =>
                  valueId.id ===
                  parseInt(formEdit.vehicle.vehiculo_retiro.chofer_local)
              )}
              onChange={(event, value) => {
                handleChangeChofer(value)
              }}
              label="Chofer"
            />
          </Grid>

          <Grid item sm={12} xs={12} md={6}>
            <TextField
              label="Número de documento"
              variant="outlined"
              name="dni_local"
              fullWidth
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={formEdit.vehicle.vehiculo_retiro.dni_local}
              onChange={handleChange}
            />
          </Grid>

          <Grid item sm={6} xs={12} md={6}>
            <Field
              component={TextField}
              disabled={true}
              label="Arrastre"
              variant="outlined"
              name="arrastre"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value="En desarrollo"
              onChange={handleChange}
            />
          </Grid>

          <Grid item sm={6} xs={12} md={6}>
            <Field
              component={TextField}
              disabled={true}
              label="Costo grúa local"
              variant="outlined"
              name="grua_local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value="En desarrollo"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Formik>
    </>
  )
}

export default Local
