import React, { useRef, useState } from "react"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import { newUserInsurance } from "Components/Validation/Settings/Various/UserInsurance"
import { Form, Formik } from "formik"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

const New = ({ open, handleclose, changeRender, insurances }) => {
  const [isLoading, setIsLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()

  const formSubmitRef = useRef(null)
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const onSubmit = async (values) => {
    setIsLoading(true)
    await interfaces.configInterface
      .newUserInsurance(values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    setIsLoading(false)
    changeRender()
    handleclose()
  }

  const field = (setFieldValue) => [
    {
      class: "textfield",
      label: "Nombre de usuario",
      name: "username",
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Nombre",
      name: "nombre",
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Apellido",
      name: "apellido",
      sm: 12,
      xs: 12,
    },

    {
      class: "textfield",
      label: "Correo electrónico",
      name: "email",
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          name="cia_seguros"
          options={insurances}
          getOptionLabel={(e) => e.nombre}
          label={"Compañias asociadas"}
          onChange={(_, value) => {
            setFieldValue("cia_seguros", value)
          }}
          tagName={"nombre"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      label: "Puesto",
      name: "puesto",
      sm: 12,
      xs: 12,
    },
  ]

  const formulario = () => {
    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          username: "",
          nombre: "",
          apellido: "",
          puesto: "",
          email: "",
          cia_seguros: [],
        }}
        validationSchema={newUserInsurance}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={field(setFieldValue)}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    )
  }
  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      title="Nuevo usuario"
      children={formulario()}
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={handleclose}
          disabledCancel={isLoading}
          disabledSubmit={isLoading}
          isLoading={isLoading}
        />
      }
    />
  )
}

export default New
