import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    marginLeft: 256,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  drawerPaper: {
    width: 240,
    border: "1px solid #EBECF0",
    overflow: "hidden",
    borderRadius: "0px 10px 0px 10px ",
    boxSizing: "border-box",
    background: "#f4f7f4",
  },
}))

export default useStyles
