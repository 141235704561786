import React, { useEffect, useState } from "react"

import { Avatar, Grid } from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import CustomButton from "Components/UI/Atoms/CustomButton"

import useStyles from "../styles"
import { interfaces } from "service/interfaces"
import { dinamicsIcon } from "Helpers"

const DrawerHistorial = () => {
  const [data, setData] = useState([])
  const [offset, setOffset] = useState(0)
  const classes = useStyles()
  useEffect(() => {
    interfaces.dashboardInterface
      .userHistory(offset)
      .then((res) => setData((oldArray) => [...oldArray, ...res.data]))
  }, [offset])

  return (
    <div>
      <Grid container>
        <Grid sm={12} xs={12} item>
          <div>
            <List>
              {Object.keys(data).map((option, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      {dinamicsIcon(data[option].tipo)}
                    </Avatar>
                  </ListItemAvatar>

                  <Grid item>
                    <ListItemText primary={data[option].descripcion} />
                    <ListItemText secondary={data[option].fecha} />
                  </Grid>
                </ListItem>
              ))}
            </List>
          </div>
          <div style={{ position: "sticky", bottom: "10px", width: "100%" }}>
            <CustomButton
              fullWidth
              text="Ver más"
              disabled={
                data.length % 25 === 0 || data.length !== 0 ? false : true
              }
              onClick={() => setOffset(offset + 1)}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default DrawerHistorial
