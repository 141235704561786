import React, { useState } from "react"
import moment from "moment"

import { Stepper, Step, StepLabel } from "@material-ui/core"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import {
  FORM_VEHICLE,
  FORM_PRODUCT,
  FORM_FINALIZE,
} from "Components/UI/Organisms/Plant/Used/NewUsed/Fields"
import { interfaces } from "service/interfaces"
import LoadSteps from "Components/UI/Organisms/Plant/Used/NewUsed/LoadSteps"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { printPDF } from "Helpers"

const NewUsed = (props) => {
  const { open, handleclose, data, setRender, render } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const steps = ["Producto", "Vehículo", "Finalizar", ""]

  const [tab, setTab] = useState(0)
  const [step, setStep] = useState(0)
  const [info] = useState(data)
  const [isLoading, setIsLoading] = useState(false)
  const [initValues, setInitValues] = useState({
    producto_id: null,
    ubicacion_id: 1,
    marca_id: null,
    modelo_id: null,
    año: null,
    tipo: "USADO",
    calidad: null,
    almacen_id: null,
    version_id: null,
    motor_id: null,
    combustible: "",
    color: "",
    transmision: "",
    almacen: "",
    comentarios: "",
    code: "",
    fotos: [],
    archivos: [],
    oblea: "",
    conf_oblea: "",
    nro_motor: "",
    nro_chasis: "",
    puertas: null,
    traccion: null,
  })

  function handleChangeUpload(e) {
    e.preventDefault()
    let prevData = [...initValues.fotos]
    let files = e.target.files
    for (let i = 0; i < files.length; i++) {
      prevData[i] = files[i]
    }
    handleChange("fotos", prevData)
  }

  function handleChangeFile(e) {
    e.preventDefault()
    let files = e.target.files[0]
    handleChange("archivos", files)
  }

  const handleChange = (name, value) => {
    setInitValues({ ...initValues, [name]: value })
  }

  const handleChangeProduct = (id, conf_oblea) => {
    setInitValues({ ...initValues, producto_id: id, conf_oblea })
  }

  async function printId(e, id) {
    e.stopPropagation()
    await interfaces.plantUsed
      .stockSticker(id)
      .then((res) => {
        printPDF(res).print()
        createDismissableSnackbar("Sticker impreso con éxito!", {
          variant: "success",
        })
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
  }

  async function handleUploadImg(id) {
    await interfaces.plantUsed
      .uploadPhoto(id, initValues.fotos, 1)
      .then((res) => {
        createDismissableSnackbar(res.data.data.msg, { variant: "success" })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }
  async function handleUploadFile(id) {
    await interfaces.plantUsed
      .uploadFiles(id, initValues.archivos)
      .then((res) => {
        createDismissableSnackbar(res.data.msg, {
          variant: "success",
        })
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  function _handlePrev() {
    if (tab !== 0) {
      setTab(tab - 1)
    } else {
      handleclose()
    }
  }

  async function createUsed() {
    const value = {
      ...initValues,
      año: moment(initValues.año).format("yyyy"),
    }

    let data = await interfaces.plantUsed.newUsed(value)
    return data
  }

  async function handleSubmit(e) {
    if (tab === 2) {
      setTab(tab + 1)
      setStep(0)
      setIsLoading(true)
      let created = await createUsed()
      createDismissableSnackbar(created.data.msg, {
        variant: "success",
      })
      if (initValues.fotos.length > 0) {
        setStep(1)
        await handleUploadImg(created.data.id)
      }
      if (
        initValues.archivos.length !== null &&
        initValues.archivos.name !== undefined
      ) {
        setStep(2)
        await handleUploadFile(created.data.id)
      }
      setStep(3)
      await printId(e, created.data.id)
      handleclose()
      setRender(!render)
      setIsLoading(false)
    } else {
      setTab(tab + 1)
    }
  }

  const confirmSteps = () => {
    let array = [
      { step: 1, label: "Creando piezas en el stock" },
      { step: 3, label: "Creando sticker" },
    ]
    if (initValues.fotos.length > 0) {
      array.push({
        step: 2,
        label: "Subiendo fotografías",
      })
    }
    if (
      initValues.archivos.length !== null &&
      initValues.archivos.name !== undefined
    ) {
      array.push({
        step: initValues.fotos.length > 0 ? 3 : 2,
        label: "Subiendo archivos",
      })
    }
    if (
      initValues.archivos.length !== null &&
      initValues.archivos.name !== undefined
    ) {
      array.push({
        step: initValues.fotos.length > 0 ? 3 : 2,
        label: "Subiendo archivos",
      })
    }
    return array
  }

  const _renderForms = (index) => {
    switch (index) {
      case 1:
        return (
          <InputsWithoutValidate
            fields={FORM_VEHICLE(initValues, info, handleChange)}
            spacing={3}
          />
        )
      case 0:
        return (
          <InputsWithoutValidate
            fields={FORM_PRODUCT(
              info,
              initValues,
              handleChange,
              handleChangeProduct
            )}
            spacing={3}
            setFieldValue={handleChange}
          />
        )
      case 2:
        return (
          <InputsWithoutValidate
            fields={FORM_FINALIZE(
              handleChange,
              handleChangeUpload,
              handleChangeFile,
              initValues
            )}
            spacing={3}
          />
        )

      case 3:
        return <LoadSteps step={step} steps={confirmSteps()} />
      default:
        break
    }
  }

  function disabledSubmit() {
    switch (tab) {
      case 1:
        if (initValues.marca_id && initValues.modelo_id && initValues.año)
          return false
        return true
      case 0:
        if (
          initValues.producto_id &&
          initValues.calidad &&
          initValues.ubicacion_id &&
          initValues.tipo
        )
          return false
        return true
      default:
        break
    }
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      handleclosedisabled={isLoading}
      widthLarge="500px"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      title="Ingreso pieza"
      maxHeight
      children={
        <>
          <Stepper
            alternativeLabel
            activeStep={tab}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingBottom: 20,
            }}
          >
            {steps
              .filter((e) => e !== "")
              .map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
          </Stepper>
          <div style={{ height: "725px" }}>{_renderForms(tab)}</div>
        </>
      }
      actions={
        <CancelConfirmButtons
          onClickSubmit={(e) => handleSubmit(e)}
          onClickCancel={_handlePrev}
          submitText={tab === 2 ? "Confirmar" : "Siguiente"}
          cancelText={tab === 0 ? "Cancelar" : "Atrás"}
          disabledSubmit={isLoading ? true : disabledSubmit(initValues)}
          disabledCancel={isLoading}
        />
      }
    />
  )
}

export default NewUsed
