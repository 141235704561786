import React, { useState, useContext } from "react"

import { Button } from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import New from "../New"
import FormEdit from "../FormEdit"
import { EditContext } from "Components/Hooks/ContextEdit"
import { interfaces } from "service/interfaces"

import { useStyles } from "./styles"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import SetState from "Components/Hooks/SetState"

const ControlOfShipments = (props) => {
  const classes = useStyles()
  const [edit] = useContext(EditContext)
  const [data, setData] = useState([])

  const {
    state: openNewState,
    setOpen: setOpenNew,
    setClose: setCloseNew
  } = SetState()

  const {
    state: openShippingControlState,
    setOpen: setOpenShippingControl,
    setClose: setCloseShippingControl
  } = SetState()

  const [dense] = useState(false)
  const { vehicle_id } = props

  async function apiCall() {
    await interfaces.vehicleInterface
      .allShipping(vehicle_id)
      .then((res) => setData(res.data))
  }

  async function handleOpen() {
    await apiCall()
    setOpenShippingControl()
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleOpen}
        disabled={edit ? false : true}
        className={classes.controlButton}
      >
        Control de envíos
      </Button>
      <DialogComponent
        tipo="center"
        open={openShippingControlState.open}
        handleclose={setCloseShippingControl}
        title="Control de envíos"
        children={
          <List dense={dense}>
            {Object.keys(data).map((item) => (
              <ListItem key={item}>
                <ListItemText
                  primary={`Control de envío #${item * 1 + 1}`}
                  secondary={`${data[item].fecha}`}
                />
                <FormEdit
                  data={data[item]}
                  handleclose={setCloseShippingControl}
                  updateList={apiCall}
                />
              </ListItem>
            ))}
          </List>
        }
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              submitText={"Nuevo"}
              onClickCancel={setCloseShippingControl}
              onClickSubmit={setOpenNew}
            />
          </div>
        }
      />
      <New
        open={openNewState.open}
        handleClose={setCloseNew}
        handleCloseParent={setCloseShippingControl}
      />
    </div>
  )
}

export default ControlOfShipments
