import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"

import NewModel from "./New"
import DeleteModel from "./Delete"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"

import Edit from "Components/UI/Organisms/Settings/Various/BrandModelVersion/BMVContent/Model/Edit"
import SetState from "Components/Hooks/SetState"
const Model = (props) => {
  const {
    data,
    idBrand,
    idModel,
    handleListItemClick,
    changeRender,
    setIdModel,
  } = props
  const [valueModel, setValueModel] = useState("")

  const {
    state: editModel,
    setOpen: setOpenEditModel,
    setClose: setCloseEditModel,
  } = SetState()

  const {
    state: newModel,
    setOpen: setOpenNewModel,
    setClose: setCloseNewModel,
  } = SetState()

  const {
    state: deleteModel,
    setOpen: setOpenDeleteModel,
    setClose: setCloseDeleteModel,
  } = SetState()

  const onChangeModel = (e) => {
    setValueModel(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (idBrand) {
          return (
            e.marca_id === idBrand &&
            e.modelo.includes(valueModel.toUpperCase())
          )
        } else {
          return null
        }
      })
    } else {
      return []
    }
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewModel}
        onChange={onChangeModel}
        value={valueModel}
        disabledButton={idBrand ? false : true}
      />
      <NewModel
        open={newModel.open}
        handleClose={setCloseNewModel}
        changeRender={changeRender}
        idBrand={idBrand}
      />
      <DeleteModel
        open={deleteModel.open}
        handleClose={setCloseDeleteModel}
        idModel={idModel}
        changeRender={changeRender}
        setIdModel={setIdModel}
      />

      {editModel.suspense && (
        <Edit
          open={editModel.open}
          handleclose={setCloseEditModel}
          data={data}
          idModel={idModel}
          changeRender={changeRender}
        />
      )}

      <Papers
        buttonStateList
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idModel}
            onClickSelect={handleListItemClick}
            onClickEdit={setOpenEditModel}
            onClickDelete={setOpenDeleteModel}
            nameIncludes="modelo"
          />
        }
        height="500px"
        text="Eliminar modelo"
        disabledOn={idModel ? false : true}
      />
    </>
  )
}
export default Model
