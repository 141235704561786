import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"

import Delete from "./Delete"
import Edit from "./Update"
import CreateAddress from "./Create"

const Address = (props) => {
  const {
    data,
    handleRemoveClick,
    idAddress,
    handleListItemClick,
    changeRender,
  } = props
  const [ valueAddress, setValueAddress ] = useState("")

  const {
    state: newAddressState,
    setOpen: setOpenNewAddress,
    setClose: setCloseNewAddress,
  } = SetState()

  const {
    state: editAddressState,
    setOpen: setOpenEditAddress,
    setClose: setCloseEditAddress,
  } = SetState()

  const {
    state: deleteAddressState,
    setOpen: setOpenDeleteAddress,
    setClose: setCloseDeleteAddress,
  } = SetState()

  const onChangeModel = (e) => {
    setValueAddress(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueAddress) {
          return e.calle.includes(valueAddress.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  function addressInfo() {
    return data.filter((e) => {
      return e.id === idAddress
    })[0]
  }

  return (
    <>
      <InputSearch
        button={false}
        onClick={setOpenNewAddress}
        onChange={onChangeModel}
        value={valueAddress}
      />
       <CreateAddress
        changeRender={changeRender}
        open={newAddressState.open}
        handleClose={setCloseNewAddress}
      />
      <Delete
        open={deleteAddressState.open}
        handleRemoveClick={handleRemoveClick}
        handleClose={setCloseDeleteAddress}
        idAddress={idAddress}
        changeRender={changeRender}
      />
      <Edit
        idAddress={idAddress}
        changeRender={changeRender}
        open={editAddressState.open}
        handleclose={setCloseEditAddress}
        data={addressInfo}
      /> 
      <Papers
        children={
          <ListWithIcon
            data={filtered(data)}
            search={valueAddress}
            selectedItem={idAddress}
            onClickSelect={handleListItemClick}
            onClickDelete={setOpenDeleteAddress}
            onClickEdit={setOpenEditAddress}
            nameIncludes="calle" 
          />

        }
        height="500px"
        text="Eliminar dirección"
        disabledOn={idAddress && handleListItemClick ? false : true}
        onClick={setOpenDeleteAddress}
      />
    </>
  )
}
export default Address
