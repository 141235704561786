import React, { useState } from 'react'

import ComplexDialog from 'Components/UI/Molecules/ComplexDialog'
import Home from 'Components/UI/Organisms/Settings/Users/UsersDialog/Content/Home'
import Permissions from 'Components/UI/Organisms/Settings/Users/UsersDialog/Content/Permissions'
import useStyles from './styles'
import useCustomSnackbar from 'Components/UI/Atoms/SnackBar'
import { interfaces } from 'service/interfaces'
import BasicTable from 'Components/UI/Atoms/BasicTable'
import ComplexDialogActions from 'Components/UI/Molecules/ComplexDialog/Actions'

const UsersDialog = (props) => {
  const { open, onClose, render, setRender, data: info } = props

  const [data, setData] = useState(info)
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()

  const tabs = [
    {
      title: 'Inicio',
    },
    {
      title: 'Permisos',
    },
    {
      title: 'Historial',
      disabled: data.historial.length === 0,
    },
  ]

  const onSubmit = () => {
    interfaces.configUsersInterface
      .updateUser(data.data.id, data.data)
      .then((res) => {
        onClose()
        createDismissableSnackbar(res.data, {
          variant: 'success',
        })
        setRender(!render)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: 'error',
        })
      })
  }
  const renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={classes.home}>
            <Home
              data={data}
              setData={setData}
              render={render}
              setRender={setRender}
              onClose={onClose}
            />
          </div>
        )
      case 1:
        return (
          <div className={classes.permissions}>
            <Permissions data={data} setData={setData} />
          </div>
        )
      case 2:
        return (
          <div className={classes.history}>
            <div style={{ width: '100%' }}>
              <BasicTable
                col={[
                  { title: 'Descripción', style: { width: '70%' } },
                  { title: 'Fecha', style: { width: '30%' } },
                ]}
                rows={data.historial.map((e) => {
                  return {
                    a: e.descripcion,
                    b: e.fecha,
                  }
                })}
              />
            </div>
          </div>
        )
      default:
        break
    }
  }

  return (
    <ComplexDialog
      open={open}
      onClose={onClose}
      tabs={tabs}
      title={`${data.data.nombre} ${data.data.apellido}`}
      subtitle={`${data.data.puesto} - ${data.data.area}`}
      children={renderForms}
      actions={
        <ComplexDialogActions
          onClose={onClose}
          handleSubmit={onSubmit}
          canEdit={true}
          data={data.data}
        />
      }
    />
  )
}

export default UsersDialog
