import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
  table: {
    height: "485px",
    width: "100%",
    overflow: "auto",
  },
}))
