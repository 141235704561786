import { TextField, InputAdornment } from "@material-ui/core"

import React from "react"

export default function CurrencyRow(props) {
  const { amount, onChangeAmount, label, icon } = props
  return (
    <TextField
      type="number"
      variant="outlined"
      fullWidth
      label={label}
      value={amount}
      onChange={onChangeAmount}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
      }}
    />
  )
}
