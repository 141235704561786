import React, { useState } from "react"

import { Grid, ButtonBase, Card, Typography } from "@material-ui/core"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import PictureCard from "Components/UI/Atoms/PictureCard"

import { interfaces } from "service/interfaces"

import DialogUpload from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/Files/DialogUpload"
import DeleteFile from "Components/UI/Molecules/Vehicles/SearchVehicles/DialogVehicle/Content/Tabs/Content/Files/Tabs/Files/DialogDelete"
import { shortenUrlFiles, fileNameFromURL } from "Helpers"

import { styles } from "./styles"

const AdditionalPhotos = ({ edit, data, id }) => {
  const [change, setChange] = useState()
  const [dataActions, setDataActions] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()

  const classes = styles()

  function handleOpenActions(openRemove, openUpload, data, position) {
    setDataActions({
      openRemove: openRemove,
      openUpload: openUpload,
      index: position,
      data: data,
      id: id,
    })
  }
  function handleChange(e) {
    e.preventDefault()
    let img = e.target.files[0]
    setChange({
      ...change,
      file: img,
    })
  }
  function handleCloseActions() {
    setChange()
    setDataActions({
      openUpload: false,
      openRemove: false,
      id: null,
    })
  }
  function handleAdd(arr) {
    data.archivos.push(arr)
  }
  function handleDeleteContext(index) {
    data.archivos.splice(index, 1)
  }
  async function handleSubmitDelete(arr, index) {
    setIsLoading(true)
    let position = index + 1
    if (arr !== "" && arr !== undefined) {
      await interfaces.quotationsInterface
        .deleteFile(id, position)
        .then((res) => {
          createDismissableSnackbar(res.data, {
            variant: "success",
          })
          handleDeleteContext(index)
          handleCloseActions()
          setIsLoading(false)
        })
        .catch((error) => {
          createDismissableSnackbar(error.response.data.data, {
            variant: "error",
          })
          handleCloseActions()
          setIsLoading(false)
        })
    } else {
      createDismissableSnackbar("Este archivo no existe! ", {
        variant: "error",
      })
      handleCloseActions()
    }
  }

  async function handleSubmitUpload(value) {
    setIsLoading(true)
    await interfaces.quotationsInterface
      .uploadFile(id, data.dominio, value)
      .then((res) => {
        createDismissableSnackbar(`${res.data.msg}`, {
          variant: "success",
        })
        handleAdd(res.data.files[res.data.files.length - 1])
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    handleCloseActions()
    setIsLoading(false)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justify="center">
        {data.archivos.map((item, index) => (
          <Grid item xs={12} xl={4} sm={6} md={4} className={classes.root2}>
            <PictureCard
              img={process.env.PUBLIC_URL + "/Images/file.png"}
              href={item}
              edit={edit}
              txt={shortenUrlFiles(data.archivos[index])}
              longtxt={fileNameFromURL(data.archivos[index])}
              index={data.archivos.index}
              handleDelete={() => handleOpenActions(true, false, item, index)}
            />
          </Grid>
        ))}
        {edit ? (
          <Grid item xs={12} xl={4} sm={6} md={4}>
            <ButtonBase
              component="a"
              onClick={() => handleOpenActions(false, true)}
            >
              <Card className={classes.cardSelectImg}>
                <Typography>Seleccione un archivo..</Typography>
              </Card>
            </ButtonBase>
          </Grid>
        ) : null}
      </Grid>
      <DeleteFile
        data={dataActions}
        open={dataActions.openRemove}
        handleClose={handleCloseActions}
        onSubmit={handleSubmitDelete}
        loading={isLoading}
      />
      <DialogUpload
        handleChange={handleChange}
        change={change}
        data={dataActions}
        onSubmit={() => handleSubmitUpload(change.file)}
        handleClose={handleCloseActions}
        open={dataActions.openUpload}
        loading={isLoading}
      />
    </div>
  )
}

export default AdditionalPhotos
