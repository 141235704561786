/* eslint-disable array-callback-return */
import React from "react"

import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { Checkbox, Paper } from "@material-ui/core"

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell)

const ChecklistGrid2 = (props) => {
  const { data, filteredData, setData } = props
  const setNew = (array) => {
    setData({
      ...data,
      data: {
        ...data.data,
        auth: {
          ...data.data.auth,
          array,
        },
      },
    })
  }

  const handleChange = (e, position) => {
    const clonedData = [...filteredData]
    clonedData[position].autorizacion = Number(e.target.checked)
    setNew(clonedData)
  }
  return (
    <Paper>
      <div style={{ overflow: "auto", height: "550px" }}>
        <Table style={{ tableLayout: "fixed" }} size="small">
          <TableBody>
            {filteredData.map((item, index) => (
              <StyledTableRow>
                <StyledTableCell style={{ width: "10%" }}>
                  <Checkbox
                    checked={item.autorizacion}
                    onChange={(e) => handleChange(e, index, item.id)}
                    disabled={data.data.activado === 0}
                  />
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: "20%" }}>
                  {item.descripcion}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default ChecklistGrid2
