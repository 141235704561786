import React, { useState, useRef } from "react"
import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import InputButton from "Components/UI/Organisms/Plant/Stock/PaperDevolution/DialogDevolution/InputButton"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { formRefund } from "Components/Validation/Plant/Stock"
import { interfaces } from "service/interfaces"

const DialogRefund = (props) => {
  const { open, setClose } = props
  const createDismissableSnackbar = useCustomSnackbar()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()

  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  function handleClose() {
    setClose()
    setData()
  }

  const onSubmitGet = (id) => {
    interfaces.plantInterfaceStock
      .returnData(Object.values(id))
      .then((res) => setData(res.data))
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const onSubmitConfirm = (values) => {
    setLoading(true)
    const valuesSubmit = {
      calidad: values.calidad,
      estado_id: values.estado,
      comentarios: values.comentarios,
    }
    interfaces.plantInterfaceStock
      .returnConfirm(data.codigo, valuesSubmit)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const OBJETO = () => [
    {
      id: 2,
      value: data ? data.producto : "",
      class: "textfield",
      label: "Producto",
      name: "producto",
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 3,
      value: data ? data.vehiculo : "",
      class: "textfield",
      label: "Vehículo",
      name: "vehiculo",
      sm: 12,
      xs: 12,
      disabled: true,
    },
    {
      id: 3,
      class: "autocomplete",
      label: "Calidad",
      name: "calidad",
      options: data ? data.calidades : [],
      optionLabel: `options["nombre"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      sortBy: "nombre",
    },
    {
      id: 3,
      class: "autocomplete",
      label: "Estado",
      name: "estado",
      options: data ? data.estados : [],
      optionLabel: `options["label"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
      sortBy: "label",
    },
    {
      id: 3,
      class: "textfield",
      label: "Comentarios",
      name: "comentarios",
      sm: 12,
      xs: 12,
      multiline: true,
      rows: 2,
    },
  ]

  const children = (
    <div style={{ height: "550px" }}>
      <InputButton
        name="id"
        label={`Código de pieza: ${data ? data.codigo : ""}`}
        onSubmit={onSubmitGet}
      />
      <Formik
        onSubmit={onSubmitConfirm}
        initialValues={{
          producto: "",
          vehiculo: "",
          calidad: "",
          estado: "",
          comentarios: "",
        }}
        validationSchema={formRefund}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <InputsWithValidate
              fields={OBJETO()}
              spacing={2}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />

            <button
              style={{ display: "none" }}
              type="submit"
              ref={formSubmitRef}
            />
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <DialogComponent
      open={open}
      handleclose={handleClose}
      children={children}
      maxHeight
      title={"Nueva devolución"}
      handleclosedisabled={loading}
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleClose}
            onClickSubmit={handleSubmit}
            disabledSubmit={loading}
            disabledCancel={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default DialogRefund
