import React, { useRef, useState } from "react"
import { Form, Formik } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { exportClientsValidation } from "Components/Validation/Sales/Clients"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

const ExportClients = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const formSubmitRef = useRef(null)

  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const onSubmit = async (values) => {
    setLoading(true)

    await interfaces.salesClients["export" + values.tipo](values)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        onClose()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  const enteprises = [
    { id: 1, label: "ECO" },
    { id: 2, label: "OEM" },
    { id: 3, label: "DOS" },
  ]
  const type = [
    { label: "Clientes", value: "Clients" },
    { label: "Proveedores", value: "Providers" },
  ]

  const today = new Date()

  const fields = (setFieldValue) => [
    {
      class: "custom",
      component: (
        <FormikAutocomplete
          label="Empresa"
          name="empresa"
          options={enteprises}
          getOptionLabel={(e) => e.label}
          onChange={(_, value) => {
            setFieldValue("empresa", value.id)
          }}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <FormikAutocomplete
          label="Tipo"
          name="tipo"
          options={type}
          getOptionLabel={(e) => e.label}
          onChange={(_, value) => {
            setFieldValue("tipo", value.value)
          }}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "datepicker",
      label: "Fecha final",
      format: "dd-MM-yyyy",
      name: "final",
      value: today,
      sm: 12,
      xs: 12,
    },
  ]

  return (
    <DialogComponent
      open={open}
      handleclose={onClose}
      title="Exportar saldo de clientes o proveedores"
      disableBackdropClick={true}
      handleclosedisabled={loading}
      disableChildHeight
      disableEscapeKeyDown
      widthLarge={"500px"}
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={onClose}
          disabledCancel={loading}
          disabledSubmit={loading}
          isLoading={loading}
        />
      }
      children={
        <Formik
          initialValues={{
            empresa: null,
            tipo: "",
            inicio: "01-01-2000",
            final: today,
          }}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={exportClientsValidation}
        >
          {({ setFieldValue }) => (
            <Form>
              <InputsWithValidate
                fields={fields(setFieldValue)}
                spacing={2}
                setFieldValue={setFieldValue}
              />
              <button
                style={{ display: "none" }}
                type="submit"
                ref={formSubmitRef}
              />
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export default ExportClients
