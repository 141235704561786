import React from "react"

import { Popover } from "@material-ui/core/"

import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import PaperComponent from "Components/UI/Atoms/PaperCompoent"
import { pop } from "Components/UI/Organisms/Ecosoft/Body/Fields"
import useStyles from "./styles"

const MoreFilters = (props) => {
  const classes = useStyles()
  const {
    anchorEl,
    setAnchorEl,
    handleChange,
    valueFilter,
    clearFilters,
    versionFilter,
  } = props

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transition
        style={{ zIndex: 1000 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PaperComponent
          classname={classes.popContent}
          style={{ maxHeight: "80vh", overflowY: "auto" }}
          children={
            <InputsWithoutValidate
              fields={pop(
                handleChange,
                valueFilter,
                clearFilters,
                versionFilter
              )}
              spacing={2}
            />
          }
        />
      </Popover>
    </>
  )
}

export default MoreFilters
