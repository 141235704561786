import React, { useEffect, useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import { useStyles } from "./style"
import FormCompany from "./Form"
import InputSearch from "Components/UI/Atoms/InputSearch"

import PaperList from "Components/UI/Molecules/Papers/PaperList"
import ButtonNew from "Components/UI/Organisms/Settings/Various/Insurance/PaperCompany/ButtonNew.js/index.js"

import { interfaces } from "service/interfaces"
import ButtonDelete from "./ButtonDelete"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"

const PaperCompany = (props) => {
  const { setCanClose, canClose } = props

  const [selectedId, setSelectedId] = useState()
  const [data, setData] = useState([])
  const [change, setChange] = useState("")
  const [render, setRender] = useState(false)
  const [oneInfo, setOneInfo] = useState([])
  const [changeForm, setChangeForm] = useState([])
  const [disbledOthers, setDisbledOthers] = useState(false)

  const createDismissableSnackbar = useCustomSnackbar()
  const classes = useStyles()

  const {
    state: openDelete,
    setOpen: setOpenDelete,
    setClose: setCloseDelete,
  } = SetState()

  const {
    state: openNew,
    setOpen: setOpenNew,
    setClose: setCloseNew,
  } = SetState()

  function handleChange(e) {
    setChange(e.target.value)
  }

  function handleCloseDelete() {
    setRender(!render)
    setCloseDelete()
  }

  function handleClose() {
    setRender(!render)
    setCloseNew()
  }

  const handleListItemClick = (item) => {
    if (selectedId !== undefined) {
      if (JSON.stringify(oneInfo) === JSON.stringify(changeForm)) {
        setOneInfo(item)
        setSelectedId(item.id)
      } else {
        createDismissableSnackbar("Tiene cambios sin guardar!", {
          variant: "error",
        })
      }
    } else if (selectedId === undefined) {
      setOneInfo(item)
      setSelectedId(item.id)
    }
  }
  function handleRender() {
    setRender(!render)
  }
  function handleResetSelect() {
    setChangeForm(oneInfo)
    setDisbledOthers(false)
    setSelectedId()
  }
  useEffect(() => {
    interfaces.configInterface.listCompany().then((res) => {
      setData(res.data)
    })
  }, [render])

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (change) {
          return e.nombre.includes(change.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }
  ;(function test() {
    if (selectedId !== null) {
      if (JSON.stringify(oneInfo) === JSON.stringify(changeForm)) {
        setCanClose(true)
      } else {
        setCanClose(false)
      }
    }
  })()
  return (
    <div style={{ paddingBottom: "30px" }}>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12}>
          <InputSearch
            onChange={handleChange}
            onClick={setOpenNew}
            button={true}
          />
        </Grid>
        <ButtonNew
          open={openNew.open}
          handleClose={handleClose}
          handleRender={handleRender}
        />
        <Grid item sm={5} xs={12}>
          <PaperList
            height="560px"
            disabledOn={selectedId === undefined}
            text="Eliminar compañía de seguros"
            onClick={setOpenDelete}
            children={
              <ListWithIcon
                data={filtered(data)}
                disabledOthers={disbledOthers || !canClose}
                selectedItem={selectedId}
                onClickSelect={handleListItemClick}
                onClickDelete={setOpenDelete}
                hiddenDelete={disbledOthers || !canClose}
                nameIncludes="nombre"
              />
            }
          />
        </Grid>
        <Grid item sm={7} xs={12}>
          {selectedId >= 0 ? (
            <PaperList
              height="560px"
              children={
                <FormCompany
                  setChanges={setChangeForm}
                  changes={changeForm}
                  data={oneInfo}
                  id={selectedId}
                  handleResetSelect={handleResetSelect}
                  handleRender={handleRender}
                  render={render}
                  listCompany={data}
                  setDisbledOthers={setDisbledOthers}
                />
              }
            />
          ) : (
            <PaperList
              height="560px"
              children={
                <div className={classes.divPaperPreSelect}>
                  <Typography align="center">
                    Seleccione una compañía para editar
                  </Typography>
                </div>
              }
            />
          )}
        </Grid>
      </Grid>
      <ButtonDelete
        handleResetSelect={handleResetSelect}
        open={openDelete.open}
        id={selectedId}
        handleClose={handleCloseDelete}
      />
    </div>
  )
}
export default PaperCompany
