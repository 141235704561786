import React, { useEffect, useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import New from "./New"
import SetState from "Components/Hooks/SetState"
import Children from "./Children"

const Permission = (props) => {
  const { data, idNavbar, changeRender, editTemplate, fullData, setData } =
    props
  const [valuePermission, setValuePermission] = useState("")
  const {
    state: newPermission,
    setOpen: setNewPermission,
    setClose: closeNewPermission,
  } = SetState()

  const onChangeNavbar = (e) => {
    setValuePermission(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (idNavbar) {
          return (
            e.navbar_id === idNavbar &&
            e.descripcion.toUpperCase().includes(valuePermission.toUpperCase())
          )
        } else {
          return null
        }
      })
    } else {
      return []
    }
  }

  const [selectedItems, setSelectedItems] = useState([editTemplate.permisos])
  useEffect(() => {
    setSelectedItems(editTemplate.permisos)
  }, [editTemplate])

  return (
    <>
      <InputSearch
        button={false}
        onClick={setNewPermission}
        onChange={onChangeNavbar}
        value={valuePermission}
        disabledButton={idNavbar ? false : true}
      />
      {newPermission.suspense && (
        <New
          open={newPermission.open}
          handleClose={closeNewPermission}
          changeRender={changeRender}
          indexNavbar={idNavbar}
        />
      )}
      <Papers
        children={
          <Children
            data={data}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            filtered={filtered}
            setData={setData}
            fullData={fullData}
            editTemplate={editTemplate}
          />
        }
        height="500px"
      />
    </>
  )
}
export default Permission
