import React from "react"

import {
  Card,
  CardMedia,
  IconButton,
  CardHeader,
  CardActionArea,
  Typography,
  Tooltip,
  Fab,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import PublishIcon from "@material-ui/icons/Publish"
import GetAppIcon from "@material-ui/icons/GetApp"

import ModalSimple from "Components/UI/Molecules/Modals/Simple"
import { styles } from "./styles"
import SwipeableTextMobileStepper from "../SwipeableViews"
import { fileTypeTest } from "Helpers"
import SetState from "Components/Hooks/SetState"

const PictureCard = (props) => {
  const {
    img,
    index,
    title,
    href,
    txt,
    longtxt,
    editIcon,
    editDelete = true,
    handleDelete,
    swipes,
    handleUpload,
    edit,
    onClick,
  } = props

  const classes = styles()

  const { state, setOpen, setClose } = SetState()

  const fileType = fileTypeTest(href)

  function downloadFile() {
    if (href) return window.location.assign(href)
    return window.open(img)
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Tooltip title={longtxt ? longtxt : txt}>
            <Typography
              variant="body1"
              style={{
                overflow: "hidden",
                wordWrap: "break-word",
                textOverflow: "ellipsis",
              }}
              display="block"
            >
              {txt}
            </Typography>
          </Tooltip>
        }
        action={
          edit ? (
            <div>
              {editIcon ? (
                <IconButton onClick={handleUpload}>
                  <PublishIcon color="primary" />
                </IconButton>
              ) : null}
              {editDelete ? (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon className={classes.icondelete} />
                </IconButton>
              ) : null}
            </div>
          ) : null
        }
      />
      <CardActionArea
        target="_blank"
        href={fileType === "video" ? null : href}
        onClick={() =>
          fileType === "doc" ? null : onClick ? onClick : setOpen()
        }
      >
        <CardMedia
          className={classes.media}
          component="img"
          image={img}
          {...props}
        />
      </CardActionArea>

      <ModalSimple
        open={state.open}
        onClose={setClose}
        div={
          <div style={{ position: "relative" }}>
            {fileType ? null : (
              <SwipeableTextMobileStepper
                component="img"
                style={{
                  objectFit: "contain",
                  width: "60vw",
                  height: "42vw",
                }}
                image={img}
                title={title}
                swipes={swipes}
                index={index}
                {...props}
              />
            )}
            <Tooltip title="Descargar">
              <Fab
                color="primary"
                style={{ position: "absolute", right: 20, bottom: 30 }}
                onClick={() => downloadFile()}
              >
                <GetAppIcon />
              </Fab>
            </Tooltip>
          </div>
        }
      />
    </Card>
  )
}

export default PictureCard
