import React, { useState, useEffect } from 'react'

import PrintIcon from '@material-ui/icons/Print'
import DeleteIcon from '@material-ui/icons/Delete'

import ComplexDialog from 'Components/UI/Molecules/ComplexDialog'
import { home, transport } from './forms'
import Articles from './Articles'
import InputsWithoutValidate from 'Components/UI/Molecules/InputsWithoutValidate'
import useStyles from './styles'
import ComplexDialogActions from 'Components/UI/Molecules/ComplexDialog/Actions'
import DialogComponent from 'Components/UI/Molecules/Modals/DialogComponent'
import SetState from 'Components/Hooks/SetState'
import CancelConfirmButtons from 'Components/UI/Molecules/CancelConfirmButtons'
import ComplexDialogHistory from 'Components/UI/Molecules/ComplexDialogHistory'

export default function DialogMoreInfo(props) {
  const { save, open, handleClose, editData, print, deleteDespatch } = props

  const classes = useStyles()

  const [data, setData] = useState(editData)

  useEffect(() => {
    setData(editData)
  }, [editData])

  const { state, setOpen, setClose } = SetState()

  const list = [
    {
      id: 1,
      title: 'Inicio',
    },
    {
      id: 2,
      title: 'Transporte',
    },
    {
      id: 3,
      title: 'Artículos',
    },
    {
      id: 4,
      title: 'Historial',
      hidden: data.history.length === 0,
    },
  ]

  const handleChange = (e) => {
    setData({
      ...data,
      data: {
        ...data.data,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeAutoComplete = (object) => {
    setData({
      ...data,
      data: {
        ...data.data,
        ...object,
      },
    })
  }

  const pushItem = (newItem) => {
    if (newItem.length) {
      setData({
        ...data,
        items: [...newItem, ...data.items],
      })
    } else {
      setData({
        ...data,
        items: [newItem, ...data.items],
      })
    }
  }

  const deleteItem = (id) => {
    let newItem = data.items.filter((e) => e.id !== id)
    setData({
      ...data,
      items: newItem,
    })
  }

  const renderSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={home(
                data.data,
                data.cliente_resp,
                data.cuit,
                handleChange,
                handleChangeAutoComplete
              )}
              spacing={2}
            />
          </div>
        )
      case 1:
        return (
          <div className={classes.paddingTabs}>
            <InputsWithoutValidate
              fields={transport(
                data.data,
                data.transports,
                handleChangeAutoComplete
              )}
              spacing={2}
            />
          </div>
        )
      case 2:
        return (
          <div className={classes.paddingArticle}>
            <Articles
              id={data.data.id}
              rows={data.items}
              pushItem={pushItem}
              deleteItem={deleteItem}
            />
          </div>
        )
      case 3:
        return (
          <div className={classes.paddingHistory}>
            <ComplexDialogHistory history={data.history} />
          </div>
        )
      default:
        break
    }
  }

  const subtitle = () => {
    return (
      <div
        className={classes.div}
      >{`Creado el ${data.data.fecha} por ${data.data.usuario}`}</div>
    )
  }

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={handleClose}
        title={`${data.data.tipo} Nº ${data.data.id}`}
        subtitle={subtitle()}
        tabs={list}
        children={renderSteps}
        actions={
          <ComplexDialogActions
            onClose={handleClose}
            handleSubmit={() => save(data)}
            canEdit={true}
            data={data.data}
            leftButton={{
              icon: <DeleteIcon fontSize='small' />,
              title: 'Eliminar',
              disabled: data.data.estado === 'Pendiente' ? false : true,
              variant: 'contained',
              style:
                data.data.estado === 'Pendiente'
                  ? { background: 'rgb(244, 67, 54)', color: '#fff' }
                  : {},
              onClick: () => {
                setOpen({ id: data.data.id })
              },
            }}
            rightButton={{
              icon: <PrintIcon fontSize='small' />,
              title: data.data.estado === 'Pendiente' ? 'Generar' : 'Imprimir',
              disabled: data.items.length === 0 ? true : false,
              variant: 'contained',
              color: 'primary',
              onClick: () => {
                print(data.data.id, false, data)
              },
            }}
          />
        }
      />
      {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={setClose}
          title='¿Desea eliminar este remito?'
          subtitle='¡Esta acción es irreversible!'
          disableChildHeight
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          widthLarge='500px'
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              onClickSubmit={() => deleteDespatch(state.id, setClose)}
            />
          }
        />
      )}
    </>
  )
}
