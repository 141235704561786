import { axiosService } from "service"
import { engineEndpoints } from "service/sources"

const configList = (limit, offset, search) => {
  let OPTIONS
  OPTIONS = `?limit=${limit}&offset=${offset}`

  if (search) {
    OPTIONS = OPTIONS.concat(`&search=${search}`)
  }
  return axiosService.get(engineEndpoints.ENGINE_LIST + OPTIONS)
}

const newEngine = (code) => {
  return axiosService.post(engineEndpoints.NEW_ENGINE, code)
}
const updateEngine = (id, body) => {
  return axiosService.put(engineEndpoints.UPDATE_ENGINE + `/${id}`, body)
}
const vehicleEngine = (id) => {
  return axiosService.get(engineEndpoints.VEHICLE_ENGINE + `/${id}`)
}
const deleteEngine = (id) => {
  return axiosService.del(engineEndpoints.UPDATE_ENGINE + `/${id}`)
}
const exportEngine = () => {
  return axiosService.get(engineEndpoints.EXPORT_MOTORES)
}

const upgradeEngines = () => {
  return axiosService.post(engineEndpoints.UPGRADE_ENGINE)
}

const updateAllEngines = (data) => {
  return axiosService.put(engineEndpoints.UPDATE_ALL_ENGINES, data)
}

export const interfaceEngine = {
  configList,
  newEngine,
  updateEngine,
  vehicleEngine,
  deleteEngine,
  exportEngine,
  upgradeEngines,
  updateAllEngines,
}
