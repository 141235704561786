import React, { useRef, useState } from "react"

import { Field, Formik, Form } from "formik"
import { TextField } from "formik-material-ui"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import { interfaces } from "service/interfaces"

const Edit = (props) => {
  const { open, handleclose, data, idTemplate, changeRender } = props

  const createDismissableSnackbar = useCustomSnackbar()
  const refInput = useRef(null)
  function handleRef() {
    refInput.current.click()
  }

  function templateInfo() {
    return data.filter((e) => {
      return e.id === idTemplate
    })[0]
  }

  const info = templateInfo()
  const [loading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    setLoading(true)
    const value = [
      {
        id: idTemplate,
        nombre: (values.nombre =
          values.nombre.charAt(0).toUpperCase() +
          values.nombre.slice(1).toLowerCase()),
        permisos: info.permisos,
      },
    ]
    await interfaces.configUsersInterface
      .updateTemplate(value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    handleclose()
    setLoading(false)
  }

  return (
    <>
      <DialogComponent
        open={open}
        handleclose={handleclose}
        widthLarge={"500px"}
        title="Editar template"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={
          <>
            <Formik
              enableReinitialize
              initialValues={{
                nombre: info.nombre,
              }}
              onSubmit={(values) => {
                onSubmit(values)
              }}
              // validationSchema={model}
            >
              {({ values }) => (
                <Form>
                  <Field
                    style={{ height: "70px" }}
                    label="Nombre nombre"
                    value={values.nombre}
                    autoFocus={true}
                    component={TextField}
                    name="nombre"
                    fullWidth
                    disabled={false}
                    variant="outlined"
                  />

                  <button
                    style={{ display: "none" }}
                    ref={refInput}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </>
        }
        actions={
          <CancelConfirmButtons
            onClickSubmit={handleRef}
            onClickCancel={handleclose}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        }
      />
    </>
  )
}

export default Edit
