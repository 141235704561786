import React from "react"

import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import SearchIcon from "@material-ui/icons/Search"
import { Button } from "@material-ui/core"

import useStyles from "./styles"

const InputSearch = (props) => {
  const {
    onClick,
    button = false,
    fullWidth = true,
    onChange,
    value,
    children,
    disabledButton,
    onKeyDown,
  } = props

  const classes = useStyles()

  return (
    <div className={classes.grow}>
      <TextField
        fullWidth={fullWidth}
        size="small"
        id="search"
        onKeyDown={onKeyDown ? onKeyDown : null}
        variant="outlined"
        placeholder="Buscador"
        onChange={onChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {button ? (
        <Button
          startIcon={<AddIcon />}
          onClick={() => onClick()}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Nuevo
        </Button>
      ) : (
        <div>
          <Button
            disabled={disabledButton}
            onClick={() => onClick()}
            color="primary"
            variant="contained"
            className={classes.button}
            size="large"
          >
            <AddIcon />
          </Button>
        </div>
      )}
      {children ? <div style={{ marginLeft: "15px" }}>{children}</div> : null}
    </div>
  )
}

export default InputSearch
