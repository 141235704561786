//user interface, call of axios with sources
import { axiosService } from "../../../index"
import { searchvehiclesEndpoints } from "../../../sources"

const vehicleList = (options) => {
  return axiosService.get(searchvehiclesEndpoints.VEHICLES + options)
}

const tableFilters = () => {
  return axiosService.get(searchvehiclesEndpoints.TABLE_FILTERS)
}

const oneVehicle = (id) => {
  return axiosService.get(searchvehiclesEndpoints.ONE_VEHICLE + "/" + id)
}

const putOneVehicle = (id, body) => {
  return axiosService.put(searchvehiclesEndpoints.VEHICLES + "/" + id, body)
}

const postNewVehicle = (body) => {
  return axiosService.post(searchvehiclesEndpoints.POST_NEW_VEHICLE, body)
}

const getNewVehicle = (params) => {
  return axiosService.get(searchvehiclesEndpoints.GET_NEW_VEHICLE, params)
}

const exportAllVehicles = (params) => {
  return axiosService.get(searchvehiclesEndpoints.EXPORT_ALL_VEHICLES, params)
}


const exportAllShipping = (params) => {
  return axiosService.get(searchvehiclesEndpoints.EXPORT_ALL_SHIPPING, params)
}
const exportAllRetreats = (params) => {
  return axiosService.get(searchvehiclesEndpoints.EXPORT_ALL_RETREATS, params)
}

const allShipping = (id) => {
  return axiosService.get(searchvehiclesEndpoints.ALL_SHIPPING + "/" + id)
}

const vehicleSticker = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.VEHICLE_STICKER + "/" + id)
}

const shippingSticker = (id) => {
  return axiosService.getPDF(
    searchvehiclesEndpoints.SHIPPING_STICKER + "/" + id
  )
}

const deleteShipping = (id) => {
  return axiosService.del(searchvehiclesEndpoints.UPDATE_SHIPPING + "/" + id)
}

const vehicleOffer = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.VEHICLE_OFFER + "/" + id)
}
const deleteFile = (type, filename, dominio, body) => {
  return axiosService.post(
    searchvehiclesEndpoints.DELETE_FILE +
      "?tipo=" +
      type +
      "&filename=" +
      filename +
      "&dominio=" +
      dominio,
    body
  )
}
const uploadPhoto = (file, filename, dominio, type) => {
  return axiosService.uploadSingleFile(
    searchvehiclesEndpoints.UPLOAD_FILE +
      `?filename=${filename}&tipo=${type}&dominio=${dominio}`,
    file
  )
}

const uploadAdditionalPhotos = (files, index, dominio) => {
  return axiosService.uploadFiles(
    `${searchvehiclesEndpoints.UPLOAD_ADDITIONAL}?dominio=${dominio}`,
    files,
    index
  )
}

const vehicleControl = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.VEHICLE_CONTROL + "/" + id)
}

const vehicleRetreat = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.VEHICLE_RETREAT + "/" + id)
}

const newPayment = (id, body) => {
  return axiosService.post(searchvehiclesEndpoints.NEW_PAYMENT + "/" + id, body)
}

const getAllPayments = (id) => {
  return axiosService.get(searchvehiclesEndpoints.GET_ALL_PAYMENTS + "/" + id)
}

const updatePayment = (id, body) => {
  return axiosService.put(
    searchvehiclesEndpoints.UPDATE_PAYMENT + "/" + id,
    body
  )
}

const deletePayment = (id) => {
  return axiosService.del(
    searchvehiclesEndpoints.DELETE_PAYMENT + "/" + id,
  )
}

const getAllVehicleForPayments = () => {
  return axiosService.get(searchvehiclesEndpoints.GET_VEHICLES_PAYMENT)
}

const getCompanies = () => {
  return axiosService.get(searchvehiclesEndpoints.GET_COMPANIES)
}

const dnrpaCall = (id) => {
  return axiosService.post(searchvehiclesEndpoints.DNRPA_DATA + id)
}

const newShipping = (id, body) => {
  return axiosService.post(
    searchvehiclesEndpoints.NEW_SHIPPING + "/" + id,
    body
  )
}

const updateShipping = (id, body) => {
  return axiosService.put(
    searchvehiclesEndpoints.UPDATE_SHIPPING + "/" + id,
    body
  )
}

const front04D = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.FRONT_04D + "/" + id)
}

const back04D = (id) => {
  return axiosService.getPDF(searchvehiclesEndpoints.BACK_04D + "/" + id)
}

const dnrpaAll = () => {
  return axiosService.post(searchvehiclesEndpoints.DNRPA_DATA)
}

const beforeQuotation = () => {
  return axiosService.get(searchvehiclesEndpoints.BEFORE_QUOTATION)
}

const quotation = (body) => {
  return axiosService.getPDF(searchvehiclesEndpoints.QUOTATION, body)
}

const exportQuotations = () => {
  return axiosService.get(searchvehiclesEndpoints.EXPORT_QUOTATIONS)
}

const convert04BD = (id) => {
  return axiosService.post(searchvehiclesEndpoints.CONVERT_04BD + "/" + id)
}

const convert04Not = (id) => {
  return axiosService.post(searchvehiclesEndpoints.CONVERT_04NOT + "/" + id)
}

const deleteVehicle = (id) => {
  return axiosService.del(searchvehiclesEndpoints.DELETE_VEHICLE + "/" + id)
}

const exportPayments = (params) => {
  return axiosService.get(searchvehiclesEndpoints.EXPORT_PAYMENTS, params)
}

const getVehiclesStock = (id) => {
  return axiosService.get(searchvehiclesEndpoints.VEHICLES_STOCK + "/" + id)
}

const getTransferedStock = (id) => {
  return axiosService.get(searchvehiclesEndpoints.TRANSFERED_STOCK + id)
}

const returnVehicles = (id) => {
  return axiosService.post(searchvehiclesEndpoints.RETURN_VEHICLES + id)
}

export const vehicleInterface = {
  postNewVehicle,
  tableFilters,
  getNewVehicle,
  exportAllVehicles,
  vehicleList,
  oneVehicle,
  allShipping,
  putOneVehicle,
  vehicleSticker,
  vehicleOffer,
  vehicleControl,
  vehicleRetreat,
  deleteFile,
  newPayment,
  getAllPayments,
  updatePayment,
  deletePayment,
  dnrpaCall,
  newShipping,
  updateShipping,
  front04D,
  back04D,
  uploadPhoto,
  shippingSticker,
  dnrpaAll,
  beforeQuotation,
  quotation,
  getAllVehicleForPayments,
  getCompanies,
  exportQuotations,
  uploadAdditionalPhotos,
  deleteShipping,
  convert04BD,
  convert04Not,
  deleteVehicle,
  exportPayments,
  getVehiclesStock,
  getTransferedStock,
  returnVehicles,
  exportAllShipping,
  exportAllRetreats
}
