import { CITIES } from "./cities.js"
const INGRESO_PREDIO = [
  {
    ingreso_predio: "SI",
    id: "1",
  },
  {
    ingreso_predio: "NO",
    id: "0",
  },
]

const COLORS = [
  {
    id: 1,
    color: "AZUL",
  },
  {
    id: 2,
    color: "BEIGE",
  },
  {
    id: 3,
    color: "BLANCO",
  },
  {
    id: 4,
    color: "BORDO",
  },
  {
    id: 5,
    color: "CELESTE",
  },
  {
    id: 6,
    color: "DORADO",
  },
  {
    id: 7,
    color: "GRIS",
  },
  {
    id: 8,
    color: "MARRON",
  },
  {
    id: 9,
    color: "NARANJA",
  },
  {
    id: 10,
    color: "NEGRO",
  },
  {
    id: 11,
    color: "ROJO",
  },
  {
    id: 12,
    color: "VERDE",
  },
]
const FUEL = [
  {
    id: 1,
    combustible: "NAFTA",
  },
  {
    id: 2,
    combustible: "DIÉSEL",
  },
]
const TRANSSMISION = [
  {
    id: 1,
    TRANSSMISION: "MANUAL",
  },
  {
    id: 2,
    TRANSSMISION: "AUTOMÁTICA",
  },
]
const BIEN_DE_USO = [
  {
    id: 1,
    BIEN_DE_USO: "SI",
  },
  {
    id: 2,
    BIEN_DE_USO: "NO",
  },
]
const TRACTION = [
  {
    id: 1,
    TRACTION: "Delantera",
  },
  {
    id: 2,
    TRACTION: "Trasera",
  },
  {
    id: 3,
    TRACTION: "4X4",
  },
  {
    id: 4,
    TRACTION: "6X2",
  },
  {
    id: 5,
    TRACTION: "6X4",
  },
  {
    id: 6,
    TRACTION: "8X4",
  },
]
const TIPO_BAJA = [
  {
    baja: "04-D",
    id: 1,
  },
  {
    baja: "04-AM",
    id: 3,
  },
  {
    baja: "04-BD",
    id: 2,
  },
  {
    baja: "08",
    id: 4,
  },
  {
    baja: "04-D (No apto)",
    id: 5,
  },
]

const PROVINCIAS = [
  {
    id: 1,
    provincia: "BUENOS AIRES",
  },
  {
    id: 2,
    provincia: "CABA",
  },
  {
    id: 3,
    provincia: "CATAMARCA",
  },
  {
    id: 4,
    provincia: "CHACO",
  },
  {
    id: 5,
    provincia: "CHUBUT",
  },
  {
    id: 6,
    provincia: "CORDOBA",
  },
  {
    id: 7,
    provincia: "CORRIENTES",
  },
  {
    id: 8,
    provincia: "ENTRE RIOS",
  },
  {
    id: 9,
    provincia: "FORMOSA",
  },
  {
    id: 10,
    provincia: "JUJUY",
  },
  {
    id: 11,
    provincia: "LA PAMPA",
  },
  {
    id: 12,
    provincia: "LA RIOJA",
  },
  {
    id: 13,
    provincia: "MENDOZA",
  },
  {
    id: 14,
    provincia: "MISIONES",
  },
  {
    id: 15,
    provincia: "NEUQUEN",
  },
  {
    id: 16,
    provincia: "RIO NEGRO",
  },
  {
    id: 17,
    provincia: "SALTA",
  },
  {
    id: 18,
    provincia: "SAN JUAN",
  },
  {
    id: 19,
    provincia: "SAN LUIS",
  },
  {
    id: 20,
    provincia: "SANTA CRUZ",
  },
  {
    id: 21,
    provincia: "SANTA FE",
  },
  {
    id: 22,
    provincia: "SANTIAGO DEL ESTERO",
  },
  {
    id: 23,
    provincia: "TIERRA DEL FUEGO",
  },
  {
    id: 24,
    provincia: "TUCUMAN",
  },
]

const MAIN_LOCATIONS = [
  {
    id: 1,
    label: "ECO Río Cuarto",
  },
  {
    id: 2,
    label: "ECO Spernanzoni",
  },
  {
    id: 3,
    label: "ECO Ruta 7",
  },
]

const CHECKLIST_MOTOR = [
  {
    id: 1,
    label: "Kilometraje",
  },
  {
    id: 2,
    label: "Ejecución de limpieza externa",
  },
  {
    id: 3,
    label: "Motor quemado",
  },
  {
    id: 4,
    label: "Kilometraje",
  },
  {
    id: 5,
    label: "Ejecución de limpieza externa",
  },
  {
    id: 6,
    label: "Motor quemado",
  },
]

const TIPO_PEDIDO = [
  {
    value: 1,
    tipo: "POR CÓDIGO/VEHÍCULO",
  },
  {
    value: 2,
    tipo: "MANUAL",
  },
]

const EMPRESAS = [
  {
    id: 3,
    label: "Dominio/Nº V",
  },
  {
    id: 1,
    label: "Cód. ECO",
  },
  {
    id: 2,
    label: "Cód. OEM",
  },
]

const UBICACIONES_VACIO = [
  {
    id: 0,
    nombre: "",
  },
]

const PRODUCTOS = [
  {
    id: 0,
    producto: "",
  },
]

const MANUAL = [
  {
    id: 1,
    tipo: "Desmontaje",
  },
  {
    id: 2,
    tipo: "Limpieza",
  },
  {
    id: 3,
    tipo: "Mantenimiento",
  },
  {
    id: 4,
    tipo: "Actividades de Stock",
  },
  {
    id: 5,
    tipo: "Otros",
  },
]

const UBICACIONES_MANUAL = [
  {
    id: 1,
    nombre: "ECO Río Cuarto",
  },
  {
    id: 2,
    nombre: "ECO Spernanzoni",
  },
  {
    id: 3,
    nombre: "ECO Ruta 7",
  },
  {
    id: 4,
    nombre: "ECO 25 de Mayo",
  },
  {
    id: 5,
    nombre: "OEM Ruta 7",
  },
  {
    id: 7,
    nombre: "En transito",
  },
]

const OPERARIOS_ROLES = [
  { id: 1, rol: "Checklist" },
  { id: 2, rol: "Taller" },
  { id: 3, rol: "Desmontaje" },
]

export const TIPO_COTIZACION = [
  { value: "Compulsa", label: "Compulsa" },
  { value: "Directa", label: "Directa" },
  { value: "Preadjudicacion", label: "Preadjudicación" },
]

const URGENCIA = [
  {
    id: 3,
    nombre: "Baja",
  },
  {
    id: 2,
    nombre: "Media",
  },
  {
    id: 1,
    nombre: "Alta",
  },
]

const TIPOS_REMITO = [
  {
    id: 1,
    optionLabel: "REMITO R",
  },
  {
    id: 2,
    optionLabel: "INTERNO",
  },
  {
    id: 3,
    optionLabel: "RETIRO",
  },
]
const ORIGEN_DESTINO = [
  {
    id: 1,
    optionLabel: "ECO Río Cuarto",
  },
  {
    id: 4,
    optionLabel: "ECO 25 Mayo",
  },
  {
    id: 3,
    optionLabel: "ECO Ruta 7",
  },
  {
    id: 2,
    optionLabel: "ECO Spernanzoni",
  },
  {
    id: 5,
    optionLabel: "OEM Ruta 7",
  },
]

const PLAZO_FECHAS = [
  {
    id: 2,
    value: "48 horas",
  },
  {
    id: 3,
    value: "72 horas",
  },
  {
    id: 4,
    value: "5 días",
  },
  {
    id: 5,
    value: "7 días",
  },
  {
    id: 6,
    value: "MercadoLibre (tiempo indefinido)",
  },
]

const EMPRESAS_STOCK = [
  {
    id: 1,
    optionLabel: "ECO",
  },
  {
    id: 2,
    optionLabel: "OEM",
  },
]

const EMPRESAS_ECOSOFT = [
  {
    id: 1,
    label: "ECO",
  },
  {
    id: 2,
    label: "NH",
  },
]

const TIPO_VEHICULO = [
  {
    id: 0,
    label: "UNIDAD",
  },
  {
    id: 1,
    label: "ARRASTRE",
  },
]

const DISEÑO_VEHICULOS = [
  { id: 1, tipo: "Automóvil" },
  { id: 2, tipo: "Utilitarios" },
  { id: 3, tipo: "Motocicletas" },
  { id: 4, tipo: "Camiones" },
  { id: 5, tipo: "Exclusivos" },
  { id: 6, tipo: "Otros" },
  { id: 7, tipo: "Pick Up" },
  { id: 8, tipo: "SUV's" },
  { id: 9, tipo: "Todoterreno" },
]

const TIPO = [
  {
    label: "USADO",
    value: "USADO",
  },
  {
    label: "NUEVO",
    value: "NUEVO",
  },
  {
    label: "REACONDICIONADO",
    value: "REACONDICIONADO",
  },
]

const ESTADO_REGISTRAL = [
  {
    label: "FINALIZADOS",
    value: "Finalizado",
  },
  {
    label: "NO FINALIZADO",
    value: "No finalizado",
  },
  {
    label: "EN TRÁMITE",
    value: "En trámite",
  },
  {
    label: "PENDIENTE DE COMPACTACIÓN",
    value: "Pendiente de compactación",
  },
  {
    label: "COMPACTADO",
    value: "Compactado",
  },
  {
    label: "CHASIS VENDIDO",
    value: "Chasis vendido",
  },
  {
    label: "TODOS",
    value: "Todos",
  },
]

const OPTIONS_PHOTOS = [
  {
    label: "FRENTE",
    value: 0,
  },
  {
    label: "IZQUIERDA",
    value: 1,
  },
  {
    label: "ATRAS",
    value: 2,
  },
  {
    label: "DERECHA",
    value: 3,
  },
  {
    label: "MOTOR",
    value: 4,
  },
  {
    label: "INTERIOR",
    value: 5,
  },
]

const ENGINE_ALIMENTATION = [
  {
    id: "Carburador",
    optionLabel: "Carburador",
  },
  {
    id: "Convencional",
    optionLabel: "Convencional",
  },
  {
    id: "Directa Diesel",
    optionLabel: "Directa Diesel",
  },
  {
    id: "Directa Nafta",
    optionLabel: "Directa Nafta",
  },
  {
    id: "Monopunto",
    optionLabel: "Monopunto",
  },
  {
    id: "Multipunto",
    optionLabel: "Multipunto",
  },
  {
    id: "Iny bomba",
    optionLabel: "Iny bomba",
  },
]

const CANCELATION_MOTIVES = [
  { label: "NO ADJUDICADO", value: "NO ADJUDICADO" },
  { label: "CANCELADO", value: "CANCELADO" },
  { label: "ADJ. OTRA BAJA", value: "ADJ. OTRA BAJA" },
]
const UBICACION_COMPACTACION = [
  {
    id: 1,
    nombre: "ECO Río Cuarto",
  },
  {
    id: 2,
    nombre: "ECO Spernanzoni",
  },
  {
    id: 3,
    nombre: "ECO Ruta 7",
  },
  {
    id: 4,
    nombre: "ECO 25 de Mayo",
  },
  {
    id: 5,
    nombre: "OEM Ruta 7",
  }
]

export {
  BIEN_DE_USO,
  COLORS,
  FUEL,
  TRANSSMISION,
  TRACTION,
  TIPO_BAJA,
  CITIES,
  PROVINCIAS,
  MAIN_LOCATIONS,
  CHECKLIST_MOTOR,
  TIPO_PEDIDO,
  EMPRESAS,
  UBICACIONES_VACIO,
  EMPRESAS_ECOSOFT,
  PRODUCTOS,
  UBICACIONES_MANUAL,
  URGENCIA,
  MANUAL,
  TIPOS_REMITO,
  ORIGEN_DESTINO,
  PLAZO_FECHAS,
  EMPRESAS_STOCK,
  TIPO_VEHICULO,
  DISEÑO_VEHICULOS,
  TIPO,
  OPERARIOS_ROLES,
  ESTADO_REGISTRAL,
  OPTIONS_PHOTOS,
  ENGINE_ALIMENTATION,
  INGRESO_PREDIO,
  CANCELATION_MOTIVES,
  UBICACION_COMPACTACION,
}
