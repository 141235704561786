import { makeStyles } from "@material-ui/core"

export const styles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "552px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))
