import React, { useState } from "react"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"

import { Grid, Typography, Button } from "@material-ui/core"

import { useStyles } from "./style"
import { interfaces } from "service/interfaces"
import { validationDomainDashboard } from "Components/Validation/Home"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import Content from "Components/UI/Organisms/Home/Widgets/InfoAndLocation/Content"
import SetState from "Components/Hooks/SetState"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { printPDF } from "Helpers"
import Loader from "Components/UI/Atoms/Loader"

const UpdateFiles = () => {
  const classes = useStyles()

  const createDismissableSnackbar = useCustomSnackbar()

  const { state, setOpen, setClose } = SetState()

  const [data, setData] = useState({})
  const [original, setOriginal] = useState({})
  const [loading, setLoading] = useState(false)
  const [loadingPrint, setLoadingPrint] = useState(false)
  async function searchVehicle(values) {
    let vehiculo = { vehiculo: values.dominio.toUpperCase() }
    setLoading(true)
    await interfaces.dashboardInterface
      .getVehicleInfoAndPhoto(vehiculo)
      .then((res) => {
        setOpen()
        setData(res.data)
        setOriginal(res.data)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
    setLoading(false)
  }

  function handleChange(name, value, name2, value2, name3, value3) {
    name3
      ? setData({
          ...data,
          [name]: value,
          [name2]: value2,
          [name3]: value3,
        })
      : name2
      ? setData({
          ...data,
          [name]: value,
          [name2]: value2,
        })
      : setData({
          ...data,
          [name]: value,
        })
  }
  async function updateVehicle() {
    const value = {
      ubicacion_id: data.ubicacion_id,
      fila: data.fila,
      lote: data.lote,
      compactado: data.lots.find(
        (lote) =>
          lote.numero === data.lote && lote.ubicacion_id === data.ubicacion_id
      )?.es_compactacion
        ? 1
        : 0,
    }

    await interfaces.dashboardInterface
      .updateVehicle(data.vehiculo_id, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        handleChange("almacen", res.data)
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })

    setClose()
  }

  async function print(e) {
    e.stopPropagation()
    setLoadingPrint(true)
    await interfaces.vehicleInterface
      .vehicleSticker(data.vehiculo_id)
      .then((res) => {
        printPDF(res, 500, 800).print()
      })
      .catch(() =>
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      )
    setLoadingPrint(false)
  }

  return (
    <div>
      <Grid spacing={1}>
        <Grid item sm={12} xs={12}>
          <Typography className={classes.textPrimary}>
            Buscar info de vehículo
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Typography variant="subtitle1">
            Ingreso y ubicación de un vehículo
          </Typography>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Formik
            initialValues={{ dominio: "" }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationDomainDashboard}
            onSubmit={(values, { resetForm }) => {
              searchVehicle(values)
              resetForm({})
            }}
          >
            {({ values }) => (
              <Form>
                <Grid item sm={12} xs={12}>
                  <Field
                    style={{ height: "70px" }}
                    component={TextField}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    disabled={false}
                    name="dominio"
                    label="Dominio / Nro. de V"
                    value={values.dominio.toUpperCase()}
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    Buscar vehículo
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {loadingPrint ? <Loader /> : null}
      {state.suspense && (
        <DialogComponent
          disableBackdropClick
          disableEscapeKeyDown
          handleclosedisabled={loading}
          widthLarge="70vw"
          title="Información y ubicación cambiando cosas"
          open={state.open}
          handleclose={setClose}
          children={<Content data={data} handleChange={handleChange} />}
          maxHeight={true}
          actions={
            <>
              <CancelConfirmButtons
                onClickCancel={setClose}
                onClickSubmit={updateVehicle}
                isLoading={loading}
                isLoadingThird={loadingPrint}
                thirdButton
                onClickThird={(e) => print(e)}
                thirdText="Sticker"
                disabledThird={loadingPrint}
                disabledSubmit={
                  JSON.stringify(data) === JSON.stringify(original)
                    ? true
                    : false
                }
                disabledCancel={
                  JSON.stringify(data) === JSON.stringify(original)
                    ? true
                    : false
                }
              />
            </>
          }
        />
      )}
    </div>
  )
}

export default UpdateFiles
