import React, { useState } from "react"

import { Checkbox, Grid, IconButton, TextField } from "@material-ui/core"

import MessageIcon from "@material-ui/icons/Message"

import DialogCheckbox from "Components/UI/Organisms/Plant/Checklist/NewChecklist/Steps/SecondStep/Dialog"

const CheckboxLabels = (props) => {
  const { handleChangeMsj, handleChange, values, data, setData } = props
  const [start, setStart] = useState({
    index: null,
    open: false,
  })

  function handleClose() {
    setStart({
      index: null,
      open: false,
    })
  }

  const setNew = (array) => {
    setData({
      ...data,
      check: array,
    })
  }

  const handleChangeCheck = (e, position) => {
    const clonedData = [...data.check]
    clonedData[position].check = e.target.checked
    setNew(clonedData)
  }
  return (
    <>
      <Grid container style={{ overflow: "scroll", height: "500px" }}>
        <Grid sm={12} xs={12} item>
          <TextField
            name="kilometros"
            label="Kilometros"
            onChange={handleChange}
            type="number"
            fullWidth
            style={{ height: "80px", marginTop: "7px" }}
            disabled={false}
            variant="filled"
            value={data.extraData.kilometros}
            InputProps={{
              endAdornment: <b>Km</b>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <br />
        {data.check.map((item, index) => (
          <>
            <Grid item sm={10} xs={10}>
              <Checkbox
                onChange={(e) => handleChangeCheck(e, index)}
                checked={item.check}
                disabled={item.detalles === "Kilometraje" ? true : false}
              />
              {item.detalles}
            </Grid>
            <Grid item sm={2} xs={2}>
              <IconButton
                disabled={item.detalles === "Kilometraje" ? true : false}
                onClick={() => {
                  setStart({
                    index: index,
                    open: true,
                  })
                }}
              >
                <MessageIcon />
              </IconButton>
            </Grid>
          </>
        ))}
      </Grid>

      <DialogCheckbox
        start={start}
        values={values}
        handleChangeMsj={handleChangeMsj}
        handleClose={handleClose}
        data={data}
      />
    </>
  )
}

export default CheckboxLabels
