import React, { useEffect } from "react"

import TemplateSearchVehicle from "Components/UI/Templates/Vehicles/SearchVehicles"

const SearchVehicles = () => {
  useEffect(() => {
    document.title = "Listado de vehículos"
  }, [])

  return <TemplateSearchVehicle />
}

export default SearchVehicles
