import React, { useState, useEffect } from "react"

const ImageZoom = (props) => {
  const { src, height, width, isFullScreen, zoom, className, id, alt } = props

  const [zoomed, setZoomed] = useState("1")
  const [position, setPosition] = useState("50% 50%")
  const [imgData, setImgData] = useState(null)
  let figureClass = imgData ? "loaded" : "loading"
  let figureZoomed = zoomed === "0" ? "zoomed" : "fullView"

  function zoomInPosition(e) {
    const zoomer = e.currentTarget.getBoundingClientRect()
    let x, y, offsetX, offsetY
    offsetX = e.clientX - zoomer.x
    offsetY = e.clientY - zoomer.y
    x = (offsetX / zoomer.width) * 100
    y = (offsetY / zoomer.height) * 100
    setPosition(`${x}% ${y}% `)
  }

  function toggleZoomImage(e) {
    if (zoomed === "0") {
      setZoomed("1")
    } else {
      setZoomed("0")
      zoomInPosition(e)
    }
  }

  function handleClick(e) {
    toggleZoomImage(e)
  }

  function handleMove(e) {
    if (zoomed === "0") {
      zoomInPosition(e)
    }
  }

  function handleLeave() {
    setZoomed("1")
    setPosition("50% 50%")
  }
  useEffect(() => {
    setZoomed("0")
    let img = new Image()
    img.src = src
    img.addEventListener("load", () => {
      setTimeout(() => {
        setZoomed("1")
        setImgData(img.src)
      }, 200)
    })
    // eslint-disable-next-line
  }, [])

  return (
    <figure
      id={id}
      className={[figureClass, figureZoomed, className].join(" ")}
      style={{
        backgroundImage: "url(" + imgData + ")",
        backgroundSize: zoom + "%",
        backgroundPosition: position,
        position: "relative",
        display: "inline-block",
        width: "auto",
        backgroundColor: "#eee",
        margin: "0",
        overflow: "hidden",
        cursor: "zoom-in",
      }}
      onClick={(e) => handleClick(e)}
      onMouseMove={(e) => handleMove(e)}
      onMouseLeave={() => handleLeave()}
    >
      <img
        id="imageZoom"
        src={imgData}
        alt={alt}
        style={{
          transition: "opacity 0.8s",
          display: "block",
          maxHeight: "85vh",
          opacity: zoomed,
          height: isFullScreen ? "85vh" : height,
          width: width,
        }}
      />
    </figure>
  )
}

ImageZoom.defaultProps = {
  zoom: "200",
  alt: "This is an imageZoom image",
  width: "100%",
  height: "auto",
}

export default ImageZoom
