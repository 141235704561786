import React, { useState, useEffect, useRef, useContext } from "react"
import { useHistory } from "react-router-dom"

import {
  AppBar,
  Box,
  Avatar,
  Divider,
  Grow,
  Toolbar,
  IconButton,
  Badge,
  MenuItem,
  ClickAwayListener,
  Popper,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"
import NotificationsIcon from "@material-ui/icons/Notifications"
import MyLocationIcon from "@material-ui/icons/MyLocation"
import SettingsIcon from "@material-ui/icons/Settings"
import HistoryIcon from "@material-ui/icons/History"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

import Profile from "Components/UI/Organisms/Home/Dashboard/Profile"
import History from "Components/UI/Organisms/Home/Dashboard/History"
import NavPopover from "Components/UI/Organisms/Home/NavBar/NavPopover"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import ProfileSettings from "Components/UI/Organisms/Home/Dashboard/Settings"

import SetState from "Components/Hooks/SetState"
import { OpenNavbar } from "Components/Hooks/ContextSideBar"
import { SocketContext } from "Components/Context/SocketProvider"
import { getUbicacion } from "service/helpers/localstorage"
import { interfaces } from "service/interfaces"
import { useLocation } from "react-router-dom"
import { axiosService } from "service"

import useStyles from "./styles"

const NavBarHome = () => {
  const { setAuth, notification } = useContext(SocketContext)
  let history = useHistory()
  const location = getUbicacion() || ""
  const { pathname } = useLocation()

  const classes = useStyles()

  const { openSideBar, setOpenSideBar } = useContext(OpenNavbar)
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])

  const title = () => {
    if ((pathname && pathname.includes("oem")) || location.includes("OEM"))
      return "OEM"
    return "ECO"
  }

  const {
    state: profile,
    setOpen: openProfile,
    setClose: closeProfile,
  } = SetState()

  const {
    state: historyState,
    setOpen: openHistory,
    setClose: closeHistory,
  } = SetState()

  const {
    state: settings,
    setOpen: openSettings,
    setClose: closeSettings,
    setState: setSettings,
  } = SetState()

  const closeSettingsDialog = () => {
    closeSettings()
    if (settings.isEdited) {
      window.location.reload()
    }
  }

  const anchorRef = useRef(null)

  const handleOpenSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const setReadNotification = () => {
    interfaces.dashboardInterface.readNotification()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setReadNotification()
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  const handleOpenCloseProfile = () => {
    profile.open ? closeProfile() : openProfile()
    setOpen(false)
  }

  const handleOpenCloseDrawerHistory = () => {
    historyState.open ? closeHistory() : openHistory()
    setOpen(false)
  }

  const backToLogin = () => {
    axiosService.logout()
    setAuth(false)
    if (location.includes("OEM")) return history.push("/oem")
    history.push("/")
  }

  useEffect(() => {
    interfaces.dashboardInterface
      .listNotification(1, 0)
      .then((res) => setData(res.data))
  }, [notification, open])

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
            marginRight: "6px",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                anchororigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <MenuItem onClick={() => handleOpenCloseProfile()}>
                  <Avatar
                    size="small"
                    src={localStorage.getItem("profile")}
                  ></Avatar>
                  &nbsp;&nbsp;Perfil
                </MenuItem>
                <Divider
                  style={{
                    height: 2,
                    margin: "5px 15px 5px 15px",
                  }}
                />
                <MenuItem onClick={() => handleOpenCloseDrawerHistory()}>
                  <HistoryIcon />
                  &nbsp;Historial
                </MenuItem>

                <MenuItem onClick={() => openSettings()}>
                  <SettingsIcon />
                  &nbsp;Configuración
                </MenuItem>

                <MenuItem onClick={() => backToLogin()}>
                  <ExitToAppIcon />
                  &nbsp;Cerrar sesión
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  return (
    <div>
      <AppBar className={classes.appBar} elevation={0} position="static">
        <Toolbar className={classes.grow}>
          <IconButton
            aria-label="menu"
            className={classes.menuButton}
            onClick={handleOpenSideBar}
          >
            <MenuIcon />
          </IconButton>
          <div>
            <Box textAlign="left" className={classes.hello}>
              <Tooltip title={localStorage.getItem("ubicacion")}>
                <MyLocationIcon className={classes.styleIcons} />
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Box
                fontWeight="fontWeightBold"
                fontSize={16}
                fontStyle="normal"
                fontFamily="fontFamily"
                style={{ display: "inline", color: "black" }}
              >
                ¡Bienvenido
              </Box>
              &nbsp;
              <Typography color="primary">
                <Box
                  fontWeight="fontWeightBold"
                  fontSize={16}
                  fontStyle="normal"
                  fontFamily="fontFamily"
                >
                  {JSON.parse(localStorage.getItem("user"))}!
                </Box>{" "}
              </Typography>
            </Box>
          </div>
          <div className={classes.search}>
            <Box display="flex" textAlign="center" justifyContent="center">
              <Typography color="primary">
                <Box
                  mr={0.5}
                  fontWeight="fontWeightBold"
                  fontSize={18}
                  fontStyle="normal"
                  fontFamily="fontFamily"
                  style={{ display: "inline" }}
                >
                  {title()}
                </Box>{" "}
              </Typography>
              <Box
                fontWeight="fontWeightBold"
                fontSize={18}
                fontStyle="normal"
                fontFamily="fontFamily"
                style={{ display: "inline", color: "black" }}
              >
                {title() === "ECO" ? "Autoparts" : "Parts"}
              </Box>
            </Box>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton color="inherit" onClick={handleClick}>
              <Badge badgeContent={data.length} color="primary">
                <NotificationsIcon className={classes.styleIcons} />
              </Badge>
            </IconButton>
            <Tooltip title="Cuenta">
              <IconButton ref={anchorRef} onClick={handleToggle} size="small">
                <Avatar size="small" src={localStorage.getItem("profile")}>
                  {JSON.parse(localStorage.getItem("user"))[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>

      {renderMenu}

      <DialogComponent
        tipo="slide"
        open={profile.open}
        handleclose={handleOpenCloseProfile}
        title="Perfil"
        children={<Profile />}
      />

      <DialogComponent
        tipo="slide"
        open={historyState.open}
        handleclose={closeHistory}
        title="Historial de actividad"
        children={<History />}
      />

      {settings.suspense && (
        <DialogComponent
          tipo="slide"
          open={settings.open}
          handleclose={closeSettingsDialog}
          title="Configuración"
          children={<ProfileSettings setSettings={setSettings} />}
        />
      )}

      <NavPopover
        data={data}
        setData={setData}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setReadNotification={setReadNotification}
      />
    </div>
  )
}

export default NavBarHome
