import React, { useState } from "react"

import DeleteIcon from "@material-ui/icons/Delete"

import ComplexDialog from "Components/UI/Molecules/ComplexDialog"
import useStyles from "./styles"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"
import BasicTable from "Components/UI/Atoms/BasicTable"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import ComplexDialogActions from "Components/UI/Molecules/ComplexDialog/Actions"
import SetState from "Components/Hooks/SetState"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"

const DialogStorages = (props) => {
  const { open, onClose, render, setRender, data: info } = props

  const [data, setData] = useState(info)

  const createDismissableSnackbar = useCustomSnackbar()

  const classes = useStyles()

  const { state, setOpen, setClose } = SetState()

  const onSubmit = () => {
    interfaces.plantStorage
      .editStorage(data.id, data)
      .then((res) => {
        createDismissableSnackbar(res.data.data, {
          variant: "success",
        })
        setRender(!render)
        onClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const deleteStorage = () => {
    interfaces.plantStorage
      .deleteStorage(data.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setRender(!render)
        onClose()
      })
      .catch((error) => {
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      })
  }

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    })
  }

  const FORM_HOME = () => {
    return [
      {
        class: "textfield",
        name: "almacen",
        label: "Almacén",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.almacen,
      },
      {
        class: "textfield",
        name: "nivel",
        label: "Nivel",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.nivel,
      },
      {
        class: "textfield",
        name: "estanteria",
        label: "Estantería",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.estanteria,
      },
      {
        class: "textfield",
        name: "columna",
        label: "Columna",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.columna,
      },
      {
        class: "textfield",
        name: "fila",
        label: "Fila",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.fila,
      },
      {
        class: "textfield",
        name: "limite",
        label: "Límite",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.limite,
      },
      {
        class: "textfield",
        name: "bin",
        label: "BIN",
        type: "number",
        sm: 2,
        xs: 12,
        disabled: true,
        value: data.bin,
      },
      {
        class: "textfield",
        name: "descripcion",
        label: "Descripción",
        sm: 10,
        xs: 12,
        value: data.descripcion,
        onChange: (e) => handleChange(e.target.name, e.target.value),
      },
    ]
  }

  const renderForms = (index) => {
    switch (index) {
      case 0:
        return (
          <div className={classes.home}>
            <div style={{ paddingBottom: "10px" }}>
              <InputsWithoutValidate fields={FORM_HOME()} spacing={2} />
            </div>
            <div className={classes.table}>
              <BasicTable
                rows={data.productos}
                col={[
                  { title: "Código", style: { width: "20%", top: "-1px" } },
                  { title: "Productos", style: { width: "60%" } },
                ]}
                size="small"
              />
            </div>
          </div>
        )
      default:
        break
    }
  }

  return (
    <>
      <ComplexDialog
        open={open}
        onClose={onClose}
        tabs={[{ title: "Inicio" }]}
        title={`Almacén ${data.empresa === 1 ? "ECO" : "OEM"} Z${data.codigo
          .toString()
          .padStart(4, 0)} - Cantidad de artículos: ${data.productos.length}`}
        children={renderForms}
        actions={
          <ComplexDialogActions
            onClose={onClose}
            handleSubmit={onSubmit}
            canEdit={true}
            data={data}
            editMode={false}
            leftButton={{
              icon: <DeleteIcon fontSize="small" />,
              title: "Eliminar",
              disabled: data.productos.length > 0 ? true : false,
              variant: "contained",
              style:
                data.productos.length > 0
                  ? {}
                  : { background: "rgb(244, 67, 54)", color: "#fff" },
              onClick: () => {
                setOpen()
              },
            }}
          />
        }
      />
      {state.suspense && (
        <DialogComponent
          open={state.open}
          handleclose={setClose}
          title="¿Desea eliminar este depósito?"
          subtitle="¡Esta acción es irreversible!"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          widthLarge="500px"
          maxHeight
          actions={
            <CancelConfirmButtons
              onClickCancel={setClose}
              onClickSubmit={() => deleteStorage(state.id, setClose)}
            />
          }
        />
      )}
    </>
  )
}

export default DialogStorages
