import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  popContent: {
    background: "#f4f7f4",
    borderRadius: "10px",
    overflow: "hidden",
    display: "block",
    border: "1px solid #EBECF0",
    zIndex: "2",
    width: "450px",
    padding: "30px",
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  headerFixed: {
    position: "fixed",
    top: 0,
    left: "250px",
    right: 0,
    height: "54px ",
    background: "#090909",
    zIndex: 1,
  },
}))

export default useStyles
