import { React, forwardRef } from "react"
import { Formik } from "formik"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"

import { TIPO_VEHICULO } from "Constants"

import { newVehicle } from "Components/Validation/Settings/Various/Transport"

const Form = forwardRef((props, ref) => {
  const { onSubmit } = props

  const fields = [
    {
      class: "textfield",
      label: "Dominio",
      name: "dominio",
      sm: 12,
      xs: 12,
      inputProps: { maxLength: 50 },
    },
    {
      class: "textfield",
      label: "Marca",
      name: "marca",
      sm: 12,
      xs: 12,
      inputProps: { maxLength: 50 },
    },
    {
      class: "textfield",
      label: "Modelo",
      name: "modelo",
      sm: 12,
      xs: 12,
      inputProps: { maxLength: 50 },
    },
    {
      class: "autocomplete",
      label: "Tipo",
      name: "tipo",
      options: TIPO_VEHICULO,
      optionLabel: `options["label"]`,
      optionValue: "id",
      sm: 12,
      xs: 12,
    },
  ]

  return (
    <>
      <Formik
        initialValues={{
          dominio: "",
          marca: "",
          modelo: "",
          tipo: "",
        }}
        validationSchema={newVehicle}
        onSubmit={onSubmit}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <>
            <InputsWithValidate
              fields={fields}
              setFieldValue={setFieldValue}
              spacing={1}
            />

            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </>
        )}
      </Formik>
    </>
  )
})
export default Form
