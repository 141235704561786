import React from "react"

import { Button } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

const SiempleUploadFile = (props) => {
  const { handleChange, change, loading, text } = props
  return (
    <form>
      <input
        style={{ display: "none" }}
        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        id="contained-button-file"
        name="file"
        type="file"
        multiple
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        {change.name !== null && change.name !== undefined ? (
          <Button
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            variant="contained"
            fullWidth
            component="span"
            disabled={loading}
          >
            Archivo(s) seleccionados
          </Button>
        ) : (
          <Button variant="contained" fullWidth component="span">
            {text}
          </Button>
        )}
      </label>
    </form>
  )
}

export default SiempleUploadFile
