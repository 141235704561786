import React, { useEffect, useState } from "react"

import { Grid, Button, Typography, Hidden } from "@material-ui/core"
import GetAppIcon from "@material-ui/icons/GetApp"
import BackupIcon from "@material-ui/icons/Backup"
import { read, utils } from "xlsx"
import Loader from "Components/UI/Atoms/Loader"
import InputSearch from "Components/UI/Atoms/InputSearch"
import PaperList from "Components/UI/Molecules/Papers/PaperList"
import FormEngine from "Components/UI/Organisms/Settings/Various/Engine/Children/FormEngine"
import ButtonNew from "./ButtonNew"
import ButtonDelete from "./ButtonDelete"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import CustomButton from "Components/UI/Atoms/CustomButton"
import { ButtonInputXls } from "Components/UI/Atoms/ButtonInputXls"
import SetState from "Components/Hooks/SetState"

const LIMIT = 12

const Children = (props) => {
  const { setCanClose, canClose } = props

  const [dataXlsx, setDataXlsx] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [idSelect, setIdSelect] = useState()
  const [data, setData] = useState([])
  const [render, setRender] = useState(false)
  const [index, setIndex] = useState([])

  const [valueEngine, setValueEngine] = useState("")
  const [offset, setOffset] = useState(0)

  const [search, setSearch] = useState([])

  const {
    state: buttonNew,
    setOpen: setOpenButtonNew,
    setClose: setCloseButtonNew
  } = SetState()

  const {
    state: buttonDelete,
    setOpen: setOpenButtonDelete,
    setClose: setCloseButtonDelete,
  } = SetState()

  const createDismissableSnackbar = useCustomSnackbar()

  const changeRender = () => {
    setRender(!render)
  }

  useEffect(() => {
    interfaces.interfaceEngine
      .configList(LIMIT, offset, search !== undefined ? search : null)
      .then((res) => {
        setData(res.data)
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
    //eslint-disable-next-line
  }, [render])

  function handleClose() {
    setRender(!render)
    setCloseButtonNew()
  }

  function handleReset() {
    setIdSelect()
    setCanClose(true)
  }

  function handleChange(e) {
    setValueEngine(e.target.value)
    if (e.target.value === "") {
      setSearch(undefined)
      setRender(!render)
    }
  }

  function handleNext() {
    setIdSelect()
    setOffset(offset + 1)
    changeRender()
  }
  function handlePrev() {
    setIdSelect()
    if (offset !== 0) {
      setOffset(offset - 1)
      changeRender()
    }
  }
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      changeRender()
      handleEnter()
      setIdSelect()
    }
  }
  function handleEnter() {
    setSearch(valueEngine.toUpperCase())
    setOffset(0)
  }

  const handleListItem = (item) => {
    if (canClose === true) {
      setIdSelect(item.id)
      setIndex(data.findIndex((e) => e.id === item.id))
    } else {
      createDismissableSnackbar("Tiene cambios sin guardar!", {
        variant: "error",
      })
    }
  }
  async function handleExportEngine() {
    setIsLoading(true)
    await interfaces.interfaceEngine
      .exportEngine()
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Tabla de motores descargada!", {
          variant: "success",
        })
        setIsLoading(false)
      })
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
        setIsLoading(false)
      })
  }

  async function upgradeEngines() {
    setIsLoading(true)
    await interfaces.interfaceEngine
      .upgradeEngines()
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setIsLoading(false)
        changeRender()
      })
      .catch((error) => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
        setIsLoading(false)
      })
  }

  async function onSubmitXlsx(json) {
    await interfaces.interfaceEngine
      .updateAllEngines(json)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        setDataXlsx([])
      })
      .catch((error) => {
        createDismissableSnackbar(error.data, {
          variant: "error",
        })
      })
    setIsLoading(false)
  }
  const readUploadFile = (e) => {
    setIsLoading(true)
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)
        setDataXlsx(json)
        onSubmitXlsx(json)
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
  }

  return (
    <div style={{ paddingBottom: "20px" }}>
      <Grid container spacing={3}>
        <Grid
          item
          sm={12}
          xs={12}
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <InputSearch
              onClick={setOpenButtonNew}
              onChange={handleChange}
              value={valueEngine}
              button={true}
              onKeyDown={onKeyDown}
              children={
                <Hidden smDown>
                  <div
                    style={{
                      display: "flex",
                      marginRight: "15px",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CustomButton
                      onClick={() => {
                        upgradeEngines()
                      }}
                      text="ACTUALIZAR"
                      size="large"
                    />
                    <Button
                      size="large"
                      onClick={() => {
                        handleExportEngine()
                      }}
                      variant="contained"
                    >
                      <GetAppIcon />
                    </Button>
                    <ButtonInputXls
                      name="xls"
                      textLater="EXCEL IMPORTADO"
                      textBefore={<BackupIcon />}
                      value={dataXlsx}
                      id={1}
                      onChange={readUploadFile}
                    />
                    {/* <Button
                      size="large"
                      onClick={() => {
                        handleExportEngine()
                      }}
                      variant="contained"
                    >
                      <BackupIcon />
                    </Button> */}
                  </div>
                </Hidden>
              }
            />
          </div>
          {buttonNew.open && <ButtonNew open={buttonNew.open} handleClose={handleClose} />}
        </Grid>

        <Grid item sm={3} xs={12}>
          <PaperList
            text="Eliminar motor"
            height="600px"
            pagination={true}
            disabledPrev={offset === 0}
            disableNext={Object.keys(data).length < LIMIT}
            onClick={setOpenButtonDelete}
            children={
              <ListWithIcon
                data={data}
                selectedItem={idSelect}
                onClickSelect={handleListItem}
                onClickDelete={setOpenButtonDelete}
                nameIncludes="codigo"
              />
            }
            onClickNext={handleNext}
            onClickPrev={handlePrev}
          />
        </Grid>
        <Grid item sm={9} xs={12}>
          {idSelect >= 0 ? (
            <PaperList
              height="600px"
              children={
                <FormEngine
                  data={data}
                  index={index}
                  id={idSelect}
                  handleRender={changeRender}
                  handleReset={handleReset}
                  setCanClose={setCanClose}
                />
              }
            />
          ) : (
            <PaperList
              height="600px"
              children={
                <Typography
                  style={{
                    height: "600px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Seleccione un motor para editar
                </Typography>
              }
            />
          )}
        </Grid>
      </Grid>

      {buttonDelete.open && (
        <ButtonDelete
          open={buttonDelete.open}
          id={idSelect}
          handleRender={changeRender}
          handleClose={setCloseButtonDelete}
          handleReset={handleReset}
          handleCloseDelete={setCloseButtonDelete}
        />
      )}
      {isLoading ? <Loader /> : null}
    </div>
  )
}
export default Children
