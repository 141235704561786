import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  grow: {
    display: "flex",
    marginBottom: "15px",
    justifyContent: "space-between",
  },
  button: {
    marginLeft: "10px",
    minWidth: "110px",
  },
  divButton: {
    display: "flex",
    justifyContent: "center",
  },
}))

export default useStyles
