import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import VerticalTabs from "./VerticalTabs"
import Tabs from "./Tabs"

const ContentAppBarTabs = ({ edit, id, data }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div>
      <Grid container>
        <Grid item sm={3} container justify="center" alignItems="center">
          <VerticalTabs value={value} onChange={handleChange} />
        </Grid>
        <Grid item sm={9} xs={12}>
          <Tabs value={value} edit={edit} id={id} data={data} />
        </Grid>
      </Grid>
    </div>
  )
}

export default ContentAppBarTabs
