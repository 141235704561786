import React, {  useState } from "react"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import MuiDatables from "Components/UI/Atoms/Table"
import SetState from "Components/Hooks/SetState"
import { interfaces } from "service/interfaces"

import EditContextProvider from "Components/Hooks/ContextEdit"

import Edit from "./Update"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"

const SuppliersTable = (props) => {
  const { render, setRender, dataBefore } = props

  const windowSize = useMediaQuery("(min-width:1000px)")
  const [loading, setLoading] = useState(false)
  
  const [values, setValues] = useState({
    rows: [],
    header: [],
  })

  const { state, setOpen, setClose } = SetState()

  async function apiCall(options, callback) {
    setValues({
      rows: [],
      header: [],
    })
    try {
      const result = await interfaces.salesSuppliers.suppliersList(options)
      setValues({
        rows: result.data,
        header: result.header.header.count,
      })
      callback()
    } catch (error) {}
  }
  
  async function supplierInfo(id) {
    await interfaces.salesSuppliers
      .infoOneSupplier(id)
      .then((res) => {
        setOpen({ 
          data: res.data 
        })
      })
  }

  const ESTADO = [
    {
      id: 0,
      label: "Desactivo"
    },
    {
      id: 1,
      label: "Activo"
    },
    {
      id: 2,
      label: "Todos"
    }
  ]

  const RowsTransit = () =>
    values.rows.map((file) => [
      file.proveedor,
      file.denominacion,
      file.cuit,
      file.tipo_responsable,
      ""
    ])

  let columns = [
    {
      name: "proveedor",
      label: "Razon Social",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "denominacion",
      label: "Denominación",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "cuit",
      label: "CUIT",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "tipo_responsable",
      label: "Tipo responsable",
      options: {
        filter: true,
        sort: false,
        sortThirdClickReset: true,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(dataBefore).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Tipo responsable"}
                  filterName={"name"}
                  options={dataBefore.data.tipo_responsable}
                  fullWidth={true}
                />
              )
            }
          },
        },
      },
    }, 
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: false,
        display: false,
        sortThirdClickReset: true,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(dataBefore).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Estado"}
                  filterName={"label"}
                  options={ESTADO}
                  defaultOption={{ label: "Activo", id: 1 }}
                  style={{width: '200px', paddingRight: '100px'}}
                  fullWidth={true}
                />
              )
            }
          },
        },
      },
    }
  ]

  return (
    <>
      {state.suspense && (
        <EditContextProvider>
            <Edit
              open={state.open}
              handleclose={setClose}
              data={state.data}
              setRender={setRender}
              render={render}
              setLoading={setLoading}
              setOpen={setOpen}
              setValues={setValues}
              loading={loading}
              dataBefore={dataBefore}
            />
        </EditContextProvider>
      )}

      <MuiDatables
        columns={columns}
        data={RowsTransit()}
        title={`Total de proveedores: ${values.rows.length}`}
        apiCall={apiCall}
        count={values.header}
        render={render}
        setRender={setRender}
        textPlaceholder="Buscar por razón social, denomicación o cuit..."
        onRowClick={(_, index) => supplierInfo(values.rows[index.dataIndex].id)}
        padding={windowSize ? "10px" : ""}       
      />
    </>
  )
}
export default SuppliersTable