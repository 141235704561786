import React from "react"
import { TextField } from "@material-ui/core"

import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import SearchIcon from "@material-ui/icons/Search"
import { InputAdornment } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useStyles from "./style"

const InputButtonWithOutFormik = (props) => {
  const classes = useStyles()
  const {
    name,
    placeholder,
    onSubmit,
    handleChangeCode,
    code,
    value,
    defaultValue,
    disabled,
    autoFocus = true,
    style,
    disabledSubmit,
    loading,
  } = props

  const IconAdornment = () => {
    if (loading) {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <CircularProgress
            style={{
              color: "primary",
              width: "24px",
              height: "24px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
        </InputAdornment>
      )
    } else {
      return (
        <InputAdornment position="end" className={classes.endAdornment}>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={() => onSubmit(code)}
            disabled={disabledSubmit || disabled}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )
    }
  }

  return (
    <div>
      <TextField
        style={style}
        name={name}
        fullWidth
        disabled={disabled}
        onChange={handleChangeCode}
        onInput={handleChangeCode}
        variant="outlined"
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        label={placeholder}
        InputProps={{
          endAdornment: <IconAdornment />,
        }}
        autoFocus={autoFocus}
        onKeyPress={(event) => {
          if (event.key === "Enter" && !disabledSubmit) {
            event.stopPropagation()
            onSubmit(code)
          }
        }}
      />
    </div>
  )
}

export default InputButtonWithOutFormik
