import React, { useContext, useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { EditContext } from "Components/Hooks/ContextEdit"
import { FormContext } from "Components/Hooks/ContextForm"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import { Button } from "@material-ui/core"

const SubmitSave = (props) => {
  const {
    handleclose,
    setRenderTable,
    renderTable,
    setRenderModal,
    renderModal,
    closeBigDialog,
  } = props

  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line
  const [edit, setEdit] = useContext(EditContext)
  const { setFormApi, formApi, formEdit } = useContext(FormContext)
  const createDismissableSnackbar = useCustomSnackbar()

  const SubmitApi = async () => {
    setEdit(false)
    setLoading(true)
    await interfaces.vehicleInterface
      .putOneVehicle(formEdit.vehicle.id, formApi)
      .then(() => {
        createDismissableSnackbar("Vehículo editado con éxito!", {
          variant: "success",
        })
        setFormApi({})
        setTimeout(() => {
          setRenderModal(!renderModal)
          setRenderTable(!renderTable)
        }, 500)
        closeBigDialog()
      })
      .catch(() =>
        createDismissableSnackbar("Hubo un error!", {
          variant: "error",
        })
      )
    handleclose()
    setLoading(false)
  }

  return (
    <div>
      {formEdit.vehicle.vehiculo_info.chasis_nro.length > 0 &&
      formEdit.vehicle.vehiculo_info.chasis_nro.length !== 17 ? (
        <DialogComponent
          open={props.open}
          handleclose={() => handleclose()}
          title="Error en la longitud del número de chasis"
          widthLarge={"450px"}
          disableChildHeight
          actions={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => handleclose()}
                variant="contained"
                color="primary"
                type="submit"
              >
                Cerrar
              </Button>
            </div>
          }
        ></DialogComponent>
      ) : (
        <div>
          <DialogComponent
            open={props.open}
            handleclose={handleclose}
            title="¿Desea confirmar los cambios?"
            widthLarge={"450px"}
            disableChildHeight
            actions={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CancelConfirmButtons
                  onClickCancel={() => handleclose()}
                  onClickSubmit={() => SubmitApi()}
                  submitText="Aceptar"
                  isLoading={loading}
                  disabledCancel={loading}
                  disabledSubmit={loading}
                />
              </div>
            }
          />
        </div>
      )}
    </div>
  )
}

export default SubmitSave
