import React from "react"

import Papers from "Components/UI/Molecules/Papers/PaperList"
import DeleteItemList from "./DeleteItemList"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"

const ItemList = (props) => {
  const {
    data,
    handleListItemClick,
    selectedIndex,
    changeRender,
    valueTransport,
    idSelected,
    RenderContentItemList,
  } = props

  const { state, setOpen, setClose } = SetState()

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueTransport) {
          return e.nombre.includes(valueTransport.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  return (
    <>
      <DeleteItemList
        open={state.open}
        handleClose={setClose}
        selectedIndex={idSelected}
        changeRender={changeRender}
        RenderContentItemList={RenderContentItemList}
      />
      <Papers
        height="600px"
        disabledOn={selectedIndex >= 0 ? false : true}
        onClick={setOpen}
        children={
          <ListWithIcon
            data={filtered(data)}
            selectedItem={idSelected}
            onClickSelect={handleListItemClick}
            onClickDelete={setOpen}
            nameIncludes="nombre"
          />
        }
      />
    </>
  )
}

export default ItemList
